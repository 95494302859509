import { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import DivisionRequestModel from 'ab-requestModels/division.requestModel';
import { GroupedDivisions } from 'ab-viewModels/division.viewModel';

import { GetRootState } from 'af-reducers';

export function create(form: DivisionRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.RESOURCES.DIVISION.CREATE(), { ...form });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(divisionId: number, form: DivisionRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.RESOURCES.DIVISION.EDIT(divisionId.toString()), { ...form });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteDivision(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.DIVISION.DELETE(id.toString()));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<GroupedDivisions>(API.V1.RESOURCES.DIVISION.LIST(''));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
