import { Dispatch, AnyAction } from 'redux';

import FieldReportAccessVM from 'ab-viewModels/fieldReportAccess/fieldReportAccessByWorkOrder.viewModel';

import AccessRequestModel from 'ab-requestModels/fieldReport/fieldReportAccess.requestModel';

import API from 'af-constants/routes/api';

import { GetRootState } from 'af-reducers';

import { errorHandler } from 'af-utils/actions.util';
import { http } from 'af-utils/http.util';

export function findAllByWorkOrderId(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<FieldReportAccessVM[]>(API.V1.FIELD_REPORT_ACCESS.FIND_BY_WORK_ORDER_ID(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(workOrderId: number, accesses: AccessRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<void>(API.V1.FIELD_REPORT_ACCESS.BULK_UPDATE(workOrderId), accesses);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
