import * as React from 'react';

interface OwnProps {
	message: string;
}

type Props = OwnProps;

const DescriptionBullet: React.FC<Props> = ({ message }: Props) => {
	return (
		<li>
			{message}
		</li>
	);
};

export default DescriptionBullet;
