import * as React from 'react';

import { FieldReportListItemVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import FieldReport from './FieldReport';

interface OwnProps {
	fieldReports: FieldReportListItemVM[];
}

type Props = OwnProps;

const FieldReports: React.FC<Props> = (props) => {
	const { fieldReports } = props;

	if (!fieldReports?.length) {
		return null;
	}

	const renderFieldReport = (fieldReport: FieldReportListItemVM, index: number) => {
		return (
			<FieldReport
				fieldReport={fieldReport}
				index={index}
				key={index}
			/>
		);
	};

	return (
		<div className="report-submit-modal__section">
			<div className="report-submit-modal__section__title">FIELD REPORTS</div>
			<div className="report-submit-modal__section__list">
				{fieldReports.map(renderFieldReport)}
			</div>
		</div>
	);
};

export default React.memo(FieldReports);
