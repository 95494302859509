import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Field, FieldArray, FormErrors, formValueSelector, getFormSubmitErrors, getFormSyncErrors, InjectedFormProps } from 'redux-form';
import { Row, Col, Form, Button } from 'react-bootstrap';

import Checkbox from 'af-fields/Checkbox';
import SegmentLabel from 'af-components/SegmentLabel';

import * as AccountActions from 'af-actions/accounts';
import * as LocationActions from 'af-actions/location';
import * as EmployeeActions from 'af-actions/employee';
import * as DivisionActions from 'af-actions/division';
import * as DeliverableDataActions from 'af-actions/deliverableData';
import * as ContactActions from 'af-actions/contacts';
import * as JobStatusActions from 'af-actions/jobStatus';

import { useToggle } from 'af-utils/react.util';

import { RootState } from 'af-reducers';

import ImportBillingCodeRM from 'ab-requestModels/billingCode/importBillingCode.requestModel';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import JobUpsertFM from '../../../formModel';

import BillingCodeArray, { BillingCodeErrors, OwnProps as BillingCodeArrayProps } from './BillingCodes/BillingCodeArray';
import BulkImport from './BulkImport';

type JobValidationErrors = JobUpsertFM;

interface OwnProps {
	formName: string;
	isDisabled: boolean;
	change: (fieldName: string, value: string | number | boolean | Metadata | null) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<JobUpsertFM>;

const CustomerSignatureAndBillingCodesSection: React.FC<Props> = (props) => {

	const {
		isDisabled,
		change,
		billingCodes,
		billingCodeSyncErrors,
		billingCodeSubmitErrors,
		hasPermissionsToManageBillingCodes,
	} = props;

	const [importedBillingCodes, setImportedBillingCodes] = React.useState<ImportBillingCodeRM[]>([]);

	const {
		value: showBulkImportModal,
		setToTrue: openBulkImportModal,
		setToFalse: hideBulkImportModal,
	} = useToggle(false);

	const { lineItemNumberCounter, customerIdCounter } = React.useMemo(() => {
		const _lineItemNumberCounter: Record<number, number> = {};
		const _customerIdCounter: Record<string, number> = {};
		let _hasDuplicates = false;
		if (billingCodes?.length) {
			for (const billingCode of billingCodes) {
				if (billingCode.lineItemNumber) {
					_lineItemNumberCounter[billingCode.lineItemNumber] = (_lineItemNumberCounter[billingCode.lineItemNumber] ?? 0) + 1;
					if (_lineItemNumberCounter[billingCode.lineItemNumber] > 1) {
						_hasDuplicates = true;
					}
				}
				if (billingCode.customerId) {
					_customerIdCounter[billingCode.customerId] = (_customerIdCounter[billingCode.customerId] ?? 0) + 1;

					if (_customerIdCounter[billingCode.customerId] > 1) {
						_hasDuplicates = true;
					}
				}
			}
		}

		return {
			lineItemNumberCounter: _lineItemNumberCounter,
			customerIdCounter: _customerIdCounter,
			hasDuplicates: _hasDuplicates,
		};
	}, [billingCodes]);

	return (
		<Form className="form-box">
			<Row>
				<Col sm={24}>
					<SegmentLabel label="Customer signature" />
				</Col>
			</Row>
			<Row>
				<Col sm={8}>
					<Field
						component={Checkbox}
						inline={true}
						isDisabled={isDisabled}
						isStandalone={true}
						label="Allow Customer Signature"
						name="allowCustomerSignature"
						propName="allowCustomerSignature"
					/>
				</Col>
			</Row>
			{hasPermissionsToManageBillingCodes &&
				<>
					<Row>
						<Col sm={24}>
							<SegmentLabel label="Billing Codes" />
						</Col>
					</Row>
					<Row>
						<Col sm={24}>
							<Button className="btn btn--link" onClick={openBulkImportModal}>
								<span className="icon-upload" />
								<span>CSV Import</span>
							</Button>
						</Col>
					</Row>
					<FieldArray<BillingCodeArrayProps>
						billingCodeSubmitErrors={billingCodeSubmitErrors}
						billingCodeSyncErrors={billingCodeSyncErrors}
						change={change}
						component={BillingCodeArray}
						customerIdCounter={customerIdCounter}
						importedBillingCodes={importedBillingCodes}
						lineItemNumberCounter={lineItemNumberCounter}
						name="billingCodes"
						rerenderOnEveryChange={true}
					/>
				</>
			}
			<BulkImport
				addImportedBillingCodes={setImportedBillingCodes}
				closeModal={hideBulkImportModal}
				showModal={showBulkImportModal}
			/>
		</Form>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const hasPermissionsToManageBillingCodes = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_BILLING_CODES,
		permissions,
		isCompanyAdmin,
		role
	);

	const selector = formValueSelector(ownProps.formName);

	const getErrors = getFormSyncErrors(ownProps.formName);
	const getSubmitErrors = getFormSubmitErrors(ownProps.formName);

	const { billingCodes: billingCodeSyncErrors } = getErrors(state) as FormErrors<JobValidationErrors, string>;
	const { billingCodes: billingCodeSubmitErrors } = getSubmitErrors(state) as FormErrors<JobValidationErrors, string>;

	const {
		allowCustomerSignature,
		billingCodes,
	} = selector(state, 'allowCustomerSignature', 'billingCodes');

	return {
		allowCustomerSignature,
		hasPermissionsToManageBillingCodes,
		billingCodes,
		billingCodeSyncErrors: billingCodeSyncErrors as unknown as BillingCodeErrors,
		billingCodeSubmitErrors: billingCodeSubmitErrors as unknown as BillingCodeErrors,
	};
}

function mapDispatchToProps() {
	return {
		findAllProjectManagers: EmployeeActions.findAllProjectManagers,
		loadDeliverableData: DeliverableDataActions.findAllForCompany,
		findAllAssignableForDeliverable: AccountActions.findAllAssignableForDeliverable,
		findAllLocations: LocationActions.findList,
		findAllDivisions: DivisionActions.findAllForCompany,
		findAllContacts: ContactActions.findAllContacts,
		findAllJobStatuses: JobStatusActions.findList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(CustomerSignatureAndBillingCodesSection);
