import * as React from 'react';

import { TIMEOUT_DELAY } from 'af-constants/values';

export default class TimeoutComponent<P = Record<string, unknown>, S = Record<string, unknown>> extends React.Component<P, S> {
	timeoutTimer: number;

	cancelTimeout = async (): Promise<void> => {
		if (this.timeoutTimer) {
			window.clearTimeout(this.timeoutTimer);
		}
	};

	startTimeout = async (onTimeout: () => void, timeoutDelay: number = TIMEOUT_DELAY): Promise<void> => {
		this.cancelTimeout();
		this.timeoutTimer = window.setTimeout(async () => {
			onTimeout();
		}, timeoutDelay);
	};
}
