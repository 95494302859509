import CountryCode from 'acceligent-shared/enums/countryCode';
import UserPermission from 'acceligent-shared/enums/userPermission';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';
import AccountPermissionTemplate from 'acceligent-shared/enums/accountPermissionTemplate';
import MemberInviteEnum from 'acceligent-shared/enums/memberInvite';
import SystemNotificationType from 'acceligent-shared/enums/systemNotificationType';
import { TimeOptionValues } from 'acceligent-shared/enums/timeOption';

import * as BlobStorageUtil from 'acceligent-shared/utils/blobStorage';

import Account from 'acceligent-shared/models/account';
import Permission from 'acceligent-shared/models/permission';
import User from 'acceligent-shared/models/user';
import Organization from 'acceligent-shared/models/organization';
import SystemNotification from 'acceligent-shared/models/systemNotification';
import { getStorageUrl } from 'acceligent-shared/utils/blobStorage';

class W_Authentication_PhoneLogin_VM_SystemNotification {
	id: number;
	type: SystemNotificationType;
	content: string;
	startsAt: Date;
	startsAtDate?: string; // only on unparsed version
	startsAtTime?: TimeOptionValues; // only on unparsed version
	endsAt: Date;
	endsAtDate?: string; // only on unparsed version
	endsAtTime?: TimeOptionValues; // only on unparsed version

	static fromModelToRequestModel(systemNotification: SystemNotification): W_Authentication_PhoneLogin_VM_SystemNotification {
		return {
			id: systemNotification.id,
			type: systemNotification.type,
			content: systemNotification.content,
			startsAt: systemNotification.startsAt,
			endsAt: systemNotification.endsAt,
		};
	}

	static fromModelListToRequestModelList(systemNotifications: SystemNotification[]): W_Authentication_PhoneLogin_VM_SystemNotification[] {
		return systemNotifications.map((_sn) => this.fromModelToRequestModel(_sn));
	}

}

class W_Authentication_PhoneLogin_VM_OrganizationData {
	organizationId: number;
	name: string;
	alias: string;
	/** Can be empty if accounts not passed */
	companies: W_Authentication_PhoneLogin_VM_CompanyData[];
	isPlatformAdmin: boolean;

	constructor(organization: Organization, accounts: Account[]) {
		this.organizationId = organization.id;
		this.name = organization.name;
		this.alias = organization.alias;
		this.isPlatformAdmin = organization.isPlatformAdmin;
		this.companies = (accounts ?? [])
			.map((_account: Account) => new W_Authentication_PhoneLogin_VM_CompanyData(_account))
			.sort((_company1: W_Authentication_PhoneLogin_VM_CompanyData, _company2: W_Authentication_PhoneLogin_VM_CompanyData) =>
				(_company1.name.localeCompare(_company2.name)));
	}
}

class W_Authentication_PhoneLogin_VM_UserData {
	/** -1 if platform admin */
	id: number;
	firstName: string;
	lastName: string;
	token: Nullable<string>;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;
	isFinalized: boolean;
	status: MemberInviteEnum;
	role: UserPermission;
	imageUrl: string;
	offlinePin: Nullable<string>;
	isDigitalSignatureEnabled: boolean;
	digitalSignatureUrl: Nullable<string>;
	digitalSignatureId: Nullable<number>;
	showCreateDigitalSignature: boolean;

	constructor(user: User, token?: string) {
		this.id = user.id;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.token = token ?? null;
		this.email = user.email;
		this.phoneNumber = user.phoneNumber;
		this.countryCode = user.countryCode;
		this.isFinalized = user.isFinalizedByEmail;
		this.status = user.status;
		this.role = user.role;
		this.offlinePin = user.offlinePin;
		this.isDigitalSignatureEnabled = user.isDigitalSignatureEnabled;
		this.showCreateDigitalSignature = user.showCreateDigitalSignature;

		const signature = user.digitalSignature;
		this.digitalSignatureUrl = (user.isDigitalSignatureEnabled && signature)
			? getStorageUrl(signature.signatureAttachment.storageContainer, signature.signatureAttachment.storageName)
			: null;
		this.digitalSignatureId = (user.isDigitalSignatureEnabled && signature)
			? signature.id
			: null;

		const result = user.imageUrl && BlobStorageUtil.parseStorageUrl(user.imageUrl);
		if (result) {
			result.directories = BlobStorageUtil.replaceDirectorySize(result.directories, BlobStorageImageSizeContainer.SIZE_200X200, true);
			this.imageUrl = BlobStorageUtil.getStorageUrl(result.directories, result.filename);
		}
	}
}

class W_Authentication_PhoneLogin_VM_CompanyData {
	id: number;
	name: string;
	permissions: string[];
	accountId: number;
	accountTemplate: AccountPermissionTemplate;
	logo: string;
	isCompanyAdmin: boolean;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	assignableAsManagement: boolean;
	assignableAsTechnician: boolean;
	assignableAsAccounting: boolean;
	complianceEhsUrl: Nullable<string>;
	areWorkRequestsEnabled: boolean;
	arePortalFunctionalitiesEnabled: boolean;
	isSendingInvoiceNotificationsEnabled: boolean;
	purchaseOrderNumberPrefix: Nullable<string>;

	constructor(account: Account) {
		this.name = account.company.name;
		this.id = account.company.id;
		this.permissions = account.permissions.map((_permission: Permission) => (_permission.permission.toString()));
		this.accountId = account.id;
		this.accountTemplate = account.accountTemplate;
		const parseResult = account.company.imageUrl ? BlobStorageUtil.parseStorageUrl(account.company.imageUrl) : null;
		this.logo = parseResult ? BlobStorageUtil.getStorageUrl(parseResult.directories, parseResult.filename) : '';
		this.isCompanyAdmin = account.isCompanyAdmin;
		this.assignableToWorkOrder = account.assignableToWorkOrder;
		this.assignableAsSuperintendent = account.assignableAsSuperintendent;
		this.assignableAsProjectManager = account.assignableAsProjectManager;
		this.assignableAsQAQC = account.assignableAsQAQC;
		this.assignableAsManagement = account.assignableAsManagement;
		this.assignableAsTechnician = account.assignableAsTechnician;
		this.assignableAsAccounting = account.assignableAsAccounting;
		this.complianceEhsUrl = account.company.complianceEhsUrl;
		this.areWorkRequestsEnabled = account.company.areWorkRequestsEnabled;
		this.arePortalFunctionalitiesEnabled = account.company.arePortalFunctionalitiesEnabled;
		this.isSendingInvoiceNotificationsEnabled = account.company.isSendingInvoiceNotificationsEnabled;
		this.purchaseOrderNumberPrefix = account.company.purchaseOrderNumberPrefix;
	}
}

/** NOTE: This view model is kept in localstorage on FE. Do not send entire User data from here */
export class W_Authentication_PhoneLogin_VM {
	organization: W_Authentication_PhoneLogin_VM_OrganizationData;
	user: W_Authentication_PhoneLogin_VM_UserData;
	systemNotifications: W_Authentication_PhoneLogin_VM_SystemNotification[];

	constructor(user: User, token: string, systemNotifications: SystemNotification[] = []) {
		this.user = new W_Authentication_PhoneLogin_VM_UserData(user, token);
		this.organization = new W_Authentication_PhoneLogin_VM_OrganizationData(user.organization, user.accounts);
		this.systemNotifications = W_Authentication_PhoneLogin_VM_SystemNotification.fromModelListToRequestModelList(systemNotifications);
	}
}
