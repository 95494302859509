import * as React from 'react';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';

import { WageRateRequestModel } from 'ab-requestModels/wageRate.requestModel';

import { WAGE_RATE_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import { RootState } from 'af-reducers';

import * as WageRateActions from 'af-actions/wageRate';

import CreateWageRateForm from 'af-components/SharedForms/Resources/WageRate';
import { validate } from 'af-components/SharedForms/Resources/WageRate/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	create: typeof WageRateActions.create;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps> & InjectedFormProps<WageRateRequestModel>;

const INITIAL_VALUES = { hourlyRate: 0.00 };

class Create extends React.Component<Props> {

	createWageRate = async (form: WageRateRequestModel) => {
		const {
			create,
			location: { state: { orgAlias } },
			companyName,
		} = this.props;
		await create(form, companyName, orgAlias);
	};

	render() {
		const {
			location: { state: { orgAlias } },
			companyName,
			invalid,
			submitting,
			handleSubmit,
		} = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={[
						{ label: 'Wage Rates', url: CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, companyName) },
						{ label: 'New Wage Rate' },
					]}
				/>
				<form onSubmit={handleSubmit(this.createWageRate)}>
					<CreateWageRateForm
						disabled={invalid}
						submitting={submitting}
					/>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		create: WageRateActions.create,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect(mapStateToProps, mapDispatchToProps()),
	reduxForm({ form: WAGE_RATE_CREATE, validate, enableReinitialize: true, initialValues: INITIAL_VALUES })
);

export default enhance(Create);
