import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import { TimeSheetInternalApprovalStatus } from 'acceligent-shared/enums/timeSheetApprovalStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';
import { filterMap, groupBy } from 'acceligent-shared/utils/array';

import * as TimeUtils from 'acceligent-shared/utils/time';

import PayrollEntryForCompany from 'ab-common/interfaces/domainEntities/views/jobPayrollTable/payrollEntryForCompany';

import { stateAbbreviation } from 'ab-enums/states.enum';

import { formatDecimalNumber } from 'ab-utils/formatting.util';

// #region Private:CSV

const _booleanFormatter = (value: boolean) => value ? 'Yes' : 'No';
const _dateOnlyFormatter = (value: string) => TimeUtils.formatDate(value, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

type ParentRowCSVKeys = keyof JobPayrollTableBetaRowVM & (
	| 'calculatedJobCode'
	| 'jobTitle'
	| 'divisionName'
	| 'allCrewIsInternal'
);
type ChildRowCSVKeys = keyof JobPayrollChildTableBetaRowVM & (
	| 'localDateValue'
	| 'workOrderShift'
	| 'workOrderWeeklyCode'
	| 'isWorkOrderCanceled'
	| 'crewName'
	| 'userUniqueId'
	| 'userFullName'
	| 'accountLocationAddressState'
	| 'isTimeSheetSigned'
	| 'isTimeSheetApproved'
	| 'timeSheetNote'
	| 'fieldWorkClassificationCode'
	| 'equipmentIdCodes'
	| 'jobHours'
	| 'totalJobHours'
	| 'breakHours'
	| 'totalBreakHours'
	| 'shopHours'
	| 'totalShopHours'
	| 'travelHours'
	| 'totalTravelHours'
	| 'totalHours'
	| 'totalHoursPerWO'
);
/** Complex keys require dynamic getters/formatters to be created in runtime */
type ComplexValueCSVKeys = 'reportUrl';

type CSVKey = ParentRowCSVKeys | ChildRowCSVKeys | ComplexValueCSVKeys;

interface SharedCSVMetadataProps {
	label: string;
	index: number;
}
interface ParentRowCSVMetadataProps<TKey extends ParentRowCSVKeys> extends SharedCSVMetadataProps {
	isInChildTable: false;
	formatter?: (value: JobPayrollTableBetaRowVM[TKey]) => string;
	fallback?: string;
	isComplexValue?: false;
}
interface ChildRowCSVMetadataProps<TKey extends ChildRowCSVKeys> extends SharedCSVMetadataProps {
	isInChildTable: true;
	formatter?: (value: JobPayrollChildTableBetaRowVM[TKey]) => string;
	fallback?: string;
	isComplexValue?: false;
}
interface ComplexValueCSVMetadataProps extends SharedCSVMetadataProps {
	isComplexValue: true;
}

/** Value used ONLY inside `CSV_METADATA_LOOKUP` to initialize `index` values */
let CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX = -1;

const CSV_METADATA_LOOKUP: Readonly<(
	& { [ParentKey in ParentRowCSVKeys]: Readonly<ParentRowCSVMetadataProps<ParentKey>>; }
	& { [ChildKey in ChildRowCSVKeys]: Readonly<ChildRowCSVMetadataProps<ChildKey>>; }
	& { [ComplexKey in ComplexValueCSVKeys]: Readonly<ComplexValueCSVMetadataProps> }
)> = {
	localDateValue: { isInChildTable: true, label: 'Date', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX, formatter: _dateOnlyFormatter },
	calculatedJobCode: { isInChildTable: false, label: 'Job Id', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	workOrderShift: { isInChildTable: true, label: 'Work Order', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	workOrderWeeklyCode: { isInChildTable: true, label: 'Work Order Weekly', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	userUniqueId: { isInChildTable: true, label: 'Employee ID', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	userFullName: { isInChildTable: true, label: 'Employee Name', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	divisionName: { isInChildTable: false, label: 'Division', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	accountLocationAddressState: { isInChildTable: true, label: 'Home State', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	fieldWorkClassificationCode: { isInChildTable: true, label: 'Classification Code', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX, fallback: '[MISSING]' },
	equipmentIdCodes: { isInChildTable: true, label: 'Equipment', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	timeSheetNote: { isInChildTable: true, label: 'Time Card Note', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	jobHours: { isInChildTable: true, label: 'Job Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	shopHours: { isInChildTable: true, label: 'Shop Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	travelHours: { isInChildTable: true, label: 'Travel Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	breakHours: { isInChildTable: true, label: 'Break Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalJobHours: { isInChildTable: true, label: 'Total Job Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalShopHours: { isInChildTable: true, label: 'Total Shop Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalTravelHours: { isInChildTable: true, label: 'Total Travel Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalBreakHours: { isInChildTable: true, label: 'Total Break Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalHours: { isInChildTable: true, label: 'Total Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalHoursPerWO: { isInChildTable: true, label: 'Total Hours/WO', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	jobTitle: { isInChildTable: false, label: 'Job Title', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	allCrewIsInternal: { isInChildTable: false, label: 'Job Is Internal', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	crewName: { isInChildTable: true, label: 'Crew', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	isTimeSheetSigned: { isInChildTable: true, label: 'Time Is Signed', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX, formatter: _booleanFormatter },
	isTimeSheetApproved: { isInChildTable: true, label: 'Time Is Approved', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX, formatter: _booleanFormatter },
	isWorkOrderCanceled: { isInChildTable: true, label: 'Work Order Is Cancelled', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX, formatter: _booleanFormatter },
	reportUrl: { isComplexValue: true, label: 'Report URL', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
};

type _CSVMetadataLookup = { [TKey in CSVKey]: (typeof CSV_METADATA_LOOKUP)[TKey] & { key: TKey; } };
type CSVMetadata = _CSVMetadataLookup[CSVKey];
type SimpleValueCSVMetadata = Omit<_CSVMetadataLookup, ComplexValueCSVKeys>[Exclude<CSVKey, ComplexValueCSVKeys>];

const CSV_METADATA_LIST = Object.keys(CSV_METADATA_LOOKUP)
	.map((_key): CSVMetadata => ({ ...CSV_METADATA_LOOKUP[_key], key: _key }))
	.sort((_e1, _e2) => _e1.index - _e2.index);

const CSV_HEADER_ROW = CSV_METADATA_LIST.map((_metadata) => _metadata.label);

const BETA_COLUMN_KEYS = [
	'totalJobHours',
	'totalBreakHours',
	'totalShopHours',
	'totalTravelHours',
	'totalHours',
	'totalHoursPerWO',
];

const BETA_COLUMN_LABELS = filterMap(CSV_METADATA_LIST, (value) => BETA_COLUMN_KEYS.includes(value.key), (value) => value.label);

type ComplexValueGetter = (parentRow: JobPayrollTableBetaRowVM, childRow?: JobPayrollChildTableBetaRowVM) => string;

// #endregion Private:CSV

export type JobPayrollTableCSVComplexValueGetterLookup = Record<ComplexValueCSVKeys, ComplexValueGetter>;

function calculateDateIndex(reference: string, date: string, timezone: Nullable<string>) {
	const referenceMidnight = timezone
		? TimeUtils.parseMomentTimezone(reference, timezone, TimeFormat.DB_DATE_ONLY, true).toDate()
		: TimeUtils.parseDate(reference, TimeFormat.DB_DATE_ONLY);

	return Math.floor(TimeUtils.getDiff(date, referenceMidnight, 'day', TimeFormat.ISO_DATETIME));
}

function calculateWeekIndex(reference: string, date: string, timezone: Nullable<string>) {
	const referenceMidnight = timezone
		? TimeUtils.parseMomentTimezone(reference, timezone, TimeFormat.DB_DATE_ONLY, true).toDate()
		: TimeUtils.parseDate(reference, TimeFormat.DB_DATE_ONLY);

	const parsedDate = timezone
		? TimeUtils.parseMomentTimezone(date, timezone, TimeFormat.DB_DATE_ONLY, true).toDate()
		: TimeUtils.parseDate(date, TimeFormat.DB_DATE_ONLY);

	const referenceWeekStart = TimeUtils.positionDate(referenceMidnight, 'start', 'week', timezone ?? undefined);
	const dateWeekStart = TimeUtils.positionDate(parsedDate, 'start', 'week', timezone ?? undefined);

	return TimeUtils.getDiff(dateWeekStart, referenceWeekStart, 'week', TimeFormat.ISO_DATETIME);
}

export class JobPayrollChildTableBetaRowVM {
	// Computed time range columns:

	/** `YYYY-MM-DD`, time date value based on notification settings timezone */
	localDateValue: string;

	// WorkOrder columns:

	workOrderId: number;
	/** `YYYY-MM-DD` */
	workOrderDueDate: string;
	isWorkOrderCanceled: boolean;

	/** `customCrewType` of WO if internal, otherwise `CrewType.name` */
	crewName: string;

	// WorkOrder x Computed time range columns:

	/**
	 * `WorkOrder['code']` (the middle part of the `calculated_work_order_code` string)
	 * plus a shift, i.e. day indicator (see implementation)
	 *
	 * @see {@link https://acceligent.atlassian.net/browse/AP-6711 AP-6711} for some special cases of how shits should be assigned.
	 */
	workOrderShift: string;
	workOrderWeeklyCode: string;

	// Account columns:

	userId: number;
	userUniqueId: string;
	userFullName: string;

	accountId: number;
	accountLocationAddressState: Nullable<string>;

	// TimeSheet & TimeSplit columns:

	timeSheetId: number;

	isTimeSheetSigned: boolean;
	isTimeSheetApproved: boolean;
	timeSheetNote: Nullable<string>;

	hasClassificationCode: boolean;

	/** `timeSplitFWCCCode` ?? `timeSplitsWithZeroTimeFWCCCodesText` ?? null */
	fieldWorkClassificationCode: Nullable<string>;
	/** `timeSplitEquipmentIdCodesText` ?? `timeSplitsWithZeroTimeEquipmentIdCodesText` ?? null */
	equipmentIdCodes: Nullable<string>;

	/** `#.##` */
	jobHours: string;
	totalJobHours: string;
	/** `#.##` */
	breakHours: string;
	totalBreakHours: string;
	/** `#.##` */
	shopHours: string;
	totalShopHours: string;
	/** `#.##` */
	travelHours: string;
	totalTravelHours: string;
	/** `#.##` */
	totalHours: string;
	totalHoursPerWO: string;

	/** list of all possible shift indicators, currently: none (`''`), `'N'` or `'NN'` */
	private static readonly SHIFTS = ['', 'N', 'NN'];
	private static readonly LAST_WEEK_SHIFT = 'LW';
	private static readonly NEXT_WEEK_SHIFT = 'FW';
	/** shift indicator for unexpected cases, should never happen */
	private static readonly SHIFT_OUT_OF_RANGE = 'ERR';

	private constructor(
		dbRow: PayrollEntryForCompany,
		weekReferenceDate: string,
		showWeekIndicators?: boolean,
		showTotalEmployee?: boolean,
		showTotalWO?: boolean
	) {
		this.localDateValue = dbRow.localDateValue;

		this.workOrderId = dbRow.workOrderId;
		this.workOrderDueDate = dbRow.workOrderDueDate;
		this.isWorkOrderCanceled = dbRow.workOrderStatus === WorkOrderStatus.CANCELED;
		this.crewName = dbRow.crewName;

		const localDateInWorkOrderIndex = calculateDateIndex(dbRow.firstLocalDateValue, dbRow.localDateValue, dbRow.timezone);
		const lastWeekIndicatorIndex = calculateWeekIndex(weekReferenceDate, dbRow.firstLocalDateValue, dbRow.timezone);
		const nextWeekIndicatorIndex = calculateWeekIndex(weekReferenceDate, dbRow.localDateValue, dbRow.timezone);

		const workOrderCodeShort = dbRow.workOrderCode < 10 ? `0${dbRow.workOrderCode}` : dbRow.workOrderCode.toString();
		const shiftIndicator = JobPayrollChildTableBetaRowVM.SHIFTS[localDateInWorkOrderIndex] ?? JobPayrollChildTableBetaRowVM.SHIFT_OUT_OF_RANGE;
		const nextWeekIndicator = (showWeekIndicators && nextWeekIndicatorIndex > 0) ? JobPayrollChildTableBetaRowVM.NEXT_WEEK_SHIFT : '';
		const lastWeekIndicator = (showWeekIndicators && lastWeekIndicatorIndex < 0) ? JobPayrollChildTableBetaRowVM.LAST_WEEK_SHIFT : '';
		this.workOrderShift = `${workOrderCodeShort}${shiftIndicator}${lastWeekIndicator}${nextWeekIndicator}`;

		const workOrderWeeklyCodeShort = dbRow.workOrderWeeklyCode < 10 ? `0${dbRow.workOrderWeeklyCode}` : dbRow.workOrderWeeklyCode?.toString();
		this.workOrderWeeklyCode = `${workOrderWeeklyCodeShort}${shiftIndicator}${lastWeekIndicator}${nextWeekIndicator}`;

		this.userId = dbRow.userId;
		this.userUniqueId = dbRow.userUniqueId;
		this.userFullName = dbRow.userFullName;
		this.accountId = dbRow.accountId;
		this.accountLocationAddressState = stateAbbreviation[dbRow.accountLocationAddressAa1] ?? null;

		this.timeSheetId = dbRow.timeSheetId;
		this.isTimeSheetSigned = !!dbRow.timeSheetSignatureId;
		this.isTimeSheetApproved = dbRow.timeSheetApprovalStatus === TimeSheetInternalApprovalStatus.APPROVED;
		this.timeSheetNote = dbRow.timeSheetNote;
		this.hasClassificationCode = !!dbRow.timeSplitFWCCId;
		this.fieldWorkClassificationCode = dbRow.timeSplitFWCCCode ?? dbRow.timeSplitsWithZeroTimeFWCCCodesText ?? null;
		if (!dbRow.timeSplitFWCCId && !!this.fieldWorkClassificationCode) {
			this.fieldWorkClassificationCode = `[MISSING] ${this.fieldWorkClassificationCode}`;
		}
		this.equipmentIdCodes = dbRow.timeSplitEquipmentIdCodesText ?? null;

		this.jobHours = formatDecimalNumber(dbRow.jobTime / 60);
		this.totalJobHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalJobTime / 60) : '';
		this.breakHours = formatDecimalNumber(dbRow.breakTime / 60);
		this.totalBreakHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalBreakTime / 60) : '';
		this.shopHours = formatDecimalNumber(dbRow.shopTime / 60);
		this.totalShopHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalShopTime / 60) : '';
		this.travelHours = formatDecimalNumber(dbRow.travelTime / 60);
		this.totalTravelHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalTravelTime / 60) : '';

		this.totalHours = formatDecimalNumber(dbRow.totalHours / 60);
		this.totalHoursPerWO = showTotalWO ? formatDecimalNumber(dbRow.totalHoursPerWO / 60) : '';
	}

	static bulkConstructor = (dbRowsForJob: PayrollEntryForCompany[], weekReferenceDate: string, showWeekIndicators?: boolean) => {
		/*
			Bear with me here. If there is no excess job time on a NULL CC payroll entry, meaning all the job time has
			been covered by the classification code splits we want to take the travel, shop and break times from that
			entry and merely display them on the first non-NULL CC entry. We do this in the presentational layer only,
			meaning this view model.
		*/
		const nullCCEntriesToOmit: Record<string, true> = {};

		// here we create daily groups of entries for a single user working on a single work order on a given day
		const payrollEntriesByDateKey = groupBy(dbRowsForJob, JobPayrollChildTableBetaRowVM.payrollEntryDateKey);

		for (const key of Object.keys(payrollEntriesByDateKey)) {
			// we get the daily entries
			const dateEntries = payrollEntriesByDateKey[key];
			if (dateEntries.length > 1) {
				// we find the first non NULL CC entry
				const firstNonNullCCEntry = dateEntries.find((_entry) => _entry.timeSplitFWCCId !== null);
				if (!firstNonNullCCEntry) {
					continue;
				}
				const nullCCEntries = dateEntries.filter((_entry) => _entry.timeSplitFWCCId === null);
				// we find all the NULL CC entries, there could ptentially be more than one because of old data
				if (nullCCEntries.some((_entry) => (
					_entry.jobTime !== 0
					|| !!_entry.timeSplitEquipmentIdCodesText
					|| !!_entry.timeSplitsWithZeroTimeFWCCCodesText)
				)) {
					// if we find at least one NULL CC entry which has unallocated job time we skip the whole reassignment procedure
					continue;
				}
				for (const nullCCEntry of nullCCEntries) {
					// at this point all the NULL CC entries have a job time of 0 and their non job times can be moved over
					// to the first non NULL CC entry
					firstNonNullCCEntry.shopTime += nullCCEntry.shopTime;
					firstNonNullCCEntry.travelTime += nullCCEntry.travelTime;
					firstNonNullCCEntry.breakTime += nullCCEntry.breakTime;
					firstNonNullCCEntry.totalHours += (nullCCEntry.shopTime + nullCCEntry.travelTime + nullCCEntry.breakTime);
					// we mark that we're not showing these NULL CC entries anymore
					nullCCEntriesToOmit[key] = true;
				}
			}
		}

		const filteredDbRowsForJob = dbRowsForJob.filter((dbRow) => (
			!!dbRow.timeSplitFWCCId
			|| !nullCCEntriesToOmit[JobPayrollChildTableBetaRowVM.payrollEntryDateKey(dbRow)]
		));

		const childTableRows: JobPayrollChildTableBetaRowVM[] = [];
		for (let i = 0; i < filteredDbRowsForJob.length; i++) {
			const dbRow = filteredDbRowsForJob[i];
			childTableRows.push(new JobPayrollChildTableBetaRowVM(
				dbRow,
				weekReferenceDate,
				showWeekIndicators,
				dbRow.userId !== filteredDbRowsForJob[i + 1]?.userId || dbRow.workOrderId !== filteredDbRowsForJob[i + 1]?.workOrderId,
				dbRow.workOrderId !== filteredDbRowsForJob[i + 1]?.workOrderId
			));
		}
		return childTableRows;
	};

	private static payrollEntryDateKey = (entry: PayrollEntryForCompany) => {
		return JSON.stringify([
			entry.workOrderId,
			entry.accountId,
			entry.localDateValue,
		]);
	};
}

export class JobPayrollTableBetaRowVM {
	jobId: number;
	calculatedJobCode: string;
	jobTitle: string;

	divisionName: string;

	allCrewIsInternal: boolean;

	childTable: TableViewModel<JobPayrollChildTableBetaRowVM>;

	private constructor(dbRowsForJob: PayrollEntryForCompany[], weekReferenceDate: string, showWeekIndicators?: boolean) {
		const firstRow = dbRowsForJob[0];

		this.jobId = firstRow.jobId;
		this.calculatedJobCode = firstRow.calculatedJobCode;
		this.jobTitle = firstRow.jobTitle;
		this.divisionName = firstRow.divisionName ?? '';
		this.allCrewIsInternal = firstRow.crewIsInternal;

		const childTableRows = JobPayrollChildTableBetaRowVM.bulkConstructor(dbRowsForJob, weekReferenceDate, showWeekIndicators);
		this.childTable = new TableViewModel(childTableRows, 1, childTableRows.length);
	}

	/** NOTE: `dbRows` is assumed to be ordered by (job, workOrder, user, date), as described in {@link PayrollEntryForCompany} */
	static bulkConstructor(dbRows: PayrollEntryForCompany[], startDate: string, endDate: string): JobPayrollTableBetaRowVM[] {
		if (!dbRows?.length) {
			return [];
		}
		const showWeekIndicators = (
			TimeUtils.dayOfWeek(startDate, TimeFormat.DB_DATE_ONLY) === 0
			&& TimeUtils.getDiff(endDate, startDate, 'days', TimeFormat.DB_DATE_ONLY) === 6
		);
		const result: JobPayrollTableBetaRowVM[] = [];

		const firstRow = dbRows[0];
		let currentJobId: number = firstRow.jobId;
		let currentUserId: number = firstRow.userId;
		let currentWorkOrderId: number = firstRow.workOrderId;
		/** All rows for one (workOrder, user) grouping, i.e. all originating data belongs to the same TimeSheet */
		let dbRowsForCurrentWorkOrderUser: PayrollEntryForCompany[] = [firstRow];
		/** All rows for one job, but with each (workOrder, user) grouping having normalized time values */
		let dbRowsForCurrentJobNormalized: PayrollEntryForCompany[] = [];

		for (const _dbRow of dbRows) {
			if (_dbRow === firstRow) {
				// Already assigned all values for first row
				continue;
			}
			if (currentUserId !== _dbRow.userId || currentWorkOrderId !== _dbRow.workOrderId) {
				dbRowsForCurrentJobNormalized.push(...JobPayrollTableBetaRowVM._normalizeTimeValues(dbRowsForCurrentWorkOrderUser));

				// Reset (workOrder, user) grouping:
				currentUserId = _dbRow.userId;
				currentWorkOrderId = _dbRow.workOrderId;
				dbRowsForCurrentWorkOrderUser = [];
			}
			if (currentJobId !== _dbRow.jobId) {
				const dbRowsForCurrentJobNormalizedInRange = dbRowsForCurrentJobNormalized.filter(JobPayrollTableBetaRowVM._isNotOutOfDateRange);
				if (!!dbRowsForCurrentJobNormalizedInRange.length) {
					result.push(new JobPayrollTableBetaRowVM(dbRowsForCurrentJobNormalizedInRange, startDate, showWeekIndicators));
				}

				// Reset job:
				currentJobId = _dbRow.jobId;
				dbRowsForCurrentJobNormalized = [];
			}

			dbRowsForCurrentWorkOrderUser.push(_dbRow);
		}
		// Loop logic for final grouping:
		dbRowsForCurrentJobNormalized.push(...JobPayrollTableBetaRowVM._normalizeTimeValues(dbRowsForCurrentWorkOrderUser));
		const dbRowsForCurrentJobNormalizedInRange = dbRowsForCurrentJobNormalized.filter(JobPayrollTableBetaRowVM._isNotOutOfDateRange);
		if (!!dbRowsForCurrentJobNormalizedInRange.length) {
			result.push(new JobPayrollTableBetaRowVM(dbRowsForCurrentJobNormalizedInRange, startDate, showWeekIndicators));
		}

		return result;
	}

	/**
	 * Returns `true` if row is in queried Payroll Report date range and should be included in the final result.
	 * Includes rows in date range or if it overflows into the following week (FW suffix)
	 *
	 * **DO NOT** exclude rows before calculations between rows (such as {@link _normalizeTimeValues})
	 * to ensure same results for same data between differing date ranges.
	 */
	private static _isNotOutOfDateRange = (entry: PayrollEntryForCompany) => entry.shouldShow;

	/**
	 * Adjust time values in a group so that all are non-negative while maintaining the same sum in a time category.
	 *
	 * The values are adjusted in reverse order - last rows adjusted first,
	 * and so on until the total sum is adjusted and no negative values left.
	 * This is because the rows are assumed do be ordered by date (ASC order),
	 * and `get_job_payroll_table` is implemented to do offsets at the final row of that grouping.
	 *
	 * @param dbRows row group that is assumed to belong to the same (workOrder, user), i.e. the same Time Sheet
	 * @returns row group with normalized time values in the same order as `dbRows`
	 */
	private static _normalizeTimeValues(dbRows: PayrollEntryForCompany[]): PayrollEntryForCompany[] {
		if (!dbRows?.length) {
			return [];
		}
		const { userId, workOrderId } = dbRows[0];
		/** value `<= 0` */
		let jobTimeOffset = 0;
		/** value `<= 0` */
		let breakTimeOffset = 0;
		/** value `<= 0` */
		let shopTimeOffset = 0;
		/** value `<= 0` */
		let travelTimeOffset = 0;

		for (const _row of dbRows) {
			if (_row.userId !== userId || _row.workOrderId !== workOrderId) {
				throw new Error('Cannot normalize values of a different Time Sheet');
			}
			jobTimeOffset += Math.min(_row.jobTime, 0);
			breakTimeOffset += Math.min(_row.breakTime, 0);
			shopTimeOffset += Math.min(_row.shopTime, 0);
			travelTimeOffset += Math.min(_row.travelTime, 0);
		}
		if ((jobTimeOffset + breakTimeOffset + shopTimeOffset + travelTimeOffset) === 0) {
			// Nothing to offset
			return dbRows;
		}

		const reverseResult: PayrollEntryForCompany[] = [];

		for (const _row of [...dbRows].reverse()) {
			let { jobTime, breakTime, shopTime, travelTime } = _row;

			[jobTime, jobTimeOffset] = JobPayrollTableBetaRowVM._normalizeTimeValue(jobTime, jobTimeOffset);
			[breakTime, breakTimeOffset] = JobPayrollTableBetaRowVM._normalizeTimeValue(breakTime, breakTimeOffset);
			[shopTime, shopTimeOffset] = JobPayrollTableBetaRowVM._normalizeTimeValue(shopTime, shopTimeOffset);
			[travelTime, travelTimeOffset] = JobPayrollTableBetaRowVM._normalizeTimeValue(travelTime, travelTimeOffset);

			reverseResult.push({ ..._row, jobTime, breakTime, shopTime, travelTime });
		}
		if ((jobTimeOffset + breakTimeOffset + shopTimeOffset + travelTimeOffset) !== 0) {
			throw new Error('Unable to fully offset row group, please check your data set');
		}
		return reverseResult.reverse();
	}

	/**
	 * @param currentValue single time value
	 * @param currentOffset total offset for related time value, always `<= 0`
	 * @returns updated value (always `>= 0`) and offset (always `<= 0`)
	 */
	private static _normalizeTimeValue(currentValue: number, currentOffset: number): [newValue: number, newOffset: number] {
		if (currentOffset === 0) {
			return [currentValue, 0];
		}
		if (currentValue <= 0) {
			return [0, currentOffset];
		}
		const newValue = Math.max(currentValue + currentOffset, 0);
		const newOffset = Math.min(currentOffset + (currentValue - newValue), 0);

		return [newValue, newOffset];
	}

	private static _toCSVCell(
		metadata: CSVMetadata,
		parentRow: JobPayrollTableBetaRowVM,
		childRow: JobPayrollChildTableBetaRowVM,
		complexValueGetters: JobPayrollTableCSVComplexValueGetterLookup
	): string {
		if (metadata.isComplexValue) {
			return complexValueGetters[metadata.key](parentRow, childRow);
		}
		const simpleValueMetadata = metadata as SimpleValueCSVMetadata;

		const input = simpleValueMetadata.isInChildTable ? childRow[simpleValueMetadata.key] : parentRow[simpleValueMetadata.key as ParentRowCSVKeys];
		const formatter: Nullable<(value: typeof input) => string> = 'formatter' in simpleValueMetadata ? simpleValueMetadata.formatter ?? null : null; // required to infer typing

		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
		return (formatter ? formatter(input) : `${input || ''}`) || simpleValueMetadata.fallback || '';
	}

	static toCSVData(
		jobRows: JobPayrollTableBetaRowVM[],
		complexValueGetters: JobPayrollTableCSVComplexValueGetterLookup,
		showTotalColumns = false
	): string[][] {
		const rows: string[][] = [showTotalColumns ? CSV_HEADER_ROW : CSV_HEADER_ROW.filter((value) => !BETA_COLUMN_LABELS.includes(value))];

		for (const _parentRow of jobRows) {
			for (const _childRow of _parentRow.childTable.rows ?? []) {
				const _currentRow: string[] = [];

				for (const _metadata of CSV_METADATA_LIST) {
					if (!showTotalColumns && BETA_COLUMN_LABELS.includes(_metadata.label)) {
						continue;
					}
					_currentRow.push(JobPayrollTableBetaRowVM._toCSVCell(_metadata, _parentRow, _childRow, complexValueGetters));
				}
				rows.push(_currentRow);
			}
		}
		return rows;
	}
}
