import * as React from 'react';

import styles from './styles.module.scss';

interface Props {
	value: number;
	className?: string;
}

const PercentageCell: React.FC<Props> = ({ value, className }) => {
	const classNames: string[] = [styles['percentage-cell']];
	if (className) {
		classNames.push(className);
	}
	return (
		<div className={classNames.join(' ')}>
			{value}%
		</div>
	);
};

export default React.memo(PercentageCell);
