import * as React from 'react';

import { previewSectionClass, previewSectionClassColumn2, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';
import LockedValue from 'af-components/LockedValue';
import { dollarFormatter } from 'af-utils/format.util';

type Props = {
	salesId: Nullable<string>;
	salesLeadName: Nullable<string>;
	estimateTotal: Nullable<number>;
};

const SalesSection: React.FC<Props> = ({ estimateTotal, salesId, salesLeadName }) => {

	const estimateTotalFormatted = estimateTotal !== null ? dollarFormatter.format(estimateTotal) : null;
	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Sales
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Sales ID" value={salesId} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Sales Lead" value={salesLeadName} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Estimate total" value={estimateTotalFormatted} />
				</div>
			</div>
		</div>
	);
};

export default React.memo(SalesSection);
