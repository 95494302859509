import * as FieldReportRM from 'ab-requestModels/fieldReport/fieldReport.requestModel';

import * as FieldReportVM from 'ab-viewModels/fieldReport/fieldReport.viewModel';

import { getFormName } from './helpers';

const _reduceToRequestModel = (map: FieldReportRM.default, item: FieldReportVM.FieldReportBlockFieldVM): FieldReportRM.default => {
	map[getFormName(item.id)] = item.value;
	return map;
};

export const fromVMtoRM = (fieldReport: FieldReportVM.default): FieldReportRM.default => {
	return Object.values(fieldReport.fieldReportBlockFieldMap).reduce(_reduceToRequestModel, {});
};
