import * as React from 'react';

import { DisplayViewEquipmentViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import UnavailableEquipmentItem from 'af-root/scenes/Company/ScheduleBoard/DisplayView/ResourceItems/EquipmentItems/UnavailableEquipmentItem';

interface OwnProps {
	equipmentGroup: DisplayViewEquipmentViewModel[];
}

type Props = OwnProps;

const UnavailableEquipmentColumn: React.FC<Props> = (props: Props) => {
	const { equipmentGroup } = props;

	return (
		<div className="display-view-unavailable-column-wrapper">
			<div className="display-view-column">
				{
					equipmentGroup.map((_equipment, _index) => (
						<UnavailableEquipmentItem equipment={_equipment} key={_index} />
					))
				}
			</div>
		</div>
	);
};

export default React.memo(UnavailableEquipmentColumn);
