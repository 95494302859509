import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';

import { RootState } from 'af-reducers/index';

import * as MessageActions from 'af-actions/message';

import MessageVM from 'ab-viewModels/message/message.viewModel';

import Breadcrumbs from 'af-components/Breadcrumbs';

import TableNameEnum from 'ab-enums/tableName.enum';

import Loading from './Loading';
import Message from './Message';
import MessageStatuses from './MessageStatuses';

interface PathParams {
	messageId: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const MessagePreview: React.FC<Props> = (props: Props) => {
	const {
		match: { params: { messageId } },
		location: { state: { orgAlias } },
		findMessageById,
		findMessageStatusHistory,
		companyName,
	} = props;

	const [message, setMessage] = React.useState<Nullable<MessageVM>>(null);

	React.useEffect(() => {
		findMessage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const findMessage = React.useCallback(async () => {
		const _msg = await findMessageById(+messageId);

		if (!_msg) {
			throw new Error(`Message with id ${messageId} not found`);
		}
		setMessage(_msg);
	}, [findMessageById, messageId]);

	if (!companyName) {
		return null;
	}

	return (
		<>
			{message ?
				<Message
					companyName={companyName}
					message={message}
					orgAlias={orgAlias}
				/>
				: <Loading />
			}
			<div className="form-segment">
				<Breadcrumbs items={[{ label: 'Message statuses' }]} />
				<MessageStatuses
					companyName={companyName}
					findMessageStatusHistory={findMessageStatusHistory}
					messageId={+messageId}
					tableName={TableNameEnum.COMMUNICATION_HISTORY_STATUSES}
				/>
			</div>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findMessageById: MessageActions.findMessageById,
		findMessageStatusHistory: MessageActions.findMessagesStatusHistoryForTable,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	React.memo
);

export default enhance(MessagePreview);
