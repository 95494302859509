import JobWorkSummary from 'acceligent-shared/models/jobWorkSummary';

import { workOrderCode } from 'acceligent-shared/utils/codes';

import { MAX_BILLABLE_WORK_DEFINITION_FIELDS } from 'ab-constants/value';

const resolveCompanyWorkSummaryTableHeadersForCSVExport = (maxNumberOfDefFields: number, detailed: boolean) => {
	const defFields: string[] = [];
	for (let i = 0; i < maxNumberOfDefFields; i++) {
		defFields.push(`Definition ${i + 1}`);
	}

	const basicHeaders = [
		'Work Request',
		'WorkOrder',
		'Date',
		'Quantity',
		'Description',
		'Billing Code',
		'Unit',
		'Unit Price ($)',
		'Revenue ($)',
	];

	const detailedHeaders = [
		'Work',
		'Type',
		...defFields,
	];

	return detailed ? [...basicHeaders, ...detailedHeaders] : basicHeaders;
};

const resolveMaxNumberOfDefFieldsForTable = (_jwss: CompanyWorkSummaryVM[]) => {

	const max = _jwss.reduce((_acc, _jws) => {
		let currentJwsMax = 0;
		for (let i = 0; i < MAX_BILLABLE_WORK_DEFINITION_FIELDS; i++) {
			if (!_jws?.[`definition${i + 1}`]) {
				break;
			}
			currentJwsMax++;
		}
		return (currentJwsMax > _acc) ? currentJwsMax : _acc;
	}, 0);
	return max;
};

export class CompanyWorkSummaryVM {
	workRequest: string;
	workOrder: Nullable<string>;
	workOrderId: Nullable<number>;
	/** YYYY-MM-DD */
	startDate: string;
	quantity: number;
	description: Nullable<string>;
	billingCode: Nullable<string>;
	unit: Nullable<string>;
	unitPrice: Nullable<number>;
	revenue: number;
	work: Nullable<string>;
	type: Nullable<string>;
	typeValue: Nullable<string>;
	definition1: Nullable<string>;
	definition2: Nullable<string>;
	definition3: Nullable<string>;
	definition4: Nullable<string>;

	constructor(row: JobWorkSummary) {
		this.workRequest = row.workRequest!.jobCode as string;
		this.workOrder = row.workOrder ? workOrderCode(row.workOrder, row.workOrder.workRequest) : null;
		this.workOrderId = row.workOrderId;
		this.startDate = row.startDate;
		this.quantity = row.quantity ?? 0;
		this.description = row.description;
		this.billingCode = row.customerId;
		this.unit = row.unit;
		this.unitPrice = row.unitPrice;
		this.revenue = row.revenue ?? 0;
		this.work = row.work;
		this.type = row.type !== row.typeValue && !!row.typeValue ? `${row.type}: ${row.typeValue}` : row.type;
		this.definition1 = row.definitionField1Name ? `${row.definitionField1Name}: ${row.definitionField1Value ?? 'N/A'}` : null;
		this.definition2 = row.definitionField2Name ? `${row.definitionField2Name}: ${row.definitionField2Value ?? 'N/A'}` : null;
		this.definition3 = row.definitionField3Name ? `${row.definitionField3Name}: ${row.definitionField3Value ?? 'N/A'}` : null;
		this.definition4 = row.definitionField4Name ? `${row.definitionField4Name}: ${row.definitionField4Value ?? 'N/A'}` : null;
	}

	static bulkConstructor(rows: JobWorkSummary[]) {
		return rows.map((_row) => new CompanyWorkSummaryVM(_row));
	}

	static toCSVData(data: CompanyWorkSummaryVM[], detailed: boolean): string[][] {
		const maxNumberOfDefFields = resolveMaxNumberOfDefFieldsForTable(data);
		const headers = resolveCompanyWorkSummaryTableHeadersForCSVExport(maxNumberOfDefFields, detailed);

		const rows: string[][] = data.map((_jws) => {
			const definitionFieldsValues: string[] = [];
			for (let i = 0; i < maxNumberOfDefFields; i++) {
				const defField = _jws?.[`definition${i + 1}`] ?? '';
				definitionFieldsValues.push(defField);
			}

			const basicInfo = [
				_jws.workRequest,
				_jws.workOrder ?? '',
				_jws.startDate,
				`${_jws.quantity}`,
				_jws.description ?? '',
				_jws.billingCode ?? '',
				_jws.unit ?? '',
				_jws.unitPrice ? _jws.unitPrice.toFixed(2) : '',
				_jws.revenue.toFixed(2),
			];

			const detailedInfo = [
				_jws.work ?? '',
				_jws.type ?? '',
				...definitionFieldsValues,
			];

			return detailed ? [...basicInfo, ...detailedInfo] : basicInfo;
		});

		return [headers, ...rows];
	}
}
