import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';

import SaleRequestModel from 'ab-requestModels/sale.requestModel';

import { SALE_EDIT } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import { RootState } from 'af-reducers';

import * as SaleActions from 'af-actions/sale';

import EditSaleForm from 'af-components/SharedForms/Resources/Sale';
import { validate, warn } from 'af-components/SharedForms/Resources/Sale/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

import { toRequestModelWithRawPhoneNumbers } from 'ab-utils/form.util';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type FormProps = InjectedFormProps<SaleRequestModel, FormOwnProps>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormOwnProps & FormProps;

class EditSale extends React.PureComponent<Props> {

	async componentDidMount() {
		const { findById, match: { params: { id } }, initialize } = this.props;
		const sale = await findById(id);
		initialize(sale);
	}

	editSale = async (form: SaleRequestModel) => {
		const { updateSale, location: { state: { orgAlias } }, companyName, history } = this.props;
		const data: SaleRequestModel = toRequestModelWithRawPhoneNumbers(form, 'phone', 'fax');
		await updateSale(data);
		history.push(CLIENT.COMPANY.RESOURCES.SALE.LIST(orgAlias, companyName));
	};

	render() {
		const {
			match: { params: { id } },
			location: { state: { orgAlias } },
			companyName,
		} = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={
						[
							{ label: 'Sales', url: CLIENT.COMPANY.RESOURCES.SALE.LIST(orgAlias, companyName) },
							{ label: 'Preview', url: CLIENT.COMPANY.RESOURCES.SALE.PREVIEW(id, orgAlias, companyName) },
							{ label: 'Edit' },
						]
					}
				/>
				<EditSaleForm {...this.props} onSubmit={this.editSale} />
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findById: SaleActions.findById,
		updateSale: SaleActions.update,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<SaleRequestModel, FormOwnProps>({ form: SALE_EDIT, validate, warn })
);
export default enhance(EditSale);
