import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { ConnectedProps, connect } from 'react-redux';

import LockedValue from 'af-components/LockedValue';

import CLIENT from 'af-routes/client';

import { RootState } from 'af-reducers';

import { previewSectionClass, previewSectionClassColumn2, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';
import styles from '../../styles.module.scss';

type OwnProps = {
	projectOwner: Nullable<string>;
	ownerReference: Nullable<string>;
	taxExempt: boolean;
	generalContractor: Nullable<string>;
	contractorReference: Nullable<string>;
	isUnion: boolean;
	isPrevailingWage: boolean;
	jurisdiction: Nullable<string>;
	createdFromWorkRequestJobCode: Nullable<string>;
	createdFromWorkRequestId: Nullable<number>;
	orgAlias: string;
};

type Props = CustomRouteComponentProps & OwnProps & ConnectedProps<typeof connector>;

const JobDetailSection: React.FC<Props> = (props) => {
	const {
		contractorReference,
		generalContractor,
		jurisdiction,
		ownerReference,
		isPrevailingWage,
		projectOwner,
		taxExempt,
		isUnion,
		createdFromWorkRequestJobCode,
		createdFromWorkRequestId,
		orgAlias,
		companyName,
	} = props;

	const showAssociatedWorkRequest = !!createdFromWorkRequestJobCode && !!createdFromWorkRequestId;

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Job Detail
			</div>
			{showAssociatedWorkRequest && (
				<div className={styles['work-request-preview__associated-work-request']}>
					<a href={CLIENT.COMPANY.WORK_REQUESTS.PREVIEW(orgAlias, companyName, `${createdFromWorkRequestId}`)} rel="noreferrer" target="_blank">
						<span className="icon-external" />
						{`View Work Request (${createdFromWorkRequestJobCode})`}
					</a>
				</div>
			)}
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Project Owner" value={projectOwner} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Owner Reference" value={ownerReference} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Tax Exempt" value={taxExempt ? 'YES' : 'NO'} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="General Contractor" value={generalContractor} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Contractor Reference" value={contractorReference} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Union" value={isUnion ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Prevailing Wage" value={isPrevailingWage ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Jurisdiction" value={jurisdiction} />
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

const connector = connect(mapStateToProps, null);

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(JobDetailSection);
