import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { WrappedFieldArrayProps, change } from 'redux-form';
import Scrollbars from 'react-custom-scrollbars';

import { RootState } from 'af-reducers';

import WorkOrderMetricIndexEnum from 'ab-enums/workOrderMetricIndex.enum';

import { WorkOrderMetricsItem } from 'ab-requestModels/scheduleBoardMetrics.requestModel';

import CustomScrollbar from 'af-components/CustomScrollbar';

import MetricsHorizontalScrollbar from 'af-root/scenes/Company/ScheduleBoard/DailyMetrics/MetricsHorizontalScrollbar';
import MetricsCardHeader from 'af-root/scenes/Company/ScheduleBoard/DailyMetrics/MetricsCardHeader';
import Loading from 'af-root/scenes/Company/ScheduleBoard/DailyMetrics/Loading';
import BlankCard from 'af-root/scenes/Company/ScheduleBoard/Shared/Card/BlankCard';
import Card from 'af-root/scenes/Company/ScheduleBoard/Shared/Card';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { UPDATE_DAILY_VIEW_METRICS } from 'af-constants/reduxForms';
import { SCROLL_EVENT_LOCK_TIMEOUT } from 'af-constants/values';
import { SCHEDULE_BOARD_MAX_ITEMS_IN_ROW } from 'ab-constants/scheduleBoard';

import ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

interface StateProps {
	/** SB `date` prop, `MM-DD-YYYY`, null only while `isLoading` is true */
	dueDate: Nullable<string>;
	isLoading: boolean;
	maxResourceItems: number;
}

export interface OwnProps {
	areDailyMetricsLocked: boolean;
}

type ConnectOwnProps = OwnProps & WrappedFieldArrayProps<WorkOrderMetricsItem>;

interface DispatchProps {
	setMetricsHorizontalScrollingPercentage: typeof ScheduleBoardActions.setMetricsHorizontalScrollingPercentage;
	changeField: typeof change;
}

type Props = ConnectOwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	cardsContainerWidth: number;
	headerWidth: number;
}

class WorkOrdersMetrics extends React.Component<Props, State> {
	state: State = {
		cardsContainerWidth: 0,
		headerWidth: 0,
	};

	_scrollbar: Nullable<React.RefObject<Scrollbars>> = null;
	_headerRef: Nullable<React.RefObject<HTMLDivElement>> = null;
	_onScrollEventLocked: boolean = false;
	_onScrollEventLockTimer: Nullable<NodeJS.Timer> = null;

	componentDidMount() {
		document.addEventListener('paste', this.onPaste);
	}

	componentWillUnmount() {
		document.removeEventListener('paste', this.onPaste);
	}

	onScrollbarUpdate = () => {
		if (this._scrollbar?.current) {
			const { setMetricsHorizontalScrollingPercentage } = this.props;

			const cardsContainerWidth = this._scrollbar.current.getScrollWidth() || 0;
			const scrollBarLeftOffset = this._scrollbar.current.getScrollLeft() || 0;
			const clientWidth = this._scrollbar.current.getClientWidth() || 0;

			if (cardsContainerWidth !== this.state.cardsContainerWidth) {
				this.setState(() => ({ cardsContainerWidth }));

				const newScrollingPercentage = scrollBarLeftOffset / (cardsContainerWidth - clientWidth);
				setMetricsHorizontalScrollingPercentage(newScrollingPercentage);
			}

		}

		if (this._headerRef?.current) {
			const headerWidth = this._headerRef.current.clientWidth || 0;
			if (this.state.headerWidth !== headerWidth) {
				this.setState(() => ({ headerWidth }));
			}
		}
	};

	onPaste = (event) => {
		if (event.target.className.includes('form-control')) {
			event.preventDefault();
			event.stopPropagation();
			const { fields, changeField } = this.props;

			const data: string = event.clipboardData.getData('text');

			const reduxFieldName: string = event.target.name;
			// find card index on which paste event has triggered
			const _startIndex = reduxFieldName.indexOf('[') + 1;
			const _endIndex = reduxFieldName.indexOf(']');
			const cardStartIndex: number = parseInt(reduxFieldName.slice(_startIndex, _endIndex), 10);

			const reduxFieldId: string = event.target.id;
			const fieldIndex: number = WorkOrderMetricIndexEnum[reduxFieldId];
			const rows = data.trim().replace(/[\r]/g, '').split('\n');

			const fieldsValuesCount = Object.keys(WorkOrderMetricIndexEnum).length;
			const workOrderValues: WorkOrderMetricsItem[] = fields.map((_, _index) => fields.get(_index));
			const blanksAndInternalsBeforeStartWO = workOrderValues.filter((_wo, _index) => _index < cardStartIndex && (!_wo || _wo.isInternal)).length;
			// count only ones which have editable metrics
			const workOrdersCount = workOrderValues.filter((_wo) => !!_wo && !_wo.isInternal).length;
			const workOrdersLeftAfterStartWO = workOrdersCount - cardStartIndex + blanksAndInternalsBeforeStartWO;
			// get cells for each row
			rows.slice(0, Math.min(fieldsValuesCount, fieldsValuesCount - fieldIndex)).forEach((_row: string, _index: number) => {
				const cells = _row.trim().split('\t');
				const newFieldNameIndex: number = (fieldIndex + _index) % fieldsValuesCount;
				const fieldName: string = WorkOrderMetricIndexEnum[newFieldNameIndex];
				let blanksSkipped = 0;
				// assign each cell to corresponding input in form
				cells.slice(0, Math.min(workOrdersCount, workOrdersLeftAfterStartWO)).forEach((_cell: string, _cellIndex: number) => {
					const _cellValue = _cell.trim();
					let cardIndex: number = cardStartIndex + blanksSkipped + _cellIndex;

					// skip blank and internal work orders
					while ((!fields.get(cardIndex) || fields.get(cardIndex)?.isInternal) && (cardIndex < fields.length)) {
						blanksSkipped++;
						cardIndex++;
					}
					// assign cell value to form field
					const workOrderFieldName = `workOrders[${cardIndex}].${fieldName}`;
					if (fieldName === 'workDescription') {
						changeField(UPDATE_DAILY_VIEW_METRICS, workOrderFieldName, _cellValue);
					} else {
						try {
							const floatValue = parseFloat(_cellValue.replace(/[$,]/g, ''));
							const newValue = isNaN(floatValue) ? null : floatValue;
							changeField(UPDATE_DAILY_VIEW_METRICS, workOrderFieldName, newValue);
						} catch (e) {
							// ignore all invalid values
							changeField(UPDATE_DAILY_VIEW_METRICS, workOrderFieldName, null);
						}
					}
				});
			});
		}
	};

	onScrollbarMount = (_scrollbar: React.RefObject<Scrollbars>) => {
		this._scrollbar = _scrollbar;

		if (_scrollbar?.current) {
			this.setState(() => ({ cardsContainerWidth: _scrollbar.current?.getScrollWidth() ?? 0 }));
		}
	};

	onHeaderMount = (_headerRef: Nullable<React.RefObject<HTMLDivElement>>) => {
		this._headerRef = _headerRef;

		if (_headerRef?.current) {
			this.setState(() => ({ headerWidth: _headerRef.current?.clientWidth ?? 0 }));
		}
	};

	onHorizontalScroll = (scrollPercentage: number, width: number, force: boolean = false) => {
		if (!force && this._onScrollEventLocked) {
			return;
		}
		if (force && !!this._onScrollEventLockTimer) {
			clearTimeout(this._onScrollEventLockTimer);
		}
		this._onScrollEventLocked = true;
		this._onScrollEventLockTimer = setTimeout(() => {
			this._onScrollEventLocked = false;
		}, SCROLL_EVENT_LOCK_TIMEOUT);

		const { setMetricsHorizontalScrollingPercentage } = this.props;

		this._scrollbar?.current?.scrollLeft(scrollPercentage * width);

		setMetricsHorizontalScrollingPercentage(scrollPercentage);
	};

	renderFields = () => {
		const { maxResourceItems, fields, dueDate, areDailyMetricsLocked } = this.props;
		if (!dueDate) {
			return [];
		}

		return (fields.getAll() ?? []).map((workOrderCalculation: WorkOrderMetricsItem | null, _index: number) => (
			!workOrderCalculation?.workOrderCode
				? (
					<BlankCard
						hasPermissionsToEditScheduleBoard={false}
						index={_index}
						isDragAndDropDisabled={true}
						key={`blank_${_index}`}
					/>
				) : (
					<Card
						areDailyMetricsLocked={areDailyMetricsLocked}
						dueDate={dueDate}
						hasPermissionsToEditScheduleBoard={false}
						ignorePlaceholders={true}
						index={_index}
						isCalculationsView={true}
						isDragAndDropDisabled={true}
						key={`${workOrderCalculation?.workOrderCode}#${dueDate}`}
						maxResourceItems={maxResourceItems}
						showMetrics={true}
						workOrderCode={workOrderCalculation?.workOrderCode}
					/>
				)
		));
	};

	render() {
		const { isLoading, maxResourceItems } = this.props;
		const { cardsContainerWidth, headerWidth } = this.state;
		const maxWidth = cardsContainerWidth - headerWidth;

		return (
			<>
				<MetricsHorizontalScrollbar
					maxWidth={maxWidth}
					onHorizontalScroll={this.onHorizontalScroll}
					position="top"
				/>
				<CustomScrollbar
					contentWrapperClassName="cards-container"
					onMount={this.onScrollbarMount}
					onScroll={this.onHorizontalScroll}
					onScrollUpdate={this.onScrollbarUpdate}
				>
					<MetricsCardHeader
						maxResourceItems={maxResourceItems}
						onMount={this.onHeaderMount}
					/>
					{!isLoading ? this.renderFields() : <Loading />}
				</CustomScrollbar>
				<MetricsHorizontalScrollbar
					maxWidth={maxWidth}
					onHorizontalScroll={this.onHorizontalScroll}
					position="bottom"
				/>
			</>
		);
	}
}

const _getResourceItemCount = (wo: ScheduleBoardWorkOrderViewModel) => wo?.workOrderResourceLookups?.length ?? 0;

function mapStateToProps(state: RootState): StateProps {
	const { date: dueDate, workOrdersByDateDictionary, resourcesLoaded } = state.scheduleBoard;

	const isLoading = !dueDate || !workOrdersByDateDictionary?.[dueDate]?.workOrders || !resourcesLoaded;

	if (isLoading) {
		return {
			isLoading: true,
			dueDate: dueDate ?? null,
			maxResourceItems: 0,
		};
	}

	const resourceItemCounts: number[] = Object.values(workOrdersByDateDictionary?.[dueDate]?.workOrders ?? {}).map(_getResourceItemCount);
	const maxResourceItems = Math.min(SCHEDULE_BOARD_MAX_ITEMS_IN_ROW, Math.max(...resourceItemCounts));

	return {
		isLoading: false,
		dueDate,
		maxResourceItems,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		setMetricsHorizontalScrollingPercentage: ScheduleBoardActions.setMetricsHorizontalScrollingPercentage,
		changeField: change,
	};
}

export default connect<StateProps, DispatchProps, ConnectOwnProps>(mapStateToProps, mapDispatchToProps())(WorkOrdersMetrics);
