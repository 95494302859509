import * as React from 'react';
import { Link } from 'react-router-dom';

export interface BreadcrumbItem {
	label: string;
	url?: string;
	onClick?: () => void;
}

interface OwnProps {
	items: BreadcrumbItem[];
}

type Props = OwnProps;

class Breadcrumbs extends React.Component<Props> {

	renderItem = (item: BreadcrumbItem, index: number, isCurrentPage: boolean) => {
		let className = 'breadcrumbs__item';
		className = isCurrentPage ? `${className} breadcrumbs__item--current` : className;
		className = item.url || item.onClick ? `${className} breadcrumbs__item--link` : className;

		const key = `breadcrumb#${index}`;

		if (item.url) {
			return (
				<Link
					className={className}
					key={key}
					to={item.url}
				>
					{item.label}
				</Link>
			);
		}
		return (
			<a
				className={className}
				key={key}
				onClick={item.onClick}
				type="button"
			>
				{item.label}
			</a>
		);
	};

	renderItems = () => {
		const { items } = this.props;

		return items.reduce<JSX.Element[]>((_acc, _item, _index) => {
			const isCurrentPage = _index + 1 === items.length;
			const item = this.renderItem(_item, _index, isCurrentPage);
			if (_acc.length) {
				_acc.push(<span className="breadcrumbs__separator" key={`separator#${_index}`}> | </span>);
			}
			_acc.push(item);
			return _acc;
		}, []);
	};

	render() {
		return (
			<div className="breadcrumbs">
				{this.renderItems()}
			</div>
		);
	}
}

export default Breadcrumbs;
