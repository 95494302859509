import * as React from 'react';

import { previewSectionClass, previewSectionClassColumn6, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';

type Props = {
	jobHazardSummary: Nullable<string>;
};

const JobHazardSummarySection: React.FC<Props> = ({ jobHazardSummary }) => {
	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Job Hazard Summary
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn6}>
					<b>{jobHazardSummary ?? '-'}</b>
				</div>
			</div>

		</div>
	);
};

export default React.memo(JobHazardSummarySection);
