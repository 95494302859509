import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { compose } from 'redux';

import CountryCode from 'acceligent-shared/enums/countryCode';

import * as UserRequestModel from 'ab-requestModels/users.requestModel';

import { RootState } from 'af-reducers';

import * as AuthenticationActions from 'af-actions/authentication';

import Input from 'af-fields/Input';

import SubmitButton from 'af-components/SubmitButton';

import { USER_LOGIN_CODE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import { formatPhoneNumber } from 'ab-utils/phone.util';

import validate from './validations';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<UserRequestModel.UserPhoneCode>;

class CodeLogin extends React.PureComponent<Props> {

	componentDidMount() {
		const { pendingPhoneNumber, history, location: { state: { orgAlias } } } = this.props;

		if (!pendingPhoneNumber) {
			history.push(CLIENT.AUTH.LOGIN(orgAlias));
		}
	}

	submitCode = async (phoneCode: UserRequestModel.UserPhoneCode) => {
		const { pendingPhoneNumber, phoneLogin, organizationPublicDetails } = this.props;

		if (!pendingPhoneNumber || !organizationPublicDetails) {
			throw new Error('Not enough information for login');
		}

		const countryCode = CountryCode.US;
		await phoneLogin(pendingPhoneNumber, countryCode, phoneCode, organizationPublicDetails.alias);
	};

	render(): JSX.Element {
		const { pendingPhoneNumber, handleSubmit, submitting } = this.props;
		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone">
					<h2>Submit Activation Code</h2>
					<h4>Please check your mobile phone: <b>{formatPhoneNumber(pendingPhoneNumber, CountryCode.US)}</b></h4>
					<h4>You should receive an SMS with the activation code within a few minutes.</h4>
					<h4>
						If you do not receive the SMS, check that the number is correct and try again.<br />
						If you continue to have issues, contact support.
					</h4>
					<Form onSubmit={handleSubmit(this.submitCode)}>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									id="activationCode"
									label="Activation Code *"
									name="activationCode"
									type="text"
								/>
							</Col>
						</Row>
						<Row className="row--padded">
							<Col sm={24}>
								<SubmitButton
									className="btn--full"
									label="Submit"
									reduxFormSubmitting={submitting}
								/>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	return {
		organizationPublicDetails: state.organization.publicDetails,
		pendingPhoneNumber: state.user.pendingPhoneNumber,
	};
}
function mapDispatchToProps() {
	return {
		phoneLogin: AuthenticationActions.phoneLogin,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm({ form: USER_LOGIN_CODE, validate })
);
export default enhance(CodeLogin);
