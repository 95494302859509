import * as React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';

import CountryCode from 'acceligent-shared/enums/countryCode';
import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';

import AssignmentsModalSection from 'af-root/scenes/Company/ScheduleBoard/Shared/AssignmentsModalSection';
import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';

import CustomModal from 'af-components/CustomModal';
import LockedValue from 'af-components/LockedValue';
import SegmentLabel from 'af-components/SegmentLabel';
import LabelWithColor from 'af-components/LabelWithColor';

import ScheduleBoardTemporaryEmployeeModalVM from 'ab-viewModels/scheduleBoardTemporaryEmployeeModal.viewModel';
import ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import CreateTemporaryEmployeeAssignmentRM from 'ab-requestModels/workOrderTemporaryEmployee/createTemporaryEmployeeAssignment';

import { MAX_EMPLOYEE_ASSIGNMENTS_PER_DAY } from 'ab-constants/scheduleBoard';

import { RootState } from 'af-reducers';

import { formatPhoneNumber } from 'ab-utils/phone.util';
import { ScheduleBoardOnDateViewModel } from 'ab-viewModels/scheduleBoardWorkOrdersOnDateView.viewModel';

interface OwnProps {
	createAssignment: (workOrderId: number, data: CreateTemporaryEmployeeAssignmentRM) => void;
	/** `MM-DD-YYYY` */
	date: Nullable<string>;
	employee: Nullable<ScheduleBoardTemporaryEmployeeModalVM>;
	hasPermissionsToEditScheduleBoard: boolean;
	removeAssignment: (workOrderId: number, workOrderResourceLookupId: number) => void;
	setTemporaryEmployeeModalData: (employee: Nullable<ScheduleBoardTemporaryEmployeeModalVM>, date: Nullable<string>) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const getStateFromProps = (workOrders: ScheduleBoardOnDateViewModel | undefined, temporaryEmployeeId: Nullable<number>) => {
	const assignedWorkOrderCodes = !!temporaryEmployeeId
		? workOrders?.temporaryEmployeeAssignments?.[temporaryEmployeeId] ?? []
		: [];
	const assignedWorkOrders: ScheduleBoardWorkOrderViewModel[] = assignedWorkOrderCodes
		.map((workOrderCode) => workOrders?.workOrders?.[workOrderCode])
		.filter((_wo): _wo is ScheduleBoardWorkOrderViewModel => (!!_wo));

	return {
		assignedWorkOrders,
		assignableWorkOrders: Object.keys(workOrders?.workOrders ?? {})
			.reduce((_acc, _woCode) => {
				if (
					workOrders?.workOrders?.[_woCode]
					&& !assignedWorkOrderCodes.includes(_woCode)
					&& workOrders.workOrders[_woCode].status !== WorkOrderStatusEnum.CANCELED
				) {
					_acc.push(workOrders.workOrders[_woCode]);
				}
				return _acc;
			}, [] as ScheduleBoardWorkOrderViewModel[]),
	};
};

const TemporaryEmployeeModal: React.FC<Props> = (props: Props) => {
	const {
		createAssignment,
		employee,
		hasPermissionsToEditScheduleBoard,
		removeAssignment,
		setTemporaryEmployeeModalData,
		workOrders,
	} = props;

	const {
		assignedWorkOrders,
		assignableWorkOrders,
	} = React.useMemo(
		() => getStateFromProps(workOrders, employee?.id ?? null),
		[workOrders, employee]
	);

	const closeModal = React.useCallback(() => {
		setTemporaryEmployeeModalData(null, null);
	}, [setTemporaryEmployeeModalData]);

	const onCreateAssignment = React.useCallback(async (workOrderId: number) => {
		if (!hasPermissionsToEditScheduleBoard || !employee) {
			return false;
		}
		createAssignment(workOrderId, { temporaryEmployeeId: employee.id });
		return true;
	}, [hasPermissionsToEditScheduleBoard, employee, createAssignment]);

	const onRemoveAssignment = React.useCallback((workOrderId: number, workOrderCode: string, itemId: number, resourceLookupId?: number) => {
		if (!hasPermissionsToEditScheduleBoard || !resourceLookupId) {
			return;
		}
		removeAssignment(workOrderId, resourceLookupId);
	}, [hasPermissionsToEditScheduleBoard, removeAssignment]);

	const renderLoadingModalContent = () => {
		return (<Loading onHide={closeModal} />);
	};

	const renderDataModalContent = () => {
		if (!employee) {
			throw new Error('Employee not loaded');
		}

		const {
			email,
			firstName,
			id,
			jobCode,
			lastName,
			phoneNumber,
			agency,
			agencyColor,
			workOrderIdResourceLookupIdLookup,
		} = employee;

		const assignedInOffices = assignedWorkOrders.filter((wo) => !!wo?.officeNickname);

		return (
			<>
				<CustomModal.Header className="schedule-board-modal__resource__header">
					<div>
						<span className="icon-temp_labor schedule-board-modal__resource__header__icon"></span>
						<span>{firstName} {lastName}</span>
					</div>
					<LabelWithColor color={agencyColor} text={agency} />
				</CustomModal.Header>
				<CustomModal.Body>
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Agency" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Name"
									value={<LabelWithColor color={agencyColor} text={agency} />}
								/>
							</Col>
						</Row>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Contact Information" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Mobile Phone"
									value={formatPhoneNumber(phoneNumber, CountryCode.US)}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Email"
									value={email}
								/>
							</Col>
							<Col md={12} />
						</Row>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Current Status" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Job"
									value={jobCode}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Job Locations"
									value={assignedInOffices.length > 0
										? assignedInOffices.map((wo) => wo?.officeNickname).join(', ')
										: '-'
									}
								/>
							</Col>
						</Row>
					</div>
					<hr />
					<AssignmentsModalSection
						assignableWorkOrders={assignableWorkOrders}
						assignedWorkOrders={assignedWorkOrders}
						createAssignment={onCreateAssignment}
						isEditable={hasPermissionsToEditScheduleBoard}
						itemId={id}
						maxItems={MAX_EMPLOYEE_ASSIGNMENTS_PER_DAY}
						removeAssignment={onRemoveAssignment}
						resourceLookupIds={workOrderIdResourceLookupIdLookup}
						showPerDiem={false}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</>
		);
	};

	return (
		<CustomModal
			className="schedule-board-modal__resource"
			closeModal={closeModal}
			modalStyle="info"
			showModal={!!employee}
			size="xl"
		>
			{employee ? renderDataModalContent() : renderLoadingModalContent()}
		</CustomModal>
	);
};

function mapStateToProps(state: RootState, props: OwnProps) {
	const { date } = props;

	return {
		workOrders: date ? state?.scheduleBoard?.workOrdersByDateDictionary?.[date] : undefined,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(TemporaryEmployeeModal);
