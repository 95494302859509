import * as React from 'react';

import { TextColor, ColorPalette, AdditionalColors } from 'acceligent-shared/enums/color';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { DeliverableHistoryViewModel } from 'ab-viewModels/deliverableTable.viewModel';

import Tooltip from 'af-components/Tooltip';

import * as ColorUtils from 'ab-utils/color.util';

interface OwnProps {
	abbreviation: string;
	statusColor: ColorPalette | AdditionalColors;
	textColor: TextColor;
	history?: DeliverableHistoryViewModel[];
	onClick?: (event: React.MouseEvent) => void;
}

type Props = OwnProps;

const NUMBER_OF_ROWS = 6;

class DeliverableStatusLabel extends React.PureComponent<Props> {

	renderLabel = () => {
		const { abbreviation, statusColor, textColor, onClick } = this.props;
		const className = `deliverable-status ${ColorUtils.getColorBackgroundClass(statusColor, true)} ${ColorUtils.getColorTextClass(textColor, false, 'text-pastel-black')}`;
		return <div className={className} onClick={onClick}>{abbreviation}</div>;
	};

	renderTooltip = () => {
		const { history } = this.props;

		return (
			<>
				{(history ?? []).slice(0, NUMBER_OF_ROWS - 1).map((_history, _index) => (
					<div key={_index}><b>{TimeUtils.formatDate(_history.updatedAt)}</b>: {_history.status?.abbreviation}</div>
				))}
			</>
		);

	};

	renderLabelWithWrapper = () => {
		return <Tooltip message={this.renderTooltip()}>{this.renderLabel()}</Tooltip>;
	};

	render() {
		const { history } = this.props;
		return history?.length ? this.renderLabelWithWrapper() : this.renderLabel();
	}
}

export default DeliverableStatusLabel;
