import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	isOpaque: boolean;
	onClick?: () => void;
}

type Props = OwnProps;

const Gap: React.FC<Props> = (props: Props) => {
	const { isOpaque, onClick } = props;

	const className = bemElement('time-sheet-edit-modal', 'time-sheet-gap', { opaque: isOpaque, editable: !!onClick });

	const onGapClick = React.useCallback(() => {
		if (onClick) {
			onClick();
		}
	}, [onClick]);

	return (
		<div className={className} onClick={onGapClick}>
			<span className="icon-info" /> A gap in timeline
		</div>
	);
};

export default React.memo(Gap);
