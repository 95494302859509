import * as React from 'react';

interface Props {
	disabled: boolean;
	label: string;
	propName: string;
	addWorkOrderResourceLookup: (propName: string) => void;
}

export default class AddResourceLookupButton extends React.PureComponent<Props> {

	addResource = () => {
		const { addWorkOrderResourceLookup, propName } = this.props;
		addWorkOrderResourceLookup(propName);
	};

	render() {
		const { label, disabled } = this.props;

		return !disabled && (
			<a
				className="btn btn--link resource-lookup__add-new"
				id={`accqa__wo-edit__${label.replaceAll(' ', '-').toLowerCase()}`}
				onClick={this.addResource}
				role="button"
			>
				<span className="icon-plus" />
				{label}
			</a>
		);
	}

}
