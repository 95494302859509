import * as React from 'react';
import { Field } from 'redux-form';

import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';

import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';

import { formSectionClass, formSectionClassColumn1, formSectionClassColumn2, formSectionClassRow, formSectionTitleClass } from '../../helpers';
import styles from '../../styles.module.scss';

type Props = {
	isDisabled: boolean;
};

const JobDetailSection: React.FC<Props> = ({ isDisabled }) => {
	const taxExemptStyle = React.useMemo(() => [formSectionClassColumn2, styles['job-form__tab-content__form-section__row__centered-column']].join(' '), []);
	const unionPrevailingWageStyle = React.useMemo(() => [formSectionClassColumn1, styles['job-form__tab-content__form-section__row__centered-column']].join(' '), []);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Job Detail
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="projectOwner"
						label="Project Owner"
						name={getAttributeName('projectOwner')}
						placeholder="Enter Project Owner"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="ownerReference"
						label="Owner Reference"
						name={getAttributeName('ownerReference')}
						placeholder="Enter Owner Reference"
						type="text"
					/>
				</div>
				<div className={taxExemptStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="taxExempt"
						label="Tax Exempt"
						name={getAttributeName('taxExempt')}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="generalContractor"
						label="General Contractor"
						name={getAttributeName('generalContractor')}
						placeholder="Enter General Contractor"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="contractorReference"
						label="Contractor Reference"
						name={getAttributeName('contractorReference')}
						placeholder="Enter Contractor Reference"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={unionPrevailingWageStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="union"
						label="Union"
						name={getAttributeName('isUnion')}
					/>
				</div>
				<div className={unionPrevailingWageStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="prevailingWage"
						label="Prevailing Wage"
						name={getAttributeName('isPrevailingWage')}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="jurisdiction"
						label="Jurisdiction"
						name={getAttributeName('jurisdiction')}
						placeholder="Enter Jurisdiction"
						type="text"
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(JobDetailSection);
