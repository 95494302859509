import { EquipmentCostViewModel, EquipmentCostCategoryVM } from 'ab-viewModels/equipmentCost.viewModel';
import { TreeViewModel } from 'ab-viewModels/equipmentCostTree.viewModel';

import { EquipmentCostDragEnd } from 'ab-paramModels/equipmentCost.params';

import EquipmentCostTreeNodeLabel from 'ab-enums/equipmentCostTreeNodeLabel.enum';
import { isNullOrUndefined } from 'acceligent-shared/utils/extensions';

export const categoryHierarchyDashWidth = (level: number) => 5 + 10 * level;

export const getAncestors = (equipmentCost: EquipmentCostViewModel): EquipmentCostCategoryVM[] => {
	const ancestors: EquipmentCostCategoryVM[] = [];
	let category = equipmentCost.category;

	while (category) {
		ancestors.unshift(category);
		category = category.group ?? undefined;
	}
	return ancestors;
};

export const pad = (n: string | number, width: number, z: string = '0'): string => {
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

const _compareByCode = (a, b) => {
	if (a.code < b.code) {
		return -1;
	}
	if (a.code > b.code) {
		return 1;
	}
	return 0;
};

export const sortEquipmentCostCategoriesTree = (treeView: TreeViewModel[]): TreeViewModel[] => {
	treeView
		.sort((a, b) => {
			if (a.isCategory && !b.isCategory) {
				return -1;
			}
			if (!a.isCategory && b.isCategory) {
				return 1;
			}
			return _compareByCode(a, b);
		})
		.forEach((_tv) => {
			if (_tv.children) {
				_tv.children = sortEquipmentCostCategoriesTree(_tv.children);
			}
		});
	return treeView;
};

export const tagEmptyAndCountElements = (treeView: TreeViewModel[]): TreeViewModel[] => {
	return treeView.map((_tv) => _tagEmptyAndCountElement(_tv));
};

const _tagEmptyAndCountElement = (treeView: TreeViewModel): TreeViewModel => {
	if (!treeView.isCategory) {
		treeView.isEmpty = false;
		treeView.count = 1;
	} else if (!treeView.children?.length) {
		treeView.count = 0;
		treeView.isEmpty = true;
	} else {
		treeView.children = treeView.children.map((_tv) => _tagEmptyAndCountElement(_tv));
		treeView.isEmpty = !treeView.children || treeView.children.length === 0;
		treeView.count = treeView.children.reduce((sum, _tv) => sum + (_tv.count ?? 0), 0);
		treeView.hasEquipmentCostChildren = treeView.children.some((_tv) => !_tv.isCategory);
	}
	return treeView;
};

function filterEquipmentCosts(q: string, _element: TreeViewModel) {
	const query = q.toLowerCase();
	const name = _element.name.toLowerCase();
	const nameMatchStart = name.indexOf(query);

	return {
		isHighlighted: !!query && name.includes(query),
		nameMatchStart,
		matchLength: query.length,
	};
}

type TreeNodes = { [key: number]: TreeViewModel; };

const highlightNodes = (filter: string, nodes: TreeNodes = {}) => {
	return Object.keys(nodes).reduce((acc, id: string) => {
		const { isHighlighted, nameMatchStart, matchLength } = filterEquipmentCosts(filter, nodes[id]);

		return {
			...acc,
			[id]: {
				...nodes[id],
				toggled: isHighlighted,
				isHighlighted,
				nameMatchStart,
				matchLength,
			},
		};
	}, {});
};

export const highlightCategoryNodes = (filter: string, categoryNodes: TreeNodes | undefined = {}, equipmentCostNodes: TreeNodes = {}) => {
	const newCategoryNodes = highlightNodes(filter, categoryNodes);

	const toggleParents = (nodeId: number) => {
		if (!nodeId) {
			return;
		}
		newCategoryNodes[nodeId] = {
			...newCategoryNodes[nodeId], toggled: true,
		};
		toggleParents(newCategoryNodes[nodeId].parentNodeId);
	};

	const highlightedNodes = [
		...Object.keys(newCategoryNodes).filter((nodeId) => newCategoryNodes[nodeId].isHighlighted).map((nodeId) => newCategoryNodes[nodeId]),
		...Object.keys(equipmentCostNodes).filter((nodeId) => equipmentCostNodes[nodeId].isHighlighted).map((nodeId) => equipmentCostNodes[nodeId]),
	];
	highlightedNodes.forEach((node) => toggleParents(node.parentNodeId));
	return newCategoryNodes;
};

export const highlightEquipmentCostNodes = (filter: string, nodes: TreeNodes | undefined = {}) => highlightNodes(filter, nodes);

export const resetHighlightingForNodes = (nodes: TreeNodes) =>
	Object.keys(nodes).reduce((acc, id) => ({
		...acc,
		[id]: {
			...nodes[id],
			toggled: false,
			isHighlighted: false,
			nameMatchStart: -1,
			codeMatchStart: -1,
			matchLength: null,
		},
	}), {});

// #region Reorder

const _getLabelForChildrenAtLevel = (level: number): Nullable<EquipmentCostTreeNodeLabel> => {
	switch (level) {
		case 0:
			return EquipmentCostTreeNodeLabel.GROUP;
		case 1:
			return EquipmentCostTreeNodeLabel.CATEGORY;
		case 2:
			return EquipmentCostTreeNodeLabel.EQUIPMENT_COST;
		default:
			return null;
	}
};

const _reorderEquipmentCostTreeChildren = (
	tree: TreeViewModel,
	payload: EquipmentCostDragEnd,
	sourceIndex: number,
	destinationIndex: Nullable<number>,
	level: number = 0
): TreeViewModel => {
	// there are only 4 levels, we always reorder node's children so we need to stop at leafs. Leafs are at level 3.
	if (level > 2) {
		return { ...tree };
	}
	if (_getLabelForChildrenAtLevel(level) === payload.label) {
		const draggedNodeIsChild = tree.children?.some((_child: TreeViewModel) => _child._id === payload.id);
		if (draggedNodeIsChild && tree.children) {
			const newChildren = Array.from(tree.children);
			if (!isNullOrUndefined(sourceIndex) && !isNullOrUndefined(destinationIndex)) {
				const [removed] = newChildren.splice(sourceIndex, 1);
				newChildren.splice(destinationIndex, 0, removed);
			}
			return {
				...tree,
				children: newChildren,
			};
		}
	}

	return {
		...tree,
		children: tree.children?.map((_subTree: TreeViewModel) =>
			_reorderEquipmentCostTreeChildren(_subTree, payload, sourceIndex, destinationIndex, level + 1)),
	};
};

export const reorderEquipmentCostTree = (tree: TreeViewModel[], dragInfo: EquipmentCostDragEnd): TreeViewModel[] => {
	const sourceIndex = dragInfo.source.index;
	const destinationIndex = dragInfo?.destination?.index ?? null;

	if (destinationIndex === sourceIndex) {
		return tree;
	}

	return tree.map((_treeData: TreeViewModel) => _reorderEquipmentCostTreeChildren(_treeData, dragInfo, sourceIndex, destinationIndex));
};

// #endregion Reorder

// #region Node Removal

interface ChildrenCountData {
	children: TreeViewModel[];
	childrenCount: number;
}

const _removeEquipmentCostTreeNode = (tree: TreeViewModel, id: number, isCategory: boolean): { data: TreeViewModel; counter: number; } => {
	const child = tree.children?.find((_child: TreeViewModel) => _child._id === id && _child.isCategory === isCategory);
	if (child) {
		return {
			data: {
				...tree,
				count: !isNullOrUndefined(tree.count) && !isNullOrUndefined(child.count) ? tree.count - child.count : null,
				children: tree.children?.filter((_child: TreeViewModel) => _child._id !== id || _child.isCategory !== isCategory),
			},
			counter: child.count ?? 0,
		};
	}

	const { children, childrenCount }: ChildrenCountData = (tree.children ?? []).reduce((_acc: ChildrenCountData, _subTree: TreeViewModel) => {
		const { data, counter } = _removeEquipmentCostTreeNode(_subTree, id, isCategory);
		_acc.childrenCount += counter;

		// remove node if there are no children left
		if (_subTree.count !== data.count && data.count === 0) {
			return _acc;
		}
		_acc.children.push(data);
		return _acc;
	}, { children: [], childrenCount: 0 });

	return {
		data: {
			...tree,
			count: !isNullOrUndefined(tree.count) && !isNullOrUndefined(childrenCount) ? tree.count - childrenCount : null,
			children,
		},
		counter: childrenCount,
	};
};

export const removeEquipmentCostTreeNode = (tree: TreeViewModel[], id: number, isCategory: boolean): TreeViewModel[] => {
	return tree.map((_treeData: TreeViewModel) => _removeEquipmentCostTreeNode(_treeData, id, isCategory).data);
};

// #endregion Node Removal

export const countEquipmentCosts = (children: TreeViewModel[]): number => {
	return children.reduce(
		(_acc, _child) => _acc
			+ (_child.children?.length ? (_child.data as EquipmentCostCategoryVM)?.equipmentCostsCount : 1)
			+ (_child.isEquipmentCostGroup ? countEquipmentCosts(_child.children ?? []) : 0),
		0
	);
};
