import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';

import { RootState } from 'af-reducers';

import { WorkOrderDelayForm } from 'ab-requestModels/workOrder.requestModel';

import { ORDER_DELAY_FORM } from 'af-constants/reduxForms';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import Textarea from 'af-fields/Textarea';

interface OwnProps {
	showDelayModal: boolean;
	workOrderCode: string;
	closeDelayModal: () => void;
	onSubmit: (delayReason: WorkOrderDelayForm) => Promise<void>;
}

interface StateProps {
	selector: (prop: string) => string | number;
}

type FormOwnProps = OwnProps & StateProps;
type Props = FormOwnProps & InjectedFormProps<WorkOrderDelayForm, FormOwnProps>;

class DelayModal extends React.PureComponent<Props> {

	render() {
		const { selector, submitting, showDelayModal, workOrderCode, closeDelayModal, handleSubmit, onSubmit } = this.props;
		const delayReason = selector('delayReason') || '';

		return (
			<CustomModal
				closeModal={closeDelayModal}
				showModal={showDelayModal}
			>
				<CustomModal.Header
					closeModal={closeDelayModal}
					title={`Work Order - ${workOrderCode}`}
				/>
				<CustomModal.Body>
					<Field
						component={Textarea}
						label="Delay Reason *"
						name="delayReason"
						placeholder="Please enter begin late reason"
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button onClick={closeDelayModal} variant="info">Cancel</Button>
					<SubmitButton
						disabled={!delayReason}
						label="Publish Work Order"
						onClick={handleSubmit(onSubmit)}
						reduxFormSubmitting={submitting}
						submitKey={ORDER_DELAY_FORM}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState) {
	return {
		selector: (prop: string) => formValueSelector(ORDER_DELAY_FORM)(state, prop),
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, null, OwnProps>(mapStateToProps),
	reduxForm<WorkOrderDelayForm, FormOwnProps>({ form: ORDER_DELAY_FORM, enableReinitialize: true })
);

export default enhance(DelayModal);
