import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

class Loading extends React.PureComponent {

	render() {
		return (
			<div className="form-segment">
				<h3>Status</h3>
				<div className="form-box form-box--midi">
					<Row>
						<Col className="loading-box-field" sm={12} />
						<Col sm={12} />
					</Row>
				</div>
			</div>
		);
	}
}

export default Loading;
