import * as React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';

import Breadcrumbs, { BreadcrumbItem } from 'af-components/Breadcrumbs';

interface OwnProps {
	backToListLabel?: string;
	backToListUrl?: string;
	breadcrumbs: BreadcrumbItem[];
	editLabel?: string;
	editUrl?: string;
	isSmall?: boolean;
}

type Props = React.PropsWithChildren<OwnProps>;

const ResourcePreview: React.FC<Props> = (props: Props) => {
	const { breadcrumbs, backToListUrl, editUrl, backToListLabel, children, editLabel = 'Edit', isSmall = false } = props;

	let segmentClassName = 'form-segment';
	segmentClassName = isSmall ? `${segmentClassName} form-segment--mini` : segmentClassName;

	return (
		<div className={segmentClassName}>
			<Breadcrumbs items={breadcrumbs} />
			<div className="form-box">
				{children}
				<Row className="row--submit">
					{(backToListLabel && backToListUrl) && <Link className="btn btn-info" to={backToListUrl}>{backToListLabel}</Link>}
					{editUrl && <Link className="btn btn-info" to={editUrl}>{editLabel}</Link>}
				</Row>
			</div>
		</div>
	);
};

export default React.memo(ResourcePreview);
