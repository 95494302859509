import * as React from 'react';

import LockedValue from 'af-components/LockedValue';

import { previewSectionClass, previewSectionClassColumn2, previewSectionClassColumn4, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';

type Props = {
	environmentalExposure: boolean;
	environmentalExposureDescription: Nullable<string>;
	respiratoryProtection: boolean;
	respiratoryProtectionDescription: Nullable<string>;
	overheadHazards: boolean;
	overheadHazardsDescription: Nullable<string>;
	heightWork: boolean;
	heightWorkDescription: Nullable<string>;
	mechanicalHazards: boolean;
	mechanicalHazardsDescription: Nullable<string>;
	electricalHazards: boolean;
	electricalHazardsDescription: Nullable<string>;
	hazardousMaterials: boolean;
	hazardousMaterialsDescription: Nullable<string>;
	dangerousAnimalsOrPlants: boolean;
	dangerousAnimalsOrPlantsDescription: Nullable<string>;
};

const SafetySection: React.FC<Props> = (props) => {
	const {
		dangerousAnimalsOrPlants,
		dangerousAnimalsOrPlantsDescription,
		electricalHazards,
		electricalHazardsDescription,
		environmentalExposure,
		environmentalExposureDescription,
		hazardousMaterials,
		hazardousMaterialsDescription,
		heightWork,
		heightWorkDescription,
		mechanicalHazards,
		mechanicalHazardsDescription,
		overheadHazards,
		overheadHazardsDescription,
		respiratoryProtection,
		respiratoryProtectionDescription,
	} = props;
	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Safety
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Environmental Exposure" value={environmentalExposure ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={environmentalExposureDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Respiratory Protection" value={respiratoryProtection ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={respiratoryProtectionDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Ovearhead Hazards" value={overheadHazards ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={overheadHazardsDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Height Work" value={heightWork ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={heightWorkDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Mechanical Hazards" value={mechanicalHazards ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={mechanicalHazardsDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Electrical Hazards" value={electricalHazards ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={electricalHazardsDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Hazardous Materials" value={hazardousMaterials ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={hazardousMaterialsDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Dangerous Animals or Plants" value={dangerousAnimalsOrPlants ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={dangerousAnimalsOrPlantsDescription} />
				</div>
			</div>
		</div>
	);
};

export default React.memo(SafetySection);
