import * as React from 'react';
import { Field } from 'redux-form';

import Input from 'af-fields/Input';

import { phoneNormalizer } from 'ab-utils/reduxForms.util';

import styles from '../../../styles.module.scss';
import JobUpsertFM from '../../../formModel';
import { formSectionClassColumn2, formSectionClassRow } from '../../../helpers';

const AdditionalBillingInformation: React.FC = () => {
	return (
		<>
			<div className={styles['job-form__billing-information-title']}>
				Billing Information
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="billTo"
						label="Bill To"
						name={JobUpsertFM.getAttributeName('billTo')}
						placeholder="Enter Bill To"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="billToAddress"
						label="Address"
						name={JobUpsertFM.getAttributeName('billToAddress')}
						placeholder="Enter Address"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="billToCityStateZip"
						label="City, State, Zip"
						name={JobUpsertFM.getAttributeName('billToCityStateZip')}
						placeholder="Enter City, State, Zip"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="billToContactName"
						label="Contact Name"
						name={JobUpsertFM.getAttributeName('billToContactName')}
						placeholder="Enter Contact Name"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="billToPhoneNumber"
						label="Phone Number"
						name={JobUpsertFM.getAttributeName('billToPhoneNumber')}
						normalize={phoneNormalizer}
						placeholder="Enter Phone Number"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="billToFaxNumber"
						label="Fax Number"
						name={JobUpsertFM.getAttributeName('billToFaxNumber')}
						placeholder="Enter Fax Number"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="billToEmail"
						label="Email Address"
						name={JobUpsertFM.getAttributeName('billToEmail')}
						placeholder="Enter Email Address"
					/>
				</div>
			</div>
		</>
	);
};

export default React.memo(AdditionalBillingInformation);
