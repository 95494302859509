import * as React from 'react';

import { dollarFormatter, dollarFormatterUnitPrice } from 'af-utils/format.util';

import styles from './styles.module.scss';

interface Props {
	value: number;
	className?: string;
	isUnitPrice?: boolean;
	maxFractionDigits?: number;
	minFractionDigits?: number;
}

const DollarCell: React.FC<Props> = ({ value, className, isUnitPrice = false, maxFractionDigits, minFractionDigits }) => {
	const classNames: string[] = [styles['dollar-cell']];
	if (className) {
		classNames.push(className);
	}
	return (
		<div className={classNames.join(' ')}>
			{isUnitPrice ? dollarFormatterUnitPrice(maxFractionDigits, minFractionDigits).format(value) : dollarFormatter.format(value)}
		</div>
	);
};

export default React.memo(DollarCell);
