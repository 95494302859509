import * as React from 'react';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { bemElement } from 'ab-utils/bem.util';

interface Props {
	startTime: string;
	addedMinutes?: number;
}

const Stopwatch: React.FC<Props> = (props: Props) => {
	const { startTime, addedMinutes } = props;

	const [currentTime, setCurrentTime] = React.useState(new Date());

	React.useEffect(() => {
		// https://en.wikipedia.org/wiki/Nyquist%E2%80%93Shannon_sampling_theorem
		const intervalId = setInterval(() => setCurrentTime(new Date()), 500);

		return () => clearInterval(intervalId);
	}, []);

	let timeDiff = TimeUtils.getDiff(currentTime, new Date(startTime), 'seconds', TimeFormat.ISO_DATETIME);

	if (addedMinutes) {
		timeDiff += addedMinutes * 60;
	}

	return (
		<div className={bemElement('time-sheet-list__row__info__total-time', 'tracked-entry')}>
			{TimeUtils.secondsToHMS(timeDiff)}
		</div>
	);
};

export default Stopwatch;
