import * as React from 'react';
import { previewSectionClass, previewSectionClassColumn1, previewSectionClassColumn2, previewSectionClassRow } from '../../helpers';
import LockedValue from 'af-components/LockedValue';

type Props = {
	streetAddress: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	zip: Nullable<string>;
};

const WorkLocationSection: React.FC<Props> = (props) => {
	const {
		city,
		state,
		streetAddress,
		suite,
		zip,
	} = props;

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Street Address" value={streetAddress} />
				</div>
				<div className={previewSectionClassColumn1}>
					<LockedValue label="Suite/Floor" value={suite} />
				</div>
				<div className={previewSectionClassColumn1}>
					<LockedValue label="City" value={city} />
				</div>
				<div className={previewSectionClassColumn1}>
					<LockedValue label="State" value={state} />
				</div>
				<div className={previewSectionClassColumn1}>
					<LockedValue label="Zip" value={zip} />
				</div>
				<div className={previewSectionClassColumn1}>
					<LockedValue label="PO Box" value={streetAddress} />
				</div>
			</div>
		</div>
	);
};

export default React.memo(WorkLocationSection);
