import * as React from 'react';

import TimeSheetSignatureStatus from 'acceligent-shared/enums/timeSheetSignatureStatus';

interface Props {
	signatureStatus: Nullable<TimeSheetSignatureStatus>;
}

const SignatureStatus: React.FC<Props> = (props) => {
	const { signatureStatus } = props;

	switch (signatureStatus) {

		case TimeSheetSignatureStatus.OUTDATED:
			return <span className="condensed-table__signature condensed-table__signature--outdated">Signature Outdated</span>;
		case TimeSheetSignatureStatus.SIGNED:
			return <span className="condensed-table__signature condensed-table__signature--signed">Signed</span>;
		case TimeSheetSignatureStatus.UNSIGNED:
			return <span className="condensed-table__signature condensed-table__signature--unsigned">Unsigned</span>;
		default:
			return <span className="condensed-table__signature condensed-table__signature--unsigned">Not available</span>;
	}
};

export default SignatureStatus;
