import * as React from 'react';
import ReactTable, { TableProps } from 'react-table-6';
import checkboxHOC from 'react-table-6/lib/hoc/selectTable';

import { filterMap } from 'ab-utils/array.util';

/**
 * Key values, i.e. ids, are prefixed with this value when passed to the default checkbox component.
 * @link https://github.com/tannerlinsley/react-table/blob/f003ba3e947f94e440358bf8e2c7d9779a4c4f9c/src/hoc/selectTable/index.js#L37
 */
const KEY_PREFIX = 'select-';

function getSelectionItem(key: string): Nullable<number> {
	if (!key) {
		return null;
	}
	if (typeof key === 'number') {
		return key;
	}
	return parseInt(key.replace(KEY_PREFIX, ''));
}

interface Props<T> extends Partial<TableProps<T>> {
	keyField: string;
	selectType: 'checkbox' | 'radio';
	onSelect: (selection: (number | null)[]) => void;
	onMount?: (table: ReactTable<T>) => void;
}

interface State {
	selection: (number | null)[];
	selectAll: boolean;
}

function hoc(component: React.ComponentClass) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const CheckboxReactTable = checkboxHOC(component) as any;
	const wrapper = class SelectableTable<T> extends React.Component<Props<T>, State> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		private _checkboxTable: Nullable<React.RefObject<unknown> & { wrappedInstance: any; }> = null;

		constructor(props: Props<T>) {
			super(props);
			this.state = {
				selection: [],
				selectAll: false,
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			this._checkboxTable = React.createRef() as React.RefObject<unknown> & { wrappedInstance: any; };
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		static isRecordSelectable = (_item: any) => (typeof _item._original.selectable === 'boolean' ? _item._original.selectable : true) && _item._original.id;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		static getRecordId = (_item: any) => _item._original.id;

		// eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
		public clearSelection = () => {
			this.setState({ selection: [] });
		};

		toggleSelection = (key: string) => {
			const item = getSelectionItem(key);
			let selection: (number | null)[] = [
				...this.state.selection,
			];
			const itemIndex = item !== null ? selection.indexOf(item) : -1;
			if (itemIndex >= 0) {
				selection = [
					...selection.slice(0, itemIndex),
					...selection.slice(itemIndex + 1),
				];
			} else {
				selection.push(item);
			}
			// update the state
			this.setState({ selection }, () => {
				this.props.onSelect(selection);
			});
		};

		toggleAll = () => {
			const selectAll = !this.state.selectAll;
			let selection: (number | null)[] = [];
			const wrappedInstance = this._checkboxTable?.wrappedInstance;
			const currentRecords = wrappedInstance.getResolvedState().sortedData;
			if (selectAll) {
				selection = filterMap(
					currentRecords,
					SelectableTable.isRecordSelectable,
					SelectableTable.getRecordId
				);
			}
			this.setState({ selectAll, selection }, () => {
				this.props.onSelect(selection);
			});
		};

		isSelected = (key: string) => this.state.selection.includes(getSelectionItem(key));

		onTableMount = (table) => {
			const { onMount } = this.props;
			this._checkboxTable = table;

			if (onMount) {
				onMount(this._checkboxTable?.wrappedInstance);
			}
		};

		render() {
			const { toggleSelection, toggleAll, isSelected } = this;
			const { className } = this.props;

			return (
				<CheckboxReactTable
					isSelected={isSelected}
					ref={this.onTableMount}
					selectAll={this.state.selectAll}
					toggleAll={toggleAll}
					toggleSelection={toggleSelection}
					{...this.props}
					className={`checkbox-table ${className ?? ''}`}
					selectType="checkbox"
				/>
			);
		}
	};

	return wrapper;
}

export default hoc;
