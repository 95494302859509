import * as React from 'react';

import { bemBlock } from 'ab-utils/bem.util';

interface OwnProps {
	text: string;
	badgeColor: string;
	colorNegative: boolean;
}

type Props = OwnProps;

const BadgeCell = ({ text, badgeColor, colorNegative }: Props) => {
	if (!text) {
		return <span>-</span>;
	}

	return (
		<div
			className={bemBlock('rt-badge', colorNegative ? ['negative'] : undefined)}
			style={{ backgroundColor: badgeColor }}
		>
			<span>{text}</span>
		</div>
	);
};

export default BadgeCell;
