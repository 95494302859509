import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';

import { RootState } from 'af-reducers';

import * as AuthenticationActions from 'af-actions/authentication';

import * as User from 'ab-viewModels/user.viewModel';

import { defaultRedirectUrl } from 'af-utils/actions.util';

interface OwnProps {
	index: number;
	orgAlias: string;
	company: User.CompanyData;
	onClose: () => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const CompanyItem: React.FC<Props> = (props) => {

	const { company, index, setCurrentCompany, userRole, orgAlias, onClose } = props;

	const navigate = useNavigate();

	const changeCompany = React.useCallback(() => {
		// NOTE: socket company change and SB reload will be initiated by the SocketConnectionHOC
		navigate(defaultRedirectUrl(orgAlias, company.name, company.permissions, company.isCompanyAdmin, userRole));
		setCurrentCompany(orgAlias, company.id, company.name);
		onClose();
	}, [company.id, company.isCompanyAdmin, company.name, company.permissions, navigate, onClose, orgAlias, setCurrentCompany, userRole]);

	return (
		<div className="main-header__companies-dropdown__menu__item" key={index} onClick={changeCompany}>
			{company.logo
				? <img className="main-header__companies-dropdown__menu__item__logo" src={company.logo} />
				: <div className="main-header__companies-dropdown__menu__item__logo">{company.name.substring(0, 1).toUpperCase()}</div>
			}
			<span title={company.name}>
				{company.name}
			</span>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { userData } = state.user;
	if (!userData) {
		throw new Error('User not logged in');
	}

	return {
		userRole: userData.role,
	};
}

function mapDispatchToProps() {
	return {
		setCurrentCompany: AuthenticationActions.setCurrentCompany,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector<React.ComponentType<OwnProps>>(CompanyItem);
