import * as React from 'react';
import { Field, formValueSelector, getFormValues } from 'redux-form';
import { ConnectedProps, connect } from 'react-redux';

import BlobStorageContainer from 'acceligent-shared/enums/blobStorageContainer';

import DirectoriesAndAttachmentsForm, { DirectoryRM, Column } from 'af-components/SharedForms/DirectoriesAndAttachments';
import { AttachmentEntity } from 'af-components/DocumentsAttachmentsTree/types';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import { RootState } from 'af-reducers';

import WorkOrderFM from '../../formModel';

const COLUMNS: Column[] = [
	{
		header: 'Category folder',
		id: 'categoryFolder',
		width: 6,
	},
	{
		header: 'Files',
		id: 'files',
		width: 1,
	},
	{
		header: 'Download',
		id: 'download',
		width: 1,
	},
	{
		header: 'Upload',
		id: 'upload',
		width: 1,
	},
	{
		header: 'Preview',
		id: 'preview',
		width: 1,
	},
	{
		header: 'Last modified',
		id: 'lastModified',
		width: 2,
	},
];

interface OwnProps {
	change: (fieldName: string, value: unknown) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const Attachments: React.FC<Props> = (props) => {
	const {
		attachments,
		change: stupidChangeWhichChanges,
		directories,
		uploadedAttachmentIds,
		uploadingAttachments,
		workOrderId,
	} = props;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const change = React.useMemo(() => stupidChangeWhichChanges, []);
	// ignore deps - change changes for no reason

	const handleDirectoriesChanged = React.useCallback((changedDirectories: DirectoryRM[]) => {
		change('directories', changedDirectories);
	}, [change]);

	const handleAttachmentsChanged = React.useCallback((changedAttachments: AttachmentEntity) => {
		change('attachments', changedAttachments);
	}, [change]);

	const handleAttachmentsUpload = React.useCallback((attachmentIds: number[]) => {
		change('uploadedAttachmentIds', [...attachmentIds]);
	}, [change]);

	const handleAttachmentUploadStart = React.useCallback((correlationId: string) => {
		const nextUploadingAttachments = { ...uploadingAttachments };
		nextUploadingAttachments[correlationId] = true;
		change('uploadingAttachments', nextUploadingAttachments);
	}, [change, uploadingAttachments]);

	const handleAttachmentUploadEnd = React.useCallback((correlationId: string) => {
		const nextUploadingAttachments = { ...uploadingAttachments };
		delete nextUploadingAttachments[correlationId];
		change('uploadingAttachments', nextUploadingAttachments);
	}, [change, uploadingAttachments]);

	if (!workOrderId) {
		return null;
	}

	return (
		<Field
			attachments={attachments}
			component={DirectoriesAndAttachmentsForm}
			containerId={workOrderId}
			directories={directories}
			name="uploadingAttachments"
			onAttachmentsChanged={handleAttachmentsChanged}
			onAttachmentsUpload={handleAttachmentsUpload}
			onAttachmentUploadEnd={handleAttachmentUploadEnd}
			onAttachmentUploadStart={handleAttachmentUploadStart}
			onDirectoriesChanged={handleDirectoriesChanged}
			storageContainer={BlobStorageContainer.WO_ATTACHMENTS}
			tableColumns={COLUMNS}
			uploadedAttachmentIds={uploadedAttachmentIds}
		/>
	);
};

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const { user: { userData, companyData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}
	const workOrderId = selector(state, 'id') as Nullable<number>;
	const formData = getFormValues(WORK_ORDER_FORM)(state) as WorkOrderFM;

	return {
		workOrderId,
		attachments: formData.attachments,
		directories: formData.directories ?? null,
		uploadedAttachmentIds: formData.uploadedAttachmentIds ?? null,
		uploadingAttachments: formData.uploadingAttachments,
	};
}

const connector = connect(mapStateToProps);

export default connector(Attachments);
