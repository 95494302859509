import * as React from 'react';

import Header from './Header';
import Section from './Section';

import styles from './styles.module.scss';

interface FormProps extends React.PropsWithChildren {
	id?: string;
}

const Form = ({ children, id }: FormProps) => {
	return (
		<div className={styles.form} id={id}>{children}</div>
	);
};

Form.Header = Header;
Form.Section = Section;

export default Form;

