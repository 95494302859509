import * as TimeOptionUtils from 'acceligent-shared/utils/timeOption';

import { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import { WorkOrderSettingsRM } from 'ab-requestModels/company/workOrderSettings.requestModel';

export const fromVMtoRM = (company: CompanyViewModel): WorkOrderSettingsRM => {
	return {
		dayShiftStart: TimeOptionUtils.toString(company.dayShiftStart),
		dayShiftEnd: TimeOptionUtils.toString(company.dayShiftEnd),
		nightShiftStart: TimeOptionUtils.toString(company.nightShiftStart),
		nightShiftEnd: TimeOptionUtils.toString(company.nightShiftEnd),
	};
};
