import * as React from 'react';

interface Props {
	color?: 'black' | 'blue' | 'orange' | 'white';
	size?: 'small' | 'medium' | 'big';
}

const LoadingIndicator = ({ color = 'white', size = 'medium' }: Props): JSX.Element => {

	const baseClassName = `icon-dot loading-indicator__dots__dot loading-indicator__dots__dot--${size}`;
	const colorName = `loading-indicator__dots__dot--${color}`;

	return (
		<span className="loading-indicator">
			<div className="loading-indicator__dots">
				<span className={`${baseClassName} loading-indicator__dots__dot--one ${colorName}`} />
				<span className={`${baseClassName} loading-indicator__dots__dot--two ${colorName}`} />
				<span className={`${baseClassName} loading-indicator__dots__dot--three ${colorName}`} />
			</div>
		</span>
	);
};

export default LoadingIndicator;
