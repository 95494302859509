import * as React from 'react';
import { CustomRouteComponentProps, Redirect } from 'react-router-dom';

import CLIENT from 'af-routes/client';

import LoadingIndicator from 'af-components/LoadingIndicator';

import { createOrganizationEventListenerForPlatform, sendAdminDataRequestToPlatform } from 'af-utils/platform.util';

type Props = CustomRouteComponentProps;

interface State {
	storageUpdated: boolean;
}

export default class Login extends React.PureComponent<Props, State> {
	state: State = {
		storageUpdated: false,
	};

	componentDidMount() {
		createOrganizationEventListenerForPlatform(this.onStorageUpdate);
		sendAdminDataRequestToPlatform();
	}

	onStorageUpdate = () => this.setState(() => ({ storageUpdated: true }));

	render() {
		if (!this.state.storageUpdated) {
			return <LoadingIndicator color="orange" />;
		}

		const { location, location: { state: { orgAlias } } } = this.props;

		return <Redirect to={{ ...location, pathname: CLIENT.AUTH.LOGIN(orgAlias) }} />;
	}
}
