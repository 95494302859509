import * as React from 'react';
import { Accept, FileError, FileRejection, useDropzone } from 'react-dropzone';

import styles from './styles.module.scss';
import { Button } from 'react-bootstrap';

interface OwnProps {
	onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
	allowedTypes?: Accept;
	className?: string;
	disabled?: boolean;
	style?: React.CSSProperties;
	noKeyboard?: boolean;
	noDrag?: boolean;
	errorText?: FileError[];
}

type Props = React.PropsWithChildren<OwnProps>;

const DropzoneWithButton = (props: Props) => {
	const { children, allowedTypes, noKeyboard, noDrag, onDrop, style, className } = props;
	const {
		getRootProps,
		getInputProps,
		open,
	} = useDropzone({
		accept: allowedTypes,
		noClick: true,
		noKeyboard,
		noDrag,
		onDrop,
	});

	return (
		<section>
			<div {...getRootProps({ className: className ?? `${styles['dropzone-element']}`, style: style })}>
				<input {...getInputProps()} />
				{children}
				<Button className={styles['dropzone-element__select-files-button']} onClick={open} variant="info">
					Select files
				</Button>
			</div>
		</section>
	);
};

export default DropzoneWithButton;
