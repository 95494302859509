import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, FieldArray, getFormValues } from 'redux-form';
import { Button, Row, Col } from 'react-bootstrap';
import { nanoid } from 'nanoid';

import * as ReportBlockFieldEnums from 'acceligent-shared/enums/reportBlockField';
import OperationType, { OperationName } from 'acceligent-shared/enums/operation';

import { REPORT_BLOCK_TOOLTIP_MAX_LENGTH } from 'acceligent-shared/constants/value';

import { RootState } from 'af-reducers';

import { REPORT_BLOCK_CALCULATED_FIELD } from 'af-constants/reduxForms';

import Input from 'af-fields/Input';
import Dropdown from 'af-fields/Dropdown';
import Checkbox from 'af-fields/Checkbox';
import Textarea from 'af-fields/Textarea';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { UNIQUE_ID_SIZE } from 'ab-constants/value';

import { validateReportBlockField as validate } from '../../ReportBlock/Modals/validation';
import { PREDEFINED_INITIAL_VALUES } from '../../ReportBlock/values';
import CalculatedFieldOptions, { OwnProps as CalculatedFieldOptionsProps } from '../../Shared/CalculatedFieldOption';

import { ReportBlockFieldFormModel, CalculatedFieldOptionFormModel, BlockDropdownSection } from '../../Shared/formModel';

const DEFAULT_FORM_VALUE = {};

interface DropdownOption<T> {
	id: T;
	label: string;
}

interface OwnProps {
	close: () => void;
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; };
	firstInitialValues: Nullable<Partial<ReportBlockFieldFormModel>>;
	isPrimary: boolean;
	isTotalBlock: boolean;
	onSubmit: (
		form: ReportBlockFieldFormModel,
		isPrimary: boolean,
		isTotalBlock: boolean,
		reportTypeBlockId: Nullable<string>
	) => void;
	options: BlockDropdownSection<CalculatedFieldOptionFormModel>[];
	reportTypeBlockId: Nullable<string>;
	showModal: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<ReportBlockFieldFormModel>;

const OPERATION_TYPE_OPTIONS: DropdownOption<OperationType>[] = Object.keys(OperationName)
	.map((_key: OperationType) => ({
		id: _key,
		label: OperationName[_key],
	}));

const DIMENSION_OPTIONS: DropdownOption<ReportBlockFieldEnums.Dimension>[] = Object.keys(ReportBlockFieldEnums.DimensionNamed)
	.reduce((_acc: DropdownOption<ReportBlockFieldEnums.Dimension>[], _key: ReportBlockFieldEnums.Dimension) => {
		if (_key !== ReportBlockFieldEnums.Dimension.VERY_SMALL) {
			_acc.push({ id: _key, label: ReportBlockFieldEnums.DimensionNamed[_key] });
		}
		return _acc;
	}, []);

const _reportTypeBuilderFilter = (option: ReportBlockFieldFormModel) => {
	return option.fieldType !== ReportBlockFieldEnums.Type.CALCULATED;
};

const CalculatedFieldModal: React.FC<Props> = (props: Props) => {
	const {
		destroy,
		change,
		close,
		fieldsByIdMap,
		firstInitialValues,
		handleSubmit,
		initialize,
		invalid,
		isPrimary,
		isTotalBlock,
		reportTypeBlockId,
		onSubmit,
		options,
		formValues,
		showModal,
		submitting,
	} = props;

	const [showCalculatedOptions, setShowCalculatedOptions] = React.useState<boolean>(!!formValues.operationType);

	React.useEffect(() => {
		setShowCalculatedOptions(!!formValues.operationType);
	}, [formValues]);

	React.useEffect(() => {
		if (showModal) {
			initialize(firstInitialValues ?? PREDEFINED_INITIAL_VALUES[ReportBlockFieldEnums.Type.CALCULATED]);
		} else {
			destroy();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal]);

	const onHandleSubmit = (form: ReportBlockFieldFormModel) => {
		if (!form.virtualId) {
			form.virtualId = nanoid(UNIQUE_ID_SIZE);
		}
		onSubmit(form, isPrimary, isTotalBlock, reportTypeBlockId);
		close();
	};

	const onOperationChange = React.useCallback(() => {
		change('calculatedFieldOptions', null);
		change('unit', null);
	}, [change]);

	// Only Total block and Calculated blocks in primary segment can use fields from other calculated blocks
	const additionalFilter = (isTotalBlock || isPrimary) ? undefined : _reportTypeBuilderFilter;

	return (
		<CustomModal
			className="report-block-modal"
			closeModal={close}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={close}
				title={`${firstInitialValues?.id ? 'Edit' : 'Add'} Calculated Fields`}
			/>
			<CustomModal.Body>
				<Row className="row--non-padded">
					<Col sm={12}>
						<Field
							component={Input}
							id="name"
							label="Field Name *"
							name="name"
							placeholder="Enter Name"
							type="text"
						/>
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							dropdownClassName="report-block-form-field-modal__dimension-dropdown"
							fixed={true}
							id="dimension"
							label="Field Dimensions *"
							labelKey="label"
							name="dimension"
							options={DIMENSION_OPTIONS}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--non-padded">
					<Col sm={12}>
						<Field
							component={Dropdown}
							fixed={true}
							id="operationType"
							label="Operator *"
							labelKey="label"
							name="operationType"
							onValueChange={onOperationChange}
							options={OPERATION_TYPE_OPTIONS}
							placeholder="Select Operation"
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<div className="report-block-form-field-modal__grouped">
					{showCalculatedOptions &&
						<FieldArray<CalculatedFieldOptionsProps>
							additionalFilter={additionalFilter}
							change={change}
							component={CalculatedFieldOptions}
							fieldsByIdMap={fieldsByIdMap}
							formValues={formValues}
							grouped={true}
							name="calculatedFieldOptions"
							options={options}
						/>
					}
					<Row className="row--non-padded">
						<Field
							component={Checkbox}
							id="isVisibleToCustomer"
							inline={true}
							isStandalone={true}
							label={ReportBlockFieldEnums.ReportBlockFieldFlags.IS_VISIBLE_TO_CUSTOMER}
							name="isVisibleToCustomer"
							tooltipMessage={ReportBlockFieldEnums.ReportBlockFieldFlagHint.IS_VISIBLE_TO_CUSTOMER}
						/>
					</Row>
					<Row className="row--non-padded">
						<Field
							component={Checkbox}
							id="hasTooltip"
							inline={true}
							isStandalone={true}
							label={ReportBlockFieldEnums.ReportBlockFieldFlags.HAS_CUSTOM_INFORMATION_TOOLTIP}
							name="hasTooltip"
							tooltipMessage={ReportBlockFieldEnums.ReportBlockFieldFlagHint.HAS_CUSTOM_INFORMATION_TOOLTIP}
						/>
					</Row>
					{formValues.hasTooltip &&
						<Field
							component={Textarea}
							maxCharacters={REPORT_BLOCK_TOOLTIP_MAX_LENGTH}
							name="tooltipText"
							placeholder="Tooltip Message"
							rows={3}
							showMaxCharactersLabel={true}
						/>
					}
				</div>
			</CustomModal.Body >
			<CustomModal.Footer>
				<Button onClick={close} variant="info">
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					label="Save"
					onClick={handleSubmit(onHandleSubmit)}
					reduxFormSubmitting={submitting}
					submitKey={REPORT_BLOCK_CALCULATED_FIELD}
				/>
			</CustomModal.Footer>
		</CustomModal >
	);
};

const getForm = getFormValues(REPORT_BLOCK_CALCULATED_FIELD);

function mapStateToProps(state: RootState) {
	return {
		formValues: (getForm(state) ?? DEFAULT_FORM_VALUE) as ReportBlockFieldFormModel,
	};
}

const connector = connect(mapStateToProps);
const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector,
	reduxForm({
		form: REPORT_BLOCK_CALCULATED_FIELD,
		validate,
	})
);

export default enhance(CalculatedFieldModal);

