import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'react-bootstrap';
import { reduxForm, InjectedFormProps, FieldArray } from 'redux-form';

import * as LocationActions from 'af-actions/location';

import LocationOptionVM from 'ab-viewModels/location/option.viewModel';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { RootState } from 'af-reducers';

import { SORT_LOCATION_FORM, LOADING_LOCATIONS } from 'af-constants/reduxForms';

import SortableLocations, { OwnProps as LocationsOwnProps } from './SortableLocations';

interface OwnProps {
	refreshTable: () => Promise<void>;
}

type SortableLocationForm = { locationsForCompany: LocationOptionVM[]; };

type FormProps = InjectedFormProps<SortableLocationForm, FormOwnProps>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormOwnProps & FormProps;

interface State {
	show: boolean;
}

export class SortModal extends React.PureComponent<Props, State> {

	state: State = {
		show: false,
	};

	onSubmit = async (form: { locationsForCompany: LocationOptionVM[]; }) => {
		const { saveSortedIndexes, refreshTable } = this.props;
		const formData = form.locationsForCompany.map((_location, _index) => ({
			..._location,
			index: _index,
		}));
		await saveSortedIndexes(formData);
		if (refreshTable) {
			await refreshTable();
		}
		this.close();
	};

	public open = () => {
		this.setState(() => ({ show: true }), async () => {
			const { findAll, initialize } = this.props;
			const locations = await findAll();
			initialize({ locationsForCompany: locations });
		});
	};

	close = () => {
		this.setState(() => ({ show: false }), () => {
			const { destroy } = this.props;
			destroy();
		});
	};

	render() {
		const { submitting, handleSubmit, isLoadingLocations } = this.props;
		const { show } = this.state;

		return (
			<CustomModal
				closeModal={this.close}
				modalStyle="info"
				showModal={show}
				size="md"
			>
				<CustomModal.Header
					closeModal={this.close}
					title="Edit Sort Indices"
				/>
				<CustomModal.Body>
					<p>
						The sorting order defined below will be used
						for resources on the <strong>Schedule Board Job Toolbar</strong> and <strong>Statistics</strong>.
						Drag the handles next to the locations to reorder them.
					</p>
					<br />
					<FieldArray<LocationsOwnProps>
						component={SortableLocations}
						isLoading={isLoadingLocations}
						name="locationsForCompany"
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={this.close}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						label="Save"
						onClick={handleSubmit(this.onSubmit)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState) {

	return {
		isLoadingLocations: state.http.submitting.includes(LOADING_LOCATIONS),
	};
}

function mapDispatchToProps() {
	return {
		saveSortedIndexes: LocationActions.saveSortedIndexes,
		findAll: LocationActions.findList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<SortableLocationForm, FormOwnProps>({ form: SORT_LOCATION_FORM })
);
export default React.forwardRef<SortModal, OwnProps>((ownProps: OwnProps, ref: React.RefObject<SortModal>) => {
	const EnhancedComponent = enhance((props) => {
		return <SortModal {...props} ref={ref} />;
	});
	return <EnhancedComponent {...ownProps} />;
});
