import * as React from 'react';

import { ModalNavigationManager, ModalRouteData, NavigationManager } from 'af-utils/modalNavigation.util';

// Props for routing components
interface LinkProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children?: any;
	link: string;
	className?: string;
	onClick?: () => void;
}

interface RouteProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	propsForPath?: any;
}

interface RouterProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children?: any;
	routes: ModalRouteData[];
}

// Router context types
interface ModalRouterContextData {
	manager: NavigationManager;
	routes: ModalRouteData[];
	navigationAttempted: number;
}

type ModalRouterContextType = [ModalRouterContextData, React.Dispatch<React.SetStateAction<ModalRouterContextData>>];

// Hooks for using modal navigation context
const ModalRouterContext = React.createContext<ModalRouterContextType>(
	[{ manager: new ModalNavigationManager(), routes: [], navigationAttempted: 1 }, () => { return; }]);

export const useModalLocation = () => {
	const [RouterData] = React.useContext(ModalRouterContext);

	const currentLocation = React.useMemo(() => {
		return RouterData.manager.currentUri;
	}, [RouterData.manager.currentUri]);

	return currentLocation;
};

export const useModalBack = () => {
	const [RouterData, setRouterData] = React.useContext(ModalRouterContext);
	const backFunction = React.useCallback(() => {
		RouterData.manager.back();
		setRouterData({ ...RouterData });
		// We dont want this function to cause rerenders for users when it is added as a dependency
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return backFunction;
};

export const useModalForward = () => {
	const [RouterData, setRouterData] = React.useContext(ModalRouterContext);
	const forwardFunction = React.useCallback(() => {
		RouterData.manager.forward();
		setRouterData({ ...RouterData });
		// We dont want this function to cause rerenders for users when it is added as a dependency
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return forwardFunction;
};

export const useModalNavigate = () => {
	const [RouterData, setRouterData] = React.useContext(ModalRouterContext);
	const navigateFunction = React.useCallback((uri: string) => {
		RouterData.manager.navigate(uri);
		setRouterData({ ...RouterData });
		// We dont want this function to cause rerenders for users when it is added as a dependency
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return navigateFunction;
};

const useForcableModalNavigate = () => {
	const [RouterData, setRouterData] = React.useContext(ModalRouterContext);
	const navigateFunction = React.useCallback((uri: string, forceNavigateExecute?: boolean) => {
		RouterData.manager.navigate(uri);
		if (forceNavigateExecute) {
			setRouterData({ ...RouterData, navigationAttempted: RouterData.navigationAttempted + 1 });
		} else {
			setRouterData({ ...RouterData });
		}
		// We dont want this function to cause rerenders for users when it is added as a dependency
	}, [RouterData, setRouterData]);
	return navigateFunction;
};

// Router components
export const ModalLink: React.FC<LinkProps> = (props) => {

	const { children, link, className = '', onClick } = props;

	const navigate = useForcableModalNavigate();

	const navigateFunction = React.useCallback(() => {
		if (onClick) {
			onClick();
		}

		if (link.includes('#')) {
			navigate(link, true);
		} else {
			navigate(link);
		}
	}, [link, navigate, onClick]);

	return (
		<span className={`modal-link ${className}`} onClick={navigateFunction}>
			{children}
		</span>
	);
};

const findMatchingPath = (path: string, routes: ModalRouteData[]): React.ReactElement => {

	let catchAllRoute: ModalRouteData | undefined = undefined;
	for (const route of routes) {
		let _path: string = path.split('#')[0];
		_path = _path.split('?')[0];
		if (_path === route.path) {
			return route.element;
		}
		if (_path === '*') {
			catchAllRoute = route;
		}
	}

	return catchAllRoute?.element ?? <></>;
};

export const ModalRoute: React.FC<RouteProps> = (props) => {

	const { children, propsForPath } = props;

	const sectionRef = React.useRef<HTMLElement | null>(null);
	const [RouterData] = React.useContext(ModalRouterContext);
	const modalLocation = useModalLocation();

	const sectionScrollRequest = React.useCallback(() => {
		if (!modalLocation.includes('#') || modalLocation.indexOf('#') !== modalLocation.lastIndexOf('#')) {
			return; // Only care about valid urls that have a single #
		}

		let locationData = modalLocation.split('#')[1];
		if (locationData.includes('?')) {
			locationData = modalLocation.split('?')[0];
		}

		sectionRef.current = document.getElementById(locationData);

		if (sectionRef.current) {
			sectionRef.current.scrollIntoView(true);
		}
	}, [modalLocation]);

	// navigationAttempted is used to track force navigation so multiple clicks on the same link that follow each other are executed.
	// This is needed beacause a user can click on a link that scrolls onto a section and then manually scroll away. In such scenarios we want
	// to be able to execute the scrolling once again on link click.
	React.useEffect(() => {
		window.requestAnimationFrame(sectionScrollRequest);
	}, [RouterData.navigationAttempted, sectionScrollRequest]);

	const renderElement = React.useMemo(() => {
		return React.cloneElement(findMatchingPath(modalLocation, RouterData.routes), propsForPath);
	}, [RouterData.routes, modalLocation, propsForPath]);

	return (
		<>
			{renderElement}
			{children}
		</>
	);
};

const ModalRouter: React.FC<RouterProps> = ({ children, routes }) => {

	const contextState = React.useState<ModalRouterContextData>({ manager: new ModalNavigationManager(), routes, navigationAttempted: 0 });

	return (
		<ModalRouterContext.Provider value={contextState}>
			{children}
		</ModalRouterContext.Provider>);
};

export function withModalNavigation<P extends object>(
	WrappedComponent: React.ComponentType<P>,
	routes: ModalRouteData[]
): React.FC<P> {
	const WithRouterV6: React.FC<P> = (props: P) => {

		return (
			<ModalRouter routes={routes}>
				<WrappedComponent {...props as P} />
			</ModalRouter>
		);

	};
	return WithRouterV6;
}

export default ModalRouter;
