import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

interface Props {
	disabled: boolean;
	index: number;
	removeWorkOrderResourceLookup: (index: number) => void;
}

export default class RemoveResourceLookupButton extends React.PureComponent<Props> {

	removeWorkOrderResourceLookup = () => {
		const { index, removeWorkOrderResourceLookup } = this.props;
		removeWorkOrderResourceLookup(index);
	};

	render() {
		const { disabled } = this.props;

		return !disabled && (
			<Tooltip message="Delete">
				<a
					className="btn btn--flat btn--icon"
					onClick={this.removeWorkOrderResourceLookup}
					role="button"
				>
					<span className="icon-delete" />
				</a>
			</Tooltip>
		);
	}

}
