enum Shift {
	DAY = 'Day',
	NIGHT = 'Night'
}

export const DAY_SHIFT = {
	id: 1,
	name: Shift.DAY,
};

export const NIGHT_SHIFT = {
	id: 2,
	name: Shift.NIGHT,
};

export const ShiftItems = [
	{ label: Shift.DAY, value: 1 },
	{ label: Shift.NIGHT, value: 2 },
];
