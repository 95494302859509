import QuantityUnitType, { QuantityUnitMap } from 'acceligent-shared/enums/quantityUnit';

import { MAX_BILLING_CODE_UNIT_PRICE_VALUE } from 'ab-constants/value';

class ImportBillingCodeRM {
	lineItemNumber: number;
	customerNumber?: Nullable<number>;
	customerId: string;
	ownerNumber?: Nullable<string>;
	ownerId?: Nullable<string>;
	unit: Nullable<QuantityUnitType>;
	unitPrice: string;
	bidQuantity?: Nullable<number>;
	group?: Nullable<string>;
	description: string;

	static validateRequest(request: ImportBillingCodeRM) {
		const errors = ImportBillingCodeRM.validateBillingCode(request);

		return {
			isValid: !Object.keys(errors).length,
			errors,
		};
	}

	static bulkValidateRequest(request: ImportBillingCodeRM[]) {
		const bulkValidation = ImportBillingCodeRM.bulkValidation(request);

		const singleBillingCodeValidation = request.reduce((_acc: ValidationResult[], _request, _index) => {
			const validation = ImportBillingCodeRM.validateRequest(_request);

			if (!validation.isValid) {
				_acc[_index] = validation;
			}

			if (bulkValidation.hasOwnProperty(_index)) {
				if (_acc[_index]) {
					_acc[_index].errors = { ..._acc[_index].errors, ...bulkValidation[_index].errors };
				} else {
					_acc[_index] = bulkValidation[_index];
				}
			}

			return _acc;
		}, []);

		return singleBillingCodeValidation;

	}

	public static validateBillingCode(billingCode: ImportBillingCodeRM) {
		const errors = {} as ValidationErrors;

		if (!billingCode.lineItemNumber) {
			errors.lineItemNumber = 'Line Item Number is required.';
		} else if (typeof (billingCode.lineItemNumber) !== 'number') {
			errors.lineItemNumber = 'Line Item Number must be number.';
		}

		if (billingCode.customerNumber && typeof (billingCode.customerNumber) !== 'number') {
			errors.customerNumber = 'Customer Number must be number.';
		}

		if (!billingCode.customerId) {
			errors.customerId = 'Customer ID is required.';
		}

		if (!billingCode.unit) {
			errors.unit = 'Unit is required.';
		} else if (!QuantityUnitMap[billingCode.unit.toUpperCase()]) {
			errors.unit = 'Non existing unit';
		}

		if (!billingCode.unitPrice) {
			errors.unitPrice = 'Unit Price is required.';
		} else if (+billingCode.unitPrice.split('.')[0] > MAX_BILLING_CODE_UNIT_PRICE_VALUE) {
			errors.unitPrice = `Unit Price value must be less than ${MAX_BILLING_CODE_UNIT_PRICE_VALUE}.`;
		} else if (+billingCode.unitPrice === 0) {
			errors.unitPrice = 'Unit price must be different from zero (0)';
		} else if ((billingCode.unitPrice.toString().match(/\./g) || []).length > 1) {
			errors.unitPrice = 'The period (.) should be used only to denote decimals';
		} else if ((billingCode.unitPrice.toString().match(/\,/g) || []).length) {
			errors.unitPrice = 'Comma (,) is not allowed';
		} else if ((billingCode.unitPrice.toString().match(/\s/g) || []).length) {
			errors.unitPrice = 'Space is not allowed';
		}

		if (!billingCode.description) {
			errors.description = 'Description is required.';
		}

		return errors;
	}

	static containsDuplicates(array) {
		if (array.length !== new Set(array).size) {
			return true;
		}

		return false;
	}

	static findIndexOfDuplicates(array) {
		const indexObj = {};
		let indexesOfDuplicates: number[] = [];

		for (let i = 0; i < array.length; i++) {
			const item = array[i];

			if (indexObj[item] === undefined) {
				// If the item is encountered for the first time, create an array with the current index.
				indexObj[item] = [i];
			} else {
				// If the item is already in the object, it's a duplicate.
				// Add the current index to the list of indexes for this item.
				indexObj[item].push(i);
			}
		}

		for (const item in indexObj) {
			if (indexObj[item].length > 1) {
				indexesOfDuplicates = indexObj[item];
			}
		}
		return indexesOfDuplicates;
	}

	static bulkValidation(request: ImportBillingCodeRM[]) {
		const errors = {} as ValidationErrors;

		const lineItemNumberErrors = {};
		const customerIdErrors = {};

		const lineItemNumbers = request.map(({ lineItemNumber }) => lineItemNumber);
		const customerIds = request.map(({ customerId }) => customerId);

		if (this.containsDuplicates(lineItemNumbers)) {
			const duplicates = this.findIndexOfDuplicates(lineItemNumbers);

			duplicates.forEach((duplicateIndex) => {
				lineItemNumberErrors[duplicateIndex] = {
					'lineItemNumber': 'Line item number must be unique',
				};
			});
		}

		if (this.containsDuplicates(customerIds)) {
			const duplicates = this.findIndexOfDuplicates(customerIds);

			duplicates.forEach((duplicateIndex) => {
				customerIdErrors[duplicateIndex] = {
					'customerId': 'Customer ID must be unique',
				};
			});
		}

		// Iterate through lineItemNumberErrors
		for (const key in lineItemNumberErrors) {
			if (lineItemNumberErrors.hasOwnProperty(key)) {
				if (customerIdErrors[key]) {
					// If the key exists in both lineItemNumberErrors and customerIdErrors, merge the properties
					errors[key] = { isValid: false, errors: { ...lineItemNumberErrors[key], ...customerIdErrors[key] } };
				} else {
					// If the key only exists in lineItemNumberErrors, add it to errors
					errors[key] = { isValid: false, errors: lineItemNumberErrors[key] };
				}
			}
		}

		// Iterate through customerIdErrors
		for (const key in customerIdErrors) {
			if (customerIdErrors.hasOwnProperty(key) && !errors[key]) {
				errors[key] = { isValid: false, errors: customerIdErrors[key] };
			}
		}

		return errors;
	}
}

export default ImportBillingCodeRM;
