import { USE_WILDCARDS } from 'af-constants/values';

import { getProtocol } from 'af-utils/http.util';
import { getHostname } from 'af-utils/window.util';
import { getPlatformAdminDataFromStorage, addPlatformAdminDataToStorage } from 'af-utils/localStorage.util';

const ADMIN_DATA_REQ = 'REQ admin data';

const _getPlatformOrigin = () => `${getProtocol()}://admin.${getHostname()}`;

export function createPlatformEventListenerForOrganization(orgAlias: string, organizationWindow: Nullable<Window>) {
	if (!USE_WILDCARDS) {
		throw new Error('Method createPlatformEventListenerForOrganization should not be called if USE_WILDCARDS is set to false');
	}

	const organizationOrigin = `${getProtocol()}://${orgAlias}.${getHostname()}`;
	window.addEventListener('message', (_event) => {
		if (_event.origin === organizationOrigin && _event.data === ADMIN_DATA_REQ) {
			const _data = getPlatformAdminDataFromStorage();
			if (_data) {
				organizationWindow?.postMessage(JSON.stringify(_data), organizationOrigin);
			}
		}
	});
}

export function createOrganizationEventListenerForPlatform(callback?: () => void) {
	if (!USE_WILDCARDS) {
		throw new Error('Method createOrganizationEventListenerForPlatform should not be called if USE_WILDCARDS is set to false');
	}

	const platformOrigin = _getPlatformOrigin();
	window.addEventListener('message', (_event) => {
		if (_event.origin === platformOrigin) {
			const data = JSON.parse(_event.data);
			addPlatformAdminDataToStorage(data);
			if (callback) {
				callback();
			}
		}
	});
}

export function sendAdminDataRequestToPlatform() {
	if (!USE_WILDCARDS) {
		throw new Error('Method sendAdminDataRequestToPlatform should not be called if USE_WILDCARDS is set to false');
	}

	const platformWindow: Window = window.opener as Window;
	const platformOrigin = _getPlatformOrigin();
	if (!platformWindow) {
		alert('Admin login failed! Please close this tab, refresh the organizations table page and try again.');
		return;
	}
	platformWindow.postMessage(ADMIN_DATA_REQ, platformOrigin);
}
