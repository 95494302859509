import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { CellContext, Row } from '@tanstack/react-table';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { getId } from 'acceligent-shared/utils/array';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';
import WorkSummaryStatus from 'acceligent-shared/enums/workSummaryStatus';

import { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import JobWorkSummaryVM from 'ab-viewModels/workRequest/jobWorkSummary.viewModel';
import JobWorkSummaryWorkOrderVM from 'ab-viewModels/workRequest/jobWorkSummaryWorkOrder.viewModel';
import { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as SettingsKeys from 'af-constants/settingsKeys';

import * as SettingsUtils from 'af-utils/settings.util';
import { getFullClientUrl } from 'af-utils/http.util';
import { downloadCSV } from 'af-utils/csv.utils';

import CLIENT from 'af-routes/client';

import TableNew, { TableRef } from 'af-components/Table';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import DollarCell from 'af-components/Table/Cells/DollarCell';
import DateFilter from 'af-components/DateFilter';
import LinkCell from 'af-components/Table/Cells/LinkCell';
import TextCell from 'af-components/Table/Cells/TextCell';
import ActionsCell from 'af-components/Table/Cells/ActionsCell';
import { TableProps } from 'af-components/Table/types';
import Dropdown from 'af-components/Controls/Dropdown';

import * as BillingCodeActions from 'af-actions/billingCode/index';
import * as JobActions from 'af-actions/jobs';
import * as WorkRequestActions from 'af-actions/workRequests';

import styles from './styles.module.scss';
import AddBillableWorkModal from './AddBillableWorkModal';
import TableHeader from './TableHeader';

type OwnProps = {
	jobCode: string;
	jobId: number;
	orgAlias: string;
	companyName: string;
};

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

type TableHeaderData = {
	totalRevenue: number;
};

export interface SubjobBillingCodes {
	workRequestId: number;
	billingCodes: BillingCodeVM[];
}

export interface SubjobWorkOrders {
	workRequestId: number;
	workOrders: JobWorkSummaryWorkOrderVM[];
}

type Props = OwnProps & ConnectedProps<typeof connector> & SettingProps;

const _resolveDefFieldFullValue = (value: string, name: Nullable<string>) => {
	if (!name) {
		return value;
	}

	return `${name}: ${value}`;
};

const setLocalStorageStartDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.JOB_WORK_SUMMARY_START_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStorageEndDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.JOB_WORK_SUMMARY_END_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStoragePeriod = (period: TimePeriodRecurrence) => {
	SettingsUtils.setItem(SettingsKeys.JOB_WORK_SUMMARY_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
};

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormatEnum.FULL_DATE);
};

const defaultSubjobOptions = [
	{ jobCode: 'All sub-jobs' },
];

const renderSubjob = (option: SubjobVM) => <span>{option.jobCode}</span>;

const WorkSummary: React.FC<Props> = (props) => {
	const {
		findBillingCodesForJobId,
		jobId,
		getWorkSummaryTable,
		getWorkSummaryTableCSV,
		getWorkOrdersForJobWorkSummary,
		getWorkSummaryTotalRevenue,
		companyName,
		orgAlias,
		restoreOriginalJobWorkSummary,
		jobCode,
		findAllSubjobs,
	} = props;

	const tableRef = React.useRef<TableRef<JobWorkSummaryVM>>(null);
	const [startDate, setStartDate] = React.useState(TimeUtils.positionDate(props.startDate, 'start', 'day'));
	const [endDate, setEndDate] = React.useState(TimeUtils.positionDate(props.endDate, 'end', 'day'));
	const [period, setPeriod] = React.useState(props.period);
	const [showAddBillableWorkModal, setShowAddBillableWorkModal] = React.useState(false);
	const [billingCodes, setBillingCodes] = React.useState<BillingCodeVM[]>([]);
	const [workOrders, setWorkOrders] = React.useState<JobWorkSummaryWorkOrderVM[]>([]);
	const [currentlyEditedRow, setCurrentlyEditedRow] = React.useState<Nullable<JobWorkSummaryVM>>(null);
	const [subjobs, setSubjobs] = React.useState<SubjobVM[]>([]);
	const [selectedSubjob, setSelectedSubjob] = React.useState<Partial<SubjobVM>>(defaultSubjobOptions[0]);
	const [subjobBillingCodes, setSubjobBillingCodes] = React.useState<SubjobBillingCodes[]>([]);
	const [subjobWorkOrders, setSubjobWorkOrders] = React.useState<SubjobWorkOrders[]>([]);

	const subjobFilter = React.useMemo(() => {
		return selectedSubjob.jobCode === defaultSubjobOptions[0].jobCode
			? subjobs.map(getId)
			: [(selectedSubjob as SubjobVM).id];
	}, [subjobs, selectedSubjob]);

	const isProject = React.useMemo(() => {
		return !!subjobs.length;
	}, [subjobs]);

	const openAddBillableWorkModal = React.useCallback(async () => {
		setShowAddBillableWorkModal(true);
	}, []);

	const closeAddBillableWorkModal = React.useCallback(() => {
		setShowAddBillableWorkModal(false);
		setCurrentlyEditedRow(null);
	}, []);

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableViewModel<JobWorkSummaryVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;

		const result = await getWorkSummaryTable(
			jobId,
			new TableQuery({ page, pageSize, sortBy, filterByText }),
			startDate,
			endDate,
			subjobFilter
		);
		return result;
	}, [getWorkSummaryTable, jobId, startDate, endDate, subjobFilter]);

	const fetchRevenueTotal = React.useCallback(async () => {
		const totalRevenue = await getWorkSummaryTotalRevenue(jobId, subjobFilter);
		return { totalRevenue };
	}, [getWorkSummaryTotalRevenue, jobId, subjobFilter]);

	const renderTableHeader = React.useCallback((tableHeaderData: TableHeaderData) => {
		return (
			<TableHeader
				totalRevenue={tableHeaderData.totalRevenue}
			/>
		);
	}, []);

	const onRowEdit = React.useCallback((_jws: JobWorkSummaryVM) => () => {
		setCurrentlyEditedRow(_jws);
		openAddBillableWorkModal();
	}, [openAddBillableWorkModal]);

	const onRestoreOriginalClick = React.useCallback((_jws: JobWorkSummaryVM, _jobId) => {
		return async () => {
			await restoreOriginalJobWorkSummary(_jobId, _jws.id);
			tableRef.current?.refreshTable();
		};
	}, [restoreOriginalJobWorkSummary]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<JobWorkSummaryVM, unknown>) => {
		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={[
					{ onClick: onRowEdit(_cell.row.original), label: 'Edit' },
					{ onClick: onRestoreOriginalClick(_cell.row.original, jobId), label: 'Restore Original Data', disabled: !_cell.row.original.originalData }]
				}
				valueKey="label"
			/>
		);
	}, [jobId, onRestoreOriginalClick, onRowEdit]);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, _selected: Date) => {
		let _startDate: Date = _selected, _endDate: Date = _selected;

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'month'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'week'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				_startDate = TimeUtils.positionDate(_selected, 'start', 'day');
				_endDate = TimeUtils.positionDate(_selected, 'end', 'day');
			default:
				break;
		}

		tableRef.current?.resetPagination();
		setStartDate(_startDate);
		setEndDate(_endDate);
		setPeriod(_period);
	}, []);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		tableRef.current?.resetPagination();
		setStartDate(TimeUtils.positionDate(_startDate, 'start', 'day'));
		setEndDate(TimeUtils.positionDate(_endDate, 'end', 'day'));
	}, []);

	const onSubjobFilterChange = React.useCallback((option: Partial<SubjobVM>) => {
		setSelectedSubjob(option);
	}, []);

	const renderAdditionalFilter = React.useCallback(() => {
		return (
			<>
				<DateFilter
					changePeriod={changePeriod}
					endDate={endDate}
					onChange={filterByDate}
					period={period}
					startDate={startDate}
				/>
				{
					isProject &&
					process.env.FTD_PROJECT !== 'true' &&
					<>
						<Dropdown<Partial<SubjobVM>>
							defaultValue={defaultSubjobOptions[0]}
							filterable={false}
							forcePlaceholder={false}
							isWhite={true}
							onValueChange={onSubjobFilterChange}
							options={[...defaultSubjobOptions, ...subjobs]}
							renderMenuItem={renderSubjob}
							valueKey="jobCode"
							withBorder={true}
							withCaret={true}
						/>
					</>
				}

			</>

		);
	}, [changePeriod, endDate, filterByDate, isProject, onSubjobFilterChange, period, startDate, subjobs]);

	const renderFirstSubRowCell = React.useCallback(() => {
		return (
			<TextCell className={styles['job-work-summary__sub-row-cell']} value="Original Data" />
		);
	}, []);

	const renderTextCell = React.useCallback((isSubRowCell: boolean) => (cell) => {
		const className: string[] = [];
		isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);

		if (isSubRowCell) {
			return (
				<TextCell className={styles['job-work-summary__sub-row-cell']} value={cell.getValue()} />
			);
		}

		return <TextCell value={cell.getValue()} />;
	}, []);

	const renderDateCell = React.useCallback((isSubRowCell: boolean) => (cell) => {
		const className: string[] = [];
		isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);

		const dbDate = cell.getValue() as string;

		const date = TimeUtils.formatDate(dbDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

		if (isSubRowCell) {
			return (
				<TextCell className={styles['job-work-summary__sub-row-cell']} value={date} />
			);
		}

		return <TextCell value={date} />;
	}, []);

	const renderUnitPriceCell = React.useCallback((isSubRow: boolean) => (cell) => {
		const className = isSubRow ? styles['job-work-summary__sub-row-cell'] : undefined;
		if (!cell.getValue()) {
			return null;
		}

		return (
			<DollarCell className={className} isUnitPrice={true} value={cell.getValue()} />
		);
	}, []);

	const renderLinkCell = React.useCallback((isSubRowCell: boolean) =>
		(_cell: CellContext<JobWorkSummaryVM, unknown>) => {
			const className: string[] = [];
			isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);

			if (!_cell.cell.row.original.workOrderCode) {
				className.push(styles['job-work-summary__empty-cell']);
				return (
					<span className={className.join(' ')}>
						N/A
					</span>
				);
			}

			const path = _cell.row.original.workOrderId
				? getFullClientUrl(orgAlias, CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(`${_cell.row.original.workOrderId}`, orgAlias, companyName))
				: '';

			return <LinkCell label={_cell.row.original.workOrderCode!} labelClassName={className.join(' ')} path={path} />;
		}, [companyName, orgAlias]);

	const renderDefinitionOrInfoField = React.useCallback((isSubRowCell: boolean) => (cell) => {
		const className: string[] = [];
		isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);
		return (
			<TextCell className={className.join('')} value={cell.renderValue()} />
		);
	}, []);

	const renderBillingCode = React.useCallback((isSubRowCell: boolean) => (_cell: CellContext<JobWorkSummaryVM, unknown>) => {
		const className: string[] = [];
		isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);

		if (!_cell.getValue<string>()) {
			className.push(styles['job-work-summary__no-billing-code']);
			return (
				<span className={className.join(' ')}>
					No Billing Code
				</span>
			);
		}

		return <TextCell className={className.join(' ')} value={_cell.getValue<string>()} />;
	}, []);

	const renderRevenueCell = React.useCallback((isSubRowCell: boolean) => (_cell: CellContext<JobWorkSummaryVM, unknown>) => {
		const className: string[] = [];
		isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);
		if (_cell.getValue() === null) {
			className.push(styles['job-work-summary__empty-cell']);
			className.push(styles['job-work-summary__empty-cell--right-aligned']);
			return (
				<span className={className.join(' ')}>
					N/A
				</span>
			);
		}

		return <DollarCell className={className.join(' ')} value={_cell.getValue<JobWorkSummaryVM['revenue']>() ?? 0} />;
	}, []);

	const renderWorkSummaryStatusCell = React.useCallback((isSubRow: boolean) => (_cell: CellContext<JobWorkSummaryVM, unknown>) => {
		const cellStyle: string[] = [];
		cellStyle.push(styles['job-work-summary__review-status-cell']);
		isSubRow && cellStyle.push(styles['job-work-summary__review-status-cell--sub-row-cell']);

		switch (_cell.cell.row.original.reviewStatus) {
			case WorkSummaryStatus.DRAFT: {
				cellStyle.push(styles['job-work-summary__review-status-cell--draft']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-dot" />
						<b>Draft</b>
					</span>
				);
			}
			case WorkSummaryStatus.IN_PROGRESS: {
				cellStyle.push(styles['job-work-summary__review-status-cell--in-progress']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-dot" />
						<b>In Progress</b>
					</span>
				);
			}
			case WorkSummaryStatus.OUTDATED: {
				cellStyle.push(styles['job-work-summary__review-status-cell--outdated']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-dot_outline" />
						<b>Outdated</b>
					</span>
				);
			}
			case WorkSummaryStatus.REVIEWED: {
				cellStyle.push(styles['job-work-summary__review-status-cell--reviewed']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-dot" />
						<b>Reviewed</b>
					</span>
				);
			}
			case WorkSummaryStatus.COMPLETED: {
				cellStyle.push(styles['job-work-summary__review-status-cell--completed']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-dot" />
						<b>Completed</b>
					</span>
				);
			}
			default: {
				cellStyle.push(styles['job-work-summary__empty-cell']);
				return (
					<span className={cellStyle.join(' ')}>
						N/A
					</span>
				);
			}
		}
	}, []);

	const getSubRows = React.useCallback((_originalRow) => {
		if (!!_originalRow.originalData) {
			return [_originalRow.originalData];
		} else {
			return [];
		}
	}, []);

	const onRowClick = React.useCallback((_row: Row<JobWorkSummaryVM>) => {
		setCurrentlyEditedRow(_row.original);
		openAddBillableWorkModal();
	}, [openAddBillableWorkModal]);

	const fetchAndInitializeData = React.useCallback(async () => {
		const fetchedBillingCodes = await findBillingCodesForJobId(jobId);
		const fetchedWorkOrders = await getWorkOrdersForJobWorkSummary(jobId);

		setBillingCodes(fetchedBillingCodes);
		setWorkOrders(fetchedWorkOrders);
	}, [findBillingCodesForJobId, getWorkOrdersForJobWorkSummary, jobId]);

	const refreshTable = React.useCallback(() => {
		tableRef.current?.refreshTable();
	}, []);

	const onExportWorkSummaryClick = React.useCallback(async () => {
		const result = await getWorkSummaryTableCSV(jobId, startDate, endDate, subjobFilter);
		const csvData = JobWorkSummaryVM.toCSVData(result);
		downloadCSV(csvData, `${jobCode}_work_summary.csv`);
	}, [getWorkSummaryTableCSV, jobId, startDate, endDate, subjobFilter, jobCode]);

	const buttons = React.useMemo(() => [
		{ label: 'Add Billable Work', icon: 'icon-plus', type: TableButtonType.LINK, hasPermission: true, onClick: openAddBillableWorkModal },
		{ label: 'Export Work Summary', icon: 'icon-download', type: TableButtonType.LINK, hasPermission: true, onClick: onExportWorkSummaryClick },
	], [onExportWorkSummaryClick, openAddBillableWorkModal]);

	const fetchSubJobs = React.useCallback(async () => {
		const _subjobs = await findAllSubjobs(jobId);
		setSubjobs(_subjobs);
		const billingCodeMap: SubjobBillingCodes[] = [];
		const workOrderMap: SubjobWorkOrders[] = [];
		for (const _subjob of _subjobs) {
			const _billingCodes = await findBillingCodesForJobId(_subjob.id);
			const _workOrders = await getWorkOrdersForJobWorkSummary(_subjob.id);
			billingCodeMap.push({ workRequestId: _subjob.id, billingCodes: _billingCodes });
			workOrderMap.push({ workRequestId: _subjob.id, workOrders: _workOrders });
		}
		setSubjobBillingCodes(billingCodeMap);
		setSubjobWorkOrders(workOrderMap);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [findAllSubjobs, findBillingCodesForJobId, jobId]);

	React.useEffect(() => {
		fetchSubJobs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isJobColumnHidden = React.useMemo(() => { return !isProject || process.env.FTD_PROJECT === 'true'; }, [isProject]);

	const columns: TableProps<JobWorkSummaryVM>['columns'] = React.useMemo(() => [
		{
			id: 'actions',
			isDisplayColumn: true,
			isFixed: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
			subRowColumn: {
				cell: renderFirstSubRowCell,
			},
			size: 100,
		},
		{
			id: 'customerId',
			accessor: 'customerId',
			header: 'Billing Code',
			cell: renderBillingCode(false),
			isFixed: true,
			subRowColumn: {
				cell: renderBillingCode(true),
			},
			enableSorting: true,
			size: 150,
		},
		{
			id: 'workRequest',
			accessor: 'workRequest',
			header: 'Job Name',
			cell: renderTextCell(false),
			isFixed: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
			enableSorting: true,
			size: isJobColumnHidden ? 0 : 150,
			enableHiding: true,
			isHidden: isJobColumnHidden,
		},
		{
			id: 'revenue',
			accessor: 'revenue',
			header: 'Revenue',
			cell: renderRevenueCell(false),
			isFixed: true,
			subRowColumn: {
				cell: renderRevenueCell(true),
			},
			enableSorting: true,
			size: 100,
		},
		{
			id: 'workOrder',
			accessor: 'workOrderCode',
			header: 'Work Order',
			cell: renderLinkCell(false),
			enableSorting: true,
			size: 250,
			subRowColumn: {
				cell: renderLinkCell(true),
			},
		},
		{
			id: 'startDate',
			accessor: 'startDate',
			header: 'Start Date',
			cell: renderDateCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDateCell(true),
			},
		},
		{
			id: 'reviewStatus',
			accessor: 'reviewStatus',
			header: 'Review Status',
			cell: renderWorkSummaryStatusCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderWorkSummaryStatusCell(true),
			},
		},
		{
			id: 'invoiceId',
			accessor: 'invoiceId',
			header: 'Invoice ID',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'work',
			accessor: 'work',
			header: 'Work',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'type',
			accessor: (_originalRow: JobWorkSummaryVM) =>
				_originalRow.type === _originalRow.typeValue
					? _originalRow.type
					: `${_originalRow.type}: ${_originalRow.typeValue}`,
			header: 'Type',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'defField1',
			accessor: (_originalRow: JobWorkSummaryVM) =>
				_originalRow.definitionField1Value ? _resolveDefFieldFullValue(_originalRow.definitionField1Value, _originalRow.definitionField1Name) : null,
			header: 'Definition field 1',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'defField2',
			accessor: (_originalRow: JobWorkSummaryVM) =>
				_originalRow.definitionField2Value ? _resolveDefFieldFullValue(_originalRow.definitionField2Value, _originalRow.definitionField2Name) : null,
			header: 'Definition field 2',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'defField3',
			accessor: (_originalRow: JobWorkSummaryVM) =>
				_originalRow.definitionField3Value ? _resolveDefFieldFullValue(_originalRow.definitionField3Value, _originalRow.definitionField3Name) : null,
			header: 'Definition field 3',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'defField4',
			accessor: (_originalRow: JobWorkSummaryVM) =>
				_originalRow.definitionField4Value ? _resolveDefFieldFullValue(_originalRow.definitionField4Value, _originalRow.definitionField4Name) : null,
			header: 'Definition field 4',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'infoField1',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.informationField1Value,
			header: 'Informational field 1',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'infoField2',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.informationField2Value,
			header: 'Informational field 2',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'infoField3',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.informationField3Value,
			header: 'Informational field 3',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'infoField4',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.informationField4Value,
			header: 'Informational field 4',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'quantity',
			accessor: 'quantity',
			header: 'Quantity',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'unit',
			accessor: 'unit',
			header: 'Unit',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'group',
			accessor: 'group',
			header: 'Group',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'unitPrice',
			accessor: 'unitPrice',
			header: 'Unit Price',
			cell: renderUnitPriceCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderUnitPriceCell(true),
			},
		},
		{
			id: 'description',
			accessor: 'description',
			header: 'Description',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
	], [resolveActionsButton,
		renderFirstSubRowCell,
		renderBillingCode,
		renderRevenueCell,
		renderLinkCell,
		renderTextCell,
		renderWorkSummaryStatusCell,
		renderDefinitionOrInfoField,
		renderUnitPriceCell,
		renderDateCell,
		isJobColumnHidden,
	]);

	React.useEffect(() => {
		fetchAndInitializeData();
	}, [fetchAndInitializeData]);

	React.useEffect(() => {
		startDate && setLocalStorageStartDate(startDate);
		endDate && setLocalStorageEndDate(endDate);
		period && setLocalStoragePeriod(period);
	}, [startDate, endDate, period]);

	return (
		<>
			<TableNew
				additionalFilter={renderAdditionalFilter}
				buttons={buttons}
				columns={columns}
				fetch={fetchRows}
				fetchTableHeaderData={fetchRevenueTotal}
				getSubRows={getSubRows}
				hasSearchInput={true}
				hasSubRows={true}
				onRowClick={onRowClick}
				ref={tableRef}
				renderTableHeader={renderTableHeader}
				selectable={true}
				tableBodyClassName={styles['work-summary-table']}
				tableName={TableNameEnum.JOB_WORK_SUMMARY}
			/>
			<AddBillableWorkModal
				billingCodes={billingCodes}
				closeModal={closeAddBillableWorkModal}
				currentlyEditedRow={currentlyEditedRow}
				isProject={isProject}
				jobId={jobId}
				refreshTable={refreshTable}
				showModal={showAddBillableWorkModal}
				subjobBillingCodes={subjobBillingCodes}
				subjobs={subjobs}
				subjobWorkOrders={subjobWorkOrders}
				workOrders={workOrders}
			/>
		</>
	);
};

function mapDispatchToProps() {
	return {
		findBillingCodesForJobId: BillingCodeActions.findForJobId,
		getWorkSummaryTable: JobActions.getWorkSummaryTable,
		getWorkSummaryTableCSV: JobActions.getWorkSummaryTableCSV,
		getWorkOrdersForJobWorkSummary: JobActions.getWorkOrdersForJobWorkSummary,
		getWorkSummaryTotalRevenue: JobActions.getWorkSummaryTotalRevenue,
		restoreOriginalJobWorkSummary: JobActions.restoreOriginalJobWorkSummary,
		findAllSubjobs: WorkRequestActions.findAllSubjobs,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.JOB_WORK_SUMMARY_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.JOB_WORK_SUMMARY_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.JOB_WORK_SUMMARY_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(WorkSummary);
