import { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import VendorVM from 'ab-viewModels/vendor/vendor.viewModel';

import VendorRM from 'ab-requestModels/vendor/vendor.requestModel';

import { GetRootState } from 'af-reducers';

export function create(form: VendorRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<VendorVM>(API.V1.RESOURCES.VENDOR.CREATE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<VendorVM[]>(API.V1.RESOURCES.VENDOR.FIND_LIST);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
