import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import * as CrewTypeActions from 'af-actions/crewType';

import { GroupedCrewTypes, CrewTypeViewModel } from 'ab-viewModels/crewType.viewModel';

import CrewTypeRequestModel from 'ab-requestModels/crewType.requestModel';

import Loading from './Loading';
import CrewTypeItem from '../CrewTypeItem';
import CrewTypeCreate from '../CrewTypeCreate';

import Breadcrumbs from 'af-components/Breadcrumbs';

import { bemElement, bemBlock } from 'ab-utils/bem.util';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	crewTypes: Nullable<GroupedCrewTypes>;
	addingCrewType: boolean;
	editingId: Nullable<number>;
}

class CrewTypes extends React.PureComponent<Props, State> {
	state: State = {
		addingCrewType: false,
		editingId: null,
		crewTypes: null,
	};

	async componentDidMount() {
		await this.load();
	}

	toggleAddingCrewType = () => this.setState(() => ({ addingCrewType: !this.state.addingCrewType }));

	toggleEditingCrewType = (id: number) => this.setState(() => ({ editingId: id }));

	load = async () => {
		const { findAllForCompany } = this.props;
		const crewTypes = await findAllForCompany();
		this.setState(() => ({ crewTypes }));
	};

	create = async (form: CrewTypeRequestModel) => {
		const { create } = this.props;
		await create(form);
		await this.load();
	};

	update = async (id: number, form: CrewTypeRequestModel) => {
		const { update } = this.props;
		await update(id, form);
		await this.load();
	};

	remove = async (id: number) => {
		const { deleteCrewType } = this.props;
		await deleteCrewType(id);
		await this.load();
	};

	render() {
		const { addingCrewType, editingId, crewTypes } = this.state;

		if (!crewTypes) {
			return <Loading />;
		}

		const filteredCrewTypeKeys = Object.keys(crewTypes).filter((_ct) => !!crewTypes[_ct].length);

		return (
			<div className="form-segment resources-pilled">
				<Breadcrumbs items={[{ label: 'Crew Types' }]} />
				<div className={bemBlock('form-box', ['full-padded'])}>
					{!filteredCrewTypeKeys.length
						? <div className={bemElement('resources-pilled', 'empty')}>There are no crew types available.</div>
						: <div className={bemElement('resources-pilled', 'list')}>
							{filteredCrewTypeKeys.map((crewTypeColor, _index) =>
								<div
									className={bemElement('resources-pilled', 'color-list')}
									key={_index}
								>
									{crewTypes[crewTypeColor].map((_ct: CrewTypeViewModel, _i) => (
										<CrewTypeItem
											crewType={_ct}
											deleteCrewType={this.remove}
											editingId={editingId}
											index={_index}
											key={`crew_type_${crewTypeColor}-${_i}`}
											toggleEditingCrewType={this.toggleEditingCrewType}
											updateCrewType={this.update}
										/>
									))}
								</div>
							)}
						</div>
					}
					<div className={bemElement('resources-pilled', 'addition')}>
						{
							addingCrewType ?
								<CrewTypeCreate close={this.toggleAddingCrewType} createCrewType={this.create} /> :
								<span className={bemBlock('btn', ['link'])} onClick={this.toggleAddingCrewType}>
									<span className="icon-plus" />
									<span>Add Crew Type</span>
								</span>
						}
					</div>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		create: CrewTypeActions.create,
		deleteCrewType: CrewTypeActions.deleteCrewType,
		update: CrewTypeActions.update,
		findAllForCompany: CrewTypeActions.findAllForCompany,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(CrewTypes);
