import * as ActionTypes from 'af-constants/actionTypes';

import { ReportBlockAction } from 'af-actions/reportBlock';

import { ReportBlockStoreState } from 'af-models/reportBlock.models';

const initialState = {
	newReportBlock: undefined,
	fields: undefined,
};
export default function ReportBlockReducer(state: ReportBlockStoreState = initialState, action: ReportBlockAction) {
	switch (action.type) {
		case ActionTypes.SET_NEW_REPORT_BLOCK:
			return { ...state, newReportBlock: action.reportBlock, fields: action.fields };
		default:
			return state;
	}
}
