import * as React from 'react';
import { Link } from 'react-router-dom';

import CLIENT from 'af-routes/client';

const ForgotOrganizationSuccess: React.FC = () => (
	<div className="form-segment form-segment--mini">
		<div className="form-box form-box--standalone form-box--padded">
			<div className="form-box__icon-container">
				<span className="form-box__icon form-box__icon--success icon-check" />
			</div>
			<h2>Instructions Sent</h2>
			<h4>We have sent an email to your address!</h4>
			<h4>Please follow the instructions You have received.</h4>
			<div className="form-box__footer">
				<Link
					className="btn btn-primary"
					to={CLIENT.APP.ORGANIZATIONS}
				>
					Back
				</Link>
			</div>
		</div>
	</div>
);

export default React.memo(ForgotOrganizationSuccess);
