import * as React from 'react';

import { HOLD_END_DELAY, PRESS_AND_HOLD_DELAY } from 'ab-constants/value';

interface Props {
	onHoldEnd?: () => void;
	regularAnchor?: boolean;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
	children: (isHolding?: boolean, holdFinished?: boolean) => React.ReactNode;
	onRelease?: () => void;
}

export default class PressAndHoldAnchor extends React.Component<Props> {
	// timer which indicates when will holdTimer start
	// pressAndHoldTimer indicates how long user has to hold a button
	// to start an action (in our case, copy action)
	_pressAndHoldTimer: Nullable<NodeJS.Timeout> = null;

	// hold timer triggers onHoldEnd action after its timeout
	// in our case, we use it for copying, so, when hold timer expires
	// order is copied
	_holdTimer: Nullable<NodeJS.Timeout> = null;

	state = {
		isHolding: false,
		holdFinished: false,
	};

	onPress = () => {
		const { onHoldEnd } = this.props;
		this._pressAndHoldTimer = setTimeout(() => {
			this.setState(() => ({
				isHolding: true,
				holdFinished: false,
			}), () => {
				this._holdTimer = setTimeout(() => {
					this.setState(() => ({
						isHolding: false,
						holdFinished: true,
					}), () => {
						onHoldEnd?.();
					});
				}, HOLD_END_DELAY);
			});
		}, PRESS_AND_HOLD_DELAY);
	};

	onRelease = () => {
		const { onRelease } = this.props;
		const { isHolding } = this.state;

		if (isHolding) {
			this.setState(() => ({ isHolding: false }), () => {
				this.clearTimer();
				if (onRelease) {
					onRelease();
				}
			});
		} else {
			this.clearTimer();
			if (onRelease) {
				onRelease();
			}
		}

	};

	onMove = () => {
		const { isHolding } = this.state;
		if (isHolding) {
			this.setState(() => ({ isHolding: false }), this.clearTimer);
		} else {
			this.clearTimer();
		}
	};

	clearTimer = () => {
		const { holdFinished } = this.state;

		if (this._pressAndHoldTimer) {
			clearTimeout(this._pressAndHoldTimer);
		}

		if (this._holdTimer) {
			clearTimeout(this._holdTimer);
		}

		if (holdFinished) {
			this.setState({ holdFinished: false });
		}
	};

	render() {
		const { onClick, children, regularAnchor } = this.props;
		const { isHolding, holdFinished } = this.state;

		if (regularAnchor) {
			return (
				<a
					onClick={onClick}
					role="button"
				>
					{children()}
				</a>
			);
		}

		return (
			<a
				className="press-and-hold-anchor"
				onClick={onClick}
				onMouseDown={this.onPress}
				onMouseMove={this.onMove}
				onMouseUp={this.onRelease}
				onTouchEnd={this.onRelease}
				onTouchMove={this.onMove}
				onTouchStart={this.onPress}
				role="button"
			>
				{children(isHolding, holdFinished)}
			</a>
		);
	}
}
