import CreateEquipmentPlaceholderForm from 'ab-requestModels/scheduleBoardCreateEquipmentPlaceholder.requestModel';

export function validate(values: CreateEquipmentPlaceholderForm) {
	const errors: ValidationErrors = {};

	if (!values.equipmentCostId) {
		errors.equipmentCostId = 'Equipment Cost is required';
	}

	return errors;
}
