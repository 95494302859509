import { DeliverableRequestModel } from 'ab-requestModels/deliverable.requestModel';

export const validate = (form: DeliverableRequestModel) => {
	const errors: ValidationErrors = {};

	if (!form.statusId) {
		errors.statusId = 'Status is required';
	} else if (!form.status.isJobStatus) {
		errors.statusId = 'Invalid Status selected.';
	}

	if (!form.deliverableAssigneeId) {
		errors.deliverableAssigneeId = 'Assignee is required';
	}

	return errors;
};
