import * as React from 'react';
import { Accept, FileError, FileRejection, useDropzone } from 'react-dropzone';

import styles from './styles.module.scss';

interface OwnProps {
	onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
	allowedTypes?: Accept;
	className?: string;
	disabled?: boolean;
	style?: React.CSSProperties;
	noClick?: boolean;
	noKeyboard?: boolean;
	noDrag?: boolean;
	errorText?: FileError[];
}

type Props = React.PropsWithChildren<OwnProps>;

const DropzoneComponent = (props: Props) => {
	const { children, allowedTypes, noClick, noKeyboard, noDrag, onDrop, style, className } = props;
	const {
		getRootProps,
		getInputProps,
	} = useDropzone({
		accept: allowedTypes,
		noClick,
		noKeyboard,
		noDrag,
		onDrop,
	});

	return (
		<section>
			<div {...getRootProps({ className: className ?? `${styles.dropzoneElementStyle}`, style: style })}>
				<input {...getInputProps()} />
				{children}
			</div>
		</section>
	);
};

export default DropzoneComponent;
