import * as React from 'react';

import { DisplayViewEquipmentViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import * as ColorUtils from 'ab-utils/color.util';

import DownEquipmentBadge from '../../../Shared/DownEquipmentBadge';
import MultiAssignmentsBadge from '../../../Shared/MultiAssignmentsBadge';

interface OwnProps {
	equipment: DisplayViewEquipmentViewModel;
	hideMultipleAssignmentBadges: boolean;
}

type Props = OwnProps;

const AvailableEquipmentItem: React.FC<Props> = (props: Props) => {
	const { equipment, hideMultipleAssignmentBadges } = props;
	const { isAvailable, specification, code, color, numberOfAssignments } = equipment;

	const showMultipleAssignments = isAvailable && numberOfAssignments > 1 && !hideMultipleAssignmentBadges;

	return (
		<div className={`sb-resource-item sb-resource-item--equipment ${ColorUtils.getColorBackgroundClass(color)}`}>
			<div className="sb-resource-item__content">
				<span className="sb-resource-item__full-name sb-resource-item__full-name--transparent-bg">{code}</span>
				{specification && <span className="sb-resource-item__specification">{specification}</span>}
			</div>
			{!isAvailable && <DownEquipmentBadge />}
			{showMultipleAssignments && <MultiAssignmentsBadge count={numberOfAssignments} />}
		</div>
	);
};

export default React.memo(AvailableEquipmentItem);
