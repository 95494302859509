import * as React from 'react';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';

import SettingPreview from '../../Shared/SettingPreview';
import PreviewItem from '../../Shared/PreviewItem';

type Props = {
	areWorkRequestsEnabled: boolean;
	toggleEdit: () => void;
};

const EnableWorkRequestsCompanyPreview: React.FC<Props> = ({ areWorkRequestsEnabled, toggleEdit }) => {
	return (
		<SettingPreview toggleEdit={toggleEdit}>
			<div className="company-settings__info-segment">
				<PreviewItem
					checked={!!areWorkRequestsEnabled}
					hint={CompanyFeatureFlagsTips.workRequests}
					indent={false}
					label={CompanyFeatureFlagsEnum.areWorkRequestsEnabled}
					showCheckbox={true}
				/>
			</div>
		</SettingPreview>
	);
};

export default React.memo(EnableWorkRequestsCompanyPreview);
