import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import EmailPreview from 'af-components/ContactMethodPreview/EmailPreview';

interface EmailState {
	value: string;
	isNew: boolean;
}

interface OwnProps {
	selectedContactEmails: ContactVM['phones'];
	unsavedContactEmail: string;
}

type Props = OwnProps;

interface State {
	emails: EmailState[];
}

class EmailList extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		selectedContactEmails: [],
	};
	state: State = {
		emails: [],
	};

	static getDerivedStateFromProps(props: Props): State {
		const { selectedContactEmails, unsavedContactEmail } = props;

		const emails: EmailState[] = [];
		let isEmailDuplicate = false;

		selectedContactEmails.forEach((_email) => {
			if (_email.value  === unsavedContactEmail) {
				isEmailDuplicate = true;
			}
			emails.push({
				value: _email.value,
				isNew: false,
			});
		});

		if (unsavedContactEmail && !isEmailDuplicate) {
			emails.push({
				value: unsavedContactEmail,
				isNew: !!selectedContactEmails.length,
			});
		}

		return { emails };
	}

	render() {
		const { emails } = this.state;

		if (!emails.length) {
			return null;
		}

		return (
			<>
				<Row>
					<Col sm={24}>Email</Col>
				</Row>
				<Row className="row--padded-bottom">
					{emails.map((_email, _index) => (
						<Col key={_index} sm={24}>
							<EmailPreview isNew={_email.isNew} value={_email.value} />
						</Col>
					))}
				</Row>
			</>
		);
	}
}

export default EmailList;
