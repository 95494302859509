import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import { EquipmentRequestModel } from 'ab-requestModels/equipment.requestModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { RootState } from 'af-reducers';

import * as EquipmentActions from 'af-actions/equipment';
import * as WorkOrderActions from 'af-actions/workOrder';

import CLIENT from 'af-constants/routes/client';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-constants/value';
import { AVAILABLE_EQUIPMENT_STATUS } from 'ab-constants/equipment';

import TableNameEnum from 'ab-enums/tableName.enum';

import ImageTag from 'af-components/Image';
import LabelWithPills from 'af-components/LockedPillsValue';
import LockedValue from 'af-components/LockedValue';
import Breadcrumbs from 'af-components/Breadcrumbs';
import StatusLabel from 'af-components/StatusLabel';
import SegmentLabel from 'af-components/SegmentLabel';

import ResourcePreview from 'af-root/scenes/Company/Resources/Shared/ResourcePreview';
import RelatedWorkOrders from 'af-root/scenes/Company/Resources/Shared/RelatedWorkOrders';

import Loading from './Loading';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

interface State {
	equipment: Nullable<EquipmentRequestModel>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

class Preview extends React.Component<Props, State> {

	state: State = {
		equipment: null,
	};

	async componentDidMount() {
		const { findById, match: { params: { id } } } = this.props;

		const equipment = await findById(id);
		this.setState(() => ({ equipment }));
	}

	fetchWorkOrders = async (tableRequestModel: TableQuery, startDate: Date, endDate: Date) => {
		const { findWorkOrdersByEquipmentId, match: { params: { id } } } = this.props;
		return await findWorkOrdersByEquipmentId(+id, tableRequestModel, startDate, endDate);
	};

	render() {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		const { equipment } = this.state;

		const breadcrumbs = [
			{ label: 'Equipment', url: CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName) },
			{ label: 'Preview' },
		];

		return (
			<>
				{equipment ?
					<ResourcePreview
						backToListLabel="Back to Equipment List"
						backToListUrl={CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName)}
						breadcrumbs={breadcrumbs}
						editUrl={CLIENT.COMPANY.RESOURCES.EQUIPMENT.EDIT(equipment.id?.toString?.(), orgAlias, companyName)}
					>
						<Row className="row row--flex">
							<div className="avatar__container avatar__container--double">
								<div className="avatar">
									<ImageTag
										fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
										minSize={BlobStorageImageSizeContainer.SIZE_200X200}
										src={equipment.frontImageUrl as string}
										tryOriginal={true}
										tryRoot={true}
									/>
								</div>
								<div className="avatar">
									<ImageTag
										fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
										minSize={BlobStorageImageSizeContainer.SIZE_200X200}
										src={equipment.backImageUrl as string}
										tryOriginal={true}
										tryRoot={true}
									/>
								</div>
							</div>
							<div className="avatar__neighbour">
								<Row className="row--padded">
									<Col md={8}><LockedValue defaultValue="N/A" label="Equipment ID" value={equipment.code} /></Col>
									<Col md={8}><LockedValue defaultValue="N/A" label="Equipment Specification" value={equipment.specification} /></Col>
									<Col md={8}>
										<LockedValue
											defaultValue="N/A"
											label="Current Status"
											value={
												<StatusLabel
													isAvailable={!!equipment?.isStatusAvailable}
													label={equipment.equipmentStatusName ?? AVAILABLE_EQUIPMENT_STATUS}
												/>
											}
										/>
									</Col>
								</Row>
								<Row className="row--padded-bottom">
									<Col md={8}><LockedValue defaultValue="N/A" label="Equipment Cost" value={equipment.equipmentCost?.subcategory} /></Col>
									<Col md={8}><LockedValue defaultValue="N/A" label="Location" value={equipment?.location?.nickname} /></Col>
									<Col md={8} />
								</Row>
							</div>
						</Row>
						<Row>
							<Col sm={24}>
								<SegmentLabel label="Contacts" />
							</Col>
						</Row>
						<Row>
							<Col md={6}><LockedValue defaultValue="N/A" label="First Contact" value={equipment.primaryContact} /></Col>
							<Col md={6}><LockedValue defaultValue="N/A" label="Second Contact" value={equipment.secondaryContact} /></Col>
							<Col md={12} />
						</Row>
						<Row>
							<Col sm={24}>
								<SegmentLabel label="Skills and Licenses" />
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<LabelWithPills
									colorKey="color"
									items={equipment.skills}
									label="Skills"
									labelKey="name"
								/>
							</Col>
						</Row>
						<Row className="row--padded">
							<Col md={24}>
								<LabelWithPills
									colorKey="color"
									items={equipment.equipmentCost?.skills ?? []}
									label="Skills Defined by Equipment Cost"
									labelKey="name"
								/>
							</Col>
						</Row>
						<Row>
							<Col md={24}><LockedValue defaultValue="N/A" label="Licenses" value={equipment.licenses} /></Col>
						</Row>
					</ResourcePreview> :
					<Loading />
				}
				<div className="form-segment">
					<Breadcrumbs items={[{ label: 'Related Work Orders' }]} />
					<RelatedWorkOrders
						companyName={companyName}
						csvName={`${companyName}_${equipment?.code}_work_orders.csv`}
						fetchWorkOrders={this.fetchWorkOrders}
						history={history}
						orgAlias={orgAlias}
						tableName={TableNameEnum.EQUIPMENT_WORK_ORDERS}
					/>
				</div>
			</>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findById: EquipmentActions.findById,
		findWorkOrdersByEquipmentId: WorkOrderActions.findWorkOrdersByEquipmentId,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Preview);
