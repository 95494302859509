import { Dispatch, AnyAction } from 'redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import API from 'af-constants/routes/api';

import { GetRootState } from 'af-reducers';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { JobPayrollTableRowVM } from 'ab-viewModels/accounting/jobPayrollTable.viewModel';
import { JobPayrollTableBetaRowVM } from 'ab-viewModels/accounting/jobPayrollTableBeta.viewModel';
import { PrevailingWagesTableRowVM } from 'ab-viewModels/accounting/prevailingWagesTable.viewModel';
import { CompanyWorkSummaryVM } from 'ab-viewModels/accounting/workSummaryTable.viewModel';
import { PurchaseOrderTableVM } from 'ab-viewModels/purchaseOrder/purchaseOrderTable.viewModel';

import * as InvoicesTableAPI from 'ab-api/web/accounting/findInvoicesTable';

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findJobPayrollTable(tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			const result = await http.getFromWorker<TableViewModel<JobPayrollTableRowVM>>(
				API.V1.ACCOUNTING.JOB_PAYROLL_TABLE(startDate, endDate, data)
			);
			return result;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findAllJobPayrollTableRows(startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<JobPayrollTableRowVM[]>(
				API.V1.ACCOUNTING.JOB_PAYROLL_TABLE_ROWS(startDate, endDate)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findJobPayrollTableNew(tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			const result = await http.get<TableViewModel<JobPayrollTableBetaRowVM>>(
				API.V1.ACCOUNTING.JOB_PAYROLL_TABLE_NEW(startDate, endDate, data)
			);
			return result;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findJobPayrollRowsNew(startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<JobPayrollTableBetaRowVM[]>(
				API.V1.ACCOUNTING.JOB_PAYROLL_ROWS_NEW(startDate, endDate)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findPrevailingWagesTableWithClassificationCodes(unionTypeId: number, tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			return await http.get<TableViewModel<PrevailingWagesTableRowVM>>(
				API.V1.ACCOUNTING.PREVAILING_WAGES_TABLE_WITH_CLASSIFICATION_CODES(unionTypeId, startDate, endDate, data)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findCompanyWorkSummaryTable(tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			return await http.get<TableViewModel<CompanyWorkSummaryVM>>(
				API.V1.ACCOUNTING.WORK_SUMMARY(startDate, endDate, data)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findCompanyWorkSummaryTableCSV(startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<CompanyWorkSummaryVM[]>(
				API.V1.ACCOUNTING.WORK_SUMMARY_CSV(startDate, endDate)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findCompanyPurchaseOrderTable(tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			return await http.get<TableViewModel<PurchaseOrderTableVM>>(
				API.V1.ACCOUNTING.PURCHASE_ORDER(startDate, endDate, data)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findCompanyPurchaseOrderTableCSV(tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			return await http.get<PurchaseOrderTableVM[]>(
				API.V1.ACCOUNTING.PURCHASE_ORDER_CSV(startDate, endDate, data)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param startDate `YYYY-MM-DD`
 * @param endDate `YYYY-MM-DD`
 */
export function findPrevailingWagesTableWithoutClassificationCodes(unionTypeId: number, tableRM: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRM);
			return await http.get<TableViewModel<PrevailingWagesTableRowVM>>(
				API.V1.ACCOUNTING.PREVAILING_WAGES_TABLE_WITHOUT_CLASSIFICATION_CODES(unionTypeId, startDate, endDate, data)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getInvoicesTable(tableRequestModel: TableQuery, startDate: Date, endDate: Date) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);
			return await http.get<InvoicesTableAPI.W_Accounting_FindInvoicesTable_VM>(InvoicesTableAPI.URL(
				startDateString, endDateString, tableRequestModel
			));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

