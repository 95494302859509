import QuantityUnit from 'acceligent-shared/enums/quantityUnit';
import OperationType from 'acceligent-shared/enums/operation';
import ReportTypeStatus from 'acceligent-shared/enums/reportTypeStatus';
import ReportTypeBlockType from 'acceligent-shared/enums/reportTypeBlockType';
import ReportBlockType from 'acceligent-shared/enums/reportBlockType';
import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import RepeatableBlockType from 'acceligent-shared/enums/repeatableBlockType';
import OperandType from 'acceligent-shared/enums/operand';

export interface CalculatedFieldOptionRM {
	id: Nullable<string>;
	type: OperandType;
	constant: Nullable<number>;
	index: number;
	isOperandBlockInPrimarySegment: Nullable<boolean>;
}

export interface ReportBlockFieldRM {
	id?: Nullable<number>;
	// Random generated id
	// Used in builder when creating new report block/type for calcs
	virtualId: string;
	name: string;
	index: Nullable<number>;
	valueType: ReportBlockFieldEnum.ValueType;
	unit?: Nullable<QuantityUnit>;
	dimension: ReportBlockFieldEnum.Dimension;
	fieldType: ReportBlockFieldEnum.Type;
	isVisibleToCustomer: boolean;
	operationType?: Nullable<OperationType>;
	// Array of field virtual ids/constants used in calculation
	calculatedFieldOptions: Nullable<CalculatedFieldOptionRM[]>;
	reportBlockId?: number;
	hasTooltip: boolean;
	tooltipText?: Nullable<string>;
}

export interface ReportBlockRM {
	id?: number;
	name: string;
	uniqueId: string;
	isMain: boolean;
	isRepeating: boolean;
	hasCompletionStatus: boolean;
	reportBlockFields: ReportBlockFieldRM[];
	type: Nullable<ReportBlockType>;
	repeatableBlockType?: Nullable<RepeatableBlockType>;
}

export interface ReportTypeBlockRM {
	virtualId: string;
	reportTypeId?: number;
	/** Nullable cause of calculated blocks */
	reportBlockId: Nullable<number>;
	reportBlock: Nullable<ReportBlockRM>;
	index: Nullable<number>;
	isPrimary: boolean;
	type: ReportTypeBlockType;
}

export interface BillableWorkDefinitionFieldRM {
	reportBlockFieldVirtualId: string;
	name: string;
	isInPrimarySegment: boolean;
}

export interface BillableWorkInformationFieldRM {
	reportBlockFieldVirtualId: string;
	isInPrimarySegment: boolean;
}

export abstract class BillableWorkRM {
	workName: string;
	isInPrimarySegment: boolean;
	workTypeReportBlockFieldVirtualId: string;
	workQuantityReportBlockFieldVirtualId: Nullable<string>;
	defaultQuantity: Nullable<number>;

	definitionFields: BillableWorkDefinitionFieldRM[];
	informationFields: BillableWorkInformationFieldRM[];

	static sort(a: BillableWorkRM, b: BillableWorkRM) {
		return a.workName.localeCompare(b.workName, 'en');
	}
}

export interface ReportTypeRM {
	id?: number;
	name: string;
	description: Nullable<string>;
	reportTypeBlocks: ReportTypeBlockRM[];
	billableWorks: BillableWorkRM[];
}

export interface ReportTypeStatusRM {
	status: ReportTypeStatus;
}
