import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { compose } from 'redux';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';

import { DeliverableAssignmentRequestModel } from 'ab-requestModels/deliverable.requestModel';

import { validate } from './validation';

import * as FORMS from 'af-constants/reduxForms';

import { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';
import { DeliverableStatusViewModel } from 'ab-viewModels/deliverableStatusTable.viewModel';

import Textarea from 'af-fields/Textarea';
import Dropdown from 'af-fields/Dropdown';

import DropdownEmployeeItem from 'af-components/DropdownEmployeeItem';
import SubmitButton from 'af-components/SubmitButton';

import * as DeliverableStatusActions from 'af-actions/deliverableStatus';
import * as EmployeeActions from 'af-actions/employee';

import StatusOption from '../Shared/StatusOption';

import DeliverableAssignmentFM from './formModel';

const NOTES_MAX_LENGTH = 750;

type FormProps = InjectedFormProps<DeliverableAssignmentFM, FormOwnProps>;

interface OwnProps {
	isDisabled?: boolean;
	initialValues?: Nullable<FormProps['initialValues']>;
	onSubmit: (form: DeliverableAssignmentFM) => void;
	onBack: () => void;
}

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & FormProps;

interface State {
	employeesForCompany: EmployeeOptionVM[];
	deliverableStatusesForCompany: DeliverableStatusViewModel[];
}

class DeliverableAssignmentForm extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		isDisabled: false,
	};

	static readonly EMPLOYEE_FILTER_BY: (keyof EmployeeOptionVM)[] = ['firstName', 'lastName'];

	state: State = {
		employeesForCompany: [],
		deliverableStatusesForCompany: [],
	};

	static renderStatusOption = (option: DeliverableStatusViewModel) => <StatusOption status={option} />;

	static renderEmployeeItem = (option: EmployeeOptionVM) => option ? <DropdownEmployeeItem option={option} /> : <div />;

	lazyLoadEmployees = async (isLazyLoaded: boolean) => {
		const { findAllAssignableForDeliverable } = this.props;
		if (!isLazyLoaded) {
			const employeesForCompany = await findAllAssignableForDeliverable();
			this.setState(() => ({ employeesForCompany }));
		}
	};

	lazyLoadStatuses = async (isLazyLoaded: boolean) => {
		const { findAllStatuses } = this.props;
		if (!isLazyLoaded) {
			const statuses = await findAllStatuses();
			this.setState(() => ({ deliverableStatusesForCompany: statuses }));
		}
	};

	render() {
		const { handleSubmit, invalid, submitting, onBack, onSubmit, isDisabled } = this.props;
		const { deliverableStatusesForCompany, employeesForCompany } = this.state;

		return (
			<Form className="form-box">
				<Row className="row--padded-top">
					<Col sm={8}>
						<Field
							component={Dropdown}
							id="statusId"
							label="Status *"
							name="statusId"
							onLazyLoad={this.lazyLoadStatuses}
							options={deliverableStatusesForCompany}
							propName="status"
							renderMenuItem={DeliverableAssignmentForm.renderStatusOption}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
					<Col sm={8}>
						<Field
							component={Dropdown}
							disabled={isDisabled}
							filterable={true}
							filterBy={DeliverableAssignmentForm.EMPLOYEE_FILTER_BY}
							id="employeeId"
							label="Field worker *"
							name="employeeId"
							onLazyLoad={this.lazyLoadEmployees}
							options={employeesForCompany}
							propName="employee"
							renderMenuItem={DeliverableAssignmentForm.renderEmployeeItem}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={16}>
						<Field
							component={Textarea}
							disabled={isDisabled}
							label="Notes"
							maxCharacters={NOTES_MAX_LENGTH}
							name="notes"
							placeholder="Enter Notes"
							rows={10}
							showMaxCharactersLabel={true}
						/>
					</Col>
				</Row>
				<Row className="row--submit">
					<a
						className="btn btn-info"
						onClick={onBack}
					>
						Back
					</a>
					<SubmitButton
						disabled={invalid || isDisabled}
						onClick={handleSubmit(onSubmit)}
						reduxFormSubmitting={submitting}
						variant="primary"
						variantDisabled="info"
					/>
				</Row>
			</Form>
		);
	}
}

function mapDispatchToProps() {
	return {
		findAllAssignableForDeliverable: EmployeeActions.findAllEmployeesForWorkOrders,
		findAllStatuses: DeliverableStatusActions.findAllDataStatusesForCompany,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<DeliverableAssignmentRequestModel, FormOwnProps>({ form: FORMS.DELIVERABLE_ASSIGNMENT, validate, enableReinitialize: true })
);
export default enhance(DeliverableAssignmentForm);
