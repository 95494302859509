import * as React from 'react';
import { Field } from 'redux-form';

import QuantityUnitType from 'acceligent-shared/enums/quantityUnit';

import CompoundText from 'af-fields/CompoundText';

interface OwnProps {
	formName: string;
	name: string;
	tooltipMessage?: React.ComponentProps<typeof CompoundText>['tooltipMessage'];
	unit: QuantityUnitType;
}

type Props = OwnProps;

const CompoundPreview: React.FC<Props> = (props) => {
	const {
		formName,
		name,
		tooltipMessage,
		unit,
	} = props;
	return (
		<Field
			component={CompoundText}
			defaultValue="None"
			id={formName}
			label={name}
			name={formName}
			tooltipMessage={tooltipMessage}
			unit={unit}
		/>
	);
};

export default CompoundPreview;
