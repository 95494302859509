import * as React from 'react';

import { FilterVM } from 'acceligent-shared/dtos/web/view/scheduleBoard/filter';

import Checkbox from 'af-components/Controls/Checkbox';
import LabelWithColor from 'af-components/LabelWithColor';

interface Props extends FilterVM {
	onCheck: (value: FilterVM) => void;
}

export default class FilterItem extends React.PureComponent<Props> {

	onChange = (event) => {
		const { onCheck, id, text, color, filterGroup } = this.props;
		onCheck({
			id,
			text,
			color,
			checked: event.target.checked,
			filterGroup,
		});
	};

	render() {
		const { id, text, color, filterGroup, checked } = this.props;
		const elementId = `checkbox-${filterGroup}-${text}-${id}`;

		return (
			<div className="filter-modal__option">
				<Checkbox
					handleChange={this.onChange}
					id={elementId}
					isChecked={checked}
					label={<LabelWithColor color={color} text={text} />}
				/>
			</div>
		);
	}

}
