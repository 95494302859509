/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

interface Props {
	displayName: string;
}

declare const window;
declare const Upscope;

export default class UpscopeButton extends React.PureComponent<Props> {

	componentDidMount() {
		const { displayName } = this.props;

		(function (w, u, d) {
			if (typeof u !== 'function') {
				var i: any = function () {
					// eslint-disable-next-line prefer-rest-params
					i.c(arguments);
				};
				i.q = [];
				i.c = function (args) {
					i.q.push(args);
				};
				w.Upscope = i;
				var l = function () {
					var s = d.createElement('script');
					s.type = 'text/javascript';
					s.async = true;
					s.src = `https://code.upscope.io/${process.env.UPSCOPE_API_KEY}.js`;
					var x = d.getElementsByTagName('script')[0];
					x.parentNode?.insertBefore(s, x);
				};
				l();
			}
		})(window, window.Upscope, document);

		Upscope('init', {
			// Optionally set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"])
			identities: [displayName],
			requireAuthorizationForSession: true,
		});
	}

	render() {
		return null;
	}
}
