import * as React from 'react';
import { Field } from 'redux-form';

import Input from 'af-fields/Input';

import WorkRequestUpsertFM from '../../formModel';
import { formSectionClass, formSectionClassColumn2, formSectionClassRow, formSectionTitleClass } from '../../helpers';

const RequierementsSection: React.FC = () => {
	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Requirements
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="requestMBEOrWBERequirementsNote"
						label="MBE/WBE Requirements"
						name={WorkRequestUpsertFM.getAttributeName('requestMBEOrWBERequirementsNote')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="requestBondRequirementsNote"
						label="Bond Requirements"
						name={WorkRequestUpsertFM.getAttributeName('requestBondRequirementsNote')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="requestHSERequirementsNote"
						label="HSE Requirements"
						name={WorkRequestUpsertFM.getAttributeName('requestHSERequirementsNote')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="retainage"
						label="Retainage"
						name={WorkRequestUpsertFM.getAttributeName('retainage')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="requestInsuranceRequirementsNote"
						label="Insurance Requirements"
						name={WorkRequestUpsertFM.getAttributeName('requestInsuranceRequirementsNote')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="requestLiquidatedDamagesNote"
						label="Liquidated Damages"
						name={WorkRequestUpsertFM.getAttributeName('requestLiquidatedDamagesNote')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(RequierementsSection);
