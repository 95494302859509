import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

const EquipmentTimeError = () => {
	return (
		<div className={bemElement('time-sheet-list__equipment-modal', 'equipment-time-error')}>
			<span className="icon-info" />Total equipment time exceeds total job time
		</div>
	);
};

export default EquipmentTimeError;
