import * as React from 'react';

import { EquipmentCostViewModel } from 'ab-viewModels/equipmentCost.viewModel';

import TextHighlight from 'af-components/TextHighlight';

import ColorSquare from 'af-components/ColorSquare';

interface OwnProps extends EquipmentCostViewModel {
	searchText: string;
}

type Props = OwnProps;

export default class EquipmentPlaceholderOptionItem extends React.PureComponent<Props> {

	render() {
		const { categoryName, subcategory, categoryColor, searchText } = this.props;
		return (
			<span className="resource-lookup__resource resource-lookup__resource--equipment resource-lookup__resource--placeholder">
				<div className="resource-lookup__resource-details-equipment">
					<ColorSquare color={categoryColor} />
					<div>
						<span className="resource-lookup__resource-name">
							<TextHighlight searchText={searchText} text={subcategory} />
						</span>
						<span className="resource-lookup__resource-info">
							<TextHighlight searchText={searchText} text={` (${categoryName})`} />
						</span>
					</div>
				</div>
			</span>
		);
	}
}
