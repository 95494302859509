import * as React from 'react';

import Tooltip, { OwnProps as TooltipProps } from 'af-components/Tooltip';

interface OwnProps {
	label: string | JSX.Element | undefined;
	/** inverse boldness */
	inverse?: boolean;
	tooltipPlacement?: TooltipProps['placement'];
	tooltipMessage?: TooltipProps['message'];
	/** margin should be added when using as a label for input fields */
	withMargin?: boolean;
	className?: string;
	isRequired?: boolean;
}

type Props = OwnProps;

const LockedInputLabel = ({ label, isRequired = false, inverse = false, tooltipMessage, tooltipPlacement, withMargin = false, className }: Props) => {
	if (!label) {
		return null;
	}

	let cn = !inverse ? 'locked-input__header' : 'locked-input__content';
	cn = withMargin ? `${cn} locked-input__marginated` : cn;
	cn = className ? `${cn} ${className}` : cn;

	return (
		<div className={cn}>
			<span className="locked-input__label">
				{label}
				{
					isRequired &&
					<span className="locked-input__label__required">
						*
					</span>
				}
			</span>
			{!!tooltipMessage &&
				<Tooltip
					message={tooltipMessage}
					placement={tooltipPlacement ?? 'top'}
				>
					<span className="icon-help_fill" />
				</Tooltip>
			}
		</div>
	);
};

export default React.memo(LockedInputLabel);
