import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { InjectedFormProps, getFormValues } from 'redux-form';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { Button, Row } from 'react-bootstrap';

import RepairStatus from 'acceligent-shared/enums/repairStatus';

import { RootState } from 'af-reducers';

import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';

import * as JobAction from 'af-actions/workRequests';
import * as LocationAction from 'af-actions/location';
import * as ToolAction from 'af-actions/tool';
import * as EmployeeAction from 'af-actions/employee';
import * as EquipmentAction from 'af-actions/equipment';

import ConfirmationModal from 'af-components/ConfirmationModal';

import FormModel from './formModel';

import DetailsSection from './DetailsSection';
import InternalRepairSection from './InternalRepairSection';
import ExternalRepairSection from './ExternalRepairSection';

interface OwnProps {
	onSubmit: (form: FormModel) => Promise<void>;
	formActionWrapper: {
		selector: <T extends keyof FormModel>(fieldName: T) => FormModel[T];
	};
	manualPriceOverride: boolean;
	externalRepair: boolean;
}

type FormProps = OwnProps & InjectedFormProps<FormModel>;

type Props = ConnectedProps<typeof connector> & FormProps;

const leavePageModalBody = <>
	You are about to leave this page without saving or submitting. <br />
	Changes that you made will not be saved.
</>;

const ToolForm = (props: Props) => {
	const {
		handleSubmit,
		invalid,
		change,
		manualPriceOverride,
		externalRepair,
		initialized,
		onSubmit,
		findAllFieldWorkers,
		findAllSuperintendents,
		findAllForShipping,
		formData,
		canManage,
		dirty,
	} = props;

	const { id } = useParams();

	const [loading, setLoading] = React.useState(false);
	const [fieldWorkers, setFieldWorkers] = React.useState<EmployeeOptionVM[]>([]);
	const [superintendents, setSuperintendents] = React.useState<EmployeeOptionVM[]>([]);
	const [employeesForShipping, setEmployeesForShipping] = React.useState<EmployeeOptionVM[]>([]);
	const isEdit = React.useMemo(() => !!id, [id]);
	const [isLeavePageModalOpen, setIsLeavePageModalOpen] = React.useState(false);

	const navigate = useNavigate();

	React.useEffect(() => {
		setLoading(true);
	}, []);

	React.useEffect(() => {
		if (loading) {
			const loadStuff = async () => {

				const [
					_superintendents,
					_fieldWorkers,
					_employeesForShipping,
				] = await Promise.all([
					findAllSuperintendents(),
					findAllFieldWorkers(),
					findAllForShipping(),
				]);

				setSuperintendents(_superintendents);
				setFieldWorkers(_fieldWorkers);
				setEmployeesForShipping(_employeesForShipping);
				setLoading(false);
			};

			loadStuff();
		}
	}, [findAllSuperintendents, findAllFieldWorkers, loading, findAllForShipping]);

	const submit = React.useCallback(async () => {
		const form = { ...formData };
		form.status = form.status ?? RepairStatus.NEW;
		await onSubmit(form);
	}, [formData, onSubmit]);

	const start = React.useCallback(async () => {
		const form = { ...formData };
		form.status = RepairStatus.OPEN;
		await onSubmit(form);
	}, [onSubmit, formData]);

	const startExternal = React.useCallback(async () => {
		const form = { ...formData };
		form.status = RepairStatus.OUT_FOR_REPAIR;
		await onSubmit(form);
	}, [onSubmit, formData]);

	const complete = React.useCallback(async () => {
		const form = { ...formData };
		form.status = RepairStatus.COMPLETED;
		await onSubmit(form);
	}, [onSubmit, formData]);

	const isCompleted = formData.status === RepairStatus.COMPLETED;
	const disabled = isEdit && !canManage && formData.status !== RepairStatus.NEW;

	const showStartButton = !disabled && canManage && (formData.status === RepairStatus.NEW || !isEdit);
	const showStartExternalButton = !disabled && !isCompleted && formData.isExternal && formData.status !== RepairStatus.OUT_FOR_REPAIR;
	const showCompleteButton = !disabled && !isCompleted && formData.status !== RepairStatus.NEW && !!formData.status;
	const showSaveButton = (!canManage && (!isEdit || formData.status === RepairStatus.NEW)) || canManage;

	const closeLeavePageModal = React.useCallback(() => {
		setIsLeavePageModalOpen(false);
	}, [setIsLeavePageModalOpen]);

	const openLeavePageModal = React.useCallback(() => {
		setIsLeavePageModalOpen(true);
	}, [setIsLeavePageModalOpen]);

	const onBack = React.useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const close = React.useCallback(() => {
		if (dirty) {
			openLeavePageModal();
		} else {
			onBack();
		}
	}, [dirty, openLeavePageModal, onBack]);

	const leavePageModalFooter = React.useCallback(() => {
		return (
			<>
				<Button onClick={closeLeavePageModal} variant="info">
					Stay
				</Button>
				<Button onClick={onBack} variant="danger">
					Leave
				</Button>
			</>
		);
	}, [closeLeavePageModal, onBack]);

	return (
		<>
			<form onSubmit={handleSubmit(submit)}>
				<div className="form-box">
					<DetailsSection
						disabled={disabled}
						operators={fieldWorkers}
						superintendents={superintendents}
					/>
					<InternalRepairSection
						completedAt={formData.completedAt}
						createdAt={formData.createdAt}
						disabled={disabled || !canManage}
						initialized={initialized}
						isEdit={isEdit}
						manualPriceOverride={manualPriceOverride}
						receivedAt={formData.receivedAt}
						returnedAt={formData.returnedAt}
						shippedAt={formData.shippedAt}
						startedAt={formData.startedAt}
					/>
					<ExternalRepairSection
						change={change}
						disabled={disabled || !canManage}
						employeesForShipping={employeesForShipping}
						externalRepair={externalRepair}
					/>
					<Row className="row--submit">
						<Button onClick={close} variant="info">
							Back
						</Button>
						{!showStartExternalButton && showStartButton && (
							<Button
								disabled={invalid}
								onClick={start}
								variant="primary"
							>
								Start Repair
							</Button>
						)}
						{showStartExternalButton && (
							<Button
								disabled={invalid}
								onClick={startExternal}
								variant="primary"
							>
								Start External Repair
							</Button>
						)}
						{showCompleteButton && (
							<Button
								disabled={invalid}
								onClick={complete}
								variant="primary"
							>
								Complete
							</Button>
						)}
						{showSaveButton && (
							<Button
								disabled={invalid}
								onClick={submit}
								variant="primary"
							>
								Save
							</Button>
						)}
					</Row>
				</div>
			</form>
			<ConfirmationModal
				body={leavePageModalBody}
				closeModal={closeLeavePageModal}
				footer={leavePageModalFooter()}
				modalStyle="danger"
				showModal={isLeavePageModalOpen}
				size="md"
				title="Leave Page?"
			/>
		</>
	);
};

function mapStateToProps(state: RootState, props: FormProps) {
	const { companyData, userData } = state.user;
	const { form } = props;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}
	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const formData = getFormValues(form)(state) as FormModel;

	return {
		formData: formData ?? { status: RepairStatus.NEW },
		canCreate: isAllowed(PagePermissions.COMPANY.TOOL_REPAIR.CREATE, permissions, isCompanyAdmin, role),
		canManage: isAllowed(PagePermissions.COMPANY.TOOL_REPAIR.MANAGE, permissions, isCompanyAdmin, role),
	};
}

function mapDispatchToProps() {
	return {
		findAllTools: ToolAction.findAllForList,
		findAllLocations: LocationAction.findList,
		findAllJobs: JobAction.findWorkRequestOptionsForPurchaseOrder,
		findAllSuperintendents: EmployeeAction.findAllSuperintendents,
		findAllFieldWorkers: EmployeeAction.findAllEmployeesForWorkOrders,
		findAllEquipment: EquipmentAction.findForPurchaseOrderOptions,
		findAllForShipping: EmployeeAction.findAllForShipping,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(ToolForm);
