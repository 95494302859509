import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import { SystemNotificationTypeList } from 'acceligent-shared/enums/systemNotificationType';

import Input from 'af-fields/Input';
import Dropdown from 'af-fields/Dropdown';
import DateInput from 'af-fields/DateInput';

import SubmitButton from 'af-components/SubmitButton';
import Breadcrumbs from 'af-components/Breadcrumbs';

import SystemNotificationRequestModel from 'ab-requestModels/systemNotification.requestModel';

import { PLATFORM_SYSTEM_NOTIFICATION_CREATE } from 'af-constants/reduxForms';

import * as TimeOptionUtils from 'ab-utils/timeOption.util';

import { validate } from 'af-root/scenes/Platform/SystemNotifications/validations';

import * as SystemNotificationActions from 'af-actions/systemNotification';

import NotificationsList from './NotificationsList';

type OwnProps = CustomRouteComponentProps;
type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & InjectedFormProps<SystemNotificationRequestModel, FormOwnProps>;

interface State {
	systemNotifications: SystemNotificationRequestModel[];
}
class SystemNotifications extends React.PureComponent<Props, State> {
	state: State = {
		systemNotifications: [],
	};

	async componentDidMount() {
		const { findAll } = this.props;
		const systemNotifications = await findAll();
		this.setState(() => ({ systemNotifications }));
	}

	submit = async (form: SystemNotificationRequestModel) => {
		const { create, reset } = this.props;

		const systemNotifications = await create(form);
		this.setState(() => ({ systemNotifications }));
		reset();
	};

	render() {
		const { handleSubmit, submitting, invalid, removeSystemNotification } = this.props;
		const { systemNotifications } = this.state;

		return (
			<div className="form-segment form-segment--mini">
				<Breadcrumbs items={[{ label: 'System Notifications' }]} />
				<div className="form-box">
					<Form onSubmit={handleSubmit(this.submit)}>
						<Row className="row--padded-top">
							<Col sm={24}>
								<Field
									component={Dropdown}
									id="type"
									label="Notification Type *"
									labelKey="label"
									name="type"
									options={SystemNotificationTypeList}
									valueKey="id"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									label="Content *"
									name="content"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<Field
									component={DateInput}
									dateFormat={TimeFormatEnum.DATE_ONLY}
									label="Start Date *"
									name="startsAtDate"
									originalDateFormat={TimeFormatEnum.DATE_ONLY}
									showTimeSelect={true}
								/>
							</Col>
							<Col sm={12}>
								<Field
									component={Dropdown}
									id="startsAtTime"
									label="Start Time *"
									labelKey="name"
									name="startsAtTime"
									options={TimeOptionUtils.enumWithoutNullValue()}
									valueKey="id"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<Field
									component={DateInput}
									dateFormat={TimeFormatEnum.DATE_ONLY}
									label="End Date *"
									name="endsAtDate"
									originalDateFormat={TimeFormatEnum.DATE_ONLY}
									showTimeSelect={true}
								/>
							</Col>
							<Col sm={12}>
								<Field
									component={Dropdown}
									id="endsAtTime"
									label="End Time *"
									labelKey="name"
									name="endsAtTime"
									options={TimeOptionUtils.enumWithoutNullValue()}
									valueKey="id"
								/>
							</Col>
						</Row>
						<Row className="row--submit">
							<SubmitButton
								disabled={invalid}
								label="Submit"
								onClick={handleSubmit(this.submit)}
								reduxFormSubmitting={submitting}
								submitKey={PLATFORM_SYSTEM_NOTIFICATION_CREATE}
							/>
						</Row>
					</Form>
				</div>
				<NotificationsList
					deleteSystemNotification={removeSystemNotification}
					systemNotifications={systemNotifications}
				/>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		create: SystemNotificationActions.createSystemNotification,
		findAll: SystemNotificationActions.findAllSystemNotifications,
		removeSystemNotification: SystemNotificationActions.deleteSystemNotification,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<SystemNotificationRequestModel, FormOwnProps>({ form: PLATFORM_SYSTEM_NOTIFICATION_CREATE, validate })
);

export default enhance(SystemNotifications);
