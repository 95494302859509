import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { Button, Col, Row } from 'react-bootstrap';

import { InvoiceFM } from '../EditWhenInvoiced/formModel';
import styles from '../styles.module.scss';
import BillingContactPreview from './BillingContactPreview';
import BillingContactEdit from './BillingContactEdit';

export type BillingContactFM = Required<InvoiceFM>['billingContacts'][0];

export type CustomFormErrors<Model> = { [K in keyof Model]: Model[K] extends object ? CustomFormErrors<Model[K]> : string };
export type BillingContactErrors = CustomFormErrors<BillingContactFM[]>;

export interface OwnProps {
	change: (fieldName: string, value: Metadata | string | number | boolean | null) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<BillingContactFM>;

const BillingContactArray: React.FC<Props> = React.memo((props) => {
	const {
		fields,
		change,
	} = props;

	const onAdd = React.useCallback(() => {
		fields.push({ editable: true } as BillingContactFM);
	}, [fields]);

	const onRemove = React.useCallback((index: number) => {
		fields.remove(index);
	}, [fields]);

	const changeEditable = React.useCallback((index: number, editable: boolean) => {
		change(`billingContacts[${index}].editable`, editable);
	}, [change]);

	const renderBillingContact = (field: string, index: number) => {
		const billingContact = fields.get(index);

		return (
			billingContact.editable ?
				<BillingContactEdit
					changeEditable={changeEditable}
					fieldName={field}
					index={index}
					key={index}
					onRemove={onRemove}
					orderNumber={index + 1}
				/>
				:
				<BillingContactPreview
					billingContact={billingContact}
					index={index}
					key={index}
					onRemove={onRemove}
					orderNumber={index + 1}
					showActions={true}
				/>
		);
	};

	return (
		<>
			{
				!!fields.length &&
				<div className={styles['billing-contacts__list__items']}>{
					fields.map(renderBillingContact)}
				</div>
			}
			<Row>
				<Col sm={24}>
					<Button className="btn btn--link" onClick={onAdd}>
						<span className="icon-plus" />
						<span>Add Billing Contact Email</span>
					</Button>
				</Col>
			</Row >
		</>
	);
});

export default BillingContactArray;
