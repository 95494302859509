import { SubmissionError } from 'redux-form';
import { Dispatch, AnyAction } from 'redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import * as organizationActions from 'af-actions/organizations/organizations.actionCreators';
import * as generalActions from 'af-actions/general/general.actionCreators';

import API from 'af-constants/routes/api';
import CLIENT from 'af-constants/routes/client';

import { OrganizationTableRowVM } from 'ab-viewModels/organization/tableRow.viewModel';
import OrganizationPublicDetailsViewModel from 'ab-viewModels/organizationPublicDetails.viewModel';
import * as User from 'ab-viewModels/user.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import OrganizationRequestModel from 'ab-requestModels/organization.requestModel';
import { OrganizationForgottenByEmailRequestModel } from 'ab-requestModels/organizationForgotten.requestModel';

import { GetRootState } from 'af-reducers';

import { http, getUrlWithAlias } from 'af-utils/http.util';
import { errorHandler, ErrorOverride } from 'af-utils/actions.util';
import { getAllSignIns } from 'af-utils/localStorage.util';

export function checkOrganizationAlias(alias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.get(API.V1.ORGANIZATION.CHECK_ALIAS(alias));
			window.location.replace(getUrlWithAlias(alias));
		};

		const error: ErrorOverride = {
			err404: () => {
				throw new SubmissionError({ alias: 'Organization not found' });
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function createOrganization(form: OrganizationRequestModel, orgAlias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.ORGANIZATION.CREATE, form);
			return redirectTo(CLIENT.PLATFORM.ALL_ORGANIZATIONS(orgAlias));
		};

		const error: ErrorOverride = {
			err409: () => {
				throw new SubmissionError({ alias: 'Alias already taken' });
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function fetchActiveOrganizations() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const allSignIns: User.SignInData[] = getAllSignIns();

			dispatch(generalActions.APP_READY());

			if (!allSignIns.length) {
				return;
			}

			const organizationAliases: string[] = allSignIns.map((_signin: User.SignInData) => _signin.orgAlias);

			const suggestedOrganizations: OrganizationPublicDetailsViewModel[] = await http.get(
				API.V1.ORGANIZATION.MULTIPLE_PUBLIC_DETAILS(organizationAliases)
			);

			dispatch(organizationActions.MULTIPLE_ORGANIZATION_PUBLIC(suggestedOrganizations));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllOrganizations(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<OrganizationTableRowVM>>(API.V1.ORGANIZATION.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findForgottenOrganization(form: OrganizationForgottenByEmailRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.ORGANIZATION.ORGANIZATION_FORGOTTEN, form);
			redirectTo(CLIENT.APP.ORGANIZATION_FORGOTTEN_SUCCESS);
		};

		const error: ErrorOverride = {
			err404: () => redirectTo(CLIENT.APP.ORGANIZATION_FORGOTTEN_SUCCESS),
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function findOrganizationPublicDetails(orgAlias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const result: OrganizationPublicDetailsViewModel = await http.get(API.V1.ORGANIZATION.PUBLIC_DETAILS(orgAlias));
			dispatch(organizationActions.ORGANIZATION_PUBLIC(result));
			dispatch(generalActions.APP_READY());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
