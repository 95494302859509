import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	title: string;
	tooltip: Nullable<string>;
}

type Props = OwnProps;

const Title: React.FC<Props> = (props: Props) => {
	const { title, tooltip } = props;

	return (
		<div className="report-block-form__title">
			{title}
			{
				tooltip &&
				<Tooltip message={tooltip}>
					<span className="icon-help_fill" />
				</Tooltip>
			}
		</div>
	);
};

export default React.memo(Title);
