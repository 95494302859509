import axios from 'axios';
import { Image, createCanvas } from 'canvas';

export const base64DataUriToString = (imageDataURI: string) => {
	return imageDataURI.replace(/^data:image\/\w+;base64,/, '');
};

export const resizeBase64ImageTo2to1Ratio = (image: string): string => {
	const img = new Image();
	img.src = image;

	// image is already in 2:1 ratio, no need for resize
	if (img.width === img.height * 2) {
		return image;
	}

	let offsetX = 0;
	const newCanvasWidth = img.height * 2;
	const newCanvasHeight = img.height;

	const canvas = createCanvas(newCanvasWidth, newCanvasHeight);
	const ctx = canvas.getContext('2d');

	// image in landscape mode and image width doesn't fit in the new 2:1 canvas
	if (img.width > img.height && img.width > newCanvasWidth) {
		offsetX = 0;
		ctx.drawImage(img, offsetX, 0, newCanvasWidth, img.height);
	} else {
		offsetX = (newCanvasWidth - img.width) / 2;
		ctx.drawImage(img, offsetX, 0);
	}

	return canvas.toDataURL();
};

export const toBase64ImageUrl = async (imgUrl: string): Promise<string> => {
	const fetchImageUrl = await axios.get(imgUrl, {
		responseType: 'arraybuffer',
	});

	return `data:image/png;base64,${Buffer.from(fetchImageUrl.data).toString('base64')}`;
};
