import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { compose, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';

import ScheduleBoardRM from 'acceligent-shared/dtos/socket/request/connection/scheduleBoard';

import WorkOrderAlreadyLockedVM from 'acceligent-shared/dtos/socket/view/workOrder/alreadyLocked';

import WorkOrderPositionEnum from 'acceligent-shared/enums/workOrderPosition';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import * as AttachmentActions from 'af-actions/attachment';
import * as WorkOrderActions from 'af-actions/workOrder';
import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as CompanyActions from 'af-actions/companies';
import * as EmployeeActions from 'af-actions/employee';
import * as EquipmentActions from 'af-actions/equipment';
import * as TemporaryEmployeeActions from 'af-actions/temporaryEmployee';

import SocketEvent from 'ab-enums/socketEvent.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import { DAY_SHIFT } from 'ab-enums/shift.enum';

import CLIENT from 'af-constants/routes/client';
import { WORK_ORDER_FORM, DELETE_WORK_ORDER } from 'af-constants/reduxForms';
import * as SettingsKeys from 'af-constants/settingsKeys';

import WorkOrderIdAndDueDate from 'ab-viewModels/workOrderIdAndDueDate.viewModel';
import ScheduleBoardViewModel, { ScheduleBoardRejoinViewModel } from 'ab-viewModels/scheduleBoard.viewModel';
import ScheduleBoardResourcesViewModel from 'ab-viewModels/scheduleBoardResources.viewModel';
import { EmployeeOptionVM } from 'ab-viewModels/employee/extendedOption.viewModel';
import EquipmentViewModel from 'ab-viewModels/equipment.viewModel';
import TemporaryEmployeeOptionVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';

import Breadcrumbs from 'af-components/Breadcrumbs';

import { http } from 'af-utils/http.util';
import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';
import { withSettings } from 'af-utils/settings.util';
import socket from 'af-utils/socket.util';
import { debounce } from 'af-utils/actions.util';

import ForcedUnlockNotificationModal from '../../ScheduleBoard/Shared/ForcedUnlockNotificationModal';

import Header from './Header';
import Info from './Info';
import WorkOrderForm from './Form';
import OrderLoading from './OrderLoading';
import SessionExpiredModal from './Modals/SessionExpired';
import { validate, warn } from './validations';
import WorkOrderFM from './formModel';

type MomentType = ReturnType<typeof TimeUtils.normalizeDateToMoment>;

interface PathParams {
	workOrderId: string;
}

interface OrderCustomLocationState {
	orgAlias: string;
	originUrl?: string;
	originLabel?: string;
	job?: WorkOrderFM['job'];
	defaultDueDate?: Date;
	redirectUrl?: string;
	jobCode?: string;
}

interface OwnProps extends CustomRouteComponentProps<PathParams, StaticContext, OrderCustomLocationState> {
	selectedDate: MomentType;
}

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<WorkOrderFM>;

interface State {
	sessionExpired: boolean;
	forcedUnlockUserFullName: Nullable<string>;
	isSettingLock: boolean;
	isDeleted: boolean;
	employees: EmployeeOptionVM[] | undefined;
	equipment: EquipmentViewModel[] | undefined;
	temporaryEmployees: TemporaryEmployeeOptionVM[] | undefined;
	isFetching: boolean;
	isJobChanged: boolean;
}

class Order extends React.Component<Props, State> {

	static CONFIRMATION_MESSAGE = 'There may have be unsaved changes. If you leave before saving, your changes will be lost.';
	static LOAD_RESOURCES_DELAY = 200;

	state: State = {
		sessionExpired: false,
		forcedUnlockUserFullName: null,
		isSettingLock: false,
		isDeleted: false,
		employees: undefined,
		equipment: undefined,
		temporaryEmployees: undefined,
		isFetching: false,
		isJobChanged: false,
	};

	constructor(props: Props) {
		super(props);
		const {
			getScheduleBoard,
			clearFilters,
			getScheduleBoardRejoin,
			getScheduleBoardResources,
		} = props;

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ALREADY_LOCKED_WORK_ORDER, this.showForcedUnlockModal);

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.DELETE_WORK_ORDER_IN_EDIT, this.onDeleteWorkOrder);

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.CONNECTED, (data: ScheduleBoardRM) => {
			if (data?.datesToLoad?.length !== 1) {
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.GET_DAILY_VIEW_REJOIN, data);
			} else {
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.GET_DAILY_VIEW, data);
			}
		});

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.DAILY_VIEW_BOARD, (data: ScheduleBoardViewModel) => {
			getScheduleBoard(data);
			clearFilters();
		});

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.DAILY_VIEW_BOARD_REJOIN, (data: ScheduleBoardRejoinViewModel) => {
			getScheduleBoardRejoin(data);
			clearFilters();
		});

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RESOURCES, (data: ScheduleBoardResourcesViewModel) => {
			getScheduleBoardResources(data);
		});

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD_RESOURCES, () => {
			ScheduleBoardUtil.loadResources();
		});

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_WORK_ORDER_EMPLOYEES, this.loadEmployeesDebounced);
		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_WORK_ORDER_EQUIPMENT, this.loadEquipmentDebounced);
	}

	async componentDidMount() {
		const {
			workOrderId,
			shouldLoadResources,
			getCompany,
			findWorkOrderById,
			lockWorkOrder,
			getLoadedDates,
			change,
			dueDate,
			isDueDateSet,
			location: { state: { job: paramJob } },
			dayShiftStart,
			dayShiftEnd,
			initialize,
		} = this.props;

		getCompany();

		// ON REFRESH:
		window.addEventListener('beforeunload', this.beforeUnloadWindowEvent);

		if (!isDueDateSet) {
			change('dueDate', dueDate);
		}

		if (paramJob) {
			// when returning back from newly created job
			change('jobId', paramJob.id);
			change('job', paramJob);

			if (paramJob.projectManager) {
				change('projectManagerId', paramJob?.projectManager?.id);
				change('projectManager', paramJob?.projectManager);
			}
		}

		if (workOrderId) {
			this.setState(() => ({ isFetching: true }));
			const order = await findWorkOrderById(workOrderId);
			initialize(new WorkOrderFM(order));
			this.setState(() => ({ isFetching: false }));
			const loadedDates = getLoadedDates();

			// NOTE: At this moment, locked will always be false. Set locked attribute properly for this to work
			if (!order || order.locked) {
				this.goBackToWorkOrders(false);
				return;
			}

			const _dueDate = order.dueDate;
			lockWorkOrder({ workOrderId: order.id, dueDate: _dueDate } as WorkOrderIdAndDueDate);

			this.setState(() => ({ isSettingLock: true }), () => {
				ScheduleBoardUtil.lockWorkOrder(workOrderId, _dueDate);
			});

			// only start timer if edit page
			this.resetTimer();

			if (shouldLoadResources) {
				ScheduleBoardUtil.loadResources();
			}

			ScheduleBoardUtil.joinDailyView(_dueDate, loadedDates, true);
		} else if (dayShiftStart) {
			change('shift', DAY_SHIFT);
			change('timeToStart', dayShiftStart);
			change('timeToEnd', dayShiftEnd);
		}
	}

	componentDidUpdate(prevProps: Props) {
		const { change, dayShiftStart, dayShiftEnd, workOrderId } = this.props;

		if (!workOrderId && prevProps.dayShiftStart === undefined && dayShiftStart !== undefined) {
			// company is loaded
			// this only happens when first page user visits is create new WO
			change('shift', DAY_SHIFT);
			change('timeToStart', dayShiftStart);
			change('timeToEnd', dayShiftEnd);
		}
	}

	async componentWillUnmount() {
		const {
			workOrderId,
			dueDate,
			deletePendingAttachments,
			resetWorkOrder,
			uploadedAttachmentIds,
		} = this.props;
		const { isSettingLock } = this.state;

		socket.connection?.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.DELETE_WORK_ORDER_IN_EDIT);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ALREADY_LOCKED_WORK_ORDER);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_WORK_ORDER_EMPLOYEES);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_WORK_ORDER_EQUIPMENT);

		if (isSettingLock && workOrderId) {
			ScheduleBoardUtil.unlockWorkOrder(workOrderId, dueDate);
		}

		if (workOrderId && uploadedAttachmentIds?.length) {
			await deletePendingAttachments({ attachmentIds: uploadedAttachmentIds });
		}
		await resetWorkOrder();
		window.removeEventListener('beforeunload', this.beforeUnloadWindowEvent);
	}

	loadEmployees = async (event: { isLazyLoad?: boolean; date?: string; } = {}) => {
		const { isLazyLoad, date } = event;
		const { employees } = this.state;

		if (!isLazyLoad && !employees) {
			return;
		}

		const { dueDate, findAllEmployeesForWorkOrdersForDueDate } = this.props;

		const newEmployees = await findAllEmployeesForWorkOrdersForDueDate(TimeUtils.formatDate(date ?? dueDate));

		this.setState(() => ({ employees: newEmployees }));
	};

	loadEquipment = async (event: { isLazyLoad?: boolean; date?: string; } = {}) => {
		const { isLazyLoad, date } = event;
		const { equipment } = this.state;

		if (!isLazyLoad && !equipment) {
			return;
		}

		const { dueDate, findAllEquipmentForOrder } = this.props;

		const newEquipment = await findAllEquipmentForOrder(date ?? dueDate);

		this.setState(() => ({ equipment: newEquipment }));
	};

	loadTemporaryEmployees = async (isLazyLoad: boolean) => {
		const { temporaryEmployees } = this.state;

		if (!isLazyLoad && !temporaryEmployees) {
			return;
		}

		const { dueDate, findAllTemporaryEmployeesForWorkOrdersForDueDate } = this.props;

		const newTemporaryEmployees = await findAllTemporaryEmployeesForWorkOrdersForDueDate(dueDate);

		this.setState(() => ({ temporaryEmployees: newTemporaryEmployees }));
	};

	onDateChange = (date: string) => {
		this.loadEmployees({ date });
		this.loadEquipment({ date });
	};

	lazyLoadEmployees = async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			await this.loadEmployees({ isLazyLoad: true });
		}
	};

	lazyLoadEquipment = async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			await this.loadEquipment({ isLazyLoad: true });
		}
	};

	lazyLoadTemporaryEmployees = async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			await this.loadTemporaryEmployees(true);
		}
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	loadEmployeesDebounced = debounce(this.loadEmployees, Order.LOAD_RESOURCES_DELAY);
	// eslint-disable-next-line @typescript-eslint/member-ordering
	loadEquipmentDebounced = debounce(this.loadEquipment, Order.LOAD_RESOURCES_DELAY);

	beforeUnloadWindowEvent = (e) => {
		const { dirty } = this.props;
		if (dirty) {
			(e || window.event).returnValue = Order.CONFIRMATION_MESSAGE; // Gecko + IE
			return Order.CONFIRMATION_MESSAGE;
		}
	};

	onDeleteWorkOrder = () => {
		const {
			location: { state: { orgAlias, originUrl } },
			companyName,
			endDeletingWorkOrder,
			history,
		} = this.props;
		endDeletingWorkOrder();
		this.setState(
			() => ({ isDeleted: true }),
			() => {
				if (originUrl) {
					history.push(originUrl);
				} else {
					history.push(CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName));
				}
			}
		);
	};

	onTimeout = () => {
		const { workOrderId, dueDate } = this.props;
		this.setState(
			() => ({ sessionExpired: true }),
			() => { workOrderId && ScheduleBoardUtil.unlockWorkOrder(workOrderId, dueDate); }
		);
	};

	resetTimer = () => socket.connection?.resetTimeout(this.onTimeout);

	goBackToWorkOrders = (checkIfFormIsDirty: boolean = true) => {
		const { location: { state: { orgAlias, originUrl } }, companyName, history, dirty, workOrderCode } = this.props;

		if (!checkIfFormIsDirty || (!dirty || confirm(Order.CONFIRMATION_MESSAGE))) {
			history.push({
				pathname: originUrl ? originUrl : CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName),
				search: originUrl && workOrderCode ? `order=${workOrderCode}` : '',
			});
		}
	};

	goToJobForm = (jobCode: string) => {
		// redirects user to job form. when user fills the form and save it, he/she will be
		// redirected back to order form with preselected created job
		const { dueDate, history, location: { state: { orgAlias }, pathname }, companyName } = this.props;
		history.push({
			pathname: CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyName),
			state: {
				redirectUrl: pathname,
				defaultDueDate: TimeUtils.parseDate(dueDate),
				orgAlias,
				jobCode,
			},
		});
	};

	closeSessionExpiredModal = () => this.setState({ sessionExpired: false });

	reload = () => window.location.reload();

	showForcedUnlockModal = (data: WorkOrderAlreadyLockedVM) => {
		const { workOrderId } = this.props;
		if (data && data.workOrderId === workOrderId) {
			this.setState(() => ({ forcedUnlockUserFullName: data.fullName }));
		}
	};

	closeForcedUnlockModal = () => {
		this.goBackToWorkOrders(false);
	};

	setIsJobChanged = () => this.setState({ isJobChanged: true });

	render() {
		const {
			companyName,
			location: { state: { orgAlias, originUrl, originLabel } },
			workOrderCode,
			loading,
			workOrderId,
			change,
			selector,
			initialValues,
			status,
			isPaused,
		} = this.props;
		const {
			sessionExpired,
			forcedUnlockUserFullName,
			employees,
			equipment,
			temporaryEmployees,
			isFetching,
			isJobChanged,
		} = this.state;

		const _originUrl = originUrl ?? CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName);
		const _originLabel = originLabel ?? 'Schedule board';

		const isReadOnly = status === WorkOrderStatus.CANCELED || status === WorkOrderStatus.LOCKED || isPaused;

		if (loading) {
			return <OrderLoading originLabel={_originLabel} originUrl={_originUrl} />;
		}

		const showForcedUnlockModal = !!forcedUnlockUserFullName;

		return (
			<div className="form-segment work-order-upsert">
				<Breadcrumbs
					items={
						[
							{ label: _originLabel, url: _originUrl },
							{ label: workOrderId ? isReadOnly ? 'View Work Order' : 'Edit Work Order' : 'New Work Order' },
						]
					}
				/>
				<Header
					goBack={this.goBackToWorkOrders}
					isFetching={isFetching}
					isJobChanged={isJobChanged}
					resetTimer={this.resetTimer}
				/>
				<Info
					createNewJob={this.goToJobForm}
					onDateChange={this.onDateChange}
					resetTimer={this.resetTimer}
					setIsJobChanged={this.setIsJobChanged}
				/>
				<WorkOrderForm
					change={change}
					employees={employees ?? []}
					equipment={equipment ?? []}
					initialIndex={initialValues.index ?? null}
					loadEmployees={this.lazyLoadEmployees}
					loadEquipment={this.lazyLoadEquipment}
					loadTemporaryEmployees={this.lazyLoadTemporaryEmployees}
					resetTimer={this.resetTimer}
					selector={selector}
					temporaryEmployees={temporaryEmployees ?? []}
				/>
				<SessionExpiredModal
					closeModal={this.closeSessionExpiredModal}
					code={workOrderCode}
					goBackToWorkOrders={this.goBackToWorkOrders}
					isFromScheduleBoard={!!originUrl}
					reload={this.reload}
					showModal={sessionExpired}
				/>
				<ForcedUnlockNotificationModal
					close={this.closeForcedUnlockModal}
					show={showForcedUnlockModal}
					userFullName={forcedUnlockUserFullName}
				/>
			</div>
		);
	}
}

const formSelector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { match: { params: { workOrderId } }, location: { state: { defaultDueDate = new Date() } } } = ownProps;
	const { order } = state.workOrder;
	const { company } = state.company;
	const { companyData } = state.user;

	if (!companyData) {
		throw new Error('User not logged in');
	}

	const dueDate = formSelector(state, 'dueDate');
	const status = formSelector(state, 'status');
	const uploadedAttachmentIds: number[] = formSelector(state, 'uploadedAttachmentIds');
	const _workOrderId = workOrderId && +workOrderId;

	return {
		workOrderId: _workOrderId,
		loading: !!_workOrderId && !order,
		workOrderCode: order?.code,
		companyName: companyData.name,
		isDueDateSet: !!dueDate,
		dueDate: dueDate ?? TimeUtils.formatDate(defaultDueDate, TimeFormatEnum.DB_DATE_ONLY, TimeFormatEnum.JS_TIME),
		shouldLoadResources: !state.scheduleBoard.resourcesLoaded,
		dayShiftStart: company?.dayShiftStart,
		dayShiftEnd: company?.dayShiftEnd,
		selector: (fieldName: string) => formSelector(state, fieldName),
		status,
		isPaused: order?.isPaused,
		uploadedAttachmentIds,
	};
}

function mapDispatchToProps() {
	return {
		deletePendingAttachments: AttachmentActions.deletePendingAttachments,
		resetWorkOrder: WorkOrderActions.resetWorkOrder,
		findWorkOrderById: WorkOrderActions.findWorkOrderById,
		lockWorkOrder: WorkOrderActions.lockWorkOrder,
		getCompany: CompanyActions.getCompany,
		getLoadedDates: ScheduleBoardActions.getLoadedDates,
		getScheduleBoard: ScheduleBoardActions.getScheduleBoard,
		clearFilters: ScheduleBoardActions.clearFilters,
		getScheduleBoardRejoin: ScheduleBoardActions.getScheduleBoardRejoin,
		getScheduleBoardResources: ScheduleBoardActions.getScheduleBoardResources,
		endDeletingWorkOrder: () => (dispatch: Dispatch) => dispatch(http.endSubmitting(DELETE_WORK_ORDER)),
		findAllEmployeesForWorkOrdersForDueDate: EmployeeActions.findAllEmployeesForWorkOrdersForDueDate,
		findAllTemporaryEmployeesForWorkOrdersForDueDate: TemporaryEmployeeActions.findAllForWorkOrdersForDueDate,
		findAllEquipmentForOrder: EquipmentActions.findAllForOrder,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	withSettings<Props>(() => ([
		{
			key: SettingsKeys.WORK_ORDER_SELECTED_DUE_DATE(),
			mappedName: 'selectedDate',
			normalize: TimeUtils.normalizeDateToMoment,
			defaultValue: TimeUtils.toMomentUtc(new Date()),
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
	])),
	connector,
	reduxForm<WorkOrderFM>({
		form: WORK_ORDER_FORM,
		validate,
		warn,
		initialValues: { position: WorkOrderPositionEnum.DEFAULT },
	})
);

export default enhance(Order);
