import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, InjectedFormProps, FieldArray } from 'redux-form';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import * as ContactActions from 'af-actions/contacts';

import Dropdown from 'af-components/Controls/Dropdown';

import SavedContactMethodFieldArray, { SavedContactMethodFieldArrayOwnProps } from './SavedContactMethodFieldArray';
import { SavedContactFormModel, SavedContactMethodFormModel } from './formModel';

type OwnProps = EmptyObject;

type Props = OwnProps & InjectedFormProps<SavedContactFormModel> & ConnectedProps<typeof connector>;

const SAVED_CONTACT_FILTER_BY: (keyof ContactVM)[] = ['fullName'];

const SavedContactForm: React.FC<Props> = (props) => {
	const { findAllSavedContacts, initialize } = props;
	const [contactOptions, setContactOptions] = React.useState<ContactVM[]>([]);
	const [selectedContact, setSelectedContact] = React.useState<Nullable<ContactVM>>(null);

	const handleSelect = React.useCallback((contact: Nullable<ContactVM>) => {
		initialize(SavedContactFormModel.fromContactVM(contact));
		setSelectedContact(contact);
	}, [initialize]);

	React.useEffect(() => {
		const action = async () => {
			const contacts = await findAllSavedContacts();
			setContactOptions(contacts);
		};
		action();
	}, [findAllSavedContacts]);

	return (
		<div className="contact-action-modal__saved-contact-form">
			<Row>
				<Col sm={24}>
					<Dropdown<ContactVM>
						filterable={true}
						filterBy={SAVED_CONTACT_FILTER_BY}
						fixed={true}
						id="contact-dropdown"
						label="Full Name"
						labelKey="fullName"
						menuItemClassName="dropdown-menu-item"
						onValueChange={handleSelect}
						options={contactOptions}
						withCaret={true}
					/>
				</Col>
			</Row>
			{selectedContact &&
				<>
					<Row>
						<Col sm={24}>
							<div className="contact-action-modal__saved-contact-form__contact-method-description">
								Use E-mail to send Reports
							</div>
							<FieldArray<SavedContactMethodFieldArrayOwnProps, SavedContactMethodFormModel>
								component={SavedContactMethodFieldArray}
								name="emails"
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={24}>
							<div className="contact-action-modal__saved-contact-form__contact-method-description">
								Use Phone Number to send Reports
							</div>
							<FieldArray<SavedContactMethodFieldArrayOwnProps, SavedContactMethodFormModel>
								component={SavedContactMethodFieldArray}
								isPhone={true}
								name="phones"
							/>
						</Col>
					</Row>
				</>
			}
		</div>
	);
};

function mapDispatchToProps() {
	return {
		findAllSavedContacts: ContactActions.findAllContacts,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector,
	reduxForm({ form: SavedContactFormModel.FORM_NAME, validate: SavedContactFormModel.validate, pure: false })
);

export { SavedContactFormModel } from './formModel';
export default enhance(SavedContactForm);
