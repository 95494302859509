import * as React from 'react';

interface Props {
	id?: string;
	value: React.ReactNode;
	defaultValue?: React.ReactNode;
	valueClassName?: Nullable<string>;
	/** inverse boldness */
	inverse?: boolean;
}

const LockedInputValue = ({ id, value, defaultValue = '-', valueClassName = '', inverse = false }: Props) => {
	let className = !inverse ? 'locked-input__content' : 'locked-input__header';
	className = valueClassName ? `${className} ${valueClassName}` : className;

	return (
		<div className={className} id={id}>
			{value ?? defaultValue}
		</div>
	);
};

export default React.memo(LockedInputValue);
