import * as React from 'react';
import { CustomRouteComponentProps, CustomLocationState } from 'react-router-dom';
import { connect, DispatchActionsMapped } from 'react-redux';
import { DragDropContext, DropResult, DragStart } from 'react-beautiful-dnd';
import { bindActionCreators, Dispatch, compose } from 'redux';

import AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import UnavailabilityReasonScope from 'acceligent-shared/enums/unavailabilityReasonScope';
import Priority from 'acceligent-shared/enums/priority';

import { toMomentUtc, normalizeDateToMoment, formatDate } from 'acceligent-shared/utils/time';

import { WORK_ORDER_FORM, ORDER_COPY_FORM } from 'af-constants/reduxForms';
import * as SettingsKeys from 'af-constants/settingsKeys';

import ResourceAssignConfirmationModal from 'af-components/SharedForms/ResourceAssignModal';

import SocketEvent from 'ab-enums/socketEvent.enum';
import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';
import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import { DailyViewDragElementType } from 'ab-enums/scheduleBoardDragElementType.enum';
import ScheduleBoardSortType from 'ab-enums/scheduleBoardSortType.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import { TemplateNotificationEnum } from 'ab-enums/notifyType.enum';

import { WorkOrderPublishForm, WorkOrderCancelForm, WorkOrderPauseForm } from 'ab-requestModels/workOrder.requestModel';
import ScheduleBoardDragRequestModel from 'ab-requestModels/scheduleBoardDrag.requestModel';
import CreateLaborPlaceholderForm from 'ab-requestModels/scheduleBoardCreateLaborPlaceholder.requestModel';
import CreateEquipmentPlaceholderForm from 'ab-requestModels/scheduleBoardCreateEquipmentPlaceholder.requestModel';
import DownEquipmentRM from 'ab-requestModels/equipment/downEquipment.requestModel';
import { EquipmentDownRequestModel } from 'ab-requestModels/equipmentDown.requestModel';
import EmployeeDownRM from 'ab-requestModels/employee/employeeDown.requestModel';
import CreateTemporaryEmployeeAssignmentRM from 'ab-requestModels/workOrderTemporaryEmployee/createTemporaryEmployeeAssignment';

import * as User from 'ab-viewModels/user.viewModel';
import SocketConnectionCountViewModelModel from 'ab-viewModels/socketConnectionCount.viewModel';
import { EquipmentUnavailabilityDetailsMap, EquipmentUnavailabilityDetails } from 'ab-viewModels/dailyEquipmentStatus.viewModel';
import { EmployeeUnavailabilityDetailsMap, EmployeeUnavailabilityDetails } from 'ab-viewModels/dailyEmployeeStatus.viewModel';
import ScheduleBoardEmployeeModalVM from 'ab-viewModels/scheduleBoardEmployeeModal.viewModel';
import ScheduleBoardEquipmentModalVM from 'ab-viewModels/scheduleBoardEquipmentModal.viewModel';
import ScheduleBoardTemporaryEmployeeModalVM from 'ab-viewModels/scheduleBoardTemporaryEmployeeModal.viewModel';
import { ScheduleBoardWorkOrdersViewModel } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as WorkOrderActions from 'af-actions/workOrder';
import * as EmployeeActions from 'af-actions/employee';
import * as EquipmentActions from 'af-actions/equipment';
import * as CompanyActions from 'af-actions/companies';
import * as GeneralActions from 'af-actions/general';
import * as NotifyActions from 'af-actions/notify';

import RefreshModal from 'af-components/RefreshModal';

import Board from 'af-root/scenes/Company/ScheduleBoard/DailyView/Board';
import Loading from 'af-root/scenes/Company/ScheduleBoard/DailyView/Loading';
import Header from 'af-root/scenes/Company/ScheduleBoard/Shared/Header';
import Toolbar from 'af-root/scenes/Company/ScheduleBoard/Shared/Toolbar';
import EmployeeModal from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceModals/EmployeeModal';
import EmployeeWorkOrderHistoryModal from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceModals/EmployeeWorkOrderHistoryModal';
import EquipmentModal from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceModals/EquipmentModal';
import TemporaryEmployeeModal from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceModals/TemporaryEmployeeModal';
import OrderInfoModals from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfo/OrderInfoModals';
import LaborModal from 'af-root/scenes/Company/ScheduleBoard/Shared/LaborModal';
import EquipmentPlaceholderModal from 'af-root/scenes/Company/ScheduleBoard/Shared/EquipmentPlaceholderModal';
import WorkOrderNoteEditModal from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfo/WorkOrderNoteModal';
import ResourceDownConfirmationModal from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceDownConfirmationModal';
import EquipmentDownModal, { EquipmentDownForm } from 'af-root/scenes/Company/ScheduleBoard/Shared/EquipmentDownModal';
import EmployeeDownModal from 'af-root/scenes/Company/ScheduleBoard/Shared/EmployeeDownModal';

import { RootState } from 'af-reducers';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';
import socket from 'af-utils/socket.util';
import { withSettings } from 'af-utils/settings.util';

import { isAllowed } from 'ab-utils/auth.util';

import EquipmentWorkOrderHistoryModal from '../Shared/ResourceModals/EquipmentWorkOrderHistoryModal';

type MomentType = ReturnType<typeof normalizeDateToMoment>;

type OnSubmitCreateAssignmentMapType = {
	[T in keyof typeof AssignableResourceType]: (
		dueDate: string,
		workOrderId: number,
		resource: ScheduleBoardEmployeeModalVM | ScheduleBoardEquipmentModalVM
	) => () => void;
};

type OnCloseCreateAssignmentMapType = {
	[T in keyof typeof AssignableResourceType]: (resource: ScheduleBoardEmployeeModalVM | ScheduleBoardEquipmentModalVM, date: string) => () => void;
};

interface LocationStateProps extends CustomLocationState {
	lastOpenedOrderCode: string;
}

interface SettingProps {
	selectedDate: MomentType;
}

interface StateProps {
	showRefreshModal: boolean;
	zoomLevel: number;
	userData: User.UserData;
	companyData: User.CompanyData;
	/** SB `date` prop or settings prop, `MM-DD-YYYY` */
	date: string;
	isFilterApplied: boolean;
	copiedEmployeeId: Nullable<number>;
	copiedEquipmentId: Nullable<number>;
	copiedPlaceholderId: Nullable<number>;
	copiedTemporaryEmployeeId: Nullable<number>;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	shouldLoadResources: boolean;
	workOrdersUpdating: boolean;
	isBoardLoading: boolean;
	equipmentUnavailabilityDetails: EquipmentUnavailabilityDetailsMap | undefined;
	employeeUnavailabilityDetails: EmployeeUnavailabilityDetailsMap | undefined;
	workOrdersByCode: Nullable<ScheduleBoardWorkOrdersViewModel>;
}

interface DispatchProps {
	scheduleBoardActions: typeof ScheduleBoardActions;
	generalActions: typeof GeneralActions;
	workOrderActions: typeof WorkOrderActions;
	companyActions: typeof CompanyActions;
	notifyActions: typeof NotifyActions;
	equipmentActions: typeof EquipmentActions;
	employeeActions: typeof EmployeeActions;
}

type OwnProps = CustomRouteComponentProps<Record<string, string>, Record<string, unknown>, LocationStateProps>;
type ConnectOwnProps = SettingProps & OwnProps;
type Props = ConnectOwnProps & StateProps & DispatchActionsMapped<DispatchProps>;

interface State {
	showEquipmentDownFormModal: boolean;
	showEmployeeDownFormModal: boolean;
	showResourceDownConfirmationModal: boolean;
	showEmployeeWOHistoryModal: boolean;
	showEmployeeModal: boolean;
	showEquipmentWOHistoryModal: boolean;
	showEquipmentModal: boolean;
	dragElement: Nullable<ScheduleBoardDragRequestModel>;
	equipmentForUpdate: Nullable<EquipmentDownRequestModel>;
	employeeForUpdate: Nullable<EmployeeDownRM>;
	downConfirmationType: Nullable<UnavailabilityReasonScope>;
	workOrderModalId: Nullable<number>;
	/** State used for Work Order Note Modal */
	workOrderNoteModalCode: Nullable<string>;
	workOrderNoteModalDate: Nullable<string>;
	/** State used for Create Resource Assignment Modal */
	showResourceAssignConfirmationModal: boolean;
	resourceAssignModalType: Nullable<AssignableResourceType>;
	resourceAssignModalOnSubmit: Nullable<() => void>;
	resourceAssignModalOnClose: Nullable<() => void>;
	/** State used for Employee Modal */
	employeeModal: Nullable<ScheduleBoardEmployeeModalVM>;
	employeeModalDate: Nullable<string>;
	/** State used for Equipment Modal */
	equipmentModal: Nullable<ScheduleBoardEquipmentModalVM>;
	equipmentModalDate: Nullable<string>;
	/** State used for Temporary employee Modal */
	temporaryEmployeeModal: Nullable<ScheduleBoardTemporaryEmployeeModalVM>;
	temporaryEmployeeModalDate: Nullable<string>;
}

class DailyView extends React.Component<Props, State> {
	state: State = {
		showEquipmentDownFormModal: false,
		showEmployeeDownFormModal: false,
		showResourceDownConfirmationModal: false,
		showResourceAssignConfirmationModal: false,
		showEmployeeWOHistoryModal: false,
		showEmployeeModal: false,
		showEquipmentWOHistoryModal: false,
		showEquipmentModal: false,
		dragElement: null,
		equipmentForUpdate: null,
		employeeForUpdate: null,
		downConfirmationType: null,
		workOrderModalId: null,
		workOrderNoteModalCode: null,
		workOrderNoteModalDate: null,
		resourceAssignModalType: null,
		resourceAssignModalOnSubmit: null,
		resourceAssignModalOnClose: null,
		employeeModal: null,
		employeeModalDate: null,
		equipmentModal: null,
		equipmentModalDate: null,
		temporaryEmployeeModal: null,
		temporaryEmployeeModalDate: null,
	};

	CREATE_ASSIGNMENT_ON_SUBMIT_MAP: Partial<OnSubmitCreateAssignmentMapType> = {
		[AssignableResourceType.EMPLOYEE]: (dueDate: string, workOrderId: number, resource: ScheduleBoardEmployeeModalVM) => {
			return async () => {
				await DailyView.createEmployeeAssignment(dueDate, workOrderId, resource.id);
				this.closeResourceAssignModal();
				this.setEmployeeModalData(resource, dueDate);
			};
		},
		[AssignableResourceType.EQUIPMENT]: (dueDate: string, workOrderId: number, resource: ScheduleBoardEquipmentModalVM) => {
			return async () => {
				await DailyView.createEquipmentAssignment(dueDate, workOrderId, resource.id);
				this.closeResourceAssignModal();
				this.setEquipmentModalData(resource, dueDate);
			};
		},
	};

	CREATE_ASSIGNMENT_ON_CLOSE_MAP: Partial<OnCloseCreateAssignmentMapType> = {
		[AssignableResourceType.EMPLOYEE]: (resource: ScheduleBoardEmployeeModalVM, date: string) => {
			return () => {
				this.closeResourceAssignModal();
				this.setEmployeeModalData(resource, date);
			};
		},
		[AssignableResourceType.EQUIPMENT]: (resource: ScheduleBoardEquipmentModalVM, date: string) => {
			return () => {
				this.closeResourceAssignModal();
				this.setEquipmentModalData(resource, date);
			};
		},
	};

	static createPlaceholderAssignment = (
		dueDate: string,
		workOrderId: number,
		workOrderPlaceholderId: number,
		dragElement?: ScheduleBoardDragRequestModel
	) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_PLACEHOLDER_ASSIGNMENT, { dueDate, workOrderId, workOrderPlaceholderId, dragElement });
	};

	static createEmployeeAssignment = async (dueDate: string, workOrderId: number, employeeId: number, dragElement?: ScheduleBoardDragRequestModel) => {
		await socket.connection?.emitWithPromise(
			SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_EMPLOYEE_ASSIGNMENT,
			null,
			{ dueDate, workOrderId, employeeId, dragElement }
		);
	};

	static removeEmployeeAssignment = (dueDate: string, workOrderId: number, workOrderCode: string, employeeId: number) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.REMOVE_EMPLOYEE_ASSIGNMENT,
			{ dueDate, workOrderId, workOrderCode, employeeId }
		);
	};

	static createEquipmentAssignment = async (dueDate: string, workOrderId: number, equipmentId: number, dragElement?: ScheduleBoardDragRequestModel) => {
		await socket.connection?.emitWithPromise(
			SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_EQUIPMENT_ASSIGNMENT,
			null,
			{ dueDate, workOrderId, equipmentId, dragElement }
		);
	};

	static removeEquipmentAssignment = (dueDate: string, workOrderId: number, workOrderCode: string, equipmentId: number) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.REMOVE_EQUIPMENT_ASSIGNMENT, { dueDate, workOrderId, workOrderCode, equipmentId });
	};

	static emitEquipmentDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		const emptyDownData = {
			returnDate: null,
			currentDate: null,
			downNotes: null,
			priority: null,
			statusId: null,
			unavailabilityReason: null,
		};
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EQUIPMENT_DRAG_END, { ...emptyDownData, ...dragElement });
	};

	static emitEmployeeDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EMPLOYEE_DRAG_END, dragElement);
	};

	static emitTemporaryEmployeeDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.TEMPORARY_EMPLOYEE_DRAG_END, dragElement);
	};

	static createLaborPlaceholder = (data: CreateLaborPlaceholderForm) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_LABOR_PLACEHOLDER, data);
	};

	static createEquipmentPlaceholder = (data: CreateEquipmentPlaceholderForm) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_EQUIPMENT_PLACEHOLDER, data);
	};

	static resetTimer = () => {
		socket.connection?.resetTimeout();
	};

	async componentDidMount() {
		const { scheduleBoardActions, companyActions, date, shouldLoadResources, generalActions } = this.props;
		const loadedDates = scheduleBoardActions.getLoadedDates();

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD, this.reloadBoard);

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.DAILY_VIEW_CONNECTIONS_COUNT, this.connectionCount);

		ScheduleBoardUtil.subscribeDailySocketEvents(generalActions, scheduleBoardActions);

		scheduleBoardActions.setScheduleBoardView(ScheduleBoardView.DAILY_VIEW);
		scheduleBoardActions.updateScheduleBoardQuery(''); // clear any leftover search
		scheduleBoardActions.updateScheduleBoardDate(date);
		await companyActions.getCompany();

		if (date) {
			if (shouldLoadResources) {
				ScheduleBoardUtil.loadResources();
			}

			if (loadedDates[date]) {
				ScheduleBoardUtil.activateDailyView(date, loadedDates);
			} else {
				ScheduleBoardUtil.joinDailyView(date, loadedDates);
			}
			scheduleBoardActions.updateScheduleBoardDate(date);
		}
	}

	componentDidUpdate(prevProps: Props) {
		const { scheduleBoardActions, date, zoomLevel, shouldLoadResources } = this.props;
		const loadedDates = scheduleBoardActions.getLoadedDates();

		if (prevProps.date !== date && socket.connection?.isConnected()) {
			ScheduleBoardUtil.leaveBoard();
			if (shouldLoadResources) {
				ScheduleBoardUtil.loadResources();
			}
			ScheduleBoardUtil.joinDailyView(date, loadedDates);
			scheduleBoardActions.clearFilters();
			scheduleBoardActions.setZoomLevel(zoomLevel);
		} else if (ScheduleBoardUtil.shouldLoadResourcesOnUpdate(prevProps, this.props)) {
			ScheduleBoardUtil.loadResources();
		}
	}

	componentWillUnmount() {
		if (socket.connection) {
			ScheduleBoardUtil.leaveBoard();
			socket.connection.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.DAILY_VIEW_CONNECTIONS_COUNT);
			socket.connection.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD);
		}
	}

	reloadBoard = () => {
		const { date } = this.props;
		ScheduleBoardUtil.activateDailyView(date, {});
	};

	connectionCount = (dueDate: string, data: SocketConnectionCountViewModelModel) => {
		const { date, scheduleBoardActions } = this.props;
		if (dueDate === date) {
			scheduleBoardActions.getSocketConnectionCount(data);
		}
	};

	updateWorkOrderEmployeePerDiem = (workOrderId: number, employeeId: number, perDiem: boolean) => {
		const { date: dueDate } = this.props;
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.UPDATE_EMPLOYEE_PER_DIEM, { workOrderId, employeeId, perDiem, dueDate });
	};

	sendNotification = async (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => {
		const { notifyActions } = this.props;

		await notifyActions.sendTemplateNotificationMultipleWO({
			employeeSmsIds: notifyBySms,
			employeeEmailIds: notifyByEmail,
			dueDate: formatDate(dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY),
			notificationType: TemplateNotificationEnum.AVAILABLE_EMPLOYEE,
		});
	};

	scheduleAutoNotify = async (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => {
		const { notifyActions } = this.props;
		await notifyActions.scheduleForAutoNotify({
			employeeSmsIds: notifyBySms,
			employeeEmailIds: notifyByEmail,
			dueDate: dueDate,
			notificationType: TemplateNotificationEnum.AVAILABLE_EMPLOYEE,
		});
	};

	onDragStart = (dragData: DragStart) => {
		const { scheduleBoardActions, date: dueDate } = this.props;

		DailyView.resetTimer();

		const { dragElementType, dragElement } = scheduleBoardActions.onDragStart(dueDate, dragData);
		if (!dragElement) {
			return;
		}
		switch (dragElementType) {
			case DailyViewDragElementType.WORK_ORDER:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.WORK_ORDER_DRAG_START, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_EMPLOYEE:
			case DailyViewDragElementType.EMPLOYEE:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EMPLOYEE_DRAG_START, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_EQUIPMENT:
			case DailyViewDragElementType.EQUIPMENT:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EQUIPMENT_DRAG_START, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_PLACEHOLDER:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.PLACEHOLDER_DRAG_START, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_TEMPORARY_EMPLOYEE:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.TEMPORARY_EMPLOYEE_DRAG_START, dragElement);
				return;
			default:
				// this should never happen
				return;
		}
	};

	onDragEnd = (result: DropResult) => {
		const { scheduleBoardActions, copiedEmployeeId, copiedEquipmentId, copiedPlaceholderId, copiedTemporaryEmployeeId, date } = this.props;
		const isCopying = !!copiedEmployeeId || !!copiedEquipmentId || !!copiedPlaceholderId || !!copiedTemporaryEmployeeId;

		DailyView.resetTimer();

		const updateOnDropResource = {
			dragElementType: ScheduleBoardUtil.updateOnDropResourceElementTypeCondition,
			dragElement: ScheduleBoardUtil.updateOnDropResourceElementCondition,
		};

		const {
			dragElementType,
			dragElement,
			destinationWorkOrderId,
			isRealCopy,
		} = scheduleBoardActions.onDragEnd(date, result, isCopying, null, false, updateOnDropResource);
		if (!dragElement) {
			return;
		}

		if (isRealCopy) {
			switch (dragElementType) {
				case DailyViewDragElementType.WORK_ORDER_EMPLOYEE:
					if (!destinationWorkOrderId || !copiedEmployeeId) {
						throw new Error('No destination defined');
					}
					DailyView.createEmployeeAssignment(date, destinationWorkOrderId, copiedEmployeeId, dragElement);
					break;
				case DailyViewDragElementType.WORK_ORDER_EQUIPMENT:
					if (!destinationWorkOrderId || !copiedEquipmentId) {
						throw new Error('No destination defined');
					}
					DailyView.createEquipmentAssignment(date, destinationWorkOrderId, copiedEquipmentId, dragElement);
					break;
				case DailyViewDragElementType.WORK_ORDER_PLACEHOLDER:
					if (!destinationWorkOrderId || !copiedPlaceholderId) {
						throw new Error('No destination defined');
					}
					DailyView.createPlaceholderAssignment(date, destinationWorkOrderId, copiedPlaceholderId, dragElement);
					break;
				case DailyViewDragElementType.WORK_ORDER_TEMPORARY_EMPLOYEE:
					if (!destinationWorkOrderId || !copiedTemporaryEmployeeId) {
						throw new Error('No destination defined');
					}
					this.createTemporaryEmployeeAssignment(destinationWorkOrderId, {
						index: dragElement.destinationIndex,
						temporaryEmployeeId: copiedTemporaryEmployeeId,
					});
					break;
				default:
				// this should never happen
			}
		}

		switch (dragElementType) {
			case DailyViewDragElementType.WORK_ORDER:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.WORK_ORDER_DRAG_END, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_EMPLOYEE:
			case DailyViewDragElementType.EMPLOYEE:
				this.onEmployeeDragEnd(dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_EQUIPMENT:
			case DailyViewDragElementType.EQUIPMENT:
				this.onEquipmentDragEnd(dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_PLACEHOLDER:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.PLACEHOLDER_DRAG_END, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_TEMPORARY_EMPLOYEE:
				this.onTemporaryEmployeeDragEnd(dragElement);
				return;
			default:
				// this should never happen
				return;
		}
	};

	onEquipmentDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		const { equipmentUnavailabilityDetails, scheduleBoardActions } = this.props;
		const { destinationDroppableId, sourceDroppableId, itemId } = dragElement;

		const shouldOpenConfirmationModal = destinationDroppableId
			&& ScheduleBoardUtil.shouldOpenResourceDownConfirmationModal(sourceDroppableId, destinationDroppableId);
		const shouldOpenFormModal = destinationDroppableId && ScheduleBoardUtil.shouldOpenResourceDownFormModal(destinationDroppableId);
		const shouldOpenResourceAvailableWarningModal = destinationDroppableId
			&& ScheduleBoardUtil.shouldOpenResourceAvailableWarningModal(sourceDroppableId, destinationDroppableId);

		scheduleBoardActions.enableEquipmentItem(dragElement.originalItemId);

		if (shouldOpenConfirmationModal) {
			this.setState(() => ({ showResourceDownConfirmationModal: true, dragElement, downConfirmationType: UnavailabilityReasonScope.EQUIPMENT }));
		} else if (shouldOpenFormModal) {
			const details: EquipmentUnavailabilityDetails = equipmentUnavailabilityDetails?.[itemId];
			if (details) {
				const _statusId = ScheduleBoardUtil.getToolbarCodeFromUniqueCode(destinationDroppableId);
				if (!_statusId) {
					throw new Error('Unavailable status must have an ID');
				}
				const equipmentForUpdate = {
					downNotes: details.notes ?? null,
					returnDate: (details.returnDate && formatDate(details.returnDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)) ?? null,
					currentDate: formatDate(new Date(), TimeFormat.DATE_ONLY),
					id: Number(itemId),
					priority: details.priority ?? Priority.MEDIUM,
					statusId: +_statusId,
					unavailabilityReason: details.unavailabilityReason ?? null,
				};
				this.setState(() => ({ showEquipmentDownFormModal: true, dragElement, equipmentForUpdate }));
			} else {
				this.setState(() => ({ showEquipmentDownFormModal: true, dragElement }));
			}
		} else if (shouldOpenResourceAvailableWarningModal) {
			this.openResourceAssignModal(
				AssignableResourceType.EQUIPMENT,
				this.onConfirmResourceAssignConfirmationModal,
				this.onCloseResourceAssignConfirmationModal,
				dragElement
			);
		} else {
			DailyView.emitEquipmentDragEnd(dragElement);
		}
	};

	onEmployeeDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		const { employeeUnavailabilityDetails, date } = this.props;
		const { destinationDroppableId, sourceDroppableId, itemId } = dragElement;

		const shouldOpenConfirmationModal = !!destinationDroppableId
			&& ScheduleBoardUtil.shouldOpenResourceDownConfirmationModal(sourceDroppableId, destinationDroppableId);
		const shouldOpenFormModal = !!destinationDroppableId && ScheduleBoardUtil.shouldOpenResourceDownFormModal(destinationDroppableId);
		const shouldOpenResourceAvailableWarningModal = !!destinationDroppableId
			&& ScheduleBoardUtil.shouldOpenResourceAvailableWarningModal(sourceDroppableId, destinationDroppableId);

		if (shouldOpenConfirmationModal) {
			this.setState(() => ({ showResourceDownConfirmationModal: true, dragElement, downConfirmationType: UnavailabilityReasonScope.EMPLOYEE }));
		} else if (shouldOpenFormModal) {
			const details: EmployeeUnavailabilityDetails | undefined = employeeUnavailabilityDetails?.[itemId];
			if (details) {
				const _unavailableStatusId = ScheduleBoardUtil.getToolbarCodeFromUniqueCode(destinationDroppableId);
				if (!_unavailableStatusId) {
					throw new Error('Unavailable status must have an ID');
				}
				const employeeForUpdate: EmployeeDownRM = {
					id: +itemId,
					unavailableStatusId: +_unavailableStatusId,
					dueDate: date,
					unavailabilityReason: { label: details.unavailabilityReason },
					returnDate: details.returnDate && formatDate(details.returnDate, TimeFormat.DATE_ONLY),
					currentDate: formatDate(new Date(), TimeFormat.DATE_ONLY),
				};
				this.setState(() => ({ showEmployeeDownFormModal: true, dragElement, employeeForUpdate }));
			} else {
				this.setState(() => ({ showEmployeeDownFormModal: true, dragElement }));
			}
		} else if (shouldOpenResourceAvailableWarningModal) {
			this.openResourceAssignModal(
				AssignableResourceType.EMPLOYEE,
				this.onConfirmResourceAssignConfirmationModal,
				this.onCloseResourceAssignConfirmationModal,
				dragElement
			);
		} else {
			DailyView.emitEmployeeDragEnd(dragElement);
		}
	};

	onTemporaryEmployeeDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		const { destinationDroppableId, sourceDroppableId, originalItemId } = dragElement;

		if (
			(destinationDroppableId && ScheduleBoardUtil.isOnBoard(destinationDroppableId))
			|| (destinationDroppableId && ScheduleBoardUtil.isInToolbar(destinationDroppableId))
		) {
			DailyView.emitTemporaryEmployeeDragEnd(dragElement);
			return;
		}
		const { workOrdersByCode } = this.props;
		const workOrderCode = ScheduleBoardUtil.getUniqueCodeFromDroppableId(sourceDroppableId);

		const workOrderId = workOrderCode && workOrdersByCode?.[workOrderCode]?.id;
		if (!workOrderId) {
			return;
		}
		this.remoteTemporaryEmployeeAssignment(workOrderId, +originalItemId);
		DailyView.emitTemporaryEmployeeDragEnd(dragElement);
	};

	closeResourceDownConfirmationAction = () => {
		const { scheduleBoardActions } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Drag element not defined');
		}

		scheduleBoardActions.onEquipmentDragEndCancellation(dragElement);

		const socketEvent: ScheduleBoardDragRequestModel = {
			...dragElement,
			destinationIndex: dragElement.sourceIndex,
			destinationDroppableId: dragElement.sourceDroppableId,
		};
		DailyView.emitEquipmentDragEnd(socketEvent);
		this.setState(() => ({ showResourceDownConfirmationModal: false, dragElement: null, downConfirmationType: null }));
	};

	confirmResourceDownAction = () => {
		const { downConfirmationType } = this.state;

		if (downConfirmationType === UnavailabilityReasonScope.EQUIPMENT) {
			this.setState(() => ({
				showResourceDownConfirmationModal: false,
				showEquipmentDownFormModal: true,
				downConfirmationType: null,
			}));
		} else {
			this.setState(() => ({
				showResourceDownConfirmationModal: false,
				showEmployeeDownFormModal: true,
				downConfirmationType: null,
			}));
		}
	};

	submitEquipmentDownFormModal = (form: DownEquipmentRM) => {
		const { scheduleBoardActions, equipmentActions, date } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Drag element not defined');
		}

		const isNotEdit = !form.unavailableStatusId;
		const newStatusId = dragElement.destinationDroppableId && ScheduleBoardUtil.getToolbarCodeFromUniqueCode(dragElement.destinationDroppableId);
		const _newStatusId = newStatusId ? +newStatusId : null;

		const shouldRunOnDragEndConfirmation = !!_newStatusId && form?.unavailableStatusId !== _newStatusId;

		if (isNotEdit && !!_newStatusId) {
			form.unavailableStatusId = _newStatusId;
		}
		form.dueDate = formatDate(date);
		form.currentDate = formatDate(new Date(), TimeFormat.DATE_ONLY);

		equipmentActions.downEquipment(+dragElement.originalItemId, form);
		// skip if source and destination are the same
		if (shouldRunOnDragEndConfirmation) {
			scheduleBoardActions.onResourceDragEndConfirmation(dragElement);
		}
		this.setState(() => ({ showEquipmentDownFormModal: false, dragElement: null, equipmentForUpdate: null }));
	};

	submitEmployeeDownFormModal = (form: EmployeeDownRM) => {
		const { scheduleBoardActions, employeeActions, date } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Drag element not defined');
		}

		const isNotEdit = !form.unavailableStatusId;
		const newStatusId = dragElement.destinationDroppableId && ScheduleBoardUtil.getToolbarCodeFromUniqueCode(dragElement.destinationDroppableId);
		const _newStatusId = newStatusId ? +newStatusId : null;

		const shouldRunOnDragEndConfirmation = !!_newStatusId && form?.unavailableStatusId !== _newStatusId;

		if (isNotEdit && _newStatusId) {
			form.unavailableStatusId = _newStatusId;
		}
		form.dueDate = formatDate(date, TimeFormat.DATE_ONLY);
		form.currentDate = formatDate(new Date(), TimeFormat.DATE_ONLY);

		employeeActions.downEmployee(+dragElement.originalItemId, form);
		// skip if source and destination are the same
		if (shouldRunOnDragEndConfirmation) {
			scheduleBoardActions.onResourceDragEndConfirmation(dragElement);
		}
		this.setState(() => ({ showEmployeeDownFormModal: false, dragElement: null, employeeForUpdate: null }));
	};

	setWorkOrderModalId = (workOrderModalId: number) => {
		this.setState(() => ({ workOrderModalId }));
	};

	setWorkOrderNoteModalData = (workOrderCode: Nullable<string>, date: Nullable<string>) => {
		this.setState(() => ({ workOrderNoteModalCode: workOrderCode, workOrderNoteModalDate: date }));
	};

	setEmployeeModalVisibility = (showEmployeeModal: boolean) => {
		this.setState(() => ({ showEmployeeModal }));
	};

	setEquipmentModalVisibility = (showEquipmentModal: boolean) => {
		this.setState(() => ({ showEquipmentModal }));
	};

	setEmployeeModalData = (employee: Nullable<ScheduleBoardEmployeeModalVM>, date: Nullable<string>) => {
		this.setState(() => ({ employeeModal: employee, employeeModalDate: date }));
	};

	setEquipmentModalData = (equipment: Nullable<ScheduleBoardEquipmentModalVM>, date: Nullable<string>) => {
		this.setState(() => ({ equipmentModal: equipment, equipmentModalDate: date }));
	};

	setTemporaryEmployeeModalData = (employee: ScheduleBoardTemporaryEmployeeModalVM, date: string) => {
		this.setState(() => ({ temporaryEmployeeModal: employee, temporaryEmployeeModalDate: date }));
	};

	setEmployeeWorkOrderHistoryModalVisibility = (showEmployeeWOHistoryModal: boolean) => {
		this.setState(() => ({ showEmployeeWOHistoryModal }));
	};

	setEquipmentWorkOrderHistoryModalVisibility = (showEquipmentWOHistoryModal: boolean) => {
		this.setState(() => ({ showEquipmentWOHistoryModal }));
	};

	closeEquipmentDownModal = () => {
		const { scheduleBoardActions } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Drag element not defined');
		}

		scheduleBoardActions.onEquipmentDragEndCancellation(dragElement);

		const socketEvent: ScheduleBoardDragRequestModel = {
			...dragElement,
			destinationIndex: dragElement.sourceIndex,
			destinationDroppableId: dragElement.sourceDroppableId,
		};
		DailyView.emitEquipmentDragEnd(socketEvent);
		this.setState(() => ({ showEquipmentDownFormModal: false, dragElement: null, equipmentForUpdate: null }));
	};

	closeEmployeeDownModal = () => {
		const { scheduleBoardActions } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Drag element not defined');
		}

		scheduleBoardActions.onEmployeeDragEndCancellation(dragElement);

		const socketEvent: ScheduleBoardDragRequestModel = {
			...dragElement,
			destinationIndex: dragElement.sourceIndex,
			destinationDroppableId: dragElement.sourceDroppableId,
		};
		DailyView.emitEmployeeDragEnd(socketEvent);
		this.setState(() => ({ showEmployeeDownFormModal: false, dragElement: null, employeeForUpdate: null }));
	};

	openResourceAssignModal = (
		type: AssignableResourceType,
		onSubmit: () => void,
		onClose: () => void,
		dragElement?: ScheduleBoardDragRequestModel
	) => {
		this.setState(() => ({
			showResourceAssignConfirmationModal: true,
			resourceAssignModalType: type,
			resourceAssignModalOnSubmit: onSubmit,
			resourceAssignModalOnClose: onClose,
			dragElement: dragElement ? dragElement : this.state.dragElement,
		}));
	};

	closeResourceAssignModal = () => {
		this.setState(() => ({
			showResourceAssignConfirmationModal: false,
			resourceAssignModalType: null,
			resourceAssignModalOnSubmit: null,
			resourceAssignModalOnClose: null,
			dragElement: null,
		}));
	};

	cancelOrder = async (workOrderId: string, cancelForm: WorkOrderCancelForm) => {
		const { workOrderActions } = this.props;
		await workOrderActions.cancelWorkOrder(+workOrderId, cancelForm);
	};

	pauseOrder = async (workOrderId: string, form: WorkOrderPauseForm) => {
		const { workOrderActions } = this.props;
		await workOrderActions.pauseWorkOrder(+workOrderId, form);
	};

	resumeOrder = async (workOrderId: string) => {
		const { workOrderActions } = this.props;
		await workOrderActions.resumeWorkOrder(+workOrderId);
	};

	addBlankWorkOrder = async (dueDate: string, index: number) => {
		const { scheduleBoardActions } = this.props;
		await scheduleBoardActions.addBlankWorkOrder(dueDate, index);
	};

	removeBlankWorkOrder = async (dueDate: string, index: number) => {
		const { scheduleBoardActions } = this.props;
		await scheduleBoardActions.removeBlankWorkOrder(dueDate, index);
	};

	publishOrder = async (workOrderId: string, workOrderPublishForm: WorkOrderPublishForm) => {
		const { workOrderActions } = this.props;

		await workOrderActions.publishWorkOrder(+workOrderId, workOrderPublishForm?.delayReason);
	};

	deleteOrder = async (workOrderId: string) => {
		const { workOrderActions } = this.props;

		await workOrderActions.deleteWorkOrder(+workOrderId);
	};

	forceUnlockOrder = (workOrderId: string) => {
		const { date } = this.props;
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.FORCE_UNLOCK_WORK_ORDER, { workOrderId: +workOrderId, dueDate: date });
	};

	generatePreview = async (workOrderId: string) => {
		const { location: { state: { orgAlias } }, companyData, workOrderActions } = this.props;
		await workOrderActions.generateWorkOrderConfirmation(workOrderId, orgAlias, companyData.name);
	};

	onCloseRefreshModal = () => {
		const { scheduleBoardActions } = this.props;
		scheduleBoardActions.setRefreshModalVisibility(false);
	};

	closeWorkOrderNoteModal = () => {
		this.setWorkOrderNoteModalData(null, null);
	};

	openWorkOrderHistoryModal = () => {
		this.setEmployeeModalVisibility(false);
		this.setEmployeeWorkOrderHistoryModalVisibility(true);
	};

	openEquipmentWorkOrderHistoryModal = () => {
		this.setEquipmentModalVisibility(false);
		this.setEquipmentWorkOrderHistoryModalVisibility(true);
	};

	closeEmployeeWOModal = () => {
		this.setEmployeeModalVisibility(true);
		this.setEmployeeWorkOrderHistoryModalVisibility(false);
	};

	closeEquipmentWOModal = () => {
		this.setEquipmentModalVisibility(true);
		this.setEquipmentWorkOrderHistoryModalVisibility(false);
	};

	// Methods used for Submit/Close in Create Resource Assignment Modal for SB stuff
	onConfirmResourceAssignConfirmationModal = () => {
		const { scheduleBoardActions } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Drag element not defined');
		}

		if (ScheduleBoardUtil.isEmployee(dragElement.sourceDroppableId)) {
			DailyView.emitEmployeeDragEnd(dragElement);
		} else if (ScheduleBoardUtil.isEquipment(dragElement.sourceDroppableId)) {
			DailyView.emitEquipmentDragEnd(dragElement);
		}
		scheduleBoardActions.onResourceDragEndConfirmation(dragElement);
		this.closeResourceAssignModal();
	};

	onCloseResourceAssignConfirmationModal = () => {
		const { scheduleBoardActions } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Drag element not defined');
		}

		const socketEvent: ScheduleBoardDragRequestModel = {
			...dragElement,
			destinationIndex: dragElement.sourceIndex,
			destinationDroppableId: dragElement.sourceDroppableId,
		};

		if (ScheduleBoardUtil.isEmployee(dragElement.sourceDroppableId)) {
			DailyView.emitEmployeeDragEnd(socketEvent);
		} else if (ScheduleBoardUtil.isEquipment(dragElement.sourceDroppableId)) {
			DailyView.emitEquipmentDragEnd(socketEvent);
		}

		const destinationUniqueCode = dragElement.destinationDroppableId && ScheduleBoardUtil.getUniqueCodeFromDroppableId(dragElement.destinationDroppableId);
		const destinationDueDate = dragElement.destinationDroppableId && ScheduleBoardUtil.getDueDateFromDroppableId(dragElement.destinationDroppableId);
		if (!destinationDueDate || !destinationUniqueCode) {
			throw new Error('Destination incorrectly defined');
		}

		scheduleBoardActions.setCopiedResourcePlaceholder(destinationDueDate, destinationUniqueCode, undefined, true);
		this.closeResourceAssignModal();
	};

	// Methods used for Submit/Close in Create Resource Assignment Modal for Resource Modals
	createAssignment = (
		type: AssignableResourceType,
		date: string,
		workOrderId: number,
		isAvailable: boolean,
		resource: ScheduleBoardEquipmentModalVM | ScheduleBoardEmployeeModalVM
	) => {
		const createAssignment = this.CREATE_ASSIGNMENT_ON_SUBMIT_MAP[type]?.(date, workOrderId, resource);
		if (!createAssignment) {
			throw new Error('Modal functions not found');
		}

		if (!isAvailable) {
			// close modal
			if (type === AssignableResourceType.EMPLOYEE) {
				this.setEmployeeModalData(null, null);
				this.setEmployeeModalVisibility(false);
			} else if (type === AssignableResourceType.EQUIPMENT) {
				this.setEquipmentModalData(null, null);
				this.setEquipmentModalVisibility(false);
			}

			const closeModal = this.CREATE_ASSIGNMENT_ON_CLOSE_MAP[type]?.(resource, date);

			if (!closeModal) {
				throw new Error('Modal functions not found');
			}
			this.openResourceAssignModal(type, createAssignment, closeModal);

			return false;
		}
		createAssignment();
		return true;
	};

	createTemporaryEmployeeAssignment = (workOrderId: number, data: CreateTemporaryEmployeeAssignmentRM) => {
		const { workOrderActions } = this.props;
		workOrderActions.createTemporaryEmployeeAssignment(workOrderId, data);
	};

	remoteTemporaryEmployeeAssignment = (workOrderId: number, workOrderResourceLookupId: number) => {
		const { workOrderActions } = this.props;
		workOrderActions.removeTemporaryEmployeeAssignment(workOrderId, workOrderResourceLookupId);
	};

	render() {
		const {
			showRefreshModal,
			zoomLevel,
			location: { state: { orgAlias }, search },
			companyData,
			isFilterApplied,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			hasPermissionsToSendNotifications,
			history,
			location,
			date,
			isBoardLoading,
		} = this.props;
		const {
			showEquipmentDownFormModal,
			showEmployeeDownFormModal,
			showResourceDownConfirmationModal,
			showResourceAssignConfirmationModal,
			showEmployeeWOHistoryModal,
			showEmployeeModal,
			showEquipmentWOHistoryModal,
			showEquipmentModal,
			dragElement,
			equipmentForUpdate,
			employeeForUpdate,
			downConfirmationType,
			workOrderModalId,
			workOrderNoteModalCode,
			workOrderNoteModalDate,
			resourceAssignModalType,
			resourceAssignModalOnSubmit,
			resourceAssignModalOnClose,
			equipmentModal,
			equipmentModalDate,
			employeeModal,
			employeeModalDate,
			temporaryEmployeeModal,
			temporaryEmployeeModalDate,
		} = this.state;

		const lastOpenedOrderCode = ScheduleBoardUtil.parseQueryString(search).order;

		return (
			<div className={`schedule-board zoom-${zoomLevel} ${isFilterApplied ? '--filtered-results' : ''}`}>
				<Header
					companyName={companyData.name}
					isBoardLoading={isBoardLoading}
					orgAlias={orgAlias}
				/>
				{
					isBoardLoading
						?
						<div className="schedule-board-container">
							<Loading zoomLevel={zoomLevel} />
						</div>
						:
						<DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
							<div className="schedule-board-container">
								<Toolbar
									dueDate={date}
									hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
									hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
									isDragAndDropDisabled={isDragAndDropDisabled}
									scheduleAutoNotify={this.scheduleAutoNotify}
									sendNotification={this.sendNotification}
									setEmployeeModalData={this.setEmployeeModalData}
									setEmployeeModalVisibility={this.setEmployeeModalVisibility}
									setEquipmentModalData={this.setEquipmentModalData}
									setEquipmentModalVisibility={this.setEquipmentModalVisibility}
								/>
								<Board
									addBlankWorkOrder={this.addBlankWorkOrder}
									currentWorkOrderModalId={workOrderModalId}
									droppableId={ScheduleBoardUtil.generateDroppableId(
										ScheduleBoardContext.BOARD,
										ScheduleBoardProperty.WORK_ORDER,
										date,
										ScheduleBoardProperty.WORK_ORDER
									)}
									dueDate={date}
									forceUnlockOrder={this.forceUnlockOrder}
									hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
									isDragAndDropDisabled={isDragAndDropDisabled}
									lastOpenedOrderCode={lastOpenedOrderCode}
									removeBlankWorkOrder={this.removeBlankWorkOrder}
									setEmployeeModalData={this.setEmployeeModalData}
									setEmployeeModalVisibility={this.setEmployeeModalVisibility}
									setEquipmentModalData={this.setEquipmentModalData}
									setEquipmentModalVisibility={this.setEquipmentModalVisibility}
									setTemporaryEmployeeModalData={this.setTemporaryEmployeeModalData}
									setWorkOrderModalId={this.setWorkOrderModalId}
									setWorkOrderNoteModalData={this.setWorkOrderNoteModalData}
								/>
							</div>
						</DragDropContext>
				}
				<RefreshModal
					message="Please refresh the page to get latest data."
					onRefresh={this.onCloseRefreshModal}
					showModal={showRefreshModal}
				/>
				<EmployeeModal
					createEmployeeAssignment={this.createAssignment}
					date={employeeModalDate}
					employee={employeeModal}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					openWorkOrderHistoryModal={this.openWorkOrderHistoryModal}
					removeEmployeeAssignment={DailyView.removeEmployeeAssignment}
					setEmployeeModalData={this.setEmployeeModalData}
					setEmployeeModalVisibility={this.setEmployeeModalVisibility}
					showEmployeeModal={showEmployeeModal}
					updateWorkOrderEmployeePerDiem={this.updateWorkOrderEmployeePerDiem}
				/>
				<EquipmentModal
					createEquipmentAssignment={this.createAssignment}
					date={equipmentModalDate}
					equipment={equipmentModal}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					openEquipmentWorkOrderHistoryModal={this.openEquipmentWorkOrderHistoryModal}
					removeEquipmentAssignment={DailyView.removeEquipmentAssignment}
					setEquipmentModalData={this.setEquipmentModalData}
					setEquipmentModalVisibility={this.setEquipmentModalVisibility}
					showEquipmentModal={showEquipmentModal}
				/>
				<TemporaryEmployeeModal
					createAssignment={this.createTemporaryEmployeeAssignment}
					date={temporaryEmployeeModalDate}
					employee={temporaryEmployeeModal}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					removeAssignment={this.remoteTemporaryEmployeeAssignment}
					setTemporaryEmployeeModalData={this.setTemporaryEmployeeModalData}
				/>
				<LaborModal
					onCreateAssignment={this.createTemporaryEmployeeAssignment}
					onCreatePlaceholder={DailyView.createLaborPlaceholder}
					resetTimer={DailyView.resetTimer}
				/>
				<EquipmentPlaceholderModal
					onSubmit={DailyView.createEquipmentPlaceholder}
					resetTimer={DailyView.resetTimer}
				/>
				<ResourceDownConfirmationModal
					dueDate={dragElement?.dueDate}
					onClose={this.closeResourceDownConfirmationAction}
					onSubmit={this.confirmResourceDownAction}
					showModal={showResourceDownConfirmationModal}
					type={downConfirmationType}
				/>
				<EquipmentDownModal
					initialFormValues={EquipmentDownForm.toForm(equipmentForUpdate)}
					onClose={this.closeEquipmentDownModal}
					onSubmit={this.submitEquipmentDownFormModal}
					showModal={showEquipmentDownFormModal}
					showStatusOption={!!equipmentForUpdate}
				/>
				<EmployeeDownModal
					initialFormValues={employeeForUpdate}
					onClose={this.closeEmployeeDownModal}
					onSubmit={this.submitEmployeeDownFormModal}
					showModal={showEmployeeDownFormModal}
					showStatusOption={!!employeeForUpdate}
				/>
				<OrderInfoModals
					cancelOrder={this.cancelOrder}
					companyName={companyData.name}
					currentWorkOrderModalId={workOrderModalId}
					deleteOrder={this.deleteOrder}
					generatePreview={this.generatePreview}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					history={history}
					location={location}
					orgAlias={orgAlias}
					pauseOrder={this.pauseOrder}
					publishOrder={this.publishOrder}
					resumeOrder={this.resumeOrder}
					setWorkOrderModalId={this.setWorkOrderModalId}
				/>
				<WorkOrderNoteEditModal
					closeModal={this.closeWorkOrderNoteModal}
					workOrderCode={workOrderNoteModalCode}
					workOrderDate={workOrderNoteModalDate}
				/>
				{resourceAssignModalOnClose &&
					<ResourceAssignConfirmationModal
						onClose={resourceAssignModalOnClose}
						onSubmit={resourceAssignModalOnSubmit}
						showModal={showResourceAssignConfirmationModal}
						type={resourceAssignModalType}
					/>
				}
				{
					employeeModal &&
					<EmployeeWorkOrderHistoryModal
						closeEmployeeWOModal={this.closeEmployeeWOModal}
						companyName={companyData.name}
						employeeFirstName={employeeModal.firstName}
						employeeFormattedCode={employeeModal.formattedCode}
						employeeId={employeeModal.id}
						employeeLastName={employeeModal.lastName}
						history={history}
						orgAlias={orgAlias}
						showEmployeeWOHistoryModal={showEmployeeWOHistoryModal}

					/>
				}
				{
					equipmentModal &&
					<EquipmentWorkOrderHistoryModal
						closeEquipmentWOModal={this.closeEquipmentWOModal}
						companyName={companyData.name}
						equipmentCode={equipmentModal.code}
						equipmentId={equipmentModal.id}
						equipmentName={equipmentModal.name}
						equipmentSpecification={equipmentModal.specification}
						history={history}
						orgAlias={orgAlias}
						showEquipmentWOHistoryModal={showEquipmentWOHistoryModal}
					/>
				}
			</div>
		);
	}
}

const _hasWorkOrderUpdateSubmit = (formName: string) => formName === WORK_ORDER_FORM || formName === ORDER_COPY_FORM;

function mapStateToProps(state: RootState, ownProps: ConnectOwnProps): StateProps {
	const { userData, companyData } = state.user;
	const { workOrdersSort } = state.scheduleBoard;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const workOrdersUpdating = state.http.submitting.some(_hasWorkOrderUpdateSubmit);
	const workOrdersOnDate = state.scheduleBoard.date
		? state.scheduleBoard.workOrdersByDateDictionary[state.scheduleBoard.date]
		: null;
	const shouldLoadResources = !state.scheduleBoard.resourcesLoaded;
	const isBoardLoading = !workOrdersOnDate?.workOrders || workOrdersUpdating || shouldLoadResources;
	const equipmentUnavailabilityDetails = workOrdersOnDate?.equipmentUnavailabilityDetails;
	const employeeUnavailabilityDetails = workOrdersOnDate?.employeeUnavailabilityDetails;

	const isDragAndDropDisabled: boolean = workOrdersSort !== ScheduleBoardSortType.FREE_REORDERING;
	const hasPermissionsToEditScheduleBoard: boolean = isAllowed(
		PagePermissions.COMPANY.WORK_ORDERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);
	const hasPermissionsToSendNotifications: boolean = isAllowed(
		PagePermissions.COMPANY.COMMUNICATION.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const _dueDate = state.scheduleBoard.date ?? (ownProps.selectedDate && formatDate(ownProps.selectedDate, TimeFormat.DATE_ONLY));
	if (!_dueDate) {
		throw new Error('Due date not provided');
	}

	return {
		userData,
		showRefreshModal: state.general.showRefreshModal || state.scheduleBoard.showRefreshModal,
		companyData,
		copiedEmployeeId: state.scheduleBoard.copiedEmployeeId,
		copiedEquipmentId: state.scheduleBoard.copiedEquipmentId,
		copiedPlaceholderId: state.scheduleBoard.copiedPlaceholderId,
		copiedTemporaryEmployeeId: state.scheduleBoard.copiedTemporaryEmployeeId,
		isFilterApplied: state.scheduleBoard.isFilterApplied,
		date: _dueDate,
		zoomLevel: state.scheduleBoard.zoomLevel,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		hasPermissionsToSendNotifications,
		shouldLoadResources,
		workOrdersUpdating,
		isBoardLoading,
		equipmentUnavailabilityDetails,
		employeeUnavailabilityDetails,
		/** Used to know to which work order id temp employee was dragged */
		workOrdersByCode: workOrdersOnDate?.workOrders ?? null,
	};
}

type ActionType = GeneralActions.GeneralAction | CompanyActions.ActionType | ScheduleBoardActions.ScheduleBoardAction | WorkOrderActions.WorkOrderAction;
function mapDispatchToProps(dispatch: Dispatch<ActionType>): DispatchProps {
	return {
		generalActions: bindActionCreators(GeneralActions, dispatch),
		companyActions: bindActionCreators(CompanyActions, dispatch),
		scheduleBoardActions: bindActionCreators(ScheduleBoardActions, dispatch),
		workOrderActions: bindActionCreators(WorkOrderActions, dispatch),
		notifyActions: bindActionCreators(NotifyActions, dispatch),
		equipmentActions: bindActionCreators(EquipmentActions, dispatch),
		employeeActions: bindActionCreators(EmployeeActions, dispatch),
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDER_SELECTED_DUE_DATE(),
			mappedName: 'selectedDate',
			normalize: normalizeDateToMoment,
			defaultValue: toMomentUtc(new Date()),
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
	])),
	connect<StateProps, DispatchProps, ConnectOwnProps>(mapStateToProps, mapDispatchToProps)
);

export default enhance(DailyView);
