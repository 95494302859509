import { EMAIL_REGEX } from 'acceligent-shared/constants/regex';

import { OrganizationForgottenByEmailRequestModel } from 'ab-requestModels/organizationForgotten.requestModel';

export default function validate(values: OrganizationForgottenByEmailRequestModel) {

	const errors: ValidationErrors = {};

	if (!values.email) {
		errors.email = 'Email is required';
	} else if (!EMAIL_REGEX.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	return errors;
}
