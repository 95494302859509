import * as React from 'react';

import { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import EquipmentSettings, { EquipmentSettingsHint } from 'ab-enums/equipmentSettings.enum';

import PreviewItem from '../../Shared/PreviewItem';
import SettingPreview from '../../Shared/SettingPreview';

interface OwnProps {
	company: CompanyViewModel;
	toggleEdit: () => void;
}

type Props = OwnProps;

class EquipmentStatusSettingsPreview extends React.PureComponent<Props> {

	render() {
		const { toggleEdit, company } = this.props;
		const { isEquipmentAutomaticReturnDate } = company;

		return (
			<SettingPreview toggleEdit={toggleEdit}>
				<div className="company-settings__info-segment">
					<PreviewItem
						checked={isEquipmentAutomaticReturnDate}
						hint={EquipmentSettingsHint.isEquipmentAutomaticReturnDate}
						indent={false}
						label={EquipmentSettings.isEquipmentAutomaticReturnDate}
						showCheckbox={true}
					/>
				</div>
			</SettingPreview>
		);
	}
}

export default EquipmentStatusSettingsPreview;
