import * as React from 'react';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';

import CountryCode from 'acceligent-shared/enums/countryCode';

import { RootState } from 'af-reducers';

import LocationRequestModel from 'ab-requestModels/location.requestModel';

import { LOCATION_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as LocationActions from 'af-actions/location';

import CreateLocationForm from 'af-components/SharedForms/Resources/Location';
import { validate } from 'af-components/SharedForms/Resources/Location/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

import socket from 'af-utils/socket.util';

import SocketEvent from 'ab-enums/socketEvent.enum';

import { toRawPhoneNumber } from 'ab-utils/phone.util';

type OwnProps = CustomRouteComponentProps<{ id?: string; }>;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	createLocation: typeof LocationActions.create;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & InjectedFormProps<LocationRequestModel, FormOwnProps>;

class Create extends React.Component<Props> {

	createLocation = async (form: LocationRequestModel) => {
		const { history, createLocation, location: { state: { orgAlias } }, companyName } = this.props;
		const data: LocationRequestModel = {
			...form,
			phone: toRawPhoneNumber(form.phone),
			fax: toRawPhoneNumber(form.fax),
		};
		const isLocationCreated = await createLocation(data);

		if (isLocationCreated) {
			socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.SYNC_LABOR_STATISTICS);
			history.push(CLIENT.COMPANY.RESOURCES.LOCATION.LIST(orgAlias, companyName));
		}
	};

	render() {
		const {
			location: { state: { orgAlias } },
			companyName,
		} = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={[
						{ label: 'Locations', url: CLIENT.COMPANY.RESOURCES.LOCATION.LIST(orgAlias, companyName) },
						{ label: 'New Location' },
					]}
				/>
				<CreateLocationForm
					{...this.props}
					onSubmit={this.createLocation}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		createLocation: LocationActions.create,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<LocationRequestModel, FormOwnProps>({
		form: LOCATION_CREATE,
		validate,
		enableReinitialize: true,
		initialValues: {
			showInStatistics: false,
			phoneCountryCode: CountryCode.US,
			faxCountryCode: CountryCode.US,
		},
	})
);

export default enhance(Create);
