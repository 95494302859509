import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import SegmentLabel from 'af-components/SegmentLabel';

interface OwnProps {
	label: string;
}

type Props = React.PropsWithChildren<OwnProps>;

const Section: React.FC<Props> = ({ label, children }) => {
	return (
		<>
			<Row>
				<Col sm={24}>
					<SegmentLabel label={label} />
				</Col>
			</Row>
			<Row className="row--padded-bottom work-order-upsert__section">
				{children}
			</Row>
		</>
	);
};

export default Section;
