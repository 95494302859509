import * as React from 'react';

import AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';

import ConfirmationModal from 'af-components/ConfirmationModal';

interface OwnProps {
	onClose: () => void;
	onSubmit: Nullable<() => void>;
	showModal: boolean;
	type: Nullable<AssignableResourceType>;
	showCancel?: boolean;
}

type Props = OwnProps;

class ResourceAssignConfirmationModal extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		showCancel: true,
	};

	static readonly TITLE: { [T in keyof Omit<typeof AssignableResourceType, 'TEMPORARY_EMPLOYEE'>]: string; } = {
		[AssignableResourceType.EQUIPMENT]: 'Assign Equipment to Work Order',
		[AssignableResourceType.EMPLOYEE]: 'Assign Laborer to Work Order',
	};

	static readonly BODY: { [T in keyof Omit<typeof AssignableResourceType, 'TEMPORARY_EMPLOYEE'>]: string } = {
		[AssignableResourceType.EQUIPMENT]: 'Assigning unavailable equipment to Work Order will change status to Available',
		[AssignableResourceType.EMPLOYEE]: 'Assigning unavailable laborer to Work Order will change status to Available',
	};

	static readonly CONFIRMATION_BTN_TEXT = 'Confirm';
	static readonly NO_CONFIRMATION_BTN_TEXT = 'OK';

	static getConfirmationButtonText = (useCancel: boolean) => {
		return useCancel
			? ResourceAssignConfirmationModal.CONFIRMATION_BTN_TEXT
			: ResourceAssignConfirmationModal.NO_CONFIRMATION_BTN_TEXT;
	};

	render() {
		const { onSubmit, onClose, showModal, type, showCancel } = this.props;

		return (
			<ConfirmationModal
				body={type && ResourceAssignConfirmationModal.BODY[type]}
				closeModal={onClose}
				confirmAction={onSubmit}
				confirmText={ResourceAssignConfirmationModal.getConfirmationButtonText(!!showCancel)}
				hideOnConfirm={false}
				modalStyle="warning"
				showCancel={showCancel}
				showModal={showModal}
				title={type && ResourceAssignConfirmationModal.TITLE[type]}
			/>
		);
	}
}

export default ResourceAssignConfirmationModal;
