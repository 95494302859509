import * as React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';

import { MAX_BILLABLE_WORK_INFORMATION_FIELDS } from 'ab-constants/value';

import Input from 'af-fields/Input';

import { FormModel } from '../FormModel';
import styles from './styles.module.scss';

type Props = WrappedFieldArrayProps<FormModel['informationalFields'][0]>;

const AddBillableWorkModalInformationalField: React.FC<Props> = (props) => {
	const { fields } = props;

	const onAddInformationalFieldClick = React.useCallback(() => fields.push(''), [fields]);

	const onRemoveInformationalFieldClick = React.useCallback((index) => () => fields.remove(index), [fields]);

	const renderField = (field, index) => {
		return (
			<div className={styles['add-billable-work-modal__info-def-item']} key={field}>
				<Field
					component={Input}
					id="informationalField"
					label={`Informational Field ${index + 1}`}
					name={`${field}`}
					placeholder="Add informational field"
					type="text"
				/>
				<span className="icon-delete" onClick={onRemoveInformationalFieldClick(index)} />
			</div>
		);
	};
	return (
		<>
			{fields.map(renderField)}
			{fields.length < MAX_BILLABLE_WORK_INFORMATION_FIELDS &&
				<div className={styles['add-billable-work-modal__add-field']} onClick={onAddInformationalFieldClick}>
					<span className="icon-plus" />
					<span>Add Informational Field</span>
				</div>
			}
		</>
	);
};

export default AddBillableWorkModalInformationalField;
