import { Dispatch, AnyAction } from 'redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import API from 'af-constants/routes/api';
import CLIENT from 'af-constants/routes/client';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { WageRateRequestModel, WageRateImportBatch } from 'ab-requestModels/wageRate.requestModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { WageRateTableRowVM } from 'ab-viewModels/wageRate/tableRow.viewModel';
import { WageRateVM } from 'ab-viewModels/wageRate/wageRate.viewModel';

import { GetRootState } from 'af-reducers';
import { BulkCreateResponseViewModel } from 'ab-viewModels/csv.viewModel';

export function create(form: WageRateRequestModel, companyName: string, orgAlias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.RESOURCES.WAGE_RATE.CREATE(), { ...form });
			redirectTo(CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, companyName));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<WageRateVM>(API.V1.RESOURCES.WAGE_RATE.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<WageRateVM[]>(API.V1.RESOURCES.WAGE_RATE.LIST('', null));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkDeleteWageRateFromTable(ids: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.WAGE_RATE.BULK_DELETE, { wageRateIds: ids });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteWageRateFromTable(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.WAGE_RATE.DELETE(id.toString()));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllByQuery(query: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<WageRateVM[]>(API.V1.RESOURCES.WAGE_RATE.LIST(query, null));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<WageRateTableRowVM>>(API.V1.RESOURCES.WAGE_RATE.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function importBatch(data: WageRateImportBatch) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<BulkCreateResponseViewModel<WageRateRequestModel>>(API.V1.RESOURCES.WAGE_RATE.IMPORT_BATCH(), data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(form: WageRateRequestModel, companyName: string, orgAlias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			if (!form.id) {
				throw new Error('Wage Rate ID not provided');
			}

			await http.put(API.V1.RESOURCES.WAGE_RATE.EDIT(form.id.toString()), { ...form });
			redirectTo(CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, companyName));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
