import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { change } from 'redux-form';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import { RootState } from 'af-reducers';

import * as ContactActions from 'af-actions/contacts';

import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import AutosaveComponent from 'af-components/AutosaveComponent';
import SelectContact from 'af-components/SharedForms/Contacts/SelectContact';
import ContactInfo from 'af-components/SharedForms/Contacts/RequestWrapper/ContactInfo';

interface OwnProps {
	addressFieldName: string;
	canPickAddress?: boolean;
	canPickContactMethods?: boolean;
	clearContact: () => void;
	contact: ContactVM;
	contactIdsSelector: (field: string) => number[];
	contactLabel: string;
	disabled?: boolean;
	emailFieldName: string;
	fieldName: string;
	formName: string;
	phoneFieldName: string;
	prepopulateWithRole?: string;
	useIn: 'WO' | 'WR';
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	showContactContactForm: boolean;
	disableContactSearch: boolean;
	contacts: ContactVM[];
}

class RequestContactWrapper extends AutosaveComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		emailFieldName: 'contactEmailIds',
		phoneFieldName: 'contactPhoneIds',
		addressFieldName: 'contactAddressIds',
	};

	state: State = {
		showContactContactForm: false,
		disableContactSearch: false,
		contacts: [],
	};

	lazyLoadContacts = async () => {
		const { findAllContacts } = this.props;

		const contacts = await findAllContacts();
		this.setState(() => ({ contacts }));
	};

	render() {
		const {
			addressFieldName,
			canPickAddress,
			canPickContactMethods,
			changeField,
			clearContact,
			contact,
			contactIdsSelector,
			contactLabel,
			disabled,
			emailFieldName,
			fieldName,
			formName,
			hasPermissions,
			phoneFieldName,
			prepopulateWithRole,
			useIn,
		} = this.props;

		const { contacts } = this.state;

		return (
			<>
				<SelectContact
					canCreateNew={hasPermissions}
					changeField={changeField}
					clearContact={clearContact}
					contacts={contacts}
					disabled={disabled}
					fieldName={fieldName}
					formName={formName}
					onLazyLoad={this.lazyLoadContacts}
					placeholder={contactLabel}
					prepopulateWithRole={prepopulateWithRole}
				/>
				{!!contact &&
					<ContactInfo
						addressFieldName={addressFieldName}
						canPickAddress={!!canPickAddress}
						canPickContactMethods={!!canPickContactMethods}
						changeField={changeField}
						contactIdsSelector={contactIdsSelector}
						disabled={disabled}
						emailFieldName={emailFieldName}
						formName={formName}
						phoneFieldName={phoneFieldName}
						selectedContact={contact}
						useIn={useIn}
					/>
				}
			</>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		hasPermissions: isAllowed(PagePermissions.COMPANY.CONTACTS.MANAGE, companyData.permissions, companyData.isCompanyAdmin, userData.role),
	};
}

function mapDispatchToProps() {
	return {
		findAllContacts: ContactActions.findAllContacts,
		changeField: change,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(RequestContactWrapper);
