import * as React from 'react';
import { Route, Routes, matchPath } from 'react-router-dom-v5-compat';

import * as SettingsKeys from 'af-constants/settingsKeys';

import * as SettingsUtils from 'af-utils/settings.util';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import Error404Container from 'af-root/scenes/Error/error404';

import CLIENT, { workOrderPrefix } from 'af-routes/client';

import WorkOrdersTable from './WorkOrdersList';
import { NavigationContext } from 'af-root/context/navigationContext';
import FieldReportsList from './FieldReportsList';

const ChildPaths = {
	workOrders: 'workOrders',
	workOrder: `workOrder/${workOrderPrefix}`,
};

const FieldReports: React.FC = () => {

	const navigationData = React.useContext(NavigationContext);

	React.useEffect(() => {
		return () => {
			navigationData.pathChangedSideEffects.push((to: string) => {
				if (!matchPath(CLIENT.COMPANY.FIELD_REPORT.PARENT(), to)) {
					SettingsUtils.setItem(SettingsKeys.FIELD_REPORTS_TEXT_FILTER(), '', BrowserStorageEnum.SESSION_STORAGE);
					return true;
				}
				return false;
			});
		};
	}, [navigationData.pathChangedSideEffects]);

	return (
		<Routes>
			<Route element={<WorkOrdersTable />} path={ChildPaths.workOrders}></Route>
			<Route element={<FieldReportsList />} path={ChildPaths.workOrder}></Route>
			<Route element={<Error404Container />} path="*" />
		</Routes >
	);

};

export default React.memo(FieldReports);
