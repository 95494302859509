import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import Textarea from 'af-fields/Textarea';
import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';
import ColorPicker from 'af-fields/ColorPicker';

interface OwnProps {
	isJobStatus: boolean;
}

type Props = OwnProps;

class DeliverableStatusForm extends React.PureComponent<Props> {
	render() {
		const { isJobStatus } = this.props;
		return (
			<form>
				<Row className="row--padded-top">
					<Col md={8}>
						<Field
							component={Input}
							label="Abbreviation *"
							maxCharacters={10}
							name="abbreviation"
							placeholder="Status Abbreviation"
							showCharNum={true}
							type="text"
						/>
					</Col>
					<Col md={6}>
						<Field
							component={ColorPicker}
							grouped={true}
							label="Status Color *"
							name="statusColor"
							position="bottom"
							showAdditionalOptions={true}
						/>
					</Col>
					<Col md={6}>
						<Field
							component={ColorPicker}
							grouped={true}
							label="Text Color *"
							name="textColor"
							position="bottom"
							showAdditionalOptions={true}
						/>
					</Col>
					<Col md={4} />
				</Row>
				<Row>
					<Col md={16}>
						<Field
							component={Input}
							label="Status Name *"
							maxCharacters={50}
							name="name"
							placeholder="Status Name"
							showCharNum={true}
							type="text"
						/>
					</Col>
					{!isJobStatus
						? <Col md={8}>
							<Field
								component={Checkbox}
								grouped={true}
								label="Notify when set"
								name="notify"
							/>
						</Col>
						: <Col md={8}>
							<Field
								component={Checkbox}
								grouped={true}
								label="Complete when set"
								name="isCompleted"
							/>
						</Col>
					}
				</Row>
				<Row>
					<Col md={24}>
						<Field
							component={Textarea}
							label="Description"
							maxCharacters={200}
							name="description"
							placeholder="Description"
						/>
					</Col>
				</Row>
			</form>
		);
	}
}

export default DeliverableStatusForm;
