import * as React from 'react';

import { DroppableZones } from 'ab-enums/reportTypeBuilder.enum';

import { bemElement } from 'ab-utils/bem.util';

import CalculatedBlock from './CalculatedBlock';
import ReportTypeCustomTypeFormDroppableZone from './DroppableZone';

import { ReportTypeBlockFormModel, ReportBlockFormModel, ReportBlockFieldFormModel } from '../../../Shared/formModel';

interface OwnProps {
	draggingElementSource: Nullable<DroppableZones>;
	lowerTotalBlockId: Nullable<string>;
	onAddBlock: (dropZone: DroppableZones) => void;
	openEditBlockNameModal: (reportTypeBlockId: string) => void;
	openFieldModal: (isPrimary: boolean, isTotalBlock: boolean, reportTypeBlockId: Nullable<string>, fieldId: Nullable<string>) => void;
	primaryIdList: string[];
	primaryMainBlockId: Nullable<string>;
	removeBlock: (id: string, isPrimary: boolean) => void;
	removeField: (blockId: string, fieldId: string) => void;
	removeMainBlock: (isPrimary: boolean) => void;
	secondaryIdList: string[];
	secondaryMainBlockId: Nullable<string>;
	showBlocksList: boolean;
	upperTotalBlockId: Nullable<string>;
	upsertField: (form: ReportBlockFieldFormModel, isPrimary: boolean, isTotalBlock: boolean, reportTypeBlockId: Nullable<string>) => void;
	reportTypeBlocksByIdMap: { [id: string]: ReportTypeBlockFormModel; };
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; };
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; };
}

type Props = OwnProps;

const _renderHeader = (name: string, isPrimary: boolean = false) => {
	const headerClassName = bemElement(
		'report-block-form',
		'fields-header',
		{ regular: true, primary: isPrimary }
	);

	return (
		<div className={headerClassName}>
			<div>
				<div className="report-block-form__header-title">{name}</div>
			</div>
		</div>
	);
};

const ReportTypeCustomTypeFormTypeInfo: React.FC<Props> = (props: Props) => {
	const {
		primaryMainBlockId,
		primaryIdList,
		secondaryMainBlockId,
		secondaryIdList,
		draggingElementSource,
		onAddBlock,
		openFieldModal,
		removeBlock,
		removeMainBlock,
		removeField,
		upsertField,
		showBlocksList,
		upperTotalBlockId,
		lowerTotalBlockId,
		openEditBlockNameModal,
		reportTypeBlocksByIdMap,
		reportBlocksByIdMap,
		reportFieldsByIdMap,
	} = props;

	const upperTotalReportTypeBlock = upperTotalBlockId ? reportTypeBlocksByIdMap[upperTotalBlockId] : null;
	const upperTotalBlock = upperTotalReportTypeBlock ? reportBlocksByIdMap[upperTotalReportTypeBlock.reportBlockVirtualId] : null;

	const lowerTotalReportTypeBlock = lowerTotalBlockId ? reportTypeBlocksByIdMap[lowerTotalBlockId] : null;
	const lowerTotalBlock = lowerTotalReportTypeBlock ? reportBlocksByIdMap[lowerTotalReportTypeBlock.reportBlockVirtualId] : null;

	const primaryMainReportTypeBlock = primaryMainBlockId ? reportTypeBlocksByIdMap[primaryMainBlockId] : null;
	const primaryMainBlock = primaryMainReportTypeBlock ? reportBlocksByIdMap[primaryMainReportTypeBlock.reportBlockVirtualId] : null;

	const secondaryMainReportTypeBlock = secondaryMainBlockId ? reportTypeBlocksByIdMap[secondaryMainBlockId] : null;
	const secondaryMainBlock = secondaryMainReportTypeBlock ? reportBlocksByIdMap[secondaryMainReportTypeBlock.reportBlockVirtualId] : null;

	return (
		<>
			<CalculatedBlock
				block={upperTotalBlock}
				editBlock={openEditBlockNameModal}
				isPrimary={true}
				isTotalBlock={true}
				openFieldModal={openFieldModal}
				removeField={removeField}
				reportFieldsByIdMap={reportFieldsByIdMap}
				reportTypeBlockId={upperTotalReportTypeBlock?.virtualId ?? null}
				upsertField={upsertField}
			/>
			<div className="form-box">
				{primaryMainBlock && _renderHeader(primaryMainBlock.name ?? 'Primary', true)}
				<ReportTypeCustomTypeFormDroppableZone
					draggingElementSource={draggingElementSource}
					ids={primaryIdList}
					isPrimary={true}
					mainBlockId={primaryMainBlockId}
					onAddBlock={onAddBlock}
					openEditBlockNameModal={openEditBlockNameModal}
					openFieldModal={openFieldModal}
					removeBlock={removeBlock}
					removeField={removeField}
					removeMainBlock={removeMainBlock}
					reportBlocksByIdMap={reportBlocksByIdMap}
					reportFieldsByIdMap={reportFieldsByIdMap}
					reportTypeBlocksByIdMap={reportTypeBlocksByIdMap}
					showBlocksList={showBlocksList}
					upsertField={upsertField}
				/>
			</div>
			<div className="form-box">
				{secondaryMainBlock && _renderHeader(secondaryMainBlock.name ?? 'Secondary')}
				<ReportTypeCustomTypeFormDroppableZone
					draggingElementSource={draggingElementSource}
					ids={secondaryIdList}
					isPrimary={false}
					mainBlockId={secondaryMainBlockId}
					onAddBlock={onAddBlock}
					openEditBlockNameModal={openEditBlockNameModal}
					openFieldModal={openFieldModal}
					removeBlock={removeBlock}
					removeField={removeField}
					removeMainBlock={removeMainBlock}
					reportBlocksByIdMap={reportBlocksByIdMap}
					reportFieldsByIdMap={reportFieldsByIdMap}
					reportTypeBlocksByIdMap={reportTypeBlocksByIdMap}
					showBlocksList={showBlocksList}
					upsertField={upsertField}
				/>
			</div>
			<CalculatedBlock
				block={lowerTotalBlock}
				editBlock={openEditBlockNameModal}
				isPrimary={false}
				isTotalBlock={true}
				openFieldModal={openFieldModal}
				removeField={removeField}
				reportFieldsByIdMap={reportFieldsByIdMap}
				reportTypeBlockId={lowerTotalReportTypeBlock?.virtualId ?? null}
				upsertField={upsertField}
			/>
		</>
	);
};

export default React.memo(ReportTypeCustomTypeFormTypeInfo);
