import * as React from 'react';
import { HandleThunkActionCreator } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import DivisionRequestModel from 'ab-requestModels/division.requestModel';

import { DivisionViewModel } from 'ab-viewModels/division.viewModel';

import { DIVISION_EDIT } from 'af-constants/reduxForms';

import * as DivisionActions from 'af-actions/division';

import DivisionForm from 'af-components/SharedForms/Resources/Division';
import { validate } from 'af-components/SharedForms/Resources/Division/validation';

interface OwnProps {
	updateDivision: HandleThunkActionCreator<typeof DivisionActions.update>;
	toggleEdit: (id: Nullable<number>) => void;
	division: DivisionViewModel;
	index: number;
}

type Props = OwnProps & InjectedFormProps<DivisionRequestModel, OwnProps>;

class DivisionEdit extends React.PureComponent<Props> {

	updateDivision = async (form: DivisionRequestModel) => {
		const { division, toggleEdit, updateDivision } = this.props;

		await updateDivision(division.id, form);
		toggleEdit(null);
	};

	close = () => {
		const { toggleEdit } = this.props;
		toggleEdit(null);
	};

	render() {
		return (
			<DivisionForm {...this.props} close={this.close} onSubmit={this.updateDivision} />
		);
	}
}

export default reduxForm<DivisionRequestModel, OwnProps>({
	form: DIVISION_EDIT, validate, enableReinitialize: true, destroyOnUnmount: false,
})(DivisionEdit);
