function getDefinitionFieldValue(definitionField: { value: Nullable<string>; }) {
	return definitionField.value;
}

function sortDefinitionFields (_a: Nullable<string>, _b: Nullable<string>) {
	return (_a ?? '').localeCompare(_b ?? '');
}

export function resolveHighlightGroupKey(billableWorkId: number, workType: Nullable<string>, definitionFields: { value: Nullable<string>; }[]) {
	return JSON.stringify([billableWorkId, workType, definitionFields.map(getDefinitionFieldValue).sort(sortDefinitionFields)]);
}
