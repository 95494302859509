import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';

import { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import BlankCard from 'af-root/scenes/Company/ScheduleBoard/Shared/Card/BlankCard';
import Card from 'af-root/scenes/Company/ScheduleBoard/Shared/Card';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import SharedModalProps from '../../Shared/ModalProps';

interface OwnProps extends SharedModalProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	className: string;
	droppableId: string;
	/** `MM-DD-YYYY` */
	dueDate: string;
	forceUnlockOrder: (workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	isDragAndDropDisabled: boolean;
	lastOpenedOrderCode: string;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	rowIndex: number;
}

interface StateProps {
	isDisabled: boolean;
	workOrderCodes: string[];
	startIndex: number;
}

interface DispatchProps {
	selectOrder: typeof ScheduleBoardActions.selectWorkOrder;
	deselectOrder: typeof ScheduleBoardActions.deselectWorkOrder;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class DroppableComponent extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		workOrderCodes: [],
	};

	renderCards = (workOrderCodes: string[]) => {
		const {
			addBlankWorkOrder,
			currentWorkOrderModalId,
			deselectOrder,
			dueDate,
			forceUnlockOrder,
			hasPermissionsToEditScheduleBoard,
			isDragAndDropDisabled,
			lastOpenedOrderCode,
			removeBlankWorkOrder,
			selectOrder,
			setWorkOrderModalId,
			setWorkOrderNoteModalData,
			setEmployeeModalData,
			setEquipmentModalData,
			setTemporaryEmployeeModalData,
			setEmployeeModalVisibility,
			setEquipmentModalVisibility,
			startIndex,
		} = this.props;

		return (
			<div className="schedule-board-cards-container">
				{workOrderCodes.map((workOrderCode: string, index: number) => {
					const isBlank = ScheduleBoardUtil.isBlankWorkOrderId(workOrderCode);
					const isPlaceholder = ScheduleBoardUtil.isLoadingPlaceholderDroppableId(workOrderCode);
					return isBlank || isPlaceholder ?
						(
							<BlankCard
								draggableId={ScheduleBoardUtil.generateBlankWorkOrderId(dueDate, startIndex + index)}
								dueDate={dueDate}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								index={startIndex + index}
								isDragAndDropDisabled={isDragAndDropDisabled}
								isPlaceholder={isPlaceholder}
								key={index}
								removeBlankWorkOrder={removeBlankWorkOrder}
							/>
						) : (
							<Card
								addBlankWorkOrder={addBlankWorkOrder}
								currentWorkOrderModalId={currentWorkOrderModalId}
								deselectOrder={deselectOrder}
								dueDate={dueDate}
								forceUnlockOrder={forceUnlockOrder}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								index={startIndex + index}
								isDragAndDropDisabled={isDragAndDropDisabled}
								key={index}
								lastOpenedOrderCode={lastOpenedOrderCode}
								selectOrder={selectOrder}
								setEmployeeModalData={setEmployeeModalData}
								setEmployeeModalVisibility={setEmployeeModalVisibility}
								setEquipmentModalData={setEquipmentModalData}
								setEquipmentModalVisibility={setEquipmentModalVisibility}
								setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
								setWorkOrderModalId={setWorkOrderModalId}
								setWorkOrderNoteModalData={setWorkOrderNoteModalData}
								workOrderCode={workOrderCode}
							/>
						);
				})}
			</div>
		);
	};

	render() {
		const { droppableId, isDisabled, workOrderCodes, className } = this.props;

		return (
			<Droppable
				direction="horizontal"
				droppableId={droppableId}
				isDropDisabled={isDisabled}
			>
				{(provided, snapshot) => {
					return (
						<div
							{...(provided.droppableProps)}
							className={`${className} ${snapshot.isDraggingOver ? 'drag-over' : ''}`}
							ref={provided.innerRef}
						>
							{this.renderCards(workOrderCodes)}
							{provided.placeholder}
						</div>
					);
				}}
			</Droppable>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate, rowIndex, isDragAndDropDisabled, hasPermissionsToEditScheduleBoard } = ownProps;
	const workOrdersRowDistribution = state?.scheduleBoard?.workOrdersByDateDictionary?.[dueDate]?.workOrdersRowDistribution ?? [];
	const workOrderCodes = workOrdersRowDistribution?.[rowIndex];
	const startIndex = ScheduleBoardUtil.getStartIndexForRow(workOrdersRowDistribution, rowIndex);

	return {
		isDisabled: !state.scheduleBoard.draggedWorkOrderCode || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard,
		workOrderCodes,
		startIndex,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		selectOrder: ScheduleBoardActions.selectWorkOrder,
		deselectOrder: ScheduleBoardActions.deselectWorkOrder,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(DroppableComponent);
