import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { History, Location } from 'history';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkOrderReviewStatus from 'acceligent-shared/enums/workOrderReviewStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { WorkDayViewModel } from 'acceligent-shared/dtos/web/view/workDay/workDay';

import { RootState } from 'af-reducers';

import OrderInfoModal from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfoModal';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as WorkOrderActions from 'af-actions/workOrder';

import { convertWorkDays } from 'ab-viewModels/company.viewModel';
import WorkOrderModalVM from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import { WorkOrderPublishForm, WorkOrderCancelForm, WorkOrderDelayForm, WorkOrderPauseForm } from 'ab-requestModels/workOrder.requestModel';

import * as WorkOrderUtil from 'af-utils/workOrder.util';

import OrderActionModals from './OrderActionModals';

import { WorkOrderModalProps } from '../../Shared/ModalProps';

interface OwnProps extends WorkOrderModalProps {
	cancelOrder: (workOrderId: string, cancelForm: WorkOrderCancelForm) => Promise<void>;
	pauseOrder: (workOrderId: string, pauseReason: WorkOrderPauseForm) => Promise<void>;
	resumeOrder: (workOrderId: string) => Promise<void>;
	companyName: string;
	deleteOrder: (workOrderId: string) => void;
	generatePreview: (workOrderId: string) => Promise<void>;
	hasPermissionsToEditScheduleBoard: boolean;
	history: History;
	location: Location;
	orgAlias: string;
	publishOrder: (workOrderId: string, publishForm?: WorkOrderPublishForm) => Promise<void>;
}

interface StateProps {
	/** default: [] */
	workDays: WorkDayViewModel[] | undefined;
}

interface DispatchProps {
	getNotificationTemplate: typeof WorkOrderActions.getNotificationTemplate;
	lazyLoadWorkOrder: typeof ScheduleBoardActions.lazyLoadWorkOrder;
	lazyLoadWorkOrderRevision: typeof ScheduleBoardActions.lazyLoadWorkOrderRevision;
	reinitializeCopyForm: typeof WorkOrderActions.reinitializeCopyForm;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	notificationTemplate: Nullable<string>;
	showCancelModal: boolean;
	showCopyModal: boolean;
	showPauseModal: boolean;
	showResumeModal: boolean;
	showDelayModal: boolean;
	showDeleteModal: boolean;
	showNotificationPreviewModal: boolean;
	showNotifyParticipantsModal: boolean;
	showWorkOrderModal: boolean;
	workOrderModal: Nullable<WorkOrderModalVM>;
	workOrderModalRevision: Nullable<WorkOrderModalVM>;
	isRevisionOpened: boolean;
	showCancelWarningModal: boolean;
}

class OrderInfoModals extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		hasPermissionsToEditScheduleBoard: true,
		workDays: [],
	};

	state: State = {
		notificationTemplate: null,
		showCancelModal: false,
		showCopyModal: false,
		showDelayModal: false,
		showDeleteModal: false,
		showNotificationPreviewModal: false,
		showNotifyParticipantsModal: false,
		showWorkOrderModal: false,
		workOrderModal: null,
		workOrderModalRevision: null,
		isRevisionOpened: false,
		showCancelWarningModal: false,
		showPauseModal: false,
		showResumeModal: false,
	};

	async componentDidUpdate(prevProps: Props) {
		const { currentWorkOrderModalId } = this.props;

		if (currentWorkOrderModalId && prevProps.currentWorkOrderModalId !== currentWorkOrderModalId) {
			await this.loadWorkOrder(currentWorkOrderModalId, true);
		}

		if (prevProps.currentWorkOrderModalId !== currentWorkOrderModalId) {
			await this.getWorkOrderNotificationTemplate();
		}
	}

	setWorkOrderModalVisibility = (isVisible: boolean) => {
		if (!isVisible) {
			this.setState(() => ({
				workOrderModalRevision: null,
				isRevisionOpened: false,
				showWorkOrderModal: isVisible,
			}));
		} else {
			this.setState(() => ({ showWorkOrderModal: isVisible }));
		}
	};

	setNotificationPreviewModalVisibility = (isVisible: boolean) => {
		this.setState(() => ({ showNotificationPreviewModal: isVisible }));
	};

	setNotifyParticipantsModalVisibility = (isVisible: boolean) => {
		this.setState(() => ({ showNotifyParticipantsModal: isVisible }));
	};

	setDeleteModalVisibility = (isVisible: boolean) => {
		this.setState(() => ({ showDeleteModal: isVisible }));
	};

	setCancelModalVisibility = (isVisible: boolean) => {
		this.setState(() => ({ showCancelModal: isVisible }));
	};

	setPauseModalVisibility = (isVisible: boolean) => {
		this.setState(() => ({ showPauseModal: isVisible }));
	};

	setResumeModalVisibility = (isVisible: boolean) => {
		this.setState(() => ({ showResumeModal: isVisible }));
	};

	setDelayModalVisibility = (isVisible: boolean) => {
		this.setState(() => ({ showDelayModal: isVisible }));
	};

	setCopyModalVisibility = (showCopyModal: boolean) => {
		this.setState(() => ({ showCopyModal }));
	};

	setCancelWarningModalVisibility = (showCancelWarningModal: boolean) => {
		this.setState(() => ({ showCancelWarningModal }));
	};

	closeOrderInfoModal = (reset?: boolean) => {
		const { history, location, setWorkOrderModalId } = this.props;
		this.setWorkOrderModalVisibility(false);
		setWorkOrderModalId(null);
		if (reset && location.search) {
			history.replace({
				pathname: location.pathname,
				search: '',
			});
		}
	};

	openDeleteModal = () => {
		this.setWorkOrderModalVisibility(false);
		this.setDeleteModalVisibility(true);
	};

	openCancelModal = () => {
		const { workOrderModal } = this.state;

		this.setWorkOrderModalVisibility(false);

		if (workOrderModal?.reviewStatus === WorkOrderReviewStatus.FINAL) {
			this.setCancelWarningModalVisibility(true);
		} else {
			this.setCancelModalVisibility(true);
		}
	};

	openPauseModal = () => {

		this.setWorkOrderModalVisibility(false);

		this.setPauseModalVisibility(true);
	};

	openResumeModal = () => {

		this.setWorkOrderModalVisibility(false);

		this.setResumeModalVisibility(true);
	};

	openDelayModal = () => {
		this.setWorkOrderModalVisibility(false);
		this.setDelayModalVisibility(true);
	};

	openCopyModal = () => {
		const { workDays, reinitializeCopyForm } = this.props;
		const { workOrderModal } = this.state;

		if (!workOrderModal) {
			throw new Error('Work Order modal not defined');
		}

		const { validOrders, finishedOrders, cancelledOrders } = WorkOrderUtil.groupWorkOrdersForCopy([workOrderModal]);
		reinitializeCopyForm(
			TimeUtils.addDays(workOrderModal.dueDate, 1).format(TimeFormat.DATE_ONLY),
			workOrderModal.dueDate,
			convertWorkDays(workDays),
			validOrders,
			finishedOrders,
			cancelledOrders,
			[workOrderModal.id],
			workOrderModal.code
		);

		this.setWorkOrderModalVisibility(false);
		this.setCopyModalVisibility(true);
	};

	openNotifyParticipantsModal = async () => {
		this.setWorkOrderModalVisibility(false);
		this.setNotifyParticipantsModalVisibility(true);
	};

	openNotificationPreviewModal = () => {
		this.setWorkOrderModalVisibility(false);
		this.setNotificationPreviewModalVisibility(true);
	};

	closeDelayModal = () => {
		this.setWorkOrderModalVisibility(true);
		this.setDelayModalVisibility(false);
	};

	loadWorkOrder = async (workOrderModalId: number, openModal: boolean = false) => {
		const { lazyLoadWorkOrder } = this.props;
		const workOrderModal = await lazyLoadWorkOrder(workOrderModalId);
		this.setState((state) => ({ workOrderModal, showWorkOrderModal: openModal ? true : state.showWorkOrderModal }));
	};

	selectWorkOrderRevision = async (revisionId: number, isCurrentWorkOrder: boolean) => {
		const { lazyLoadWorkOrder, lazyLoadWorkOrderRevision } = this.props;

		let workOrderModalRevision: Nullable<WorkOrderModalVM> = null;
		let isRevisionOpened = false;
		if (isCurrentWorkOrder) {
			workOrderModalRevision = await lazyLoadWorkOrder(revisionId);
		} else {
			workOrderModalRevision = await lazyLoadWorkOrderRevision(revisionId);
			isRevisionOpened = true;
		}
		this.setState(() => ({ workOrderModalRevision, isRevisionOpened }));
	};

	publishOrder = async (workOrderModalId: number, form?: WorkOrderDelayForm) => {
		const { publishOrder } = this.props;
		const { showDelayModal } = this.state;

		await publishOrder(`${workOrderModalId}`, form);

		if (showDelayModal) {
			this.closeDelayModal();
		}
	};

	generatePreview = async () => {
		const { generatePreview, currentWorkOrderModalId } = this.props;
		this.closeOrderInfoModal();
		await generatePreview(`${currentWorkOrderModalId}`);
	};

	getWorkOrderNotificationTemplate = async () => {
		const { getNotificationTemplate } = this.props;
		const { workOrderModal } = this.state;
		// if there is workOrderId then id is workOrderRevisionId
		const template = workOrderModal ? await getNotificationTemplate(workOrderModal.workOrderId ?? workOrderModal.id) : '';
		this.setState(() => ({ notificationTemplate: template }));
	};

	render() {
		const {
			companyName,
			history,
			orgAlias,
			hasPermissionsToEditScheduleBoard,
			cancelOrder,
			deleteOrder,
			pauseOrder,
			resumeOrder,
		} = this.props;

		const {
			notificationTemplate,
			showCancelModal,
			showCopyModal,
			showDelayModal,
			showDeleteModal,
			showNotificationPreviewModal,
			showNotifyParticipantsModal,
			showWorkOrderModal,
			showCancelWarningModal,
			workOrderModal,
			workOrderModalRevision,
			isRevisionOpened,
			showPauseModal,
			showResumeModal,
		} = this.state;

		return workOrderModal ? (
			<>
				<OrderInfoModal
					closeOrderInfoModal={this.closeOrderInfoModal}
					companyName={companyName}
					generatePreview={this.generatePreview}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					history={history}
					isRevisionOpened={isRevisionOpened}
					loadWorkOrder={this.loadWorkOrder}
					openCancelModal={this.openCancelModal}
					openCopyModal={this.openCopyModal}
					openDelayModal={this.openDelayModal}
					openDeleteModal={this.openDeleteModal}
					openNotificationPreviewModal={this.openNotificationPreviewModal}
					openNotifyParticipantsModal={this.openNotifyParticipantsModal}
					openPauseModal={this.openPauseModal}
					openResumeModal={this.openResumeModal}
					orgAlias={orgAlias}
					publishOrder={this.publishOrder}
					selectRevision={this.selectWorkOrderRevision}
					showModal={showWorkOrderModal}
					workOrder={workOrderModal}
					workOrderRevision={workOrderModalRevision}
				/>
				<OrderActionModals
					cancelOrder={cancelOrder}
					closeOrderInfoModal={this.closeOrderInfoModal}
					deleteOrder={deleteOrder}
					notificationTemplate={notificationTemplate ?? undefined}
					pauseOrder={pauseOrder}
					publishOrder={this.publishOrder}
					resumeOrder={resumeOrder}
					setCancelModalVisibility={this.setCancelModalVisibility}
					setCancelWarningModalVisibility={this.setCancelWarningModalVisibility}
					setCopyModalVisibility={this.setCopyModalVisibility}
					setDelayModalVisibility={this.setDelayModalVisibility}
					setDeleteModalVisibility={this.setDeleteModalVisibility}
					setNotificationPreviewModalVisibility={this.setNotificationPreviewModalVisibility}
					setNotifyParticipantsModalVisibility={this.setNotifyParticipantsModalVisibility}
					setPauseModalVisibility={this.setPauseModalVisibility}
					setResumeModalVisibility={this.setResumeModalVisibility}
					setWorkOrderModalVisibility={this.setWorkOrderModalVisibility}
					showCancelModal={showCancelModal}
					showCancelWarningModal={showCancelWarningModal}
					showCopyModal={showCopyModal}
					showDelayModal={showDelayModal}
					showDeleteModal={showDeleteModal}
					showNotificationPreviewModal={showNotificationPreviewModal}
					showNotifyParticipantsModal={showNotifyParticipantsModal}
					showPauseModal={showPauseModal}
					showResumeModal={showResumeModal}
					workOrder={workOrderModal}
				/>
			</>
		) : null;
	}
}

const mapStateToProps = (state: RootState): StateProps => {
	const { company } = state.company;

	return {
		workDays: company?.workDays,
	};
};

const mapDispatchToProps = (): DispatchProps => ({
	getNotificationTemplate: WorkOrderActions.getNotificationTemplate,
	lazyLoadWorkOrder: ScheduleBoardActions.lazyLoadWorkOrder,
	lazyLoadWorkOrderRevision: ScheduleBoardActions.lazyLoadWorkOrderRevision,
	reinitializeCopyForm: WorkOrderActions.reinitializeCopyForm,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(OrderInfoModals);
