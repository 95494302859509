import * as constants from 'af-constants/actionTypes';

export interface AppReady {
	type: typeof constants.APP_READY;
}

export interface ShowRefreshModal {
	type: typeof constants.SHOW_REFRESH_MODAL;
}

export interface HideRefreshModal {
	type: typeof constants.HIDE_REFRESH_MODAL;
}

export type GeneralAction =
	AppReady |
	ShowRefreshModal |
	HideRefreshModal;

export function APP_READY(): AppReady {
	return {
		type: constants.APP_READY,
	};
}

export function SHOW_REFRESH_MODAL(): ShowRefreshModal {
	return {
		type: constants.SHOW_REFRESH_MODAL,
	};
}

export function HIDE_REFRESH_MODAL(): HideRefreshModal {
	return {
		type: constants.HIDE_REFRESH_MODAL,
	};
}
