import * as React from 'react';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

interface OwnProps {
	showResumeModal: boolean;
	workOrderCode: string;
	closeResumeModal: () => void;
	resumeWorkOrder: () => void;
}

type Props = OwnProps;

const PauseModal: React.FC<Props> = (props) => {

	const { showResumeModal, workOrderCode, closeResumeModal, resumeWorkOrder } = props;

	return (
		<CustomModal
			closeModal={closeResumeModal}
			modalStyle="info"
			showModal={showResumeModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeResumeModal}
				title={`Work Order - ${workOrderCode}`}
			/>
			<CustomModal.Body>
				Are you sure you want to resume Work Order?
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button onClick={closeResumeModal} variant="info">Back</Button>
				<SubmitButton
					label="Resume Work Order"
					onClick={resumeWorkOrder}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default React.memo(PauseModal);
