import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

export default class Loading extends React.Component {

	render() {
		return (
			<div className="form-segment">
				<h3>Notifications</h3>
				<div className="form-box form-box--midi">
					<Row>
						<Col className="loading-box-field" sm={12} />
						<Col sm={12} />
					</Row>
					<Row>
						<Col sm={2} />
						<Col className="loading-box-row" sm={10} />
						<Col sm={12} />
					</Row>
					<Row>
						<Col sm={2} />
						<Col className="loading-box-row" sm={10} />
						<Col sm={12} />
					</Row>
					<Row>
						<Col sm={2} />
						<Col className="loading-box-row" sm={8} />
						<Col sm={14} />
					</Row>
					<Row>
						<Col sm={2} />
						<Col className="loading-box-row" sm={10} />
						<Col sm={12} />
					</Row>
					<Row>
						<Col sm={2} />
						<Col className="loading-box-row" sm={12} />
						<Col sm={10} />
					</Row>
					<Row>
						<Col className="loading-box-row" sm={6} />
						<Col sm={18} />
					</Row>
					<Row>
						<Col className="loading-box-row" sm={14} />
						<Col sm={10} />
					</Row>
					<Row>
						<Col className="loading-box-row" sm={12} />
						<Col sm={12} />
					</Row>
					<Row>
						<Col className="loading-box-row" sm={12} />
						<Col sm={12} />
					</Row>
					<Row>
						<Col className="loading-box-row" sm={8} />
						<Col sm={16} />
					</Row>
					<Row>
						<Col className="loading-box-row" sm={14} />
						<Col sm={10} />
					</Row>
					<Row>
						<Col className="loading-box-row" sm={12} />
						<Col sm={12} />
					</Row>
					<Row>
						<Col sm={18} />
						<Col className="loading-box-field rounded standalone" sm={3} />
						<Col className="loading-box-field rounded standalone" sm={3} />
					</Row>
				</div>
			</div>
		);
	}
}
