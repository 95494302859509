import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import TextField from 'af-fields/Text';
import Checkbox from 'af-fields/Checkbox';

import Section from '../Shared/Section';

interface OwnProps {
	disabled: boolean;
	isDeliverableJob: boolean;
}

type Props = OwnProps;

const WorkOrderDeliverableData: React.FC<Props> = (props) => {

	const { isDeliverableJob, disabled } = props;

	if (!isDeliverableJob) {
		return null;
	}

	return (
		<Section label="Deliverable Date">
			<Col sm={8}>
				<Field
					component={TextField}
					defaultValue="-"
					label="Deliverable Software"
					name="job.deliverableSoftware"
				/>
			</Col>
			<Col sm={8}>
				<Field
					component={TextField}
					defaultValue="-"
					label="Deliverable Code Standard"
					name="job.deliverableCodeStandard"
				/>
			</Col>
			<Col sm={8}>
				<Field
					component={TextField}
					defaultValue="-"
					label="Deliverable File Format"
					name="job.deliverableFileFormat"
				/>
			</Col>
			<Col sm={24}>
				<Field
					component={Checkbox}
					isDisabled={disabled}
					label="Exclude Work Order from Deliverables"
					name="excludeFromDeliverables"
				/>
			</Col>
		</Section>
	);
};

export default React.memo(WorkOrderDeliverableData);
