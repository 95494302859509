import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import { RootState } from 'af-reducers';

import * as ShiftActions from 'af-actions/shift';

import { ShiftViewModel } from 'ab-viewModels/shift.viewModel';

import RadioGroup from 'af-fields/RadioGroup';

import { ShiftItems } from 'ab-enums/shift.enum';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

interface OwnProps {
	disabled: boolean;
	onValueChange?: () => void;
}

interface StateProps {
	selector: (prop: string) => string | number;
}

interface DispatchProps {
	createShift: typeof ShiftActions.create;
	findShifts: typeof ShiftActions.findByQuery;
	changeField: typeof change;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class Shift extends React.PureComponent<Props> {
	onSearch = async (text: string) => {
		const { findShifts } = this.props;
		return await findShifts(text);
	};

	onNewShift = async (name: string): Promise<void> => {
		const { changeField, createShift } = this.props;
		const shift = await createShift(name);
		changeField(WORK_ORDER_FORM, 'shift', shift);
	};

	changeField = (field: string, value: number | string) => {
		const { changeField } = this.props;
		changeField(WORK_ORDER_FORM, field, value);
	};

	formatOptionLabel = (option: ShiftViewModel) => <span>{option.name}</span>;

	getOptionValue = (option: ShiftViewModel) => `${option.id}`;

	getOptionLabel = (option: ShiftViewModel) => option.name;

	isValidNewOption = (inputValue: string, value: ShiftViewModel, options: ShiftViewModel[]) => {
		return !!inputValue && !options.some(({ name }) => name.toLowerCase() === inputValue.toLowerCase());
	};

	render(): JSX.Element {
		const { onValueChange, selector, disabled } = this.props;

		return (
			<div className="work-order-upsert__shift">
				<span>Shift *</span>
				<div className="work-order-upsert__shift-container">
					<RadioGroup<ShiftViewModel>
						changeField={this.changeField}
						disabled={disabled}
						field="shift"
						formatOptionLabel={this.formatOptionLabel}
						getOptionLabel={this.getOptionLabel}
						getOptionValue={this.getOptionValue}
						inline={true}
						isValidNewOption={this.isValidNewOption}
						items={ShiftItems}
						labelKey="name"
						onNew={this.onNewShift}
						onValueChange={onValueChange}
						placeholder="Enter Shift *"
						searchOtherOptions={this.onSearch}
						selector={selector}
						valueKey="id"
						withOther={true}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	return {
		selector: (prop: string) => formValueSelector(WORK_ORDER_FORM)(state, prop),
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		changeField: change,
		createShift: ShiftActions.create,
		findShifts: ShiftActions.findByQuery,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(Shift);
