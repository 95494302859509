import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import Card from 'af-root/scenes/Company/ScheduleBoard/Shared/Card';
import BlankCard from 'af-root/scenes/Company/ScheduleBoard/Shared/Card/BlankCard';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import { SelectedWorkOrderModel } from 'af-models/scheduleBoard.models';

import SharedModalProps from '../../../../Shared/ModalProps';

interface OwnProps extends SharedModalProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	/** `MM-DD-YYYY` */
	dueDate: string;
	forceUnlockOrder: (workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	isDragAndDropDisabled: boolean;
	lastOpenedOrderCode: string;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
}

interface StateProps {
	workOrdersOrdering: string[];
}

interface DispatchProps {
	toggleOrder: typeof ScheduleBoardActions.toggleWorkOrderWeeklyView;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class DayViewCards extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		workOrdersOrdering: [],
	};

	onToggle = (workOrder: SelectedWorkOrderModel) => {
		const { dueDate, toggleOrder } = this.props;
		toggleOrder(dueDate, { id: workOrder.id, code: workOrder.code, isCancelled: workOrder.isCancelled });
	};

	render() {
		const {
			addBlankWorkOrder,
			currentWorkOrderModalId,
			dueDate,
			forceUnlockOrder,
			hasPermissionsToEditScheduleBoard,
			isDragAndDropDisabled,
			lastOpenedOrderCode,
			removeBlankWorkOrder,
			setWorkOrderModalId,
			setWorkOrderNoteModalData,
			setEmployeeModalData,
			setEmployeeModalVisibility,
			setEquipmentModalData,
			setEquipmentModalVisibility,
			setTemporaryEmployeeModalData,
			workOrdersOrdering,
		} = this.props;

		return workOrdersOrdering.map((_jobCode: string, _index: number) => {
			const isBlank = ScheduleBoardUtil.isBlankWorkOrderId(_jobCode);
			const isPlaceholder = ScheduleBoardUtil.isLoadingPlaceholderDroppableId(_jobCode);
			return isBlank || isPlaceholder ?
				(
					<BlankCard
						draggableId={_jobCode}
						dueDate={dueDate}
						hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
						index={_index}
						isDragAndDropDisabled={isDragAndDropDisabled}
						isPlaceholder={isPlaceholder}
						key={_jobCode}
						removeBlankWorkOrder={removeBlankWorkOrder}
					/>
				) : (
					<Card
						addBlankWorkOrder={addBlankWorkOrder}
						currentWorkOrderModalId={currentWorkOrderModalId}
						deselectOrder={this.onToggle}
						dueDate={dueDate}
						forceUnlockOrder={forceUnlockOrder}
						hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
						index={_index}
						isDragAndDropDisabled={isDragAndDropDisabled}
						key={_jobCode}
						lastOpenedOrderCode={lastOpenedOrderCode}
						selectOrder={this.onToggle}
						setEmployeeModalData={setEmployeeModalData}
						setEmployeeModalVisibility={setEmployeeModalVisibility}
						setEquipmentModalData={setEquipmentModalData}
						setEquipmentModalVisibility={setEquipmentModalVisibility}
						setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
						setWorkOrderModalId={setWorkOrderModalId}
						setWorkOrderNoteModalData={setWorkOrderNoteModalData}
						workOrderCode={_jobCode}
					/>
				);
		});
	}
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
	const { dueDate } = ownProps;

	return {
		workOrdersOrdering: state?.scheduleBoard?.workOrdersByDateDictionary?.[dueDate]?.workOrdersOrdering,
	};
};

function mapDispatchToProps(): DispatchProps {
	return {
		toggleOrder: ScheduleBoardActions.toggleWorkOrderWeeklyView,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(DayViewCards);
