import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

import { WorkOrderTableViewModel } from 'ab-viewModels/workOrderTable.viewModel';

import ResourceTooltipList from './ResourceTooltipList';
import styles from './styles.module.scss';

interface OwnProps {
	original: WorkOrderTableViewModel;
}

type Props = OwnProps;

const ResourceCell: React.FC<Props> = (props: Props) => {
	const { original } = props;
	const tooltipClassName = styles.resource__popover;

	return (
		<Tooltip
			className={tooltipClassName}
			message={
				<ResourceTooltipList
					workOrderEmployees={original.workOrderEmployees}
					workOrderEquipment={original.workOrderEquipment}
					workOrderTemporaryEmployees={original.workOrderTemporaryEmployees}
				/>
			}
			placement="top"
		>
			<div>
				{original.workOrderEquipment.length}/{original.workOrderEmployees.length}/{original.workOrderTemporaryEmployees.length}
			</div>
		</Tooltip>
	);
};

export default ResourceCell;
