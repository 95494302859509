import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { isAllowed } from 'ab-utils/auth.util';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import CardInfo from './CardInfo';

import { WorkOrderModalProps } from '../../Shared/ModalProps';

interface OwnProps extends WorkOrderModalProps {
	dueDate: string;
	isCalculationsView: boolean;
	isDragging?: boolean;
	lastOpenedOrderCode?: string;
	notificationStatus: Nullable<NotificationStatusEnum>;
	projectManagerName: string;
	supervisorName: string;
	workOrder: ScheduleBoardWorkOrderViewModel;
}

interface StateProps {
	scheduleBoardView: ScheduleBoardView | undefined;
	notificationsEnabled: boolean;
	zoomLevel: number;
	isAllowedToEditWorkOrders: boolean;
	isAllowedToViewProdData: boolean;
}

interface DispatchProps {
	setCopiedWorkOrderCode: typeof ScheduleBoardActions.setCopiedWorkOrderCode;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	modalOpenedForCurrentWorkOrder: boolean;
}

class OrderInfo extends React.PureComponent<Props, State> {

	state: State = {
		modalOpenedForCurrentWorkOrder: false,
	};

	static getDerivedStateFromProps(props: Props): Partial<State> {
		return {
			modalOpenedForCurrentWorkOrder: props.workOrder?.id === props.currentWorkOrderModalId,
		};
	}

	componentDidMount() {
		const { lastOpenedOrderCode, workOrder, isCalculationsView, setWorkOrderModalId } = this.props;
		// we get lastOpenedOrderCode from location.state sent by order form
		if (!isCalculationsView && lastOpenedOrderCode === workOrder.code) {
			setWorkOrderModalId(workOrder.id);
		}
	}

	componentDidUpdate(prevProps: Props) {
		const { workOrder: nextWorkOrder, isCalculationsView, setWorkOrderModalId } = this.props;
		const { workOrder } = prevProps;
		const { modalOpenedForCurrentWorkOrder } = this.state;

		if (isCalculationsView) {
			return;
		}
		const wasDisabled = workOrder.isDisabled && !nextWorkOrder.isDisabled;
		const wasLocked = workOrder.locked && !nextWorkOrder.locked;

		if (modalOpenedForCurrentWorkOrder && nextWorkOrder.id === workOrder.id && (wasLocked || wasDisabled)) {
			setWorkOrderModalId(null);
		}
	}

	openWorkOrderModal = async () => {
		const { isCalculationsView, setWorkOrderModalId, workOrder } = this.props;

		if (isCalculationsView) {
			return;
		}
		// fixed syncing error
		if (workOrder?.id) {
			setWorkOrderModalId(workOrder.id);
		}
	};

	render() {
		const {
			workOrder,
			projectManagerName,
			supervisorName,
			scheduleBoardView,
			setCopiedWorkOrderCode,
			isDragging,
			isCalculationsView,
			notificationStatus,
			notificationsEnabled,
			zoomLevel,
			isAllowedToEditWorkOrders,
			isAllowedToViewProdData,
		} = this.props;

		const isInternal = workOrder.isInternal;

		let className = 'schedule-board-card-order-info';
		className = isInternal ? `${className} internal-card` : className;

		return (
			<div className={className}>
				<CardInfo
					isAllowedToEditWorkOrders={isAllowedToEditWorkOrders}
					isAllowedToViewProdData={isAllowedToViewProdData}
					isCalculationsView={isCalculationsView}
					isDragging={isDragging}
					notificationsEnabled={notificationsEnabled}
					notificationStatus={notificationStatus}
					openOrderInfoModal={this.openWorkOrderModal}
					projectManagerName={projectManagerName}
					scheduleBoardView={scheduleBoardView}
					setCopiedWorkOrderCode={setCopiedWorkOrderCode}
					supervisorName={supervisorName}
					workOrder={workOrder}
					zoomLevel={zoomLevel}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: RootState): StateProps => {
	const { scheduleBoardView } = state.scheduleBoard;
	const { companyData, userData } = state.user;
	const { company } = state.company;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isAllowedToEditWorkOrders: boolean = isAllowed(
		PagePermissions.COMPANY.WORK_ORDERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const isAllowedToViewProdData: boolean = isAllowed(
		PagePermissions.COMPANY.PROD_DATA.VIEW,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		scheduleBoardView: scheduleBoardView ?? undefined,
		notificationsEnabled: company?.notification?.isEnabled ?? false,
		zoomLevel: state.scheduleBoard.zoomLevel,
		isAllowedToEditWorkOrders,
		isAllowedToViewProdData,
	};
};

const mapDispatchToProps = (): DispatchProps => ({
	setCopiedWorkOrderCode: ScheduleBoardActions.setCopiedWorkOrderCode,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(OrderInfo);
