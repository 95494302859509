import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'af-reducers';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	blockId: string;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

class PreviewHeader extends React.PureComponent<Props> {

	render() {
		const { blockName,
			fieldReportBlock,
		} = this.props;
		const { isMain } = fieldReportBlock;

		const className = bemElement('field-report-block', 'header-left', { main: isMain });

		return (
			<div className="field-report-block__header">
				<div className={className}>
					<span>{blockName}</span>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { fieldReport: { fieldReport }, user: { userData } } = state;
	const { blockId } = ownProps;

	if (!userData) {
		throw new Error('User not logged in');
	}

	const fieldReportBlock = fieldReport?.fieldReportBlockMap?.[blockId];
	if (!fieldReportBlock) {
		throw new Error('fieldReportBlock not found');
	}

	const reportBlock = fieldReport?.blockMap?.[fieldReportBlock.reportBlockId];

	return {
		blockName: reportBlock?.name,
		fieldReportBlock,
	};
}

const connector = connect(mapStateToProps);

export default connector(PreviewHeader);
