import * as React from 'react';
import { connect } from 'react-redux';

import { TOOLBAR_GROUP_DEFAULT_ID } from 'ab-constants/scheduleBoard';
import { UNKNOWN_LOCATION_NICKNAME } from 'ab-constants/value';

import { DisplayViewEquipmentViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import { RootState } from 'af-reducers';

import AvailableEquipmentGroup from './EquipmentGroup/AvailableEquipmentGroup';
import UnavailableEquipmentGroup from './EquipmentGroup/UnavailableEquipmentGroup';

interface EquipmentGroup {
	groupId: number;
	equipmentList: DisplayViewEquipmentViewModel[];
	isDeleted: boolean;
	statusName: Nullable<string>;
}

interface OwnProps {
	dueDate: string;
}

interface StateProps {
	availableEquipmentGroups: Nullable<EquipmentGroup[]>;
	unavailableEquipmentGroups: Nullable<EquipmentGroup[]>;
}

type Props = OwnProps & StateProps;

class EquipmentSection extends React.PureComponent<Props> {

	render() {
		const { availableEquipmentGroups, unavailableEquipmentGroups } = this.props;

		return (
			<div className="display-view-equipment-section-wrapper">
				<div className="display-view-equipment-section">
					<span className="display-view-section-title">EQUIPMENT (AVAILABLE)</span>
					<div className="display-view-groups-wrapper">
						{
							availableEquipmentGroups && availableEquipmentGroups.length > 0 && availableEquipmentGroups.map((_equipmentGroup) =>
								_equipmentGroup.isDeleted && !_equipmentGroup.equipmentList.length ? null : (
									<AvailableEquipmentGroup
										equipmentList={_equipmentGroup.equipmentList}
										key={_equipmentGroup.groupId}
										status={_equipmentGroup.statusName}
									/>
								))
						}
					</div>
					<span className="display-view-section-title">EQUIPMENT (UNAVAILABLE)</span>
					<div className="display-view-groups-wrapper">
						{
							unavailableEquipmentGroups && unavailableEquipmentGroups.length > 0 && unavailableEquipmentGroups.map((_equipmentGroup) =>
								_equipmentGroup.isDeleted && !_equipmentGroup.equipmentList.length ? null : (
									<UnavailableEquipmentGroup
										equipmentList={_equipmentGroup.equipmentList}
										key={_equipmentGroup.groupId}
										status={_equipmentGroup.statusName}
									/>
								))
						}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate } = ownProps;

	if (!state.scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return {
			availableEquipmentGroups: null,
			unavailableEquipmentGroups: null,
		};
	}

	const workOrdersOnDateDict = state.scheduleBoard.workOrdersByDateDictionary[dueDate];

	if (!state.scheduleBoard.equipment || !state.scheduleBoard.toolbarEquipmentGroupTitles) {
		throw new Error('SB equipment toolbar not defined');
	}

	const availableEquipmentGroupTitles = [...state.scheduleBoard.toolbarEquipmentGroupTitles.available];

	if (workOrdersOnDateDict?.toolbarEquipment?.available?.[TOOLBAR_GROUP_DEFAULT_ID]?.length) {
		availableEquipmentGroupTitles.push({ id: TOOLBAR_GROUP_DEFAULT_ID, title: UNKNOWN_LOCATION_NICKNAME, isDeleted: false });
	}

	const availableEquipmentGroups = availableEquipmentGroupTitles.map(({ id: _eqStatusId, isDeleted, title: _eqStatusName }): EquipmentGroup => {
		const equipmentList: DisplayViewEquipmentViewModel[] = [];
		const listOfEquipmentIdForLocationName = workOrdersOnDateDict?.toolbarEquipment?.available?.[_eqStatusId] ?? [];

		for (const equipmentId of listOfEquipmentIdForLocationName) {

			const equipment = state.scheduleBoard.equipment![equipmentId];
			if (equipment.showOnScheduleBoard) {
				equipmentList.push({
					code: equipment.name,
					color: equipment.color,
					equipmentId: equipment.id,
					specification: equipment.spec,
					numberOfAssignments: 0,
					isAvailable: true,
				});
			}
		}

		return { groupId: _eqStatusId, statusName: _eqStatusName, isDeleted, equipmentList };
	});

	const unavailableEquipmentGroups = state.scheduleBoard.toolbarEquipmentGroupTitles.unavailable.map(
		({ id: _eqStatusId, isDeleted, title: _eqStatusName }): EquipmentGroup => {
			const equipmentList: DisplayViewEquipmentViewModel[] = [];
			const listOfEquipmentIdForLocationName = workOrdersOnDateDict?.toolbarEquipment?.unavailable?.[_eqStatusId] ?? [];
			for (const equipmentId of listOfEquipmentIdForLocationName) {
				const equipment = state.scheduleBoard.equipment![equipmentId];
				if (equipment.showOnScheduleBoard) {
					equipmentList.push({
						code: equipment.name,
						color: equipment.color,
						equipmentId: equipment.id,
						returnDate: workOrdersOnDateDict?.equipmentReturnDate?.[equipmentId] ?? 'N/A',
						specification: equipment.spec,
						unavailabilityReason: workOrdersOnDateDict?.equipmentUnavailabilityReason?.[equipmentId]?.name ?? 'N/A',
						numberOfAssignments: 0,
						isAvailable: false,
					});
				}
			}

			return { groupId: _eqStatusId, statusName: _eqStatusName, isDeleted, equipmentList };
		}
	);

	return {
		availableEquipmentGroups,
		unavailableEquipmentGroups,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(EquipmentSection);
