import * as React from 'react';
import { Field } from 'redux-form';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import { QuantityUnitLabel } from 'acceligent-shared/enums/quantityUnit';

import { bemElement } from 'ab-utils/bem.util';

import Dropdown from 'af-fields/Dropdown';

import { BillableWorkInformationFieldFormModel } from './FormModel';
import { BillableWorkDropdownOption } from './types';

interface OwnProps {
	fieldName: string;
	index: number;
	options: BillableWorkDropdownOption[];
	onDelete: (index: number) => void;
	onChange: (fieldName: string) => (field: BillableWorkDropdownOption) => void;
	valueKey: keyof BillableWorkDropdownOption;
}

type Props = OwnProps;

const _getOptionLabel = (option: BillableWorkDropdownOption) => {
	const fieldType = ReportBlockFieldEnum.PrimaryTypeNamed[option.type] ?? '';
	const unit = option.unit ? QuantityUnitLabel[option.unit] : '';
	return `${fieldType} ${unit ? `[${unit}]` : ''}`;
};

const _getSegment = (index: number) => {
	return index === 0 ? 'Primary' : 'Secondary';
};

const _renderItem = (item: BillableWorkDropdownOption) => {
	return (
		<div className="billable-work-modal__information-fields-section__field-container__dropdown__dropdown-option">
			<div className="billable-work-modal__information-fields-section__field-container__dropdown__dropdown-option__item">{item.name}</div>
			<div className={bemElement('billable-work-modal__information-fields-section__field-container__dropdown__dropdown-option', 'item', ['meta'])}>
				{`${_getSegment(item.isInPrimarySegment ? 0 : 1)} - ${item.reportBlockName} - ${_getOptionLabel(item)}`}
			</div>
		</div>
	);
};

const _renderSelected = (item: BillableWorkDropdownOption) => item.name;

const InformationFieldField: React.FC<Props> = (props: Props) => {
	const { fieldName, index, onDelete, options, valueKey, onChange } = props;

	const handleDelete = React.useCallback(() => {
		onDelete(index);
	}, [index, onDelete]);

	return (
		<div className="billable-work-modal__information-fields-section__field-container">
			<Field
				className="billable-work-modal__information-fields-section__field-container__dropdown"
				component={Dropdown}
				labelKey="name"
				name={`${fieldName}.${BillableWorkInformationFieldFormModel.field('reportBlockFieldVirtualId')}`}
				onValueChange={onChange(fieldName)}
				options={options}
				placeholder="Select an Information Field"
				renderMenuItem={_renderItem}
				renderSelected={_renderSelected}
				valueKey={valueKey}
				withCaret={true}
			/>
			<span
				className="icon-delete billable-work-modal__information-fields-section__field-container__delete-option"
				onClick={handleDelete}
			/>
		</div>
	);
};

export default React.memo(InformationFieldField);
