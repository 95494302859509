import * as React from 'react';

import WorkSummaryVM from 'ab-viewModels/fieldReport/workSummary.viewModel';
import WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';
import { BillingCodeVM } from 'ab-viewModels/job.viewModel';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import { downloadCSV } from 'af-utils/csv.utils';
import { dollarFormatter } from 'af-utils/format.util';

import BillableAndNonBillableWorkTable from '../Table/BillableAndNonBillableWorkTable';

interface Props {
	workSummary: WorkSummaryVM[];
	billingCodes: BillingCodeVM[];
	workSummaryDetails: WorkSummaryDetailVM[];
	onRowHighlight: (groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => void;
	highlightedGroup: Nullable<string>;
	highlightedGroupKey: Nullable<string>;
	workSummaryDetailsByGroupKey: Record<string, WorkSummaryDetailVM[]>;
	isReadOnly: boolean;
	alternativeWorkSummary: Set<WorkSummaryVM>;
	workOrderCode: string;
}

const NonBillableWork: React.FC<Props> = (props) => {
	const {
		workSummary,
		billingCodes,
		workSummaryDetails,
		onRowHighlight,
		highlightedGroup,
		isReadOnly,
		highlightedGroupKey,
		workSummaryDetailsByGroupKey,
		alternativeWorkSummary,
		workOrderCode,
	} = props;

	const totalNonBillableWork = workSummary.reduce((_acc, _curr) => {
		return _acc + (_curr.total ?? 0);
	}, 0);
	const showHint = totalNonBillableWork === 0;

	const headerClassName = bemElement('field-report__work-summary__billable-work', 'header');
	const nonBillableWorkClassName = bemBlock('field-report__work-summary__billable-work', { 'non-billable': true });
	const exportWorkSummaryClassName = `btn btn--link ${bemElement(headerClassName, 'export')} ${!workSummary.length ? 'disabled' : ''}`;

	const onExportClick = React.useCallback(() => {
		const csvData = WorkSummaryVM.toCSVData(workSummary);
		downloadCSV(csvData, `${workOrderCode}_non_billable_work.csv`);
	}, [workOrderCode, workSummary]);

	return (
		<div className={nonBillableWorkClassName}>
			<div className={headerClassName}>
				<span className={bemElement(headerClassName, 'title')}>
					Non-Billable Work
				</span>
				<span className={exportWorkSummaryClassName} onClick={onExportClick}>
					<span className="icon-download" />
					Export
				</span>
			</div>
			<BillableAndNonBillableWorkTable
				alternativeWorkSummary={alternativeWorkSummary}
				billingCodes={billingCodes}
				highlightedGroup={highlightedGroup}
				highlightedGroupKey={highlightedGroupKey}
				isBillable={false}
				isReadOnly={isReadOnly}
				onRowHighlight={onRowHighlight}
				workSummary={workSummary}
				workSummaryDetails={workSummaryDetails}
				workSummaryDetailsByGroupKey={workSummaryDetailsByGroupKey}
			/>
			<div className={bemElement('field-report__work-summary__billable-work', 'total')}>
				{showHint && (
					<span className={bemElement('field-report__work-summary__billable-work__total', 'hint')}>
						Non-billable total is calculated after work with a billing code is moved to non-billable table
					</span>
				)}
				{`Non-billable total ${dollarFormatter.format(totalNonBillableWork)}`}
			</div>
		</div>
	);
};

export default React.memo(NonBillableWork);
