import * as React from 'react';

import { DisplayViewResourceViewModel, isDisplayViewEmployeeViewModel, isDisplayViewEquipmentViewModel, isDisplayViewPlaceholderViewModel, isDisplayViewTemporaryEmployeeViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import { EmployeeNightShiftAssignmentMapVM, TemporaryEmployeeNightShiftAssignmentMapVM } from 'ab-utils/nightShift.util';

import AvailableEmployeeItem from 'af-root/scenes/Company/ScheduleBoard/DisplayView/ResourceItems/EmployeeItems/AvailableEmployeeItem';
import AvailableEquipmentItem from 'af-root/scenes/Company/ScheduleBoard/DisplayView/ResourceItems/EquipmentItems/AvailableEquipmentItem';
import PlaceholderItem from 'af-root/scenes/Company/ScheduleBoard/DisplayView/ResourceItems/PlaceholderItem';
import TemporaryEmployeeItem from 'af-root/scenes/Company/ScheduleBoard/DisplayView/ResourceItems/TemporaryEmployeeItem';

interface OwnProps {
	employeeNightShiftAssignments: EmployeeNightShiftAssignmentMapVM;
	hideMultipleAssignmentBadges: boolean;
	resources: DisplayViewResourceViewModel[];
	temporaryEmployeeNightShiftAssignments: TemporaryEmployeeNightShiftAssignmentMapVM;
}

type Props = OwnProps;

const ResourceColumn: React.FC<Props> = (props: Props) => {
	const { hideMultipleAssignmentBadges, employeeNightShiftAssignments, temporaryEmployeeNightShiftAssignments, resources } = props;

	const renderResource = React.useCallback((resource: DisplayViewResourceViewModel) => {
		if (isDisplayViewEmployeeViewModel(resource)) {
			return (
				<AvailableEmployeeItem
					employee={resource}
					hideMultipleAssignmentBadges={hideMultipleAssignmentBadges}
					key={`employee-${resource.employeeId}`}
					wasInNightShift={employeeNightShiftAssignments[resource.employeeId]}
				/>
			);
		} else if (isDisplayViewEquipmentViewModel(resource)) {
			return (
				<AvailableEquipmentItem
					equipment={resource}
					hideMultipleAssignmentBadges={hideMultipleAssignmentBadges}
					key={`equipment-${resource.equipmentId}`}
				/>
			);
		} else if (isDisplayViewPlaceholderViewModel(resource)) {
			return (
				<PlaceholderItem
					key={`placeholder-${resource.placeholderId}`}
					placeholder={resource}
				/>
			);
		} else if (isDisplayViewTemporaryEmployeeViewModel(resource)) {
			return (
				<TemporaryEmployeeItem
					employee={resource}
					hideMultipleAssignmentBadges={hideMultipleAssignmentBadges}
					key={`temporary-employee-${resource.temporaryEmployeeId}`}
					wasInNightShift={temporaryEmployeeNightShiftAssignments[resource.temporaryEmployeeId] ?? false}
				/>
			);
		}
		return null;
	}, [hideMultipleAssignmentBadges, employeeNightShiftAssignments, temporaryEmployeeNightShiftAssignments]);

	return (
		<div className="resource-column">
			{resources.map(renderResource)}
		</div>
	);
};

export default ResourceColumn;
