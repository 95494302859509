import * as React from 'react';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	modalTitle: string;
	descriptionTitle: JSX.Element | null;
	descriptionMessage: JSX.Element | null;
	showModal: boolean;
	closeModal: () => void;
}

type Props = OwnProps;

const ActionSuccessModal: React.FC<Props> = (props) => {
	const { closeModal, showModal, modalTitle, descriptionTitle, descriptionMessage } = props;

	return (
		<CustomModal
			className="submit-success-modal"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header closeModal={closeModal} title={modalTitle} />
			<CustomModal.Body>
				<div className="submit-success-modal__content-title">{descriptionTitle}</div>
				<span className="submit-success-modal__check-icon icon-check_circle text-green" />
				<div className="submit-success-modal__user-message">{descriptionMessage}</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button onClick={closeModal} variant="info">Close</Button>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export type ActionSuccessModalProps = OwnProps;
export default React.memo(ActionSuccessModal);
