import RepairStatus from 'acceligent-shared/enums/repairStatus';
import Carrier from 'acceligent-shared/enums/orderCarrier';

import UpsertRM from 'ab-dtos/requestModels/toolRepair/upsert.requestModel';

import UpdateVM from 'ab-dtos/viewModels/toolRepair/update.viewModel';
import { ColorPalette } from 'acceligent-shared/enums/color';

interface LocationFM {
	id: number;
	nickname: string;
}

interface ToolFM {
	id: number;
	serialNumber: string;
	toolType: string;
}

interface WorkRequestFM {
	id: number;
	jobCode: string;
}

interface EquipmentFM {
	id: number;
	code: string;
	specification: string;
	equipmentCostCategoryColor: Nullable<ColorPalette>;
}

interface EmployeeAccountFM {
	id: number;
	accountId: number;
	firstName: string;
	lastName: string;
	uniqueId: string;
}

interface AccountFM {
	id: number;
	fullName: string;
}

interface RepairLocationFM {
	id: number;
	name: string;
}

interface OrderItemFM {
	id: number;
	itemName: string;
	quantity: number;
	price: number;
	isInEditMode: boolean;
}

class FormModel {
	tool: ToolFM;
	status: RepairStatus; // not input
	price: string;
	priceManualOverride: boolean;
	workRequest: Nullable<WorkRequestFM>;
	equipment: Nullable<EquipmentFM>;
	superintendent: EmployeeAccountFM;
	operator: EmployeeAccountFM;
	location: LocationFM;
	reportedIssue: string;
	internalComment: Nullable<string>;
	externalComment: Nullable<string>;
	createdAt: Date; // not input
	receivedAt: Nullable<Date>; // not input
	startedAt: Nullable<Date>; // not input
	completedAt: Nullable<Date>; // not input
	shippedAt: Nullable<Date>; // not input
	shippedBy: Nullable<EmployeeAccountFM>;
	isExternal: boolean;
	returnedAt: Nullable<Date>; // not input
	repairLocation: Nullable<RepairLocationFM>;
	carrier: Nullable<Carrier>;
	trackingNumber: Nullable<string>;
	repairedBy: Nullable<AccountFM>;
	items: OrderItemFM[];

	constructor(vm: UpdateVM) {
		this.tool = vm.tool;
		this.status = vm.status;
		this.price = vm.price;
		this.priceManualOverride = vm.priceManualOverride;
		this.workRequest = vm.workRequest;
		this.equipment = vm.equipment;
		this.superintendent = vm.superintendent;
		this.operator = vm.operator;
		this.location = vm.location;
		this.reportedIssue = vm.reportedIssue;
		this.internalComment = vm.internalComment;
		this.externalComment = vm.externalComment;
		this.createdAt = vm.createdAt;
		this.receivedAt = vm.receivedAt;
		this.startedAt = vm.startedAt;
		this.completedAt = vm.completedAt;
		this.shippedAt = vm.shippedAt;
		this.shippedBy = vm.shippedBy;
		this.isExternal = vm.isExternal;
		this.returnedAt = vm.returnedAt;
		this.repairLocation = vm.repairLocation;
		this.carrier = vm.carrier;
		this.trackingNumber = vm.trackingNumber;
		this.repairedBy = vm.repairedBy;
		this.items = vm.items.map((_item) => ({ ..._item, isInEditMode: false }));
	}

	static toRequestModel(form: FormModel): UpsertRM {
		return {
			toolId: form.tool.id,
			status: form.status,
			price: +form.price,
			priceOverride: form.priceManualOverride,
			workRequestId: form.workRequest?.id ?? null,
			equipmentId: form.equipment?.id ?? null,
			superintendentId: form.superintendent.accountId,
			operatorId: form.operator.accountId,
			locationId: form.location.id,
			reportedIssue: form.reportedIssue,
			internalComment: form.internalComment,
			externalComment: form.externalComment,
			shippedById: form.shippedBy?.accountId ?? null,
			repairLocationId: form.repairLocation?.id ?? null,
			carrier: form.carrier,
			trackingNumber: form.trackingNumber,
			repairedById: form.repairedBy?.id ?? null,
			items: form.items?.map((_item) => ({
				itemDepartmentId: _item.id,
				quantity: _item.quantity,
			})) ?? [],
		};
	}
}

export default FormModel;
