import * as React from 'react';
import { Redirect } from 'react-router-dom';

import CLIENT from 'af-constants/routes/client';

export default class HomeRedirect extends React.Component<Metadata> {

	render() {
		const { location, location: { state: { orgAlias } } } = this.props;
		return <Redirect to={{ ...location, pathname: CLIENT.AUTH.LOGIN(orgAlias) }} />;
	}

}
