import SegmentLabel from 'af-components/SegmentLabel';
import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

export default class Loading extends React.Component {

	render() {
		return (
			<div className="form-segment compliance-loading">
				<div className="form-box form-box--standalone">
					<Row>
						<Col sm={24}>
							<SegmentLabel label="Compliance" />
						</Col>
					</Row>
					<Row className="p-l row--column-aligned">
						<Col sm={4} />
						<Col sm={16}>
							<Col className="loading-box-field" sm={16} />
							<Col className="loading-box-row" sm={24} />
							<Col className="loading-box-row" sm={24} />
							<Col className="loading-box-row" sm={24} />
							<Col className="loading-box-row" sm={24} />
						</Col>
						<Col sm={4} />
					</Row>
					<Row className="p-l row--column-aligned">
						<Col sm={4} />
						<Col sm={16}>
							<Col className="loading-box-field" sm={16} />
							<Col className="loading-box-row" sm={24} />
							<Col className="loading-box-row" sm={24} />
							<Col className="loading-box-row" sm={24} />
							<Col className="loading-box-row" sm={24} />
						</Col>
						<Col sm={4} />
					</Row>
					<Row className="p-l row--column-aligned">
						<Col sm={4} />
						<Col sm={16}>
							<Col className="loading-box-field" sm={16} />
							<Col className="loading-box-row" sm={24} />
							<Col className="loading-box-row" sm={24} />
							<Col className="loading-box-row" sm={24} />
							<Col className="loading-box-row" sm={24} />
						</Col>
						<Col sm={4} />
					</Row>
				</div>
			</div>
		);
	}
}
