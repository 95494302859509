import * as React from 'react';
import { Button } from 'react-bootstrap';

import { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';

import Radio from 'af-components/Controls/Radio';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	canSubmitToManagement: boolean;
	canSubmitToAccounting: boolean;
	closeModal: () => void;
	currentLevel: WorkOrderReviewLevel;
	onConfirm: (reviewLevel: WorkOrderReviewLevel) => void;
	showModal: boolean;
	title: string;
}

type Props = OwnProps;

const SUPERINTENDENT_LABEL = 'Submit to superintendent for review';
const MANAGEMENT_LABEL = 'Approve and submit to management';
const ACCOUNTING_LABEL = 'Approve and submit to accounting';

const SubmitToModalBody: React.FC<Props> = (props: Props) => {
	const { showModal, closeModal, title, canSubmitToAccounting, onConfirm, currentLevel } = props;
	const level = `LEVEL_${currentLevel + 1}`;
	const [submitToLevel, setSubmitToLevel] = React.useState<WorkOrderReviewLevel>(WorkOrderReviewLevel[level]);

	const checkSubmitToSI = React.useCallback(() => setSubmitToLevel(WorkOrderReviewLevel.LEVEL_1), [setSubmitToLevel]);

	const checkSubmitManagementLevel = React.useCallback(() => setSubmitToLevel(WorkOrderReviewLevel.LEVEL_2), [setSubmitToLevel]);

	const checkSubmitAccountingLevel = React.useCallback(() => setSubmitToLevel(WorkOrderReviewLevel.LEVEL_3), [setSubmitToLevel]);

	const handleOnConfirm = React.useCallback(() => {
		onConfirm(submitToLevel);
	}, [onConfirm, submitToLevel]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={title}
			/>
			<CustomModal.Body>
				<div className="report-submit-modal__title">Submit report as:</div>
				{currentLevel === WorkOrderReviewLevel.LEVEL_0 &&
					<div className="report-submit-modal__radio">
						<Radio
							checked={submitToLevel === WorkOrderReviewLevel.LEVEL_1}
							inline={true}
							label={SUPERINTENDENT_LABEL}
							onCheck={checkSubmitToSI}
						/>
					</div>}
				<div className="report-submit-modal__radio">
					<Radio
						checked={submitToLevel === WorkOrderReviewLevel.LEVEL_2}
						inline={true}
						label={MANAGEMENT_LABEL}
						onCheck={checkSubmitManagementLevel}
					/>
				</div>
				{canSubmitToAccounting &&
					<div className="report-submit-modal__radio">
						<Radio
							checked={submitToLevel === WorkOrderReviewLevel.LEVEL_3}
							inline={true}
							label={ACCOUNTING_LABEL}
							onCheck={checkSubmitAccountingLevel}
						/>
					</div>
				}
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<Button
					disabled={!submitToLevel}
					onClick={handleOnConfirm}
					variant="primary"
				>
					Continue
				</Button>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default React.memo(SubmitToModalBody);
