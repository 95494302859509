import * as React from 'react';

import WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import AddressList from './AddressList';
import AddressHeader from './AddressHeader';

import Changes from '../../Changes';

import { generateWorkLocationAddressStringIdentifier, getListOfAddedWorkLocationAddresses, getRemovedWorkLocationAddressesDictionary } from 'af-utils/workOrderModal.util';

import SegmentLabel from 'af-components/SegmentLabel';

interface Props {
	workOrderDataToShow: WorkOrderModalViewModel;
	currentWorkOrderData: WorkOrderModalViewModel;
}

class WorkLocationsSection extends React.PureComponent<Props> {
	getAddedAddressesResourcesPopover = () => {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;
		const listOfAddedAddresses = getListOfAddedWorkLocationAddresses(workOrderDataToShow, currentWorkOrderData);

		if (listOfAddedAddresses.length === 0) {
			return null;
		}

		return (
			<>
				<span className="added-address__info">List of addresses that were added in the meantime: </span>
				{
					listOfAddedAddresses.map((_address, _index) => (
						<div className="added-address__content" key={`added-address-${_index}`}>
							{generateWorkLocationAddressStringIdentifier(_address)}
						</div>
					))
				}
			</>
		);
	};

	render() {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;

		const removedAddressesDictionary = getRemovedWorkLocationAddressesDictionary(workOrderDataToShow, currentWorkOrderData);
		const isAtLeastOneAddressRemoved = Object.keys(removedAddressesDictionary).length > 0;

		const addedAddressesResourcesPopover = this.getAddedAddressesResourcesPopover();

		const _addresses = workOrderDataToShow.addresses ?? [];

		return (
			<div className="work-order-info-modal__work-locations">
				<Changes addedDataPopover={addedAddressesResourcesPopover} removedData={isAtLeastOneAddressRemoved} />
				<SegmentLabel label="Work Locations" />
				{_addresses.length
					? <>
						<AddressHeader />
						<AddressList
							listOfAddresses={_addresses}
							removedAddressesDictionary={removedAddressesDictionary}
						/>
					</>
					: <span className="no-data-label">No work locations available</span>
				}
			</div>
		);
	}
}

export default WorkLocationsSection;
