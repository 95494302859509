import * as React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';
import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';

import * as ScheduleBoardUtil from 'ab-utils/scheduleBoard.util';
import { bemBlock } from 'ab-utils/bem.util';

interface Props {
	dueDate: string;
	label: string;
	uniqueCode: string;
	isPlaceholder: boolean;
}

const ResourcePlaceholder = ({ dueDate, label, uniqueCode, isPlaceholder }: Props) => (
	<div className="resource-placeholder">
		<Droppable
			droppableId={ScheduleBoardUtil.generateDroppableId(ScheduleBoardContext.TOOLBAR, ScheduleBoardProperty.RESOURCE, dueDate, uniqueCode)}
			isDropDisabled={true}
		>
			{(droppableProvided) => {
				return (
					<div ref={droppableProvided.innerRef}>
						<Draggable
							draggableId={`${ScheduleBoardContext.TOOLBAR}#${ScheduleBoardProperty.RESOURCE}#${dueDate}#${uniqueCode}`}
							index={0}
						>
							{(provided) => (
								<div
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									className={bemBlock('sb-resource-item', { placeholder: isPlaceholder })}
								>
									<div className="sb-resource-item__content">
										<span className="sb-resource-item__full-name sb-resource-item__full-name--transparent-bg">{label}</span>
									</div>
								</div>
							)}
						</Draggable>
					</div>
				);
			}}
		</Droppable>
	</div>
);

export default React.memo(ResourcePlaceholder);
