import * as React from 'react';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';

import SettingPreview from '../../Shared/SettingPreview';
import PreviewItem from '../../Shared/PreviewItem';

type Props = {
	arePortalFunctionalitiesEnabled: boolean;
	toggleEdit: () => void;
};

const EnablePortalFunctionalitiesPreview: React.FC<Props> = ({ arePortalFunctionalitiesEnabled, toggleEdit }) => {
	return (
		<SettingPreview toggleEdit={toggleEdit}>
			<div className="company-settings__info-segment">
				<PreviewItem
					checked={!!arePortalFunctionalitiesEnabled}
					hint={CompanyFeatureFlagsTips.portalFunctionalities}
					indent={false}
					label={CompanyFeatureFlagsEnum.arePortalFunctionalitiesEnabled}
					showCheckbox={true}
				/>
			</div>
		</SettingPreview>
	);
};

export default React.memo(EnablePortalFunctionalitiesPreview);
