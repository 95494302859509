import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import TimeSplitEquipmentVM from 'acceligent-shared/dtos/web/view/timeSplitEquipment/timeSplitEquipment';
import FieldWorkClassificationCodeListItemVM from 'acceligent-shared/dtos/web/view/fieldWorkClassificationCode/listItem';
import { TimeSplitEntryRM } from 'acceligent-shared/dtos/web/request/timeSheet/timeSheetUpdate';

import { filterMap } from 'acceligent-shared/utils/array';

import { TimeSplitEntryFM } from './formModel';
import TimeSplitEntry from './TimeSplitEntry';

interface OwnProps {
	canEdit: boolean;
	workOrderId: number;
	workRequestId: number;
	classificationCodeList: FieldWorkClassificationCodeListItemVM[];
	equipment: TimeSplitEquipmentVM;
	inEditMode: boolean;
	startEditing: () => void;
	endEditing: () => void;
	update: (form: TimeSplitEntryRM[]) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<TimeSplitEntryFM>;

const filterEntries = (entry: TimeSplitEntryFM) => !!entry.id || !!entry.equipment?.id || !!entry.classificationCode?.id;

const TimeSplitArray: React.FC<Props> = (props) => {
	const {
		fields,
		classificationCodeList,
		equipment,
		update,
		canEdit,
		inEditMode,
		startEditing,
		endEditing,
		workRequestId,
	} = props;

	const updateEquipment = React.useCallback((entries: TimeSplitEntryFM[]) => {
		update(filterMap(entries, filterEntries, TimeSplitEntryFM.toRequestModel));
	}, [update]);

	const onValueChange = React.useCallback((index: number, entry: TimeSplitEntryFM) => {
		const entries = fields.getAll();
		entries.splice(index, 1, entry);
		updateEquipment(entries);
	}, [fields, updateEquipment]);

	const add = React.useCallback(() => {
		fields.push({ allocatedWorkRequestId: workRequestId } as TimeSplitEntryFM);
		startEditing();
	}, [fields, startEditing, workRequestId]);

	const remove = React.useCallback((index: number) => {
		fields.remove(index);
		const entries = fields.getAll();
		const removed = entries.splice(index, 1);
		if (filterEntries(removed[0])) {
			updateEquipment(entries);
		}

		if (!entries.length) {
			endEditing();
		}
	}, [fields, updateEquipment, endEditing]);

	const toggleEdit = React.useCallback(() => {
		if (inEditMode) {
			endEditing();
		} else {
			startEditing();
		}
	}, [inEditMode, startEditing, endEditing]);

	const renderEquipment = (_equipment: string, _index: number) => {
		const entry = fields.get(_index);
		return (
			<TimeSplitEntry
				canEdit={canEdit}
				classificationCodeList={classificationCodeList}
				entry={entry}
				equipment={equipment}
				field={_equipment}
				index={_index}
				inEditMode={inEditMode}
				key={entry.id ?? _index}
				onToggleEdit={toggleEdit}
				onValueChange={onValueChange}
				remove={remove}
			/>
		);
	};

	const renderAddEquipment = React.useMemo(() => {
		return (
			<div className="condensed-table__add-row">
				<div className="condensed-table__add-row-button" onClick={add}>
					<span className="icon-plus" />
					<span>Add equipment</span>
				</div>
			</div>
		);
	}, [add]);

	return (
		<div className="condensed-table__employee-list-equipment">
			{fields.map(renderEquipment)}
			{canEdit && (inEditMode || !fields.length) && renderAddEquipment}
		</div>
	);
};

export default TimeSplitArray;
