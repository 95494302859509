import * as React from 'react';
import { Button, Row } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, InjectedFormProps, Field, getFormValues, FieldArray, FormErrors } from 'redux-form';

import { PrimaryTypeNamed, EQUIVALENT_BILLABLE_WORK_FIELD_TYPES } from 'acceligent-shared/enums/reportBlockField';
import { QuantityUnitLabel } from 'acceligent-shared/enums/quantityUnit';
import DefaultBillableWorkQuantity from 'acceligent-shared/enums/defaultBillableWorkQuantity';

import { bemElement } from 'ab-utils/bem.util';

import { BillableWorkRM } from 'ab-requestModels/reportType/reportType.requestModel';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import Checkbox from 'af-components/Controls/Checkbox';

import { BILLABLE_WORK } from 'af-constants/reduxForms';

import Input from 'af-fields/Input';
import Dropdown from 'af-fields/Dropdown';

import { RootState } from 'af-reducers';

import { ReportTypeBlockFormModel, ReportBlockFormModel } from '../../../Shared/formModel';

import BillableWorkDefinitionFieldsSection, { BillableWorkDefinitionFieldFieldProps } from './BillableWorkDefinitionFieldsSection';
import BillableWorkInformationFieldsSection, { BillableWorkInformationFieldFieldProps } from './BillableWorkInformationFieldsSection';
import FormModel, { BillableWorkDefinitionFieldFormModel, BillableWorkInformationFieldFormModel } from './FormModel';
import { BillableWorkDropdownOption } from './types';

const getFormData = getFormValues(BILLABLE_WORK);

interface OwnProps {
	initialData: Nullable<BillableWorkRM>;
	billableWorkFieldsByIdMap: { [id: string]: BillableWorkDropdownOption; };
	definitionFieldFieldIds: string[];
	informationFieldFieldIds: string[];
	reportTypeBlocksByIdMap: Record<string, ReportTypeBlockFormModel>;
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; };
	reservedBillableWorkNames: Nullable<Record<string, true>>;
	showModal: boolean;
	workTypeFieldIds: string[];
	workQuantityFieldIds: string[];
	closeModal: () => void;
	onCreate: (billableWork: BillableWorkRM) => void;
	onEdit: (billableWork: BillableWorkRM) => void;
}

type Props = OwnProps & InjectedFormProps<FormModel> & ConnectedProps<typeof connector>;

const _areInSameSegment = (field: BillableWorkDropdownOption, quantityWorkTypeField: Nullable<BillableWorkDropdownOption>) => {
	return quantityWorkTypeField ? (quantityWorkTypeField.isInPrimarySegment === field.isInPrimarySegment) : true;
};

/**
 * Logic for allowing definition/info fields
 * 1. If quantity/work type field in TOTAL block, definition/info field has to be in TOTAL blocks also
 * 2. If quantity/work type field in PRIMARY segment, definition/info field can be in PRIMARY segment or in TOTAL block
 * 3. If quantity/work type field in SECONDARY segment, definition/info field can be in anywhere
 */
const _isAllowedAsDefinitionOrInfoField = (field: BillableWorkDropdownOption, quantityField: Nullable<BillableWorkDropdownOption>) => {
	if (!quantityField) {
		return true;
	}
	if (quantityField.isTotalBlock) {
		return field.isTotalBlock;
	}
	if (quantityField.isInPrimarySegment) {
		return field.isInPrimarySegment || field.isTotalBlock;
	}
	return true;
};

const _getOptionLabel = (option: BillableWorkDropdownOption) => {
	const fieldType = PrimaryTypeNamed[option.type] ?? '';
	const unit = option.unit ? QuantityUnitLabel[option.unit] : '';
	return `${fieldType} ${unit ? `[${unit}]` : ''}`;
};

const _getSegment = (index: number) => {
	return index === 0 ? 'Primary' : 'Secondary';
};

const _renderItem = (item: BillableWorkDropdownOption) => {
	return (
		<div className="billable-work-modal__information-fields-section__field-container__dropdown__dropdown-option">
			<div className="billable-work-modal__information-fields-section__field-container__dropdown__dropdown-option__item">{item.name}</div>
			<div className={bemElement('billable-work-modal__information-fields-section__field-container__dropdown__dropdown-option', 'item', ['meta'])}>
				{`${_getSegment(item.isInPrimarySegment ? 0 : 1)} - ${item.reportBlockName} - ${_getOptionLabel(item)}`}
			</div>
		</div>
	);
};

const _renderSelected = (item: BillableWorkDropdownOption) => item.name;

const BillableWorkModal: React.FC<Props> = (props) => {
	const {
		change,
		touch,
		closeModal,
		billableWorkFieldsByIdMap,
		definitionFieldFieldIds: allDefinitionFieldFieldIds,
		destroy,
		formDataDefinitionFields,
		formDataInformationFields,
		formDataWorkTypeReportBlockFieldVirtualId,
		formDataWorkQuantityReportBlockFieldVirtualId,
		formDataDefaultQuantity,
		handleSubmit,
		informationFieldFieldIds: allInformationFieldFieldIds,
		initialData,
		initialize,
		invalid,
		onCreate,
		onEdit,
		showModal,
		workQuantityFieldIds: allWorkQuantityFieldIds,
		workTypeFieldIds: allWorkTypeFieldIds,
	} = props;

	const isEditMode = !!initialData;

	React.useEffect(() => {
		if (showModal) {
			initialize(FormModel.create(initialData));
		} else {
			destroy();
		}
	}, [destroy, initialData, initialize, showModal]);

	const {
		definitionFieldFields,
		informationFieldFields,
		workTypeFields,
		workQuantityFields,
		selectedDefinitionFields,
		selectedInformationFields,
	} = React.useMemo(() => {
		const selection = {
			workType: formDataWorkTypeReportBlockFieldVirtualId ? [formDataWorkTypeReportBlockFieldVirtualId] : [],
			workQuantity: formDataWorkQuantityReportBlockFieldVirtualId ? [formDataWorkQuantityReportBlockFieldVirtualId] : [],
			definitionFields: formDataDefinitionFields?.map((_df) => _df.reportBlockFieldVirtualId) ?? [],
			informationFields: formDataInformationFields?.map((_df) => _df.reportBlockFieldVirtualId) ?? [],
		};

		const disabledForSelection: { [S in keyof typeof selection]: Record<number, true> } = {
			workType: {},
			workQuantity: {},
			definitionFields: {},
			informationFields: {},
		};

		// eslint-disable-next-line guard-for-in
		for (const _disabledCategory in disabledForSelection) {
			for (const _selectionCategory in selection) {
				if (_disabledCategory !== _selectionCategory) {
					for (const _fieldId of selection[_selectionCategory]) {
						disabledForSelection[_disabledCategory][_fieldId] = true;
					}
				}
			}
		}

		if (formDataWorkQuantityReportBlockFieldVirtualId === formDataWorkTypeReportBlockFieldVirtualId) {
			if (formDataWorkTypeReportBlockFieldVirtualId) {
				delete disabledForSelection.workType[formDataWorkTypeReportBlockFieldVirtualId];
			}
			if (formDataWorkTypeReportBlockFieldVirtualId) {
				delete disabledForSelection.workQuantity[formDataWorkTypeReportBlockFieldVirtualId];
			}
		}

		const quantityWorkTypeFieldId = formDataWorkQuantityReportBlockFieldVirtualId ?? formDataWorkTypeReportBlockFieldVirtualId;
		// for loops to avoid a lot of lambdas in the .filter

		const _definitionFieldFields: BillableWorkDropdownOption[] = [];
		for (const _fieldId of allDefinitionFieldFieldIds) {
			const _field = billableWorkFieldsByIdMap[_fieldId];
			if (!disabledForSelection.definitionFields[_fieldId] &&
				_isAllowedAsDefinitionOrInfoField(
					_field,
					quantityWorkTypeFieldId ? billableWorkFieldsByIdMap[quantityWorkTypeFieldId] : null
				)
			) {
				_definitionFieldFields.push(_field);
			}
		}

		const _informationFieldFields: BillableWorkDropdownOption[] = [];
		for (const _fieldId of allInformationFieldFieldIds) {
			const _field = billableWorkFieldsByIdMap[_fieldId];
			if (!disabledForSelection.informationFields[_fieldId] &&
				_isAllowedAsDefinitionOrInfoField(
					_field,
					quantityWorkTypeFieldId ? billableWorkFieldsByIdMap[quantityWorkTypeFieldId] : null
				)
			) {
				_informationFieldFields.push(_field);
			}
		}

		const _workTypeFields: BillableWorkDropdownOption[] = [];
		for (const _fieldId of allWorkTypeFieldIds) {
			const _field = billableWorkFieldsByIdMap[_fieldId];
			if (!disabledForSelection.workType[_fieldId]) {
				_workTypeFields.push(_field);
			}
		}

		const _workQuantityFields: BillableWorkDropdownOption[] = [];
		for (const _fieldId of allWorkQuantityFieldIds) {
			const _field = billableWorkFieldsByIdMap[_fieldId];
			if (!disabledForSelection.workQuantity[_fieldId] &&
				_areInSameSegment(
					_field,
					quantityWorkTypeFieldId ? billableWorkFieldsByIdMap[quantityWorkTypeFieldId] : null
				)
			) {
				_workQuantityFields.push(_field);
			}
		}

		const _selectedDefinitionFields = new Set(formDataDefinitionFields?.map((_df) => _df.reportBlockFieldVirtualId));
		const _selectedInformationFields = new Set(formDataInformationFields?.map((_df) => _df.reportBlockFieldVirtualId));

		return {
			definitionFieldFields: _definitionFieldFields,
			informationFieldFields: _informationFieldFields,
			workQuantityFields: _workQuantityFields,
			workTypeFields: _workTypeFields,
			selectedDefinitionFields: _selectedDefinitionFields,
			selectedInformationFields: _selectedInformationFields,
		};
	}, [
		allDefinitionFieldFieldIds,
		allInformationFieldFieldIds,
		allWorkQuantityFieldIds,
		allWorkTypeFieldIds,
		formDataDefinitionFields,
		formDataInformationFields,
		formDataWorkQuantityReportBlockFieldVirtualId,
		formDataWorkTypeReportBlockFieldVirtualId,
		billableWorkFieldsByIdMap,
	]);

	// when using useCallback on handleSubmit(...) only React complains that it received a function whose dependencies are unknown so we're useMemo-ing
	const submitForm = React.useMemo(() => handleSubmit((form: FormModel) => {
		const data = FormModel.toRequestModel(form);
		if (isEditMode) {
			onEdit(data);
		} else {
			onCreate(data);
		}
		closeModal();
	}), [handleSubmit, closeModal, isEditMode, onCreate, onEdit]);

	const handleWorkTypeChange = React.useCallback((field: BillableWorkDropdownOption) => {
		if (EQUIVALENT_BILLABLE_WORK_FIELD_TYPES[field.type]) {
			change(FormModel.field('workQuantityReportBlockFieldVirtualId'), field.virtualId);
			change(FormModel.field('defaultQuantity'), null);
			touch(FormModel.field('workQuantityReportBlockFieldVirtualId'));
		}
		change(FormModel.field('isInPrimarySegment'), field.isInPrimarySegment);
	}, [change, touch]);

	const handleDefinitionFieldChange = React.useCallback((fieldName: string) =>
		(field: BillableWorkDropdownOption) => {
			change(`${fieldName}.${BillableWorkDefinitionFieldFormModel.field('reportBlockFieldVirtualId')}`, field.virtualId);
			change(`${fieldName}.${BillableWorkDefinitionFieldFormModel.field('name')}`, field.name);
			change(`${fieldName}.${BillableWorkDefinitionFieldFormModel.field('isInPrimarySegment')}`, field.isInPrimarySegment);
		}, [change]);

	const handleInformationFieldChange = React.useCallback((fieldName: string) =>
		(field: BillableWorkDropdownOption) => {
			change(`${fieldName}.${BillableWorkInformationFieldFormModel.field('reportBlockFieldVirtualId')}`, field.virtualId);
			change(`${fieldName}.${BillableWorkInformationFieldFormModel.field('isInPrimarySegment')}`, field.isInPrimarySegment);
		}, [change]);

	const handleQuantityFieldChange = React.useCallback((field: BillableWorkDropdownOption) => {
		change(FormModel.field('workQuantityReportBlockFieldVirtualId'), field.virtualId);
		change(FormModel.field('defaultQuantity'), null);
	}, [change]);

	const handleQuantityCheckBoxChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		change(FormModel.field('workQuantityReportBlockFieldVirtualId'), null);
		change(FormModel.field('defaultQuantity'), event.target.checked ? DefaultBillableWorkQuantity.ONE : null);
	}, [change]);

	const isEquivalentBillableWorkType = (
		!!formDataWorkTypeReportBlockFieldVirtualId
		&& EQUIVALENT_BILLABLE_WORK_FIELD_TYPES[billableWorkFieldsByIdMap[formDataWorkTypeReportBlockFieldVirtualId]?.type]
	);
	const showWorkQuantity = !formDataDefaultQuantity;

	const title = `${isEditMode ? 'Edit' : 'New'} Billable Work`;

	return (
		<CustomModal
			className="billable-work-modal"
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={title}
			/>
			<CustomModal.Body>
				<Row className="row--non-padded">
					<Field
						component={Input}
						controlCursor={true}
						label="Work Name *"
						name={FormModel.field('workName')}
						placeholder="Enter Work Name"
					/>
					<Field
						component={Dropdown}
						label="Work Type *"
						name={FormModel.field('workTypeReportBlockFieldVirtualId')}
						onValueChange={handleWorkTypeChange}
						options={workTypeFields}
						placeholder="Select a Work Type Field"
						renderMenuItem={_renderItem}
						renderSelected={_renderSelected}
						valueKey="virtualId"
						withCaret={true}
					/>
					<Checkbox
						handleChange={handleQuantityCheckBoxChange}
						isChecked={!!formDataDefaultQuantity}
						isDisabled={isEquivalentBillableWorkType}
						isStandalone={true}
						label="Work Quantity 1"
					/>
					{showWorkQuantity &&
						<Field
							component={Dropdown}
							disabled={isEquivalentBillableWorkType}
							label="Work Quantity *"
							name={FormModel.field('workQuantityReportBlockFieldVirtualId')}
							onValueChange={handleQuantityFieldChange}
							options={workQuantityFields}
							placeholder="Select a Work Quantity Field"
							renderMenuItem={_renderItem}
							renderSelected={_renderSelected}
							valueKey="virtualId"
							withCaret={true}
						/>}
					<FieldArray<BillableWorkDefinitionFieldFieldProps>
						component={BillableWorkDefinitionFieldsSection}
						name={FormModel.field('definitionFields')}
						onChange={handleDefinitionFieldChange}
						options={definitionFieldFields}
						selectedDefinitionFields={selectedDefinitionFields}
					/>
					<FieldArray<BillableWorkInformationFieldFieldProps>
						component={BillableWorkInformationFieldsSection}
						name={FormModel.field('informationFields')}
						onChange={handleInformationFieldChange}
						options={informationFieldFields}
						selectedInformationFields={selectedInformationFields}
					/>
				</Row>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					label="Save"
					onClick={submitForm}
					submitKey={BILLABLE_WORK}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	const {
		workTypeReportBlockFieldVirtualId: formDataWorkTypeReportBlockFieldVirtualId,
		workQuantityReportBlockFieldVirtualId: formDataWorkQuantityReportBlockFieldVirtualId,
		definitionFields: formDataDefinitionFields,
		informationFields: formDataInformationFields,
		defaultQuantity: formDataDefaultQuantity,
	} = (getFormData(state) as FormModel) ?? ({} as Partial<FormModel>);

	return {
		formDataWorkTypeReportBlockFieldVirtualId,
		formDataWorkQuantityReportBlockFieldVirtualId,
		formDataDefinitionFields,
		formDataInformationFields,
		formDataDefaultQuantity,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentClass<OwnProps>>(
	reduxForm<FormModel, OwnProps, string | FormErrors>({ form: BILLABLE_WORK, validate: FormModel.validate }),
	connector
);

export default enhance(BillableWorkModal);
