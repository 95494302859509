import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { Field, change } from 'redux-form';
import { Autocomplete } from '@react-google-maps/api';

import Input from 'af-fields/Input';

import * as AddressesActions from 'af-actions/addresses';

import GoogleScriptLoader from '../Shared/Loader';

interface OwnProps {
	autosave?: () => Promise<void>;
	onValueUpdate?: () => void;
	formName: string;
	latitudePropName: string;
	longitudePropName: string;
	locationPropName: string;
	streetNumberPropName: string;
	routePropName: string;
	localityPropName: string;
	aa1PropName: string;
	aa2PropName: string;
	aa3PropName: string;
	countryPropName: string;
	postalCodePropName: string;
	suitePropName: string;
	postalOfficeBoxPropName: string;
	disabled?: boolean;
	allowUnverifiedAddress?: boolean;
	customHeader?: string;
}

interface DispatchProps {
	changeField: typeof change;
	getAddressByLatLng: typeof AddressesActions.getAddressByLatLng;
}

type Props = OwnProps & ResolveThunks<DispatchProps>;

class AddressMap extends React.Component<Props> {
	static readonly autocompleteOptions = { componentRestrictions: { country: ['us', 'ca'] }, strictBounds: true };

	private _searchBox: google.maps.places.Autocomplete;

	updateFields = (lat, lng, address) => {
		const { onValueUpdate, autosave, changeField, formName, ...rest } = this.props;
		changeField(formName, rest.latitudePropName, lat);
		changeField(formName, rest.longitudePropName, lng);
		changeField(formName, rest.locationPropName, address.formattedAddress || '');
		changeField(formName, rest.streetNumberPropName, address.streetNumber || '');
		changeField(formName, rest.routePropName, address.route || '');
		changeField(formName, rest.localityPropName, address.locality || '');
		changeField(formName, rest.aa1PropName, address.aa1 || '');
		changeField(formName, rest.aa2PropName, address.aa2 || '');
		changeField(formName, rest.aa3PropName, address.aa3 || '');
		changeField(formName, rest.countryPropName, address.country || '');
		changeField(formName, rest.postalCodePropName, address.postalCode || '');
		if (onValueUpdate) {
			onValueUpdate();
		}
		if (autosave) {
			autosave();
		}
	};

	onPlacesChanged = () => {
		const place = this._searchBox.getPlace();
		if (!place?.geometry?.location) {
			return;
		}
		const address = AddressesActions.formatAddress(place);
		this.updateFields(place.geometry.location.lat(), place.geometry.location.lng(), address);
	};

	onLocationChange = () => {
		const { changeField, formName, allowUnverifiedAddress, onValueUpdate, ...rest } = this.props;
		if (!allowUnverifiedAddress) {
			changeField(formName, rest.latitudePropName, null);
			changeField(formName, rest.longitudePropName, null);
			changeField(formName, rest.streetNumberPropName, null);
			changeField(formName, rest.routePropName, null);
			changeField(formName, rest.localityPropName, null);
			changeField(formName, rest.aa1PropName, null);
			changeField(formName, rest.aa2PropName, null);
			changeField(formName, rest.aa3PropName, null);
			changeField(formName, rest.countryPropName, null);
			changeField(formName, rest.postalCodePropName, null);
		}
		if (onValueUpdate) {
			onValueUpdate();
		}
	};

	handleStreetFieldMount = (field: google.maps.places.Autocomplete) => {
		if (!field) {
			return;
		}
		this._searchBox = field;
	};

	showMapButton = () => <span className="icon-location_pin" />;

	render() {
		const {
			locationPropName,
			disabled,
			customHeader,
		} = this.props;

		return (
			<GoogleScriptLoader>
				<Autocomplete
					onLoad={this.handleStreetFieldMount}
					onPlaceChanged={this.onPlacesChanged}
					options={AddressMap.autocompleteOptions}
				>
					<Field
						addonAfter={this.showMapButton()}
						addonAfterUnstyled={true}
						component={Input}
						disabled={disabled}
						id={locationPropName}
						label={customHeader ?? 'Street Address'}
						name={locationPropName}
						onValueChange={this.onLocationChange}
						placeholder="Street Address"
						type="text"
					/>
				</Autocomplete>
			</GoogleScriptLoader>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		changeField: change,
		getAddressByLatLng: AddressesActions.getAddressByLatLng,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(AddressMap);
