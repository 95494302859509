import * as React from 'react';
import { connect } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import * as User from 'ab-viewModels/user.viewModel';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import SideNav, { LinkProps, SeparatorProps } from 'af-components/SideNav/SideNav';

interface StateProps {
	userData: User.UserData;
}

type OwnProps = CustomRouteComponentProps;

type Props = StateProps & OwnProps;

class OrganizationSideNav extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		userData: {} as User.UserData,
	};

	render(): JSX.Element {
		const { location: { state: { orgAlias }, pathname }, userData } = this.props;

		const links: (LinkProps | SeparatorProps)[] = [
			{
				visibleFor: PagePermissions.ORGANIZATION,
				url: CLIENT.ORGANIZATION.MEMBERS(orgAlias),
				label: 'Users',
				icon: 'icon-users',
				isActive: pathname.startsWith(CLIENT.ORGANIZATION.MEMBERS(orgAlias)),
				userData,
			},
			{
				visibleFor: PagePermissions.ORGANIZATION,
				url: CLIENT.ORGANIZATION.SETTINGS.INFO(orgAlias),
				label: 'Organization Settings',
				icon: 'icon-settings',
				isActive: pathname.startsWith(CLIENT.ORGANIZATION.SETTINGS.SIDENAV(orgAlias)),
				userData,
			},
		];

		return (
			<SideNav
				links={links}
			/>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { userData } = state.user;
	if (!userData) {
		throw new Error('User not logged in');
	}

	return {
		userData,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(OrganizationSideNav);
