import { BILLING_CODE_DECIMAL_POINTS } from 'af-constants/values';

export const dollarFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const dollarFormatterUnitPrice = (maximumFractionDigits?: number, minimumFractionDigits?: number) => new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: maximumFractionDigits ?? BILLING_CODE_DECIMAL_POINTS,
	minimumFractionDigits: minimumFractionDigits ?? 0,
});
