import * as React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';

import ItemCategoryRM from 'ab-requestModels/itemCategory/itemCategory.requestModel';

import ItemCategoryVM from 'ab-viewModels/itemCategory/itemCategory.viewModel';

import { ITEM_CATEGORY_CREATE } from 'af-constants/reduxForms';

import Form from './CategoryForm';
import { validate } from './validate';

interface OwnProps {
	create: (form: ItemCategoryRM) => Promise<void>;
	close: () => void;
	categories: ItemCategoryVM[];
	id: Nullable<number>;
}

type Props = OwnProps & InjectedFormProps<ItemCategoryRM, OwnProps>;

const CategoryCreate = (props: Props) => {
	const { close, create } = props;
	const onCreate = React.useCallback(async (form: ItemCategoryRM) => {
		await create(form);
		close();
	}, [close, create]);

	return <Form onSubmit={onCreate} {...props} />;
};

export default reduxForm<ItemCategoryRM, OwnProps>({ form: ITEM_CATEGORY_CREATE, validate, enableReinitialize: true })(CategoryCreate);
