import * as React from 'react';

import LockedValue from 'af-components/LockedValue';

type Props = {
	label: string;
	booleanValue: boolean;
};

const TrucksAndEquipmentBooleanValue: React.FC<Props> = ({ booleanValue, label }) => {
	const value = booleanValue ? 'YES' : 'NO';

	return <LockedValue label={label} value={value} />;
};

export default React.memo(TrucksAndEquipmentBooleanValue);
