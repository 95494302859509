import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import AgencyVM from 'ab-viewModels/agency/agency.viewModel';
import AgencyAssociatedWorkersTableVM from 'ab-viewModels/agency/associatedWorkersTable.viewModel';

import CLIENT from 'af-routes/client';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as AgencyActions from 'af-actions/agency';

import { RootState } from 'af-reducers';

import Breadcrumbs from 'af-components/Breadcrumbs';

import TableNameEnum from 'ab-enums/tableName.enum';

import AgencyPreview from './Agency';
import WorkersPreview from './Workers';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const PreviewAgency: React.FC<Props> = (props) => {

	const {
		location: { state: { orgAlias } },
		companyName,
		findById,
		match: { params: { id } },
		findAssociatedWorkers,
		history,
	} = props;

	const [
		agency,
		setAgency,
	] = React.useState<Nullable<AgencyVM>>(null);

	React.useEffect(() => {
		findAgency();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const findAgency = async () => {
		const fetchedAgency = await findById(+id);

		if (!fetchedAgency) {
			throw new Error(`Agency with id ${id} not found`);
		}
		setAgency(fetchedAgency);
	};

	const fetchAssociatedWorkers = React.useCallback(async (tableRequestModel: TableQuery) => {
		if (!agency) {
			throw new Error('Can\'t fetch workers for unknown agency');
		}
		return await findAssociatedWorkers(agency.id, tableRequestModel);
	}, [agency, findAssociatedWorkers]);

	const onRowClick = React.useCallback(({ original }) => {
		history.push(CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.PREVIEW(orgAlias, companyName, `${original.id}`));
	}, [companyName, history, orgAlias]);

	const editAccount = React.useCallback((original: AgencyAssociatedWorkersTableVM) => {
		history.push(CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.EDIT(orgAlias, companyName, `${original.id}`));
	}, [companyName, history, orgAlias]);

	const previewAccount = React.useCallback((original: AgencyAssociatedWorkersTableVM) => {
		history.push(CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.PREVIEW(orgAlias, companyName, `${original.id}`));
	}, [companyName, history, orgAlias]);

	return (
		<>
			<AgencyPreview
				agency={agency}
				companyName={companyName}
				orgAlias={orgAlias}
			/>
			{
				agency &&
				(
					<div className="form-segment">
						<Breadcrumbs items={[{ label: 'Associated workers' }]} />
						<WorkersPreview
							csvName={`${companyName}_${agency.name}_associated_workers.csv`}
							editAccount={editAccount}
							fetchAssociatedWorkers={fetchAssociatedWorkers}
							isDeletedAgency={agency?.isDeleted}
							onRowClick={onRowClick}
							previewAccount={previewAccount}
							tableName={TableNameEnum.AGENCY_ASSOCIATED_WORKERS}
						/>
					</div>
				)
			}

		</>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findById: AgencyActions.findById,
		findAssociatedWorkers: AgencyActions.findAssociatedWorkers,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	React.memo
);

export default enhance(PreviewAgency);
