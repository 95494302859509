import ScheduleBoardMetricsRequestModel, { WorkOrderMetricsItem, WorkOrderMetrics } from 'ab-requestModels/scheduleBoardMetrics.requestModel';

import { ScheduleBoardOnDateViewModel } from 'ab-viewModels/scheduleBoardWorkOrdersOnDateView.viewModel';
import ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import * as ScheduleBoardUtil from 'ab-utils/scheduleBoard.util';

export const fromVMtoRM = (vm: ScheduleBoardOnDateViewModel): ScheduleBoardMetricsRequestModel => {
	const workOrdersDict = vm?.workOrders;
	const workOrderCodes = vm?.workOrdersOrdering ?? [];

	const workOrders: WorkOrderMetricsItem[] = workOrderCodes.map((_workOrderCode) =>
		ScheduleBoardUtil.isBlankWorkOrderId(_workOrderCode) ? null : fromViewModel(workOrdersDict?.[_workOrderCode])
	);
	return { workOrders };
};

const fromViewModel = (workOrder: ScheduleBoardWorkOrderViewModel): WorkOrderMetrics => {
	const { id, revenue, manHourAverage, jobHours, shopHours, travelHours, workDescription, code, isInternal } = workOrder;

	return {
		workOrderId: id,
		workOrderCode: code,
		revenue: revenue,
		manHourAverage: manHourAverage,
		jobHours: jobHours,
		isInternal: isInternal,
		shopHours: shopHours,
		travelHours: travelHours,
		workDescription: workDescription,
	};
};
