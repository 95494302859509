import * as React from 'react';

import * as ScheduleBoardResourceSkillViewModel from 'ab-viewModels/scheduleBoardResourceSkill.viewModel';

import LabelWithPills from 'af-components/LockedPillsValue';

interface Props {
	skills: ScheduleBoardResourceSkillViewModel.Single[];
	title: string;
}

class ResourceSkillList extends React.PureComponent<Props> {
	render() {
		const { skills, title } = this.props;

		if (!skills?.length) {
			return null;
		}

		return (
			<LabelWithPills
				colorKey="color"
				items={skills ?? []}
				label={title}
				labelKey="name"
			/>
		);
	}
}

export default ResourceSkillList;
