import * as React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { connect, ResolveThunks } from 'react-redux';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import { CompanyContactTableVM } from 'ab-viewModels/contact/table.viewModel';

import * as ContactActions from 'af-actions/contacts';

import LockedValue from 'af-components/LockedValue';
import CustomModal from 'af-components/CustomModal';

import EmailList from './EmailList';
import PhoneList from './PhoneList';
import AddressList from './AddressList';
import ContactPicker from './ContactPicker';

interface OwnProps {
	unsavedContact: Nullable<CompanyContactTableVM>;
	showModal: boolean;
	closeModal: () => void;
	onSubmit: () => void;
}

interface DispatchProps {
	findAllSavedContacts: typeof ContactActions.findAllContacts;
	addUnsaved: typeof ContactActions.addUnsaved;
}

interface State {
	contacts: ContactVM[];
	selectedContact: Nullable<ContactVM>;
}

type Props = OwnProps & ResolveThunks<DispatchProps>;

class AddToContactModal extends React.PureComponent<Props, State> {
	state: State = {
		contacts: [],
		selectedContact: null,
	};

	componentDidUpdate(prevProps: Props) {
		const { showModal } = this.props;

		if (!showModal && prevProps.showModal) {
			this.setState(() => ({ contacts: [], selectedContact: null }));
		}
	}

	loadContacts = async () => {
		const { findAllSavedContacts } = this.props;
		const contacts = await findAllSavedContacts();
		this.setState(() => ({ contacts }));
	};

	selectContact = async (contact: ContactVM) => {
		this.setState(() => ({ selectedContact: contact }));
	};

	submit = async () => {
		const { addUnsaved, unsavedContact, onSubmit } = this.props;
		const { selectedContact } = this.state;

		if (!selectedContact || !unsavedContact) {
			throw new Error('No contact selected');
		}

		await addUnsaved(selectedContact.id, { unsavedContactId: unsavedContact.id });
		onSubmit();
	};

	renderAddresses = () => {
		const { selectedContact } = this.state;

		if (!selectedContact?.addresses?.length) {
			return null;
		}

		return (
			<div className="form-box form-box--with-background">
				<AddressList selectedContactAddresses={selectedContact?.addresses} />
			</div>
		);
	};

	render() {
		const { showModal, unsavedContact, closeModal } = this.props;
		const { contacts, selectedContact } = this.state;
		return (
			<CustomModal
				className="contact__modal"
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Add to an Existing Contact"
				/>
				<CustomModal.Body>
					<div className="form-segment">
						<div className="form-box form-box--with-background">
							<ContactPicker
								contacts={contacts}
								loadContacts={this.loadContacts}
								onSelect={this.selectContact}
							/>
							<Row className="row--padded-bottom">
								<Col sm={12}>
									<LockedValue
										label="Full Name"
										value={selectedContact?.fullName ?? unsavedContact?.fullName ?? 'N/A'}
									/>
								</Col>
								{selectedContact &&
									<Col sm={12}>
										<LockedValue
											label="Title"
											value={selectedContact.title ?? 'N/A'}
										/>
									</Col>
								}
							</Row>
							<EmailList
								selectedContactEmails={selectedContact?.emails}
								unsavedContactEmail={unsavedContact?.contactMethodEmails?.[0]?.value}
							/>
							<PhoneList
								selectedContactPhones={selectedContact?.phones}
								unsavedContactPhone={unsavedContact?.contactMethodPhones?.[0]?.value}
							/>
						</div>
						{this.renderAddresses()}
					</div>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Cancel
					</Button>
					<Button
						disabled={!selectedContact}
						onClick={this.submit}
						variant="primary"
					>
						Save
					</Button>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		addUnsaved: ContactActions.addUnsaved,
		findAllSavedContacts: ContactActions.findAllContacts,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(AddToContactModal);
