import { Reducer } from 'redux';

import * as TimeUtils from 'acceligent-shared/utils/time';

import * as ActionTypes from 'af-constants/actionTypes';

import { WorkOrderStoreState } from 'af-models/workOrder.models';

import { WorkOrderAction } from 'af-actions/workOrder';

import * as WorkOrderUtil from 'af-utils/workOrder.util';

const initialState: WorkOrderStoreState = {
	workOrders: {
		list: null,
		selectedDate: TimeUtils.dateToMomentUtc(new Date())!,
		filterByText: '',
		participantsEmailAndSmsStatuses: {},
	},
	order: null,
	orderAttachments: [],
	confirmationDataAttachments: {},
	copyWorkOrderModalData: null,
};

const WorkOrderReducer: Reducer<WorkOrderStoreState, WorkOrderAction> = (state: WorkOrderStoreState = initialState, action: WorkOrderAction) => {
	switch (action.type) {
		case ActionTypes.LOCK_WORK_ORDER:
			return WorkOrderUtil.setWorkOrderLock(state, action.payload.workOrderId, true);
		case ActionTypes.UNLOCK_WORK_ORDER:
			return WorkOrderUtil.setWorkOrderLock(state, action.payload.workOrderId, false);
		case ActionTypes.FIND_ALL_WORK_ORDERS:
			return {
				...state,
				workOrders: {
					list: action.payload,
					selectedDate: action.selectedDate,
					filterByText: action.filterByText,
					participantsEmailAndSmsStatuses: {},
				},
				order: null,
			};
		case ActionTypes.DELETE_WORK_ORDER:
			return { ...state, workOrders: { ...state.workOrders, list: state.workOrders.list?.filter((wo) => wo?.id !== action.payload) ?? null } };
		case ActionTypes.FIND_WORK_ORDER:
			return { ...state, order: action.payload };
		case ActionTypes.GET_WORK_ORDER_CONFIRMATION_DATA:
			return { ...state, confirmationData: action.payload };
		case ActionTypes.CLEAR_WORK_ORDER_PARTICIPANTS_EMAIL_STATUS:
			return { ...state, participantsEmailAndSmsStatus: null };
		case ActionTypes.CLEAR_WORK_ORDER_LIST:
			return { ...state, workOrders: { ...state.workOrders, list: initialState.workOrders.list } };
		case ActionTypes.RESET_WORK_ORDER:
			return { ...state, order: initialState.order, orderAttachments: initialState.orderAttachments };
		case ActionTypes.SET_COPY_WORK_ORDER_MODAL_DATA:
			return { ...state, copyWorkOrderModalData: action.payload };
		default:
			return state;
	}
};

export default WorkOrderReducer;
