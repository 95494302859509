import * as React from 'react';

import WorkOrderReviewStatus from 'acceligent-shared/enums/workOrderReviewStatus';

import FinalizeReviewRM from 'ab-requestModels/workOrder/finalizeReview.requestModel';

import ConfirmationModal from 'af-components/ConfirmationModal';

import SignatureModal from '../SignatureModal';

import SuccessModalBody from '../../FieldReportsList/Modals/Shared/SuccessModalBody';

interface OwnProps {
	closeModal: () => void;
	dueDate: string;
	onSubmit: (form: FinalizeReviewRM) => Promise<void>;
	showModal: boolean;
	workOrderId: number;
	hasUnsignedTimeSheet: boolean;
}

type Props = OwnProps;

interface State {
	showSignatureModal: boolean;
	showSuccessModal: boolean;
}

class FinalizeModalHandler extends React.PureComponent<Props, State> {

	state: State = {
		showSignatureModal: this.props.showModal && this.props.hasUnsignedTimeSheet,
		showSuccessModal: this.props.showModal && !this.props.hasUnsignedTimeSheet,
	};

	static MODAL_TITLE = 'Finalize Report';
	static SIGNATURE_MODAL_TITLE = 'Finalize Report and Time Sheets';
	static SIGNATURE_MODAL_DESCRIPTION = 'Signing and approving time sheets requires a signature';

	static SUCCESS_MODAL_TITLE = 'Field Reports Finalized';
	static SUCCESS_MODAL_DESCRIPTION = 'Report has been finalized and locked from further changes';

	static readonly SUCCESS_MODAL_BODY = (
		<SuccessModalBody
			description={FinalizeModalHandler.SUCCESS_MODAL_DESCRIPTION}
			isReadOnly={false}
			reviewStatus={WorkOrderReviewStatus.FINAL}
			title={FinalizeModalHandler.SUCCESS_MODAL_TITLE}
		/>
	);

	componentDidUpdate(prevProps: Props) {
		const { showModal, hasUnsignedTimeSheet } = this.props;
		if (!prevProps.showModal && showModal) {
			if (hasUnsignedTimeSheet) {
				this.setState(() => ({ showSignatureModal: true, showSuccessModal: false }));
			} else {
				this.finalize({ signatureImage: null });
			}
		} else if (prevProps.showModal && !showModal) {
			this.setState(() => ({ showSignatureModal: false, showSuccessModal: false }));
		}
	}

	finalize = async (form: FinalizeReviewRM) => {
		const { onSubmit } = this.props;

		await onSubmit(form);
		this.setState(() => ({ showSuccessModal: true, showSignatureModal: false }));
	};

	onSuccessModalConfirm = () => {
		const { closeModal } = this.props;

		closeModal();
		this.setState(() => ({ showSuccessModal: false }));
	};

	render() {
		const { closeModal } = this.props;
		const {
			showSignatureModal,
			showSuccessModal,
		} = this.state;

		return (
			<>
				<SignatureModal
					closeModal={closeModal}
					description={FinalizeModalHandler.SIGNATURE_MODAL_DESCRIPTION}
					onSubmit={this.finalize}
					showModal={showSignatureModal}
					showNameInput={false}
					title={FinalizeModalHandler.SIGNATURE_MODAL_TITLE}
				/>
				<ConfirmationModal
					body={FinalizeModalHandler.SUCCESS_MODAL_BODY}
					closeModal={this.onSuccessModalConfirm}
					confirmAction={this.onSuccessModalConfirm}
					confirmText="Done"
					modalStyle="info"
					showCancel={false}
					showModal={showSuccessModal}
					size="md"
					title={FinalizeModalHandler.MODAL_TITLE}
				/>
			</>
		);
	}
}

export default FinalizeModalHandler;
