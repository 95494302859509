import * as React from 'react';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import SubscriptionStatus from 'acceligent-shared/enums/subscriptionStatus';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import { formatDate } from 'acceligent-shared/utils/time';

import { NOTIFY_MODAL_COL_SPAN } from 'af-constants/values';

import { wrapElementWithTooltip } from 'af-utils/tooltip.util';

interface OwnProps {
	phoneNumber: string;
	lastSmsSentStatus: NotificationStatusEnum;
	subscriptionStatus: SubscriptionStatus;
	lastSmsSentAt: Date;
	phoneInvalidatedAt: Date;
	errorMessage: string;
}

type Props = OwnProps;

export default class SmsStatusInfo extends React.PureComponent<Props> {

	getEmployeeSmsColorApplication = () => {
		const { lastSmsSentStatus } = this.props;

		switch (lastSmsSentStatus) {
			case NotificationStatusEnum.OPENED:
				return 'text-green';
			case NotificationStatusEnum.ERROR:
				return 'text-red';
			default:
				return '';
		}
	};

	getEmployeeSmsOverlayMessage = () => {
		const { lastSmsSentStatus, errorMessage } = this.props;

		switch (lastSmsSentStatus) {
			case NotificationStatusEnum.QUEUED:
				return 'Notification has been sent to this participant, but he hasn\'t received it yet.';
			case NotificationStatusEnum.ERROR:
				return errorMessage;
			case NotificationStatusEnum.OPENED:
				return 'The participant has opened the link sent in the last sms.';
			default:
				return null;
		}
	};

	employeeSmsStatusInfo = (): JSX.Element => {
		const {
			lastSmsSentAt,
			lastSmsSentStatus,
			phoneInvalidatedAt,
		} = this.props;

		return (
			<>
				{lastSmsSentStatus !== NotificationStatusEnum.SCHEDULED && lastSmsSentAt ? formatDate(lastSmsSentAt, TimeFormatEnum.FULL_DATE) : '-'}
				{lastSmsSentStatus === NotificationStatusEnum.ERROR && phoneInvalidatedAt && <span className="icon-info text-red" />}
			</>
		);
	};

	render() {
		const { subscriptionStatus } = this.props;
		const hasResubscribed = subscriptionStatus === SubscriptionStatus.RESUBSCRIBED;

		return (
			<td
				className={this.getEmployeeSmsColorApplication()}
				colSpan={NOTIFY_MODAL_COL_SPAN.SEND_TIME}
			>
				{
					subscriptionStatus === SubscriptionStatus.SUBSCRIBED ?
						wrapElementWithTooltip(
							this.employeeSmsStatusInfo(),
							this.getEmployeeSmsOverlayMessage()
						) :
						<strong className={hasResubscribed ? 'text-green' : 'text-red'}>
							{hasResubscribed ? 'SUBSCRIBED' : 'UNSUBSCRIBED'}
						</strong>
				}
			</td>
		);
	}
}
