import { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { GetRootState } from 'af-reducers';

export interface FormattedAddress {
	formattedAddress: string;
	locality?: string;
	postalCode?: string;
	country?: string;
	aa1?: string;
	aa2?: string;
	aa3?: string;
	route?: string;
	streetNumber?: string;
}

// FIXME: this is more of a util than it is an action. Move elsewhere
export function formatAddress(address: Metadata | null): FormattedAddress {
	if (!address?.address_components) {
		return {
			formattedAddress: '',
		};
	}

	const locality = address.address_components.find((_ac) => (_ac.types.indexOf('locality') >= 0)) || {};
	const postalCode = address.address_components.find((_ac) => (_ac.types.indexOf('postal_code') >= 0)) || {};
	const country = address.address_components.find((_ac) => (_ac.types.indexOf('country') >= 0)) || {};
	const aa1 = address.address_components.find((_ac) => (_ac.types.indexOf('administrative_area_level_1') >= 0)) || {};
	const aa2 = address.address_components.find((_ac) => (_ac.types.indexOf('administrative_area_level_2') >= 0)) || {};
	const aa3 = address.address_components.find((_ac) => (_ac.types.indexOf('administrative_area_level_3') >= 0)) || {};
	const route = address.address_components.find((_ac) => (_ac.types.indexOf('route') >= 0)) || {};
	const streetNumber = address.address_components.find((_ac) => (_ac.types.indexOf('street_number') >= 0)) || {};

	return {
		formattedAddress: address.formatted_address,
		locality: locality.long_name,
		postalCode: postalCode.long_name,
		country: country.long_name,
		aa1: aa1.long_name,
		aa2: aa2.long_name,
		aa3: aa3.long_name,
		route: route.long_name,
		streetNumber: streetNumber.long_name,
	};
}

export function getAddressByLatLng(latitude: number, longitude: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = await http.get<Metadata>(API.GOOGLE.GEOCODE(latitude, longitude), { requireAuthentication: false, spinner: false });
			switch (data.status) {
				case 'OK':
					return formatAddress(data.results[0]);
				case 'ZERO_RESULTS':
					return formatAddress(null);
				default:
					throw new Error('ab-services.');
			}
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
