import * as React from 'react';
import { nanoid } from 'nanoid';

import { ColorPalette, AdditionalColorPaletteOption, ExtendedColorPalette } from 'acceligent-shared/enums/color';

import SelectableColorSquare from 'af-components/SelectableColorSquare';

import { bemElement } from 'ab-utils/bem.util';

import { UNIQUE_ID_SIZE } from 'ab-constants/value';

export type Position = 'bottom' | 'right';
export type Alignment = 'start' | 'center' | 'end';

interface Props {
	value: ExtendedColorPalette;
	onChange?: (color: Nullable<ExtendedColorPalette>) => void;
	position: Position;
	align: Alignment;
	locationX: number;
	locationY: number;
	showAdditionalOptions: boolean;
}

interface State {
	dropdownId: string;
	position: 'top' | 'bottom' | 'left' | 'right';
}

export default class ColorsGrid extends React.PureComponent<Props, State> {

	state: State = {
		position: 'bottom',
		dropdownId: `color-palette-${nanoid(UNIQUE_ID_SIZE)}`, // Generate dropdown ID to allow finding it in DOM
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		const { dropdownId } = state;
		const { position, locationY, locationX } = props;

		const menuItem = document.getElementById(dropdownId);
		if (position && menuItem) {
			const { width, height } = menuItem.getBoundingClientRect();
			if (position === 'right' && width + locationX > window.innerWidth) {
				return { opened: true, position: 'left' };
			}
			if (position === 'bottom' && height + locationY > window.innerHeight) {
				return { opened: true, position: 'top' };
			}
		}

		return { position };
	}

	reset = () => {
		const { onChange } = this.props;
		onChange?.(null);
	};

	render() {
		const { value, onChange, showAdditionalOptions, align } = this.props;
		const { position } = this.state;

		const className = bemElement('color-picker', 'palette-container', { [position]: true, [align]: true });
		return (
			<div className={className} id={this.state.dropdownId}>
				<div className="color-picker__reset" onClick={this.reset}>
					<span className="icon-no_color" />
					Reset
				</div>
				{showAdditionalOptions &&
					<div className="color-picker__palette">
						{Object.keys(AdditionalColorPaletteOption).map((color: AdditionalColorPaletteOption) => (
							<SelectableColorSquare
								color={color}
								key={color}
								onClick={onChange}
								selected={color === value}
								showTooltip={true}
							/>
						))}
					</div>
				}
				<div className="color-picker__palette">
					{Object.keys(ColorPalette).map((color: ColorPalette) => (
						<SelectableColorSquare
							color={color}
							key={color}
							onClick={onChange}
							selected={color === value}
							showTooltip={true}
						/>
					))}
				</div>
			</div>
		);
	}
}
