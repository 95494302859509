import * as React from 'react';

import { TimeSplitEntryVM } from 'acceligent-shared/dtos/web/view/timeSheet/timeSheet';

import TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';

import LockedValue from 'af-components/LockedValue';

import { bemElement } from 'ab-utils/bem.util';

import { TimeSheetTrackedEntryVM } from 'ab-viewModels/timeSheet/trackedEntries.viewModel';

import WorkTypeLabel from './WorkTypeLabel';

import WorkTypeValue from './WorkTypeValue';

interface OwnProps {
	timeSplitEntries: TimeSplitEntryVM[];
	breakDuration: number;
	jobDuration: number;
	shopDuration: number;
	travelDuration: number;
	showEquipmentTimeError: boolean;
	trackedEntry: Nullable<TimeSheetTrackedEntryVM>;
}

const TimeSheetDurations = (props: OwnProps) => {
	const {
		timeSplitEntries,
		breakDuration: totalBreakDuration,
		jobDuration: totalJobDuration,
		shopDuration: totalShopDuration,
		travelDuration: totalTravelDuration,
		showEquipmentTimeError,
		trackedEntry,
	} = props;

	const hasTimeSplitEntries = !!timeSplitEntries && timeSplitEntries.length > 0 && !showEquipmentTimeError;
	const jobRowClassName = bemElement('time-sheet-list', 'row__info__total-time', { greyed: hasTimeSplitEntries, error: showEquipmentTimeError });
	const rowClassName = bemElement('time-sheet-list', 'row__info__total-time');

	const isActiveEntryJobType = !!trackedEntry && (trackedEntry?.workType === TimeSheetEntryWorkType.JOB);
	const isActiveEntryShopType = !!trackedEntry && (trackedEntry?.workType === TimeSheetEntryWorkType.SHOP);
	const isActiveEntryTravelType = !!trackedEntry && (trackedEntry?.workType === TimeSheetEntryWorkType.TRAVEL);
	const isActiveEntryBreakType = !!trackedEntry && (trackedEntry?.workType === TimeSheetEntryWorkType.BREAK);

	return (
		<div className="time-sheet-list__row__info__total-split-time-container">
			<div className={jobRowClassName}>
				<LockedValue
					label={
						<WorkTypeLabel
							icon="equipment"
							isActiveEntry={isActiveEntryJobType}
							label="Job"
						/>
					}
					value={
						<WorkTypeValue
							isActiveEntry={isActiveEntryJobType}
							startTime={trackedEntry?.startTime ?? null}
							totalWorkTypeDuration={totalJobDuration}
						/>
					}
				/>
			</div>
			<div className={rowClassName}>
				<LockedValue
					label={
						<WorkTypeLabel
							icon="home"
							isActiveEntry={isActiveEntryShopType}
							label="Shop"
						/>
					}
					value={
						<WorkTypeValue
							isActiveEntry={isActiveEntryShopType}
							startTime={trackedEntry?.startTime ?? null}
							totalWorkTypeDuration={totalShopDuration}
						/>
					}
				/>
			</div>
			<div className={rowClassName}>
				<LockedValue
					label={
						<WorkTypeLabel
							icon="travel"
							isActiveEntry={isActiveEntryTravelType}
							label="Travel"
						/>
					}
					value={
						<WorkTypeValue
							isActiveEntry={isActiveEntryTravelType}
							startTime={trackedEntry?.startTime ?? null}
							totalWorkTypeDuration={totalTravelDuration}
						/>
					}
				/>
			</div>
			<div className={rowClassName}>
				<LockedValue
					label={
						<WorkTypeLabel
							icon="break"
							isActiveEntry={isActiveEntryBreakType}
							label="Break"
						/>
					}
					value={
						<WorkTypeValue
							isActiveEntry={isActiveEntryBreakType}
							startTime={trackedEntry?.startTime ?? null}
							totalWorkTypeDuration={totalBreakDuration}
						/>
					}
				/>
			</div>
		</div>
	);
};

export default TimeSheetDurations;
