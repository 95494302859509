import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

import styles from './styles.module.scss';

interface Props {
	openEquipmentWorkOrderHistoryModal: () => void;
}

const SettingsButton = (props: Props) => {
	const {
		openEquipmentWorkOrderHistoryModal,
	} = props;

	const baseClaseName = 'schedule-board-modal';
	const headerActionsClassName = `${baseClaseName}__resource__header-actions`;
	const iconClassName = 'options-icon';

	return (
		<div className={styles[headerActionsClassName]} >
			<Dropdown >
				<Dropdown.Toggle>
					<span className={`icon-actions ${styles[iconClassName]}`} />
				</Dropdown.Toggle>
				<Dropdown.Menu className={styles.dropdown}>
					<Dropdown.Item eventKey="WOHistory" onClick={openEquipmentWorkOrderHistoryModal}>
						Work Order History
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div >
	);
};

export default React.memo(SettingsButton);
