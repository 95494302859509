export interface FieldReportSettingsFormToModel {
	fieldReportAccessDayDuration: number;
	isFRManageAllowedToApproveTimeSheet: boolean;
	isFRManageAllowedToEditTimeSheet: boolean;
	isFRManageLevelAllowedInReviewProcess: boolean;
	allowAllNonBillableShortcut: boolean;
}

export class FieldReportSettingsRM {
	fieldReportAccessDayDuration: number;
	isFRManageAllowedToApproveTimeSheet: boolean;
	isFRManageAllowedToEditTimeSheet: boolean;
	isFRManageLevelAllowedInReviewProcess: boolean;
	allowAllNonBillableShortcut: boolean;

	static toModel(form: FieldReportSettingsRM): FieldReportSettingsFormToModel {
		return {
			fieldReportAccessDayDuration: +form.fieldReportAccessDayDuration,
			isFRManageAllowedToApproveTimeSheet: form.isFRManageAllowedToApproveTimeSheet,
			isFRManageAllowedToEditTimeSheet: form.isFRManageAllowedToEditTimeSheet,
			isFRManageLevelAllowedInReviewProcess: form.isFRManageLevelAllowedInReviewProcess,
			allowAllNonBillableShortcut: form.allowAllNonBillableShortcut,
		};
	}
}
