import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ResolveThunks } from 'react-redux';

import * as CompanyActions from 'af-actions/companies';

import { RootState } from 'af-reducers';

import ScheduleBoardSettingsPreview from './ScheduleBoardSettings/ScheduleBoardSettingsPreview';
import ScheduleBoardEdit from './ScheduleBoardSettings/ScheduleBoardEdit';

import { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import Breadcrumbs from 'af-components/Breadcrumbs';

interface CompanySettingsRouteProps {
	orgAlias: string;
	companyName: string;
}

interface DispatchProps {
	getCompany: typeof CompanyActions.getCompany;
}

interface StateProps {
	company: Nullable<CompanyViewModel>;
}

type OwnProps = CustomRouteComponentProps<CompanySettingsRouteProps>;

type Props = StateProps & ResolveThunks<DispatchProps> & OwnProps;

interface State {
	editScheduleBoardSettings: boolean;
}

class CompanySettings extends React.Component<Props, State> {
	state: State = {
		editScheduleBoardSettings: false,
	};

	static breadcrumbs = [{ label: 'Schedule Board Settings' }];

	async componentDidMount() {
		const { getCompany } = this.props;
		await getCompany();
	}

	toggleScheduleBoardSettingsEdit = async () => {
		this.setState((prevStatus: State) => ({ editScheduleBoardSettings: !prevStatus.editScheduleBoardSettings }));
	};

	render() {
		const { company } = this.props;
		const { editScheduleBoardSettings } = this.state;

		if (!company) {
			// TODO: Loading
			return null;
		}

		return (
			<div className="form-segment">
				<Breadcrumbs items={CompanySettings.breadcrumbs} />
				<ScheduleBoardSettingsPreview
					company={company}
					toggleEdit={this.toggleScheduleBoardSettingsEdit}
				/>
				<ScheduleBoardEdit
					isVisible={editScheduleBoardSettings}
					toggleEdit={this.toggleScheduleBoardSettingsEdit}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;

	return {
		company,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		getCompany: CompanyActions.getCompany,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(CompanySettings);
