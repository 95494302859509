import * as React from 'react';
import { Row } from 'react-bootstrap';

import { bemElement } from 'ab-utils/bem.util';

const ErrorMessage = () => {
	return (
		<Row>
			<div className={bemElement('time-sheet-list__row__info', 'time-split-table-container__message')}>
				Total time in Equipment exceeds total job time
			</div>
		</Row>
	);
};

export default React.memo(ErrorMessage);
