import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from 'af-reducers';

import CustomScrollbar from 'af-components/CustomScrollbar';

import DroppableComponent from './Droppable';

import SharedModalProps from '../../Shared/ModalProps';

interface OwnProps extends SharedModalProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	droppableId: string;
	/** `MM-DD-YYYY` */
	dueDate: string;
	forceUnlockOrder: (workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	isDragAndDropDisabled: boolean;
	lastOpenedOrderCode: string;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
}

interface StateProps {
	numberOfRows: number;
	isEmpty: boolean;
}

type Props = OwnProps & StateProps;

class Board extends React.PureComponent<Props> {

	render() {
		const {
			addBlankWorkOrder,
			currentWorkOrderModalId,
			droppableId,
			dueDate,
			forceUnlockOrder,
			hasPermissionsToEditScheduleBoard,
			isDragAndDropDisabled,
			isEmpty,
			lastOpenedOrderCode,
			numberOfRows,
			removeBlankWorkOrder,
			setWorkOrderModalId,
			setWorkOrderNoteModalData,
			setEmployeeModalData,
			setEquipmentModalData,
			setTemporaryEmployeeModalData,
			setEmployeeModalVisibility,
			setEquipmentModalVisibility,
		} = this.props;

		let className = 'work-orders-droppable';
		className += isEmpty ? ' empty-droppable' : '';

		return (
			<CustomScrollbar
				contentWrapperClassName="schedule-board-cards-scrollable-container"
				scrollbarsClassName="schedule-board-cards-container-wrapper"
			>
				{Array(numberOfRows || 1).fill(1).map((i, index) => (
					<DroppableComponent
						addBlankWorkOrder={addBlankWorkOrder}
						className={className}
						currentWorkOrderModalId={currentWorkOrderModalId}
						droppableId={`${droppableId}#${index}`}
						dueDate={dueDate}
						forceUnlockOrder={forceUnlockOrder}
						hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
						isDragAndDropDisabled={isDragAndDropDisabled}
						key={`${droppableId}#${index}`}
						lastOpenedOrderCode={lastOpenedOrderCode}
						removeBlankWorkOrder={removeBlankWorkOrder}
						rowIndex={index}
						setEmployeeModalData={setEmployeeModalData}
						setEmployeeModalVisibility={setEmployeeModalVisibility}
						setEquipmentModalData={setEquipmentModalData}
						setEquipmentModalVisibility={setEquipmentModalVisibility}
						setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
						setWorkOrderModalId={setWorkOrderModalId}
						setWorkOrderNoteModalData={setWorkOrderNoteModalData}
					/>
				))}
			</CustomScrollbar>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate } = ownProps;
	const workOrdersOnDateDict = state.scheduleBoard.workOrdersByDateDictionary[dueDate];

	return {
		numberOfRows: Object.keys(workOrdersOnDateDict?.workOrdersRowDistribution ?? {}).length,
		isEmpty: !workOrdersOnDateDict?.workOrdersOrdering?.length,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(Board);
