import * as React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import { filterMap } from 'acceligent-shared/utils/array';
import * as TimeUtils from 'acceligent-shared/utils/time';

import ReportListForBulkSendItemVM from 'ab-viewModels/report/listForBulkSendItem.viewModel';

import * as FieldReportActions from 'af-actions/fieldReport';

import CustomModal from 'af-components/CustomModal';
import DateInput from 'af-components/Controls/DateInput';
import PeriodPicker from 'af-components/Controls/PeriodPicker';
import LockedValue from 'af-components/LockedValue';
import LoadingIndicator from 'af-components/LoadingIndicator';
import SubmitButton from 'af-components/SubmitButton';
import Radio from 'af-components/Controls/Radio';

import { useToggle } from 'af-utils/react.util';

import { SubmitActionCallbackData } from './types';
import ReportListItem from './ReportListItem';

interface OwnPropsBase {
	title: string;
	jobTitle: string;
	customerCompany: string;
	description: JSX.Element;
	showModal: boolean;
	onSubmit: (data: SubmitActionCallbackData) => void;
}

interface OwnPropsWithClose {
	closeModal: () => void;
}

interface NullModalOwnProps extends OwnPropsBase {
	/** Setting this prop to null resets internal state */
	workRequestId: null;
	/** format: `YYYY-MM-DD`, should be `null` only if `workRequestId` is null */
	workOrderDueDate: null;
}

interface NotNullModalOwnProps extends OwnPropsBase {
	/** Setting this prop to null resets internal state */
	workRequestId: number;
	/** format: `YYYY-MM-DD`, should be `null` only if `workRequestId` is null */
	workOrderDueDate: string;
}

type OwnProps = OwnPropsWithClose & (NullModalOwnProps | NotNullModalOwnProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

type SelectedWorkOrderIdLookup = { [workOrderId: string]: boolean; };

function toSelectedWorkOrderIdLookup(lookup: SelectedWorkOrderIdLookup, item: ReportListForBulkSendItemVM) {
	lookup[item.workOrderId] = true;
	return lookup;
}

const MultipleReportsActionModal: React.FC<Props> = (props) => {
	const {
		closeModal,
		customerCompany,
		findReportsList,
		jobTitle,
		onSubmit,
		showModal,
		title,
		workRequestId,
		workOrderDueDate,
		description,
	} = props;

	const [interval, setInterval] = React.useState<{ startDate: Date; endDate: Date; }>({ startDate: new Date(), endDate: new Date() });
	const [reportsList, setReportsList] = React.useState<Nullable<ReportListForBulkSendItemVM[]>>([]);
	const [selectedWorkOrderIdLookup, setSelectedWorkOrderIdLookup] = React.useState<SelectedWorkOrderIdLookup>({});

	const { value: isCustomDateRange, setToTrue: setToCustomDateRange, setToFalse: setToDayRange } = useToggle(true);

	const internalShowModal = showModal && workRequestId !== null;

	React.useEffect(() => {
		// Reset initial state on workRequestId change

		if (workRequestId !== null) {
			setInterval({ startDate: new Date(), endDate: new Date() });
		} else {
			setReportsList([]);
		}
		setToCustomDateRange();
	}, [workRequestId, setToCustomDateRange]);

	React.useEffect(() => {
		// Reset initial state on date range type toggle

		if (!showModal) {
			return;
		}

		if (isCustomDateRange) {
			setInterval({ startDate: new Date(), endDate: new Date() });
		} else {
			if (!workOrderDueDate) {
				throw new Error('Work Order due date not defined');
			}

			const _dueDateDate = TimeUtils.parseDate(workOrderDueDate, TimeFormatEnum.DB_DATE_ONLY);
			setInterval({
				startDate: _dueDateDate,
				endDate: _dueDateDate,
			});
		}
		// NOTE: setting interval will update setReportsList
	}, [showModal, isCustomDateRange, workOrderDueDate]);

	React.useEffect(() => {
		// Fetch data on interval or workRequestId change
		// NOTE: do not add showModal here so that selectedWorkOrderIdLookup state is maintained while modal temporarily hidden
		let isFetchCancelled = false;

		if (workRequestId === null) {
			// Not ready to fetch data
			return;
		}
		const fetchData = async () => {
			setReportsList(null);

			const list = await findReportsList(workRequestId, interval.startDate, interval.endDate);
			if (isFetchCancelled) {
				// Some other value will cleanup setReportsList
				return;
			}
			setReportsList(list ?? null);
		};

		fetchData();

		return () => {
			isFetchCancelled = true;
		};
	}, [workRequestId, interval, findReportsList]);

	React.useEffect(() => {
		if (reportsList !== null) {
			setSelectedWorkOrderIdLookup(reportsList.reduce(toSelectedWorkOrderIdLookup, {}));
		}
	}, [reportsList]);

	const handlePeriodChange = React.useCallback((startDate: Date, endDate: Date) => setInterval({ startDate, endDate }), []);
	const handleDateChange = React.useCallback((newDate: Date) => setInterval({ startDate: newDate, endDate: newDate }), []);

	const handleSubmit = React.useCallback(() => {
		onSubmit?.({
			selectedWorkOrderIds: filterMap(Object.keys(selectedWorkOrderIdLookup), (_workOrderId) => !!selectedWorkOrderIdLookup[_workOrderId], Number),
		});
	}, [onSubmit, selectedWorkOrderIdLookup]);

	const setItemIsChecked = React.useCallback((item: ReportListForBulkSendItemVM, value: boolean) => {
		const { workOrderId } = item;
		setSelectedWorkOrderIdLookup((state) => ({ ...state, [workOrderId]: value }));
	}, []);

	const renderReportListItem = React.useCallback((item: ReportListForBulkSendItemVM) => {
		const { workOrderId } = item;
		const isChecked = !!selectedWorkOrderIdLookup[workOrderId];

		return (
			<ReportListItem
				isChecked={isChecked}
				item={item}
				key={workOrderId}
				setItemIsChecked={setItemIsChecked}
			/>
		);
	}, [selectedWorkOrderIdLookup, setItemIsChecked]);

	const reportsListElement = React.useMemo(() => {
		if (reportsList === null) {
			return <LoadingIndicator color="orange" size="small" />;
		} else if (reportsList.length === 0) {
			return <span>No Reports in period</span>;
		}
		return <>{reportsList.map(renderReportListItem)}</>;
	}, [reportsList, renderReportListItem]);

	return (
		<CustomModal
			className="field-report-orders-table__send-multiple-reports-modal"
			closeModal={closeModal}
			modalStyle="info"
			showModal={internalShowModal}
			size="md"
		>
			<CustomModal.Header closeModal={closeModal} title={title} />
			<CustomModal.Body>
				<div className="field-report-orders-table__send-multiple-reports-modal__job-details-container">
					<Row>
						<Col sm={12}>
							<LockedValue label="Job Title" value={jobTitle} />
						</Col>
						<Col sm={12}>
							<LockedValue label="Customer" value={customerCompany} />
						</Col>
					</Row>
				</div>
				<div className="field-report-orders-table__send-multiple-reports-modal__description-container">
					{description}
				</div>
				<div className="field-report-orders-table__send-multiple-reports-modal__date-picker">
					<Radio
						checked={!isCustomDateRange}
						className="field-report-orders-table__send-multiple-reports-modal__picker-radio"
						inline={true}
						label="Day"
						onCheck={setToDayRange}
					/>
					{!isCustomDateRange &&
						<DateInput
							fixed={true}
							onValueChange={handleDateChange}
							selectedValue={interval.startDate}
						/>
					}
				</div>
				<div>
					<Radio
						checked={isCustomDateRange}
						className="field-report-orders-table__send-multiple-reports-modal__picker-radio"
						inline={true}
						label="Custom Date Range"
						onCheck={setToCustomDateRange}
					/>
					{isCustomDateRange &&
						<PeriodPicker
							end={interval.endDate}
							isPopperFixed
							isPopperInline
							onChange={handlePeriodChange}
							start={interval.startDate}
						/>
					}
				</div>
				<div className="field-report-orders-table__send-multiple-reports-modal__reports-list">
					{reportsListElement}
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button onClick={closeModal} variant="info">Cancel</Button>
				<SubmitButton label="Continue" onClick={handleSubmit} />
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		findReportsList: FieldReportActions.findReportsListForBulkSend,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export type MultipleReportsActionModalSubmitActionCallbackData = SubmitActionCallbackData;
export type MultipleReportsActionModalPropsWithoutClose = NullModalOwnProps | NotNullModalOwnProps;
export default enhance(MultipleReportsActionModal);
