import * as React from 'react';

import { LaborStatisticsPerLocationParsed, IndividualLaborStatisticsParsed } from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';

import IndividualLaborStatistics from 'af-components/LaborStatistics/IndividualLaborStatistics';

import { SB_STATISTIC_WIDTH } from 'af-constants/values';

interface OwnProps {
	laborStatisticsPerLocation: LaborStatisticsPerLocationParsed;
	totalLaborStatistics?: IndividualLaborStatisticsParsed;
	offset?: number;
	isWeekly?: boolean;
}

type Props = OwnProps;

export default class LaborStatisticsPerLocation extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		laborStatisticsPerLocation: {} as LaborStatisticsPerLocationParsed,
		offset: 0,
		isWeekly: false,
	};

	render() {
		const { laborStatisticsPerLocation, totalLaborStatistics, offset, isWeekly } = this.props;

		let className = 'statistics-carousel';
		className = isWeekly ? `${className} statistics-carousel--weekly` : className;

		return (
			<div className={className} style={{ transform: `translateX(${-SB_STATISTIC_WIDTH * (offset ?? 0)}px)` }}>
				{
					Object.entries(laborStatisticsPerLocation).map(([locationNickname, laborStatisticsData]) => laborStatisticsData.hidden ? null : (
						<IndividualLaborStatistics
							key={locationNickname}
							label={locationNickname}
							laborStatistics={laborStatisticsData}
						/>
					))
				}
				{
					totalLaborStatistics &&
					<IndividualLaborStatistics
						label="Total"
						laborStatistics={totalLaborStatistics}
					/>
				}
			</div>
		);
	}
}
