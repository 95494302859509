import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import * as TimeUtils from 'acceligent-shared/utils/time';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

interface OwnProps {
	dueDate: string;
}

interface DispatchProps {
	setWeeklyViewDateWithToolbar: typeof ScheduleBoardActions.setWeeklyViewDateWithToolbar;
}

type Props = OwnProps & ResolveThunks<DispatchProps>;

class ToolbarHeader extends React.PureComponent<Props> {

	hideToolbarForDay = async () => {
		const { setWeeklyViewDateWithToolbar } = this.props;
		setWeeklyViewDateWithToolbar(null);
	};

	render() {
		const { dueDate } = this.props;

		return (
			<div className="toolbar-header">
				<span>
					<div className="schedule-board-day-name">{TimeUtils.getDayName(dueDate)}</div>
					<div className="schedule-board-date">{dueDate}</div>
				</span>
				<span className="pointer close-icon" onClick={this.hideToolbarForDay}>
					<img className="a-icon20" src="/images/inputs/ic_close_inactive.svg" />
				</span>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		setWeeklyViewDateWithToolbar: ScheduleBoardActions.setWeeklyViewDateWithToolbar,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(ToolbarHeader);
