import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { GoogleMap, Marker, MarkerProps } from '@react-google-maps/api';

import GoogleScriptLoader from '../Shared/Loader';

export type OnMapClickFunction = (location: google.maps.MapMouseEvent) => void;

interface OwnProps {
	onMapClick: OnMapClickFunction;
	marker: Nullable<MarkerProps>;
	center: google.maps.LatLng | google.maps.LatLngLiteral;
	zoom: number;
}

type Props = OwnProps;

class MapRow extends React.Component<Props> {
	render() {
		const { onMapClick, marker, center, zoom } = this.props;

		return (
			<Row>
				<Col sm={24}>
					<GoogleScriptLoader>
						<GoogleMap
							center={center}
							mapContainerStyle={{ height: '400px', width: '100%' }}
							onClick={onMapClick}
							zoom={zoom}
						>
							{!!marker
								? <Marker {...marker} />
								: <></>
							}
						</GoogleMap>
					</GoogleScriptLoader>
				</Col>
			</Row>
		);
	}
}

export default MapRow;
