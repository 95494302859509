import * as React from 'react';
import { Field } from 'redux-form';

import Checkbox from 'af-fields/Checkbox';

interface OwnProps {
	invalidEmail: boolean;
	invalidPhoneNumber: boolean;
}

type Props = OwnProps;

const Invitation: React.FC<Props> = ({ invalidEmail, invalidPhoneNumber }) => {

	const [activateImmediatelyChecked, setActivateImmediatelyChecked] = React.useState(false);

	const handleChange = React.useCallback(() => {
		setActivateImmediatelyChecked(!activateImmediatelyChecked);
	}, [activateImmediatelyChecked]);

	return (
		<div className="user-upsert__invite">
			<Field
				component={Checkbox}
				inline={true}
				isDisabled={false}
				isStandalone={true}
				label="Activate immediately"
				name="activateImmediately"
				onChange={handleChange}
				tooltipMessage="Activating the user will allow them to log into the platform."
				type="text"
			/>
			{
				activateImmediatelyChecked &&
				<>
					<Field
						component={Checkbox}
						inline={true}
						isDisabled={invalidEmail}
						isStandalone={true}
						label="Send email invite"
						name="sendEmail"
						type="text"
					/>
					<Field
						component={Checkbox}
						inline={true}
						isDisabled={invalidPhoneNumber}
						isStandalone={true}
						label="Send phone invite"
						name="sendSms"
						type="text"
					/>
				</>
			}
		</div>
	);
};

export default React.memo(Invitation);
