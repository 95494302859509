import * as React from 'react';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';

import SettingPreview from '../../Shared/SettingPreview';
import PreviewItem from '../../Shared/PreviewItem';

type Props = {
	isSendingInvoiceNotificationsEnabled: boolean;
	toggleEdit: () => void;
};

const EnableInvoiceNotificationsPreview: React.FC<Props> = ({ isSendingInvoiceNotificationsEnabled, toggleEdit }) => {
	return (
		<SettingPreview toggleEdit={toggleEdit}>
			<div className="company-settings__info-segment">
				<PreviewItem
					checked={!!isSendingInvoiceNotificationsEnabled}
					hint={CompanyFeatureFlagsTips.invoiceNotifications}
					indent={false}
					label={CompanyFeatureFlagsEnum.isSendingInvoiceNotificationsEnabled}
					showCheckbox={true}
				/>
			</div>
		</SettingPreview>
	);
};

export default React.memo(EnableInvoiceNotificationsPreview);
