import * as React from 'react';

import { TimeSheetEntryHistoryItemVM, TimeSheetEntryHistoryParentVM } from 'ab-viewModels/timeSheet/timeSheetEntryHistory.viewModel';

import TimeSheetEntryHistoryInformationItem from './TimeSheetEntryHistoryInformationItem';

interface OwnProps {
	item: TimeSheetEntryHistoryItemVM;
}

type Props = OwnProps;

const renderParentItem = (entry: TimeSheetEntryHistoryParentVM, index: number) => {
	return (
		<div key={entry.id ?? index}>
			<TimeSheetEntryHistoryInformationItem
				createdAt={entry.createdAt}
				dataFilledBy={entry.dataFilledBy}
				deletedAt={entry.deletedAt}
				deletedBy={entry.deletedBy}
				endTime={entry.endTime}
				hasParents={false}
				startTime={entry.startTime}
				type={entry.type}
				workType={entry.workType}
			/>
			<TimeSheetEntryHistoryInformationItem
				createdAt={entry.createdAt}
				dataFilledBy={entry.dataFilledBy}
				deletedAt={null}
				deletedBy={entry.deletedBy}
				endTime={entry.endTime}
				hasParents={false}
				startTime={entry.startTime}
				type={entry.type}
				workType={entry.workType}
			/>
		</div>
	);
};

const TimeSheetEntryHistoryInformation: React.FC<Props> = (props: Props) => {
	const { item } = props;

	const renderItem = React.useCallback((entry: TimeSheetEntryHistoryItemVM) => {
		return (
			<TimeSheetEntryHistoryInformationItem
				createdAt={entry.createdAt}
				dataFilledBy={entry.dataFilledBy}
				deletedAt={entry.deletedAt}
				deletedBy={entry.deletedBy}
				endTime={entry.endTime}
				hasParents={!!entry.parentEntries?.length}
				key={entry.id}
				startTime={entry.startTime}
				type={entry.type}
				workType={entry.workType}
			/>
		);
	}, []);

	return (
		<div className="timeline-history-modal__information">
			{renderItem(item)}
			{!!item.parentEntries?.length && item.parentEntries.map(renderParentItem)}
		</div>
	);
};

export default React.memo(TimeSheetEntryHistoryInformation);
