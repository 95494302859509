import * as Err from 'restify-errors';

import { DirectoryIcon } from 'acceligent-shared/enums/directoryIcon';
import FileType from 'acceligent-shared/enums/fileType';
import Priority from 'acceligent-shared/enums/priority';
import QuantityUnitType from 'acceligent-shared/enums/quantityUnit';
import ResourceStatus from 'acceligent-shared/enums/resourceStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkRequestDisposalMethod from 'acceligent-shared/enums/workRequestDisposalMethod';
import WorkRequestJobHazardAssessmentStatus from 'acceligent-shared/enums/workRequestJobHazardAssessmentStatus';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import { ColorPalette } from 'acceligent-shared/enums/color';
import AccountPermissionTemplate from 'acceligent-shared/enums/accountPermissionTemplate';
import DeliverableDataType from 'acceligent-shared/enums/deliverableDataType';
import { EmailTypes, EmailTypesArray, PhoneTypes, PhoneTypesArray } from 'acceligent-shared/enums/contactMethodType';
import CountryCode from 'acceligent-shared/enums/countryCode';

import WorkRequest from 'acceligent-shared/models/workRequest';
import Account from 'acceligent-shared/models/account';
import AttachmentDirectory from 'acceligent-shared/models/attachmentDirectory';
import BillingCode from 'acceligent-shared/models/billingCode';
import Directory from 'acceligent-shared/models/directory';
import JobStatus from 'acceligent-shared/models/jobStatus';
import Sale from 'acceligent-shared/models/sale';
import Location from 'acceligent-shared/models/location';
import Employee from 'acceligent-shared/models/employee';
import Division from 'acceligent-shared/models/division';
import DeliverableData from 'acceligent-shared/models/deliverableData';
import ContactLookup from 'acceligent-shared/models/contactLookup';
import ContactAddress from 'acceligent-shared/models/contactAddress';
import ContactMethod from 'acceligent-shared/models/contactMethod';
import Address from 'acceligent-shared/models/address';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { filterMap } from 'acceligent-shared/utils/array';
import { parseDirectoryPath } from 'acceligent-shared/utils/blobStorage';
import * as UserUtils from 'acceligent-shared/utils/user';
import { getShortAddress } from 'acceligent-shared/utils/address';

import * as BlobStorageUtil from 'ab-utils/blobStorage.util';

import * as ContactUtils from 'ab-utils/contact.util';

export class W_Job_FindJobForm_VM {
	// Sales
	salesId: Nullable<string>;
	salesLead: Nullable<W_Job_FindJobForm_VM_Sales>;
	salesLeadId: Nullable<number>;
	estimateTotal: number;

	// Job Summary
	/** on form shown as Work Request Id */
	jobCode: string;
	title: Nullable<string>;
	isInternal: boolean;
	jobNotes: Nullable<string>;
	customerCompanyName: Nullable<string>;
	customerReferencePO: Nullable<string>;
	isEmergencyJob: boolean;
	priority: Priority;
	jobStatusId: Nullable<number>;
	jobStatus: Nullable<W_Job_FindJobForm_VM_WorkRequestStatus>;
	projectManagerId: Nullable<number>;
	projectManager: Nullable<W_Job_FindJobForm_VM_Employee>;
	officeId: Nullable<number>;
	office: Nullable<W_Job_FindJobForm_VM_Office>;
	divisionId: Nullable<number>;
	division: Nullable<W_Job_FindJobForm_VM_Division>;

	// Job Detail
	projectOwner: Nullable<string>;
	ownerReference: Nullable<string>;
	taxExempt: boolean;
	generalContractor: Nullable<string>;
	contractorReference: Nullable<string>;
	isUnion: boolean;
	isPrevailingWage: boolean;
	jurisdiction: Nullable<string>;

	// Requirements
	requestMBEOrWBERequirementsNote: Nullable<string>;
	requestBondRequirementsNote: Nullable<string>;
	requestHSERequirementsNote: Nullable<string>;
	retainage: Nullable<string>;
	requestInsuranceRequirementsNote: Nullable<string>;
	requestLiquidatedDamagesNote: Nullable<string>;

	// Schedule Info
	targetStartDate: Nullable<string>;
	targetCompletionDate: Nullable<string>;
	targetDaysFromStart: Nullable<number>;
	startDate: Nullable<string>;
	guaranteedCompletionDate: Nullable<string>;
	guaranteedDaysFromStart: Nullable<number>;

	// Contacts
	customerContact: Nullable<W_Job_FindJobForm_VM_Contact>;
	siteContact: Nullable<W_Job_FindJobForm_VM_Contact>;
	billingContact: Nullable<W_Job_FindJobForm_VM_Contact>;
	additionalContacts: Nullable<W_Job_FindJobForm_VM_Contact[]>;

	// Deliverables
	isDeliverable: boolean;
	deliverableAssigneeId: Nullable<number>;
	deliverableAssignee: Nullable<W_Job_FindJobForm_VM_Account>;
	deliverableSoftwareId: Nullable<number>;
	deliverableSoftware: Nullable<W_Job_FindJobForm_VM_DeliverableData>;
	deliverableFileFormatId: Nullable<number>;
	deliverableFileFormat: Nullable<W_Job_FindJobForm_VM_DeliverableData>;
	deliverableCodeStandardId: Nullable<number>;
	deliverableCodeStandard: Nullable<W_Job_FindJobForm_VM_DeliverableData>;
	deliverableDeliveryMethodId: Nullable<number>;
	deliverableDeliveryMethod: Nullable<W_Job_FindJobForm_VM_DeliverableData>;
	deliverableDeliveryTimelineId: Nullable<number>;
	deliverableDeliveryTimeline: Nullable<W_Job_FindJobForm_VM_DeliverableData>;
	deliverableNotes: Nullable<string>;

	// Work Location
	workLocation: Nullable<W_Job_FindJobForm_VM_Address>;

	// Summary of Work
	requestJobNote: Nullable<string>;

	// Survey data
	surveyNeeded: boolean;
	surveyedById: Nullable<number>;
	surveyedBy: Nullable<W_Job_FindJobForm_VM_EmployeeOption>;
	surveyDate: Nullable<string>;
	waterAvailable: boolean;
	waterSupplyInformation: Nullable<string>;
	subcontractorNeeded: Nullable<string>;
	subcontractorInformation: Nullable<string>;
	pipeSizes: Nullable<string>;
	pipeTypes: Nullable<string>;
	manholeDepths: Nullable<string>;
	manholeFrameDiameters: Nullable<string>;
	informationsProvidedByCustomersBy: Nullable<string>;

	// Disposal
	ableToCleanAndWashEquipmentAtCustomerLocation: boolean;
	cleanAndWashLocation: Nullable<string>;
	isWasteGeneratedSolid: boolean;
	isWasteGeneratedSludge: boolean;
	isWasteGeneratedLiquid: boolean;
	isWasteHazardous: boolean;
	isWasteManifest: boolean;
	wasteDescription: Nullable<string>;
	expectedWasteQuantity: Nullable<number>;
	disposalMethod: Nullable<WorkRequestDisposalMethod>;
	disposalInstructions: Nullable<string>;
	disposalContact: Nullable<W_Job_FindJobForm_VM_Contact>;

	// Job Hazard Summary
	hazardSummary: Nullable<string>;
	permitsRequired: Nullable<string[]>;

	// Safety
	environmentalExposure: boolean;
	environmentalExposureDescription: Nullable<string>;
	respiratoryProtection: boolean;
	respiratoryProtectionDescription: Nullable<string>;
	overheadHazards: boolean;
	overheadHazardsDescription: Nullable<string>;
	heightWork: boolean;
	heightWorkDescription: Nullable<string>;
	mechanicalHazards: boolean;
	mechanicalHazardsDescription: Nullable<string>;
	electricalHazards: boolean;
	electricalHazardsDescription: Nullable<string>;
	hazardousMaterials: boolean;
	hazardousMaterialsDescription: Nullable<string>;
	dangerousAnimalsOrPlants: boolean;
	dangerousAnimalsOrPlantsDescription: Nullable<string>;

	// Environmental Protection
	waterOrWetlands: boolean;
	waterOrWetlandsDescription: Nullable<string>;
	stormDrain: boolean;
	stormDrainDescription: Nullable<string>;
	bypassPumping: boolean;
	bypassPumpingDescription: Nullable<string>;
	sensitiveEnvironment: boolean;
	sensitiveEnvironmentDescription: Nullable<string>;

	// Public Safety
	vehicleTraffic: boolean;
	vehicleTrafficDescription: Nullable<string>;
	pedestrianCrossing: boolean;
	pedestrianCrossingDescription: Nullable<string>;

	// Status
	jobHazardAssessmentStatus: Nullable<WorkRequestJobHazardAssessmentStatus>;

	/** Necessary when creating job through WO page */
	id: number;
	status: WorkRequestStatus;
	summaryOfWork: Nullable<string>;
	travelLocation: Nullable<W_Job_FindJobForm_VM_Address>;
	travelLocationShort: Nullable<string>;

	jc80gpm: boolean;
	sjc80gpm: boolean;
	jcRecycler80: boolean;
	jjc120gpm: boolean;
	jjc160gpm: boolean;
	jcHiRail: boolean;
	jetter: boolean;
	harbenJetter: boolean;
	hjJetter: boolean;
	tvTruck: boolean;
	tvLateralTk: boolean;
	tvGroutTk: boolean;
	tvLatGroutTk: boolean;
	tvSonarTk: boolean;
	tvPolaris: boolean;
	tvLCRTk: boolean;
	tvLateralReinstator: boolean;
	tvMTHTk: boolean;
	tvLaserTk: boolean;
	minicam: boolean;
	vacTk: boolean;
	hivacTk: boolean;
	hiRailvacTk: boolean;
	tanker: boolean;
	wb: boolean;
	hwBoiler: boolean;
	pressureWash: boolean;
	airComp: boolean;
	clamTk: boolean;
	bucketMach: boolean;
	mhRehabTk: boolean;
	microtrax: boolean;
	smallEasementMachine: boolean;
	bigEasementMachine: boolean;
	arrowBoard: boolean;
	supportTk: boolean;
	rollOffTk: boolean;
	pump: boolean;
	pigLaunch: boolean;
	stoveMach: boolean;
	powerRodder: boolean;
	reeferTruck: boolean;
	lateralReinstatorTrailer: boolean;
	steamCureTruck: boolean;
	airTesting: boolean;
	trackMachine: boolean;
	numberOfEmployees: number;
	additionalTruckEquipmentFields: W_Job_FindJobForm_VM_AdditionalTrucksEquipmentField[];

	// Billing
	billTo: Nullable<string>;
	billToAddress: Nullable<string>;
	billToCityStateZip: Nullable<string>;
	billToPhoneNumber: Nullable<string>;
	billToFaxNumber: Nullable<string>;
	billToContactName: Nullable<string>;
	billToEmail: Nullable<string>;

	allowCustomerSignature: boolean;

	targetedDaysFromStart: Nullable<number>;

	// Project and billing codes
	billingCodes: W_Job_FindJobForm_VM_BillingCode[];
	isProject: boolean;
	projectId: Nullable<number>;
	subjobs: W_Job_FindJobForm_VM_Subjob[];

	// Attachments
	directories: W_Job_FindJobForm_VM_WorkRequestDirectory[];
	workRequestAttachments: W_Job_FindJobForm_VM_Attachment[];
	uploadedAttachmentIds?: number[];

	constructor(data: WorkRequest) {
		if (!!data.projectId && !data.project) {
			throw new Err.InternalServerError('Include project in Work Request');
		}

		this.id = data.id;
		this.salesId = data.salesId;
		this.salesLeadId = data.salesLeadId;
		this.salesLead = data.salesLead ? new W_Job_FindJobForm_VM_Sales(data.salesLead) : null;
		this.estimateTotal = data.estimateTotal ?? 0;

		// Job Summary
		this.jobCode = data.jobCode;
		this.title = data.title;
		this.isInternal = data.isInternal ?? false;
		this.customerCompanyName = data.customerCompanyName;
		this.customerReferencePO = data.customerReferencePO;
		this.isEmergencyJob = data.isEmergencyJob;
		this.priority = data.priority;
		this.jobStatusId = data.jobStatus?.id ?? null;
		this.jobStatus = data.jobStatus;
		this.projectManagerId = data.projectManagerId;
		this.projectManager = data.projectManager ? new W_Job_FindJobForm_VM_Employee(data.projectManager) : null;
		this.officeId = data.officeId;
		this.office = data.office;
		this.divisionId = data.divisionId;
		this.division = data.division;
		this.travelLocation = data.travelLocation ? new W_Job_FindJobForm_VM_Address(data.travelLocation) : null;

		// Job Detail
		this.projectOwner = data.projectOwner;
		this.ownerReference = data.ownerReference;
		this.taxExempt = data.taxExempt;
		this.generalContractor = data.generalContractor;
		this.contractorReference = data.contractorReference;
		this.isUnion = data.isUnion;
		this.isPrevailingWage = data.isPrevailingWage;
		this.jurisdiction = data.jurisdiction;

		this.deliverableAssigneeId = data.deliverableAssigneeId;
		this.deliverableAssignee = data.deliverableAssignee && new W_Job_FindJobForm_VM_Account(data.deliverableAssignee);
		this.deliverableSoftwareId = data.deliverableSoftwareId;
		this.deliverableSoftware = data.deliverableSoftware && new W_Job_FindJobForm_VM_DeliverableData(data.deliverableSoftware);
		this.deliverableFileFormatId = data.deliverableFileFormatId;
		this.deliverableFileFormat = data.deliverableFileFormat && new W_Job_FindJobForm_VM_DeliverableData(data.deliverableFileFormat);
		this.deliverableCodeStandardId = data.deliverableCodeStandardId;
		this.deliverableCodeStandard = data.deliverableCodeStandard && new W_Job_FindJobForm_VM_DeliverableData(data.deliverableCodeStandard);
		this.deliverableDeliveryMethodId = data.deliverableDeliveryMethodId;
		this.deliverableDeliveryMethod = data.deliverableDeliveryMethod && new W_Job_FindJobForm_VM_DeliverableData(data.deliverableDeliveryMethod);
		this.deliverableDeliveryTimelineId = data.deliverableDeliveryTimelineId;
		this.deliverableDeliveryTimeline = data.deliverableDeliveryTimeline && new W_Job_FindJobForm_VM_DeliverableData(data.deliverableDeliveryTimeline);
		this.deliverableNotes = data.deliverableNotes;
		this.travelLocationShort = data.travelLocation ? getShortAddress(data.travelLocation) : null;

		// Requirements
		this.requestMBEOrWBERequirementsNote = data.requestMBEOrWBERequirementsNote;
		this.requestBondRequirementsNote = data.requestBondRequirementsNote;
		this.requestHSERequirementsNote = data.requestHSERequirementsNote;
		this.retainage = data.retainage;
		this.requestInsuranceRequirementsNote = data.requestInsuranceRequirementsNote;
		this.requestLiquidatedDamagesNote = data.requestLiquidatedDamagesNote;

		// Schedule Info
		this.targetStartDate = data.targetStartDate
			? TimeUtils.formatDate(data.targetStartDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
			: null;
		this.targetCompletionDate = data.targetCompletionDate
			? TimeUtils.formatDate(data.targetCompletionDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
			: null;
		this.startDate = data.startDate ? TimeUtils.formatDate(data.startDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY) : null;
		this.guaranteedCompletionDate = data.guaranteedCompletionDate
			? TimeUtils.formatDate(data.guaranteedCompletionDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
			: null;

		this.targetDaysFromStart = (this.targetStartDate && this.targetCompletionDate) ? TimeUtils.getDiff(this.targetCompletionDate, this.targetStartDate, 'days', TimeFormat.DATE_ONLY) : null;
		this.guaranteedDaysFromStart = (this.startDate && this.guaranteedCompletionDate) ? TimeUtils.getDiff(this.guaranteedCompletionDate, this.startDate, 'days', TimeFormat.DATE_ONLY) : null;

		// Contacts
		this.customerContact = data.customerContact ? new W_Job_FindJobForm_VM_Contact(data.customerContact) : null;
		this.siteContact = data.supervisorContact ? new W_Job_FindJobForm_VM_Contact(data.supervisorContact) : null;
		this.billingContact = data.billingContact ? new W_Job_FindJobForm_VM_Contact(data.billingContact) : null;
		this.billTo = data.billTo;
		this.billToAddress = data.billToAddress;
		this.billToCityStateZip = data.billToCityStateZip;
		this.billToPhoneNumber = data.billToPhoneNumber;
		this.billToFaxNumber = data.billToFaxNumber;
		this.billToContactName = data.billToContactName;
		this.billToEmail = data.billToEmail;
		this.additionalContacts = data.additionalContacts
			? W_Job_FindJobForm_VM_Contact.bulkConstructor(data.additionalContacts.map((_ac) => _ac.contactLookup))
			: [];

		// Deliverables
		this.isDeliverable = !!data.deliverableAssigneeId;
		this.deliverableAssigneeId = data.deliverableAssigneeId;
		this.deliverableAssignee = data.deliverableAssignee ? new W_Job_FindJobForm_VM_Account(data.deliverableAssignee) : null;
		this.deliverableSoftwareId = data.deliverableSoftwareId;
		this.deliverableSoftware = data.deliverableSoftware;
		this.deliverableFileFormatId = data.deliverableFileFormatId;
		this.deliverableFileFormat = data.deliverableFileFormat;
		this.deliverableCodeStandardId = data.deliverableCodeStandardId;
		this.deliverableCodeStandard = data.deliverableCodeStandard;
		this.deliverableDeliveryMethodId = data.deliverableDeliveryMethodId;
		this.deliverableDeliveryMethod = data.deliverableDeliveryMethod;
		this.deliverableDeliveryTimelineId = data.deliverableDeliveryTimelineId;
		this.deliverableDeliveryTimeline = data.deliverableDeliveryTimeline;
		this.deliverableNotes = data.deliverableNotes;

		this.allowCustomerSignature = data.allowCustomerSignature;

		// Work Location
		this.workLocation = data.travelLocation ? new W_Job_FindJobForm_VM_Address(data.travelLocation) : null;

		// Summary of Work
		this.requestJobNote = data.requestJobNote;

		// Survey data
		this.surveyNeeded = data.surveyNeeded;
		this.surveyedById = data.surveyedById;
		this.surveyedBy = data.surveyedBy ? new W_Job_FindJobForm_VM_EmployeeOption(data.surveyedBy) : null;
		this.surveyDate = data.surveyDate ? TimeUtils.formatDate(data.surveyDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY) : null;
		this.waterAvailable = data.waterAvailable;
		this.waterSupplyInformation = data.waterSupplyInformation;
		this.subcontractorNeeded = data.subcontractorNeeded;
		this.subcontractorInformation = data.subcontractorInformation;
		this.pipeSizes = data.pipeSizes;
		this.pipeTypes = data.pipeTypes;
		this.manholeDepths = data.manholeDepths;
		this.manholeFrameDiameters = data.manholeFrameDiameters;
		this.informationsProvidedByCustomersBy = data.informationsProvidedByCustomersBy;

		// Trucks / Equipment
		this.jc80gpm = data.jc80gpm;
		this.sjc80gpm = data.sjc80gpm;
		this.jcRecycler80 = data.jcRecycler80;
		this.jjc120gpm = data.jjc120gpm;
		this.jjc160gpm = data.jjc160gpm;
		this.jcHiRail = data.jcHiRail;
		this.jetter = data.jetter;
		this.harbenJetter = data.harbenJetter;
		this.hjJetter = data.hjJetter;
		this.tvTruck = data.tvTruck;
		this.tvLateralTk = data.tvLateralTk;
		this.tvGroutTk = data.tvGroutTk;
		this.tvLatGroutTk = data.tvLatGroutTk;
		this.tvSonarTk = data.tvSonarTk;
		this.tvPolaris = data.tvPolaris;
		this.tvLCRTk = data.tvLCRTk;
		this.tvLateralReinstator = data.tvLateralReinstator;
		this.tvMTHTk = data.tvMTHTk;
		this.tvLaserTk = data.tvLaserTk;
		this.minicam = data.minicam;
		this.vacTk = data.vacTk;
		this.hivacTk = data.hivacTk;
		this.hiRailvacTk = data.hiRailvacTk;
		this.tanker = data.tanker;
		this.wb = data.wb;
		this.hwBoiler = data.hwBoiler;
		this.pressureWash = data.pressureWash;
		this.airComp = data.airComp;
		this.clamTk = data.clamTk;
		this.bucketMach = data.bucketMach;
		this.mhRehabTk = data.mhRehabTk;
		this.microtrax = data.microtrax;
		this.smallEasementMachine = data.smallEasementMachine;
		this.bigEasementMachine = data.bigEasementMachine;
		this.arrowBoard = data.arrowBoard;
		this.supportTk = data.supportTk;
		this.rollOffTk = data.rollOffTk;
		this.pump = data.pump;
		this.pigLaunch = data.pigLaunch;
		this.stoveMach = data.stoveMach;
		this.powerRodder = data.powerRodder;
		this.reeferTruck = data.reeferTruck;
		this.lateralReinstatorTrailer = data.lateralReinstatorTrailer;
		this.steamCureTruck = data.steamCureTruck;
		this.airTesting = data.airTesting;
		this.trackMachine = data.trackMachine;
		this.numberOfEmployees = data.numberOfEmployees;
		this.additionalTruckEquipmentFields = [];
		data.additionalTruckEquipmentField1Name &&
			this.additionalTruckEquipmentFields.push({ isChecked: data.additionalTruckEquipmentField1Checked, name: data.additionalTruckEquipmentField1Name });
		data.additionalTruckEquipmentField2Name &&
			this.additionalTruckEquipmentFields.push({ isChecked: data.additionalTruckEquipmentField2Checked, name: data.additionalTruckEquipmentField2Name });

		// Disposal
		this.ableToCleanAndWashEquipmentAtCustomerLocation = data.ableToCleanAndWashEquipmentAtCustomerLocation;
		this.cleanAndWashLocation = data.cleanAndWashLocation;
		this.isWasteGeneratedSolid = data.isWasteGeneratedSolid;
		this.isWasteGeneratedSludge = data.isWasteGeneratedSludge;
		this.isWasteGeneratedLiquid = data.isWasteGeneratedLiquid;
		this.isWasteHazardous = data.isWasteHazardous;
		this.isWasteManifest = data.isWasteManifest;
		this.wasteDescription = data.wasteDescription;
		this.expectedWasteQuantity = data.expectedWasteQuantity;
		this.disposalMethod = data.disposalMethod;
		this.disposalInstructions = data.disposalInstructions;
		this.disposalContact = data.disposalContact ? new W_Job_FindJobForm_VM_Contact(data.disposalContact) : null;

		this.hazardSummary = data.hazardSummary;
		this.permitsRequired = data.permitsRequired;

		this.environmentalExposure = data.environmentalExposure;
		this.environmentalExposureDescription = data.environmentalExposureDescription;
		this.respiratoryProtection = data.respiratoryProtection;
		this.respiratoryProtectionDescription = data.respiratoryProtectionDescription;
		this.overheadHazards = data.overheadHazards;
		this.overheadHazardsDescription = data.overheadHazardsDescription;
		this.heightWork = data.heightWork;
		this.heightWorkDescription = data.heightWorkDescription;
		this.mechanicalHazards = data.mechanicalHazards;
		this.mechanicalHazardsDescription = data.mechanicalHazardsDescription;
		this.electricalHazards = data.electricalHazards;
		this.electricalHazardsDescription = data.electricalHazardsDescription;
		this.hazardousMaterials = data.hazardousMaterials;
		this.hazardousMaterialsDescription = data.hazardousMaterialsDescription;
		this.dangerousAnimalsOrPlants = data.dangerousAnimalsOrPlants;
		this.dangerousAnimalsOrPlantsDescription = data.dangerousAnimalsOrPlantsDescription;

		// Environmental Protection
		this.waterOrWetlands = data.waterOrWetlands;
		this.waterOrWetlandsDescription = data.waterOrWetlandsDescription;
		this.stormDrain = data.stormDrain;
		this.stormDrainDescription = data.stormDrainDescription;
		this.bypassPumping = data.bypassPumping;
		this.bypassPumpingDescription = data.bypassPumpingDescription;
		this.sensitiveEnvironment = data.sensitiveEnvironment;
		this.sensitiveEnvironmentDescription = data.sensitiveEnvironmentDescription;

		// Public Safety
		this.vehicleTraffic = data.vehicleTraffic;
		this.vehicleTrafficDescription = data.vehicleTrafficDescription;
		this.pedestrianCrossing = data.pedestrianCrossing;
		this.pedestrianCrossingDescription = data.pedestrianCrossingDescription;

		// Job Hazard Assessment Status
		this.jobHazardAssessmentStatus = data.jobHazardAssessmentStatus;

		this.billingCodes = !!data.billingCodes?.length
			? W_Job_FindJobForm_VM_BillingCode.bulkConstructor(data.billingCodes)
			: [];

		this.directories = W_Job_FindJobForm_VM_WorkRequestDirectory.bulkConstructor(data.workRequestDirectories?.map((_wrDir) => _wrDir.directory) ?? []);
		const attachmentDirectories = data.workRequestDirectories?.flatMap((_wrDir) => _wrDir.directory.attachmentDirectories ?? []) ?? [];
		this.workRequestAttachments = W_Job_FindJobForm_VM_Attachment.bulkConstructor(attachmentDirectories);

		this.isProject = data.project
			? data.project.mainWorkRequestId === data.id
			: false;
		this.projectId = data.projectId;
		this.subjobs = filterMap(
			data.project?.workRequests ?? [],
			(_wr) => _wr.id !== data.id,
			(_wr) => new W_Job_FindJobForm_VM_Subjob(_wr)
		);
	}
}

class W_Job_FindJobForm_VM_Subjob {
	id: number;
	jobCode: string;
	isInternal: boolean;
	title: Nullable<string>;
	customerCompany: Nullable<string>;
	customerFormatted: Nullable<string>;
	travelLocationShort: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	office: Nullable<string>;
	projectManager: Nullable<string>;
	/** YYYY-MM-DD */
	startDate: Nullable<string>;

	constructor(wr: WorkRequest) {
		this.id = wr.id;
		this.jobCode = wr.jobCode;
		this.isInternal = !!wr.isInternal;
		this.title = wr.title;
		this.customerCompany = wr.customerCompanyName ?? wr.customerContact?.contact.companyName ?? null;
		this.customerFormatted = wr.customerContact?.contact.fullName ? `${wr.customerContact.contact.fullName}${this.customerCompany ? `, ${this.customerCompany}` : ''}` : null;
		this.travelLocationShort = wr.travelLocation ? getShortAddress(wr.travelLocation) : null;
		this.office = wr.office?.nickname ?? null;
		this.city = wr.travelLocation?.locality ?? null;
		this.state = wr.travelLocation?.aa1 ?? null;
		this.projectManager = wr.projectManager?.account?.user ? UserUtils.getUserName(wr.projectManager.account.user) : null;
		this.startDate = wr.startDate;
	}
}

class W_Job_FindJobForm_VM_BillingCode {
	id: number;
	lineItemNumber: number;
	customerNumber: Nullable<number>;
	customerId: string;
	ownerNumber: Nullable<string>;
	ownerId: Nullable<string>;
	unit: QuantityUnitType;
	unitPrice: string;
	bidQuantity: Nullable<string>;
	group: Nullable<string>;
	description: string;

	constructor(billingCode: BillingCode) {
		this.id = billingCode.id;
		this.lineItemNumber = billingCode.lineItemNumber;
		this.customerNumber = billingCode.customerNumber;
		this.customerId = billingCode.customerId;
		this.ownerNumber = billingCode.ownerNumber;
		this.ownerId = billingCode.ownerId;
		this.unit = billingCode.unit;
		this.unitPrice = billingCode.unitPrice;
		this.bidQuantity = billingCode.bidQuantity;
		this.group = billingCode.group;
		this.description = billingCode.description;
	}

	private static _constructorMap = (billingCode: BillingCode) => new W_Job_FindJobForm_VM_BillingCode(billingCode);

	static bulkConstructor = (billingCodes: BillingCode[]) => billingCodes.map(W_Job_FindJobForm_VM_BillingCode._constructorMap);
}

class W_Job_FindJobForm_VM_UserInfo {
	accountId?: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;

	constructor(account: Account) {
		this.accountId = account.id ?? undefined;
		this.userId = account.user.id;
		this.firstName = account.user.firstName;
		this.lastName = account.user.lastName;
		this.fullName = UserUtils.getUserName(account.user);
	}
}

class W_Job_FindJobForm_VM_WorkRequestDirectory {
	id: number;
	name: string;
	parentId: Nullable<number>;
	icon: Nullable<DirectoryIcon>;
	lastModifiedAt: Nullable<Date>;
	lastModifiedBy: Nullable<W_Job_FindJobForm_VM_UserInfo>;
	attachmentIds: number[];

	constructor(directory: Directory) {
		this.id = directory.id;
		this.name = directory.name;
		this.parentId = directory.parentId;
		this.icon = directory.icon;
		this.attachmentIds = directory.attachmentDirectories?.map((_attDir) => _attDir.attachmentId) ?? [];
		this.lastModifiedAt = directory.lastModifiedAt;
		this.lastModifiedBy = directory.lastModifiedBy ? new W_Job_FindJobForm_VM_UserInfo(directory.lastModifiedBy) : null;
	}

	private static _constructorMap = (directory: Directory) => new W_Job_FindJobForm_VM_WorkRequestDirectory(directory);

	static bulkConstructor = (directories: Directory[]) => directories.map(W_Job_FindJobForm_VM_WorkRequestDirectory._constructorMap);
}

class W_Job_FindJobForm_VM_Attachment {
	id: number;
	size: number;
	name: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	copyToWorkOrderFlag: boolean;
	lastModifiedAt: Date;
	uploadedBy: Nullable<W_Job_FindJobForm_VM_UserInfo>;

	constructor(attachmentDirectory: AttachmentDirectory) {
		const { attachment, workRequestAttachmentDirectory } = attachmentDirectory;
		this.id = attachment.id;
		this.size = attachment.size;
		this.name = attachment.name;
		this.type = attachment.type;
		this.status = attachment.status;
		this.storageName = attachment.storageName;
		this.storageContainer = attachment.storageContainer;
		this.lastModifiedAt = attachment.createdAt;

		const directories = parseDirectoryPath(this.storageContainer);

		this.originalSrc = BlobStorageUtil.generatePresignedGetUrl(directories, this.storageName);
		this.copyToWorkOrderFlag = workRequestAttachmentDirectory?.copyToWorkOrder ?? false;

		const attUser = attachment.uploadedBy?.user;
		const uploadedObj = attachment.uploadedBy ? new W_Job_FindJobForm_VM_UserInfo(attachment.uploadedBy) : null;

		this.uploadedBy = attUser ? uploadedObj : null;
	}

	private static _constructorMap = (attachmentDirectory: AttachmentDirectory) => new W_Job_FindJobForm_VM_Attachment(attachmentDirectory);

	static bulkConstructor = (attachmentDirectories: AttachmentDirectory[]) => attachmentDirectories.map(W_Job_FindJobForm_VM_Attachment._constructorMap);
}

type W_Job_FindJobForm_VM_AdditionalTrucksEquipmentField = {
	name: string;
	isChecked: boolean;
};

class W_Job_FindJobForm_VM_WorkRequestStatus {
	id: number;
	name: string;
	description: Nullable<string>;
	color: ColorPalette;

	constructor(jobStatus: JobStatus) {
		this.id = jobStatus.id;
		this.name = jobStatus.name;
		this.description = jobStatus.description;
		this.color = jobStatus.color;
	}
}

class W_Job_FindJobForm_VM_Sales {
	id: number;
	fullName: string;

	constructor(sale: Sale) {
		this.id = sale.id;
		this.fullName = sale.fullName;
	}
}

class W_Job_FindJobForm_VM_Office {
	id: number;
	nickname: string;

	constructor(office: Location) {
		this.id = office.id;
		this.nickname = office.nickname;
	}
}

class W_Job_FindJobForm_VM_EmployeeOption {
	id: number;
	formattedCode: string;
	firstName: string;
	lastName: string;
	uniqueId: string;
	accountTemplate: AccountPermissionTemplate;
	fullName: string;

	constructor(employee: Employee) {
		const user = employee?.account?.user;

		if (!user) {
			throw new Error('Missing user property');
		}

		this.id = employee.id;
		this.formattedCode = user.uniqueId;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.uniqueId = user.uniqueId;
		this.accountTemplate = employee?.account?.accountTemplate;
		this.fullName = UserUtils.getUserName(employee.account.user);
	}
}

class W_Job_FindJobForm_VM_Division {
	id: number;
	name: string;

	constructor(division: Division) {
		this.id = division.id;
		this.name = division.name;
	}
}

class W_Job_FindJobForm_VM_DeliverableData {
	id: number;
	name: string;
	type: DeliverableDataType;

	constructor(status: DeliverableData) {
		this.id = status.id;
		this.name = status.name;
		this.type = status.type;
	}
}

class W_Job_FindJobForm_VM_Contact {
	id: number;
	workOrderContactId: number;
	role: Nullable<string>;
	fullName: string;
	companyName: Nullable<string>;

	addresses: W_Job_FindJobForm_VM_ContactAddress[];
	emails: W_Job_FindJobForm_VM_ContactMethod[];
	phones: W_Job_FindJobForm_VM_ContactMethod[];

	contactAddressIds: number[];
	contactEmailIds: number[];
	contactPhoneIds: number[];

	constructor(wrc: ContactLookup) {
		if (!wrc) {
			return;
		}

		const _contact = wrc?.contact;

		this.id = wrc.contactId;
		this.workOrderContactId = wrc.id;
		this.role = _contact?.title;
		this.fullName = _contact?.fullName;
		this.companyName = _contact?.companyName;

		this.addresses = W_Job_FindJobForm_VM_ContactAddress.toList(_contact?.addresses ?? []);
		this.emails = W_Job_FindJobForm_VM_ContactMethod.toList(_contact?.contactMethods ?? [], EmailTypes);
		this.phones = W_Job_FindJobForm_VM_ContactMethod.toList(_contact?.contactMethods ?? [], PhoneTypes);

		this.contactAddressIds = wrc.contactLookupAddresses?.map((_wrcAdr) => _wrcAdr.contactAddressId) ?? [];
		this.contactEmailIds = ContactUtils.filterContactMethod(wrc.contactLookupMethods, EmailTypesArray);
		this.contactPhoneIds = ContactUtils.filterContactMethod(wrc.contactLookupMethods, PhoneTypesArray);
	}

	static bulkConstructor = (wrcs: ContactLookup[]) => wrcs.map((_wrc) => new W_Job_FindJobForm_VM_Contact(_wrc));
}

class W_Job_FindJobForm_VM_ContactAddress {
	id: number;
	contactId: number;
	addressId: number;
	address: W_Job_FindJobForm_VM_Address;
	shortAddress: Nullable<string>;

	constructor(contactAddress: ContactAddress) {
		this.id = contactAddress.id;
		this.contactId = contactAddress.contactId;
		this.addressId = contactAddress.addressId;
		this.address = contactAddress.address && new W_Job_FindJobForm_VM_Address(contactAddress.address);
	}

	static toList(contactAddresses: ContactAddress[]): W_Job_FindJobForm_VM_ContactAddress[] {
		return contactAddresses.map((_address) => new W_Job_FindJobForm_VM_ContactAddress(_address));
	}
}

class W_Job_FindJobForm_VM_ContactMethod {
	id: number;
	contactId: number;
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode: Nullable<CountryCode>;
	extension: Nullable<string>;

	constructor(contactMethod: ContactMethod) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
		this.countryCode = contactMethod.countryCode;
		this.extension = contactMethod.extension;
	}

	static toList(contactMethods: ContactMethod[], type: typeof EmailTypes | typeof PhoneTypes): W_Job_FindJobForm_VM_ContactMethod[] {
		return contactMethods
			.filter((_method) => _method && Object.keys(type).includes(_method.type))
			.map((_method) => new W_Job_FindJobForm_VM_ContactMethod(_method));
	}
}

class W_Job_FindJobForm_VM_Address {
	id: number;
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	aa1: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	latitude: number;
	longitude: number;
	locality: Nullable<string>;

	constructor(address: Address) {
		this.id = address.id;
		this.aa1 = address.aa1;
		this.aa2 = address.aa2;
		this.aa3 = address.aa3;
		this.country = address.country;
		this.latitude = address.latitude;
		this.longitude = address.longitude;
		this.city = address.locality;
		this.zip = address.postalCode;
		this.state = address.aa1;
		this.route = address.route;
		this.street = address.street;
		this.streetNumber = address.streetNumber;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode;
		this.suite = address.suite;
		this.locality = address.locality;
	}
}

class W_Job_FindJobForm_VM_Account {
	id: number;
	firstName: string;
	lastName: string;
	uniqueId: string;
	fullName: string;

	constructor(account: Account) {
		this.id = account.id;
		this.firstName = account.user.firstName;
		this.lastName = account.user.lastName;
		this.uniqueId = account.user.uniqueId;
		this.fullName = UserUtils.getUserName(account.user);
	}
}

class W_Job_FindJobForm_VM_Employee {
	id: number;
	firstName: string;
	lastName: string;
	fullName: string;
	formattedCode: string;

	constructor(employee: Employee) {
		this.id = employee.id;
		this.firstName = employee.account?.user?.firstName;
		this.lastName = employee.account?.user?.lastName;
		this.fullName = UserUtils.getUserName(employee.account.user);
		this.formattedCode = employee.account?.user?.uniqueId;
	}
}
