import * as React from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import * as queryString from 'query-string';

import * as AuthenticationActions from 'af-actions/authentication';

import CLIENT from 'af-constants/routes/client';

import * as UserRequestModel from 'ab-requestModels/users.requestModel';

import Input from 'af-fields/Input';

import SubmitButton from 'af-components/SubmitButton';

import { USER_RESET_PASSWORD } from 'af-constants/reduxForms';

import validate from 'af-root/scenes/Authentication/ResetPassword/validations';

type OwnProps = CustomRouteComponentProps;
type FormOwnProps = ConnectedProps<typeof connector> & OwnProps;
type Props = FormOwnProps & InjectedFormProps<UserRequestModel.ResetPassword, FormOwnProps>;

class ResetPassword extends React.PureComponent<Props> {

	resetPassword = async (form: UserRequestModel.ResetPassword) => {
		const { history, resetPassword, location, location: { state: { orgAlias } } } = this.props;

		const queryParams: Metadata = queryString.parse(location.search);
		const formWithParams: UserRequestModel.ResetPassword = Object.assign(queryParams, form) as UserRequestModel.ResetPassword;
		formWithParams.orgAlias = orgAlias;
		delete formWithParams.repeatedPassword;
		await resetPassword(formWithParams);
		history.push(CLIENT.AUTH.RESET_PASS_SUCCESS(orgAlias));
	};

	render(): JSX.Element {
		const { handleSubmit, submitting } = this.props;

		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone form-box--padded">
					<h2>Acceligent Password Reset</h2>
					<h4>Please enter new password below.</h4>
					<form>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									id="password"
									label="New Password *"
									name="password"
									type="password"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									id="repeatedPassword"
									label="Repeat New Password *"
									name="repeatedPassword"
									type="password"
								/>
							</Col>
						</Row>
						<Row className="row--padded">
							<Col sm={24}>
								<SubmitButton
									className="btn--full"
									label="Submit"
									onClick={handleSubmit(this.resetPassword)}
									reduxFormSubmitting={submitting}
								/>
							</Col>
						</Row>
					</form>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		resetPassword: AuthenticationActions.resetPassword,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<UserRequestModel.ResetPassword, FormOwnProps>({ form: USER_RESET_PASSWORD, validate })
);

export default enhance(ResetPassword);
