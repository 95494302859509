import * as React from 'react';

export const useToggle = (defaultValue: boolean = false) => {
	const [value, setValue] = React.useState(defaultValue);

	const setToTrue = React.useCallback(() => setValue(true), []);
	const setToFalse = React.useCallback(() => setValue(false), []);
	const toggle = React.useCallback(() => setValue((state) => !state), []);

	return { value, setToTrue, setToFalse, toggle };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLazyLoad = <F extends (...args: any[]) => Promise<any>>(action: F, ...args: Parameters<F>) => {
	const [options, setValue] = React.useState<PromiseResult<ReturnType<F>>>([] as PromiseResult<ReturnType<F>>);

	const lazyLoad = React.useCallback(async (loaded: boolean) => {
		if (!loaded) {
			const _value = await action(...args);
			setValue(_value as PromiseResult<ReturnType<F>>);
		}
	}, [action, args]);

	return { options, lazyLoad };
};

const DEFAULT_MIN_COLUMN_WIDTH = 50;

export interface CellWidthsMap {
	[id: string]: {
		width: number;
	};
}

type DraggableColumnsOptions = {
	minColumnWidth?: number;
	onColumnDragSideEffect?: (cellWidthMap?: CellWidthsMap) => void;
};

export const useDraggableColumns = (options: DraggableColumnsOptions) => {
	const { minColumnWidth = DEFAULT_MIN_COLUMN_WIDTH, onColumnDragSideEffect } = options;
	const [cellWidthsMap, setCellWidthsMap] = React.useState<CellWidthsMap>({});
	const draggingHeaderId = React.useRef<Nullable<string>>(null);
	const columnRefs = React.useRef<Record<string, HTMLSpanElement>>({});

	const onMouseMove = React.useCallback((e: MouseEvent) => {
		if (!draggingHeaderId.current || Object.keys(columnRefs.current).length === 0) {
			return;
		}
		const id = draggingHeaderId.current;
		const newCellWidthsMap = { ...cellWidthsMap };
		let newWidth = 0;
		if (cellWidthsMap[id]) {
			newWidth = newCellWidthsMap[id].width + e.movementX;
			if (newWidth < (minColumnWidth ?? DEFAULT_MIN_COLUMN_WIDTH)) {
				return;
			}
			newCellWidthsMap[id].width = newWidth;
		} else {
			const columnWidth = columnRefs.current[id].getBoundingClientRect().width;
			newWidth = +columnWidth + e.movementX;
			if (newWidth < (minColumnWidth ?? DEFAULT_MIN_COLUMN_WIDTH)) {
				return;
			}

			newCellWidthsMap[id] = { width: newWidth };
		}

		setCellWidthsMap(newCellWidthsMap);
		onColumnDragSideEffect?.(newCellWidthsMap);

	}, [cellWidthsMap, minColumnWidth, onColumnDragSideEffect]);

	const onColumnDrag = React.useCallback((header: string) => {
		return () => {
			draggingHeaderId.current = header;
		};
	}, []);

	const onMouseUp = React.useCallback(() => {
		if (!draggingHeaderId.current) {
			return;
		}

		draggingHeaderId.current = null;
	}, []);

	React.useEffect(() => {
		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);

		return () => {
			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
		};
	}, [onMouseMove, onMouseUp]);

	return {
		onColumnDrag,
		cellWidthsMap,
		columnRefs,
	};
};
