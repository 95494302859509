import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import { WO_PREVIEW_MOBILE_BREAKPOINT } from 'af-constants/values';

import PdfDocument from './PdfDocument';
import LoadingComponent from './Loading';

export const Loading = LoadingComponent;

interface Props {
	className: string;
	data: Buffer;
	isPublic?: boolean;
	disableCollapse?: boolean;
}

const DocumentViewer: React.FC<Props> = (props) => {
	const {
		className,
		data,
	} = props;

	const [scale, setScale] = React.useState<number>(window.innerWidth < WO_PREVIEW_MOBILE_BREAKPOINT ? 0.5 : 1);

	const zoomIn = React.useCallback(() => setScale(scale + 0.1), [scale]);
	const zoomOut = React.useCallback(() => setScale(scale - 0.1), [scale]);

	return (
		<div className={`${className} document-viewer-item`}>
			<div className="zoom-options">
				<img className="pointer" onClick={zoomIn} src="/images/inputs/ic_zoom_in.svg" />
				<img className="pointer" onClick={zoomOut} src="/images/inputs/ic_zoom_out.svg" />
			</div>

			<Scrollbars
				autoHeight={true}
				autoHeightMax={500}
				autoHide={true}
				autoHideDuration={200}
				autoHideTimeout={200}
				className={className}
				thumbMinSize={60}
				universal={true}
			>
				<PdfDocument
					data={data}
					scale={scale}
				/>
			</Scrollbars>

		</div>
	);

};

export default DocumentViewer;
