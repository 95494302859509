import * as React from 'react';

import { DeliverableStatusViewModel } from 'ab-viewModels/deliverableStatusTable.viewModel';

import Tooltip from 'af-components/Tooltip';

const NOTIFY_TOOLTIP_MESSAGE = 'Superintendent, Project Manager and QA/QC assignee will be notified when this status is set.';
const COMPLETE_TOOLTIP_MESSAGE = 'Job will be moved to Completed when this status is set.';

interface OwnProps {
	status: DeliverableStatusViewModel;
}

type Props = OwnProps;

const StatusOption = ({ status }: Props) => {
	if (!status) {
		return <div />;
	}

	if (!status.notify && !status.isCompleted) {
		return <div>{status.name}</div>;
	}

	const message = status.notify ? NOTIFY_TOOLTIP_MESSAGE : COMPLETE_TOOLTIP_MESSAGE;
	const icon = `icon-${status.notify ? 'notifications_active' : 'daily_report'}`;

	return (
		<div className="deliverable-form__status-option">
			<div>{status.name}</div>
			<Tooltip className="tooltip--center-text" message={message}>
				<span className={icon} />
			</Tooltip>
		</div>
	);
};

export default React.memo(StatusOption);
