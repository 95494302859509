import * as React from 'react';

interface Email {
	value: string;
}

interface OwnProps {
	emails: Email[];
}

type Props = OwnProps;

const _mapEmail = (email: Email, index: number) => (
	<div className="email-cell" key={email.value} title={email.value}>
		<span>{index + 1}.</span>
		{email.value}
	</div>
);

const EmailCell = ({ emails }: Props) => {
	return <>{emails.map(_mapEmail)}</>;
};

export default React.memo(EmailCell);
