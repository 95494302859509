import * as React from 'react';
import { compose } from 'redux';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps, withRouter } from 'react-router-dom';

import * as JobActions from 'af-actions/jobs';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';

import CLIENT from 'af-constants/routes/client';
import * as FORMS from 'af-constants/reduxForms';

import { RootState } from 'af-reducers';

import { CopyJobFormModel } from './formModel';
import { validate } from './validations';

const getJobCodeDefaultValue = (code: string) => `${code} - copy`;

interface OwnProps {
	showModal: boolean;
	close: () => void;
	jobToCopyId: number;
	jobToCopyCode: string;
}

type FormOwnProps = OwnProps & ConnectedProps<typeof connector> & CustomRouteComponentProps;

type FormProps = InjectedFormProps<CopyJobFormModel, FormOwnProps>;

type Props = FormOwnProps & FormProps;

const CopyModal: React.FC<Props> = (props) => {
	const {
		showModal,
		close,
		copyJob,
		jobToCopyId,
		jobToCopyCode,
		history,
		companyName,
		location: { state: { orgAlias, originUrl } },
		handleSubmit,
		submitting,
		invalid,
		initialize,
	} = props;

	const resetForm = React.useCallback(() => {
		initialize({ jobCode: getJobCodeDefaultValue(jobToCopyCode) });
	}, [jobToCopyCode, initialize]);

	React.useEffect(() => {
		if (!jobToCopyId || !jobToCopyCode) {
			return;
		}

		resetForm();
	}, [jobToCopyId, jobToCopyCode, resetForm]);

	const closeModal = React.useCallback(() => {
		close();
		resetForm();
	}, [resetForm, close]);

	const onSave = React.useCallback(async (form: CopyJobFormModel) => {
		const job = await copyJob(jobToCopyId, form.jobCode, form.shouldCopyAttachments);

		if (!job) {
			return;
		}

		history.push(originUrl || CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, job.id.toString()));
		closeModal();
	}, [copyJob, jobToCopyId, closeModal, history, orgAlias, companyName, originUrl]);

	const handleSave = React.useMemo(() => handleSubmit(onSave), [handleSubmit, onSave]);

	if (!jobToCopyId || !jobToCopyCode) {
		return null;
	}

	return (
		<CustomModal
			closeModal={closeModal}
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Copy Job"
			/>
			<CustomModal.Body>
				<Form>
					<Row>
						<Col sm={24}>
							<Field
								component={Input}
								label="Job ID / Name"
								name="jobCode"
								type="text"
							/>
						</Col>
					</Row>
				</Form>
				<Row className="row--non-padded">
					<Col md={24}>
						<Field
							component={Checkbox}
							id="shouldCopyAttachments"
							isStandalone={true}
							label="Copy Job Attachments"
							name="shouldCopyAttachments"
							propName="shouldCopyAttachments"
						/>
					</Col>
				</Row>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					label="Save"
					onClick={handleSave}
					reduxFormSubmitting={submitting}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		copyJob: JobActions.copyJob,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	withRouter,
	connector,
	reduxForm<CopyJobFormModel, FormOwnProps>({ form: FORMS.JOB_COPY, validate }),
	React.memo
);

export default enhance(CopyModal);
