import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import * as UserRequestModel from 'ab-requestModels/users.requestModel';

import * as AuthenticationActions from 'af-actions/authentication';

import Input from 'af-fields/Input';

import SubmitButton from 'af-components/SubmitButton';

import { USER_PASSWORD_FORGOTTEN } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import validate from 'af-root/scenes/Authentication/PasswordForgotten/validations';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<UserRequestModel.PasswordForgotten>;

class PasswordForgotten extends React.PureComponent<Props> {

	submitRequest = async (credentials: UserRequestModel.PasswordForgotten) => {
		const { history, passwordForgotten, location: { state: { orgAlias } } } = this.props;
		credentials.orgAlias = orgAlias;
		await passwordForgotten(credentials);
		history.push(CLIENT.AUTH.PASS_FORGOTTEN_SUCCESS(orgAlias));
	};

	render(): JSX.Element {
		const { handleSubmit, submitting } = this.props;
		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone">
					<h2>Forgot your password?</h2>
					<h4>Enter your email address below, and we'll get you back on track.</h4>
					<form>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									id="email"
									label="Email *"
									name="email"
									type="text"
								/>
							</Col>
						</Row>
						<Row className="row--padded">
							<Col sm={24}>
								<SubmitButton
									className="btn--full"
									label="Submit"
									onClick={handleSubmit(this.submitRequest)}
									reduxFormSubmitting={submitting}
								/>
							</Col>
						</Row>
					</form>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		passwordForgotten: AuthenticationActions.passwordForgotten,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm({ form: USER_PASSWORD_FORGOTTEN, validate })
);

export default enhance(PasswordForgotten);
