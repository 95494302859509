import * as React from 'react';

import LoadingIndicator from 'af-components/LoadingIndicator';
import CustomModal from 'af-components/CustomModal';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	show: boolean;
	label?: React.ReactNode;
}

type Props = OwnProps;

class LoadingOverlay extends React.PureComponent<Props> {

	render() {
		const { label, show } = this.props;

		return (
			<CustomModal
				className="loading-overlay"
				showModal={show}
			>
				<CustomModal.Body>
					<LoadingIndicator color="orange" />
					{!!label && <div className={bemElement('loading-overlay', 'label')}>{label}</div>}
				</CustomModal.Body>
			</CustomModal>
		);
	}
}

export default LoadingOverlay;
