import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	iconStatus: string;
	label: string;
}

type Props = OwnProps;

const CLASS_NAME = bemElement('company-settings', 'feature-status');

const Label = ({ iconStatus, label }: Props) => (
	<span>
		<img className={CLASS_NAME} src={`/images/elements/ic_state_${iconStatus}.svg`} />
		{label}
	</span>
);

export default React.memo(Label);
