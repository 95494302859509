import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ColorPalette } from 'acceligent-shared/enums/color';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { EmployeeVM } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import * as ScheduleBoardEmployeeSkillViewModel from 'ab-viewModels/scheduleBoardResourceSkill.viewModel';
import { DisplayViewWorkOrderViewModel, DisplayViewEmployeeViewModel, DisplayViewEquipmentViewModel, DisplayViewPlaceholderViewModel, DisplayViewResourceViewModel, DisplayViewTemporaryEmployeeBaseVM } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';
import { ScheduleBoardWorkOrderResourceLookupsViewModel } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardResourceLookup.viewModel';
import ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import { NotificationStatusByEmployee } from 'ab-viewModels/notification.viewModel';

import { RootState } from 'af-reducers';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import BlankCard from './Cards/BlankCard';
import WorkOrderCard from './Cards/WorkOrderCard';

interface OwnProps {
	dueDate: string;
	notificationsEnabled: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const WorkOrderCards: React.FC<Props> = (props: Props) => {
	const { workOrders, employeeNightShiftAssignments, temporaryEmployeeNightShiftAssignments, notificationsEnabled } = props;

	return (
		<div className="display-view-work-orders-wrapper">
			{
				workOrders?.map((_workOrder, _index) => (
					_workOrder === null
						? <BlankCard index={_index} key={_index} />
						: (
							<WorkOrderCard
								employeeNightShiftAssignments={employeeNightShiftAssignments}
								hideIndex={false}
								hideMultipleAssignmentBadges={false}
								key={_index}
								notificationsEnabled={notificationsEnabled}
								temporaryEmployeeNightShiftAssignments={temporaryEmployeeNightShiftAssignments}
								workOrder={_workOrder}
							/>
						)
				))
			}
		</div>
	);
};

function getFullName(employee: Nullable<EmployeeVM>) {
	if (!employee) {
		return 'N/A';
	}
	const { firstName, lastName } = employee;
	return `${firstName[0]}. ${lastName}`.trim();
}

function getStatusIconNotificationStatus(
	workOrder: ScheduleBoardWorkOrderViewModel,
	workOrderResourceLookupsDictionary: ScheduleBoardWorkOrderResourceLookupsViewModel,
	notificationStatusMap: NotificationStatusByEmployee
): Nullable<NotificationStatusEnum> {
	const workOrderResourceLookups = (workOrder?.workOrderResourceLookups ?? [])
		.map((workOrderResourceId) => workOrderResourceLookupsDictionary?.[workOrderResourceId]);

	return ScheduleBoardUtil.getGlobalParticipantNotificationStatus(workOrderResourceLookups, notificationStatusMap, workOrder.excludeFromNotify);
}

// FIXME: mapStateToProps is 2/3 of this file
function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate } = ownProps;

	if (!state.scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return { workOrders: null, nightShiftAssignments: {} };
	}

	const workOrdersOnDateDict = state.scheduleBoard.workOrdersByDateDictionary[dueDate];
	const workOrdersOrdering = workOrdersOnDateDict?.workOrdersOrdering ?? [];
	const employeeNightShiftAssignments = state.scheduleBoard.workOrdersByDateDictionary[dueDate]?.employeeNightShiftAssignments ?? {};
	const temporaryEmployeeNightShiftAssignments = state.scheduleBoard.workOrdersByDateDictionary[dueDate]?.temporaryEmployeeNightShiftAssignments ?? {};

	// FIXME: This needs memorization
	const workOrders: (DisplayViewWorkOrderViewModel | null)[] = workOrdersOrdering.map((_jobCode, _index) => {
		if (ScheduleBoardUtil.isBlankWorkOrderId(_jobCode)) {
			return null;
		}

		const workOrder = workOrdersOnDateDict?.workOrders?.[_jobCode];

		const projectManager = workOrder?.projectManager ?? null;
		const supervisor = workOrder?.supervisor ?? null;

		const isWorkOrderCanceled = workOrder.status === WorkOrderStatusEnum.CANCELED;
		const notificationStatus = isWorkOrderCanceled
			? workOrdersOnDateDict?.assignedCanceledNotificationStatuses ?? {}
			: workOrdersOnDateDict?.assignedPublishedNotificationStatuses ?? {};

		const workOrderResourceLookupsDictionary: ScheduleBoardWorkOrderResourceLookupsViewModel = isWorkOrderCanceled
			? workOrdersOnDateDict.canceledWorkOrderResourceLookups
			: workOrdersOnDateDict.workOrderResourceLookups;

		const resources: DisplayViewResourceViewModel[] = workOrder.workOrderResourceLookups.reduce(
			(_resources: DisplayViewResourceViewModel[], _workOrderResourceId) => {
				const workOrderResource = workOrderResourceLookupsDictionary[_workOrderResourceId];

				if (!workOrderResource) {
					return _resources;
				} else if (workOrderResource.equipmentId && state.scheduleBoard.equipment) {
					const equipment = state.scheduleBoard.equipment[workOrderResource.equipmentId];
					const numberOfAssignments = !isWorkOrderCanceled &&
						(workOrdersOnDateDict?.equipmentAssignments?.[workOrderResource.equipmentId]?.length ?? null);
					_resources.push({
						equipmentId: equipment.id,
						code: equipment.name,
						specification: equipment.spec,
						color: equipment.color,
						numberOfAssignments,
						isAvailable: !workOrdersOnDateDict.equipmentUnavailabilityDetails[equipment.id],
					} as DisplayViewEquipmentViewModel);
				} else if (workOrderResource.employeeId && state.scheduleBoard.employees) {
					const employee = state.scheduleBoard.employees[workOrderResource.employeeId];
					const numberOfAssignments = !isWorkOrderCanceled &&
						(workOrdersOnDateDict?.employeeAssignments?.[workOrderResource.employeeId]?.length ?? null);
					const employeeStatus = workOrderResource.workOrderEmployeeId
						? notificationStatus?.[workOrderResource.workOrderEmployeeId]
						: null;
					const resourceNotificationStatus = ScheduleBoardUtil.getEmployeeNotificationStatus(
						employeeStatus?.emailStatus ?? null,
						employeeStatus?.smsStatus ?? null,
						employeeStatus?.emailSentAt ?? null,
						employeeStatus?.smsSentAt ?? null,
						employeeStatus?.isPreviousRevision ?? undefined
					);

					_resources.push({
						employeeId: employee.id,
						firstName: employee.firstName,
						lastName: employee.lastName,
						account: employee.account,
						skills: employee.skills,
						cdlStatus: employee.cdlStatus,
						officeColor: employee?.office?.color,
						notificationStatus: resourceNotificationStatus,
						duplicateDisplayNameExists: employee.duplicateDisplayNameExists,
						numberOfAssignments,
					} as DisplayViewEmployeeViewModel);
				} else if (workOrderResource.temporaryEmployeeId && state.scheduleBoard.temporaryEmployees) {
					const employee = state.scheduleBoard.temporaryEmployees[workOrderResource.temporaryEmployeeId];
					const numberOfAssignments = !isWorkOrderCanceled &&
						(workOrdersOnDateDict?.temporaryEmployeeAssignments?.[workOrderResource.temporaryEmployeeId]?.length ?? null);
					const employeeStatus = workOrderResource.workOrderTemporaryEmployeeId
						? notificationStatus?.[workOrderResource.workOrderTemporaryEmployeeId]
						: null;
					const resourceNotificationStatus = ScheduleBoardUtil.getEmployeeNotificationStatus(
						employeeStatus?.emailStatus ?? null,
						employeeStatus?.smsStatus ?? null,
						employeeStatus?.emailSentAt ?? null,
						employeeStatus?.smsSentAt ?? null,
						employeeStatus?.isPreviousRevision ?? undefined
					);

					_resources.push({
						temporaryEmployeeId: employee.id,
						firstName: employee.firstName,
						lastName: employee.lastName,
						accountId: employee.account.id,
						uniqueId: employee.uniqueId,
						account: employee.account,
						officeColor: employee?.agencyColor,
						notificationStatus: resourceNotificationStatus,
						duplicateDisplayNameExists: employee.duplicateDisplayNameExists,
						numberOfAssignments,
					} as DisplayViewTemporaryEmployeeBaseVM);
				} else if (workOrderResource.workOrderPlaceholderId) {
					let displayName: string = '';
					let skills: ScheduleBoardEmployeeSkillViewModel.Single[] = [];
					let categoryColor: Nullable<ColorPalette> = null;

					if (workOrderResource.wageRate) { // Checking if it's employee placeholder.
						displayName = `${workOrderResource.wageRate.type} (${workOrderResource.wageRate.wageClassification})`;
						skills = workOrderResource.skills ?? [];
					} else { // It's equipment placeholder.
						displayName = `${workOrderResource.equipmentCost?.subcategory} (${workOrderResource.equipmentCost?.categoryName})`;
						categoryColor = workOrderResource.equipmentCost?.categoryColor ?? null;
					}
					_resources.push({
						placeholderId: workOrderResource.workOrderPlaceholderId,
						displayName,
						skills,
						categoryColor,
					} as DisplayViewPlaceholderViewModel);
				}

				return _resources;
			},
			[]
		);

		return {
			index: _index,
			id: workOrder.id,
			dueDate: TimeUtils.formatDate(workOrder.dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY),
			isInternal: workOrder.isInternal,
			isNightShift: workOrder.isNightShift,
			status: workOrder.status,
			revision: workOrder.revision,
			customCrewType: workOrder.customCrewType,
			codeStripped: workOrder.codeStripped,
			crewTypeColor: workOrder.crewTypeColor,
			customer: workOrder.customer,
			jobTitle: workOrder.title,
			location: workOrder.address,
			projectManager: getFullName(projectManager),
			supervisor: getFullName(supervisor),
			timeToStart: workOrder.timeToStart,
			timeToEnd: workOrder.timeToEnd,
			workStart: workOrder.workStart,
			workEnd: workOrder.workEnd,
			resources,
			statusIconNotificationStatus: getStatusIconNotificationStatus(workOrder, workOrderResourceLookupsDictionary, notificationStatus),
			notes: workOrder.workOrderNotes,
			isPaused: workOrder.isPaused,
			jobStatusColor: workOrder.jobStatusColor,
			jobStatusName: workOrder.jobStatusName,
			isUnion: workOrder.isUnion,
			isPrevailingWage: workOrder.isPrevailingWage,
			projectJobCode: workOrder.projectJobCode,
		};
	});

	return {
		employeeNightShiftAssignments,
		temporaryEmployeeNightShiftAssignments,
		workOrders,
	};
}

const connector = connect(mapStateToProps);

export default connector(WorkOrderCards);
