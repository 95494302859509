import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import ResourceList from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceDroppable/ResourceList';

import { ResourceModalProps } from '../../Shared/ModalProps';

interface Props extends ResourceModalProps {
	droppableId: string;
	draggablePrefix: string; // to distinguish if it's board or toolbar dragged employee
	resourceIds: number[];
	isDisabled: boolean;
	className: string;
	startIndex: number;
	endIndex: number;
	/** `MM-DD-YYYY` */
	dueDate: string;
	isWorkOrderCanceled: boolean;
	isCopyPlaceholder: boolean;
	isCardDisabled: boolean;
	isCalculationsView: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	ignorePlaceholders: boolean;
	workOrderCode: string;
	isWOLocked: boolean;
}

const DroppableComponent: React.FC<Props> = (props: Props) => {
	const {
		className,
		draggablePrefix,
		droppableId,
		dueDate,
		endIndex,
		hasPermissionsToEditScheduleBoard,
		ignorePlaceholders,
		isCalculationsView,
		isCardDisabled,
		isCopyPlaceholder,
		isDisabled,
		isDragAndDropDisabled,
		isWorkOrderCanceled,
		resourceIds: _resourceIds,
		setEmployeeModalData,
		setEquipmentModalData,
		setTemporaryEmployeeModalData,
		setEmployeeModalVisibility,
		setEquipmentModalVisibility,
		startIndex,
		workOrderCode,
		isWOLocked,
	} = props;

	const renderDroppableBody = (resourceIds: number[]) => {
		return (
			<ResourceList
				draggablePrefix={draggablePrefix}
				droppableId={droppableId}
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				ignorePlaceholders={ignorePlaceholders}
				isCalculationsView={isCalculationsView}
				isCardDisabled={isCardDisabled}
				isCopyPlaceholder={isCopyPlaceholder}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isWOLocked={isWOLocked}
				isWorkOrderCanceled={isWorkOrderCanceled}
				resourceIds={resourceIds}
				setEmployeeModalData={setEmployeeModalData}
				setEmployeeModalVisibility={setEmployeeModalVisibility}
				setEquipmentModalData={setEquipmentModalData}
				setEquipmentModalVisibility={setEquipmentModalVisibility}
				setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
				startIndex={startIndex}
				workOrderCode={workOrderCode}
			/>
		);
	};

	const resourceIds = React.useMemo(
		() => _resourceIds.slice(startIndex, endIndex),
		[_resourceIds, startIndex, endIndex]
	);

	// react-beautiful-dnd can not create Draggable and Droppable elements/
	// while we are dragging so in order to mimic the copied card, we render
	// div instead of a Draggable (same for Droppable in parent component)
	if (isCopyPlaceholder || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard) {
		return (
			<div className={className}>
				{renderDroppableBody(resourceIds)}
			</div>
		);
	}

	return (
		<Droppable
			droppableId={droppableId}
			isDropDisabled={isDisabled}
		>
			{(provided, snapshot) => {
				return (
					<div
						className={`${className} ${snapshot.isDraggingOver ? 'drag-over' : ''}`}
						ref={provided.innerRef}
					>
						{renderDroppableBody(resourceIds)}
						{provided.placeholder}
					</div>
				);
			}}
		</Droppable>
	);
};

export default DroppableComponent;
