import * as React from 'react';

import LockedValue from 'af-components/LockedValue';

import { previewSectionClass, previewSectionClassColumn6, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';

type Props = {
	permitsRequired: Nullable<string[]>;
};

const _permitsMapper = (_permit: string, _i: number) => {
	return (
		<div className={previewSectionClassRow} key={_i}>
			<div className={previewSectionClassColumn6}>
				<LockedValue label={`Permit #${_i + 1}`} value={_permit} />
			</div>
		</div>
	);
};

const PermitsRequiredSection: React.FC<Props> = ({ permitsRequired }) => {
	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Permits Required
			</div>
			{permitsRequired?.map(_permitsMapper) ?? <b>-</b>}
		</div>
	);
};

export default React.memo(PermitsRequiredSection);
