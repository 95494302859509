import CDLStatus from 'acceligent-shared/enums/cdlStatus';
import { ColorPalette, ExtendedColorValue } from 'acceligent-shared/enums/color';
import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import { formatPhoneNumber } from 'acceligent-shared/utils/phone';
import { getUserName } from 'acceligent-shared/utils/user';

import Account from 'acceligent-shared/models/account';

import EmployeeNotificationStatusEnum from 'ab-enums/employeeNotificationStatus.enum';

interface ScheduleBoardResourceSkillVM {
	id: number;
	name: string;
	color: ColorPalette;
}

interface UserVM {
	id: number;
	fullName: string;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
}
class AccountVM {
	id: number;
	fullName: string;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	user: UserVM;

	constructor(account: Account) {
		this.id = account.id;
		this.fullName = getUserName(account.user);
		this.assignableToWorkOrder = account.assignableToWorkOrder;
		this.assignableAsSuperintendent = account.assignableAsSuperintendent;
		this.assignableAsProjectManager = account.assignableAsProjectManager;
		this.assignableAsQAQC = account.assignableAsQAQC;
		this.user = {
			id: account.user.id,
			fullName: getUserName(account.user),
			email: account.user.email,
			phoneNumber: account.user.phoneNumber ? formatPhoneNumber(account.user.phoneNumber) : null,
		} as UserVM;
	}
}

export interface DisplayViewEmployeeViewModel {
	employeeId: number;
	returnDate?: string;
	firstName: string;
	lastName: string;
	account: AccountVM;
	notificationStatus: Nullable<EmployeeNotificationStatusEnum>;
	numberOfAssignments: number;
	officeColor?: Nullable<ColorPalette>;
	skills: ScheduleBoardResourceSkillVM[];
	unavailabilityReason?: string;
	duplicateDisplayNameExists?: boolean;
	cdlStatus: Nullable<CDLStatus>;
	wasInNightShift?: boolean;
}

export interface DisplayViewEquipmentViewModel {
	code: string;
	color: Nullable<ColorPalette>;
	equipmentId: number;
	isAvailable: boolean;
	returnDate?: Nullable<string>;
	numberOfAssignments: number;
	specification: Nullable<string>;
	unavailabilityReason?: Nullable<string>;
}

export interface DisplayViewPlaceholderViewModel {
	placeholderId: number;
	displayName: string;
	skills?: ScheduleBoardResourceSkillVM[];
	categoryColor: Nullable<ColorPalette>;
}

export interface DisplayViewTemporaryEmployeeBaseVM {
	temporaryEmployeeId: number;
	firstName: string;
	lastName: string;
	accountId: number;
	uniqueId: string;
	numberOfAssignments: number;
	duplicateDisplayNameExists?: boolean;
	wasInNightShift?: boolean;
	officeColor: ColorPalette;
	notificationStatus: Nullable<EmployeeNotificationStatusEnum>;
}

export type DisplayViewResourceViewModel = DisplayViewEmployeeViewModel
	| DisplayViewEquipmentViewModel
	| DisplayViewPlaceholderViewModel
	| DisplayViewTemporaryEmployeeBaseVM;

export function isDisplayViewEmployeeViewModel(resource: DisplayViewResourceViewModel): resource is DisplayViewEmployeeViewModel {
	return (resource as DisplayViewEmployeeViewModel).employeeId !== undefined;
}

export function isDisplayViewEquipmentViewModel(resource: DisplayViewResourceViewModel): resource is DisplayViewEquipmentViewModel {
	return (resource as DisplayViewEquipmentViewModel).equipmentId !== undefined;
}

export function isDisplayViewPlaceholderViewModel(resource: DisplayViewResourceViewModel): resource is DisplayViewPlaceholderViewModel {
	return (resource as DisplayViewPlaceholderViewModel).placeholderId !== undefined;
}

export function isDisplayViewTemporaryEmployeeViewModel(resource: DisplayViewResourceViewModel): resource is DisplayViewTemporaryEmployeeBaseVM {
	return (resource as DisplayViewTemporaryEmployeeBaseVM).temporaryEmployeeId !== undefined;
}

export interface DisplayViewWorkOrderViewModel {
	index: number;
	id: number;
	dueDate: string;
	isInternal: boolean;
	status: WorkOrderStatusEnum;
	revision: string;
	customCrewType: Nullable<string>;
	codeStripped: string;
	crewTypeColor: Nullable<ExtendedColorValue>;
	customer: Nullable<string>;
	jobTitle: Nullable<string>;
	location: string;
	projectManager: string;
	supervisor: string;
	timeToStart: Nullable<number>;
	timeToEnd: Nullable<number>;
	workStart: string;
	workEnd: string;
	isNightShift: boolean;
	notes: Nullable<string>;
	statusIconNotificationStatus: Nullable<NotificationStatusEnum>;
	resources: DisplayViewResourceViewModel[];
	isPaused: boolean;
	jobStatusColor: Nullable<ColorPalette>;
	jobStatusName: Nullable<string>;
	isUnion: boolean;
	isPrevailingWage: boolean;
	projectJobCode: Nullable<string>;
}
