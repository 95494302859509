import * as React from 'react';

import { WORK_SUMMARY_DETAILS_TABLE_HEADERS } from '../values';

import { CellWidthsMap } from 'af-utils/react.util';

import { bemBlock } from 'ab-utils/bem.util';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	additionalStyling?: { [modifier: string]: boolean; };
	cellClassName: string;
	cellTextClassName: string;
	cellWidthsMap: CellWidthsMap;
	displayValue: string | JSX.Element;
	header: WORK_SUMMARY_DETAILS_TABLE_HEADERS | string;
	tooltipMessage?: string;
}

type Props = OwnProps;

const _resolveStyleForHeader = (
	header: WORK_SUMMARY_DETAILS_TABLE_HEADERS | string,
	cellWidths: CellWidthsMap
) => {
	if (!cellWidths?.[header]) {
		return {};
	}
	return {
		minWidth: cellWidths[header].width,
		maxWidth: cellWidths[header].width,
	};
};

const _renderContent = (displayValue: string | JSX.Element, tooltipMessage?: string) => {
	if (tooltipMessage) {
		return (
			<Tooltip message={tooltipMessage}>
				{displayValue}
			</Tooltip>
		);
	}
	return displayValue;
};

const WorkSummaryDetailsTableCell: React.FC<Props> = (props) => {
	const { cellClassName, cellTextClassName, cellWidthsMap, displayValue, header, additionalStyling, tooltipMessage } = props;

	return (
		<span
			className={bemBlock(cellClassName, additionalStyling)}
			style={_resolveStyleForHeader(header, cellWidthsMap)}
		>
			<span className={cellTextClassName}>
				{_renderContent(displayValue, tooltipMessage)}
			</span>
		</span>
	);
};

export default React.memo(WorkSummaryDetailsTableCell);
