import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';

import * as AccountActions from 'af-actions/accounts';

import { PreviewVM } from 'ab-viewModels/account/preview.viewModel';

import Dropdown from 'af-components/Controls/Dropdown';
import Pills from 'af-components/Pills';

type OwnProps = WrappedFieldArrayProps<PreviewVM>;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	accounts: PreviewVM[];
	options: PreviewVM[];
}

class Accounts extends React.PureComponent<Props, State> {
	state: State = {
		accounts: [],
		options: [],
	};

	static renderOption = (option: PreviewVM) => <span>{option.fullName} ({option.uniqueId})</span>;

	static filterOption = (option: PreviewVM, rawInput: string) => {
		const { fullName, uniqueId } = option;

		return `${fullName} ${uniqueId}`.toLowerCase().includes(rawInput.toLowerCase());
	};

	static filterOptions = (
		allOptions: PreviewVM[],
		selectedAccounts: PreviewVM[] = []
	) => {

		const reduceAccounts = (_acc: Record<number, true>, _account: PreviewVM) => {
			_acc[_account.id] = true;
			return _acc;
		};
		const accounts = selectedAccounts.reduce(reduceAccounts, {});
		return allOptions.filter((_account) => !accounts[_account.id]);
	};

	async componentDidMount() {
		const { findAllAccountsForCompany } = this.props;
		const accounts = await findAllAccountsForCompany();
		this.setState(() => ({ accounts }), this.setOptions);
	}

	setOptions = () => {
		const { fields } = this.props;
		this.setState((state) => ({ options: Accounts.filterOptions(state.accounts, fields.getAll()) }));
	};

	removeAccount = (index: number) => {
		const { fields } = this.props;
		fields.remove(index);
		this.setOptions();
	};

	onChange = (option: PreviewVM) => {
		const { fields } = this.props;
		fields.push(option);
		this.setOptions();
	};

	modalBody = (original: PreviewVM) => (
		<>
			You are about to remove <b>{original.fullName}</b> from this user group.
			Are you sure you want to continue?
		</>
	);

	render() {
		const { fields } = this.props;
		const { options } = this.state;

		return (
			<>
				<div>
					<Dropdown<PreviewVM>
						filterable={true}
						filterBy={Accounts.filterOption}
						forcePlaceholder={true}
						isWhite={true}
						onValueChange={this.onChange}
						options={options}
						placeholder="Select employees"
						renderMenuItem={Accounts.renderOption}
						valueKey="id"
						withBorder={true}
						withCaret={true}
					/>
				</div>
				<Pills
					destructiveAction={this.removeAccount}
					emptyLabel="No users selected"
					items={fields}
					labelKey="fullName"
					modalBody={this.modalBody}
					modalTitle="Remove User"
				/>
			</>
		);
	}
}

function mapDispatchToProps() {
	return {
		findAllAccountsForCompany: AccountActions.findAllForCompanyPreview,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(Accounts);
