import * as React from 'react';

import DirectoriesAttachments from 'af-components/SharedForms/Job/Details/Attachments/DirectoriesAttachments';

import { formSectionClass, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../helpers';

type Props = {
	workRequestId?: number;
	change: (field: string, value: unknown) => void;
};

const AttachmentsSection: React.FC<Props> = ({ change, workRequestId }) => {
	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Attachments
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					{workRequestId
						? (
							<DirectoriesAttachments change={change} jobId={workRequestId} />
						)
						: 'In order to attach files, work request has to be saved first.'
					}
				</div>
			</div>
		</div>
	);
};

export default React.memo(AttachmentsSection);
