import * as React from 'react';

import TimeSheetTime from '../TimeSheetRow/TimeSheetTime';
import TimeSheetNote from '../TimeSheetRow/TimeSheetNote';

import styles from './styles.module.scss';

interface OwnProps {
	endTime: Nullable<string>;
	startTime: Nullable<string>;
	totalTime: number;
	note: Nullable<string>;
	showNoteModal: () => void;
	isTimeSheetReadonly: boolean;
}

type Props = OwnProps;

const OriginalShiftTime: React.FC<Props> = (props) => {
	const {
		endTime,
		startTime,
		totalTime,
		note,
		showNoteModal,
		isTimeSheetReadonly,
	} = props;

	return (
		<div className={styles['original-time-container']}>
			<div className={styles['original-time-header']}>
				ORIGINAL SHIFT TIME
			</div>
			<TimeSheetTime
				endTime={endTime}
				startTime={startTime}
				totalTime={totalTime}
			/>
			{note &&
				<TimeSheetNote
					note={note}
					openNoteModal={showNoteModal}
					showNoteActionButton={!isTimeSheetReadonly}
				/>
			}
		</div>
	);
};

export default OriginalShiftTime;
