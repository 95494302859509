import { isValidTextInput } from 'acceligent-shared/utils/text';

import FormModel from './formModel';

export function validate(values: FormModel) {
	const errors: ValidationErrors = {};

	if (!values.tool) {
		errors.tool = 'Tool is required';
	}

	if (!values.reportedIssue || !isValidTextInput(values.reportedIssue)) {
		errors.reportedIssue = 'Reported issue is required';
	}

	if (!values.location) {
		errors.location = 'Location is required';
	}

	if (!values.superintendent) {
		errors.superintendent = 'Superintendent is required';
	}

	if (!values.operator) {
		errors.operator = 'Operator is required';
	}

	if (values.isExternal) {
		if (!values.repairLocation) {
			errors.repairLocation = 'Repair location is required';
		}
		if (!values.carrier) {
			errors.carrier = 'Carrier is required';
		}
	}

	return errors;
}
