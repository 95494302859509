import * as React from 'react';

import LoadingIndicator from 'af-components/LoadingIndicator';
import CustomModal from 'af-components/CustomModal';

export interface OwnProps {
	isOpen: boolean;
	reason: string;
	onEntered?: () => void;
}

type Props = OwnProps;

export default class BoardLockedModal extends React.PureComponent<Props> {

	render() {
		const { isOpen, onEntered, reason } = this.props;

		return (
			<CustomModal
				closeModal={undefined}
				modalStyle="warning"
				onEntered={onEntered}
				showModal={isOpen}
				size="sm"
			>
				<CustomModal.Header
					title={reason}
				>
					<LoadingIndicator color="white" />
				</CustomModal.Header>
				<CustomModal.Body>
					<p>
						The schedule board is temporary locked
						and will unlock once the action is finished.
					</p>
					<p>
						This might take a few minutes.
						Please <b>DO NOT</b> leave or refresh the page
						as that will cancel the action in progress.
					</p>
				</CustomModal.Body>
			</CustomModal>
		);
	}
}
