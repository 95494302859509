import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

class Loading extends React.PureComponent {

	render() {
		return (
			<div className="form-box loading-box">
				<Row>
					<Col className="loading-box-radio" sm={4} />
					<Col className="loading-box-row" sm={20} />
				</Row>
				<Row>
					<Col className="loading-box-radio" sm={4} />
					<Col className="loading-box-row" sm={20} />
				</Row>
				<Row>
					<Col className="loading-box-radio standalone" sm={4} />
					<Col className="loading-box-row standalone" sm={20} />
				</Row>
			</div>
		);
	}
}

export default Loading;
