import * as React from 'react';

import { previewSectionClass, previewSectionClassColumn2, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';
import LockedValue from 'af-components/LockedValue';

type Props = {
	requestMBEOrWBERequirementsNote: Nullable<string>;
	requestBondRequirementsNote: Nullable<string>;
	requestHSERequirementsNote: Nullable<string>;
	retainage: Nullable<string>;
	requestInsuranceRequirementsNote: Nullable<string>;
	requestLiquidatedDamagesNote: Nullable<string>;
};

const RequirementsSection: React.FC<Props> = (props) => {
	const {
		requestBondRequirementsNote,
		requestHSERequirementsNote,
		requestInsuranceRequirementsNote,
		requestLiquidatedDamagesNote,
		requestMBEOrWBERequirementsNote,
		retainage,
	} = props;
	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Requirements
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue
						label="MBE/WBE Requirements"
						value={requestMBEOrWBERequirementsNote}
					/>
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue
						label="Bond Requirements"
						value={requestBondRequirementsNote}
					/>
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue
						label="HSE Requirements"
						value={requestHSERequirementsNote}
					/>
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue
						label="Retainage"
						value={retainage}
					/>
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue
						label="Insurance Requirements"
						value={requestInsuranceRequirementsNote}
					/>
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue
						label="Liquidated Damages"
						value={requestLiquidatedDamagesNote}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(RequirementsSection);
