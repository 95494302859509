import * as React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot, DraggableElement } from 'react-beautiful-dnd';
import { connect } from 'react-redux';

import { RootState } from 'af-reducers';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import DraggablePlaceholder from './Draggable';

interface OwnProps {
	draggableId: string;
	droppableId: string;
	dueDate: string;
	hasPermissionsToEditScheduleBoard: boolean;
	index: number;
	isCardDisabled?: boolean;
	isCopyPlaceholder?: boolean;
	isDragAndDropDisabled: boolean;
	isToolbar?: boolean;
	isWorkOrderCanceled?: boolean;
	resourceId?: number;
	workOrderPlaceholderId: number;
}

interface StateProps {
	isDisabled: boolean;
}

type Props = OwnProps & StateProps;

interface DraggableProps extends Props {
	provided?: DraggableProvided;
	snapshot?: DraggableStateSnapshot;
}

class PlaceholderDraggable extends React.PureComponent<Props> {

	renderDraggable = (props: DraggableProps) => {
		const {
			dueDate,
			hasPermissionsToEditScheduleBoard,
			isDragAndDropDisabled,
			isWorkOrderCanceled,
			provided = {} as DraggableProvided,
			resourceId,
			snapshot = {} as DraggableStateSnapshot,
			workOrderPlaceholderId,
		} = props;

		if (!resourceId) {
			throw new Error('Resource ID not provided');
		}

		return (
			<DraggablePlaceholder
				draggableProps={provided.draggableProps}
				draggingOver={snapshot.draggingOver}
				dragHandleProps={provided.dragHandleProps ?? undefined}
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				innerRef={provided.innerRef}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isDragging={snapshot.isDragging}
				isDropAnimating={snapshot.isDropAnimating}
				isWorkOrderCanceled={isWorkOrderCanceled}
				resourceId={resourceId}
				workOrderPlaceholderId={workOrderPlaceholderId}
			/>
		);
	};

	render() {
		const {
			draggableId,
			hasPermissionsToEditScheduleBoard,
			index,
			isCopyPlaceholder,
			isDisabled,
			isDragAndDropDisabled,
			isWorkOrderCanceled,
		} = this.props;

		// react-beautiful-dnd can not create Draggable and Droppable elements/
		// while we are dragging so in order to mimic the copied card, we render
		// div instead of a Draggable (same for Droppable in parent component)
		if (isCopyPlaceholder || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard) {
			return (
				<div>
					{this.renderDraggable(this.props)}
				</div>
			);
		}

		return (
			<Draggable
				draggableId={draggableId}
				index={index}
				isDragDisabled={isDisabled || isWorkOrderCanceled}
			>
				{(provided, snapshot) => React.createElement(this.renderDraggable, { ...this.props, provided, snapshot }) as DraggableElement<DraggableProps>}
			</Draggable>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { isToolbar, isCardDisabled, dueDate } = ownProps;
	const { weeklyViewDateWithToolbar, scheduleBoardView } = state.scheduleBoard;

	const disableDraggingWhenToolbarOpened = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW &&
		(!weeklyViewDateWithToolbar || weeklyViewDateWithToolbar !== dueDate);
	let isDisabled = false;

	if (!isToolbar) {
		isDisabled = !!isCardDisabled || disableDraggingWhenToolbarOpened;
	}
	return {
		isDisabled,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(PlaceholderDraggable);
