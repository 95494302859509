import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

import Tooltip from 'af-components/Tooltip';

import { useDraggableColumns, CellWidthsMap as WorkSummaryCellWidthsMap } from 'af-utils/react.util';

import { WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS, WORK_SUMMARY_DETAILS_TABLE_HEADERS, WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_HEADERS } from '../values';

const RESIZER_WIDTH = 8;

type Groupable = {
	workSummaryGroup: Nullable<string>;
};

interface Props<T extends Groupable> {
	data: T[];
	headers: string[];
	useGroups: boolean;
	sort: (a: T, b: T) => number;
	getGroupKey: (item: T) => string;
	renderRow: (data: T, cellWidths: WorkSummaryCellWidthsMap) => JSX.Element;
	highlightedGroupKey: Nullable<string>;
}

const WorkSummaryTable = <T extends Groupable,>(props: Props<T>) => {
	const { data, headers, renderRow, sort, getGroupKey, useGroups, highlightedGroupKey } = props;

	const { cellWidthsMap, columnRefs, onColumnDrag } = useDraggableColumns({});

	const sortedData = React.useMemo(() => [...data].sort(sort), [data, sort]);

	const groupByReducer = React.useCallback((acc: Record<number, T[]>, current: T) => {
		const key = getGroupKey(current);
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push(current);
		return acc;
	}, [getGroupKey]);

	const groupedData = React.useMemo(() => {
		return sortedData.reduce<Record<number, T[]>>(groupByReducer, {});
	}, [groupByReducer, sortedData]);

	const renderRowWithCells = React.useCallback((item: T) => renderRow(item, cellWidthsMap), [renderRow, cellWidthsMap]);

	const renderGroupedRow = React.useCallback(([groupKey, workSummaryData]: [string, T[]]) => {
		return (
			<div className={bemElement('field-report__work-summary', 'table', { grouped: true })} key={groupKey}>
				{workSummaryData.map((item: T, index) => {
					const showSeperator = (
						item.workSummaryGroup !== workSummaryData[index + 1]?.workSummaryGroup &&
						item !== workSummaryData[workSummaryData.length - 1]
					);
					const showConnector = highlightedGroupKey === groupKey;

					return (
						<React.Fragment key={`${groupKey}-${index}`}>
							{renderRow(item, cellWidthsMap)}
							{showSeperator && <div className={bemElement('field-report__work-summary__table__row', 'seperator', { connect: showConnector })} />}
						</React.Fragment >
					);
				})}
			</div>
		);
	}, [cellWidthsMap, highlightedGroupKey, renderRow]);

	const _setHeaderReference = React.useCallback((headerId: string) => (_ref) => columnRefs.current[headerId] = _ref, [columnRefs]);

	const renderTableHeader = React.useCallback((header: string) => {
		const isRecentlyUsed =
			header === (WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.RECENTLY_USED || WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_HEADERS.RECENTLY_USED);

		const isShortCell = (
			header === WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.QUANTITY ||
			header === WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.UNIT ||
			header === WORK_SUMMARY_DETAILS_TABLE_HEADERS.UNIT ||
			header === WORK_SUMMARY_DETAILS_TABLE_HEADERS.QUANTITY ||
			header === WORK_SUMMARY_DETAILS_TABLE_HEADERS.BILLING_CODE_ID ||
			header === WORK_SUMMARY_DETAILS_TABLE_HEADERS.BILLING_CODE_DESCRIPTION
		);
		const isLongCell = (
			header === WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.CHARGE_RATE ||
			header === WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.DESCRIPTION ||
			header === WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_HEADERS.BILLING_CODE ||
			header === WORK_SUMMARY_DETAILS_TABLE_HEADERS.WORK_ORDER
		);

		const width = cellWidthsMap[header] ? (cellWidthsMap[header].width - RESIZER_WIDTH) : undefined;

		return (
			<React.Fragment key={header}>

				<span
					className={bemElement('field-report__work-summary__table__headers', 'cell', { short: isShortCell, long: isLongCell, 'recently-used': isRecentlyUsed })}
					ref={_setHeaderReference(header)}
					style={{ minWidth: width, maxWidth: width }}
				>
					<Tooltip message={header}>
						{isRecentlyUsed ? '' : header}
					</Tooltip>
				</span>
				<span
					className={bemElement('field-report__work-summary__table__headers', 'resizer')}
					onMouseDown={onColumnDrag(header)}
				/>

			</React.Fragment >
		);
	}, [_setHeaderReference, cellWidthsMap, onColumnDrag]);

	return (
		<div className={bemElement('field-report__work-summary', 'table-container')}>
			<div className={bemElement('field-report__work-summary', 'table')}>
				<div className={bemElement('field-report__work-summary__table', 'headers')}>
					{headers.map(renderTableHeader)}
				</div>
				{
					useGroups
						? Object.entries(groupedData).map(renderGroupedRow)
						: sortedData.map(renderRowWithCells)
				}
			</div>
		</div>
	);
};

export default WorkSummaryTable;
