import * as React from 'react';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	lockedBy: Nullable<string>;
	showModal: boolean;
	closeModal: () => void;
	goBackToWorkOrders: () => void;
}

type Props = OwnProps;

declare const location: Location;

class UserKickedModal extends React.PureComponent<Props> {

	reload = () => location.reload();

	render() {
		const { showModal, closeModal, goBackToWorkOrders, lockedBy } = this.props;

		return (
			<CustomModal
				closeModal={closeModal}
				modalStyle="warning"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					title={`${lockedBy} Took Over Editing`}
				/>
				<CustomModal.Body>
					Please click "Refresh" to keep working on calculations.
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button onClick={goBackToWorkOrders} variant="info">Back to Schedule Board</Button>
					<Button onClick={this.reload} variant="primary">Refresh</Button>
				</CustomModal.Footer>
			</CustomModal>
		);
	}

}

export default UserKickedModal;
