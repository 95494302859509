import * as React from 'react';
import { HandleThunkActionCreator } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Button } from 'react-bootstrap';

import * as CompanyActions from 'af-actions/companies';

import { DefaultNotificationTimesForm, DefaultNotificationTimes } from '../formModel';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { DAILY_NOTIFICATIONS_SCHEDULE } from 'af-constants/reduxForms';

import NotificationTimesForm from './Form';

interface OwnProps {
	isVisible: boolean;
	toggleEdit: () => void;
	editDefaultNotificationSendTimes: HandleThunkActionCreator<typeof CompanyActions.editDefaultNotificationSendTimes>;
}

type Props = OwnProps & InjectedFormProps<DefaultNotificationTimesForm, OwnProps>;

class NotificationTimesEdit extends React.PureComponent<Props> {

	editNotificationTimes = async (form: DefaultNotificationTimesForm) => {
		const { toggleEdit, editDefaultNotificationSendTimes } = this.props;

		await editDefaultNotificationSendTimes(DefaultNotificationTimes.fromForm(form));
		toggleEdit();
	};

	onClose = () => {
		const { toggleEdit, reset } = this.props;
		reset();
		toggleEdit();
	};

	render() {
		const { handleSubmit, isVisible, invalid, submitting } = this.props;

		return (
			<CustomModal
				closeModal={this.onClose}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={this.onClose}
					title="Edit Default Notification Times"
				/>
				<CustomModal.Body padding="none">
					<NotificationTimesForm
						{...this.props}
						onSubmit={this.editNotificationTimes}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						id="accqa__notification-settings__default-times__cancel"
						onClick={this.onClose}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={invalid}
						id="accqa__notification-settings__default-times__save"
						label="Save"
						onClick={handleSubmit(this.editNotificationTimes)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default reduxForm<DefaultNotificationTimesForm, OwnProps>({ form: DAILY_NOTIFICATIONS_SCHEDULE, enableReinitialize: true })(NotificationTimesEdit);
