import * as React from 'react';

import { AccountViewModel } from 'ab-viewModels/account.viewModel';

interface Props {
	option: AccountViewModel;
}

const DropdownAccountItem: React.FC<Props> = (props: Props) => {
	const { option } = props;

	if (!option) {
		return (<div className="clear-option" />);
	}
	const { firstName, lastName, uniqueId } = option;

	return <span>{`${firstName} ${lastName} (${uniqueId ?? ''})`}</span>;
};

export default React.memo(DropdownAccountItem);
