import * as React from 'react';
import { compose } from 'redux';
import { ConnectedProps, connect } from 'react-redux';

import * as DivisionActions from 'af-actions/division';
import * as EmployeeActions from 'af-actions/employee';
import * as JobStatusActions from 'af-actions/jobStatus';
import * as LocationActions from 'af-actions/location';
import * as SaleActions from 'af-actions/sale';

import AttachmentsSection from './Sections/AttachmentsSection';
import ContactsSection from './Sections/Contacts';
import DeliverableSection from './Sections/DeliverableSection';
import DisposalSection from './Sections/DisposalSection';
import JobDetailSection from './Sections/JobDetailSection';
import JobSummarySection from './Sections/JobSummarySection';
import RequierementsSection from './Sections/RequierementsSection';
import SalesSection from './Sections/SalesSection';
import ScheduleInfo from './Sections/ScheduleInfo';
import SummaryOfWorkSection from './Sections/SummaryOfWorkSection';
import SurveyDataSection from './Sections/SurveyDataSection';
import TrucksAndEquipmentSection from './Sections/TrucksAndEquipment';
import WorkLocationSection from './Sections/WorkLocation';

import styles from '../styles.module.scss';

type OwnProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
	workRequestId?: string;
	formName: string;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const DetailsFormTab: React.FC<Props> = (props) => {
	const {
		change,
		findSales,
		findJobStatuses,
		findAllProjectManagers,
		findAllLocations,
		findAllDivisions,
		findAllSuperintendentsAndProjectManagers,
		workRequestId,
		formName,
	} = props;

	return (
		<div className={styles['work-request-form__tab-content']}>
			<SalesSection findSales={findSales} />
			<JobSummarySection
				findAllDivisions={findAllDivisions}
				findAllLocations={findAllLocations}
				findAllProjectManagers={findAllProjectManagers}
				findJobStatuses={findJobStatuses}
			/>
			<JobDetailSection />
			<RequierementsSection />
			<ScheduleInfo change={change} formName={formName} />
			<ContactsSection formName={formName} />
			<DeliverableSection change={change} formName={formName} />
			<WorkLocationSection formName={formName} />
			<SummaryOfWorkSection />
			<SurveyDataSection findAllSuperintendentsAndProjectManagers={findAllSuperintendentsAndProjectManagers} />
			<TrucksAndEquipmentSection />
			<DisposalSection change={change} />
			<AttachmentsSection change={change} workRequestId={workRequestId} />
		</div >
	);
};

function mapDispatchToProps() {
	return {
		findSales: SaleActions.findAllForForm,
		findJobStatuses: JobStatusActions.findList,
		findAllProjectManagers: EmployeeActions.findAllProjectManagers,
		findAllLocations: LocationActions.findList,
		findAllDivisions: DivisionActions.findAllForCompany,
		findAllSuperintendentsAndProjectManagers: EmployeeActions.findAllSuperintendentsAndProjectManagers,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(DetailsFormTab);
