import * as React from 'react';

import { TimeSheetEntryHistoryItemVM } from 'ab-viewModels/timeSheet/timeSheetEntryHistory.viewModel';

import { bemElement } from 'ab-utils/bem.util';

import { useToggle } from 'af-utils/react.util';

import TimeSheetEntryHistoryInformationItem from './TimeSheetEntryHistoryInformationItem';

interface OwnProps {
	items: Nullable<TimeSheetEntryHistoryItemVM[]>;
}

type Props = OwnProps;

const renderItem = (entry: TimeSheetEntryHistoryItemVM) => {
	return (
		<div
			className="timeline-history-modal__deleted-row"
			key={entry.id}
		>
			<TimeSheetEntryHistoryInformationItem
				createdAt={entry.createdAt}
				dataFilledBy={entry.dataFilledBy}
				deletedAt={entry.deletedAt}
				deletedBy={entry.deletedBy}
				endTime={entry.endTime}
				hasParents={!!entry.parentEntries?.length}
				startTime={entry.startTime}
				type={entry.type}
				workType={entry.workType}
			/>
			<TimeSheetEntryHistoryInformationItem
				createdAt={entry.createdAt}
				dataFilledBy={entry.dataFilledBy}
				deletedAt={null}
				deletedBy={entry.deletedBy}
				endTime={entry.endTime}
				hasParents={!!entry.parentEntries?.length}
				startTime={entry.startTime}
				type={entry.type}
				workType={entry.workType}
			/>
		</div>
	);
};

const TimeSheetEntryHistoryDeletedSection: React.FC<Props> = (props: Props) => {
	const { items } = props;

	const {
		value: isExpanded,
		toggle: toggleExpand,
	} = useToggle(false);

	if (!items?.length) {
		return null;
	}

	return (
		<>
			<div className={bemElement('timeline-history-modal', 'section', ['deleted'])}>
				<div className="timeline-history-modal__section-title">DELETED ENTRIES</div>
				<div>
					<span className={`timeline-history-modal__expander-icon ${isExpanded ? 'icon-up' : 'icon-down'}`} onClick={toggleExpand}/>
				</div>
			</div>
			{isExpanded &&
				<div className="timeline-history-modal__information">
					{items.map(renderItem)}
				</div>
			}
		</>
	);
};

export default React.memo(TimeSheetEntryHistoryDeletedSection);
