import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import * as TimeUtils from 'acceligent-shared/utils/time';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import DateInput from 'af-fields/DateInput';
import Input from 'af-fields/Input';

import { RootState } from 'af-reducers';

import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';

import { formSectionClass, formSectionClassColumn2, formSectionClassRow, formSectionTitleClass } from '../../helpers';

type OwnProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
	formName: string;
	isDisabled: boolean;
};

type Props = OwnProps & ConnectedProps<typeof connector>;
const ScheduleInfoSection: React.FC<Props> = (props) => {
	const { targetStartDate, targetCompletionDate, startDate, guaranteedCompletionDate, change, isDisabled } = props;

	const handleTargetStartDateChange = React.useCallback((value: Date) => {
		const dateString = TimeUtils.formatDate(value, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME);

		const daysFromTargetCompletionDate = (value && targetCompletionDate) ? TimeUtils.getDiff(targetCompletionDate, dateString, 'days', TimeFormat.DATE_ONLY) : null;
		change(getAttributeName('targetDaysFromStart'), daysFromTargetCompletionDate);
	}, [change, targetCompletionDate]);

	const handleTargetCompletionDate = React.useCallback((value: Date) => {
		const dateMoment = TimeUtils.parseMoment(value);

		const daysFromTargetCompletionDate = (value && targetStartDate) ? TimeUtils.daysBetween(targetStartDate, dateMoment, TimeFormat.DATE_ONLY) : null;
		change(getAttributeName('targetDaysFromStart'), daysFromTargetCompletionDate);
	}, [change, targetStartDate]);

	const handleTargetDaysFromStartChange = React.useCallback((targetDaysFromStartChange: number) => {
		const completionDate = TimeUtils.addDays(targetStartDate, targetDaysFromStartChange, TimeFormat.DATE_ONLY);
		change(getAttributeName('targetCompletionDate'), TimeUtils.formatDate(completionDate, TimeFormat.DATE_ONLY, TimeFormat.DATE_ONLY));
	}, [change, targetStartDate]);

	const handleActualStartDateChange = React.useCallback((value: Date) => {
		const dateString = TimeUtils.formatDate(value, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME);

		const daysFromActualStartDate = (value && guaranteedCompletionDate)
			? TimeUtils.daysBetween(dateString, guaranteedCompletionDate, TimeFormat.DATE_ONLY)
			: null;

		change(getAttributeName('guaranteedDaysFromStart'), daysFromActualStartDate);
	}, [change, guaranteedCompletionDate]);

	const handleGuaranteedCompletionDateChange = React.useCallback((value: Date) => {
		const dateString = TimeUtils.formatDate(value, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME);

		const daysFromActualStartDate = (value && startDate) ? TimeUtils.daysBetween(startDate, dateString, TimeFormat.DATE_ONLY) : null;
		change(getAttributeName('guaranteedDaysFromStart'), daysFromActualStartDate);
	}, [startDate, change]);

	const handleGuaranteedDaysFromStartChange = React.useCallback((targetDaysFromStartChange: number) => {
		const completionDate = TimeUtils.addDays(startDate, targetDaysFromStartChange, TimeFormat.DATE_ONLY);
		change(getAttributeName('guaranteedCompletionDate'), TimeUtils.formatDate(completionDate, TimeFormat.DATE_ONLY, TimeFormat.DATE_ONLY));
	}, [startDate, change]);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Schedule Info
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={DateInput}
						disabled={isDisabled}
						id="startDate"
						label="Target Start Date"
						name={getAttributeName('targetStartDate')}
						onValueChange={handleTargetStartDateChange}
						originalDateFormat={TimeFormat.DATE_ONLY}
						placeholderText={TimeFormat.DATE_ONLY}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={DateInput}
						disabled={isDisabled}
						id="startDate"
						label="Target Completion Date"
						name={getAttributeName('targetCompletionDate')}
						onValueChange={handleTargetCompletionDate}
						originalDateFormat={TimeFormat.DATE_ONLY}
						placeholderText={TimeFormat.DATE_ONLY}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="targetDaysFromStart"
						label="Days from Start"
						name={getAttributeName('targetDaysFromStart')}
						onValueChange={handleTargetDaysFromStartChange}
						type="number"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={DateInput}
						disabled={isDisabled}
						id="startDate"
						label="Actual Start Date"
						name={getAttributeName('startDate')}
						onValueChange={handleActualStartDateChange}
						originalDateFormat={TimeFormat.DATE_ONLY}
						placeholderText={TimeFormat.DATE_ONLY}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={DateInput}
						disabled={isDisabled}
						id="guaranteedCompletionDate"
						label="Guaranteed Completion Date"
						name={getAttributeName('guaranteedCompletionDate')}
						onValueChange={handleGuaranteedCompletionDateChange}
						originalDateFormat={TimeFormat.DATE_ONLY}
						placeholderText={TimeFormat.DATE_ONLY}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="guaranteedDaysFromStart"
						label="Days from Start"
						name={getAttributeName('guaranteedDaysFromStart')}
						onValueChange={handleGuaranteedDaysFromStartChange}
						type="number"
					/>
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState, props: OwnProps) {
	const selector = formValueSelector(props.formName);

	const {
		targetStartDate,
		targetCompletionDate,
		startDate,
		guaranteedCompletionDate,
	} = selector(
		state,
		getAttributeName('targetStartDate'),
		getAttributeName('targetCompletionDate'),
		getAttributeName('startDate'),
		getAttributeName('guaranteedCompletionDate')
	);

	return {
		targetStartDate,
		targetCompletionDate,
		startDate,
		guaranteedCompletionDate,
	};
}

const connector = connect(mapStateToProps);

export default connector(ScheduleInfoSection);
