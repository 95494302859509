import * as React from 'react';
import { Button } from 'react-bootstrap';

import { bemElement } from 'ab-utils/bem.util';

import SignButton from './SignButton';

interface OwnProps {
	disableStartEndValidation: boolean;
	showRejectButton: boolean;
	showSignButton: boolean;
	onRejectButtonClick: () => void;
	onSignButtonClick: () => void;
	endTime: Nullable<string>;
	startTime: Nullable<string>;
	hasOverlaps: boolean;
}

const TimeSheetApprovalActions = (props: OwnProps) => {
	const { disableStartEndValidation, showSignButton, showRejectButton, onRejectButtonClick, endTime, startTime, onSignButtonClick, hasOverlaps } = props;

	const approvalActionsClassName = bemElement('time-sheet-list', 'row__info__approval-actions');

	return (
		<div className={approvalActionsClassName}>
			{showRejectButton &&
				<Button onClick={onRejectButtonClick} variant="danger">
					Reject
				</Button>
			}
			{showSignButton &&
				<SignButton
					disableStartEndValidation={disableStartEndValidation}
					endTime={endTime}
					hasOverlaps={hasOverlaps}
					showSignatureModal={onSignButtonClick}
					startTime={startTime}
				/>
			}
		</div>
	);
};

export default TimeSheetApprovalActions;
