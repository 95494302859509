import * as React from 'react';
import { Modal } from 'react-bootstrap';

interface OwnProps {
	className?: string;
	padding?: 'none' | 'vertical' | 'horizontal' | 'all' | 'all-but-top';
	margin?: 'none' | 'top' | 'double-top';
}

type Props = React.PropsWithChildren<OwnProps>;

const Body: React.FC<Props> = ({ className, children, padding = 'all', margin = 'none' }: Props) => {
	if (!children) {
		return null;
	}

	let cn = `modal-body--padded-${padding} modal-body--margin-${margin}`;
	cn = className ? `${cn} ${className}` : cn;
	cn = className ? `${cn} ${className}` : cn;

	return (
		<Modal.Body className={cn}>
			{children}
		</Modal.Body>
	);
};

export default React.memo(Body);
