import * as React from 'react';

import CDLStatus from 'acceligent-shared/enums/cdlStatus';

import { DisplayViewEmployeeViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';
import ScheduleBoardEmployeeViewModel from 'ab-viewModels/scheduleBoardEmployee.viewModel';

import * as ColorUtils from 'ab-utils/color.util';

import EmployeeDraggableBadgesContainer from '../../../Shared/EmployeeDraggable/BadgesContainer';
import MultiAssignmentsBadge from '../../../Shared/MultiAssignmentsBadge';
import NightShiftBadge from '../../../Shared/NightShiftBadge';

import EmployeeAdditionalInfo from './EmployeeAdditionalInfo';

interface OwnProps {
	employee: DisplayViewEmployeeViewModel;
	hideMultipleAssignmentBadges: boolean;
	wasInNightShift: boolean;
}

type Props = OwnProps;

const AvailableEmployeeItem: React.FC<Props> = (props: Props) => {
	const { employee, hideMultipleAssignmentBadges, wasInNightShift } = props;
	const { notificationStatus, numberOfAssignments, officeColor, cdlStatus } = employee;
	const { firstName, lastName } = ScheduleBoardEmployeeViewModel.getDisplayData(employee as unknown as ScheduleBoardEmployeeViewModel);
	const noCDL = cdlStatus === CDLStatus.NO_CDL;

	const showMultipleAssignmentsBadge = numberOfAssignments > 1 && !hideMultipleAssignmentBadges;
	const showNightShiftBadge = !!employee.wasInNightShift || wasInNightShift;

	let className = 'sb-resource-item sb-resource-item--employee';
	className = noCDL ? `${className} sb-resource-item--no-cdl` : className;

	return (
		<div className={className}>
			{notificationStatus && <div className={`icon-notification_status ${notificationStatus.toLowerCase()}`} />}
			<div className="sb-resource-item__content">
				<span className={`sb-resource-item__full-name ${ColorUtils.getColorTextClass(officeColor, true)}`}>
					{firstName} <span className="sb-resource-item__last-name">{lastName}</span>
				</span>
				<EmployeeAdditionalInfo employee={employee} />
			</div>
			<EmployeeDraggableBadgesContainer>
				{showMultipleAssignmentsBadge && <MultiAssignmentsBadge count={numberOfAssignments} disableAutoPositioning />}
				{showNightShiftBadge && <NightShiftBadge />}
			</EmployeeDraggableBadgesContainer>
		</div>
	);
};

export default React.memo(AvailableEmployeeItem);
