import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import SegmentLabel from 'af-components/SegmentLabel';

class Dashboard extends React.Component {

	render() {
		return (
			<div className="company-dashboard">
				<div className="form-segment">
					<div className="form-box form-box--standalone">
						<Row>
							<Col sm={24}>
								<SegmentLabel label="Company Dashboard" />
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

export default Dashboard;
