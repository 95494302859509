import * as React from 'react';

interface OwnProps {
	message: string;
	reportTypes: Nullable<string[]>;
}

const ReportBlockActionConfirmationBody = ({ reportTypes, message }: OwnProps) => (
	<>
		<div>
			{message}
		</div>
		<div className="row--padded-top bold">
			{reportTypes?.map((_name, _index) => <div key={_index}>{_name}</div>)}
		</div>
	</>
);

export default React.memo(ReportBlockActionConfirmationBody);
