export const SCROLL_TO_ROW_ID = 'scroll-to-row';

export const DEFAULT_REACT_TABLE_PROPS = {
	manual: true,
	filterable: false,
	showPagination: true,
	previousText: '',
	nextText: '',
	rowsText: '',
	minRows: 0,
	className: '-highlight',
	pageSizeOptions: [5, 10, 20, 25, 50, 100, 500],
};
