import * as React from 'react';
import { nanoid } from 'nanoid';

import { EmailTypes, PhoneTypes, EmailTypeNames } from 'acceligent-shared/enums/contactMethodType';

import { UNIQUE_ID_SIZE } from 'ab-constants/value';

import CopyValue from './Shared/CopyValue';

interface OwnProps {
	isNew?: boolean;
	showCopyToClipboard?: boolean;
	type: EmailTypes | PhoneTypes;
	value: string;
}

type Props = OwnProps;

interface State {
	/** html tag id used for copying value to clipboard */
	id: string;
}

class ContactEmailPreview extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		showCopyToClipboard: false,
		isNew: false,
	};

	state: State = {
		id: nanoid(UNIQUE_ID_SIZE),
	};

	render() {
		const { value, showCopyToClipboard, isNew, type } = this.props;
		const { id } = this.state;
		return (
			<div className="contact-preview__method" id={id}>
				<span className="label label-default contact-preview__method-type">{EmailTypeNames[type]}</span>
				<span className="contact-preview__method-value">{value}</span>
				{showCopyToClipboard && <CopyValue parentId={id} value={value} />}
				{isNew && <span className="contact-preview__new">NEW</span>}
			</div>
		);
	}
}

export default ContactEmailPreview;
