import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FileRejection, useDropzone } from 'react-dropzone';

import { ALLOWED_FILE_TYPES_LIST, ALLOWED_FILE_TYPES_MIME } from 'acceligent-shared/enums/fileType';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import DirectoryNames from 'ab-enums/directoryNames.enum';
import { NotificationSnackbarTypes } from 'ab-enums/notificationSnackbarContext.enum';
import { fromFileType } from 'ab-enums/mimeType.enum';

import * as AttachmentActions from 'af-actions/attachment';

import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import AttachmentTableRowComponent from './AttachmentTableRowComponent';

import styles from './styles.module.scss';

import { AttachmentEntity, DirectoryEntity } from '../types';

function sortNamedEntity(e1: { name: string; }, e2: { name: string; }) {
	return e1.name.localeCompare(e2.name);
}

const ROOT_DIRECTORY_ICONS: { [name in DirectoryNames]: string; } = {
	[DirectoryNames.JOB_EHS]: 'icon-dir-job-ehs',
	[DirectoryNames.JOB_INFO]: 'icon-dir-job-info',
	[DirectoryNames.WAGE_INFO]: 'icon-dir-wage-info',
	[DirectoryNames.WORK_ORDER_EHS]: 'icon-dir-wo-ehs',
	[DirectoryNames.WORK_ORDER_INFO]: 'icon-dir-wo-info',
};

interface NotificationData {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

interface RowActionOptions {
	actionName: string;
	actionFunction: (original: DirectoryEntity | AttachmentEntity) => void;
	disabled?: boolean;
	show?: boolean;
}

interface RowActions {
	parentDirectory: RowActionOptions[];
	childDirectory: RowActionOptions[];
	attachmentLevel: RowActionOptions[];
}

interface DispatchProps {
	uploadAttachmentsToDirectory: typeof AttachmentActions.uploadAttachmentsToDirectory;
}

interface TypedColumn {
	header: string;
	accessor?: string;
	id?: string;
	className?: string;
	width?: number;
}

interface DirectoryAttachmentCellProps {
	directory: DirectoryEntity;
	level: number;
	columns: TypedColumn[];
	fetchData?: () => void;
	toggleRowVisibility: (arg0: number[]) => void;
	onFileUpload?: (directoryId: number, files: File[]) => void;
	visibleRows?: number[];
	id: number;
	rowActions?: RowActions;
	onCopyToWOChange?: (attachmentId: number, copyToWorkOrder: boolean) => void;
	setDirIndex: (arg0: number) => void;
	mainIndex: number;
}

type Props = DirectoryAttachmentCellProps & ResolveThunks<DispatchProps>;

const DirectoryAttachmentTableRowConnected = connect<null, DispatchProps>(null, mapDispatchToProps())(DirectoryAttachmentTableRow);

function DirectoryAttachmentTableRow(props: Props) {
	const {
		directory,
		level,
		columns,
		uploadAttachmentsToDirectory,
		onFileUpload,
		fetchData,
		toggleRowVisibility,
		visibleRows,
		id,
		rowActions,
		onCopyToWOChange,
		setDirIndex,
		mainIndex,
	} = props;
	const inputRef = React.useRef<HTMLInputElement>(null);
	const notificationSnackbar = useNotificationSnackbar();
	const currentNotificationList = React.useRef<NotificationData[]>(notificationSnackbar.notifications);

	const settingsActionsClassName = 'settings-actions';
	const dropdownToggle = `${settingsActionsClassName}__dropdown-toggle`;
	const childDirectoryNameFlexLevel = 6 - level * 0.5;

	const hasAttachedToWOColumn: boolean = columns.some((col) => col.id === 'attachedToWO');
	const hasCopyToWOColumn: boolean = columns.some((col) => col.id === 'copyToWO');

	const upload = React.useCallback(() => {
		inputRef.current?.click();
	}, []);

	React.useEffect(() => {
		currentNotificationList.current = notificationSnackbar.notifications;
	}, [notificationSnackbar.notifications]);

	const uploadDroppedAttachment = React.useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
		setDirIndex(-1);
		setChildDirColor(false);

		if (fileRejections.length > 0) {
			let message = `Uploading of ${fileRejections[0].file.name} failed.`;
			if (fileRejections[0].errors[0].code === 'file-invalid-type') {
				message = 'Uploading failed - File type not supported';
			}
			notificationSnackbar.error(message, new Date());
			return;
		}

		if (onFileUpload) {
			setDirIndex(-1);
			onFileUpload(directory.id, acceptedFiles);
			setChildDirColor(false);
			return;
		}

		const notification: Nullable<NotificationData> = notificationSnackbar.loading(`Uploading ${acceptedFiles[0].name}`, new Date());

		try {
			await uploadAttachmentsToDirectory(directory.id, { requestFiles: acceptedFiles });

			if (notification) {
				notificationSnackbar.removeNotificationSnackbar(notification);
				const notificationText = `${acceptedFiles[0].name} has successfully been uploaded to ${directory.name} `;
				notificationSnackbar.success(notificationText, new Date());
			}
			fetchData?.();
		} catch ({ errors }) {
			if (errors?.files) {
				if (notification) {
					notificationSnackbar.removeNotificationSnackbar(notification);
					notificationSnackbar.error('Upload failed. Please try again.', new Date());
				}
			}
		}
	}, [directory.id, directory.name, fetchData, notificationSnackbar, onFileUpload, setDirIndex, uploadAttachmentsToDirectory]);

	const handleFileUpload = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
		const filesObj = event.target.files;
		const listOfFiles: { requestFiles: File[]; } = { requestFiles: [] };

		if (!filesObj) {
			return;
		}

		Array.from(filesObj).map((_file) => {
			listOfFiles.requestFiles.push(_file);
		});

		// reset file input
		event.target.value = '';

		uploadDroppedAttachment(listOfFiles.requestFiles, []);
	}, [uploadDroppedAttachment]);

	const onClickHandler = (rowAction: (arg0: DirectoryEntity) => void) => {
		rowAction(directory);
	};

	const toggleExpand = React.useCallback(() => {
		if (!directory.directories.length && !directory.attachments.length) {
			return;
		}
		toggleRowVisibility([directory.id]);
	}, [directory.attachments.length, directory.directories.length, directory.id, toggleRowVisibility]);

	const renderExpander = React.useCallback(() => {
		return <div className={visibleRows?.includes(directory.id) ? 'icon-collapse' : 'icon-expand'} onClick={toggleExpand} />;
	}, [directory.id, toggleExpand, visibleRows]);

	const resolvedDirectoryIcon = React.useMemo(() => {
		if (level === 0) {
			if (directory.name in ROOT_DIRECTORY_ICONS) {
				return ROOT_DIRECTORY_ICONS[directory.name];
			}
		}
		return directory.directories.length || directory.attachments.length ? 'icon-folder_open' : 'icon-folder_closed';
	}, [directory.attachments.length, directory.directories.length, directory.name, level]);

	const sortedChildDirectories = React.useMemo(() => [...directory.directories].sort(sortNamedEntity), [directory.directories]);

	const sortedAttachments = React.useMemo(() => [...directory.attachments].sort(sortNamedEntity), [directory.attachments]);

	const allowedFileTypes = React.useMemo(() => [ALLOWED_FILE_TYPES_LIST.map(fromFileType)].join(', '), []);

	const [childDirColor, setChildDirColor] = React.useState(false);

	// uploadDroppedAttachment has to be defined before using useDropzone
	const { getRootProps, getInputProps } = useDropzone({
		accept: ALLOWED_FILE_TYPES_MIME,
		noClick: true,
		onDrop: uploadDroppedAttachment,
		onDragLeave: () => { setDirIndex(-1); setChildDirColor(false); },
		onDragOver: () => { setDirIndex(mainIndex); setChildDirColor(true); },
	});

	return (
		<React.Fragment key={`${level}#${directory.id}`}>
			<section >
				<div {...getRootProps()}>
					<input {...getInputProps()} />
					<div className={`${styles['tree-table-row']} ${childDirColor ? styles['child-color'] : ''}`}
						onDoubleClick={toggleExpand}
					>
						<div style={{ flex: level * 0.5 }} />
						<div className={`${styles['expand-icon-cell']}`} id="clickable">
							{renderExpander()}
						</div>
						<div className={`${styles['name-icon']}`} style={{ flex: childDirectoryNameFlexLevel }}>
							<div className={`${styles['directory-icon']}`}>
								<span className={resolvedDirectoryIcon}>
									<span className="path1" />
									<span className="path2" />
								</span>
							</div>
							{directory.name}
						</div>
						<div className={`${styles['cell-flex-1']}`} >
							{directory.numberOfFiles !== 0 ? directory.numberOfFiles : '-'}
						</div>
						<div className={`${styles['cell-flex-1']}`} />
						<div className={`${styles['cell-flex-1']}`} >
							<div className={`${styles['upload-icon']}`} >
								<div className="icon-upload" onClick={upload} />
								<input
									accept={allowedFileTypes}
									onChange={handleFileUpload}
									ref={inputRef}
									style={{ display: 'none' }}
									type="file"
								/>
							</div>
						</div>
						<div className={`${styles['cell-flex-1']}`} />

						{hasAttachedToWOColumn && <div className={`${styles['cell-flex-3']}`} />}
						{hasCopyToWOColumn && <div className={`${styles['cell-flex-2']}`} />}

						<div className={`${styles['cell-flex-2']}`}>
							<div>
								<LastUpdatedByCell
									updatedAt={TimeUtils.formatDate(directory.lastModifiedAt, TimeFormat.FULL_DATE)}
									updatedBy={directory.lastModifiedBy ?? null}
								/>
							</div>
						</div>
						<div className={`${styles['cell-flex-1']}`}>
							<Dropdown >
								<Dropdown.Toggle className={`${styles[dropdownToggle]}`}>
									<span className="icon-actions icon" />
								</Dropdown.Toggle>
								<Dropdown.Menu >
									{level === 0 ? rowActions?.parentDirectory?.map((_rowAction) => {
										return (
											<Dropdown.Item
												disabled={_rowAction.disabled}
												key={`${directory.id}_${level}_${_rowAction.actionName}`}
												onClick={onClickHandler.bind(this, _rowAction.actionFunction)}
												open={false}
											>
												{_rowAction.actionName}
											</Dropdown.Item>
										);
									}) :
										rowActions?.childDirectory?.map((_rowAction) => {
											return (
												<Dropdown.Item
													// TODO: Add restriction on backend side
													disabled={!!_rowAction.disabled || (_rowAction.actionName === 'New folder' && level > 1)}
													key={`${directory.id}_${_rowAction.actionName}_${level}`}
													onClick={onClickHandler.bind(this, _rowAction.actionFunction)}
													open={false}
												>
													{_rowAction.actionName}
												</Dropdown.Item>
											);
										})
									}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</div>
			</section>
			{
				sortedChildDirectories.map((_childDir) => {
					return (
						<div className={`${styles['tree-child']}`}
							key={`${level}#${_childDir.id}`}
							style={{
								display: visibleRows?.includes(directory.id) ? undefined : 'none',
							}}
						>
							<DirectoryAttachmentTableRowConnected
								columns={columns}
								directory={_childDir}
								fetchData={fetchData}
								id={id}
								level={level + 1}
								mainIndex={mainIndex}
								onCopyToWOChange={onCopyToWOChange}
								onFileUpload={onFileUpload}
								rowActions={rowActions}
								setDirIndex={setDirIndex}
								toggleRowVisibility={toggleRowVisibility}
								visibleRows={visibleRows}
							/>
						</div>
					);
				})
			}
			{
				sortedAttachments.map((_attachment) => {
					return (
						<React.Fragment key={`${_attachment.id}`}>
							<AttachmentTableRowComponent
								attachment={_attachment}
								attachmentRowActions={rowActions?.attachmentLevel}
								columns={columns}
								directoryId={directory.id}
								fetchData={fetchData}
								id={id}
								level={level}
								onCopyToWOChange={onCopyToWOChange}
								visibleRows={visibleRows}
							/>
						</React.Fragment>
					);
				})
			}
		</React.Fragment >
	);
}

function mapDispatchToProps(): DispatchProps {
	return {
		uploadAttachmentsToDirectory: AttachmentActions.uploadAttachmentsToDirectory,
	};
}

export default DirectoryAttachmentTableRowConnected;
