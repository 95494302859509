interface CreateTemporaryEmployeeRM {
	temporaryEmployee: {
		firstName: string;
		lastName: string;
		agencyId: number;
	};
}

interface TemporaryEmployeeAssignmentRM {
	temporaryEmployeeId: number;
}
interface CreateTemporaryEmployeeAssignmentBaseRM {
	index?: number;
}

type CreateTemporaryEmployeeAssignmentWithNewEmployee = CreateTemporaryEmployeeAssignmentBaseRM & CreateTemporaryEmployeeRM;
type CreateTemporaryEmployeeAssignmentWithExistingEmployee = CreateTemporaryEmployeeAssignmentBaseRM & TemporaryEmployeeAssignmentRM;

type CreateTemporaryEmployeeAssignmentRM = CreateTemporaryEmployeeAssignmentWithNewEmployee | CreateTemporaryEmployeeAssignmentWithExistingEmployee;

export function shouldCreateEmployee(request: CreateTemporaryEmployeeAssignmentRM): request is CreateTemporaryEmployeeAssignmentWithNewEmployee {
	return (request as CreateTemporaryEmployeeAssignmentWithNewEmployee).temporaryEmployee !== undefined;
}

export default CreateTemporaryEmployeeAssignmentRM;

export type CreateReqBody = {
	name: string;
};
