import * as React from 'react';
import { FieldArray } from 'redux-form';

import JobUpsertFM from '../../../formModel';
import { formSectionClass, formSectionTitleClass } from '../../../helpers';

import Permits from './Permits';

const PermitsRequiredSection: React.FC = () => {
	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Permits Required
			</div>
			<FieldArray<void>
				component={Permits}
				name={JobUpsertFM.getAttributeName('permitsRequired')}
			/>
		</div>
	);
};

export default PermitsRequiredSection;
