import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { DragDropContext, DropResult, DragStart } from 'react-beautiful-dnd';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as CompanyActions from 'af-actions/companies';
import * as EquipmentActions from 'af-actions/equipment';

import SocketEvent from 'ab-enums/socketEvent.enum';

import EquipmentDownModal, { EquipmentDownForm } from 'af-root/scenes/Company/ScheduleBoard/Shared/EquipmentDownModal';

import { EquipmentDownRequestModel } from 'ab-requestModels/equipmentDown.requestModel';
import ScheduleBoardDragRequestModel, { ScheduleBoardDragEquipmentRequestModel } from 'ab-requestModels/scheduleBoardDrag.requestModel';
import DownEquipmentRM from 'ab-requestModels/equipment/downEquipment.requestModel';

import SocketConnectionCountViewModelModel from 'ab-viewModels/socketConnectionCount.viewModel';

import { debounce } from 'af-utils/actions.util';
import * as MechanicViewUtil from 'af-utils/mechanicView.util';
import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';
import socket from 'af-utils/socket.util';

import { MECHANIC_VIEW_DEFAULT_ID } from 'ab-constants/scheduleBoard';

import Body from './Body';
import Header from './Header';
import Loading from './Loading';
import { UnavailableEquipmentStatusViewModel } from 'ab-viewModels/equipmentStatus.viewModel';

const LOAD_RESOURCES_DELAY = 200;

const RELOAD_EVENTS = [
	SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_EQUIPMENT_DOWN_DETAILS,
	SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_TOOLBAR_EQUIPMENT,
	SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS,
	SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_UNAVAILABILITY_REASON,
	SocketEvent.V2.BE.SCHEDULE_BOARD.CHANGE_RETURN_DATE,
	SocketEvent.V2.BE.SCHEDULE_BOARD.CLEAR_UNAVAILABILITY_REASON,
	SocketEvent.V2.BE.SCHEDULE_BOARD.COPY_MULTIPLE_WORK_ORDER,
	SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_EQUIPMENT_ASSIGNMENT,
	SocketEvent.V2.BE.SCHEDULE_BOARD.DELETE_WORK_ORDER,
	SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD,
	SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD_RESOURCES,
	SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_ALL_EQUIPMENT_ASSIGNMENT_FROM_DICT,
	SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_ALL_EQUIPMENT_ASSIGNMENT_ON_DRAFTS,
	SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_EQUIPMENT_ASSIGNMENT,
	SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_MULTIPLE_TOOLBAR_EQUIPMENT,
	SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_TOOLBAR_EQUIPMENT,
	SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS,
	SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_EQUIPMENT,
	SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_SCHEDULE_BOARD_DROPPABLE_LIST,
	SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_WORK_ORDER,
] as const;

interface StateProps {
	zoomLevel: number;
}

interface DispatchProps {
	downEquipment: typeof EquipmentActions.downEquipment;
	getCompany: typeof CompanyActions.getCompany;
	getSocketConnectionCount: typeof ScheduleBoardActions.getSocketConnectionCount;
	loadAllAvailableForMechanicView: typeof ScheduleBoardActions.loadAllAvailableForMechanicView;
	loadAllUnavailableForMechanicView: typeof ScheduleBoardActions.loadAllUnavailableForMechanicView;
}

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	date: string;
	draggableId: Nullable<string>;
	equipmentForUpdate: Nullable<EquipmentDownRequestModel>;
	isLoading: boolean;
	showEquipmentDownModal: boolean;
	unavailableEquipmentStatuses: UnavailableEquipmentStatusViewModel[];
}

class MechanicView extends React.PureComponent<Props, State> {
	state: State = {
		date: TimeUtils.getTodaysDate(),
		draggableId: null,
		equipmentForUpdate: null,
		isLoading: true,
		showEquipmentDownModal: false,
		unavailableEquipmentStatuses: [],
	};
	_unregisterDayChangeHandler: Nullable<() => void> = null;

	async componentDidMount() {
		const { getCompany, getSocketConnectionCount } = this.props;
		const { date } = this.state;

		RELOAD_EVENTS.forEach((_evt) => socket.connection?.subscribe(_evt, this.loadResourcesDebounced, true));

		socket.connection?.subscribe(
			SocketEvent.V2.BE.SCHEDULE_BOARD.DAILY_VIEW_CONNECTIONS_COUNT,
			(dueDate: string, data: SocketConnectionCountViewModelModel) => {
				if (dueDate === date) {
					getSocketConnectionCount(data);
				}
			}
		);

		ScheduleBoardUtil.joinDailyView(date, {});

		await Promise.all([
			getCompany(),
			this.loadData(),
		]);

		this.setState(() => ({ isLoading: false }));

		this.setupDayChangeHandler();
	}

	async componentDidUpdate(prevProps: Props, prevState: State) {
		const { date } = this.state;

		if (prevState.date !== date && socket.connection?.isConnected()) {
			ScheduleBoardUtil.leaveBoard();
			ScheduleBoardUtil.joinDailyView(date, {});
			await this.loadData();
		}
	}

	componentWillUnmount() {
		if (socket.connection) {
			ScheduleBoardUtil.leaveBoard();
			socket.connection.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.DAILY_VIEW_CONNECTIONS_COUNT);

			RELOAD_EVENTS.forEach((_evt) => socket.connection?.removeHandler(_evt, this.loadResourcesDebounced));
		}

		this._unregisterDayChangeHandler?.();
	}

	setupDayChangeHandler = () => {
		this._unregisterDayChangeHandler = TimeUtils.onDayChange(() => this.setState(
			() => ({ date: TimeUtils.getTodaysDate() }),
			this.setupDayChangeHandler
		));
	};

	loadData = async () => {
		const {
			loadAllAvailableForMechanicView,
			loadAllUnavailableForMechanicView,
		} = this.props;

		const today = TimeUtils.getTodaysDate(TimeFormat.DB_DATE_ONLY);

		await Promise.all([
			loadAllAvailableForMechanicView(today),
			loadAllUnavailableForMechanicView(today),
		]);
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	loadResourcesDebounced = debounce(this.loadData, LOAD_RESOURCES_DELAY);

	setEquipmentAvailable = (draggableId: string) => {
		const { date } = this.state;

		const source = MechanicViewUtil.parseDraggable(draggableId, date, false);
		const destination = MechanicViewUtil.generateDroppableId(MECHANIC_VIEW_DEFAULT_ID, date, true);

		const dragElement = {
			...source,
			destinationDroppableId: destination,
			destinationIndex: 0,
			isCopying: false,
		} as ScheduleBoardDragEquipmentRequestModel;

		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EQUIPMENT_DRAG_END, dragElement);
	};

	handleSubmitEquipmentDownModal = async (form: DownEquipmentRM) => {
		const { downEquipment } = this.props;
		const { draggableId, date } = this.state;

		const equipmentId = draggableId
			? MechanicViewUtil.parseDraggable(draggableId, date, false)?.itemId as number
			: form?.id;

		if (!equipmentId) {
			return;
		}

		// Important to keep a consistent route on all downing actions
		form.dueDate = TimeUtils.formatDate(date, TimeFormat.DATE_ONLY);
		form.currentDate = TimeUtils.formatDate(new Date(), TimeFormat.DATE_ONLY);

		await downEquipment(equipmentId, form);

		this.setState(() => ({ showEquipmentDownModal: false, draggableId: null, equipmentForUpdate: null }));
	};

	handleCloseEquipmentDownModal = () => {
		this.setState(() => ({ showEquipmentDownModal: false, draggableId: null, equipmentForUpdate: null }));
	};

	handleOnDragStart = (dragData: DragStart) => {
		const { date } = this.state;
		const { draggableId, source: { droppableId } } = dragData;

		const source = MechanicViewUtil.parseDraggable(draggableId, date, droppableId === MechanicViewUtil.AVAILABLE_CONTAINER_ID);
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EQUIPMENT_DRAG_START, source);
	};

	handleOnDragEnd = (result: DropResult) => {
		const { date } = this.state;
		const { destination, draggableId, source: { droppableId: sourceId } } = result;

		if (destination && destination.droppableId !== sourceId) {
			if (destination.droppableId === MechanicViewUtil.UNAVAILABLE_CONTAINER_ID) {
				this.setState(() => ({ showEquipmentDownModal: true, draggableId }));
			} else {
				this.setEquipmentAvailable(draggableId);
			}
		} else {
			const source = MechanicViewUtil.parseDraggable(draggableId, date, sourceId === MechanicViewUtil.AVAILABLE_CONTAINER_ID);
			const dragElement: ScheduleBoardDragRequestModel = {
				...source,
				destinationDroppableId: source.sourceDroppableId,
				destinationIndex: source.sourceIndex,
				isCopying: false,
			};

			const emptyDownData = {
				returnDate: null,
				currentDate: null,
				downNotes: null,
				priority: null,
				statusId: null,
				unavailabilityReason: null,
			};
			socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EQUIPMENT_DRAG_END, { ...emptyDownData, ...dragElement });
		}
	};

	onEquipmentDownEdit = (equipment: EquipmentDownRequestModel) => this.setState(() => ({ equipmentForUpdate: equipment, showEquipmentDownModal: true }));

	render() {
		const { zoomLevel } = this.props;

		const {
			equipmentForUpdate,
			isLoading,
			showEquipmentDownModal,
		} = this.state;

		return (
			<div className={`mechanic-view zoom-${zoomLevel}`}>
				<Header />
				<DragDropContext
					onDragEnd={this.handleOnDragEnd}
					onDragStart={this.handleOnDragStart}
				>
					{isLoading ? <Loading /> : <Body onEquipmentDownEdit={this.onEquipmentDownEdit} />}
				</DragDropContext>
				<EquipmentDownModal
					initialFormValues={EquipmentDownForm.toForm(equipmentForUpdate)}
					onClose={this.handleCloseEquipmentDownModal}
					onSubmit={this.handleSubmitEquipmentDownModal}
					showModal={showEquipmentDownModal}
					showStatusOption={true}
					useTodayDate={true}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { zoomLevel } = state.scheduleBoard;

	return { zoomLevel };
}

const mapDispatchToProps = {
	downEquipment: EquipmentActions.downEquipment,
	getCompany: CompanyActions.getCompany,
	getSocketConnectionCount: ScheduleBoardActions.getSocketConnectionCount,
	loadAllAvailableForMechanicView: ScheduleBoardActions.loadAllAvailableForMechanicView,
	loadAllUnavailableForMechanicView: ScheduleBoardActions.loadAllUnavailableForMechanicView,
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(MechanicView);
