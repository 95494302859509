import * as React from 'react';
import { Button } from 'react-bootstrap';

import Tooltip from 'af-components/Tooltip';

import { bemBlock } from 'ab-utils/bem.util';

import { ButtonData } from '../../types';

interface OwnProps {
	icon: ButtonData['icon'];
	label: string;
	onClick: ButtonData['onClick'];
	showSmallButtons: boolean;
	id?: string;
}

const LinkButton = ({ showSmallButtons, label, icon, onClick, id }: OwnProps) => {
	const showIcon = showSmallButtons && !!icon;
	const linkButtonClass = bemBlock('btn', { link: true, icon: showIcon });

	return (
		<Tooltip message={label} placement="bottom">
			<Button className={linkButtonClass} id={id} onClick={onClick} >
				{icon ? <span className={`icon-${icon}`} /> : null}
				{showIcon ? null : label}
			</Button>
		</Tooltip>
	);
};

export default React.memo(LinkButton);
