import * as React from 'react';
import { Modal } from 'react-bootstrap';

interface OwnProps {
	className?: string;
	title?: string | null;
	closeModal?: () => void;
}

type Props = React.PropsWithChildren<OwnProps>;

const Header: React.FC<Props> = ({ className, title, closeModal, children = null }: Props) => {
	return (
		<Modal.Header className={className}>
			{!!title
				? <Modal.Title>{title}</Modal.Title>
				: <div className="modal-title--filler">{children}</div>
			}
			{closeModal &&
				<button className="modal-header__close" onClick={closeModal}>
					<span className="icon-close" />
				</button>
			}
		</Modal.Header>
	);
};

export default React.memo(Header);
