import * as React from 'react';
import { FormAction } from 'redux-form';

import Radio, { OwnProps as RadioProps } from 'af-components/Controls/Radio';

interface Props extends RadioProps {
	field: string;
	changeField: (field: string, value: string | number | undefined | null) => FormAction | void;
}

class RadioField extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		disabled: false,
		inline: true,
		className: '',
	};

	onCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { field, value, onCheck, changeField } = this.props;

		changeField(field, value);

		if (onCheck) {
			onCheck(event);
		}
	};

	render() {
		const {
			disabled,
			inline,
			label,
			value,
			checked,
			className,
			tooltipMessage,
		} = this.props;

		return (
			<Radio
				checked={checked}
				className={className}
				disabled={disabled}
				inline={inline}
				label={label}
				onCheck={this.onCheck}
				tooltipMessage={tooltipMessage}
				value={value}
			/>
		);
	}
}

export default RadioField;
