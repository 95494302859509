import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { ResolveThunks, connect } from 'react-redux';

import { RootState } from 'af-reducers';

import DeliverableFormDetails from 'af-components/DeliverableFormDetails';
import DeliverableSubmissionForm from 'af-components/SharedForms/Deliverables/Submission';
import DeliverableSubmissionFM from 'af-components/SharedForms/Deliverables/Submission/formModel';
import CanceledRibbon from 'af-components/CanceledRibbon';
import Breadcrumbs from 'af-components/Breadcrumbs';

import CLIENT from 'af-constants/routes/client';

import * as DeliverableActions from 'af-actions/deliverable';

import { DeliverableSubmissionViewModel, DeliverableViewModel } from 'ab-viewModels/deliverable.viewModel';
import { DeliverableComment } from 'ab-viewModels/deliverableTable.viewModel';

import * as SettingsUtils from 'af-utils/settings.util';

interface PathParams {
	deliverableId: string;
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	editSubmission: typeof DeliverableActions.updateDeliverableSubmission;
	findSubmission: typeof DeliverableActions.findSubmissionById;
	findDeliverable: typeof DeliverableActions.findById;
	createComment: typeof DeliverableActions.createSubmissionComment;
	findComments: typeof DeliverableActions.findDeliverableSubmissionComments;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	deliverable: DeliverableViewModel;
	submission: DeliverableSubmissionViewModel;
	comments: DeliverableComment[];
}

class DeliverableSubmission extends React.Component<Props, State> {
	state: State = {
		deliverable: {} as DeliverableViewModel,
		submission: {} as DeliverableSubmissionViewModel,
		comments: [],
	};

	async componentDidMount() {
		const { match: { params: { deliverableId, id } }, findDeliverable, findSubmission, findComments } = this.props;
		const [deliverable, submission, comments] = await Promise.all([findDeliverable(+deliverableId), findSubmission(+id), findComments(+id)]);
		SettingsUtils.setExpandedDeliverableIds(deliverableId, id);
		this.setState(() => ({ deliverable, submission, comments }));
	}

	loadComments = async () => {
		const { findComments, match: { params: { id } } } = this.props;
		const comments = await findComments(+id);
		this.setState(() => ({ comments }));
	};

	onSubmit = (form: DeliverableSubmissionFM) => {
		const { editSubmission, companyName, location: { state: { orgAlias } } } = this.props;
		const rm = DeliverableSubmissionFM.toRM(form);
		editSubmission(rm, orgAlias, companyName);
	};

	onBack = () => {
		const { history, companyName, location: { state: { orgAlias } } } = this.props;
		history.push(CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName));
	};

	createComment = async (comment: string) => {
		const { createComment, match: { params: { id } } } = this.props;
		await createComment({ comment, deliverableSubmissionId: +id });
		await this.loadComments();
	};

	render() {
		const {
			location: { state: { orgAlias } },
			companyName,
		} = this.props;
		const { deliverable, submission, comments } = this.state;

		return (
			<div className="form-segment deliverable-form">
				<Breadcrumbs
					items={[
						{ label: 'Deliverables', url: CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName) },
						{ label: 'Edit Deliverable Submission' },
					]}
				/>
				<DeliverableFormDetails
					deliveryMethod={deliverable.deliveryMethod}
					deliveryTimeline={deliverable.deliveryTimeline}
					dueDate={submission.dueDate}
					endDate={deliverable.endDate}
					jobCode={deliverable.jobCode}
					startDate={deliverable.startDate}
					submissionCode={submission.submissionCode}
					workOrderCode={submission.workOrderCode}
				/>
				{submission.isCanceled &&
					<CanceledRibbon
						cancellationReason={submission.cancellationReason ?? ''}
						updatedAt={submission.canceledAt}
						updatedBy={submission.canceledBy}
					/>
				}
				<DeliverableSubmissionForm
					comments={comments}
					createComment={this.createComment}
					initialValues={DeliverableSubmissionFM.fromVM(submission)}
					isDisabled={submission.isCanceled}
					onBack={this.onBack}
					onSubmit={this.onSubmit}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: RootState): StateProps => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

function mapDispatchToProps(): DispatchProps {
	return {
		findComments: DeliverableActions.findDeliverableSubmissionComments,
		createComment: DeliverableActions.createSubmissionComment,
		editSubmission: DeliverableActions.updateDeliverableSubmission,
		findSubmission: DeliverableActions.findSubmissionById,
		findDeliverable: DeliverableActions.findById,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(DeliverableSubmission);
