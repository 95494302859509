import { SCREEN_BREAKPOINT_XXS, SCREEN_BREAKPOINT_S } from 'af-constants/values';

enum TableTypeEnum {
	DEFAULT = 'DEFAULT',
	DEFAULT_SMALL = 'DEFAULT_SMALL',
	SCROLLABLE = 'SCROLLABLE',
}

export function getTableType(width: number): TableTypeEnum {
	if (width < SCREEN_BREAKPOINT_XXS) {
		return TableTypeEnum.SCROLLABLE;
	} else if (width < SCREEN_BREAKPOINT_S) {
		return TableTypeEnum.DEFAULT_SMALL;
	} else {
		return TableTypeEnum.DEFAULT;
	}
}

export default TableTypeEnum;
