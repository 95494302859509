import * as React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';

import CLIENT from 'af-constants/routes/client';

const Error403: React.FC = () => {

	const location = useLocation();
	const orgAlias = location.state.orgAlias;

	return (
		<div className="form-segment form-segment--mini">
			<div className="form-box form-box--standalone form-box--padded">
				<div className="form-box__icon-container">
					<span className="form-box__icon icon-key" />
				</div>
				<h2>Unauthorized access.</h2>
				<h4>
					You do not have permission to perform this action. Please contact your administrator to adjust your account settings and permissions.
				</h4>
				<div className="form-box__footer">
					<Link className="btn btn-primary" to={CLIENT.HOME(orgAlias)}>Back to Home</Link>
				</div>
			</div>
		</div>
	);

};

export default Error403;
