import * as React from 'react';
import { FieldArray } from 'redux-form';

import { formSectionClass, formSectionTitleClass } from '../../../helpers';
import WorkRequestUpsertFM from '../../../formModel';

import Permits from './Permits';

const PermitsRequiredSection: React.FC = () => {
	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Permits Required
			</div>
			<FieldArray<void>
				component={Permits}
				name={WorkRequestUpsertFM.getAttributeName('permitsRequired')}
			/>
		</div>
	);
};

export default PermitsRequiredSection;
