import * as React from 'react';

import styles from './styles.module.scss';

interface Props {
	path: string;
	label: string;
	labelClassName?: string;
}

const LinkCell: React.FC<Props> = ({ label, path, labelClassName }) => {

	const onRedirect = React.useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();
		window.open(path, '_blank');
	}, [path]);

	return (
		<div className={styles['link-cell']}>
			<span className="icon-external" onClick={onRedirect} />
			<b className={labelClassName}>{label}</b>
		</div>
	);
};

export default React.memo(LinkCell);
