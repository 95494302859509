import * as React from 'react';

interface OwnProps {
	count: number;
	disableAutoPositioning?: boolean;
	isOpaque?: boolean;
}

type Props = OwnProps;

const MultiAssignmentsBadge: React.FC<Props> = (props: Props) => {
	const { count, disableAutoPositioning, isOpaque } = props;

	let className = 'multi-assignment';
	className = isOpaque ? `${className} multi-assignment--opaque` : className;
	className = !disableAutoPositioning ? `${className} multi-assignment--auto-position` : className;

	return <div className={className}>{count}</div>;
};

export default React.memo(MultiAssignmentsBadge);
