import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

import { bemBlock } from 'ab-utils/bem.util';

import { ButtonData } from '../../types';

interface OwnProps {
	icon: ButtonData['icon'];
	label: string | undefined;
	onClick: ButtonData['onClick'];
	id?: string;
}

const LinkButton = ({ label, icon, onClick, id }: OwnProps) => {
	const linkButtonClass = bemBlock('btn', { link: true });

	return (
		<Tooltip message={label} placement="bottom">
			<a className={linkButtonClass} id={id} onClick={onClick} >
				{icon ? <span className={icon} /> : null}
				{label}
			</a>
		</Tooltip>
	);
};

export default React.memo(LinkButton);
