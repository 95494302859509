import { isValidFormattedPhoneNumber } from 'acceligent-shared/utils/phone';
import { isValidTextInput } from 'acceligent-shared/utils/text';

import { EMAIL_REGEX } from 'acceligent-shared/constants/regex';

import SaleRequestModel from 'ab-requestModels/sale.requestModel';

import { isValidNumber } from 'ab-utils/validation.util';

export function validate(values: SaleRequestModel) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.fullName)) {
		errors.fullName = 'Full name is required';
	}

	if (!isValidTextInput(values.title)) {
		errors.title = 'Title is required';
	}

	if (!values.faxCountryCode) {
		errors.faxCountryCode = 'Country code is required';
	}

	if (!values.fax) {
		errors.fax = 'Fax is required';
	} else if (!isValidFormattedPhoneNumber(values.fax, values.faxCountryCode)) {
		errors.fax = 'Invalid fax';
	}

	if (!values.phoneCountryCode) {
		errors.phoneCountryCode = 'Country code is required';
	}

	if (!values.phone) {
		errors.phone = 'Mobile phone is required';
	} else if (!isValidFormattedPhoneNumber(values.phone, values.phoneCountryCode)) {
		errors.phone = 'Invalid mobile phone';
	}

	if (!values.email) {
		errors.email = 'Email is required';
	} else if (!EMAIL_REGEX.test(values.email)) {
		errors.email = 'Invalid email';
	}

	if (!isValidTextInput(values.street)) {
		errors.street = 'Location required';
	}

	if (!isValidTextInput(values.locality!)) {
		errors.locality = 'City required';
	}

	if (!isValidTextInput(values.aa1!)) {
		errors.aa1 = 'State required';
	}

	if (!isValidTextInput(values.postalCode!)) {
		errors.postalCode = 'Zip required';
	}

	if (!isValidTextInput(values.country!)) {
		errors.country = 'Country required';
	}

	return errors;
}

export function warn(values: SaleRequestModel) {
	const warnings: ValidationErrors = {};
	if (!isValidNumber(values.latitude) || !isValidNumber(values.latitude)) {
		warnings.street = 'Location is not recognized by Google Maps.';
	}
	return warnings;
}
