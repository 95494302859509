import * as React from 'react';
import { Field, InjectedFormProps } from 'redux-form';

import { SkillRequestModel } from 'ab-requestModels/skill.requestModel';

import Input from 'af-fields/Input';
import ColorPicker from 'af-fields/ColorPicker';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import Tooltip from 'af-components/Tooltip';

interface Props extends InjectedFormProps<SkillRequestModel> {
	onSubmit: (form: SkillRequestModel) => void;
	close?: () => void;
}

interface State {
	focused: boolean;
}

export default class SkillForm extends React.PureComponent<Props, State> {
	state: State = {
		focused: false,
	};

	handleKeyDown = (e: React.KeyboardEvent) => {
		const { handleSubmit, onSubmit, submitting } = this.props;
		if (e.key === 'Enter' && e.shiftKey === false) {
			e.preventDefault();
			if (!submitting) {
				handleSubmit(onSubmit)(e);
			}
		}
	};

	close = () => {
		const { close } = this.props;
		close?.();
	};

	toggleFocus = () => {
		this.setState({ focused: !this.state.focused });
	};

	onColorFocus = () => {
		const { touch } = this.props;
		touch('color');
	};

	render() {
		const { handleSubmit, onSubmit } = this.props;
		const { focused } = this.state;

		let wrapperClassName = 'color-picker__wrapper color-picker__wrapper--open';
		wrapperClassName = focused ? `${wrapperClassName} color-picker__wrapper--focused` : wrapperClassName;

		return (
			<form className="color-picker__form">
				<Field
					autoFocus={true}
					centered={true}
					component={Input}
					cursorControl={true}
					hideErrorText={true}
					isStandalone={true}
					name="name"
					onBlur={this.toggleFocus}
					onFocus={this.toggleFocus}
					onKeyDown={this.handleKeyDown}
					placeholder="Enter Skill"
					type="text"
				/>
				<div className={wrapperClassName} >
					<Field
						component={ColorPicker}
						name="color"
						onFocus={this.onColorFocus}
					/>
				</div>
				<MultipleOptionsButton isLeftFlat={true}>
					<RectangleButton
						action={handleSubmit(onSubmit)}
						isLeftOpen={true}
						isSquare={true}
						label={
							<Tooltip message="Submit" >
								<span className="icon-check" />
							</Tooltip>
						}
					/>
					<RectangleButton
						action={this.close}
						isSquare={true}
						label={
							<Tooltip message="Close" >
								<span className="icon-close" />
							</Tooltip>
						}
					/>
				</MultipleOptionsButton>
			</form>
		);
	}
}
