import * as React from 'react';

import CDLStatus from 'acceligent-shared/enums/cdlStatus';

import { EmployeeOptionVM } from 'ab-viewModels/employee/extendedOption.viewModel';

import * as ColorUtil from 'ab-utils/color.util';

import LabelWithColor from 'af-components/LabelWithColor';
import SkillsColorGrid from 'af-components/SkillsColorGrid';
import TextHighlight from 'af-components/TextHighlight';
import Tooltip from 'af-components/Tooltip';
import NightShiftBadge from 'af-root/scenes/Company/ScheduleBoard/Shared/NightShiftBadge';

interface OwnProps extends EmployeeOptionVM {
	searchText: string;
}

type Props = OwnProps;

export default class LaborOptionItem extends React.PureComponent<Props> {

	static renderSkill = (skill: OwnProps['skills'][0], index: number) => {
		return (
			<div className="employee-item-skill" key={`${skill.name}${index}`}>
				<span className={ColorUtil.getColorBackgroundClass(skill.color)} />
				{skill.name}
			</div>
		);
	};

	renderMessage = () => {
		const { skills, locationColor, locationNickname } = this.props;
		return (
			<>
				<div className="location-nickname">
					<LabelWithColor color={locationColor} text={locationNickname ?? 'Unknown location'} />
				</div>
				{!!skills?.length && <hr />}
				{skills.map(LaborOptionItem.renderSkill)}
			</>
		);
	};

	renderName = () => {
		const { fullName, locationColor, searchText, formattedCode } = this.props;
		return (
			<div>
				<span className={`resource-lookup__resource-name ${ColorUtil.getColorTextClass(locationColor, true)}`}>
					<TextHighlight searchText={searchText} text={fullName} />
				</span>
				<span className="resource-lookup__resource-info">
					<TextHighlight searchText={searchText} text={`(${formattedCode})`} />
				</span>
			</div>
		);
	};

	render() {
		const {
			skills,
			cdlStatus,
			assignments: _assignments,
			wasInNightShift,
		} = this.props;
		const assignments = _assignments?.length
			? _assignments.length === 1
				? `${_assignments[0].code}`
				: `${_assignments[0].code} +${_assignments.length - 1} more`
			: '';

		return (
			<Tooltip
				className="labor-popover"
				message={this.renderMessage()}
				placement="right"
			>
				<span className="resource-lookup__resource">
					<div className="resource-lookup__resource-details-employee">
						{this.renderName()}
						<span className="resource-lookup__resource-additional-info resource-lookup__resource-additional-info--large">{assignments}</span>
						<SkillsColorGrid enableCdlIndicator={cdlStatus === CDLStatus.CDL_A} skills={skills} withBackground={false} />
						{wasInNightShift && <NightShiftBadge />}
					</div>
					<small className="resource-lookup__resource-additional-info resource-lookup__resource-additional-info--small">{assignments}</small>
				</span>
			</Tooltip>
		);
	}
}
