import * as React from 'react';
import { Field } from 'redux-form';

import FormFieldArrayStatusEnum from 'acceligent-shared/enums/formFieldArrayStatus';

import { ReportTypeListVM } from 'ab-viewModels/reportType/reportTypeList.viewModel';

import Dropdown from 'af-fields/Dropdown';

import Tooltip from 'af-components/Tooltip';

import WorkOrderFM from '../../formModel';

interface OwnProps {
	fieldName: string;
	index: number;
	reportTypes: ReportTypeListVM;
	hasReorderReportTypesPermission: boolean;
	onRemove: (index: number) => void;
	onChange: (index: number, value: ReportTypeListVM[0]) => void;
	lazyLoadOptions: () => Promise<void>;
	selector: (fieldName: string) => unknown;
	isEditable: boolean;
}

type Props = OwnProps;

class ReportType extends React.PureComponent<Props> {

	static filterReportType = (reportType: ReportTypeListVM[0], searchText: string) => {
		const text = searchText?.toLowerCase() ?? '';
		return reportType.name.toLowerCase().includes(text);
	};

	static renderOptionItem = (reportType: ReportTypeListVM[0]) => {
		return (
			<div>
				<div className="text-hidden">{reportType.name}</div>
				<small>{reportType.description}</small>
			</div>
		);
	};

	static renderSelectedItem = (reportType: ReportTypeListVM[0]) => <div className="text-hidden">{reportType.name}</div>;

	remove = () => {
		const { index, onRemove } = this.props;
		onRemove(index);
	};

	change = (value: ReportTypeListVM[0]) => {
		const { index, onChange } = this.props;
		onChange(index, value);
	};

	render() {
		const { fieldName, selector, reportTypes, lazyLoadOptions, hasReorderReportTypesPermission, isEditable } = this.props;

		const field = `${fieldName}.reportTypeLookup`;
		const lookup = selector(fieldName) as WorkOrderFM['workOrderReportTypeLookup'][0];

		if (!lookup?.reportTypeLookup || lookup.status === FormFieldArrayStatusEnum.REMOVED) {
			return null;
		}

		return (
			<>
				<>
					{hasReorderReportTypesPermission && <span className="icon-drag_indicator work-order-upsert__draggable-indicator" />}
					<Field
						component={Dropdown}
						disabled={!isEditable || lookup.reportTypeLookup.isRequired}
						dropdownClassName="work-order-upsert__report-type-dropdown"
						filterable={true}
						filterBy={ReportType.filterReportType}
						id={field}
						name={field}
						onLazyLoad={lazyLoadOptions}
						onValueChange={this.change}
						options={reportTypes}
						renderMenuItem={ReportType.renderOptionItem}
						renderSelected={ReportType.renderSelectedItem}
						valueKey="id"
						withCaret={true}
					/>
				</>
				{isEditable && !lookup.reportTypeLookup.isRequired &&
					< Tooltip message="Delete">
						<span className="icon-delete work-order-upsert__remove-icon" onClick={this.remove} />
					</Tooltip>
				}
			</>
		);
	}
}

export default ReportType;
