import { bemElement } from 'ab-utils/bem.util';

export function getFormDraggableZoneListAttributes(isDraggingOver: boolean, listCount: number, expandedCount: number = 0) {
	// 32px for a field + 16px for margin
	// 2px to mitigate the border appearing and disappearing
	const listHeight = ((listCount + 1) * 48) + (isDraggingOver ? 2 : 0) + (expandedCount * 64);

	const containerClassName = bemElement(
		'report-block-form',
		'droppable-container',
		{ empty: !listCount, hover: (isDraggingOver && !!listCount) }
	);

	return {
		listHeight,
		containerClassName,
	};
}
