enum NotificationPriority {
	EMAIL_ONLY = 'EMAIL_ONLY',
	SMS_ONLY = 'SMS_ONLY',
	EMAIL_FIRST = 'EMAIL_FIRST',
	SMS_FIRST = 'SMS_FIRST',
	SEND_BOTH = 'SEND_BOTH'
}

export const NotificationPriorityOptions = [
	{ id: NotificationPriority.EMAIL_ONLY, name: 'Send only e-mail' },
	{ id: NotificationPriority.SMS_ONLY, name: 'Send only SMS' },
	{ id: NotificationPriority.EMAIL_FIRST, name: 'Send e-mail if has one, SMS otherwise' },
	{ id: NotificationPriority.SMS_FIRST, name: 'Send SMS if has mobile phone, e-mail otherwise' },
	{ id: NotificationPriority.SEND_BOTH, name: 'Send both' },
];

export default NotificationPriority;
