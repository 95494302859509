import { Dispatch, AnyAction } from 'redux';
import { initialize } from 'redux-form';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import API from 'af-constants/routes/api';
import { REPORT_BLOCK_PREVIEW } from 'af-constants/reduxForms';

import * as reportBlockActionCreators from 'af-actions/reportBlock/reportBlock.actionCreators';

import ReportBlockVM, { ReportBlockUniqueIdAvailableVM } from 'ab-viewModels/reportBlock/reportBlock.viewModel';
import ReportBlockTableVM from 'ab-viewModels/reportBlock/reportBlockTable.viewModel';
import ReportBlockMapVM from 'ab-viewModels/reportBlock/reportBlockMap.viewModel';
import ReportBlockImageFieldVM from 'ab-viewModels/reportBlock/reportBlockImageField.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { ReportBlockRM, ReportBlockFieldRM, ReportBlockFieldListRM, ReportBlockUniqueIdAvailableRM, ReportBlockFieldUploadRM } from 'ab-requestModels/reportBlock/reportBlock.requestModel';

import * as FormUtil from 'ab-utils/form.util';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { GetRootState } from 'af-reducers';

export function reinitializePreviewForm() {
	return (dispatch: Dispatch<AnyAction>) => {
		// data doesn't matter in preview so we can place empty object
		dispatch(initialize(REPORT_BLOCK_PREVIEW, {}, false));
	};
}

export function setNewReportBlock(reportBlock: ReportBlockRM, fields: Nullable<ReportBlockFieldRM[]>) {
	return async (dispatch: Dispatch<reportBlockActionCreators.ReportBlockAction>) => {
		dispatch(reportBlockActionCreators.SET_NEW_REPORT_BLOCK(reportBlock, fields));
	};
}

export function findTable(query: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TableViewModel<ReportBlockTableVM>>(API.V1.COMPANY.REPORT_BLOCK.FIND_TABLE(query));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ReportBlockMapVM>(API.V1.COMPANY.REPORT_BLOCK.FIND_LIST);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function isBlockUniqueIdAvailable(request: ReportBlockUniqueIdAvailableRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<ReportBlockUniqueIdAvailableVM>(API.V1.COMPANY.REPORT_BLOCK.IS_BLOCK_NAME_AVAILABLE, request);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ReportBlockVM>(API.V1.COMPANY.REPORT_BLOCK.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function create(form: ReportBlockFieldListRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.COMPANY.REPORT_BLOCK.CREATE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function edit(id: number, form: ReportBlockFieldListRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.COMPANY.REPORT_BLOCK.EDIT(id), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteReportBlock(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.COMPANY.REPORT_BLOCK.DELETE(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function uploadReportBlockFieldImage(form: ReportBlockFieldUploadRM) {
	return async (dispatch: Dispatch<AnyAction>, { redirectTo }) => {
		const action = async () => {
			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'imageUrl');
			return await http.post<ReportBlockImageFieldVM>(
				API.V1.COMPANY.REPORT_BLOCK.UPLOAD_IMAGE,
				fd,
				options
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
