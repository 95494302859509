import * as React from 'react';

import styles from './styles.module.scss';

interface Props {
	value: string | number;
	className?: string;
}

const TextCell: React.FC<Props> = ({ value, className }) => {
	const classNames = [styles['text-cell']];
	if (className) {
		classNames.push(className);
	}

	if (typeof value === 'number') {
		return <span className={classNames.join(' ')}>{value}</span>;
	}

	// Split the value by newline characters and map them to <br /> elements
	const formattedValue = (value ?? '')?.split?.('\n')?.map((line, index) => (
		<React.Fragment key={index}>
			{line}
			<br />
		</React.Fragment>
	));

	return <span className={classNames.join(' ')}>{formattedValue}</span>;
};

export default TextCell;
