import * as React from 'react';

import { DisplayViewTemporaryEmployeeBaseVM } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import { bemElement } from 'ab-utils/bem.util';
import * as ColorUtils from 'ab-utils/color.util';

import TemporaryEmployeeDraggableBadgesContainer from '../../Shared/TemporaryEmployeeDraggable/BadgesContainer';
import UniqueIdIndicator from '../../Shared/TemporaryEmployeeDraggable/UniqueIdIndicator';
import MultiAssignmentsBadge from '../../Shared/MultiAssignmentsBadge';
import NightShiftBadge from '../../Shared/NightShiftBadge';

interface OwnProps {
	employee: DisplayViewTemporaryEmployeeBaseVM;
	hideMultipleAssignmentBadges: boolean;
	wasInNightShift: boolean;
}

type Props = OwnProps;

const getDisplayName = (employee: DisplayViewTemporaryEmployeeBaseVM) => {
	const { firstName: _firstName, lastName, duplicateDisplayNameExists } = employee;
	return {
		firstName: _firstName && (duplicateDisplayNameExists ? _firstName.slice(0, 2) : _firstName[0]),
		lastName,
	};
};

const TemporaryEmployeeItem: React.FC<Props> = (props: Props) => {
	const { employee, hideMultipleAssignmentBadges, wasInNightShift } = props;
	const { notificationStatus, numberOfAssignments, officeColor } = employee;

	const { firstName, lastName } = getDisplayName(employee);
	const showMultipleAssignmentsBadge = numberOfAssignments > 1 && !hideMultipleAssignmentBadges;
	const hasDuplicates = employee.duplicateDisplayNameExists;

	const className = 'sb-resource-item sb-resource-item--employee';
	const color = ColorUtils.getColorTextClass(officeColor, true);
	const shouldDisplayNightShift = !!employee.wasInNightShift || wasInNightShift;

	return (
		<div className={className}>
			{notificationStatus && <div className={`icon-notification_status ${notificationStatus.toLowerCase()}`} />}
			<div className={`${bemElement('sb-resource-item', 'content', ['justify-start'])} `}>
				<span className={`icon-temp_labor sb-resource-item__temp-labor-icon ${color}`} />
				<span className={`sb-resource-item__full-name ${color}`}>
					{firstName} <span className="sb-resource-item__last-name">{lastName}</span>
				</span>
				{hasDuplicates && <UniqueIdIndicator uniqueId={employee.uniqueId} />}
			</div>
			<TemporaryEmployeeDraggableBadgesContainer>
				{showMultipleAssignmentsBadge && <MultiAssignmentsBadge count={numberOfAssignments} disableAutoPositioning />}
				{shouldDisplayNightShift && <NightShiftBadge />}
			</TemporaryEmployeeDraggableBadgesContainer>
		</div>
	);
};

export default React.memo(TemporaryEmployeeItem);
