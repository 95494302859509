import { FieldReportSettingsRM } from 'ab-requestModels/company/fieldReportSettings.requestModel';

export const validate = (values: FieldReportSettingsRM) => {
	const errors: ValidationErrors = {};

	if (!values?.fieldReportAccessDayDuration || values.fieldReportAccessDayDuration < 1) {
		errors.fieldReportAccessDayDuration = 'Select number greater than 0';
	}

	return Object.keys(errors).length === 0 ? {} : errors;
};
