import * as React from 'react';
import { Button } from 'react-bootstrap';

import { SCREEN_BREAKPOINT_XL } from 'af-constants/values';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	isShowNotesActive: boolean;
	toggleShowNotesMode: () => void;
}

interface State {
	isButtonSmall: boolean;
}

type Props = OwnProps;

class ShowNotesButton extends React.PureComponent<Props, State> {

	state: State = {
		isButtonSmall: window.innerWidth < SCREEN_BREAKPOINT_XL,
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		const { isButtonSmall: prevIsButtonSmall } = this.state;
		const isButtonSmall = window.innerWidth < SCREEN_BREAKPOINT_XL;

		if (prevIsButtonSmall !== isButtonSmall) {
			this.setState(() => ({ isButtonSmall }));
		}
	};

	render() {
		const { toggleShowNotesMode, isShowNotesActive } = this.props;
		const { isButtonSmall } = this.state;

		return (
			<>
				{
					isButtonSmall
						? (
							<Tooltip message={`${isShowNotesActive ? 'Hide' : 'Show'} Notes`}>
								<Button className={`btn--icon ${isShowNotesActive ? 'btn-info--blue-fill' : ''}`} onClick={toggleShowNotesMode} variant="info">
									<span className="icon-notes" />
								</Button>
							</Tooltip>
						)
						: (
							<Button className={isShowNotesActive ? 'btn-info--blue-fill' : ''} onClick={toggleShowNotesMode} variant="info">
								<span className="icon-notes m-r-xs" />
								Show notes
							</Button>
						)
				}
			</>
		);
	}
}

export default ShowNotesButton;
