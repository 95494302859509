import * as React from 'react';
import { FieldArray, FormAction } from 'redux-form';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import EmptyContactMethodPicker from 'af-components/SharedForms/Contacts/EmptyContactMethodPicker';

import SelectableAddressesContainer from './SelectableAddressesContainer';

export interface Props {
	addressFieldName: string;
	change: (formName: string, field: string, value: string | string[]) => FormAction;
	contact: ContactVM;
	disabled?: boolean;
	formName: string;
	select: (field: string) => number[];
	useIn: string;
}

export default class AddressPicker extends React.PureComponent<Props> {

	renderAddress = () => {
		const { addressFieldName, change, disabled, contact, formName, select } = this.props;
		if (!contact?.addresses) {
			return null;
		}

		return (
			<>
				<div className="contact__methods-row contact__methods-row--address">
					<div />
					<div>Street Address</div>
					<div>Suite / Floor</div>
					<div>City</div>
					<div>State</div>
					<div>Zip</div>
					<div>P.O. Box</div>
					<div>Country</div>
				</div>
				<EmptyContactMethodPicker
					change={change}
					disabled={disabled}
					fieldName={addressFieldName}
					formName={formName}
					select={select}
				/>
				<FieldArray<Props>
					component={SelectableAddressesContainer}
					name={addressFieldName}
					{...this.props}
				/>
			</>
		);
	};

	render() {
		const { useIn } = this.props;
		return (
			<>
				<div className="contact__methods-row contact__methods-row--heading">
					<div className="contact__method-control">Use in {useIn}</div>
					<div>Address</div>
				</div>
				{this.renderAddress()}
			</>
		);
	}
}
