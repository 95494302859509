import * as React from 'react';

import Tooltip, { OwnProps as TooltipProps } from 'af-components/Tooltip';

interface OwnProps {
	label: string | JSX.Element;
	isSquare?: boolean;
	isActive?: boolean;
	isLeftOpen?: boolean;
	isDisabled?: boolean;
	tooltipMessage?: string;
	tooltipPlacement?: TooltipProps['placement'];
	tooltipClassName?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action: (event?: any) => void;
}

type Props = OwnProps;

export class RectangleButton extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		isSquare: false,
		isLeftOpen: false,
		isActive: false,
		isDisabled: false,
	};

	renderButton = () => {
		const { action, label, isSquare, isActive, isLeftOpen, isDisabled } = this.props;

		let className = 'btn btn--rectangle';
		className = isSquare ? `${className} btn--icon` : className;
		className = isDisabled ? `${className} btn--disabled` : className;
		className = isActive ? `${className} btn--active` : className;
		className = isLeftOpen ? `${className} btn--open-left` : className;

		return (
			<span
				className={className}
				onClick={isDisabled ? undefined : action}
			>
				{label}
			</span>
		);
	};

	render() {
		const { tooltipMessage, tooltipPlacement, tooltipClassName } = this.props;
		if (tooltipMessage) {
			return (
				<Tooltip className={tooltipClassName} message={tooltipMessage} placement={tooltipPlacement}>
					{this.renderButton()}
				</Tooltip>
			);
		}
		return this.renderButton();
	}
}

export default RectangleButton;
