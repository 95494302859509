import * as React from 'react';
import { compose } from 'redux';
import { nanoid } from 'nanoid';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { ReportBlockRM, ReportBlockFieldRM } from 'ab-requestModels/reportBlock/reportBlock.requestModel';

import { UNIQUE_ID_SIZE } from 'ab-constants/value';

import * as ReportBlockActions from 'af-actions/reportBlock';

import CLIENT from 'af-constants/routes/client';

import Breadcrumbs from 'af-components/Breadcrumbs';

import { RootState } from 'af-reducers';

import Loading from './Loading';

import { fromReportBlockFieldVMtoRMBulk, fromVMtoRM } from '../Form/formModel';
import ReportBlockForm from '../Form';

import { ReportBlockFormModel, ReportBlockFieldFormModel } from '../../Shared/formModel';

interface PathParams {
	id: string;
}

type Props = ConnectedProps<typeof connector> & CustomRouteComponentProps<PathParams>;

const EditReportBlock: React.FC<Props> = (props) => {
	const {
		fetch,
		match: { params: { id } },
		create,
		history,
		location: { state: { orgAlias } },
		companyName,
		edit,
		refreshPreview,
	} = props;

	const [block, setBlock] = React.useState<Nullable<ReportBlockRM>>(null);
	const [fields, setFields] = React.useState<ReportBlockFieldRM[]>([]);
	const [reportTypes, setReportTypes] = React.useState<Nullable<string[]>>(null);
	const [refreshing, setRefreshing] = React.useState<boolean>(false);

	// component did mount
	React.useEffect(() => {
		let isSubscribed = true;

		const getBlock = async () => {
			const _block = await fetch(+id);
			if (_block && isSubscribed) {
				const _blockRM = fromVMtoRM(_block);
				setBlock(_blockRM);
				setFields(fromReportBlockFieldVMtoRMBulk(_block.reportBlockFields));
				setReportTypes(_block.reportTypes ?? null);
			}
		};
		getBlock();

		return (() => {
			isSubscribed = false;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const save = React.useCallback(async (form: ReportBlockFormModel, _fields: ReportBlockFieldRM[]) => {
		// remove ids from fields
		const newFields = _fields?.reduce<ReportBlockFieldRM[]>((_acc, _curr) => {
			const _field = JSON.parse(JSON.stringify(_curr));
			_field.id = undefined;
			_acc.push(_field);

			return _acc;
		}, []);
		const newBlock = {
			name: form.name,
			virtualId: nanoid(UNIQUE_ID_SIZE),
			isMain: form.isMain,
			uniqueId: form.uniqueId,
			isRepeating: form.isRepeating,
			repeatableBlockType: form.repeatableBlockType ?? null,
			completionFieldVirtualId: form.completionFieldVirtualId,
		};
		await create({ block: newBlock, fields: newFields });
		history.push(CLIENT.COMPANY.SETTINGS.REPORT.TABLE(orgAlias, companyName));
	}, [companyName, create, history, orgAlias]);

	const update = React.useCallback(async (_fields: ReportBlockFieldRM[]) => {
		if (block) {
			await edit(+id, { block, fields: _fields });
			history.push(CLIENT.COMPANY.SETTINGS.REPORT.TABLE(orgAlias, companyName));
		}
	}, [block, companyName, edit, history, id, orgAlias]);

	const editBlock = React.useCallback(async (form: ReportBlockRM, formFields: ReportBlockFieldRM[]) => {
		setRefreshing(true);
		setBlock(form);
		setFields(formFields);
		refreshPreview();
		setRefreshing(false);
	}, [refreshPreview]);

	const breadcrumbs = React.useMemo(() => {
		return [
			{ label: 'Report Block', url: CLIENT.COMPANY.SETTINGS.REPORT.TABLE(orgAlias, companyName) },
			{ label: 'Edit' },
		];
	}, [companyName, orgAlias]);

	const formFields = React.useMemo(() => {
		if (fields && block?.virtualId) {
			return ReportBlockFieldFormModel.bulkFromReportBlockRM(fields, block.virtualId);
		}
	}, [fields, block]);

	const formReportBlock = React.useMemo(() => {
		if (block) {
			return ReportBlockFormModel.fromReportBlockRequestModel(block);
		}
	}, [block]);

	if (!formFields || !formReportBlock) {
		return <Loading />;
	}

	return (
		<div className="form-segment">
			<Breadcrumbs items={breadcrumbs} />
			<ReportBlockForm
				fields={formFields}
				loading={refreshing}
				onEditBlock={editBlock}
				onSaveAs={save}
				onSubmit={update}
				reportBlock={formReportBlock}
				reportTypes={reportTypes ?? undefined}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		fetch: ReportBlockActions.findById,
		create: ReportBlockActions.create,
		edit: ReportBlockActions.edit,
		refreshPreview: ReportBlockActions.reinitializePreviewForm,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	React.memo,
	connector
);

export default enhance(EditReportBlock);
