import * as React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot, DraggableElement } from 'react-beautiful-dnd';
import { connect, ConnectedProps } from 'react-redux';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import DraggableTemporaryEmployee from './Draggable';

import { TemporaryEmployeeModalProps } from '../../Shared/ModalProps';

interface OwnProps extends TemporaryEmployeeModalProps {
	temporaryEmployeeId: number;
	draggableId: string;
	index: number;
	droppableId: string;
	/** `MM-DD-YYYY` */
	dueDate: string;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	isCardDisabled?: boolean;
	isCalculationsView?: boolean;
	resourceId?: number;
	workOrderTemporaryEmployeeId?: number;
	isDragAndDropDisabled: boolean;
	workOrderCode?: string;
	isWOLocked: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface DraggableProps extends Props {
	provided?: DraggableProvided;
	snapshot?: DraggableStateSnapshot;
}

interface State {
	isModalOpenedForCurrentEmployee: boolean;
}

class TemporaryEmployeeDraggable extends React.PureComponent<Props, State> {

	static defaultProps: OptionalObject<OwnProps> = {
		isCalculationsView: false,
	};

	state: State = {
		isModalOpenedForCurrentEmployee: false,
	};

	componentDidUpdate(prevProps: Props) {
		const { temporaryEmployeeId, isDisabled } = this.props;
		const { setTemporaryEmployeeModalData, isDisabled: wasDisabled, temporaryEmployeeId: prevTemporaryEmployeeId } = prevProps;
		const { isModalOpenedForCurrentEmployee } = this.state;

		if (isModalOpenedForCurrentEmployee && wasDisabled && !isDisabled && temporaryEmployeeId === prevTemporaryEmployeeId) {
			setTemporaryEmployeeModalData(null, null);
			this.setState(() => ({ isModalOpenedForCurrentEmployee: false }));
			this.fetchModalData();
		}
	}

	fetchModalData = async () => {
		const {
			setTemporaryEmployeeModalData,
			findWorkOrderTemporaryEmployeeById,
			workOrderTemporaryEmployeeId,
			dueDate,
		} = this.props;

		if (!workOrderTemporaryEmployeeId) {
			throw new Error('Resource draggable is missing workOrderTemporaryEmployeeId');
		}

		const _dueDate = TimeUtils.formatDate(dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY);
		const employee = await findWorkOrderTemporaryEmployeeById(workOrderTemporaryEmployeeId, _dueDate);

		setTemporaryEmployeeModalData(employee, dueDate);
		this.setState(() => ({ isModalOpenedForCurrentEmployee: true }));
	};

	openModal = () => {
		const { isCalculationsView } = this.props;
		if (isCalculationsView) {
			return;
		}
		this.fetchModalData();
	};

	renderDraggable = (props: DraggableProps) => {
		const {
			isCardDisabled,
			resourceId,
			temporaryEmployeeId,
			dueDate,
			isWorkOrderCanceled,
			isDragAndDropDisabled,
			workOrderCode,
			provided = {} as DraggableProvided,
			snapshot = {} as DraggableStateSnapshot,
			isWOLocked,
		} = props;

		if (!resourceId) {
			throw new Error('Resource ID not provided');
		}

		return (
			<DraggableTemporaryEmployee
				draggableProps={provided.draggableProps}
				draggingOver={snapshot.draggingOver}
				dragHandleProps={provided.dragHandleProps}
				dueDate={dueDate}
				innerRef={provided.innerRef}
				isCardDisabled={!!isCardDisabled}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isDragging={snapshot.isDragging}
				isDropAnimating={snapshot.isDropAnimating}
				isWOLocked={isWOLocked}
				isWorkOrderCanceled={isWorkOrderCanceled}
				onClick={this.openModal}
				resourceId={resourceId}
				temporaryEmployeeId={temporaryEmployeeId}
				workOrderCode={workOrderCode}
			/>
		);
	};

	render() {
		const {
			draggableId,
			index,
			isDisabled,
			isWorkOrderCanceled,
			isCopyPlaceholder,
			isDragAndDropDisabled,
		} = this.props;

		// react-beautiful-dnd can not create Draggable and Droppable elements/
		// while we are dragging so in order to mimic the copied card, we render
		// div instead of a Draggable (same for Droppable in parent component)
		if (isCopyPlaceholder || isDragAndDropDisabled) {
			return (
				<div>
					{this.renderDraggable(this.props)}
				</div>
			);
		}

		return (
			<Draggable
				draggableId={draggableId}
				index={index}
				isDragDisabled={!!isDisabled || isWorkOrderCanceled}
			>
				{(provided, snapshot) => (
					React.createElement(this.renderDraggable, { ...this.props, provided, snapshot }, null) as DraggableElement<DraggableProps>
				)}
			</Draggable>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { temporaryEmployeeId } = ownProps;
	const { temporaryEmployees, draggedTemporaryEmployeeId } = state.scheduleBoard;

	const temporaryEmployee = temporaryEmployees?.[temporaryEmployeeId];

	return {
		isDisabled: temporaryEmployee?.isDisabled && !draggedTemporaryEmployeeId,
	};
}

function mapDispatchToProps() {
	return {
		findWorkOrderTemporaryEmployeeById: ScheduleBoardActions.findWorkOrderTemporaryEmployeeById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(TemporaryEmployeeDraggable);
