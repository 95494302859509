import * as React from 'react';

interface Props {
	isCopying: boolean;
	copyFinished: boolean;
}

export default class CopyProgressBar extends React.PureComponent<Props> {
	render() {
		const { isCopying, copyFinished } = this.props;

		const copyProgress = copyFinished ? '--finished' : '--in-progress';
		return (
			<div className={`copy-container ${(isCopying && !copyFinished) || (!isCopying && copyFinished) ? `--visible ${copyProgress}` : '--hidden'}`}>
				<div className="copy-progress-bar" />
				<span className="copy-label" />
			</div>
		);
	}
}
