enum ScheduleBoardProperty {
	WORK_ORDER = 'WORK_ORDER',
	EMPLOYEE = 'EMPLOYEE',
	EQUIPMENT = 'EQUIPMENT',
	RESOURCE = 'RESOURCE',
	PLACEHOLDER = 'PLACEHOLDER',
	TEMPORARY_EMPLOYEE = 'TEMPORARY_EMPLOYEE'
}

export default ScheduleBoardProperty;
