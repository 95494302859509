import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import { EQUIPMENT_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import { EquipmentRequestModel } from 'ab-requestModels/equipment.requestModel';

import * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import * as EquipmentActions from 'af-actions/equipment';

import CreateEquipmentForm from 'af-components/SharedForms/Resources/Equipment';
import { validate } from 'af-components/SharedForms/Resources/Equipment/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<EquipmentRequestModel>;

class Create extends React.Component<Props> {

	createEquipment = async (form: EquipmentRequestModel) => {
		const { history, create, location: { state: { orgAlias } }, companyName } = this.props;
		await create(form);
		history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName));
	};

	render() {
		const {
			location: { state: { orgAlias } },
			companyName,
		} = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={[
						{ label: 'Equipment', url: CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName) },
						{ label: 'New Equipment' },
					]}
				/>
				<CreateEquipmentForm
					{...this.props}
					onSubmit={this.createEquipment}
				/>
			</div>
		);
	}
}

const formSelector = formValueSelector(EQUIPMENT_CREATE);

interface FormActionWrapper {
	selector: <T extends keyof EquipmentRequestModel>(fieldName: T) => EquipmentRequestModel[T];
}
const formActionWrapper: Partial<FormActionWrapper> = {
	selector: undefined,
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	formActionWrapper.selector = (fieldName: keyof EquipmentRequestModel) => formSelector(state, fieldName);

	return {
		formActionWrapper: formActionWrapper as FormActionWrapper, // selector is no longer undefined
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		create: EquipmentActions.create,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm({
		form: EQUIPMENT_CREATE,
		validate,
		enableReinitialize: true,
		initialValues: {
			showOnScheduleBoard: true,
			equipmentStatusId: ResourceStatusesViewModel.Item.DEFAULT_AVAILABLE.id,
		},
	})
);

export default enhance(Create);
