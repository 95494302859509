import CountryCode from 'acceligent-shared/enums/countryCode';
import MessageDestinationType from 'acceligent-shared/enums/messageDestinationType';
import MessageStatus from 'acceligent-shared/enums/messageStatus';
import MessageType from 'acceligent-shared/enums/messageType';

import * as UserUtils from 'acceligent-shared/utils/user';

import * as PhoneUtils from 'ab-utils/phone.util';

import AllMessagesTableBase, { MessageStatus as MessageStatusBase } from 'ab-common/interfaces/domainEntities/views/allMessagesTable';

const CSV_REQUIRED_KEYS = [
	'SentAt',
	'Sender',
	'Type',
	'Destination',
	'Recipient',
	'MessageType',
	'Status',
	'UserGroup',
	'Content',
];

class MessageStatusVM {
	/** format: `ISO_DATETIME` */
	createdAt: string;
	createdByFullName: Nullable<string>;
	status: MessageStatus;

	constructor(item: MessageStatusBase) {
		this.createdAt = item.createdAt;
		this.createdByFullName = item.createdByFullName;
		this.status = item.status;
	}
}

class MessageTableVM {
	// Message fields
	id: number;
	/** format: `ISO_DATETIME` */
	sentAt: string;
	senderFullName: Nullable<string>;
	destinationType: MessageDestinationType;
	destination: Nullable<string>;
	userGroupName: Nullable<string>;
	content: string;
	subject: Nullable<string>;
	heading: Nullable<string>;
	footer: Nullable<string>;
	isAutomatic: boolean;
	// Recipient
	recipient: Nullable<string>;
	// Status history
	currentStatus: MessageStatusVM;
	// Message type
	messageType: MessageType;

	constructor(row: AllMessagesTableBase) {
		this.id = row.id;
		this.sentAt = row.sentAt.toISOString();
		this.senderFullName = UserUtils.getUserName(
			(row.senderFirstName && row.senderLastName)
				? { firstName: row.senderFirstName, lastName: row.senderLastName }
				: null
		);
		this.destinationType = row.destinationType;

		if (row.destinationType === MessageDestinationType.PHONE) {
			this.destination = PhoneUtils.formatPhoneNumber(row.destination, CountryCode.US) ?? row.destination;
		} else if (row.destinationType === MessageDestinationType.EMAIL) {
			this.destination = row.destination;
		} else {
			this.destination = null;
		}

		this.userGroupName = row.userGroupName;
		this.content = row.content;
		this.isAutomatic = row.isAutomatic;
		this.messageType = row.messageType;
		// Recipient
		this.recipient = row.recipient;
		// Status History
		this.currentStatus = row.statusHistory ? new MessageStatusVM(row.statusHistory[0]) : {
			createdAt: row.sentAt.toISOString(),
			createdByFullName: this.senderFullName,
			status: MessageStatus.CREATED,
		};
	}

	private static _constructorMap = (row: AllMessagesTableBase) => new MessageTableVM(row);

	static bulkConstructor = (rows: AllMessagesTableBase[]) => rows.map(MessageTableVM._constructorMap);

	static toCSVData(viewModels: MessageTableVM[]): string[][] {
		const header: string[] = CSV_REQUIRED_KEYS;

		const rows: string[][] = viewModels.reduce((_acc, _item: MessageTableVM) => {
			_acc.push([
				_item.sentAt,
				_item.isAutomatic
					? 'AUTOMATIC'
					: _item.senderFullName ?? '-',
				_item.destinationType,
				_item.destination ?? '-',
				_item.recipient ?? '-',
				_item.messageType,
				_item.currentStatus.status,
				_item.userGroupName ?? '-',
				_item.content,
			]);
			return _acc;
		}, [] as string[][]);

		return [header, ...rows];
	}
}

export default MessageTableVM;
