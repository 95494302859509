import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import { TimeSheetWorkTypeDisplayInfo } from 'acceligent-shared/enums/timeSheetEntryWorkType';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { bemElement } from 'ab-utils/bem.util';

import { useToggle } from 'af-utils/react.util';

import { TimeSheetEntryHistoryItemVM } from 'ab-viewModels/timeSheet/timeSheetEntryHistory.viewModel';

import TimeSheetEntryHistoryInformation from './TimeSheetEntryHistoryInformation';

interface OwnProps {
	item: TimeSheetEntryHistoryItemVM;
}

type Props = OwnProps;

const TimeSheetEntryHistoryItem: React.FC<Props> = (props: Props) => {
	const { item: { workType, startTime, endTime } } = props;

	const {
		value: isExpanded,
		toggle: toggleExpand,
	} = useToggle(false);

	const workTypeIcon = React.useMemo(() => TimeSheetWorkTypeDisplayInfo[workType], [workType]);

	const startTimeLabel = React.useMemo(() => {
		return !!startTime ? TimeUtils.formatDate(startTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME) : null;
	}, [startTime]);

	const endTimeLabel = React.useMemo(() => {
		return !!endTime ? TimeUtils.formatDate(endTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME) : null;
	}, [endTime]);

	const duration = React.useMemo(() => {
		return (startTime && endTime)
			? TimeUtils.minutesToHoursAndMinutes(TimeUtils.compare(endTime, startTime, 'minutes', TimeFormat.ISO_DATETIME))
			: '';
	}, [startTime, endTime]);

	return (
		<>
			<Row className="row--column-aligned row--padded-medium timeline-history-modal__entry">
				<Col
					className={bemElement('timeline-history-modal', 'entry-item', ['blue'])}
					sm={1}
				>
					<span className={`timeline-history-modal__type-icon icon-${workTypeIcon.icon}`} />
				</Col>
				<Col
					className={`${bemElement('timeline-history-modal', 'entry-item', ['start'])} timeline-history-modal__type`}
					sm={8}
				>
					{workTypeIcon.label}
				</Col>
				<Col
					className={bemElement('timeline-history-modal', 'entry-item', ['blue'])}
					sm={4}
				>
					{startTimeLabel}
				</Col>
				<Col
					className={bemElement('timeline-history-modal', 'entry-item', ['center', 'blue'])}
					sm={1}
				>
					-
				</Col>
				<Col
					className={bemElement('timeline-history-modal', 'entry-item', ['blue'])}
					sm={4}
				>
					{endTimeLabel}
				</Col>
				<Col
					className={bemElement('timeline-history-modal', 'entry-item', ['end'])}
					sm={4}
				>
					{duration}
				</Col>
				<Col
					className={bemElement('timeline-history-modal', 'entry-item', ['end'])}
					sm={2}
				>
					<span className={`timeline-history-modal__expander-icon ${isExpanded ? 'icon-up' : 'icon-down'}`} onClick={toggleExpand} />
				</Col>
			</Row>
			{isExpanded &&
				<TimeSheetEntryHistoryInformation
					item={props.item}
				/>
			}
		</>
	);
};

export default React.memo(TimeSheetEntryHistoryItem);
