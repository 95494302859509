import QuantityUnitType, { CompoundUnitEnum } from 'acceligent-shared/enums/quantityUnit';
import OperationType from 'acceligent-shared/enums/operation';
import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import OperandType from 'acceligent-shared/enums/operand';

import { StringifiedCompoundUnit, ParsedCompoundUnit } from 'acceligent-shared/utils/unit';

import { ReportBlockFormModel, ReportBlockFieldFormModel } from 'af-root/scenes/Company/Settings/Reports/Shared/formModel';

import * as CalculationUtils from 'ab-utils/calculation.util';

import { BLOCK_PREFIX, FIELD_PREFIX } from 'af-utils/reportTypeBuilder.util';

// Types

export interface Operand {
	virtualId: string;
	blockId: string;
	fieldType: Nullable<ReportBlockFieldEnum.Type>;
	unit: Nullable<QuantityUnitType>;
	isRepeating: boolean;
	constant: Nullable<number>;
	type: OperandType;
}

export interface Calculation {
	operationType: OperationType;
	unit: Nullable<QuantityUnitType>;
	isRepeating: boolean;
	blockId: string;
	operands: Operand[];
}

export type CalculationByResultFieldMap = {
	[resultFieldId: string]: Calculation;
};

export type CalculationMapType = {
	[operandFieldId: string]: CalculationByResultFieldMap;
};

// Private functions

/**
* Returns redux form field name
* @param blockId id of field block
* @param fieldId id of field
* @param index index in block if block is repeatable
* @returns string
*/
const _getFieldName = (fieldId: string, index?: Nullable<number>) => `${index !== null && index !== undefined && Number.isInteger(index) ? `[${index}]` : ''}${FIELD_PREFIX}#${fieldId}`;
const _getCompletionFieldName = (fieldId: string) => `${FIELD_PREFIX}#${fieldId}`;
const _getBlockName = (blockId: string) => `${BLOCK_PREFIX}#${blockId}`;

const _formatValue = (operand: Operand, value: string | Record<string, string>[]) => {
	if (operand.unit && CompoundUnitEnum[operand.unit]) {
		return StringifiedCompoundUnit[operand.unit](value);
	}
	return value;
};

/**
 * Returns single field value
 * @param blockId id of block where field resides
 * @param fieldId id of field
 * @param fieldType type of field
 * @param selector redux form selector
 * @param index index in repeatable block
 * @returns returns field value as string
 */
const _selectSingleFieldValue = (operand: Operand, formValues: string | Record<string, string>[], index?: Nullable<number>): string => {
	const { virtualId, blockId } = operand;
	let fieldName: Nullable<string>;
	const blockName = _getBlockName(blockId);
	let blockValues: Nullable<Record<string, string>[]>;
	if (operand.fieldType === ReportBlockFieldEnum.Type.COMPLETION) {
		fieldName = _getCompletionFieldName(virtualId);
		blockValues = formValues[blockName];
	} else {
		fieldName = _getFieldName(virtualId, index);
		blockValues = formValues[blockName]?.values;
	}
	const value = blockValues?.[fieldName];
	return _formatValue(operand, value);
};

/**
 * Returns array of string values
 * @param blockId id of block where field resides
 * @param selector redux form selector
 * @param mapper mapper function which maps field values
 * @returns string array of field values
 */
const _selectRepeatableFieldValue = (
	blockId: string,
	formValues: string | Record<string, string>[],
	mapper: ((operand: Record<string, string>, index: number) => string) | undefined
): string[] => {
	const blockValues = formValues[`${BLOCK_PREFIX}#${blockId}`];
	const repeatableFieldValues = blockValues?.values;
	return repeatableFieldValues?.length && !!mapper ? repeatableFieldValues.map(mapper) : [];
};

/**
* Returns single field value if not repeating block or all field values in repeating block
* @param option option in calculated field
* @param selector redux form selector
* @returns returns string or array of string
*/
const _getFieldValue = (
	option: Operand,
	formValues: Record<string, string>[] | string,
	repeatingValuesMapper?: (operand: Record<string, string>, index: number) => string
): string | string[] => {
	if (option.type === OperandType.CONSTANT) {
		return (option.constant ?? '').toString();
	} else if (option.isRepeating) {
		return _selectRepeatableFieldValue(option.blockId, formValues, repeatingValuesMapper);
	}
	return _selectSingleFieldValue(option, formValues);
};

/**
 * Used to return operand values for calculation with ability to replace return value with @param returnValue for operand that meets @param condition
 * It is used to replace updated field value when running calculations before the field value is updated in redux
 * It returns operand values for both repeatable and non-repeatable fields
 * @param selector redux form selector
 * @param condition for operand that meets condition will return @param returnValue
 * @param returnValue return value for operand that meets @param condition
 * @param index index in block if block is repeatable
 * @returns list of string or string
 */
const _getFieldValueWithReplaceRepeatingMapper = (
	formValues: Record<string, string>[] | string,
	condition: (operandId: string) => boolean,
	returnValue: Nullable<string>,
	index?: Nullable<number>
) => {
	return (operand: Operand) => {
		let repeatingMapper: ((_operand: Record<string, string>, _index: number) => string) | undefined;
		if (condition(operand.virtualId)) {
			if (operand.isRepeating) {
				const key = `${FIELD_PREFIX}#${operand.virtualId}`;
				repeatingMapper = (_operand: Record<string, string>, _index: number) => {
					if (_index === index) {
						return returnValue;
					}
					return _formatValue(operand, _operand[key]);
				};
			} else {
				return returnValue;
			}
		} else if (operand.isRepeating) {
			const key = `${FIELD_PREFIX}#${operand.virtualId}`;
			repeatingMapper = (_operand: Record<string, string>) => {
				return _formatValue(operand, _operand[key]);
			};
		}
		return _getFieldValue(operand, formValues, repeatingMapper);
	};
};

/**
 * Used to return operand values for calculation with ability to replace return value with @param returnValue for operand that meets @param condition
 * It is used to replace updated field value when running calculations before the field value is updated in redux
 * @param selector redux form selector
 * @param condition for operand that meets condition will return @param returnValue
 * @param returnValue return value for operand that meets @param condition
 * @param index index in block if block is repeatable
 * @returns operand values as string or array of strings
 */
const _getFieldValuesWithReplaceNonRepeatingMapper = (
	formValues: Record<string, string>[] | string,
	condition: (operandId: string) => boolean,
	returnValue: Nullable<string>,
	index?: Nullable<number>
) => {
	return (operand: Operand) => {
		// Return returnValue for operand that meets condition
		if (condition(operand.virtualId)) {
			return returnValue;
		}
		if (operand.type === OperandType.CONSTANT) {
			return (operand.constant ?? '').toString();
		}
		return _selectSingleFieldValue(operand, formValues, operand.isRepeating ? index : null);
	};
};

/**
 * Returns reducer which creates a map of index and field values for repeatable field
 * @param key redux field key
 * @returns map of index and array of string values
 */
const _getRepeatableFieldsMapReducer = (key: string, unit: Nullable<QuantityUnitType>) => {
	return (acc: Record<number, string[]>, operand: Record<string, string>, index: number) => {
		if (!acc[index]) {
			acc[index] = [];
		}
		acc[index].push(unit && CompoundUnitEnum[unit] ? StringifiedCompoundUnit[unit](operand[key]) : operand[key]);
		return acc;
	};
};

/**
 * Returns non repeatable field values as dictionary [index, string[]]
 * @param newValue new value of completion field
 * @param blockId id of block
 * @param accumulator accumulator where to store values
 * @param selector redux form selector
 * @returns returns dictionary [index, string[]]
 */
const _getNonRepeatableValuesMap = (
	newValue: string,
	blockId: string,
	accumulator: Record<number, string[]>,
	formValues: Record<string, string>[] | string
) => {
	const blockValues = formValues[`${BLOCK_PREFIX}#${blockId}`];
	const operandValues = blockValues?.values;
	operandValues.forEach((_, _index) => {
		if (!accumulator[_index]) {
			accumulator[_index] = [];
		}
		accumulator[_index].push(newValue);
	});
	return accumulator;
};

/**
 * Finds redux form field values and stores them in dict of type <index of repeatable fields, array of values as string>
 * @param fieldId field id
 * @param blockId block id
 * @param accumulator accumulator to return values to
 * @param selector redux form selector
 * @returns returns dic of type Record<number, string[]>
 */
const _getRepeatableFieldsMap = (
	fieldId: string,
	blockId: string,
	unit: Nullable<QuantityUnitType>,
	accumulator: Record<number, string[]>,
	formValues: Record<string, string>[] | string
) => {
	const key = `${FIELD_PREFIX}#${fieldId}`;
	const blockValues = formValues[`${BLOCK_PREFIX}#${blockId}`];
	const operandValues = blockValues.values as Record<string, string>[];
	const reducer = _getRepeatableFieldsMapReducer(key, unit);
	return operandValues.reduce(reducer, accumulator);
};

/**
* Performs calculation operation
* @param fieldId id of calculated field
* @param blockId id of calculated field block
* @param operation calculation type
* @param values calculation operands as string array
* @param index index in block if repeatable
* @returns calculation value as string
*/
const _calculate = (
	fieldId: string,
	blockId: string,
	unit: Nullable<QuantityUnitType>,
	operation: OperationType | undefined,
	values: Nullable<string>[],
	change: (prop: string, value: string) => void,
	index?: Nullable<number>
) => {
	if (!operation) {
		throw new Error('Operation not defined');
	}

	const resultValue = CalculationUtils.calculateValue(operation, values.map((_val) => _val ?? ''));
	const blockName = _getBlockName(blockId);
	const resultFieldName = _getFieldName(fieldId, index);
	change(`${blockName}.values.${resultFieldName}`, unit && CompoundUnitEnum[unit] ? ParsedCompoundUnit[unit](resultValue) : resultValue);
	return resultValue;
};

interface CalculateReturnType {
	id: string;
	value: Nullable<string>;
	index?: Nullable<number>;
}
/**
 * Performs calculation and replaces operand that meets @param replaceCondition with @param replaceValue
 * @param calculation calculation to be performed
 * @param resultFieldId id of result field
 * @param replaceCondition condition to identify the operand to which value should be replaced
 * @param replaceValue replace value of operand
 * @param selector redux form selector
 * @param change redux form change
 * @param index optional index of field in repeatable block
 * @returns object of type @type CalculateReturnType
 */
const _calculateWithReplace = (
	calculation: Calculation,
	resultFieldId: string,
	replaceCondition: (operandId: string) => boolean,
	replaceValue: Nullable<string>,
	formValues: Record<string, string>[] | string,
	change: (prop: string, value: string) => void,
	index?: Nullable<number>
): CalculateReturnType => {
	const { operands, blockId, operationType, unit, isRepeating } = calculation;
	if (!isRepeating) {
		// Calculate on non-repeatable field
		// When calculated field is not repeatable, operands can be repeatable and non-repeatable
		const mapper = _getFieldValueWithReplaceRepeatingMapper(formValues, replaceCondition, replaceValue, index);
		const values = operands.flatMap(mapper);
		const resultValue = _calculate(resultFieldId, blockId, unit, operationType, values, change);
		return { id: resultFieldId, value: resultValue };
	} else {
		// Calculate repeatable field
		// When calculated field is repeatable, all operands are repeatable
		const mapper = _getFieldValuesWithReplaceNonRepeatingMapper(formValues, replaceCondition, replaceValue, index);
		const values = calculation.operands.flatMap(mapper);
		const resultValue = _calculate(resultFieldId, blockId, unit, operationType, values, change, index);
		return { id: resultFieldId, value: resultValue, index: calculation.isRepeating ? index : null };
	}
};

// Public functions

/**
* Called when field value changes
* Using new value because we are updating calculations before the field is updated in the form
* @param id id of field
* @param newValue new value
* @param calculationMap map of calculations by field Id
* @param selector redux form field selector function
* @param change redux form field change function
* @param index index of field if field is in repeatable block
*/
export const calculateOnFieldValueChange = (
	id: string,
	newValue: Nullable<string>,
	calculationMap: CalculationMapType,
	formValues: Record<string, string>[] | string,
	change: (prop: string, value: string) => void,
	index?: Nullable<number>
) => {
	const calculations = calculationMap[id];
	if (calculations) {
		const replaceCondition = (operandId: string) => operandId === id;
		const updatedFields = Object.entries(calculations).reduce<CalculateReturnType[]>((_acc, [_resultFieldId, _calculation]) => {
			const result = _calculateWithReplace(_calculation, _resultFieldId, replaceCondition, newValue, formValues, change, index);
			_acc.push(result);
			return _acc;
		}, []);
		updatedFields.forEach((_field) => calculateOnFieldValueChange(_field.id, _field.value, calculationMap, formValues, change, _field.index));
	}
};

interface CalculateOnRemoveFieldsAccumulatorType {
	updatedFields: CalculateReturnType[];
	calculatedFields: Record<string, true>;
}
/**
* Called when removing repeatable fields
* @param fields map of field ids that are removed
* @param calculationMap map of calculations by field Id
* @param selector redux form field selector function
* @param change redux form field change function
* @param index index of repeatable fields in block
*/
export const calculateOnRemoveFields = (
	fields: Record<string, true>,
	calculationMap: CalculationMapType,
	formValues: Record<string, string>[] | string,
	change: (prop: string, value: string) => void,
	index: number
) => {
	const updatedFields = Object.keys(fields).reduce<CalculateReturnType[]>((_acc, _fieldId) => {
		const calculations = calculationMap[_fieldId];
		if (calculations) {
			const replaceCondition = (operandId: string) => fields[operandId];
			Object.entries(calculations).reduce<CalculateOnRemoveFieldsAccumulatorType>((_agg, [_resultFieldId, _calculation]) => {
				// If field was already calculated skip
				if (_agg.calculatedFields[_resultFieldId]) {
					return _agg;
				}
				const result = _calculateWithReplace(_calculation, _resultFieldId, replaceCondition, '', formValues, change, index);

				_agg.updatedFields.push(result);
				_agg.calculatedFields[_resultFieldId] = true;

				return _agg;
			}, { updatedFields: _acc, calculatedFields: {} } as CalculateOnRemoveFieldsAccumulatorType);
		}
		return _acc;
	}, []);
	updatedFields.map((_field) => calculateOnFieldValueChange(_field.id, _field.value, calculationMap, formValues, change, _field.index));
};

/**
* Called when Calculated fields calculated field options change
* @param id field id
* @param blockId id of the block where the field is located
* @param operationType type of calculation operation
* @param options calculated field options
* @param isRepeating is field in repeating block
* @param calculationMap map of calculations by field Id
* @param selector redux form field selector function
* @param change redux form field change function
*/
export const calculateOnFieldChange = async (
	field: ReportBlockFieldFormModel,
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; },
	blocksByIdMap: { [blockId: string]: ReportBlockFormModel; },
	isRepeating: boolean,
	calculationMap: CalculationMapType,
	formValues: Record<string, string>[] | string,
	change: (prop: string, value: string) => void
) => {
	const { reportBlockVirtualId, operationType, unit, calculatedFieldOptions } = field;
	const fieldId = field.virtualId;
	const updatedFields: { id: string; value: Nullable<string>; index?: number; }[] = [];

	if (!operationType) {
		throw Error('Field is not of calculated field type');
	}

	if (calculatedFieldOptions) {
		if (isRepeating) {
			// If field is repeating get all values as '{[index in repeatable block]: array of operand values }' and recalculate field
			const values = calculatedFieldOptions.reduce((_acc: Record<number, string[]>, _option) => {
				const { id, type, constant } = _option;

				if (!id) {
					throw new Error('Missing id of field');
				}

				if (type === OperandType.CONSTANT) {
					return _getNonRepeatableValuesMap(constant?.toString() ?? '', reportBlockVirtualId, _acc, formValues);
				}

				const _field = fieldsByIdMap[id];

				if (_field.fieldType === ReportBlockFieldEnum.Type.COMPLETION) {
					const blockName = _getBlockName(reportBlockVirtualId);
					const blockValues = formValues?.[blockName];
					const completionValue = blockValues[_getCompletionFieldName(id)] as string;
					return _getNonRepeatableValuesMap(completionValue, reportBlockVirtualId, _acc, formValues);
				} else {
					return _getRepeatableFieldsMap(id, reportBlockVirtualId, _field.unit, _acc, formValues);
				}
			}, {} as Record<number, string[]>);
			Object.entries(values).forEach(([_index, _values]) => _calculate(fieldId, reportBlockVirtualId, unit, operationType, _values, change, +_index));
		} else {
			// If field is not repeating get all operand values and recalculate
			const values = calculatedFieldOptions.flatMap((_option) => {
				const { id, constant, type } = _option;

				if (type === OperandType.CONSTANT) {
					return (constant ?? '').toString();
				}

				if (!id) {
					throw new Error('Missing id of field');
				}
				const _field = fieldsByIdMap[id];
				if (!_field) {
					throw new Error('Missing field');
				}

				const _block = blocksByIdMap[_field.reportBlockVirtualId];
				if (!_block) {
					throw new Error('Missing block');
				}

				const operand = {
					virtualId: id,
					blockId: _field.reportBlockVirtualId,
					fieldType: _field.fieldType,
					unit: _field.unit,
					isRepeating: _block.isRepeating,
					constant: null,
					type: OperandType.FIELD,
				};
				const key = `${FIELD_PREFIX}#${_option.id}`;
				const repeatingValuesMapper = (_operand) => _operand[key];
				return _getFieldValue(operand, formValues, repeatingValuesMapper);
			});
			const resultValue = _calculate(fieldId, reportBlockVirtualId, unit, operationType, values, change);
			updatedFields.push({ id: fieldId, value: resultValue });
		}
	}
	updatedFields.forEach((_field) => calculateOnFieldValueChange(_field.id, _field.value, calculationMap, formValues, change, _field.index));
};

/**
* Called when Completion fields value is changed
* @param id id of completion field
* @param newValue new value of completion field
* @param calculationMap map of calculations by field Id
* @param selector redux form field selector function
* @param change redux form field change function
 */
export const calculateOnCompletionFieldChange = async (
	id: string,
	newValue: boolean,
	calculationMap: CalculationMapType,
	formValues: Record<string, string>[] | string,
	change: (prop: string, value: string) => void
) => {
	const calculations = calculationMap[id];
	if (calculations) {
		const updatedFields = Object.entries(calculations).reduce<CalculateReturnType[]>((_acc, [_resultFieldId, _calculation]) => {
			const { operands, blockId, operationType, unit, isRepeating } = _calculation;

			if (isRepeating) {
				// If field is repeating get all values as '{[index in repeatable block]: array of operand values }' and recalculate field
				const values = operands.reduce((_agg: Record<number, string[]>, _option: Operand) => {
					if (_option.fieldType === ReportBlockFieldEnum.Type.COMPLETION) {
						return _getNonRepeatableValuesMap(newValue.toString(), blockId, _agg, formValues);
					}
					return _getRepeatableFieldsMap(_option.virtualId, blockId, _option.unit, _agg, formValues);
				}, {} as Record<number, string[]>);
				const fields = Object.entries(values).map(([_index, _values]) => {
					const resultValue = _calculate(_resultFieldId, blockId, unit, operationType, _values, change, +_index);
					return { id: _resultFieldId, value: resultValue };
				});
				return _acc.concat(fields);
			} else {
				const replaceCondition = (operandId: string) => operandId === id;
				const result = _calculateWithReplace(_calculation, _resultFieldId, replaceCondition, newValue.toString(), formValues, change);
				return _acc.concat(result);
			}
		}, []);
		updatedFields.forEach((_field) => calculateOnFieldValueChange(_field.id, _field.value, calculationMap, formValues, change, _field.index));
	}
};

