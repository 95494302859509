import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';

import { EmployeeOptionVM } from 'ab-viewModels/employee/extendedOption.viewModel';
import WorkOrderResourceLookupViewModel from 'ab-viewModels/workOrderResourceLookup.viewModel';

import { AVAILABLE_EMPLOYEE_STATUS } from 'ab-constants/employee';

import { RootState } from 'af-reducers';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import Dropdown from 'af-fields/Dropdown';

import StatusLabel from 'af-components/StatusLabel';

import { getSelectedEmployeeMap } from 'af-utils/workOrderModal.util';

import LaborOptionItem from './LaborOptionItem';

const _getCode = <T extends { code: string; }>(element: T) => element.code;

type LaborOption = EmployeeOptionVM & { disabled: boolean; };
type LaborSection = { title: string; options: LaborOption[]; };

type EmployeeSectionsAvailabilityType = {
	sections: { [status: string]: LaborOption[]; };
	availability: { [status: string]: boolean; };
};

interface OwnProps {
	disabled: boolean;
	dueDate: string;
	employees: EmployeeOptionVM[];
	name: string;
	onLazyLoad: (isLazyLoaded: boolean) => void;
	propName: string;
	resetTimer: () => void;
	showResourceAssignConfirmationModal: (resourceType: AssignableResourceType) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const getAvailabilitySort = (availability: { [status: string]: boolean; }) => {
	return (
		[statusA]: [string, LaborOption[]],
		[statusB]: [string, LaborOption[]]
	) => {
		const availabilityA = availability[statusA];
		const availabilityB = availability[statusB];
		if (availabilityA === availabilityB) {
			return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
		} else if (availabilityA) {
			return -1;
		} else {
			return 1;
		}
	};
};

const laborSectionMapper = ([title, options]: [string, LaborOption[]]): LaborSection => {
	return {
		title,
		options,
	};
};

const getLaborStatusReducer = (selectedEmployeeIds: { [id: number]: true; }) => {
	return (acc: EmployeeSectionsAvailabilityType, employee: EmployeeOptionVM) => {
		const { employeeStatusName, available } = employee;
		const status = employeeStatusName ?? AVAILABLE_EMPLOYEE_STATUS;

		if (!acc.sections?.[status]) {
			acc.sections[status] = [];
		}
		acc.sections[status].push({
			...employee,
			disabled: !!selectedEmployeeIds?.[employee.id],
		});
		acc.availability[status] = available;
		return acc;
	};
};

const filterLaborItem = (option: EmployeeOptionVM, searchText: string) => {
	if (!searchText) {
		return true;
	}
	const { firstName, lastName, formattedCode, assignments: _assignments } = option;
	const assignments = _assignments?.length ? ` - ${_assignments.map(_getCode).join(', ').toLowerCase()}` : '';

	const searchWords = searchText.replace(/\s\s+/g, ' ').toLowerCase().split(' ');
	const searchableFields = [
		firstName?.toLowerCase?.() ?? '',
		lastName?.toLowerCase?.() ?? '',
		formattedCode?.toLowerCase?.() ?? '',
		assignments,
	];

	for (const _searchWord of searchWords) {
		let _matched = false;
		for (const _value of searchableFields) {
			if (_value.includes(_searchWord)) {
				_matched = true;
				break;
			}
		}
		if (!_matched) {
			// All search words must match at least one filter by prop
			return false;
		}
	}
	return true;
};

const renderLaborOptionItem = (option: EmployeeOptionVM, searchText: string) => <LaborOptionItem {...option} searchText={searchText} />;

const mapEmployeesToOptions = (employees: EmployeeOptionVM[], selectedEmployeeIds: { [id: number]: true; }) => {
	const { sections, availability } = employees.reduce(
		getLaborStatusReducer(selectedEmployeeIds),
		{ sections: {}, availability: {} } as EmployeeSectionsAvailabilityType
	);

	const options = Object.entries(sections)
		.sort(getAvailabilitySort(availability))
		.map(laborSectionMapper);

	return {
		options,
		availability,
	};
};

const LaborItem: React.FC<Props> = (props: Props) => {
	const { name, propName, disabled, onLazyLoad, resetTimer, showResourceAssignConfirmationModal, employees, selectedEmployeeIds } = props;

	const [options, setOptions] = React.useState<LaborSection[]>([]);
	const [statusAvailabilityMap, setStatusAvailabilityMap] = React.useState<{ [status: string]: boolean; }>({});

	React.useEffect(() => {
		const {
			options: newOptions,
			availability: newAvailability,
		} = mapEmployeesToOptions(employees, selectedEmployeeIds);
		setOptions(newOptions);
		setStatusAvailabilityMap(newAvailability);
	}, [employees, selectedEmployeeIds]);

	const onChange = React.useCallback((option: LaborOption) => {
		if (!option.available) {
			showResourceAssignConfirmationModal(AssignableResourceType.EMPLOYEE);
		}
		resetTimer();
	}, [resetTimer, showResourceAssignConfirmationModal]);

	const renderSectionHeader = React.useCallback((section: LaborSection) => {
		const employeeStatusName = section.title;

		const available = statusAvailabilityMap[employeeStatusName];
		return (
			<div className="resource-lookup__resource-status">
				<StatusLabel isAvailable={available ?? true} label={employeeStatusName ?? AVAILABLE_EMPLOYEE_STATUS} />
			</div>
		);
	}, [statusAvailabilityMap]);

	return (
		<div className="resource-lookup__item">
			<Field
				component={Dropdown}
				disabled={disabled}
				filterable={true}
				filterBy={filterLaborItem}
				hideErrorText={true}
				id={name}
				isArrayField={true}
				isButtonFullWidth={true}
				isStandalone={true}
				name={name}
				onLazyLoad={onLazyLoad}
				onValueChange={onChange}
				placeholder="Choose laborer"
				propName={propName}
				renderMenuItem={renderLaborOptionItem}
				renderSectionHeader={renderSectionHeader}
				sectionOptionsKey="options"
				sections={options}
				sectionTitleKey="title"
				useSectionList
				valueKey="id"
				withCaret={true}

			/>
		</div>
	);
};

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const selectedResourceLookups = selector(state, 'workOrderResourceLookups') as WorkOrderResourceLookupViewModel[];
	return {
		selectedEmployeeIds: getSelectedEmployeeMap(selectedResourceLookups),
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(LaborItem);
