import { Dispatch, AnyAction } from 'redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import ToolTableViewModel from 'ab-viewModels/tool/table.viewModel';
import OptionVM from 'ab-viewModels/tool/option.viewModel';
import ToolVM from 'ab-viewModels/tool/edit.viewModel';
import ListVM from 'ab-viewModels/tool/list.viewModel';

import UpsertToolRM from 'ab-requestModels/tool/upsert.requestModel';

import { GetRootState } from 'af-reducers';

export function create(form: UpsertToolRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.RESOURCES.TOOL.CREATE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(id: number, data: UpsertToolRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.RESOURCES.TOOL.FIND_BY_ID(id), data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ToolVM>(API.V1.RESOURCES.TOOL.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findTakenSerialList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ListVM[]>(API.V1.RESOURCES.TOOL.FIND_SERIAL_NUMBER_LIST);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteTool(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.TOOL.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<ToolTableViewModel>>(API.V1.RESOURCES.TOOL.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<OptionVM[]>(API.V1.RESOURCES.TOOL.FIND_OPTION_LIST);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
