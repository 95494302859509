import * as React from 'react';
import { compose } from 'redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'react-bootstrap';

import * as WorkOrderActions from 'af-actions/workOrder';

import { RootState } from 'af-reducers/index';

import WorkOrderConfirmationViewModel from 'ab-viewModels/workOrderConfirmation.viewModel';

import { DOWNLOAD_ORDER_CONFIRMATION } from 'af-constants/reduxForms';

import Loading from 'af-components/DocumentViewer/Loading';
import { CircleLoadingIndicator } from 'af-components/CircleLoadingIndicator';
import CLIENT from 'af-constants/routes/client';

import WorkOrderConfirmation from '../Shared/WorkOrderConfirmation';
import Breadcrumbs from 'af-components/Breadcrumbs';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

interface PathParams {
	workOrderId: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;
type Props = OwnProps & ConnectedProps<typeof connector>;

const ConfirmationPrivate: React.FC<Props> = (props) => {
	const {
		match: { params: { workOrderId } },
		location: { state: { orgAlias, originUrl, originLabel } },
		findForConfirmationById,
		findWorkOrderById,
		downloadWorkOrderConfirmationPdf,
		resetWorkOrder,
		companyName,
		isDownloading,
	} = props;

	const [workOrder, setWorkOrder] = React.useState<Nullable<WorkOrderConfirmationViewModel>>(null);
	const notificationSnackbar = useNotificationSnackbar();

	React.useEffect(() => {
		const fetchData = async () => {
			await findWorkOrderById(+workOrderId);
			const _workOrder = await findForConfirmationById(+workOrderId);
			setWorkOrder(_workOrder);
		};
		fetchData();
		return () => {
			resetWorkOrder();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const downloadPdf = React.useCallback(async () => {
		if (!workOrder?.code) {
			throw new Error('Work order not defined');
		}
		const msg = notificationSnackbar.info('Started preparing for download');
		notificationSnackbar.removeNotificationSnackbar(msg);
		await downloadWorkOrderConfirmationPdf(workOrderId, `${workOrder?.code}_confirmation.pdf`, notificationSnackbar);
	}, [downloadWorkOrderConfirmationPdf, workOrder, workOrderId, notificationSnackbar]);

	if (!workOrder) {
		return (<Loading />);
	}

	const orderCode = workOrder?.code;
	const url = originUrl || CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName);
	const _originUrl = `${url}?order=${orderCode ?? ''}`;
	const _originLabel = originLabel || 'Schedule Board';

	return (
		<div className="form-segment form-segment--midi">
			<Breadcrumbs
				items={
					[
						{ label: _originLabel, url: _originUrl },
						{ label: 'Work Order Confirmation' },
					]
				}
			/>
			<div className="form-box form-box--full-padded">
				<WorkOrderConfirmation workOrder={workOrder} />
			</div>
			<Button
				className="btn btn-primary btn--floating btn--big-icon"
				onClick={!isDownloading ? downloadPdf : undefined}
				variant="primary"
			>
				{isDownloading ?
					<CircleLoadingIndicator /> :
					<span className="icon-print" />
				}
			</Button>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		isDownloading: state.http.submitting.includes(DOWNLOAD_ORDER_CONFIRMATION),
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findWorkOrderById: WorkOrderActions.findWorkOrderById,
		findForConfirmationById: WorkOrderActions.findForConfirmationById,
		downloadWorkOrderConfirmationPdf: WorkOrderActions.downloadWorkOrderConfirmationPdf,
		resetWorkOrder: WorkOrderActions.resetWorkOrder,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(ConfirmationPrivate);
