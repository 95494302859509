import { Reducer } from 'redux';

import * as ActionTypes from 'af-constants/actionTypes';

import { ActionType } from 'af-actions/companies';

import { CompanyStoreState } from 'af-models/company.models';

const initialState: CompanyStoreState = {
	company: null,
	acceptedMembers: [],
	rejectedMembers: [],
};

const CompanyReducer: Reducer<CompanyStoreState, ActionType> = (state: CompanyStoreState = initialState, action: ActionType) => {
	switch (action.type) {
		case ActionTypes.GET_COMPANY:
			return { ...state, company: { ...action.payload } };
		case ActionTypes.GET_COMPANY_NOTIFICATION_SETTINGS:
			if (!state.company) {
				throw new Error('User not logged in');
			}
			return { ...state, company: { ...state.company, notification: { ...state.company.notification, ...action.payload } } };
		case ActionTypes.SET_ACCEPTED_MEMBERS:
			return { ...state, acceptedMembers: action.payload };
		case ActionTypes.SET_REJECTED_MEMBERS:
			return { ...state, rejectedMembers: action.payload };
		default:
			return state;
	}
};

export default CompanyReducer;
