import { Reducer } from 'redux';

import * as ActionTypes from 'af-constants/actionTypes';

import { UserStoreState } from 'af-models/user.models';

import * as AuthenticationActions from 'af-actions/authentication';

import * as UserUtils from 'af-utils/user.util';

const initialState: UserStoreState = {
	userData: null,
	isAuthenticated: false,
	companyData: null,
	organizationData: null,
	forgottenEmail: null,
	pendingPhoneNumber: null,
};

const UserReducer: Reducer<UserStoreState, AuthenticationActions.ActionType> = (
	state: UserStoreState = initialState,
	action: AuthenticationActions.ActionType
) => {
	switch (action.type) {
		case ActionTypes.UPDATE_USER:
		case ActionTypes.LOGIN_USER:
			return { ...state, userData: { ...state.userData, ...action.payload }, isAuthenticated: true };
		case ActionTypes.AUTH_SUCCESS:
			return { ...state, isAuthenticated: true, userData: { ...state.userData!, isFinalized: action.payload } };
		case ActionTypes.AUTH_FAIL:
			return { ...state, isAuthenticated: false };
		case ActionTypes.SET_CURRENT_COMPANY:
			const _currentCompany = UserUtils.findCurrentCompany(state.organizationData, action.companyId);
			return { ...state, companyData: _currentCompany ? { ..._currentCompany } : null };
		case ActionTypes.UPDATE_CURRENT_COMPANY:
			return UserUtils.updateCurrentCompany(state, action.payload);
		case ActionTypes.SET_FORGOTTEN_EMAIL:
			return { ...state, forgottenEmail: action.payload };
		case ActionTypes.SET_CURRENT_ORGANIZATION:
			return { ...state, organizationData: { ...action.payload } };
		case ActionTypes.SET_PENDING_PHONE_NUMBER:
			return { ...state, pendingPhoneNumber: action.payload };
		default:
			return state;
	}
};

export default UserReducer;
