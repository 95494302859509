import * as React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';

import CLIENT from 'af-constants/routes/client';

const Error404: React.FC = () => {

	const location = useLocation();

	return (
		<div className="form-segment form-segment--mini">
			<div className="form-box form-box--standalone form-box--padded">
				<div className="form-box__icon-container">
					<span className="form-box__icon icon-not_found" />
				</div>
				<h2>Ooops! Looks like the page is gone...</h2>
				<h4 className="light">Sorry, but the page you are looking for has not been found!</h4>
				<div className="form-box__footer">
					<Link className="btn btn-primary" to={CLIENT.HOME(location.state.orgAlias)}>Back to Home</Link>
				</div>
			</div>
		</div>
	);

};

export default Error404;
