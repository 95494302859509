import { Dispatch, AnyAction } from 'redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import ToolRepairTableViewModel from 'ab-viewModels/toolRepair/table.viewModel';
import UpdateViewModel from 'ab-viewModels/toolRepair/update.viewModel';

import UpsertToolRM from 'ab-requestModels/toolRepair/upsert.requestModel';

import { GetRootState } from 'af-reducers';

export function create(form: UpsertToolRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.TOOL_REPAIR.CREATE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(id: number, form: UpsertToolRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.TOOL_REPAIR.FIND_BY_ID(id), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<UpdateViewModel>(API.V1.TOOL_REPAIR.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<ToolRepairTableViewModel>>(API.V1.TOOL_REPAIR.FIND_TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
