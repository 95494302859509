enum EquipmentUtilizationLevel {
	TYPE = 'TYPE',
	GROUP = 'GROUP',
	CATEGORY = 'CATEGORY',
	EQUIPMENT_COST = 'EQUIPMENT_COST',
	EQUIPMENT = 'EQUIPMENT'
}

export enum EquipmentUtilizationLevelLabel {
	TYPE = 'Equipment Utilization - Type',
	GROUP = 'Category Group',
	CATEGORY = 'Category',
	EQUIPMENT_COST = 'Subcategory',
	EQUIPMENT = 'Equipment'
}

function getUpperEquipmentUtilizationLevel(level: EquipmentUtilizationLevel): Nullable<EquipmentUtilizationLevel> {
	switch (level) {
		case EquipmentUtilizationLevel.EQUIPMENT:
			return EquipmentUtilizationLevel.EQUIPMENT_COST;
		case EquipmentUtilizationLevel.EQUIPMENT_COST:
			return EquipmentUtilizationLevel.CATEGORY;
		case EquipmentUtilizationLevel.CATEGORY:
			return EquipmentUtilizationLevel.GROUP;
		case EquipmentUtilizationLevel.GROUP:
			return EquipmentUtilizationLevel.TYPE;
		case EquipmentUtilizationLevel.TYPE:
		default:
			return null;
	}
}

export function getAllUpperEquipmentUtilizationLevels(level: EquipmentUtilizationLevel): EquipmentUtilizationLevel[] {
	const upperLevel = getUpperEquipmentUtilizationLevel(level);
	return upperLevel ? [...getAllUpperEquipmentUtilizationLevels(upperLevel), upperLevel] : [];
}

export default EquipmentUtilizationLevel;
