import { EmailTypes, EmailTypesArray, PhoneTypes, PhoneTypesArray } from 'acceligent-shared/enums/contactMethodType';
import CountryCode from 'acceligent-shared/enums/countryCode';
import { stateAbbreviation } from 'acceligent-shared/enums/state';

import Address from 'acceligent-shared/models/address';
import Contact from 'acceligent-shared/models/contact';
import ContactAddress from 'acceligent-shared/models/contactAddress';
import ContactMethod from 'acceligent-shared/models/contactMethod';

import { getShortAddress, getStreetAddress } from 'acceligent-shared/utils/address';

export class W_Contact_Create_VM {
	id: number;
	fullName: string;
	companyName: Nullable<string>;
	title: Nullable<string>;
	addresses: W_Contact_Create_VM_ContactAddress[];
	emails: W_Contact_Create_VM_ContactMethod[];
	phones: W_Contact_Create_VM_ContactMethod[];
	isUnsaved: boolean;

	constructor(contact: Contact) {
		this.id = contact.id;
		this.companyName = contact.companyName;
		this.fullName = contact.fullName;
		this.title = contact.title;
		this.addresses = W_Contact_Create_VM_ContactAddress.bulkConstructor(contact.addresses ?? []);

		const groupedMethods = W_Contact_Create_VM_ContactMethod.groupedBulkConstructor(contact.contactMethods ?? []);
		this.emails = groupedMethods.emails;
		this.phones = groupedMethods.phones;

		this.isUnsaved = contact.isUnsaved;
	}
}

type W_Contact_Create_VM_GroupedContactMethods = {
	emails: W_Contact_Create_VM_ContactMethod[];
	phones: W_Contact_Create_VM_ContactMethod[];
};

class W_Contact_Create_VM_ContactMethod {
	id: number;
	contactId: number;
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode: Nullable<CountryCode>;
	extension: Nullable<string>;

	constructor(contactMethod: ContactMethod) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
		this.countryCode = contactMethod.countryCode;
		this.extension = contactMethod.extension;
	}

	static groupedBulkConstructor(contactMethods: ContactMethod[]): W_Contact_Create_VM_GroupedContactMethods {
		const result: W_Contact_Create_VM_GroupedContactMethods = {
			phones: [],
			emails: [],
		};

		for (const _cm of contactMethods) {
			if (PhoneTypesArray.indexOf(_cm.type) !== -1) {
				result.phones.push(new W_Contact_Create_VM_ContactMethod(_cm));
			} else if (EmailTypesArray.indexOf(_cm.type) !== -1) {
				result.emails.push(new W_Contact_Create_VM_ContactMethod(_cm));
			}
		}

		return result;
	}
}

class W_Contact_Create_VM_Address {
	id: number;
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	stateAbbreviation: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	shortAddress: string;
	latitude: number;
	longitude: number;

	constructor(address: Address) {
		this.id = address.id;
		this.street = getStreetAddress(address);
		this.streetNumber = address.streetNumber;
		this.route = address.route;
		this.suite = address.suite;
		this.city = address.locality;
		this.state = address.aa1;
		this.stateAbbreviation = address.aa1 && stateAbbreviation[address.aa1];
		this.aa2 = address.aa2;
		this.aa3 = address.aa3;
		this.zip = address.postalCode;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode;
		this.country = address.country;
		this.shortAddress = getShortAddress(address);
		this.longitude = address.longitude;
		this.latitude = address.latitude;
	}
}

class W_Contact_Create_VM_ContactAddress {
	id: number;
	contactId: number;
	addressId: number;
	address: W_Contact_Create_VM_Address;

	constructor(contactAddress: ContactAddress) {
		this.id = contactAddress.id;
		this.contactId = contactAddress.contactId;
		this.addressId = contactAddress.addressId;
		this.address = contactAddress.address && new W_Contact_Create_VM_Address(contactAddress.address);
	}

	static bulkConstructor(contactAddresses: ContactAddress[]): W_Contact_Create_VM_ContactAddress[] {
		return contactAddresses.map(W_Contact_Create_VM_ContactAddress._mapConstructor);
	}

	private static _mapConstructor(address: ContactAddress): W_Contact_Create_VM_ContactAddress {
		return new W_Contact_Create_VM_ContactAddress(address);
	}
}

