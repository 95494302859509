import * as React from 'react';
import { Field } from 'redux-form';

import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';

import { formSectionClass, formSectionClassColumn2, formSectionClassColumn4, formSectionClassRow, formSectionTitleClass } from '../../helpers';
import JobUpsertFM from '../../formModel';

import styles from '../../styles.module.scss';

const PublicSafetySection: React.FC = () => {
	const checkboxStyle = React.useMemo(() => [formSectionClassColumn2, styles['job-form__tab-content__form-section__row__centered-column']].join(' '), []);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Public Safety
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="vehicleTraffic"
						label="Vehicle Traffic"
						name={JobUpsertFM.getAttributeName('vehicleTraffic')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="vehicleTrafficDescription"
						label="Description"
						name={JobUpsertFM.getAttributeName('vehicleTrafficDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="pedestrianCrossing"
						label="Pedestrian Crossing"
						name={JobUpsertFM.getAttributeName('pedestrianCrossing')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="pedestrianCrossingDescription"
						label="Description"
						name={JobUpsertFM.getAttributeName('pedestrianCrossingDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(PublicSafetySection);
