import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import Label from 'af-components/Label';

import AssociatedSubjobVM from 'ab-viewModels/workRequest/associatedSubjob.viewModel';

import styles from './styles.module.scss';

interface OwnProps {
	timeAllocationEntry: AssociatedSubjobVM;
	redirectToVirtualFieldReport: (subjob: Nullable<AssociatedSubjobVM>) => void;
	isProject: boolean;
}

type Props = OwnProps;

const TimeAllocationEntry: React.FC<Props> = (props: Props) => {
	const { timeAllocationEntry, redirectToVirtualFieldReport, isProject } = props;

	const onRedirect = React.useCallback(() => {
		redirectToVirtualFieldReport(timeAllocationEntry);
	}, [redirectToVirtualFieldReport, timeAllocationEntry]);

	return (
		<Row className={styles['subjob-row']}>
			<Col className={styles['subjob-link']} sm={1}>
				<span className="icon-external" onClick={onRedirect} />
			</Col>
			<Col className={styles['subjob-row-column']} sm={3}>
				<Label
					className={isProject ? undefined : 'subjob'}
					text={isProject ? 'PROJECT' : 'SUB-JOB'}
				/>
				{timeAllocationEntry.jobCode.toUpperCase()}
			</Col>
			<Col className={styles['subjob-row-column']} sm={3}>
				{timeAllocationEntry.startDate}
			</Col>
			<Col className={styles['subjob-row-column']} sm={3}>
				{timeAllocationEntry.projectManager}
			</Col>
			<Col className={styles['subjob-row-column']} sm={3}>
				{timeAllocationEntry.status.replace(/_/g, ' ')}
			</Col>
			<Col className={styles['subjob-row-column']} sm={3}>
				{timeAllocationEntry.customer}
			</Col>
		</Row>

	);
};

export default React.memo(TimeAllocationEntry);
