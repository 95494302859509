enum WorkOrderMetricIndex {
	revenue,
	manHourAverage,
	jobHours,
	shopHours,
	travelHours,
	workDescription
}

export default WorkOrderMetricIndex;
