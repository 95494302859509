import * as React from 'react';
import { Button } from 'react-bootstrap';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	note: Nullable<string>;
	openNoteModal: () => void;
	showNoteActionButton: boolean;
}

const TimeSheetNote = (props: OwnProps) => {
	const { note, openNoteModal, showNoteActionButton } = props;

	const noteClassName = bemElement('time-sheet-list', 'row__info__note', [showNoteActionButton ? '' : 'readonly']);

	return (
		<div className={noteClassName}>
			{note ?
				<>
					<div className={bemElement('time-sheet-list__row__info', 'note__edit__header')}>
						<span>Time Card Note</span>
						{showNoteActionButton &&
							<div className={bemElement('time-sheet-list__row__info', 'note__edit__action')} >
								<Button className="btn btn--link" onClick={openNoteModal}>
									<span className="icon-edit" />
									<span>Edit Time Card Note</span>
								</Button>
							</div>
						}
					</div>
					<div className={bemElement('time-sheet-list__row__info', 'note__edit__text')}>
						{note}
					</div>
				</> :
				<div className={bemElement('time-sheet-list__row__info', 'note__add')}>
					{showNoteActionButton &&
						<Button className="btn btn--link" onClick={openNoteModal}>
							<span className="icon-plus" />
							<span>Add Time Card Note</span>
						</Button>
					}
				</div>
			}
		</div>
	);
};

export default React.memo(TimeSheetNote);

