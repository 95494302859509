import * as React from 'react';
import { connect } from 'react-redux';

import { SCHEDULE_BOARD_MAX_ITEMS_IN_ROW, SCHEDULE_BOARD_MAX_TOOLBAR_AVAILABLE_EMPLOYEE_COLUMNS } from 'ab-constants/scheduleBoard';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import { RootState } from 'af-reducers';

import DroppableComponent from './Droppable';

import { EmployeeModalProps } from '../../Shared/ModalProps';

interface StateProps {
	isDisabled: boolean;
}

interface OwnProps extends EmployeeModalProps {
	droppableId: string;
	draggablePrefix: string; // to distinguish if it's board or toolbar dragged employee
	employeeIds: number[];
	isToolbar: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	dueDate: string;
	available?: boolean;
	wrappedColumns?: boolean;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	hasReason?: boolean;
	isCardDisabled?: boolean;
	disabled?: boolean;
}

type Props = OwnProps & StateProps;

class EmployeesDroppable extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		wrappedColumns: false,
		isToolbar: false,
		isCopyPlaceholder: false,
		isDragAndDropDisabled: false,
		hasPermissionsToEditScheduleBoard: true,
	};

	render() {
		const {
			dueDate,
			droppableId,
			employeeIds,
			draggablePrefix,
			wrappedColumns,
			isCardDisabled,
			isToolbar,
			isWorkOrderCanceled,
			isCopyPlaceholder,
			hasReason,
			isDisabled,
			available,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			setEmployeeModalData,
			setEmployeeModalVisibility,
		} = this.props;

		let className = 'employee-droppable';
		className += !employeeIds.length ? ' empty-droppable' : '';
		className += wrappedColumns ? ' wrapped-columns' : '';

		if (!isToolbar) {
			// case for employees within card
			const numberOfColumns = Math.max(1, Math.ceil(employeeIds.length / SCHEDULE_BOARD_MAX_ITEMS_IN_ROW));
			return (
				<div className="employee-droppable-columns-wrapper">
					{
						Array(numberOfColumns || 1).fill(1).map((_, _index: number) => (
							<DroppableComponent
								available={available}
								className={`${className} ${_index > 0 ? ' multiple-droppable-column' : ''}`}
								draggablePrefix={draggablePrefix}
								droppableId={`${droppableId}#${_index}`}
								dueDate={dueDate}
								employeeIds={employeeIds}
								endIndex={(_index + 1) * SCHEDULE_BOARD_MAX_ITEMS_IN_ROW}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								isCardDisabled={isCardDisabled}
								isCopyPlaceholder={isCopyPlaceholder}
								isDisabled={isDisabled}
								isDragAndDropDisabled={isDragAndDropDisabled}
								isToolbar={isToolbar}
								isWorkOrderCanceled={isWorkOrderCanceled}
								key={`${droppableId}#${_index}`}
								setEmployeeModalData={setEmployeeModalData}
								setEmployeeModalVisibility={setEmployeeModalVisibility}
								startIndex={_index * SCHEDULE_BOARD_MAX_ITEMS_IN_ROW}
							/>
						))
					}
				</div>
			);
		} else if (!hasReason) {
			// case for available employees in toolbar,
			// number of columns defined in SCHEDULE_BOARD_MAX_TOOLBAR_AVAILABLE_EMPLOYEE_COLUMNS
			const numberOfColumns = SCHEDULE_BOARD_MAX_TOOLBAR_AVAILABLE_EMPLOYEE_COLUMNS;
			const maxItemsPerRow = Math.max(1, Math.ceil(employeeIds.length / 2));
			return (
				<div className="employee-toolbar-multicolumn-wrapper">
					{Array(numberOfColumns).fill(1).map((i, index) => (
						<DroppableComponent
							available={available}
							className={`${className} employee-toolbar-multicolumn`}
							draggablePrefix={draggablePrefix}
							droppableId={`${droppableId}#${index}`}
							dueDate={dueDate}
							employeeIds={employeeIds}
							endIndex={(index + 1) * maxItemsPerRow}
							hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
							hasReason={hasReason}
							isCopyPlaceholder={isCopyPlaceholder}
							isDisabled={isDisabled}
							isDragAndDropDisabled={isDragAndDropDisabled}
							isToolbar={isToolbar}
							isWorkOrderCanceled={isWorkOrderCanceled}
							key={`${droppableId}#${index}`}
							setEmployeeModalData={setEmployeeModalData}
							setEmployeeModalVisibility={setEmployeeModalVisibility}
							startIndex={index * maxItemsPerRow}
						/>
					))}
				</div>
			);
		}

		// case for unavailable employees in toolbar, single column
		return (
			<DroppableComponent
				available={available}
				className={className}
				draggablePrefix={draggablePrefix}
				droppableId={droppableId}
				dueDate={dueDate}
				employeeIds={employeeIds}
				endIndex={employeeIds.length}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				hasReason={hasReason}
				isCopyPlaceholder={isCopyPlaceholder}
				isDisabled={isDisabled}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isToolbar={isToolbar}
				isWorkOrderCanceled={isWorkOrderCanceled}
				setEmployeeModalData={setEmployeeModalData}
				setEmployeeModalVisibility={setEmployeeModalVisibility}
				startIndex={0}
			/>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { employeeIds, dueDate, disabled } = ownProps;
	const {
		draggedEmployeeId,
		copiedEmployeeId,
		weeklyViewDateWithToolbar,
		draggedResourceId,
		workOrdersByDateDictionary,
		scheduleBoardView,
	} = state.scheduleBoard;
	const isIncluded: boolean = employeeIds.some((_employeeId: number) => _employeeId === draggedEmployeeId || _employeeId === copiedEmployeeId);

	const disableDraggingWhenToolbarOpened = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW &&
		(!weeklyViewDateWithToolbar || weeklyViewDateWithToolbar !== dueDate);

	const isEquipment = draggedResourceId !== null && !!workOrdersByDateDictionary?.[dueDate]?.workOrderResourceLookups?.[draggedResourceId]?.equipmentId;

	const isDisabled: boolean = disableDraggingWhenToolbarOpened ||
		!!disabled ||
		isEquipment ||
		isIncluded ||
		!!copiedEmployeeId;

	return {
		isDisabled,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(EmployeesDroppable);
