import * as React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import ReportBlockType from 'acceligent-shared/enums/reportBlockType';

import { DroppableZones } from 'ab-enums/reportTypeBuilder.enum';

import * as ReportTypeBuilderUtils from 'af-utils/reportTypeBuilder.util';

import { getFormDraggableZoneListAttributes } from '../../Shared/helpers';

import CalculatedBlock from './CalculatedBlock';
import ReportTypeCustomTypeFormDraggableBlock from './DraggableBlock';

import { ReportTypeBlockFormModel, ReportBlockFormModel, ReportBlockFieldFormModel } from '../../../Shared/formModel';

interface OwnProps {
	draggingElementSource: Nullable<DroppableZones>;
	ids: string[];
	isPrimary: boolean;
	mainBlockId: Nullable<string>;
	onAddBlock: (dropZone: DroppableZones) => void;
	openEditBlockNameModal: (reportTypeBlockId: string) => void;
	openFieldModal: (isPrimary: boolean, isTotalBlock: boolean, reportTypeBlockId: Nullable<string>, fieldId: Nullable<string>) => void;
	removeBlock: (id: string, isPrimary: boolean) => void;
	removeField: (blockId: string, fieldId: string) => void;
	removeMainBlock: (isPrimary: boolean) => void;
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; };
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; };
	reportTypeBlocksByIdMap: { [id: string]: ReportTypeBlockFormModel; };
	showBlocksList: boolean;
	upsertField: (form: ReportBlockFieldFormModel, isPrimary: boolean, isTotalBlock: boolean, reportTypeBlockId: Nullable<string>) => void;
}

type Props = OwnProps;

const ReportTypeCustomTypeFormDroppableZone: React.FC<Props> = (props: Props) => {
	const {
		onAddBlock,
		isPrimary,
		removeBlock,
		removeMainBlock,
		openFieldModal,
		openEditBlockNameModal,
		removeField,
		upsertField,
		draggingElementSource,
		reportFieldsByIdMap,
		reportBlocksByIdMap,
		ids,
		showBlocksList,
		mainBlockId,
		reportTypeBlocksByIdMap,
	} = props;

	const [mainDroppableId] = React.useState<DroppableZones>(isPrimary ? DroppableZones.PRIMARY_MAIN_BLOCK_ID : DroppableZones.SECONDARY_MAIN_BLOCK_ID);
	const [listDroppableId] = React.useState<DroppableZones>(isPrimary ? DroppableZones.PRIMARY_LIST_ID : DroppableZones.SECONDARY_LIST_ID);
	const [expandedBlocks, setExpandedBlocks] = React.useState<{ [id: string]: boolean; }>({});
	const [isMainExpanded, setIsMainExpanded] = React.useState<boolean>(false);

	const removeItem = React.useCallback((id: string) => {
		removeBlock(id, isPrimary);
	}, [removeBlock, isPrimary]);

	const removeMain = React.useCallback(() => {
		removeMainBlock(isPrimary);
	}, [removeMainBlock, isPrimary]);

	const onTogglePrimary = React.useCallback((id: string) => {
		setExpandedBlocks({ ...expandedBlocks, [id]: !expandedBlocks[id] });
	}, [expandedBlocks]);

	const onToggleMain = React.useCallback(() => {
		setIsMainExpanded(!isMainExpanded);
	}, [isMainExpanded]);

	const renderCalculatedBlock = (item: ReportTypeBlockFormModel, index: number) => {
		const block = reportBlocksByIdMap[item.reportBlockVirtualId];

		return (
			<CalculatedBlock
				block={block}
				draggingElementSource={draggingElementSource}
				editBlock={openEditBlockNameModal}
				index={index}
				isPrimary={item.isPrimary}
				isTotalBlock={false}
				openFieldModal={openFieldModal}
				removeBlock={removeItem}
				removeField={removeField}
				reportFieldsByIdMap={reportFieldsByIdMap}
				reportTypeBlockId={item.virtualId}
				upsertField={upsertField}
			/>
		);
	};

	const renderCustomBlock = (item: ReportTypeBlockFormModel) => {
		const block = reportBlocksByIdMap[item.reportBlockVirtualId];

		return (
			<ReportTypeCustomTypeFormDraggableBlock
				isMain={false}
				onToggle={onTogglePrimary}
				remove={removeItem}
				reportBlock={block}
				reportFieldsByIdMap={reportFieldsByIdMap}
				reportTypeBlockId={item.virtualId}
				showDragIndicator={true}
			/>
		);
	};

	const renderDraggable = (id: string, index: number) => {
		const item = reportTypeBlocksByIdMap[id];
		const block = reportBlocksByIdMap[item.reportBlockVirtualId];

		return (
			<Draggable
				draggableId={`${listDroppableId}#${block.name}`}
				index={index}	// important
				key={index}
			>
				{(provided) => (
					<div
						{...provided?.draggableProps}
						{...provided?.dragHandleProps}
						className="report-block-form__draggable-field"
						ref={provided?.innerRef}
					>
						{block?.type === ReportBlockType.CALCULATED
							? renderCalculatedBlock(item, index)
							: renderCustomBlock(item)
						}
					</div>
				)}
			</Draggable>
		);
	};

	const renderMainBlock = (id: string) => {
		const item = reportTypeBlocksByIdMap[id];
		const block = reportBlocksByIdMap[item.reportBlockVirtualId];

		return (
			<div className="report-block-form__draggable-field report-block-form__draggable-field--main">
				<ReportTypeCustomTypeFormDraggableBlock
					isMain={true}
					onToggle={onToggleMain}
					remove={removeMain}
					reportBlock={block}
					reportFieldsByIdMap={reportFieldsByIdMap}
					reportTypeBlockId={id}
					showDragIndicator={false}
				/>
			</div>
		);
	};

	const renderDroppableZoneMain = () => {
		const isEmpty = !mainBlockId;

		let containerClassName = 'report-block-form__droppable-container report-block-form__droppable-container--main';
		containerClassName = isEmpty ? `${containerClassName} report-block-form__droppable-container--empty` : containerClassName;
		containerClassName = isMainExpanded ? `${containerClassName} report-block-form__droppable-container--main-expanded` : containerClassName;

		return (
			<Droppable
				direction="vertical"
				droppableId={mainDroppableId}
				isDropDisabled={!!mainBlockId || draggingElementSource !== DroppableZones.MAIN_BLOCK_LIST_ID}
			>
				{(droppableProvided) => (
					<div ref={droppableProvided.innerRef}>
						<div className={containerClassName}>
							{!isEmpty && renderMainBlock(mainBlockId)}
							{isEmpty && (
								<span
									className="report-block-form__droppable-container__action-description"
									onClick={onAddBlock.bind(this, mainDroppableId)}
								>
									{
										showBlocksList
											? 'Drag and Drop / '
											: <span className="icon-plus" />
									}
									Add Main Block
								</span>
							)}
						</div>
						{droppableProvided.placeholder}
					</div>
				)}
			</Droppable>
		);
	};

	const renderList = (isDraggingOver: boolean) => {
		const isEmpty = !ids.length;
		const expandedCount = Object.keys(expandedBlocks).filter((_key) => !!expandedBlocks[_key]).length;

		const { containerClassName } = getFormDraggableZoneListAttributes(isDraggingOver, ids.length, expandedCount);

		return (
			<div
				className={containerClassName}
				onClick={isEmpty ? onAddBlock.bind(this, listDroppableId) : undefined}
			>
				{isEmpty
					? (
						<span className="report-block-form__droppable-container__action-description">
							{showBlocksList
								? 'Drag and Drop / '
								: <span className="icon-plus" />
							}
							Add Secondary Block
						</span>
					)
					: ids.map(renderDraggable)
				}
			</div>
		);
	};

	const renderDroppableZoneList = () => {
		const isDragging = draggingElementSource !== undefined;
		const isDropDisabled = (!isDragging && (draggingElementSource === DroppableZones.BLOCK_LIST_ID || draggingElementSource === listDroppableId))
			|| (!!draggingElementSource && ReportTypeBuilderUtils.isCalculatedBlockDroppableZone(draggingElementSource));

		return (
			<Droppable
				direction="vertical"
				droppableId={listDroppableId}
				isDropDisabled={isDropDisabled}
			>
				{(droppableProvided, snapshot) => (
					<div ref={droppableProvided.innerRef}>
						{renderList(snapshot.isDraggingOver)}
						{droppableProvided.placeholder}
					</div>
				)}
			</Droppable>
		);
	};

	return (
		<>
			<div className="report-block-form report-block-form--open-bottom">
				<div className="report-block-form__title">
					Blocks
				</div>
				{renderDroppableZoneMain()}
			</div>
			<div className="report-block-form">
				{renderDroppableZoneList()}
			</div>
			<div className="report-block-form__add-button-container report-block-form__add-button-container--padded">
				<a className="btn btn--link btn--non-padded btn--justify-start" onClick={onAddBlock.bind(this, listDroppableId)} role="button">
					<span className="icon-plus" />
					Add Secondary Block
				</a>
			</div>
		</>
	);
};

export default React.memo(ReportTypeCustomTypeFormDroppableZone);
