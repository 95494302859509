import * as React from 'react';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { withRouter, CustomRouteComponentProps } from 'react-router-dom';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';

import { EquipmentCostCategoryForm as ECCForm, EquipmentCostCategoryFM as ECCFormModel } from './formModel';

import { EquipmentCostCategoryItem, CreateEquipmentCostTreeNode } from 'ab-viewModels/editableTree.viewModel';

import * as EquipmentCostActions from 'af-actions/equipmentCost';

import Input from 'af-fields/Input';

import ColorPicker from 'af-fields/ColorPicker';

import { EQUIPMENT_COST_CATEGORY_CREATE } from 'af-constants/reduxForms';

import { validateEquipmentCategory } from './validation';

interface OwnProps {
	removeAddCategoryForm: (event: React.MouseEvent) => void;
	parent: EquipmentCostCategoryItem;
	onCreateCategory: (values: CreateEquipmentCostTreeNode) => void;
}

interface DispatchProps {
	createEquipmentCostCategory: typeof EquipmentCostActions.createEquipmentCostCategory;
	createEquipmentCostGroup: typeof EquipmentCostActions.createEquipmentCostGroup;
}

type ConnectOwnProps = OwnProps & CustomRouteComponentProps;

type FormOwnProps = ConnectOwnProps & ResolveThunks<DispatchProps>;

type Props = FormOwnProps & InjectedFormProps<ECCForm, FormOwnProps>;

class EquipmentCostCategoryForm extends React.Component<Props> {

	handleKeyDown = (event: React.KeyboardEvent) => {
		const { submitting, handleSubmit } = this.props;
		if (event.key === 'Enter') {
			event.preventDefault();
			if (!submitting) {
				handleSubmit(this.onSubmit)(event);
			}
		}
	};

	onSubmit = async (form: ECCForm) => {
		const {
			parent,
			onCreateCategory,
			createEquipmentCostCategory,
			createEquipmentCostGroup,
		} = this.props;

		const values = ECCFormModel.fromTree(parent, form);
		if (values.parentId) {
			const equipmentCostCategory = await createEquipmentCostCategory(values);
			onCreateCategory(equipmentCostCategory);
		} else {
			const equipmentCostGroup = await createEquipmentCostGroup(values);
			onCreateCategory(equipmentCostGroup);
		}
	};

	onColorPickerFocus = () => {
		const { touch } = this.props;
		touch('color');
	};

	render() {
		const { handleSubmit, parent, removeAddCategoryForm } = this.props;

		return (
			<div className="treebeard-form">
				{parent.nodeId > 0 &&
					<div className="color-picker__wrapper" >
						<Field
							component={ColorPicker}
							name="color"
							onFocus={this.onColorPickerFocus}
						/>
					</div>
				}
				<Field
					component={Input}
					hideErrorText={true}
					maxCharacters={null}
					name="name"
					onKeyDown={this.handleKeyDown}
					placeholder="Enter name"
					showCharNum={true}
					type="text"
				/>
				<span
					className="btn btn--icon btn--rectangle btn--open-left"
					onClick={handleSubmit(this.onSubmit)}
				>
					<span className="icon-check" />
				</span>
				<span
					className="btn btn--icon btn--rectangle btn--open-left"
					onClick={removeAddCategoryForm}
				>
					<span className="icon-close" />
				</span>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		createEquipmentCostCategory: EquipmentCostActions.createEquipmentCostCategory,
		createEquipmentCostGroup: EquipmentCostActions.createEquipmentCostGroup,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	withRouter,
	connect<null, DispatchProps, ConnectOwnProps>(null, mapDispatchToProps()),
	reduxForm<ECCForm, FormOwnProps>({ form: EQUIPMENT_COST_CATEGORY_CREATE, validate: validateEquipmentCategory })
);

export default enhance(EquipmentCostCategoryForm);
