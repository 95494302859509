import * as React from 'react';

import EmployeeSection from './EmployeeSection';
import EquipmentSection from './EquipmentSection';
import DailyTipSection from './DailyTipSection';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import * as SettingsKeys from 'af-constants/settingsKeys';

import { withSettings } from 'af-utils/settings.util';

import { ToolbarResourceModalProps } from '../../Shared/ModalProps';

interface OwnProps extends ToolbarResourceModalProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	sendNotification: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
	scheduleAutoNotify: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
}

interface SettingsProps {
	isTipsSectionCollapsed: boolean;
}

type Props = OwnProps & SettingsProps;

interface State {
	isTipsSectionCollapsed: boolean;
}

class Toolbar extends React.PureComponent<Props, State> {
	state: State = {
		isTipsSectionCollapsed: this.props.isTipsSectionCollapsed,
	};

	setDailyTipsSectionCollapsed = (isTipsSectionCollapsed: boolean) => this.setState(() => ({ isTipsSectionCollapsed }));

	render() {
		const {
			dueDate,
			sendNotification,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			hasPermissionsToSendNotifications,
			scheduleAutoNotify,
			setEmployeeModalData,
			setEquipmentModalData,
			setEmployeeModalVisibility,
			setEquipmentModalVisibility,
		} = this.props;
		const { isTipsSectionCollapsed } = this.state;

		return (
			<div className="schedule-board-toolbar">
				<div className={`resources ${isTipsSectionCollapsed ? 'resources--extended' : ''}`}>
					<EmployeeSection
						dueDate={dueDate}
						hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
						hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
						isDragAndDropDisabled={isDragAndDropDisabled}
						scheduleAutoNotify={scheduleAutoNotify}
						sendNotification={sendNotification}
						setEmployeeModalData={setEmployeeModalData}
						setEmployeeModalVisibility={setEmployeeModalVisibility}
					/>
					<EquipmentSection
						dueDate={dueDate}
						hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
						isDragAndDropDisabled={isDragAndDropDisabled}
						setEquipmentModalData={setEquipmentModalData}
						setEquipmentModalVisibility={setEquipmentModalVisibility}
					/>
				</div>
				<DailyTipSection
					dueDate={dueDate}
					isCollapsed={isTipsSectionCollapsed}
					setDailyTipsSectionCollapsed={this.setDailyTipsSectionCollapsed}
				/>
			</div>
		);
	}
}

export default withSettings(() => ([
	{
		key: SettingsKeys.DAILY_TIP_SECTION_COLLAPSED(),
		mappedName: 'isTipsSectionCollapsed',
		normalize: (value: string) => value === 'true',
		defaultValue: false,
		source: BrowserStorageEnum.LOCAL_STORAGE,
	},
]))(Toolbar) as React.ComponentClass<OwnProps>;
