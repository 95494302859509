import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps, withRouter } from 'react-router-dom';
import { FormControl, FormGroup, Button } from 'react-bootstrap';

import FileType from 'acceligent-shared/enums/fileType';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import ScheduleBoardSetPerDiemForWorkOrdersRequestModel from 'ab-requestModels/scheduleBoardSetPerDiemsForWorkOrder.requestModel';
import WorkOrderCopyRM from 'ab-requestModels/workOrder/copyWorkOrders.requestModel';

import { convertWorkDays } from 'ab-viewModels/company.viewModel';
import ScheduleBoardWorkOrderViewModel, { ScheduleBoardWorkOrdersViewModel } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import { DailyTipViewModel } from 'ab-viewModels/dailyTip.viewModel';
import { ScheduleBoardWorkOrderResourceLookupsViewModel } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardResourceLookup.viewModel';
import { NotificationStatusByEmployee, NotificationStatusByTemporaryEmployee } from 'ab-viewModels/notification.viewModel';
import { DailyPerDiemTipViewModel } from 'ab-viewModels/dailyPerDiemTip.viewModel';
import ScheduleBoardNightShiftWorkOrder from 'ab-viewModels/scheduleBoardNightShiftWorkOrder.viewModel';

import { SelectedWorkOrderModel, BaseWorkOrderModel, WarningWorkOrderModel } from 'af-models/scheduleBoard.models';

import { EXPORT_SCHEDULE_BOARD, WORK_ORDER_FORM, DAILY_TIP_FORM, DAILY_PER_DIEM_TIP_FORM, SET_PER_DIEM_FOR_WORK_ORDERS } from 'af-constants/reduxForms';
import { SCREEN_BREAKPOINT_XL } from 'af-constants/values';

import { SEARCH_DELAY } from 'ab-constants/value';

import SocketEvent from 'ab-enums/socketEvent.enum';
import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import { TemplateNotificationEnum } from 'ab-enums/notifyType.enum';

import * as WorkOrderValidator from 'ab-validators/workOrder.validator';
import { DailyMessageRequestModel } from 'ab-requestModels/dailyMessage.requestModel';

import WorkOrderCopyModal from 'af-root/scenes/Company/WorkOrders/Table/OrderCopyForm';
import BoardLockedModal, { OwnProps as BoardLockedModalProps } from 'af-root/scenes/Company/ScheduleBoard/Shared/BoardLockedModal';
import FilterModal from 'af-root/scenes/Company/ScheduleBoard/Shared/FilterModal';
import AddPerDiemModal from '../AddPerDiemModal';
import CrewsInfo from 'af-root/scenes/Company/ScheduleBoard/Shared/Header/CrewsInfo';
import NotifyAllParticipantsModal from 'af-root/scenes/Company/ScheduleBoard/Shared/NotifyAllParticipantsModal';
import ScheduleBoardTemplateNotifyModal from 'af-root/scenes/Company/ScheduleBoard/Shared/ScheduleBoardTemplateNotifyModal';
import getDailyTipModel from 'af-components/SharedForms/DailyTipModal';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as WorkOrderActions from 'af-actions/workOrder';
import * as DailyTipActions from 'af-actions/dailyTip';
import * as DailyPerDiemTipActions from 'af-actions/dailyPerDiemTip';
import * as NotifyActions from 'af-actions/notify';

import { isAllowed } from 'ab-utils/auth.util';

import socket from 'af-utils/socket.util';
import { debounce } from 'af-utils/actions.util';
import { captureDailyViewScheduleBoard } from 'af-utils/screenshot.util';
import * as WorkOrderUtils from 'af-utils/workOrder.util';

import SortMenu from './SortMenu';
import ActionsMenu from './ActionsMenu';
import NightShiftsButton from './NightShiftsButton';
import NightShiftWorkOrdersModal from './NightShiftWorkOrdersModal';
import SelectMultipleButton from './SelectMultipleButton';
import ShowNotesButton from './ShowNotesButton';
import WarningModal from './WarningModal';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

interface OwnProps {
	companyName: string;
	orgAlias: string;
	isBoardLoading: boolean;
	refreshOrdersOnCopy?: (workOrderIds: number[]) => void;
}

type Props = OwnProps & CustomRouteComponentProps & ConnectedProps<typeof connector>;

interface State {
	addPerDiemModalVisible: boolean;
	boardLockedModalProps: BoardLockedModalProps;
	copyMultipleModalVisible: boolean;
	customTipId?: number;
	filterModalVisible: boolean;
	internalQuery: string;
	invalidWorkOrders: WarningWorkOrderModel[];
	lastFiveDailyTips: DailyTipViewModel[];
	lastFiveDailyPerDiemTips: DailyPerDiemTipViewModel[];
	notifyAllModalVisible: boolean;
	shouldReloadFilters: boolean;
	showDailyTipModal: boolean;
	showDailyPerDiemTipModal: boolean;
	showScheduleBoardTemplateModal: boolean;
	showWarningModal: boolean;
	workOrdersToPublish: number[];
	notificationStatusByEmployee: NotificationStatusByEmployee;
	notificationStatusByTemporaryEmployee: NotificationStatusByTemporaryEmployee;
	publishedSelectedWorkOrders: ScheduleBoardWorkOrderViewModel[];
	unpublishedSelectedWorkOrders: ScheduleBoardWorkOrderViewModel[];
	canceledSelectedWorkOrders: ScheduleBoardWorkOrderViewModel[];
	workOrderResourceLookup: ScheduleBoardWorkOrderResourceLookupsViewModel;
	nightShiftWorkOrders: Nullable<ScheduleBoardNightShiftWorkOrder[]>;
	/** YYYY-MM-DD */
	nightShiftModalDueDate: Nullable<string>;
}

const initialBoardLockedModalProps: BoardLockedModalProps = {
	isOpen: false,
	reason: '',
	onEntered: undefined,
};

const DailyTipModal = getDailyTipModel(DAILY_TIP_FORM);
const DailyPerDiemTipModal = getDailyTipModel(DAILY_PER_DIEM_TIP_FORM);

class ScheduleBoardHeader extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		workDays: [],
		selectedDate: '',
		selectedWorkOrders: [],
		searchResultItems: [],
		employees: {},
		dailyTip: {} as DailyTipViewModel,
		dailyPerDiemTip: {} as DailyPerDiemTipViewModel,
		workOrderList: [],
		workOrders: {},
		participantsEmailAndSmsStatuses: {},
		assignedPublishedNotificationStatuses: {},
		assignedCanceledNotificationStatuses: {},
		canceledWorkOrderResourceLookup: {},
		notCanceledWorkOrderResourceLookup: {},
	};

	state: State = {
		copyMultipleModalVisible: false,
		filterModalVisible: false,
		addPerDiemModalVisible: false,
		shouldReloadFilters: false,
		internalQuery: '',
		notifyAllModalVisible: false,
		showWarningModal: false,
		showScheduleBoardTemplateModal: false,
		workOrdersToPublish: [],
		invalidWorkOrders: [],
		showDailyTipModal: false,
		showDailyPerDiemTipModal: false,
		lastFiveDailyTips: [],
		lastFiveDailyPerDiemTips: [],
		boardLockedModalProps: initialBoardLockedModalProps,
		notificationStatusByEmployee: { ...this.props.assignedPublishedNotificationStatuses, ...this.props.assignedCanceledNotificationStatuses },
		notificationStatusByTemporaryEmployee: {
			...(this.props.assignedPublishedTempLaborNotificationStatuses ?? {}),
			...(this.props.assignedCanceledTempLaborNotificationStatuses ?? {}),
		},
		publishedSelectedWorkOrders: [],
		unpublishedSelectedWorkOrders: [],
		canceledSelectedWorkOrders: [],
		workOrderResourceLookup: { ...this.props.canceledWorkOrderResourceLookup, ...this.props.notCanceledWorkOrderResourceLookup },
		nightShiftWorkOrders: null,
		nightShiftModalDueDate: null,
	};

	static computeSelectedWorkOrdersState(
		workOrders: ScheduleBoardWorkOrdersViewModel,
		selectedWorkOrders: SelectedWorkOrderModel[],
		isMultiSelectModeActive: boolean
	) {
		const publishedSelectedWorkOrders: ScheduleBoardWorkOrderViewModel[] = [];
		const unpublishedSelectedWorkOrders: ScheduleBoardWorkOrderViewModel[] = [];
		const canceledSelectedWorkOrders: ScheduleBoardWorkOrderViewModel[] = [];

		const isSelectedLookup = selectedWorkOrders.reduce((_dict: Record<number, true>, { id }: SelectedWorkOrderModel) => {
			_dict[id] = true;
			return _dict;
		}, {});

		Object.values(workOrders).forEach((_wo) => {
			const isSelected = isSelectedLookup[_wo.id];
			// multiSelectMode isn't active or if active wo is selected
			const multiSelectModeCondition = (!isMultiSelectModeActive || (isMultiSelectModeActive && isSelected));
			if (_wo.status === WorkOrderStatus.PUBLISHED && multiSelectModeCondition) {
				// if wo is published and multiSelectModeCondition satisfied
				publishedSelectedWorkOrders.push(_wo);
			} else if (_wo.status !== WorkOrderStatus.PUBLISHED && _wo.status !== WorkOrderStatus.CANCELED && multiSelectModeCondition) {
				unpublishedSelectedWorkOrders.push(_wo);
			} else if (_wo.status === WorkOrderStatus.CANCELED && multiSelectModeCondition) {
				canceledSelectedWorkOrders.push(_wo);
			}
		});

		return {
			publishedSelectedWorkOrders,
			unpublishedSelectedWorkOrders,
			canceledSelectedWorkOrders,
		};
	}

	componentDidMount(): void {
		const {
			workOrders,
			selectedWorkOrders,
			scheduleBoardView,
			isMultiSelectModeActive,
		} = this.props;

		if (scheduleBoardView === ScheduleBoardView.DAILY_VIEW && !!workOrders) {
			this.setState(() => ScheduleBoardHeader.computeSelectedWorkOrdersState(workOrders, selectedWorkOrders, isMultiSelectModeActive));
		}
	}

	componentDidUpdate(prevProps: Props) {
		const { selectedDate: prevDate } = prevProps;
		const {
			selectedDate,
			updateScheduleBoardQuery,
			deactivateMultiSelectMode,
			searchResultItems,
			assignedPublishedNotificationStatuses,
			assignedCanceledNotificationStatuses,
			assignedPublishedTempLaborNotificationStatuses,
			assignedCanceledTempLaborNotificationStatuses,
			workOrders,
			selectedWorkOrders,
			scheduleBoardView,
			isMultiSelectModeActive,
			notCanceledWorkOrderResourceLookup,
			canceledWorkOrderResourceLookup,
		} = this.props;
		const {
			publishedSelectedWorkOrders,
			unpublishedSelectedWorkOrders,
			canceledSelectedWorkOrders,
		} = this.state;

		if (
			prevProps.assignedPublishedNotificationStatuses !== assignedPublishedNotificationStatuses
			|| prevProps.assignedCanceledNotificationStatuses !== assignedCanceledNotificationStatuses
		) {
			this.setState(() => ({ notificationStatusByEmployee: { ...assignedPublishedNotificationStatuses, ...assignedCanceledNotificationStatuses } }));
		}

		if (
			prevProps.assignedPublishedTempLaborNotificationStatuses !== assignedPublishedTempLaborNotificationStatuses
			|| prevProps.assignedCanceledTempLaborNotificationStatuses !== assignedCanceledTempLaborNotificationStatuses
		) {
			this.setState(() => ({
				notificationStatusByTemporaryEmployee: {
					...assignedPublishedTempLaborNotificationStatuses,
					...assignedCanceledTempLaborNotificationStatuses,
				},
			}));
		}

		if (
			prevProps.canceledWorkOrderResourceLookup !== canceledWorkOrderResourceLookup
			|| prevProps.notCanceledWorkOrderResourceLookup !== notCanceledWorkOrderResourceLookup
		) {
			this.setState(() => ({ workOrderResourceLookup: { ...canceledWorkOrderResourceLookup, ...notCanceledWorkOrderResourceLookup } }));
		}

		if (
			scheduleBoardView === ScheduleBoardView.DAILY_VIEW
			&& !!workOrders
			&& (
				prevProps.scheduleBoardView !== scheduleBoardView
				|| prevProps.workOrders !== workOrders
				|| prevProps.selectedWorkOrders !== selectedWorkOrders
				|| prevProps.isMultiSelectModeActive !== isMultiSelectModeActive
			)
		) {
			this.setState(() => ScheduleBoardHeader.computeSelectedWorkOrdersState(workOrders, selectedWorkOrders, isMultiSelectModeActive));
		} else if (
			scheduleBoardView !== ScheduleBoardView.DAILY_VIEW
			&& (publishedSelectedWorkOrders.length + unpublishedSelectedWorkOrders.length + canceledSelectedWorkOrders.length) > 0
		) {
			this.setState(() => ({
				publishedSelectedWorkOrders: [],
				unpublishedSelectedWorkOrders: [],
				canceledSelectedWorkOrders: [],
			}));
		}

		const shouldReset: boolean = selectedDate && prevDate ? selectedDate.localeCompare(prevDate) !== 0 : !!prevDate;

		if (prevProps.searchResultItems !== searchResultItems) {
			this.scrollNodeIntoView(searchResultItems[0]);
		}

		if (shouldReset) {
			// reset state
			this.setState(() => ({ internalQuery: '' }), () => {
				updateScheduleBoardQuery('');
				deactivateMultiSelectMode();
			});
		}
	}

	componentWillUnmount() {
		const { updateScheduleBoardQuery, deactivateMultiSelectMode, clearFilters } = this.props;
		updateScheduleBoardQuery('');
		deactivateMultiSelectMode();
		clearFilters();
	}

	toggleMultiSelectModeActive = () => {
		const { isMultiSelectModeActive, deactivateMultiSelectMode, activateMultiSelectMode } = this.props;
		const action = isMultiSelectModeActive ? deactivateMultiSelectMode : activateMultiSelectMode;
		action();
	};

	toggleShowNotesMode = () => {
		const { isShowNotesActive, setShowNotesMode } = this.props;
		setShowNotesMode(!isShowNotesActive);
	};

	onQueryChanged = (query: string = '') => {
		const { updateScheduleBoardQuery } = this.props;
		updateScheduleBoardQuery(query);
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	onQueryChangedDebounced = debounce(this.onQueryChanged, SEARCH_DELAY);

	onQueryChangedDebouncedEventHandler = (event: Metadata = {}) => {
		const query = event?.target?.value || '';
		this.setState(() => ({ internalQuery: query }), () => this.onQueryChangedDebounced(this.state.internalQuery));
	};

	setPerDiemForWorkOrders = async () => {
		const { selectedWorkOrders, selectedDate } = this.props;

		if (!selectedDate) {
			throw new Error('No date selected');
		}

		const workOrderIds = selectedWorkOrders.map((_workOrder) => _workOrder.id);
		const data: ScheduleBoardSetPerDiemForWorkOrdersRequestModel = {
			workOrderIds,
			dueDate: selectedDate,
		};
		await socket.connection?.emitWithPromise(
			SocketEvent.V2.FE.SCHEDULE_BOARD.SET_PER_DIEM_FOR_WORK_ORDERS,
			{ submitting: SET_PER_DIEM_FOR_WORK_ORDERS },
			data
		);
		this.closeAddPerDiemModal();
	};

	openAddPerDiemModal = () => this.setState(() => ({ addPerDiemModalVisible: true }));

	closeAddPerDiemModal = () => this.setState(() => ({ addPerDiemModalVisible: false }));

	openCopyMultipleModal = () => {
		const {
			isMultiSelectModeActive,
			selectedDate: _selectedDate,
			workDays,
			reinitializeCopyForm,
			selectedWorkOrders,
			workOrders,
		} = this.props;

		const selectedDate = TimeUtils.parseMoment(_selectedDate, TimeFormatEnum.DATE_ONLY);
		const _workOrders: BaseWorkOrderModel[] = isMultiSelectModeActive ? selectedWorkOrders : Object.values(workOrders ?? {});

		if (!selectedDate || !_selectedDate) {
			throw new Error('No date selected');
		}

		const validityCondition = (wo: BaseWorkOrderModel) => {
			if (workOrders?.[wo.code]?.jobStatus === WorkRequestStatus.FINISHED) {
				return 'finishedOrders';
			} else if (workOrders?.[wo.code]?.status === WorkOrderStatus.CANCELED) {
				return 'cancelledOrders';
			} else {
				return 'validOrders';
			}
		};

		const { validOrders, finishedOrders, cancelledOrders } = WorkOrderUtils.groupWorkOrdersByValidity(_workOrders, validityCondition);

		reinitializeCopyForm(
			selectedDate.clone().add(1, 'days').format(TimeFormatEnum.DATE_ONLY),
			_selectedDate,
			convertWorkDays(workDays),
			validOrders,
			finishedOrders,
			cancelledOrders
		);
		this.setState(() => ({ copyMultipleModalVisible: true }));
	};

	closeCopyMultipleModal = () => {
		this.setState(() => ({ copyMultipleModalVisible: false }));
	};

	openFilterModal = () => {
		this.setState(() => ({ filterModalVisible: true, shouldReloadFilters: false }));
	};

	closeFilterModal = () => {
		this.setState(() => ({ filterModalVisible: false }));
	};

	clearFilter = () => {
		const { clearFilters } = this.props;
		this.setState(() => ({ shouldReloadFilters: true }));
		clearFilters();
	};

	openNotifyAllModal = async () => {
		const { findAllWorkOrdersWithNotificationStatusesByDueDate, selectedDate } = this.props;
		if (!selectedDate) {
			throw new Error('No date selected');
		}

		this.setState(() => ({ notifyAllModalVisible: true }));
		await findAllWorkOrdersWithNotificationStatusesByDueDate(selectedDate);
	};

	openScheduleBoardTemplateNotifyModal = () => this.setState(() => ({ showScheduleBoardTemplateModal: true }));

	closeScheduleBoardTemplateNotifyModal = () => this.setState(() => ({ showScheduleBoardTemplateModal: false }));

	closeNotifyAllModal = () => {
		this.setState(() => ({ notifyAllModalVisible: false }));
	};

	getLongestNotificationMessage = () => {
		const { workOrderList } = this.props;
		const notificationsLengths = workOrderList?.map((_wo) => _wo?.notificationTemplateLength ?? 0) ?? [];
		return Math.max(...notificationsLengths);
	};

	getPublishedWorkOrderList = (): (ScheduleBoardWorkOrderViewModel & ScheduleBoardWorkOrderViewModel)[] => {
		const { participantsEmailAndSmsStatuses } = this.props;
		const { publishedSelectedWorkOrders } = this.state;
		return publishedSelectedWorkOrders.map((_wo) => ({ ..._wo, participantsEmailAndSmsStatus: participantsEmailAndSmsStatuses[_wo.code] }));
	};

	getCanceledWorkOrderList = (): (ScheduleBoardWorkOrderViewModel & ScheduleBoardWorkOrderViewModel)[] => {
		const { participantsEmailAndSmsStatuses } = this.props;
		const { canceledSelectedWorkOrders } = this.state;
		return canceledSelectedWorkOrders.map((_wo) => ({ ..._wo, participantsEmailAndSmsStatus: participantsEmailAndSmsStatuses[_wo.code] }));
	};

	getUnPublishedWorkOrderList = () => {
		return this.state.unpublishedSelectedWorkOrders;
	};

	copyOrders = async (copyForm: WorkOrderCopyRM) => {
		const {
			copySelectedWorkOrders,
			copyWorkOrdersOnDay,
			setWorkOrdersSelection,
			refreshOrdersOnCopy,
			isMultiSelectModeActive,
			selectedWorkOrders,
		} = this.props;
		let workOrderIds: number[] = [];
		if (isMultiSelectModeActive) {
			const expandedCopyForm: WorkOrderCopyRM = {
				...copyForm,
				selectedOrdersIds: selectedWorkOrders.map(({ id }) => id),
			};
			workOrderIds = await copySelectedWorkOrders(expandedCopyForm);
			setWorkOrdersSelection();
		} else {
			workOrderIds = await copyWorkOrdersOnDay(copyForm);
		}
		if (refreshOrdersOnCopy) {
			refreshOrdersOnCopy(workOrderIds);
		}
		this.closeCopyMultipleModal();
	};

	notifyParticipants = async (
		workOrderEmployeeIdsForSms: number[],
		workOrderEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForSms: number[],
		message?: string,
		isEdited?: boolean
	) => {
		let customTipId: Nullable<number> = null;
		if (message) {
			customTipId = isEdited
				? await this.createCustomDailyTip(message)
				: this.props.dailyTip?.id ?? null;
		}
		const { sendTemplateNotification, dueDate: _dueDate } = this.props;

		const dueDate = _dueDate && TimeUtils.formatDate(_dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY);
		if (!dueDate) {
			throw new Error('Due date not provided');
		}

		await sendTemplateNotification({
			dueDate,
			employeeEmailIds: workOrderEmployeeIdsForEmail,
			employeeSmsIds: workOrderEmployeeIdsForSms,
			tempEmployeeEmailIds: workOrderTemporaryEmployeeIdsForEmail,
			tempEmployeeSmsIds: workOrderTemporaryEmployeeIdsForSms,
			notificationType: TemplateNotificationEnum.PUBLISHED_WORK_ORDER,
			dailyTipId: customTipId,
		});
	};

	scheduleAutoNotify = async (
		workOrderEmployeeIdsForSms: number[],
		workOrderEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForSms: number[],
		message?: string
	) => {
		const { scheduleForAutoNotify, dueDate } = this.props;
		if (!dueDate) {
			throw new Error('Due date not provided');
		}

		const customTipId = message ? await this.createCustomDailyTip(message) : undefined;
		await scheduleForAutoNotify({
			dueDate,
			employeeEmailIds: workOrderEmployeeIdsForEmail,
			employeeSmsIds: workOrderEmployeeIdsForSms,
			tempEmployeeEmailIds: workOrderTemporaryEmployeeIdsForEmail,
			tempEmployeeSmsIds: workOrderTemporaryEmployeeIdsForSms,
			notificationType: TemplateNotificationEnum.PUBLISHED_WORK_ORDER,
			dailyTipId: customTipId,
		});
	};

	createCustomDailyTip = async (message: string) => {
		const { createDailyTip, dueDate } = this.props;
		if (!dueDate) {
			throw new Error('Due date not provided');
		}

		const customTip = await createDailyTip(message, dueDate, true);
		return customTip.id;
	};

	exportAsXlsx = async (withCalculations: boolean, onlyCurrentDay: boolean) => {
		const { selectedDate, exportScheduleBoard } = this.props;
		if (!selectedDate) {
			throw new Error('Date not provided');
		}

		await exportScheduleBoard(selectedDate, withCalculations, onlyCurrentDay, FileType.XLSX);
	};

	exportAsPdf = async (withCalculations: boolean, onlyCurrentDay: boolean) => {
		const { selectedDate, exportScheduleBoard } = this.props;
		if (!selectedDate) {
			throw new Error('Date not provided');
		}

		await exportScheduleBoard(selectedDate, withCalculations, onlyCurrentDay, FileType.PDF);
	};

	exportTemplate = async () => {
		const { selectedDate, exportScheduleBoardTemplate } = this.props;
		if (!selectedDate) {
			throw new Error('Date not provided');
		}

		await exportScheduleBoardTemplate(selectedDate);
	};

	captureBoardScreenshotBlockingAction = async () => {
		const { selectedDate, zoomLevel } = this.props;
		const filename = `Schedule_board_${selectedDate}`;
		await captureDailyViewScheduleBoard(SCREEN_BREAKPOINT_XL, filename, zoomLevel);
		this.setState(() => ({ boardLockedModalProps: initialBoardLockedModalProps }));
	};

	captureBoardScreenshot = () => {
		const { scheduleBoardView } = this.props;
		if (scheduleBoardView === ScheduleBoardView.DAILY_VIEW) {
			const boardLockedModalProps = {
				isOpen: true,
				reason: 'Capturing screenshot',
				onEntered: this.captureBoardScreenshotBlockingAction,
			};
			this.setState(() => ({ boardLockedModalProps }));
			// the capture screenshot logic is activated inside the modal for it to render properly
		}
	};

	showWarningModal = () => {
		this.setState(() => ({ showWarningModal: true }));
	};

	hideWarningModal = () => {
		this.setState(() => ({ showWarningModal: false }));
	};

	checkWorkOrders = () => {
		const { unpublishedSelectedWorkOrders, publishedSelectedWorkOrders, canceledSelectedWorkOrders } = this.state;
		let hasInvalid = false;
		const { valid, invalid } = unpublishedSelectedWorkOrders.reduce((_acc, _wo) => {
			const { isValid, errors } = WorkOrderValidator.isValidScheduleBoardWorkOrder(_wo);
			if (!isValid) {
				hasInvalid = true;
				const reason = `Invalid, missing ${Object.keys(errors).map((_r) => WorkOrderValidator.InvalidWorkOrderModalReasons[_r]).join(', ')}`;

				_acc.invalid.push({ woCode: _wo.codeStripped, reason, color: _wo.crewTypeColor });
			} else {
				_acc.valid.push(_wo.id);
			}
			return _acc;
		}, { valid: [], invalid: [] } as { valid: number[]; invalid: WarningWorkOrderModel[]; });
		this.setState(() => ({ workOrdersToPublish: valid, invalidWorkOrders: invalid }), () => {
			if (hasInvalid || publishedSelectedWorkOrders.length || canceledSelectedWorkOrders.length) {
				this.showWarningModal();
			} else {
				this.publishWorkOrders();
			}
		});
	};

	publishWorkOrders = async () => {
		const { publishWorkOrders } = this.props;
		const { workOrdersToPublish } = this.state;
		this.hideWarningModal();
		await publishWorkOrders(workOrdersToPublish);
		this.setState(() => ({ workOrdersToPublish: [], invalidWorkOrders: [] }));
	};

	getWarnings = () => {
		const { publishedSelectedWorkOrders, canceledSelectedWorkOrders, invalidWorkOrders } = this.state;
		const publishedWarning = publishedSelectedWorkOrders.map((_wo) => ({ woCode: _wo.codeStripped, reason: 'Already Published, Not Outdated', color: _wo.crewTypeColor }));
		const canceledWarning = canceledSelectedWorkOrders.map((_wo) => ({ woCode: _wo.codeStripped, reason: 'Canceled', color: _wo.crewTypeColor }));
		return [...publishedWarning, ...canceledWarning, ...invalidWorkOrders];
	};

	openDailyTipModal = async () => {
		const { findLastFiveDailyTips } = this.props;
		const lastFive = await findLastFiveDailyTips();
		this.setState(() => ({ showDailyTipModal: true, lastFiveDailyTips: lastFive }));
	};

	hideDailyTipModal = () => {
		this.setState(() => ({ showDailyTipModal: false }));
	};

	submitDailyTip = (values: DailyMessageRequestModel) => {
		const { dueDate, createDailyTip } = this.props;
		if (!dueDate) {
			throw new Error('Due date not provided');
		}

		createDailyTip(values.message, dueDate, false);
		this.hideDailyTipModal();
	};

	openDailyPerDiemTipModal = async () => {
		const { findLastFiveDailyPerDiemTips } = this.props;
		const lastFive = await findLastFiveDailyPerDiemTips();
		this.setState(() => ({ showDailyPerDiemTipModal: true, lastFiveDailyPerDiemTips: lastFive }));
	};

	hideDailyPerDiemTipModal = () => {
		this.setState(() => ({ showDailyPerDiemTipModal: false, lastFiveDailyPerDiemTips: [] }));
	};

	submitDailyPerDiemTip = (values: DailyMessageRequestModel) => {
		const { dueDate, createDailyPerDiemTip } = this.props;
		if (!dueDate) {
			throw new Error('Due date not provided');
		}

		createDailyPerDiemTip(values.message, dueDate);
		this.hideDailyPerDiemTipModal();
	};

	clearSearch = () => {
		this.setState(
			() => ({ internalQuery: '' }),
			() => {
				const { updateScheduleBoardQuery } = this.props;
				updateScheduleBoardQuery('');
			}
		);
	};

	decrementHighlightedNodeIndex = () => {
		const { setActiveSearchItemIndex, activeSearchItemIndex, searchResultItems } = this.props;
		const newActiveSearchItemIndex = activeSearchItemIndex - 1;
		const activeNodeId = searchResultItems[newActiveSearchItemIndex];

		setActiveSearchItemIndex(newActiveSearchItemIndex);
		this.scrollNodeIntoView(activeNodeId);
	};

	incrementHighlightedNodeIndex = () => {
		const { setActiveSearchItemIndex, activeSearchItemIndex, searchResultItems } = this.props;
		const newActiveSearchItemIndex = activeSearchItemIndex + 1;
		const activeNodeId = searchResultItems[newActiveSearchItemIndex];

		setActiveSearchItemIndex(newActiveSearchItemIndex);
		this.scrollNodeIntoView(activeNodeId);
	};

	scrollNodeIntoView = (nodeId: string) => {
		const { scheduleBoardView } = this.props;
		const node = document.getElementById(nodeId);
		const expressionToDetermineContextOfContainerInWhichNodeResides = /search-item-(wo|employee|equipment)/;
		const result = expressionToDetermineContextOfContainerInWhichNodeResides.exec(nodeId);
		const containerContext = result?.[1];
		let containerThatNeedsToBeScrolled: Nullable<Element> = null;

		switch (containerContext) {
			case 'employee':
				containerThatNeedsToBeScrolled = document
					.getElementsByClassName('schedule-board-toolbar-column-wrapper employees')[0]
					.firstChild as Element;
				break;
			case 'equipment':
				containerThatNeedsToBeScrolled = document
					.getElementsByClassName('schedule-board-toolbar-column-wrapper equipment')[0]
					.firstChild as Element;
				break;
			case 'wo':
				if (scheduleBoardView === ScheduleBoardView.DAILY_VIEW) {
					containerThatNeedsToBeScrolled = document
						.getElementsByClassName('schedule-board-cards-container-wrapper')[0]
						.firstChild as Element;
				}
				break;
			default:
				return;
		}

		if (containerThatNeedsToBeScrolled && node) {
			// 30 px above the active node.
			const positionToScrollTo = containerThatNeedsToBeScrolled.scrollTop
				- containerThatNeedsToBeScrolled.getBoundingClientRect().top
				+ node.getBoundingClientRect().top - 30;

			containerThatNeedsToBeScrolled.scrollTo({ top: positionToScrollTo, behavior: 'smooth' });
		}
	};

	_closeNightShiftModal = () => this.setState(() => ({ nightShiftWorkOrders: null }));

	_handleNightShiftPress = async () => {
		const { dueDate, findNightShiftWorkOrders } = this.props;

		const today = dueDate && TimeUtils.parseDate(dueDate, TimeFormatEnum.DATE_ONLY);
		const yesterday = today && TimeUtils.addDays(today, -1);
		const dbYesterdayDate = yesterday && TimeUtils.formatDate(yesterday, TimeFormatEnum.DB_DATE_ONLY);

		const workOrders = dbYesterdayDate
			? await findNightShiftWorkOrders(dbYesterdayDate)
			: null;
		if (!workOrders) {
			return;
		}
		this.setState(() => ({ nightShiftWorkOrders: workOrders, nightShiftModalDueDate: dbYesterdayDate }));
	};

	render() {
		const {
			activeSearchItemIndex,
			autoNotifyAt,
			autoNotifyOnPublish,
			companyName,
			employees,
			temporaryEmployees,
			exporting,
			history,
			isAllowedToEditWorkOrders,
			isAllowedToManagePerDiems,
			isAllowedToEditProdData,
			isAllowedToViewProdData,
			isBoardLoading,
			isLoading,
			isMultiSelectModeActive,
			isShowNotesActive,
			notificationsDisabled,
			orgAlias,
			publishing,
			query,
			scheduleBoardView,
			searchResultItems,
			selectedDate,
			selectedWorkOrders,
			dailyTip: { message: dailyTipMessage = '' } = {},
			dailyPerDiemTip: { message: dailyPerDiemTipMessage = '' } = {},
			initialCopyModalData,
			notifyTemporaryLabor,
		} = this.props;

		const {
			copyMultipleModalVisible,
			filterModalVisible,
			shouldReloadFilters,
			internalQuery,
			nightShiftWorkOrders,
			nightShiftModalDueDate,
			notifyAllModalVisible,
			addPerDiemModalVisible,
			showWarningModal,
			showDailyTipModal,
			showDailyPerDiemTipModal,
			showScheduleBoardTemplateModal,
			lastFiveDailyTips,
			lastFiveDailyPerDiemTips,
			boardLockedModalProps,
			notificationStatusByEmployee,
			notificationStatusByTemporaryEmployee,
			publishedSelectedWorkOrders,
			unpublishedSelectedWorkOrders,
			canceledSelectedWorkOrders,
			workOrderResourceLookup,
		} = this.state;

		const isDailyView = scheduleBoardView === ScheduleBoardView.DAILY_VIEW;
		const isWeeklyView = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW;

		const isCopyDisabled = !isDailyView
			|| (isMultiSelectModeActive && (selectedWorkOrders.length === 0 || selectedWorkOrders.every((wo) => wo.isCancelled)));
		const isNotifyAllDisabled = !publishedSelectedWorkOrders.length && !canceledSelectedWorkOrders.length;
		const isPublishDisabled = !unpublishedSelectedWorkOrders.length;
		const isAddPerDiemDisabled = selectedWorkOrders.length === 0 || !isAllowedToManagePerDiems;

		const isCrewsInfoVisible = isDailyView && isAllowedToViewProdData;

		return (
			<div className="schedule-board-header__second">
				<div className="schedule-board-header__second__left">
					<FormGroup>
						<div className="schedule-board-header__second__left__search">
							<FormControl
								className="schedule-board-header__second__left__search__form"
								id="query"
								name="query"
								onChange={this.onQueryChangedDebouncedEventHandler}
								placeholder="Search"
								type="text"
								value={internalQuery}
							/>
							{query && query.length > 1 &&
								<span className="input-group-append">
									<div className="match">
										<span className="icon-left" onClick={this.decrementHighlightedNodeIndex} />
										<span>{searchResultItems.length ? activeSearchItemIndex + 1 : 0}/{searchResultItems.length}</span>
										<span className="icon-right" onClick={this.incrementHighlightedNodeIndex} />
									</div>
									<span className="icon-close" onClick={this.clearSearch} />
								</span>
							}
						</div>
					</FormGroup>
					<Button
						className="schedule-board-header__second__left__filter"
						onClick={this.openFilterModal}
						variant="info"
					>
						Filter
					</Button>
					<Button
						className="schedule-board-header__second__left__filter"
						onClick={this.clearFilter}
						variant="info"
					>
						Clear
					</Button>
					<SortMenu />
				</div>
				<div className="schedule-board-header__second__center">
					{isCrewsInfoVisible && <CrewsInfo dueDate={selectedDate!} />}
				</div>
				<div className="schedule-board-header__second__right">
					<NightShiftsButton onPress={this._handleNightShiftPress} />
					{
						(isDailyView || isWeeklyView) &&
						<ShowNotesButton
							isShowNotesActive={isShowNotesActive}
							toggleShowNotesMode={this.toggleShowNotesMode}
						/>
					}
					{
						isDailyView && isAllowedToEditWorkOrders &&
						<SelectMultipleButton
							isMultiSelectActive={isMultiSelectModeActive}
							toggleMultiSelect={this.toggleMultiSelectModeActive}
						/>
					}
					<ActionsMenu
						captureBoardScreenshot={this.captureBoardScreenshot}
						companyName={companyName}
						copyWorkOrders={this.openCopyMultipleModal}
						exportPdf={this.exportAsPdf}
						exportTemplate={this.exportTemplate}
						exportXlsx={this.exportAsXlsx}
						history={history}
						isAddPerDiemDisabled={isAddPerDiemDisabled}
						isAllowedToEdit={isAllowedToEditWorkOrders}
						isAllowedToEditProdData={isAllowedToEditProdData}
						isAllowedToViewProdData={isAllowedToViewProdData}
						isBoardLoading={isBoardLoading}
						isCopyDisabled={isCopyDisabled}
						isExporting={exporting}
						isMultiSelectActive={isMultiSelectModeActive}
						isNotifyDisabled={isNotifyAllDisabled}
						isNotifyShown={!notificationsDisabled}
						isPublishDisabled={isPublishDisabled}
						isPublishing={publishing}
						notifyWorkOrders={this.openNotifyAllModal}
						openAddPerDiemModal={this.openAddPerDiemModal}
						openDailyPerDiemTipModal={this.openDailyPerDiemTipModal}
						openDailyTipModal={this.openDailyTipModal}
						openSBTemplateNotificationModal={this.openScheduleBoardTemplateNotifyModal}
						orgAlias={orgAlias}
						publishWorkOrders={this.checkWorkOrders}
						scheduleBoardView={scheduleBoardView}
					/>
				</div>
				<AddPerDiemModal
					closeModal={this.closeAddPerDiemModal}
					modalVisible={addPerDiemModalVisible}
					onSave={this.setPerDiemForWorkOrders}
					selectedWorkOrders={selectedWorkOrders}
				/>
				<BoardLockedModal {...boardLockedModalProps} />
				<WorkOrderCopyModal
					closeCopyModal={this.closeCopyMultipleModal}
					initialFormData={initialCopyModalData}
					isCopyMultipleModal={true}
					onSubmit={this.copyOrders}
					showCopyModal={copyMultipleModalVisible}
				/>
				<FilterModal
					closeFilterModal={this.closeFilterModal}
					modalVisible={filterModalVisible}
					shouldReloadFilters={shouldReloadFilters}
				/>
				<NotifyAllParticipantsModal
					autoNotifyAt={autoNotifyAt}
					autoNotifyOnPublish={autoNotifyOnPublish}
					canceledOrders={this.getCanceledWorkOrderList()}
					closeModal={this.closeNotifyAllModal}
					dailyTip={dailyTipMessage}
					dueDate={selectedDate}
					employeeMap={employees}
					isLoading={isLoading}
					notificationMessageLength={notifyAllModalVisible ? this.getLongestNotificationMessage() : 0}
					notificationStatusByEmployee={notificationStatusByEmployee}
					notificationStatusByTemporaryEmployee={notificationStatusByTemporaryEmployee}
					notifyParticipants={this.notifyParticipants}
					notifyTemporaryLabor={notifyTemporaryLabor}
					orders={this.getPublishedWorkOrderList()}
					scheduleAutoNotify={this.scheduleAutoNotify}
					showModal={notifyAllModalVisible}
					temporaryEmployeeMap={temporaryEmployees}
					unpublishedOrders={this.getUnPublishedWorkOrderList()}
					workOrderResourceLookup={workOrderResourceLookup}
				/>
				<WarningModal
					closeModal={this.hideWarningModal}
					isMultiSelectModeActive={isMultiSelectModeActive}
					showWarning={showWarningModal}
					submit={this.publishWorkOrders}
					warnings={showWarningModal ? this.getWarnings() : []}
				/>
				{showDailyTipModal &&
					<DailyTipModal
						closeModal={this.hideDailyTipModal}
						initialValues={{ message: dailyTipMessage }}
						isLoading={isLoading}
						label="Daily Tip Message"
						modalVisible={showDailyTipModal}
						onSubmit={this.submitDailyTip}
						previousMessages={lastFiveDailyTips}
					/>
				}
				{showDailyPerDiemTipModal &&
					<DailyPerDiemTipModal
						closeModal={this.hideDailyPerDiemTipModal}
						initialValues={{ message: dailyPerDiemTipMessage }}
						isLoading={isLoading}
						label="Daily Per Diem Message"
						modalVisible={showDailyPerDiemTipModal}
						onSubmit={this.submitDailyPerDiemTip}
						previousMessages={lastFiveDailyPerDiemTips}
					/>
				}
				{isDailyView && selectedDate &&
					<ScheduleBoardTemplateNotifyModal
						closeModal={this.closeScheduleBoardTemplateNotifyModal}
						date={selectedDate}
						showModal={showScheduleBoardTemplateModal}
					/>
				}
				{isDailyView && nightShiftModalDueDate &&
					<NightShiftWorkOrdersModal
						closeModal={this._closeNightShiftModal}
						dueDate={nightShiftModalDueDate}
						workOrders={nightShiftWorkOrders}
					/>
				}
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	const { company } = state.company;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isAllowedToEditWorkOrders: boolean = isAllowed(
		PagePermissions.COMPANY.WORK_ORDERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);
	const isAllowedToManagePerDiems: boolean = isAllowed(
		PagePermissions.COMPANY.PER_DIEMS,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);
	const isAllowedToEditProdData: boolean = isAllowed(
		PagePermissions.COMPANY.PROD_DATA.EDIT,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);
	const isAllowedToViewProdData: boolean = isAllowed(
		PagePermissions.COMPANY.PROD_DATA.VIEW,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const scheduleBoardOnDate = state.scheduleBoard.date ? state?.scheduleBoard?.workOrdersByDateDictionary?.[state.scheduleBoard.date] : undefined;

	const workOrders = scheduleBoardOnDate?.workOrders;
	const selectedDate = state.scheduleBoard.date ?? state.scheduleBoard.startDate ?? TimeUtils.formatDate(new Date(), TimeFormatEnum.DATE_ONLY);
	const selectedDayOfWeek = selectedDate && TimeUtils.parseMoment(selectedDate, TimeFormatEnum.DATE_ONLY)?.day();
	const dayBefore = selectedDayOfWeek ? selectedDayOfWeek - 1 : 6;

	return {
		dueDate: state.scheduleBoard.date ?? null,
		zoomLevel: state.scheduleBoard.zoomLevel,
		userData,
		companyData,
		workDays: company?.workDays ?? [],
		workOrderList: state?.workOrder?.workOrders?.list ?? [],
		assignedPublishedNotificationStatuses: scheduleBoardOnDate?.assignedPublishedNotificationStatuses ?? undefined,
		assignedCanceledNotificationStatuses: scheduleBoardOnDate?.assignedCanceledNotificationStatuses ?? undefined,
		assignedPublishedTempLaborNotificationStatuses: scheduleBoardOnDate?.assignedPublishedTempLaborNotificationStatuses ?? null,
		assignedCanceledTempLaborNotificationStatuses: scheduleBoardOnDate?.assignedCanceledTempLaborNotificationStatuses ?? null,
		workOrders,
		participantsEmailAndSmsStatuses: state?.workOrder?.workOrders?.participantsEmailAndSmsStatuses,
		isLoading: state.http.isFetching,
		scheduleBoardView: state.scheduleBoard.scheduleBoardView,
		selectedDate,
		query: state.scheduleBoard.query,
		searchResultItems: state.scheduleBoard.searchResultItems,
		activeSearchItemIndex: state.scheduleBoard.activeSearchItemIndex,
		isMultiSelectModeActive: state.scheduleBoard.isMultiSelectModeActive,
		isShowNotesActive: state.scheduleBoard.isShowNotesActive,
		selectedWorkOrders: state?.scheduleBoard?.selectedWorkOrders,
		isAllowedToEditWorkOrders,
		isAllowedToManagePerDiems,
		isAllowedToEditProdData,
		isAllowedToViewProdData,
		exporting: state.http.submitting.includes(EXPORT_SCHEDULE_BOARD),
		publishing: state.http.submitting.includes(WORK_ORDER_FORM),
		notificationsDisabled: !(company?.notification?.isEnabled),
		autoNotifyOnPublish: company?.notification?.notifyOnPublish ?? null,
		autoNotifyAt: (selectedDate ? company?.notification?.notifyOnDay?.[dayBefore] : null) ?? null,
		canceledWorkOrderResourceLookup: scheduleBoardOnDate?.canceledWorkOrderResourceLookups ?? undefined,
		notCanceledWorkOrderResourceLookup: scheduleBoardOnDate?.workOrderResourceLookups ?? undefined,
		employees: state?.scheduleBoard?.employees,
		temporaryEmployees: state?.scheduleBoard?.temporaryEmployees,
		dailyTip: scheduleBoardOnDate?.dailyTip,
		dailyPerDiemTip: scheduleBoardOnDate?.dailyPerDiemTip,
		initialCopyModalData: state.workOrder.copyWorkOrderModalData,
		notifyTemporaryLabor: company?.notification.notifyTemporaryLabor ?? false,
	};
}

function mapDispatchToProps() {
	return {
		updateScheduleBoardQuery: ScheduleBoardActions.updateScheduleBoardQuery,
		deactivateMultiSelectMode: ScheduleBoardActions.deactivateMultiSelectMode,
		clearFilters: ScheduleBoardActions.clearFilters,
		activateMultiSelectMode: ScheduleBoardActions.activateMultiSelectMode,
		setShowNotesMode: ScheduleBoardActions.setShowNotesMode,
		exportScheduleBoard: ScheduleBoardActions.exportScheduleBoard,
		exportScheduleBoardTemplate: ScheduleBoardActions.exportScheduleBoardTemplate,
		setActiveSearchItemIndex: ScheduleBoardActions.setActiveSearchItemIndex,
		setWorkOrdersSelection: ScheduleBoardActions.setWorkOrdersSelection,
		reinitializeCopyForm: WorkOrderActions.reinitializeCopyForm,
		findAllWorkOrdersWithNotificationStatusesByDueDate: WorkOrderActions.findAllWorkOrdersWithNotificationStatusesByDueDate,
		copySelectedWorkOrders: WorkOrderActions.copySelectedWorkOrders,
		copyWorkOrdersOnDay: WorkOrderActions.copyWorkOrdersOnDay,
		publishWorkOrders: WorkOrderActions.publishWorkOrders,
		createDailyTip: DailyTipActions.create,
		findLastFiveDailyTips: DailyTipActions.findLastFiveDailyTips,
		createDailyPerDiemTip: DailyPerDiemTipActions.create,
		findLastFiveDailyPerDiemTips: DailyPerDiemTipActions.findLastFiveDailyPerDiemTips,
		scheduleForAutoNotify: NotifyActions.scheduleForAutoNotify,
		sendTemplateNotification: NotifyActions.sendTemplateNotificationMultipleWO,
		findNightShiftWorkOrders: ScheduleBoardActions.findNightShiftWorkOrders,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	withRouter
);

export default enhance(ScheduleBoardHeader);
