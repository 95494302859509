import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import { DeliverableReviewTypeEnum, DeliverableReviewTypeLabelEnum } from 'acceligent-shared/enums/deliverableReviewType';
import { ColorPalette, TextColor } from 'acceligent-shared/enums/color';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { UpdatedByAccountViewModel } from 'acceligent-shared/dtos/web/view/updatedBy';
import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import DeliverableStatus from 'acceligent-shared/models/deliverableStatus';
import Comment from 'acceligent-shared/models/comment';

import * as DeliverableServiceModels from 'ab-serviceModels/deliverable.serviceModel';

const CSV_DELIVERABLE_HEADER_KEYS = [
	'Status',
	'Job Id',
	'Start date',
	'End date',
	'QAQC assignee',
	'Delivery timeline',
];

const CSV_SUBMISSION_HEADER_KEYS = [
	'\tSubmission status',
	'Submission Id',
	'Submission date',
	'Work order work order Id',
	'Submission work order date of work',
	'Submission review type',
	'Delivery type',
];

const CSV_ASSIGNMENT_HEADER_KEYS = [
	'\t\tAssignment status',
	'Field worker',
	'Updated by',
	'Updated at',
];

const _mapDeliverableHistoryServiceModelToViewModel = (
	history: DeliverableServiceModels.DeliverableStatusHistoryServiceModel
): DeliverableHistoryViewModel => ({
	id: history.id,
	status: DeliverableStatusViewModel.toViewModelPlain(history.abbreviation, history.statusColor, history.textColor),
	updatedAt: history.updatedAt,
	updatedBy: UpdatedByAccountViewModel.toViewModelPlain(history.updatedById, history.updatedByFullName),
});

class DeliverableStatusViewModel {
	id: number;
	name: string;
	abbreviation: string;
	description: Nullable<string>;
	statusColor: ColorPalette;
	textColor: TextColor;
	notify: boolean;
	isCompleted: boolean;
	isJobStatus: boolean;
	createdAt: Date;
	updatedAt: Date;

	constructor(status: DeliverableStatus) {
		this.id = status.id;
		this.name = status.name;
		this.abbreviation = status.abbreviation;
		this.description = status.description;
		this.statusColor = status.statusColor;
		this.textColor = status.textColor;
		this.notify = status.notify;
		this.isCompleted = status.isCompleted;
		this.isJobStatus = status.isJobStatus;
		this.createdAt = status.createdAt;
		this.updatedAt = status.updatedAt;
	}

	static toViewModelBulk(statuses: DeliverableStatus[]): DeliverableStatusViewModel[] {
		return statuses.map((_status: DeliverableStatus) => new DeliverableStatusViewModel(_status));
	}

	static toViewModelPlain(abbreviation: string, statusColor: ColorPalette, textColor: TextColor): Nullable<DeliverableStatusViewModel> {
		if (!abbreviation) {
			return null;
		}
		return {
			abbreviation,
			statusColor,
			textColor,
		} as DeliverableStatusViewModel;
	}
}

export class DeliverableComment {
	id: number;
	text: string;

	constructor(comment: Comment) {
		this.id = comment.id;
		this.text = comment.text;
	}

	static bulkConstructor(comments: Comment[]) {
		return comments.map(DeliverableComment.mapConstructor);
	}

	private static mapConstructor(comment: Comment) {
		return new DeliverableComment(comment);
	}
}

export interface DeliverableHistoryViewModel {
	id: number;
	updatedAt: Date;
	updatedBy: UpdatedByAccountViewModel;
	status: Nullable<DeliverableStatusViewModel>;
}

export class DeliverableTableViewModel {
	id: number;
	status: Nullable<DeliverableStatusViewModel>;
	notes: Nullable<string>;

	jobCode: string;
	/** `YYYY-MM-DD` */
	startDate: string;
	/** `YYYY-MM-DD` */
	endDate: null;
	deliverableDeliveryTimeline: string;
	deliverableAssignee: string;

	comments: string[];

	history: DeliverableHistoryViewModel[];

	submissions: TableViewModel<DeliverableSubmissionTableViewModel>;

	constructor(deliverable: DeliverableServiceModels.DeliverableTableServiceModel) {
		this.id = deliverable.id;
		this.status = DeliverableStatusViewModel.toViewModelPlain(deliverable.statusAbbreviation, deliverable.statusStatusColor, deliverable.statusTextColor);
		this.notes = deliverable.notes;

		this.jobCode = deliverable.jobCode;
		this.startDate = TimeUtils.formatDate(deliverable.startDate);
		this.endDate = null; // TODO: check with Jeremy if we need to add this field to job

		this.deliverableDeliveryTimeline = deliverable.deliverableDeliveryTimeline;
		this.deliverableAssignee = deliverable.QAQCAssigneeFullName;

		this.comments = deliverable.comments;

		this.history = deliverable.statusHistory?.map(_mapDeliverableHistoryServiceModelToViewModel) ?? [];

		const submissions: DeliverableSubmissionTableViewModel[] = [];
		for (const _submission of deliverable.submissions) {
			submissions.push(new DeliverableSubmissionTableViewModel(_submission));
		}
		this.submissions = new TableViewModel(submissions, 0, 0);
	}

	static toViewModelBulk(deliverableList: DeliverableServiceModels.DeliverableTableServiceModel[]): DeliverableTableViewModel[] {
		return deliverableList.map(DeliverableTableViewModel.mapServiceModelToViewModel);
	}

	static toCSVData(viewModels: DeliverableTableViewModel[]): string[][] {
		const header: string[] = [...CSV_DELIVERABLE_HEADER_KEYS];

		const mapDeliverable = (deliverable: DeliverableTableViewModel): string[] => {
			return [
				deliverable?.status?.abbreviation ?? '-',
				deliverable?.jobCode ?? '-',
				deliverable?.startDate ?? '-',
				deliverable?.endDate ?? '-',
				deliverable?.deliverableAssignee ?? '-',
				deliverable?.deliverableDeliveryTimeline ?? '-',
			];
		};

		const mapSubmission = (submissions: DeliverableSubmissionTableViewModel): string[] => {
			return [
				`\t${submissions?.status?.abbreviation ?? '-'}`,
				submissions?.submissionId ?? '-',
				submissions?.submissionDate ?? '-',
				submissions?.workOrderCode ?? '-',
				submissions?.dueDate ?? '-',
				submissions?.reviewType ? DeliverableReviewTypeLabelEnum[submissions?.reviewType] : '-',
				submissions?.deliverableDeliveryMethod ?? '-',
			];
		};

		const mapAssignment = (assignment: DeliverableAssignmentTableViewModel): string[] => {
			return [
				`\t\t${assignment?.status?.abbreviation ?? '-'}`,
				assignment?.employee ?? '-',
				assignment?.updatedBy?.fullName ?? '-',
				assignment?.updatedAt.toString() ?? '-',
			];
		};

		const rows: string[][] = viewModels.reduce((_acc, _deliverable) => {
			_acc.push(mapDeliverable(_deliverable));

			if (_deliverable.submissions.rows?.length) {
				_acc.push(CSV_SUBMISSION_HEADER_KEYS);
				for (const _submission of _deliverable.submissions.rows) {
					_acc.push(mapSubmission(_submission));

					if (_submission.assignments.rows?.length) {
						_acc.push(CSV_ASSIGNMENT_HEADER_KEYS);
						_acc.push(..._submission.assignments.rows.map(mapAssignment));
					}
				}
			}
			return _acc;
		}, [] as string[][]);

		return [header, ...rows];
	}

	private static mapServiceModelToViewModel = (_deliverable: DeliverableServiceModels.DeliverableTableServiceModel): DeliverableTableViewModel =>
		new DeliverableTableViewModel(_deliverable);
}

export class DeliverableSubmissionTableViewModel {
	id: number;
	deliverableId: number;
	status: Nullable<DeliverableStatusViewModel>;
	submissionDate: Nullable<string>;
	reviewType: Nullable<DeliverableReviewTypeEnum>;
	notes: Nullable<string>;
	trackingLink: Nullable<string>;

	/** `YYYY-MM-DD` */
	dueDate: string;
	workOrderCode: string;
	isCanceled: boolean;
	submissionId: string;
	deliverableDeliveryMethod: string;

	equipment: string[];
	employees: string[];
	temporaryEmployees: string[];

	comments: string[];

	history: DeliverableHistoryViewModel[];

	assignments: TableViewModel<DeliverableAssignmentTableViewModel>;

	constructor(submission: DeliverableServiceModels.DeliverableSubmissionServiceModel) {
		this.id = submission.id;
		this.deliverableId = submission.deliverableId;
		this.status = DeliverableStatusViewModel.toViewModelPlain(submission.statusAbbreviation, submission.statusStatusColor, submission.statusTextColor);
		this.submissionDate = TimeUtils.formatDate(submission.date);
		this.reviewType = submission.reviewType;
		this.notes = submission.notes;
		this.trackingLink = submission.trackingLink;

		this.dueDate = TimeUtils.formatDate(submission.dueDate);
		this.workOrderCode = submission.workOrderCode;
		this.isCanceled = submission.workOrderStatus === WorkOrderStatus.CANCELED;
		this.submissionId = submission.workOrderCode;
		this.deliverableDeliveryMethod = submission.deliverableDeliveryType;

		this.equipment = submission.equipment;
		this.employees = submission.employees;
		this.temporaryEmployees = submission.temporaryEmployees;

		this.comments = submission.comments;

		this.history = submission.statusHistory?.map(_mapDeliverableHistoryServiceModelToViewModel) ?? [];

		const assignments: DeliverableAssignmentTableViewModel[] = [];
		for (const _assignment of submission.assignments) {
			if (_assignment) {
				assignments.push(new DeliverableAssignmentTableViewModel(submission.deliverableId, submission.workOrderCode, _assignment));
			}
		}

		this.assignments = new TableViewModel(assignments, 0, 0);
	}
}

export class DeliverableAssignmentTableViewModel {
	id: number;
	deliverableId: number;
	deliverableSubmissionId: number;
	status: Nullable<DeliverableStatusViewModel>;
	updatedAt: Date;
	updatedBy: UpdatedByAccountViewModel;
	employee: string;
	notes: string;
	workOrderCode: string;

	history: DeliverableHistoryViewModel[];

	constructor(deliverableId: number, workOrderCode: string, assignment: DeliverableServiceModels.DeliverableAssignmentServiceModel) {
		this.id = assignment.id;
		this.deliverableId = deliverableId;
		this.deliverableSubmissionId = assignment.deliverableSubmissionId;
		this.status = DeliverableStatusViewModel.toViewModelPlain(assignment.statusAbbreviation, assignment.statusStatusColor, assignment.statusTextColor);
		this.updatedAt = assignment.updatedAt;
		this.updatedBy = UpdatedByAccountViewModel.toViewModelPlain(assignment.updatedById, assignment.updatedByFullName);
		this.employee = assignment.assigneeFullName;
		this.notes = assignment.notes;

		this.workOrderCode = workOrderCode;

		this.history = assignment.statusHistory?.map(_mapDeliverableHistoryServiceModelToViewModel) ?? [];
	}
}
