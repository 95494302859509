import * as User from 'ab-viewModels/user.viewModel';
import { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import { FAKE_ACCOUNT_DATA } from 'ab-constants/value';

import { UserStoreState } from 'af-models/user.models';

export const findCurrentCompany = (organization: Nullable<User.OrganizationData>, companyId: number): Nullable<User.CompanyData> => {
	return organization?.companies?.find((_company: User.CompanyData) => _company.id === companyId) ?? null;
};

export const updateCurrentCompany = (state: UserStoreState, company: CompanyViewModel): UserStoreState => {
	const { companyData, organizationData } = state;
	if (!companyData || !organizationData) {
		throw new Error('User not logged in');
	}

	companyData.name = company.name;
	companyData.logo = company.imageUrl;
	companyData.areWorkRequestsEnabled = company.areWorkRequestsEnabled;
	companyData.purchaseOrderNumberPrefix = company.purchaseOrderNumberPrefix;
	organizationData.companies = organizationData.companies.map((_company) => _company.id === company.id ? companyData : _company);

	return {
		...state,
		companyData: { ...companyData },
		organizationData: { ...organizationData },
	};
};

export const isAdmin = (userData: User.UserData): boolean => {
	return userData.id === FAKE_ACCOUNT_DATA.USER_ID;
};
