import * as React from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';

import { RootState } from 'af-reducers';

import * as CompanyActions from 'af-actions/companies';

import WorkOrderSettingsEnum from 'ab-enums/workOrderSettings.enum';

import * as TimeOptionUtils from 'ab-utils/timeOption.util';

import { WORK_ORDER_SETTINGS } from 'af-constants/reduxForms';

import { WorkOrderSettingsRM } from 'ab-requestModels/company/workOrderSettings.requestModel';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Dropdown from 'af-fields/Dropdown';
import { fromVMtoRM } from './formModel';

interface OwnProps {
	isVisible: boolean;
	toggleEdit: () => void;
}

type FormProps = InjectedFormProps<WorkOrderSettingsRM, FormOwnProps>;

interface StateProps {
	companyId: number | undefined;
	initialValues: FormProps['initialValues'] | null;
}

interface DispatchProps {
	editWorkOrderSettings: typeof CompanyActions.editWorkOrderSettings;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & FormProps;

class WorkOrderEdit extends React.PureComponent<Props> {

	editWorkOrderSettings = async (form: WorkOrderSettingsRM) => {
		const { toggleEdit, editWorkOrderSettings } = this.props;

		await editWorkOrderSettings(form);
		toggleEdit();
	};

	onClose = () => {
		const { toggleEdit, reset } = this.props;
		reset();
		toggleEdit();
	};

	render() {
		const { isVisible, handleSubmit, submitting, invalid } = this.props;

		return (
			<CustomModal
				closeModal={this.onClose}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={this.onClose}
					title="Edit Work Order Settings"
				/>
				<CustomModal.Body padding="none">
					<Form onSubmit={handleSubmit(this.editWorkOrderSettings)}>
						<Row className="row--padded-top">
							<Col sm={6}>
								<Field
									component={Dropdown}
									disabled={false}
									fixed={true}
									id="dayShiftStart"
									label={WorkOrderSettingsEnum.dayShiftStart}
									labelKey="name"
									name="dayShiftStart"
									options={TimeOptionUtils.enumWithoutNullValue()}
									valueKey="id"
									withCaret={true}
								/>
							</Col>
							<Col sm={6}>
								<Field
									component={Dropdown}
									disabled={false}
									fixed={true}
									id="dayShiftEnd"
									label={WorkOrderSettingsEnum.dayShiftEnd}
									labelKey="name"
									name="dayShiftEnd"
									options={TimeOptionUtils.enumWithoutNullValue()}
									valueKey="id"
									withCaret={true}
								/>
							</Col>
						</Row>
						<Row className="row--padded-top">
							<Col sm={6}>
								<Field
									component={Dropdown}
									disabled={false}
									fixed={true}
									id="nightShiftStart"
									label={WorkOrderSettingsEnum.nightShiftStart}
									labelKey="name"
									name="nightShiftStart"
									options={TimeOptionUtils.enumWithoutNullValue()}
									valueKey="id"
									withCaret={true}
								/>
							</Col>
							<Col sm={6}>
								<Field
									component={Dropdown}
									disabled={false}
									fixed={true}
									id="nightShiftEnd"
									label={WorkOrderSettingsEnum.nightShiftEnd}
									labelKey="name"
									name="nightShiftEnd"
									options={TimeOptionUtils.enumWithoutNullValue()}
									valueKey="id"
									withCaret={true}
								/>
							</Col>
						</Row>
					</Form>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={this.onClose}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(this.editWorkOrderSettings)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;

	return {
		companyId: company?.id,
		initialValues: company && fromVMtoRM(company),
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		editWorkOrderSettings: CompanyActions.editWorkOrderSettings,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<WorkOrderSettingsRM, FormOwnProps>({ form: WORK_ORDER_SETTINGS, enableReinitialize: true })
);

export default enhance(WorkOrderEdit);
