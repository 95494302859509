import * as React from 'react';
import { History } from 'history';

import Toolbar from './Toolbar';
import Board from './Board';

import SharedModalProps from '../../../Shared/ModalProps';

interface OwnProps extends SharedModalProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	className: string;
	companyName: string;
	droppableId: string;
	dueDate: string;
	forceUnlockOrder: (dueDate: string, workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	history: History;
	isDragAndDropDisabled: boolean;
	isWeeklyViewSelectMultiple: boolean;
	lastOpenedOrderCode: string;
	maxWorkOrdersPerDay: number;
	onHorizontalScroll: (scrollPercentage: number, width: number) => void;
	onHorizontalScrollMount: (scrollElement: Metadata) => void;
	onHorizontalScrollUpdate: () => void;
	orgAlias: string;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	weeklyViewDateWithToolbar: Nullable<string>;
}

type Props = OwnProps;

export default class DayView extends React.PureComponent<Props> {

	forceUnlockOrder = (workOrderId: string) => {
		const { dueDate, forceUnlockOrder } = this.props;
		forceUnlockOrder(dueDate, workOrderId);
	};

	render() {
		const {
			addBlankWorkOrder,
			className,
			companyName,
			currentWorkOrderModalId,
			droppableId,
			dueDate,
			hasPermissionsToEditScheduleBoard,
			history,
			isDragAndDropDisabled,
			isWeeklyViewSelectMultiple,
			lastOpenedOrderCode,
			maxWorkOrdersPerDay,
			onHorizontalScroll,
			onHorizontalScrollMount,
			onHorizontalScrollUpdate,
			orgAlias,
			removeBlankWorkOrder,
			setWorkOrderModalId,
			setWorkOrderNoteModalData,
			setEmployeeModalData,
			setEmployeeModalVisibility,
			setEquipmentModalData,
			setEquipmentModalVisibility,
			setTemporaryEmployeeModalData,
			weeklyViewDateWithToolbar,
		} = this.props;

		const lockForToolbar: boolean = !!weeklyViewDateWithToolbar && weeklyViewDateWithToolbar !== dueDate;
		const lock: boolean = isWeeklyViewSelectMultiple || lockForToolbar;

		return (
			<div className={`schedule-board-week-row ${className}`}>
				{lock && <div className="day-curtain" />}
				<Toolbar
					companyName={companyName}
					dueDate={dueDate}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					history={history}
					isDragAndDropDisabled={isDragAndDropDisabled}
					orgAlias={orgAlias}
				/>
				<Board
					addBlankWorkOrder={addBlankWorkOrder}
					currentWorkOrderModalId={currentWorkOrderModalId}
					droppableId={droppableId}
					dueDate={dueDate}
					forceUnlockOrder={this.forceUnlockOrder}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					isDragAndDropDisabled={isDragAndDropDisabled}
					lastOpenedOrderCode={lastOpenedOrderCode}
					maxWorkOrdersPerDay={maxWorkOrdersPerDay}
					onHorizontalScroll={onHorizontalScroll}
					onHorizontalScrollMount={onHorizontalScrollMount}
					onHorizontalScrollUpdate={onHorizontalScrollUpdate}
					removeBlankWorkOrder={removeBlankWorkOrder}
					setEmployeeModalData={setEmployeeModalData}
					setEmployeeModalVisibility={setEmployeeModalVisibility}
					setEquipmentModalData={setEquipmentModalData}
					setEquipmentModalVisibility={setEquipmentModalVisibility}
					setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
					setWorkOrderModalId={setWorkOrderModalId}
					setWorkOrderNoteModalData={setWorkOrderNoteModalData}
				/>
			</div>
		);
	}
}
