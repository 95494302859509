import * as React from 'react';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { History } from 'history';
import { RowInfo } from 'react-table-6';

import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';

import * as TimeUtils from 'acceligent-shared/utils/time';

import * as DeliverableActions from 'af-actions/deliverable';
import * as DeliverableStatusActions from 'af-actions/deliverableStatus';

import Tooltip from 'af-components/Tooltip';
import TableComponent from 'af-components/Table6/Table';
import CommentCell from 'af-components/Table6/Cells/CommentCell';
import _Table, { OwnProps as TableOwnProps, TabProps, Column, ExpandedDefinition } from 'af-components/Table6';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import DeliverableStatusLabel from 'af-components/DeliverableStatusLabel';
import StatusHistoryModal from 'af-components/DeliverableHistoryModal';
import DeliverableHeader from 'af-components/DeliverableHistoryModal/DeliverableHeader';
import Dropdown from 'af-components/Controls/Dropdown';
import DateFilter from 'af-components/DateFilter';
import { RectangleButton } from 'af-components/MultipleOptionsButton/RectangleButton';
import Breadcrumbs from 'af-components/Breadcrumbs';
import Checkbox from 'af-components/Controls/Checkbox';
import InfiniteScroll from 'af-components/ScrollToLoad';

import { DeliverableTableViewModel } from 'ab-viewModels/deliverableTable.viewModel';
import { DeliverableStatusViewModel } from 'ab-viewModels/deliverableStatusTable.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import CLIENT from 'af-constants/routes/client';
import * as SettingsKeys from 'af-constants/settingsKeys';

import DeliverableChildTable from './ChildTable';
import BulkEditModal from './BulkEditModal';

import { bemElement } from 'ab-utils/bem.util';

import { generateCSVHref } from 'af-utils/csv.utils';
import { getTableRequestModel } from 'af-utils/table.utils';
import * as SettingsUtils from 'af-utils/settings.util';

const ALL_STATUSES_OPTION: Partial<DeliverableStatusViewModel> = {
	name: 'All Statuses',
	id: undefined,
	abbreviation: undefined,
	description: undefined,
	statusColor: undefined,
	textColor: undefined,
	notify: undefined,
	isJobStatus: undefined,
	createdAt: undefined,
	updatedAt: undefined,
	isCompleted: undefined,
};

const Table = _Table as unknown as React.ComponentClass<TableOwnProps<DeliverableTableViewModel>>;

interface OwnProps {
	companyName: string;
	orgAlias: string;
	history: History;
}

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	expandedDeliverableId: number;
	expandedDeliverableSubmissionId: number;
	expandedDeliverableAssignmentId: number;
}

interface DispatchProps {
	findAllForCompanyTable: typeof DeliverableActions.findAllForCompanyTable;
	findAllDataStatuses: typeof DeliverableStatusActions.findAllDataStatusesForCompany;
	findAllJobStatuses: typeof DeliverableStatusActions.findAllJobStatusesForCompany;
}

type ConnectOwnProps = OwnProps & SettingProps;
type Props = ConnectOwnProps & ResolveThunks<DispatchProps>;

interface State {
	showStatusHistoryModal: boolean;
	showBulkEditModal: boolean;
	data: DeliverableTableViewModel[];
	deliverable: Nullable<DeliverableTableViewModel>;
	statuses: DeliverableStatusViewModel[];
	filterByStatus: DeliverableStatusViewModel;
	startDate: Nullable<Date>;
	endDate: Nullable<Date>;
	period: Nullable<TimePeriodRecurrence>;
	expanded: ExpandedDefinition;
	showExpander: boolean;
	expandedCleared: boolean;
	selected: {
		[deliverableId: number]: {
			[deliverableSubmissionId: number]: true;
		};
	};
	isAllSelected: boolean;
}

class DeliverableParentTable extends React.Component<Props, State> {
	state: State = {
		showStatusHistoryModal: false,
		showBulkEditModal: false,
		deliverable: {} as DeliverableTableViewModel,
		statuses: [],
		filterByStatus: {} as DeliverableStatusViewModel,
		startDate: this.props.startDate,
		endDate: this.props.endDate,
		period: this.props.period,
		expanded: {},
		showExpander: false,
		expandedCleared: !this.props.expandedDeliverableId,
		selected: {},
		data: [],
		isAllSelected: false,
	};

	private _tableRef: Nullable<TableComponent<DeliverableTableViewModel>> = null;
	private _list: Nullable<InfiniteScroll<DeliverableTableViewModel>> = null;

	// eslint-disable-next-line @typescript-eslint/member-ordering
	columns: Column<DeliverableTableViewModel>[] = [
		{
			Header: () => {
				const { expanded, showExpander } = this.state;

				if (!showExpander) {
					return null;
				}
				const isAnyExpanded = Object.keys(expanded).some((_key) => !!expanded[_key]);
				return (
					<a
						className={`table-container__expander ${isAnyExpanded ? 'icon-collapse' : 'icon-expand'}`}
						onClick={isAnyExpanded ? this.collapseAll : this.expandAll}
						role="submit"
					/>
				);
			},
			accessor: 'expander',
			sortable: false,
			expander: true,
			Expander: ({ isExpanded, original }) => {
				if (original.submissions?.rows.length) {
					if (original.infiniteScroll) {
						return (
							<div className="table-container__expander-container">
								{isExpanded ? 'Hide' : 'Show'} Submissions
								{isExpanded
									? <span className="table-container__expander icon-collapse" />
									: <span className="table-container__expander icon-expand" />
								}
							</div>
						);
					}
					return (
						<div>
							{isExpanded
								? <span className="table-container__expander icon-collapse" />
								: <span className="table-container__expander icon-expand" />}
						</div>
					);
				}
				return <div />;
			},
			getProps: () => {
				return { className: 'custom-expander' };
			},
			getHeaderProps: () => {
				return { className: 'custom-expander' };
			},
			Cell: () => <div />,
		},
		{
			Header: () => {
				const { isAllSelected } = this.state;

				return (
					<Checkbox
						handleChange={this.selectAll}
						isChecked={isAllSelected}
					/>
				);
			},
			sortable: false,
			accessor: 'id',
			Cell: () => <div />,
			getProps: () => ({ onClick: () => { return; } }),
			resizable: false,
			className: 'expander unresizable',
			headerClassName: 'unresizable',
		},
		{
			Header: 'Job Deliverable Status',
			accessor: 'dataStatus',
			sortable: false,
			Cell: ({ original }) => (
				original.status
					? (
						<DeliverableStatusLabel
							abbreviation={original.status.abbreviation}
							history={original.history}
							onClick={this.onStatusClick(original)}
							statusColor={original.status.statusColor}
							textColor={original.status.textColor}
						/>
					)
					: <EmptyCell />
			),
		},
		{
			Header: 'Job ID',
			sortable: false,
			accessor: 'jobCode',
			Cell: ({ original }) => original.jobCode ?? <EmptyCell />,
		},
		{
			Header: 'Start Date',
			sortable: false,
			accessor: 'startDate',
			Cell: ({ original }) => original.startDate ?? <EmptyCell />,
		},
		{
			Header: 'End Date',
			sortable: false,
			accessor: 'endDate',
			Cell: ({ original }) => original.endDate ?? <EmptyCell />,
		},
		{
			Header: 'Delivery Timeline',
			sortable: false,
			accessor: 'deliverableDeliveryTimeline',
			Cell: ({ original }) => original.deliverableDeliveryTimeline ?? <EmptyCell />,
		},
		{
			Header: 'Comments',
			sortable: false,
			accessor: 'comments',
			Cell: ({ original }) => <CommentCell comments={original.comments} />,
		},
		{
			Header: 'QAQC Assignee',
			sortable: false,
			accessor: 'deliverableAssignee',
			Cell: ({ original }) => original.deliverableAssignee ?? <EmptyCell message="None" />,
		},
		{
			Header: 'Notes',
			accessor: 'notes',
			sortable: false,
			Cell: ({ original }) =>
				original.notes
					? (
						<Tooltip message={original.notes}>
							<span className="icon-display_view notes-icon" />
						</Tooltip>
					)
					: <EmptyCell />,
		},
	];

	async componentDidMount() {
		const { findAllDataStatuses, findAllJobStatuses } = this.props;
		const dataStatuses = await findAllDataStatuses();
		const jobStatuses = await findAllJobStatuses();
		this.setState(() => ({ statuses: [...dataStatuses, ...jobStatuses] }));
	}

	tabs = (): TabProps<DeliverableTableViewModel>[] => {
		const tab = {
			columns: this.columns,
			hasSearchInput: true,
			onExpandedChange: this.onExpandedChange,
			additionalFilter: this.renderFilter,
			onRowClick: this.onRowClick,
			renderBulkActionHeader: this.renderBulkAction,
			buttons: [
				{
					type: TableButtonType.EXPORT,
					hasPermission: true,
					onClick: this.downloadCSV,
				},
			],
			rowActions: [
				{
					label: 'Edit',
					action: this.edit,
					shouldRefresh: false,
				},
			],
		};

		return [
			{ ...tab, label: 'Active', fetch: this.fetchActive },
			{ ...tab, label: 'Completed', fetch: this.fetchCompleted },

		];
	};

	onExpandedChange = (expanded: ExpandedDefinition) => {
		this.setState(() => ({ expanded }));
	};

	expandAll = () => {
		const allRows = this._tableRef?.props?.table?.rows;
		const newExpanded = allRows
			? allRows.reduce((_acc, _row, _index) => {
				_acc[_index] = true;
				return _acc;
			}, {} as ExpandedDefinition)
			: {};
		this.onExpandedChange(newExpanded);
	};

	collapseAll = () => {
		this.onExpandedChange({});
	};

	selectAll = () => {
		this.setState(({ data, isAllSelected }) => {

			if (isAllSelected) {
				return {
					selected: {},
					isAllSelected: false,
				};
			}

			const newSelection = data.reduce((_acc, _deliverable) => {
				_acc[_deliverable.id] = {};
				for (const _submission of _deliverable.submissions.rows) {

					_acc[_deliverable.id][_submission.id] = true;
				}
				return _acc;
			}, {} as State['selected']);

			return {
				selected: newSelection,
				isAllSelected: true,
			};
		}, () => this.refresh(false));
	};

	selectDeliverable = (deliverableId: number) => {
		this.setState(({ selected, data }) => {

			let isAllSelected = true;
			// we need go trough all deliverables to check if isAllSelected is set
			const newSelection = data.reduce((_acc, _deliverable) => {
				if (_deliverable.id !== deliverableId) {
					// not selected deliverable, keep everything as it is
					if (selected[_deliverable.id]) {
						// keep old state and check if all submissions are selected
						_acc[_deliverable.id] = selected[_deliverable.id];
						isAllSelected = isAllSelected && Object.keys(selected[_deliverable.id]).length === _deliverable.submissions.rows.length;
					} else {
						// current deliverable doesn't have any submission selected
						isAllSelected = false;
					}
				} else {
					if (selected[deliverableId] && _deliverable.submissions.rows.length === Object.keys(selected[deliverableId]).length) {
						// all submissions from selected deliverable were selected
						isAllSelected = false;
					} else {
						// select all deliverable submissions
						_acc[_deliverable.id] = {};
						for (const _submission of _deliverable.submissions.rows) {
							_acc[_deliverable.id][_submission.id] = true;
						}

					}
				}
				return _acc;
			}, {} as State['selected']);

			return {
				selected: newSelection,
				isAllSelected,
			};
		}, () => this.refresh(false));
	};

	selectDeliverableSubmission = (deliverableId: number, deliverableSubmissionId: number) => {
		this.setState(({ selected, data }) => {

			let isAllSelected = true;
			let deliverableSubmissionLength: number = 0;
			// we need go trough all deliverables to check if isAllSelected is set
			const newSelection = data.reduce((_acc, _deliverable) => {
				if (_deliverable.id !== deliverableId) {
					// not selected deliverable, keep everything as it is
					if (selected[_deliverable.id]) {
						// keep old state and check if all submissions are selected
						_acc[_deliverable.id] = selected[_deliverable.id];
						isAllSelected = isAllSelected && Object.keys(selected[_deliverable.id]).length === _deliverable.submissions.rows.length;
					} else {
						// current deliverable doesn't have any submission selected
						isAllSelected = false;
					}
				} else {
					deliverableSubmissionLength = _deliverable.submissions.rows.length;
					for (const _submission of _deliverable.submissions.rows) {
						if (_submission.id === deliverableSubmissionId && selected[_deliverable.id]?.[_submission.id]) {
							// deselect submission;
							isAllSelected = false;
						} else {
							if (!_acc[_deliverable.id]) {
								_acc[_deliverable.id] = {};
							}
							if (_submission.id === deliverableSubmissionId || selected[_deliverable.id]?.[_submission.id]) {
								_acc[_deliverable.id][_submission.id] = true;
							}
						}
					}
				}
				return _acc;
			}, {} as State['selected']);

			return {
				selected: newSelection,
				isAllSelected: isAllSelected && Object.keys(newSelection?.[deliverableId] ?? []).length === deliverableSubmissionLength,
			};
		}, () => this.refresh(false));
	};

	onRowClick = (rowInfo: RowInfo) => {
		this.edit(rowInfo.original);
	};

	renderBulkAction = () => {
		const { selected } = this.state;

		const expanded = !!Object.keys(selected).length;
		const className = bemElement('deliverable-dashboard', 'bulk-actions', { expanded });
		return (
			<div className={className}>
				{expanded &&
					<div className="deliverable-dashboard__bulk_action_link" onClick={this.openBulkEditModal}>
						<span className="icon-edit" />
						<span className="deliverable-dashboard__bulk_action_label">Bulk Edit</span>
					</div>
				}
			</div>
		);
	};

	openBulkEditModal = () => {
		this.setState(() => ({ showBulkEditModal: true }));
	};

	closeBulkEditModal = (isSubmitted: boolean = false) => {
		this.setState(() => ({ showBulkEditModal: false }));

		if (isSubmitted) {
			this.setState(() => ({ selected: {}, isAllSelected: false }));
			this.refresh();
		}
	};

	edit = (original: DeliverableTableViewModel) => {
		const { orgAlias, companyName, history } = this.props;
		history.push(CLIENT.COMPANY.DELIVERABLE.JOB.EDIT(orgAlias, companyName, original.id.toString()));
	};

	fetch = async (tableRequestModel: TableQuery, completed: boolean) => {
		const { filterByStatus, startDate, endDate } = this.state;
		const { findAllForCompanyTable } = this.props;
		const { id } = filterByStatus;

		if (!startDate || !endDate) {
			throw new Error('Date range not provided');
		}

		// NOTE: There used to be this.setState(() => ({ expanded: {} })), but it was causing issues when using bulk actions on children
		// Doesn't seem to be necessary any longer
		const data = await findAllForCompanyTable(
			tableRequestModel,
			id,
			TimeUtils.formatDate(startDate),
			TimeUtils.formatDate(endDate),
			completed
		);

		this.setState(() => ({
			data: data.rows,
			showExpander: data.rows.some((_row) => _row.submissions.rows.length),
		}));

		return data;
	};

	fetchActive = async (tableRequestModel: TableQuery) => this.fetch(tableRequestModel, false);
	fetchCompleted = async (tableRequestModel: TableQuery) => this.fetch(tableRequestModel, true);

	downloadCSV = async () => {
		const { findAllForCompanyTable, companyName } = this.props;
		const { filterByStatus, startDate, endDate } = this.state;
		const { id } = filterByStatus;

		const tableRequestModel = (this._tableRef && getTableRequestModel(this._tableRef.state))
			?? (this._list && getTableRequestModel(this._list.state));

		if (!tableRequestModel || !startDate || !endDate) {
			throw new Error('Missing table request model');
		}
		const deliverables = await findAllForCompanyTable(tableRequestModel, id, TimeUtils.formatDate(startDate), TimeUtils.formatDate(endDate), false);

		const data = DeliverableTableViewModel.toCSVData(deliverables.rows);

		const url = generateCSVHref(data);

		const a = document.createElement('a');
		a.download = `${companyName}_deliverables.csv`;
		a.target = '_blank';
		a.href = url;
		a.click();
	};

	renderStatusMenuItem = (optionItem: DeliverableStatusViewModel) => {
		if (optionItem === null) {
			return <></>;
		}
		return (
			<div>
				{optionItem.name}
			</div>
		);
	};

	refresh = (keepIsLoadingState: boolean = true) => {
		if (this._tableRef) {
			this._tableRef.refreshTable(keepIsLoadingState);
		} else if (this._list) {
			this._list.refreshList();
		}
	};

	filterByStatus = async (option: DeliverableStatusViewModel) => {
		this.setState(
			() => ({ filterByStatus: option || {} as DeliverableStatusViewModel }),
			() => {
				if (this._tableRef) {
					this._tableRef.refreshTable();
				} else if (this._list) {
					this._list.refreshList();
				}
			}
		);
	};

	filterByDate = (startDate: Date, endDate: Date) => {
		this.setState(
			() => ({ startDate, endDate }),
			() => {
				SettingsUtils.setDeliverablesStartDate(startDate);
				SettingsUtils.setDeliverablesEndDate(endDate);
				this.refresh();
			}
		);
	};

	changePeriod = (period: TimePeriodRecurrence, selected: Date) => {
		let startDate: Date | undefined, endDate: Date | undefined;

		const selectedMoment = TimeUtils.parseMoment(selected);

		switch (period) {
			case TimePeriodRecurrence.MONTHLY:
				if (!selectedMoment) {
					throw new Error('Moment could not be parsed');
				}
				startDate = selectedMoment.clone().startOf('month').toDate() ?? null;
				endDate = selectedMoment.clone().endOf('month').toDate() ?? null;
				break;
			case TimePeriodRecurrence.WEEKLY:
				if (!selectedMoment) {
					throw new Error('Moment could not be parsed');
				}
				startDate = selectedMoment.clone().startOf('week').toDate() ?? null;
				endDate = selectedMoment.clone().endOf('week').toDate() ?? null;
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
			default:
				startDate = selected;
				endDate = selected;
				break;
		}

		this.setState(
			() => ({
				period: period ?? null,
				startDate: startDate ?? null,
				endDate: endDate ?? null,
			}),
			() => {
				SettingsUtils.setDeliverablesPeriod(period);
				SettingsUtils.setDeliverablesStartDate(startDate);
				SettingsUtils.setDeliverablesEndDate(endDate);
				this.refresh();
			}
		);
	};

	isRowExpanded = (row: DeliverableTableViewModel, index: number): boolean => {
		const { expandedDeliverableId, expandedDeliverableSubmissionId } = this.props;
		const { expanded } = this.state;

		return (row.id === expandedDeliverableId || expanded[index]) && !!row.submissions.rows?.length && !!expandedDeliverableSubmissionId;
	};

	isRowHighlighted = (row: DeliverableTableViewModel): boolean => {
		const { expandedDeliverableSubmissionId, expandedDeliverableId } = this.props;
		const { expandedCleared } = this.state;
		return !expandedCleared && row.id === expandedDeliverableId && !expandedDeliverableSubmissionId;
	};

	clearExpandedState = () => {
		SettingsUtils.setExpandedDeliverableIds(null);
		this.setState(() => ({ expandedCleared: true }));
	};

	renderFilter = () => {
		const { statuses, endDate, startDate, period } = this.state;

		return (
			<div className="table-filter">
				<div className="table-filter__parameter table-filter__parameter--no-margin-top table-filter__parameter--margin-right">
					<Dropdown<DeliverableStatusViewModel>
						className="deliverable-table__filter-button"
						defaultValue={ALL_STATUSES_OPTION as DeliverableStatusViewModel}
						id="deliverables-status-filter"
						labelKey="name"
						onValueChange={this.filterByStatus}
						options={[ALL_STATUSES_OPTION as DeliverableStatusViewModel, ...statuses]}
						renderMenuItem={this.renderStatusMenuItem}
						valueKey="id"
						withBorder={false}
					/>
					<RectangleButton
						action={undefined}
						isLeftOpen={true}
						isSquare={true}
						label={<span className="icon-filter" />}
					/>
				</div>
				{!!endDate && !!startDate && !!period
					? <DateFilter changePeriod={this.changePeriod} endDate={endDate} onChange={this.filterByDate} period={period} startDate={startDate} />
					: <></>
				}
			</div>
		);
	};

	renderChildTable = ({ original }: { original: DeliverableTableViewModel; }) => {
		const { companyName, history, orgAlias, expandedDeliverableAssignmentId, expandedDeliverableSubmissionId } = this.props;
		const { selected } = this.state;
		const selectedDeliverableState = selected?.[original.id] ?? {};
		const isAllSelected = (original.submissions?.rows?.length ?? -1) === Object.keys(selectedDeliverableState ?? {}).length;

		return (
			<DeliverableChildTable
				clearExpandedState={this.clearExpandedState}
				companyName={companyName}
				data={original.submissions}
				deliverableId={original.id}
				expandedDeliverableAssignmentId={expandedDeliverableAssignmentId}
				expandedDeliverableSubmissionId={expandedDeliverableSubmissionId}
				history={history}
				isAllSelected={isAllSelected}
				orgAlias={orgAlias}
				selectAllSubmissions={this.selectDeliverable}
				selected={selected[original.id]}
				selectSubmission={this.selectDeliverableSubmission}
			/>
		);
	};

	onStatusClick = (original: DeliverableTableViewModel) => {
		return (event: React.MouseEvent) => {
			event.stopPropagation();
			event.preventDefault();
			this.setState(() => ({ showStatusHistoryModal: true, deliverable: original }));
		};
	};

	closeModal = () => this.setState(() => ({ showStatusHistoryModal: false, deliverable: null }));

	onTableMount = (table: TableComponent<DeliverableTableViewModel>, list) => {
		this._tableRef = table;
		this._list = list;
	};

	breadcrumbs = () => [{ label: 'Deliverables' }];

	render() {
		const { showStatusHistoryModal, deliverable, expanded, selected, showBulkEditModal } = this.state;
		const { expandedDeliverableId } = this.props;

		const selectedSubmissionIds = Object.keys(selected).reduce((_acc, _deliverableId) => {
			return _acc.concat(Object.keys(selected[_deliverableId]));
		}, [] as string[]);
		return (
			<div className="form-segment form-segment--maxi deliverable-dashboard">
				<Breadcrumbs items={this.breadcrumbs()} />
				<Table
					autoHeight={true}
					expanded={expanded}
					getRowExpanded={expandedDeliverableId ? this.isRowExpanded : undefined}
					getRowHighlighted={expandedDeliverableId ? this.isRowHighlighted : undefined}
					onMount={this.onTableMount}
					onScrollIntoNode={this.clearExpandedState}
					SubComponent={this.renderChildTable}
					tableName={TableNameEnum.DELIVERABLE_TABLE}
					tabs={this.tabs()}
				/>
				{(showStatusHistoryModal && deliverable) &&
					<StatusHistoryModal
						closeModal={this.closeModal}
						history={deliverable.history}
						showModal={showStatusHistoryModal}
						statusInfo={
							<DeliverableHeader
								assigneeFullName={deliverable.deliverableAssignee}
								comments={deliverable.comments}
								jobCode={deliverable.jobCode}
								notes={deliverable.notes}
							/>
						}
					/>
				}
				<BulkEditModal
					closeModal={this.closeBulkEditModal}
					selectedSubmissionIds={selectedSubmissionIds}
					showModal={showBulkEditModal}
				/>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllForCompanyTable: DeliverableActions.findAllForCompanyTable,
		findAllDataStatuses: DeliverableStatusActions.findAllDataStatusesForCompany,
		findAllJobStatuses: DeliverableStatusActions.findAllJobStatusesForCompany,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.DELIVERABLES_START_DATE(),
			mappedName: 'startDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.DELIVERABLES_END_DATE(),
			mappedName: 'endDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.DELIVERABLES_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.EXPANDED_DELIVERABLE_ID(),
			mappedName: 'expandedDeliverableId',
			normalize: (value: string) => +value,
			defaultValue: null,
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
		{
			key: SettingsKeys.EXPANDED_DELIVERABLE_SUBMISSION_ID(),
			mappedName: 'expandedDeliverableSubmissionId',
			normalize: (value: string) => +value,
			defaultValue: null,
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
		{
			key: SettingsKeys.EXPANDED_DELIVERABLE_ASSIGNMENT_ID(),
			mappedName: 'expandedDeliverableAssignmentId',
			normalize: (value: string) => +value,
			defaultValue: null,
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
	])),
	connect<null, DispatchProps, ConnectOwnProps>(null, mapDispatchToProps())
);

export default enhance(DeliverableParentTable);
