import * as React from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';

import OrderDeliveryMethod from 'acceligent-shared/enums/orderDeliveryMethod';
import OrderCarrier from 'acceligent-shared/enums/orderCarrier';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';
import RadioGroup from 'af-fields/RadioGroup';

import { useLazyLoad } from 'af-utils/react.util';

import LocationVM from 'ab-viewModels/location/option.viewModel';

import MapField from 'af-components/Maps/AddressMap/PlainStreet';

import styles from './styles.module.scss';
import OrderUpsertFM from './formModel';

const OrderDeliveryMethodLabel: Record<OrderDeliveryMethod, string> = {
	[OrderDeliveryMethod.DELIVERY]: 'Deliver to Field',
	[OrderDeliveryMethod.PICKUP]: 'Pickup',
	[OrderDeliveryMethod.SHIPMENT]: 'Shipment',
};

const DELIVERY_METHOD_OPTIONS = Object.values(OrderDeliveryMethod).map((_deliveryMethod) => ({
	value: _deliveryMethod,
	label: OrderDeliveryMethodLabel[_deliveryMethod],
}));

const CARRIER_OPTIONS = Object.values(OrderCarrier).map((_carrier, _index) => ({
	id: _index,
	label: _carrier,
}));

type Props = {
	formDeliveryMethod: OrderDeliveryMethod;
	findAllLocationsForCompany: () => Promise<LocationVM[]>;
	formName: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
	disabled: boolean;
	formValues: Nullable<OrderUpsertFM>;
};

const renderLocationOption = (option: LocationVM) => (
	<div>
		<div>{option.nickname}</div>
		<small className={styles['order-form__menu-option__sub-text']}>{option.street}</small>
	</div>
);

const renderLocationSelected = (option: LocationVM) => (
	<div>{option.nickname}</div>
);

const DeliveryMethodFields: React.FC<Props> = ({ formDeliveryMethod, findAllLocationsForCompany, formName, change, disabled, formValues }) => {
	const { lazyLoad: lazyLoadLocations, options: locationOptions } = useLazyLoad(findAllLocationsForCompany);

	const changeFormType = React.useCallback((field: string, value: OrderUpsertFM['deliveryMethod']) => {

		if (formDeliveryMethod === OrderDeliveryMethod.PICKUP) {
			change('locationId', null);
			change('location', null);
		} else if (formDeliveryMethod === OrderDeliveryMethod.SHIPMENT) {
			change('carrier', null);
			change('attendee', null);
			change('trackingNumber', null);
			change('addressId', null);
			change('address', null);
		}

		change(field, value);
	}, [change, formDeliveryMethod]);

	const formLocation = React.useMemo(() => formValues?.location, [formValues?.location]);

	const attendeeName = React.useMemo(() => formValues?.attendee ?? 'N/A', [formValues?.attendee]);

	const carrier = React.useMemo(() => formValues?.carrier ?? 'N/A', [formValues?.carrier]);

	const formAddress = React.useMemo(() => formValues?.address?.street ?? 'N/A', [formValues?.address]);

	const trackingNumber = React.useMemo(() => formValues?.trackingNumber ?? 'N/A', [formValues?.trackingNumber]);

	return (
		<Row className={styles['order-row']}>
			<Col md={24}>
				{
					!disabled &&
					<RadioGroup
						changeField={changeFormType}
						disabled={disabled}
						extraClass={styles['radio-group']}
						field={OrderUpsertFM.getAttributeName('deliveryMethod')}
						initialValue={OrderDeliveryMethod.DELIVERY}
						inline={true}
						items={DELIVERY_METHOD_OPTIONS}
					/>
				}
				{
					disabled && formDeliveryMethod === OrderDeliveryMethod.DELIVERY &&
					<div className={styles['field-value']}>Deliver to Field</div>
				}
			</Col>
			{formDeliveryMethod === OrderDeliveryMethod.PICKUP && (
				<Col md={24}>
					{
						!disabled &&
						<Field
							component={Dropdown}
							disabled={disabled}
							id="location_select"
							label="Location *"
							name="locationId"
							onLazyLoad={lazyLoadLocations}
							options={locationOptions}
							placeholder="Select Location"
							propName={OrderUpsertFM.getAttributeName('location')}
							renderMenuItem={renderLocationOption}
							renderSelected={renderLocationSelected}
							valueKey="id"
							withCaret={true}
						/>
					}
					{
						disabled &&
						<>
							<div className={styles['field-value']}>Pickup</div>
							{
								!formLocation &&
								<div className={styles['field-value']}>-</div>
							}
							{
								formLocation &&
								<div className={styles['field-value']}>{formLocation.nickname}</div>
							}
						</>
					}
				</Col>
			)}
			{formDeliveryMethod === OrderDeliveryMethod.SHIPMENT && (
				<>
					{
						disabled &&
						<div className={styles['field-value']}>Shipment</div>
					}
					<Col md={12}>
						{
							!disabled &&
							<Field
								component={Input}
								label="Attendee"
								name={OrderUpsertFM.getAttributeName('attendee')}
								placeholder="Enter Attendee"
								type="text"
							/>
						}
						{
							disabled &&
							<>
								<div>Attendee</div>
								<div className={styles['field-value']}>{attendeeName}</div>
							</>
						}
						{
							!disabled &&
							<Field
								component={Dropdown}
								disabled={disabled}
								label="Carrier *"
								labelKey="label"
								name={OrderUpsertFM.getAttributeName('carrier')}
								options={CARRIER_OPTIONS}
								placeholder="Select Carrier"
								valueKey="label"
								withCaret={true}
							/>
						}
						{
							disabled &&
							<>
								<div>Carrier</div>
								<div className={styles['field-value']}>{carrier}</div>
							</>
						}
					</Col>
					<Col md={12}>
						{
							!disabled &&
							<MapField
								aa1PropName="address.aa1"
								aa2PropName="address.aa2"
								aa3PropName="address.aa3"
								countryPropName="address.country"
								customHeader="Address *"
								formName={formName}
								latitudePropName="address.latitude"
								localityPropName="address.locality"
								locationPropName="address.street"
								longitudePropName="address.longitude"
								postalCodePropName="address.zip"
								postalOfficeBoxPropName="address.postalOfficeBoxCode"
								routePropName="address.route"
								streetNumberPropName="address.streetNumber"
								suitePropName="address.suite"
							/>
						}
						{
							disabled &&
							<>
								<div>Address</div>
								<div className={styles['field-value']}>{formAddress}</div>
							</>
						}
						{
							!disabled &&
							<Field
								component={Input}
								disabled={disabled}
								label="Tracking number"
								name={OrderUpsertFM.getAttributeName('trackingNumber')}
								placeholder="Enter Tracking Number"
							/>
						}
						{
							disabled &&
							<>
								<div>Tracking number</div>
								<div className={styles['field-value']}>{trackingNumber}</div>
							</>
						}
					</Col>

				</>
			)}
		</Row>
	);
};

export default React.memo(DeliveryMethodFields);
