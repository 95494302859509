import * as React from 'react';
import { Field } from 'redux-form';

import Input from 'af-fields/Input';

import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';

import { formSectionClass, formSectionClassColumn2, formSectionClassRow, formSectionTitleClass } from '../../helpers';

type Props = {
	isDisabled: boolean;
};

const RequierementsSection: React.FC<Props> = ({ isDisabled }) => {
	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Requirements
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="requestMBEOrWBERequirementsNote"
						label="MBE/WBE Requirements"
						name={getAttributeName('requestMBEOrWBERequirementsNote')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="requestBondRequirementsNote"
						label="Bond Requirements"
						name={getAttributeName('requestBondRequirementsNote')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="requestHSERequirementsNote"
						label="HSE Requirements"
						name={getAttributeName('requestHSERequirementsNote')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="retainage"
						label="Retainage"
						name={getAttributeName('retainage')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="requestInsuranceRequirementsNote"
						label="Insurance Requirements"
						name={getAttributeName('requestInsuranceRequirementsNote')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="requestLiquidatedDamagesNote"
						label="Liquidated Damages"
						name={getAttributeName('requestLiquidatedDamagesNote')}
						placeholder="Enter Requirements"
						type="text"
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(RequierementsSection);
