/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { TIMEOUT_DELAY } from 'af-constants/values';

export default class Timeout {
	private _timeoutTimer: number;
	private _onTimeout: () => void;
	private _timeoutDelay: number;

	constructor(onTimeout: () => void, timeoutDelay: number = TIMEOUT_DELAY) {
		this._onTimeout = onTimeout;
		this._timeoutDelay = timeoutDelay;
		this.reset();
	}

	cancel = (): void => {
		if (this._timeoutTimer) {
			window.clearTimeout(this._timeoutTimer);
		}
	};

	reset = (defaultTimeoutOverride?: () => void): void => {
		this.cancel();

		if (defaultTimeoutOverride) {
			this._onTimeout = defaultTimeoutOverride;
		}

		this._timeoutTimer = window.setTimeout(() => {
			if (this._onTimeout) {
				this._onTimeout();
			}
		}, this._timeoutDelay);
	};

}

export const awaitableTimeout = (callback: () => Promise<void | string>, ms: number) => {
	return new Promise((resolve) => setTimeout(async () => {
		const value = await callback();
		if (value) {
			resolve(value);
		} else {
			resolve(true);
		}
	}, ms));
};
