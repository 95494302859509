import * as React from 'react';

interface Props {
	onMount: (ref: Nullable<React.RefObject<HTMLDivElement>>) => void;
	maxResourceItems: number;
}

export default class MetricsCardHeader extends React.PureComponent<Props> {
	_ref: Nullable<React.RefObject<HTMLDivElement>> = null;

	constructor(props: Props) {
		super(props);
		this._ref = React.createRef();
	}

	componentDidMount() {
		const { onMount } = this.props;
		if (onMount) {
			onMount(this._ref);
		}
	}

	render() {
		const { maxResourceItems } = this.props;

		return (
			<div className="schedule-board-card-wrapper card-header columns-1" ref={this._ref}>
				<div className="schedule-board-arrow-index-container" />
				<div>
					<div>
						<div className="title schedule-board-card-crew" />
						<div className="title schedule-board-card-address">{'Customer & Work Location'}</div>
						<div className="title schedule-board-card-supervisor">PM/Superintendent</div>
						<div className="title schedule-board-card-shop-work">{'Shift & Work'}</div>
					</div>
					<div className={`title schedule-board-card-resources items-${maxResourceItems}`}>
						Labor/Equipment
					</div>
				</div>
				<div className="input-fields-titles">
					<div className="title">Revenue</div>
					<div className="title">Man Hour Average</div>
					<br />
					<div className="title">Job Hours</div>
					<div className="title">Shop Hours</div>
					<div className="title">Travel Hours</div>
					<br />
					<div className="title work-description">Work Description</div>
				</div>
			</div>
		);
	}
}
