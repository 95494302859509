import * as React from 'react';

interface OwnProps {
	message?: string;
}

type Props = OwnProps;

function EmptyCell({ message = 'N/A' }: Props) {
	return (
		<div className="cell-additional-info">
			{message}
		</div>
	);
}

export default React.memo(EmptyCell);
