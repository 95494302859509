import * as ActionTypes from 'af-constants/actionTypes';

import { DailyTipViewModel } from 'ab-viewModels/dailyTip.viewModel';

export interface UpdateDailyTip {
	type: typeof ActionTypes.UPDATE_DAILY_TIP;
	payload: DailyTipViewModel;
}

export function UPDATE_DAILY_TIP(payload: DailyTipViewModel): UpdateDailyTip {
	return {
		type: ActionTypes.UPDATE_DAILY_TIP,
		payload,
	};
}

export type DailyTipAction = UpdateDailyTip;
