import * as React from 'react';
import { HandleThunkActionCreator } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { SkillRequestModel } from 'ab-requestModels/skill.requestModel';

import * as SkillActions from 'af-actions/skill';

import { SKILL_CREATE } from 'af-constants/reduxForms';

import SkillForm from 'af-components/SharedForms/Resources/Skill';
import { validate } from 'af-components/SharedForms/Resources/Skill/validation';

interface OwnProps {
	createSkill: HandleThunkActionCreator<typeof SkillActions.create>;
	close: () => void;
}

type Props = OwnProps & InjectedFormProps<SkillRequestModel, OwnProps>;

class SkillCreate extends React.PureComponent<Props> {
	createSkill = async (form: SkillRequestModel) => {
		const { close, createSkill } = this.props;
		await createSkill(form);
		close();
	};

	render() {
		return (
			<SkillForm onSubmit={this.createSkill} {...this.props} />
		);
	}
}

export default reduxForm<SkillRequestModel, OwnProps>({ form: SKILL_CREATE, validate, enableReinitialize: true })(SkillCreate);
