import * as React from 'react';
import { Field as ReduxField } from 'redux-form';

import Text from 'af-fields/Text';

interface OwnProps {
	formName: string;
	name: string;
	tooltipMessage?: React.ComponentProps<typeof Text>['tooltipMessage'];
}

type Props = OwnProps;

const TimeDropdownInput: React.FC<Props> = (props) => {
	const {
		formName,
		name,
		tooltipMessage,
	} = props;
	return (
		<ReduxField
			component={Text}
			defaultValue="None"
			label={name}
			name={`${formName}.street`}
			tooltipMessage={tooltipMessage}
		/>
	);
};

export default TimeDropdownInput;
