import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

const setToGivenDate = (dateToChange: Date, date: Date) => {
	const newDate = new Date(dateToChange);
	newDate.setDate(date.getDate());
	newDate.setMonth(date.getMonth());
	newDate.setFullYear(date.getFullYear());
	return newDate;
};

const dateWithSecondsFloored = (dateToChange: Date) => {
	const newDate = new Date(dateToChange);
	newDate.setSeconds(0);
	newDate.setMilliseconds(0);
	return newDate;
};

type TimeSheetEntry = {
	startDate: Nullable<string>;
	startTime: Nullable<string>;
	endTime: Nullable<string>;
};

type Result = {
	startTime: Nullable<string>;
	endTime: Nullable<string>;
};

/**
 * @param value ISO_DATETIME
 */
export const adjustStartTime = (value: Nullable<string>, entry: TimeSheetEntry): Result => {
	const { endTime, startDate } = entry;

	const _value = value ? TimeUtils.toDate(value, TimeFormat.ISO_DATETIME) : null;

	if (!_value) {
		return {
			startTime: null,
			endTime: null,
		};
	}

	let interrimStartTime: Date = dateWithSecondsFloored(_value);
	let interrimEndTime: Nullable<Date> = null;

	const _startDate = !!startDate ? TimeUtils.toDate(startDate, TimeFormat.DATE_ONLY) : null;

	if (!!_startDate) {
		interrimStartTime = setToGivenDate(interrimStartTime, _startDate);
	}

	const _endTime = !!endTime ? TimeUtils.toDate(endTime, TimeFormat.ISO_DATETIME) : null;
	if (!!_endTime) {
		interrimEndTime = setToGivenDate(_endTime, interrimStartTime);
		if (interrimEndTime < interrimStartTime) {
			interrimEndTime = TimeUtils.addDays(interrimEndTime, 1).toDate();
		}
	}

	return {
		startTime: TimeUtils.formatDate(interrimStartTime, TimeFormat.ISO_DATETIME),
		endTime: interrimEndTime ? TimeUtils.formatDate(interrimEndTime, TimeFormat.ISO_DATETIME) : null,
	};
};

/**
 * @param value ISO_DATETIME
 */
export const adjustEndTime = (value: Nullable<string>, entry: TimeSheetEntry): Result => {
	const { startTime, startDate } = entry;

	if (!value) {
		return {
			startTime: null,
			endTime: null,
		};
	}

	let interrimStartTime: Nullable<Date> = null;
	let interrimEndTime: Nullable<Date> = null;

	const valueDate = TimeUtils.toDate(value, TimeFormat.ISO_DATETIME);

	if (!valueDate) {
		return {
			startTime: null,
			endTime: null,
		};
	}

	interrimEndTime = dateWithSecondsFloored(valueDate);
	interrimStartTime = startTime ? TimeUtils.toDate(startTime, TimeFormat.ISO_DATETIME) : null;

	const _startDate = !!startDate ? TimeUtils.toDate(startDate, TimeFormat.DATE_ONLY) : null;
	if (_startDate) {
		interrimEndTime = setToGivenDate(interrimEndTime, _startDate);
		interrimStartTime = interrimStartTime ? setToGivenDate(interrimStartTime, _startDate) : null;
	}

	if (interrimStartTime && (interrimEndTime < interrimStartTime)) {
		interrimEndTime = TimeUtils.addDays(interrimEndTime, 1).toDate();
	}

	return {
		startTime: interrimStartTime ? TimeUtils.formatDate(interrimStartTime, TimeFormat.ISO_DATETIME) : null,
		endTime: interrimEndTime ? TimeUtils.formatDate(interrimEndTime, TimeFormat.ISO_DATETIME) : null,
	};
};

/**
 * @param value ISO_DATETIME
 */
export const onStartDateChange = (value: string, entry: TimeSheetEntry): Result => {
	const { startTime, endTime } = entry;

	const _value = !!value ? TimeUtils.toDate(value, TimeFormat.ISO_DATETIME) : null;

	if (!_value) {
		return {
			startTime: null,
			endTime: null,
		};
	}

	let interrimStartTime: Nullable<Date> = null;
	let interrimEndTime: Nullable<Date> = null;

	const _startTime = !!startTime ? TimeUtils.toDate(startTime, TimeFormat.ISO_DATETIME) : null;
	if (_startTime) {
		interrimStartTime = setToGivenDate(_startTime, _value);
	}

	const _endTime = !!endTime ? TimeUtils.toDate(endTime, TimeFormat.ISO_DATETIME) : null;
	if (_endTime) {
		interrimEndTime = setToGivenDate(_endTime, _value);
		if (interrimStartTime && interrimEndTime < interrimStartTime) {
			interrimEndTime = TimeUtils.addDays(interrimEndTime, 1).toDate();
		}
	}

	return {
		startTime: interrimStartTime ? TimeUtils.formatDate(interrimStartTime, TimeFormat.ISO_DATETIME) : null,
		endTime: interrimEndTime ? TimeUtils.formatDate(interrimEndTime, TimeFormat.ISO_DATETIME) : null,
	};
};
