import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import Breadcrumbs from 'af-components/Breadcrumbs';

interface OwnProps {
	originUrl: string;
	originLabel: string;
}

type Props = OwnProps;

const OrderLoading: React.FC<Props> = (props) => {
	const { originUrl, originLabel } = props;

	return (
		<div>
			<Breadcrumbs
				items={
					[
						{ label: originLabel, url: originUrl },
						{ label: 'Edit Work Order' },
					]
				}
			/>
			<div className="form-segment work-order-upsert">
				<div className="form-box work-order-upsert__sticky-header">
					<Row className="row--padded">
						<Col className="loading-box-field rounded standalone" sm={2} />
						<Col className="loading-box-field standalone" sm={3} />
						<Col className="loading-box-field standalone" sm={3} />
						<Col sm={6} />
						<Col className="loading-box-field standalone" sm={4} />
						<Col className="loading-box-field rounded standalone" sm={2} />
						<Col className="loading-box-field rounded standalone" sm={2} />
						<Col className="loading-box-field rounded standalone" sm={2} />
					</Row>
				</div>
				<div className="form-box work-order-upsert__non-sticky-header">
					<Row className="row--padded">
						<Col className="loading-box-field standalone" sm={5} />
						<Col sm={6} />
						<Col className="loading-box-field standalone" sm={4} />
						<Col sm={6} />
						<Col className="loading-box-field standalone" sm={3} />
					</Row>
				</div>
				<br />
				<div className="form-box">
					<Row className="row--padded"><Col className="loading-box-field standalone" sm={3} /></Row>
					<Row className="row--padded">
						<Col className="loading-box-field standalone" sm={8} />
						<Col className="loading-box-field standalone" sm={8} />
						<Col className="loading-box-field standalone" sm={8} />
					</Row>
					<br />
					<Row className="row--padded"><Col className="loading-box-field standalone" sm={3} /></Row>
					<Row className="row--padded"><Col className="loading-box-field standalone" sm={8} /></Row>
					<br />
					<Row className="row--padded"><Col className="loading-box-field standalone" sm={3} /></Row>
					<Row className="row--padded"><Col className="loading-box-field standalone" sm={8} /></Row>
					<br />
					<Row className="row--padded"><Col className="loading-box-field standalone" sm={3} /></Row>
					<Row className="row--padded">
						<Col className="loading-box-field standalone" sm={8} />
						<Col className="loading-box-field standalone" sm={8} />
						<Col className="loading-box-field standalone" sm={8} />
					</Row>
					<br />
					<Row className="row--padded"><Col className="loading-box-field standalone" sm={5} /></Row>
					<Row className="row--padded">
						<Col className="loading-box-area standalone" sm={12} />
						<Col className="loading-box-area standalone" sm={12} />
					</Row>
				</div>
			</div>
		</div>
	);
};

export default OrderLoading;
