import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import * as ResourceStatusesUpdateRequestModel from 'ab-requestModels/resources/resourceStatusesUpdate.requestModel';

import * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import * as EmployeeStatusActions from 'af-actions/employeeStatus';

import Breadcrumbs from 'af-components/Breadcrumbs';

import { RootState } from 'af-reducers';

import { RESOURCE_STATUS } from 'af-constants/reduxForms';

import ResourceStatusesPreview from '../Shared/ResourceStatusesPreview';
import ResourceStatusesModal from '../Shared/ResourceStatusesModal';
import { CustomRouteComponentProps } from 'react-router-dom';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	isEditMode: boolean;
	statuses: ResourceStatusesViewModel.Default | undefined;
}

class EmployeeStatuses extends React.Component<Props, State> {

	state: State = {
		isEditMode: false,
		statuses: undefined,
	};

	async componentDidMount() {
		const { findAll } = this.props;
		const statuses = await findAll();
		this.setState(() => ({ statuses }));
	}

	getStatuses = (): ResourceStatusesViewModel.Default => {
		const { statuses } = this.state;
		if (!statuses) {
			throw new Error('Statuses not loaded');
		}
		return statuses;
	};

	handleSubmitUpdate = async (data: ResourceStatusesUpdateRequestModel.Default) => {
		const { updateAll } = this.props;
		const statuses = await updateAll(data);
		this.setState(() => ({ statuses }));
	};

	toggleEditMode = () => {
		this.setState((prevStatus: State) => ({ isEditMode: !prevStatus.isEditMode }));
	};

	render() {
		const {
			isEditMode,
			statuses,
		} = this.state;

		const { isLoading } = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs items={[{ label: 'Employee Status' }]} />
				<ResourceStatusesPreview
					isLoading={isLoading}
					statuses={statuses}
					toggleEdit={this.toggleEditMode}
				/>
				<ResourceStatusesModal
					close={this.toggleEditMode}
					getStatuses={this.getStatuses}
					isVisible={isEditMode}
					submit={this.handleSubmitUpdate}
				/>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		findAll: EmployeeStatusActions.findAllForCompany,
		updateAll: EmployeeStatusActions.updateAllForCompany,
	};
}

function mapStateToProps(state: RootState) {
	return {
		isLoading: state.http.submitting.includes(RESOURCE_STATUS),
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(EmployeeStatuses);
