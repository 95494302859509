import * as React from 'react';

type Props = React.PropsWithChildren<unknown>;

const TemporaryEmployeeDraggableBadgesContainer: React.FC<Props> = (props: Props) => {
	const { children } = props;

	return (
		<div className="badges-container">
			{children}
		</div>
	);
};

export default TemporaryEmployeeDraggableBadgesContainer;
