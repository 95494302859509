import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import { formatDate, parseDate } from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';
import { CrewTypeViewModel } from 'ab-viewModels/crewType.viewModel';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';

import PlainDateInput from 'af-components/Controls/DateInput';

import * as EmployeeActions from 'af-actions/employee';
import * as CrewTypeActions from 'af-actions/crewType';

import DropdownEmployeeItem from 'af-components/DropdownEmployeeItem';
import LabelWithColor from 'af-components/LabelWithColor';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import WorkOrderFM from '../formModel';

interface OwnProps {
	onDateChange: (date: string) => void;
	resetTimer: () => void;
}

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<WorkOrderFM>;

interface State {
	selectedDate: Nullable<Date>;
	crewTypes: CrewTypeViewModel[];
	superintendents: EmployeeOptionVM[];
}

class Details extends React.PureComponent<Props, State> {
	_datepicker = null;

	state: State = {
		selectedDate: this.props.dueDate ? parseDate(this.props.dueDate, TimeFormatEnum.DB_DATE_ONLY) : null,
		crewTypes: [],
		superintendents: [],
	};

	static FILTER_SI_BY = ['firstName', 'lastName', 'fullName', 'formattedCode', 'accountTemplate'];

	static getDerivedStateFromProps(props: Props) {
		return {
			selectedDate: props.dueDate ? parseDate(props.dueDate, TimeFormatEnum.DB_DATE_ONLY) : null,
		};
	}

	static renderSuperintendent = (option: EmployeeOptionVM) => <DropdownEmployeeItem option={option} showUserRole={true} />;
	static renderSuperintendentSelected = (option: EmployeeOptionVM) => <DropdownEmployeeItem option={option} showUserRole={false} />;

	static renderCrewTypeOption = (option: CrewTypeViewModel) => <LabelWithColor color={option.color} text={option.name} />;

	componentDidUpdate(prevProps: Props) {
		if (prevProps.crewTypeId !== this.props.crewTypeId || prevProps.customCrewType !== this.props.customCrewType) {
			this.updateInternalState();
		}

	}

	onDateSelect = async (date: Date) => {
		const { resetTimer, onDateChange, change } = this.props;

		const selectedDate = formatDate(date, TimeFormatEnum.DB_DATE_ONLY);
		onDateChange(selectedDate);
		change('dueDate', selectedDate);
		resetTimer();
	};

	updateInternalState = () => {
		const { isJobInternal, isWOInternal, crewTypeId, customCrewType, change } = this.props;
		const isInternal = (isJobInternal && !!customCrewType) || (!isJobInternal && !crewTypeId);
		if (isWOInternal !== isInternal) {
			change('isInternal', isInternal);
		}
	};

	lazyLoadSuperintendent = async (isLazyLoaded: boolean) => {
		const { loadSuperintendents } = this.props;
		if (!isLazyLoaded) {
			const superintendents = await loadSuperintendents();
			this.setState(() => ({ superintendents }));
		}
	};

	lazyLoadCrewType = async (isLazyLoaded: boolean) => {
		const { loadCrewTypes } = this.props;
		if (!isLazyLoaded) {
			const crewTypes = await loadCrewTypes();
			this.setState(() => ({ crewTypes }));
		}
	};

	render() {
		const { disabled, resetTimer, isJobInternal, dueDate, workOrderId } = this.props;
		const { selectedDate, crewTypes, superintendents } = this.state;
		return (
			<Row className="row--padded work-order-upsert__section">
				<Col lg={6}>
					{isJobInternal ?
						<Field
							component={Input}
							disabled={disabled}
							hideErrorText={true}
							id="customCrewType"
							isStandalone={true}
							label="Crew Type"
							name="customCrewType"
							placeholder="Crew Type *"
						/> :
						<Field
							component={Dropdown}
							containerId="work-order__crew-type"
							disabled={disabled}
							disableErrorMessages={true}
							dropdownId="work-order__crew-type--dropdown"
							id="crewTypeId"
							isWhite={true}
							label="Crew Type *"
							name="crewTypeId"
							onLazyLoad={this.lazyLoadCrewType}
							onValueChange={resetTimer}
							options={crewTypes}
							placeholder="Select Crew Type"
							propName="crewType"
							renderMenuItem={Details.renderCrewTypeOption}
							renderSelected={Details.renderCrewTypeOption}
							valueKey="id"
							withCaret={true}
						/>
					}
				</Col>
				<Col lg={6}>
					<Field
						component={Dropdown}
						containerId="work-order__superintendent"
						disabled={disabled}
						dropdownClassName="work-order-upsert__user-type-dropdown"
						dropdownId="work-order__superintendent--dropdown"
						filterable={true}
						filterBy={Details.FILTER_SI_BY}
						id="supervisorId"
						label="Superintendent *"
						name="supervisorId"
						onLazyLoad={this.lazyLoadSuperintendent}
						onValueChange={resetTimer}
						options={superintendents}
						placeholder="Select Superintendent"
						propName="supervisor"
						renderMenuItem={Details.renderSuperintendent}
						renderSelected={Details.renderSuperintendentSelected}
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				{dueDate && workOrderId ?
					<Col lg={12}>
						<div>Date *</div>
						<div className="work-order-upsert__sticky-date-locked" >
							{formatDate(dueDate, TimeFormatEnum.DAY_WITH_DATE)}
						</div>
					</Col> :
					<>
						<Col lg={4}>
							<PlainDateInput
								dateFormat={TimeFormatEnum.DATE_ONLY}
								defaultValue={selectedDate ?? undefined}
								disabled={disabled}
								isStandalone={true}
								label="Date *"
								onValueChange={this.onDateSelect}
								originalDateFormat={TimeFormatEnum.DB_DATE_ONLY}
								placeholderText="Select Date of Work *"
								selectedValue={selectedDate ?? undefined}
							/>
						</Col>
						<Col lg={8} />
					</>
				}
			</Row>
		);
	}
}

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {

	const { workOrder: { order } } = state;

	const {
		id: workOrderId,
		job,
		dueDate,
		status,
		isInternal: isWOInternal,
		customCrewType,
		crewTypeId,
	} = selector(state, 'id', 'job', 'dueDate', 'status', 'isInternal', 'customCrewType', 'crewTypeId');

	return {
		workOrderId,
		disabled: status === WorkOrderStatus.CANCELED || status === WorkOrderStatus.LOCKED || order?.isPaused,
		isJobInternal: job?.isInternal,
		isWOInternal,
		dueDate,
		customCrewType,
		crewTypeId: crewTypeId,
	};
}

function mapDispatchToProps() {
	return {
		loadSuperintendents: EmployeeActions.findAllSuperintendents,
		loadCrewTypes: CrewTypeActions.findAllForCompanyList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<WorkOrderFM>({ form: WORK_ORDER_FORM })
);

export default enhance(Details);
