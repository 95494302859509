import * as React from 'react';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { RootState } from 'af-reducers';

import * as WorkOrderActions from 'af-actions/workOrder';

import { WorkOrderNoteForm } from 'ab-requestModels/workOrder.requestModel';

import { WORK_ORDER_NOTE } from 'af-constants/reduxForms';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import Textarea from 'af-fields/Textarea';

type FormProps = InjectedFormProps<WorkOrderNoteForm, FormOwnProps>;

interface OwnProps {
	closeModal: () => void;
	workOrderCode: Nullable<string>;
	workOrderDate: Nullable<string>;
}

interface StateProps {
	initialValues: FormProps['initialValues'];
}

interface DispatchProps {
	editWorkOrderNote: typeof WorkOrderActions.editWorkOrderNote;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & FormProps;

interface State {
	showModal: boolean;
}

class WorkOrderNoteModal extends React.PureComponent<Props> {

	state: State = {
		showModal: !!this.props.workOrderCode,
	};

	static getDerivedStateFromProps(props: Props): Partial<State> {
		return {
			showModal: !!props.workOrderCode,
		};
	}

	onSubmit = async (form: WorkOrderNoteForm) => {
		const { closeModal, editWorkOrderNote } = this.props;
		await editWorkOrderNote(form.workOrderId, form);
		closeModal();
	};

	render() {
		const { submitting, closeModal, handleSubmit } = this.props;
		const { showModal } = this.state;

		return (
			<CustomModal
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Edit Work Order Note"
				/>
				<CustomModal.Body>
					<Field
						component={Textarea}
						label="Note"
						maxCharacters={250}
						name="note"
						placeholder="Enter note"
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button onClick={closeModal} variant="info">Cancel</Button>
					<SubmitButton
						label="Save Note"
						onClick={handleSubmit(this.onSubmit)}
						reduxFormSubmitting={submitting}
						submitKey={WORK_ORDER_NOTE}
						variant="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { workOrderCode, workOrderDate } = ownProps;
	const workOrdersByDate = state.scheduleBoard.workOrdersByDateDictionary;
	const workOrder = workOrderDate && workOrderCode
		? workOrdersByDate[workOrderDate]?.workOrders?.[workOrderCode]
		: null;

	return {
		initialValues: {
			workOrderId: workOrder?.id,
			note: workOrder?.workOrderNotes ?? null,
		},
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		editWorkOrderNote: WorkOrderActions.editWorkOrderNote,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<WorkOrderNoteForm, FormOwnProps>({ form: WORK_ORDER_NOTE, enableReinitialize: true })
);

export default enhance(WorkOrderNoteModal);
