import { Formatter } from 'react-google-charts';

import { ColorHex, ColorPalette } from 'acceligent-shared/enums/color';

export const MONEY_FORMATTER: Formatter = {
	type: 'NumberFormat',
	options: {
		prefix: '$ ',
		negativeColor: ColorHex[ColorPalette.RED],
	},
};
