import * as React from 'react';
import { connect } from 'react-redux';

import { AdditionalColors } from 'acceligent-shared/enums/color';
import UnavailabilityReasonScope from 'acceligent-shared/enums/unavailabilityReasonScope';

import { RootState } from 'af-reducers';

import * as ColorUtils from 'ab-utils/color.util';

import { generateEquipmentSearchItemId } from 'af-utils/scheduleBoard.util';

import ScheduleBoardEquipmentViewModel from 'ab-viewModels/scheduleBoardEquipment.viewModel';

import EquipmentUnavailabilityReason from 'af-root/scenes/Company/ScheduleBoard/Shared/UnavailabilityReason';
import ReturnDateDatePicker from 'af-root/scenes/Company/ScheduleBoard/Shared/ReturnDateDatepicker';

interface StateProps {
	draggableId: string;
	isDisabled: boolean;
	isActive: boolean;
	equipment: ScheduleBoardEquipmentViewModel;
}

interface OwnProps {
	draggableProps: Metadata;
	dragHandleProps: Metadata;
	innerRef: string;
	equipmentId: number;
	dueDate: string;
	isToolbar: boolean;
	isDragging: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	onClick: () => void;
}

type Props = OwnProps & StateProps;

interface State {
	draggableItemClassName: string;
	categoryColor: AdditionalColors;
}

class DraggableWithReason extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		equipment: {} as ScheduleBoardEquipmentViewModel,
	};

	state: State = {
		draggableItemClassName: '',
		categoryColor: AdditionalColors.GREY,
	};

	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		const {
			isActive,
			isDisabled,
			isToolbar,
			equipment: { color, isFilteredOnToolbar, isFilteredOnBoard, isMatched } = {} as ScheduleBoardEquipmentViewModel,
		} = nextProps;

		const categoryColor = color ?? AdditionalColors.GREY;

		let draggableItemClassName = 'sb-resource-item sb-resource-item--equipment';
		draggableItemClassName += isDisabled ? ' sb-resource-item--disabled' : '';
		draggableItemClassName += isMatched ? ' sb-resource-item--highlighted' : '';
		draggableItemClassName += (isToolbar && isFilteredOnToolbar) || (!isToolbar && isFilteredOnBoard) ? ' filtered' : '';
		draggableItemClassName += ` ${ColorUtils.getColorBackgroundClass(categoryColor)}`;
		draggableItemClassName += isActive ? ' sb-resource-item--active' : '';
		draggableItemClassName = draggableItemClassName.trim();

		return prevState.draggableItemClassName !== draggableItemClassName || prevState.categoryColor !== categoryColor ? {
			draggableItemClassName,
			categoryColor,
		} : null;
	}

	render() {
		const {
			isDragging,
			draggableProps,
			dragHandleProps,
			innerRef,
			onClick,
			dueDate,
			equipment,
			hasPermissionsToEditScheduleBoard,
		} = this.props;
		const { name, spec: specification, id: equipmentId } = equipment;
		const { draggableItemClassName } = this.state;
		const { draggableId } = this.props;

		return (
			<div
				ref={innerRef}
				{...draggableProps}
				{...dragHandleProps}
				className="sb-resource-item--with-reason"
			>
				<div className="sb-resource-item--with-datepicker">
					<div
						className={draggableItemClassName}
						id={draggableId}
					>
						<a className="sb-resource-item__content" onClick={onClick} role="button">
							<span className="sb-resource-item__full-name sb-resource-item__full-name--transparent-bg">{name}</span>
							{specification && <span className="sb-resource-item__specification">{specification}</span>}
						</a>
					</div>
					<div className={isDragging ? 'sb-resource-item--opaque' : ''}>
						<ReturnDateDatePicker
							dueDate={dueDate}
							hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
							itemId={equipmentId}
							scope={UnavailabilityReasonScope.EQUIPMENT}
						/>
					</div>
				</div>
				<EquipmentUnavailabilityReason
					dueDate={dueDate}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					isDragging={isDragging}
					itemId={equipmentId}
					scope={UnavailabilityReasonScope.EQUIPMENT}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { equipment: equipmentDict, draggedEquipmentId, searchResultItems, activeSearchItemIndex } = state.scheduleBoard;
	const { equipmentId, isToolbar } = ownProps;
	if (!equipmentDict) {
		throw new Error('Equipment dictionary not loaded');
	}

	const draggableId = generateEquipmentSearchItemId(equipmentId.toString());
	const equipment = equipmentDict[equipmentId];
	const isActive = searchResultItems[activeSearchItemIndex] === draggableId;

	const isDraggedFromToolbarInOtherTab = isToolbar && equipment?.isDisabled && !draggedEquipmentId;

	return {
		draggableId,
		equipment,
		isDisabled: draggedEquipmentId === equipmentId || isDraggedFromToolbarInOtherTab,
		isActive,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(DraggableWithReason);
