import { isValidTextInput } from 'acceligent-shared/utils/text';

import * as UserRequestModel from 'ab-requestModels/users.requestModel';

import { PASSWORD_LENGTH_MIN } from 'ab-constants/value';

export default function validate(values: UserRequestModel.FinalizeUser) {

	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.password)) {
		errors.password = 'Password required';
	} else if (values.password.length < PASSWORD_LENGTH_MIN) {
		errors.password = `Password requires at least ${PASSWORD_LENGTH_MIN} characters`;
	}

	if (!values.repeatedPassword) {
		errors.repeatedPassword = 'Repeated password is required';
	} else if (values.password !== values.repeatedPassword) {
		errors.repeatedPassword = 'Password and repeated password do not match';
	}

	return errors;
}
