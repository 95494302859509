import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import styles from '../styles.module.scss';
import { BillingContactFM } from './BillingContactArray';

interface OwnProps {
	billingContact: BillingContactFM;
	index: number;
	orderNumber: number;
	showActions?: boolean;
	onRemove?: (index: number) => void;
}

type Props = OwnProps;

const BillingContactPreview: React.FC<Props> = React.memo((props) => {
	const {
		billingContact,
		index,
		onRemove,
		showActions = true,
		orderNumber,
	} = props;

	const remove = React.useCallback(() => {
		onRemove?.(index);
	}, [onRemove, index]);

	return (
		<div className={styles['billing-contacts__list__item']}>
			<Row>
				<Col className={styles['billing-contacts__list__item__preview__header-number']}>
					{'Billing Email ' + orderNumber}
				</Col>
			</Row>
			<Row>
				<Col className={styles['billing-contacts__list__item__preview__field']} sm={7}>
					{billingContact.email}
				</Col>
				{showActions &&
					<Col className={styles['billing-contacts__list__item__actions']} sm={4}>
						<span className={`${styles['billing-contacts__list__item__edit__actions--red']}`} onClick={remove} >
							<span
								className={'icon-delete'}
							/>
						</span>
					</Col>
				}
			</Row>
		</div>

	);

});

export default BillingContactPreview;
