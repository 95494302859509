import * as React from 'react';

interface OwnProps {
	zoomLevel: number;
}

type Props = OwnProps;

class Loading extends React.PureComponent<Props> {

	renderToolbarItem = (): JSX.Element => {
		return (
			<div className="toolbar-group p-l">
				<div className="loading-box-row dark" />
				<div className="loading-box-row dark" />
				<div className="loading-box-row dark" />
				<div className="loading-box-row dark" />
				<div className="loading-box-row dark" />
				<div className="loading-box-row dark" />
				<div className="loading-box-row dark" />
				<div className="loading-box-row dark" />
				<div className="loading-box-row dark" />
				<div className="loading-box-row dark" />
			</div>
		);
	};

	renderWorkOrderCard = (columns: number, isEmpty: boolean = false): JSX.Element => {
		return (
			<div className={`schedule-board-card-wrapper columns-${columns}`}>
				<div className="schedule-board-card">
					{
						!isEmpty &&
						<>
							<div className="schedule-board-card-order-info">
								<div className="schedule-board-card-crew p-s p-t-m">
									<div className="loading-col-24 loading-box-row dark" />
								</div>
								<div className="schedule-board-card-supervisor p-s p-t-m">
									<div className="loading-col-24 loading-box-row dark" />
								</div>
								<div className="schedule-board-card-code p-s p-t-m">
									<div className="loading-col-24 loading-box-row dark" />
								</div>
								<div className="schedule-board-card-address p-s p-t-m">
									<div className="loading-col-24 loading-box-row dark" />
								</div>
							</div>
							<div className="schedule-board-card-employees p-s p-t-m">
								<div className="loading-col-24 loading-box-area dark" />
							</div>
						</>
					}
				</div>
			</div>
		);
	};

	renderRow = (itemsPerRow: number): JSX.Element => {
		let totalItems = 0;
		const itemWidthDefinition: number[] = [];
		while (totalItems < itemsPerRow) {
			const multiplier = totalItems === 4 ? 1 : 2;
			const itemType = Math.round(Math.random() * multiplier);
			totalItems += itemType !== 2 ? 1 : 2;
			itemWidthDefinition.push(itemType);
		}

		return (
			<div className="schedule-board-cards-container">
				{
					itemWidthDefinition.map((_item: number, _index: number) => {
						return (
							<React.Fragment key={_index}>
								{this.renderWorkOrderCard(_item === 0 ? 1 : _item, !_item)}
							</React.Fragment>
						);
					})
				}
			</div>
		);
	};

	renderRows = (): JSX.Element[] => {
		const { zoomLevel } = this.props;

		let itemsPerRow: number = 5;
		let rowsCount: number = 2;

		switch (zoomLevel) {
			case 2:
				itemsPerRow = 5;
				rowsCount = 2;
				break;
			case 1:
				itemsPerRow = 10;
				rowsCount = 3;
				break;
			case 0:
				itemsPerRow = 20;
				rowsCount = 4;
				break;
		}

		const rows: JSX.Element[] = [];

		for (let i = 0; i < rowsCount; i++) {
			rows.push(
				<React.Fragment key={i}>
					{this.renderRow(itemsPerRow)}
				</React.Fragment>
			);
		}

		return rows;
	};

	render() {
		return (
			<>
				<div className="schedule-board-toolbar schedule-board-toolbar-loading">
					{this.renderToolbarItem()}
					{this.renderToolbarItem()}
					{this.renderToolbarItem()}
					{this.renderToolbarItem()}
				</div>
				<div className="schedule-board-cards-container">
					<div className="schedule-board-cards-container-wrapper">
						{this.renderRows()}
					</div>
				</div>
			</>
		);
	}
}

export default Loading;
