import * as React from 'react';
import { Row, Button } from 'react-bootstrap';

interface OwnProps {
	toggleEdit: (event: React.MouseEvent<HTMLElement>) => void;
	className?: string;
	editButtonId?: string;
	containerId?: string;
}

type Props = React.PropsWithChildren<OwnProps>;

class SettingPreview extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		className: '',
	};

	render() {
		const { toggleEdit, children, className, editButtonId, containerId } = this.props;

		return (
			<div className="form-box company-settings" id={containerId}>
				<Row className={`company-settings__row ${className}`}>
					{children}
					<div className="company-settings__edit-segment">
						<Button
							id={editButtonId}
							onClick={toggleEdit}
							variant="info"
						>
							<strong>Edit</strong>
						</Button>
					</div>
				</Row>
			</div>
		);
	}
}

export default SettingPreview;
