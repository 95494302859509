import * as React from 'react';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import { formatDate } from 'acceligent-shared/utils/time';

import { NOTIFY_MODAL_COL_SPAN } from 'af-constants/values';

import { wrapElementWithTooltip } from 'af-utils/tooltip.util';

interface OwnProps {
	email: string;
	emailInvalidatedAt: Date;
	lastEmailSentAt: Date;
	lastEmailSentStatus: NotificationStatusEnum;
	errorMessage: string;
}

type Props = OwnProps;

export default class EmailStatusInfo extends React.PureComponent<Props> {

	getEmployeeEmailColorApplication = () => {
		const { lastEmailSentStatus } = this.props;

		switch (lastEmailSentStatus) {
			case NotificationStatusEnum.ERROR:
				return 'text-orange';
			case NotificationStatusEnum.OPENED:
				return 'text-green';
			default:
				return '';
		}
	};

	getEmployeeEmailOverlayMessage = () => {
		const { errorMessage, lastEmailSentStatus } = this.props;

		switch (lastEmailSentStatus) {
			case NotificationStatusEnum.ERROR:
				return errorMessage;
			case NotificationStatusEnum.OPENED:
				return 'The participant has opened the link sent in the last email.';
			case NotificationStatusEnum.UNKNOWN:
				return 'Email status is unknown. The participant probably received it, but has not yet opened the supplied link.';
			default:
				return null;
		}
	};

	employeeEmailStatusInfo = (): JSX.Element => {
		const { lastEmailSentStatus, emailInvalidatedAt, lastEmailSentAt } = this.props;

		return (
			<>
				{lastEmailSentStatus !== NotificationStatusEnum.SCHEDULED && lastEmailSentAt ? formatDate(lastEmailSentAt, TimeFormatEnum.FULL_DATE) : '-'}
				{lastEmailSentStatus === NotificationStatusEnum.ERROR && emailInvalidatedAt && <span className="icon-info text-red" />}
			</>
		);
	};

	render() {
		return (
			<td className={this.getEmployeeEmailColorApplication()}
				colSpan={NOTIFY_MODAL_COL_SPAN.SEND_TIME}
			>
				{
					wrapElementWithTooltip(
						this.employeeEmailStatusInfo(),
						this.getEmployeeEmailOverlayMessage()
					)
				}
			</td>
		);
	}
}
