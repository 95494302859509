import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import * as FieldReportActions from 'af-actions/fieldReport';

import { RootState } from 'af-reducers';

import SocketConnectionCount from 'ab-viewModels/socketConnectionCount.viewModel';

import SocketEvent from 'ab-enums/socketEvent.enum';

import socket from 'af-utils/socket.util';

import ConnectionCounters from 'af-components/ConnectionCounters';

interface StateProps {
	activeCount: number;
	idleCount: number;
}

interface DispatchProps {
	setCount: typeof FieldReportActions.setConnectionCount;
}

type Props = StateProps & ResolveThunks<DispatchProps>;

class FieldReportEditNavigation extends React.PureComponent<Props> {

	constructor(props: Props) {
		super(props);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.CONNECTION_COUNT, this.setCount);
	}

	componentWillUnmount() {
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.CONNECTION_COUNT);
	}

	setCount = (connectionCount: SocketConnectionCount) => {
		const { setCount } = this.props;
		setCount(connectionCount);
	};

	render() {
		const { activeCount, idleCount } = this.props;

		return (
			<div className="field-report__header">
				<ConnectionCounters activeCount={activeCount} idleCount={idleCount} />
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		setCount: FieldReportActions.setConnectionCount,
	};
}

function mapStateToProps(state: RootState): StateProps {
	const { fieldReport } = state;
	return {
		activeCount: fieldReport?.connectionCount?.activeConnections ?? 0,
		idleCount: fieldReport?.connectionCount?.idleConnections ?? 0,
	};
}

export default connect(mapStateToProps, mapDispatchToProps())(FieldReportEditNavigation);
