import * as React from 'react';
import { Row } from 'react-bootstrap';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	showCheckbox: boolean;
	checked: boolean;
	indent: boolean;
	label: string;
	id?: string;
	value?: string;
	hint?: string;
}

type Props = OwnProps;

const FeatureFlagPreviewItem = ({ indent, showCheckbox, checked, label, value, hint, id }: Props) => {
	return (
		<Row
			className={`company-settings__feature-flag ${indent ? 'company-settings__feature-flag--indented' : ''}`}
			id={id}
		>
			{
				showCheckbox &&
				<span className={`company-settings__feature-status ${checked ? 'icon-dot text-green' : 'icon-dot_outline text-orange'}`} />
			}
			<span className="regular">{label}</span>
			{value && checked && <b className="m-l-xs">{value}</b>}
			{hint &&
				<Tooltip className="tooltip--center-text" message={hint}>
					<span className="icon-help_fill" />
				</Tooltip>
			}
		</Row>
	);
};

export default FeatureFlagPreviewItem;
