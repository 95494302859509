import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import SegmentLabel from 'af-components/SegmentLabel';

interface OwnProps {
	isVisible: boolean;
	toggle: () => void;
	renderContent: () => Nullable<JSX.Element>;
	isExpanded: boolean;
	label: string;
}

type Props = OwnProps;

const AccountEditSegment: React.FC<Props> = (props) => {
	const { isVisible, renderContent, toggle, isExpanded, label } = props;

	if (!isVisible) {
		return null;
	}

	const icon = `icon-${isExpanded ? 'collapse' : 'expand'}`;

	return (
		<>
			<Row>
				<Col sm={24}>
					<div className="segment-label-container">
						<a className="btn--link" onClick={toggle} role="button">
							<SegmentLabel isLink={true} label={label} />
							<span className={icon} />
						</a>
					</div>
				</Col>
			</Row>
			{isExpanded && renderContent()}
		</>
	);

};

export default React.memo(AccountEditSegment);
