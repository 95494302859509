import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import LockedValue from 'af-components/LockedValue';

import AddressViewModel from 'ab-viewModels/address.viewModel';

interface OwnProps {
	address: AddressViewModel;
}

type Props = OwnProps;

class AddressRowPreview extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		address: {} as AddressViewModel,
	};

	render() {
		const { address } = this.props;

		return (
			<Row className="row--padded">
				<Col md={5}><LockedValue label="Street Address" value={address.street} /></Col>
				<Col md={3}><LockedValue label="Suite/Floor" value={address.suite} /></Col>
				<Col md={4}><LockedValue label="City" value={address.city} /></Col>
				<Col md={3}><LockedValue label="State" value={address.state} /></Col>
				<Col md={3}><LockedValue label="Zip" value={address.zip} /></Col>
				<Col md={3}><LockedValue label="P.O. Box" value={address.postalOfficeBoxCode} /></Col>
				<Col md={3}><LockedValue label="Country" value={address.country} /></Col>
			</Row>
		);
	}

}

export default AddressRowPreview;
