import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';

import { RootState } from 'af-reducers';

import * as User from 'ab-viewModels/user.viewModel';

import CompanyItem from 'af-components/Header/HeaderCompanies/CompanyItem';

interface OwnProps {
	onClose: () => void;
}

interface StateProps {
	organizationData: Nullable<User.OrganizationData>;
}

type Props = OwnProps & StateProps;

const CompaniesList: React.FC<Props> = (props) => {

	const { organizationData, onClose } = props;

	if (!organizationData) {
		return null;
	}

	const companies: JSX.Element[] = (organizationData.companies || [])
		.map((_company: User.CompanyData, _index: number) => {
			return (
				<CompanyItem
					company={_company}
					index={_index}
					key={`company_item_${_index}`}
					onClose={onClose}
					orgAlias={organizationData.alias}
				/>
			);
		});

	return (
		<Dropdown.Item className="main-header__companies-dropdown__menu__companies">
			<Scrollbars
				autoHeight={true}
				autoHeightMax={300}
				autoHide={true}
				autoHideDuration={0}
				autoHideTimeout={0}
				className="custom-scroll-container"
				hideTracksWhenNotNeeded={true}
				thumbMinSize={0}
				universal={true}
			>
				<div>
					{companies}
				</div>
			</Scrollbars>
		</Dropdown.Item>
	);
};

function mapStateToProps(state: RootState): StateProps {
	const { organizationData } = state.user;

	return {
		organizationData,
	};
}

export default connect<StateProps, void, OwnProps>(mapStateToProps)(CompaniesList);
