import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import FSN from './FSN';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector>;

const PERMISSIONS = [];

const OrganizationSettingsFSN: React.FC<Props> = (props: Props) => {
	const { organizationData, userRole, location: { state: { orgAlias }, pathname } } = props;

	const links = React.useMemo(() => [
		{
			url: CLIENT.ORGANIZATION.SETTINGS.INFO(orgAlias),
			label: 'Info',
			icon: 'icon-help_fill',
			visibleFor: [], // FIXME: links weren't showing for PagePermissions.ORGANIZATION
			isActive: (pathname.includes(CLIENT.ORGANIZATION.SETTINGS.INFO(orgAlias))),
		},
		{
			url: CLIENT.ORGANIZATION.SETTINGS.LOGS.TABLE(orgAlias),
			label: 'Logs',
			icon: 'icon-logs',
			visibleFor: [], // FIXME: links weren't showing for PagePermissions.ORGANIZATION
			isActive: (pathname.includes(CLIENT.ORGANIZATION.SETTINGS.LOGS.TABLE(orgAlias))),
		},
	], [orgAlias, pathname]);

	return (
		<FSN
			infoLabel="Settings"
			isCompanyAdmin={organizationData.isPlatformAdmin}
			links={links}
			permissions={PERMISSIONS}
			scrollLabel="none"
			userRole={userRole}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, organizationData } = state.user;
	if (!userData || !organizationData) {
		throw new Error('User not logged in');
	}

	return {
		organizationData,
		userRole: userData.role,
	};
}

const connector = connect(mapStateToProps);

export default connector(OrganizationSettingsFSN);
