/* eslint-disable react/no-danger */
import * as React from 'react';
import { Button } from 'react-bootstrap';

import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	showModal: boolean;
	isLoading?: boolean;
	closeModal: () => void;
	notificationTemplate: string;
}

type Props = OwnProps;

interface State {
	template: { __html: string; };
}

export default class NotificationPreviewModal extends React.Component<Props, State> {
	state: State = {
		template: { __html: '' },
	};

	static getDerivedStateFromProps(props: Props) {
		return {
			template: {
				__html: props.notificationTemplate?.replace(/(?:\r\n|\r|\n)/g, '<br>') ?? '',
			},
		};
	}

	renderDataModalContent = () => {
		const { closeModal } = this.props;
		const { template } = this.state;

		return (
			<>
				<CustomModal.Header
					closeModal={closeModal}
					title="Notification Preview"
				/>
				<CustomModal.Body>
					<span dangerouslySetInnerHTML={template} />
				</CustomModal.Body>
				< CustomModal.Footer>
					<Button className="m-b-m" onClick={closeModal} variant="info">Close</Button>
				</CustomModal.Footer>
			</>
		);
	};

	renderLoadingModalContent = () => {
		const { closeModal } = this.props;

		return (
			<Loading onHide={closeModal} />
		);
	};

	render() {
		const { showModal, isLoading, closeModal } = this.props;

		return (
			<CustomModal
				className="notification-preview-modal"
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				{isLoading ? this.renderLoadingModalContent() : this.renderDataModalContent()}
			</CustomModal>
		);
	}
}
