import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps, DroppableId } from 'react-beautiful-dnd';

import { ColorPalette } from 'acceligent-shared/enums/color';

import { RootState } from 'af-reducers';

import { SkillViewModel } from 'ab-viewModels/skill.viewModel';

import { setCopiedPlaceholderId } from 'af-actions/scheduleBoard/scheduleBoard.actions';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import * as ColorUtils from 'ab-utils/color.util';

import SkillsColorGrid from 'af-components/SkillsColorGrid';

import PressAndHoldAnchor from 'af-root/scenes/Company/ScheduleBoard/Shared/PressAndHoldAnchor';
import CopyProgressBar from 'af-root/scenes/Company/ScheduleBoard/Shared/CopyProgressBar';

interface OwnProps {
	displayName?: string;
	draggableProps: DraggableProvidedDraggableProps;
	draggingOver?: DroppableId;
	dragHandleProps: DraggableProvidedDragHandleProps | undefined;
	dueDate: string;
	hasPermissionsToEditScheduleBoard: boolean;
	innerRef: (element?: HTMLElement | null) => unknown;
	isDisabled?: boolean;
	isDragAndDropDisabled: boolean;
	isDragging: boolean;
	isDropAnimating?: boolean;
	isToolbar?: boolean;
	isWorkOrderCanceled?: boolean;
	resourceId: number;
	workOrderPlaceholderId: number;
}

interface StateProps {
	isDailyView: boolean;
	isToolbarShown: boolean;
	displayName?: string;
	copiedPlaceholderId: Nullable<number>;
	skills?: SkillViewModel[];
	categoryColor?: ColorPalette;
}

interface DispatchProps {
	setCopiedPlaceholderId: typeof setCopiedPlaceholderId;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	draggableItemClassName: string;
}

class Draggable extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		isDisabled: false,
	};

	state: State = {
		draggableItemClassName: '',
	};

	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		const {
			isDisabled,
			isWorkOrderCanceled,
			draggingOver,
			isDropAnimating,
			isToolbar,
		} = nextProps;

		let draggableItemClassName = 'sb-resource-item sb-resource-item--placeholder';
		if (isDropAnimating && !draggingOver && !isToolbar) {
			draggableItemClassName += ' sb-resource-item--hidden';
		} else {
			draggableItemClassName += isDisabled && !isWorkOrderCanceled ? ' sb-resource-item--disabled' : '';
			draggableItemClassName = draggableItemClassName.trim();
		}

		return prevState.draggableItemClassName !== draggableItemClassName ? { draggableItemClassName } : null;
	}

	onHoldEnd = () => {
		const { setCopiedPlaceholderId: _setCopiedPlaceholderId, workOrderPlaceholderId } = this.props;
		_setCopiedPlaceholderId(workOrderPlaceholderId);
	};

	onRelease = () => {
		const { setCopiedPlaceholderId: _setCopiedPlaceholderId, isDragging } = this.props;
		if (!isDragging) {
			_setCopiedPlaceholderId(null);
		}
	};

	renderDraggablePlaceholder = (isCopying: boolean, copyFinished: boolean) => {
		const { displayName, categoryColor, skills } = this.props;

		return (
			<>
				<div className="sb-resource-item__content">
					<span className="sb-resource-item__full-name sb-resource-item__full-name--transparent-bg">{displayName}</span>
					{!!skills?.length && <SkillsColorGrid skills={skills} />}
					{!!categoryColor && <div className={`sb-resource-item__category-color-preview ${ColorUtils.getColorBackgroundClass(categoryColor, true)}`} />}
				</div>
				<CopyProgressBar
					copyFinished={copyFinished}
					isCopying={isCopying}
				/>
			</>
		);
	};

	render() {
		const {
			copiedPlaceholderId,
			draggableProps,
			dragHandleProps,
			hasPermissionsToEditScheduleBoard,
			innerRef,
			isDailyView,
			isDragAndDropDisabled,
			isDragging,
			isToolbarShown,
			workOrderPlaceholderId,
		} = this.props;
		const { draggableItemClassName } = this.state;

		return (
			<>
				<div
					ref={innerRef}
					{...draggableProps}
					{...dragHandleProps}
					className={draggableItemClassName}
				>
					<PressAndHoldAnchor
						onHoldEnd={this.onHoldEnd}
						onRelease={this.onRelease}
						regularAnchor={isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard || (!isDailyView && !isToolbarShown)}
					>
						{this.renderDraggablePlaceholder}
					</PressAndHoldAnchor>
				</div>
				{isDragging && copiedPlaceholderId && copiedPlaceholderId === workOrderPlaceholderId && (
					<div className={draggableItemClassName}>
						{this.renderDraggablePlaceholder(false, false)}
					</div>
				)}
			</>
		);
	}
}

const EMPTY_ARRAY = [];

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { isToolbar, dueDate, isWorkOrderCanceled, resourceId } = ownProps;
	let { displayName } = ownProps;
	const { workOrdersByDateDictionary, copiedPlaceholderId, scheduleBoardView, weeklyViewDateWithToolbar } = state.scheduleBoard;
	const { workOrderResourceLookups, canceledWorkOrderResourceLookups } = workOrdersByDateDictionary[dueDate];

	let skills: SkillViewModel[] | undefined = undefined;
	let categoryColor: ColorPalette | undefined = undefined;
	if (!isToolbar) {
		const resource = isWorkOrderCanceled ?
			canceledWorkOrderResourceLookups[resourceId] :
			workOrderResourceLookups[resourceId];

		if (resource.wageRateId) {
			displayName = `${resource?.wageRate?.type} (${resource?.wageRate?.wageClassification})`;
		} else {
			displayName = `${resource?.equipmentCost?.subcategory} (${resource?.equipmentCost?.categoryName})`;
		}

		skills = resource?.skills ?? EMPTY_ARRAY;
		categoryColor = resource?.equipmentCost?.categoryColor ?? undefined;
	}

	return {
		displayName,
		copiedPlaceholderId,
		isDailyView: scheduleBoardView === ScheduleBoardView.DAILY_VIEW,
		isToolbarShown: weeklyViewDateWithToolbar === dueDate,
		skills,
		categoryColor,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		setCopiedPlaceholderId,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(Draggable);
