import * as React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';

import { MAX_BILLABLE_WORK_DEFINITION_FIELDS } from 'ab-constants/value';

import Input from 'af-fields/Input';

import { FormModel } from '../FormModel';
import styles from './styles.module.scss';

type Props = WrappedFieldArrayProps<FormModel['definitionFields'][0]>;

const AddBillableWorkModalDefinitionField: React.FC<Props> = (props) => {
	const { fields } = props;

	const onAddDefinitionFieldClick = React.useCallback(() => fields.push('') , [fields]);

	const onRemoveDefinitionFieldClick = React.useCallback((index) => () => fields.remove(index) , [fields]);

	const renderField = (field, index) => {
		return (
			<div className={styles['add-billable-work-modal__info-def-item']} key={field}>
				<Field
					component={Input}
					id="definitionField"
					label={`Definition Field ${index + 1}`}
					name={`${field}`}
					placeholder="Add definition field"
					type="text"
				/>
				<span className="icon-delete" onClick={onRemoveDefinitionFieldClick(index)}/>
			</div>
		);
	};
	return (
		<>
			{fields.map(renderField)}
			{fields.length < MAX_BILLABLE_WORK_DEFINITION_FIELDS &&
				<div className={styles['add-billable-work-modal__add-field']} onClick={onAddDefinitionFieldClick}>
					<span className="icon-plus" />
					<span>Add Definition Field</span>
				</div>
			}
		</>
	);
};

export default AddBillableWorkModalDefinitionField;
