import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Field, WrappedFieldArrayProps } from 'redux-form';

import ContactRM from 'acceligent-shared/dtos/web/request/contact/upsert';
import { EmailTypes, EmailTypeNames } from 'acceligent-shared/enums/contactMethodType';
import UpsertContactStatusEnum from 'acceligent-shared/enums/upsertContactStatus';

import Input from 'af-fields/Input';
import Dropdown from 'af-fields/Dropdown';

import Tooltip from 'af-components/Tooltip';

interface EmailTypeOption {
	id: EmailTypes;
	label: string;
}

export interface OwnProps {
	customError?: string;
	onRemoveContactMethod: (email: ContactRM['emails'][0]) => void;
	change: (fieldName: string, value: UpsertContactStatusEnum) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<ContactRM['emails'][0]>;

class EmailContactMethods extends React.Component<Props> {

	static _emailTypeOptions: EmailTypeOption[] = Object.keys(EmailTypes)
		.map((_key: EmailTypes) => ({ id: _key, label: EmailTypeNames[_key] }));

	addContactMethod = () => {
		const { fields } = this.props;

		fields.push({
			type: EmailTypes.EMAIL_DEFAULT,
			status: UpsertContactStatusEnum.ADDED,
		} as ContactRM['emails'][0]);
	};

	updateContactMethod = (index: number, fieldName: string) => {
		const { change, fields } = this.props;

		const field = fields.get(index);
		if (field.status === UpsertContactStatusEnum.OLD) {
			change(`${fieldName}.status`, UpsertContactStatusEnum.EDITED);
		}
	};

	removeContactMethod = (index: number) => {
		const { onRemoveContactMethod, fields } = this.props;
		const removedMethod = fields.get(index);
		fields.remove(index);
		onRemoveContactMethod({ ...removedMethod, status: UpsertContactStatusEnum.REMOVED });
	};

	renderMethodTypes = () => {
		const { fields } = this.props;

		return fields.map((_field, _index: number) => (
			<Row key={_index}>
				<Col sm={6}>
					<Field
						component={Input}
						name={`${_field}.value`}
						onValueChange={this.updateContactMethod.bind(this, _index, _field)}
						placeholder="Email"
						type="text"
					/>
				</Col>
				<Col sm={6}>
					<Field
						component={Dropdown}
						id={_field}
						labelKey="label"
						name={`${_field}.type`}
						onValueChange={this.updateContactMethod.bind(this, _index, _field)}
						options={EmailContactMethods._emailTypeOptions}
						placeholder="Type"
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col sm={1}>
					<Tooltip message="Delete">
						<Button
							className="btn--flat btn--icon"
							onClick={this.removeContactMethod.bind(this, _index)}
						>
							<span className="icon-delete" />
						</Button>
					</Tooltip>
				</Col>
			</Row>
		));
	};

	render() {
		const { meta: { error, submitFailed }, customError } = this.props;

		return (
			<div className="contact-method">
				<div className="contact-method__header">
					Email
					<Tooltip
						message="At least one email or phone number is required"
						placement="top"
					>
						<span className="icon-help_fill" />
					</Tooltip>
				</div>
				{this.renderMethodTypes()}
				<Row>
					<Col sm={12}>
						<Button className="btn--link" onClick={this.addContactMethod}>
							<span className="icon-plus" />
							<span>Add Email</span>
						</Button>
						{submitFailed && error && <span>{error}</span>}
						{customError && <span className="help-block"><span className="icon-info"></span>{customError}</span>}
					</Col>
				</Row>
			</div>
		);
	}
}

export default EmailContactMethods;
