import { SubmissionError } from 'redux-form';

import * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import * as ResourceStatusesUpdateRequestModel from 'ab-requestModels/resources/resourceStatusesUpdate.requestModel';

import { move } from 'ab-utils/array.util';

import { Props, State, CreateFormModel, OrderChangedFormModel, ResourceStatusesItem } from './types';

const _fromUpdateViewToRequestModel = (item: ResourceStatusesViewModel.Item): ResourceStatusesUpdateRequestModel.Item => {
	return {
		id: item.id,
		name: item.name,
		available: item.available,
	};
};

export function mapViewModelToState(viewModel: ResourceStatusesViewModel.Default): State['statuses'] {
	return {
		available: viewModel.available.map(_fromUpdateViewToRequestModel),
		unavailable: viewModel.unavailable.map(_fromUpdateViewToRequestModel),
	};
}

/** throws `SubmissionError` if create form data is invalid, `void` otherwise */
export function validateCreateAgainstState(form: CreateFormModel, state: Readonly<State>): void | never {
	const { available, name } = form;
	const { statuses, forDeleteLookup } = state;
	const lowercasedName = name.trim().toLowerCase();
	const availabilityKey = available ? 'available' : 'unavailable';
	const otherAvailabilityKey = !available ? 'available' : 'unavailable';
	const hasSameName = (_item: ResourceStatusesItem) => _item.name.toLowerCase() === lowercasedName;

	if (!name) {
		throw new SubmissionError({ name: 'Name is required.' });
	}
	if (statuses[availabilityKey].some(hasSameName)) {
		throw new SubmissionError({ name: 'Status already exists.' });
	}
	if (statuses[otherAvailabilityKey].some(hasSameName)) {
		throw new SubmissionError({ name: `Status cannot have the same name as one in ${otherAvailabilityKey}` });
	}
	if (forDeleteLookup[lowercasedName]?.available === !available) {
		throw new SubmissionError({ name: `Status cannot have the same name as a deleted one in ${otherAvailabilityKey}` });
	}
}

function _hasSameNamePredicate(lowercasedName: string) {
	return (_item: ResourceStatusesItem) => _item.name.toLowerCase() === lowercasedName;
}

type StateAction = <K extends keyof State>(prevState: Readonly<State>, props: Readonly<Props>) => Pick<State, K> | State | null;

export namespace StateActionCreators {

	export function createStatus(form: CreateFormModel): StateAction {
		const available = form.available;
		const name = form.name.trim();
		const lowercasedName = name.toLowerCase();
		const availabilityKey = available ? 'available' : 'unavailable';
		const hasSameName = _hasSameNamePredicate(lowercasedName);

		return (prevState) => {
			if (!name || prevState.statuses.available.some(hasSameName) || prevState.statuses.unavailable.some(hasSameName)) {
				// this should be handled via validation not to happen
				return null;
			}
			const list = [...prevState.statuses[availabilityKey]];
			const deletedItem = prevState.forDeleteLookup[lowercasedName];

			if (deletedItem) {
				if (deletedItem.available !== available) {
					// this should be handled via validation not to happen
					return null;
				}
				// deleted item, reactivate it
				list.push({ ...deletedItem, name });	// name added if letter case was changed
				const statuses: State['statuses'] = {
					...prevState.statuses,
					[availabilityKey]: list,
				};
				const forDeleteLookup: State['forDeleteLookup'] = {
					...prevState.forDeleteLookup,
					[lowercasedName]: undefined,
				};
				return { ...prevState, statuses, forDeleteLookup };
			} else {
				// new item, create it
				const createdItem = { ...form };
				list.push({ ...createdItem, id: null });
				const statuses: State['statuses'] = {
					...prevState.statuses,
					[availabilityKey]: list,
				};
				const forCreateLookup: State['forCreateLookup'] = {
					...prevState.forCreateLookup,
					[lowercasedName]: createdItem,
				};
				return { ...prevState, statuses, forCreateLookup };
			}
		};
	}

	export function moveStatus(form: OrderChangedFormModel): StateAction {
		const { available, sourceName, sourceIndex, destinationIndex } = form;
		const availabilityKey = available ? 'available' : 'unavailable';
		const lowercasedSourceName = sourceName.toLowerCase();

		return (prevState) => {
			if (sourceIndex === destinationIndex) {
				return null;
			}
			const list = [...prevState.statuses[availabilityKey]];
			const realSourceIndex = list.findIndex(_hasSameNamePredicate(lowercasedSourceName));
			if (realSourceIndex !== sourceIndex) {
				// this should never happen, some other action has changed the order before this action
				// return the same data in a new object to refresh any possible inconsistencies
				return { ...prevState, statuses: { ...prevState.statuses, [availabilityKey]: list } };
			}

			const statuses: State['statuses'] = {
				...prevState.statuses,
				[availabilityKey]: move(list, sourceIndex, destinationIndex),
			};
			return { ...prevState, statuses };
		};
	}

	export function deleteStatus(available: boolean, name: string): StateAction {
		const availabilityKey = available ? 'available' : 'unavailable';
		const lowercasedName = name.toLowerCase();

		return (prevState) => {
			const list = [...prevState.statuses[availabilityKey]];
			const index = list.findIndex(_hasSameNamePredicate(lowercasedName));
			if (index === -1) {
				// this should probably never happen, but just in case multiple events get fired
				return null;
			}
			const [item] = list.splice(index, 1);
			const statuses: State['statuses'] = {
				...prevState.statuses,
				[availabilityKey]: list,
			};

			if (prevState.forCreateLookup[lowercasedName]) {
				// newly created item, delete it from the create lookup
				const forCreateLookup: State['forCreateLookup'] = {
					...prevState.forCreateLookup,
					[lowercasedName]: undefined,
				};
				return { ...prevState, statuses, forCreateLookup };
			} else {
				// old item, add it to delete lookup
				const forDeleteLookup: State['forDeleteLookup'] = {
					...prevState.forDeleteLookup,
					[lowercasedName]: { id: item.id, name, available },
				};
				return { ...prevState, statuses, forDeleteLookup };
			}
		};
	}
}
