import { isNullOrUndefined } from 'acceligent-shared/utils/extensions';

import WorkOrderPosition from 'acceligent-shared/enums/workOrderPosition';

import { MAX_WORK_ORDER_REVENUE, MAX_WORK_ORDER_MAN_HOUR_AVERAGE } from 'ab-constants/value';

import { validateAddress } from 'af-components/SharedForms/Address/validation';

import { isValidNumber } from 'ab-utils/validation.util';

import { CUSTOMER_WORK_ORDER_MAX_CHARACTERS } from 'af-constants/values';

import WorkOrderUpserFormModel from './formModel';

export const validate = (values: WorkOrderUpserFormModel) => {
	const errors: ValidationErrors = {};

	// TODO remove comments when cancellation is reimplemented
	// if (values.status === WorkOrderStatus.PUBLISHED && !values.cancellationReason) {
	// 	errors.cancellationReason = 'Cancellation reason is required.';
	// }

	// if (values.isFirst && values.isLate && !values.delayReason) {
	// 	errors.delayReason = 'Delay reason is required.';
	// }

	if (!values.supervisorId) {
		errors.supervisorId = 'Superintendent is required';
	}

	if (!values.dueDate) {
		errors.dueDate = 'Date of work is required';
	}

	if (!values.jobId) {
		errors.jobId = 'Job is required';
	}

	if (!(values?.job?.isInternal ?? false) && !values.crewTypeId) {
		errors.crewTypeId = 'Crew type is required';
	}

	if ((values?.job?.isInternal ?? false) && !values.customCrewType?.trim()) {
		errors.customCrewType = 'Crew type is required';
	}

	if (!values.shift?.id) {
		errors.shift = 'Shift is required';
	}

	if (values.customerWorkOrder?.length && values.customerWorkOrder.length > CUSTOMER_WORK_ORDER_MAX_CHARACTERS) {
		errors.customerWorkOrder = 'Customer WorkOrder must not exceed 25 characters';
	}

	if ((values.position === WorkOrderPosition.BLANK || values.position === WorkOrderPosition.SPECIFIC)
		&& !isValidNumber(values.index)) {
		errors.index = 'Index is required';
	}

	if (values.addresses?.length) {
		const _addresses: ValidationErrors[] = [];
		values.addresses.forEach((_adr, _index) => {
			const _addressErrors = validateAddress(_adr.address);
			if (_addressErrors) {
				_addresses[_index] = { address: _addressErrors };
			}
		});
		if (_addresses.length > 0) {
			errors.addresses = _addresses;
		}
	}

	if (values.workOrderReportTypeLookup?.length) {
		const _workOrderReportTypeLookup: ValidationErrors[] = [];
		for (let _index = 0; _index < values.workOrderReportTypeLookup.length; _index++) {
			const _wort = values.workOrderReportTypeLookup[_index];
			if (!_wort?.reportTypeLookup?.name) {
				// no name means no RT has been selected in the dropdown
				_workOrderReportTypeLookup[_index] = { reportTypeLookup: 'Select Report Type or delete row' };
			}
		}
		if (_workOrderReportTypeLookup.length > 0) {
			errors.workOrderReportTypeLookup = _workOrderReportTypeLookup;
		}
	}

	// if (!values.workOrderTasks || !values.workOrderTasks.length) {
	// 	// DISABLED FOR JOBS THAT SKIPPED ESTIMATION
	// 	// errors.workOrderTasks = { _error: 'Select at least one task with cost code.' };
	// } else {
	// 	const workOrderTasks = [];
	// 	values.workOrderTasks.forEach((wot, index) => {
	// 		const taskErrors = isValidWorkOrderTask(wot);
	// 		if (!!taskErrors) {
	// 			workOrderTasks[index] = taskErrors;
	// 		}
	// 	});
	// 	if (workOrderTasks.length > 0) {
	// 		errors.workOrderTasks = workOrderTasks;
	// 	}
	// }

	const workOrderResourceLookups: ValidationErrors[] = [];
	(values.workOrderResourceLookups ?? []).forEach((_worl, _index) => {
		const resourceLookupErrors = isValidWorkOrderResourceLookup(_worl);
		if (!!resourceLookupErrors) {
			workOrderResourceLookups[_index] = resourceLookupErrors;
		}
	});
	if (workOrderResourceLookups.length > 0) {
		errors.workOrderResourceLookups = workOrderResourceLookups;
	}

	if (values.revenue && !isValidNumber(values.revenue)) {
		errors.revenue = 'Revenue must be a number';
	} else if (values.revenue && +values.revenue > MAX_WORK_ORDER_REVENUE) {
		errors.revenue = 'Revenue value is too high';
	}

	if (values.manHourAverage && !isValidNumber(values.manHourAverage)) {
		errors.manHourAverage = 'Man Hour Average must be a number';
	} else if (values.manHourAverage && +values.manHourAverage > MAX_WORK_ORDER_MAN_HOUR_AVERAGE) {
		errors.manHourAverage = 'Man Hour Average value is too high';
	}

	if (!!values.uploadingAttachments && Object.keys(values.uploadingAttachments).length) {
		errors.uploadingAttachments = 'Attachment upload in progress.';
	}

	return errors;
};

export const warn = (values: WorkOrderUpserFormModel) => {
	const warnings: ValidationErrors = {};

	if (values?.addresses?.length) {
		const addressesWarnings: ValidationErrors[] = [];
		values.addresses.forEach((_address, _index) => {
			if (isNullOrUndefined(_address.address?.latitude) || isNullOrUndefined(_address.address?.longitude)) {
				addressesWarnings[_index] = { street: 'Work location not recognized by Google Maps.' };
			}
		});
		warnings.addresses = addressesWarnings;
	}

	return warnings;
};

const isValidWorkOrderResourceLookup = (values: WorkOrderUpserFormModel['workOrderResourceLookups'][0]) => {
	const errors: ValidationErrors = {};

	if (values.employeeId === null) {
		errors.employeeId = 'Employee is required';
	}

	if (values.equipmentId === null) {
		errors.equipmentId = 'Equipment is required';
	}

	if (values.wageRateId === null) {
		errors.wageRateId = 'Wage rate is required';
	}

	if (values.equipmentCostId === null) {
		errors.equipmentCostId = 'Equipment cost is required';
	}

	if (values.temporaryEmployeeId === null) {
		errors.temporaryEmployeeId = 'Temporary Employee is required';
	}

	if (Object.keys(errors).length === 0) {
		return null;
	}

	return errors;
};

// const isValidWorkOrderTask = (values: WorkOrderTaskViewModel) => {
// 	const errors: ValidationErrors = {};

// 	if (!values.taskId) {
// 		errors.taskId = 'Pay Item is required';
// 	}

// 	if (!values.taskCostCodeId) {
// 		errors.taskCostCodeId = 'Pay Item must have one Cost Code';
// 	}

// 	if (Object.keys(errors).length === 0) {
// 		return null;
// 	}

// 	return errors;
// };
