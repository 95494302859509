import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import * as AuthenticationActions from 'af-actions/authentication';

import SideNav, { LinkProps, SeparatorProps } from 'af-components/SideNav/SideNav';
import CustomerSupportButton from 'af-components/CustomerSupport';

import { isAllowed } from 'ab-utils/auth.util';

import * as UserUtils from 'af-utils/user.util';

type OwnProps = CustomRouteComponentProps;

type Props = CustomRouteComponentProps & OwnProps & ConnectedProps<typeof connector>;

const ignoreWorkOrdersSuffix = 's/order/';

const CompanySideNav: React.FC<Props> = (props: Props) => {

	const {
		companyData,
		companyData: { permissions, isCompanyAdmin, name },
		userData,
		userData: { role },
		location: { pathname, state: { orgAlias } },
		setCurrentCompany,
	} = props;

	const [isAllowedToEnterScheduleBoard, setIsAllowedToEnterScheduleBoard] = React.useState(false);
	const [isAllowedToEnterMechanicView, setIsAllowedToEnterMechanicView] = React.useState(false);
	const [resourcesDefaultUrl, setResourcesDefaultUrl] = React.useState('');
	const [accountingDefaultUrl, setAccountingDefaultUrl] = React.useState('');
	const [analyticsDefaultUrl, setAnalyticsDefaultUrl] = React.useState('');
	const [settingsDefaultUrl, setSettingsDefaultUrl] = React.useState('');
	const [communicationDefaultUrl, setCommunicationDefaultUrl] = React.useState('');

	React.useEffect(() => {
		const _isAllowedToEnterScheduleBoard: boolean = isAllowed(PagePermissions.COMPANY.WORK_ORDERS.SCHEDULE_BOARD, permissions, isCompanyAdmin, role);

		let _resourcesDefaultUrl: Nullable<string> = null;
		let _analyticsDefaultUrl: Nullable<string> = null;
		let _settingsDefaultUrl: Nullable<string> = null;
		let _communicationDefaultUrl: Nullable<string> = null;
		let _accountingDefaultUrl: Nullable<string> = null;

		// Decide where to redirect on resources icon click
		if (isAllowed(PagePermissions.COMPANY.RESOURCES.WAGE_RATE, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.EMPLOYEE, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.EMPLOYEE.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.EQUIPMENT_COST, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.EQUIPMENT.MANAGE, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.CREW_TYPE, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.CREW_TYPE.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.SKILL, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.SKILL.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.LOCATION, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.LOCATION.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.SALE, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.SALE.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.DOCUMENT, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.DOCUMENT.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.DIVISION, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.DIVISION.LIST(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.EMPLOYEE_STATUS, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.EMPLOYEE_STATUS(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.EQUIPMENT_STATUS, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.EQUIPMENT_STATUS(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.RESOURCES.DELIVERABLE.MANAGE, permissions, isCompanyAdmin, role)) {
			_resourcesDefaultUrl = CLIENT.COMPANY.RESOURCES.DELIVERABLE.LIST(orgAlias, name);
		}

		if (isAllowed(PagePermissions.COMPANY.ANALYTICS.LABOR_UTILIZATION, permissions, isCompanyAdmin, role)) {
			_analyticsDefaultUrl = CLIENT.COMPANY.ANALYTICS.LABOR_UTILIZATION(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.ANALYTICS.EQUIPMENT_UTILIZATION, permissions, isCompanyAdmin, role)) {
			_analyticsDefaultUrl = CLIENT.COMPANY.ANALYTICS.EQUIPMENT_UTILIZATION(orgAlias, name);
		}

		if (UserUtils.isAdmin(userData)) {
			_settingsDefaultUrl = CLIENT.COMPANY.SETTINGS.COMPANY(orgAlias, name);
		} else {
			_settingsDefaultUrl = CLIENT.COMPANY.SETTINGS.ACCOUNT(orgAlias, name);
		}

		const _isAllowedToEnterMechanicView = isAllowed(
			PagePermissions.COMPANY.RESOURCES.EQUIPMENT.MECHANIC_VIEW, permissions, isCompanyAdmin, role
		);

		if (isAllowed(PagePermissions.COMPANY.COMMUNICATION.MANAGE, permissions, isCompanyAdmin, role)) {
			_communicationDefaultUrl = CLIENT.COMPANY.COMMUNICATION.NOTIFY_EMPLOYEES(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.COMMUNICATION.VIEW_COMMUNICATION_HISTORY, permissions, isCompanyAdmin, role)) {
			_communicationDefaultUrl = CLIENT.COMPANY.COMMUNICATION.COMMUNICATION_HISTORY(orgAlias, name);
		}

		if (isAllowed(PagePermissions.COMPANY.ACCOUNTING.PER_DIEM, permissions, isCompanyAdmin, role)) {
			_accountingDefaultUrl = CLIENT.COMPANY.ACCOUNTING.PER_DIEM_REPORT.TABLE(orgAlias, companyData.name);
		} else if (isAllowed(PagePermissions.COMPANY.ACCOUNTING.PREVAILING_WAGE, permissions, isCompanyAdmin, role)) {
			_accountingDefaultUrl = CLIENT.COMPANY.ACCOUNTING.PREVAILING_WAGE.TABLE(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.ACCOUNTING.JOB_PAYROLL, permissions, isCompanyAdmin, role)) {
			_accountingDefaultUrl = CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_REPORT.TABLE(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.ACCOUNTING.WORK_SUMMARY, permissions, isCompanyAdmin, role)) {
			_accountingDefaultUrl = CLIENT.COMPANY.ACCOUNTING.JOB_WORK_SUMMARY.TABLE(orgAlias, name);
		} else if (isAllowed(PagePermissions.COMPANY.ACCOUNTING.MANAGE_INVOICES, permissions, isCompanyAdmin, role)) {
			_accountingDefaultUrl = CLIENT.COMPANY.ACCOUNTING.INVOICES.TABLE(orgAlias, name);
		}

		setIsAllowedToEnterScheduleBoard(_isAllowedToEnterScheduleBoard);
		setIsAllowedToEnterMechanicView(_isAllowedToEnterMechanicView);
		setResourcesDefaultUrl(_resourcesDefaultUrl ?? '');
		setAnalyticsDefaultUrl(_analyticsDefaultUrl ?? '');
		setSettingsDefaultUrl(_settingsDefaultUrl);
		setCommunicationDefaultUrl(_communicationDefaultUrl ?? '');
		setAccountingDefaultUrl(_accountingDefaultUrl ?? '');
	}, [companyData.name, isCompanyAdmin, name, orgAlias, permissions, role, userData]);

	React.useEffect(() => {
		setCurrentCompany(orgAlias, companyData.id, companyData.name);
	}, [companyData.id, companyData.name, orgAlias, setCurrentCompany]);

	const links: (LinkProps | SeparatorProps | null)[] = [
		{
			isFeatureHidden: DisabledFeatures.Compliance || !companyData.complianceEhsUrl,
			url: CLIENT.COMPANY.COMPLIANCE.ROOT(orgAlias, companyData.name),
			label: 'Compliance',
			icon: 'icon-plus',
			isActive: pathname.startsWith(CLIENT.COMPANY.COMPLIANCE.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.COMPANY.DASHBOARD,
			isFeatureHidden: DisabledFeatures.Dashboard,
			url: CLIENT.COMPANY.DASHBOARD.ROOT(orgAlias, companyData.name),
			label: 'Dashboard',
			icon: 'icon-schedule_board',
			isActive: pathname.startsWith(CLIENT.COMPANY.DASHBOARD.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			isFeatureHidden: DisabledFeatures.WorkRequest || !companyData.areWorkRequestsEnabled,
			visibleFor: PagePermissions.COMPANY.WORK_REQUEST,
			url: CLIENT.COMPANY.WORK_REQUESTS.ROOT(orgAlias, companyData.name),
			label: 'Work Requests',
			icon: 'icon-work_request',
			isActive: pathname.startsWith(CLIENT.COMPANY.WORK_REQUESTS.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.COMPANY.JOBS,
			url: CLIENT.COMPANY.JOBS.ROOT(orgAlias, companyData.name),
			label: 'Jobs',
			icon: 'icon-jobs',
			isActive: pathname.startsWith(CLIENT.COMPANY.JOBS.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.COMPANY.WORK_ORDERS,
			url: isAllowedToEnterScheduleBoard
				? CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyData.name)
				: CLIENT.COMPANY.WORK_ORDERS.LIST(orgAlias, companyData.name),
			label: 'Work Orders',
			icon: 'icon-work_order',
			isActive: pathname.startsWith(CLIENT.COMPANY.SCHEDULE_BOARD.ROOT(orgAlias, companyData.name))
				|| pathname.startsWith(CLIENT.COMPANY.WORK_ORDERS.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.COMPANY.RESOURCES.EQUIPMENT,
			isFeatureHidden: !(!isAllowedToEnterScheduleBoard && isAllowedToEnterMechanicView),
			url: CLIENT.COMPANY.MECHANIC_VIEW.ROOT(orgAlias, companyData.name),
			label: 'Mechanic View',
			icon: 'icon-equipment',
			isActive: pathname.startsWith(CLIENT.COMPANY.MECHANIC_VIEW.ROOT(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.COMPANY.RESOURCES.DELIVERABLE.MANAGE,
			url: CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyData.name),
			label: 'Deliverables',
			icon: 'icon-deliverables',
			isActive: pathname.startsWith(CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			url: CLIENT.COMPANY.ORDER.TABLE(orgAlias, companyData.name),
			label: 'Orders',
			icon: 'icon-cost_code',
			visibleFor: PagePermissions.COMPANY.ORDER,
			isActive: pathname.startsWith(CLIENT.COMPANY.ORDER.ROOT(orgAlias, companyData.name)) &&
				!pathname.startsWith(CLIENT.COMPANY.ORDER.ROOT(orgAlias, companyData.name) + ignoreWorkOrdersSuffix),
			companyData,
			userData,
			isFeatureHidden: DisabledFeatures.Orders || !companyData.arePortalFunctionalitiesEnabled,
		},
		{
			url: CLIENT.COMPANY.PURCHASE_ORDER.TABLE(orgAlias, companyData.name),
			label: 'Purchase Order',
			icon: 'icon-cart',
			visibleFor: PagePermissions.COMPANY.PURCHASE_ORDER,
			isActive: pathname.includes(CLIENT.COMPANY.PURCHASE_ORDER.ROOT(orgAlias, companyData.name)),
			companyData,
			userData,
			isFeatureHidden: DisabledFeatures.PurchaseOrder || !companyData.arePortalFunctionalitiesEnabled,
		},
		{
			visibleFor: PagePermissions.COMPANY.FIELD_REPORT,
			url: CLIENT.COMPANY.FIELD_REPORT.ROOT(orgAlias, companyData.name),
			label: 'Field Reports',
			icon: 'icon-daily_report',
			isActive: pathname.startsWith(CLIENT.COMPANY.FIELD_REPORT.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.COMPANY.ACCOUNTING,
			url: accountingDefaultUrl,
			label: 'Accounting',
			icon: 'icon-accounting',
			isActive: pathname.startsWith(CLIENT.COMPANY.ACCOUNTING.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.COMPANY.ANALYTICS,
			url: analyticsDefaultUrl,
			label: 'Analytics',
			icon: 'icon-cost_analysis',
			isActive: pathname.startsWith(CLIENT.COMPANY.ANALYTICS.ROOT(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{ isHr: true },
		{
			visibleFor: PagePermissions.COMPANY.CONTACTS,
			url: CLIENT.COMPANY.CONTACTS.LIST(orgAlias, companyData.name),
			label: 'Contacts',
			icon: 'icon-contact',
			isActive: pathname.startsWith(CLIENT.COMPANY.CONTACTS.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.COMPANY.RESOURCES,
			url: resourcesDefaultUrl,
			label: 'Resources',
			icon: 'icon-list',
			isActive: pathname.startsWith(CLIENT.COMPANY.RESOURCES.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.COMPANY.TOOL_REPAIR,
			isFeatureHidden: DisabledFeatures.Inventory || !companyData.arePortalFunctionalitiesEnabled,
			url: CLIENT.COMPANY.TOOL_REPAIR.TABLE(orgAlias, companyData.name),
			label: 'Tool Repairs',
			icon: 'icon-wrench-cog',
			isActive: pathname.startsWith(CLIENT.COMPANY.TOOL_REPAIR.ROOT(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.EVERYONE,
			url: settingsDefaultUrl,
			label: 'Settings',
			icon: 'icon-settings',
			isActive: pathname.startsWith(CLIENT.COMPANY.SETTINGS.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{
			visibleFor: PagePermissions.COMPANY.COMMUNICATION,
			url: communicationDefaultUrl,
			label: 'Communication',
			icon: 'icon-notifications_active',
			isActive: pathname.startsWith(CLIENT.COMPANY.COMMUNICATION.SIDENAV(orgAlias, companyData.name)),
			companyData,
			userData,
		},
		{ isHr: true },
		{
			visibleFor: PagePermissions.EVERYONE,
			component: CustomerSupportButton,
			companyData,
			userData,
			label: 'Customer Support',
			url: null,
			icon: null,
			isActive: false,
		},
	];

	return (
		<SideNav links={links.filter((_link): _link is NonNullable<LinkProps | SeparatorProps> => !!_link)} />
	);
};

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
		userData,
	};
}

function mapDispatchToProps() {
	return {
		setCurrentCompany: AuthenticationActions.setCurrentCompany,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(React.memo(CompanySideNav));
