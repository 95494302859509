import * as React from 'react';

import { ColorPalette, ExtendedColorValue } from 'acceligent-shared/enums/color';

import Tooltip from 'af-components/Tooltip';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import ColorSquare from 'af-components/ColorSquare';

import * as ColorUtil from 'ab-utils/color.util';

interface OwnProps {
	content: Nullable<string>;
	additionalContent?: Nullable<string>;
	className?: string;
	color?: ExtendedColorValue;
	backgroundColor?: ColorPalette;
	emptyMessage?: string;
	placement?: 'top' | 'bottom';
}

type Props = OwnProps;

class CellWithTooltip extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		placement: 'bottom',
	};

	getClassName = () => {
		const { className, backgroundColor } = this.props;

		let _className = 'draggable-table__cell';
		_className = className ? `${_className} ${className}` : _className;
		_className = backgroundColor ? `${_className} draggable-table__cell--with-background ${ColorUtil.getColorBackgroundClass(backgroundColor)}` : _className;
		return _className;
	};

	renderEmptyCell = () => {
		const { emptyMessage } = this.props;
		return (
			<div className={this.getClassName()}>
				<EmptyCell message={emptyMessage} />
			</div>
		);
	};

	render() {
		const { content, additionalContent, color, placement } = this.props;

		if (!content || content === '-') {
			return this.renderEmptyCell();
		}

		const tooltipMessage = additionalContent ? `${content} ${additionalContent}` : content;

		return (
			<div className={this.getClassName()}>
				<Tooltip message={tooltipMessage} placement={placement}>
					<div className="draggable-table__cell-content">
						{color && <ColorSquare color={color} />}
						<span>{content}</span>
						{additionalContent && <span className="draggable-table__cell-content--additional">{additionalContent}</span>}
					</div>
				</Tooltip>
			</div>
		);
	}
}

export default CellWithTooltip;
