import * as React from 'react';

import LockedValue from 'af-components/LockedValue';

import { OrderChangedFormModel, ResourceStatusesList } from '../types';
import DroppableContainer from './DroppableContainer';
import Label from './Label';

interface OwnProps {
	available: boolean;
	iconStatus: string;
	items: ResourceStatusesList;
	label: string;
	deleteStatus: (available: boolean, name: string) => void;
	onOrderChange: (form: OrderChangedFormModel) => void;
}

type Props = OwnProps;

class ResourceStatusList extends React.PureComponent<Props> {

	render() {
		const { available, deleteStatus, onOrderChange, label, iconStatus, items } = this.props;
		return (
			<LockedValue
				label={<Label iconStatus={iconStatus} label={label} />}
				value={
					<DroppableContainer
						available={available}
						deleteStatus={deleteStatus}
						items={items}
						onOrderChange={onOrderChange}
					/>
				}
			/>
		);
	}
}

export default ResourceStatusList;
