import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, formValueSelector, change, FormAction } from 'redux-form';
import { Button, Row, Col } from 'react-bootstrap';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import { weekDays } from 'acceligent-shared/enums/weekDay';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { ORDER_COPY_FORM } from 'af-constants/reduxForms';

import { RootState } from 'af-reducers';

import WorkOrderCopyRM from 'ab-requestModels/workOrder/copyWorkOrders.requestModel';

import WorkOrderCopyVM from 'ab-viewModels/workOrder/copyWorkOrders.viewModel';

import Checkbox from 'af-fields/Checkbox';
import DateInput from 'af-fields/DateInput';

import SubmitButton from 'af-components/SubmitButton';
import ConfirmationModal from 'af-components/ConfirmationModal';
import CustomModal from 'af-components/CustomModal';
import Tooltip from 'af-components/Tooltip';

import WorkOrderCode from 'af-root/scenes/Company/WorkOrders/Table/OrderCopyForm/WorkOrderCode';

import * as ArrayUtils from 'ab-utils/array.util';

import validate from './validation';
import { fromVMtoRM } from './formModel';

const VALID_ORDER_GROUP_SIZE = 5;

interface OwnProps {
	onSubmit: (form: WorkOrderCopyRM) => Promise<void>;
	closeCopyModal: () => void;
	showCopyModal: boolean;
	isCopyMultipleModal?: boolean;
	initialFormData: Nullable<WorkOrderCopyVM>;
}

type StateProps = WorkOrderCopyRM;

interface DispatchProps {
	changeDate: (field: string, value: Nullable<moment.Moment>) => FormAction;
}

type FormOwnProps = OwnProps & StateProps & DispatchProps;
type Props = FormOwnProps & InjectedFormProps<WorkOrderCopyRM, FormOwnProps>;

interface State {
	showCopyToTodayWarningModal: boolean;
	showFinishedJobWarningModal: boolean;
}

class WorkOrderCopyModal extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		isCopyMultipleModal: false,
		validOrders: [],
		finishedOrders: [],
		cancelledOrders: [],
	};

	state: State = {
		showCopyToTodayWarningModal: false,
		showFinishedJobWarningModal: false,
	};

	componentDidUpdate(prevProps: Props) {
		const { initialFormData, initialize, showCopyModal } = this.props;

		// SelectedOrdersIds checks are for isCopyMultipleModal === false
		// Other checks are for isCopyMultipleModal === true
		// Additionally, we want to reinitialize every time we open up the modal. Destroying on close causes problems with reinitialization in some cases
		if (
			!!initialFormData
			&& (!!initialFormData.selectedOrdersIds?.length || !!initialFormData.validOrders)
			&& (
				(showCopyModal === true && prevProps.showCopyModal === false)
				|| (!prevProps.selectedOrdersIds && initialFormData.selectedOrdersIds)
				|| initialFormData.selectedOrdersIds !== prevProps.selectedOrdersIds
				|| !ArrayUtils.areEqual(initialFormData.validOrders, prevProps.initialFormData?.validOrders)
				|| !ArrayUtils.areEqual(initialFormData.finishedOrders, prevProps.initialFormData?.finishedOrders)
				|| !ArrayUtils.areEqual(initialFormData.cancelledOrders, prevProps.initialFormData?.cancelledOrders)
			)
		) {
			initialize(fromVMtoRM(initialFormData));
		}
	}

	showCopyToTodayWarning = () => this.setState(() => ({ showCopyToTodayWarningModal: true }));

	hideCopyToTodayWarning = () => this.setState(() => ({ showCopyToTodayWarningModal: false }));

	showFinishedJobWarning = () => this.setState(() => ({ showFinishedJobWarningModal: true }));

	hideFinishedJobWarning = () => this.setState(() => ({ showFinishedJobWarningModal: false }));

	closeModal = () => {
		const { closeCopyModal, destroy } = this.props;
		closeCopyModal();
		destroy();
	};

	onStartDateSelect = async (date: Date) => {
		const { endDate, changeDate } = this.props;
		const parsedDate = TimeUtils.parseMoment(date, TimeFormatEnum.DB_DATE_ONLY);
		const parsedEndDate = TimeUtils.parseMoment(endDate, TimeFormat.DB_DATE_ONLY);

		if (TimeUtils.isBefore(parsedEndDate, parsedDate)) {
			const tomorrow = TimeUtils.add(parsedDate, 1, 'days');
			changeDate('endDate', TimeUtils.parseMoment(tomorrow, TimeFormat.ISO_DATETIME));
		}
		changeDate('startDate', parsedDate);
	};

	onEndDateSelect = async (date: Date) => {
		const { startDate, changeDate } = this.props;
		const parsedDate = TimeUtils.parseMoment(date, TimeFormatEnum.DB_DATE_ONLY);
		const parsedStartDate = TimeUtils.parseMoment(startDate, TimeFormat.DB_DATE_ONLY);

		if (TimeUtils.isBefore(parsedDate, parsedStartDate)) {
			const yesterday = TimeUtils.add(parsedDate, -1, 'days');
			changeDate('startDate', TimeUtils.parseMoment(yesterday, TimeFormatEnum.ISO_DATETIME));
		}
		changeDate('endDate', parsedDate);
	};

	getCurrentForm = (): WorkOrderCopyRM => {
		const {
			initialFormData,
			intervalSelection,
			shouldPreserveIndex,
			shouldCopyAttachments,
			startDate,
			endDate,
			code,
			orderId,
			monday,
			tuesday,
			wednesday,
			thursday,
			friday,
			saturday,
			sunday,
			copyDate,
			selectedOrdersIds,
		} = this.props;

		if (!initialFormData) {
			throw new Error('Initial form data not found');
		}

		return {
			intervalSelection,
			shouldPreserveIndex,
			shouldCopyAttachments,
			startDate: startDate && TimeUtils.formatDate(startDate, TimeFormatEnum.DB_DATE_ONLY),
			endDate: endDate && TimeUtils.formatDate(endDate, TimeFormatEnum.DB_DATE_ONLY),
			copyDate: copyDate && TimeUtils.formatDate(copyDate, TimeFormatEnum.DB_DATE_ONLY),
			code,
			validOrders: initialFormData.validOrders,
			finishedOrders: initialFormData.finishedOrders,
			cancelledOrders: initialFormData.cancelledOrders,
			orderId,
			monday,
			tuesday,
			wednesday,
			thursday,
			friday,
			saturday,
			sunday,
			selectedOrdersIds,
		};
	};

	// NOTE: handleSubmit does not return current form value in this case so we'll access it through mapped ones
	submit = async () => {
		const { onSubmit, intervalSelection, initialFormData } = this.props;

		const form = this.getCurrentForm();

		if (
			!!initialFormData
			&& (
				(intervalSelection && TimeUtils.isDateInPeriod(initialFormData.copyDate, form.startDate, form.endDate, TimeFormatEnum.DB_DATE_ONLY))
				|| (!intervalSelection && TimeUtils.isSame(initialFormData.copyDate, form.startDate, TimeFormatEnum.DB_DATE_ONLY))
			)
		) {
			this.showCopyToTodayWarning();
		} else {
			await onSubmit(form);
		}
	};

	confirmCopyToTodayWarning = async () => {
		const { onSubmit } = this.props;

		const form = this.getCurrentForm();
		await onSubmit(form);
		this.hideCopyToTodayWarning();
		this.closeModal();
	};

	renderCopyToTodayWarning(code: string | undefined) {
		const { showCopyToTodayWarningModal } = this.state;
		const { isCopyMultipleModal, invalid, submitting, initialFormData } = this.props;
		const messageSuffix = isCopyMultipleModal ? 's' : '';

		return (
			<ConfirmationModal
				body={
					<>
						You are about to make {isCopyMultipleModal ? '' : 'a'} duplicate work order{messageSuffix}.
						Are you sure you want to copy the work order{messageSuffix}?
						<br />
					</>
				}
				closeModal={this.hideCopyToTodayWarning}
				confirmAction={this.confirmCopyToTodayWarning}
				confirmText="Copy Work Order"
				footer={
					<>
						<Button onClick={this.hideCopyToTodayWarning} variant="info">Cancel</Button>
						<SubmitButton
							disabled={invalid}
							label={`Copy Work Order${messageSuffix}`}
							onClick={this.confirmCopyToTodayWarning}
							reduxFormSubmitting={submitting}
							submitKey={ORDER_COPY_FORM}
							variant="danger"
						/>
					</>
				}
				modalStyle="danger"
				showModal={showCopyToTodayWarningModal}
				title={isCopyMultipleModal ?
					`Copy Work Orders from ${initialFormData?.copyDate}` :
					`Copy Work Order ${code}`
				}
			/>
		);
	}

	renderWorkOrderCodes = (validOrders: string[], shouldCopy: boolean) => {
		const groupedValidOrders = validOrders.reduce<string[][]>((_acc, _wo) => {
			const index = _acc.length - 1;
			_acc[index].push(_wo);
			if (_acc[index].length === VALID_ORDER_GROUP_SIZE) {
				_acc.push([]);
			}
			return _acc;
		}, [[]]);

		return (
			<div className="work-order-copy-modal__codes-list">
				{groupedValidOrders.map((_orderGroup, _i1) => (
					<div
						className="work-order-copy-modal__codes-group"
						key={_i1}
					>
						{_orderGroup.map((_code: string, _i2: number) => (
							<WorkOrderCode
								code={_code}
								key={`${_code}#${_i2}`}
								shouldCopy={shouldCopy}
							/>
						))}
					</div>

				))}
			</div>
		);
	};

	renderModalData = () => {
		const {
			submitting,
			invalid,
			isCopyMultipleModal,
			intervalSelection,
			initialFormData,
			error,
		} = this.props;

		const isAnyOrderValid = !!(initialFormData?.validOrders?.[0]?.length);
		const isAnyOrderFinished = !!(initialFormData?.finishedOrders?.[0]?.length);
		const isAnyOrderCancelled = !!(initialFormData?.cancelledOrders?.[0]?.length);

		return (
			<>
				<CustomModal.Header
					closeModal={this.closeModal}
					title={isCopyMultipleModal ?
						`Copy Work Order(s) from ${initialFormData?.copyDate}` :
						`Copy Work Order ${initialFormData?.code}`
					}
				/>
				<CustomModal.Body className="modal-body modal-body--visible-overflow">
					{isCopyMultipleModal ?
						<>
							{
								isAnyOrderValid &&
								<Row className="row--non-padded">
									<Col sm={24}>
										Following work order(s) will be copied:
										<br />
										{this.renderWorkOrderCodes(initialFormData?.validOrders ?? [], true)}
									</Col>
								</Row>
							}
							{
								isAnyOrderFinished &&
								<Row className="row--non-padded">
									<Col sm={24}>
										Following work order(s) <strong>won't be copied</strong> because their Jobs are finished:
										<br />
										{this.renderWorkOrderCodes(initialFormData?.finishedOrders ?? [], false)}
									</Col>
								</Row>
							}
							{
								isAnyOrderCancelled &&
								<Row className="row--non-padded">
									<Col sm={24}>
										Following work order(s) <strong>won't be copied</strong> because they were cancelled:
										<br />
										{this.renderWorkOrderCodes(initialFormData?.cancelledOrders ?? [], false)}
									</Col>
								</Row>
							}
							<hr />
						</>
						:
						<Row className="row--non-padded">
							<Col sm={24}>
								Work order <strong>{initialFormData?.code}</strong> will be copied to the {intervalSelection ? 'selected days into the choose interval' : 'following date'}:
							</Col>
						</Row>
					}
					<br />
					<Row className="work-order-copy-modal__date-selection row--non-padded">
						{intervalSelection ?
							<>
								<Col sm={6}>
									<Field
										component={DateInput}
										dateFormat={TimeFormatEnum.DATE_ONLY}
										id="startDate"
										label="Start Date"
										name="startDate"
										onValueChange={this.onStartDateSelect}
										originalDateFormat={TimeFormatEnum.ISO_DATETIME}
										placeholderText={TimeFormatEnum.DATE_ONLY}
										popperPlacement="right"
										wrapperId="accqa__wo-copy-start-date"
									/>
								</Col>
								<Col sm={6}>
									<Field
										component={DateInput}
										dateFormat={TimeFormatEnum.DATE_ONLY}
										id="endDate"
										label="End Date"
										name="endDate"
										onValueChange={this.onEndDateSelect}
										originalDateFormat={TimeFormatEnum.ISO_DATETIME}
										placeholderText={TimeFormatEnum.DATE_ONLY}
										popperPlacement="right"
										wrapperId="accqa__wo-copy-end-date"
									/>
								</Col>
							</> :
							<>
								<div />
								<Col className="work-order-copy-modal__single-date" sm={12}>
									<Field
										component={DateInput}
										dateFormat={TimeFormatEnum.DATE_ONLY}
										id="startDate"
										label="Copy to Date"
										name="startDate"
										onValueChange={this.onStartDateSelect}
										originalDateFormat={TimeFormatEnum.ISO_DATETIME}
										placeholderText={TimeFormatEnum.DATE_ONLY}
										popperPlacement="right"
										wrapperId="accqa__wo-copy-date"
									/>
								</Col>
							</>
						}
						<Col sm={12} />
						<Col sm={4}>
							<Field
								component={Checkbox}
								id="intervalSelection"
								isStandalone={true}
								label="Select Interval"
								name="intervalSelection"
								propName="intervalSelection"
							/>
						</Col>
						<Col className="work-order-copy-modal__work-ordering" sm={8}>
							<Field
								component={Checkbox}
								id="shouldPreserveIndex"
								isStandalone={true}
								label={isCopyMultipleModal ?
									'Maintain Work Order Ordering' :
									'Keep Work Order Position'
								}
								name="shouldPreserveIndex"
								propName="shouldPreserveIndex"
							/>
							<Tooltip
								message={`If left unchecked, the Work Order ${isCopyMultipleModal ? 'copies ' : 'copy '} will be put at the end of the schedule ${intervalSelection ? 'boards' : 'board'}.`}
								placement="top"
							>
								<span className="icon-info" />
							</Tooltip>
						</Col>
					</Row>
					<br />
					{intervalSelection
						? <>
							<Row className="row--non-padded">
								<Col md={24}>
									<div>Select days of the week within the interval:</div>
								</Col>
							</Row>
							<Row className="row--non-padded">
								<Col className="work-order-copy-modal__work-days" md={24}>
									{weekDays.map((_d: string, _index: number) => (
										<Field
											capitalize={true}
											component={Checkbox}
											isStandalone={true}
											key={_index}
											label={_d.toLowerCase()}
											name={_d.toLowerCase()}
										/>
									))}
								</Col>
							</Row>
							<br />
							<Row className="row--non-padded">
								<Col md={24}>
									<div className="text-danger">{error}</div>
								</Col>
							</Row>
						</>
						: <></>
					}
					<Row className="row--non-padded">
						<Col md={24}>
							<Field
								component={Checkbox}
								id="shouldCopyAttachments"
								isStandalone={true}
								label="Copy Work Order Attachments"
								name="shouldCopyAttachments"
								propName="shouldCopyAttachments"
							/>
						</Col>
					</Row>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button onClick={this.closeModal} variant="info">Cancel</Button>
					<SubmitButton
						disabled={invalid}
						label={`Copy Work Order${isCopyMultipleModal ? 's' : ''}`}
						onClick={this.submit}
						reduxFormSubmitting={submitting}
						submitKey={ORDER_COPY_FORM}
					/>
				</CustomModal.Footer>
			</>
		);
	};

	render() {
		const { showCopyToTodayWarningModal } = this.state;
		const { showCopyModal, code } = this.props;

		if (showCopyToTodayWarningModal) {
			return this.renderCopyToTodayWarning(code);
		}

		return (
			<CustomModal
				className="work-order-copy-modal"
				closeModal={this.closeModal}
				modalStyle="info"
				showModal={showCopyModal}
				size="xl"
			>
				{this.renderModalData()}
			</CustomModal>
		);
	}
}

const selector = formValueSelector(ORDER_COPY_FORM);

function mapStateToProps(state: RootState): StateProps {
	return {
		intervalSelection: selector(state, 'intervalSelection'),
		shouldPreserveIndex: selector(state, 'shouldPreserveIndex'),
		shouldCopyAttachments: selector(state, 'shouldCopyAttachments'),
		startDate: selector(state, 'startDate'),
		endDate: selector(state, 'endDate'),
		code: selector(state, 'code'),
		validOrders: selector(state, 'validOrders'),
		finishedOrders: selector(state, 'finishedOrders'),
		cancelledOrders: selector(state, 'cancelledOrders'),
		orderId: selector(state, 'orderId'),
		monday: selector(state, 'monday'),
		tuesday: selector(state, 'tuesday'),
		wednesday: selector(state, 'wednesday'),
		thursday: selector(state, 'thursday'),
		friday: selector(state, 'friday'),
		saturday: selector(state, 'saturday'),
		sunday: selector(state, 'sunday'),
		copyDate: selector(state, 'copyDate'),
		selectedOrdersIds: selector(state, 'selectedOrdersIds'),
	};
}

function mapDispatchToProps(dispatch: Dispatch<FormAction>): DispatchProps {
	return {
		changeDate: (field: string, value: Nullable<moment.Moment>) => dispatch(change(ORDER_COPY_FORM, field, value)),
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
	reduxForm<WorkOrderCopyRM, FormOwnProps>({ form: ORDER_COPY_FORM, validate, enableReinitialize: true })
);

export default enhance(WorkOrderCopyModal);
