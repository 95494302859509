import * as React from 'react';

type Props = React.PropsWithChildren<unknown>;

const EmployeeDraggableBadgesContainer = (props: Props) => {
	const { children } = props;

	return (
		<div className="badges-container">
			{children}
		</div>
	);
};

export default EmployeeDraggableBadgesContainer;
