/* eslint-disable @typescript-eslint/no-explicit-any */
export function deepClone<T>(obj: T): T {
	if (obj === null || typeof obj !== 'object' || typeof obj === 'function') {
	  return obj;
	}

	if (obj instanceof Date) {
	  return new Date(obj.getTime()) as any;
	}

	if (typeof obj === 'number' && (isNaN(obj) || obj === Infinity || obj === -Infinity)) {
	  return obj;
	}

	if (obj instanceof ArrayBuffer) {
	  return obj.slice(0) as any;
	}

	if (ArrayBuffer.isView(obj)) {
	  return new (obj.constructor as any)(deepClone(obj.buffer)) as any;
	}

	if (obj instanceof Set) {
	  const clonedSet = new Set();
	  obj.forEach((value) => clonedSet.add(deepClone(value)));
	  return clonedSet as any;
	}

	if (obj instanceof Map) {
	  const clonedMap = new Map();
	  obj.forEach((value, key) => clonedMap.set(deepClone(key), deepClone(value)));
	  return clonedMap as any;
	}

	if (obj instanceof File) {
	  return new File([obj], obj.name, { type: obj.type, lastModified: obj.lastModified }) as any;
	}

	const clonedObj: any = Array.isArray(obj) ? [] : {};

	for (const key of Reflect.ownKeys(obj as unknown as object)) {
	  if (typeof key === 'symbol') {
			clonedObj[key] = deepClone((obj as any)[key]);
	  } else {
			clonedObj[key] = deepClone(obj[key]);
	  }
	}

	return clonedObj;
}

