import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import * as SettingsKeys from 'af-constants/settingsKeys';

import TabNavigation from 'af-components/TabNavigation';
import Breadcrumbs from 'af-components/Breadcrumbs';

import { withSettings } from 'af-utils/settings.util';
import * as SettingsUtils from 'af-utils/settings.util';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import DeliverableDataStatusList from './DeliverableDataStatus';
import DeliverableJobStatusList from './DeliverableJobStatus';
import DeliverableData from './DeliverableData';

const TAB_IDS = {
	DATA_STATUS: 0,
	JOB_DELIVERABLE_STATUS: 1,
	DATA: 2,
};

interface StateProps {
	companyName: string;
}
interface SettingProps {
	activeTabId: number;
}

type OwnProps = CustomRouteComponentProps;

type Props = StateProps & OwnProps & SettingProps;

interface State {
	activeTabId: number;
}

const TABS = [
	{ id: TAB_IDS.DATA_STATUS, label: 'Data Status' },
	{ id: TAB_IDS.JOB_DELIVERABLE_STATUS, label: 'Job Deliverable Status' },
	{ id: TAB_IDS.DATA, label: 'Data' },
];

class DeliverableResources extends React.PureComponent<Props, State> {
	state: State = {
		activeTabId: this.props.activeTabId,
	};

	onTabSelect = (tabId: number) => {
		SettingsUtils.setDeliverableDataActiveTabId(tabId);
		this.setState(() => ({ activeTabId: tabId }));
	};

	renderTab = () => {
		const { location: { state: { orgAlias } }, companyName } = this.props;
		const { activeTabId } = this.state;
		switch (activeTabId) {
			case TAB_IDS.DATA:
				return <DeliverableData />;
			case TAB_IDS.JOB_DELIVERABLE_STATUS:
				return <DeliverableJobStatusList companyName={companyName} orgAlias={orgAlias} />;
			case TAB_IDS.DATA_STATUS:
			default:
				return <DeliverableDataStatusList companyName={companyName} orgAlias={orgAlias} />;
		}
	};

	breadcrumbs = () => [{ label: 'Deliverable Resource' }];

	render() {
		const { activeTabId } = this.state;
		return (
			<>
				<Breadcrumbs items={this.breadcrumbs()} />
				<div className="table-container table-container--tabbed">
					<div>
						<TabNavigation
							active={activeTabId}
							onClick={this.onTabSelect}
							tabs={TABS}
						/>
						{this.renderTab()}
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state: RootState): StateProps => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

const enhance = compose<React.ComponentClass<OwnProps>>(
	withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.DELIVERABLE_RESOURCE_ACTIVE_TAB_ID(),
			mappedName: 'activeTabId',
			normalize: (activeTabId: string) => +activeTabId,
			defaultValue: TAB_IDS.DATA_STATUS,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connect<StateProps, null, OwnProps>(mapStateToProps)
);

export default enhance(DeliverableResources);
