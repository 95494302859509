import * as React from 'react';

import styles from './styles.module.scss';

type EmployeeName = { fullName: string; };
type EquipmentCode = { code: string; };

type Employee = EmployeeName | string;
type Equipment = EquipmentCode | string;

type Resource = Employee | Equipment;

interface OwnProps {
	workOrderEmployees: Employee[];
	workOrderEquipment: Equipment[];
	workOrderTemporaryEmployees: Employee[];
}

type Props = OwnProps;

const baseClassName = 'resource-tooltip';

const _isEquipment = (resource: Resource): resource is EquipmentCode => {
	return typeof resource !== 'string' && 'code' in resource && !!resource.code;
};

const _isEmployee = (resource: Resource): resource is EmployeeName => {
	return typeof resource !== 'string' && 'fullName' in resource && !!resource.fullName;
};

const _getResource = (resource: Resource): string => {
	if (_isEmployee(resource)) {
		return resource.fullName;
	} else if (_isEquipment(resource)) {
		return resource.code;
	}
	return resource;
};

const _renderWorkOrderResource = (resource: Resource, index: number): JSX.Element => (
	<div className={styles[`${baseClassName}__resource-item`]} key={`resource-${index}`}>
		{resource && _getResource(resource)}
	</div>
);

const ResourceTooltipList = ({ workOrderEmployees, workOrderEquipment, workOrderTemporaryEmployees }: Props) => {
	return (
		<div className={styles[`${baseClassName}__container`]}>
			<div className={styles[`${baseClassName}__resource-container`]}>
				<label>Equipment</label>
				{workOrderEquipment?.map(_renderWorkOrderResource)}
			</div>
			<div className={styles[`${baseClassName}__resource-container`]}>
				<label>Labor</label>
				{workOrderEmployees?.map(_renderWorkOrderResource)}
			</div>
			<div className={styles[`${baseClassName}__resource-container`]}>
				<label>Temporary Labor</label>
				{workOrderTemporaryEmployees?.map(_renderWorkOrderResource)}
			</div>
		</div>
	);
};

export default React.memo(ResourceTooltipList);
