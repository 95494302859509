import * as React from 'react';

import { NOTIFY_MODAL_COL_SPAN, NOTIFY_EMPLOYEE_TYPE } from 'af-constants/values';

interface OwnProps {
	checkAll: (value: boolean, type: NOTIFY_EMPLOYEE_TYPE) => void;
	showSubscribedColumns: boolean;
}

type Props = OwnProps;

class TableHeader extends React.PureComponent<Props> {

	checkAllEmail = () => this.props.checkAll(true, NOTIFY_EMPLOYEE_TYPE.EMAIL);

	uncheckAllEmail = () => this.props.checkAll(false, NOTIFY_EMPLOYEE_TYPE.EMAIL);

	checkAllSMS = () => this.props.checkAll(true, NOTIFY_EMPLOYEE_TYPE.SMS);

	uncheckAllSMS = () => this.props.checkAll(false, NOTIFY_EMPLOYEE_TYPE.SMS);

	render() {
		const { showSubscribedColumns } = this.props;
		return (
			<thead>
				<tr>
					<th colSpan={NOTIFY_MODAL_COL_SPAN.CREW}>
						<div className="column-name">CREW</div>
					</th>
					<th colSpan={NOTIFY_MODAL_COL_SPAN.CHECKBOX}>
						<div className="column-title">SEND EMAIL</div>
						<div className="column-actions">
							<a className="btn--link" onClick={this.checkAllEmail} role="button">All</a>
							<a className="btn--link" onClick={this.uncheckAllEmail} role="button">None</a>
						</div>
					</th>
					<th colSpan={NOTIFY_MODAL_COL_SPAN.SEND_TIME}>
						<div className="column-name">LAST E-MAIL SENT</div>
					</th>
					{showSubscribedColumns &&
					<th colSpan={NOTIFY_MODAL_COL_SPAN.SEND_TIME}>
						<div className="column-name">AUTO-NOTIFY AT</div>
					</th>
					}
					<th colSpan={NOTIFY_MODAL_COL_SPAN.CHECKBOX}>
						<div className="column-title">SEND SMS</div>
						<div className="column-actions">
							<a className="btn--link" onClick={this.checkAllSMS} role="button">All</a>
							<a className="btn--link" onClick={this.uncheckAllSMS} role="button">None</a>
						</div>
					</th>
					<th colSpan={NOTIFY_MODAL_COL_SPAN.SEND_TIME}>
						<div className="column-name">LAST SMS SENT</div>
					</th>
					{showSubscribedColumns &&
					<th colSpan={NOTIFY_MODAL_COL_SPAN.SEND_TIME}>
						<div className="column-name">AUTO-NOTIFY AT</div>
					</th>
					}
				</tr>
			</thead>
		);
	}
}

export default TableHeader;
