import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import Tooltip from 'af-components/Tooltip';
import LockedValue from 'af-components/LockedValue';

interface OwnProps {
	submissionCode: string;
	comments: string[];
	notes: Nullable<string>;
}

type Props = OwnProps;

const getHeader = (workOrderCode: string) => {
	return (
		<Tooltip message={workOrderCode}>
			<div className="text-hidden">{workOrderCode}</div>
		</Tooltip>
	);
};

const DeliverableSubmissionHeader: React.FC<Props> = (props: Props) => {
	const { comments, submissionCode, notes } = props;

	return (
		<Row className="deliverable-status-history-modal-details" >
			<Col sm={8}><LockedValue defaultValue="N/A" label="Work Order Code" value={getHeader(submissionCode)} /></Col>
			<Col md={8}>
				<LockedValue defaultValue="N/A"
					label="Comments"
					value={comments.length &&
						<Tooltip message={<div>{comments.map((_comment, _index) => <div key={_index}>{_comment}</div>)}</div>}>
							<span className="icon-chat chat-icon" />
						</Tooltip>
					}
				/>
			</Col>
			<Col sm={8}>
				<LockedValue
					defaultValue="N/A"
					label="Notes"
					value={notes &&
						<Tooltip message={notes}>
							<span className="icon-display_view notes-icon" />
						</Tooltip>
					}
				/>
			</Col>
		</Row>
	);
};

export default React.memo(DeliverableSubmissionHeader);
