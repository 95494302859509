import * as React from 'react';
import { compose } from 'redux';
import { Button, Row, Col } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import PeriodPicker from 'af-components/Controls/PeriodPicker';

import * as WorkRequestActions from 'af-actions/workRequests';

import JobReportListVM from 'ab-viewModels/report/jobReportList.viewModel';

import JobReportList from './JobReportList';

import styles from './styles.module.scss';
import { filterMap } from 'acceligent-shared/utils/array';
import { bemElement } from 'ab-utils/bem.util';

const MODAL_TITLE = 'Export Complete Reports Across Multiple Jobs';

interface OwnProps {
	closeModal: () => void;
	onSubmit: (ids: number[], startDate: Date, endDate: Date) => void;
	showModal: boolean;
	initialStartDate: Date;
	initialEndDate: Date;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const _getFilterJobs = (query: string) => {
	return (jobReport: JobReportListVM) => {
		return JobReportListVM.matches(jobReport, query);
	};
};

const MultipleJobReportsActionModal: React.FC<Props> = (props) => {
	const {
		showModal,
		closeModal,
		findJobReportsList,
		onSubmit,
		initialStartDate,
		initialEndDate,
	} = props;

	const [startDate, setStartDate] = React.useState<Date>(initialStartDate);
	const [endDate, setEndDate] = React.useState<Date>(initialEndDate);
	const [showApprovalWarning, setShowApprovalWarning] = React.useState(false);
	const [searchText, setSearchText] = React.useState<string>('');
	const [selectJobsMap, setSelectJobsMap] = React.useState<{ [id: number]: boolean; }>({});
	const [jobReports, setJobReports] = React.useState<JobReportListVM[]>([]);
	const [filteredJobReports, setFilteredJobReports] = React.useState<JobReportListVM[]>([]);
	const [isValid, setIsValid] = React.useState(false);

	React.useEffect(() => {
		setStartDate(initialStartDate);
	}, [initialStartDate]);

	React.useEffect(() => {
		setEndDate(initialEndDate);
	}, [initialEndDate]);

	React.useEffect(() => {
		const getReports = async () => {
			const _reports = await findJobReportsList(startDate, endDate);
			setJobReports(_reports);
			setFilteredJobReports(_reports);
		};
		if (showModal) {
			getReports();
		}
	}, [startDate, endDate, findJobReportsList, showModal]);

	React.useEffect(() => {
		setShowApprovalWarning(jobReports.some((_item) => selectJobsMap[_item.workRequestId] && !_item.areAllReportsInAccounting));
	}, [selectJobsMap, jobReports]);

	React.useEffect(() => {
		const _isValid = Object.values(selectJobsMap).some((_val) => _val);
		setIsValid(_isValid);
	}, [selectJobsMap]);

	const handlePeriodChange = React.useCallback((_startDate: Date, _endDate: Date) => {
		setStartDate(_startDate);
		setEndDate(_endDate);
	}, []);

	const submit = React.useCallback(() => {
		const ids = filterMap(
			Object.entries(selectJobsMap),
			([, value]) => value,
			([key]) => Number(key)
		);
		onSubmit(ids, startDate, endDate);
		setSearchText('');
		setFilteredJobReports([]);
		setSelectJobsMap({});
	}, [selectJobsMap, onSubmit, startDate, endDate]);

	const onSearchInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const _searchText = event.target.value;
		setSearchText(_searchText);
		if (_searchText) {
			const filteredJobs = jobReports.filter(_getFilterJobs(_searchText));
			setFilteredJobReports(filteredJobs);
		} else {
			setFilteredJobReports(jobReports);
		}
	}, [jobReports]);

	const clearSearch = React.useCallback(() => {
		setSearchText('');
		setFilteredJobReports(jobReports);
	}, [jobReports]);

	const onValueChange = React.useCallback((id: number, value: boolean) => {
		const _newSelectJobsMap = { ...selectJobsMap, [id]: value };
		setSelectJobsMap(_newSelectJobsMap);
	}, [selectJobsMap]);

	const onSelectAll = React.useCallback(() => {
		const _newSelectJobsMap = {};
		filteredJobReports.forEach((jr) => _newSelectJobsMap[jr.workRequestId] = true);
		setSelectJobsMap(_newSelectJobsMap);
	}, [filteredJobReports]);

	const onClearAll = React.useCallback(() => {
		const _newSelectJobsMap = { ...selectJobsMap };
		filteredJobReports.forEach((jr) => _newSelectJobsMap[jr.workRequestId] = false);
		setSelectJobsMap(_newSelectJobsMap);
	}, [filteredJobReports, selectJobsMap]);

	const onClose = React.useCallback(() => {
		setSearchText('');
		setStartDate(initialStartDate);
		setEndDate(initialEndDate);
		setSelectJobsMap({});
		setJobReports([]);
		setFilteredJobReports([]);
		closeModal();
	}, [closeModal, initialEndDate, initialStartDate]);

	return (
		<CustomModal
			closeModal={onClose}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header closeModal={onClose} title={MODAL_TITLE} />
			<CustomModal.Body padding="none">
				{showApprovalWarning &&
					<div className={styles['modal__approved-warning']}>
						Some of the Jobs in the filtered time period are not in "Accounting approved" state.
					</div>
				}
				<div className={styles.modal__container}>
					<Row>
						Select date range and Jobs you wish to download Field Reports from.
						<br />
						All reports will be downloaded as a single file.
					</Row>
					<Row>
						<span className={bemElement('field-report__work-summary__billable-work__legend', 'single')}>
							<span className={`${styles['modal__legend__single__empty-cell']} ${styles['modal__legend__single__empty-cell--selected']}`} />
							This job has some Field Reports in selected date range that have not been approved by accounting
						</span>
					</Row>
					<Row>
						<Col className={styles.modal__col} sm={12}>
							<div className={styles['modal__search-container']}>
								<input
									className={`form-control ${styles['modal__search-container__search-input']}`}
									onChange={onSearchInputChange}
									placeholder="Search Job"
									type="text"
									value={searchText}
								/>
								{
									searchText
										?
										<span
											className={`icon-close ${styles['modal__search-container__search-icon']}`}
											onClick={clearSearch}
											role="button"
										/>
										: <span className={`icon-search ${styles['modal__search-container__search-icon']}`} />
								}
							</div>
						</Col>
						<Col className={styles.modal__col} sm={12}>
							<PeriodPicker
								end={endDate}
								isPopperFixed
								isPopperInline
								onChange={handlePeriodChange}
								start={startDate}
							/>
						</Col>
					</Row>
					<Row>
						<JobReportList
							filterExists={!!searchText}
							items={filteredJobReports}
							onClearAll={onClearAll}
							onSelectAll={onSelectAll}
							onValueChange={onValueChange}
							selectJobsMap={selectJobsMap}
						/>
					</Row>
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button onClick={onClose} variant="info">Cancel</Button>
				<SubmitButton
					disabled={!isValid}
					label="Export to PDF"
					onClick={submit}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		findJobReportsList: WorkRequestActions.findJobReportsList,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(MultipleJobReportsActionModal);
