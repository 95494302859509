import { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import CrewTypeRequestModel from 'ab-requestModels/crewType.requestModel';
import { GroupedCrewTypes } from 'ab-viewModels/crewType.viewModel';

import { GetRootState } from 'af-reducers';

export function create(form: CrewTypeRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.RESOURCES.CREW_TYPE.CREATE(), { ...form });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(crewTypeId: number, form: CrewTypeRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.RESOURCES.CREW_TYPE.EDIT(crewTypeId.toString()), { ...form });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteCrewType(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.CREW_TYPE.DELETE(id.toString()));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<GroupedCrewTypes>(API.V1.RESOURCES.CREW_TYPE.LIST(''));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const result = await http.get<GroupedCrewTypes>(API.V1.RESOURCES.CREW_TYPE.LIST(''));
			return Object.values(result).flat();
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
