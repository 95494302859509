import * as React from 'react';

import { FieldReportTypeStatusEnumIcon } from 'acceligent-shared/enums/fieldReportTypeStatus';

import { bemElement } from 'ab-utils/bem.util';

import { FieldReportTypeVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

interface OwnProps {
	fieldReportType: FieldReportTypeVM;
	index: number;
}

type Props = OwnProps;

const FieldReportType: React.FC<Props> = (props) => {
	const { fieldReportType: { name }, index } = props;

	const iconClassName = `${bemElement('report-submit-modal', 'report-type-icon')} ${FieldReportTypeStatusEnumIcon.NOT_FILLED}`;

	return (
		<span className="report-submit-modal__field-report-type-item" key={index}>
			<span className={iconClassName} />
			<span className="report-submit-modal__field-report-type-name">
				{name}
			</span>
		</span>
	);
};

export default React.memo(FieldReportType);
