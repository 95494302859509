export const DEFAULT_TIMEZONE = 'America/New_York';

export const COMPANY_DEFAULT = {
	INDIRECTS: 0,
	OVERHEAD: 0,
	RISK: 0,
	CONTINGENCY: 0,
	PROFIT: 0,
};

export const DAY_SHIFT_START = 420;
export const DAY_SHIFT_END = 900;
export const NIGHT_SHIFT_START = DAY_SHIFT_END;
export const NIGHT_SHIFT_END = 1380;

export const DEFAULT_FISCAL_YEAR_START_MONTH = 11; // November

export const NOTION_URL = 'https://www.notion.so';
export const NOTION_ORGANIZATIONS = ['acceligent'];
