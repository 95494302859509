import * as React from 'react';
import { bemElement as bem } from 'ab-utils/bem.util';

interface Props {
	uploading?: boolean;
	disabled?: boolean;
	error?: Nullable<string>;
}

const FileUploadMessage = ({ disabled = false, uploading = false, error }: Props) => {
	if (disabled) {
		return <span className={bem('dropzone-element', 'text')}>Upload is disabled	</span>;
	} else if (uploading) {
		return <span className={bem('dropzone-element', 'text', ['uploading'])}>Uploading...</span>;
	} else {
		return (
			<>
				{error &&
					<span className={bem('dropzone-element', 'error')}>
						{error}
					</span>
				}
				<span className={`icon-upload ${bem('dropzone-element', 'icon-upload')}`} />
				<span className={bem('dropzone-element', 'text')}>
					Drop files to attach or <span className="text-blue">click to browse</span>
				</span>
			</>
		);
	}
};

export default React.memo(FileUploadMessage);
