import * as React from 'react';

import Dropdown from 'af-components/Controls/Dropdown';
import Tooltip from 'af-components/Tooltip';

import { bemElement } from 'ab-utils/bem.util';

export const TIME_SHEET_TAB_ID = 0;

interface DropdownOption {
	label: string;
	onClick: () => void;
}

interface OwnPropsBase {
	title: string;
	subtitle: string;
	isActive: boolean;
	onSelect: (tabId: number) => void;
}

interface TimeSheetOwnProps extends OwnPropsBase {
	tabId: typeof TIME_SHEET_TAB_ID;
	modifiedUsers: Nullable<string[]>;
	rejectedUsers: Nullable<string[]>;
	areAllTimeSheetsAndSplitsReadOnly: boolean;
	numberOfTimeSheets: number;
	onTimeSheetsBulkEditClick: () => void;
}

interface FieldReportOwnProps extends OwnPropsBase {
	title: string;
	subtitle: string;
	isActive: boolean;
	tabId: number;
	canDelete: boolean;
	onDelete: (tabId: number) => void;
}

type OwnProps = TimeSheetOwnProps | FieldReportOwnProps;

type Props = OwnProps;

class FieldReportListTab extends React.PureComponent<Props> {

	static rejectedUserMessageMapper = (name: string) => {
		return (
			<div key={name}>
				{`${name}'s time sheet rejected`}
			</div>
		);
	};

	static modifiedUserMessageMapper = (name: string) => {
		return (
			<div key={name}>
				{`${name}'s time sheet re-signed`}
			</div>
		);
	};

	static renderTooltipMessage = (rejectedUsers: Nullable<string[]>, modifiedUsers: Nullable<string[]>) => {
		const rejectedUsersMessages = rejectedUsers?.map(FieldReportListTab.rejectedUserMessageMapper) ?? [];
		const modifiedUsersMessages = modifiedUsers?.map(FieldReportListTab.modifiedUserMessageMapper) ?? [];
		return [...rejectedUsersMessages, ...modifiedUsersMessages];
	};

	onSelect = () => {
		const { onSelect, tabId } = this.props;
		onSelect(tabId);
	};

	onEditTimeSheets = () => {
		const { onTimeSheetsBulkEditClick } = this.props as TimeSheetOwnProps;
		onTimeSheetsBulkEditClick();
	};

	onDelete = () => {
		const { onDelete, tabId } = this.props as FieldReportOwnProps;
		onDelete?.(tabId);
	};

	actionOptions = () => {
		const { canDelete } = this.props as FieldReportOwnProps;
		const options: DropdownOption[] = [];

		if (canDelete) {
			options.push({ label: 'Delete', onClick: this.onDelete });
		}

		return options;
	};

	renderActions = () => {
		const { tabId } = this.props;

		if (tabId === TIME_SHEET_TAB_ID) {
			return null;
		}

		const options = this.actionOptions();

		if (!options.length) {
			return null;
		}

		return (
			<div>
				<Dropdown<DropdownOption>
					id="actions"
					isActionDropdown={true}
					labelKey="label"
					options={this.actionOptions()}
				/>
			</div>
		);
	};

	renderTabWithToolTip = () => {
		const { rejectedUsers, modifiedUsers } = this.props as TimeSheetOwnProps;

		return (
			<Tooltip message={FieldReportListTab.renderTooltipMessage(rejectedUsers, modifiedUsers)}>
				{this.renderTab(!!rejectedUsers?.length, !!modifiedUsers?.length)}
			</Tooltip>
		);
	};

	renderTab = (hasRejected: boolean, isModified: boolean) => {
		const { title, subtitle, isActive, tabId } = this.props;
		const { areAllTimeSheetsAndSplitsReadOnly, numberOfTimeSheets } = this.props as TimeSheetOwnProps;

		const className = bemElement('field-report-list__tabs', 'tab', {
			active: isActive,
			'fit-content': tabId === TIME_SHEET_TAB_ID,
			'active-rejected': tabId === TIME_SHEET_TAB_ID && isActive && hasRejected,
			'inactive-rejected': tabId === TIME_SHEET_TAB_ID && !isActive && hasRejected,
		});
		const iconClassName = `icon-dot ${bemElement('field-report-list__tabs__tab', 'modified-icon', { 'inactive-rejected': !isActive && hasRejected })}`;

		const editIconType = areAllTimeSheetsAndSplitsReadOnly ? 'icon-search' : 'icon-edit';
		const editIconClassName = `${editIconType} ${bemElement('field-report-list__tabs__tab', `${editIconType}`)}`;

		return (
			<div
				className={className}
			>
				<div
					className="field-report-list__tabs__tab__content"
					onClick={this.onSelect}
				>
					<div className="field-report-list__tabs__tab__title">
						{title}
						{isModified && <span className={iconClassName} />}
					</div>
					<div className="field-report-list__tabs__tab__subtitle">{subtitle}</div>
				</div>
				{tabId === TIME_SHEET_TAB_ID && numberOfTimeSheets > 0 && <span className={editIconClassName} onClick={this.onEditTimeSheets} />}
				{this.renderActions()}
			</div>
		);
	};

	render() {
		const { rejectedUsers, modifiedUsers } = this.props as TimeSheetOwnProps;

		const showTooltip = !!rejectedUsers?.length || !!modifiedUsers?.length;

		return showTooltip
			? this.renderTabWithToolTip()
			: this.renderTab(false, false);
	}
}

export default FieldReportListTab as React.ComponentClass<OwnProps>;
