import * as React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { reduxForm, InjectedFormProps, Field, getFormSyncWarnings, FormErrors } from 'redux-form';

import Priority, { PriorityItem, PriorityList } from 'acceligent-shared/enums/priority';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import UnavailabilityReasonScope from 'acceligent-shared/enums/unavailabilityReasonScope';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { UnavailableEquipmentStatusViewModel } from 'ab-viewModels/equipmentStatus.viewModel';
import { UnavailabilityReasonViewModel } from 'ab-viewModels/unavailabilityReason.viewModel';

import Textarea from 'af-fields/Textarea';
import Dropdown from 'af-fields/Dropdown';
import DateInput from 'af-fields/DateInput';
import AsyncSelect from 'af-fields/AsyncSelect';

import { EQUIPMENT_DOWN_FORM } from 'af-constants/reduxForms';
import { ASYNC_SELECT_DROPDOWN_WIDTH } from 'af-constants/values';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';
import PriorityLabel from 'af-components/PriorityLabel';
import StatusLabel from 'af-components/StatusLabel';

import * as UnavailabilityActions from 'af-actions/unavailabilityReason';
import * as EquipmentActions from 'af-actions/equipment';

import { RootState } from 'af-reducers';

import { validate, warn } from './validation';
import DownEquipmentFM from './formModel';

export const EquipmentDownForm = DownEquipmentFM;

const NOTES_MAX_LENGTH = 500;

type UnavailabilityOption = {
	name: string;
	value: number;
};

type FormProps = InjectedFormProps<DownEquipmentFM, FormOwnProps>;

interface OwnProps {
	initialFormValues: Nullable<FormProps['initialValues']>;
	onClose: () => void;
	onSubmit: (form: DownEquipmentFM) => void;
	showModal: boolean;
	showStatusOption?: boolean;
	useTodayDate?: boolean;
}

interface StateProps {
	isEdit: boolean;
	date: string;
	isAutomaticReturnDate: boolean;
	formWarnings: FormErrors;
}

interface DispatchProps {
	findAllUnavailableEquipmentStatuses: typeof EquipmentActions.findAllUnavailableStatuses;
	findUnavailabilityReasons: typeof UnavailabilityActions.findUnavailabilityReasons;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & FormProps;

interface State {
	unavailableStatuses: UnavailableEquipmentStatusViewModel[];
}

class EquipmentDownModal extends React.PureComponent<Props, State> {

	static readonly AUTOMATIC_RETURN_DATE_TITLE = 'Automatic Return Date';
	static readonly EXPECTED_RETURN_DATE_TITLE = 'Expected Return Date';
	static readonly AUTOMATIC_RETURN_DATE_TOOLTIP_MESSAGE = 'Equipment will become available on this date';
	static readonly EXPECTED_RETURN_DATE_TOOLTIP_MESSAGE = 'Equipment may become available on this date';

	static defaultProps: Partial<Props> = {
		useTodayDate: false,
	};

	static readonly MENU_STYLE: React.CSSProperties = {
		width: ASYNC_SELECT_DROPDOWN_WIDTH,
	};

	state: State = {
		unavailableStatuses: [],
	};

	static unavailabilityToOptionMapper = (reason: UnavailabilityReasonViewModel) => ({ name: reason.name, value: reason.id } as UnavailabilityOption);

	static formatOptionLabel = (option: UnavailabilityOption) => option.name;

	static renderPriorityItem = (option: PriorityItem) => <PriorityLabel priority={option.id} />;

	static renderStatusMenuItem = (selectedItem: UnavailableEquipmentStatusViewModel) => selectedItem
		? <StatusLabel isAvailable={false} label={selectedItem.name} />
		: null;

	async componentDidUpdate(prevProps: Props) {
		const { initialFormValues, isEdit, formWarnings, destroy, initialize, touch } = this.props;

		// modal closed
		if (prevProps.showModal && !this.props.showModal) {
			destroy();
		}

		// modal opened
		if (!prevProps.showModal && this.props.showModal) {
			initialize(initialFormValues ?? { priority: Priority.MEDIUM } as DownEquipmentFM);
			await this.loadUnavailableStatuses();
			if (!isEdit) {
				touch('returnDate');
			}
		}

		if (initialFormValues && formWarnings && 'returnDate' in formWarnings) {
			touch('returnDate');
		}
	}

	loadUnavailableStatuses = async () => {
		const { findAllUnavailableEquipmentStatuses } = this.props;

		const statuses = await findAllUnavailableEquipmentStatuses();

		this.setState(() => ({ unavailableStatuses: statuses }));
	};

	onSubmit = (form: DownEquipmentFM) => {
		const { onSubmit } = this.props;
		onSubmit(form);
	};

	onSearchReason = async (query: string) => {
		const { findUnavailabilityReasons } = this.props;
		const options = await findUnavailabilityReasons(query, UnavailabilityReasonScope.EQUIPMENT);
		return options.map(EquipmentDownModal.unavailabilityToOptionMapper);
	};

	onClearReason = () => {
		const { change } = this.props;
		change('unavailabilityReason', { name: '', value: null });
	};

	onNewReason = (name: string) => {
		const { change } = this.props;

		if (!name) {
			return;
		}

		change('unavailabilityReason', { name, value: null });
	};

	render() {
		const { unavailableStatuses } = this.state;
		const {
			submitting,
			showModal,
			handleSubmit,
			onClose,
			showStatusOption,
			isEdit,
			invalid,
			isAutomaticReturnDate,
		} = this.props;

		return (
			<CustomModal
				closeModal={onClose}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={onClose}
					title="Set Equipment as Unavailable"
				/>
				<CustomModal.Body padding="none">
					{showStatusOption
						? (
							<Row className="row--padded-top">
								<Col md={12}>
									<Field
										component={Dropdown}
										fixed={true}
										id="status"
										label="Failure status *"
										name="unavailableStatusId"
										options={unavailableStatuses}
										renderMenuItem={EquipmentDownModal.renderStatusMenuItem}
										valueKey="id"
										withCaret={true}
									/>
								</Col>
							</Row>
						)
						: <></>
					}
					<Row className={showStatusOption ? '' : 'row--padded-top'}>
						<Col md={24}>
							<Field
								component={Textarea}
								label="Failure details *"
								maxCharacters={NOTES_MAX_LENGTH}
								name="note"
								placeholder="Enter description"
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Field
								component={Dropdown}
								id="priority"
								label="Priority *"
								name="priority"
								options={PriorityList}
								renderMenuItem={EquipmentDownModal.renderPriorityItem}
								valueKey="id"
								withCaret={true}
							/>
						</Col>
						<Col md={12}>
							<Field
								component={DateInput}
								dateFormat={TimeFormatEnum.DATE_ONLY}
								fixed={true}
								id="returnDate"
								isClearable={true}
								label={isAutomaticReturnDate
									? EquipmentDownModal.AUTOMATIC_RETURN_DATE_TITLE
									: EquipmentDownModal.EXPECTED_RETURN_DATE_TITLE
								}
								name="returnDate"
								originalDateFormat={TimeFormatEnum.DATE_ONLY}
								tooltipMessage={isAutomaticReturnDate
									? EquipmentDownModal.AUTOMATIC_RETURN_DATE_TOOLTIP_MESSAGE
									: EquipmentDownModal.EXPECTED_RETURN_DATE_TOOLTIP_MESSAGE
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={24}>
							<Field
								allowNew={true}
								component={AsyncSelect}
								fixed={true}
								formatOptionLabel={EquipmentDownModal.formatOptionLabel}
								id="unavailabilityReason"
								label="Unavailability Reason"
								menuStyle={EquipmentDownModal.MENU_STYLE}
								name="unavailabilityReason"
								onClear={this.onClearReason}
								onCreateNew={this.onNewReason}
								onSearch={this.onSearchReason}
								placeholder="None"
							/>
						</Col>
					</Row>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button onClick={onClose} variant="info">Cancel</Button>
					<SubmitButton
						disabled={invalid}
						label={isEdit ? 'Save' : 'Set as Unavailable'}
						onClick={handleSubmit(this.onSubmit)}
						reduxFormSubmitting={submitting}
						submitKey={EQUIPMENT_DOWN_FORM}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState, props: OwnProps): StateProps {
	const { initialFormValues } = props;
	const { company } = state.company;

	return {
		isEdit: !!initialFormValues?.id,
		date: props.useTodayDate
			? TimeUtils.getTodaysDate(TimeFormatEnum.DATE_ONLY)
			: state.scheduleBoard.date!,
		isAutomaticReturnDate: company?.isEquipmentAutomaticReturnDate ?? false,
		formWarnings: getFormSyncWarnings(EQUIPMENT_DOWN_FORM)(state),
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findUnavailabilityReasons: UnavailabilityActions.findUnavailabilityReasons,
		findAllUnavailableEquipmentStatuses: EquipmentActions.findAllUnavailableStatuses,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<DownEquipmentFM, FormOwnProps>({
		form: EQUIPMENT_DOWN_FORM,
		validate,
		warn,
	})
);

export default enhance(EquipmentDownModal) as React.ComponentClass<OwnProps>;
