import * as React from 'react';
import { Button } from 'react-bootstrap';

import { bemElement } from 'ab-utils/bem.util';

import { DeviceType } from '../ReportConfirmation/helpers';
import PrintButton from './PrintButton';

interface OwnProps {
	allowCustomerSignature: boolean;
	areReportsLoaded: boolean;
	areAllReportsSigned: boolean;
	isWebview: boolean;
	webViewLink: string;
	onAllReportSigning: () => void;
	printReport: () => Promise<void>;
	printAllReports: () => Promise<void>;
	deviceType: DeviceType;
	reportsRefs: React.MutableRefObject<HTMLDivElement[]>;
	currentReportIndex: number;
	noOfReports: number;
	noOfSignedReports: Nullable<number>;
}

type Props = OwnProps;

const FieldReportsPublicReportFooter: React.FC<Props> = (props: Props) => {
	const {
		allowCustomerSignature,
		areReportsLoaded,
		areAllReportsSigned,
		isWebview,
		webViewLink,
		onAllReportSigning,
		printReport,
		printAllReports,
		deviceType,
		currentReportIndex,
		reportsRefs,
		noOfReports,
		noOfSignedReports,
	} = props;

	const [isPrintingReport, setIsPrintingReport] = React.useState(false);
	const [isPrintingAllReports, setIsPrintingAllReports] = React.useState(false);

	const isPrintDisabled = !areReportsLoaded || isPrintingReport;
	const isPrintingAllReportsDisabled = !areReportsLoaded || isPrintingAllReports;

	const onPrintClick = React.useCallback(async () => {
		if (isPrintDisabled) {
			return;
		}
		setIsPrintingReport(true);
		await printReport();
		setIsPrintingReport(false);
	}, [printReport, isPrintDisabled]);

	const onPrintAllReportsClick = React.useCallback(async () => {
		if (isPrintingAllReportsDisabled) {
			return;
		}
		setIsPrintingAllReports(true);
		await printAllReports();
		setIsPrintingAllReports(false);
	}, [isPrintingAllReportsDisabled, printAllReports]);

	const onPreviousClick = React.useCallback(() => {
		reportsRefs.current[currentReportIndex - 1].scrollIntoView();
	}, [currentReportIndex, reportsRefs]);

	const onNextClick = React.useCallback(() => {
		reportsRefs.current[currentReportIndex + 1].scrollIntoView();
	}, [currentReportIndex, reportsRefs]);

	const isSignDisabled = !areReportsLoaded || !allowCustomerSignature;
	const isMobileOrTablet = (deviceType === DeviceType.MOBILE || deviceType === DeviceType.TABLET);

	const isPreviousDisabled = (currentReportIndex <= 0);
	const isNextDisabled = (currentReportIndex >= reportsRefs.current?.length - 1);
	const previousButtonValue = isMobileOrTablet ? <span className="icon-left" /> : 'Previous Report';
	const nextButtonValue = isMobileOrTablet ? <span className="icon-right" /> : 'Next Report';

	const hasMultipleReports = noOfReports > 1;

	const reportsSignedLabel = hasMultipleReports ? 'Reports Signed' : 'Report Signed';
	const primarySignButtonName = hasMultipleReports ? 'Sign All Reports' : 'Sign Report';

	const footerFlexDirection = hasMultipleReports ? 'column' : 'row';

	const showCloseButton = (!isWebview && noOfReports === 1) || !isMobileOrTablet;
	const showReportsSignedLabel = areAllReportsSigned && !isSignDisabled;
	const showPrintSingleButton = !isMobileOrTablet || isWebview;
	const showPrintAllButton = hasMultipleReports && showPrintSingleButton;

	return (
		<div className="public-report__footer" style={{ flexDirection: footerFlexDirection }}>
			{showCloseButton &&
				(<div className="public-report__footer__close">
					<Button onClick={window.close} variant="info">Close</Button>
				</div>)
			}
			{hasMultipleReports && (
				<div className="public-report__footer__previous-next">
					<div
						className={`btn btn-info ${isPreviousDisabled ? 'disabled' : ''}`}
						onClick={onPreviousClick}
					>
						{previousButtonValue}
					</div>
					{isMobileOrTablet && (
						<div className="public-report__footer__previous-next__reports-status">
							<div className={`public-report__footer__previous-next__reports-status__signed ${areAllReportsSigned ? 'text-green' : ''}`}>
								Signed <b>{noOfSignedReports}</b> of <b>{noOfReports}</b>
							</div>
							<div className="public-report__footer__previous-next__reports-status__position">
								Report <b>{currentReportIndex + 1}</b> of <b>{noOfReports}</b>
							</div>
						</div>
					)}

					<div
						className={`btn btn-info ${isNextDisabled ? 'disabled' : ''}`}
						onClick={onNextClick}
					>
						{nextButtonValue}
					</div>
				</div>
			)}
			<div className={bemElement('public-report__footer', 'print-sign', { 'web-view': isWebview && hasMultipleReports })}>
				{showPrintSingleButton &&
					<PrintButton
						handlePrintClick={onPrintClick}
						isPrintDisabled={isPrintDisabled}
						isWebView={isWebview}
						name="Print"
						webViewLink={webViewLink}
					/>
				}
				{showPrintAllButton &&
					<PrintButton
						handlePrintClick={onPrintAllReportsClick}
						isPrintDisabled={isPrintingAllReportsDisabled}
						isWebView={isWebview}
						name="Print All Reports"
					/>
				}
				<div className="public-report__footer__print-sign__signings">
					{showReportsSignedLabel &&
						<div className="public-report__footer__print-sign__label text-green"><span className="icon-check" />
							{reportsSignedLabel}
						</div>
					}

					{!areAllReportsSigned && !isSignDisabled &&
						<Button className="public-report__footer__print-sign__signings__sign-all" disabled={isSignDisabled} onClick={onAllReportSigning} variant="primary">
							{primarySignButtonName}
						</Button>
					}
				</div>
			</div>
		</div>
	);
};

export default React.memo(FieldReportsPublicReportFooter);
