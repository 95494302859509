import OperationType, { CalculationContext, CalculationTypeResultFormat } from 'acceligent-shared/enums/operation';

import { FieldValuesServiceModel, OperationServiceModel } from 'ab-serviceModels/calculatedField.serviceModel';

import { isValidNumber } from 'ab-utils/validation.util';

// Basic calculation methods

const _isValueEmpty = (value: Nullable<string>) => !Boolean(value) || value === 'false';

const _sum = (current: Nullable<number> = 0, value: Nullable<string>) => {
	if (!isValidNumber(current)) {
		return isValidNumber(value) ? Number(value) : null;
	}
	if (!isValidNumber(value)) {
		return current;
	}
	return current! + Number(value);
};

const _subtract = (current: Nullable<number> = 0, value: Nullable<string>) => {
	if (!isValidNumber(current)) {
		return isValidNumber(value) ? -Number(value) : null;
	}
	if (!isValidNumber(value)) {
		return current;
	}
	return current! - Number(value);
};

const _multiply = (current: Nullable<number> = 1, value: Nullable<string>) => {
	if (!isValidNumber(current)) {
		return isValidNumber(value) ? Number(value) : null;
	}
	if (!isValidNumber(value)) {
		return current;
	}
	return current! * Number(value); // If not a number, consider it 0
};

const _divide = (current: Nullable<number> = 1, value: Nullable<string>) => {
	if (!isValidNumber(current)) {
		return isValidNumber(value) ? Number(value) : null;
	}
	if (!isValidNumber(value)) {
		return current;
	}
	return current! / Number(value); // If not a number, consider it 0
};

const _countFilled = (current: Nullable<number> = 0, value: Nullable<string>) => {
	return _isValueEmpty(value) ? current : (current ?? 0) + 1;
};

const _countEmpty = (current: Nullable<number> = 0, value: Nullable<string>) => {
	return _isValueEmpty(value) ? (current ?? 0) + 1 : current;
};

export const BASIC_CALCULATION_MAP: { [T in keyof typeof OperationType]: (current: Nullable<number>, value: Nullable<string>) => Nullable<number>; } = {
	[OperationType.SUM]: (current: Nullable<number>, value: Nullable<string>) => _sum(current, value),
	[OperationType.SUBTRACT]: (current: Nullable<number>, value: Nullable<string>) => _subtract(current, value),
	[OperationType.MULTIPLY]: (current: Nullable<number>, value: Nullable<string>) => _multiply(current, value),
	[OperationType.DIVIDE]: (current: Nullable<number>, value: Nullable<string>) => _divide(current, value),
	[OperationType.COUNT]: (current: Nullable<number>, value: Nullable<string>) => _countFilled(current, value),
	[OperationType.COUNT_FILLED]: (current: Nullable<number>, value: Nullable<string>) => _countFilled(current, value),
	[OperationType.COUNT_EMPTY]: (current: Nullable<number>, value: Nullable<string>) => _countEmpty(current, value),
};

// Calculation on fields methods

const _handleRepeating = (
	values: Nullable<string>[],
	operationType: OperationType
): Nullable<number>[] => {

	// Perform calculations on repeatable fields, and store non repeatable into temp array
	// So we know how many repeatable fields there are
	const {
		result,
		nonRepeatingFields,
	} = values.reduce<{ result: Nullable<number>[]; nonRepeatingFields: Nullable<string>[]; }>((_acc, _value) => {
		const parsed: string[] | string = JSON.parse(_value ?? '[]');
		if (Array.isArray(parsed)) {
			_acc.result = CALCULATED_REPEATABLE_FIELD_VALUE_MAP[operationType](parsed, _acc.result);
		} else {
			_acc.nonRepeatingFields.push(parsed);
		}
		return _acc;
	}, { result: [], nonRepeatingFields: [] });

	// After calculations have been performed on repeatable fields and we know how many repeatable fields there are
	// Perform calculations with non-repeating fields
	return nonRepeatingFields.reduce<Nullable<number>[]>((_acc, _field) => {
		for (let _index = 0; _index < _acc.length; _index++) {
			_acc[_index] = BASIC_CALCULATION_MAP[operationType](_acc[_index], _field);
		}
		return _acc;
	}, result);
};

const _sumFieldValues = (isRepeating: boolean, values: Nullable<string>[]): Nullable<string> => {
	if (isRepeating) {
		const result = _handleRepeating(values, OperationType.SUM);
		return JSON.stringify(result.map((_result) => _result !== null ? CalculationTypeResultFormat[OperationType.SUM](_result, {}) : null));
	}
	// We're sure result won't be undefined because we're providing it with an initial value of 0
	const [initialValue, ...operands] = values;
	const result = operands.reduce(_sum, Number(initialValue))!;
	return CalculationTypeResultFormat[OperationType.SUM](result, {});
};

const _subtractFieldValues = (isRepeating: boolean, values: Nullable<string>[]): Nullable<string> => {
	if (isRepeating) {
		const result = _handleRepeating(values, OperationType.SUBTRACT);
		return JSON.stringify(result.map((_result) => _result !== null ? CalculationTypeResultFormat[OperationType.SUBTRACT](_result, {}) : null));
	}
	// We're sure result won't be undefined because we're providing it with an initial value of 0
	const [initial, ...operands] = values;
	const result = operands.reduce(_subtract, Number(initial))!;
	return CalculationTypeResultFormat[OperationType.SUM](result, {});
};

const _multiplyFieldValues = (isRepeating: boolean, values: Nullable<string>[]): Nullable<string> => {
	if (isRepeating) {
		const result = _handleRepeating(values, OperationType.MULTIPLY);
		return JSON.stringify(result.map((_result) => _result !== null ? CalculationTypeResultFormat[OperationType.MULTIPLY](_result, {}) : null));
	}
	// We're sure result won't be undefined because we're providing it with an initial value of 0
	const [initialValue, ...operands] = values;
	const result = operands.reduce(_multiply, Number(initialValue))!;
	return CalculationTypeResultFormat[OperationType.SUM](result, {});
};

const _divideFieldValues = (isRepeating: boolean, values: Nullable<string>[]): Nullable<string> => {
	if (isRepeating) {
		const result = _handleRepeating(values, OperationType.DIVIDE);
		return JSON.stringify(result.map((_result) => _result !== null ? CalculationTypeResultFormat[OperationType.DIVIDE](_result, {}) : null));
	}
	// We're sure result won't be undefined because we're providing it with an initial value of 0
	const [initialValue, ...operands] = values;
	const result = operands.reduce(_divide, Number(initialValue))!;
	return CalculationTypeResultFormat[OperationType.SUM](result, {});
};

const _countFieldValues = (isRepeating: boolean, values: Nullable<string>[]): Nullable<string> => {
	if (isRepeating) {
		const result = _handleRepeating(values, OperationType.COUNT);
		return JSON.stringify(result.map(
			(_result) => _result !== null ? CalculationTypeResultFormat[OperationType.COUNT](_result, { operandCount: values.length }) : null)
		);
	}
	// We're sure result won't be undefined because we're providing it with an initial value of 0
	const result = values.reduce(_countFilled, 0)!;
	return CalculationTypeResultFormat[OperationType.COUNT](result, { operandCount: values.length });
};

const _countFieldFilledValues = (isRepeating: boolean, values: Nullable<string>[]): Nullable<string> => {
	if (isRepeating) {
		const result = _handleRepeating(values, OperationType.COUNT_FILLED);
		return JSON.stringify(result.map(
			(_result) => _result !== null ? CalculationTypeResultFormat[OperationType.COUNT_FILLED](_result, { operandCount: values.length }) : null)
		);
	}
	// We're sure result won't be undefined because we're providing it with an initial value of 0
	const result = values.reduce(_countFilled, 0)!;
	return CalculationTypeResultFormat[OperationType.COUNT_FILLED](result, { operandCount: values.length });
};

const _countFieldEmptyValues = (isRepeating: boolean, values: Nullable<string>[]): Nullable<string> => {
	if (isRepeating) {
		const result = _handleRepeating(values, OperationType.COUNT_EMPTY);
		return JSON.stringify(result.map(
			(_result) => _result !== null ? CalculationTypeResultFormat[OperationType.COUNT_EMPTY](_result, { operandCount: values.length }) : null)
		);
	}
	// We're sure result won't be undefined because we're providing it with an initial value of 0
	const result = values.reduce(_countEmpty, 0)!;
	return CalculationTypeResultFormat[OperationType.COUNT_EMPTY](result, { operandCount: values.length });
};

const CALCULATED_FIELD_VALUE_MAP: { [T in keyof typeof OperationType]: (isRepeating: boolean, values: Nullable<string[]>) => Nullable<string> } = {
	[OperationType.SUM]: (isRepeating: boolean, values: string[]) => _sumFieldValues(isRepeating, values),
	[OperationType.SUBTRACT]: (isRepeating: boolean, values: string[]) => _subtractFieldValues(isRepeating, values),
	[OperationType.MULTIPLY]: (isRepeating: boolean, values: string[]) => _multiplyFieldValues(isRepeating, values),
	[OperationType.DIVIDE]: (isRepeating: boolean, values: string[]) => _divideFieldValues(isRepeating, values),
	[OperationType.COUNT]: (isRepeating: boolean, values: string[]) => _countFieldValues(isRepeating, values),
	[OperationType.COUNT_FILLED]: (isRepeating: boolean, values: string[]) => _countFieldFilledValues(isRepeating, values),
	[OperationType.COUNT_EMPTY]: (isRepeating: boolean, values: string[]) => _countFieldEmptyValues(isRepeating, values),
};

// Calculated block methods

const _sumBlockValues = (values: FieldValuesServiceModel[]) => {
	const result = values.reduce<Nullable<number>>((_acc, _field, _index) => {
		const { f1: value, f2: isRepeating } = _field;
		if (isRepeating) {
			const _value = JSON.parse(value ?? '[]');
			let initialValue = _acc;
			let operands = _value;
			// Case is first value, initialize accumulator to first operand value
			if (_index === 0) {
				const [_initialValue, ..._operands] = _value;
				initialValue = _initialValue;
				operands = _operands;
			}
			_acc = operands.reduce(_sum, isValidNumber(initialValue) ? Number(initialValue) : null);
		} else {
			// Case is first value, initialize accumulator to first operand value
			if (_index === 0) {
				_acc = isValidNumber(value) ? Number(value) : null;
			} else {
				_acc = _sum(_acc, value);
			}
		}
		return _acc;
	}, null);
	return result !== null ? CalculationTypeResultFormat[OperationType.SUM](result, {}) : null;
};

const __subtractBlockValues = (values: FieldValuesServiceModel[]) => {
	const result = values.reduce<Nullable<number>>((_acc, _field, _index) => {
		const { f1: value, f2: isRepeating } = _field;
		if (isRepeating) {
			const _value = JSON.parse(value ?? '[]');
			let initialValue = _acc;
			let operands = _value;
			// Case is first value, initialize accumulator to first operand value
			if (_index === 0) {
				const [_initialValue, ..._operands] = _value;
				initialValue = _initialValue;
				operands = _operands;
			}
			_acc = operands.reduce(_subtract, isValidNumber(initialValue) ? Number(initialValue) : null);
		} else {
			// Case is first value, initialize accumulator to first operand value
			if (_index === 0) {
				_acc = isValidNumber(value) ? Number(value) : null;
			} else {
				_acc = _subtract(_acc, value);
			}
		}
		return _acc;
	}, null);
	return result !== null ? CalculationTypeResultFormat[OperationType.SUBTRACT](result, {}) : null;
};

const _multiplyBlockValues = (values: FieldValuesServiceModel[]) => {
	const result = values.reduce<Nullable<number>>((_acc, _field, _index) => {
		const { f1: value, f2: isRepeating } = _field;
		if (isRepeating) {
			const _value = JSON.parse(value ?? '[]');
			let initialValue = _acc;
			let operands = _value;
			// Case is first value, initialize accumulator to first operand value
			if (_index === 0) {
				const [_initialValue, ..._operands] = _value;
				initialValue = _initialValue;
				operands = _operands;
			}
			_acc = operands.reduce(_multiply, isValidNumber(initialValue) ? Number(initialValue) : null);
		} else {
			// Case is first value, initialize accumulator to first operand value
			if (_index === 0) {
				_acc = isValidNumber(value) ? Number(value) : null;
			} else {
				_acc = _multiply(_acc, value);
			}
		}
		return _acc;
	}, null);
	return result !== null ? CalculationTypeResultFormat[OperationType.MULTIPLY](result, {}) : null;
};

const _divideBlockValues = (values: FieldValuesServiceModel[]) => {
	const result = values.reduce<Nullable<number>>((_acc, _field, _index) => {
		const { f1: value, f2: isRepeating } = _field;
		if (isRepeating) {
			const _value = JSON.parse(value ?? '[]');
			let initialValue = _acc;
			let operands = _value;
			// Case is first value, initialize accumulator to first operand value
			if (_index === 0) {
				const [_initialValue, ..._operands] = _value;
				initialValue = _initialValue;
				operands = _operands;
			}
			_acc = operands.reduce(_divide, isValidNumber(initialValue) ? Number(initialValue) : null);
		} else {
			// Case is first value, initialize accumulator to first operand value
			if (_index === 0) {
				_acc = isValidNumber(value) ? Number(value) : null;
			} else {
				_acc = _divide(_acc, value);
			}
		}
		return _acc;
	}, null);
	return result !== null ? CalculationTypeResultFormat[OperationType.DIVIDE](result, {}) : null;
};

const _countBlockValues = (values: FieldValuesServiceModel[]) => {
	const {
		count,
		operandCount,
	} = values.reduce<{ operandCount: number; count: Nullable<number>; }>((_acc, _fieldValue) => {
		const { f1: fieldValue, f2: isRepeating } = _fieldValue;
		if (isRepeating) {
			const _value = JSON.parse(fieldValue ?? '[]');
			_acc.count = _value.reduce(_countFilled, _acc.count);
			_acc.operandCount += _value.length;
		} else {
			_acc.count = _countFilled(_acc.count, fieldValue);
			_acc.operandCount += 1;
		}
		return _acc;
	}, { operandCount: 0, count: null });
	return count !== null ? CalculationTypeResultFormat[OperationType.COUNT](count, { operandCount }) : null;
};

const _countBlockFilledValues = (values: FieldValuesServiceModel[]) => {
	const {
		count,
		operandCount,
	} = values.reduce<{ operandCount: number; count: Nullable<number>; }>((_acc, _fieldValue) => {
		const { f1: fieldValue, f2: isRepeating } = _fieldValue;
		if (isRepeating) {
			const _value = JSON.parse(fieldValue ?? '[]');
			_acc.count = _value.reduce(_countFilled, _acc.count);
			_acc.operandCount += _value.length;
		} else {
			_acc.count = _countFilled(_acc.count, fieldValue);
			_acc.operandCount += 1;
		}
		return _acc;
	}, { operandCount: 0, count: null });
	return count !== null ? CalculationTypeResultFormat[OperationType.COUNT_FILLED](count, { operandCount }) : null;
};

const _countBlockEmptyValues = (values: FieldValuesServiceModel[]) => {
	const {
		count,
		operandCount,
	} = values.reduce<{ operandCount: number; count: Nullable<number>; }>((_acc, _fieldValue) => {
		const { f1: fieldValue, f2: isRepeating } = _fieldValue;
		if (isRepeating) {
			const _value = JSON.parse(fieldValue ?? '[]');
			_acc.count += _value.reduce(_countEmpty, 0);
			_acc.operandCount += _value.length;
		} else {
			_acc.count = _countEmpty(_acc.count, fieldValue);
			_acc.operandCount += 1;
		}
		return _acc;
	}, { operandCount: 0, count: null });
	return count !== null ? CalculationTypeResultFormat[OperationType.COUNT_EMPTY](count, { operandCount }) : null;
};

const CALCULATED_BLOCK_VALUE_MAP: { [T in keyof typeof OperationType]: (values: FieldValuesServiceModel[]) => Nullable<string> } = {
	[OperationType.SUM]: (values: FieldValuesServiceModel[]) => _sumBlockValues(values),
	[OperationType.SUBTRACT]: (values: FieldValuesServiceModel[]) => __subtractBlockValues(values),
	[OperationType.MULTIPLY]: (values: FieldValuesServiceModel[]) => _multiplyBlockValues(values),
	[OperationType.DIVIDE]: (values: FieldValuesServiceModel[]) => _divideBlockValues(values),
	[OperationType.COUNT]: (values: FieldValuesServiceModel[]) => _countBlockValues(values),
	[OperationType.COUNT_FILLED]: (values: FieldValuesServiceModel[]) => _countBlockFilledValues(values),
	[OperationType.COUNT_EMPTY]: (values: FieldValuesServiceModel[]) => _countBlockEmptyValues(values),
};

// Repeatable field methods

const _handleRepeatableMathCalculations = (values: Nullable<string>[], result: Nullable<number>[], operation: OperationType) => {
	for (let _index = 0; _index < values.length; _index++) {
		if (!result[_index] && result[_index] !== 0) {
			result[_index] = isValidNumber(values[_index]) ? Number(values[_index]) : null;
		} else {
			result[_index] = BASIC_CALCULATION_MAP[operation](result[_index], values[_index]);
		}
	}
	return result;
};

const _handleRepeatableCountCalculations = (values: Nullable<string>[], result: Nullable<number>[], operation: OperationType) => {
	for (let _index = 0; _index < values.length; _index++) {
		result[_index] = BASIC_CALCULATION_MAP[operation](result[_index], values[_index]);
	}
	return result;
};

const CALCULATED_REPEATABLE_FIELD_VALUE_MAP: {
	[T in keyof typeof OperationType]: (values: Nullable<string[]>, result: Nullable<number>[]) => Nullable<number>[]
} = {
	[OperationType.SUM]: (values: string[], result: number[]) => _handleRepeatableMathCalculations(values, result, OperationType.SUM),
	[OperationType.SUBTRACT]: (values: string[], result: number[]) => _handleRepeatableMathCalculations(values, result, OperationType.SUBTRACT),
	[OperationType.MULTIPLY]: (values: string[], result: number[]) => _handleRepeatableMathCalculations(values, result, OperationType.MULTIPLY),
	[OperationType.DIVIDE]: (values: string[], result: number[]) => _handleRepeatableMathCalculations(values, result, OperationType.DIVIDE),
	[OperationType.COUNT]: (values: string[], result: number[]) => _handleRepeatableCountCalculations(values, result, OperationType.COUNT),
	[OperationType.COUNT_FILLED]: (values: string[], result: number[]) => _handleRepeatableCountCalculations(values, result, OperationType.COUNT_FILLED),
	[OperationType.COUNT_EMPTY]: (values: string[], result: number[]) => _handleRepeatableCountCalculations(values, result, OperationType.COUNT_EMPTY),
};

// Calculated field calculation methods

export const formatCalculationValue = (value: number, operation: OperationType, isRepeating: boolean, context: CalculationContext) => {
	const result = CalculationTypeResultFormat[operation]?.(value, context) ?? '';
	return isRepeating ? JSON.stringify([result]) : result;
};

export const getCalculatedFieldValues = (lookup: OperationServiceModel, values: string[]) => {
	return {
		id: lookup.fieldReportBlockFieldId,
		isRepeating: lookup.isRepeating,
		unit: lookup.unit,
		valueType: lookup.valueType,
		fieldType: lookup.fieldType,
		value: CALCULATED_FIELD_VALUE_MAP[lookup.operationType]?.(lookup.isRepeating, values) ?? null,
	};
};

export const getCalculatedBlockValues = (lookup: OperationServiceModel, values: FieldValuesServiceModel[]) => {
	return {
		id: lookup.fieldReportBlockFieldId,
		isRepeating: lookup.isRepeating,
		unit: lookup.unit,
		valueType: lookup.valueType,
		fieldType: lookup.fieldType,
		value: CALCULATED_BLOCK_VALUE_MAP[lookup.operationType]?.(values) ?? null,
	};
};

export const calculateValue = (operation: OperationType, values: Nullable<string[]>) => {
	return CALCULATED_FIELD_VALUE_MAP[operation]?.(false, values) ?? null;
};
