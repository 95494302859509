import * as React from 'react';

interface OwnProps {
	index: number;
}

type Props = OwnProps;

const BlankCard: React.FC<Props> = (props: Props) => {
	const { index } = props;

	return (
		<div className="display-view-work-order-wrapper-width-1">
			<div className="display-view-card-index">
				{index + 1}
			</div>
		</div>
	);
};

export default React.memo(BlankCard);
