import { ColorPalette } from 'acceligent-shared/enums/color';
import EquipmentCostCategoryEnum from 'acceligent-shared/enums/equipmentCostCategory';
import ResourceStatus from 'acceligent-shared/enums/resourceStatus';
import TimePeriodSpan from 'acceligent-shared/enums/timePeriodSpan';

import EquipmentCost from 'acceligent-shared/models/equipmentCost';
import EquipmentCostCategory from 'acceligent-shared/models/equipmentCostCategory';

import { EquipmentUtilizationServiceModel } from 'ab-serviceModels/equipment.serviceModel';

import { moneyNormalizer } from 'ab-utils/formatting.util';

const CSV_HEADER_EQUIPMENT_GROUP_BASE_KEYS = [
	'Year',
	'Scheduled',
	'Not Scheduled',
	'Not Available',
	'Daily Revenue',
	'Daily Cost',
	'Target Profit/Loss',
];

const CSV_HEADER_EQUIPMENT_KEYS = [
	'Equipment ID',
	'Year',
	'Scheduled',
	'Not Scheduled',
	'Not Available',
	'Daily Revenue',
	'Total Revenue',
	'Daily Cost',
	'Total Cost',
	'Target Profit/Loss',
];

const EQUIPMENT_COST_CATEGORY_TYPES: EquipmentCostCategoryEnum[] = Object.values(EquipmentCostCategoryEnum);

/**
 * Mocks EquipmentCostCategoryEnum elements as if they are EquipmentCostCategory root entities
 */
interface EquipmentCostCategoryType {
	id: EquipmentCostCategoryEnum;
	status: ResourceStatus.ACTIVE;
}

export interface EquipmentUtilizationViewModelShared {
	id: number | EquipmentCostCategoryEnum;
	groupingId: number | EquipmentCostCategoryEnum | null;
	status: ResourceStatus;

	timePeriod: TimePeriodSpan;
	timePeriodId: number | string;

	/** does NOT exist for `EquipmentUtilizationViewModel` */
	equipmentCount?: number;

	/** does NOT exist for `EquipmentUtilizationViewModel` */
	daysAssignedSum?: number;
	/** does NOT exist for `EquipmentUtilizationViewModel` */
	daysAvailableSum?: number;
	/** does NOT exist for `EquipmentUtilizationViewModel` */
	daysUnavailableSum?: number;
	/** does NOT exist for `EquipmentUtilizationViewModel` */
	totalDaysSum?: number;

	/** value from query for `EquipmentUtilizationViewModel`, rounded average by `equipmentCount` otherwise, equals sum if no `equipmentCount` */
	daysAssigned: number;
	/** value from query for `EquipmentUtilizationViewModel`, rounded average by `equipmentCount` otherwise, equals sum if no `equipmentCount` */
	daysAvailable: number;
	/** value from query for `EquipmentUtilizationViewModel`, rounded average by `equipmentCount` otherwise, equals sum if no `equipmentCount` */
	daysUnavailable: number;
	/** shorthand for `daysAssigned + daysAvailable + daysUnavailable` */
	totalDays: number;

	/** `null` if `totalDays === 0`, average `totalRevenue` by `totalDays` */
	dailyRevenue: Nullable<number>;
	/** value from query for `EquipmentUtilizationViewModel`, sum of equipments' `totalRevenue` otherwise */
	totalRevenue: number;
	/** `null` if `totalDays === 0`, average `totalCost` by `totalDays` */
	dailyCost: Nullable<number>;
	/** value from query for `EquipmentUtilizationViewModel`, sum of equipments' `totalCost` otherwise */
	totalCost: number;
	/** difference between `totalRevenue` and `totalCost` */
	targetProfit: number;
}

export abstract class EquipmentUtilizationGroupViewModelBase implements EquipmentUtilizationViewModelShared {
	id: number | EquipmentCostCategoryEnum;
	groupingId: number | EquipmentCostCategoryEnum | null;
	status: ResourceStatus;

	name: string;

	timePeriod: TimePeriodSpan;
	timePeriodId: number | string;

	equipmentCount: number;

	daysAssignedSum: number;
	daysAvailableSum: number;
	daysUnavailableSum: number;
	totalDaysSum: number;

	daysAssigned: number;
	daysAvailable: number;
	daysUnavailable: number;
	totalDays: number;

	dailyRevenue: Nullable<number>;
	totalRevenue: number;
	dailyCost: Nullable<number>;
	totalCost: number;
	targetProfit: number;

	constructor(
		group: EquipmentCost | EquipmentCostCategory | EquipmentCostCategoryType,
		items: EquipmentUtilizationViewModelShared[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		this.id = group.id;
		this.status = group.status;

		this.timePeriod = timePeriod;
		this.timePeriodId = timePeriodId;

		this.equipmentCount = 0;

		this.daysAssignedSum = 0;
		this.daysAvailableSum = 0;
		this.daysUnavailableSum = 0;

		this.totalRevenue = 0;
		this.totalCost = 0;

		for (const _item of items) {
			if (this.id !== _item.groupingId || this.timePeriodId !== _item.timePeriodId || this.timePeriod !== _item.timePeriod) {
				continue;
			}
			this.equipmentCount += _item.equipmentCount === undefined ? 1 : _item.equipmentCount;	// only equipment should have equipmentCount undefined
			this.daysAssignedSum += _item.daysAssignedSum ?? _item.daysAssigned;
			this.daysAvailableSum += _item.daysAvailableSum ?? _item.daysAvailable;
			this.daysUnavailableSum += _item.daysUnavailableSum ?? _item.daysUnavailable;
			this.totalRevenue += _item.totalRevenue;
			this.totalCost += _item.totalCost;
		}
		this.totalDaysSum = this.daysAssignedSum + this.daysAvailableSum + this.daysUnavailableSum;

		this.daysAssigned = Math.round(this.daysAssignedSum / (this.equipmentCount || 1));
		this.daysAvailable = Math.round(this.daysAvailableSum / (this.equipmentCount || 1));
		this.daysUnavailable = Math.round(this.daysUnavailableSum / (this.equipmentCount || 1));
		this.totalDays = this.daysAssigned + this.daysAvailable + this.daysUnavailable;

		this.dailyRevenue = this.totalDays ? (this.totalRevenue / this.totalDays) : null;
		this.dailyCost = this.totalDays ? (this.totalCost / this.totalDays) : null;
		this.targetProfit = this.totalRevenue - this.totalCost;
	}

	static isInstance(viewModel: EquipmentUtilizationViewModelShared): viewModel is EquipmentUtilizationGroupViewModelBase {
		if (viewModel instanceof EquipmentUtilizationGroupViewModelBase) {
			return true;
		}
		const _viewModel = viewModel as EquipmentUtilizationGroupViewModelBase;
		return (!!_viewModel.name && _viewModel.equipmentCount !== undefined);
	}

	static toCSVData(viewModels: EquipmentUtilizationGroupViewModelBase[], firstHeaderKey: string): string[][] {
		const header: string[] = [firstHeaderKey, ...CSV_HEADER_EQUIPMENT_GROUP_BASE_KEYS];

		const rows: string[][] = viewModels.map((_entry: EquipmentCostCategoryTypeUtilizationViewModel) => {
			const _row: string[] = [
				_entry.name,
				_entry.timePeriodId.toString(),
				`${_entry.daysAssigned} days`,
				`${_entry.daysAvailable} days`,
				`${_entry.daysUnavailable} days`,
				`${moneyNormalizer(_entry.dailyRevenue)}`,
				`${moneyNormalizer(_entry.dailyCost)}`,
				`${moneyNormalizer(_entry.targetProfit)}`,
			];
			return _row;
		});

		return [header, ...rows];
	}
}

class EquipmentCostCategoryTypeUtilizationViewModel extends EquipmentUtilizationGroupViewModelBase {
	id: EquipmentCostCategoryEnum;

	constructor(
		equipmentCostCategoryType: EquipmentCostCategoryEnum,
		equipmentCostCategoryGroupList: EquipmentCostCategoryGroupUtilizationViewModel[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		super(
			{ id: equipmentCostCategoryType, status: ResourceStatus.ACTIVE },
			equipmentCostCategoryGroupList,
			timePeriodId,
			timePeriod
		);

		this.groupingId = null;
		this.name = equipmentCostCategoryType;
	}
}

class EquipmentCostCategoryGroupUtilizationViewModel extends EquipmentUtilizationGroupViewModelBase {
	id: number;

	constructor(
		equipmentCostCategory: EquipmentCostCategory,
		equipmentCostCategoryList: EquipmentCostCategoryUtilizationViewModel[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		super(equipmentCostCategory, equipmentCostCategoryList, timePeriodId, timePeriod);

		this.groupingId = EquipmentCostCategoryEnum[equipmentCostCategory.type] || EquipmentCostCategoryEnum.EQUIPMENT;
		this.name = equipmentCostCategory.name;
	}
}

class EquipmentCostCategoryUtilizationViewModel extends EquipmentUtilizationGroupViewModelBase {
	id: number;
	color: Nullable<ColorPalette>;

	constructor(
		equipmentCostCategory: EquipmentCostCategory,
		equipmentCostList: EquipmentCostUtilizationViewModel[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		super(equipmentCostCategory, equipmentCostList, timePeriodId, timePeriod);

		this.groupingId = equipmentCostCategory.groupId;
		this.name = equipmentCostCategory.name;
		this.color = equipmentCostCategory.categoryColor;
	}
}

export class EquipmentCostUtilizationViewModel extends EquipmentUtilizationGroupViewModelBase {
	id: number;

	constructor(
		equipmentCost: EquipmentCost,
		equipmentList: EquipmentUtilizationViewModel[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		super(equipmentCost, equipmentList, timePeriodId, timePeriod);

		this.groupingId = equipmentCost.categoryId;
		this.name = equipmentCost.subcategory;
	}
}

export class EquipmentUtilizationViewModel implements EquipmentUtilizationViewModelShared {
	id: number;
	groupingId: number;

	createdAt: Date;
	deletedAt: Nullable<Date>;
	status: ResourceStatus;

	timePeriod: TimePeriodSpan;
	timePeriodId: number | string;

	code: string;
	specification: string;

	daysAssigned: number;
	daysAvailable: number;
	daysUnavailable: number;
	totalDays: number;

	dailyRevenue: number;
	totalRevenue: number;
	dailyCost: number;
	totalCost: number;
	targetProfit: number;

	constructor(serviceModel: EquipmentUtilizationServiceModel, timePeriodId: number | string, timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR) {
		this.id = serviceModel.equipmentId;
		this.groupingId = serviceModel.equipmentCostId;

		this.createdAt = serviceModel.createdAt;
		this.deletedAt = serviceModel.deletedAt;
		this.status = serviceModel.status;

		this.timePeriod = timePeriod;
		this.timePeriodId = timePeriodId;

		this.code = serviceModel.code;
		this.specification = serviceModel.specification ?? '';

		this.daysAssigned = serviceModel.daysAssigned;
		this.daysAvailable = serviceModel.daysAvailable;
		this.daysUnavailable = serviceModel.daysUnavailable;
		this.totalDays = this.daysAssigned + this.daysAvailable + this.daysUnavailable;

		this.dailyRevenue = +serviceModel.dailyRevenue;
		this.totalRevenue = +serviceModel.totalRevenue;
		this.dailyCost = +serviceModel.dailyCost;
		this.totalCost = +serviceModel.totalCost;
		this.targetProfit = this.totalRevenue - this.totalCost;
	}

	static isInstance(viewModel: EquipmentUtilizationViewModelShared): viewModel is EquipmentUtilizationViewModel {
		if (viewModel instanceof EquipmentUtilizationViewModel) {
			return true;
		}
		if (viewModel.equipmentCount !== undefined) {
			return false;
		}
		const _viewModel = viewModel as EquipmentUtilizationViewModel;
		return (!!_viewModel.code && _viewModel.specification !== undefined);
	}

	static toCSVData(viewModels: EquipmentUtilizationViewModel[]): string[][] {
		const header: string[] = [...CSV_HEADER_EQUIPMENT_KEYS];

		const rows: string[][] = viewModels.map((_entry: EquipmentUtilizationViewModel) => {
			const _row: string[] = [
				`${_entry.code} ${_entry.specification}`,
				_entry.timePeriodId.toString(),
				`${_entry.daysAssigned} days`,
				`${_entry.daysAvailable} days`,
				`${_entry.daysUnavailable} days`,
				`${moneyNormalizer(_entry.dailyRevenue)}`,
				`${moneyNormalizer(_entry.totalRevenue)}`,
				`${moneyNormalizer(_entry.dailyCost)}`,
				`${moneyNormalizer(_entry.totalCost)}`,
				`${moneyNormalizer(_entry.targetProfit)}`,
			];
			return _row;
		});

		return [header, ...rows];
	}
}

type EquipmentUtilizationByCostDict = { [equipmentCostId: number]: EquipmentUtilizationViewModel[]; };
type EquipmentCostUtilizationByCategoryDict = { [equipmentCostCategoryId: number]: EquipmentCostUtilizationViewModel[]; };
type EquipmentCostCategoryUtilizationByGroupDict = { [equipmentCostCategoryGroupId: number]: EquipmentCostCategoryUtilizationViewModel[]; };
type EquipmentCostCategoryGroupUtilizationByTypeDict = { [equipmentCostCategoryType: string]: EquipmentCostCategoryGroupUtilizationViewModel[]; };

export class EquipmentUtilizationViewModelsAggregate {
	equipmentByCostDict: EquipmentUtilizationByCostDict;
	equipmentCostByCategoryDict: EquipmentCostUtilizationByCategoryDict;
	equipmentCostCategoryByGroupDict: EquipmentCostCategoryUtilizationByGroupDict;
	equipmentCostGroupByTypeDict: EquipmentCostCategoryGroupUtilizationByTypeDict;
	equipmentTypeList: EquipmentCostCategoryTypeUtilizationViewModel[];

	constructor(
		equipmentServiceModels: EquipmentUtilizationServiceModel[],
		equipmentCosts: EquipmentCost[],
		equipmentCostCategories: EquipmentCostCategory[],
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		// NOTE: this will probably be called a loop, so avoid anonymous functions
		this.equipmentByCostDict = {};
		this.equipmentCostByCategoryDict = {};
		this.equipmentCostCategoryByGroupDict = {};
		this.equipmentCostGroupByTypeDict = {};
		this.equipmentTypeList = [];

		for (const _type of EQUIPMENT_COST_CATEGORY_TYPES) {
			// pre-fill to make sure that all types are present
			this.equipmentCostGroupByTypeDict[_type] = [];
		}
		for (const _serviceModel of equipmentServiceModels) {
			const _viewModel = new EquipmentUtilizationViewModel(_serviceModel, timePeriodId, timePeriod);
			if (!this.equipmentByCostDict[_serviceModel.equipmentCostId]) {
				this.equipmentByCostDict[_serviceModel.equipmentCostId] = [];
			}
			this.equipmentByCostDict[_serviceModel.equipmentCostId].push(_viewModel);
		}
		for (const _ec of equipmentCosts) {
			if (!this.equipmentByCostDict[_ec.id]) {
				continue;	// don't include if no equipment, replace with empty array assignment if this condition changes
			}
			const _viewModel = new EquipmentCostUtilizationViewModel(_ec, this.equipmentByCostDict[_ec.id], timePeriodId, timePeriod);
			if (_viewModel.equipmentCount === 0) {
				// don't include if no equipment, update condition above if this requirement changes
				// should never happen because of the previous continue condition, but it's here to revert back to another condition more easily
				continue;
			}
			if (!this.equipmentCostByCategoryDict[_ec.categoryId]) {
				this.equipmentCostByCategoryDict[_ec.categoryId] = [];
			}
			this.equipmentCostByCategoryDict[_ec.categoryId].push(_viewModel);
		}
		for (const _ecc of equipmentCostCategories) {
			if (_ecc.groupId === null) {
				continue;
			}
			if (!this.equipmentCostByCategoryDict[_ecc.id]) {
				continue;	// don't include if no equipment costs, replace with empty array assignment if this condition changes
			}
			const _viewModel = new EquipmentCostCategoryUtilizationViewModel(_ecc, this.equipmentCostByCategoryDict[_ecc.id], timePeriodId, timePeriod);
			if (_viewModel.equipmentCount === 0) {
				// don't include if no equipment, update condition above if this requirement changes
				// should never happen because of the previous continue condition, but it's here to revert back to another condition more easily
				continue;
			}
			if (!this.equipmentCostCategoryByGroupDict[_ecc.groupId]) {
				this.equipmentCostCategoryByGroupDict[_ecc.groupId] = [];
			}
			this.equipmentCostCategoryByGroupDict[_ecc.groupId].push(_viewModel);
		}
		for (const _ecc of equipmentCostCategories) {
			if (_ecc.groupId !== null) {
				continue;
			}
			if (!this.equipmentCostCategoryByGroupDict[_ecc.id]) {
				continue;	// don't include if no equipment categories, replace with empty array assignment if this condition changes
			}
			const _viewModel = new EquipmentCostCategoryGroupUtilizationViewModel(
				_ecc,
				this.equipmentCostCategoryByGroupDict[_ecc.id],
				timePeriodId,
				timePeriod
			);
			if (_viewModel.equipmentCount === 0) {
				// don't include if no equipment, update condition above if this requirement changes
				// should never happen because of the previous continue condition, but it's here to revert back to another condition more easily
				continue;
			}
			if (_viewModel.groupingId && !this.equipmentCostGroupByTypeDict[_viewModel.groupingId]) {
				// this should never happen
				console.error('[DATA ERROR]: Incorrect equipment type on for equipment category row at id: ', _ecc.id);
				continue;
			}
			_viewModel.groupingId && this.equipmentCostGroupByTypeDict[_viewModel.groupingId].push(_viewModel);
		}
		for (const _type of EQUIPMENT_COST_CATEGORY_TYPES) {
			if (this.equipmentCostGroupByTypeDict[_type].length === 0) {
				// don't include if no groups (i.e. no equipment), update condition above if this requirement changes
				continue;
			}
			this.equipmentTypeList.push(new EquipmentCostCategoryTypeUtilizationViewModel(
				_type,
				this.equipmentCostGroupByTypeDict[_type],
				timePeriodId,
				timePeriod
			));
		}
	}
}
