import * as React from 'react';
import { connect } from 'react-redux';
import { DroppableStateSnapshot, Droppable } from 'react-beautiful-dnd';

import * as MechanicViewEnums from 'ab-enums/mechanicView.enum';

import { EquipmentDownRequestModel } from 'ab-requestModels/equipmentDown.requestModel';

import * as ArrayUtil from 'ab-utils/array.util';

import { MechanicViewUnavailableEquipmentDataVM } from 'ab-viewModels/mechanicView/mechanicView.viewModel';

import CustomScrollbar from 'af-components/CustomScrollbar';

import { MatchedEquipmentIdMap } from 'af-models/scheduleBoard.models';

import { RootState } from 'af-reducers';

import * as MechanicViewUtil from 'af-utils/mechanicView.util';

import EquipmentCards from './EquipmentCards';

interface OwnProps {
	onEquipmentDownEdit: (equipment: EquipmentDownRequestModel) => void;
}

interface StateProps {
	focusedSearchIndex: number;
	matchedEquipmentIdMap: MatchedEquipmentIdMap;
	matchedEquipmentIds: number[];
	orderEquipmentBy: MechanicViewEnums.OrderByEnum;
	sortEquipmentBy: MechanicViewEnums.SortByEnum;
	unavailableEquipment: MechanicViewUnavailableEquipmentDataVM[];
	isAutomaticReturnDate: boolean;
}

type Props = OwnProps & StateProps;

interface State {
	sortedEquipment: MechanicViewUnavailableEquipmentDataVM[];
}

class UnavailableBoard extends React.PureComponent<Props, State> {
	state: State = {
		sortedEquipment: [],
	};

	static className = (snapshot: DroppableStateSnapshot) => {
		let className = 'mechanic-view-body__unavailable';

		if (snapshot?.isDraggingOver) {
			className = `${className} mechanic-view-body__unavailable--dragged-over`;
		}
		return className;
	};

	componentDidMount() {
		this.setSortedEquipment();
	}

	componentDidUpdate(prevProps: Props) {
		const { unavailableEquipment, sortEquipmentBy, orderEquipmentBy } = this.props;

		if (
			unavailableEquipment !== prevProps.unavailableEquipment
			|| sortEquipmentBy !== prevProps.sortEquipmentBy
			|| orderEquipmentBy !== prevProps.orderEquipmentBy
		) {
			this.setSortedEquipment();
		}
	}

	setSortedEquipment = () => {
		const { unavailableEquipment, sortEquipmentBy, orderEquipmentBy } = this.props;

		type EquipmentMap = { [equipmentId: string]: MechanicViewUnavailableEquipmentDataVM; };
		const equipmentMap = unavailableEquipment.reduce<EquipmentMap>(
			ArrayUtil.toLookupById,
			{}
		);

		const sortedEquipmentIds = MechanicViewUtil.getSortedUnavailableEquipmentIds(unavailableEquipment, orderEquipmentBy, sortEquipmentBy);

		this.setState(() => ({
			sortedEquipment: sortedEquipmentIds.map((_id) => equipmentMap[_id]),
		}));
	};

	render() {
		const {
			focusedSearchIndex,
			isAutomaticReturnDate,
			matchedEquipmentIdMap,
			matchedEquipmentIds,
			onEquipmentDownEdit,
		} = this.props;

		const { sortedEquipment } = this.state;

		const contentWrapperClassName = sortedEquipment.length ? '' : 'mechanic-view-body__unavailable-wrapper--empty';

		return (
			<CustomScrollbar contentWrapperClassName={contentWrapperClassName}>
				<Droppable
					direction="horizontal"
					droppableId={MechanicViewUtil.UNAVAILABLE_CONTAINER_ID}
				>
					{
						(_provided, _snapshot) => (
							<div
								className={UnavailableBoard.className(_snapshot)}
								id="unavailable-equipment-scrollable-container"
								{...(_provided.droppableProps)}
								ref={_provided.innerRef}
							>
								<EquipmentCards
									equipment={sortedEquipment}
									focusedEquipmentId={matchedEquipmentIds[focusedSearchIndex] ?? null}
									isAutomaticReturnDate={isAutomaticReturnDate}
									matchedEquipment={matchedEquipmentIdMap}
									onEquipmentDownEdit={onEquipmentDownEdit}
									provided={_provided}
								/>
								{_provided.placeholder}
							</div>
						)
					}
				</Droppable>
			</CustomScrollbar>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const {
		scheduleBoard: {
			mechanicView: {
				focusedSearchIndex,
				matchedEquipmentIdMap,
				matchedEquipmentIds,
				orderEquipmentBy,
				sortEquipmentBy,
				unavailableEquipment,
			},
		},
		company: { company },
	} = state;

	return {
		focusedSearchIndex,
		matchedEquipmentIdMap,
		matchedEquipmentIds,
		orderEquipmentBy,
		sortEquipmentBy,
		unavailableEquipment,
		isAutomaticReturnDate: company?.isEquipmentAutomaticReturnDate ?? false,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(UnavailableBoard);
