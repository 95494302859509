import * as React from 'react';
import { Image } from 'react-bootstrap';

import * as User from 'ab-viewModels/user.viewModel';
import { ActiveVM } from 'ab-viewModels/account/active.viewModel';

import SettingPreview from '../Shared/SettingPreview';

import { formatPhoneNumber } from 'ab-utils/phone.util';
import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	toggleEdit: () => void;
	accountDetails: Nullable<ActiveVM>;
	userData: User.UserData;
}

type Props = OwnProps;

export default class AccountPreview extends React.PureComponent<Props> {
	render(): JSX.Element {
		const { toggleEdit, accountDetails, userData } = this.props;

		return (
			<SettingPreview toggleEdit={toggleEdit}>
				<div className="company-settings__image-segment">
					{userData.imageUrl
						? <Image className="company-settings__image" roundedCircle={true} src={userData.imageUrl} />
						: (
							<div className={bemElement('company-settings', 'image', ['default'])}>
								<span className="icon-user" />
							</div>
						)
					}
				</div>
				<div className="company-settings__info-segment">
					<div className="company-settings__graphic-bullet">
						<span className="icon-user" />
						<strong>{userData.firstName} {userData.lastName}</strong>
					</div>
					<div className="company-settings__graphic-bullet">
						<span className="icon-email" />
						<span>{userData.email}</span>
					</div>
					<div className="company-settings__graphic-bullet">
						<span className="icon-smartphone" />
						<span>{accountDetails ? formatPhoneNumber(accountDetails.phoneNumber, accountDetails.countryCode) : '-'}</span>
					</div>
				</div>
			</SettingPreview>
		);
	}
}
