import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Breadcrumbs from 'af-components/Breadcrumbs';
import TabNavigation from 'af-components/TabNavigation';

import * as CompanyActions from 'af-actions/companies';

import FullTable from './FullTable';
import CondensedTable from './CondensedTable';
import { RootState } from 'af-reducers';
import { isAllowed } from 'ab-utils/auth.util';
import PagePermissions from 'ab-enums/pagePermissions.enum';

type Props = ConnectedProps<typeof connector>;

const BREADCRUMBS = [{ label: 'Field Reports' }];
const FULL_VIEW_TAB = { ID: 1, LABEL: 'FULL VIEW' };
const CONDENSED_VIEW_TAB = { ID: 2, LABEL: 'CONDENSED VIEW' };
const TABS = [
	{ id: FULL_VIEW_TAB.ID, label: FULL_VIEW_TAB.LABEL },
	{ id: CONDENSED_VIEW_TAB.ID, label: CONDENSED_VIEW_TAB.LABEL },
];

const WorkOrdersTable: React.FC<Props> = (props) => {

	const { getCompany, showCondensedTab } = props;

	const [activeTab, setActiveTab] = React.useState(FULL_VIEW_TAB.ID);

	React.useEffect(() => {
		getCompany();
	}, [getCompany]);

	const renderTab = () => {
		switch (activeTab) {
			case CONDENSED_VIEW_TAB.ID:
				return <CondensedTable />;
			case FULL_VIEW_TAB.ID:
			default:
				return <FullTable />;
		}
	};

	return <div className="form-segment form-segment--maxi field-report-orders-table">
		<Breadcrumbs items={BREADCRUMBS} />
		{showCondensedTab && (
			<TabNavigation
				active={activeTab}
				onClick={setActiveTab}
				tabs={TABS}
			/>
		)}
		{renderTab()}
	</div>;
};

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const showCondensedTab = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE, permissions, isCompanyAdmin, role);

	return {
		showCondensedTab,
	};
}

function mapDispatchToProps() {
	return {
		getCompany: CompanyActions.getCompany,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(WorkOrdersTable);

