import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';

import SegmentLabel from 'af-components/SegmentLabel';

import NotificationPriority from 'ab-enums/notificationPriority.enum';

import { MAX_EMPLOYEE_NOTIFICATION_MESSAGE_LENGTH } from 'ab-constants/value';

interface OwnProps {
	notificationPriority?: NotificationPriority;
}

type Props = OwnProps;

class NotifyUsersFields extends React.PureComponent<Props> {
	render() {
		const { notificationPriority } = this.props;
		const onlySms = notificationPriority && notificationPriority === NotificationPriority.SMS_ONLY;
		const sendBothOrOneThenOther = notificationPriority
			&& notificationPriority !== NotificationPriority.SMS_ONLY
			&& notificationPriority !== NotificationPriority.EMAIL_ONLY;
		const tooltip = sendBothOrOneThenOther ? 'This field is used only in email.' : null;

		return (
			<>
				{!onlySms &&
					<>
						<Row>
							<Col sm={24}>
								<SegmentLabel label="Message" />
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<Field
									component={Input}
									label="Subject *"
									name="subject"
									tooltipMessage={tooltip}
								/>
							</Col>
							<Col md={12}>
								<Field
									component={Input}
									label="Heading"
									name="heading"
									tooltipMessage={tooltip}
								/>
							</Col>
						</Row>
					</>
				}
				<Row>
					<Col md={24}>
						<Field
							component={Textarea}
							controlCursor={true}
							label="Message *"
							maxCharacters={MAX_EMPLOYEE_NOTIFICATION_MESSAGE_LENGTH}
							name="message"
							rows={5}
							showMaxCaractersLabel={true}
						/>
					</Col>
				</Row>
				{!onlySms &&
					<Row>
						<Col md={24}>
							<Field
								component={Textarea}
								controlCursor={true}
								label="Footer"
								maxCharacters={MAX_EMPLOYEE_NOTIFICATION_MESSAGE_LENGTH}
								name="footer"
								rows={5}
								showMaxCaractersLabel={true}
								tooltipMessage={tooltip}
							/>
						</Col>
					</Row>
				}
			</>
		);
	}
}

export default NotifyUsersFields;
