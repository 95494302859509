import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import ResourcePreview from '../../Shared/ResourcePreview';

const breadcrumbs = [
	{ label: 'Locations' },
	{ label: 'Preview' },
];

export default () => (
	<ResourcePreview
		backToListLabel="Back to Location List"
		backToListUrl="#"
		breadcrumbs={breadcrumbs}
		editUrl="#"
	>
		<Row className="row--padded-top">
			<Col className="loading-box-field standalone" md={8} />
			<Col className="loading-box-field standalone" md={8} />
			<Col className="loading-box-field standalone" md={8} />
		</Row>
		<Row className="row--padded">
			<Col className="loading-box-field standalone" md={8} />
			<Col className="loading-box-field standalone" md={8} />
		</Row>
		<Row className="row--padded">
			<Col className="loading-box-field standalone" md={5} />
			<Col className="loading-box-field standalone" md={3} />
			<Col className="loading-box-field standalone" md={4} />
			<Col className="loading-box-field standalone" md={3} />
			<Col className="loading-box-field standalone" md={3} />
			<Col className="loading-box-field standalone" md={3} />
			<Col className="loading-box-field standalone" md={3} />
		</Row>
	</ResourcePreview>
);
