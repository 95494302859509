import * as React from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import JobStatusRequestModel from 'ab-requestModels/jobStatus.requestModel';

import SubmitButton from 'af-components/SubmitButton';
import BackButton from 'af-components/BackButton';

import Input from 'af-fields/Input';
import ColorPicker from 'af-fields/ColorPicker';

interface PathParams {
	id?: string;
}

interface OwnProps {
	onSubmit: (form: JobStatusRequestModel) => void;
}

type Props = OwnProps & CustomRouteComponentProps<PathParams> & InjectedFormProps<JobStatusRequestModel>;

const JobStatusForm = React.memo<Props>((props) => {

	const {
		handleSubmit,
		submitting,
		invalid,
		onSubmit,
		touch,
	} = props;

	const onColorFocus = React.useCallback(() => {
		touch('color');
	}, [touch]);

	const handleFormSubmit = React.useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

	return (
		<form onSubmit={handleFormSubmit}>
			<div className="form-box">
				<Row className="row--padded-top">
					<Col md={10}>
						<Field
							component={Input}
							cursorControl={true}
							label="Name *"
							name="name"
							placeholder="Enter name"
							type="text"
						/>
					</Col>
					<Col md={10}>
						<Field
							component={Input}
							cursorControl={true}
							label="Description"
							name="description"
							placeholder="Enter description"
							type="text"
						/>
					</Col>
					<Col md={4}>
						<Field
							component={ColorPicker}
							label="Color *"
							name="color"
							onFocus={onColorFocus}
						/>
					</Col>
				</Row>
				<div className="form-box__after">
					<div />
					<div>
						<BackButton />
						<SubmitButton
							disabled={invalid}
							reduxFormSubmitting={submitting}
						/>
					</div>
				</div>
			</div>
		</form>
	);
});

export default JobStatusForm;
