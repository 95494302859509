import * as React from 'react';
import { Field } from 'redux-form';

import ConfirmationModal from 'af-components/ConfirmationModal';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';

const OTHER_OPTION = { id: null, label: 'Other' };

type Option = { id: Nullable<string>; label: string; };

interface OwnProps {
	allowCustomValue: boolean;
	className: string;
	fieldName: string;
	id: number | string | undefined;
	label: string | JSX.Element;
	name: string;
	options: string[];
	tooltipMessage?: string;
}

type Props = OwnProps;

const _getOptions = (options: string[] = [], allowCustomValue: boolean = false) => {
	const mappedOptions = options?.map(_mapStringToOption) ?? [];
	if (!allowCustomValue) {
		return mappedOptions;
	}
	return [...mappedOptions, OTHER_OPTION];
};

const _mapStringToOption = (option: string) => ({ id: option, label: option });

const DropdownListField: React.FC<Props> = (props: Props) => {
	const { name, className, label, fieldName, tooltipMessage, id, options, allowCustomValue } = props;

	const [showModal, setShowModal] = React.useState<boolean>(false);
	const [dropDownOptions, setDropDownOptions] = React.useState<Option[]>(_getOptions(options, allowCustomValue));

	React.useEffect(() => {
		setDropDownOptions(_getOptions(options, allowCustomValue));
	}, [options, allowCustomValue]);

	const onDropdownChange = React.useCallback((option: Option) => {
		if (option.id === OTHER_OPTION.id) {
			setShowModal(true);
		}
	}, []);

	const closeModal = React.useCallback(() => setShowModal(false), []);

	const renderModalBody = () => {
		return (
			<Field
				component={Input}
				name={fieldName}
				placeholder="Other"
				type="text"
			/>
		);
	};

	return (
		<>
			<Field
				className={className}
				component={Dropdown}
				id={id}
				label={label}
				labelKey="label"
				name={fieldName}
				onValueChange={onDropdownChange}
				options={dropDownOptions}
				tooltipMessage={tooltipMessage}
				valueKey="id"
				withCaret={true}
			/>
			<ConfirmationModal
				body={renderModalBody()}
				closeModal={closeModal}
				confirmAction={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="md"
				title={name}
			/>
		</>
	);
};

export default React.memo(DropdownListField);
