import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import * as TemporaryEmployeeActions from 'af-actions/temporaryEmployee';

import Breadcrumbs from 'af-components/Breadcrumbs';
import LabelWithColor from 'af-components/LabelWithColor';
import Table, { TabProps, Column, ButtonData, ItemBlueprint, BulkAction } from 'af-components/Table6';
import UserCell from 'af-components/Table6/Cells/UserCell';
import { OwnProps as TableOwnProps } from 'af-components/Table6';

import CLIENT from 'af-constants/routes/client';

import TemporaryEmployeeTableVM from 'ab-viewModels/temporaryEmployee/table.viewModel';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

class TemporaryEmployees extends React.PureComponent<Props> {

	tableRef = React.createRef<React.Component<TableOwnProps<TemporaryEmployeeTableVM>>>();

	static readonly SHARED_COLUMNS: Column<TemporaryEmployeeTableVM>[] = [
		{
			Header: 'Full Name',
			accessor: 'fullName',
			Cell: UserCell,
		},
		{
			Header: 'Agency',
			accessor: 'agency',
			Cell: ({ original }) => <LabelWithColor color={original.color} text={<strong>{original.agency}</strong>} />,
		},
	];

	static readonly COLUMNS_FOR_DELETED: Column<TemporaryEmployeeTableVM>[] = [
		...TemporaryEmployees.SHARED_COLUMNS,
	];

	static readonly COLUMNS_FOR_ACTIVE: Column<TemporaryEmployeeTableVM>[] = [
		...TemporaryEmployees.SHARED_COLUMNS,
	];

	static readonly DEACTIVATE_ACTION_LABEL = (<><span className="icon-delete" />Deactivate</>);
	static readonly DEACTIVATE_MODAL_CONFIRM_TEXT = 'Deactivate';
	static readonly DEACTIVATE_MODAL_TITLE = 'Deactivate temporary laborers';

	static deleteAccountModalTitle = () => 'Deactivating Temporary Labor';
	static deleteAccountModalBody = (original: TemporaryEmployeeTableVM) => `Are you sure you want to deactivate this User for company (${original.fullName})?`;
	static deleteAccountModalText = () => 'Deactivate User';

	static breadcrumbs = () => [{ label: 'Temporary labor' }];

	onRowClick = ({ original }: { original: TemporaryEmployeeTableVM; }) => {
		const { companyName, history, location: { state: { orgAlias } } } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.PREVIEW(orgAlias, companyName, `${original.id}`));
	};

	editAccount = async (original: TemporaryEmployeeTableVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.EDIT(orgAlias, companyName, `${original.id}`));
	};

	previewAccount = async (original: TemporaryEmployeeTableVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.PREVIEW(orgAlias, companyName, `${original.id}`));
	};

	deactivateAccount = async (original: TemporaryEmployeeTableVM) => {
		const { deactivateAccount } = this.props;
		await deactivateAccount(original.id);
	};

	bulkDeactivateAccounts = async (ids: number[]) => {
		const { bulkDeactivateAccounts } = this.props;
		await bulkDeactivateAccounts(ids);
	};

	handleRefreshTable = () => {
		if (this.tableRef.current) {
			this.tableRef.current.componentDidMount!();
		}
	};

	reactivateUser = async (original: TemporaryEmployeeTableVM) => {
		const { reactivateAccount } = this.props;
		await reactivateAccount(original.id);
		this.handleRefreshTable();
	};

	bulkActions = (): BulkAction[] => {
		return [
			{
				onActionConfirmation: this.bulkDeactivateAccounts,
				label: TemporaryEmployees.DEACTIVATE_ACTION_LABEL,
				key: 'deactivate',
				modalType: 'warning',
				modalSize: 'md',
				modalTitle: () => TemporaryEmployees.DEACTIVATE_MODAL_TITLE,
				confirmText: () => TemporaryEmployees.DEACTIVATE_MODAL_CONFIRM_TEXT,
				actionConfirmationBody: (ids: number[]) => `Are you sure you want to deactivate ${ids.length} temporary laborer${ids.length > 1 ? 's' : ''}`,
			},
		];
	};

	tabs = (): TabProps<TemporaryEmployeeTableVM>[] => {
		const {
			fetchActive,
			fetchDeleted,
			location: { state: { orgAlias } },
			companyName,
			history,
		} = this.props;

		const activeUsersRowActions: ItemBlueprint<TemporaryEmployeeTableVM>[] = [
			{
				label: 'Edit',
				action: this.editAccount,
				shouldRefresh: false,
			},
			{
				label: 'Preview',
				action: this.previewAccount,
				shouldRefresh: false,
			},
			{
				label: 'Deactivate for Company',
				action: this.deactivateAccount,
				hasModal: true,
				modalTitle: TemporaryEmployees.deleteAccountModalTitle,
				modalBody: TemporaryEmployees.deleteAccountModalBody,
				modalText: TemporaryEmployees.deleteAccountModalText,
				shouldRefresh: true,
			},
		];

		const deletedUsersRowActions: ItemBlueprint<TemporaryEmployeeTableVM>[] = [
			{
				label: 'Reactivate User',
				action: this.reactivateUser,
				shouldRefresh: false,
			},
		];

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Temporary Labor',
				icon: 'plus',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.CREATE(orgAlias, companyName)),
			},
		];

		return [
			{
				label: 'All',
				columns: TemporaryEmployees.COLUMNS_FOR_ACTIVE,
				selectable: true,
				hasSearchInput: true,
				searchLabel: 'Users',
				buttons,
				fetch: fetchActive,
				additionalBulkActions: this.bulkActions(),
				onRowClick: this.onRowClick,
				rowActions: activeUsersRowActions,
			},
			{
				label: 'Recently Deactivated',
				columns: TemporaryEmployees.COLUMNS_FOR_DELETED,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Users',
				buttons,
				fetch: fetchDeleted,
				rowActions: deletedUsersRowActions,
			},
		];
	};

	render() {
		return (
			<>
				<Breadcrumbs items={TemporaryEmployees.breadcrumbs()} />
				<Table
					ref={this.tableRef}
					tableName={TableNameEnum.TEMPORARY_EMPLOYEE}
					tabs={this.tabs()}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: RootState) => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

function mapDispatchToProps() {
	return {
		fetchActive: TemporaryEmployeeActions.findAllForCompanyTable,
		fetchDeleted: TemporaryEmployeeActions.findAllDeletedForCompanyTable,
		deactivateAccount: TemporaryEmployeeActions.remove,
		bulkDeactivateAccounts: TemporaryEmployeeActions.bulkRemove,
		reactivateAccount: TemporaryEmployeeActions.reactivate,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(TemporaryEmployees);
