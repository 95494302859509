import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import * as ItemCategoryActions from 'af-actions/itemCategory';

import ItemCategoryVM from 'ab-viewModels/itemCategory/itemCategory.viewModel';

import Breadcrumbs from 'af-components/Breadcrumbs';

import Loading from './Loading';
import CategoryPreview from './CategoryPreview';
import CategoryEdit from './CategoryEdit';
import CategoryCreate from './CategoryCreate';

import { bemElement, bemBlock } from 'ab-utils/bem.util';
import ItemCategoryRM from 'ab-requestModels/itemCategory/itemCategory.requestModel';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

const Category = (props: Props) => {
	const {
		findAll,
		create,
		update,
		remove,
	} = props;

	const [adding, setAdding] = React.useState(false);
	const [categories, setCategories] = React.useState<ItemCategoryVM[]>([]);
	const [editingId, setEditingId] = React.useState<Nullable<number>>(null);
	const [loading, setLoading] = React.useState(false);

	const load = React.useCallback(async () => {
		const _categories = await findAll();
		setCategories(_categories);
		setLoading(false);
	}, [findAll]);

	React.useEffect(() => {
		setLoading(true);
	}, []);

	React.useEffect(() => {
		if (loading) {
			load();
		}
	}, [load, loading]);

	const createCategory = React.useCallback(async (form: ItemCategoryRM) => {
		await create(form);
		await load();
	}, [create, load]);

	const updateCategory = React.useCallback(async (form: ItemCategoryRM) => {
		await update(editingId!, form);
		setEditingId(null);
		await load();
	}, [update, load, editingId]);

	const deleteCategory = React.useCallback(async (id: number) => {
		await remove(id);
		await load();
	}, [remove, load]);

	const toggleAdding = React.useCallback(() => {
		setAdding(!adding);
	}, [adding]);

	const toggleEditing = React.useCallback((id: Nullable<number>) => {
		setEditingId(id);
	}, [setEditingId]);

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="form-segment resources-pilled">
			<Breadcrumbs items={[{ label: 'Accessory Category' }]} />
			<div className={bemBlock('form-box', ['full-padded'])}>
				{!categories.length
					? <div className={bemElement('resources-pilled', 'empty')}>There are no categories available.</div>
					: <div className={bemElement('resources-pilled', 'list')}>
						<div className={bemElement('resources-pilled', 'color-list')}>
							{categories.map((_category) =>
								<React.Fragment key={_category.id}>
									{_category.id === editingId ? (
										<CategoryEdit
											categories={categories}
											category={_category}
											id={editingId}
											toggleEdit={toggleEditing}
											update={updateCategory}
										/>
									) : (
										<CategoryPreview
											category={_category}
											edit={toggleEditing}
											remove={deleteCategory}
										/>
									)}
								</React.Fragment>
							)}
						</div>
					</div>
				}
				<div className={bemElement('resources-pilled', 'addition')}>
					{adding ?
						<CategoryCreate
							categories={categories}
							close={toggleAdding}
							create={createCategory}
							id={editingId}
						/> :
						<span className={bemBlock('btn', ['link'])} onClick={toggleAdding}>
							<span className="icon-plus" />
							<span>Add Category</span>
						</span>
					}
				</div>
			</div>
		</div>
	);
};

function mapDispatchToProps() {
	return {
		findAll: ItemCategoryActions.findList,
		create: ItemCategoryActions.create,
		update: ItemCategoryActions.update,
		remove: ItemCategoryActions.remove,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(Category);
