import Address from 'acceligent-shared/models/address';
import WorkRequest from 'acceligent-shared/models/workRequest';

export const getJobDescriptionForLogs = (job: WorkRequest): string => {
	const { customerCompanyName, jobCode, title, travelLocation = {} as Address } = job;

	const description = [
		jobCode,
		customerCompanyName,
		title,
		travelLocation?.street,
	].filter(Boolean).join(', ');

	return description;
};
