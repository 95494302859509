import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

import { bemElement } from 'ab-utils/bem.util';

import TableTypeEnum from '../tableType.enum';

interface OwnProps {
	filterText: string;
	onFilterTextChange: (filterText: string) => void;
	searchLabel: string;
	tableType: TableTypeEnum;
}

type Props = OwnProps;

class FilterInput extends React.PureComponent<Props> {

	clearFilter = () => this.props.onFilterTextChange('');

	handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
		const { onFilterTextChange } = this.props;
		const filterText = typeof e === 'string' ? e : e.target.value;
		onFilterTextChange(filterText);
	};

	renderSearchIcon = () => {
		const { filterText } = this.props;

		if (filterText) {
			return (
				<Tooltip message="Clear" placement="bottom">
					<a
						className={bemElement('table-container', 'clear-filter')}
						onClick={this.clearFilter}
						role="button"
					>
						<span className="icon-close" />
					</a>
				</Tooltip>
			);
		}
		return (
			<Tooltip message="Search" placement="bottom">
				<span className="icon-search" />
			</Tooltip>
		);
	};

	render() {
		const { filterText, tableType, searchLabel } = this.props;

		const showSmallButtons = tableType !== TableTypeEnum.DEFAULT;
		const placeholder = tableType === TableTypeEnum.SCROLLABLE || !searchLabel ? '' : searchLabel;

		return (
			<div className={bemElement('table-container', 'filter', showSmallButtons ? ['stretch'] : [])}>
				<input
					className="form-control"
					onChange={this.handleInputChange}
					placeholder={`Search ${placeholder}`}
					type="text"
					value={filterText}
				/>
				{this.renderSearchIcon()}
			</div>
		);
	}
}

export default FilterInput;
