import * as React from 'react';
import { Button } from 'react-bootstrap';

import { CUSTOMER_SERVICE_NAME } from 'acceligent-shared/constants/value';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	close: () => void;
	show: boolean;
	userFullName: Nullable<string>;
}

type Props = OwnProps;

const ForcedUnlockNotificationModal: React.FC<Props> = (props: Props) => {
	const {
		close,
		show,
		userFullName,
	} = props;

	return (
		<CustomModal
			closeModal={close}
			modalStyle="warning"
			showModal={show}
			size="md"
		>
			<CustomModal.Header
				closeModal={close}
				title="Session Canceled"
			/>
			<CustomModal.Body>
				<p>{userFullName ?? CUSTOMER_SERVICE_NAME} terminated your Work Order Edit session.</p>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button onClick={close} variant="info">Ok</Button>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default ForcedUnlockNotificationModal;
