enum PreviewTabsEnum {
	PREVIEW = 0,
	FIELD_REPORT = 1,
	CUSTOMER_VIEW = 2,
}

export const PREVIEW_TABS = [
	{ id: PreviewTabsEnum.PREVIEW, label: 'Preview' },
	{ id: PreviewTabsEnum.FIELD_REPORT, label: 'Field Report' },
	{ id: PreviewTabsEnum.CUSTOMER_VIEW, label: 'Customer View' },
];

export default PreviewTabsEnum;
