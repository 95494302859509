import * as React from 'react';

import { bemBlock } from 'ab-utils/bem.util';

export default class DownEquipmentBadge extends React.PureComponent {

	render() {
		const className = bemBlock('down-resource-badge');

		return (
			<div className={className}>
				<span className="icon-equipment" />
			</div>
		);
	}
}
