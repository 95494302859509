const _reverse = (value: string): string => {
	const chars = value.split('');
	return chars.reverse().join('');
};

const _insertCommas = (value: string = ''): string => {
	const negative = value[0] === '-';
	value = negative ? value.slice(1) : value;
	value = _reverse(value);
	const groups: string[] = [];
	let group = '';
	for (let i = 0; i < value.length; i++) {
		if (i !== 0 && i % 3 === 0) {
			groups.push(group + ',');
			group = '';
		}
		group += value[i];
	}
	groups.push(group);
	return negative ? `-${_reverse(groups.join(''))}` : _reverse(groups.join(''));
};

const _sumReducer = (_sum: number, _elem: number) => _sum + _elem;

export const _parseThousands = (value: string) => {
	const args = value.split('.');
	let parsed = _insertCommas(args[0]);
	if (args.length > 1) {
		parsed += `.${args[1]}`;
	}
	return parsed;
};

export const round = (num: Nullable<number> = 2) => {
	return Math.round((num ?? 0) * 100) / 100;
};

export const boundToInterval = (value: number, start: number = 0, end: number = 1) => {
	return Math.max(start, Math.min(end, value || 0));
};

export const fraction = (numerator: number, denominator: number): Nullable<number> => {
	return denominator !== 0 ? numerator / denominator : null;
};

export const stringToInteger = (value: string): Nullable<number> => {
	if (!value) {
		return null;
	}
	const intValue = parseInt(value, 10);
	return isNaN(intValue) ? null : intValue;
};

export function normalizePercentages(percentages: number[]): number[] {
	const changedPercentages = [...percentages];
	let totalPercentage = percentages.reduce(_sumReducer, 0);

	if (totalPercentage === 0) {
		return percentages;
	}
	while (totalPercentage !== 100) {
		for (let i = 0; i < percentages.length; i++) {
			if (changedPercentages[i] === 0) {
				continue;
			}
			if (totalPercentage === 100) {
				break;
			}
			const diff = totalPercentage > 100 ? -1 : 1;
			changedPercentages[i] += diff;
			totalPercentage += diff;
		}
	}
	return changedPercentages;
}

/**
 * Rounds a `value` up to the nearest integer that has the first `precision` digits the same as `value` and the rest are filled with zeroes.
 *
 * @example
 * roundToLeadingDigitsPrecision(87332, 1) // `90000`
 * roundToLeadingDigitsPrecision(87332, 2) // `88000`
 */
export function roundToLeadingDigitsPrecision(value: number, precision: number): number {
	let order = 0;
	const precisionDelimiter = Math.pow(10, precision);
	if (value < precisionDelimiter && value > -precisionDelimiter) {
		return value;
	}
	if (value >= 0) {
		while (value >= precisionDelimiter) {
			value = Math.trunc(value / 10);
			order++;
		}
		value = (value + 1) * Math.pow(10, order);
	} else {
		value *= -1;
		while (value >= precisionDelimiter) {
			value = Math.trunc(value / 10);
			order++;
		}
		value = -(value + 1) * Math.pow(10, order);
	}
	return value;
}
