import * as React from 'react';

import PeriodPicker from 'af-components/Controls/PeriodPicker';

interface OwnProps {
	start: Date;
	end: Date;
	maxDays?: number;
	onChange: (selected: Date, startDate: Date, endDate: Date) => void;
}

type Props = OwnProps;

const DateFilterPeriodPicker: React.FC<Props> = (props) => {
	const { start, end, onChange, maxDays } = props;

	const handleChange = React.useCallback((_start: Date, _end: Date) => onChange(_start, _start, _end), [onChange]);

	return <PeriodPicker end={end} maxDays={maxDays} onChange={handleChange} start={start} />;
};

export default DateFilterPeriodPicker;
