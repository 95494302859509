import UpdateDigitalSignatureRM from 'ab-requestModels/account/updateDigitalSignature.requestModel';

export function validate(values: UpdateDigitalSignatureRM) {
	const errors: ValidationErrors = {};

	if (!values.signatureImage) {
		errors.signatureImage = 'Signature is required';
	}

	return errors;
}
