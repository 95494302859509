import * as React from 'react';

interface OwnProps {
	text: string;
	searchText?: string;
	caseSensitive?: boolean;
	invertHighlight?: boolean;
}

type Props = OwnProps;

export default class TextHighlight extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		text: '',
		searchText: '',
		caseSensitive: false,
		invertHighlight: false,
	};

	render() {
		const { text, searchText, caseSensitive, invertHighlight } = this.props;
		const _text = caseSensitive ? text : text.toLowerCase();
		const _searchText = caseSensitive ? searchText : searchText?.toLowerCase();

		const nameMatchStart = _searchText ? _text.indexOf(_searchText) : -1;
		const matchLength = _searchText?.length ?? 0;

		if (nameMatchStart > -1) {
			return (
				<>
					{text.substr(0, nameMatchStart)}
					<mark className={`text-highlight ${invertHighlight ? 'invert' : ''}`}>{text.substr(nameMatchStart, matchLength)}</mark>
					{text.substr(nameMatchStart + matchLength)}
				</>
			);
		}
		return <span>{text}</span>;
	}
}
