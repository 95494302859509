import * as React from 'react';

import Pills from 'af-components/Pills/LockedList';
import Label from 'af-components/LockedValue/Label';

interface Props {
	label: string;
	items: Metadata[];
	labelKey: string;
	colorKey: string;
}

export default ({ label, items, labelKey, colorKey }: Props): JSX.Element => {
	return (
		<>
			<Label label={label} />
			<div className="locked-input__pills">
				<Pills
					colorKey={colorKey}
					emptyLabel="No skills available"
					items={items}
					labelKey={labelKey}
				/>
			</div>
		</>
	);
};
