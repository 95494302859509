import * as React from 'react';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import NavigationItem from 'af-components/Header/ScheduleBoardNavigation/NavigationItem';

import OfficeInfo from '../../Shared/Header/OfficeInfo';
import EquipmentSection from './EquipmentSection';
import LaborSection from './LaborSection';

type OwnProps = {
	dueDate: string;
	showOfficeInfo: boolean;
};

type Props = OwnProps;

const Toolbar: React.FC<Props> = (props: Props) => {
	const { dueDate, showOfficeInfo } = props;

	return (
		<>
			<div className="display-view-header">
				<span className="display-view-header-title">{TimeUtils.formatDate(dueDate, TimeFormatEnum.SB_DATE_LONG)}</span>
				<div className="display-view-statistics-wrapper">
					{showOfficeInfo && <OfficeInfo dueDate={dueDate} />}
				</div>
				<div className="display-view-header-navigation">
					<NavigationItem />
				</div>
			</div>
			<div className="display-view-toolbar">
				<LaborSection dueDate={dueDate} />
				<EquipmentSection dueDate={dueDate} />
			</div>
		</>
	);
};

export default React.memo(Toolbar);
