import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

export default class Loading extends React.PureComponent {

	render() {
		return (
			<div>
				<div className="scrollable-elements">
					<div className="form-segment">
						<div className="loading-header" />
						<div className="form-box loading-box">
							<Row>
								<Col className="loading-box-row" sm={4} />
								<Col className="loading-box-row" sm={5} />
								<Col className="loading-box-row" sm={3} />
								<Col className="loading-box-row" sm={2} />
								<Col className="loading-box-row" sm={6} />
								<Col className="loading-box-row" sm={4} />
							</Row>
							<Row>
								<Col className="loading-box-row" sm={6} />
								<Col className="loading-box-row" sm={3} />
								<Col className="loading-box-row" sm={4} />
								<Col className="loading-box-row" sm={4} />
								<Col className="loading-box-row" sm={2} />
								<Col className="loading-box-row" sm={5} />
							</Row>
							<Row>
								<Col className="loading-box-row standalone" sm={3} />
								<Col className="loading-box-row standalone" sm={4} />
								<Col className="loading-box-row standalone" sm={6} />
								<Col className="loading-box-row standalone" sm={5} />
								<Col className="loading-box-row standalone" sm={2} />
								<Col className="loading-box-row standalone" sm={4} />
							</Row>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
