import * as React from 'react';
import { Button } from 'react-bootstrap';

import FileType from 'acceligent-shared/enums/fileType';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import styles from './styles.module.scss';

interface Props {
	attachmentData: { attachmentId: Nullable<number>; attachmentName: string; attachmentType: Nullable<FileType>; };
	showModal: boolean;
	setModalVisible: (arg0: boolean) => void;
	updateAttachment: (attachmentId: number, value: string | undefined) => void;
}

const EditAttachmentModal = React.memo((props: Props) => {
	const { attachmentData: { attachmentId, attachmentName, attachmentType }, showModal, setModalVisible, updateAttachment } = props;
	const inputRef = React.useRef<HTMLInputElement>(null);

	const baseClassName = 'modal';
	const headerClassName = `${baseClassName}__big-header__thin`;
	const bodyClassName = `${baseClassName}__body`;
	const inputFieldClassName = 'input-text-field';

	const updateName = () => {
		if (attachmentId) {
			const attName = inputRef.current?.value;
			const fileName = `${attName}${attachmentType}`;

			updateAttachment(attachmentId, fileName);
			setModalVisible(false);
		}
	};

	return (
		<>
			<CustomModal
				className={styles[baseClassName]}
				closeModal={setModalVisible.bind(this, false)}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header className={styles[headerClassName]}>
					<span>Rename</span>
				</CustomModal.Header>
				<CustomModal.Body className={styles[bodyClassName]}>

					<input
						className={styles[inputFieldClassName]}
						defaultValue={attachmentName}
						placeholder="New name"
						ref={inputRef}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={setModalVisible.bind(this, false)}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton label="Save" onClick={updateName.bind(this)} />
				</CustomModal.Footer>
			</CustomModal>
		</>
	);
});

export default EditAttachmentModal;
