import * as React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { RootState } from 'af-reducers';

import { isAllowed } from 'ab-utils/auth.util';
import * as TextUtil from 'ab-utils/text.util';
import * as EquipmentCostTreeUtil from 'ab-utils/equipmentCostTree.util';

import * as ResourceUtil from 'af-utils/resources.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import * as EquipmentCostActions from 'af-actions/equipmentCost';
import * as ReduxFormActions from 'af-actions/reduxForm/actions';

import { EQUIPMENT_COST_FILTER } from 'af-constants/reduxForms';
import { EQUIPMENT_COST_TREE_FILTER, SCREEN_BREAKPOINT_S, SCREEN_BREAKPOINT_XS } from 'af-constants/values';

import { TreeViewModel, EquipmentCostTreeDict } from 'ab-viewModels/equipmentCostTree.viewModel';
import * as User from 'ab-viewModels/user.viewModel';

import SearchBoxForm from 'ab-requestModels/searchBox.requestModel';

import { EquipmentCostDragEnd } from 'ab-paramModels/equipmentCost.params';

import RefreshModal from 'af-components/RefreshModal';
import Breadcrumbs from 'af-components/Breadcrumbs';

import TreeElement from './TreeElement';
import TreeHeader from './TreeHeader';
import TreeLoading from './TreeLoading';
import TreeEmpty from './TreeEmpty';

enum TableTypeEnum {
	DEFAULT = 'DEFAULT',
	DEFAULT_SMALL = 'DEFAULT_SMALL',
	SCROLLABLE = 'SCROLLABLE',
}

const _getTableType = (width: number): TableTypeEnum => {
	if (width < SCREEN_BREAKPOINT_XS) {
		return TableTypeEnum.SCROLLABLE;
	} else if (width < SCREEN_BREAKPOINT_S) {
		return TableTypeEnum.DEFAULT_SMALL;
	} else {
		return TableTypeEnum.DEFAULT;
	}
};

type OwnProps = CustomRouteComponentProps;

interface DispatchProps {
	findAllForCompanyTree: typeof EquipmentCostActions.findAllForCompanyTree;
	softDeleteEquipmentCostCategory: typeof EquipmentCostActions.softDeleteEquipmentCostCategory;
	softDeleteEquipmentCostTreeNode: typeof EquipmentCostActions.softDeleteEquipmentCostTreeNode;
	equipmentCostTreeNodeDragEnd: typeof EquipmentCostActions.equipmentCostTreeNodeDragEnd;
	resetForm: () => void;
}

interface StateProps {
	isAllowedToCreate: boolean;
	showRefreshModal: boolean;
	companyData: User.CompanyData;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps> & InjectedFormProps<SearchBoxForm>;

interface State {
	activeNode: number;
	showSmallButtons: boolean;
	tableType: TableTypeEnum;
	isAnyExpanded: boolean;
	tree: Nullable<TreeViewModel[]>;
	categoryNodes: Nullable<EquipmentCostTreeDict>;
	equipmentCostNodes: Nullable<EquipmentCostTreeDict>;
	filter: string;
	highlightedNodes: number[];
}

class EquipmentCostsTable extends React.Component<Props, State> {

	state: State = {
		tableType: _getTableType(window.innerWidth),
		activeNode: 0,
		showSmallButtons: false,
		isAnyExpanded: false,
		tree: null,
		categoryNodes: null,
		equipmentCostNodes: null,
		filter: '',
		highlightedNodes: [],
	};

	_filterInput: Nullable<HTMLElement> = null;

	async componentDidMount() {
		const { findAllForCompanyTree } = this.props;

		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		const { tree, categoryNodes, equipmentCostNodes } = await findAllForCompanyTree();
		this.setState(() => ({ tree, categoryNodes, equipmentCostNodes }));

		this._filterInput = document.getElementById(EQUIPMENT_COST_TREE_FILTER);
		if (this._filterInput) {
			this._filterInput.addEventListener('keypress', this.focusNode);
		}
	}

	async componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
		if (this._filterInput) {
			this._filterInput.removeEventListener('keypress', this.focusNode);
		}
	}

	updateWindowDimensions = () => {
		const _tableType = _getTableType(window.innerWidth);
		const _showSmallButtons = window.innerWidth < SCREEN_BREAKPOINT_S;

		this.setState((state: State) => {
			const showSmallButtons = state.showSmallButtons !== _showSmallButtons ? _showSmallButtons : state.showSmallButtons;
			const tableType = state.tableType !== _tableType ? _tableType : state.tableType;
			return {
				showSmallButtons,
				tableType,
			};
		});
	};

	focusNode = (event: KeyboardEvent) => {
		const isEnter = event.key && TextUtil.isLowerCaseEqual(event.key, 'enter');
		if (isEnter && !event.shiftKey) {
			this.incrementHighlightedNodeIndex();
		} else if (isEnter && event.shiftKey) {
			this.decrementHighlightedNodeIndex();
		}
	};

	incrementHighlightedNodeIndex = () => {
		const { highlightedNodes } = this.state;

		const increment = this.state.activeNode < (highlightedNodes.length - 1);
		if (increment) {
			this.setState(({ activeNode }) => ({ activeNode: activeNode + 1 }), () => {
				this.scrollNodeIntoView();
			});
		}
	};

	decrementHighlightedNodeIndex = () => {
		const decrement = this.state.activeNode > 0;
		if (decrement) {
			this.setState(({ activeNode }) => ({ activeNode: activeNode - 1 }), () => {
				this.scrollNodeIntoView();
			});
		}
	};

	scrollNodeIntoView = () => {
		const { activeNode, highlightedNodes } = this.state;

		const node = document.getElementById(`node${highlightedNodes[activeNode]}`);
		if (node) {
			node.scrollIntoView({ behavior: 'smooth' });
		}
	};

	deleteEquipmentCostCategory = async (id: number, isCategory: boolean) => {
		const { softDeleteEquipmentCostCategory } = this.props;
		const { tree } = this.state;

		await softDeleteEquipmentCostCategory(id);

		const newTree = EquipmentCostTreeUtil.removeEquipmentCostTreeNode(tree ?? [], id, isCategory);
		this.setState(() => ({ tree: newTree }));
	};

	deleteEquipmentCost = async (id: number, isCategory: boolean) => {
		const { softDeleteEquipmentCostTreeNode } = this.props;
		const { tree } = this.state;

		await softDeleteEquipmentCostTreeNode(id);

		const newTree = EquipmentCostTreeUtil.removeEquipmentCostTreeNode(tree ?? [], id, isCategory);
		this.setState(() => ({ tree: newTree }));
	};

	renderTreeElements = () => {
		const { location, history, companyData: { name: companyName } } = this.props;
		const { activeNode, showSmallButtons, tableType, tree, categoryNodes, equipmentCostNodes } = this.state;

		if (!tree) {
			return <TreeLoading />;
		}

		if (!tree.length) {
			return <TreeEmpty />;
		}

		const highlightedNodes = {};

		return tree.map((_element, _index, array) => {
			const node: TreeViewModel | undefined = _element.isCategory
				? categoryNodes?.[_element.nodeId]
				: equipmentCostNodes?.[_element.nodeId];

			if (!node) {
				throw new Error('Tree node not found');
			}

			const element = {
				...node,
				children: _element.children,
				count: EquipmentCostTreeUtil.countEquipmentCosts(_element.children ?? []),
				hasEquipmentCostChildren: _element.hasEquipmentCostChildren,
			};

			return (
				<TreeElement
					activeNode={highlightedNodes[activeNode]}
					categoryNodes={categoryNodes}
					companyName={companyName}
					deleteEquipmentCost={this.deleteEquipmentCost}
					deleteEquipmentCostCategory={this.deleteEquipmentCostCategory}
					element={element}
					equipmentCostNodes={equipmentCostNodes}
					hasBottomBorder={(_index + 1) !== array.length}
					history={history}
					index={_index}
					key={`${element.nodeId}${_index}`}
					level={0}
					location={location}
					onToggle={this.onToggle}
					showScrollableTable={tableType === TableTypeEnum.SCROLLABLE}
					showSmallButtons={showSmallButtons}
				/>
			);
		});
	};

	onDragEnd = async ({ source, destination, draggableId }: DropResult) => {
		const { equipmentCostTreeNodeDragEnd } = this.props;
		const { tree } = this.state;

		if (!!destination) {
			const { label, id } = ResourceUtil.parseEquipmentCosTreeNodeId(draggableId);
			const dragEndData: EquipmentCostDragEnd = { label, id, source, destination };

			const newTree = EquipmentCostTreeUtil.reorderEquipmentCostTree(tree ?? [], dragEndData);
			this.setState(() => ({ tree: newTree }), async () => {
				await equipmentCostTreeNodeDragEnd(dragEndData);
			});
		}
	};

	onFilterClear = () => {
		const { resetForm } = this.props;

		resetForm();
		this.onFilter('');
	};

	onFilter = (query: string) => {
		const { equipmentCostNodes, categoryNodes, tree } = this.state;

		const newEquipmentCostNodes = query
			? EquipmentCostTreeUtil.highlightEquipmentCostNodes(query, equipmentCostNodes ?? undefined)
			: EquipmentCostTreeUtil.resetHighlightingForNodes(equipmentCostNodes ?? {});
		const newCategoryNodes = query
			? EquipmentCostTreeUtil.highlightCategoryNodes(query, categoryNodes ?? undefined, newEquipmentCostNodes)
			: EquipmentCostTreeUtil.resetHighlightingForNodes(categoryNodes ?? {});

		const highlightedNodes: number[] = [];
		const highlight = (children: TreeNodeId[]) => {
			children.forEach((_element) => {
				const element = _element.isCategory ? categoryNodes?.[_element.nodeId] : equipmentCostNodes?.[_element.nodeId];
				if (!element) {
					throw new Error('Element not found');
				}

				if (element.isHighlighted) {
					highlightedNodes.push(_element.nodeId);
				}
				highlight(_element.children ?? []);
			});
		};

		if (tree) {
			highlight(tree);
		}

		this.setState(() => ({
			equipmentCostNodes: newEquipmentCostNodes,
			categoryNodes: newCategoryNodes,
			activeNode: 0,
			filter: query,
			highlightedNodes,
		}), async () => {
			if (query) {
				this.scrollNodeIntoView();
			}
		});
	};

	breadcrumbs = () => [{ label: 'Equipment Cost' }];

	onToggle = (nodeId: number) => {
		const { categoryNodes } = this.state;

		if (!categoryNodes) {
			return;
		}

		categoryNodes[nodeId].toggled = !categoryNodes[nodeId].toggled;

		const isAnyExpanded = Object.keys(categoryNodes).some((_nodeId) => categoryNodes[_nodeId].toggled);

		this.setState(() => ({ categoryNodes: { ...categoryNodes }, isAnyExpanded }));
	};

	onExpandAll = async () => {
		const { categoryNodes } = this.state;

		if (!categoryNodes) {
			return;
		}

		Object.keys(categoryNodes).forEach((_nodeId) => {
			categoryNodes[_nodeId].toggled = true;
		});

		this.setState(() => ({ categoryNodes: { ...categoryNodes }, isAnyExpanded: true }));
	};

	onCollapseAll = async () => {
		const { categoryNodes } = this.state;

		if (!categoryNodes) {
			return;
		}

		Object.keys(categoryNodes).forEach((_nodeId) => {
			categoryNodes[_nodeId].toggled = false;
		});

		this.setState(() => ({ categoryNodes: { ...categoryNodes }, isAnyExpanded: false }));
	};

	render() {
		const { activeNode, showSmallButtons, tableType } = this.state;
		const {
			showRefreshModal,
			isAllowedToCreate,
			companyData: { name: companyName },
			location: { state: { orgAlias } },
		} = this.props;
		const { isAnyExpanded, highlightedNodes } = this.state;
		const dataFiltered = highlightedNodes.length ? `${activeNode + 1}/${highlightedNodes.length}` : '';

		return (
			<>
				<DragDropContext onDragEnd={this.onDragEnd} >
					<div className="form-segment form-segment--maxi tree-table">
						<Breadcrumbs items={this.breadcrumbs()} />
						<div className={`form-box ${tableType === TableTypeEnum.SCROLLABLE ? 'scroll-to-load' : ''}`}>
							<TreeHeader
								collapseAll={this.onCollapseAll}
								companyName={companyName}
								dataFiltered={dataFiltered}
								decrementHighlightedNodeIndex={this.decrementHighlightedNodeIndex}
								expandAll={this.onExpandAll}
								incrementHighlightedNodeIndex={this.incrementHighlightedNodeIndex}
								isAllowedToCreate={isAllowedToCreate}
								isAnyExpanded={isAnyExpanded}
								onClear={this.onFilterClear}
								onFilter={this.onFilter}
								orgAlias={orgAlias}
								showSmallButtons={showSmallButtons}
								showToolbarHeaders={tableType !== TableTypeEnum.SCROLLABLE}
							/>
							{this.renderTreeElements()}
						</div>
					</div>
				</DragDropContext>
				<RefreshModal message="Please refresh the page to get latest data." showModal={showRefreshModal} />
			</>
		);
	}
}

type TreeNodeId = { nodeId: number; isCategory?: boolean; children?: TreeNodeId[]; };
function mapStateToProps(state: RootState): StateProps {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isAllowedToCreate = isAllowed(PagePermissions.COMPANY.RESOURCES.EQUIPMENT_COST, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	return {
		isAllowedToCreate,
		showRefreshModal: state.general.showRefreshModal,
		companyData,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllForCompanyTree: EquipmentCostActions.findAllForCompanyTree,
		softDeleteEquipmentCostCategory: EquipmentCostActions.softDeleteEquipmentCostCategory,
		softDeleteEquipmentCostTreeNode: EquipmentCostActions.softDeleteEquipmentCostTreeNode,
		equipmentCostTreeNodeDragEnd: EquipmentCostActions.equipmentCostTreeNodeDragEnd,
		resetForm: () => ReduxFormActions.resetForm(EQUIPMENT_COST_FILTER),
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm({ form: EQUIPMENT_COST_FILTER })
);

export default enhance(EquipmentCostsTable);
