import * as React from 'react';
import { Field, FieldArray, InjectedFormProps } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import LocationRequestModel from 'ab-requestModels/location.requestModel';

import SubmitButton from 'af-components/SubmitButton';
import BackButton from 'af-components/BackButton';

import Input from 'af-fields/Input';
import ColorPicker from 'af-fields/ColorPicker';
import AddressField from 'af-fields/AddressField';
import Checkbox from 'af-fields/Checkbox';

import Department from './DepartmentList';

import { phoneNormalizer } from 'ab-utils/reduxForms.util';

interface PathParams {
	id?: string;
}

interface OwnProps {
	onSubmit: (form: LocationRequestModel) => void;
}

type Props = OwnProps & CustomRouteComponentProps<PathParams> & InjectedFormProps<LocationRequestModel>;

class LocationForm extends React.Component<Props> {

	onColorFocus = () => {
		const { touch } = this.props;
		touch('color');
	};

	render() {
		const {
			handleSubmit,
			form,
			submitting,
			invalid,
			onSubmit,
		} = this.props;

		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-box">
					<Row className="row--padded-top">
						<Col md={12}>
							<Field
								component={Input}
								label="Nickname *"
								name="nickname"
								placeholder="Enter nickname"
								type="text"
							/>
						</Col>
						<Col md={5}>
							<Field
								component={ColorPicker}
								label="Color *"
								name="color"
								onFocus={this.onColorFocus}
							/>
						</Col>
						<Col className="m-b-xs" md={7}>
							<Field
								component={Checkbox}
								inline={true}
								label="Show in labor statistics"
								name="showInStatistics"
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Field
								component={Input}
								label="Mobile Phone *"
								name="phone"
								normalize={phoneNormalizer}
								placeholder="Enter mobile phone"
								type="text"
							/>
						</Col>
						<Col md={12}>
							<Field
								component={Input}
								label="Fax *"
								name="fax"
								normalize={phoneNormalizer}
								placeholder="Enter fax"
								type="text"
							/>
						</Col>
					</Row>
					<AddressField
						aa1PropName="aa1"
						aa2PropName="aa2"
						aa3PropName="aa3"
						countryPropName="country"
						disableMap={true}
						formName={form}
						latitudePropName="latitude"
						localityPropName="locality"
						locationPropName="street"
						longitudePropName="longitude"
						postalCodePropName="postalCode"
						postalOfficeBoxPropName="postalOfficeBoxCode"
						routePropName="route"
						streetNumberPropName="streetNumber"
						suitePropName="suite"
					/>
					<Row>
						<Col md={24}>
							<FieldArray
								component={Department}
								label="Mobile Phone *"
								name="departments"
							/>
						</Col>
					</Row>
					<div className="form-box__after">
						<div />
						<div>
							<BackButton />
							<SubmitButton
								disabled={invalid}
								reduxFormSubmitting={submitting}
							/>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

export default LocationForm;
