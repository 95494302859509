import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps, ResolveThunks } from 'react-redux';
import { compose } from 'redux';
import { Button } from 'react-bootstrap';
import { FileRejection } from 'react-dropzone';
import { reduxForm, InjectedFormProps, FieldArray, getFormSyncErrors, getFormValues, FormErrorsWithArray, Field } from 'redux-form';
import { nanoid } from 'nanoid';

import BlobStorageContainer from 'acceligent-shared/enums/blobStorageContainer';
import FileType, { IMAGE_FILE_TYPES } from 'acceligent-shared/enums/fileType';

import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';
import { NotificationSnackbarTypes } from 'ab-enums/notificationSnackbarContext.enum';

import { PendingAttachmentVM } from 'ab-viewModels/attachment/pendingAttachment.viewModel';

import { getId } from 'ab-utils/array.util';
import * as ReduxUtils from 'ab-utils/reduxForms.util';

import * as JobActions from 'af-actions/jobs';
import * as AttachmentActions from 'af-actions/attachment';

import Breadcrumbs from 'af-components/Breadcrumbs';
import SubmitButton from 'af-components/SubmitButton';
import LockedValue from 'af-components/LockedValue';
import { RouteParams } from 'af-components/Header';
import DropzoneWithButton from 'af-components/DropzoneWithButton';
import FileUploadMessageWithTypes from 'af-components/Dropzone/FileUploadMessageWithTypes';
import ConfirmationModal from 'af-components/ConfirmationModal';

import * as FORMS from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import Input from 'af-fields/Input';

import { RootState } from 'af-reducers';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import { dollarFormatter } from 'af-utils/format.util';

import styles from './styles.module.scss';
import InstallmentItems, { OwnProps as InstallmentArrayProps, InstallmentFM } from './Installments/InstallmentItems';
import { AttachmentFM, InvoiceFM } from './formModel';
import BillingContactArray, { OwnProps as BillingContactArrayProps } from '../BillingContacts/BillingContactArray';
import { PreviewTabsEnum } from '../..';
import AttachmentItems, { OwnProps as AttachmentArrayProps } from '../Attachments/AttachmentItems';
import Checkbox from 'af-fields/Checkbox';
import Tooltip from 'af-components/Tooltip';

interface PathParams {
	jobId: string;
	invoiceId: string;
}

type OwnProps = CustomRouteComponentProps<RouteParams & PathParams>;

interface DispatchProps {
	updateInvoicedInvoice: typeof JobActions.updateInvoicedInvoice;
	getInvoiceById: typeof JobActions.getInvoiceById;
	areAutomaticRemindersEnabled: typeof JobActions.areAutomaticRemindersEnabled;
	sendManualReminderForInvoice: typeof JobActions.sendManualReminderForInvoice;
	uploadPendingAttachments: typeof AttachmentActions.uploadPendingAttachments;
	deletePendingAttachments: typeof AttachmentActions.deletePendingAttachments;
}

type FormOwnProps = ConnectedProps<typeof connector> & ResolveThunks<DispatchProps> & OwnProps;
type Props = FormOwnProps & InjectedFormProps<InvoiceFM>;

interface NotificationData {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

const InvoiceSectionInvoiced: React.FC<Props> = (props) => {

	const {
		updateInvoicedInvoice,
		getInvoiceById,
		areAutomaticRemindersEnabled,
		sendManualReminderForInvoice,
		uploadPendingAttachments,
		deletePendingAttachments,
		history,
		match: { params: { jobId, invoiceId } },
		location: { state: { orgAlias, jobCode, backToAccountingPage } },
		companyName,
		handleSubmit,
		submitting,
		initialize,
		change,
		valid,
		currentFormValues,
		errors,
		initialized,
		uploadProgress,
		isSendingInvoiceNotificationsEnabled,
		pristine,
	} = props;

	const [startingBillingContacts, setStartingBillingContacts] = React.useState<string[]>([]);
	const [automaticRemindersEnabled, setAutomaticRemindersEnabled] = React.useState<boolean>(false);
	const [hasInstallmentsInEditMode, setHasInstallmentsInEditMode] = React.useState(false); // Can't use dirty or pristine since we use change with initialization
	const [showLeaveModal, setShowLeaveModal] = React.useState<boolean>(false);
	const [totalPriceChanged, setTotalPriceChanged] = React.useState<boolean>(false);
	const [showChangePriceModal, setShowChangePriceModal] = React.useState<boolean>(false);

	const notificationSnackbar = useNotificationSnackbar();

	const ALLOWED_ATTACHMENT_FILE_TYPES_MIME = {
		'image/*': IMAGE_FILE_TYPES,
		'application/*': [
			FileType.PDF,
			FileType.XLSX,
			FileType.XLS,
			FileType.DOCX,
			FileType.DOC,
		],
		'text/*': [FileType.CSV],
	};

	const LEAVE_PAGE_MODAL_BODY = (
		<>
			You are about to leave this page without saving.
			<br />
			Changes that you made will not be saved.
		</>
	);

	const CHANGE_TOTAL_PRICE_MODAL_BODY = (
		<>
			You are about change the total price for invoiced invoice.
			<br />
			Are you sure?
		</>
	);

	const onTotalAmountChange = React.useCallback((value: string) => {
		const outstandingDebt = +(value ?? 0) - (currentFormValues?.paidAmount ?? 0);
		if (currentFormValues.installments.length > 0) {
			if (outstandingDebt < 0) {
				change('status', InvoiceStatusDisplay.OVERPAID);
			} else if (outstandingDebt === 0) {
				change('status', InvoiceStatusDisplay.PAID);
			} else {
				change('status', InvoiceStatusDisplay.PARTIALLY_PAID);
			}
		}
		change('outstandingDebt', outstandingDebt < 0 ? 0 : outstandingDebt);
		setTotalPriceChanged(true);
	}, [change, currentFormValues]);

	const closeLeaveModal = React.useCallback(() => setShowLeaveModal(false), []);

	const closeChangePriceModal = React.useCallback(() => setShowChangePriceModal(false), []);

	const openChangePriceModal = React.useCallback(() => setShowChangePriceModal(true), []);

	const fetchAndInitializeData = React.useCallback(async () => {
		// get company setting for notifications
		const areAutomaticInvoiceRemindersEnabledForCompany = await areAutomaticRemindersEnabled();
		setAutomaticRemindersEnabled(areAutomaticInvoiceRemindersEnabledForCompany);

		const invoiceVM = await getInvoiceById(+jobId, +invoiceId);
		if (!invoiceVM) {
			throw new Error('Invoice not found');
		}
		initialize(InvoiceFM.fromVMtoFM(invoiceVM));
		setStartingBillingContacts(invoiceVM.billingEmails);
	}, [getInvoiceById, areAutomaticRemindersEnabled, initialize, invoiceId, jobId]);

	// in order to be used only once the component is mounted, deps are left empty
	React.useEffect(() => {
		fetchAndInitializeData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resolveInvoiceStatus = React.useCallback((totalAmount: number, paidAmount: number) => {
		const differenceBetweenOwedAndPaid = totalAmount - paidAmount;
		if (differenceBetweenOwedAndPaid < 0) {
			return InvoiceStatusDisplay.OVERPAID;
		} else if (differenceBetweenOwedAndPaid === 0) {
			return InvoiceStatusDisplay.PAID;
		} else if (differenceBetweenOwedAndPaid > 0 && differenceBetweenOwedAndPaid < totalAmount) {
			return InvoiceStatusDisplay.PARTIALLY_PAID;
		} else {
			return InvoiceStatusDisplay.INVOICED;
		}
	}, []);

	const updateTotalPaid = React.useCallback(async (installments: InstallmentFM[]) => {
		if (installments && currentFormValues) {
			const recalculatedTotalPaid = InvoiceFM.recalculatePaidAmount(installments);
			change('paidAmount', recalculatedTotalPaid);
			const totalPaidDiff = currentFormValues.totalAmount - recalculatedTotalPaid;
			change('outstandingDebt', totalPaidDiff > 0 ? totalPaidDiff : 0);
			change('status', resolveInvoiceStatus(currentFormValues.totalAmount, recalculatedTotalPaid));
		}
	}, [change, currentFormValues, resolveInvoiceStatus]);

	const clearPendingAttachments = React.useCallback(async () => {
		if (currentFormValues?.uploadedAttachmentIds?.length) {
			await deletePendingAttachments({ attachmentIds: currentFormValues?.uploadedAttachmentIds });
		}
	}, [currentFormValues?.uploadedAttachmentIds, deletePendingAttachments]);

	const onBack = React.useCallback(() => {
		setShowLeaveModal(true);
	}, []);

	const onSendManualReminder = React.useCallback(async () => {
		const notification: Nullable<NotificationData> = notificationSnackbar.loading('Sending invoice reminder to billing contacts.', new Date());
		try {
			await sendManualReminderForInvoice(+jobId, +invoiceId);
			if (notification) {
				notificationSnackbar.removeNotificationSnackbar(notification);
				const notificationText = `Reminders for invoice ${currentFormValues.invoiceCode} were sent to the billing contacts.`;
				notificationSnackbar.success(notificationText, new Date());
			}
		} catch (error) {
			if (error.errors.billingContacts) {
				notificationSnackbar.removeNotificationSnackbar(notification);
				const notificationText = error.errors.billingContacts;
				notificationSnackbar.error(notificationText, new Date());
			}
		}

	}, [currentFormValues?.invoiceCode, invoiceId, jobId, notificationSnackbar, sendManualReminderForInvoice]);

	const goBackToTable = React.useCallback(() => {
		clearPendingAttachments();
		if (backToAccountingPage) {
			history.push({
				pathname: CLIENT.COMPANY.ACCOUNTING.INVOICES.TABLE(orgAlias, companyName),
				state: {
					orgAlias,
				},
			});
		} else {
			history.push({
				pathname: CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, jobId),
				state: {
					orgAlias,
					activeTabEnum: PreviewTabsEnum.INVOICES,
				},
			});
		}
	}, [companyName, history, jobId, orgAlias, backToAccountingPage, clearPendingAttachments]);

	const onSubmit = React.useCallback(async (form: InvoiceFM) => {
		if (form.id) {
			await updateInvoicedInvoice(+jobId, form.id, InvoiceFM.fromFMtoRM(form));
			goBackToTable();
		} else {
			throw new Error('Missing invoice id');
		}
	}, [goBackToTable, jobId, updateInvoicedInvoice]);

	const renderPaidAmount = () => {
		if (currentFormValues.status === InvoiceStatusDisplay.PAID) {
			return (
				<LockedValue label="Total Paid"
					value={dollarFormatter.format(currentFormValues.paidAmount)}
					valueClassName={styles['invoice-form__tab-content__form__section__row__column-total-paid__paid']}
				/>
			);
		}
		if (currentFormValues.status === InvoiceStatusDisplay.OVERPAID) {
			const overpaidAmount = dollarFormatter.format(currentFormValues.paidAmount - currentFormValues.totalAmount);
			const innerChildNode = (
				<>
					<span>{dollarFormatter.format(currentFormValues.paidAmount)} </span>
					<span className={styles['invoice-form__tab-content__form__section__row__column-total-paid__overpaid']}>(+{overpaidAmount})</span>
				</>
			);
			return (
				<LockedValue label="Total Paid"
					value={innerChildNode}
					valueClassName={styles['invoice-form__tab-content__form__section__row__column-total-paid']}
				/>
			);
		}

		return (
			<>
				<LockedValue label="Total Paid"
					value={dollarFormatter.format(currentFormValues.paidAmount)}
				/>
			</>
		);
	};

	const renderInvoiceStatus = React.useCallback((invoiceStatus: InvoiceStatusDisplay) => {
		const cellStyle: string[] = [];
		cellStyle.push(styles['invoice__status-cell']);

		switch (invoiceStatus) {
			case InvoiceStatusDisplay.DRAFT: {
				cellStyle.push(styles['invoice__status-cell--draft']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-draft" />
						<b>Draft</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.INVOICED: {
				cellStyle.push(styles['invoice__status-cell--invoiced']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-invoice_status_invoiced" />
						<b>Invoiced</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.PARTIALLY_PAID: {
				cellStyle.push(styles['invoice__status-cell--partially-paid']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-check" />
						<b>Partially paid</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.PAID: {
				cellStyle.push(styles['invoice__status-cell--paid']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-check" />
						<b>Paid</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.OVERPAID: {
				cellStyle.push(styles['invoice__status-cell--overpaid']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-check" />
						<b>Overpaid</b>
					</span>
				);
			}
			default: {
				cellStyle.push(styles['invoice__empty-cell']);
				return (
					<span className={cellStyle.join(' ')}>
						N/A
					</span>
				);
			}
		}
	}, []);

	const handleAttachmentsUpload = React.useCallback((attachmentIds: number[]) => {
		change('uploadedAttachmentIds', [...attachmentIds]);
	}, [change]);

	const handleAttachmentDeleted = React.useCallback((attachmentId: number) => {
		const indexOfAttachmentId = currentFormValues.uploadedAttachmentIds?.findIndex((_id) => _id === attachmentId);
		if (indexOfAttachmentId !== undefined && indexOfAttachmentId !== -1 && currentFormValues.uploadedAttachmentIds) {
			const nextAttachmentIds = [...currentFormValues.uploadedAttachmentIds];
			nextAttachmentIds.splice(indexOfAttachmentId, 1);
			change('uploadedAttachmentIds', nextAttachmentIds);
		}
	}, [change, currentFormValues]);

	const handleAttachmentsChanged = React.useCallback((changedAttachments: AttachmentFM[]) => {
		change('attachments', changedAttachments);
	}, [change]);

	const handleAttachmentUploadStart = React.useCallback((correlationId: string) => {
		const nextUploadingAttachments = { ...currentFormValues.uploadingAttachments };
		nextUploadingAttachments[correlationId] = true;
		change('uploadingAttachments', nextUploadingAttachments);
	}, [change, currentFormValues]);

	const handleAttachmentUploadEnd = React.useCallback((correlationId: string) => {
		const nextUploadingAttachments = { ...currentFormValues.uploadingAttachments };
		delete nextUploadingAttachments[correlationId];
		change('uploadingAttachments', nextUploadingAttachments);
	}, [change, currentFormValues]);

	const handleFileUpload = React.useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {

		if (fileRejections.length > 0) {
			let message = `Uploading of ${fileRejections[0].file.name} failed.`;
			if (fileRejections[0].errors[0].code === 'file-invalid-type') {
				message = 'Uploading failed - File type not supported';
			}
			notificationSnackbar.error(message, new Date());
			return;
		}

		const correlationId = nanoid();
		handleAttachmentUploadStart?.(correlationId);
		let uploadedAttachments: PendingAttachmentVM[] = [];

		const notification: Nullable<NotificationData> = notificationSnackbar.loading(`Uploading ${acceptedFiles[0].name}`, new Date());

		try {
			uploadedAttachments = await uploadPendingAttachments({ requestFiles: acceptedFiles }, BlobStorageContainer.INVOICES_ATTACHMENTS);

			if (notification) {
				notificationSnackbar.removeNotificationSnackbar(notification);
				const notificationText = `${acceptedFiles[0].name} has successfully been uploaded.`;
				notificationSnackbar.success(notificationText, new Date());
			}
		} finally {
			handleAttachmentUploadEnd?.(correlationId);
		}

		const nextUploadedAttachmentIds = [...(currentFormValues.uploadedAttachmentIds ?? []), ...uploadedAttachments.map(getId)];
		handleAttachmentsChanged([
			...(currentFormValues.attachments ?? []),
			...(uploadedAttachments.map((_att) => { return { ..._att, fileName: _att.name }; })),
		]);
		handleAttachmentsUpload(nextUploadedAttachmentIds);
	}, [
		currentFormValues,
		handleAttachmentUploadEnd,
		handleAttachmentUploadStart,
		handleAttachmentsChanged,
		handleAttachmentsUpload,
		uploadPendingAttachments,
		notificationSnackbar,
	]);

	const saveForm = React.useCallback(() => {
		if (totalPriceChanged) {
			openChangePriceModal();
		} else {
			handleSubmit(onSubmit)();
		}
	}, [handleSubmit, onSubmit, openChangePriceModal, totalPriceChanged]);

	const renderInvoiceSections = () => {

		if (!currentFormValues) {
			return null;
		}

		const uploading = uploadProgress !== 100 && uploadProgress !== 0;

		const style: Partial<React.CSSProperties> | undefined = uploading
			? { marginTop: '15px', padding: '1px', background: `linear-gradient(to right, #9FCAFF ${uploadProgress}%, transparent ${uploadProgress}% 100%)` }
			: { marginTop: '15px' };

		const manualReminderTooltipMessage =
			<>
				<strong>Send Manual Reminder</strong> is disabled until all changes on the form have been saved.
				Reminder will be sent to the emails listed bellow as billing contacts.
			</>;

		return (
			<>
				<div className={styles['invoice-form__tab-content__form']}>
					<div className={styles['invoice-form__submit-section']}>
						<div className={styles['invoice-form__submit-section__invoice-code-and-reminder']}>
							<span className={styles['invoice-form__submit-section__invoice-code-and-reminder__code']}>
								{currentFormValues.invoiceCode}
							</span>

							{
								isSendingInvoiceNotificationsEnabled &&
								<Tooltip message={manualReminderTooltipMessage}>
									<Button
										disabled={!pristine
											|| !startingBillingContacts.length
											|| currentFormValues.status === InvoiceStatusDisplay.PAID
											|| currentFormValues.status === InvoiceStatusDisplay.OVERPAID}
										onClick={onSendManualReminder}
										variant="info"
									>
										<span className="icon-email" />
										Send Manual Reminder
									</Button>
								</Tooltip>
							}

						</div>
						<Button className={styles['invoice-form__back-to-list']} onClick={onBack} variant="info">
							Back
						</Button>
						<SubmitButton
							disabled={!isSaveFormEnabled}
							label="Save"
							onClick={saveForm}
							reduxFormSubmitting={submitting}
						/>
					</div>
					<div className={styles['invoice-form__tab-content__form__section']}>
						<div className={styles['invoice-form__tab-content__form__section__title']}>
							GENERAL INFO
						</div>
						<div className={styles['invoice-form__tab-content__form__section__row']}>
							<div className={styles['invoice-form__tab-content__form__section__row__column-2']}>
								<LockedValue label="Invoice ID" value={currentFormValues.invoiceCode} />
							</div>
							<div className={styles['invoice-form__tab-content__form__section__row__column-2']}>
								<LockedValue label="Date Created" value={currentFormValues.dateCreated} />
							</div>
							<div className={styles['invoice-form__tab-content__form__section__row__column-2']}>
								<LockedValue label="Due Date" value={currentFormValues.dueDate} />
							</div>
							<div className={styles['invoice-form__tab-content__form__section__row__column-invoice']}>
								<LockedValue label="Invoice Status" value={renderInvoiceStatus(currentFormValues.status)} />
							</div>
						</div>
						<div className={styles['invoice-form__tab-content__form__section__row']}>
							<div className={styles['invoice-form__tab-content__form__section__row__column-2']}>
								<LockedValue label="Note" value={currentFormValues.note} />
							</div>
						</div>
					</div>
					<div className={styles['invoice-form__tab-content__form__section']}>
						<div className={styles['invoice-form__tab-content__form__section__title']}>
							BILLING CONTACTS
						</div>
						<FieldArray<BillingContactArrayProps>
							change={change}
							component={BillingContactArray}
							name="billingContacts"
							rerenderOnEveryChange={true}
						/>
					</div>
					{
						isSendingInvoiceNotificationsEnabled &&
						<div className={styles['invoice-form__tab-content__form__section']}>
							<div className={styles['invoice-form__tab-content__form__section__title']}>
								REMINDERS
							</div>
							<Field
								component={Checkbox}
								inline={true}
								isDisabled={true}
								label="Send email to billing contacts when invoicing"
								name="sendReminderOnInvoice"
							/>
							{
								automaticRemindersEnabled &&
								<Field
									component={Checkbox}
									inline={true}
									label="Exclude from automatic reminders"
									name="excludeFromAutomaticReminders"
								/>
							}
						</div>
					}
					<div className={styles['invoice-form__tab-content__form__section']}>
						<div className={styles['invoice-form__tab-content__form__section__title']}>
							PAYMENT INFORMATION
						</div>
						<div className={styles['invoice-form__tab-content__form__section__row']}>
							<div className={styles['invoice-form__tab-content__form__section__row__column-2']}>
								<Field
									component={Input}
									id="totalAmount"
									isDollarValue={true}
									isRequired={true}
									label="Total"
									name={InvoiceFM.getAttributeName('totalAmount')}
									normalize={ReduxUtils.normalizeDecimalNumber}
									onValueChange={onTotalAmountChange}
									required={true}
									type="number"
								/>
							</div>
							<div className={styles['invoice-form__tab-content__form__section__row__column-total-paid']}>
								{renderPaidAmount()}
							</div>
							<div className={styles['invoice-form__tab-content__form__section__row__column-2']}>
								<LockedValue label="Outstanding" value={dollarFormatter.format(currentFormValues.outstandingDebt)} />
							</div>
							<div className={styles['invoice-form__tab-content__form__section__row__column-2']}>
								<Field
									component={Input}
									id="retainedAmount"
									isDollarValue={true}
									label="Retained Amount"
									name="retainedAmount"
									normalize={ReduxUtils.normalizeDecimalNumber}
									type="number"
								/>
							</div>
						</div>
					</div>
					<div className={styles['invoice-form__tab-content__form__section']}>

						<div className={styles['invoice-form__tab-content__form__section__title']}>
							INSTALLMENTS
						</div>
						<div className={styles['invoice-form__tab-content__form__section__installment-notice']}>
							Please note that when You add an installment in the table,
							You still have to click Save button in the right corner of the page for changes to be saved.
						</div>
						<FieldArray<InstallmentArrayProps>
							component={InstallmentItems}
							errors={errors}
							initialized={initialized}
							invoiceId={currentFormValues.id}
							name="installments"
							rerenderOnEveryChange={true}
							setUnsavedChanges={setHasInstallmentsInEditMode}
							updateTotalPaid={updateTotalPaid}
						/>
					</div>
					<div className={styles['invoice-form__tab-content__form__section']}>

						<div className={styles['invoice-form__tab-content__form__section__title']}>
							ATTACHMENTS
						</div>
						<FieldArray<AttachmentArrayProps>
							component={AttachmentItems}
							errors={errors}
							initialized={initialized}
							name="attachments"
							onDelete={handleAttachmentDeleted}
							rerenderOnEveryChange={true}
						/>
						<DropzoneWithButton
							allowedTypes={ALLOWED_ATTACHMENT_FILE_TYPES_MIME}
							onDrop={handleFileUpload}
							style={style}
						>
							<FileUploadMessageWithTypes
								uploading={uploading}
							/>
						</DropzoneWithButton>
					</div>
				</div>
				<ConfirmationModal
					body={LEAVE_PAGE_MODAL_BODY}
					closeModal={closeLeaveModal}
					closeText="Stay"
					confirmAction={goBackToTable}
					confirmText="Leave"
					modalStyle="danger"
					showModal={showLeaveModal}
					size="md"
					title="Leave Page?"
				/>
				<ConfirmationModal
					body={CHANGE_TOTAL_PRICE_MODAL_BODY}
					closeModal={closeChangePriceModal}
					closeText="Cancel"
					confirmAction={handleSubmit(onSubmit)}
					confirmText="I understand"
					modalStyle="danger"
					showModal={showChangePriceModal}
					size="md"
					title="Change Total Price?"
				/>
			</>
		);
	};

	const isSaveFormEnabled = React.useMemo(() => {
		return InvoiceFM.saveFormEnabled(valid, currentFormValues, hasInstallmentsInEditMode);
	}, [currentFormValues, valid, hasInstallmentsInEditMode]);

	return (
		<>
			<Breadcrumbs
				items={[
					{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
					{ label: jobCode, url: CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, jobId) },
					{ label: 'Invoices', onClick: goBackToTable },
					{ label: invoiceId ? 'Edit' : 'New Invoice' },
				]}
			/>
			{renderInvoiceSections()}

		</>
	);

};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const currentFormValues = getFormValues(FORMS.INVOICE_CREATE_FORM)(state) as InvoiceFM;

	const errors = getFormSyncErrors(FORMS.INVOICE_CREATE_FORM)(state) as FormErrorsWithArray<InvoiceFM, string>;

	return {
		companyName: companyData.name,
		isSendingInvoiceNotificationsEnabled: companyData.isSendingInvoiceNotificationsEnabled,
		currentFormValues,
		errors,
		uploadProgress: state.http.uploadProgress,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		updateInvoicedInvoice: JobActions.updateInvoicedInvoice,
		getInvoiceById: JobActions.getInvoiceById,
		areAutomaticRemindersEnabled: JobActions.areAutomaticRemindersEnabled,
		sendManualReminderForInvoice: JobActions.sendManualReminderForInvoice,
		uploadPendingAttachments: AttachmentActions.uploadPendingAttachments,
		deletePendingAttachments: AttachmentActions.deletePendingAttachments,
	};
}

const formConnector = reduxForm<InvoiceFM>({
	form: FORMS.INVOICE_CREATE_FORM,
	validate: InvoiceFM.validate,

});

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	formConnector,
	connector
);

export default enhance(InvoiceSectionInvoiced);
