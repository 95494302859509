export enum DailyViewDragElementType {
	INVALID = 'INVALID',
	DEFAULT_LABOR_PLACEHOLDER = 'DEFAULT_LABOR_PLACEHOLDER',
	DEFAULT_EQUIPMENT_PLACEHOLDER = 'DEFAULT_EQUIPMENT_PLACEHOLDER',
	WORK_ORDER = 'WORK_ORDER',
	INVALID_RESOURCE = 'INVALID_RESOURCE',
	WORK_ORDER_EMPLOYEE = 'WORK_ORDER_EMPLOYEE',
	WORK_ORDER_EQUIPMENT = 'WORK_ORDER_EQUIPMENT',
	WORK_ORDER_PLACEHOLDER = 'WORK_ORDER_PLACEHOLDER',
	WORK_ORDER_TEMPORARY_EMPLOYEE = 'WORK_ORDER_TEMPORARY_EMPLOYEE',
	EMPLOYEE = 'EMPLOYEE',
	EQUIPMENT = 'EQUIPMENT',
}
