import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import * as AuthenticationActions from 'af-actions/authentication';

import CLIENT from 'af-constants/routes/client';
import { USER_FINALIZE } from 'af-constants/reduxForms';

import { RootState } from 'af-reducers';

import Input from 'af-fields/Input';

import SubmitButton from 'af-components/SubmitButton';

import * as UserRequestModel from 'ab-requestModels/users.requestModel';

import * as User from 'ab-viewModels/user.viewModel';

import validate from 'af-root/scenes/Authentication/FinalizeUser/validations';

import { defaultRedirectUrl } from 'af-utils/actions.util';

type OwnProps = CustomRouteComponentProps;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & InjectedFormProps<UserRequestModel.FinalizeUser, FormOwnProps>;

class FinalizeUser extends React.Component<Props> {

	static defaultProps: Partial<Props> = {
		userData: {} as User.UserData,
		organizationData: {} as User.OrganizationData,
	};

	componentDidUpdate() {
		const { userData, organizationData, history, location: { state: { orgAlias } } } = this.props;
		if (!userData?.isFinalized || !organizationData) {
			return;
		}
		if (organizationData.isPlatformAdmin) {
			return history.push(CLIENT.PLATFORM.ALL_ORGANIZATIONS(orgAlias));
		}
		if (organizationData.companies.length) {
			// Consider feature toggles before redirecting
			return history.push(defaultRedirectUrl(orgAlias, organizationData.companies[0].name, [], true, userData.role));
		}
		return history.push(CLIENT.COMPANY.CREATE(orgAlias));
	}

	finalize = async (form: UserRequestModel.FinalizeUser) => {
		const { finalize, organizationData, location: { state: { orgAlias } } } = this.props;
		if (!organizationData) {
			throw new Error('Organization not defined');
		}

		await finalize(form, organizationData, orgAlias);
	};

	render(): JSX.Element {
		const { handleSubmit, submitting, invalid } = this.props;

		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone">
					<Row>
						<Col sm={24}>
							<h2>Welcome to Acceligent!</h2>
							<h4><b>Powerful. Fast. Easy-to-use.</b></h4>
							<h4>
								Acceligent is a comprehensive solution that helps businesses bid smarter,
								utilize resources more efficiently and improve job performance.
							</h4>
							<h4>Please enter a new password to finalize your account.</h4>
						</Col>
					</Row>
					<Form onSubmit={handleSubmit(this.finalize)}>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									id="password"
									label="Password *"
									name="password"
									type="password"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									id="repeatedPassword"
									label="Repeat Password *"
									name="repeatedPassword"
									type="password"
								/>
							</Col>
						</Row>
						<Row className="row--submit">
							<SubmitButton
								className="btn--full"
								disabled={invalid}
								label="Submit"
								reduxFormSubmitting={submitting}
							/>
						</Row>
					</Form>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	return {
		organizationData: state.user.organizationData,
		userData: state.user.userData,
	};
}

function mapDispatchToProps() {
	return {
		finalize: AuthenticationActions.finalize,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<UserRequestModel.FinalizeUser, FormOwnProps>({ form: USER_FINALIZE, validate })
);

export default enhance(FinalizeUser);
