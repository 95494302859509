import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import FSN from './FSN';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector>;

const CommunicationFSN = (props: Props) => {
	const { companyData, userRole, location: { state: { orgAlias }, pathname } } = props;
	const companyName = companyData.name;

	const links = React.useMemo(() => [
		{
			url: CLIENT.COMPANY.COMMUNICATION.NOTIFY_EMPLOYEES(orgAlias, companyName),
			label: 'Notification Center',
			visibleFor: PagePermissions.COMPANY.COMMUNICATION.MANAGE,
			isActive: pathname.includes(CLIENT.COMPANY.COMMUNICATION.NOTIFY_EMPLOYEES(orgAlias, companyName)),
		},
		{
			url: CLIENT.COMPANY.COMMUNICATION.URL_SHORTENER(orgAlias, companyName),
			label: 'URL Shortener',
			visibleFor: PagePermissions.COMPANY.COMMUNICATION.MANAGE,
			isActive: pathname.includes(CLIENT.COMPANY.COMMUNICATION.URL_SHORTENER(orgAlias, companyName)),
		},
		{
			url: CLIENT.COMPANY.COMMUNICATION.COMMUNICATION_HISTORY(orgAlias, companyName),
			label: 'Communication History',
			visibleFor: PagePermissions.COMPANY.COMMUNICATION.VIEW_COMMUNICATION_HISTORY,
			isActive: pathname.includes(CLIENT.COMPANY.COMMUNICATION.COMMUNICATION_HISTORY(orgAlias, companyName)),
		},
	], [orgAlias, companyName, pathname]);

	return (
		<FSN
			infoLabel="Communication"
			isCompanyAdmin={companyData.isCompanyAdmin}
			links={links}
			permissions={companyData.permissions}
			scrollLabel="none"
			userRole={userRole}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
		userRole: userData.role,
	};
}

const connector = connect(mapStateToProps);

export default connector(CommunicationFSN);
