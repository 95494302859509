import * as React from 'react';

import * as TimeUtils from 'acceligent-shared/utils/time';
import JobPreviewViewModel from 'ab-viewModels/jobPreview.viewModel';

interface Props {
	job: JobPreviewViewModel;
	renderItem: (label: string, value: Nullable<string>, icon?: JSX.Element) => JSX.Element;
}

export default ({ job, renderItem }: Props) => {
	return (
		<div className="dashboard-card">
			<div className="dashboard-card-header">
				JOB INFO
			</div>
			<div className="dashboard-card-content">
				{renderItem('Job #', job?.details.jobCode)}
				{renderItem('Customer', job?.details.customerCompanyName)}
				{renderItem('Start Date', job?.details.startDate && TimeUtils.formatDate(job.details.startDate))}
				{renderItem('Target Compl', job?.details.targetCompletionDate)}
				{renderItem('Guaranteed Compl', job?.details.guaranteedCompletionDate)}
			</div>
		</div>
	);
};
