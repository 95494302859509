import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { Option } from 'react-select/src/filters';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import ItemDepartmentVM from 'ab-viewModels/ItemDepartment/itemDepartment.viewModel';
import AccountOptionVM from 'ab-viewModels/account/option.viewModel';

import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';
import Select from 'af-fields/SelectField';
import Checkbox from 'af-fields/Checkbox';

import * as ItemActions from 'af-actions/item';
import * as AccountActions from 'af-actions/accounts';

import Order from './Order';
import styles from './styles.module.scss';

interface OwnProps {
	manualPriceOverride: boolean;
	isEdit: boolean;
	initialized: boolean;
	disabled: boolean;
	createdAt: Date; // not input
	startedAt: Nullable<Date>; // not input
	receivedAt: Nullable<Date>; // not input
	shippedAt: Nullable<Date>; // not input
	returnedAt: Nullable<Date>; // not input
	completedAt: Nullable<Date>; // not input
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const formatEmployeeOptionLabel = (option: AccountOptionVM) => <div>{option.fullName}</div>;
const getSelectOptionValue = (option: { id: number; }) => option.id;
const filterSelects = (textA?: string, textB?: string) => (textA ?? '').toLowerCase().includes((textB ?? '').toLowerCase());
const filterEmployee = ({ data }: Option, text: string) => filterSelects(data.fullName, text);

const AccountSelect = Select as unknown as new () => Select<{ id: number; serialNumber: string; toolType: string; }>;

const InternalRepairSection = (props: Props) => {
	const {
		manualPriceOverride,
		findAllItems,
		findAllTechnicians,
		initialized,
		disabled,
		createdAt,
		startedAt,
		isEdit,
		shippedAt,
		returnedAt,
		completedAt,
	} = props;
	const [loading, setLoading] = React.useState(false);
	const [items, setItems] = React.useState<ItemDepartmentVM[]>([]);
	const [technicians, setTechnicians] = React.useState<AccountOptionVM[]>([]);

	React.useEffect(() => {
		setLoading(true);
	}, []);

	React.useEffect(() => {
		if (loading) {
			const loadStuff = async () => {
				setItems(await findAllItems());
				setTechnicians(await findAllTechnicians());
				setLoading(false);
			};
			loadStuff();
		}
	}, [loading, findAllItems, findAllTechnicians]);

	return (
		<>
			<Row>
				<Col md={3}>
					<Field
						component={Input}
						disabled={!manualPriceOverride || disabled}
						label="Cost"
						name="price"
						step={0.01}
						type="number"
					/>
				</Col>
				<Col md={21}>
					<Field
						component={Checkbox}
						isDisabled={disabled}
						isStandaloneRow={true}
						label="Manual Override"
						name="priceManualOverride"
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<Field
						component={AccountSelect}
						filterOption={filterEmployee}
						formatOptionLabel={formatEmployeeOptionLabel}
						getOptionValue={getSelectOptionValue}
						isDisabled={disabled}
						label="Technician name"
						name="repairedBy"
						options={technicians}
						placeholder="Select"
					/>
					{isEdit && (
						<>
							{createdAt && (
								<div className={styles.timestamp}>
									<span className={styles.timestamp__label}>Created:</span>
									<span>
										{TimeUtils.formatDate(createdAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
									</span>
								</div>
							)}
							{startedAt && (
								<div className={styles.timestamp}>
									<span className={styles.timestamp__label}>Started:</span>
									<span>
										{TimeUtils.formatDate(startedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
									</span>
								</div>
							)}
							{shippedAt && (
								<div className={styles.timestamp}>
									<span className={styles.timestamp__label}>Shipped:</span>
									<span>
										{TimeUtils.formatDate(shippedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
									</span>
								</div>
							)}
							{returnedAt && (
								<div className={styles.timestamp}>
									<span className={styles.timestamp__label}>Returned:</span>
									<span>
										{TimeUtils.formatDate(returnedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
									</span>
								</div>
							)}
							{completedAt && (
								<div className={styles.timestamp}>
									<span className={styles.timestamp__label}>Completed:</span>
									<span>
										{TimeUtils.formatDate(completedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
									</span>
								</div>
							)}
						</>
					)}
				</Col>
				<Col md={18}>
					<Field
						component={Textarea}
						controlCursor={true}
						disabled={disabled}
						label="Internal Comment"
						name="internalComment"
						rows={6}
					/>
				</Col>
				<Col md={6} />
				<Col md={18}>
					<FieldArray
						availableItems={items}
						component={Order}
						disabled={disabled}
						initialized={initialized}
						isReadOnly={false}
						name="items"
					/>
				</Col>
			</Row>
		</>
	);
};
function mapDispatchToProps() {
	return {
		findAllItems: ItemActions.findItemDepartments,
		findAllTechnicians: AccountActions.findAllAssignableAsTechnician,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(InternalRepairSection);
