import * as React from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

import { EquipmentCostCategoryLabel } from 'acceligent-shared/enums/equipmentCostCategory';

import { MechanicViewAvailableEquipmentDataVM } from 'ab-viewModels/mechanicView/mechanicView.viewModel';

import { scrollIntoView } from 'af-utils/browser.util';
import { generateEquipmentSearchItemId } from 'af-utils/scheduleBoard.util';

import CellWithTooltip from './CellWithTooltip';

interface OwnProps {
	isActive: boolean;
	isMatch: boolean;
	provided?: DraggableProvided;
	row: MechanicViewAvailableEquipmentDataVM;
	snapshot: DraggableStateSnapshot;
}

type Props = OwnProps;

class Row extends React.PureComponent<Props> {
	_ref: Nullable<HTMLDivElement> = null;

	componentDidUpdate(prevProps: Props) {
		if (this._ref && !prevProps.isActive && this.props.isActive) {
			scrollIntoView(this._ref);
		}
	}

	setRef = (element: HTMLDivElement) => {
		const { provided } = this.props;
		if (provided) {
			provided.innerRef(element);
		}
		this._ref = element ?? null;
	};

	className = () => {
		const { isActive, isMatch } = this.props;
		let className = 'draggable-table__row';
		if (isMatch) {
			className = `${className} draggable-table__row--highlighted`;
		}
		if (isActive) {
			className = `${className} draggable-table__row--highlighted-active`;
		}
		return className;
	};

	getDraggableStyle = (): React.CSSProperties | undefined => {
		const { provided, snapshot } = this.props;
		if (!!provided && snapshot.isDragging) {
			return provided.draggableProps.style;
		}
	};

	render() {
		const {
			row: {
				assignmentWorkOrderCodes,
				code: equipmentId,
				categoryColor,
				id,
				location,
				locationColor,
				specification: equipmentSpecification,
				type: equipmentType,
			},
			provided,
		} = this.props;

		return (
			<div
				{...provided?.draggableProps}
				{...provided?.dragHandleProps}
				ref={this.setRef}
				style={this.getDraggableStyle()}
			>
				<div className={this.className()} id={generateEquipmentSearchItemId(id.toString())}>
					<div className="draggable-table__cell draggable-table__cell--blank-space" />
					<CellWithTooltip additionalContent={equipmentSpecification} backgroundColor={categoryColor} className="draggable-table__cell--uppercase" content={equipmentId} placement="top" />
					<CellWithTooltip content={assignmentWorkOrderCodes.join(', ')} emptyMessage="None" placement="top" />
					<CellWithTooltip color={locationColor} content={location} emptyMessage="Unknown" placement="top" />
					<CellWithTooltip content={EquipmentCostCategoryLabel[equipmentType]} placement="top" />
					{/* removed for now */}
					{/* <CellWithTooltip content={currentLocation} />
					<CellWithTooltip content={operator} />
					<CellWithTooltip content={nextServiceDue} /> */}
					<div className="draggable-table__cell draggable-table__cell--blank-space" />
				</div>
			</div>
		);
	}
}

export default Row;
