import * as React from 'react';
import { TypedCellInfo } from 'react-table-6';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import WorkRequestBidStatus from 'acceligent-shared/enums/workRequestBidStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import WorkRequestTableViewModel from 'ab-viewModels/workRequestTable.viewModel';

import * as WorkRequestActions from 'af-actions/workRequests/index';

import CLIENT from 'af-routes/client';

import TableButtonType from 'ab-enums/tableButtonType.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import Table, { LegacyTableRef } from 'af-components/Table6';
import TableComponent from 'af-components/Table6/Table';
import PriorityCell from 'af-components/Table6/Cells/PriorityCell';
import Dropdown, { DropdownOptionType } from 'af-components/Controls/Dropdown';
import MonetaryCell from 'af-components/Table6/Cells/MonetaryCell';
import { TabProps } from 'af-components/Table6/types';
import DateCell from 'af-components/Table6/Cells/DateCell';
import Breadcrumbs from 'af-components/Breadcrumbs';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';

import { downloadCSV } from 'af-utils/csv.utils';

import { RootState } from 'af-reducers';

import WorkRequestReasonForLostModal from '../Shared/WorkRequestReasonForLostModal';
import WorkRequestConvertToJobModal from '../Shared/WorkRequestConvertToJobModal';

import styles from './styles.module.scss';
import { formatDate } from 'acceligent-shared/utils/time';
import WorkRequestCopyModal from '../Shared/WorkRequestCopyModal';

type Props = ConnectedProps<typeof connector> & CustomRouteComponentProps;

type WorkRequestsTableRef = LegacyTableRef<WorkRequestTableViewModel>;

type WorkRequestBidStatusOption = { id: WorkRequestBidStatus | 'ALL_STATUSES'; label: string; } & DropdownOptionType;

const ALL_STATUSES_OPTION: WorkRequestBidStatusOption = { id: 'ALL_STATUSES', label: 'All Bid Statuses' };

const BID_STATUS_OPTIONS = Object.keys(WorkRequestBidStatus).reduce<WorkRequestBidStatusOption[]>((_acc, _bidStatus: keyof typeof WorkRequestBidStatus) => {
	if (WorkRequestBidStatus[_bidStatus] === WorkRequestBidStatus.JOB) {
		return _acc;
	}
	switch (WorkRequestBidStatus[_bidStatus]) {
		case WorkRequestBidStatus.IN_PROGRESS: {
			_acc.push({ id: WorkRequestBidStatus.IN_PROGRESS, label: 'In Progress' });
			break;
		}
		case WorkRequestBidStatus.LOCKED_IN: {
			_acc.push({ id: WorkRequestBidStatus.LOCKED_IN, label: 'Converted to Job' });
			break;
		}
		case WorkRequestBidStatus.LOST: {
			_acc.push({ id: WorkRequestBidStatus.LOST, label: 'Lost' });
			break;
		}
		case WorkRequestBidStatus.WON: {
			_acc.push({ id: WorkRequestBidStatus.WON, label: 'Won' });
			break;
		}
	}

	return _acc;
}, [ALL_STATUSES_OPTION]);

const WorkRequestTable: React.FC<Props> = (props) => {
	const { getWorkRequestsTable, editBidStatus, companyName, history, location: { state: { orgAlias } } } = props;

	const [bidStatusFilter, setBidStatusFilter] = React.useState<WorkRequestBidStatusOption>(BID_STATUS_OPTIONS[0]);
	const [showReasonForLostModal, setShowReasonForLostModal] = React.useState(false);
	const [showConvertToJobModal, setShowConvertToJobModal] = React.useState(false);
	const [showCopyModal, setShowCopyModal] = React.useState(false);
	const [workRequestToMarkAsLost, setWorkRequestToMarkAsLost] = React.useState<Nullable<WorkRequestTableViewModel>>(null);
	const [workRequestToConvertToJob, setWorkRequestToConvertToJob] = React.useState<Nullable<WorkRequestTableViewModel>>(null);
	const [workRequestToCopy, setWorkRequestToCopy] = React.useState<Nullable<WorkRequestTableViewModel>>(null);
	const tableRef = React.useRef<TableComponent<WorkRequestTableViewModel>>(null);

	const fetchData = React.useCallback(async (_tableRequest: TableQuery) => {
		const bidStatusToFetch =
			bidStatusFilter.id !== ALL_STATUSES_OPTION.id
				? bidStatusFilter.id
				: undefined;

		return await getWorkRequestsTable(_tableRequest, bidStatusToFetch as WorkRequestBidStatus | undefined);
	}, [getWorkRequestsTable, bidStatusFilter]);

	const onConvertedToJobClick = React.useCallback((original: WorkRequestTableViewModel) => (e) => {
		e.stopPropagation();
		if (original.convertedToJobId) {
			history.push(CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, original.convertedToJobId.toString()));
		}
	}, [companyName, history, orgAlias]);

	const renderDoubleCell = (firstValue: Nullable<string>, secondString: Nullable<string>) => {
		return (
			<div className={styles['work-request-table__double-cell']}>
				{firstValue ?? 'N/A'}
				<span>
					{secondString ?? 'N/A'}
				</span>
			</div>
		);
	};

	const renderStatusMenuItem = React.useCallback((_item: WorkRequestBidStatusOption) => {
		return <div>{_item.label}</div>;
	}, []);

	const onBidStatusFilterChange = React.useCallback((selectedOption: Nullable<WorkRequestBidStatusOption>) => {
		if (!selectedOption) return;
		setBidStatusFilter(selectedOption);
		tableRef.current?.refreshTable();
	}, []);

	const renderFilter = React.useCallback(() => {
		return (
			<div className={styles['work-request-table__filters']}>
				<div className={styles['work-request-table__filters__bid-status-container']}>
					<Dropdown<WorkRequestBidStatusOption>
						className={styles['work-request-table__filters__bid-status']}
						defaultValue={bidStatusFilter}
						id="work-request-table-filter"
						labelKey="label"
						onValueChange={onBidStatusFilterChange}
						options={BID_STATUS_OPTIONS}
						renderMenuItem={renderStatusMenuItem}
						valueKey="label"
						withBorder={false}
					/>
					<RectangleButton
						action={undefined}
						isLeftOpen={true}
						isSquare={true}
						label={<span className="icon-filter" />}
					/>
				</div>
			</div>
		);
	}, [bidStatusFilter, onBidStatusFilterChange, renderStatusMenuItem]);

	const renderBidStatusCell = React.useCallback(({ original }: Omit<TypedCellInfo<WorkRequestTableViewModel>, 'value'>) => {
		const className = [styles['work-request-table__bid-status']];
		switch (original.bidStatus) {
			case WorkRequestBidStatus.IN_PROGRESS: {
				className.push(styles['work-request-table__bid-status--in-progress']);

				return (
					<span className={className.join(' ')}>
						In Progress
					</span>
				);
			}
			case WorkRequestBidStatus.WON: {
				className.push(styles['work-request-table__bid-status--won']);
				return (
					<span className={className.join(' ')}>
						Won
					</span>
				);
			}
			case WorkRequestBidStatus.LOST: {
				className.push(styles['work-request-table__bid-status--lost']);
				return (
					<span className={className.join(' ')}>
						Lost
					</span>
				);
			}
			case WorkRequestBidStatus.LOCKED_IN: {
				return (
					<span className={styles['work-request-table__bid-status--locked-in']} onClick={onConvertedToJobClick(original)}>
						Converted to Job
					</span>
				);
			}

			case WorkRequestBidStatus.JOB: {
				return null;
			}
		}
	}, [onConvertedToJobClick]);

	const columns: TabProps<WorkRequestTableViewModel>['columns'] = React.useMemo(() => [
		{
			Header: 'Bid Status',
			accessor: 'bidStatus',
			sortable: true,
			width: 150,
			Cell: renderBidStatusCell,
		},
		{
			Header: 'Priority',
			accessor: 'priority',
			sortable: true,
			Cell: ({ original }) => <PriorityCell priority={original.priority} />,
		},
		{
			Header: 'Work Request ID',
			accessor: 'jobCode',
			sortable: true,
			Cell: ({ original }) => renderDoubleCell(original.jobCode, original.title),
		},
		{
			Header: 'Customer Company',
			accessor: 'customerCompanyName',
			sortable: true,
			Cell: ({ original }) => original.customerCompanyName,
		},
		{
			Header: 'City / State',
			accessor: 'city',
			sortable: true,
			Cell: ({ original }) => renderDoubleCell(original.city, original.state),
		},
		{
			Header: 'Office',
			accessor: 'office',
			sortable: true,
			Cell: ({ original }) => original.office,
		},
		{
			Header: 'Project Manager',
			accessor: 'projectManager',
			sortable: true,
			Cell: ({ original }) => original.projectManager,
		},
		{
			Header: 'Start Date',
			accessor: 'startDate',
			sortable: true,
			Cell: ({ original }) =>
				<DateCell
					date={original.startDate}
					dateSourceFormat={TimeFormat.DB_DATE_ONLY}
					format={TimeFormat.DATE_ONLY}
					isLeftAligned={true}
				/>,
		},
		{
			Header: 'Target Completion / Guar. Completion',
			accessor: 'targetCompletionDate',
			sortable: true,
			Cell: ({ original }) => renderDoubleCell(
				original?.targetCompletionDate ? formatDate(original.targetCompletionDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY) : null,
				original?.guaranteedCompletionDate ? formatDate(original.guaranteedCompletionDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY) : null
			),
		},
		{
			Header: 'Billing Total/Estimate Total',
			accessor: 'estimateTotal',
			sortable: true,
			Cell: MonetaryCell,
		},
		{
			Header: 'Updated',
			accessor: 'updatedAt',
			sortable: true,
			Cell: ({ original }) => (
				<LastUpdatedByCell
					dateSourceFormat={TimeFormat.ISO_DATETIME}
					updatedAt={original.updatedAt ?? ''}
					updatedBy={original.updatedBy}
				/>
			),
		},
	], [renderBidStatusCell]);

	const onMarkAsLostClick = React.useCallback((original: WorkRequestTableViewModel) => {
		setShowReasonForLostModal(true);
		setWorkRequestToMarkAsLost(original);
	}, []);

	const onConvertToJobActionClick = React.useCallback((original: WorkRequestTableViewModel) => {
		setShowConvertToJobModal(true);
		setWorkRequestToConvertToJob(original);
	}, []);

	const onCloseReasonForLostModal = React.useCallback(() => {
		setShowReasonForLostModal(false);
		setWorkRequestToMarkAsLost(null);
	}, []);

	const onCloseConvertToJobModal = React.useCallback(() => {
		setShowConvertToJobModal(false);
		setWorkRequestToConvertToJob(null);
	}, []);

	const onCopyWorkRequestActionClick = React.useCallback((original?: WorkRequestTableViewModel) => {
		if (!original) {
			return;
		}
		setWorkRequestToCopy(original);
		setShowCopyModal(true);
	}, []);

	const onCloseCopyModal = React.useCallback(() => {
		setWorkRequestToCopy(null);
		setShowCopyModal(false);
	}, []);

	const redirectToCopiedWorkRequestPage = React.useCallback((copiedWorkRequestId: number) => {
		history.push(CLIENT.COMPANY.WORK_REQUESTS.PREVIEW(orgAlias, companyName, `${copiedWorkRequestId}`));
	}, [companyName, history, orgAlias]);

	const handleMarkAsLostSave = React.useCallback(async (lostForReason: string) => {
		if (!workRequestToMarkAsLost) {
			return;
		}

		await editBidStatus(workRequestToMarkAsLost.id,
			{
				newBidStatus: WorkRequestBidStatus.LOST,
				previousBidStatus: workRequestToMarkAsLost.bidStatus as WorkRequestBidStatus.WON | WorkRequestBidStatus.IN_PROGRESS,
				lostForReason,
			});
		await tableRef.current?.refreshTable();
	}, [editBidStatus, workRequestToMarkAsLost]);

	const redirectToConvertedJobPage = React.useCallback((convertedToJobId: string) => {
		history.push(CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, convertedToJobId));
	}, [companyName, history, orgAlias]);

	const goToEditPageForWorkRequest = React.useCallback((workRequest: WorkRequestTableViewModel | undefined) => {
		if (!workRequest) {
			return;
		}
		if (workRequest.bidStatus !== WorkRequestBidStatus.LOCKED_IN) {
			history.push(CLIENT.COMPANY.WORK_REQUESTS.EDIT(orgAlias, companyName, `${workRequest.id}`));
		}
	}, [companyName, history, orgAlias]);

	const goToPreviewPageForWorkRequest = React.useCallback((workRequest: WorkRequestTableViewModel | undefined) => {
		if (!workRequest) {
			return;
		}
		history.push(CLIENT.COMPANY.WORK_REQUESTS.PREVIEW(orgAlias, companyName, `${workRequest.id}`));
	}, [companyName, history, orgAlias]);

	const onCsvExportClick = React.useCallback(async () => {
		const tableRequestModel = new TableQuery({
			pageSize: 0,
			page: 0,
			sortBy: [{ id: 'startDate', desc: false }],
			filterByText: '',
			includeAll: true,
		});
		const bidStatusToFetch =
			bidStatusFilter.id !== ALL_STATUSES_OPTION.id
				? bidStatusFilter.id
				: undefined;

		const result = await getWorkRequestsTable(tableRequestModel, bidStatusToFetch as WorkRequestBidStatus | undefined);
		const csvData = WorkRequestTableViewModel.toCSVData(result.rows);
		downloadCSV(csvData, 'Work_requests.csv');
	}, [bidStatusFilter.id, getWorkRequestsTable]);

	const tabs = React.useCallback((): TabProps<WorkRequestTableViewModel>[] => {
		const tabProps: TabProps<WorkRequestTableViewModel> = {
			label: 'Work Requests',
			buttons: [
				{
					type: TableButtonType.EXPORT,
					hasPermission: true,
					onClick: onCsvExportClick,
				},
				{
					type: TableButtonType.PRIMARY,
					hasPermission: true,
					onClick: async () => {
						history.push(CLIENT.COMPANY.WORK_REQUESTS.CREATE(orgAlias, companyName));
					},
					label: 'New Work Request',
				},
			],
			columns: columns,
			hasSearchInput: true,
			searchLabel: 'Work Requests',
			fetch: fetchData,
			rowActions: [
				{
					action: async (original) => {
						if (!original) return;
						if (original.bidStatus === WorkRequestBidStatus.IN_PROGRESS || original.bidStatus === WorkRequestBidStatus.LOST) {
							await editBidStatus(original.id, { newBidStatus: WorkRequestBidStatus.WON, previousBidStatus: original.bidStatus });
						}
					},
					label: 'Mark as Won',
					shouldRefresh: true,
					hide: (original) => original?.bidStatus === WorkRequestBidStatus.LOCKED_IN || original?.bidStatus === WorkRequestBidStatus.WON,
				},
				{
					action: async (original) => {
						if (!original) return;
						if (original.bidStatus === WorkRequestBidStatus.LOST || original.bidStatus === WorkRequestBidStatus.WON) {
							await editBidStatus(original.id, { newBidStatus: WorkRequestBidStatus.IN_PROGRESS, previousBidStatus: original.bidStatus });
						}
					},
					label: 'Mark as In Progress',
					shouldRefresh: true,
					hide: (original) => original?.bidStatus === WorkRequestBidStatus.LOCKED_IN || original?.bidStatus === WorkRequestBidStatus.IN_PROGRESS,
				},
				{
					action: onMarkAsLostClick,
					label: 'Mark as Lost',
					shouldRefresh: false,
					hide: (original) => original?.bidStatus === WorkRequestBidStatus.LOCKED_IN || original?.bidStatus === WorkRequestBidStatus.LOST,
				},
				{
					action: onConvertToJobActionClick,
					label: 'Convert to Job',
					shouldRefresh: false,
					hide: (original) =>
						original?.bidStatus === WorkRequestBidStatus.LOCKED_IN
						|| original?.bidStatus === WorkRequestBidStatus.LOST
						|| original?.bidStatus === WorkRequestBidStatus.IN_PROGRESS,
				},
				{
					action: onConvertToJobActionClick,
					label: 'Mark as Won and Convert to Job',
					shouldRefresh: false,
					hide: (original) =>
						original?.bidStatus === WorkRequestBidStatus.LOCKED_IN
						|| original?.bidStatus === WorkRequestBidStatus.WON,
				},
				{
					action: (original) => onCopyWorkRequestActionClick(original),
					label: 'Copy Work Request',
					shouldRefresh: false,
				},
				{
					action: (original) => goToEditPageForWorkRequest(original),
					label: 'Edit',
					shouldRefresh: false,
					hide: (original) => original?.bidStatus === WorkRequestBidStatus.LOCKED_IN,
				},
				{
					action: (original) => goToPreviewPageForWorkRequest(original),
					label: 'Preview',
					shouldRefresh: false,
				},
			],
			onRowClick: ({ original }) => {
				goToPreviewPageForWorkRequest(original);
			},
			additionalFilter: renderFilter,
		};

		return [tabProps];
	}, [
		columns,
		companyName,
		editBidStatus,
		fetchData,
		goToEditPageForWorkRequest,
		history,
		onConvertToJobActionClick,
		onMarkAsLostClick,
		orgAlias,
		renderFilter,
		onCsvExportClick,
		goToPreviewPageForWorkRequest,
		onCopyWorkRequestActionClick,
	]);

	return (
		<>
			<Breadcrumbs items={[{ label: 'Work Request' }]} />
			<Table
				hideTabs={true}
				ref={tableRef as unknown as WorkRequestsTableRef}
				tableName="Work Requests"
				tabs={tabs()}
			/>
			<WorkRequestReasonForLostModal
				closeModal={onCloseReasonForLostModal}
				handleMarkAsLostSave={handleMarkAsLostSave}
				showModal={showReasonForLostModal}
			/>
			{workRequestToConvertToJob && (
				<WorkRequestConvertToJobModal
					closeModal={onCloseConvertToJobModal}
					currentJobCode={workRequestToConvertToJob.jobCode}
					onConvert={redirectToConvertedJobPage}
					showModal={showConvertToJobModal}
					workRequestId={workRequestToConvertToJob.id}
				/>
			)}
			{workRequestToCopy && (
				<WorkRequestCopyModal
					closeModal={onCloseCopyModal}
					currentWorkRequestCode={workRequestToCopy.jobCode}
					currentWorkRequestId={workRequestToCopy.id}
					redirectToCopiedWorkRequestPage={redirectToCopiedWorkRequestPage}
					showModal={showCopyModal}
				/>
			)}
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		getWorkRequestsTable: WorkRequestActions.getWorkRequestsTable,
		editBidStatus: WorkRequestActions.editBidStatus,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(WorkRequestTable);
