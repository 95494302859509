import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ResolveThunks } from 'react-redux';

import { RootState } from 'af-reducers';

import * as CompanyActions from 'af-actions/companies';

import Breadcrumbs from 'af-components/Breadcrumbs';

import { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import Loading from './Loading';
import EquipmentStatusSettingsPreview from './EquipmentStatusSettings/EquipmentStatusSettingsPreview';
import EquipmentStatusSettingsEdit from './EquipmentStatusSettings/EquipmentStatusSettingsEdit';

interface CompanySettingsRouteProps {
	orgAlias: string;
	companyName: string;
}

interface DispatchProps {
	getCompany: typeof CompanyActions.getCompany;
}

interface StateProps {
	company: Nullable<CompanyViewModel>;
}

type OwnProps = CustomRouteComponentProps<CompanySettingsRouteProps>;

type Props = StateProps & ResolveThunks<DispatchProps> & OwnProps;

interface State {
	showEquipmentStatusSettingsModal: boolean;
}

class EquipmentDetails extends React.PureComponent<Props, State> {

	state: State = {
		showEquipmentStatusSettingsModal: false,
	};

	async componentDidMount() {
		const { getCompany } = this.props;
		await getCompany();
	}

	toggleEquipmentStatusSettingsEdit = async () => {
		this.setState(
			(prevStatus: State) => ({ showEquipmentStatusSettingsModal: !prevStatus.showEquipmentStatusSettingsModal })
		);
	};

	render() {
		const { company } = this.props;
		const { showEquipmentStatusSettingsModal } = this.state;

		if (!company) {
			return <Loading />;
		}

		return (
			<div className="form-segment">
				<Breadcrumbs items={[{ label: 'Status' }]} />
				<EquipmentStatusSettingsPreview
					company={company}
					toggleEdit={this.toggleEquipmentStatusSettingsEdit}
				/>
				<EquipmentStatusSettingsEdit
					isVisible={showEquipmentStatusSettingsModal}
					toggleEdit={this.toggleEquipmentStatusSettingsEdit}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;

	return {
		company,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		getCompany: CompanyActions.getCompany,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(EquipmentDetails);
