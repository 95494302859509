import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps, Redirect, withRouter } from 'react-router-dom';

import MemberInviteEnum from 'acceligent-shared/enums/memberInvite';

import CLIENT from 'af-constants/routes/client';

import * as AuthenticationActions from 'af-actions/authentication';

import * as User from 'ab-viewModels/user.viewModel';

import { RootState } from 'af-reducers';

declare let window;

type Props = ConnectedProps<typeof connector> & CustomRouteComponentProps;

function mapStateToProps(state: RootState) {
	return {
		userData: state?.user?.userData,
	};
}

function mapDispatchToProps() {
	return {
		isAuthenticated: AuthenticationActions.isAuthenticated,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const enhance = compose<React.ComponentClass<any>>(
	withRouter,
	connector
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function (ComposedComponent: React.ComponentType<any>): React.ComponentType<unknown> {
	class Authentication extends React.PureComponent<Props> {

		static defaultProps: Partial<Props> = {
			userData: {} as User.UserData,
		};

		async componentDidMount() {
			const { isAuthenticated, location: { state: { orgAlias } } } = this.props;

			await isAuthenticated(orgAlias);
		}

		render() {
			const { userData, location } = this.props;
			// previous location if user needs to be redirected
			window.redirectToLocation = location;
			const { orgAlias } = location.state;

			if (userData?.isFinalized || (!userData?.isFinalized && userData?.status === MemberInviteEnum.ACTIVE)) {
				return React.createElement(ComposedComponent, this.props);
			} else if (userData?.id) {
				// User has not yet been finalized
				return location.pathname !== CLIENT.AUTH.FINALIZE(orgAlias) ?
					React.createElement(Redirect, { to: CLIENT.AUTH.FINALIZE(orgAlias) }) :
					React.createElement(ComposedComponent, this.props);
			} else {
				return React.createElement(Redirect, { to: `${CLIENT.AUTH.LOGIN(orgAlias)}?redirect` });
			}

		}
	}
	return enhance(Authentication);
}
