import * as React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';

interface OwnProps {
	id?: string;
	label: string;
	bold: boolean;
	addonLeft?: React.ReactNode;
	addonRight?: React.ReactNode;
	value?: React.ReactNode;
	defaultValue?: React.ReactNode;
}

type Props = React.PropsWithChildren<OwnProps>;

class Text extends React.PureComponent<Props> {
	static defaultProps: Partial<OwnProps> = {
		bold: false,
	};

	render() {
		const {
			addonLeft,
			addonRight,
			bold,
			id,
			label,
			value,
			defaultValue,
			children,
		} = this.props;

		return (
			<FormGroup controlId={id}>
				<FormLabel>{label}</FormLabel>
				<div className={`text-field ${bold ? 'text-strong' : ''}`}>
					{children ??
						<>
							{addonLeft}
							{value ?? defaultValue}
							{addonRight}
						</>
					}
				</div>
			</FormGroup>
		);
	}
}

export default Text;
