import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	label: string;
	icon: string;
	isActiveEntry: boolean;
}

const WorkTypeLabel = ({ label, icon, isActiveEntry }: OwnProps) => {
	const iconClassName = bemElement('time-sheet-list__row__info', 'work-type-label-container__icon', { 'tracked-entry': isActiveEntry });

	return (
		<div className="time-sheet-list__row__info__work-type-label-container">
			<span className={`${iconClassName} icon-${icon}`} />
			<span>{label}</span>
		</div>
	);
};

export default WorkTypeLabel;
