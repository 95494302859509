import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import SegmentLabel from 'af-components/SegmentLabel';

import ResourcePreview from '../../../Shared/ResourcePreview';

const breadcrumbs = [
	{ label: 'Equipment' },
	{ label: 'Preview' },
];

export default () => (
	<ResourcePreview
		backToListLabel="Back to Equipment List"
		backToListUrl="#"
		breadcrumbs={breadcrumbs}
		editUrl="#"
	>
		<Row className="row--padded-top">
			<Col className="loading-box-col stretched" sm={6} />
			<Col className="loading-box-col stretched" sm={6} />
			<Col md={4}>
				<div className="loading-box-field standalone" />
				<br />
				<div className="loading-box-field standalone" />
			</Col>
			<Col md={4}>
				<div className="loading-box-field standalone" />
				<br />
				<div className="loading-box-field standalone" />
			</Col>
			<Col md={4}>
				<div className="loading-box-field standalone" />
				<br />
				<div className="loading-box-field standalone" />
			</Col>
		</Row>
		<Row>
			<SegmentLabel label="Contacts" />
		</Row>
		<Row>
			<Col className="loading-box-field" sm={6} />
			<Col className="loading-box-field" sm={6} />
		</Row>
		<Row>
			<SegmentLabel label="Needed skills and licences" />
		</Row>
		<Row>
			<Col className="loading-box-col stretched" sm={12} />
		</Row>
		<Row>
			<Col className="loading-box-field stretched" sm={12} />
		</Row>
		<Row>
			<Col className="loading-box-col stretched" sm={24} />
		</Row>
	</ResourcePreview>
);
