import * as React from 'react';
import { Field } from 'redux-form';

import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';

import { formSectionClass, formSectionClassColumn2, formSectionClassColumn4, formSectionClassRow, formSectionTitleClass } from '../../helpers';
import JobUpsertFM from '../../formModel';

import styles from '../../styles.module.scss';

const SafetySection: React.FC = () => {
	const checkboxStyle = React.useMemo(() => [formSectionClassColumn2, styles['job-form__tab-content__form-section__row__centered-column']].join(' '), []);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Safety
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="environmentalExposure"
						label="Environmental Exposure"
						name={JobUpsertFM.getAttributeName('environmentalExposure')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="environmentalExposureDescription"
						label="Description"
						name={JobUpsertFM.getAttributeName('environmentalExposureDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="respiratoryProtection"
						label="Respiratory Protection"
						name={JobUpsertFM.getAttributeName('respiratoryProtection')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="respiratoryProtectionDescription"
						label="Description"
						name={JobUpsertFM.getAttributeName('respiratoryProtectionDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="overheadHazards"
						label="Overhead Hazards"
						name={JobUpsertFM.getAttributeName('overheadHazards')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="overheadHazardsDescription"
						label="Description"
						name={JobUpsertFM.getAttributeName('overheadHazardsDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="heightWork"
						label="Height Work"
						name={JobUpsertFM.getAttributeName('heightWork')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="heightWorkDescription"
						label="Description"
						name={JobUpsertFM.getAttributeName('heightWorkDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="mechanicalHazards"
						label="Mechanical Hazards"
						name={JobUpsertFM.getAttributeName('mechanicalHazards')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="mechanicalHazardsDescription"
						label="Description"
						name={JobUpsertFM.getAttributeName('mechanicalHazardsDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="electricalHazards"
						label="Electrical Hazards"
						name={JobUpsertFM.getAttributeName('electricalHazards')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="electricalHazardsDescription"
						label="Description"
						name={JobUpsertFM.getAttributeName('electricalHazardsDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="hazardousMaterials"
						label="Hazardous Materials"
						name={JobUpsertFM.getAttributeName('hazardousMaterials')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="hazardousMaterialsDescription"
						label="Description"
						name={JobUpsertFM.getAttributeName('hazardousMaterialsDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="dangerousAnimalsOrPlants"
						label="Dangerous Animals or Plants"
						name={JobUpsertFM.getAttributeName('dangerousAnimalsOrPlants')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="dangerousAnimalsOrPlantsDescription"
						label="Description"
						name={JobUpsertFM.getAttributeName('dangerousAnimalsOrPlantsDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(SafetySection);
