import * as React from 'react';
import { Row } from 'react-bootstrap';

import SystemNotificationRequestModel from 'ab-requestModels/systemNotification.requestModel';

import NotificationItem from './NotificationItem';
import Loading from './Loading';

interface OwnProps {
	systemNotifications: SystemNotificationRequestModel[];
	deleteSystemNotification: (id: number) => void;
}

type Props = OwnProps;

class NotificationsList extends React.PureComponent<Props> {

	render() {
		const { systemNotifications, deleteSystemNotification } = this.props;

		if (!systemNotifications) {
			return <Loading />;
		}

		return (
			<div className="form-box">
				<Row className="row--padded">
					{
						systemNotifications.length ?
							systemNotifications.map((_notification, _index) => (
								<NotificationItem
									content={_notification.content}
									deleteSystemNotification={deleteSystemNotification}
									endsAt={_notification.endsAt}
									id={_notification.id}
									key={`${_index}#${_notification.content}`}
									startsAt={_notification.startsAt}
									type={_notification.type}
								/>
							)) :
							<>No active or scheduled notifications.</>
					}
				</Row>
			</div>
		);
	}
}

export default NotificationsList;
