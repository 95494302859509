import * as React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';

export interface DepartmentOwnProps {
	deletedDepartmentsIds: number[];
}

type Props = DepartmentOwnProps & WrappedFieldArrayProps;

const Department = (props: Props) => {
	const { fields, deletedDepartmentsIds } = props;

	const renderDepartment = (field: string, index: number) => {
		const department = fields.get(index);
		if (deletedDepartmentsIds.includes(department.id) && !department.includeDepartment) {
			return null;
		}
		return (
			<Row key={index}>
				<Col md={2} />
				<Col md={6}>
					<Field
						component={Checkbox}
						label={department.name}
						name={`${field}.includeDepartment`}
					/>
				</Col>
				<Col md={4}>
					<Field
						component={Input}
						name={`${field}.currentStock`}
						type="number"
					/>
				</Col>
				<Col md={4}>
					<Field
						component={Input}
						name={`${field}.minimumStock`}
						type="number"
					/>
				</Col>
				<Col md={4}>
					<Field
						component={Input}
						name={`${field}.maximumStock`}
						type="number"
					/>
				</Col>
				<Col md={4} />
			</Row>
		);
	};

	return (
		<>
			{fields.map(renderDepartment)}
		</>
	);
};

export default Department;
