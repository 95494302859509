import * as React from 'react';

import MapField from 'af-components/Maps/AddressMap';

import { formSectionClass, formSectionClassRow, formSectionTitleClass } from '../../helpers';

type Props = {
	formName: string;
	isDisabled: boolean;
};

const WorkLocationSection: React.FC<Props> = ({ formName, isDisabled }) => {
	return (
		<div className={formSectionClass}>
			<div className={formSectionClassRow}>
				<div className={formSectionTitleClass}>
					Work location
				</div>
			</div>
			<MapField
				aa1PropName="workLocation.state"
				aa2PropName="workLocation.aa2"
				aa3PropName="workLocation.aa3"
				countryPropName="workLocation.country"
				disabled={isDisabled}
				disableMap={true}
				formName={formName}
				latitudePropName="workLocation.latitude"
				localityPropName="workLocation.city"
				locationPropName="workLocation.street"
				longitudePropName="workLocation.longitude"
				postalCodePropName="workLocation.zip"
				postalOfficeBoxPropName="workLocation.postalOfficeBoxCode"
				routePropName="workLocation.route"
				streetNumberPropName="workLocation.streetNumber"
				suitePropName="workLocation.suite"
			/>
		</div>
	);
};

export default React.memo(WorkLocationSection);
