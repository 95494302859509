import * as React from 'react';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import { ExtendedColorPalette } from 'acceligent-shared/enums/color';

import { PCFRBlockFieldVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import Field from './Field';

interface OwnProps {
	fieldReportField: PCFRBlockFieldVM;
}

type Props = OwnProps;

const FieldReportBlockField: React.FC<Props> = (props: Props) => {
	const {
		value,
		reportBlockField: {
			unit,
			name,
			dimension,
			fieldType,
			descriptiveTextColor,
			isDescriptiveTextBold,
		},
	} = props.fieldReportField;

	const isImageField = fieldType === ReportBlockFieldEnum.Type.IMAGE || fieldType === ReportBlockFieldEnum.Type.IMMUTABLE_IMAGE;

	return (
		<Field
			descriptiveTextColor={descriptiveTextColor ?? ExtendedColorPalette.BLACK}
			dimension={dimension}
			fieldType={fieldType}
			isDescriptiveTextBold={isDescriptiveTextBold}
			isImage={isImageField}
			name={name}
			unit={unit}
			value={value as string}
		/>
	);
};

export default React.memo(FieldReportBlockField);
