import * as React from 'react';
import { connect } from 'react-redux';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import { formatDate } from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import { WorkOrderResourceLookupModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';
import ScheduleBoardEmployee from 'ab-viewModels/scheduleBoardEmployee.viewModel';

import Tooltip from 'af-components/Tooltip';

import { getColorTextClass } from 'ab-utils/color.util';

type NotificationStatus = {
	smsSentAt: Date | undefined;
	smsStatus: NotificationStatusEnum | undefined;
	emailSentAt: Date | undefined;
	emailStatus: NotificationStatusEnum | undefined;
};

interface OwnProps {
	dueDate: string;
	removed: boolean;
	resourceLookup: WorkOrderResourceLookupModel;
	workOrderStatus: WorkOrderStatus;
}

interface StateProps {
	employeeDetails: ScheduleBoardEmployee | undefined;
}

type Props = OwnProps & StateProps;

class EmployeeItem extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		employeeDetails: {} as ScheduleBoardEmployee,
	};

	static renderNotificationStatus = (status: NotificationStatus) => {
		return (
			<div>
				{status.emailSentAt &&
					<div>Email sent at {formatDate(status.emailSentAt, TimeFormatEnum.FULL_DATE)} ({status.emailStatus})</div>
				}
				{status.smsSentAt &&
					<div>SMS sent at {formatDate(status.smsSentAt, TimeFormatEnum.FULL_DATE)} ({status.smsStatus})</div>
				}
			</div>
		);
	};

	getEmployeeLabel = () => {
		const { employeeDetails: { firstName, lastName, wageRate, duplicateDisplayNameExists } = {}, resourceLookup: { name, notification } } = this.props;

		const label = firstName ? `${firstName[0]}${duplicateDisplayNameExists ? firstName[1] : ''} ${lastName} ${wageRate}` : name;
		const hasNotificationStatus = !!notification?.smsSentAt || !!notification?.emailSentAt;
		const notificationStatus = {
			smsSentAt: notification?.smsSentAt,
			smsStatus: notification?.smsStatus,
			emailSentAt: notification?.emailSentAt,
			emailStatus: notification?.emailStatus,
		};
		return (
			<span className="work-order-info-modal__resource-item--details">
				{label}
				{hasNotificationStatus &&
					<Tooltip message={EmployeeItem.renderNotificationStatus(notificationStatus)}>
						<span className="icon-help_fill" />
					</Tooltip>
				}
			</span>
		);
	};

	render() {
		const { resourceLookup, removed } = this.props;
		const { color } = resourceLookup;

		return removed ?
			(
				<Tooltip message="Removed" placement="right">
					<div className="work-order-info-modal__resource-item work-order-info-modal__resource-item--employee work-order-info-modal__resource-item--removed">
						<div className={getColorTextClass(color)}>{this.getEmployeeLabel()}</div>
					</div>
				</Tooltip>
			) : (
				<div className="work-order-info-modal__resource-item work-order-info-modal__resource-item--employee">
					<div className={getColorTextClass(color)}>{this.getEmployeeLabel()}</div>
				</div>
			);
	}
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
	const { resourceLookup: { id: resourceLookupId }, dueDate, workOrderStatus } = ownProps;
	const { workOrdersByDateDictionary, employees } = state.scheduleBoard;

	let employeeId: number | undefined;
	if (workOrderStatus === WorkOrderStatus.CANCELED) {
		employeeId = workOrdersByDateDictionary?.[dueDate]?.canceledWorkOrderResourceLookups?.[resourceLookupId]?.employeeId;
	} else {
		employeeId = workOrdersByDateDictionary?.[dueDate]?.workOrderResourceLookups?.[resourceLookupId]?.employeeId;
	}

	return {
		employeeDetails: employees && employeeId ? employees[employeeId] : undefined,
	};
};

export default connect<StateProps, null, OwnProps>(mapStateToProps)(EmployeeItem);
