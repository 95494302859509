import AccountPermission from 'acceligent-shared/enums/accountPermission';
import AccountPermissionTemplate from 'acceligent-shared/enums/accountPermissionTemplate';

import { AccountPermissionTemplateOptions } from 'ab-enums/accountPermissionTemplates.enum';

interface AccountRM {
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	uniqueId: string;
	firstName: string;
	lastName: string;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	assignableAsAccounting: boolean;
	assignableAsManagement: boolean;
	assignableAsTechnician: boolean;
	activateImmediately: boolean;
	shouldSendEmailInvite: boolean;
	shouldSendPhoneInvite: boolean;
	accountTemplateId: AccountPermissionTemplate;
	accountTemplate: typeof AccountPermissionTemplateOptions[0] | null;
	permissions: Record<AccountPermission, boolean>;
}

interface BulkAccountRM {
	accounts: AccountRM[];
	message: string | undefined;
}

interface AccountValidationVM {
	isUniqueIdMissing: boolean;
	isUniqueIdUsed: boolean;
	isPhoneOrEmailMissing: boolean;
	isEmailUsed: boolean;
	isEmailIncorrect: boolean;
	isPhoneUsed: boolean;
	isPhoneIncorrect: boolean;
	isNameMissing: boolean;
	isDuplicateEmailInCSV: boolean;
	isDuplicateUniqueIdInCSV: boolean;
	isDuplicatePhoneNumberInCSV: boolean;
	isMultipleUsers: boolean;
	isSameAccountInCompany: boolean;
}

class CSVValidAccountVM {
	email: string;
	phoneNumber: string;
	uniqueId: string;
	firstName: string;
	lastName: string;

	static toRequestModel(model: CSVValidAccountVM): BulkAccountRM['accounts'][0] {
		return {
			email: model.email,
			phoneNumber: model.phoneNumber,
			uniqueId: model.uniqueId,
			firstName: model.firstName,
			lastName: model.lastName,
			assignableToWorkOrder: true,
			assignableAsSuperintendent: false,
			assignableAsProjectManager: false,
			assignableAsQAQC: false,
			assignableAsAccounting: false,
			assignableAsManagement: false,
			assignableAsTechnician: false,
			activateImmediately: false,
			shouldSendEmailInvite: false,
			shouldSendPhoneInvite: false,
			accountTemplateId: AccountPermissionTemplate.FIELD_WORKER,
			accountTemplate: AccountPermissionTemplateOptions.find((_option) => _option.id === AccountPermissionTemplate.FIELD_WORKER) ?? null,
			permissions: Object.keys(AccountPermission).reduce(CSVValidAccountVM.reducePermissions, {} as Record<AccountPermission, boolean>),
		};

	}

	static reducePermissions(acc: Record<AccountPermission, boolean>, permission: AccountPermission) {
		acc[AccountPermission[permission]] = permission === AccountPermission.FR_FILL;
		return acc;
	}
}

interface CSVInvalidAccountVM {
	email: string;
	phoneNumber: string;
	uniqueId: string;
	firstName: string;
	lastName: string;
	errors: AccountValidationVM;
}

export class CSVBulkAccountVM {
	valid: CSVValidAccountVM[];
	invalid: CSVInvalidAccountVM[];

	static toRequestModel(model: CSVBulkAccountVM['valid']): BulkAccountRM {
		return {
			accounts: model.map(CSVValidAccountVM.toRequestModel),
			message: undefined,
		};
	}
}
