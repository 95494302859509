import * as React from 'react';

import CondensedTableVM from 'ab-viewModels/workOrder/workOrderForReportsCondensedTable';

interface OwnProps {
	sortedBy: keyof CondensedTableVM;
	sortedAscending: boolean;
	onSortChange: (sortBy: keyof CondensedTableVM) => void;
}

type Props = OwnProps;

const CondensedTableHeader: React.FC<Props> = ({ onSortChange, sortedBy, sortedAscending }) => {

	const onWorkOrderSort = React.useCallback(() => {
		onSortChange('workOrderCode');
	}, [onSortChange]);

	const onAddressSort = React.useCallback(() => {
		onSortChange('location');
	}, [onSortChange]);

	const onDueDateSort = React.useCallback(() => {
		onSortChange('dueDate');
	}, [onSortChange]);

	const onStatusSort = React.useCallback(() => {
		onSortChange('reviewStatus');
	}, [onSortChange]);

	const renderSortIcon = React.useCallback((sort: keyof CondensedTableVM) => {
		let icon = 'icon-table_sort';

		if (sort === sortedBy) {
			icon = sortedAscending ? 'icon-table_sort_up' : 'icon-table_sort_down';
		}

		return (
			<span className={icon} />
		);
	}, [sortedAscending, sortedBy]);

	return (
		<div className="condensed-table__header">
			<div className="condensed-table__header-element" onClick={onWorkOrderSort}>
				Work Order
				{renderSortIcon('workOrderCode')}
			</div>
			<div className="condensed-table__header-element" onClick={onAddressSort}>
				Address
				{renderSortIcon('location')}
			</div>
			<div className="condensed-table__header-element" onClick={onDueDateSort}>
				Date of Work
				{renderSortIcon('dueDate')}
			</div>
			<div className="condensed-table__header-element" onClick={onStatusSort}>
				Status
				{renderSortIcon('reviewStatus')}
			</div>
		</div>
	);
};

export default React.memo(CondensedTableHeader);
