import { Dispatch, AnyAction } from 'redux';
import { ExtendedRecordMap } from 'notion-types';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import NotionResponse from 'ab-viewModels/knowledgeCenter/notionResponse.viewModel';

import { GetRootState } from 'af-reducers';

export function findNotionResponse() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<NotionResponse>(API.V1.KNOWLEDGE_CENTER.FIND_NOTION_RESPONSE());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getNotionPage(pageId: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<ExtendedRecordMap>(API.V1.KNOWLEDGE_CENTER.GET_NOTION_PAGE(pageId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
