/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { ONE_SECOND } from 'af-constants/values';

export default class TimeInterval {
	private _intervalTimer: NodeJS.Timer;
	private _intervalAction: () => void;
	private _timeInterval: number;

	constructor(_intervalAction: () => void, timeInterval: number = ONE_SECOND) {
		this._intervalAction = _intervalAction;
		this._timeInterval = timeInterval;
		this.reset();
	}

	cancel = async (): Promise<void> => {
		if (this._intervalTimer) {
			clearInterval(this._intervalTimer);
		}
	};

	reset = async (defaultIntervalActionOverride?: () => void): Promise<void> => {
		this.cancel();

		if (defaultIntervalActionOverride) {
			this._intervalAction = defaultIntervalActionOverride;
		}

		this._intervalTimer = setInterval(async () => {
			if (this._intervalAction) {
				this._intervalAction();
			}
		}, this._timeInterval);
	};
}
