import * as React from 'react';

import { TimeSheetInfoVM, TimeSheetEntryInfoVM } from 'acceligent-shared/dtos/web/view/workOrder/submitForReviewRecap';

import TimeSheetEmployee from '../Shared/ReportsSummary/TimeSheets/TimeSheetEmployee';

interface OwnProps {
	approvedTimeSheets: TimeSheetInfoVM[];
	signedUnapprovedTimeSheets: TimeSheetInfoVM[];
	timeSheetEntriesByAccountIdMap: Nullable<{ [accountId: number]: TimeSheetEntryInfoVM[]; }>;
	unsignedTimeSheets: TimeSheetInfoVM[];
	timezone: Nullable<string>;
}

type Props = OwnProps;

const TimeSheets: React.FC<Props> = (props) => {
	const { timeSheetEntriesByAccountIdMap, approvedTimeSheets, signedUnapprovedTimeSheets, unsignedTimeSheets, timezone } = props;

	const renderEmployee = React.useCallback((timeSheet: TimeSheetInfoVM) => {
		return (
			<TimeSheetEmployee
				key={timeSheet.accountId}
				timeSheet={timeSheet}
				timeSheetEntries={timeSheetEntriesByAccountIdMap?.[timeSheet.accountId] ?? []}
				timezone={timezone}
			/>
		);
	}, [timeSheetEntriesByAccountIdMap, timezone]);

	if (!approvedTimeSheets.length && !signedUnapprovedTimeSheets.length && !unsignedTimeSheets.length) {
		return null;
	}

	const hasUnsignedTimeSheets = !!unsignedTimeSheets.length;
	const hasSignedUnapprovedTimeSheets = !!signedUnapprovedTimeSheets.length;
	const hasApprovedTimeSheets = !!approvedTimeSheets.length;

	return (
		<div className="report-submit-modal__section">
			<div className="report-submit-modal__section__title">TIME SHEETS</div>
			{hasUnsignedTimeSheets &&
				<div className="report-submit-modal__time-sheet-section">
					<div className="report-submit-modal__time-sheet-section__title">Sign, Approve and Finalize Time Sheets On Behalf Of</div>
					<div className="report-submit-modal__section__list">
						{unsignedTimeSheets.map(renderEmployee)}
					</div>
				</div>
			}
			{hasSignedUnapprovedTimeSheets &&
				<div className="report-submit-modal__time-sheet-section">
					<div className="report-submit-modal__time-sheet-section__title">Approve and Finalize Time Sheets On Behalf Of</div>
					<div className="report-submit-modal__section__list">
						{signedUnapprovedTimeSheets.map(renderEmployee)}
					</div>
				</div>
			}
			{hasApprovedTimeSheets &&
				<div className="report-submit-modal__time-sheet-section">
					<div className="report-submit-modal__time-sheet-section__title">Finalize Approved Time Sheets</div>
					<div className="report-submit-modal__section__list">
						{approvedTimeSheets.map(renderEmployee)}
					</div>
				</div>
			}
		</div>
	);
};

export default React.memo(TimeSheets);
