import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import Checkbox from 'af-fields/Checkbox';

import LocationDepartmentVM from 'ab-viewModels/location/locationDepartment.viewModel';

import Location from './Location';

type Props = {
	locations: LocationDepartmentVM[];
};

const DepartmentList = (props: Props) => {
	const { locations } = props;
	const departments = React.useMemo(() => {
		return locations.filter((_location) => !!_location.departments.length);
	}, [locations]);

	return (
		<>
			<Row>
				<Col className="row--padded-bottom" md={24}>
					<Field
						component={Checkbox}
						inline={true}
						isDisabled={!departments.length}
						label="Restock Regularly"
						name="stockRegularly"
					/>
				</Col>
			</Row>
			{!!departments.length ? (
				departments.map((_location, _index) => (
					<Location
						index={_index}
						key={_location.id}
						location={_location}
					/>
				)
				)
			) : (
				<span className="cell-additional-info">Need active location with departments to define inventory</span>
			)}
		</>
	);
};

export default DepartmentList;
