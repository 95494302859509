import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

export class WorkDaysVM {
	monday?: boolean;
	tuesday?: boolean;
	wednesday?: boolean;
	thursday?: boolean;
	friday?: boolean;
	saturday?: boolean;
	sunday?: boolean;

	constructor(workDays: WorkDaysVM = {}) {
		this.monday = !!workDays.monday;
		this.tuesday = !!workDays.tuesday;
		this.wednesday = !!workDays.wednesday;
		this.thursday = !!workDays.thursday;
		this.friday = !!workDays.friday;
		this.saturday = !!workDays.saturday;
		this.sunday = !!workDays.sunday;
	}
}

export default class Default extends WorkDaysVM {
	orderId?: number;
	selectedOrdersIds?: number[];
	code?: string;
	intervalSelection: boolean;
	/** YYYY-MM-DD */
	startDate: string;
	/** YYYY-MM-DD */
	endDate: string;
	/** YYYY-MM-DD */
	copyDate: string;
	shouldPreserveIndex: boolean;
	validOrders?: string[];
	finishedOrders?: string[];
	cancelledOrders?: string[];

	constructor(
		startDate: string,
		copyDate: string,
		workingWeekDays: WorkDaysVM,
		validOrders: string[] = [],
		finishedOrders: string[] = [],
		cancelledOrders: string[] = [],
		selectedOrdersIds?: number[],
		code?: string
	) {
		super(workingWeekDays);

		this.startDate = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
		this.copyDate = TimeUtils.formatDate(copyDate, TimeFormat.DB_DATE_ONLY);
		this.validOrders = validOrders;
		this.finishedOrders = finishedOrders;
		this.cancelledOrders = cancelledOrders;
		this.selectedOrdersIds = selectedOrdersIds;
		this.code = code;
	}
}
