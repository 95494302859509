import { Dispatch, AnyAction } from 'redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import API from 'af-constants/routes/api';

import * as FormUtil from 'ab-utils/form.util';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import ItemDepartmentVM from 'ab-viewModels/ItemDepartment/itemDepartment.viewModel';
import ItemTableViewModel from 'ab-viewModels/item/table.viewModel';
import ItemVM from 'ab-viewModels/item/edit.viewModel';
import ItemOptionVM from 'ab-viewModels/item/itemOption.viewModel';
import ListVM from 'ab-viewModels/item/list.viewModel';

import UpsertItemRM from 'ab-requestModels/item/upsert.requestModel';

import { GetRootState } from 'af-reducers';

export function create(form: UpsertItemRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'imageUrl');
			await http.post(API.V1.RESOURCES.ITEM.CREATE, fd, options);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(id: number, data: UpsertItemRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(data, 'imageUrl');
			await http.put(API.V1.RESOURCES.ITEM.FIND_BY_ID(id), fd, options);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ItemVM>(API.V1.RESOURCES.ITEM.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findTakenNameList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ListVM[]>(API.V1.RESOURCES.ITEM.FIND_NAME_LIST);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteItem(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.ITEM.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<ItemTableViewModel>>(API.V1.RESOURCES.ITEM.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findItemDepartments() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ItemDepartmentVM[]>(API.V1.RESOURCES.ITEM.FIND_ITEM_DEPARTMENTS);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ItemOptionVM[]>(API.V1.RESOURCES.ITEM.FIND_FOR_PURCHASE_ORDER_OPTIONS);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
