import * as React from 'react';

interface OwnProps {
	isBlue?: boolean;
	dataLang?: string;
}

type Props = React.PropsWithChildren<OwnProps>;

class CodeField extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		isBlue: false,
		dataLang: 'html',
	};

	render() {
		const { children, isBlue, dataLang } = this.props;

		let className = 'code-field';
		className = isBlue ? `${className} code-field--blue` : className;

		return (
			<figure className={className}>
				<pre>
					<code data-lang={dataLang}>
						{children}
					</code>
				</pre>
			</figure>
		);
	}
}

export default CodeField;
