import * as React from 'react';

interface OwnProps {
	isLeftFlat?: boolean;
}

type Props = React.PropsWithChildren<OwnProps>;

class MultipleOptionsButton extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		isLeftFlat: false,
	};

	render() {
		const { isLeftFlat, children } = this.props;

		let className = 'btn__rectangle-group';
		className = isLeftFlat ? `${className} btn__rectangle-group--flat-left` : className;

		return (
			<div className={className}>
				{children}
			</div>
		);
	}
}

export default MultipleOptionsButton;
