import * as React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { Button, Row } from 'react-bootstrap';

import { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import { TimeAllocationEntryFormModel } from '../formModel';
import TimeAllocationEntry from './TimeAllocationEntry';
import { TimeAllocationEntryAction } from '../TimeSheetEditModalBody';
import styles from '../styles.module.scss';

export interface OwnProps {
	readonly: boolean;
	index: number;
	isAllowedToAddTimeAllocations: boolean;
	subJobs: SubjobVM[];
	change: (fieldName: string, value) => void;
	changeEntries: (action: TimeAllocationEntryAction, entryIndex: Nullable<number>) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<TimeAllocationEntryFormModel>;

const TimeAllocationEntriesList: React.FC<Props> = (props) => {

	const {
		fields,
		change,
		readonly,
		isAllowedToAddTimeAllocations,
		subJobs,
		changeEntries,
	} = props;

	const removeEntry = React.useCallback((index: number) => {
		changeEntries(TimeAllocationEntryAction.REMOVE, index);
	}, [changeEntries]);

	const addEntry = React.useCallback(() => {
		changeEntries(TimeAllocationEntryAction.ADD, null);
	}, [changeEntries]);

	const renderTimeAllocationEntry = React.useCallback((field: string, index: number) => {

		const entry = fields.get(index);
		return (
			<Field
				change={change}
				changeEntries={changeEntries}
				component={TimeAllocationEntry}
				entry={entry}
				index={index}
				key={index}
				name={field}
				readonly={readonly}
				removeEntry={removeEntry}
				subJobs={subJobs}
			/>
		);
	}, [
		change,
		changeEntries,
		removeEntry,
		readonly,
		subJobs,
		fields,
	]);

	return (
		<>
			<div className={styles['time-allocations__time-allocation-list']}>
				{fields.map(renderTimeAllocationEntry)}
			</div>
			{isAllowedToAddTimeAllocations &&
				<Row className={styles['time-allocations__add-allocation']}>
					<Button className="btn btn--link" onClick={addEntry}>
						<span className="icon-plus" />
						<span>Add Time Allocation</span>
					</Button>
				</Row>
			}
		</>
	);
};

export default React.memo(TimeAllocationEntriesList);
