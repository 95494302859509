import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { ColumnSettingItem } from 'ab-viewModels/columnSettings.viewModel';

import ColumnItem from './ColumnItem';

import styles from './styles.module.scss';

interface Props {
	columns: ColumnSettingItem[];
	toggleColumnVisibility: (item: ColumnSettingItem) => void;
}

export default class ColumnList extends React.PureComponent<Props> {
	render() {
		const { columns, toggleColumnVisibility } = this.props;

		return (
			<Droppable
				droppableId="table_settings"
			>
				{(provided) => {
					return (
						<div
							className={styles['table-settings__list']}
							ref={provided.innerRef}
						>
							{columns.map(
								(cell, index) => (
									<ColumnItem
										index={index}
										item={cell}
										key={cell.name}
										onCheck={toggleColumnVisibility}
									/>
								)
							)}
							{provided.placeholder}
						</div>
					);
				}}
			</Droppable>
		);
	}
}
