import * as React from 'react';

import LockedValue from 'af-components/LockedValue';

import { previewSectionClass, previewSectionTitleClass, previewSectionClassRow, previewSectionClassColumn2 } from '../../helpers';

type Props = {
	surveyNeeded: boolean;
	surveyedBy: Nullable<string>;
	surveyDate: Nullable<string>;
	waterAvailable: boolean;
	waterSupplyInformation: Nullable<string>;
	subcontractorNeeded: Nullable<string>;
	subcontractorInformation: Nullable<string>;
	pipeSizes: Nullable<string>;
	pipeTypes: Nullable<string>;
	manholeDepths: Nullable<string>;
	manholeFrameDiameters: Nullable<string>;
	informationsProvidedByCustomersBy: Nullable<string>;
};

const SurveyDataSection: React.FC<Props> = (props) => {
	const {
		informationsProvidedByCustomersBy,
		manholeDepths,
		manholeFrameDiameters,
		pipeSizes,
		pipeTypes,
		subcontractorInformation,
		subcontractorNeeded,
		surveyDate,
		surveyNeeded,
		surveyedBy,
		waterAvailable,
		waterSupplyInformation,
	} = props;

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Survey Data
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Survey Needed" value={surveyNeeded ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Surveyed By" value={surveyedBy} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Survey Date" value={surveyDate} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Water Available" value={waterAvailable ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Water Supply information" value={waterSupplyInformation} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Subcontractor Needed" value={subcontractorNeeded ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Subcontractor information" value={subcontractorInformation} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Pipe Sizes" value={pipeSizes} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Pipe Types" value={pipeTypes} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Manhole Depths" value={manholeDepths} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Manhole Frame Diameters" value={manholeFrameDiameters} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="How Will information be Provided by Customer?" value={informationsProvidedByCustomersBy} />
				</div>
			</div>
		</div>
	);
};

export default React.memo(SurveyDataSection);
