import * as React from 'react';

interface OwnProps {
	name: string;
	pathNumber: number;
}

type Props = OwnProps;

const Icon = ({ name, pathNumber }: Props) => (
	<span className={`icon-${name}`}>
		{Array(pathNumber).fill(1).map((_, _index) => {
			const key = `path${_index + 1}`;
			return <span className={key} key={key} />;
		})}
	</span>
);

export default Icon;
