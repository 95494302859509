import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import WorkSummaryStatusEnum from 'acceligent-shared/enums/workSummaryStatus';

import { bemBlock } from 'ab-utils/bem.util';
import * as ArrayUtils from 'ab-utils/array.util';

import * as FieldReportActions from 'af-actions/fieldReport';
import * as BillingCodeActions from 'af-actions/billingCode';
import * as WorkSummaryDetailsActions from 'af-actions/workSummaryDetails';
import * as WorkOrderActions from 'af-actions/workOrder';

import socket from 'af-utils/socket.util';

import SocketEvent from 'ab-enums/socketEvent.enum';

import WorkSummaryVM from 'ab-viewModels/fieldReport/workSummary.viewModel';
import { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';
import WorkSummaryStatusVM from 'ab-viewModels/workOrder/workSummaryStatus.viewModel';

import WorkSummaryDetailsAssignBillingCodeRM from 'ab-requestModels/workSummaryDetails/assignBillingCode.requestModel';

import CLIENT from 'af-routes/client';

import * as UserUtils from 'af-utils/user.util';

import { resolveHighlightGroupKey } from './helpers';
import WorkSummaryBillableWork from './BillableAndNonBillableWork/BillableWork';
import WorkSummaryDetails from './Details';
import NonBillableWork from './BillableAndNonBillableWork/NonBillableWork';
import WorkSummaryStatus from './Status';
import { WorkSummaryUpdatedBillingCodesVM } from 'ab-socketModels/viewModels/workOrder/workSummaryUpdatedBillingCodes.viewModel';
import { RootState } from 'af-reducers';

interface OwnProps {
	jobId: number;
	fieldReportId: number;
	workOrderId: number;
	invalidateWorkSummaryStatus: () => Promise<void>;
	workSummaryStatus: WorkSummaryStatusVM;
	workOrderCode: string;
	canCompleteWorkSummary: boolean;
	hasPermissionToManageWS: boolean;
	areFRsReadOnly: boolean;
	pathName: string;
	isAbleToReject: boolean;
}

type Props = ConnectedProps<typeof connector> & OwnProps;

const _sortWorkSummaries = (_wsa: WorkSummaryVM, _wsb: WorkSummaryVM) => {
	const _wsaGroupKey = resolveHighlightGroupKey(_wsa.billableWorkId, _wsa.type, _wsa.definitionFields);
	const _wsbGroupKey = resolveHighlightGroupKey(_wsb.billableWorkId, _wsb.type, _wsb.definitionFields);

	return _wsaGroupKey.localeCompare(_wsbGroupKey);
};

const _sortWorkSummaryDetails = (_wsda: WorkSummaryDetailVM, _wsdb: WorkSummaryDetailVM) => {
	const _wsaGroupKey = resolveHighlightGroupKey(_wsda.billableWorkId, _wsda.workType, _wsda.definitionFields);
	const _wsbGroupKey = resolveHighlightGroupKey(_wsdb.billableWorkId, _wsdb.workType, _wsdb.definitionFields);

	return _wsaGroupKey.localeCompare(_wsbGroupKey);
};

const _workSummaryDetailsByGroupKeyReducer = (_acc, _currWS) => {
	const _wsGroupkey = resolveHighlightGroupKey(_currWS.billableWorkId, _currWS.workType, _currWS.definitionFields);
	if (!_acc[_wsGroupkey]) {
		_acc[_wsGroupkey] = [];
	}
	_acc[_wsGroupkey].push(_currWS);
	return _acc;
};

const _isEveryWorkSummaryBilled = (_ws: WorkSummaryVM) => !!_ws.billingCodeId;

const WorkSummary: React.FC<Props> = (props) => {
	const {
		findBillingCodesForJobId,
		findWorkSummaryDetails,
		jobId,
		fieldReportId,
		findWorkSummary,
		updateBillingStatuses,
		assignBillingCode,
		workOrderId,
		invalidateWorkSummaryStatus,
		workSummaryStatus,
		editWorkSummaryStatus,
		canCompleteWorkSummary,
		hasPermissionToManageWS,
		areFRsReadOnly,
		workOrderCode,
		pathName,
		checkIfBillableWorkExists,
		isAbleToReject,
		companyData,
		organizationData,
		allowToMarkAllAsNonBillable,
		bulkUpdateBillingStatuses,
		isPlatformAdmin,
		resetJobWorkSummary,
	} = props;

	const [billingCodes, setBillingCodes] = React.useState<Nullable<BillingCodeVM[]>>(null);
	const [workSummaryDetails, setWorkSummaryDetails] = React.useState<Nullable<WorkSummaryDetailVM[]>>(null);
	const [workSummaryDetailsByGroupKey, setWorkSummaryDetailsByGroupKey] = React.useState<Nullable<Record<string, WorkSummaryDetailVM[]>>>(null);
	const [workSummaries, setWorkSummaries] = React.useState<Nullable<WorkSummaryVM[]>>(null);

	const [billableWorkSummaries, setBillableWorkSummaries] = React.useState<Nullable<WorkSummaryVM[]>>(null);
	const [nonBillableWorkSummaries, setNonBillableWorkSummaries] = React.useState<Nullable<WorkSummaryVM[]>>(null);

	const [alternativeWorkSummaryDetails, setAlternativeWorkSummaryDetails] = React.useState<Set<WorkSummaryDetailVM>>(new Set());
	const [alternativeWorkSummary, setAlternativeWorkSummary] = React.useState<Set<WorkSummaryVM>>(new Set());

	const [highlightedGroup, setHighlightedGroup] = React.useState<Nullable<string>>(null);
	const [highlightedGroupKey, setHighlightedGroupKey] = React.useState<Nullable<string>>(null);

	const [noWorkSummaryMessage, setNoWorkSummaryMessage] = React.useState<Nullable<string>>(null);

	const isWorkSummaryReadOnly = (workSummaryStatus.status === WorkSummaryStatusEnum.COMPLETED) || areFRsReadOnly;

	const fetchAndInitializeData = React.useCallback(
		async () => {
			const fetchedBillingCodes = await findBillingCodesForJobId(jobId);
			const fetchedWorkSummaryDetails = await findWorkSummaryDetails(fieldReportId);
			const fetchedWorkSummaries = await findWorkSummary(fieldReportId);

			if (fetchedWorkSummaryDetails.length === 0) {
				const billableWorkExists = await checkIfBillableWorkExists(fieldReportId);
				const emptyWorkSummaryMessage = billableWorkExists
					? 'Fill in the Report Type to generate Work Summary.'
					: 'No Report Types are generating Work Summary.';
				setNoWorkSummaryMessage(emptyWorkSummaryMessage);
			}

			fetchedWorkSummaryDetails.sort(_sortWorkSummaryDetails);
			fetchedWorkSummaries.sort(_sortWorkSummaries);
			const newWorkSummariesByGroupKey = fetchedWorkSummaryDetails
				.reduce<Record<string, WorkSummaryDetailVM[]>>(_workSummaryDetailsByGroupKeyReducer, {});

			setWorkSummaryDetailsByGroupKey(newWorkSummariesByGroupKey);
			setWorkSummaryDetails(fetchedWorkSummaryDetails);
			setBillingCodes(fetchedBillingCodes);
			setWorkSummaries(fetchedWorkSummaries);
		}, [checkIfBillableWorkExists, fieldReportId, findBillingCodesForJobId, findWorkSummary, findWorkSummaryDetails, jobId]);

	const onBillingStatusToggle = React.useCallback(async (workSummaryDetail: WorkSummaryDetailVM) => {
		const { id, isBillable } = workSummaryDetail;

		if (isWorkSummaryReadOnly) {
			return;
		}

		await updateBillingStatuses({ fieldReportId, workSummaryDetails: [{ isBillable: !isBillable, id }] });
		await fetchAndInitializeData();
		await invalidateWorkSummaryStatus();
	}, [fetchAndInitializeData, fieldReportId, invalidateWorkSummaryStatus, isWorkSummaryReadOnly, updateBillingStatuses]);

	const onBulkStatusToFalse = React.useCallback(async () => {

		await bulkUpdateBillingStatuses({ fieldReportId });
		await fetchAndInitializeData();
		await invalidateWorkSummaryStatus();
	}, [fetchAndInitializeData, invalidateWorkSummaryStatus, bulkUpdateBillingStatuses, fieldReportId]);

	const onBillingCodeSelect = React.useCallback(async (
		billingCode: Nullable<BillingCodeVM>,
		workSummaryDetailIds: number[]
	) => {
		if (isWorkSummaryReadOnly) {
			return;
		}

		const assignBillingCodeRM: WorkSummaryDetailsAssignBillingCodeRM = {
			billingCodeId: billingCode?.id ?? null,
			fieldReportId,
			workOrderId,
			workSummaryDetailIds,
		};

		await assignBillingCode(assignBillingCodeRM);
		await fetchAndInitializeData();
		await invalidateWorkSummaryStatus();
	}, [assignBillingCode, fetchAndInitializeData, fieldReportId, invalidateWorkSummaryStatus, isWorkSummaryReadOnly, workOrderId]);

	React.useEffect(() => {
		if (!workSummaryDetails) {
			return;
		}
		const currHighlightedGroup = highlightedGroup ?? highlightedGroupKey;
		const currHighlightedGroupWsd = workSummaryDetails.find((_wsd) => {
			const _wsdGroup = _wsd.group ?? resolveHighlightGroupKey(_wsd.billableWorkId, _wsd.workType, _wsd.definitionFields);
			if (currHighlightedGroup === _wsdGroup) {
				return true;
			}
		});

		if (!currHighlightedGroupWsd) {
			return;
		}

		const newAlternativeWorkSummaryDetails = workSummaryDetails.reduce<Set<WorkSummaryDetailVM>>((_acc, _wsd) => {
			if (_wsd.billableWorkId !== currHighlightedGroupWsd.billableWorkId &&
				ArrayUtils.areEqualByKeys(_wsd.definitionFields, currHighlightedGroupWsd.definitionFields, ['value', 'fieldType', 'name']) &&
				_wsd.workType === currHighlightedGroupWsd.workType) {
				_acc.add(_wsd);
			}
			return _acc;
		}, new Set());

		setAlternativeWorkSummaryDetails(newAlternativeWorkSummaryDetails);
	}, [highlightedGroup, highlightedGroupKey, workSummaries, workSummaryDetails]);

	React.useEffect(() => {
		if (!workSummaries) {
			return;
		}

		const currHighlightedGroup = highlightedGroup ?? highlightedGroupKey;

		const currHighlightedGroupWs = workSummaries?.find((_wsd) => {
			const _wsdGroup = _wsd.group ?? resolveHighlightGroupKey(_wsd.billableWorkId, _wsd.type, _wsd.definitionFields);
			if (currHighlightedGroup === _wsdGroup) {
				return true;
			}
		});

		if (!currHighlightedGroupWs) {
			return;
		}

		const newAlternativeWorkSummary = workSummaries.reduce<Set<WorkSummaryVM>>((_acc, _ws) => {
			if (_ws.billableWorkId !== currHighlightedGroupWs.billableWorkId &&
				ArrayUtils.areEqualByKeys(_ws.definitionFields, currHighlightedGroupWs.definitionFields, ['value', 'fieldType', 'fieldName']) &&
				_ws.type === currHighlightedGroupWs.type) {
				_acc.add(_ws);
			}
			return _acc;
		}, new Set());

		setAlternativeWorkSummary(newAlternativeWorkSummary);
	}, [highlightedGroup, highlightedGroupKey, workSummaries]);

	const onRowHighlight = React.useCallback((groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => {
		setHighlightedGroup(groupToHighlight);
		setHighlightedGroupKey(groupKeyToHighlight);
	}, []);

	const onWorkSummaryStatusChange = React.useCallback((wss: WorkSummaryStatusEnum) => {
		return async () => {
			if (isWorkSummaryReadOnly && wss !== WorkSummaryStatusEnum.OUTDATED) {
				return;
			}

			await editWorkSummaryStatus(fieldReportId, wss);
			await invalidateWorkSummaryStatus();
		};
	}, [editWorkSummaryStatus, fieldReportId, invalidateWorkSummaryStatus, isWorkSummaryReadOnly]);

	React.useEffect(() => {
		fetchAndInitializeData();
	}, [fetchAndInitializeData]);

	React.useEffect(() => {
		if (workSummaries) {
			const { filteredBillableWS, filteredNonBillableWS } = workSummaries.reduce((_acc, _curr) => {
				if (_curr.isBillable) {
					_acc.filteredBillableWS.push(_curr);
				} else if (!_curr.isBillable) {
					_acc.filteredNonBillableWS.push(_curr);
				}
				return _acc;
			}, { filteredBillableWS: [] as WorkSummaryVM[], filteredNonBillableWS: [] as WorkSummaryVM[] });

			setBillableWorkSummaries(filteredBillableWS);
			setNonBillableWorkSummaries(filteredNonBillableWS);
		}
	}, [workSummaries]);

	React.useEffect(() => {
		socket.connection?.subscribe(
			SocketEvent.V2.BE.FIELD_REPORT_LIST.WORK_SUMMARY_UPDATE_BILLING_CODES,
			async (data: WorkSummaryUpdatedBillingCodesVM, { socketId }: { socketId: string; }) => {
				if (socketId === socket.getConnection()?.getId() || fieldReportId !== data.fieldReportId) {
					return;
				}
				const updatedBillingCodes = await findBillingCodesForJobId(jobId);
				setBillingCodes(updatedBillingCodes);

				const fetchedWorkSummaries = await findWorkSummary(fieldReportId);
				setWorkSummaries(fetchedWorkSummaries.sort(_sortWorkSummaries));
			});

		return () => {
			socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.WORK_SUMMARY_UPDATE_BILLING_CODES);
		};
	}, [fieldReportId, findWorkSummary, findBillingCodesForJobId, jobId]);

	if (!billingCodes || !workSummaryDetails || !billableWorkSummaries || !nonBillableWorkSummaries || !workSummaryDetailsByGroupKey) {
		return null;
	}

	if (!workSummaryDetails.length && noWorkSummaryMessage) {
		return (
			<div className={bemBlock('field-report__work-summary', { empty: true })}>
				{noWorkSummaryMessage}
			</div>
		);
	}

	const areAllWorkSummariesBilled = !!billableWorkSummaries?.every(_isEveryWorkSummaryBilled);

	return (
		<div className="field-report__work-summary">
			{
				areFRsReadOnly && isAbleToReject &&
				<div className="field-report-type__status-information">
					In order to edit Report Types, please reject Field Report, which will bring it back to edit mode.
				</div>
			}
			<div className="field-report-type__status-information">
				This information is based on true, original data that was gathered in the field report.
				Some overrides might have been applied on job level.
				{
					hasPermissionToManageWS &&
					<Link className="m-l-s" to={CLIENT.COMPANY.JOBS.PREVIEW(organizationData.alias, companyData.name, jobId.toString())}>
						See here
					</Link>
				}
			</div>
			<WorkSummaryStatus
				status={workSummaryStatus}
			/>
			<WorkSummaryBillableWork
				alternativeWorkSummary={alternativeWorkSummary}
				areAllWorkSummariesBilled={areAllWorkSummariesBilled}
				areFRsReadOnly={areFRsReadOnly}
				billingCodes={billingCodes}
				canCompleteWorkSummary={canCompleteWorkSummary}
				highlightedGroup={highlightedGroup}
				highlightedGroupKey={highlightedGroupKey}
				isWorkSummaryStatusCompleted={workSummaryStatus.status === WorkSummaryStatusEnum.COMPLETED}
				isWorkSummaryStatusReviewed={workSummaryStatus.status === WorkSummaryStatusEnum.REVIEWED}
				onBillingCodeSelect={onBillingCodeSelect}
				onRowHighlight={onRowHighlight}
				onWorkSummaryStatusChange={onWorkSummaryStatusChange}
				workOrderCode={workOrderCode}
				workSummary={billableWorkSummaries}
				workSummaryDetails={workSummaryDetails}
				workSummaryDetailsByGroupKey={workSummaryDetailsByGroupKey}
			/>
			<NonBillableWork
				alternativeWorkSummary={alternativeWorkSummary}
				billingCodes={billingCodes}
				highlightedGroup={highlightedGroup}
				highlightedGroupKey={highlightedGroupKey}
				isReadOnly={isWorkSummaryReadOnly}
				onRowHighlight={onRowHighlight}
				workOrderCode={workOrderCode}
				workSummary={nonBillableWorkSummaries}
				workSummaryDetails={workSummaryDetails}
				workSummaryDetailsByGroupKey={workSummaryDetailsByGroupKey}
			/>
			<WorkSummaryDetails
				allowToMarkAllAsNonBillable={allowToMarkAllAsNonBillable && !isWorkSummaryReadOnly}
				alternativeWorkSummaryDetails={alternativeWorkSummaryDetails}
				canResetJobWorkSummary={isPlatformAdmin}
				fetchAndInitializeData={fetchAndInitializeData}
				fieldReportId={fieldReportId}
				highlightedGroup={highlightedGroup}
				highlightedGroupKey={highlightedGroupKey}
				isReadOnly={isWorkSummaryReadOnly}
				onBillingStatusToggle={onBillingStatusToggle}
				onBulkStatusToFalse={onBulkStatusToFalse}
				onRowHighlight={onRowHighlight}
				pathName={pathName}
				resetJobWorkSummary={resetJobWorkSummary}
				workOrderCode={workOrderCode}
				workSummaryDetails={workSummaryDetails}
				workSummaryDetailsByGroupKey={workSummaryDetailsByGroupKey}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { companyData, organizationData, userData } = state.user;
	const { company } = state.company;
	if (!companyData || !organizationData || !userData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
		organizationData,
		allowToMarkAllAsNonBillable: company?.allowAllNonBillableShortcut ?? false,
		isPlatformAdmin: UserUtils.isAdmin(userData),
	};
};

function mapDispatchToProps() {
	return {
		findBillingCodesForJobId: BillingCodeActions.findForJobId,
		findWorkSummaryDetails: FieldReportActions.findWorkSummaryDetails,
		findWorkSummary: FieldReportActions.findWorkSummary,
		updateBillingStatuses: WorkSummaryDetailsActions.updateBillingStatuses,
		bulkUpdateBillingStatuses: WorkSummaryDetailsActions.bulkUpdateBillingStatuses,
		assignBillingCode: WorkSummaryDetailsActions.assignBillingCode,
		editWorkSummaryStatus: WorkOrderActions.editWorkSummaryStatus,
		checkIfBillableWorkExists: FieldReportActions.checkIfBillableWorkExists,
		resetJobWorkSummary: FieldReportActions.resetJobWorkSummary,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(WorkSummary);
