import * as React from 'react';

import Priority, { PriorityItem, PriorityList } from 'acceligent-shared/enums/priority';

interface OwnProps {
	priority: Priority | string;
}

interface State {
	priorityItem: Nullable<PriorityItem>;
}

type Props = OwnProps;

class PriorityLabel extends React.PureComponent<Props, State> {

	state: State = {
		priorityItem: null,
	};

	static getDerivedStateFromProps(props: Props) {
		const { priority } = props;
		const priorityItem: Nullable<PriorityItem> = PriorityList.find((_item) => _item.id === priority) ?? null;

		return { priorityItem };
	}

	renderIcon = () => {
		const { priority } = this.props;

		let className = 'priority-label__icon';

		switch (priority) {
			case Priority.HIGH:
				className = `${className} priority-label__icon--high icon-priority_high`;
				break;
			case Priority.MEDIUM:
				className = `${className} priority-label__icon--medium icon-priority_medium`;
				break;
			case Priority.LOW:
				className = `${className} priority-label__icon--low icon-priority_low`;
				break;
		}

		return <span className={className} />;
	};

	render() {
		const { priorityItem } = this.state;

		if (!priorityItem) {
			return null;
		}

		return (
			<span className="priority-label">
				{this.renderIcon()}
				{priorityItem.label}
			</span>
		);
	}
}

export default PriorityLabel;
