import * as React from 'react';

import styles from '../styles.module.scss';

interface Props {
	tableHeader: (resolvedData: Nullable<Record<string, unknown>>) => JSX.Element;
	tableHeaderData: Nullable<Record<string, unknown>>;
}

const TableHeader: React.FC<Props> = (props) => {
	const { tableHeader, tableHeaderData } = props;
	return (
		<div className={styles['table-container__table-header']}>
			{tableHeader(tableHeaderData)}
		</div>
	);
};

export default React.memo(TableHeader);
