import * as React from 'react';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';

import { SortByEnum, OrderByEnum } from 'ab-enums/mechanicView.enum';

interface OwnProps {
	sortBy: SortByEnum;
	orderBy: OrderByEnum;
	changeSortBy: (sortByEnum: SortByEnum) => void;
}

type Props = OwnProps;

class SortByButtonGroup extends React.PureComponent<Props> {

	getTooltip = (sort: SortByEnum, label: string) => {
		const { orderBy, sortBy } = this.props;
		let orderLabel = OrderByEnum.DESCENDING;
		if (sort === sortBy && orderBy === OrderByEnum.DESCENDING) {
			orderLabel = OrderByEnum.ASCENDING;
		}
		return `Sort by ${label} ${orderLabel === OrderByEnum.DESCENDING ? 'Descending' : 'Ascending'}`;
	};

	renderLabel = (sortBy: SortByEnum, label: string) => {
		const { orderBy } = this.props;
		if (this.props.sortBy === sortBy) {
			return (
				<>
					<span className="m-r-xs">{label}</span>
					<span className={orderBy === OrderByEnum.DESCENDING ? 'icon-sort' : 'icon-sort_reverse'} />
				</>
			);
		}
		return label;
	};

	renderButton = (sortBy: SortByEnum, label: string) => {
		const { changeSortBy, sortBy: _currentSort } = this.props;
		return (
			<RectangleButton
				action={changeSortBy.bind(this, sortBy)}
				isActive={sortBy === _currentSort}
				label={this.renderLabel(sortBy, label)}
				tooltipMessage={this.getTooltip(sortBy, label)}
				tooltipPlacement="bottom"
			/>
		);
	};

	render() {
		return (
			<>
				<span className="m-r-s">Sort By</span>
				<MultipleOptionsButton>
					{this.renderButton(SortByEnum.PRIORITY, 'Priority')}
					{this.renderButton(SortByEnum.LAST_UPDATED, 'Updated')}
					{this.renderButton(SortByEnum.EXPECTED_RETURN, 'Return Date')}
				</MultipleOptionsButton>
			</>
		);
	}
}

export default SortByButtonGroup;
