import * as React from 'react';
import DatePicker from 'react-datepicker';
import enUs from 'date-fns/locale/en-US';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { RectangleButton } from 'af-components/MultipleOptionsButton/RectangleButton';

interface OwnProps {
	selected: Date;
	onChange: (selected: Date, startDate: Date, endDate: Date) => void;
}

type Props = OwnProps;

interface State {
	weekDays: Date[];
}

class WeeklyPicker extends React.Component<Props, State> {
	state: State = {
		weekDays: [],
	};

	private _datepicker: Nullable<DatePicker> = null;

	componentDidMount() {
		const { selected } = this.props;
		this.setWeekDays(selected, false);
	}

	setWeekDays = (value: Date, sendChange: boolean) => {
		const { onChange } = this.props;
		const { weekDays } = this.state;

		if (weekDays.includes(value)) {
			return;
		}

		const momentDate = TimeUtils.parseMoment(value);

		if (!momentDate) {
			throw new Error('Incorrect date provided');
		}

		const startDate = momentDate.clone().startOf('week');
		const endDate = momentDate.clone().endOf('week');
		const newWeekDays: Date[] = [];

		for (let i = 0; i < 7; i++) {
			newWeekDays.push(startDate.clone().add(i, 'days').toDate());
		}

		this.setState(
			() => ({ weekDays: newWeekDays }),
			() => {
				if (sendChange) {
					onChange(value, startDate.toDate(), endDate.toDate());
				}
			}
		);

	};

	onDateChange = (value: Date) => {
		this.setWeekDays(value, true);
	};

	setDatepickerRef = (datepicker: DatePicker) => this._datepicker = datepicker;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	focusDatePicker = () => (this._datepicker as any).input.focus();

	render() {
		const { selected } = this.props;
		const { weekDays } = this.state;

		return (
			<div className="table-filter__date week-picker">
				<DatePicker
					className="form-control"
					dateFormat={TimeUtils.datePickerFormat(TimeFormatEnum.DATE_ONLY)}
					dropdownMode="select"
					highlightDates={weekDays}
					locale={enUs}
					onChange={this.onDateChange}
					ref={this.setDatepickerRef}
					selected={selected}
					showMonthDropdown={true}
					showYearDropdown={true}
					todayButton="Today"
				/>
				<RectangleButton
					action={this.focusDatePicker}
					isLeftOpen={true}
					isSquare={true}
					label={<span className="icon-calendar" />}
				/>
			</div>
		);
	}
}

export default WeeklyPicker;
