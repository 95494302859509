import * as React from 'react';

import { bemBlock } from 'ab-utils/bem.util';

interface OwnProps {
	showError: boolean;
	text?: Nullable<string>;
}

type Props = React.PropsWithChildren<OwnProps>;

const Fieldset: React.FC<Props> = (props) => {
	const {
		showError,
		text = '',
		children,
	} = props;

	return (
		<fieldset className={bemBlock('fieldset', [showError ? '' : 'inactive'])}>
			<legend className="fieldset__legend">
				{showError ? text : null}
			</legend>
			{children}
		</fieldset>
	);
};

export default React.memo(Fieldset);
