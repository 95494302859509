import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import { MAX_WR_TRUCK_EQUIPMENT_ADDITIONAL_FIELDS } from 'ab-constants/value';

import styles from '../../../styles.module.scss';
import { AdditionalTrucksEquipmentField } from '../../../formModel';
import AdditionalField from './AdditionalField';

type Props = WrappedFieldArrayProps<AdditionalTrucksEquipmentField>;

const AdditionalTrucksAndEquipment: React.FC<Props> = (props) => {
	const { fields } = props;

	const onAddClick = React.useCallback(() => {
		fields.push({ isChecked: false, name: '' });
	}, [fields]);

	const onRemoveClick = React.useCallback((index: number) => () => fields.remove(index), [fields]);

	const renderAdditionalField = React.useCallback((_af: string, _index: number) => {
		return <AdditionalField fieldName={_af} onRemoveClick={onRemoveClick(_index)} />;
	}, [onRemoveClick]);

	return <>
		{fields.map(renderAdditionalField)}
		{fields.length < MAX_WR_TRUCK_EQUIPMENT_ADDITIONAL_FIELDS && (
			<div
				className={styles['job-form__add-field-button']}
				onClick={onAddClick}
			>
				<span className="icon-plus" />
				<span>
					Add
				</span>
			</div>)
		}
	</>;
};

export default React.memo(AdditionalTrucksAndEquipment);
