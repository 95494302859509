import * as React from 'react';

import SharedPreview from './TextPreview';

interface OwnProps {
	isPreview: boolean;
}

type Props<InputProps, PreviewProps> = OwnProps & ((InputProps & Partial<PreviewProps>) | (PreviewProps & Partial<InputProps>));

function fieldStateHoc<TInputProps, TPreviewProps extends React.ComponentProps<typeof SharedPreview>>(
	Input: React.FC<TInputProps>,
	Preview: React.FC<TPreviewProps> = SharedPreview as React.FC
) {
	return (props: Props<React.ComponentProps<typeof Input>, React.ComponentProps<typeof Preview>>) => {
		const { isPreview, ...innerProps } = props;
		return isPreview
			? <Preview {...innerProps as unknown as TPreviewProps} />
			: <Input {...innerProps as unknown as TInputProps & React.JSX.IntrinsicAttributes} />;
	};
}

export default fieldStateHoc;
