import * as React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { Button, Row } from 'react-bootstrap';

import FieldWorkClassificationCodeListItemVM from 'acceligent-shared/dtos/web/view/fieldWorkClassificationCode/listItem';
import { EquipmentListItemVM } from 'acceligent-shared/dtos/web/view/timeSplitEquipment/timeSplitEquipment';

import { bemElement } from 'ab-utils/bem.util';

import { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import { TimeSplitEntryFormModel } from '../formModel';
import TimeSplitEntry from './TimeSplitEntry';
import { TimeSplitEntryAction } from '../TimeSheetEditModalBody';

type TimeSplitEntryEquipmentSections = {
	title: string;
	options: EquipmentListItemVM[];
};

export interface OwnProps {
	readonly: boolean;
	index: number;
	isAllowedToAddEquipment: boolean;
	canEditClassificationCodes: boolean;
	canSeeTimeAllocations: boolean;
	classificationCodeIdsWithValues: Record<number, string>;
	equipmentIdsWithValues: Record<number, string>;
	classificationCodes: FieldWorkClassificationCodeListItemVM[];
	sections: Nullable<TimeSplitEntryEquipmentSections>[];
	subJobs: SubjobVM[];
	changeEntries: (action: TimeSplitEntryAction, entryIndex: Nullable<number>) => void;
	change: (fieldName: string, value) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<TimeSplitEntryFormModel>;

const TimeSplitEntriesList: React.FC<Props> = (props) => {

	const {
		fields,
		isAllowedToAddEquipment,
		classificationCodeIdsWithValues,
		classificationCodes,
		sections,
		equipmentIdsWithValues,
		canEditClassificationCodes,
		changeEntries,
		change,
		readonly,
		subJobs,
		canSeeTimeAllocations,
	} = props;

	const removeEntry = React.useCallback((index: number) => {
		changeEntries(TimeSplitEntryAction.REMOVE, index);
	}, [changeEntries]);

	const addEntry = React.useCallback(() => {
		changeEntries(TimeSplitEntryAction.ADD, null);
	}, [changeEntries]);

	const renderTimeSplitEntry = React.useCallback((field: string, index: number) => {
		const entry = fields.get(index);
		return (
			<Field
				canEditClassificationCodes={canEditClassificationCodes}
				canSeeTimeAllocations={canSeeTimeAllocations}
				change={change}
				changeEntries={changeEntries}
				classificationCodeIdsWithValues={classificationCodeIdsWithValues}
				classificationCodes={classificationCodes}
				component={TimeSplitEntry}
				entry={entry}
				equipmentIdsWithValues={equipmentIdsWithValues}
				index={index}
				key={index}
				name={field}
				readonly={readonly}
				removeEntry={removeEntry}
				sections={sections}
				subJobs={subJobs}
			/>
		);
	}, [
		canEditClassificationCodes,
		canSeeTimeAllocations,
		change,
		changeEntries,
		classificationCodeIdsWithValues,
		classificationCodes,
		equipmentIdsWithValues,
		fields,
		removeEntry,
		sections,
		readonly,
		subJobs,
	]);

	return (
		<>
			<div className={bemElement('time-sheet-list__equipment-modal', 'time-split-list')}>
				{fields.map(renderTimeSplitEntry)}
			</div>
			{isAllowedToAddEquipment &&
				<Row className={bemElement('time-sheet-list__equipment-modal', 'add-equipment')}>
					<Button className="btn btn--link" onClick={addEntry}>
						<span className="icon-plus" />
						<span>Add Equipment</span>
					</Button>
				</Row>
			}
		</>
	);
};

export default React.memo(TimeSplitEntriesList);
