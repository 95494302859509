import { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';

import { SignatureRequestModel } from 'ab-requestModels/signature.requestModel';
import SubmitWorkOrderForReviewRM from 'ab-requestModels/workOrder/submitForReview.requestModel';
import ApproveWorkOrderRM from 'ab-requestModels/workOrder/approveWorkOrder.requestModel';
import CreateCustomerSignatureForPublicReportsRM from 'ab-requestModels/workOrder/createCustomerSignatureForPublicReports.requestModel';

class SignatureForm {
	fullName: string;
	isSigned?: boolean;
	signatureImage: Nullable<string>;
	digitalSignatureId: Nullable<number>;
	signedAt: string;

	static toSubmitForReviewRM(form: SignatureForm, reviewLevel: WorkOrderReviewLevel, revertOutdated: boolean): SubmitWorkOrderForReviewRM {
		return {
			signatureImage: form.signatureImage,
			digitalSignatureId: form.digitalSignatureId,
			signedAt: form.signedAt,
			reviewLevel,
			revertOutdated,
		};
	}

	static toSignatureRM(form: SignatureForm): SignatureRequestModel {
		if (!form.signatureImage) {
			throw new Error('Missing signature image');
		}
		const rm: SignatureRequestModel = {
			signatureImage: form.signatureImage,
			signedAt: form.signedAt,
		};
		if (!!form.fullName) {
			rm.fullName = form.fullName;
		}
		return rm;
	}

	static toApproveRM(form: SignatureForm, reviewLevel: Nullable<WorkOrderReviewLevel>, revertOutdated: boolean): ApproveWorkOrderRM {
		return {
			signatureImage: form.signatureImage,
			digitalSignatureId: form.digitalSignatureId,
			signedAt: form.signedAt,
			reviewLevel,
			revertOutdated,
			fullName: form.fullName,
		};
	}

	static toCreateCustomerSignatureRM(form: SignatureForm): CreateCustomerSignatureForPublicReportsRM['signatureData'] {
		if (!form.signatureImage) {
			throw new Error('Missing signature image');
		}
		const rm: CreateCustomerSignatureForPublicReportsRM['signatureData'] = {
			signatureImage: form.signatureImage,
			signedAt: form.signedAt,
			fullName: form.fullName,
		};
		return rm;
	}
}

export default SignatureForm;

