import * as handlebars from 'handlebars';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import { EmailTypesArray, PhoneTypeNames, PhoneTypesArray } from 'acceligent-shared/enums/contactMethodType';
import WorkOrderPositionOption from 'acceligent-shared/enums/workOrderPosition';
import ResourceStatus from 'acceligent-shared/enums/resourceStatus';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';
import FileType from 'acceligent-shared/enums/fileType';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import { ExtendedColorValue, DefaultColor, ColorPalette } from 'acceligent-shared/enums/color';
import CDLStatus from 'acceligent-shared/enums/cdlStatus';
import NotificationTypeEnum from 'acceligent-shared/enums/notificationType';
import SubscriptionStatus from 'acceligent-shared/enums/subscriptionStatus';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';
import QuantityUnitType from 'acceligent-shared/enums/quantityUnit';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import Priority from 'acceligent-shared/enums/priority';
import DeliverableDataType from 'acceligent-shared/enums/deliverableDataType';

import { UpdatedByViewModel, UpdatedByAccountViewModel } from 'acceligent-shared/dtos/web/view/updatedBy';
import { SearchableModel } from 'acceligent-shared/dtos/web/view/searchableModel';
import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';
import WorkOrderUpsertRM from 'acceligent-shared/dtos/web/request/workOrder/upsert';

import { formatPhoneNumber } from 'acceligent-shared/utils/phone';
import { getShortAddress } from 'acceligent-shared/utils/address';
import * as TextUtil from 'acceligent-shared/utils/text';
import * as TimeUtils from 'acceligent-shared/utils/time';
import * as ScheduleBoardSharedUtils from 'acceligent-shared/utils/scheduleBoard';
import * as UserUtils from 'acceligent-shared/utils/user';
import * as TimeOptionUtils from 'acceligent-shared/utils/timeOption';
import * as BlobStorageUtil from 'acceligent-shared/utils/blobStorage';

import WorkOrder from 'acceligent-shared/models/workOrder';
import Address from 'acceligent-shared/models/address';
import WorkRequest from 'acceligent-shared/models/workRequest';
import Employee from 'acceligent-shared/models/employee';
import ContactMethod from 'acceligent-shared/models/contactMethod';
import ContactLookup from 'acceligent-shared/models/contactLookup';
import Attachment from 'acceligent-shared/models/attachment';
import Shift from 'acceligent-shared/models/shift';
import CrewType from 'acceligent-shared/models/crewType';
import WorkOrderEmployee from 'acceligent-shared/models/workOrderEmployee';
import WageRate from 'acceligent-shared/models/wageRate';
import WorkOrderEquipment from 'acceligent-shared/models/workOrderEquipment';
import Equipment from 'acceligent-shared/models/equipment';
import Skill from 'acceligent-shared/models/skill';
import EquipmentCost from 'acceligent-shared/models/equipmentCost';
import EquipmentCostCategory from 'acceligent-shared/models/equipmentCostCategory';
import Location from 'acceligent-shared/models/location';
import Account from 'acceligent-shared/models/account';
import WorkOrderPlaceholder from 'acceligent-shared/models/workOrderPlaceholder';
import Agency from 'acceligent-shared/models/agency';
import TemporaryEmployee from 'acceligent-shared/models/temporaryEmployee';
import WorkOrderResourceLookup from 'acceligent-shared/models/workOrderResourceLookup';
import WorkOrderTemporaryEmployee from 'acceligent-shared/models/workOrderTemporaryEmployee';
import BillingCode from 'acceligent-shared/models/billingCode';
import JobStatus from 'acceligent-shared/models/jobStatus';
import DeliverableData from 'acceligent-shared/models/deliverableData';

import { ScheduleBoardQueryMatchingFunction } from 'ab-common/dataStructures/scheduleBoardQuery';

import { TemplateNotificationLookupServiceModel } from 'ab-serviceModels/notification.serviceModel';

import ValidationState from 'ab-enums/validationState.enum';
import { getErrorMessage } from 'ab-enums/notifyType.enum';
import { stateAbbreviation } from 'ab-enums/states.enum';

import * as WorkOrderValidator from 'ab-validators/workOrder.validator';

import { isEmptyNumber } from 'ab-utils/validation.util';
import * as CodeUtils from 'ab-utils/codes.util';
import * as ContactUtils from 'ab-utils/contact.util';
import { addToArrayIfExists } from 'ab-utils/array.util';
import { workOrderCodeStripped } from 'ab-utils/codes.util';
import * as PhoneUtils from 'ab-utils/phone.util';
import * as BlobStorageUtilLocal from 'ab-utils/blobStorage.util'; // TODO: move everything to shared
import * as BlobFileUtil from 'ab-utils/blobFile.util';
import { generateGoogleMapLink } from 'ab-utils/location.util';
import * as ConverterUtil from 'ab-utils/converter.util';

import { TOOLBAR_GROUP_DEFAULT_ID } from 'ab-constants/scheduleBoard';
import { AVAILABLE_EQUIPMENT_STATUS } from 'ab-constants/equipment';
import { DEFAULT_WORK_ORDER_REVISION } from 'ab-constants/value';
import { DEFAULT_INTERNAL_CREW_TYPE_NAME, UNDEFINED_CREW_TYPE_NAME } from 'ab-constants/crewType';
import { IHandlebarsService } from 'ab-application/services/handlebars.service';

class BillingCodeVM {
	id: number;
	lineItemNumber: number;
	customerNumber: Nullable<number>;
	customerId: string;
	ownerNumber: Nullable<string>;
	ownerId: Nullable<string>;
	unit: QuantityUnitType;
	unitPrice: Nullable<string>;
	bidQuantity: Nullable<string>;
	group: Nullable<string>;
	description: string;

	constructor(billingCode: BillingCode) {
		this.id = billingCode.id;
		this.lineItemNumber = billingCode.lineItemNumber;
		this.customerNumber = billingCode.customerNumber;
		this.customerId = billingCode.customerId;
		this.ownerNumber = billingCode.ownerNumber;
		this.ownerId = billingCode.ownerId;
		this.unit = billingCode.unit;
		this.unitPrice = billingCode.unitPrice;
		this.bidQuantity = billingCode.bidQuantity;
		this.group = billingCode.group;
		this.description = billingCode.description;
	}

	private static _constructorMap = (billingCode: BillingCode) => new BillingCodeVM(billingCode);

	static bulkConstructor = (billingCodes: BillingCode[]) => billingCodes.map(BillingCodeVM._constructorMap);
}

class JobStatusVM {
	id: number;
	name: string;
	description: Nullable<string>;
	color: ColorPalette;

	constructor(jobStatus: JobStatus) {
		this.id = jobStatus.id;
		this.name = jobStatus.name;
		this.description = jobStatus.description;
		this.color = jobStatus.color;
	}
}

class DeliverableDataSingleViewModel {
	id: number;
	name: string;
	type: DeliverableDataType;

	constructor(status: DeliverableData) {
		this.id = status.id;
		this.name = status.name;
		this.type = status.type;
	}
}

class JobViewModel {
	id: number;
	/** @deprecated */
	code: Nullable<string>;
	jobCode: string;
	isInternal: boolean;
	customer: Nullable<RequestContactViewModel>;
	siteContact: Nullable<RequestContactViewModel>;
	customerCompany: Nullable<string>;
	customerFormatted: Nullable<string>;
	customerFullName: Nullable<string>;
	office: Nullable<string>;
	division: Nullable<string>;
	guaranteedCompletionDate: Nullable<string>;
	projectManager: Nullable<EmployeeVM>;
	supervisor: RequestContactViewModel;
	targetCompletionDate: Nullable<string>;
	guaranteedDaysFromStart: Nullable<number>;
	targetedDaysFromStart: Nullable<number>;
	title: Nullable<string>;
	workLocation: Nullable<AddressVM>;
	travelDistance: Nullable<string>;
	travelDuration: Nullable<number>;
	travelLocation: Nullable<string>;
	travelLocationShort: Nullable<string>;
	hseRequirementsNote: Nullable<string>;
	jobNote: Nullable<string>;
	scheduleNote: Nullable<string>;
	startDate: Nullable<string>;
	actualStartDate: Nullable<Date>;
	status: WorkRequestStatus;
	jobStatus: Nullable<JobStatusVM>;
	year: Nullable<number>;
	codeRaw: Nullable<number>;
	companyId: Nullable<number>;
	organizationId: Nullable<number>;
	isShortCircuited: Nullable<boolean>;
	deliverableAssigneeId: Nullable<number>;
	deliverableAssignee: Nullable<AccountVM>;
	deliverableSoftwareId: Nullable<number>;
	deliverableSoftware: Nullable<DeliverableDataSingleViewModel>;
	deliverableFileFormatId: Nullable<number>;
	deliverableFileFormat: Nullable<DeliverableDataSingleViewModel>;
	deliverableCodeStandardId: Nullable<number>;
	deliverableCodeStandard: Nullable<DeliverableDataSingleViewModel>;
	deliverableDeliveryMethodId: Nullable<number>;
	deliverableDeliveryMethod: Nullable<DeliverableDataSingleViewModel>;
	deliverableDeliveryTimelineId: Nullable<number>;
	deliverableDeliveryTimeline: Nullable<DeliverableDataSingleViewModel>;
	deliverableNotes: Nullable<string>;
	isDeliverable: boolean;
	allowCustomerSignature: boolean;
	estimateTotal: Nullable<number>;
	priority: Priority;
	billingCodes?: BillingCodeVM[];

	constructor(workRequest: WorkRequest, includeBillingCodes = false) {
		this.id = workRequest?.id;
		this.code = workRequest?.year && workRequest?.code ? CodeUtils.workRequestCode(workRequest.year, workRequest.code) : null;
		const jobCode = workRequest?.jobCode ? workRequest?.jobCode : this.code;
		if (!jobCode) {
			// Job code must be either of the things
			throw new Error('Job code not valid');
		}
		this.jobCode = jobCode;
		this.isInternal = workRequest?.isInternal ?? false;

		this.projectManager = workRequest.projectManager && new EmployeeVM(workRequest.projectManager);

		this.customer = workRequest.customerContactId && workRequest.customerContact ? new RequestContactViewModel(workRequest.customerContact) : null;
		this.customerCompany = workRequest.customerCompanyName ?? this.customer?.contact?.companyName ?? null;
		this.customerFullName = this.customer?.contact?.fullName ?? null;
		this.customerFormatted = this.customerFullName && `${this.customerFullName}${this.customerCompany ? `, ${this.customerCompany}` : ''}`;

		this.siteContact = workRequest.supervisorContactId && workRequest.supervisorContact ? new RequestContactViewModel(workRequest.supervisorContact) : null;

		this.office = workRequest?.office?.nickname ?? null;
		this.division = workRequest?.division?.name ?? null;
		this.guaranteedCompletionDate = workRequest?.guaranteedCompletionDate
			? TimeUtils.formatDate(workRequest?.guaranteedCompletionDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
			: null;

		this.targetCompletionDate = workRequest?.targetCompletionDate
			? TimeUtils.formatDate(workRequest?.targetCompletionDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
			: null;
		this.guaranteedDaysFromStart = workRequest.startDate && workRequest.guaranteedCompletionDate
			? TimeUtils.daysBetween(workRequest.startDate, workRequest.guaranteedCompletionDate)
			: null;
		this.targetedDaysFromStart = workRequest.startDate && workRequest.targetCompletionDate
			? TimeUtils.daysBetween(workRequest.startDate, workRequest.targetCompletionDate)
			: null;
		this.title = workRequest?.title;

		this.travelDistance = workRequest?.travelDistance !== null
			? ConverterUtil.metersToMiles(workRequest?.travelDistance).toFixed(2)
			: null;
		this.travelDuration = workRequest?.travelDuration;
		const travelLocation = workRequest?.travelLocation && new AddressVM(workRequest?.travelLocation);
		this.workLocation = travelLocation;
		this.travelLocation = travelLocation?.street ?? null;
		this.travelLocationShort = travelLocation?.shortAddress ?? null;

		this.hseRequirementsNote = workRequest?.requestHSERequirementsNote;
		this.jobNote = workRequest?.requestJobNote;
		this.scheduleNote = workRequest?.scheduleNote;
		this.startDate = workRequest?.startDate;

		// published work orders with completed field reports sorted by dueDate DESC
		const workOrders = workRequest?.workOrders || [];
		const dateToUseAsStartDate = workOrders[0]?.dueDate || workRequest?.startDate;
		this.actualStartDate = dateToUseAsStartDate
			? TimeUtils.toUtcDate(dateToUseAsStartDate, TimeFormat.DB_DATE_ONLY)
			: null;

		this.year = workRequest?.year;
		this.codeRaw = workRequest?.code;
		this.companyId = workRequest?.companyId;
		this.organizationId = workRequest?.company?.organizationId;

		this.status = workRequest?.status;
		this.jobStatus = workRequest?.jobStatus ? new JobStatusVM(workRequest.jobStatus) : null;
		this.isShortCircuited = workRequest?.status === WorkRequestStatus.SHORT_CIRCUITED;

		this.deliverableAssigneeId = workRequest.deliverableAssigneeId;
		this.deliverableAssignee = workRequest.deliverableAssignee && new AccountVM(workRequest.deliverableAssignee);
		this.deliverableSoftwareId = workRequest.deliverableSoftwareId;
		this.deliverableSoftware = workRequest.deliverableSoftware && new DeliverableDataSingleViewModel(workRequest.deliverableSoftware);
		this.deliverableFileFormatId = workRequest.deliverableFileFormatId;
		this.deliverableFileFormat = workRequest.deliverableFileFormat && new DeliverableDataSingleViewModel(workRequest.deliverableFileFormat);
		this.deliverableCodeStandardId = workRequest.deliverableCodeStandardId;
		this.deliverableCodeStandard = workRequest.deliverableCodeStandard && new DeliverableDataSingleViewModel(workRequest.deliverableCodeStandard);
		this.deliverableDeliveryMethodId = workRequest.deliverableDeliveryMethodId;
		this.deliverableDeliveryMethod = workRequest.deliverableDeliveryMethod && new DeliverableDataSingleViewModel(workRequest.deliverableDeliveryMethod);
		this.deliverableDeliveryTimelineId = workRequest.deliverableDeliveryTimelineId;
		this.deliverableDeliveryTimeline = workRequest.deliverableDeliveryTimeline &&
			new DeliverableDataSingleViewModel(workRequest.deliverableDeliveryTimeline);
		this.deliverableNotes = workRequest.deliverableNotes;

		this.isDeliverable = !!this.deliverableAssigneeId;

		this.allowCustomerSignature = workRequest.allowCustomerSignature;
		this.estimateTotal = workRequest.estimateTotal;
		this.priority = workRequest.priority;

		this.billingCodes = includeBillingCodes
			? (workRequest.billingCodes?.map((_billingCode) => new BillingCodeVM(_billingCode)) ?? undefined)
			: undefined;
	}

	static toWorkOrderJobRequestModel(vm: JobViewModel): WorkOrderUpsertRM['job'] {
		return {
			id: vm.id,
			jobCode: vm.jobCode,
			isInternal: vm.isInternal,
			customerCompany: vm.customerCompany,
			customerFormatted: vm.customerFormatted,
			customerFullName: vm.customerFullName,
			status: vm.status,
			travelLocationShort: vm.travelLocationShort,
			title: vm.title,
			office: vm.office,
			isDeliverable: vm.isDeliverable,
			deliverableSoftware: vm.deliverableSoftware?.name ?? null,
			deliverableFileFormat: vm.deliverableFileFormat?.name ?? null,
			deliverableCodeStandard: vm.deliverableCodeStandard?.name ?? null,
			projectManager: vm.projectManager,
		};
	}
}

class RequestContactViewModel {
	contact: ContactVM;
	contactId: number;
	contactAddressIds: number[];
	contactEmailIds: number[];
	contactPhoneIds: number[];
	companyName: Nullable<string>;

	constructor(contact: ContactLookup) {
		this.contact = new ContactVM(contact.contact);
		this.contactId = contact.contact?.id;
		this.contactAddressIds = contact.contactLookupAddresses?.map((_wrcAdr) => _wrcAdr.contactAddressId) ?? [];
		this.contactEmailIds = ContactUtils.filterContactMethod(contact.contactLookupMethods, EmailTypesArray);
		this.contactPhoneIds = ContactUtils.filterContactMethod(contact.contactLookupMethods, PhoneTypesArray);
		this.companyName = contact.contact?.companyName;
	}
}

class RequestLocationViewModel {
	/** Value in meters */
	travelDistance: Nullable<number>;
	/** Value in minutes */
	travelDuration: Nullable<number>;
	companyAddress: AddressVM;
	requestLocationNotes: Nullable<string>;
	reportTo?: RequestContactViewModel;

	constructor(workRequest: WorkRequest) {
		this.travelDistance = workRequest.travelDistance;
		this.travelDuration = workRequest.travelDuration;
		this.requestLocationNotes = workRequest.requestLocationNotes;

		if (workRequest.office?.address) {
			this.companyAddress = new AddressVM(workRequest.office.address);
		} else if (workRequest.company?.primaryAddress) {
			this.companyAddress = new AddressVM(workRequest.company.primaryAddress);
		}
	}
}

class WorkOrderConfirmationJobViewModel extends JobViewModel {
	locationContact: Nullable<RequestContactViewModel>;
	location: RequestLocationViewModel;
	projectOwner: Nullable<string>;
	generalContractor: Nullable<string>;

	constructor(workRequest: WorkRequest) {
		super(workRequest);
		this.locationContact = this.siteContact;
		this.location = new RequestLocationViewModel(workRequest);
		this.projectOwner = workRequest.projectOwner;
		this.generalContractor = workRequest.generalContractor;
	}
}

type ScheduleBoardWorkOrdersLocks = { [workOrderId: string]: true; };

export class WorkOrderTemporaryEmployeeVM {
	id: number;
	workOrderId: number;
	temporaryEmployeeId: number;
	temporaryEmployee: TemporaryEmployeeVM;

	constructor(employee: WorkOrderTemporaryEmployee) {
		if (!employee.temporaryEmployeeId || !employee.temporaryEmployee) {
			throw new Error('Can\'t construct WorkOrderTemporaryEmployeeVM from employee with no temporary employee');
		}
		this.id = employee.id;
		this.workOrderId = employee.workOrderId;
		this.temporaryEmployeeId = employee.temporaryEmployeeId;
		this.temporaryEmployee = new TemporaryEmployeeVM(employee.temporaryEmployee);
	}

	static bulkConstructor = (employees: WorkOrderTemporaryEmployee[]) => employees.map(WorkOrderTemporaryEmployeeVM._constructorMap);

	private static _constructorMap = (employee: WorkOrderTemporaryEmployee) => new WorkOrderTemporaryEmployeeVM(employee);
}

class EmployeeVM {
	id: number;
	formattedCode: string;
	wageRate: Nullable<WageRateVM>;
	firstName: string;
	lastName: string;
	accountId: number;
	locationColor: ColorPalette | undefined;
	hasDuplicateName: boolean;
	cdlStatus: Nullable<CDLStatus>;
	fullName: string;
	locationNickname: string | undefined;

	constructor(employee: Employee) {
		const user = employee?.account?.user;
		const location = employee?.account?.location;

		this.id = employee.id;
		this.wageRate = employee.wageRate ? new WageRateVM(employee.wageRate) : null;
		this.formattedCode = user.uniqueId;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.accountId = employee.accountId;
		this.locationColor = location?.color;
		this.cdlStatus = employee?.cdlStatus;
		this.locationNickname = employee.account.location?.nickname;
		this.fullName = UserUtils.getUserName(employee.account.user);
	}

	getDisplayName = () => {
		return TextUtil.capitalizeText(`${this.firstName[0].toUpperCase()}${this.hasDuplicateName ? this.firstName[1] : ''} ${this.lastName}`);
	};
}

class AgencyVM {
	id: number;
	name: string;
	color: ColorPalette;
	email: Nullable<string>;
	phone: Nullable<string>;
	website: Nullable<string>;
	isDeleted: boolean;

	constructor(agency: Agency) {
		this.id = agency.id;
		this.name = agency.name;
		this.color = agency.color;
		this.email = agency.email;
		this.phone = agency.phone;
		this.website = agency.website;
		this.isDeleted = !!agency.deletedAt;
	}
}

class TemporaryEmployeeVM {
	id: number;
	firstName: string;
	lastName: string;
	email: Nullable<string>;
	uniqueId: string;
	phoneNumber: Nullable<string>;
	agencyId: number;
	agency: AgencyVM;
	account: AccountVM;

	constructor(employee: TemporaryEmployee) {
		this.id = employee.id;
		this.firstName = employee.account.user.firstName;
		this.lastName = employee.account.user.lastName;
		this.email = employee.account.user.email;
		this.uniqueId = employee.uniqueId;
		this.phoneNumber = employee.account.user.phoneNumber;
		this.agencyId = employee.agencyId;
		this.agency = new AgencyVM(employee.agency);
		this.account = new AccountVM(employee.account);
	}
}

class WorkOrderResourceLookupViewModel {
	id: number;
	workOrderId: number;
	workOrderEmployeeId?: Nullable<number>;
	workOrderEquipmentId?: Nullable<number>;
	workOrderTemporaryEmployeeId?: Nullable<number>;
	workOrderPlaceholderId?: Nullable<number>;
	employeeId?: number;
	employee?: Nullable<EmployeeVM>;
	equipmentId?: number;
	equipment?: Nullable<EquipmentVM>;
	temporaryEmployeeId?: number;
	temporaryEmployee?: Nullable<TemporaryEmployeeVM>;
	wageRateId?: Nullable<number>;
	wageRate?: Nullable<WageRateVM>; // used in the placeholder
	equipmentCostId?: Nullable<number>;
	equipmentCost?: Nullable<EquipmentCostVM>; // used in the placeholder
	skills?: SkillVM[]; // placeholder skills
	index: number;
	perDiem?: boolean;
	perDiemAmount?: Nullable<string>;
	isAvailable: boolean;

	constructor(resourceLookup: WorkOrderResourceLookup) {
		this.id = resourceLookup?.id;
		this.workOrderId = resourceLookup?.workOrderId;
		this.workOrderEmployeeId = resourceLookup?.workOrderEmployeeId;
		this.workOrderEquipmentId = resourceLookup?.workOrderEquipmentId;
		this.workOrderPlaceholderId = resourceLookup?.workOrderPlaceholderId;
		this.workOrderTemporaryEmployeeId = resourceLookup?.workOrderTemporaryEmployeeId;
		this.employeeId = resourceLookup?.workOrderEmployee?.employeeId;
		this.employee = resourceLookup?.workOrderEmployee?.employee && new EmployeeVM(resourceLookup.workOrderEmployee.employee);
		this.equipmentId = resourceLookup?.workOrderEquipment?.equipmentId;
		this.equipment = resourceLookup?.workOrderEquipment?.equipment && new EquipmentVM(resourceLookup.workOrderEquipment.equipment);
		this.temporaryEmployeeId = resourceLookup?.workOrderTemporaryEmployee?.temporaryEmployeeId;
		this.temporaryEmployee = resourceLookup?.workOrderTemporaryEmployee?.temporaryEmployee &&
			new TemporaryEmployeeVM(resourceLookup.workOrderTemporaryEmployee.temporaryEmployee);
		this.wageRateId = resourceLookup?.workOrderPlaceholder?.wageRateId;
		this.wageRate = resourceLookup?.workOrderPlaceholder?.wageRate && new WageRateVM(resourceLookup.workOrderPlaceholder.wageRate);
		this.equipmentCostId = resourceLookup?.workOrderPlaceholder?.equipmentCostId;
		this.equipmentCost = resourceLookup?.workOrderPlaceholder?.equipmentCost &&
			new EquipmentCostVM(resourceLookup.workOrderPlaceholder.equipmentCost);
		this.skills = resourceLookup?.workOrderPlaceholder?.skills && SkillVM.bulkConstructor(resourceLookup.workOrderPlaceholder.skills);
		this.index = resourceLookup?.index;

		this.perDiem = resourceLookup?.workOrderEmployee?.perDiem;
		this.perDiemAmount = resourceLookup?.workOrderEmployee?.perDiemAmount ?? null;
	}

	static bulkConstructor(resourceLookups: WorkOrderResourceLookup[] = []): WorkOrderResourceLookupViewModel[] {
		return resourceLookups.map(WorkOrderResourceLookupViewModel._constructorMap);
	}

	private static _constructorMap = (_resourceLookup: WorkOrderResourceLookup) => new WorkOrderResourceLookupViewModel(_resourceLookup);
}

class WorkOrderPlaceholderViewModel {
	id: number;
	workOrderId: number;
	wageRateId: Nullable<number>;
	wageRate: Nullable<WageRateVM>;
	equipmentCostId: Nullable<number>;
	equipmentCost: Nullable<EquipmentCostVM>;

	constructor(workOrderPlaceholder: WorkOrderPlaceholder) {
		this.id = workOrderPlaceholder?.id;
		this.workOrderId = workOrderPlaceholder?.workOrderId;
		this.wageRateId = workOrderPlaceholder?.wageRateId;
		this.wageRate = workOrderPlaceholder?.wageRate && new WageRateVM(workOrderPlaceholder?.wageRate);
		this.equipmentCostId = workOrderPlaceholder?.equipmentCostId;
		this.equipmentCost = workOrderPlaceholder?.equipmentCost && new EquipmentCostVM(workOrderPlaceholder?.equipmentCost);
	}
}

function WorkOrderPlaceholderViewModels(workOrderPlaceholders: WorkOrderPlaceholder[] = []): WorkOrderPlaceholderViewModel[] {
	return workOrderPlaceholders.map((_placeholder) => new WorkOrderPlaceholderViewModel(_placeholder));
}

interface NotificationStatusViewModel {
	isPreviousRevision: Nullable<boolean>;
	// SMS information available if SMS sent
	smsErrorMessage?: Nullable<string>;
	smsStatus?: Nullable<NotificationStatusEnum>;
	smsSentAt?: Nullable<Date>;
	// Email information available if email sent
	emailErrorMessage?: Nullable<string>;
	emailStatus?: Nullable<NotificationStatusEnum>;
	emailSentAt?: Nullable<Date>;
}

interface NotificationStatusByEmployee {
	[id: number]: Nullable<NotificationStatusViewModel>;
}

class ScheduleBoardOfficeVM {
	id: number;
	nickname: string;
	color: ColorPalette;
	index: number;

	constructor(location: Location) {
		this.id = location.id;
		this.nickname = location.nickname;
		this.color = location.color;
		this.index = location.index;
	}
}

interface UserVM {
	id: number;
	fullName: string;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
}

class AccountVM {
	id: number;
	fullName: string;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	user: UserVM;

	constructor(account: Account) {
		this.id = account.id;
		this.fullName = UserUtils.getUserName(account.user);
		this.assignableToWorkOrder = account.assignableToWorkOrder;
		this.assignableAsSuperintendent = account.assignableAsSuperintendent;
		this.assignableAsProjectManager = account.assignableAsProjectManager;
		this.assignableAsQAQC = account.assignableAsQAQC;
		this.user = {
			id: account.user.id,
			fullName: UserUtils.getUserName(account.user),
			email: account.user.email,
			phoneNumber: account.user.phoneNumber ? formatPhoneNumber(account.user.phoneNumber) : null,
		};
	}
}

class ScheduleBoardEmployee implements SearchableModel {
	static matches: ScheduleBoardQueryMatchingFunction<ScheduleBoardEmployee> = TextUtil.matches;

	id: number;
	firstName: string;
	lastName: string;
	skills: SkillVM[];
	office: Nullable<ScheduleBoardOfficeVM>;
	isDisabled: boolean; // do not need to initialize them on the server side
	isFilteredOnBoard: boolean; // do not need to initialize them on the server side
	isFilteredOnToolbar: boolean; // do not need to initialize them on the server side
	isMatched?: boolean;
	currentLocation: string;
	isDeleted: boolean;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	account: AccountVM;
	emailInvalidatedAt: Nullable<Date>;
	phoneInvalidatedAt: Nullable<Date>;
	/** subscription status, name stripped in order to save lots of bytes on sch.board */
	subscriptionStatus: SubscriptionStatus;
	wageRate: string;
	showOnScheduleBoard: boolean;
	cdlStatus: CDLStatus;

	duplicateDisplayNameExists?: boolean;

	_desc: string;

	constructor(employee: Employee) {
		const _employeeUser = employee?.account?.user;

		this.id = employee.id;
		this.firstName = _employeeUser?.firstName;
		this.lastName = _employeeUser?.lastName;

		this.skills = employee.skills ? SkillVM.bulkConstructor(employee.skills) : [];
		this.office = employee?.account?.location && new ScheduleBoardOfficeVM(employee.account.location);
		this.wageRate = employee?.wageRate?.type;
		this.isDeleted = !!employee.isDeleted;

		this.email = _employeeUser?.email;
		this.phoneNumber = _employeeUser?.phoneNumber;
		this.account = employee?.account && new AccountVM(employee.account);
		this.emailInvalidatedAt = _employeeUser?.emailInvalidatedAt;
		this.phoneInvalidatedAt = _employeeUser?.phoneInvalidatedAt;
		this.subscriptionStatus = _employeeUser?.subscriptionStatus;
		// variations of fullName
		const searchableLiterals = [`${this.firstName} ${this.lastName}`];

		addToArrayIfExists(searchableLiterals, employee?.wageRate?.type);
		addToArrayIfExists(searchableLiterals, employee?.wageRate?.wageClassification);

		this._desc = searchableLiterals.join(' ');
		this.isMatched = false;
		this.showOnScheduleBoard = employee.showOnScheduleBoard;
		this.cdlStatus = employee.cdlStatus;
	}
}

type ScheduleBoardEmployeesViewModel = { [employeeId: number]: ScheduleBoardEmployee; };

class WorkOrderNotificationData {
	workOrderId: number;
	isUpdated: boolean;
	companyName: string;
	workOrderDayOfWeek: string;
	workOrderDate: string;
	workOrderLongDate: string;
	shift: string;
	jobCode: string;
	crewNumber: number;
	revision: string;
	workOrderRevision: number;
	cancellationReason: Nullable<string>;
	isCanceled: boolean;
	pauseReason: Nullable<string>;
	isPaused: boolean;
	isResumed: boolean;
	status: WorkOrderStatus;
	publicLink: Nullable<string>;

	customerCompanyName: Nullable<string>;
	jobTitle: string;

	locations?: {
		address: string;
		link: Nullable<string>;
		nonEscapedLink: Nullable<Handlebars.SafeString>;
	}[];

	siteContact?: {
		fullName: string;
		phoneNumbers: string[];
		emails: string[];
	};

	notes: Nullable<string>;

	projectManager: string;
	projectManagersPhoneNumber: Nullable<string>;
	superintendent: string;
	superintendentPhoneNumber: Nullable<string>;

	deliverableSoftware: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	deliverableFileFormat: Nullable<string>;

	resources: string[];
	temporaryResources: string[];

	constructor(workOrder: WorkOrder, employeesWithDuplicateName: number[], publicLink?: string) {
		if (!workOrder) {
			// NOTE: Defensive due to recurring issue: https://sentry.io/organizations/acceligent-llc/issues/2664025697/
			// TODO: check if work order should ever be null, this indicates that it hasn't been properly fetched from the database
			return;
		}

		const workRequest = workOrder.workRequest;
		const formattedDueDate = TimeUtils.formatDate(workOrder.dueDate);

		this.workOrderId = workOrder.id;
		this.isUpdated = workOrder.revision >= (DEFAULT_WORK_ORDER_REVISION + 2);
		this.companyName = workOrder?.company?.name;

		this.workOrderDayOfWeek = TimeUtils.getShortDayName(formattedDueDate);
		this.workOrderDate = TimeUtils.formatDate(workOrder.dueDate, TimeFormat.SHORT_DATE);
		this.workOrderLongDate = TimeUtils.formatDate(workOrder.dueDate, TimeFormat.DAY_WITH_DATE);
		this.shift = `${TimeOptionUtils.format(workOrder.timeToStart, TimeFormat.TIME_ONLY_12H_LOWERCASE)}-${TimeOptionUtils.format(workOrder.timeToEnd, TimeFormat.TIME_ONLY_12H_LOWERCASE)}`;

		this.jobCode = workRequest.jobCode ?? CodeUtils.workRequestCode(workRequest.year, workRequest.code);
		this.crewNumber = workOrder.code;
		this.revision = CodeUtils.revisionCode(workOrder.revision);
		this.workOrderRevision = workOrder.revision;
		this.cancellationReason = workOrder.cancellationReason;
		this.isCanceled = workOrder.status === WorkOrderStatus.CANCELED;
		this.pauseReason = workOrder.pauseReason;
		this.isPaused = workOrder.isPaused;
		this.status = workOrder.status;
		this.publicLink = publicLink ?? null;

		this.customerCompanyName = workRequest.customerCompanyName;

		if (!workRequest.title) {
			throw new Error('Job title not defined');
		}
		this.jobTitle = workRequest.title;

		const workRequestProxy = workOrder?.workRequest;

		this.deliverableCodeStandard = workRequestProxy?.deliverableCodeStandard?.name ?? null;
		this.deliverableFileFormat = workRequestProxy?.deliverableFileFormat?.name ?? null;
		this.deliverableSoftware = workRequestProxy?.deliverableSoftware?.name ?? null;

		this.locations = workOrder.addresses.map((_woa) => {
			const { address } = _woa;
			const mapLink = generateGoogleMapLink(address.latitude, address.longitude);
			const street = mapLink ? address.street : `${address.street}, ${address.locality}, ${address.aa1}, ${address.country}`;

			return {
				address: street || 'N/A',
				link: mapLink,
				nonEscapedLink: mapLink ? new handlebars.SafeString(mapLink) : null,
			};
		});

		if (!!workOrder.siteContact) {
			const siteContactFullName = workOrder.siteContact.contact.fullName;
			const siteContactPhones = workOrder.siteContact.contactLookupMethods
				.filter((_cm) => PhoneTypesArray.includes(_cm.contactMethod.type))
				.map((_cm) => _cm.contactMethod.value);
			const siteContactEmails = workOrder.siteContact.contactLookupMethods
				.filter((_cm) => EmailTypesArray.includes(_cm.contactMethod.type))
				.map((_cm) => _cm.contactMethod.value);

			this.siteContact = {
				fullName: siteContactFullName,
				phoneNumbers: siteContactPhones,
				emails: siteContactEmails,
			};
		}

		this.notes = workOrder.notes;

		const _projectManager = workOrder?.projectManager?.account?.user;
		const _projectManagerId = workOrder.projectManagerId;
		const isPMDuplicate = _projectManagerId && employeesWithDuplicateName.includes(_projectManagerId);
		this.projectManager = _projectManager ? `${_projectManager.firstName[0].toUpperCase()}${isPMDuplicate ? _projectManager.firstName[1] : ''} ${_projectManager.lastName}` : 'N/A';
		this.projectManagersPhoneNumber = _projectManager?.phoneNumber ?? null;

		const _supervisor = workOrder?.supervisor?.account?.user;
		const _supervisorId = workOrder.supervisorId;
		const isSIDuplicate = _supervisorId ? employeesWithDuplicateName.includes(_supervisorId) : false;
		this.superintendent = _supervisor ? `${_supervisor.firstName[0].toUpperCase()}${isSIDuplicate ? _supervisor.firstName[1] : ''} ${_supervisor.lastName}` : 'N/A';
		this.superintendentPhoneNumber = _supervisor?.phoneNumber ?? null;

		this.resources = workOrder.workOrderResourceLookups?.reduce<string[]>((_acc, _worl) => {
			if (_worl.workOrderEmployeeId && _worl.workOrderEmployee) {
				const { employeeId } = _worl.workOrderEmployee;
				const { firstName, lastName } = _worl.workOrderEmployee.employee.account.user;
				const isDuplicate = employeesWithDuplicateName.includes(employeeId);

				_acc.push(`${firstName[0].toUpperCase()}${isDuplicate ? firstName[1] : ''} ${lastName}`);
			} else if (_worl.workOrderEquipmentId && _worl.workOrderEquipment) {
				const { code } = _worl.workOrderEquipment.equipment;
				const availability = _worl.workOrderEquipment.equipment?.dailyEquipmentStatus?.[0]?.equipmentStatus?.available ?? true;
				_acc.push(`${code}${availability ? '' : '   DOWN'}`);
			}
			return _acc;
		}, []) ?? [];

		this.temporaryResources = workOrder.workOrderResourceLookups?.reduce<string[]>((_acc, _worl) => {
			if (_worl.workOrderTemporaryEmployeeId && _worl.workOrderTemporaryEmployee) {
				const { temporaryEmployeeId } = _worl.workOrderTemporaryEmployee;
				const { firstName, lastName } = _worl.workOrderTemporaryEmployee.temporaryEmployee.account.user;
				const isDuplicate = employeesWithDuplicateName.includes(temporaryEmployeeId);

				_acc.push(TextUtil.capitalizeText(`${firstName[0].toUpperCase()}${isDuplicate ? firstName[1] : ''} ${lastName}`));
			}
			return _acc;
		}, []) ?? [];
	}
}

interface WorkOrderEmployeeNotificationData {
	id?: number;
	email?: string;
	phone?: string;
	fullName: string | Handlebars.SafeString;
	redirectLink: string | Handlebars.SafeString;
	tipMessage: string;

	workOrderId: number;
	isUpdated: boolean;
	companyName: string;
	workOrderDayOfWeek: string;
	workOrderDate: string;
	workOrderLongDate: string;
	shift: string;
	jobCode: string;
	crewNumber: number;
	revision: string;
	cancellationReason: Nullable<string>;
	isCanceled: boolean;
	pauseReason: Nullable<string>;
	isPaused: boolean;
	status: WorkOrderStatus;
	publicLink: Nullable<string>;

	customerCompanyName: Nullable<string>;
	jobTitle: string;

	locations?: {
		address: string;
		link: Nullable<string>;
		nonEscapedLink: Nullable<Handlebars.SafeString>;
	}[];

	siteContact?: {
		fullName: string;
		phoneNumbers: string[];
		emails: string[];
	};

	notes: Nullable<string>;

	projectManager: string;
	projectManagersPhoneNumber: Nullable<string>;
	superintendent: string;
	superintendentPhoneNumber: Nullable<string>;

	deliverableSoftware: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	deliverableFileFormat: Nullable<string>;

	resources: string[];
	temporaryResources: string[];
}

class CrewTypeVM {
	id: Nullable<number>;
	name: string;
	color: Nullable<ExtendedColorValue>;

	constructor(crewType: Nullable<CrewType>) {
		this.name = crewType?.name ?? UNDEFINED_CREW_TYPE_NAME;
		this.color = crewType?.color ?? null;

		if (!crewType) {
			// UNDEFINED_CREW_TYPE
			this.id = null;
			return;
		}
		this.id = crewType.id;
	}

	/**
	 * Used for crew types of internal jobs
	 * @param crewTypeName usually `WorkOrder.customCrewType`
	 */
	static internal(crewTypeName: Nullable<string>): CrewTypeVM {
		return {
			name: crewTypeName ?? DEFAULT_INTERNAL_CREW_TYPE_NAME,
			color: DefaultColor.INTERNAL_JOB,
			id: null,
		};
	}
}

class SkillVM {
	id: number;
	name: string;
	color: ColorPalette;

	constructor(skill: Skill) {
		this.id = skill.id;
		this.name = skill.name;
		this.color = skill.color;
	}

	static bulkConstructor = (skills: Skill[]) => skills.map(SkillVM._constructorMap);
	private static _constructorMap = (_skill: Skill) => new SkillVM(_skill);
}

class EquipmentCostCategoryVM {
	name: string;
	categoryColor: Nullable<ColorPalette>;
	groupId: Nullable<number>;
	type: string; // level 1
	group: Nullable<EquipmentCostCategoryVM>; // level 2 and level 3
	equipmentCostsCount: number;

	constructor(ecc: EquipmentCostCategory) {
		this.categoryColor = ecc.categoryColor;
		this.groupId = ecc.groupId;
		this.group = ecc.group && new EquipmentCostCategoryVM(ecc.group);
		this.equipmentCostsCount = ecc.equipmentCosts?.length ?? 0;
		this.type = ecc.type;
		this.name = ecc.name;
	}
}

class EquipmentCostVM {
	id: number;
	type: Nullable<string>;
	categoryName: string;
	categoryId: number;
	categoryColor: Nullable<ColorPalette>;
	subcategory: string;
	groupName: Nullable<string>;
	category: EquipmentCostCategoryVM | undefined;
	group: Nullable<EquipmentCostCategoryVM>;
	image: Nullable<string>;
	licenses: Nullable<string>;
	skills: SkillVM[];

	constructor(equipmentCost: EquipmentCost) {
		const _equipmentCostCategory = equipmentCost?.category;
		this.id = equipmentCost?.id;
		this.group = _equipmentCostCategory?.group ? new EquipmentCostCategoryVM(_equipmentCostCategory.group) : null;
		this.category = _equipmentCostCategory && new EquipmentCostCategoryVM(equipmentCost.category);
		this.type = _equipmentCostCategory?.group?.type ?? null;
		this.groupName = _equipmentCostCategory?.group?.name ?? null;
		this.categoryName = _equipmentCostCategory?.name;
		this.categoryId = _equipmentCostCategory?.id;
		this.categoryColor = _equipmentCostCategory?.categoryColor ?? null;
		this.subcategory = equipmentCost?.subcategory;
		this.image = equipmentCost?.imageUrl;
		this.licenses = equipmentCost?.licenses;

		if (equipmentCost.skills?.length) {
			this.skills = equipmentCost.skills
				.map((_skill) => new SkillVM(_skill));
		} else if (equipmentCost.equipmentCostSkills?.length) {
			this.skills = equipmentCost.equipmentCostSkills
				.filter((_eqCostSkill) => !!_eqCostSkill.skill)
				.map((_eqCostSkill) => new SkillVM(_eqCostSkill.skill));
		} else {
			this.skills = [];
		}
	}

	static bulkConstructor(equipmentCosts: EquipmentCost[]): EquipmentCostVM[] {
		return equipmentCosts.map(EquipmentCostVM._constructorMap);
	}

	private static _constructorMap = (equipmentCost: EquipmentCost) => new EquipmentCostVM(equipmentCost);
}

class LocationVM {
	id: number;
	nickname: string;
	color: ColorPalette;
	index: number;
	showInStatistics: boolean;
	address: AddressVM | undefined;
	isDeleted: boolean;

	constructor(location: Location) {
		this.id = location.id;
		this.nickname = location.nickname;
		this.color = location.color;
		this.index = location.index;
		this.showInStatistics = location.showInStatistics;
		this.address = location.address && new AddressVM(location.address);
		this.isDeleted = location.status === ResourceStatus.DELETED ?? undefined;
	}
}

type EquipmentViewModelWorkOrder = { id: number; strippedCode: string; };

class EquipmentVM {
	id: number;
	workOrders: EquipmentViewModelWorkOrder[];
	skills: SkillVM[];
	/** combined from equipmentCost and equipment, without duplicates */
	allSkills: SkillVM[];
	code: string;
	specification: Nullable<string>;
	equipmentCost: Nullable<EquipmentCostVM>;
	primaryContact: Nullable<string>;
	secondaryContact: Nullable<string>;
	equipmentStatusId?: number;
	equipmentStatusName?: string;
	location: Nullable<LocationVM>;
	frontImageUrl?: string;
	backImageUrl?: string;
	licenses: Nullable<string>;
	showOnScheduleBoard: boolean;
	deleteFrontImage?: boolean;
	deleteBackImage?: boolean;
	isStatusAvailable?: boolean;

	constructor(equipment: Equipment, workOrders: WorkOrder[] = [], dueDate?: Date) {
		this.id = equipment.id;
		this.code = equipment.code;
		this.specification = equipment.specification;
		this.equipmentCost = equipment.equipmentCost ? new EquipmentCostVM(equipment.equipmentCost) : null;
		this.primaryContact = equipment.primaryContact;
		this.secondaryContact = equipment.secondaryContact;
		this.showOnScheduleBoard = equipment.showOnScheduleBoard;

		if (dueDate) {
			const dueDateParsed = TimeUtils.formatDate(dueDate, TimeFormat.DB_DATE_ONLY);
			const _dailyEquipmentStatus = ScheduleBoardSharedUtils.getEquipmentDailyStatusForDay(equipment, dueDateParsed);

			this.equipmentStatusId = _dailyEquipmentStatus?.equipmentStatus?.id ?? TOOLBAR_GROUP_DEFAULT_ID;
			this.equipmentStatusName = _dailyEquipmentStatus?.equipmentStatus?.name ?? AVAILABLE_EQUIPMENT_STATUS;
			this.isStatusAvailable = _dailyEquipmentStatus?.equipmentStatus?.available ?? true;
		}

		this.location = equipment.location ? new LocationVM(equipment.location) : null;

		this.frontImageUrl = equipment.frontImageUrl
			? BlobStorageUtil.tryGetStorageUrlForSize(equipment.frontImageUrl, BlobStorageImageSizeContainer.SIZE_200X200)
			: undefined;
		this.backImageUrl = equipment.backImageUrl
			? BlobStorageUtil.tryGetStorageUrlForSize(equipment.backImageUrl, BlobStorageImageSizeContainer.SIZE_200X200)
			: undefined;

		this.licenses = equipment.licenses;

		if (equipment.skills?.length) {
			this.skills = equipment.skills.map((_skill) => new SkillVM(_skill));
		} else if (equipment.equipmentSkills?.length) {
			this.skills = equipment.equipmentSkills
				.filter((_eqSkill) => !!_eqSkill.skill)
				.map((_eqSkill) => new SkillVM(_eqSkill.skill));
		} else {
			this.skills = [];
		}

		this.workOrders = workOrders.reduce((_acc, _wo) => {
			if (_wo.status !== WorkOrderStatus.CANCELED && EquipmentVM.isEquipmentInWorkOrder(equipment.id, _wo)) {
				_acc.push({
					id: _wo.id,
					strippedCode: workOrderCodeStripped(_wo, _wo.workRequest),
				});
			}
			return _acc;
		}, [] as EquipmentViewModelWorkOrder[]);

		const skillIds = this.skills.map((_skill) => _skill.id);
		this.allSkills = [...this.skills];
		if (this.equipmentCost?.skills) {
			this.allSkills.push(...this.equipmentCost.skills.filter((_skill) => !skillIds.includes(_skill.id)));
		}
	}

	static bulkConstructor = (equipments: Equipment[], workOrders?: WorkOrder[], dueDate?: Date) => equipments.map(
		(_equipment: Equipment) => new EquipmentVM(_equipment, workOrders, dueDate)
	);

	static isEquipmentInWorkOrder = (equipmentId: number, workOrder: WorkOrder) =>
		workOrder.workOrderEquipment?.some((_woe) => _woe.equipmentId === equipmentId);
}

class WorkOrderEquipmentVM {
	id: number;
	equipmentId: number;
	equipment: Nullable<EquipmentVM>;
	equipmentCostType: string;

	constructor(workOrderEquipment: WorkOrderEquipment) {
		this.id = workOrderEquipment.id;
		this.equipmentId = workOrderEquipment.equipmentId;
		this.equipment = workOrderEquipment.equipment ? new EquipmentVM(workOrderEquipment.equipment) : null;
	}

	static bulkConstructor = (employees: WorkOrderEquipment[]) => employees.map(WorkOrderEquipmentVM._constructorMap);
	private static _constructorMap = (employee: WorkOrderEquipment) => new WorkOrderEquipmentVM(employee);
}

class AddressVM {
	id: number;
	aa1: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	country: Nullable<string>;
	latitude: number;
	longitude: number;
	locality: Nullable<string>;
	postalCode: Nullable<string>;
	route: Nullable<string>;
	street: string;
	streetNumber: Nullable<string>;
	suite: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	shortAddress: string;

	city: Nullable<string>;
	state: Nullable<string>;
	stateAbbreviation: Nullable<string>;
	zip: Nullable<string>;

	constructor(address: Address) {
		this.id = address.id;
		this.aa1 = address.aa1;
		this.aa2 = address.aa2;
		this.aa3 = address.aa3;
		this.country = address.country;
		this.latitude = address.latitude;
		this.longitude = address.longitude;
		this.locality = address.locality;
		this.postalCode = address.postalCode;
		this.route = address.route;
		this.street = address.street;
		this.streetNumber = address.streetNumber;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode;
		this.suite = address.suite;
		this.shortAddress = getShortAddress(address);

		this.city = address.locality;
		this.state = address.aa1;
		this.stateAbbreviation = address.aa1 ? stateAbbreviation[address.aa1] : null;
		this.zip = address.postalCode;
	}

	static bulkConstructor = (addresses: Address[]) => addresses.map(AddressVM._constructorMap);
	private static _constructorMap = (_address: Address) => new AddressVM(_address);
}

class ShiftVM {
	id: number;
	name: string;

	constructor(shift: Shift) {
		this.id = shift.id;
		this.name = shift.name;
	}
}

class WageRateVM {
	id: number;
	type: string;
	wageClassification: string;
	hourlyRate: number;

	constructor(wageRate: WageRate) {
		this.id = wageRate.id;
		this.type = wageRate.type;
		this.wageClassification = wageRate.wageClassification;
		this.hourlyRate = +wageRate.hourlyRate;
	}
}

class FieldEmployeeVM {
	id: number;
	formattedCode: string;
	wageRate: WageRateVM;
	firstName: string;
	lastName: string;
	accountId: number;
	locationColor: ColorPalette | undefined;
	hasDuplicateName: boolean;
	cdlStatus: Nullable<CDLStatus>;
	fullName: string;
	locationNickname: string | undefined;

	constructor(employee: Employee) {
		this.id = employee.id;
		this.wageRate = new WageRateVM(employee.wageRate);
		this.formattedCode = employee.account.user.uniqueId;
		this.firstName = employee.account.user.firstName;
		this.lastName = employee.account.user.lastName;
		this.accountId = employee.accountId;
		this.locationColor = employee.account.location?.color;
		this.cdlStatus = employee?.cdlStatus;
		this.locationNickname = employee.account.location?.nickname;
		this.fullName = UserUtils.getUserName(employee.account.user);
	}

	getDisplayName = () => {
		return TextUtil.capitalizeText(`${this.firstName[0].toUpperCase()}${this.hasDuplicateName ? this.firstName[1] : ''} ${this.lastName}`);
	};
}

class WorkOrderEmployeeVM {
	id: number;
	workOrderId: number;
	wageRateType: string;
	employeeId: number;
	employee: Nullable<FieldEmployeeVM>;
	perDiem: boolean;

	constructor(workOrderEmployee: WorkOrderEmployee) {
		this.id = workOrderEmployee.id;
		this.workOrderId = workOrderEmployee.workOrderId;
		this.employeeId = workOrderEmployee.employeeId;
		this.employee = workOrderEmployee.employee ? new FieldEmployeeVM(workOrderEmployee.employee) : null;
		this.perDiem = workOrderEmployee.perDiem;
	}

	static bulkConstructor = (employees: WorkOrderEmployee[]) => employees.map(WorkOrderEmployeeVM._constructorMap);

	private static _constructorMap = (employee: WorkOrderEmployee) => new WorkOrderEmployeeVM(employee);
}

class OfficeEmployeeVM {
	id: number;
	formattedCode: string;
	firstName: string;
	lastName: string;
	fullName: string;
	accountId: number;

	constructor(employee: Employee) {
		this.id = employee.id;
		this.formattedCode = employee.account.user.uniqueId;
		this.firstName = employee.account.user.firstName;
		this.lastName = employee.account.user.lastName;
		this.fullName = UserUtils.getUserName(employee.account.user);
		this.accountId = employee.accountId;
	}
}

class AttachmentVM {
	id: number;
	size: number;
	name: string;
	type: string;
	status: ResourceStatus;
	storageName: string;
	uploadedBy: Nullable<string>;
	uploadedOn: Date;
	storageContainer: string;
	/** resized version */
	src: string;
	/** original version with presigned url */
	originalSrc: string;

	constructor(attachment: Attachment) {
		const uploadedByUser = attachment?.uploadedBy?.user;

		this.id = attachment.id;
		this.status = attachment.status;
		this.uploadedBy = uploadedByUser ? UserUtils.getUserName(uploadedByUser) : null;
		this.uploadedOn = attachment.createdAt;
		this.size = attachment.size;
		this.name = attachment.name;
		this.type = attachment.type;
		this.storageContainer = attachment.storageContainer;
		this.storageName = attachment.storageName;

		let directories = BlobStorageUtil.parseDirectoryPath(this.storageContainer);

		this.originalSrc = BlobStorageUtilLocal.generatePresignedGetUrl(directories, this.storageName);

		directories = BlobStorageUtil.replaceDirectorySize(directories, BlobStorageImageSizeContainer.SIZE_200X200);
		this.src = BlobStorageUtil.getStorageUrl(directories, this.storageName);
	}

	static bulkConstructor = (attachments: Attachment[]) => attachments.map(AttachmentVM._constructorMap);

	static isImageAttachment(type: string | FileType): boolean {
		return BlobFileUtil.isImageExt(type as FileType);
	}

	private static _constructorMap = (_attachment) => new AttachmentVM(_attachment);
}

export class WorkOrderViewModel {
	id: number;
	code: string;
	dailyCode: Nullable<number>;
	index: Nullable<number>;

	updatedAt: Date;
	updatedBy: UpdatedByViewModel;
	updatedById: Nullable<number>;

	/** MM-DD-YYYY */
	dueDate: string;
	isFirst: boolean;
	isLate: boolean;
	delayReason?: string;
	cancellationReason: Nullable<string>;
	status: WorkOrderStatus;
	locked: boolean;
	isPaused: boolean;

	job: JobViewModel;
	jobId: number;
	crewType: CrewTypeVM;
	crewTypeId: Nullable<number>;
	customCrewType: Nullable<string>;
	timeToStart: Nullable<number>;
	timeToEnd: Nullable<number>;
	notes: Nullable<string>;
	scopeOfWork: Nullable<string>;
	shift: ShiftVM;
	title: Nullable<string>;
	revision: string;

	projectManager: Nullable<OfficeEmployeeVM>;
	projectManagerId: Nullable<number>;
	supervisor: Nullable<OfficeEmployeeVM>;
	supervisorId: Nullable<number>;
	workLocationAddress: string;
	officeNickname: Nullable<string>;
	divisionName: Nullable<string>;
	customerName: Nullable<string>;

	addresses: AddressVM[];
	attachments: AttachmentVM[];
	workOrderPlaceholders: WorkOrderPlaceholderViewModel[];
	workOrderEmployees: WorkOrderEmployeeVM[];
	workOrderEquipments: WorkOrderEquipmentVM[];
	workOrderTemporaryEmployees: WorkOrderTemporaryEmployeeVM[];
	workOrderResourceLookups: WorkOrderResourceLookupViewModel[];

	notificationStatusByEmployee: NotificationStatusByEmployee;
	resourceLookup: { [workOrderEmployeeId: number]: number; };
	employees: ScheduleBoardEmployeesViewModel;

	position: WorkOrderPositionOption;
	validationState: ValidationState;

	revenue: Nullable<string>;
	manHourAverage: Nullable<string>;
	jobHours: Nullable<number>;
	shopHours: Nullable<number>;
	travelHours: Nullable<number>;
	workDescription: Nullable<string>;

	deliverableSoftware: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	deliverableFileFormat: Nullable<string>;

	notificationTemplateLength: number;

	excludeFromNotify: boolean;
	excludeTempLaborFromNotify: boolean;
	excludeFromDeliverables: boolean;

	constructor(
		handlebarsService: IHandlebarsService,
		workOrder: WorkOrder,
		locks: ScheduleBoardWorkOrdersLocks = {},
		isFirst: boolean = false,
		notificationStatus: TemplateNotificationLookupServiceModel[] = []
	) {
		this.id = workOrder.id;
		this.code = CodeUtils.workOrderCode(workOrder, workOrder.workRequest);
		this.dailyCode = workOrder.dailyCode;
		this.index = workOrder.index;

		this.updatedAt = workOrder.updatedAt;
		this.updatedBy = new UpdatedByAccountViewModel(workOrder.updatedBy);
		this.updatedById = workOrder.updatedById;

		this.dueDate = TimeUtils.formatDate(workOrder.dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);
		this.isFirst = isFirst;
		// TODO: AP-2095, uncomment this if delay modal is needed in future
		// this.isLate = workOrder.dueDate > workOrder.workRequest.startDate;

		this.cancellationReason = workOrder.cancellationReason;
		this.status = workOrder.status;
		this.locked = !!locks?.[workOrder.id] ?? false;
		this.isPaused = workOrder.isPaused;

		this.job = workOrder.workRequest && new JobViewModel(workOrder.workRequest);
		this.jobId = workOrder.workRequestId;
		this.crewType = workOrder.isInternal
			? CrewTypeVM.internal(workOrder.customCrewType!)
			: new CrewTypeVM(workOrder.crewType!);
		this.crewTypeId = workOrder.crewTypeId;
		this.customCrewType = workOrder.customCrewType;
		this.timeToStart = !isEmptyNumber(workOrder.timeToStart) ? workOrder.timeToStart : null;
		this.timeToEnd = !isEmptyNumber(workOrder.timeToEnd) ? workOrder.timeToEnd : null;
		this.notes = workOrder.notes;
		this.scopeOfWork = workOrder.scopeOfWork;
		this.shift = new ShiftVM(workOrder.shift);
		this.title = this.job.title;
		this.revision = CodeUtils.revisionCode(workOrder.revision);

		this.projectManager = workOrder.projectManager && new OfficeEmployeeVM(workOrder.projectManager);
		this.projectManagerId = workOrder.projectManagerId;
		this.supervisor = workOrder.supervisor && new OfficeEmployeeVM(workOrder.supervisor);
		this.supervisorId = workOrder.supervisorId;

		const _travelLocation = workOrder.workRequest.travelLocation ?? {} as Address;
		this.workLocationAddress = new AddressVM(_travelLocation).shortAddress;
		this.officeNickname = workOrder?.workRequest?.office?.nickname ?? null;
		this.divisionName = workOrder?.workRequest?.division?.name ?? null;
		this.customerName = workOrder.workRequest.customerCompanyName ?? workOrder.workRequest.customerContact?.contact?.companyName ?? null;

		this.addresses = workOrder.addresses ? AddressVM.bulkConstructor(workOrder.addresses.map((_woa) => _woa.address)) : [];
		this.workOrderEmployees = workOrder.workOrderEmployees && WorkOrderEmployeeVM.bulkConstructor(workOrder.workOrderEmployees);
		this.workOrderEquipments = workOrder.workOrderEquipment && WorkOrderEquipmentVM.bulkConstructor(workOrder.workOrderEquipment);
		this.workOrderPlaceholders = WorkOrderPlaceholderViewModels(workOrder.workOrderPlaceholders);
		this.workOrderTemporaryEmployees = workOrder.workOrderTemporaryEmployees
			&& WorkOrderTemporaryEmployeeVM.bulkConstructor(workOrder.workOrderTemporaryEmployees);
		this.resourceLookup = {};

		this.notificationStatusByEmployee = {};
		this.employees = workOrder.workOrderEmployees.reduce((_acc, _woe) => {
			_acc[_woe.employeeId] = new ScheduleBoardEmployee(_woe.employee);
			return _acc;
		}, {});

		const _workOrderValidation = WorkOrderValidator.isValidWorkOrder(this);
		this.validationState = _workOrderValidation.isValid ? ValidationState.COMPLETE : ValidationState.INVALID;
		this.position = WorkOrderPositionOption.DEFAULT;

		this.revenue = workOrder.revenue;
		this.manHourAverage = workOrder.manHourAverage;
		this.jobHours = workOrder.jobHours;
		this.shopHours = workOrder.shopHours;
		this.travelHours = workOrder.travelHours;
		this.workDescription = workOrder.workDescription;

		const notificationMap = notificationStatus.reduce((_acc: NotificationStatusByEmployee, _notification: TemplateNotificationLookupServiceModel) => {
			if (!_acc[_notification.workOrderEmployeeId]) {
				_acc[_notification.workOrderEmployeeId] = {} as NotificationStatusViewModel;
			}
			for (const _status of _notification.statuses) {
				if (_status.f1 === NotificationTypeEnum.EMAIL) {
					_acc[_notification.workOrderEmployeeId]!.emailStatus = _status.f3;
					_acc[_notification.workOrderEmployeeId]!.emailSentAt = _status.f2;
					_acc[_notification.workOrderEmployeeId]!.emailErrorMessage = getErrorMessage(_status.f6, _status.f5);
				} else {
					_acc[_notification.workOrderEmployeeId]!.smsStatus = _status.f3;
					_acc[_notification.workOrderEmployeeId]!.smsSentAt = _status.f2;
					_acc[_notification.workOrderEmployeeId]!.smsErrorMessage = getErrorMessage(_status.f6, _status.f5, true);
				}
				_acc[_notification.workOrderEmployeeId]!.isPreviousRevision = !!_status.f4;
			}
			return _acc;
		}, {} as NotificationStatusByEmployee);

		this.workOrderResourceLookups = WorkOrderResourceLookupViewModel.bulkConstructor(workOrder.workOrderResourceLookups).map((_resourceLookup) => {
			// identifer length is too big for sequielize :(
			if (_resourceLookup.workOrderEmployeeId) {
				_resourceLookup.employee = this.workOrderEmployees
					.find((_workOrderEmployee) => _workOrderEmployee.id === _resourceLookup.workOrderEmployeeId)
					?.employee ?? null;
				this.notificationStatusByEmployee[_resourceLookup.workOrderEmployeeId] = notificationMap[_resourceLookup.workOrderEmployeeId];
				this.resourceLookup[_resourceLookup.workOrderEmployeeId] = _resourceLookup.employee!.id;
			} else if (_resourceLookup.workOrderEquipmentId) {
				_resourceLookup.equipment = this.workOrderEquipments
					.find((_workOrderEquipment) => _workOrderEquipment.id === _resourceLookup.workOrderEquipmentId)
					?.equipment;
			} else if (_resourceLookup.wageRateId) { // but not work work order placeholder O.o
				_resourceLookup.wageRate = this.workOrderPlaceholders
					.find((_workOrderPlaceholder) => _workOrderPlaceholder.id === _resourceLookup.workOrderPlaceholderId)
					?.wageRate;
			} else if (_resourceLookup.workOrderTemporaryEmployeeId) {
				_resourceLookup.temporaryEmployee = this.workOrderTemporaryEmployees
					?.find((_workOrderTemporaryEmployee) => _workOrderTemporaryEmployee.id === _resourceLookup.workOrderTemporaryEmployeeId)
					?.temporaryEmployee;

				if (!_resourceLookup.temporaryEmployee?.id) {
					throw new Error('Temporary employee not provided');
				}

				this.resourceLookup[_resourceLookup.workOrderTemporaryEmployeeId] = _resourceLookup.temporaryEmployee.id;
			} else {
				_resourceLookup.equipmentCost = this.workOrderPlaceholders
					.find((_workOrderPlaceholder) => _workOrderPlaceholder.id === _resourceLookup.workOrderPlaceholderId)
					?.equipmentCost;
			}

			return _resourceLookup;
		});

		this.deliverableCodeStandard = workOrder.workRequest.deliverableCodeStandardId && workOrder.workRequest.deliverableCodeStandard?.name
			? workOrder.workRequest.deliverableCodeStandard.name
			: null;
		this.deliverableFileFormat = workOrder.workRequest.deliverableFileFormatId && workOrder.workRequest.deliverableFileFormat?.name
			? workOrder.workRequest.deliverableFileFormat.name
			: null;
		this.deliverableSoftware = workOrder.workRequest.deliverableSoftwareId && workOrder.workRequest.deliverableSoftware?.name
			? workOrder.workRequest.deliverableSoftware.name
			: null;

		const data = new WorkOrderNotificationData(workOrder, []);
		const params: WorkOrderEmployeeNotificationData = {
			...data,
			fullName: '<EMPLOYEE-FIRSTNAME> <EMPLOYEE-LASTNAME>',
			redirectLink: '<PERSONAL-LINK>',
			tipMessage: '<DAILY-TIP>',
			workOrderDate: '<DUEDATE-TIME>',
		};

		const getTemplateName = (_isCanceled: boolean, _isPaused: boolean, _isResumed: boolean) => {
			if (_isCanceled) return 'cancelWorkOrderParticipation';
			else if (_isPaused) return 'workOrderPaused';
			else if (_isResumed) return 'workOrderResumed';
			return 'participantWorkOrderConfirmation';
		};
		const templateName = getTemplateName(data.isCanceled, data.isPaused, data.isResumed);

		const notification = handlebarsService.generateMessageFromTemplate(templateName, false, params);
		this.notificationTemplateLength = notification.length;

		this.excludeFromNotify = workOrder.excludeFromNotify;
		this.excludeTempLaborFromNotify = workOrder.excludeTempLaborFromNotify;
		this.excludeFromDeliverables = workOrder.excludeFromDeliverables;
	}
}

export function WorkOrdersViewModel(
	handlebarsService: IHandlebarsService,
	orders: WorkOrder[] = [],
	locks: ScheduleBoardWorkOrdersLocks = {},
	notificationStatus: TemplateNotificationLookupServiceModel[] = []
) {
	return orders.map((_order) => new WorkOrderViewModel(handlebarsService, _order, locks, false, notificationStatus));
}

interface ContactMethodVM {
	value: Nullable<string>;
	type: string;
}

class WorkOrderSiteContactViewModel {
	title: string;
	fullNameAndRole: string;
	companyName: Nullable<string>;
	street: string;
	place: string;
	email: Nullable<string>;
	phonesFirstRow: ContactMethodVM[];
	showSecondRow: boolean;
	phonesSecondRow: ContactMethodVM[];

	constructor(siteContact: ContactLookup) {
		const _contact = siteContact?.contact;
		const siteContactName = _contact?.fullName;
		const companyName = _contact?.companyName;
		const siteContactRole = _contact?.title ?? 'N/A';

		const { emails, phoneNumbers } = ContactUtils.getActiveContactMethods<ContactMethodVM, ContactMethodVM>(
			siteContact,
			(cm: ContactMethod) => ({
				value: PhoneUtils.formatPhoneNumber(cm.value, cm.countryCode),
				type: PhoneTypeNames[cm.type],
			}),
			(cm: ContactMethod) => ({ value: cm.value, type: cm.type })
		);
		let street = '';
		if (siteContact?.contactLookupAddresses?.length) {
			const contactAddressId = siteContact.contactLookupAddresses[0].contactAddressId;
			const addresses = _contact?.addresses ?? [];
			const selectedAddress = addresses.find(({ id }) => id === contactAddressId);
			street = selectedAddress?.address?.street ?? '';
		}

		const place = street.split(',').slice(1).join(',');
		const phonesFirstRow = phoneNumbers.slice(0, 2);
		const phonesSecondRow = phoneNumbers.slice(2);

		this.fullNameAndRole = `${siteContactName}, ${siteContactRole}`;
		this.companyName = companyName;
		this.street = street;
		this.place = place;
		this.email = emails.length ? emails[0].value : null;
		this.phonesFirstRow = phonesFirstRow;
		this.showSecondRow = !!phonesSecondRow?.length;
		this.phonesSecondRow = phonesSecondRow;
	}
}

export class WorkOrderConfirmationPdfViewModel {
	cancellationReason: Nullable<string>;
	delayReason?: string;
	code: string;
	dueDate: Date;
	job: WorkOrderConfirmationJobViewModel;
	crewType: CrewTypeVM;
	workStart: string;
	workEnd: string;
	shift: string;

	revision: string;
	crewNumber: number;

	notes: Nullable<string>;
	scopeOfWork: Nullable<string>;
	projectManager: Nullable<OfficeEmployeeVM>;
	supervisor: Nullable<OfficeEmployeeVM>;
	siteContact: Nullable<WorkOrderSiteContactViewModel>;
	updatedAt: Date;
	updatedBy: string;

	addresses: AddressVM[];
	workOrderEquipment: WorkOrderEquipmentVM[];
	workOrderEmployees: WorkOrderEmployeeVM[];
	workOrderTemporaryEmployees: WorkOrderTemporaryEmployeeVM[];
	workOrderResourceLookups: WorkOrderResourceLookupViewModel[];

	deliverableSoftware: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	deliverableFileFormat: Nullable<string>;

	constructor(workOrder: WorkOrder, employees?: Employee[]) {
		const updatedBy = new UpdatedByAccountViewModel(workOrder.updatedBy);
		const workRequest: WorkRequest = workOrder?.workRequest ?? {} as WorkRequest;

		this.cancellationReason = workOrder.cancellationReason;
		if (!workOrder.workRequest.jobCode) {
			throw new Error('Job Code not provided');
		}
		this.code = workOrder.workRequest.jobCode;

		const _dueDate = TimeUtils.toUtcDate(workOrder.dueDate);
		if (!_dueDate) {
			throw new Error('Due date not provided');
		}
		this.dueDate = _dueDate;
		this.job = workRequest && new WorkOrderConfirmationJobViewModel(workRequest);
		if (workOrder.isInternal) {
			this.crewType = CrewTypeVM.internal(workOrder.customCrewType);
		} else {
			this.crewType = new CrewTypeVM(workOrder.crewType);
		}
		this.workStart = TimeOptionUtils.format(workOrder.timeToStart);
		this.workEnd = TimeOptionUtils.format(workOrder.timeToEnd);
		this.shift = workOrder.shift?.name;

		this.revision = CodeUtils.revisionCode(workOrder.revision);
		this.crewNumber = workOrder.code;

		this.notes = workOrder.notes;
		this.scopeOfWork = workOrder.scopeOfWork;
		this.projectManager = workOrder.projectManager && new OfficeEmployeeVM(workOrder.projectManager);
		this.supervisor = workOrder.supervisor && new OfficeEmployeeVM(workOrder.supervisor);
		this.updatedAt = workOrder.updatedAt;
		this.updatedBy = updatedBy.fullName;
		this.siteContact = workOrder.siteContact && new WorkOrderSiteContactViewModel(workOrder.siteContact);

		this.addresses = workOrder.addresses ? AddressVM.bulkConstructor(workOrder.addresses.map((woa) => woa.address)) : [];
		this.workOrderEmployees = workOrder.workOrderEmployees && WorkOrderEmployeeVM.bulkConstructor(workOrder.workOrderEmployees);
		this.workOrderEquipment = workOrder.workOrderEquipment && WorkOrderEquipmentVM.bulkConstructor(workOrder.workOrderEquipment);
		this.workOrderTemporaryEmployees = workOrder.workOrderTemporaryEmployees
			&& WorkOrderTemporaryEmployeeVM.bulkConstructor(workOrder.workOrderTemporaryEmployees);

		const equipmentAvailabilityDict = workOrder.workOrderEquipment.reduce((_acc, _woe) => {
			const dailyEquipmentStatus = ScheduleBoardSharedUtils.getEquipmentDailyStatusForDay(_woe.equipment, workOrder.dueDate);
			_acc[_woe.equipmentId] = dailyEquipmentStatus?.equipmentStatus?.available ?? true;
			return _acc;
		}, {});

		type EmployeeDisplayNamesDict = { [woeId: number]: OfficeEmployeeVM; };
		const employeeDisplayNamesDict = (employees ?? []).reduce<EmployeeDisplayNamesDict>((_acc, _emp) => {
			const _displayName = TextUtil.capitalizeText(`${_emp.account.user.firstName[0]} ${_emp.account.user.lastName}`);
			if (!_acc[_displayName]) {
				_acc[_displayName] = [];
			}
			_acc[_displayName].push(_emp.id);
			return _acc;
		}, {});

		this.workOrderTemporaryEmployees.map((_tempEmp) => {
			const _fullName = _tempEmp.temporaryEmployee.account.user.fullName;
			const _displayName = `${_fullName.split(' ')[0][0]} ${_fullName.split(' ').slice(1).join(' ')}`;
			if (!employeeDisplayNamesDict[_displayName]) {
				employeeDisplayNamesDict[_displayName] = [];
			}
			employeeDisplayNamesDict[_displayName].push(_tempEmp.id);
		});

		const employeesByWorkOrderEmployeeId = this.workOrderEmployees.reduce((_acc, _woe) => {
			const _employee = _woe.employee;
			if (!_employee) {
				throw new Error('Employee not defined');
			}

			const _displayName = TextUtil.capitalizeText(`${_employee.firstName[0]} ${_employee.lastName}`);
			if (employeeDisplayNamesDict[_displayName] && employeeDisplayNamesDict[_displayName].length > 1) {
				_employee.hasDuplicateName = true;
			}
			_acc[_woe.id] = _employee;
			return _acc;
		}, {});
		const equipmentByWorkOrderEquipmentId = this.workOrderEquipment.reduce((_acc, _woe) => {
			if (!_woe.equipment) {
				throw new Error('Equipment not defined');
			}
			_acc[_woe.id] = _woe.equipment;
			return _acc;
		}, {} as { [woeId: number]: EquipmentVM; });
		this.workOrderResourceLookups = WorkOrderResourceLookupViewModel.bulkConstructor(workOrder.workOrderResourceLookups).map((_resourceLookup) => {
			// identifer length is too big for sequielize :(
			if (_resourceLookup.workOrderEmployeeId) {
				_resourceLookup.employee = employeesByWorkOrderEmployeeId[_resourceLookup.workOrderEmployeeId];
				_resourceLookup.isAvailable = false; // TODO: not used yet but we'll add similar feature for employees in the future
			} else if (_resourceLookup.workOrderEquipmentId) {
				_resourceLookup.equipment = equipmentByWorkOrderEquipmentId[_resourceLookup.workOrderEquipmentId];
				_resourceLookup.isAvailable = equipmentAvailabilityDict[_resourceLookup.equipment?.id];
			}
			return _resourceLookup;
		});

		this.deliverableCodeStandard = workOrder.workRequest.deliverableCodeStandardId && workOrder.workRequest.deliverableCodeStandard?.name
			? workOrder.workRequest.deliverableCodeStandard.name
			: null;
		this.deliverableFileFormat = workOrder.workRequest.deliverableFileFormatId && workOrder.workRequest.deliverableFileFormat?.name
			? workOrder.workRequest.deliverableFileFormat.name
			: null;
		this.deliverableSoftware = workOrder.workRequest.deliverableSoftwareId && workOrder.workRequest.deliverableSoftware?.name
			? workOrder.workRequest.deliverableSoftware.name
			: null;
	}

	static getConfirmationFileName = (workOrderCode: string) => `${workOrderCode}_confirmation`;
}
