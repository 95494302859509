import * as React from 'react';

import { PCFRBlockVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import * as FieldReportUtils from 'ab-utils/fieldReport.util';

import PrintoutSegmentHeader from 'af-components/PrintoutSegmentHeader';
import FieldReportBlock from './FieldReportBlock';

interface OwnProps {
	fieldReportBlock: PCFRBlockVM;
	instanceCount: number;
	segmentCount: number;
}

type Props = OwnProps;

const FieldReportBlocks: React.FC<Props> = (props: Props) => {
	const { fieldReportBlock, instanceCount, segmentCount } = props;
	const { reportBlock: { name, isMain }, segmentIndex, instanceIndex, index, completed } = fieldReportBlock;

	const displayIndex = React.useMemo(() => ((!!index && !!segmentIndex && !!instanceIndex)
		? FieldReportUtils.resolveBlockDisplayIndex(instanceIndex, segmentIndex, index, instanceCount, segmentCount)
		: '')
	, [index, instanceCount, instanceIndex, segmentCount, segmentIndex]);

	const style = React.useMemo(() => (segmentIndex === 0 ? 'primary' : 'secondary'), [segmentIndex]);

	const title = React.useMemo(() => (`${name} ${displayIndex}`), [displayIndex, name]);

	const type = React.useMemo(() => (isMain ? 'segment' : 'block'), [isMain]);

	return (
		<>
			<PrintoutSegmentHeader
				completionStatus={completed}
				style={style}
				title={title}
				type={type}
			/>
			<div className="public-report__segment" key={`block-${instanceIndex}-${segmentIndex}-${index}`}>
				<FieldReportBlock fieldReportBlock={fieldReportBlock} />
			</div>
		</>
	);
};

export default React.memo(FieldReportBlocks);
