import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { formValueSelector } from 'redux-form';

import AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import WorkOrderPositionOption from 'acceligent-shared/enums/workOrderPosition';

import { EmployeeOptionVM } from 'ab-viewModels/employee/extendedOption.viewModel';
import EquipmentViewModel from 'ab-viewModels/equipment.viewModel';
import TemporaryEmployeeOptionVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';

import AddressRM from 'acceligent-shared/dtos/web/request/address/upsert';

import AddressRequestModel from 'ab-requestModels/address.requestModel';

import * as WorkOrderActions from 'af-actions/workOrder';

import { RootState } from 'af-reducers';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import ResourceAssignConfirmationModal from 'af-components/SharedForms/ResourceAssignModal';

import WorkOrderFM from '../formModel';
import Section from '../Shared/Section';

import General from './General';
import ScheduleBoard from './ScheduleBoard';
import WorkLocation from './WorkLocation';
import Customer from './Customer';
import SiteContact from './SiteContact';
import Notes from './Notes';
import Resources from './ResourceLookups';
import ProductionData from './ProductionData';
import Notifications from './Notifications';
import DeliverableData from './DeliverableData';
import DirectoriesAndAttachments from './Attachments';

interface OwnProps {
	employees: EmployeeOptionVM[];
	equipment: EquipmentViewModel[];
	temporaryEmployees: TemporaryEmployeeOptionVM[];
	initialIndex: Nullable<number>;
	loadEmployees: (isLazyLoaded: boolean) => void;
	loadEquipment: (isLazyLoaded: boolean) => void;
	loadTemporaryEmployees: (isLazyLoaded: boolean) => void;
	resetTimer: () => void;
	change: <T extends keyof WorkOrderFM>(fieldName: T, value: WorkOrderFM[T]) => void;
	selector: (fieldName: string) => AddressRM;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	resourceAssignModalType: Nullable<AssignableResourceType>;
}

class WorkOrderForm extends React.PureComponent<Props, State> {

	state: State = {
		resourceAssignModalType: null,
	};

	openResourceAssignConfirmationModal = (resourceType: AssignableResourceType) => {
		this.setState(() => ({ resourceAssignModalType: resourceType }));
	};

	closeResourceAssignConfirmationModal = () => {
		this.setState(() => ({ resourceAssignModalType: null }));
	};

	render() {
		const {
			resetTimer,
			disabled,
			hasJob,
			isAllowedToEditProdData,
			isAllowedToViewProdData,
			isAllowedToViewContacts,
			employees,
			equipment,
			temporaryEmployees,
			isDeliverable,
			loadEmployees,
			loadEquipment,
			loadTemporaryEmployees,
			addresses,
			orderPosition,
			dueDate,
			isInternal,
			notificationsEnabled,
			loadBlankPositions,
			initialIndex,
			change,
			selector,
			workOrderId,
		} = this.props;
		const { resourceAssignModalType } = this.state;

		if (!hasJob) {
			return null;
		}

		return (
			<div className="form-box">
				<General
					change={change}
					disabled={disabled}
					resetTimer={resetTimer}
				/>
				<ScheduleBoard
					change={change}
					disabled={disabled}
					dueDate={dueDate}
					initialIndex={initialIndex}
					loadBlankPositions={loadBlankPositions}
					orderPosition={orderPosition}
					resetTimer={resetTimer}
				/>
				{notificationsEnabled && <Notifications disabled={disabled} />}
				<WorkLocation
					addresses={addresses}
					change={change}
					disabled={disabled}
					resetTimer={resetTimer}
					selector={selector}
				/>
				<DeliverableData
					disabled={disabled}
					isDeliverableJob={isDeliverable}
				/>
				<Customer />
				{isAllowedToViewContacts && <SiteContact disabled={disabled} resetTimer={resetTimer} />}
				<Notes disabled={disabled} resetTimer={resetTimer} />
				<Resources
					change={change}
					disabled={disabled}
					employees={employees}
					equipment={equipment}
					loadEmployees={loadEmployees}
					loadEquipment={loadEquipment}
					loadTemporaryEmployees={loadTemporaryEmployees}
					resetTimer={resetTimer}
					showResourceAssignConfirmationModal={this.openResourceAssignConfirmationModal}
					temporaryEmployees={temporaryEmployees}
				/>
				{!!workOrderId && <Section label="Attachments"><DirectoriesAndAttachments change={change} /></Section>}
				{
					(isAllowedToViewProdData && !isInternal) &&
					<ProductionData
						disabled={disabled || !isAllowedToEditProdData}
						resetTimer={resetTimer}
					/>
				}
				<ResourceAssignConfirmationModal
					onClose={this.closeResourceAssignConfirmationModal}
					onSubmit={this.closeResourceAssignConfirmationModal}
					showCancel={false}
					showModal={!!resourceAssignModalType}
					type={resourceAssignModalType}
				/>
			</div >
		);
	}
}

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const { user: { userData, companyData }, company: { company }, workOrder: { order } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const {
		status,
		job,
		dueDate,
		addresses,
		position,
		isInternal,
		id: workOrderId,
	} = selector(state, 'status', 'job', 'dueDate', 'addresses', 'position', 'isInternal', 'id');

	const isAllowedToEditProdData = isAllowed(
		PagePermissions.COMPANY.PROD_DATA.EDIT,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);
	const isAllowedToViewProdData = isAllowed(
		PagePermissions.COMPANY.PROD_DATA.VIEW,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const isAllowedToViewContacts = isAllowed(
		PagePermissions.COMPANY.CONTACTS,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		hasJob: !!job?.id,
		disabled: status === WorkOrderStatus.CANCELED || status === WorkOrderStatus.LOCKED || !job?.id || !dueDate || (order?.isPaused ?? false),
		isAllowedToViewProdData,
		isAllowedToEditProdData,
		isAllowedToViewContacts,
		isDeliverable: !!job?.isDeliverable,
		addresses: addresses as AddressRequestModel[],
		orderPosition: position as WorkOrderPositionOption,
		dueDate: dueDate as string,
		isInternal: !!isInternal,
		notificationsEnabled: !!company?.notification?.isEnabled ?? false,
		workOrderId,
	};
}

function mapDispatchToProps() {
	return {
		loadBlankPositions: WorkOrderActions.findBlankWorkOrdersByDueDate,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(WorkOrderForm);
