import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { Field, change } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { MarkerProps, Autocomplete } from '@react-google-maps/api';

import Input from 'af-fields/Input';

import * as AddressesActions from 'af-actions/addresses';

import { DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER } from 'af-constants/values';

import GoogleScriptLoader from '../Shared/Loader';

import MapRow from './MapRow';

interface OwnProps {
	marker?: MarkerProps;
	autosave?: () => Promise<void>;
	onValueUpdate?: () => void;
	formName: string;
	latitudePropName: string;
	longitudePropName: string;
	locationPropName: string;
	streetNumberPropName: string;
	routePropName: string;
	localityPropName: string;
	aa1PropName: string;
	aa2PropName: string;
	aa3PropName: string;
	countryPropName: string;
	postalCodePropName: string;
	suitePropName: string;
	postalOfficeBoxPropName: string;
	disableMap?: boolean;
	disabled?: boolean;
	allowUnverifiedAddress?: boolean;
	hideHeaders?: boolean;
}

interface DispatchProps {
	changeField: typeof change;
	getAddressByLatLng: typeof AddressesActions.getAddressByLatLng;
}

type Props = OwnProps & ResolveThunks<DispatchProps>;

interface State {
	mapVisible: boolean;
	marker: Nullable<MarkerProps>;
	center?: google.maps.LatLng | google.maps.LatLngLiteral;
	zoom: number;
}

const defaults = {
	center: { lat: 38.7099085, lng: -95.137812 },
	zoom: 3,
};

class AddressMap extends React.Component<Props, State> {
	static readonly autocompleteOptions = { componentRestrictions: { country: ['us', 'ca'] }, strictBounds: true };

	static defaultProps: Partial<Props> = {
		hideHeaders: false,
	};

	state: State = {
		mapVisible: false,
		marker: null,
		center: defaults.center,
		zoom: defaults.zoom,
	};

	private _searchBox: google.maps.places.Autocomplete;

	componentDidMount() {
		const { marker } = this.props;
		if (!!marker?.position) {
			this.setState(() => ({
				marker,
				center: marker.position,
				zoom: DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER,
			}));
		}
	}

	updateFields = (lat, lng, address) => {
		const { onValueUpdate, autosave, changeField, formName, ...rest } = this.props;
		changeField(formName, rest.latitudePropName, lat);
		changeField(formName, rest.longitudePropName, lng);
		changeField(formName, rest.locationPropName, address.formattedAddress || '');
		changeField(formName, rest.streetNumberPropName, address.streetNumber || '');
		changeField(formName, rest.routePropName, address.route || '');
		changeField(formName, rest.localityPropName, address.locality || '');
		changeField(formName, rest.aa1PropName, address.aa1 || '');
		changeField(formName, rest.aa2PropName, address.aa2 || '');
		changeField(formName, rest.aa3PropName, address.aa3 || '');
		changeField(formName, rest.countryPropName, address.country || '');
		changeField(formName, rest.postalCodePropName, address.postalCode || '');
		if (onValueUpdate) {
			onValueUpdate();
		}
		if (autosave) {
			autosave();
		}
	};

	changeMapVisibility = () => this.setState({ mapVisible: !this.state.mapVisible });

	onMapClick = async ({ latLng }: google.maps.MapMouseEvent) => {
		const { getAddressByLatLng } = this.props;

		if (!latLng) {
			throw new Error('Coordinates not found');
		}

		const marker: MarkerProps = {
			position: latLng,
			animation: 2,
		};

		this.setState(() => ({
			marker,
			center: marker.position,
			zoom: DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER,
		}));

		const address = latLng ? await getAddressByLatLng(latLng.lat(), latLng.lng()) : null;
		this.updateFields(latLng?.lat(), latLng?.lng(), address);
	};

	onPlacesChanged = () => {
		const place = this._searchBox.getPlace();
		if (!place?.geometry?.location) {
			return;
		}

		const marker: MarkerProps = {
			position: place.geometry.location,
			animation: 2,
		};

		this.setState({
			marker,
			center: marker.position,
			zoom: DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER,
		});

		const address = AddressesActions.formatAddress(place);
		this.updateFields((marker.position.lat as () => number)(), (marker.position.lng as () => number)(), address);
	};

	onLocationChange = () => {
		const { changeField, formName, allowUnverifiedAddress, onValueUpdate, ...rest } = this.props;
		if (!allowUnverifiedAddress) {
			changeField(formName, rest.latitudePropName, null);
			changeField(formName, rest.longitudePropName, null);
			changeField(formName, rest.streetNumberPropName, null);
			changeField(formName, rest.routePropName, null);
			changeField(formName, rest.localityPropName, null);
			changeField(formName, rest.aa1PropName, null);
			changeField(formName, rest.aa2PropName, null);
			changeField(formName, rest.aa3PropName, null);
			changeField(formName, rest.countryPropName, null);
			changeField(formName, rest.postalCodePropName, null);
		}
		if (onValueUpdate) {
			onValueUpdate();
		}
	};

	handleStreetFieldMount = (field: google.maps.places.Autocomplete) => {
		if (!field) {
			return;
		}
		this._searchBox = field;
	};

	handleFieldMount = (field) => {
		if (!field) {
			return;
		}
	};

	showMapButton = () => {
		return (
			<a className="btn btn--rectangle btn--icon address__map-toggle" onClick={this.changeMapVisibility} role="button">
				<span className="icon-location_pin" />
			</a>
		);
	};

	render() {
		const {
			locationPropName,
			suitePropName,
			postalOfficeBoxPropName,
			localityPropName,
			aa1PropName,
			postalCodePropName,
			countryPropName,
			disableMap,
			disabled,
			hideHeaders,
			onValueUpdate,
		} = this.props;
		const { marker, mapVisible } = this.state;

		return (
			<div>
				<Row>
					<Col md={6} sm={12}>
						<GoogleScriptLoader>
							<Autocomplete
								onLoad={this.handleStreetFieldMount}
								onPlaceChanged={this.onPlacesChanged}
								options={AddressMap.autocompleteOptions}
							>
								<Field
									addonAfter={!disableMap ? this.showMapButton() : null}
									addonAfterUnstyled={true}
									component={Input}
									disabled={disabled}
									id={locationPropName}
									label={!hideHeaders && 'Street Address'}
									name={locationPropName}
									onValueChange={this.onLocationChange}
									placeholder="Street Address"
									type="text"
								/>
							</Autocomplete>
						</GoogleScriptLoader>
					</Col>
					<Col md={2} sm={4}>
						<Field
							component={Input}
							disabled={disabled}
							id={suitePropName}
							label={!hideHeaders && 'Suite/Floor'}
							name={suitePropName}
							onValueChange={onValueUpdate}
							placeholder="Suite/Floor"
							ref={this.handleFieldMount}
							type="text"
						/>
					</Col>
					<Col md={4} sm={8}>
						<Field
							component={Input}
							disabled={disabled}
							id={localityPropName}
							label={!hideHeaders && 'City'}
							name={localityPropName}
							onValueChange={onValueUpdate}
							placeholder="City"
							ref={this.handleFieldMount}
							type="text"
						/>
					</Col>
					<Col md={4} sm={8}>
						<Field
							component={Input}
							disabled={disabled}
							id={aa1PropName}
							label={!hideHeaders && 'State'}
							name={aa1PropName}
							onValueChange={onValueUpdate}
							placeholder="State"
							ref={this.handleFieldMount}
							type="text"
						/>
					</Col>
					<Col md={2} sm={4}>
						<Field
							component={Input}
							disabled={disabled}
							id={postalCodePropName}
							label={!hideHeaders && 'Zip'}
							name={postalCodePropName}
							onValueChange={onValueUpdate}
							placeholder="Zip"
							ref={this.handleFieldMount}
							type="text"
						/>
					</Col>
					<Col md={2} sm={4}>
						<Field
							component={Input}
							disabled={disabled}
							id={postalOfficeBoxPropName}
							label={!hideHeaders && 'P.O. Box'}
							name={postalOfficeBoxPropName}
							onValueChange={onValueUpdate}
							placeholder="P.O. Box"
							ref={this.handleFieldMount}
							type="text"
						/>
					</Col>
					<Col md={4} sm={8}>
						<Field
							component={Input}
							disabled={disabled}
							id={countryPropName}
							label={!hideHeaders && 'Country'}
							name={countryPropName}
							onValueChange={onValueUpdate}
							placeholder="Country"
							ref={this.handleFieldMount}
							type="text"
						/>
					</Col>
				</Row>
				{
					mapVisible && !disableMap &&
					<MapRow
						center={this.state.center ?? defaults.center}
						marker={marker}
						onMapClick={this.onMapClick}
						zoom={this.state.zoom ?? defaults.zoom}
					/>
				}
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		changeField: change,
		getAddressByLatLng: AddressesActions.getAddressByLatLng,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(AddressMap);
