import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from 'af-reducers';

import { ResourcesGroupItem } from 'ab-viewModels/scheduleBoardToolbarResources.viewModel';

import { AVAILABLE_EQUIPMENT_STATUS } from 'ab-constants/equipment';
import { TOOLBAR_GROUP_DEFAULT_ID } from 'ab-constants/scheduleBoard';

import EquipmentGroup from './EquipmentGroup';

import { EquipmentModalProps } from '../../../Shared/ModalProps';

interface OwnProps extends EquipmentModalProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

interface StateProps {
	locationItems?: ResourcesGroupItem[];
	hasDefault?: boolean;
}

type Props = OwnProps & StateProps;

class AvailableEquipment extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		locationItems: [],
		hasDefault: false,
	};

	render() {
		const {
			dueDate,
			locationItems,
			hasDefault,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			setEquipmentModalData,
			setEquipmentModalVisibility,
		} = this.props;

		return (
			<div className="schedule-board-toolbar-equipment-row">
				<label>Equipment</label>
				<div className="schedule-board-toolbar-equipment-wrapper">
					<div className="schedule-board-toolbar-equipment">
						{
							locationItems?.reduce((acc: JSX.Element[], { id: _equipmentStatusId, title: _equipmentStatusName, isDeleted }) => {
								if (_equipmentStatusName) {
									acc.push(
										<EquipmentGroup
											available={true}
											dueDate={dueDate}
											groupId={_equipmentStatusId}
											groupTitle={_equipmentStatusName}
											hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
											isDeleted={isDeleted}
											isDragAndDropDisabled={isDragAndDropDisabled}
											key={_equipmentStatusId}
											setEquipmentModalData={setEquipmentModalData}
											setEquipmentModalVisibility={setEquipmentModalVisibility}
										/>
									);
								}
								return acc;

							}, [] as JSX.Element[])
						}
						{
							hasDefault &&
							<EquipmentGroup
								available={true}
								dueDate={dueDate}
								groupId={TOOLBAR_GROUP_DEFAULT_ID}
								groupTitle={AVAILABLE_EQUIPMENT_STATUS}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								isDeleted={false}
								isDragAndDropDisabled={isDragAndDropDisabled}
								setEquipmentModalData={setEquipmentModalData}
								setEquipmentModalVisibility={setEquipmentModalVisibility}
							/>
						}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate } = ownProps;

	if (!state.scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return {};
	}

	const { toolbarEquipment } = state?.scheduleBoard?.workOrdersByDateDictionary?.[dueDate];
	const { toolbarEquipmentGroupTitles } = state?.scheduleBoard;

	return {
		locationItems: toolbarEquipmentGroupTitles?.available,
		hasDefault: !!toolbarEquipment?.available?.[TOOLBAR_GROUP_DEFAULT_ID],
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(AvailableEquipment);
