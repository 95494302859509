import * as React from 'react';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';

import Dropdown from 'af-components/Controls/Dropdown';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import { dollarFormatter, dollarFormatterUnitPrice } from 'af-utils/format.util';
import { CellWidthsMap } from 'af-utils/react.util';

import { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import WorkSummaryVM, { WorkSummaryDefinitionFieldVM } from 'ab-viewModels/fieldReport/workSummary.viewModel';

import { WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS, WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_HEADERS } from '../../values';
import { resolveHighlightGroupKey } from '../../helpers';
import { resolveFieldValue } from '../../Table/helpers';

interface Props {
	rowData: WorkSummaryVM;
	billingCodes: BillingCodeVM[];
	maxNumberOfDefinitionFields: number;
	isReadOnly: boolean;
	onRowHighlight: (groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => void;
	highlightedGroup: Nullable<string>;
	cellWidths: CellWidthsMap;
	hasPreviouslyBeenSplit: boolean;
	isOfCurrentlyHighlightedGroupKey: boolean;
	isAlternativeOption: boolean;
	workSummaryDetailIds: number[];
}

export interface BillableWorkTableRowProps {
	isBillable: true;
	onBillingCodeSelect: (billingCode: Nullable<BillingCodeVM>, workSummaryDetailIds: number[]) => Promise<void>;
}

export interface NonBillableWorkTableRowProps {
	isBillable: false;
	onBillingCodeSelect?: never;
}

type OwnProps = Props & (BillableWorkTableRowProps | NonBillableWorkTableRowProps);

type DropdownOption = {
	id: number;
	customerId: string;
	unitPrice: Nullable<string>;
	description: string;
	group: Nullable<string>;
};

const _delimitersRegEx = new RegExp('\/|-| ');

const _filterBillingCodes = (_option: BillingCodeVM, _searchText: string) => {
	_searchText.trim();
	const splitSearch = _searchText.toLowerCase().split(_delimitersRegEx).join('');
	const _customerIdToLowerCase = _option.customerId.toLowerCase().split(_delimitersRegEx).join('');
	const _descriptionToLowerCase = _option.description.toLowerCase().split(_delimitersRegEx).join('');

	if (!!splitSearch && _customerIdToLowerCase.includes(splitSearch) || _descriptionToLowerCase.includes(splitSearch)) {
		return true;
	}

	return false;
};

const _renderBillingCodeMenuItem = (_billingCode: DropdownOption) => {
	return (
		<div className={bemElement('field-report__work-summary__table__row__data__cell', 'dropdown-menu-item')}>
			<b>{_billingCode.customerId}</b>
			<b>{`$ ${_billingCode.unitPrice} ${_billingCode.group ?? ''}`}</b>
			<p>{_billingCode.description}</p>
		</div>
	);
};

const _renderNoBillingCodeMenuItem = (item: { disabled: true; label: string; }) =>
	<div className={bemElement('field-report__work-summary__table__row__data__cell', 'dropdown-menu-item')}>
		<p>
			{item.label}
		</p>
	</div>;

const _renderSelectedDropdownMenuItem = (_billingCode: DropdownOption) => <span>{_billingCode.customerId}</span>;

const _renderSelectedDropdownMenuItemWithNoBillingCodes = ({ label }: { label: string; }) => <span>{label}</span>;

const _resolveTableCellClassName = (modifiers?) => {
	return bemElement('field-report__work-summary__table__row__data', 'cell', { ...modifiers });
};

const _fillWithEmptyFields = (fields: WorkSummaryDefinitionFieldVM[], maxNumberOfFields: number) => {
	const noOfExtraEmptyFields = maxNumberOfFields - (fields.length);
	let modifiedFields: Nullable<WorkSummaryDefinitionFieldVM>[] = [];

	if (noOfExtraEmptyFields) {
		const extraFields = new Array(noOfExtraEmptyFields).fill(null, 0, maxNumberOfFields);
		modifiedFields =
			fields
				? fields.concat(extraFields)
				: extraFields;
	} else {
		modifiedFields = fields;
	}

	return modifiedFields;
};

const _resolveStyleForHeader = (
	header: WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS | WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_HEADERS | string,
	cellWidths: CellWidthsMap
) => {
	if (!cellWidths?.[header]) {
		return {};
	}
	return {
		minWidth: cellWidths[header].width,
		maxWidth: cellWidths[header].width,
	};
};

const BillableAndNonBillableWorkTableRow: React.FC<OwnProps> = (props) => {
	const {
		billingCodes,
		rowData,
		maxNumberOfDefinitionFields,
		isBillable,
		onBillingCodeSelect,
		onRowHighlight,
		highlightedGroup,
		cellWidths,
		isReadOnly,
		hasPreviouslyBeenSplit,
		isOfCurrentlyHighlightedGroupKey,
		isAlternativeOption,
		workSummaryDetailIds,
	} = props;

	const {
		billableWorkId,
		quantity,
		unit,
		work,
		type,
		definitionFields,
		billingCodeId,
		unitPrice,
		total,
		group: groupName,
		description,
		customerId,
		isRecentlyUsed,
		billingGroup,
		typeFieldName,
		typeFieldType,
	} = rowData;

	const groupKey = React.useMemo(() => resolveHighlightGroupKey(billableWorkId, type, definitionFields), [billableWorkId, definitionFields, type]);
	const groupToHighlight = groupName ?? groupKey;

	const onBillingCodeChange = React.useCallback(async (value: BillingCodeVM) => {
		onRowHighlight(groupToHighlight, groupKey);
		await onBillingCodeSelect?.(value, workSummaryDetailIds);
	}, [groupKey, groupToHighlight, onBillingCodeSelect, onRowHighlight, workSummaryDetailIds]);

	const onBillingCodeClear = React.useCallback(() => {
		onRowHighlight(groupToHighlight, groupKey);
		onBillingCodeSelect?.(null, workSummaryDetailIds);
	}, [groupKey, groupToHighlight, onBillingCodeSelect, onRowHighlight, workSummaryDetailIds]);

	const cellClassName = bemElement('field-report__work-summary__table__row__data', 'cell');
	const cellTextClassName = bemElement(cellClassName, 'text');

	const _definitionFieldsMapper = React.useCallback((_df: WorkSummaryDefinitionFieldVM, _index: number) => {
		const header = `${WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.DEFINITION_FIELD} ${_index + 1}`;
		const formatted = _df ? `${_df.fieldName}: ${resolveFieldValue(_df.value, _df.fieldType)} ${_df.fieldUnit ?? ''}` : 'N/A';

		return (
			<span
				className={_resolveTableCellClassName()}
				key={_index}
				style={_resolveStyleForHeader(header, cellWidths)}
			>
				<span className={cellTextClassName}>
					{formatted}
				</span>
			</span>
		);
	}, [cellTextClassName, cellWidths]);

	const filteredBillingCodeOptions = React.useMemo(
		() => billingCodes.reduce<DropdownOption[]>((_acc: DropdownOption[], _bc: BillingCodeVM) => {
			if (!unit || _bc.unit === unit) {
				_acc.push({ customerId: _bc.customerId, id: _bc.id, description: _bc.description, unitPrice: _bc.unitPrice, group: _bc.group });
			}
			return _acc;
		}, [])
		, [billingCodes, unit]);

	const selectedBillingCode = React.useMemo(
		() => billingCodes.find((_bc) => _bc.id === billingCodeId, {})
		, [billingCodeId, billingCodes]);

	const selectedBillingCodeOption = React.useMemo<Nullable<DropdownOption>>(() => {
		if (selectedBillingCode) {
			return {
				customerId: selectedBillingCode.customerId,
				id: selectedBillingCode.id,
				description: selectedBillingCode.description,
				unitPrice: selectedBillingCode.unitPrice,
				group: selectedBillingCode.group,
			};
		}
		if (customerId && billingCodeId && description) {
			return {
				customerId,
				id: billingCodeId,
				unitPrice: unitPrice ? unitPrice + '' : '',
				description,
				group: billingGroup,
			};
		}

		return null;
	}, [billingCodeId, billingGroup, customerId, description, selectedBillingCode, unitPrice]);

	const hasSelectableBillingCodes = filteredBillingCodeOptions.length > 0;

	const onRowClick = React.useCallback(() => onRowHighlight(groupToHighlight, groupKey), [groupKey, groupToHighlight, onRowHighlight]);

	const _renderDropdown = React.useCallback(() => {
		if (!hasSelectableBillingCodes) {
			return (
				<Dropdown
					className={bemElement('field-report__work-summary__table__row__data__cell', 'dropdown')}
					defaultValue={customerId ? { label: customerId } : undefined}
					onClear={onBillingCodeClear}
					options={[{ disabled: true, label: 'No billing codes found.' }]}
					placeholder="No billing codes found."
					renderMenuItem={_renderNoBillingCodeMenuItem}
					renderSelected={_renderSelectedDropdownMenuItemWithNoBillingCodes}
					withCaret={true}
				/>
			);
		}

		return (
			<Dropdown<DropdownOption>
				className={bemElement('field-report__work-summary__table__row__data__cell', 'dropdown')}
				defaultValue={selectedBillingCodeOption}
				disabled={isReadOnly}
				filterable={true}
				filterBy={_filterBillingCodes}
				labelKey="customerId"
				onClear={onBillingCodeClear}
				onValueChange={onBillingCodeChange}
				options={filteredBillingCodeOptions}
				placeholder="Add Billing Code"
				renderMenuItem={_renderBillingCodeMenuItem}
				renderSelected={_renderSelectedDropdownMenuItem}
				valueKey="id"
				withCaret={true}
			/>);

	}, [customerId, filteredBillingCodeOptions, hasSelectableBillingCodes, isReadOnly, onBillingCodeChange, onBillingCodeClear, selectedBillingCodeOption]);

	const renderBillingCode = React.useCallback(() => {
		if (isBillable) {
			return (
				<span
					className={_resolveTableCellClassName({ long: true, dropdown: true })}
					style={_resolveStyleForHeader(WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.CHARGE_RATE, cellWidths)}
				>
					{isReadOnly
						? <span className={cellTextClassName}>
							{customerId}
						</span>
						: _renderDropdown()
					}
				</span>
			);
		}

		return (
			<span
				className={_resolveTableCellClassName({ long: true, dropdown: true })}
				style={_resolveStyleForHeader(WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_HEADERS.BILLING_CODE, cellWidths)}
			>
				<span className={cellTextClassName}>
					{customerId}
				</span>
			</span>
		);

	}, [_renderDropdown, cellTextClassName, cellWidths, customerId, isBillable, isReadOnly]);

	const modifiedDefinitionFields = _fillWithEmptyFields(definitionFields, maxNumberOfDefinitionFields);

	const isHighlighted = (highlightedGroup === groupToHighlight);
	const indicatePreviousSplit = hasPreviouslyBeenSplit && isOfCurrentlyHighlightedGroupKey;

	const rowClassName = bemElement('field-report__work-summary__table', 'row__data', {
		highlighted: isHighlighted,
		split: indicatePreviousSplit,
		'billable-table': true,
		alternative: isAlternativeOption,
	});

	const workTypeAdjustedName = typeFieldType === ReportBlockFieldEnum.Type.NUMERIC_ATTRIBUTE
		? typeFieldName
		: `${typeFieldName}: ${resolveFieldValue(type, typeFieldType)}`;

	return (
		<div
			className={rowClassName}
			onClick={onRowClick}
		>
			<span className={_resolveTableCellClassName({ actions: true })}>
				{isRecentlyUsed && <span className="icon-star" />}
			</span>
			<span
				className={_resolveTableCellClassName()}
				style={_resolveStyleForHeader(WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.WORK, cellWidths)}
			>
				<span className={cellTextClassName}>
					{work}
				</span>
			</span>
			<span
				className={_resolveTableCellClassName()}
				style={_resolveStyleForHeader(WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.TYPE, cellWidths)}
			>
				<span className={cellTextClassName}>
					{type ? workTypeAdjustedName : 'N/A'}
				</span>
			</span>
			{modifiedDefinitionFields?.map(_definitionFieldsMapper)}
			<span
				className={_resolveTableCellClassName({ short: true })}
				style={_resolveStyleForHeader(WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.QUANTITY, cellWidths)}
			>
				<span className={bemBlock(cellTextClassName, { 'align-right': true })}>
					{quantity}
				</span>
			</span>
			<span
				className={_resolveTableCellClassName({ short: true })}
				style={_resolveStyleForHeader(WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.UNIT, cellWidths)}
			>
				<span className={cellTextClassName}>
					{unit ?? 'N/A'}
				</span>
			</span>
			{renderBillingCode()}
			<span
				className={_resolveTableCellClassName()}
				style={_resolveStyleForHeader(WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.UNIT_PRICE, cellWidths)}
			>
				<span className={bemBlock(cellTextClassName, { 'align-right': true })}>
					{unitPrice ? dollarFormatterUnitPrice().format(unitPrice) : ''}
				</span>
			</span>
			<span
				className={_resolveTableCellClassName({ long: true })}
				style={_resolveStyleForHeader(WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.DESCRIPTION, cellWidths)}
			>
				<span className={cellTextClassName}>
					{description}
				</span>
			</span>
			<span
				className={_resolveTableCellClassName()}
				style={_resolveStyleForHeader(WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS.REVENUE, cellWidths)}
			>
				<span className={bemBlock(cellTextClassName, { 'align-right': true })}>
					{total ? dollarFormatter.format(total) : ''}
				</span>
			</span>
		</div>
	);
};

export default React.memo(BillableAndNonBillableWorkTableRow);
