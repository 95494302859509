import * as React from 'react';
import { Col } from 'react-bootstrap';
import { compose } from 'redux';
import { formValueSelector, Field } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';

import * as TimeOptionUtils from 'acceligent-shared/utils/timeOption';

import { TimeOptionValues } from 'acceligent-shared/enums/timeOption';

import { RootState } from 'af-reducers';

import Dropdown from 'af-fields/Dropdown';

import DropdownEmployeeItem from 'af-components/DropdownEmployeeItem';

import { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';

import * as EmployeeActions from 'af-actions/employee';

import { DAY_SHIFT, NIGHT_SHIFT } from 'ab-enums/shift.enum';

import { DROPDOWN_MENU_CONTAINER_CLASSNAME } from 'af-constants/values';
import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import Section from '../../Shared/Section';

import Shift from './Shift';

const TimeOptions = Object.keys(TimeOptionValues).map((key) => ({ id: TimeOptionUtils.toNumber(key), name: TimeOptionValues[key] }));

interface OwnProps {
	disabled: boolean;
	resetTimer: () => void;
	change: (fieldName: string, value: string | number | null) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	projectManagers: EmployeeOptionVM[];
}

class WorkOrderGeneral extends React.PureComponent<Props, State> {
	state: State = {
		projectManagers: [],
	};

	TIME_TO_START_CONTAINER = 'timeToStart-container';
	TIME_TO_END_CONTAINER = 'timeToEnd-container';

	static FILTER_PM_BY = ['firstName', 'lastName', 'fullName', 'formattedCode', 'accountTemplate'];

	static renderEmployee = (option: EmployeeOptionVM) => <DropdownEmployeeItem option={option} showUserRole={true} />;
	static renderSelectedEmployee = (option: EmployeeOptionVM) => <DropdownEmployeeItem option={option} showUserRole={false} />;

	componentDidUpdate(prevProps: Props) {
		const { shift } = this.props;

		if (shift?.id !== prevProps.shift?.id) {
			this.onShiftChange();
		}
	}

	lazyLoadProjectManagers = async (isLazyLoaded: boolean) => {
		const { loadProjectManagers } = this.props;

		if (!isLazyLoaded) {
			const projectManagers = await loadProjectManagers();
			this.setState(() => ({ projectManagers }));
		}
	};

	onMenuOpen = (id: string, propName: string) => {
		const { valueSelector } = this.props;

		const dropdownContainer = document.getElementById(id);
		if (!dropdownContainer) {
			throw new Error('Dropdown container not found');
		}

		const menu = dropdownContainer.getElementsByClassName(DROPDOWN_MENU_CONTAINER_CLASSNAME)[0];
		const selectedNumber = valueSelector(propName) as number;
		const selected = TimeOptionUtils.toString(selectedNumber);
		const items = Array.from(menu.children);
		const index = items.findIndex((item) => item.textContent === TimeOptionValues[selected]);
		const selectedItem = items[index];
		if (selectedItem) {
			const { height } = selectedItem.getBoundingClientRect();
			menu.scrollTo({ top: index * height });
		}
	};

	onMenuOpenWorkTimeStart = () => this.onMenuOpen(this.TIME_TO_START_CONTAINER, 'timeToStart');

	onMenuOpenWorkTimeEnd = () => this.onMenuOpen(this.TIME_TO_END_CONTAINER, 'timeToEnd');

	onShiftChange = () => {
		const {
			dayShiftStart,
			dayShiftEnd,
			nightShiftStart,
			nightShiftEnd,
			shift,
			change,
			resetTimer,
		} = this.props;
		if (shift?.id === DAY_SHIFT.id) {
			change('timeToStart', dayShiftStart ?? null);
			change('timeToEnd', dayShiftEnd ?? null);
		} else if (shift?.id === NIGHT_SHIFT.id) {
			change('timeToStart', nightShiftStart ?? null);
			change('timeToEnd', nightShiftEnd ?? null);
		}
		resetTimer();
	};

	onStartTimeSelected = (timeOption: typeof TimeOptions[0]) => {
		const { resetTimer, change } = this.props;

		change('timeToStart', timeOption.id);
		resetTimer();
	};

	onEndTimeSelected = (timeOption: typeof TimeOptions[0]) => {
		const { resetTimer, change } = this.props;

		change('timeToEnd', timeOption.id);
		resetTimer();
	};

	render() {
		const { disabled, resetTimer, shift } = this.props;
		const { projectManagers } = this.state;

		const isCustomShift = shift?.id !== DAY_SHIFT.id && shift?.id !== NIGHT_SHIFT.id;

		return (
			<Section label="General">
				<Col md={8}>
					<Field
						component={Dropdown}
						containerId="work-order__project-manager"
						disabled={disabled}
						dropdownClassName="work-order-upsert__user-type-dropdown"
						dropdownId="work-order__project-manager--dropdown"
						filterable={true}
						filterBy={WorkOrderGeneral.FILTER_PM_BY}
						hasBlankOption={true}
						id="projectManagerId"
						label="Project Manager"
						name="projectManagerId"
						onLazyLoad={this.lazyLoadProjectManagers}
						onValueChange={resetTimer}
						options={projectManagers}
						placeholder="Select Project Manager"
						propName="projectManager"
						renderMenuItem={WorkOrderGeneral.renderEmployee}
						renderSelected={WorkOrderGeneral.renderSelectedEmployee}
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col md={isCustomShift ? 8 : 6}>
					<Shift disabled={disabled} />
				</Col>
				<Col id={this.TIME_TO_START_CONTAINER} md={3} xs={12}>
					<Field
						component={Dropdown}
						disabled={disabled}
						focusToSelected={true}
						id="timeToStart"
						label="Work Time Start"
						labelKey="name"
						name="timeToStart"
						onMenuOpen={this.onMenuOpenWorkTimeStart}
						onValueChange={this.onStartTimeSelected}
						options={TimeOptions}
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col id={this.TIME_TO_END_CONTAINER} md={3} xs={12}>
					<Field
						component={Dropdown}
						disabled={disabled}
						focusToSelected={true}
						id="timeToEnd"
						label="Work Time End"
						labelKey="name"
						name="timeToEnd"
						onMenuOpen={this.onMenuOpenWorkTimeEnd}
						onValueChange={this.onEndTimeSelected}
						options={TimeOptions}
						valueKey="id"
						withCaret={true}
					/>
				</Col>
			</Section>
		);
	}
}

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const { company } = state.company;

	const shift = selector(state, 'shift');

	return {
		dayShiftStart: company?.dayShiftStart,
		dayShiftEnd: company?.dayShiftEnd,
		nightShiftStart: company?.nightShiftStart,
		nightShiftEnd: company?.nightShiftEnd,
		shift,
		valueSelector: (fieldName: string) => selector(state, fieldName),
	};
}

function mapDispatchToProps() {
	return {
		loadProjectManagers: EmployeeActions.findAllProjectManagers,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(connector);

export default enhance(WorkOrderGeneral);
