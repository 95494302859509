import * as React from 'react';
import { Button } from 'react-bootstrap';

interface OwnProps {
	onUnlockClick: () => void;
	lockedBy: Nullable<string>;
}

type Props = OwnProps;

class LockedBanner extends React.PureComponent<Props> {

	render() {
		const { onUnlockClick, lockedBy } = this.props;

		return (
			<div className="banner-wrapper">
				<div className="banner-container">
					<div className="banner-description">
						<span className="icon-lock" />
						<span>Locked for editing {!!lockedBy && `by ${lockedBy}`}</span>
					</div>
					<div className="banner-actions">
						<Button
							onClick={onUnlockClick}
							variant="info"
						>
							Take Over Editing*
						</Button>
						<span className="banner-action-description">*Discard the current editors data and take over editing of the calculations.</span>
					</div>
				</div>
			</div>
		);
	}
}

export default LockedBanner;
