import * as React from 'react';
import { FieldArray, FormAction, WrappedFieldArrayProps } from 'redux-form';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import EmptyContactMethodPicker from 'af-components/SharedForms/Contacts/EmptyContactMethodPicker';

import PhoneCheckbox from './PhoneCheckbox';
import EmailRadioButtonsContainer from './EmailRadioButtonsContainer';

export interface Props {
	change: (formName: string, field: string, value: string | string[]) => FormAction;
	contact: ContactVM;
	disabled?: boolean;
	emailFieldName: string;
	formName: string;
	phoneFieldName: string;
	select: (field: string) => number[];
	useIn: string;
}

export default class ContactMethodPicker extends React.PureComponent<Props> {

	renderPhones = () => {
		const { contact, phoneFieldName, disabled } = this.props;

		if (!contact?.phones) {
			return null;
		}

		return (
			<FieldArray
				component={this.renderPhoneCheckboxes}
				disabled={disabled}
				name={phoneFieldName}
			/>
		);
	};

	renderPhoneCheckboxes = ({ fields, meta: { error } }: WrappedFieldArrayProps<Metadata>) => {
		const { contact: { phones }, disabled } = this.props;

		return (
			<>
				{phones?.map((phone, index) => (
					<PhoneCheckbox
						disabled={disabled}
						fields={fields}
						key={index}
						phone={phone}
					/>
				))}
				{error && <span className="help-block"><span className="icon-info" /> {error}</span>}
			</>
		);
	};

	renderEmails = () => {
		const { contact, emailFieldName, change, disabled, formName, select } = this.props;

		if (!contact?.emails) {
			return null;
		}

		return (
			<>
				<EmptyContactMethodPicker
					change={change}
					disabled={disabled}
					fieldName={emailFieldName}
					formName={formName}
					select={select}

				/>
				<FieldArray<Props>
					component={EmailRadioButtonsContainer}
					name={emailFieldName}
					{...this.props}
				/>
			</>
		);
	};

	render() {
		const { useIn } = this.props;
		return (
			<>
				<div className="contact__methods-row contact__methods-row--heading">
					<div className="contact__method-control">Use in {useIn}</div>
					<div>Email</div>
				</div>
				{this.renderEmails()}
				<div className="contact__methods-row contact__methods-row--heading">
					<div className="contact__method-control">Use in {useIn}</div>
					<div>Mobile Phone</div>
				</div>
				{this.renderPhones()}
			</>
		);
	}
}
