import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import Textarea from 'af-fields/Textarea';
import TextField from 'af-fields/Text';

import Section from '../Shared/Section';

interface OwnProps {
	disabled: boolean;
	resetTimer: () => void;
}

type Props = OwnProps;

const TEXTAREA_ROW_COUNT = 6;
const NOTES_MAX_CHAR_COUNT = 250;

const WorkOrderNotes: React.FC<Props> = (props) => {
	const { disabled, resetTimer } = props;
	return (
		<Section label="Notes">
			<Col sm={12}>
				<Field
					component={Textarea}
					disabled={disabled}
					label="Work Order Notes"
					maxCharacters={NOTES_MAX_CHAR_COUNT}
					name="notes"
					onValueChange={resetTimer}
					placeholder="Enter Work Order Notes"
					rows={TEXTAREA_ROW_COUNT}
					showMaxCharactersLabel={true}
				/>
			</Col>
			<Col sm={12}>
				<Field
					component={Textarea}
					disabled={disabled}
					label="Scope of Work"
					name="scopeOfWork"
					onValueChange={resetTimer}
					placeholder="Enter Scope of Work"
					rows={TEXTAREA_ROW_COUNT}
				/>
			</Col>
			<Col sm={12}>
				<Field component={TextField} defaultValue="-" label="Job Notes" name="job.jobNote" />
			</Col>
		</Section>
	);
};

export default React.memo(WorkOrderNotes);
