import * as React from 'react';
import { Field, InjectedFormProps } from 'redux-form';

import ItemCategoryRM from 'ab-requestModels/itemCategory/itemCategory.requestModel';

import ItemCategoryVM from 'ab-viewModels/itemCategory/itemCategory.viewModel';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';

import Input from 'af-fields/Input';
import Tooltip from 'af-components/Tooltip';

interface Props extends InjectedFormProps<ItemCategoryRM> {
	onSubmit: (form: ItemCategoryRM) => void;
	close: () => void;
	categories: ItemCategoryVM[];
	id: Nullable<number>;
	formValue?: string;
}

const ItemCategoryForm = (props: Props) => {
	const {
		handleSubmit,
		onSubmit,
		submitting,
		close,
		categories,
		id,
		formValue,
	} = props;

	const handleKeyDown = React.useCallback((e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			e.preventDefault();
			if (!submitting) {
				handleSubmit(onSubmit)(e);
			}
		}
	}, [handleSubmit, onSubmit, submitting]);

	const checkUniqueName = React.useCallback((value: string) => {
		if (categories.some((_category) => _category.name === (formValue ?? value) && _category.id !== id)) {
			return 'Category already exists';
		}
	}, [categories, id, formValue]);

	return (
		<form className="color-picker__form">
			<Field
				autoFocus={true}
				centered={true}
				component={Input}
				cursorControl={true}
				hideErrorText={true}
				isStandalone={true}
				name="name"
				onKeyDown={handleKeyDown}
				placeholder="Enter Category Name"
				type="text"
				validate={checkUniqueName}
			/>
			<MultipleOptionsButton isLeftFlat={true}>
				<RectangleButton
					action={handleSubmit(onSubmit)}
					isLeftOpen={true}
					isSquare={true}
					label={
						<Tooltip message="Submit" >
							<span className="icon-check" />
						</Tooltip>
					}
				/>
				<RectangleButton
					action={close}
					isSquare={true}
					label={
						<Tooltip message="Close" >
							<span className="icon-close" />
						</Tooltip>
					}
				/>
			</MultipleOptionsButton>
		</form>
	);
};

export default ItemCategoryForm;
