import * as React from 'react';
import { Field } from 'redux-form';

import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';

import Textarea from 'af-fields/Textarea';

import { formSectionClass, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../helpers';

const WORK_SUMMARY_MAX_LENGTH = 600;

type Props = {
	isDisabled: boolean;
};

const SummaryOfWorkSection: React.FC<Props> = ({ isDisabled }) => {
	return (
		<div className={formSectionClass}>
			<div className={formSectionClassRow}>
				<div className={formSectionTitleClass}>
					Summary of Work
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					<Field
						component={Textarea}
						disabled={isDisabled}
						maxCharacters={WORK_SUMMARY_MAX_LENGTH}
						name={getAttributeName('requestJobNote')}
						placeholder="Enter text"
						rows={4}
						showMaxCharactersLabel={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(SummaryOfWorkSection);
