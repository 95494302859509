import * as User from 'ab-viewModels/user.viewModel';

import PagePermissions from './pagePermissions.enum';

import { FAKE_ACCOUNT_DATA } from 'ab-constants/value';

import { isAllowed } from 'ab-utils/auth.util';

enum UserTypeFilter {
	ALL = 'ALL',
	ALL_ASSIGNED = 'ALL_ASSIGNED',
	AS_FIELD_WORKER = 'AS_FIELD_WORKER',
	AS_SUPERINTENDENT = 'AS_SUPERINTENDENT'
}

const UserTypeFilterNames = {
	ALL: 'All',
	ALL_ASSIGNED: 'All Assigned',
	AS_FIELD_WORKER: 'As Field Worker',
	AS_SUPERINTENDENT: 'As Superintendent',
} as const;

export type UserTypeFilterOption = {
	id: UserTypeFilter;
	name: string;
};

export const getUserTypeFilterOptions = (companyData: User.CompanyData, userData: User.UserData): UserTypeFilterOption[] => {
	const { isCompanyAdmin, permissions } = companyData;
	const { id: userId, role } = userData;

	const isPlatformAdmin = userId === FAKE_ACCOUNT_DATA.USER_ID;
	const hasViewAllPermission: boolean = isAllowed(
		PagePermissions.COMPANY.FIELD_REPORT.VIEW_ALL,
		permissions,
		isCompanyAdmin,
		role
	);

	const options: UserTypeFilterOption[] = [];

	if (hasViewAllPermission) {
		options.push({ id: UserTypeFilter.ALL, name: UserTypeFilterNames.ALL });
	}
	if (!isPlatformAdmin) {
		options.push({ id: UserTypeFilter.ALL_ASSIGNED, name: UserTypeFilterNames.ALL_ASSIGNED });
		options.push({ id: UserTypeFilter.AS_FIELD_WORKER, name: UserTypeFilterNames.AS_FIELD_WORKER });
		options.push({ id: UserTypeFilter.AS_SUPERINTENDENT, name: UserTypeFilterNames.AS_SUPERINTENDENT });
	}

	return options;
};

export default UserTypeFilter;
