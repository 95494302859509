import * as React from 'react';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
}

type Props = OwnProps;

class TimelineSubmitSuccessModal extends React.PureComponent<Props> {

	static readonly COMPONENT_CLASS_NAME = 'submit-success-modal';

	render() {
		const { closeModal, showModal } = this.props;

		const iconClassName = `${TimelineSubmitSuccessModal.COMPONENT_CLASS_NAME}__check-icon icon-check_circle text-green`;

		return (
			<CustomModal
				className="submit-success-modal"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Sign Time Sheet"
				/>
				<CustomModal.Body>
					<div className={`${TimelineSubmitSuccessModal.COMPONENT_CLASS_NAME}__content-title`}>Time Sheet Signed</div>
					<span className={iconClassName} />
					<div className={`${TimelineSubmitSuccessModal.COMPONENT_CLASS_NAME}__user-message`}>
						Your signature has been successfully submitted.
					</div>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button onClick={closeModal} variant="info">Close</Button>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default TimelineSubmitSuccessModal as React.ComponentClass<OwnProps>;
