import * as React from 'react';
import { connect } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import DeliverableParentTable from './Table/ParentTable';

interface PathParams {
	orgAlias: string;
}

interface StateProps {
	companyName: string;
}

type Props = StateProps & CustomRouteComponentProps<PathParams>;

class DeliverableDashboard extends React.Component<Props> {

	render() {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;

		return (
			<DeliverableParentTable
				companyName={companyName}
				history={history}
				orgAlias={orgAlias}
			/>
		);
	}
}

const mapStateToProps = (state: RootState): StateProps => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

export default connect(mapStateToProps)(DeliverableDashboard);
