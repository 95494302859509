import * as React from 'react';

import * as ReportBlockEnums from 'acceligent-shared/enums/reportBlockField';

import { ReportBlockFieldVM } from 'ab-viewModels/reportBlock/reportBlockTable.viewModel';

import Tooltip from 'af-components/Tooltip';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	keyFields: ReportBlockFieldVM[];
	plainFields: ReportBlockFieldVM[];
}

type Props = OwnProps;

const _renderFieldTypeArray = (fields: ReportBlockFieldVM[]) => fields.map(_renderToolTipFieldType);

const _renderToolTipFieldType = (field: ReportBlockFieldVM) => {
	return (
		<div key={field.id}>
			{field.name} <span className="report-table__tooltip--field-type">[{ReportBlockEnums.PrimaryTypeNamed[field.fieldType]}]</span>
		</div>
	);
};

const FieldTypeCell: React.FC<Props> = (props: Props) => {
	const { keyFields, plainFields } = props;

	const renderTooltipKeyFields = () => {
		return (
			<>
				<div className="report-table__tooltip--title">KEY FIELD TYPES</div>
				{_renderFieldTypeArray(keyFields)}
			</>
		);
	};

	const renderTooltipOtherFields = () => {
		return (
			<div>
				<div className="report-table__tooltip--title">OTHER FIELD TYPES</div>
				{_renderFieldTypeArray(plainFields)}
			</div>
		);
	};

	return (
		<>
			<div className={bemElement('report-table', 'field-count', ['left'])}>
				<Tooltip message={renderTooltipKeyFields()}>
					<div>{keyFields?.length ?? '-'}</div>
				</Tooltip>
			</div>
			/
			<div className={bemElement('report-table', 'field-count', ['right'])}>
				<Tooltip message={renderTooltipOtherFields()}>
					<div>{plainFields?.length ?? '-'}</div>
				</Tooltip>
			</div>
		</>

	);
};

export default React.memo(FieldTypeCell);
