import * as React from 'react';

import { ColorPalette } from 'acceligent-shared/enums/color';

import * as ColorUtils from 'ab-utils/color.util';

import ConfirmationModal from 'af-components/ConfirmationModal';
import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	label: string;
	color?: ColorPalette;
	title: string;
	onEdit: () => void;
	onDelete: () => void;
}

type Props = OwnProps;

interface State {
	showModal: boolean;
}

class PreviewTag extends React.PureComponent<Props, State> {
	state: State = {
		showModal: false,
	};

	hideModal = () => this.setState(() => ({ showModal: false }));

	openModal = () => this.setState(() => ({ showModal: true }));

	deleteTag = async () => {
		const { onDelete } = this.props;
		onDelete();
		this.hideModal();
	};

	editTag = () => this.props.onEdit();

	render() {
		const { label, color, title } = this.props;
		const { showModal } = this.state;

		const className = `pills__item pills__item--clickable text-and-color-resource-preview ${ColorUtils.getColorBackgroundClass(color, false)}`;

		return (
			<span className={className}>
				<Tooltip message={label}>
					<span className="pills__label pills__label--text-wrap" onClick={this.editTag}>{label}</span>
					<span className="icon-close" onClick={this.openModal} />
				</Tooltip>
				<ConfirmationModal
					body={
						<>
							You are about to delete <b>{label}</b>.
							Are you sure you want to continue?
						</>
					}
					closeModal={this.hideModal}
					confirmAction={this.deleteTag}
					confirmText="Delete"
					modalStyle="danger"
					showModal={showModal}
					title={title}
				/>
			</span>
		);
	}
}

export default PreviewTag;
