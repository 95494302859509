import * as React from 'react';
import { Field, FormErrors, InjectedFormProps } from 'redux-form';
import { Form } from 'react-bootstrap';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';

import Input from 'af-fields/Input';

export interface OwnProps<T> {
	form?: string;
	placeholder: string;
	onSubmit: (form: T) => Promise<void>;
	close?: () => void;
	validate: (values: T) => FormErrors<T>;
	initialValues?: Partial<T>;
	toggleEdit?: () => void;
}

type Props<T> = OwnProps<T> & InjectedFormProps<T, OwnProps<T>, T>;

export default class TagForm<T> extends React.PureComponent<Props<T>> {

	handleKeyDown = (e: React.KeyboardEvent) => {
		const { handleSubmit, onSubmit, submitting } = this.props;
		if (e.key === 'Enter' && e.shiftKey === false) {
			e.preventDefault();
			if (!submitting) {
				handleSubmit(onSubmit)(e);
			}
		}
	};

	close = () => {
		const { close } = this.props;
		close?.();
	};

	render() {
		const { handleSubmit, onSubmit, placeholder } = this.props;

		return (
			<Form className="text-and-color-form">
				<Field
					autoFocus={true}
					centered={true}
					component={Input}
					cursorControl={true}
					hideErrorText={true}
					name="name"
					onKeyDown={this.handleKeyDown}
					placeholder={placeholder}
					type="text"
				/>
				<MultipleOptionsButton isLeftFlat={true}>
					<RectangleButton
						action={handleSubmit(onSubmit)}
						isLeftOpen={true}
						isSquare={true}
						label={<span className="icon-check" />}
						tooltipMessage="Submit"
					/>
					<RectangleButton
						action={this.close}
						isSquare={true}
						label={<span className="icon-close" />}
						tooltipMessage="Close"
					/>
				</MultipleOptionsButton>
			</Form>
		);
	}
}
