import * as React from 'react';

interface Props {
	additionalClassName?: string;
}

export default class PlaceholderCard extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		additionalClassName: '',
	};

	render() {
		const { additionalClassName } = this.props;
		return (
			<>
				<div className={`schedule-board-card-order-info ${additionalClassName}`}>
					<div className={`schedule-board-card-crew ${additionalClassName}`}>
						<div className={`loading-col-24 loading-box-row dark ${additionalClassName}`} />
					</div>
					<div className={`schedule-board-card-supervisor ${additionalClassName}`}>
						<div className={`loading-col-24 loading-box-row dark ${additionalClassName}`} />
					</div>
					<div className={`schedule-board-card-code ${additionalClassName}`}>
						<div className={`loading-col-24 loading-box-row dark ${additionalClassName}`} />
					</div>
					<div className={`schedule-board-card-address ${additionalClassName}`}>
						<div className={`loading-col-24 loading-box-row dark ${additionalClassName}`} />
					</div>
				</div>
				<div className={`schedule-board-card-employees ${additionalClassName}`}>
					<div className={`sb-resource-item sb-resource-item--employee ${additionalClassName}`}>
						<div className={`loading-col-24 loading-box-row dark ${additionalClassName}`} />
					</div>
					<div className={`sb-resource-item sb-resource-item--employee ${additionalClassName}`}>
						<div className={`loading-col-24 loading-box-row dark ${additionalClassName}`} />
					</div>
				</div>
				<div className={`schedule-board-card-equipment ${additionalClassName}`}>
					<div className={`sb-resource-item sb-resource-item--equipment ${additionalClassName}`}>
						<div className={`loading-col-24 loading-box-row dark ${additionalClassName}`} />
					</div>
					<div className={`sb-resource-item sb-resource-item--equipment ${additionalClassName}`}>
						<div className={`loading-col-24 loading-box-row dark ${additionalClassName}`} />
					</div>
				</div>
			</>
		);
	}
}
