import { AnyAction, Dispatch } from 'redux';

import TimePeriodSpan from 'acceligent-shared/enums/timePeriodSpan';

import API from 'af-constants/routes/api';

import { GetRootState } from 'af-reducers';

import { EquipmentCostUtilizationChartsViewModel } from 'ab-viewModels/equipmentCostUtilization.viewModel';
import { EquipmentUtilizationTablesViewModel } from 'ab-viewModels/equipmentUtilizationTable.viewModel';
import { LaborUtilizationDataViewModel, LaborUtilizationChartsViewModel } from 'ab-viewModels/laborUtilization.viewModel';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

export function fetchEquipmentUtilizationTables(timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<EquipmentUtilizationTablesViewModel>(API.V1.KPI.EQUIPMENT_UTILIZATION(timePeriod));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function fetchEquipmentCostUtilizationCharts(equipmentCostId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<EquipmentCostUtilizationChartsViewModel>(API.V1.KPI.EQUIPMENT_COST_UTILIZATION_CHARTS(equipmentCostId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function fetchLaborUtilizationData() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<LaborUtilizationDataViewModel>(API.V1.KPI.LABOR_UTILIZATION());
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function fetchLaborUtilizationCharts() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<LaborUtilizationChartsViewModel>(API.V1.KPI.LABOR_UTILIZATION_CHARTS());
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
