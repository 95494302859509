import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

export default class Loading extends React.PureComponent {

	renderRow = () => {
		return (
			<Row>
				<Col className="loading-box-field" sm={4} />
				<Col sm={1} />
				<Col className="loading-box-field" sm={4} />
				<Col sm={1} />
				<Col className="loading-box-field" sm={4} />
				<Col sm={1} />
				<Col className="loading-box-field" sm={4} />
				<Col sm={1} />
				<Col className="loading-box-field" sm={4} />
			</Row>
		);
	};

	render() {
		return (
			<div className="form-segment p-l">
				<Row>
					<Col className="loading-box-row" sm={4} />
					<Col sm={1} />
					<Col className="loading-box-row" sm={4} />
					<Col sm={1} />
					<Col className="loading-box-row" sm={4} />
					<Col sm={1} />
					<Col className="loading-box-row" sm={4} />
					<Col sm={1} />
					<Col className="loading-box-row" sm={4} />
				</Row>
				{this.renderRow()}
				{this.renderRow()}
				{this.renderRow()}
				{this.renderRow()}
				{this.renderRow()}
				<Row>
					<Col className="loading-box-row" sm={4} />
					<Col sm={1} />
					<Col className="loading-box-row" sm={4} />
					<Col sm={1} />
					<Col className="loading-box-row" sm={4} />
					<Col sm={1} />
					<Col className="loading-box-row" sm={4} />
					<Col sm={1} />
					<Col className="loading-box-row" sm={4} />
				</Row>
			</div>
		);
	}
}
