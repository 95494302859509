import * as React from 'react';

import ConfirmationModal from 'af-components/ConfirmationModal';

interface OwnProps {
	takeOverDailyMetrics: () => void;
	closeModal: () => void;
	showModal: boolean;
}

type Props = OwnProps;

const ConfirmTakeOverModal: React.FC<Props> = (props: Props) => {
	const { showModal, closeModal, takeOverDailyMetrics } = props;

	return (
		<ConfirmationModal
			body={
				<>
						All changes the current user made will be discarded.
					<br />
						This action cannot be undone.
				</>
			}
			closeModal={closeModal}
			confirmAction={takeOverDailyMetrics}
			confirmText="Take Over Editing"
			modalStyle="danger"
			showModal={showModal}
			size="md"
			title="Are you sure you want to take over editing?"
		/>
	);
};

export default React.memo(ConfirmTakeOverModal);
