import TimeSheetEntryBaseVM from 'acceligent-shared/dtos/web/view/timeSheet/timeSheetEntry';
import TimeSplitEntryVM from 'acceligent-shared/dtos/web/view/timeSplitEntry/timeSplitEntry';
import TimeAllocationEntryVM from 'acceligent-shared/dtos/web/view/timeAllocationEntry/timeAllocationEntry';

import TimeSheetApprovalStatus, { mapTimeSheetApprovalStatus } from 'acceligent-shared/enums/timeSheetApprovalStatus';
import TimeSheetEntryType from 'acceligent-shared/enums/timeSheetEntryType';
import TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';
import TimelineEntityType from 'acceligent-shared/enums/timelineEntityType';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { workOrderCode } from 'acceligent-shared/utils/codes';
import { findOverlaps, getTimelineEntities, OccupiedEntity, OverlapMeta, TimelineEntity } from 'acceligent-shared/utils/timeSheetEntry';
import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeSheetEntry from 'acceligent-shared/models/timeSheetEntry';

import { OccupiedTimeSheetEntryForAccount } from 'ab-serviceModels/timeSheetEntry.serviceModel';

interface TimeSheetEntriesVM {
	entries: TimeSheetEntryVM[];
	hasChanges: boolean;
}

export class TimeSheetEntriesByAccountIdVM {
	timeSheetEntriesByAccountId: { [accountId: number]: TimeSheetEntriesVM; };

	constructor(timeSheetEntries: TimeSheetEntry[]) {

		this.timeSheetEntriesByAccountId = timeSheetEntries.reduce(TimeSheetEntriesByAccountIdVM._timeSheetEntriesReducer, {});
	}

	private static _timeSheetEntriesReducer = (acc: { [accountId: number]: TimeSheetEntriesVM; }, timeSheetEntry: TimeSheetEntry): typeof acc => {
		if (acc[timeSheetEntry.timeSheet.accountId]) {
			if (!timeSheetEntry.deletedAt) {
				acc[timeSheetEntry.timeSheet.accountId].entries.push(new TimeSheetEntryVM(timeSheetEntry));
			}
			acc[timeSheetEntry.timeSheet.accountId].hasChanges = true;
		} else {
			if (!timeSheetEntry.deletedAt) {
				acc[timeSheetEntry.timeSheet.accountId] = { entries: [new TimeSheetEntryVM(timeSheetEntry)], hasChanges: true };
			} else {
				acc[timeSheetEntry.timeSheet.accountId] = { entries: [], hasChanges: true };
			}
		}
		return acc;
	};
}

interface TimelineEntityDefinition {
	/** ISO_DATETIME */
	startTime: string;
	/** ISO_DATETIME */
	endTime: Nullable<string>;
	id: number;
	workType: TimeSheetEntryWorkType;
	type: TimeSheetEntryType;
	workOrderCode: string;
}

type OccupiedEntry = OccupiedEntity['entry'];
interface OccupiedTimeSheetEntityForAccount extends OccupiedEntry {
	id: string;
	workOrderCode: string;
}

export type TimelineEntitesForAccount = TimelineEntity<TimelineEntityDefinition, OccupiedTimeSheetEntityForAccount>;

interface OccupiedTimeSheetEntryByAccountId {
	[accountId: number]: OccupiedTimeSheetEntityForAccount[];
}
export class TimeSheetEntriesForTimelineVM {
	timelineEntitiesAndOverlaps: {
		[accountId: number]: {
			timelineEntities: TimelineEntitesForAccount[];
			overlaps: Record<string, OverlapMeta>;
			hasChanges: boolean;
		};
	};

	constructor(timeSheetEntriesForAccount: TimeSheetEntriesByAccountIdVM, occupiedSlots: OccupiedTimeSheetEntryForAccount[]) {

		const accountIds = Object.keys(timeSheetEntriesForAccount.timeSheetEntriesByAccountId);
		const UNASSIGNED_ENTRY_LABEL = 'Unassigned time';
		const occupiedSlotsForAccount = occupiedSlots.reduce((_occupiedEntriesMap, _currOccEntry) => {

			const newOccEntry = {
				id: _currOccEntry.id,
				startTime: _currOccEntry.startTime,
				endTime: _currOccEntry.endTime,
				workOrderCode: _currOccEntry.workOrderCode,
			};

			if (_occupiedEntriesMap[_currOccEntry.accountId]) {
				_occupiedEntriesMap[_currOccEntry.accountId].push(newOccEntry);
				return _occupiedEntriesMap;
			}
			_occupiedEntriesMap[_currOccEntry.accountId] = [newOccEntry];
			return _occupiedEntriesMap;

		}, {} as OccupiedTimeSheetEntryByAccountId);

		this.timelineEntitiesAndOverlaps = accountIds.reduce((_acc, _accId) => {

			const _tse: Nullable<TimeSheetEntriesVM> = timeSheetEntriesForAccount.timeSheetEntriesByAccountId[_accId];
			if (!_tse) {
				throw new Error('Time sheet entries not found');
			}
			const { entries, hasChanges } = _tse;

			const formattedEntries = entries.map((_e) => ({
				startTime: _e.startTime,
				endTime: _e.endTime,
				id: _e.id,
				workType: _e.workType,
				type: _e.type,
				workOrderCode: _e.workOrderCode ?? UNASSIGNED_ENTRY_LABEL,
			}));

			const occupiedSlotsForCurrAcc = occupiedSlotsForAccount[_accId] ?? [];

			const overlaps = findOverlaps(formattedEntries, occupiedSlotsForCurrAcc);

			let timelineEntities = getTimelineEntities<TimelineEntityDefinition, OccupiedTimeSheetEntityForAccount>(
				formattedEntries,
				occupiedSlotsForCurrAcc,
				false,
				true
			);

			// unlike on the TS Edit modal, we do not want the timeline to start and end with occupied slots
			timelineEntities = TimeSheetEntriesForTimelineVM._removeStartingAndEndingOccupiedSlots(timelineEntities);

			_acc[_accId] = {
				timelineEntities,
				overlaps,
				hasChanges,
			};

			return _acc;
		}, {} as TimeSheetEntriesForTimelineVM['timelineEntitiesAndOverlaps']);

	}

	private static _removeStartingAndEndingOccupiedSlots = (entities: TimelineEntity<TimelineEntityDefinition, OccupiedTimeSheetEntityForAccount>[]) => {
		let firstNonOccupiedIndex: Nullable<number> = null;
		let lastNonOccupiedIndex: Nullable<number> = null;

		for (let i = 0; i < entities.length; i++) {
			if (entities[i].type !== TimelineEntityType.OCCUPIED) {
				firstNonOccupiedIndex = i;
				break;
			}
		}

		for (let i = entities.length - 1; i >= 0; i--) {
			if (entities[i].type !== TimelineEntityType.OCCUPIED) {
				lastNonOccupiedIndex = i;
				break;
			}
		}

		// should always find atleast one non occupied slot due to the nature of getTimelineEntities function
		if (firstNonOccupiedIndex !== null && lastNonOccupiedIndex !== null) {
			return entities.slice(firstNonOccupiedIndex, lastNonOccupiedIndex + 1);
		}

		return entities;
	};

	private static _offsetTimelineEntries(entries: TimeSheetEntriesForTimelineVM, offset: number): TimeSheetEntriesForTimelineVM {
		const offsetEntries: TimeSheetEntriesForTimelineVM['timelineEntitiesAndOverlaps'] = Object.keys(entries.timelineEntitiesAndOverlaps)
			.reduce<TimeSheetEntriesForTimelineVM['timelineEntitiesAndOverlaps']>((_acc, _accountId) => {
				const entity: Nullable<TimeSheetEntriesForTimelineVM['timelineEntitiesAndOverlaps'][number]> = entries.timelineEntitiesAndOverlaps[_accountId];
				if (!entity) {
					throw new Error('Time Sheet Entity not found');
				}

				entity.timelineEntities = entity.timelineEntities.map((_ent) => {
					if (_ent.type === TimelineEntityType.GAP) {
						return _ent;
					}

					return {
						..._ent,
						entry: {
							..._ent.entry,
							startTime: _ent.entry.startTime ? TimeUtils.offsetTime(_ent.entry.startTime, offset, 'minutes', TimeFormat.ISO_DATETIME) : null,
							endTime: _ent.entry.endTime ? TimeUtils.offsetTime(_ent.entry.endTime, offset, 'minutes', TimeFormat.ISO_DATETIME) : null,
						},
					} as TimelineEntitesForAccount;
				});
				_acc[_accountId] = entity;
				return _acc;
			}, {});

		return {
			timelineEntitiesAndOverlaps: offsetEntries,
		};
	}

	/**
	 * Revert all time zone offsetting done by `offsetTimelineEntriesForTimeZone`
	 * Called only on FE when updating how items are shown on the timeline with stale data
	 */
	static revertOffsetTimelineEntriesForTimeZone(entries: TimeSheetEntriesForTimelineVM, timeZone: Nullable<string>): TimeSheetEntriesForTimelineVM {
		if (!timeZone) {
			return entries;
		}

		const browserOffset = (new Date()).getTimezoneOffset();
		const timeZoneOffset = TimeUtils.getOffset(timeZone);

		const timeOffset = -1 * (timeZoneOffset + browserOffset);

		return TimeSheetEntriesForTimelineVM._offsetTimelineEntries(entries, timeOffset);

	}

	/**
	 * Offset times for a time zone difference. Allows data in the timeline to match the rest of the time sheet card
	 * Revert these time zone offsetting by using `revertOffsetTimelineEntriesForTimeZone` on the same dataset
	 */
	static offsetTimelineEntriesForTimeZone(entries: TimeSheetEntriesForTimelineVM, timeZone: Nullable<string>): TimeSheetEntriesForTimelineVM {
		if (!timeZone) {
			return entries;
		}

		const browserOffset = (new Date()).getTimezoneOffset();
		const timeZoneOffset = TimeUtils.getOffset(timeZone);

		const timeOffset = timeZoneOffset + browserOffset;

		return TimeSheetEntriesForTimelineVM._offsetTimelineEntries(entries, timeOffset);
	}
}

class TimeSheetEntryVM implements TimeSheetEntryBaseVM {
	id: number;
	timeSheetId: number;
	isInActiveShift: boolean;
	type: TimeSheetEntryType;
	workType: TimeSheetEntryWorkType;
	/** ISO Date string */
	startTime: string;
	/** ISO Date string */
	endTime: Nullable<string>;
	equipmentId: Nullable<number>;
	/** ISO Date string */
	createdAt: string;
	isSigned: boolean;
	timeSheetApprovalStatus?: TimeSheetApprovalStatus;

	workOrderCode: Nullable<string>;
	belongsToOtherSheet: boolean;

	constructor(entry: TimeSheetEntry, timeSheetId?: number) {
		this.id = entry.id;
		this.timeSheetId = entry.timeSheetId;
		this.isInActiveShift = entry.isInActiveShift;
		this.type = entry.type;
		this.workType = entry.workType;
		// startTime and endTime are formatted in virtual getter
		this.startTime = entry.startTime;
		this.endTime = entry.endTime ?? null;
		this.equipmentId = entry.equipmentId;
		this.createdAt = entry.startTime;
		this.isSigned = !!entry.timeSheet?.signatureId;
		if (entry.timeSheet?.approvalStatus) {
			this.timeSheetApprovalStatus = mapTimeSheetApprovalStatus(entry.timeSheet?.approvalStatus);
		}

		this.workOrderCode = entry.timeSheet?.workOrder ? workOrderCode(entry.timeSheet.workOrder, entry.timeSheet.workOrder.workRequest) : null;
		this.belongsToOtherSheet = !timeSheetId || timeSheetId !== entry.timeSheetId;
	}

	static bulkConstructor = (entries: TimeSheetEntry[], timeSheetId?: number) => {
		return entries.map((_entry) => new TimeSheetEntryVM(_entry, timeSheetId));
	};
}

export interface TimeSheetAndSplitEntriesForAccountVM {
	timeSheetEntries: TimeSheetEntryVM[];
	timeSplitEntries: TimeSplitEntryVM[];
	timeAllocationEntries: TimeAllocationEntryVM[];
}

export default TimeSheetEntryVM;
