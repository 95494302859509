import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { BlockValueType } from 'acceligent-shared/utils/fieldReport';

import { RootState } from 'af-reducers';

import Block from './Block';
import { RequestQueue } from '../../helpers';

interface OwnProps {
	instanceIndex: number;
	index: number;
	fieldReportTypeId: number;
	isPreview: boolean;
	segmentId: string;
	showInstanceCount: boolean;
	showSegmentCount: boolean;
	secondaryCount: number;
	highlightedBlockId: Nullable<string>;
	change: (fieldName: string, value: BlockValueType) => void;
	toggleSegment: () => void;
	lock: (blockId: string) => void;
	unlock: () => void;
	forceUnlock: (blockId: string) => void;
	fieldReportId: number;
	focusedBlockId: Nullable<number>;
	setFieldToFocus: (ref: HTMLDivElement) => void;
	removeFocusedField: () => void;
	requestQueue: RequestQueue;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	showFullSegment: boolean;
	hiddenBlockMap: { [fieldReportBlockId: string]: true; };
}

class Segment extends React.PureComponent<Props, State> {
	state: State = {
		showFullSegment: true,
		hiddenBlockMap: {},
	};

	static reduceSegmentBlocks(map: { [blockId: string]: true; }, blockId: string) {
		map[blockId] = true;
		return map;
	}

	toggleSegment = (isPrimary: boolean) => {
		const { toggleSegment } = this.props;

		if (isPrimary) {
			toggleSegment();
		}
		this.setState((state) => ({ showFullSegment: !state.showFullSegment }));
	};

	hide = (blockId: string, isMain: boolean) => {
		const { segment } = this.props;
		this.setState((state) => {
			const hiddenBlocks = Object.keys(state.hiddenBlockMap);
			if (isMain && hiddenBlocks.length) {
				return { hiddenBlockMap: {} };
			} else if (isMain) {
				const blocks = [...segment.blocks];
				blocks.splice(0, 1);
				return { hiddenBlockMap: segment.blocks.reduce(Segment.reduceSegmentBlocks, {}) };
			} else if (state.hiddenBlockMap[blockId]) {
				const index = hiddenBlocks.indexOf(blockId);
				hiddenBlocks.splice(index, 1);
				return { hiddenBlockMap: hiddenBlocks.reduce(Segment.reduceSegmentBlocks, {}) };
			}
			return { hiddenBlockMap: { ...state.hiddenBlockMap, [blockId]: true } };
		});
	};

	renderBlock = (blockId: string, key: number) => {
		const {
			showInstanceCount,
			showSegmentCount,
			secondaryCount,
			segment,
			fieldReportTypeId,
			change,
			lock,
			unlock,
			forceUnlock,
			highlightedBlockId,
			isPreview,
			index,
			instanceIndex,
			fieldReportId,
			focusedBlockId,
			setFieldToFocus,
			removeFocusedField,
			requestQueue,
		} = this.props;
		const { showFullSegment, hiddenBlockMap } = this.state;

		if (!showFullSegment && key > 0) {
			return null;
		}

		const hidden = (key > 0 && hiddenBlockMap[blockId]) || Object.keys(hiddenBlockMap).length === segment.blocks.length - 1;

		return (
			<Block
				blockId={blockId}
				change={change}
				fieldReportId={fieldReportId}
				fieldReportTypeId={fieldReportTypeId}
				focusedBlockId={focusedBlockId}
				forceUnlock={forceUnlock}
				hidden={hidden}
				hideBlock={this.hide}
				highlightedBlockId={highlightedBlockId}
				instanceIndex={instanceIndex}
				isPreview={isPreview}
				key={key}
				lock={lock}
				removable={key === 0}
				removeFocusedField={removeFocusedField}
				requestQueue={requestQueue}
				secondaryCount={secondaryCount}
				segmentIndex={index}
				setFieldToFocus={setFieldToFocus}
				showInstanceCount={showInstanceCount}
				showSegmentCount={showSegmentCount}
				toggleSegment={this.toggleSegment}
				unlock={unlock}
			/>
		);
	};

	render() {
		const { segment } = this.props;
		if (!segment) {
			return null;
		}

		const { blocks } = segment;
		return (
			<div className="field-report-type__segment">
				<div className="field-report-type__segment__blocks-container">
					{blocks.map(this.renderBlock)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { fieldReport: { fieldReport } } = state;
	const { segmentId } = ownProps;

	if (!fieldReport) {
		throw new Error('fieldReport is required in this context');
	}

	const segment = fieldReport.segmentMap[segmentId];
	if (!segment) {
		throw new Error('segment not found');
	}
	return { segment };
}

const connector = connect(mapStateToProps);

export default connector(Segment);
