import * as React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import CLIENT from 'af-constants/routes/client';
import { EQUIPMENT_COST_TREE_FILTER } from 'af-constants/values';

import Tooltip from 'af-components/Tooltip';

import Input from 'af-fields/Input';

interface Props {
	showSmallButtons: boolean;
	showToolbarHeaders: boolean;
	incrementHighlightedNodeIndex: () => void;
	decrementHighlightedNodeIndex: () => void;
	isAllowedToCreate: boolean;
	dataFiltered?: string;
	collapseAll: () => void;
	expandAll: () => void;
	onClear: () => void;
	onFilter: (query: string) => void;
	isAnyExpanded: boolean;
	companyName: string;
	orgAlias: string;
}

class TreeHeader extends React.PureComponent<Props> {

	onClear = () => {
		const { onClear } = this.props;
		onClear();
	};

	onFilter = (query: string) => {
		const { onFilter } = this.props;
		onFilter(query);
	};

	collapseAll = () => this.props.collapseAll();

	expandAll = () => this.props.expandAll();

	renderTableHeaders = () => {
		const { showSmallButtons, isAnyExpanded } = this.props;

		return (
			<Row className="tree-table__header-titles">
				<Col md={1}>
					{isAnyExpanded
						? <span className="tree-table__expander icon-collapse" onClick={this.collapseAll} />
						: <span className="tree-table__expander icon-expand" onClick={this.expandAll} />
					}
				</Col>
				<Col md={7}>
					Name
				</Col>
				<Col md={5}>
					{showSmallButtons ? 'Skills' : 'Needed Skills'}
				</Col>
				<Col md={2}>
					{showSmallButtons ? '$/Day' : 'Daily Cost'}
				</Col>
				<Col md={2}>
					{showSmallButtons ? '$/Week' : 'Weekly Cost'}
				</Col>
				<Col md={2}>
					{showSmallButtons ? '$/Month' : 'Monthly Cost'}
				</Col>
				<Col md={2}>
					Mob Charge
				</Col>
				<Col md={2}>
					{showSmallButtons ? 'Op hr Charge' : 'Op Hour Charge'}
				</Col>
				<Col md={2}>
					{showSmallButtons ? 'Fuel' : 'Fuel Cost'}
				</Col>
				<Col className="pull-right" md={4}>
					Updated
				</Col>
				<Col md={1} />
			</Row>
		);
	};

	render() {
		const {
			incrementHighlightedNodeIndex,
			decrementHighlightedNodeIndex,
			dataFiltered,
			orgAlias,
			companyName,
			isAllowedToCreate,
			showSmallButtons,
			showToolbarHeaders,
		} = this.props;

		const idAndClassName = 'tree-table__header';

		return (
			<div
				className={idAndClassName}
				id={idAndClassName}
			>
				<div className="tree-table__header-container">
					<div className="tree-table__header-left">
						<div className="tree-table__match-filter">
							<Field
								component={Input}
								dataFiltered={dataFiltered}
								id={EQUIPMENT_COST_TREE_FILTER}
								inputGroupClassName="tree-table__input-filter"
								isStandalone={true}
								name="filter"
								onValueChange={this.onFilter}
								placeholder={showToolbarHeaders ? 'Search Equipment Costs' : 'Search'}
								type="text"
							/>
							{!!dataFiltered &&
								<div className="tree-table__match">
									<Tooltip message="Previous">
										<span className="icon-left" onClick={decrementHighlightedNodeIndex} />
									</Tooltip>
									<span>{dataFiltered}</span>
									<Tooltip message="Next">
										<span className="icon-right" onClick={incrementHighlightedNodeIndex} />
									</Tooltip>
								</div>
							}
							{
								!!dataFiltered ?
									<Tooltip message="Close">
										<span
											className="icon-close tree-table__filter-icon tree-table__filter-icon--clear"
											onClick={this.onClear}
										/>
									</Tooltip> :
									<Tooltip message="Search"><span className="icon-search tree-table__filter-icon" /></Tooltip>
							}
						</div>
					</div>
					<div className="tree-table__header-right">
						{
							isAllowedToCreate &&
							<>
								<Tooltip message="CSV Import">
									<Link
										className={`btn btn--link ${showSmallButtons ? 'btn--icon' : ''}`}
										to={CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.BULK_UPLOAD(orgAlias, companyName)}
									>
										<span className="icon-upload" />
										{!showSmallButtons && 'CSV Import'}
									</Link>
								</Tooltip>
								<Tooltip message="New Equipment Cost">
									<Link
										className={`btn btn-primary ${showSmallButtons ? 'btn--icon' : ''}`}
										to={CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.CREATE(orgAlias, companyName)}
									>
										{showSmallButtons ? <span className="icon-plus" /> : 'New Equipment Cost'}
									</Link>
								</Tooltip>
							</>
						}
					</div>
				</div>
				{showToolbarHeaders && this.renderTableHeaders()}
			</div>
		);
	}
}

export default TreeHeader;
