import * as React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'af-reducers';

import ScheduleBoardTemporaryEmployeeViewModel from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';

import * as scheduleBoardActions from 'af-actions/scheduleBoard/scheduleBoard.actions';

import MultiAssignmentsBadge from 'af-root/scenes/Company/ScheduleBoard/Shared/MultiAssignmentsBadge';
import CopyProgressBar from 'af-root/scenes/Company/ScheduleBoard/Shared/CopyProgressBar';
import PressAndHoldAnchor from 'af-root/scenes/Company/ScheduleBoard/Shared/PressAndHoldAnchor';

import { generateTemporaryEmployeeSearchItemId, generateWorkOrderTemporaryEmployeeSearchItemId } from 'af-utils/scheduleBoard.util';

import { bemElement } from 'ab-utils/bem.util';
import * as ScheduleBoardUtils from 'ab-utils/scheduleBoard.util';

import EmployeeNotificationStatus from 'ab-enums/employeeNotificationStatus.enum';

import NightShiftBadge from '../NightShiftBadge';

import TemporaryEmployeeDraggableBadgesContainer from './BadgesContainer';
import DraggableItemBody from './DraggableItemBody';

interface OwnProps extends DraggableProvided {
	/** `MM-DD-YYYY` */
	dueDate: string;
	temporaryEmployeeId: number;
	isDragging: boolean;
	isWorkOrderCanceled?: boolean;
	onClick: () => void;
	isDragAndDropDisabled: boolean;
	resourceId: number;
	isCardDisabled: boolean;
	workOrderCode?: string;
	isDropAnimating?: boolean;
	draggingOver?: string;
	isWOLocked: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	draggableItemClassName: string;
}

class Draggable extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		temporaryEmployee: {} as ScheduleBoardTemporaryEmployeeViewModel,
		assignmentCount: 0,
	};

	state: State = {
		draggableItemClassName: '',
	};

	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		const {
			isDisabled,
			isWorkOrderCanceled,
			isActive,
			temporaryEmployee: { isMatched, isFilteredOnBoard } = {} as ScheduleBoardTemporaryEmployeeViewModel,
			isDropAnimating,
			draggingOver,
		} = nextProps;

		let draggableItemClassName = 'sb-resource-item sb-resource-item--employee';
		if (isDropAnimating && !draggingOver) { // FIXME: animation is still rendering when dropping item in toolbar
			draggableItemClassName += ' sb-resource-item--hidden';
		} else {
			draggableItemClassName += isDisabled && !isWorkOrderCanceled ? ' sb-resource-item--disabled' : '';
			draggableItemClassName += isMatched ? ' sb-resource-item--highlighted' : '';
			draggableItemClassName += isActive ? ' sb-resource-item--active' : '';
			draggableItemClassName += isFilteredOnBoard ? ' filtered' : '';
			draggableItemClassName = draggableItemClassName.trim();
		}

		return prevState.draggableItemClassName !== draggableItemClassName ? {
			draggableItemClassName,
		} : null;
	}

	onHoldEnd = () => {
		const { setCopiedTemporaryEmployeeId, temporaryEmployee } = this.props;
		if (!temporaryEmployee) {
			throw new Error('Temp employee not defined');
		}
		setCopiedTemporaryEmployeeId(temporaryEmployee.id);
	};

	onRelease = () => {
		const { setCopiedTemporaryEmployeeId, isDragging } = this.props;
		if (!isDragging) {
			setCopiedTemporaryEmployeeId(null);
		}
	};

	renderEmployeeDraggable = (isCopying: boolean, copyFinished: boolean) => {
		const {
			temporaryEmployee,
			isWorkOrderCanceled,
			isDragAndDropDisabled,
		} = this.props;

		if (!temporaryEmployee) {
			return null;
		}

		return (
			<>
				<div className={bemElement('sb-resource-item', 'content', ['justify-start'])}>
					<DraggableItemBody
						employee={temporaryEmployee}
						isDragAndDropDisabled={isDragAndDropDisabled}
					/>
				</div>
				{!isWorkOrderCanceled &&
					<CopyProgressBar
						copyFinished={copyFinished}
						isCopying={isCopying}
					/>
				}
			</>
		);
	};

	render() {
		const {
			draggableProps,
			dragHandleProps,
			innerRef,
			onClick,
			temporaryEmployee,
			assignmentCount,
			isDragging,
			copiedTemporaryEmployeeId,
			searchId,
			isWorkOrderCanceled,
			isDragAndDropDisabled,
			isDailyView,
			isToolbarShown,
			isDeletedResource,
			wasInNightShift,
			isWOLocked,
			notificationStatus,
		} = this.props;
		if (!temporaryEmployee) {
			throw new Error('Temp employee not defined');
		}

		const { id: temporaryEmployeeId } = temporaryEmployee;
		const { draggableItemClassName } = this.state;

		if (isDeletedResource) {
			return null;
		}

		const useRegularAnchor = (isDragAndDropDisabled && !isWOLocked) || (!isDailyView && !isToolbarShown);
		const showMultiAssignmentsBadge = !isWorkOrderCanceled && assignmentCount > 1;
		const isDraggingCopiedEmployee = isDragging && copiedTemporaryEmployeeId && copiedTemporaryEmployeeId === temporaryEmployeeId;

		return (
			<>
				<div
					id={searchId ?? undefined}
					ref={innerRef}
					{...draggableProps}
					{...dragHandleProps}
					className={draggableItemClassName}
				>
					{notificationStatus && <div className={`icon-notification_status ${notificationStatus.toLowerCase()}`} />}
					<PressAndHoldAnchor
						onClick={onClick}
						onHoldEnd={this.onHoldEnd}
						onRelease={this.onRelease}
						regularAnchor={useRegularAnchor}
					>
						{this.renderEmployeeDraggable}
					</PressAndHoldAnchor>
					<TemporaryEmployeeDraggableBadgesContainer>
						{showMultiAssignmentsBadge && <MultiAssignmentsBadge count={assignmentCount} disableAutoPositioning />}
						{wasInNightShift && <NightShiftBadge />}
					</TemporaryEmployeeDraggableBadgesContainer>
				</div>
				{isDraggingCopiedEmployee && (
					<div className={draggableItemClassName}>
						{notificationStatus && <div className={`icon-notification_status ${notificationStatus.toLowerCase()}`} />}
						{this.renderEmployeeDraggable(false, false)}
						{showMultiAssignmentsBadge && <MultiAssignmentsBadge count={assignmentCount} />}
					</div>
				)}
			</>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const {
		temporaryEmployees,
		draggedTemporaryEmployeeId,
		workOrdersByDateDictionary,
		copiedTemporaryEmployeeId,
		dates,
		weeklyViewDateWithToolbar,
		activeSearchItemIndex,
		searchResultItems,
	} = state.scheduleBoard;
	const { dueDate, isWorkOrderCanceled, resourceId, temporaryEmployeeId, isCardDisabled } = ownProps;

	const temporaryEmployee = temporaryEmployees?.[temporaryEmployeeId];

	let searchId: Nullable<string> = null;
	let isDeletedResource = false;
	let notificationStatus: EmployeeNotificationStatus | undefined = undefined;

	const nightShiftAssignments = workOrdersByDateDictionary?.[dueDate]?.temporaryEmployeeNightShiftAssignments;

	if (temporaryEmployee) {
		const dataOnDate = workOrdersByDateDictionary?.[dueDate];
		const _resources = isWorkOrderCanceled ? dataOnDate?.canceledWorkOrderResourceLookups : dataOnDate?.workOrderResourceLookups;
		const _notificationStatusMap = isWorkOrderCanceled
			? dataOnDate?.assignedCanceledTempLaborNotificationStatuses
			: dataOnDate?.assignedPublishedTempLaborNotificationStatuses;

		const workOrderTemporaryEmployeeId = _resources?.[resourceId]?.workOrderTemporaryEmployeeId;
		if (workOrderTemporaryEmployeeId) {
			searchId = generateWorkOrderTemporaryEmployeeSearchItemId(workOrderTemporaryEmployeeId.toString());

			const status = _notificationStatusMap?.[workOrderTemporaryEmployeeId] ?? null;
			notificationStatus = ScheduleBoardUtils.getEmployeeNotificationStatus(
				status?.emailStatus ?? null,
				status?.smsStatus ?? null,
				status?.emailSentAt ?? null,
				status?.smsSentAt ?? null,
				!!status?.isPreviousRevision
			);
		} else {
			isDeletedResource = true;
		}
	} else if (temporaryEmployeeId) {
		searchId = generateTemporaryEmployeeSearchItemId(temporaryEmployeeId.toString());
	}

	return {
		temporaryEmployee,
		searchId,
		isActive: searchResultItems[activeSearchItemIndex] === searchId,
		copiedTemporaryEmployeeId,
		isDisabled: (!isCardDisabled && draggedTemporaryEmployeeId === temporaryEmployee?.id),
		assignmentCount: (workOrdersByDateDictionary[dueDate].temporaryEmployeeAssignments[temporaryEmployeeId] || []).length,
		isDailyView: dates.length === 1,
		isToolbarShown: weeklyViewDateWithToolbar === dueDate,
		isDeletedResource,
		wasInNightShift: temporaryEmployee?.id !== undefined && !!nightShiftAssignments?.[temporaryEmployee?.id],
		notificationStatus,
	};
}

function mapDispatchToProps() {
	return {
		setCopiedTemporaryEmployeeId: scheduleBoardActions.setCopiedTemporaryEmployeeId,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Draggable);
