import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import CopyWorkOrderRM from 'ab-requestModels/workOrder/copyWorkOrders.requestModel';

import CopyWorkOrderVM from 'ab-viewModels/workOrder/copyWorkOrders.viewModel';

export const fromVMtoRM = (vm: CopyWorkOrderVM): CopyWorkOrderRM => {
	return {
		orderId: vm.orderId,
		code: vm.code,
		startDate: TimeUtils.formatDate(vm.startDate, TimeFormat.DB_DATE_ONLY),
		endDate: vm.endDate ? TimeUtils.formatDate(vm.endDate, TimeFormat.DB_DATE_ONLY) : TimeUtils.getNextDayDate(vm.startDate, TimeFormat.DB_DATE_ONLY),
		copyDate: TimeUtils.formatDate(vm.copyDate, TimeFormat.DB_DATE_ONLY),
		shouldPreserveIndex: true,
		intervalSelection: false,
		monday: !!vm.monday,
		tuesday: !!vm.tuesday,
		wednesday: !!vm.wednesday,
		thursday: !!vm.thursday,
		friday: !!vm.friday,
		saturday: !!vm.saturday,
		sunday: !!vm.sunday,
		shouldCopyAttachments: false,
		selectedOrdersIds: vm.selectedOrdersIds,
	};
};
