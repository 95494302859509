import * as React from 'react';

import { TimeSheetInternalApprovalStatus } from 'acceligent-shared/enums/timeSheetApprovalStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { CUSTOMER_SERVICE_NAME_SHORT } from 'acceligent-shared/constants/value';

import Tooltip from 'af-components/Tooltip';
import ImageTag from 'af-components/Image';

import { bemBlock } from 'ab-utils/bem.util';

interface Props {
	status: TimeSheetInternalApprovalStatus;
	approvedAt: Nullable<string>;
	approvedBy: Nullable<string>;
	approvalSignature: Nullable<string>;
	rejectedBy: Nullable<string>;
	rejectedAt: Nullable<string>;
	rejectReason: Nullable<string>;
}

const ReviewCell: React.FC<Props> = (props) => {
	const {
		status,
		approvedAt,
		approvedBy,
		approvalSignature,
		rejectedBy,
		rejectedAt,
		rejectReason,
	} = props;

	const renderApprovedTooltip = React.useMemo(() => {
		return (
			<div className="review-status-tooltip">
				<ImageTag className="review-status-tooltip__image" src={approvalSignature} />
				<div className="review-status-tooltip__info">
					{!!approvedAt && <span className="review-status-tooltip__date">{TimeUtils.formatDate(approvedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}</span>}
					<span className="review-status-tooltip__name">Signed by {approvedBy}</span>
				</div>
			</div>
		);
	}, [approvalSignature, approvedAt, approvedBy]);

	const labelClassName = React.useMemo(() => {
		return bemBlock('condensed-table__employee-list-employee__review-status', {
			approved: status === TimeSheetInternalApprovalStatus.APPROVED,
			rejected: status === TimeSheetInternalApprovalStatus.REJECTED,
			pending: !status || status === TimeSheetInternalApprovalStatus.PENDING || status === TimeSheetInternalApprovalStatus.MODIFIED_REJECTED,
		});
	}, [status]);

	const renderRejectedTooltip = React.useMemo(() => {
		return (
			<div className="review-status-tooltip__info">
				{!!rejectedAt && <span className="review-status-tooltip__date">{TimeUtils.formatDate(rejectedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}</span>}
				<span>{rejectReason}</span>
				<span className="review-status-tooltip__name">Rejected by {rejectedBy ?? CUSTOMER_SERVICE_NAME_SHORT}</span>
			</div>
		);
	}, [rejectedAt, rejectedBy, rejectReason]);

	switch (status) {
		case TimeSheetInternalApprovalStatus.APPROVED:
			return (
				<Tooltip className="approved-tooltip" message={renderApprovedTooltip}>
					<div className={labelClassName}>Approved</div>
				</Tooltip>
			);
		case TimeSheetInternalApprovalStatus.REJECTED:
			return (
				<Tooltip message={renderRejectedTooltip}>
					<div className={labelClassName}>Rejected</div>
				</Tooltip>
			);
		case TimeSheetInternalApprovalStatus.PENDING:
		case TimeSheetInternalApprovalStatus.MODIFIED_REJECTED:
		default:
			return <div className={labelClassName}>Pending Approval</div>;
	}
};

export default React.memo(ReviewCell);
