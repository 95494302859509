import * as React from 'react';

const UserCell = ({ original: { fullName, uniqueId } }: { original: { fullName: string; uniqueId: string; }; }) => (
	<div>
		<div className="cell-main-info">{fullName}</div>
		<div className="cell-additional-info">ID: {uniqueId}</div>
	</div>
);

export default UserCell;
