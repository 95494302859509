import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';

import JobContact from 'af-components/SharedForms/Contacts/ContactWrapper';

import { WORK_REQUEST_FORM } from 'af-constants/reduxForms';

import WorkRequestUpsertFM from '../../../formModel';
import { formSectionClassColumn8, formSectionClassRow } from '../../../helpers';

import styles from '../../../styles.module.scss';

type Props = WrappedFieldArrayProps<WorkRequestUpsertFM['additionalContacts']>;

const AdditionalContacts: React.FC<Props> = ({ fields }) => {
	const onAddClick = React.useCallback(() => {
		fields.push(null);
	}, [fields]);

	const onRemoveClick = React.useCallback((index: number) => () => fields.remove(index), [fields]);

	const renderAdditionalContact = React.useCallback((_name: string, _index: number) => {
		const additionalContactName = `Additional Contact ${_index + 1}`;
		return (
			<React.Fragment key={_name}>
				<div className={formSectionClassRow}>
					{additionalContactName}
				</div>
				<div className={formSectionClassRow} key={_name}>
					<div className={[formSectionClassColumn8, styles['work-request-form__additional-contact-container'], styles['work-request-form__delete-button']].join(' ')}>
						<JobContact
							className={[styles['work-request-form__additional-contact']].join(' ')}
							contactLabel="Enter Additional Contact"
							fieldName={_name}
							formName={WORK_REQUEST_FORM}
						/>
						<span className="icon-delete" onClick={onRemoveClick(_index)} />
					</div>
				</div>
			</React.Fragment>
		);
	}, [onRemoveClick]);

	return (
		<>
			{fields.map(renderAdditionalContact)}
			<div
				className={styles['work-request-form__add-field-button']}
				onClick={onAddClick}
			>
				<span className="icon-plus" />
				<span>
					Add Contact
				</span>
			</div>
		</>
	);
};

export default AdditionalContacts;
