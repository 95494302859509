import * as React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot, DraggableElement } from 'react-beautiful-dnd';
import { connect, ResolveThunks } from 'react-redux';

import { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { EMPLOYEE_PLACEHOLDER } from 'ab-constants/scheduleBoard';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import DraggableEmployee from './Draggable';
import DraggableEmployeeWithReason from './DraggableWithReason';
import DraggableLoadingPlaceholder from 'af-root/scenes/Company/ScheduleBoard/Shared/DraggableLoadingPlaceholder';

import { EmployeeModalProps } from '../../Shared/ModalProps';

interface OwnProps extends EmployeeModalProps {
	employeeId: number;
	draggableId: string;
	index: number;
	droppableId: string;
	isToolbar: boolean;
	dueDate: string;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	available?: boolean;
	isCardDisabled?: boolean;
	isCalculationsView?: boolean;
	hasReason?: boolean;
	resourceId?: number;
	workOrderEmployeeId?: number;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	isPerDiem?: boolean;
	workOrderCode?: string;
	isWOLocked: boolean;
}

interface StateProps {
	isDisabled: boolean;
}

interface DispatchProps {
	findToolbarEmployeeById: typeof ScheduleBoardActions.findToolbarEmployeeById;
	findWorkOrderEmployeeById: typeof ScheduleBoardActions.findWorkOrderEmployeeById;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface DraggableProps extends Props {
	provided?: DraggableProvided;
	snapshot?: DraggableStateSnapshot;
}

interface State {
	isModalOpenedForCurrentEmployee: boolean;
}

class EmployeeDraggable extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		isCalculationsView: false,
	};

	state: State = {
		isModalOpenedForCurrentEmployee: false,
	};

	componentDidUpdate(prevProps: Props) {
		const { employeeId, isDisabled } = this.props;
		const { setEmployeeModalData, isDisabled: wasDisabled, employeeId: prevEmployeeId } = prevProps;
		const { isModalOpenedForCurrentEmployee } = this.state;

		if (isModalOpenedForCurrentEmployee && wasDisabled && !isDisabled && employeeId === prevEmployeeId) {
			setEmployeeModalData(null, null);
			this.setState(() => ({ isModalOpenedForCurrentEmployee: false }));
			this.fetchModalData();
		}
	}

	fetchModalData = async () => {
		const {
			setEmployeeModalData,
			setEmployeeModalVisibility,
			findToolbarEmployeeById,
			findWorkOrderEmployeeById,
			isToolbar,
			employeeId,
			workOrderEmployeeId,
			dueDate,
		} = this.props;

		const employee = isToolbar
			? await findToolbarEmployeeById(employeeId, dueDate)
			: await findWorkOrderEmployeeById(workOrderEmployeeId!, dueDate);

		setEmployeeModalData(employee, dueDate);
		setEmployeeModalVisibility(true);
		this.setState(() => ({ isModalOpenedForCurrentEmployee: true }));
	};

	openModal = () => {
		const { isCalculationsView } = this.props;
		if (isCalculationsView) {
			return;
		}
		this.fetchModalData();
	};

	renderDraggable = (props: DraggableProps) => {
		const {
			isCardDisabled,
			resourceId,
			employeeId,
			isToolbar,
			dueDate,
			isWorkOrderCanceled,
			hasReason,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			workOrderCode,
			isPerDiem,
			provided = {} as DraggableProvided,
			snapshot = {} as DraggableStateSnapshot,
			isWOLocked,
		} = props;

		if (hasReason) {
			return (
				<DraggableEmployeeWithReason
					draggableProps={provided.draggableProps}
					dragHandleProps={provided.dragHandleProps}
					dueDate={dueDate}
					employeeId={employeeId}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					innerRef={provided.innerRef}
					isDragAndDropDisabled={isDragAndDropDisabled}
					isDragging={snapshot.isDragging}
					isPerDiem={isPerDiem}
					isToolbar={isToolbar}
					onClick={this.openModal}
				/>
			);
		}

		return (
			<DraggableEmployee
				draggableProps={provided.draggableProps}
				draggingOver={snapshot.draggingOver}
				dragHandleProps={provided.dragHandleProps}
				dueDate={dueDate}
				employeeId={employeeId}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				innerRef={provided.innerRef}
				isCardDisabled={!!isCardDisabled}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isDragging={snapshot.isDragging}
				isDropAnimating={snapshot.isDropAnimating}
				isPerDiem={isPerDiem}
				isToolbar={isToolbar}
				isWOLocked={isWOLocked}
				isWorkOrderCanceled={isWorkOrderCanceled}
				onClick={this.openModal}
				resourceId={resourceId}
				workOrderCode={workOrderCode}
			/>
		);
	};

	render() {
		const {
			draggableId,
			index,
			isDisabled,
			employeeId,
			isWorkOrderCanceled,
			isCopyPlaceholder,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
		} = this.props;

		// loading indicator when assigning employee from toolbar
		if (employeeId === EMPLOYEE_PLACEHOLDER) {
			return <DraggableLoadingPlaceholder />;
		}

		// react-beautiful-dnd can not create Draggable and Droppable elements/
		// while we are dragging so in order to mimic the copied card, we render
		// div instead of a Draggable (same for Droppable in parent component)
		if (isCopyPlaceholder || !hasPermissionsToEditScheduleBoard || isDragAndDropDisabled) {
			return (
				<div>
					{this.renderDraggable(this.props)}
				</div>
			);
		}

		return (
			<Draggable
				draggableId={draggableId}
				index={index}
				isDragDisabled={isDisabled || isWorkOrderCanceled}
			>
				{(provided, snapshot) => (
					React.createElement(this.renderDraggable, { ...this.props, provided, snapshot }, null) as DraggableElement<DraggableProps>
				)}
			</Draggable>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { employeeId, isToolbar, isCardDisabled, resourceId, dueDate } = ownProps;
	const {
		employees,
		draggedResourceId,
		draggedEmployeeId,
		weeklyViewDateWithToolbar,
		scheduleBoardView,
	} = state.scheduleBoard;

	let isDisabled: boolean = false;

	const employee = employees?.[employeeId];
	if (!isToolbar) {
		const disableDraggingWhenToolbarOpened = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW &&
			(!weeklyViewDateWithToolbar || weeklyViewDateWithToolbar !== dueDate);
		isDisabled = !!isCardDisabled ||
			!!disableDraggingWhenToolbarOpened ||
			!!(employee?.isDisabled && resourceId !== draggedResourceId);
	} else {
		// if employee is disabled and `draggedEmployeeId` is not set => it's dragged by another user or in another tab
		isDisabled = !!employee?.isDisabled && !draggedEmployeeId;
	}

	return {
		isDisabled,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findToolbarEmployeeById: ScheduleBoardActions.findToolbarEmployeeById,
		findWorkOrderEmployeeById: ScheduleBoardActions.findWorkOrderEmployeeById,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(EmployeeDraggable);
