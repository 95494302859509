import * as React from 'react';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { TimeSheetVM } from 'acceligent-shared/dtos/web/view/timeSheet/timeSheet';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import TimelineEntityType from 'acceligent-shared/enums/timelineEntityType';
import TimeSheetEntryWorkType, { TimeSheetWorkTypeDisplayInfo } from 'acceligent-shared/enums/timeSheetEntryWorkType';
import TimeSheetEntryType from 'acceligent-shared/enums/timeSheetEntryType';
import TimeSheetSignatureStatus from 'acceligent-shared/enums/timeSheetSignatureStatus';

import { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';

import Tooltip from 'af-components/Tooltip';

import TimelineTooltip from '../TimelineTooltip';
import TimeSheetApprovalStatus from 'acceligent-shared/enums/timeSheetApprovalStatus';

import styles from './styles.module.scss';

const TIMELINE_SECTION_WIDTH_THRESHOLD = 60; // in pixels

const MINIMAL_TIMELINE_PERCENTAGE = 1;

interface Props {
	entity: TimelineEntitesForAccount;
	percentageOfTimeline: number;
	roundedLeft: boolean;
	roundedRight: boolean;
	overlap: boolean;
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	superintendentApprovalStatus: TimeSheetVM['superintendentApprovalStatus'];
	isManagerOrAdmin: boolean;
}

const TimelineBarSection: React.FC<Props> = (props: Props) => {
	const sectionRef = React.useRef<HTMLDivElement>(null);

	const { percentageOfTimeline, entity, roundedLeft, roundedRight, overlap, employeeApprovalStatus, isManagerOrAdmin, superintendentApprovalStatus } = props;

	const timelinePercentage = React.useMemo(() => Math.max(percentageOfTimeline, MINIMAL_TIMELINE_PERCENTAGE), [percentageOfTimeline]);

	if (entity.type === TimelineEntityType.GAP) {
		return <span className={styles.gap} />;
	}

	const { entry } = entity;

	const sectionBarClassNameArray = ['section'];
	let icon: Nullable<string> = null;
	let workType: Nullable<TimeSheetEntryWorkType> = null;
	let workOrderCode: Nullable<string> = null;
	let isUnsignedManualEntry = false;
	let isOutdated = false;
	let isApprovedBySI = false;

	const endTime = entry.endTime ?? TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME);
	const durationInMinutes = TimeUtils.getDiff(endTime, entry.startTime, 'minutes', TimeFormat.ISO_DATETIME);
	const durationInHoursAndMinutes = TimeUtils.minutesToHoursAndMinutes(durationInMinutes);
	const showIconAndDuration = !!sectionRef.current && sectionRef.current.offsetWidth > TIMELINE_SECTION_WIDTH_THRESHOLD;

	if (entity.type === TimelineEntityType.ENTRY) {
		isUnsignedManualEntry = (
			entity.entry.type === TimeSheetEntryType.MANUAL
			&& employeeApprovalStatus.signatureStatus === TimeSheetSignatureStatus.UNSIGNED
		);
		isOutdated = employeeApprovalStatus.signatureStatus === TimeSheetSignatureStatus.OUTDATED;
		isApprovedBySI = superintendentApprovalStatus.approvalStatus === TimeSheetApprovalStatus.APPROVED;

		sectionBarClassNameArray.push('entry');
		if (roundedLeft) sectionBarClassNameArray.push('rounded-left');
		if (roundedRight) sectionBarClassNameArray.push('rounded-right');
		if (isUnsignedManualEntry || isOutdated) sectionBarClassNameArray.push('unsigned-manual');
		if (isApprovedBySI) sectionBarClassNameArray.push('si-signed');
		if (overlap) sectionBarClassNameArray.push('overlap');

		icon = TimeSheetWorkTypeDisplayInfo[entity.entry.workType].icon;
		workType = entity.entry.workType;
		workOrderCode = null;
	} else {
		sectionBarClassNameArray.push('occupied');
		if (roundedLeft) sectionBarClassNameArray.push('rounded-left');
		if (roundedRight) sectionBarClassNameArray.push('rounded-right');
		if (overlap) sectionBarClassNameArray.push('overlap');

		workType = null;
		workOrderCode = entity.entry.workOrderCode;
	}

	const sectionBarClassName = sectionBarClassNameArray.map((style) => styles[style]).join(' ');

	const sectionContainerClassName = styles.container;

	const iconClassNameArray = ['section__icon'];

	if (isUnsignedManualEntry || isOutdated) iconClassNameArray.push('unsigned-manual');
	if (isApprovedBySI) iconClassNameArray.push('si-signed');
	if (overlap) iconClassNameArray.push('overlap');

	const iconClassName = iconClassNameArray.map((style) => styles[style]).join(' ');

	const durationClassName = styles.section__duration;

	return (
		<div ref={sectionRef} style={{ 'width': `${timelinePercentage}%` }}>
			<Tooltip
				message={
					<TimelineTooltip
						endTime={entry.endTime}
						isManagerOrAdmin={isManagerOrAdmin}
						startTime={entry.startTime}
						workOrderCode={workOrderCode}
						workType={workType}
					/>
				}
			>
				<div className={sectionContainerClassName}>
					<div className={iconClassName}>
						<span className={`icon-${icon}`} />
					</div>
					<span className={sectionBarClassName} />
					<div className={durationClassName}>
						{showIconAndDuration && durationInHoursAndMinutes}
					</div>
				</div>
			</Tooltip>
		</div>
	);
};

export default TimelineBarSection;
