import * as React from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';

import * as CompanyActions from 'af-actions/companies';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import { COMPANY_EHS_EDIT } from 'af-constants/reduxForms';

import Input from 'af-fields/Input';

import { EHSRM } from 'ab-requestModels/company.requestModel';

import { validate } from './validate';

type FormProps = InjectedFormProps<EHSRM, FormOwnProps>;

interface CompanySettingsRouteProps {
	orgAlias: string;
}

interface OwnProps extends CustomRouteComponentProps<CompanySettingsRouteProps> {
	isVisible: boolean;
	toggleEdit: () => void;
	initialValues: FormProps['initialValues'];
}

interface DispatchProps {
	editEHS: typeof CompanyActions.editEHS;
}

type FormOwnProps = OwnProps & ResolveThunks<DispatchProps>;

type Props = FormOwnProps & FormProps;

class EHSEdit extends React.PureComponent<Props> {

	submit = async (form: EHSRM) => {
		const { editEHS, toggleEdit } = this.props;

		await editEHS(form);
		toggleEdit();
	};

	onClose = () => {
		const { toggleEdit, reset } = this.props;
		reset();
		toggleEdit();
	};

	render() {
		const { handleSubmit, isVisible, invalid, submitting } = this.props;

		return (
			<CustomModal
				closeModal={this.onClose}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={this.onClose}
					title="Edit EHS Settings"
				/>
				<CustomModal.Body padding="none">
					<Row className="row--padded">
						<Col md={24}>
							<Field
								component={Input}
								label="Notion Compliance URL"
								name="ehsUrl"
								type="text"
							/>
						</Col>
					</Row>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button onClick={this.onClose} variant="info">Cancel</Button>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(this.submit)}
						reduxFormSubmitting={submitting}
						submitKey={COMPANY_EHS_EDIT}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		editEHS: CompanyActions.editEHS,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps()),
	reduxForm<EHSRM, FormOwnProps>({ form: COMPANY_EHS_EDIT, validate, enableReinitialize: true })
);

export default enhance(EHSEdit);
