import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { compose } from 'redux';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import { DeliverableReviewTypeEnum, DeliverableReviewTypeLabelEnum } from 'acceligent-shared/enums/deliverableReviewType';

import * as FORMS from 'af-constants/reduxForms';

import { DeliverableStatusViewModel } from 'ab-viewModels/deliverableStatusTable.viewModel';
import { DeliverableComment } from 'ab-viewModels/deliverableTable.viewModel';

import DeliverableSubmissionFM from './formModel';

import Textarea from 'af-fields/Textarea';
import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';
import DateInput from 'af-fields/DateInput';

import * as DeliverableStatusActions from 'af-actions/deliverableStatus';

import SubmitButton from 'af-components/SubmitButton';

import DeliverableCommentForm from '../Comment';
import StatusOption from '../Shared/StatusOption';

import { validate } from './validation';

const NOTES_MAX_LENGTH = 750;
const NOTES_NUMBER_OF_LINES = 10;

interface ReviewTypeOption {
	id: DeliverableReviewTypeEnum;
	label: string;
}

interface OwnProps {
	comments: DeliverableComment[];
	createComment: (comment: string) => void;
	isDisabled?: boolean;
	onSubmit: (form: DeliverableSubmissionFM) => void;
	onBack: () => void;
	initialValues?: FormProps['initialValues'];
}

type FormProps = InjectedFormProps<DeliverableSubmissionFM, FormOwnProps>;

interface DispatchProps {
	findAllStatuses: typeof DeliverableStatusActions.findAllDataStatusesForCompany;
}

type FormOwnProps = OwnProps & ResolveThunks<DispatchProps>;

type Props = FormOwnProps & FormProps;

interface State {
	deliverableStatusesForCompany: DeliverableStatusViewModel[];
}

class DeliverableSubmissionForm extends React.PureComponent<Props, State> {
	state: State = {
		deliverableStatusesForCompany: [],
	};

	static _reviewTypeOptions: ReviewTypeOption[] = Object.keys(DeliverableReviewTypeEnum)
		.map((_key: DeliverableReviewTypeEnum) => ({ id: _key, label: DeliverableReviewTypeLabelEnum[_key] }));

	lazyLoadStatuses = async (isLazyLoaded: boolean) => {
		const { findAllStatuses } = this.props;
		if (!isLazyLoaded) {
			const statuses = await findAllStatuses();
			this.setState(() => ({ deliverableStatusesForCompany: statuses }));
		}
	};

	renderStatusOption = (option: DeliverableStatusViewModel) => <StatusOption status={option} />;

	render() {
		const {
			handleSubmit,
			invalid,
			submitting,
			onBack,
			onSubmit,
			isDisabled,
			comments,
			createComment,
		} = this.props;
		const { deliverableStatusesForCompany } = this.state;

		return (
			<Form className="form-box">
				<Row className="row--padded-top">
					<Col sm={8}>
						<Field
							component={Dropdown}
							disabled={isDisabled}
							id="statusId"
							label="Status *"
							name="statusId"
							onLazyLoad={this.lazyLoadStatuses}
							options={deliverableStatusesForCompany}
							propName="status"
							renderMenuItem={this.renderStatusOption}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
					<Col sm={4}>
						<Field
							component={DateInput}
							dateFormat={TimeFormatEnum.DATE_ONLY}
							disabled={isDisabled}
							id="date"
							label="Submission Date"
							name="date"
							originalDateFormat={TimeFormatEnum.DATE_ONLY}
						/>
					</Col>
					<Col sm={4}>
						<Field
							component={Dropdown}
							disabled={isDisabled}
							id="reviewType"
							label="Review Type *"
							labelKey="label"
							name="reviewType"
							options={DeliverableSubmissionForm._reviewTypeOptions}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={8}>
						<Field
							component={Input}
							disabled={isDisabled}
							id="trackingNumber"
							label="Tracking number"
							name="trackingNumber"
							placeholder="Enter tracking number"
							type="text"
						/>
					</Col>
					<Col sm={8}>
						<Field
							component={Input}
							disabled={isDisabled}
							id="trackingLink"
							label="Tracking Link"
							name="trackingLink"
							placeholder="Enter tracking link"
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={16}>
						<Field
							component={Textarea}
							disabled={isDisabled}
							label="Notes"
							maxCharacters={NOTES_MAX_LENGTH}
							name="notes"
							placeholder="Enter Notes"
							rows={NOTES_NUMBER_OF_LINES}
							showMaxCharactersLabel={true}
						/>
					</Col>
				</Row>
				<DeliverableCommentForm comments={comments} disabled={isDisabled} onSubmit={createComment} />
				<Row className="row--submit">
					<a
						className="btn btn-info"
						onClick={onBack}
					>
						Back
					</a>
					<SubmitButton
						disabled={invalid || isDisabled}
						onClick={handleSubmit(onSubmit)}
						reduxFormSubmitting={submitting}
						variant="primary"
						variantDisabled="info"
					/>
				</Row>
			</Form>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllStatuses: DeliverableStatusActions.findAllDataStatusesForCompany,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps()),
	reduxForm<DeliverableSubmissionFM, FormOwnProps>({ form: FORMS.DELIVERABLE_SUBMISSION, validate, enableReinitialize: true })
);
export default enhance(DeliverableSubmissionForm);
