import { ModalStyle } from 'af-components/CustomModal';

interface DividerItem {
	isDivider: true;
}
export function isDividerItem(item: Item): item is DividerItem {
	return !!(item as DividerItem).isDivider;
}

export interface SettingsItem {
	label: string;
	onClick?: (event: React.MouseEvent<unknown>) => Promise<void>;
	action?: ((event: React.MouseEvent<unknown>) => void) | React.EventHandler<React.MouseEvent<unknown>>;
	to?: string;
	disabled?: boolean;
	modalStyle?: ModalStyle;
	hasModal?: boolean;
	modalTitle?: string | null;
	modalBody?: string | JSX.Element | null;
	modalText?: string | null;
	beforeModalOpen?: () => Promise<void> | void;
	onModalClose?: () => void;
}

export type NonCascadeItem = SettingsItem | DividerItem;

interface CascadeItem {
	isCascade: true;
	label: string;
	items: NonCascadeItem[];
}
export function isCascadeItem(item: Item): item is CascadeItem {
	return !!(item as CascadeItem).isCascade;
}

export type Item = NonCascadeItem | CascadeItem;
