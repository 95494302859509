import * as React from 'react';
interface OwnProps {
	logo: Nullable<string>;
	address: string;
	website: Nullable<string>;
	companyName: string;
	customPDFHeader: Nullable<string>;
}

type Props = OwnProps;

const CompanyHeader: React.FC<Props> = (props: Props) => {
	const { logo, address, website, companyName, customPDFHeader } = props;

	const customPDFHeaderStyle = React.useMemo(() => {
		return { backgroundImage: `url("${customPDFHeader}")` };
	}, [customPDFHeader]);

	if (customPDFHeader) {
		return (
			<div className="public-report__company-header-custom" style={customPDFHeaderStyle} />
		);
	}

	return (
		<div className="public-report__company-header">
			{logo
				? <div className="public-report__company-header__logo" style={{ backgroundImage: `url("${logo}")` }} />
				: <div className="public-report__company-header__logo">{companyName.substring(0, 1).toUpperCase()}</div>
			}
			<div className="public-report__company-header__divider" />
			<div className="public-report__company-header__details">
				{website &&
					<div className="public-report__company-header__contacts">
						<div className="public-report__company-header__contact">
							<span className="icon-external public-report__company-header__contact__icon" />
							<div>{website}</div>
						</div>
					</div>
				}
				{address &&
					<div className="public-report__company-header__contact">
						<span className="icon-location_pin public-report__company-header__contact__icon"></span>
						<div>{address}</div>
					</div>
				}
			</div>
		</div>
	);
};

export default React.memo(CompanyHeader);
