import { EMAIL_REGEX } from 'acceligent-shared/constants/regex';

import * as UserRequestModel from 'ab-requestModels/users.requestModel';

export default function validate(values: UserRequestModel.PasswordForgotten) {
	const errors: UserRequestModel.PasswordForgotten = {} as UserRequestModel.PasswordForgotten;

	if (!values.email) {
		errors.email = 'Email required';
	} else if (!EMAIL_REGEX.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	return errors;
}
