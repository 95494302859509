import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import * as LogActions from 'af-actions/log';

import { LogViewModel } from 'ab-viewModels/log.viewModel';

import CLIENT from 'af-constants/routes/client';

import LogPreviewFields from 'af-components/SharedPreviews/Settings/Logs';
import LockedValue from 'af-components/LockedValue';
import Breadcrumbs from 'af-components/Breadcrumbs';
import BackButton from 'af-components/BackButton';

import Loading from './Loading';

interface PathParams {
	logId: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	log: Nullable<LogViewModel>;
}

class LogPreview extends React.PureComponent<Props, State> {
	state: State = {
		log: null,
	};

	async componentDidMount() {
		const { fetch, match: { params: { logId } } } = this.props;
		const log = await fetch(logId);
		this.setState(() => ({ log }));
	}

	render() {
		const { location: { state: { orgAlias } } } = this.props;
		const { log } = this.state;

		if (!log) {
			return <Loading />;
		}

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={[
						{ label: 'Logs', url: CLIENT.ORGANIZATION.SETTINGS.LOGS.TABLE(orgAlias) },
						{ label: 'Preview' },
					]}
				/>
				<div className="form-box">
					<Row className="row--padded-top">
						<Col sm={6}><LockedValue label="Company" value={log.companyName} /></Col>
					</Row>
					<LogPreviewFields log={log} />
					<Row className="row--submit">
						<BackButton />
					</Row>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		fetch: LogActions.findById,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(LogPreview);
