import * as React from 'react';

import { CrewTypeViewModel } from 'ab-viewModels/crewType.viewModel';

import Pill from 'af-components/Pills/Pill';

interface OwnProps {
	crewType: CrewTypeViewModel;
	toEdit: (id: number) => void;
	deleteCrewType: (id: number) => void;
}

type Props = OwnProps;

export default class CrewTypePreview extends React.PureComponent<Props> {

	edit = () => {
		const { crewType, toEdit } = this.props;

		if (!crewType.id) {
			throw new Error('No crew type to edit');
		}
		toEdit(crewType.id);
	};

	deleteCrewType = () => {
		const { crewType, deleteCrewType } = this.props;

		if (!crewType.id) {
			throw new Error('No crew type to deoete');
		}
		deleteCrewType(crewType.id);
	};

	modalBody = (crewType: CrewTypeViewModel) => (
		<>
			You are about to delete crew type <b>{crewType.name}</b>.
			<br />
			Are you sure you want to continue?
		</>
	);

	render() {
		const { crewType } = this.props;

		return (
			<Pill
				color={crewType.color}
				item={crewType}
				labelKey="name"
				modalBody={this.modalBody}
				modalTitle="Delete Crew Type"
				onClick={this.edit}
				removeItem={this.deleteCrewType}
			/>
		);
	}
}
