import * as React from 'react';
import { FieldIterate, WrappedFieldArrayProps } from 'redux-form';

import { MAX_BILLABLE_WORK_INFORMATION_FIELDS } from 'ab-constants/value';

import { BillableWorkInformationFieldFormModel } from './FormModel';
import InformationFieldField from './InformationFieldField';
import { BillableWorkDropdownOption } from './types';

interface OwnProps {
	options: BillableWorkDropdownOption[];
	selectedInformationFields: Set<Nullable<string>>;
	onChange: (fieldName: string) => (field: BillableWorkDropdownOption) => void;
}

export type BillableWorkInformationFieldFieldProps = OwnProps;

type Props = OwnProps & WrappedFieldArrayProps<BillableWorkInformationFieldFormModel>;

const BillableWorkInformationFieldsSection: React.FC<Props> = (props: Props) => {
	const { options: reportBlockFields, fields, selectedInformationFields, onChange } = props;

	const handleAddInformationField = React.useCallback(() => {
		fields.push(BillableWorkInformationFieldFormModel.constructorMap());
	}, [fields]);

	const handleDeleteField = React.useCallback((index: number) => {
		fields.remove(index);
	}, [fields]);

	const renderField = React.useCallback<FieldIterate<BillableWorkInformationFieldFormModel>>((field, index) => {
		const currentField = fields.get(index).reportBlockFieldVirtualId;
		const adjustedOptions: BillableWorkDropdownOption[] = [];
		if (selectedInformationFields.has(currentField!)) {
			for (const _rbf of reportBlockFields) {
				if (selectedInformationFields.has(_rbf.virtualId) && currentField !== _rbf.virtualId) {
					continue;
				}
				adjustedOptions.push(_rbf);
			}
		}

		return (
			<InformationFieldField
				fieldName={field}
				index={index}
				key={field}
				onChange={onChange}
				onDelete={handleDeleteField}
				options={adjustedOptions.length ? adjustedOptions : reportBlockFields}
				valueKey="virtualId"
			/>
		);
	}, [fields, handleDeleteField, reportBlockFields, selectedInformationFields, onChange]);

	return (
		<div className="billable-work-modal__information-fields-section">
			<div className="billable-work-modal__information-fields-section__title">INFORMATION FIELDS</div>
			<>{fields.map(renderField)}</>
			{fields.length < MAX_BILLABLE_WORK_INFORMATION_FIELDS && (
				<div className="billable-work-modal__information-fields-section__add-action" onClick={handleAddInformationField}>
					<span className="icon-plus" />
					<span>Add Information Field</span>
				</div>
			)}
		</div>
	);
};

export default React.memo(BillableWorkInformationFieldsSection);
