import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import FieldReportBlockType, { FieldReportBlocksTotals } from 'acceligent-shared/enums/fieldReportBlockType';

import { RootState } from 'af-reducers';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import Field from './Field';
import PreviewHeader from './Headers/PreviewHeader';
import { getFormName } from '../helpers';

interface OwnProps {
	fieldReportId: number;
	fieldReportTypeId: number;
	blockId: string;
	focusedBlockId: Nullable<number>;
	setFieldToFocus: (ref: HTMLDivElement) => void;
	removeFocusedField: () => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	isMatch: boolean;
	isActiveMatch: boolean;
}

class TotalBlock extends React.PureComponent<Props, State> {
	state: State = {
		isMatch: false,
		isActiveMatch: false,
	};

	renderHeader = () => {
		const {
			blockId,
		} = this.props;

		return (
			<PreviewHeader
				blockId={blockId}
			/>
		);
	};

	renderField = (fieldId: string, key: number) => {
		const { fieldReportTypeId, blockId, fieldReportId, focusedBlockId, setFieldToFocus, removeFocusedField } = this.props;
		return (
			<Field
				blockId={blockId}
				disabled={true}
				fieldId={fieldId}
				fieldName={getFormName(fieldId)}
				fieldReportId={fieldReportId}
				fieldReportTypeId={fieldReportTypeId}
				focusedBlockId={focusedBlockId}
				isPreview={true}
				key={key}
				removeFocusedField={removeFocusedField}
				setFieldToFocus={setFieldToFocus}
			/>
		);
	};

	renderBody = () => {
		const { fieldReportBlock, reportBlock } = this.props;
		const { fields, isJustifiable } = fieldReportBlock;
		const { isRepeating } = reportBlock;

		const className = bemElement('field-report-block', 'body', { repeating: isRepeating });
		const fieldWrapperClassName = bemElement('field-report-block', 'field-array', { justifiable: isJustifiable, preview: true });

		return (
			<div className={className}>
				<div className="field-report-block__block">
					<div className={fieldWrapperClassName}>{fields.map(this.renderField)}</div>
				</div>
			</div>
		);
	};

	render() {
		const { fieldReportBlock, blockId } = this.props;
		const { isMatch, isActiveMatch } = this.state;
		if (!fieldReportBlock) {
			return null;
		}
		const { isMain, isPrimary } = fieldReportBlock;

		const isPrimaryBlock = isPrimary || FieldReportBlocksTotals.includes(fieldReportBlock.type);

		const className = bemBlock('field-report-block', {
			main: isMain,
			primary: isPrimaryBlock,
			secondary: !isPrimaryBlock,
			match: isMatch,
			active: isActiveMatch,
			'primary-total-block': fieldReportBlock.type === FieldReportBlockType.UPPER_TOTAL,
			'secondary-total-block': fieldReportBlock.type === FieldReportBlockType.LOWER_TOTAL,
		});

		return (
			<div className={className} id={blockId}>
				{this.renderHeader()}
				{this.renderBody()}
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { fieldReport: { fieldReport, activeSearchItemIndex } } = state;
	const { blockId } = ownProps;

	if (!fieldReport) {
		throw new Error('fieldReport is required in this context');
	}

	const fieldReportBlock = fieldReport.fieldReportBlockMap[blockId];
	if (!fieldReportBlock) {
		throw new Error('fieldReportBlock not found');
	}

	return {
		fieldReportBlock,
		reportBlock: fieldReport?.blockMap?.[fieldReportBlock?.reportBlockId],
		activeSearchItemIndex,
	};
}

const connector = connect(mapStateToProps);

export default connector(TotalBlock);
