import * as React from 'react';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';

import { AttachmentEntity } from '../types';

import styles from './styles.module.scss';

interface Props {
	attachment: AttachmentEntity;
	showModal: boolean;
	setModalVisible: (arg0: boolean) => void;
}

const ImagePreviewModal = React.memo((props: Props) => {
	const { attachment, showModal, setModalVisible } = props;

	const baseClassName = 'modal';
	const headerClassName = `${baseClassName}__big-header`;
	const bodyClassName = `${baseClassName}__body`;

	const imgClassName = 'img-preview';

	return (
		<>
			<CustomModal
				className={styles[baseClassName]}
				closeModal={setModalVisible.bind(this, false)}
				modalStyle="info"
				showModal={showModal}
				size="xl"
			>
				<CustomModal.Header className={styles[headerClassName]}>
					<span>IMAGE PREVIEW</span>
				</CustomModal.Header>
				<CustomModal.Body className={styles[bodyClassName]}>
					<img className={styles[imgClassName]} src={attachment.originalSrc} />
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={setModalVisible.bind(this, false)}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</CustomModal>
		</>
	);
});

export default ImagePreviewModal;
