import * as React from 'react';

import { DISPLAY_VIEW_NUMBER_OF_ITEMS_IN_COLUMN } from 'af-constants/values';

import { DisplayViewEquipmentViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import UnavailableEquipmentColumn from './UnavailableEquipmentColumn';

import { chunkArray } from 'ab-utils/array.util';

interface OwnProps {
	status: Nullable<string>;
	equipmentList: DisplayViewEquipmentViewModel[];
}

type Props = OwnProps;

const UnavailableEquipmentGroup: React.FC<Props> = (props: Props) => {
	const { equipmentList, status } = props;
	const equipmentGroups = chunkArray<DisplayViewEquipmentViewModel>(equipmentList, DISPLAY_VIEW_NUMBER_OF_ITEMS_IN_COLUMN);

	return (
		<div className="display-view-group">
			<div className="display-view-group-title">
				<img src="/images/elements/ic_state_incomplete.svg" />
				<span className="ellipsed display-view-group-title-text">
					{status}
				</span>
			</div>
			<div className="display-view-columns-wrapper">
				{
					equipmentGroups.map((_equipmentGroup, _index) => (
						<UnavailableEquipmentColumn equipmentGroup={_equipmentGroup} key={_index} />
					))
				}
			</div>
		</div>
	);
};

export default React.memo(UnavailableEquipmentGroup);
