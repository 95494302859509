import * as React from 'react';
import { FormGroup, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import { WrappedFieldProps, formValueSelector, change } from 'redux-form';

import { isNullOrUndefined } from 'acceligent-shared/utils/extensions';

import { RootState } from 'af-reducers';

import PlainDropdown, { OwnProps as PlainDropdownProps, PropsWithLabel, PropsWithCustomRender, DropdownOptionType, Section } from 'af-components/Controls/Dropdown';

type OwnProps<T extends DropdownOptionType> = PlainDropdownProps<T> & {
	alwaysShowErrors?: boolean;
	change: typeof change;
	containerId?: string;
	disableErrorMessage?: boolean;
	isStandalone?: boolean;
	/** object-like property name */
	propName?: string;
};

interface StateProps<T extends DropdownOptionType> {
	/** value will be whatever is dictated by selected option -> valueKey; see **AP-5020** for what behavior needs to be fixed */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selected: T | T[keyof T]; // FIXME: this is obviously not the intended value of the prop, but that's how mapStateToProps is written - **AP-5020**
}

type Props<T extends DropdownOptionType> = OwnProps<T> & WrappedFieldProps & StateProps<T>;

interface State<T extends DropdownOptionType> {
	selected: T | T[keyof T] | undefined;
}

class Dropdown<T extends DropdownOptionType> extends React.Component<Props<T>, State<T>> {

	state: State<T> = {
		selected: Dropdown.getSelected(this.props),
	};

	static readonly MANDATORY_SECTION_PROPS_KEYS = ['sections', 'sectionTitleKey', 'sectionOptionsKey'];

	static getSelected<T extends DropdownOptionType>(props: Props<T>) {
		const { selected, valueKey } = props;

		const getSectionsMapper = (key: string) => (section: Section<T>) => section[key];

		let options: Nullable<T[]> = null;
		if ('useSectionList' in props) {
			options = props.sections?.flatMap(getSectionsMapper(props.sectionOptionsKey)) ?? null;
		} else if ('options' in props) {
			options = props.options;
		}

		if (!isNullOrUndefined(selected) && isNullOrUndefined(selected?.[valueKey]) && !!options?.length) {
			// selected is probably value, so try to map it to one of the options
			return options.find((_option) => _option[valueKey] === selected) ?? selected;
		}
		return selected;
	}

	componentDidUpdate(prevProps: Props<T>) {
		const { selected } = this.props;

		let shouldRefreshSelected = false;
		if ('useSectionList' in this.props && 'useSectionList' in prevProps) {
			shouldRefreshSelected = !prevProps.sections.length && !!this.props.sections.length || prevProps.selected !== selected;
		} else if ('options' in this.props && 'options' in prevProps) {
			shouldRefreshSelected = (prevProps.options !== this.props.options) || prevProps.selected !== selected;
		} else if (prevProps.selected !== selected) {
			shouldRefreshSelected = true;
		}
		shouldRefreshSelected && this.setState(() => ({ selected: Dropdown.getSelected(this.props) }));
	}

	handleChange = async (item: T, selectedValue: string) => {
		const {
			input,
			onValueChange,
			change: _change,
			meta: { form },
			propName,
			valueKey,
			hasBlankOption,
		} = this.props;

		if (!item && hasBlankOption) {
			input.onChange(null);
		}
		else {
			input.onChange(item[valueKey]);
		}
		// changes [propName] prop, e.g. job (entire object)
		if (propName) {
			_change(form, propName, item || null);
		}

		// on change callback, returns entire option that got selected
		if (onValueChange) {
			await onValueChange(item, selectedValue);
		}

		this.handleBlur();
	};

	handleFocus = () => {
		const { input } = this.props;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(input as any).onFocus();
	};

	handleBlur = () => {
		const { input } = this.props;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(input as any).onBlur();
	};

	getClassName = () => {
		const {
			className,
			meta: { error, touched },
			alwaysShowErrors,
		} = this.props;

		let cn = 'dropdown-field';
		cn = className ? `${cn} ${className}` : cn;
		cn = (touched || alwaysShowErrors) && error ? `${cn} dropdown-field--error` : cn;
		return cn;
	};

	/**
	 * Get Dropdown props that cannot coexist.
	 *
	 * If the state of the props is invalid, will log an error or warning to the console.
	 */
	getRenderExclusiveProps = (): ExcludeFrom<PropsWithLabel<T>, PlainDropdownProps<T>>
		| ExcludeFrom<PropsWithCustomRender<T>, PlainDropdownProps<T>>
		| undefined => {
		if ('renderMenuItem' in this.props && 'labelKey' in this.props) {
			// eslint-disable-next-line no-console
			console.warn('[Dropdown Field]: `renderMenuItem` and `labelKey` are mutually exclusive, please use just one of them');

			const { renderMenuItem, renderSelected, labelKey } = this.props as PropsWithCustomRender<T> & PropsWithLabel<T>;
			return { renderMenuItem, renderSelected, labelKey };
		}
		if ('renderMenuItem' in this.props) {
			const { renderMenuItem, renderSelected } = this.props;
			return { renderMenuItem, renderSelected };
		}
		if ('labelKey' in this.props) {
			const { labelKey } = this.props;
			return { labelKey };
		}
		// eslint-disable-next-line no-console
		throw new Error('[Dropdown Field]: Cannot render without `renderMenuItem` or `labelKey` defined');
	};

	getMenuListExclusiveProps = () => {
		if ('useSectionList' in this.props && 'options' in this.props) {
			// eslint-disable-next-line no-console
			console.warn('[Dropdown Field]: `sections` and `options` are mutually exclusive, please use just one of them');
		}
		if ('useSectionList' in this.props) {
			if (!Dropdown.MANDATORY_SECTION_PROPS_KEYS.every((_key) => _key in this.props)) {
				// eslint-disable-next-line no-console
				throw new Error(`[Dropdown Field]: is missing one of the following fields ${Dropdown.MANDATORY_SECTION_PROPS_KEYS}`);
			}
			const { sectionOptionsKey, sectionTitleKey, sections, useSectionList, renderSectionHeader } = this.props;
			return {
				renderSectionHeader,
				sectionOptionsKey,
				sections,
				sectionTitleKey,
				useSectionList,
			};
		}
		if ('options' in this.props) {
			if (Dropdown.MANDATORY_SECTION_PROPS_KEYS.some((_key) => _key in this.props)) {
				// eslint-disable-next-line no-console
				throw new Error('[Dropdown Field]: Some of section props like `sections`, `sectionTitleKey`, `sectionOptionsKey`, `renderSectionHeader` are used with `options`, please remove them');
			}
			const { options } = this.props;
			return { options };
		}
		// eslint-disable-next-line no-console
		throw new Error('[Dropdown Field]: Cannot render without `options` or `sections` defined');
	};

	render() {
		const {
			alwaysShowErrors,
			containerId,
			defaultValue,
			disabled,
			disableErrorMessage,
			filterable,
			filterBy,
			filterOptions,
			fixed,
			fullWidth,
			hasBlankOption,
			id,
			isStandalone,
			isWhite,
			label,
			menuItemClassName,
			meta: { error, warning, touched },
			onLazyLoad,
			onMenuOpen,
			tooltipMessage,
			valueKey,
			withCaret,
			onClear,
			placeholder,
			inputClassName,
		} = this.props;
		const { selected } = this.state;

		const fromGroupClassName = isStandalone ? 'form-group--standalone' : undefined;

		return (
			<FormGroup className={fromGroupClassName} id={containerId}>
				<PlainDropdown<T>
					className={this.getClassName()}
					containerClassName={fromGroupClassName}
					defaultValue={!isNullOrUndefined(selected) ? selected : defaultValue}
					disabled={disabled}
					filterable={filterable}
					filterBy={filterBy}
					filterOptions={filterOptions}
					fixed={fixed}
					fullWidth={fullWidth}
					hasBlankOption={hasBlankOption}
					id={id}
					inputClassName={inputClassName}
					isWhite={isWhite}
					label={label}
					menuItemClassName={menuItemClassName}
					onClear={onClear}
					onFocus={this.handleFocus}
					onLazyLoad={onLazyLoad}
					onMenuOpen={onMenuOpen}
					onValueChange={this.handleChange}
					placeholder={placeholder}
					tooltipMessage={tooltipMessage}
					valueKey={valueKey}
					withBorder={false}
					withCaret={withCaret}
					{...this.getRenderExclusiveProps()}
					{...this.getMenuListExclusiveProps()}
				/>
				<FormControl.Feedback />
				{
					(touched || alwaysShowErrors) && !disableErrorMessage &&
					(
						(error && <span className="help-block"><span className="icon-info" /> {typeof error === 'object' ? error[valueKey] : error}</span>) ||
						(warning && <span className="help-block text-orange"><span className="icon-info" /> {warning}</span>)
					)
				}
			</FormGroup>
		);
	}
}

function mapStateToProps<T extends DropdownOptionType>(
	state: RootState,
	ownProps: OwnProps<T> & WrappedFieldProps
): StateProps<T> {
	const {
		meta: { form },
		input: { name },
		propName,
	} = ownProps;
	const calculatedPropName = propName ?? name;
	const selector = formValueSelector(form);

	/** probably option object type if `propName` is used, otherwise value */
	const selected: T | T[typeof name] = selector(state, calculatedPropName);

	return {
		selected,
	};
}

export default connect(mapStateToProps, { change })(Dropdown as React.ComponentClass);
