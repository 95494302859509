import * as React from 'react';

import ScheduleBoardTemporaryEmployeeViewModel from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';

import * as ColorUtils from 'ab-utils/color.util';

import UniqueIdIndicator from './UniqueIdIndicator';

interface Props {
	employee: ScheduleBoardTemporaryEmployeeViewModel;
	isDragAndDropDisabled: boolean;
}

const DraggableItemBody: React.FC<Props> = (props: Props) => {
	const { employee } = props;
	const { firstName, lastName } = ScheduleBoardTemporaryEmployeeViewModel.getDisplayData(employee);
	const color = ColorUtils.getColorTextClass(employee.agencyColor, true);

	return (
		<>
			<span className={`icon-temp_labor sb-resource-item__temp-labor-icon ${color}`} />
			<span className={`sb-resource-item__full-name ${color}`}>
				{firstName} <span className="sb-resource-item__lastName">{lastName}</span>
			</span>
			{employee.duplicateDisplayNameExists && <UniqueIdIndicator uniqueId={employee.uniqueId} />}
		</>
	);
};

export default DraggableItemBody;
