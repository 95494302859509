import { APP_SCROLLBARS_ID } from 'af-constants/values';

declare const document;
declare const Element;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toggleFullscreen(element: any = document.documentElement): void {
	const isInFullScreen =
		(document.fullscreenElement && document.fullscreenElement !== null) ||
		(document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
		(document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
		(document.msFullscreenElement && document.msFullscreenElement !== null);

	if (!isInFullScreen) {
		if (element.requestFullscreen) {
			element.requestFullscreen();
		} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if (element.webkitRequestFullScreen) {
			element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
		} else if (element.msRequestFullscreen) {
			element.msRequestFullscreen();
		}
	} else {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		}
	}
}

export function scrollIntoView(ref: HTMLElement) {
	ref.scrollIntoView({ block: 'center' });
	const e = document.getElementById(APP_SCROLLBARS_ID);
	if (e) {
		e.scrollTop = 0;
	}
}
