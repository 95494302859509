import * as React from 'react';
import { DroppableProvided, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';

import { MechanicViewAvailableEquipmentDataVM } from 'ab-viewModels/mechanicView/mechanicView.viewModel';

import CustomScrollbar from 'af-components/CustomScrollbar';

import { MatchedEquipmentIdMap } from 'af-models/scheduleBoard.models';

import { RootState } from 'af-reducers';

import * as MechanicViewUtil from 'af-utils/mechanicView.util';

import DraggableRow from './Row';

interface OwnProps {
	provided?: DroppableProvided;
}

interface StateProps {
	availableEquipment: MechanicViewAvailableEquipmentDataVM[];
	matchedEquipmentIdMap: MatchedEquipmentIdMap;
	matchedEquipmentIds: number[];
	focusedSearchIndex: number;
}

type Props = OwnProps & StateProps;

class Body extends React.PureComponent<Props> {
	renderDraggableEquipment = (_equipment: MechanicViewAvailableEquipmentDataVM, _index: number) => {
		const {
			focusedSearchIndex,
			matchedEquipmentIdMap,
			matchedEquipmentIds,
		} = this.props;

		return (
			<Draggable
				draggableId={MechanicViewUtil.generateAvailableDraggableId(_equipment)}
				index={_index}
				key={_equipment.id}
			>
				{
					(_draggableProvided, _snapshot) => (
						<DraggableRow
							isActive={_equipment.id === matchedEquipmentIds[focusedSearchIndex]}
							isMatch={matchedEquipmentIdMap[_equipment.id.toString()]}
							provided={_draggableProvided}
							row={_equipment}
							snapshot={_snapshot}
						/>
					)
				}
			</Draggable>
		);
	};

	render() {
		const { availableEquipment, provided } = this.props;

		return (
			<div
				{...provided?.droppableProps}
				className="draggable-table__content"
				id="available-equipment-scrollable-container"
				ref={provided?.innerRef}
			>
				<CustomScrollbar>
					{
						availableEquipment.map(this.renderDraggableEquipment)
					}
				</CustomScrollbar>
				{provided?.placeholder}
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const {
		mechanicView: {
			availableEquipment,
			focusedSearchIndex,
			matchedEquipmentIdMap,
			matchedEquipmentIds,
		},
	} = state.scheduleBoard;

	return {
		availableEquipment,
		focusedSearchIndex,
		matchedEquipmentIdMap,
		matchedEquipmentIds,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(Body);
