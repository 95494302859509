import * as React from 'react';
import { HandleThunkActionCreator } from 'react-redux';

import { SkillViewModel } from 'ab-viewModels/skill.viewModel';

import SkillPreview from 'af-root/scenes/Company/Resources/Skill/SkillPreview';
import SkillEdit from 'af-root/scenes/Company/Resources/Skill/SkillEdit';

import * as SkillActions from 'af-actions/skill';

interface OwnProps {
	skill: SkillViewModel;
	index: number;
	toggleEditingSkill: (id: number) => void;
	editingId: Nullable<number>;
	updateSkill: HandleThunkActionCreator<typeof SkillActions.update>;
	deleteSkill: HandleThunkActionCreator<typeof SkillActions.deleteSkill>;
}

type Props = OwnProps;

export default class SkillItem extends React.PureComponent<Props> {

	getInitialValues = () => {
		const { skill } = this.props;
		return {
			name: skill.name,
			color: skill.color,
		};
	};

	render() {
		const { toggleEditingSkill, editingId, skill, index, updateSkill, deleteSkill } = this.props;

		return editingId === skill.id ?
			(
				<SkillEdit
					index={index}
					initialValues={this.getInitialValues()}
					skill={skill}
					toggleEdit={toggleEditingSkill}
					updateSkill={updateSkill}
				/>
			) : (
				<SkillPreview
					deleteSkill={deleteSkill}
					skill={skill}
					toEdit={toggleEditingSkill}
				/>
			);
	}
}
