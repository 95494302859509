import * as React from 'react';

import Radio from 'af-components/Controls/Radio';

import ContactMethodViewModel from 'ab-viewModels/contactMethod.viewModel';

interface Props {
	email: ContactMethodViewModel;
	onSelect: (email: ContactMethodViewModel) => Promise<void>;
	isSelected: boolean;
	disabled?: boolean;
}

class EmailRadioButton extends React.PureComponent<Props> {
	onSelect = async () => {
		const { onSelect, email, disabled = false } = this.props;
		if (disabled) {
			return;
		}
		onSelect(email);
	};

	render () {
		const { email, isSelected, disabled } = this.props;
		let rowClassName = 'contact__methods-row contact__methods-row contact__methods-row--option';
		rowClassName = !isSelected ? `${rowClassName} contact__methods-row--not-selected` : rowClassName;

		return (
			<div className={rowClassName}>
				<div className="contact__method-control">
					<Radio
						checked={isSelected}
						disabled={disabled}
						onCheck={this.onSelect}
					/>
				</div>
				<div>
					<strong>{email.value}</strong>
				</div>
			</div>
		);
	}
}

export default EmailRadioButton;
