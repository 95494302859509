import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Field } from 'redux-form';

import Textarea from 'af-fields/Textarea';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

interface Props {
	code: string;
	submitting: boolean;
	resetTimer: () => void;
	cancel: () => void;
	closeModal: () => void;
	showModal: boolean;
	cancellationReason: string;
}

interface State {
	cancellationReason: Nullable<string>;
}

class CancelModal extends React.PureComponent<Props, State> {

	state: State = {
		cancellationReason: null,
	};

	onCancellationReasonChange = (cancellationReason: string) => {
		const { resetTimer } = this.props;

		this.setState(() => ({ cancellationReason: cancellationReason?.length ? cancellationReason : null }));
		resetTimer();
	};

	render() {
		const { showModal, code, submitting, closeModal, cancel } = this.props;
		const { cancellationReason } = this.state;

		return (
			<CustomModal
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title={`Work Order - ${code}`}
				/>
				<CustomModal.Body>
					<Field
						component={Textarea}
						label="Cancellation Reason *"
						name="cancellationReason"
						onValueChange={this.onCancellationReasonChange}
						placeholder="Please enter cancellation reason"
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button onClick={closeModal} variant="info">Back</Button>
					<SubmitButton
						disabled={!cancellationReason}
						label="Cancel Work Order"
						onClick={cancel}
						reduxFormSubmitting={submitting}
						submitKey={WORK_ORDER_FORM}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}

}

export default CancelModal;
