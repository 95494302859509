import * as React from 'react';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { PCTimeSheetVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import PrintoutSegmentHeader from 'af-components/PrintoutSegmentHeader';

import { getDateTimeWithOffset } from '../../FieldReportsList/helpers';

import TimeSheet from './TimeSheet';

interface OwnProps {
	timeSheets: PCTimeSheetVM[];
	timezone: string;
}

type Props = OwnProps;

const TimeSheets: React.FC<Props> = (props: Props) => {
	const { timeSheets, timezone } = props;

	const correctedTimeZoneSheet: PCTimeSheetVM[] = React.useMemo(() => {
		return timeSheets.map((_ts) => {
			const startTime = getDateTimeWithOffset(_ts.startTime, timezone, TimeFormat.TIME_ONLY_12H);
			const endTime = getDateTimeWithOffset(_ts.endTime, timezone, TimeFormat.TIME_ONLY_12H);
			const signedAt = getDateTimeWithOffset(_ts.signedAt, timezone, TimeFormat.FULL_DATE);
			const approvedAt = getDateTimeWithOffset(_ts.approvedAt, timezone, TimeFormat.FULL_DATE);
			const rejectedAt = getDateTimeWithOffset(_ts.rejectedAt, timezone, TimeFormat.FULL_DATE);

			return {
				..._ts,
				startTime,
				endTime,
				signedAt,
				approvedAt,
				rejectedAt,
			};
		});
	}, [timeSheets, timezone]);

	return (
		<div className="public-report__segment">
			<PrintoutSegmentHeader style="primary" title="TimeSheet" type="segment" />
			{correctedTimeZoneSheet.map((_ts, _index) => (
				<TimeSheet
					key={_index}
					timeSheet={_ts}
				/>
			))}
		</div>
	);
};

export default React.memo(TimeSheets);
