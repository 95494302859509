import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import FSN from './FSN';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector>;

const SettingsFSN = (props: Props) => {
	const { companyData, userRole, location: { state: { orgAlias }, pathname } } = props;
	const companyName = companyData.name;

	const links = React.useMemo(() => [
		{
			url: CLIENT.COMPANY.ANALYTICS.LABOR_UTILIZATION(orgAlias, companyName),
			label: 'Labor Utilization',
			icon: 'icon-user',
			visibleFor: PagePermissions.COMPANY.ANALYTICS.LABOR_UTILIZATION,
			isActive: pathname === CLIENT.COMPANY.ANALYTICS.LABOR_UTILIZATION(orgAlias, companyName),
		},
		{
			url: CLIENT.COMPANY.ANALYTICS.EQUIPMENT_UTILIZATION(orgAlias, companyName),
			label: 'Equipment Utilization',
			icon: 'icon-equipment',
			visibleFor: PagePermissions.COMPANY.ANALYTICS.EQUIPMENT_UTILIZATION,
			isActive: pathname === CLIENT.COMPANY.ANALYTICS.EQUIPMENT_UTILIZATION(orgAlias, companyName),
		},
	], [orgAlias, companyName, pathname]);

	return (
		<FSN
			infoLabel="Analytics"
			isCompanyAdmin={companyData.isCompanyAdmin}
			links={links}
			permissions={companyData.permissions}
			scrollLabel="none"
			userRole={userRole}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
		userRole: userData.role,
	};
}

const connector = connect(mapStateToProps);

export default connector(SettingsFSN);
