import * as React from 'react';

import { UPDATED_BY_FAKE_USER } from 'acceligent-shared/dtos/web/view/updatedBy';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import DateCell from 'af-components/Table6/Cells/DateCell';

interface UpdatedBy {
	accountId?: Nullable<number>;
	userId?: Nullable<number>;
	firstName?: string;
	lastName?: string;
	fullName?: Nullable<string>;
}

interface UpdatedBy {
	accountId?: Nullable<number>;
	userId?: Nullable<number>;
	firstName?: string;
	lastName?: string;
	fullName?: Nullable<string>;
}

interface OwnProps {
	updatedBy: Nullable<UpdatedBy>;
	updatedAt: Date | string;
	isLeftAligned?: boolean;
	dateSourceFormat?: TimeFormat;
}

type Props = OwnProps;

function LastUpdatedByCell({ updatedBy = UPDATED_BY_FAKE_USER, ...restParams }: Props) {
	const { updatedAt, isLeftAligned, dateSourceFormat } = restParams;

	if (!updatedAt && !updatedBy) {
		return <span>-</span>;
	}

	const isCustomerService = !updatedBy?.accountId && (!updatedBy?.userId || updatedBy?.userId < 0);

	return (
		<DateCell
			date={updatedAt}
			dateSourceFormat={dateSourceFormat}
			details={updatedBy?.fullName}
			format={TimeFormat.DATE_ONLY}
			isCustomerService={isCustomerService}
			isLeftAligned={isLeftAligned}
			withTime={true}
		/>
	);
}

export default React.memo(LastUpdatedByCell);
