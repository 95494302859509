import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { UpdatedByViewModel, UpdatedByAccountViewModel } from 'acceligent-shared/dtos/web/view/updatedBy';

import { formatDate } from 'acceligent-shared/utils/time';

import WorkRequest from 'acceligent-shared/models/workRequest';
import User from 'acceligent-shared/models/user';

import WorkRequestBidStatus from 'acceligent-shared/enums/workRequestBidStatus';
import Priority from 'acceligent-shared/enums/priority';

const WORK_REQUEST_TABLE_HEADERS = [
	'Bid Status',
	'Priority',
	'Work Request ID',
	'Title',
	'Customer Company',
	'City',
	'State',
	'Office',
	'Project Manager',
	'Start Date',
	'Target Completion Date',
	'Guaranteed Completion Date',
	'Billing Total / Estimate Total',
];

class WorkRequestTableViewModel {
	id: number;
	bidStatus: WorkRequestBidStatus;
	jobCode: string;
	title: string;
	customerCompanyName: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	office: Nullable<string>;
	projectManager: Nullable<string>;
	/** YYYY-MM-DDTHH:mm:ss.SSSZ */
	updatedAt: Nullable<string>;
	priority: Priority;
	updatedBy: UpdatedByViewModel;
	/** YYYY-MM-DD */
	startDate: Nullable<string>;
	/** YYYY-MM-DD */
	targetCompletionDate: Nullable<string>;
	/** YYYY-MM-DD */
	guaranteedCompletionDate: Nullable<string>;
	estimateTotal: Nullable<number>;
	convertedToJobId: Nullable<number>;

	constructor(workRequest: WorkRequest) {
		const _projectManager: Nullable<User> = workRequest?.projectManager?.account?.user ?? null;

		this.id = workRequest.id;
		this.jobCode = workRequest.jobCode;
		this.title = workRequest.title;
		this.startDate = workRequest.startDate;
		this.updatedBy = new UpdatedByAccountViewModel(workRequest.updatedBy);
		this.updatedAt = workRequest.updatedAt ? formatDate(workRequest.updatedAt, TimeFormat.ISO_DATETIME, TimeFormat.ISO_DATETIME) : null;
		this.office = workRequest?.office?.nickname ?? null;

		this.targetCompletionDate = workRequest.targetCompletionDate;
		this.guaranteedCompletionDate = workRequest.guaranteedCompletionDate;
		this.estimateTotal = workRequest?.estimateTotal;

		this.projectManager = _projectManager ? `${_projectManager.firstName} ${_projectManager.lastName}` : null;
		this.customerCompanyName = workRequest.customerCompanyName;
		this.bidStatus = workRequest.bidStatus;

		this.city = workRequest.travelLocation?.locality ?? null;
		this.state = workRequest.travelLocation?.aa1 ?? null;
		this.priority = workRequest.priority;

		this.convertedToJobId = workRequest.convertedToJobId;

	}

	static bulkConstructor = (_workRequests: WorkRequest[]) => _workRequests.map((_wr) => new WorkRequestTableViewModel(_wr));

	static toCSVData = (workRequests: WorkRequestTableViewModel[]): string[][] => {

		const headers = WORK_REQUEST_TABLE_HEADERS;
		const rows = workRequests.map((_wr) => {
			return [
				_wr.bidStatus,
				_wr.priority,
				_wr.jobCode,
				_wr.title,
				_wr.customerCompanyName ?? '',
				_wr.city ?? '',
				_wr.state ?? '',
				_wr.office ?? '',
				_wr.projectManager ?? '',
				_wr.startDate ?? '',
				_wr.targetCompletionDate ?? '',
				_wr.guaranteedCompletionDate ?? '',
				_wr.estimateTotal ? `${_wr.estimateTotal}` : '',
			];
		});

		return [headers, ...rows];
	};
}

export default WorkRequestTableViewModel;
