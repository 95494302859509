import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';

import { RootState } from 'af-reducers';

import * as CompanyActions from 'af-actions/companies';
import * as UserGroupActions from 'af-actions/userGroup';

import Breadcrumbs from 'af-components/Breadcrumbs';

import Loading from './Loading';
import GeneralNotificationsPreview from './GeneralNotifications/Preview';
import GeneralNotificationsEdit from './GeneralNotifications/Edit';
import AutomaticNotificationsPreview from './AutomaticNotifications/Preview';
import AutomaticNotificationsEdit from './AutomaticNotifications/Edit';
import SpecialEmailsPreview from './SpecialEmails/Preview';
import SpecialEmailsEdit from './SpecialEmails/Edit';
import NotificationTimesPreview from './NotificationTimes/Preview';
import NotificationTimesEdit from './NotificationTimes/Edit';
import TemporaryLaborNotificationsPreview from './TemporaryLaborNotifications/Preview';
import TemporaryLaborNotificationsEdit from './TemporaryLaborNotifications/Edit';

import { CompanyFeatureFlagEntries } from 'ab-viewModels/companyFeatureFlags.viewModel';

import { GeneralNotifications, DefaultNotificationTimes, SpecialEmails, AutomaticNotifications, CompanyFeatureFlagsFM, TemporaryLaborNotificationsForm } from './formModel';
import UserGroupRequestModel from 'ab-requestModels/userGroup.requestModel';

type OwnProps = CustomRouteComponentProps<void>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const NotificationDetails: React.FC<Props> = (props) => {
	const {
		company,
		editGeneralNotifications,
		editDefaultNotificationSendTimes,
		editSpecialEmailNotifications,
		editAutomaticNotifications,
		editTemporaryLaborNotifications,
		getCompany,
		findAllForCompany,
	} = props;

	const [isAutomaticNotificationsModalOpen, setIsAutomaticNotificationsModalOpen] = React.useState(false);
	const [isGeneralNotificationsModalOpen, setIsGeneralNotificationsModalOpen] = React.useState(false);
	const [isDefaultTimesModalOpen, setIsDefaultTimesModalOpen] = React.useState(false);
	const [isSpecialEmailsModalOpen, setIsSpecialEmailsModalOpen] = React.useState(false);
	const [isTemporaryLaborModalOpen, setIsTemporaryLaborModalOpen] = React.useState(false);
	const [companyFeatureFlagsEntries, setCompanyFeatureFlagsEntries] = React.useState<CompanyFeatureFlagEntries>({} as CompanyFeatureFlagEntries);
	const [userGroupsForCompany, setUserGroupsForCompany] = React.useState<UserGroupRequestModel[]>([]);

	React.useEffect(() => {
		const fetchAndInitializeData = async () => {
			await getCompany();
			const userGroups = await findAllForCompany();
			setUserGroupsForCompany(userGroups);
		};
		fetchAndInitializeData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (!company?.id) {
			return;
		}
		setCompanyFeatureFlagsEntries(new CompanyFeatureFlagEntries(CompanyFeatureFlagsFM.fromCompanyViewModel(company)));
	}, [company]);

	const toggleGeneralNotifications = React.useCallback(() => {
		setIsGeneralNotificationsModalOpen(!isGeneralNotificationsModalOpen);
	}, [isGeneralNotificationsModalOpen]);

	const toggleDefaultTimes = React.useCallback(() => {
		setIsDefaultTimesModalOpen(!isDefaultTimesModalOpen);
	}, [isDefaultTimesModalOpen]);

	const toggleSpecialEmails = React.useCallback(() => {
		setIsSpecialEmailsModalOpen(!isSpecialEmailsModalOpen);
	}, [isSpecialEmailsModalOpen]);

	const toggleAutomaticNotifications = React.useCallback(() => {
		setIsAutomaticNotificationsModalOpen(!isAutomaticNotificationsModalOpen);
	}, [isAutomaticNotificationsModalOpen]);

	const toggleTemporaryLaborNotifications = React.useCallback(() => {
		setIsTemporaryLaborModalOpen(!isTemporaryLaborModalOpen);
	}, [isTemporaryLaborModalOpen]);

	if (!company) {
		return <Loading />;
	}

	return (
		<div className="form-segment">
			<Breadcrumbs items={[{ label: 'Notifications' }]} />
			<GeneralNotificationsPreview
				company={company}
				general={companyFeatureFlagsEntries.general}
				toggleEdit={toggleGeneralNotifications}
			/>
			<GeneralNotificationsEdit
				editGeneralNotifications={editGeneralNotifications}
				initialValues={GeneralNotifications.fromCompanyViewModel(company)}
				isVisible={isGeneralNotificationsModalOpen}
				toggleEdit={toggleGeneralNotifications}
			/>

			{
				companyFeatureFlagsEntries.areNotificationsOn &&
				<>
					<Breadcrumbs items={[{ label: 'Default notification times' }]} />
					<NotificationTimesPreview
						defaultSendTimes={companyFeatureFlagsEntries.defaultSendTimes}
						toggleEdit={toggleDefaultTimes}
					/>
					<NotificationTimesEdit
						editDefaultNotificationSendTimes={editDefaultNotificationSendTimes}
						initialValues={DefaultNotificationTimes.fromCompanyViewModel(company)}
						isVisible={isDefaultTimesModalOpen}
						toggleEdit={toggleDefaultTimes}
					/>

					<Breadcrumbs items={[{ label: 'Special Emails' }]} />
					<SpecialEmailsPreview
						specialEmails={companyFeatureFlagsEntries.specialEmails}
						toggleEdit={toggleSpecialEmails}
					/>
					<SpecialEmailsEdit
						editSpecialEmailNotifications={editSpecialEmailNotifications}
						initialValues={SpecialEmails.fromCompanyViewModel(company)}
						isVisible={isSpecialEmailsModalOpen}
						toggleEdit={toggleSpecialEmails}
						userGroupsForCompany={userGroupsForCompany}
					/>

					<Breadcrumbs items={[{ label: 'Automatic Work Order notifications' }]} />
					<AutomaticNotificationsPreview
						toggleEdit={toggleAutomaticNotifications}
						workOrderNotifications={companyFeatureFlagsEntries.workOrderNotifications}
					/>
					<AutomaticNotificationsEdit
						editAutomaticNotifications={editAutomaticNotifications}
						initialValues={AutomaticNotifications.fromCompanyViewModel(company)}
						isVisible={isAutomaticNotificationsModalOpen}
						toggleEdit={toggleAutomaticNotifications}
					/>

					<Breadcrumbs items={[{ label: 'Temporary labor' }]} />
					<TemporaryLaborNotificationsPreview
						toggleEdit={toggleTemporaryLaborNotifications}
						workOrderNotifications={companyFeatureFlagsEntries.temporaryLaborNotifications}
					/>
					<TemporaryLaborNotificationsEdit
						editTemporaryLaborNotifications={editTemporaryLaborNotifications}
						initialValues={TemporaryLaborNotificationsForm.fromCompanyViewModel(company)}
						isVisible={isTemporaryLaborModalOpen}
						toggleEdit={toggleTemporaryLaborNotifications}
					/>
				</>
			}

		</div >
	);
};

function mapStateToProps(state: RootState) {
	const { company } = state.company;

	return {
		company,
	};
}

function mapDispatchToProps() {
	return {
		findAllForCompany: UserGroupActions.findAllForCompany,
		getCompany: CompanyActions.getCompany,
		editGeneralNotifications: CompanyActions.editGeneralNotifications,
		editDefaultNotificationSendTimes: CompanyActions.editDefaultNotificationSendTimes,
		editSpecialEmailNotifications: CompanyActions.editSpecialEmailNotifications,
		editAutomaticNotifications: CompanyActions.editAutomaticNotifications,
		editTemporaryLaborNotifications: CompanyActions.editTemporaryLaborNotifications,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType>(
	React.memo,
	connector
);

export default enhance(NotificationDetails);
