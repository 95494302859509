import * as React from 'react';
import { FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import enUs from 'date-fns/locale/en-US';
import { nanoid } from 'nanoid';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import HorizontalAlignment from 'ab-enums/horizontalAlignment.enum';

import Label from 'af-components/LockedValue/Label';
import { OwnProps as TooltipProps } from 'af-components/Tooltip';

import { bemBlock } from 'ab-utils/bem.util';

import { UNIQUE_ID_SIZE } from 'ab-constants/value';

type PopperPlacementPositions = (
	| 'auto'
	| 'auto-left'
	| 'auto-right'
	| 'bottom'
	| 'bottom-end'
	| 'bottom-start'
	| 'left'
	| 'left-end'
	| 'left-start'
	| 'right'
	| 'right-end'
	| 'right-start'
	| 'top'
	| 'top-end'
	| 'top-start'
);

interface CalendarSettings<T> {
	minDate?: T;
	maxDate?: T;
	filterDate?: (date: T) => boolean;
	excludeDates?: T[];
	highlightDates?: T[];
}

export interface Props {
	id?: string;
	label?: string;
	isRequired?: boolean;
	disabled?: boolean;
	placeholder?: string;
	placeholderText?: string;
	onValueChange?: (newDate: Date) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	suggestionText?: string | JSX.Element;
	isConnected?: boolean;
	horizontalAlignment?: HorizontalAlignment;
	popperPlacement?: PopperPlacementPositions;
	isStandalone?: boolean;
	/**
	 * The date format that will be shown and saved.
	 *
	 * Should be be an ISO 8601 or RFC 2822 Date time format, otherwise moment.js might not accept it.
	 */
	dateFormat?: TimeFormatEnum;
	originalDateFormat?: TimeFormatEnum;
	locale?: Locale;	// from date-fns
	/**
	 * If this value is specified it will override the date that is shown on Date Picker.
	 */
	selectedValue?: Date | string;
	defaultValue?: Date;
	/**
	 * DatePicker attributes.
	 * For more attributes see {@link https://hacker0x01.github.io/react-datepicker }.
	 * To comply with v2, we are remapping moment values into dates in State
	 */
	calendarSettings?: CalendarSettings<Date>;
	handleSelect?: (changedDate: Date, event) => void;
	datePickerClassName?: string;
	/**
	 * This element (if exists) will be rendered at the bottom of the component. (Used for error or warning info).
	 */
	block?: JSX.Element;
	/**
	 * Used for giving IDs on elements that need to be targeted through automated tests
	 */
	wrapperId?: string;
	fixed?: boolean;
	tooltipMessage?: TooltipProps['message'];
	isClearable?: boolean;
	withAppend?: boolean;
	wrapperClassName?: string;
}

interface DropdownStyle {
	top: number | 'auto';
	bottom: 'auto';
	left: number;
	width: number;
}

const DateInput = (props: Props) => {
	const [wrapperId] = React.useState(props.wrapperId ?? nanoid(UNIQUE_ID_SIZE));
	const [dropdownStyle, setDropdownStyle] = React.useState({} as DropdownStyle);
	const datePickerRef = React.useRef<DatePicker>(null);

	const {
		id,
		disabled,
		label,
		isRequired,
		dateFormat: format = TimeFormatEnum.DATE_ONLY,
		originalDateFormat: originalFormat = TimeFormatEnum.ISO_DATETIME,
		locale = enUs,
		horizontalAlignment = HorizontalAlignment.LEFT,
		defaultValue,
		placeholderText,
		suggestionText,
		isConnected,
		popperPlacement = 'bottom',
		selectedValue,
		block,
		datePickerClassName = 'form-control',
		isStandalone = false,
		calendarSettings,
		tooltipMessage,
		isClearable,
		fixed = false,
		onBlur,
		onFocus,
		onValueChange,
		handleSelect: onSelect,
		withAppend = true,
		wrapperClassName,
	} = props;

	const formGroupClassName = bemBlock('date-input', {
		left: horizontalAlignment === HorizontalAlignment.LEFT,
		right: horizontalAlignment !== HorizontalAlignment.LEFT,
		connected: !!isConnected,
		standalone: !!isStandalone,
	});

	const _selectedDate = selectedValue ?? defaultValue;
	const selectedDate = _selectedDate instanceof Date
		? _selectedDate
		: _selectedDate ? TimeUtils.parseDate(_selectedDate, originalFormat) : null;

	const handleSelect = React.useCallback((changedDate: Date, event: React.ChangeEvent<HTMLInputElement>) => {
		event.target.value = TimeUtils.formatDate(changedDate, format);
		// If we have redux logic, only handleSelect will be called
		if (onSelect) {
			onSelect(changedDate, event);
			return;
		}
		onValueChange?.(changedDate);
	}, [format, onSelect, onValueChange]);

	const handleOpen = React.useCallback(() => {
		if (!fixed) {
			return;
		}

		const {
			x: inputPositionX = 0,
			bottom: inputBottom = 0,
			width: inputWidth = 0,
		} = document.getElementById(wrapperId)?.getClientRects()?.[0] ?? {};

		setDropdownStyle({
			top: inputBottom,
			bottom: 'auto',
			left: inputPositionX,
			width: inputWidth,
		});
	}, [fixed, wrapperId]);

	const popperContainer = React.useCallback((popperProps: { children: React.ReactNode[]; }) => {
		if (!fixed) {
			return <>{popperProps.children}</>;
		}

		return (
			<div
				className="date-input__popper date-input__popper--fixed"
				style={dropdownStyle}
			>
				{popperProps.children}
			</div>
		);
	}, [fixed, dropdownStyle]);

	const onAppendClick = React.useCallback(() => {
		datePickerRef.current?.setFocus();
	}, []);

	return (
		<FormGroup className={formGroupClassName} controlId={id}>
			<div className="input-header">
				{label &&
					<Label
						isRequired={isRequired}
						label={label}
						tooltipMessage={tooltipMessage}
						tooltipPlacement="top"
						withMargin={true}
					/>
				}
			</div>
			<div
				className={`${wrapperClassName} date-input__wrapper`}
				id={wrapperId}
			>
				<DatePicker
					{...calendarSettings}
					autoComplete="off"
					className={datePickerClassName}
					dateFormat={format && TimeUtils.datePickerFormat(format)} // remove this to set the date format to depend on *locale* - make sure that moment.js format requirements are met
					disabled={disabled}
					dropdownMode="select"
					isClearable={isClearable}
					locale={locale}
					maxDate={calendarSettings?.maxDate ?? undefined}
					minDate={calendarSettings?.minDate ?? undefined}
					onBlur={onBlur}
					onCalendarOpen={handleOpen}
					onChange={handleSelect}
					onFocus={onFocus}
					placeholderText={placeholderText}
					popperContainer={popperContainer}
					popperPlacement={popperPlacement}
					ref={datePickerRef}
					selected={selectedDate}
					showMonthDropdown={true}
					showYearDropdown={true}
					todayButton="Today"
				/>
				{withAppend && (
					<InputGroup.Append
						className="input-group-append--clickable input-group-append--icon input-group-append--open-left"
						onClick={onAppendClick}
					>
						<span className="icon-calendar" />
					</InputGroup.Append>
				)}
			</div>
			{suggestionText}
			<FormControl.Feedback />
			{block}
		</FormGroup>
	);
};

export default React.memo(DateInput);
