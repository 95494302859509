import * as React from 'react';

import { getDayName, parseMoment, formatDate, datesBetween } from 'acceligent-shared/utils/time';

type MomentType = ReturnType<typeof datesBetween>[number];

interface Props {
	startDate: Nullable<string>;
	endDate: Nullable<string>;
}

const Loading = (props: Props) => {
	const { startDate, endDate } = props;

	const startDateMoment = startDate && parseMoment(startDate);
	const endDateMoment = endDate && parseMoment(endDate);
	const momentRange = startDateMoment && endDateMoment
		? datesBetween(startDateMoment, endDateMoment)
		: [];
	return (
		<div className="schedule-board-container">
			<div className="schedule-board-weekly-view-wrapper">
				{momentRange.map((_dueDate: MomentType, _index: number) => {
					const dueDate = formatDate(_dueDate);
					return (
						<div className={`schedule-board-week-row loading ${!_index ? 'start-day' : ''}`} key={dueDate}>
							<div className="schedule-board-week-day">
								<div className="schedule-board-day-name">
									<div className="loading-col-24 loading-box-row dark" />
								</div>
								<div className="schedule-board-date">
									<div className="loading-col-24 loading-box-row dark" />
								</div>
							</div>
							<div className="schedule-board-week-day-wrapper">
								<div className="schedule-board-week-day">
									<div>
										<div className="schedule-board-day-name">{getDayName(dueDate)}</div>
										<div className="schedule-board-date">{dueDate}</div>
									</div>
								</div>
							</div>
							<div className="schedule-board-cards-container-wrapper">
								<div className="schedule-board-cards-container">
									<div className="schedule-board-card-wrapper columns-1">
										<div className="schedule-board-card">
											<div className="schedule-board-card-order-info">
												<div className="schedule-board-card-crew">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-supervisor">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-code">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-address">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
											</div>
										</div>
									</div>
									<div className="schedule-board-card-wrapper columns-2">
										<div className="schedule-board-card">
											<div className="schedule-board-card-order-info">
												<div className="schedule-board-card-crew">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-supervisor">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-code">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-address">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
											</div>
										</div>
									</div>
									<div className="schedule-board-card-wrapper columns-1">
										<div className="schedule-board-card">
											<div className="schedule-board-card-order-info">
												<div className="schedule-board-card-crew">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-supervisor">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-code">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-address">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
											</div>
										</div>
									</div>
									<div className="schedule-board-card-wrapper columns-1">
										<div className="schedule-board-card">
											<div className="schedule-board-card-order-info">
												<div className="schedule-board-card-crew">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-supervisor">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-code">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-address">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
											</div>
										</div>
									</div>
									<div className="schedule-board-card-wrapper columns-2">
										<div className="schedule-board-card">
											<div className="schedule-board-card-order-info">
												<div className="schedule-board-card-crew">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-supervisor">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-code">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
												<div className="schedule-board-card-address">
													<div className="loading-col-24 loading-box-row dark" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})
				}
			</div>
		</div>
	);
};

export default Loading;
