import * as React from 'react';

import LockedValue from 'af-components/LockedValue';

import { previewSectionClass, previewSectionTitleClass, previewSectionClassRow, previewSectionClassColumn2, previewSectionClassColumn4 } from '../../helpers';

type Props = {
	vehicleTraffic: boolean;
	vehicleTrafficDescription: Nullable<string>;
	pedestrianCrossing: boolean;
	pedestrianCrossingDescription: Nullable<string>;
};

const PublicSafetySection: React.FC<Props> = (props) => {
	const {
		pedestrianCrossing,
		pedestrianCrossingDescription,
		vehicleTraffic,
		vehicleTrafficDescription,
	} = props;

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Public Safety
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Vehicle Traffic" value={vehicleTraffic ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={vehicleTrafficDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Pedestrian Crossing" value={pedestrianCrossing ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={pedestrianCrossingDescription} />
				</div>
			</div>
		</div>
	);
};

export default React.memo(PublicSafetySection);
