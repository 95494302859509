import * as React from 'react';

interface OwnProps {
	url: string;
	label?: string | JSX.Element;
}

type Props = OwnProps;

const LinkCell = ({ url, label }: Props) => {

	const onRedirect = React.useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();
		window.open(url, '_blank');
	}, [url]);

	return (
		<div className="link-cell">
			<span className="icon-external link-cell__icon" onClick={onRedirect} />
			{label ? (
				<div className="link-cell__label">{label}</div>
			) : null}
		</div>
	);
};

export default LinkCell;
