import { createBrowserHistory, Location } from 'history';
import { CustomLocationState, matchPath } from 'react-router-dom';

import { getCurrentOrgAlias } from 'af-utils/window.util';

import { PUBLIC_ROUTES_LIST } from 'af-routes/client';

const history = createBrowserHistory();
const orgAlias = getCurrentOrgAlias();

// initial state
history.location.state = { orgAlias };

// TODO remove once all components use router v6
history.listen((location: Location<CustomLocationState>) => {

	// ensure that every time we access location.state we have orgalias
	if (location.state) {
		location.state.orgAlias = orgAlias!;
	} else {
		location.state = { orgAlias: orgAlias! };
	}
});

export function isCurrentPathPublic() {
	const currentPathname = history.location.pathname;

	for (const _publicRoute of PUBLIC_ROUTES_LIST) {
		const matchResult = matchPath(currentPathname, { path: _publicRoute, exact: true });
		if (matchResult?.isExact) {
			return true;
		}
	}
	return false;
}

export default history;
