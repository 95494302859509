import * as React from 'react';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { ExtendedColorPalette } from 'acceligent-shared/enums/color';

import Label from 'af-components/LockedValue/Label';
import ColorSquare from 'af-components/ColorSquare';

interface OwnProps {
	time: number;
	equipmentCode: Nullable<string>;
	classificationCode: Nullable<string>;
	equipmentCategoryColor: Nullable<ExtendedColorPalette>;
	equipmentSpecification: Nullable<string>;
}

type Props = OwnProps;

const EquipmentItem: React.FC<Props> = (props: Props) => {
	const { time, equipmentCode, classificationCode, equipmentCategoryColor, equipmentSpecification } = props;

	return (
		<div className="public-report__time-sheet__time-and-equipment__equipment__item">
			<div className="public-report__time-sheet__time-and-equipment__equipment__item__time">
				<Label inverse={true} label={TimeUtils.minutesToHoursAndMinutes(time)} />
			</div>
			<div className="public-report__time-sheet__time-and-equipment__equipment__item__equipment">
				{!!equipmentCode
					? <>
						<ColorSquare color={equipmentCategoryColor} isSmall={true} />
						<Label inverse={true} label={`${equipmentCode}${equipmentSpecification ? ` (${equipmentSpecification})` : ''}`} />
					</>
					: <Label label="N/A" />
				}
			</div>
			<div className="public-report__time-sheet__time-and-equipment__equipment__item__classification-code">
				{!!classificationCode
					? <Label inverse={true} label={classificationCode} />
					: <Label label="N/A" />
				}
			</div>
		</div>
	);
};

export default React.memo(EquipmentItem);
