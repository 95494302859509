import * as React from 'react';

const DraggableLoading = () => {
	return (
		<div>
			<div className="locations-table__draggable-container">
				<span className="icon-drag_indicator" />
				<div className="locations-table__draggable-location loading-element" />
			</div>
		</div>
	);
};

export default DraggableLoading;
