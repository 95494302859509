import styles from './styles.module.scss';

export const previewSectionClass = styles['work-request-preview__tab-content__section'];
export const previewSectionTitleClass = styles['work-request-preview__tab-content__section__title'];
export const previewSectionClassRow = styles['work-request-preview__tab-content__section__row'];
export const previewSectionClassColumn1 = styles['work-request-preview__tab-content__section__row__column-1'];
export const previewSectionClassColumn2 = styles['work-request-preview__tab-content__section__row__column-2'];
export const previewSectionClassColumn4 = styles['work-request-preview__tab-content__section__row__column-4'];
export const previewSectionClassColumn6 = styles['work-request-preview__tab-content__section__row__column-6'];
export const previewSectionCenteredColumn = styles['work-request-preview__tab-content__section__row__centered-column'];

export const tableCellTextClass = styles['work-request-preview-table__cell-text'];
export const tableLightTextClass = styles['work-request-preview-table__light-text'];
export const tableBlueTextClass = styles['work-request-preview-table__cell-text__blue'];
export const tableUppercaseTextClass = styles['work-request-preview-table__cell-text__uppercase'];
