import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import EmployeeList from 'af-root/scenes/Company/ScheduleBoard/DailyView/EmployeesDroppable/EmployeeList';

import { EmployeeModalProps } from '../../Shared/ModalProps';

interface Props extends EmployeeModalProps {
	droppableId: string;
	draggablePrefix: string; // to distinguish if it's board or toolbar dragged employee
	employeeIds: number[];
	isToolbar: boolean;
	isDisabled: boolean;
	className: string;
	startIndex: number;
	endIndex: number;
	title?: string;
	dueDate: string;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	available?: boolean;
	isCardDisabled?: boolean;
	hasReason?: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

export default class DroppableComponent extends React.PureComponent<Props> {

	renderDroppableBody = (employeeIds: number[]) => {
		const {
			available,
			draggablePrefix,
			droppableId,
			dueDate,
			hasPermissionsToEditScheduleBoard,
			hasReason,
			isCardDisabled,
			isCopyPlaceholder,
			isDragAndDropDisabled,
			isToolbar,
			isWorkOrderCanceled,
			startIndex,
			title,
			setEmployeeModalData,
			setEmployeeModalVisibility,
		} = this.props;

		return (
			<>
				{title && <label className="droppable-title">{title}</label>}
				<EmployeeList
					available={available}
					draggablePrefix={draggablePrefix}
					droppableId={droppableId}
					dueDate={dueDate}
					employeeIds={employeeIds}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					hasReason={hasReason}
					isCardDisabled={!!isCardDisabled}
					isCopyPlaceholder={isCopyPlaceholder}
					isDragAndDropDisabled={isDragAndDropDisabled}
					isToolbar={isToolbar}
					isWorkOrderCanceled={isWorkOrderCanceled}
					setEmployeeModalData={setEmployeeModalData}
					setEmployeeModalVisibility={setEmployeeModalVisibility}
					startIndex={startIndex}
				/>
			</>
		);
	};

	render() {
		const {
			className,
			droppableId,
			employeeIds: _employeeIds,
			endIndex,
			hasPermissionsToEditScheduleBoard,
			isCopyPlaceholder,
			isDisabled,
			isDragAndDropDisabled,
			startIndex,
		} = this.props;

		const employeeIds = _employeeIds.slice(startIndex, endIndex);

		// react-beautiful-dnd can not create Draggable and Droppable elements/
		// while we are dragging so in order to mimic the copied card, we render
		// div instead of a Draggable (same for Droppable in parent component)
		if (isCopyPlaceholder || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard) {
			return (
				<div className={className}>
					{this.renderDroppableBody(employeeIds)}
				</div>
			);
		}

		return (
			<Droppable
				droppableId={droppableId}
				isDropDisabled={isDisabled}
			>
				{(provided, snapshot) => {
					return (
						<div
							className={`${className} ${snapshot.isDraggingOver ? 'drag-over' : ''}`}
							ref={provided.innerRef}
						>
							{this.renderDroppableBody(employeeIds)}
							{provided.placeholder}
						</div>
					);
				}}
			</Droppable>
		);
	}
}
