import * as React from 'react';

import { WorkOrderResourceLookupModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import SkillsColorGrid from 'af-components/SkillsColorGrid';
import Tooltip from 'af-components/Tooltip';

interface Props {
	resourceLookup: WorkOrderResourceLookupModel;
	removed: boolean;
}

const LaborPlaceholderItem: React.FC<Props> = (props: Props) => {
	const { resourceLookup, removed } = props;
	const { name } = resourceLookup;
	const skills = (resourceLookup.skills ?? []).map((color) => ({ color }));

	return removed
		? (
			<Tooltip message="Removed" placement="right">
				<div className="work-order-info-modal__resource-item work-order-info-modal__resource-item--placeholder work-order-info-modal__resource-item--removed">
					<div>{name}</div>
					<SkillsColorGrid skills={skills} />
				</div>
			</Tooltip>
		) : (
			<div className="work-order-info-modal__resource-item work-order-info-modal__resource-item--placeholder">
				<div>{name}</div>
				<SkillsColorGrid skills={skills} />
			</div>
		);
};

export default React.memo(LaborPlaceholderItem);
