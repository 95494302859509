import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

export default () => (
	<div className="form-segment text-page">
		<div className={`form-box form-box--midi ${bemElement('text-page', 'container')}`}>
			<div className={bemElement('text-page', 'title')}>
				<h2>Acceligent Privacy Policy</h2>
				<h4>Updated July 15, 2019</h4>
			</div>
			<strong>Scope</strong>
			<p>
				This Privacy Policy covers how we collect, store, use and disclose personal information gathered by Acceligent
				during the delivery of our products and services. This Privacy Policy applies to all products and services
				offered by Acceligent. This Privacy Policy may be superseded by the applicable terms of any agreements governing
				the relationship between your organization and Acceligent.
				<br />
				This Privacy Policy covers:
			</p>
			<ul>
				<li>Information We Collect</li>
				<li>How We Collect and Store Information</li>
				<li>How We Use Information</li>
				<li>How We May Share or Disclose Information</li>
				<li>Information Collection by Partners and Third Parties</li>
				<li>Your Choices to Control How We Use Information</li>
				<li>Changes to this Privacy Policy</li>
				<li>Contact Information</li>
			</ul>

			<strong>Information We Collect</strong>
			<p>
				Acceligent collects information about your identity when you register with Acceligent or use our products and services.
				The information we collect includes your name, company, division, title, roles, permissions, classifications, certifications,
				ratings, skills, email address, mobile phone, street address, password, photographs and other employment and demographic information.
				We collect information about the devices, browsers and applications that you use to access Acceligent products and services.
				The information we collect includes computer or mobile device manufacturer, device type and settings, operating system type and version,
				system activity, crash reports, Wi-Fi and mobile network information, wireless carrier, mobile phone, IP address, unique identifiers,
				browser type and settings, and application version and settings.
				We also collect information about your activity when you use Acceligent products and services. The information we collect includes
				user identity, date, time, duration of use, settings, product section, information displayed, data entered, changes and edits made,
				cursor movement and clicks, touch gestures, talk to text audio, links opened, notifications received, opened and sent, files uploaded
				and downloaded and other usage data.
				Acceligent collects information about your location when you use our products and services. The information we collect includes GPS location,
				IP address, device location data, device sensor activity and information about nearby broadcasting telecommunication devices, such as wireless
				access points, cellular towers and other devices.
				We may also collect information about you, your devices, activity and location from third parties that provide us with information to protect
				against misuse of our products and services, fraud and abuse.
			</p>

			<strong>How We Collect and Store Information</strong>
			<p>
				Acceligent uses various technologies to collect and store information, including cookies, beacons, pixel tags, individualized links, customer
				service applications, carrier records, geofencing, browser storage, application data caches, application logs, databases,
				backups and server logs. We may combine the information we collect among our products and services and across your devices with data we obtain
				from third parties.
				We take reasonable and appropriate measures to safeguard information against accidental and intentional loss, misuse and theft,
				including unauthorized access, alteration, copying, deletion, disclosure or use. We review our procedures from time-to-time in order to consider
				new technologies and methodologies.
				No data protection measures are perfect, nor can they fully protect against loss, misuse and theft, whether intentional or accidental.
				Acceligent disclaims all warranties and guarantees that information will remain secure from unauthorized access, alteration, copying, deletion,
				disclosure or use. As set forth in the Acceligent terms of services, users waive any claims and hold Acceligent harmless from any damages
				cause by such breach.
				We store and process information in the United States and other countries where Acceligent and our partners, service providers and
				advertisers maintain systems and facilities. Users are on notice that we may transfer information to any country; such countries may
				not have the same data protection laws as the United States, the European Union or any other country.
				We retain information while your organization has an Acceligent account and for a reasonable period thereafter,
				unless a longer period is permitted or required by law, in order to provide detailed records to other Acceligent users in your organization.
				We will also retain information for as long as necessary to resolve disputes or enforce our rights under applicable agreements.
			</p>

			<strong>How We Use Information</strong>
			<p>
				We use the information we collect in order to:
			</p>
			<ul>
				<li>Provide personalized products and services;</li>
				<li>Communicate with you;</li>
				<li>Respond to customer service requests;</li>
				<li>Measure performance;</li>
				<li>Maintain and improve our services;</li>
				<li>Develop new services;</li>
				<li>Ensure the safety and reliability of our services; and</li>
				<li>Provide you with targeted offers and advertising.</li>
			</ul>
			<p>
				We also use generalized and anonymized statistical information to show trends about the use of our products and services.
			</p>

			<strong>How We May Share or Disclose Information</strong>
			<p>
				Acceligent does not share information with individuals, companies or organizations outside of Acceligent except in the following cases:
			</p>
			<ul>
				<li>
					We may share information with other Acceligent users in your organization in order to provide our products and services
					and to enable communication with you.
				</li>
				<li>
					We may share information with partners that provide products and services to Acceligent in order to facilitate the development,
					deployment, usage, maintenance and improvement of such products and services.
				</li>
				<li>We may share information with partners that help ensure the safety and reliability of our products and services.</li>
				<li>
					We may disclose information in order to comply with laws, regulations, court orders, subpoenas, or government or law
					enforcement agency requests. We may also disclose information when we believe it is necessary to protect against harm to the
					safety or property of any individual or entity, or when we believe it is necessary detect, investigate, prevent and address possible misuse
					of our products and services, fraud or abuse, including actual or suspected violations of any Acceligent policies,
					terms of service and agreements.
				</li>
				<li>
					We may share information with prospective advisors, lenders, investors and purchasers in connection with a loan to, financing for,
					investment in, merger with, purchase of, sale of, reorganization of, divesture of, or transfer of Acceligent, interests in Acceligent,
					or any of its products, services, assets and businesses.
					We may also sell, assign or transfer information in the course of any such transaction.
				</li>
				<li>
					We may share generalized and anonymized statistical information with third parties and
					the public to show trends about the use of our products and services.
				</li>
			</ul>

			<strong>Information Collection by Partners and Third Parties</strong>
			<p>
				Our products and services may contain links to other websites, such as partners, social media networks, advertisers and third parties.
				Acceligent is not responsible for the information practices employed by such third parties. This Privacy Policy does not apply
				to information collected by others; please see the respective privacy policies of such third parties for more information.
			</p>

			<strong>Your Choices to Control How We Use Information</strong>
			<p>
				Acceligent products and services are made available to users through organizations. The organization account administrator is responsible
				for determining the access, retention, privacy and security controls for the organization. Other than as set forth below, Acceligent
				cannot honor individual user requests to review, correct or delete information. Please contact your organization’s account administrator
				with any such requests.
				Your organization may permit you to correct and update certain account information by logging in to your Acceligent user account and changing
				the account settings. Your organization may permit you to opt-out of certain types of notifications through your account settings.
				You may opt-out from receiving marketing emails through your account settings or by clicking the unsubscribe link in the applicable email.
				If you opt-out from receiving marketing emails, you may continue to receive account, product and transactional messages.
				Acceligent does not comply with “Do Not Track” and “Prevent Cross-Site Tracking” browser requests as we need to track user activity
				in order to provide our products and services.
			</p>

			<strong>Changes to this Privacy Policy</strong>
			<p>
				We may update this Privacy Policy from time to time.
				We will notify you of material changes to this Privacy Policy by posting a notice on this website.
			</p>

			<strong>Contact Information</strong>
			<p>
				If you have questions or concerns about this Privacy Policy or our privacy practices, you may contact Acceligent by mail at:<br />
				Privacy Officer<br />
				Acceligent<br />
				745 Atlantic Avenue, 8th Floor<br />
				Boston, MA 02118<br />
			</p>
		</div>
	</div>
);
