import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkOrderReviewStatus, { isReviewable, isApprovable, isRejectable, isFinalizeable } from 'acceligent-shared/enums/workOrderReviewStatus';
import { CalculatedReportDisplayStatus } from 'acceligent-shared/enums/reportDisplayStatus';

import { formatDate } from 'acceligent-shared/utils/time';

import TimeAllocationEntryVM from 'acceligent-shared/dtos/web/view/timeAllocationEntry/timeAllocationEntry';

import WorkOrderFieldReportCardVM from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';
import AssociatedSubjobVM from 'ab-viewModels/workRequest/associatedSubjob.viewModel';

import LockedValue from 'af-components/LockedValue';
import LabelWithColor from 'af-components/LabelWithColor';
import Dropdown from 'af-components/Controls/Dropdown';

import * as WorkOrderActions from 'af-actions/workOrder';
import * as WorkRequestActions from 'af-actions/workRequests';
import * as JobWorkSummaryActions from 'af-actions/jobWorkSummary';

import { bemElement } from 'ab-utils/bem.util';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import { useToggle } from 'af-utils/react.util';

import AccessModal from '../FieldReportAccess';

import WorkOrderRejectionReason from './WorkOrderRejectionReason';
import WorkOrderReviewedByLabel from './WorkOrderReviewedByLabel';
import WorkOrderPauseReason from './WorkOrderPauseReason';
import DisplayReviewStatusLabel from '../../Shared/DisplayReviewStatusLabel';
import WorkOrderAssociatedSubJobData from './WorkOrderAssociatedSubJobData';
import WorkOrderLinks from './WorkOrderLinks';
import { getId } from 'acceligent-shared/utils/array';

// Function to compare time allocation entries
const areEntriesDifferent = (prevEntries: TimeAllocationEntryVM[], newEntries: TimeAllocationEntryVM[]) => {
	if (prevEntries.length !== newEntries.length) {
		return true;
	}

	for (let i = 0; i < prevEntries.length; i++) {
		const prevEntry = prevEntries[i];
		const newEntry = newEntries[i];
		if (
			prevEntry.id !== newEntry.id ||
			prevEntry.accountId !== newEntry.accountId ||
			prevEntry.workOrderId !== newEntry.workOrderId ||
			prevEntry.workType !== newEntry.workType ||
			prevEntry.time !== newEntry.time ||
			prevEntry.allocatedWorkRequestId !== newEntry.allocatedWorkRequestId
		) {
			return true;
		}
	}

	return false;
};

interface DropdownOption {
	label: string;
	onClick: () => void;
}

interface OwnProps {
	workOrder: WorkOrderFieldReportCardVM;
	fieldReportAccessDayDuration: number;
	isManagerAdminSI: boolean;
	calculatedReportDisplayStatus: Nullable<CalculatedReportDisplayStatus>;
	canApproveOrReject: boolean;
	canCreate: boolean;
	canSubmit: boolean;
	canFinalize: boolean;
	isCanceled: boolean;
	isFRStatusOfInterest: boolean;
	isReadOnly: boolean;
	createFieldReport: () => void;
	submitForReview: () => void;
	approve: () => void;
	reject: () => void;
	finalize: () => void;
	orgAlias: string;
	companyName: string;
	timeAllocationEntries: TimeAllocationEntryVM[];
	jobId: number;
	redirectToVirtualFieldReport: (subjob: AssociatedSubjobVM) => void;
	associatedWRId: Nullable<number>;
	setShowedAssociatedSubjob: (subjob: Nullable<AssociatedSubjobVM>) => void;
	redirectToFieldReport: () => void;
	redirectToWorkOrder: () => void;
	redirectToJob: (jobId: number) => void;
	reviewVirtual: () => void;
	virtualReportReviewedAt: Nullable<Date>;
	virtualReportLastReviewedBy: Nullable<string>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const WorkOrderInfoCard = (props: Props) => {
	const {
		workOrder,
		fieldReportAccessDayDuration,
		isCanceled,
		calculatedReportDisplayStatus,
		isFRStatusOfInterest,
		canApproveOrReject,
		canFinalize,
		canSubmit,
		isManagerAdminSI,
		isReadOnly,
		canCreate,
		createFieldReport,
		downloadCompleteReportsPdf,
		downloadPublicReportsPdf,
		findOrCreateCompleteReportBulkGroupCode,
		findOrCreatePublicReportLink,
		submitForReview,
		approve,
		reject,
		finalize,
		findWorkRequestsForAssociatedSubjobData,
		timeAllocationEntries,
		jobId,
		findJWSWorkRequestIds,
		setShowedAssociatedSubjob,
		associatedWRId,
		redirectToJob,
		redirectToFieldReport,
		redirectToVirtualFieldReport,
		redirectToWorkOrder,
		reviewVirtual,
		virtualReportReviewedAt,
		virtualReportLastReviewedBy,
	} = props;

	const {
		value: showAccessModal,
		setToTrue: openAccessModal,
		setToFalse: closeAccessModal,
	} = useToggle(false);

	const [allocations, setAllocations] = React.useState<AssociatedSubjobVM[]>([]);

	const prevTimeAllocationEntriesRef = React.useRef(timeAllocationEntries);

	const fetchAssociatedSubJobs = React.useCallback(async () => {
		const allocatedWorkRequestIds = timeAllocationEntries.reduce((_agg, _curr) => {
			if (_curr.allocatedWorkRequestId !== jobId && !_agg.includes(_curr.allocatedWorkRequestId)) {
				_agg.push(_curr.allocatedWorkRequestId);
			}
			return _agg;
		}, [] as number[]);

		const jwsWorkRequestIds = await findJWSWorkRequestIds(workOrder.fieldReportList.map(getId));

		const allocatedJWSWorkRequestIds = jwsWorkRequestIds?.filter((id) => id !== jobId) ?? [];

		const uniqueWorkRequestIds = new Set([...allocatedWorkRequestIds, ...allocatedJWSWorkRequestIds]);

		const _allocations = await findWorkRequestsForAssociatedSubjobData(Array.from(uniqueWorkRequestIds));

		setAllocations(_allocations);
	}, [findJWSWorkRequestIds, findWorkRequestsForAssociatedSubjobData, jobId, timeAllocationEntries, workOrder.fieldReportList]);

	React.useEffect(() => {
		fetchAssociatedSubJobs();
		// Imitating componentDidMount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setShowedAssociatedSubjob(allocations.find((all) => all.id === associatedWRId)!);
	}, [allocations, associatedWRId, setShowedAssociatedSubjob]);

	React.useEffect(() => {
		setShowedAssociatedSubjob(allocations.find((all) => all.id === associatedWRId)!);
	}, [allocations, associatedWRId, setShowedAssociatedSubjob]);

	React.useEffect(() => {
		if (areEntriesDifferent(prevTimeAllocationEntriesRef.current, timeAllocationEntries)) {
			fetchAssociatedSubJobs();
		}
		// Update the ref to the current entries after comparison
		prevTimeAllocationEntriesRef.current = timeAllocationEntries;
	}, [fetchAssociatedSubJobs, timeAllocationEntries]);

	const createReport = React.useCallback(() => {
		createFieldReport();
	}, [createFieldReport]);

	const notificationSnackbar = useNotificationSnackbar();

	const exportToCustomerPDF = React.useCallback(async () => {
		const msg = notificationSnackbar.info('Started preparing for download');
		const publicLink = await findOrCreatePublicReportLink([workOrder.id]);
		if (!!publicLink) {
			notificationSnackbar.removeNotificationSnackbar(msg);
			await downloadPublicReportsPdf(publicLink, `${workOrder.code}_report`, notificationSnackbar);
		}
	}, [downloadPublicReportsPdf, findOrCreatePublicReportLink, workOrder.code, workOrder.id, notificationSnackbar]);

	const exportToCompletePDF = React.useCallback(async () => {
		const msg = notificationSnackbar.info('Started preparing for download');
		const bulkGroupCode = await findOrCreateCompleteReportBulkGroupCode([workOrder.id]);
		if (!!bulkGroupCode) {
			notificationSnackbar.removeNotificationSnackbar(msg);
			await downloadCompleteReportsPdf(bulkGroupCode, `${workOrder.code}_report`, notificationSnackbar);
		}
	}, [notificationSnackbar, findOrCreateCompleteReportBulkGroupCode, workOrder.id, workOrder.code, downloadCompleteReportsPdf]);

	const submitTitle = React.useMemo(() => {
		return isManagerAdminSI && workOrder.reviewStatus === WorkOrderReviewStatus.DRAFT ? 'Submit' : 'Submit for Review';
	}, [isManagerAdminSI, workOrder.reviewStatus]);

	const actionOptions = React.useMemo(() => {

		const options: DropdownOption[] = [];

		if (isManagerAdminSI) {
			options.push({ label: 'Manage Access', onClick: openAccessModal });
		}

		if (!isReadOnly && canCreate && !isCanceled) {
			options.push({ label: 'New Field Report', onClick: createReport });
		}

		if (!isCanceled) {
			options.push({ label: 'Export for Customer', onClick: exportToCustomerPDF });
			options.push({ label: 'Export Complete', onClick: exportToCompletePDF });
		}

		return options;
	}, [canCreate, createReport, exportToCompletePDF, exportToCustomerPDF, isCanceled, isManagerAdminSI, isReadOnly, openAccessModal]);

	const renderDropdownOptionsButton = () => {

		if (!actionOptions.length) {
			return null;
		}

		return (
			<div className={bemElement('field-report-work-order-info-card', 'dropdown-options')}>
				<Dropdown<DropdownOption>
					containerClassName="field-report-list-table__action-dropdown"
					id="actions"
					isActionDropdown={true}
					labelKey="label"
					options={actionOptions}
				/>
			</div>
		);
	};

	const renderButtonsAndActions = () => {
		const isFinalizeDisabled = !isFinalizeable(workOrder.reviewStatus, workOrder.reviewLevel)
			|| !canFinalize || isCanceled || workOrder.isPaused || !!associatedWRId;
		const isSubmitForReviewDisabled = !isReviewable(workOrder.reviewStatus, workOrder.reviewLevel)
			|| !canSubmit || isCanceled || workOrder.isPaused || !!associatedWRId;
		const isApprovalDisabled = !isApprovable(workOrder.reviewStatus)
			|| !canApproveOrReject || isCanceled || workOrder.isPaused || !!associatedWRId;
		const isRejectDisabled = !isRejectable(workOrder.reviewStatus, workOrder.reviewLevel)
			|| !canApproveOrReject || isCanceled || workOrder.isPaused || !!associatedWRId;
		const showReview = !!associatedWRId && (!virtualReportReviewedAt || virtualReportReviewedAt < workOrder.reportLastUpdatedAt!);

		return (
			<div className="field-report-work-order-info-card__header__actions">
				{
					showReview &&
					<Button
						onClick={reviewVirtual}
						variant="info"
					>
						<span className="icon-display_view" />
						Review
					</Button>
				}
				{!!associatedWRId && !showReview &&
					<div className="field-report-work-order-info-card__header__virtual_review_info">
						<div className="field-report-work-order-info-card__header__virtual_reviewed_by">
							Last reviewed {formatDate(virtualReportReviewedAt, TimeFormat.FULL_DATE)}
						</div>
						By {virtualReportLastReviewedBy}
					</div>
				}
				{!isSubmitForReviewDisabled &&
					<Button
						disabled={isSubmitForReviewDisabled}
						onClick={submitForReview}
						variant="primary"
					>
						{submitTitle}
					</Button>
				}
				{!isApprovalDisabled &&
					<Button
						disabled={isApprovalDisabled}
						onClick={approve}
						variant="primary"
					>
						Approve
					</Button>
				}
				{!isRejectDisabled &&
					<Button
						disabled={isRejectDisabled}
						onClick={reject}
						variant="danger"
					>
						Reject
					</Button>
				}
				{!isFinalizeDisabled &&
					<Button
						disabled={isFinalizeDisabled}
						onClick={finalize}
						variant="primary"
					>
						Finalize
					</Button>
				}
				{!associatedWRId && renderDropdownOptionsButton()}
			</div>
		);
	};

	return (
		<div className="form-box field-report-work-order-info-card">
			<div className="field-report-work-order-info-card__header">
				<div className="field-report-work-order-info-card__header__title">
					<DisplayReviewStatusLabel
						calculatedReportDisplayStatus={calculatedReportDisplayStatus}
						isCondensedView={false}
						isOfInterest={isFRStatusOfInterest}
					/>
					{isCanceled &&
						<div className="field-report-work-order-info-card__header__title__canceled">
							CANCELED
						</div>
					}
					<div className="field-report-work-order-info-card__header__title__name">
						{workOrder.code}
					</div>
				</div>
				{renderButtonsAndActions()}
			</div>
			<Row className="row--padded">
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Date of Work"
						value={formatDate(workOrder.dueDate, TimeFormat.SB_DATE_SHORT, TimeFormat.ISO_DATETIME)}
					/>
				</Col>
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Job Title"
						value={workOrder.jobTitle}
					/>
				</Col>
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Location"
						value={workOrder.workLocationAddress}
					/>
				</Col>
				{workOrder.customerWorkOrder &&
					<Col sm={3}>
						<LockedValue
							label="Customer WO"
							value={workOrder.customerWorkOrder}
						/>
					</Col>
				}
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Superintendent"
						value={workOrder.supervisorFullName}
					/>
				</Col>
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Crew Type"
						value={
							workOrder.crewTypeColor
								? <LabelWithColor color={workOrder.crewTypeColor} text={workOrder.crewTypeName} />
								: null
						}
					/>
				</Col>
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Last Update"
						value={
							<LockedValue
								inverse={true}
								label={formatDate(workOrder.updatedAt, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME)}
								value={`by ${workOrder.updatedBy.fullNameShort}`}
							/>
						}
					/>
				</Col>
				<Col sm={3}>
					<WorkOrderReviewedByLabel
						currentReviewHistoryItem={workOrder.currentReviewHistoryItem}
						reviewLevel={workOrder.reviewLevel}
						reviewStatus={workOrder.reviewStatus}
					/>
				</Col>
				{
					associatedWRId &&
					<WorkOrderLinks
						jobId={associatedWRId!}
						redirectToFieldReport={redirectToFieldReport}
						redirectToJob={redirectToJob}
						redirectToWorkOrder={redirectToWorkOrder}
					/>
				}
			</Row>
			<WorkOrderRejectionReason
				workOrderReviewHistoryItem={workOrder.currentReviewHistoryItem}
			/>
			<WorkOrderPauseReason
				pauseReason={workOrder.pauseReason}
			/>
			{
				!associatedWRId
				&& workOrder.isProjectOrSubjob
				&& process.env.FTD_PROJECT !== 'true' &&
				<WorkOrderAssociatedSubJobData
					allocations={allocations}
					redirectToVirtualFieldReport={redirectToVirtualFieldReport}
				/>
			}
			<AccessModal
				close={closeAccessModal}
				fieldReportAccessDayDuration={fieldReportAccessDayDuration}
				showModal={showAccessModal}
				workOrderId={workOrder.id}
			/>
		</div>
	);
};

function mapDispatchToProps() {
	return {
		downloadCompleteReportsPdf: WorkOrderActions.downloadCompleteReportPDF,
		downloadPublicReportsPdf: WorkOrderActions.downloadPublicReportPDF,
		findOrCreateCompleteReportBulkGroupCode: WorkOrderActions.findOrCreateCompleteGroupCode,
		findOrCreatePublicReportLink: WorkOrderActions.findOrCreatePublicGroupCode,
		findWorkRequestsForAssociatedSubjobData: WorkRequestActions.findWorkRequestsForAssociatedSubjobData,
		findJWSWorkRequestIds: JobWorkSummaryActions.findJWSWorkRequestIds,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(WorkOrderInfoCard);
