import * as React from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';

import { RootState } from 'af-reducers';

import * as CompanyActions from 'af-actions/companies';

import { LABOR_SETTINGS } from 'af-constants/reduxForms';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Checkbox from 'af-fields/Checkbox';

import { LaborSettingsRM } from 'ab-requestModels/employee.requestModel';

import LaborSettings, { LaborSettingsHint } from 'ab-enums/laborSettings.enum';

interface OwnProps {
	isVisible: boolean;
	toggleEdit: () => void;
}

interface StateProps {
	initialValues: FormProps['initialValues'];
}

interface DispatchProps {
	editLaborSettings: typeof CompanyActions.editLaborSettings;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

type FormProps = InjectedFormProps<LaborSettingsRM, FormOwnProps>;

type Props = FormOwnProps & FormProps;

class EmployeeStatusSettingsEdit extends React.PureComponent<Props> {

	editLaborSettings = async (form: LaborSettingsRM) => {
		const { toggleEdit, editLaborSettings } = this.props;
		await editLaborSettings(form);
		toggleEdit();
	};

	render() {
		const { isVisible, toggleEdit, handleSubmit, invalid, submitting } = this.props;

		return (
			<CustomModal
				closeModal={toggleEdit}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={toggleEdit}
					title="Edit Labor Settings"
				/>
				<CustomModal.Body className="modal-body--non-padded">
					<Form onSubmit={handleSubmit(this.editLaborSettings)}>
						<Row className="row--padded-bottom">
							<Col sm={24}>
								<Field
									component={Checkbox}
									id="isEmployeeAutomaticReturnDate"
									label={LaborSettings.isEmployeeAutomaticReturnDate}
									name="isEmployeeAutomaticReturnDate"
									tooltipMessage={LaborSettingsHint.isEmployeeAutomaticReturnDate}
								/>
							</Col>
						</Row>
					</Form>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={toggleEdit}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(this.editLaborSettings)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		editLaborSettings: CompanyActions.editLaborSettings,
	};
}

function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;

	return {
		initialValues: { isEmployeeAutomaticReturnDate: company?.isEmployeeAutomaticReturnDate ?? false } as LaborSettingsRM,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<LaborSettingsRM, FormOwnProps>({ form: LABOR_SETTINGS, enableReinitialize: true })
);

export default enhance(EmployeeStatusSettingsEdit);
