import * as React from 'react';
import Markdown from 'react-markdown';

import { createLinkableId } from 'af-utils/userGuideModal.util';
import { ModalLink } from 'af-components/ModalNavigation/ModalRouter';

import { RawPages } from './types';

interface Props {
	mdFile: string;
	icon: string;
	rawPages?: RawPages;
	visitPage?: (page: string) => void;
}

const UserGuide: React.FC<Props> = (props) => {

	const [content, setContent] = React.useState('');

	const { mdFile, icon, rawPages, visitPage } = props;

	React.useEffect(() => {
		if (!rawPages) {
			return;
		}
		if (visitPage) {
			visitPage(mdFile);
		}
		setContent(rawPages[mdFile]);
	}, [mdFile, rawPages, visitPage]);

	const guideId = React.useMemo(() => {
		return createLinkableId(mdFile, 'mainpage');
	}, [mdFile]);

	/**
	 * Render functions
	 */
	const h1 = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		if (!children) {
			throw new Error('Heading 1 - page, has no text');
		}
		const id = createLinkableId(children.toString(), 'page');
		return <div className="user-guide__page-title" {...rest} id={id}>
			<span className={`${icon} page-icon`}></span>
			<div className="page-title-text">{children}</div>
		</div>;
	}, [icon]);
	const h2 = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		if (!children) {
			throw new Error('Heading 2 - subpage, has no text');
		}
		const id = createLinkableId(children.toString(), 'subpage');
		return <div className="user-guide__sub-page-title" {...rest} id={id}>{children}</div>;
	}, []);
	const h3 = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		if (!children) {
			throw new Error('Heading 3 - subtitle, has no text');
		}
		const id = createLinkableId(children.toString(), 'subtitle');
		return <div className="user-guide__sub-title" id={id} {...rest}>{children}</div>;
	}, []);
	const em = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		return <span className="user-guide__blue" {...rest}>{children}</span>;
	}, []);
	const code = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		return <span className="user-guide__orange" {...rest}>{children}</span>;
	}, []);
	const li = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		return <li className="user-guide__li" {...rest}>{children}</li>;
	}, []);
	const a = React.useCallback((_props) => {
		const { href, children } = _props;
		return <ModalLink className="user-guide__link" link={href}>{children}</ModalLink>;
	}, []);
	const img = React.useCallback((_props) => {
		const { alt, src } = _props;
		return <div className="user-guide__image-container"><img alt={alt} src={src} /></div>;
	}, []);
	const p = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		return <div className="user-guide__paragraph" {...rest}>{children}</div>;
	}, []);

	return (
		<div className="user-guide" id={guideId}>
			<Markdown
				components={{ h1, h2, h3, em, code, li, a, img, p }}
			>
				{content}
			</Markdown>
		</div>
	);
};

export default UserGuide;
