import CreateLaborPlaceholderForm from 'ab-requestModels/scheduleBoardCreateLaborPlaceholder.requestModel';

export function validate(values: CreateLaborPlaceholderForm) {
	const errors: ValidationErrors = {};

	if (!values.wageRateId) {
		errors.wageRateId = 'Wage Rate is required';
	}

	return errors;
}
