import { nanoid } from 'nanoid';

import { UpdatedByAccountViewModel } from 'acceligent-shared/dtos/web/view/updatedBy';

import QuantityUnit from 'acceligent-shared/enums/quantityUnit';
import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import OperationType from 'acceligent-shared/enums/operation';
import RepeatableBlockType from 'acceligent-shared/enums/repeatableBlockType';
import ReportBlockType from 'acceligent-shared/enums/reportBlockType';
import ReportTypeBlockType from 'acceligent-shared/enums/reportTypeBlockType';
import { ExtendedColorPalette } from 'acceligent-shared/enums/color';
import DefaultBillableWorkQuantity from 'acceligent-shared/enums/defaultBillableWorkQuantity';
import OperandType from 'acceligent-shared/enums/operand';

import { REVISION_ALPHABET, UNIQUE_ID_SIZE } from 'ab-constants/value';

import BillableWork from 'acceligent-shared/models/billableWork';
import BillableWorkDefinitionField from 'acceligent-shared/models/billableWorkDefinitionField';
import BillableWorkInformationField from 'acceligent-shared/models/billableWorkInformationField';
import CalculatedFieldLookup from 'acceligent-shared/models/calculatedFieldLookup';
import ReportTypeBlock from 'acceligent-shared/models/reportTypeBlock';
import ReportType from 'acceligent-shared/models/reportType';
import ReportBlock from 'acceligent-shared/models/reportBlock';
import ReportBlockField from 'acceligent-shared/models/reportBlockField';

import ReportTypeLocationVM from './locationState.viewModel';

interface IdToVirtualIdAccumulator {
	reportTypeBlockIdToVirtualIdMap: { [id: number]: string; };
	fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; };
}

interface ReportElementsAccumulator {
	ids: string[];
	typeBlockMap: { [virtualId: string]: ReportTypeBlockVM; };
	blockMap: { [virtualId: string]: ReportBlockVM; };
	fieldsMap: { [virtualId: string]: ReportBlockFieldVM; };
}

export class CalculatedFieldOptionsVM {
	virtualId: string;
	index: number;
	type: OperandType;
	constant: Nullable<number>;
	isOperandBlockInPrimarySegment: Nullable<boolean>;

	constructor(
		fieldLookup: CalculatedFieldLookup,
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; },
		fieldSegment: number
	) {
		let virtualId = nanoid(UNIQUE_ID_SIZE);
		if (fieldLookup.operandFieldId) {
			let segmentIndex = fieldSegment;
			if (fieldLookup.isOperandBlockInPrimarySegment !== null) {
				segmentIndex = fieldLookup.isOperandBlockInPrimarySegment ? 0 : 1;
			}
			virtualId = fieldIdToVirtualIdBySegmentMap[segmentIndex][fieldLookup.operandFieldId];
		}
		this.virtualId = virtualId;
		this.type = fieldLookup.type;
		this.constant = fieldLookup.constant;
		this.index = fieldLookup.index;
		this.isOperandBlockInPrimarySegment = fieldLookup.isOperandBlockInPrimarySegment;
	}

	static bulkConstructor(
		fieldLookups: CalculatedFieldLookup[] = [],
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; },
		segment: number
	) {
		return fieldLookups.map((_lookup) => CalculatedFieldOptionsVM._constructorMap(_lookup, fieldIdToVirtualIdBySegmentMap, segment));
	}

	private static _constructorMap = (
		fieldLookup: CalculatedFieldLookup,
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; },
		segment: number
	) => {
		return new CalculatedFieldOptionsVM(fieldLookup, fieldIdToVirtualIdBySegmentMap, segment);
	};
}

export class ReportBlockFieldVM {
	id: number;
	virtualId: string;
	name: string;
	index: Nullable<number>;
	reportBlockVirtualId: string;
	valueType: ReportBlockFieldEnum.ValueType;
	unit: Nullable<QuantityUnit>;
	defaultValue: Nullable<string>;
	dimension: ReportBlockFieldEnum.Dimension;
	isKeyParameter: boolean;
	isRequired: boolean;
	isUnique: boolean;
	fieldType: ReportBlockFieldEnum.Type;
	isVisibleToCustomer: boolean;
	hasTooltip: boolean;
	tooltipText: Nullable<string>;
	options: Nullable<string[]>;
	allowCustomDropdownListValue: boolean;
	operationType: Nullable<OperationType>;
	calculatedFieldOptions: Nullable<CalculatedFieldOptionsVM[]>;
	isDescriptiveTextBold: boolean;
	descriptiveTextColor?: Nullable<ExtendedColorPalette>;
	icon: Nullable<string>;

	constructor(
		field: ReportBlockField,
		reportBlockVirtualId: string,
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; },
		segment: number
	) {
		this.id = field.id;
		this.virtualId = fieldIdToVirtualIdBySegmentMap[segment][field.id];
		this.name = field.name;
		this.index = field.index;
		this.reportBlockVirtualId = reportBlockVirtualId;
		this.valueType = field.valueType;
		this.unit = field.unit;
		this.defaultValue = field.defaultValue;
		this.dimension = field.dimension;
		this.isKeyParameter = field.isKeyParameter;
		this.isRequired = field.isRequired;
		this.isUnique = field.isUnique;
		this.fieldType = field.fieldType;
		this.isVisibleToCustomer = field.isVisibleToCustomer;
		this.hasTooltip = field.hasTooltip;
		this.tooltipText = field.tooltipText;
		this.options = field.options;
		this.allowCustomDropdownListValue = field.allowCustomDropdownListValue;
		this.operationType = field.operationType;
		this.isDescriptiveTextBold = field.isDescriptiveTextBold;
		this.descriptiveTextColor = field.descriptiveTextColor;
		this.icon = field.icon;
		this.calculatedFieldOptions = field.calculatedFieldLookups
			? CalculatedFieldOptionsVM.bulkConstructor(field.calculatedFieldLookups, fieldIdToVirtualIdBySegmentMap, segment)
			: null;
	}

	static bulkConstructor(
		blockFields: ReportBlockField[] = [],
		reportBlockVirtualId: string,
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; },
		segment: number
	): ReportBlockFieldVM[] {
		return blockFields.map((_field) => ReportBlockFieldVM._constructorMap(_field, reportBlockVirtualId, fieldIdToVirtualIdBySegmentMap, segment));
	}

	private static _constructorMap(
		field: ReportBlockField,
		reportBlockVirtualId: string,
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; },
		segment: number
	) { return new ReportBlockFieldVM(field, reportBlockVirtualId, fieldIdToVirtualIdBySegmentMap, segment); }
}

export class ReportBlockVM {
	id: number;
	virtualId: string;
	name: string;
	uniqueId: string;
	completionReportBlockFieldId: Nullable<number>;
	completionReportBlockFieldVirtualId: Nullable<string>;
	isMain: boolean;
	isRepeating: boolean;
	type: ReportBlockType;
	repeatableBlockType: Nullable<RepeatableBlockType>;
	reportBlockFieldIds: string[];

	constructor(block: ReportBlock, virtualId: string, fieldIds: string[], completionReportBlockFieldVirtualId: Nullable<string>) {
		this.id = block.id;
		this.virtualId = virtualId;
		this.name = block.name;
		this.uniqueId = block.uniqueId;
		this.completionReportBlockFieldId = block.completionReportBlockFieldId;
		this.isMain = block.isMain;
		this.isRepeating = block.isRepeating;
		this.type = block.type;
		this.repeatableBlockType = block.repeatableBlockType;
		this.reportBlockFieldIds = fieldIds;
		this.completionReportBlockFieldVirtualId = completionReportBlockFieldVirtualId;
	}
}

export class ReportTypeBlockVM {
	id: number;
	virtualId: string;
	reportTypeId: number;
	reportBlockId: number;
	reportBlockVirtualId: string;
	index: Nullable<number>;
	isPrimary: boolean;
	type: ReportTypeBlockType;

	constructor(
		reportTypeBlock: ReportTypeBlock,
		reportBlockVirtualId: string,
		reportTypeBlockIdToVirtualIdMap: { [id: number]: string; }
	) {
		this.id = reportTypeBlock.id;
		this.virtualId = reportTypeBlockIdToVirtualIdMap[reportTypeBlock.id];
		this.reportTypeId = reportTypeBlock.reportTypeId;
		this.reportBlockId = reportTypeBlock.reportBlockId;
		this.index = reportTypeBlock.index;
		this.isPrimary = reportTypeBlock.isPrimary;
		this.reportBlockVirtualId = reportBlockVirtualId;
		this.type = reportTypeBlock.type;
	}
}

export class BillableWorkVM {
	id: number;
	workName: string;
	isInPrimarySegment: boolean;
	reportTypeId: number;
	workTypeReportBlockFieldVirtualId: string;
	workQuantityReportBlockFieldVirtualId: Nullable<string>;
	defaultQuantity: Nullable<DefaultBillableWorkQuantity>;
	definitionFields: BillableWorkDefinitionFieldVM[];
	informationFields: BillableWorkInformationFieldVM[];

	constructor(billableWork: BillableWork, fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; }) {
		this.id = billableWork.id;
		this.workName = billableWork.workName;
		this.isInPrimarySegment = billableWork.isInPrimarySegment;
		this.reportTypeId = billableWork.reportTypeId;
		this.defaultQuantity = billableWork.defaultQuantity;
		this.workTypeReportBlockFieldVirtualId = fieldIdToVirtualIdBySegmentMap[this.isInPrimarySegment ? 0 : 1][billableWork.workTypeReportBlockFieldId];
		this.workQuantityReportBlockFieldVirtualId = billableWork.workQuantityReportBlockFieldId
			? fieldIdToVirtualIdBySegmentMap[this.isInPrimarySegment ? 0 : 1][billableWork.workQuantityReportBlockFieldId]
			: null;

		this.definitionFields = billableWork.definitionFields
			? BillableWorkDefinitionFieldVM.bulkConstructor(billableWork.definitionFields, fieldIdToVirtualIdBySegmentMap)
			: [];
		this.informationFields = billableWork.informationFields
			? BillableWorkInformationFieldVM.bulkConstructor(billableWork.informationFields, fieldIdToVirtualIdBySegmentMap)
			: [];
	}

	private static _constructorMap = (billableWork: BillableWork, fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; }) => {
		return new BillableWorkVM(billableWork, fieldIdToVirtualIdBySegmentMap);
	};

	static bulkConstructor = (billableWorks: BillableWork[], fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; }) => {
		return billableWorks.map((_work) => BillableWorkVM._constructorMap(_work, fieldIdToVirtualIdBySegmentMap));
	};
}

class BillableWorkDefinitionFieldVM {
	id: number;
	reportBlockFieldVirtualId: string;
	name: string;
	isInPrimarySegment: boolean;

	constructor(definitionField: BillableWorkDefinitionField, fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; }) {
		this.id = definitionField.id;
		this.isInPrimarySegment = definitionField.isInPrimarySegment;
		this.name = definitionField.reportBlockField.name;
		this.reportBlockFieldVirtualId = fieldIdToVirtualIdBySegmentMap[definitionField.isInPrimarySegment ? 0 : 1][definitionField.reportBlockFieldId];
	}

	private static _constructorMap = (
		definitionField: BillableWorkDefinitionField,
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; }
	) => {
		return new BillableWorkDefinitionFieldVM(definitionField, fieldIdToVirtualIdBySegmentMap);
	};

	static bulkConstructor = (
		definitionFields: BillableWorkDefinitionField[],
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; }
	) => {
		return definitionFields.map((_field) => BillableWorkDefinitionFieldVM._constructorMap(_field, fieldIdToVirtualIdBySegmentMap));
	};
}

class BillableWorkInformationFieldVM {
	id: number;
	reportBlockFieldVirtualId: string;
	isInPrimarySegment: boolean;

	constructor(
		informationField: BillableWorkInformationField,
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; }
	) {
		this.id = informationField.id;
		this.isInPrimarySegment = informationField.isInPrimarySegment;
		this.reportBlockFieldVirtualId = fieldIdToVirtualIdBySegmentMap[informationField.isInPrimarySegment ? 0 : 1][informationField.reportBlockFieldId];
	}

	private static _constructorMap = (
		informationField: BillableWorkInformationField,
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; }
	) => {
		return new BillableWorkInformationFieldVM(informationField, fieldIdToVirtualIdBySegmentMap);
	};

	static bulkConstructor = (
		informationFields: BillableWorkInformationField[],
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; }
	) => {
		return informationFields.map((_field) => BillableWorkInformationFieldVM._constructorMap(_field, fieldIdToVirtualIdBySegmentMap));
	};
}

export class ReportTypeVM {
	id: number;
	name: string;
	description: Nullable<string>;
	createdBy: UpdatedByAccountViewModel;
	createdAt: Date;
	updatedBy: UpdatedByAccountViewModel;
	updatedAt: Date;
	revision: string;
	billableWorks: BillableWorkVM[];
	primaryBlockIds: string[];
	secondaryBlockIds: string[];
	upperTotalBlockId: Nullable<string>;
	lowerTotalBlockId: Nullable<string>;
	reportTypeBlocks: { [id: string]: ReportTypeBlockVM; };
	reportBlocks: { [id: string]: ReportBlockVM; };
	reportFields: { [id: string]: ReportBlockFieldVM; };

	constructor(reportType: ReportType) {
		this.id = reportType.id;
		this.name = reportType.name;
		this.description = reportType.description;
		this.createdBy = new UpdatedByAccountViewModel(reportType.createdBy);
		this.createdAt = reportType.createdAt;
		this.updatedBy = new UpdatedByAccountViewModel(reportType.updatedBy);
		this.updatedAt = reportType.updatedAt;
		this.revision = REVISION_ALPHABET[reportType.revision];

		const {
			fieldIdToVirtualIdBySegmentMap,
			reportTypeBlockIdToVirtualIdMap,
		} = reportType.reportTypeBlocks.reduce(
			ReportTypeVM._idToVirtualIdMapReducer,
			{ fieldIdToVirtualIdBySegmentMap: {}, reportTypeBlockIdToVirtualIdMap: {} }
		);

		this.billableWorks = reportType.billableWorks ? BillableWorkVM.bulkConstructor(reportType.billableWorks, fieldIdToVirtualIdBySegmentMap) : [];

		const {
			ids: primaryBlockIds,
			typeBlockMap: primaryTypeBlockMap,
			blockMap: primaryBlockMap,
			fieldsMap: primaryFieldsMap,
		} = reportType.primaryBlocks.reduce(
			ReportTypeVM._getReportElementsReducer(fieldIdToVirtualIdBySegmentMap, reportTypeBlockIdToVirtualIdMap, 0),
			{ ids: [], typeBlockMap: {}, blockMap: {}, fieldsMap: {} }
		);

		const {
			ids: secondaryBlockIds,
			typeBlockMap: secondaryTypeBlockMap,
			blockMap: secondaryBlockMap,
			fieldsMap: secondaryFieldsMap,
		} = reportType.secondaryBlocks.reduce(
			ReportTypeVM._getReportElementsReducer(fieldIdToVirtualIdBySegmentMap, reportTypeBlockIdToVirtualIdMap, 1),
			{ ids: [], typeBlockMap: primaryTypeBlockMap, blockMap: primaryBlockMap, fieldsMap: primaryFieldsMap }
		);

		this.primaryBlockIds = primaryBlockIds;
		this.secondaryBlockIds = secondaryBlockIds;

		if (reportType.upperTotalBlock) {
			const blockVirtualId = nanoid(UNIQUE_ID_SIZE);
			const upperTotalBlockVM = new ReportTypeBlockVM(reportType.upperTotalBlock, blockVirtualId, reportTypeBlockIdToVirtualIdMap);
			const upperBlockFields = ReportBlockFieldVM.bulkConstructor(
				reportType.upperTotalBlock.reportBlock.reportBlockFields,
				blockVirtualId,
				fieldIdToVirtualIdBySegmentMap,
				0
			);
			const fieldIds = upperBlockFields.map((_field) => _field.virtualId);
			const upperBlockVM = new ReportBlockVM(reportType.upperTotalBlock.reportBlock, blockVirtualId, fieldIds, null);

			this.upperTotalBlockId = upperTotalBlockVM.virtualId;
			secondaryTypeBlockMap[upperTotalBlockVM.virtualId] = upperTotalBlockVM;
			secondaryBlockMap[upperBlockVM.virtualId] = upperBlockVM;

			for (const _field of upperBlockFields) {
				secondaryFieldsMap[_field.virtualId] = _field;
			}
		}

		if (reportType.lowerTotalBlock) {
			const blockVirtualId = nanoid(UNIQUE_ID_SIZE);
			const lowerTotalBlockVM = new ReportTypeBlockVM(reportType.lowerTotalBlock, blockVirtualId, reportTypeBlockIdToVirtualIdMap);
			const lowerBlockFields = ReportBlockFieldVM.bulkConstructor(
				reportType.lowerTotalBlock.reportBlock.reportBlockFields,
				blockVirtualId,
				fieldIdToVirtualIdBySegmentMap,
				1
			);
			const fieldIds = lowerBlockFields.map((_field) => _field.virtualId);
			const lowerBlockVM = new ReportBlockVM(reportType.lowerTotalBlock.reportBlock, blockVirtualId, fieldIds, null);

			this.lowerTotalBlockId = lowerTotalBlockVM.virtualId;
			secondaryTypeBlockMap[lowerTotalBlockVM.virtualId] = lowerTotalBlockVM;
			secondaryBlockMap[lowerBlockVM.virtualId] = lowerBlockVM;

			for (const _field of lowerBlockFields) {
				secondaryFieldsMap[_field.virtualId] = _field;
			}
		}

		this.reportTypeBlocks = secondaryTypeBlockMap;
		this.reportBlocks = secondaryBlockMap;
		this.reportFields = secondaryFieldsMap;
	}

	static fromLocationStateVM(reportType: ReportTypeLocationVM): ReportTypeVM {
		return {
			name: reportType?.name,
			description: reportType?.description,
		} as ReportTypeVM;
	}

	private static _getFieldIdToVirtualIdMapReducer = (segment: number) => {
		return (acc: IdToVirtualIdAccumulator, field: ReportBlockField) => {
			const virtualId = nanoid(UNIQUE_ID_SIZE);
			if (!acc.fieldIdToVirtualIdBySegmentMap[segment]) {
				acc.fieldIdToVirtualIdBySegmentMap[segment] = {};
			}
			acc.fieldIdToVirtualIdBySegmentMap[segment][field.id] = virtualId;
			return acc;
		};
	};

	private static _idToVirtualIdMapReducer = (acc: IdToVirtualIdAccumulator, reportTypeBlock: ReportTypeBlock) => {
		const reportTypeBlockVirtualId = nanoid(UNIQUE_ID_SIZE);
		if (!acc.reportTypeBlockIdToVirtualIdMap[reportTypeBlock.id]) {
			acc.reportTypeBlockIdToVirtualIdMap[reportTypeBlock.id] = reportTypeBlockVirtualId;
		}
		reportTypeBlock.reportBlock.reportBlockFields.reduce(ReportTypeVM._getFieldIdToVirtualIdMapReducer(reportTypeBlock.isPrimary ? 0 : 1), acc);
		return acc;
	};

	private static _getReportElementsReducer = (
		fieldIdToVirtualIdBySegmentMap: { [segment: number]: { [id: number]: string; }; },
		reportTypeBlockIdToVirtualIdMap: { [id: number]: string; },
		segment: number
	) => {
		return (acc: ReportElementsAccumulator, reportTypeBlock: ReportTypeBlock) => {
			const blockVirtualId = nanoid(UNIQUE_ID_SIZE);

			const typeBlockVM = new ReportTypeBlockVM(reportTypeBlock, blockVirtualId, reportTypeBlockIdToVirtualIdMap);
			acc.ids.push(typeBlockVM.virtualId);
			acc.typeBlockMap[typeBlockVM.virtualId] = typeBlockVM;

			const fieldVMs = ReportBlockFieldVM.bulkConstructor(
				reportTypeBlock.reportBlock.reportBlockFields,
				blockVirtualId,
				fieldIdToVirtualIdBySegmentMap,
				segment
			);
			const fieldIds = fieldVMs.map((_field) => _field.virtualId);
			let completionFieldVirtualId: Nullable<string> = null;

			for (const _field of fieldVMs) {
				acc.fieldsMap[_field.virtualId] = _field;
				if (_field.fieldType === ReportBlockFieldEnum.Type.COMPLETION) {
					completionFieldVirtualId = _field.virtualId;
				}
			}

			const blockVM = new ReportBlockVM(reportTypeBlock.reportBlock, blockVirtualId, fieldIds, completionFieldVirtualId);
			acc.blockMap[blockVM.virtualId] = blockVM;

			return acc;
		};
	};
}
