import * as React from 'react';

import styles from './styles.module.scss';

interface Props {
	title?: string;
}

const FormSection = (props: React.PropsWithChildren<Props>) => {
	const { children, title } = props;

	return (
		<div className={styles.form__section}>
			{!!title && <div className={styles['form__section-title']}>{title}</div>}
			{children}
		</div>
	);
};

export default React.memo(FormSection);
