import * as React from 'react';
import { ButtonProps } from 'react-bootstrap';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: any;
	onClick?: (event: React.MouseEvent<React.ClassicComponent<ButtonProps>>) => Promise<void>;
	disabled?: boolean;
	variant?: string;
	activeClassName?: string;
	inactiveClassName?: string;
	element?: JSX.Element;
	disabledClassName?: string;
	activeElement?: JSX.Element;
	inactiveElement?: JSX.Element;
	disabledElement?: JSX.Element;
}

interface State {
	actionActive: boolean;
}

export default class DisabledOnAction extends React.Component<Props, State> {
	state: State = {
		actionActive: false,
	};

	private _mounted = false;

	componentDidMount() {
		this._mounted = true;
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	action = (e: React.MouseEvent<React.ClassicComponent<ButtonProps, unknown>, MouseEvent>) => {
		e.preventDefault();
		const { onClick } = this.props;
		this.setState(() => ({ actionActive: true }), async () => {
			await onClick?.(e as React.MouseEvent<React.ClassicComponent<ButtonProps>>);
			if (this._mounted) {
				this.setState(() => ({ actionActive: false }));
			}
		});
	};

	render() {
		const { component } = this.props;
		const { actionActive } = this.state;

		return React.createElement(component, { ...this.props, actionActive, action: this.action });
	}

}
