import * as React from 'react';

import { previewSectionClass, previewSectionClassColumn2, previewSectionClassColumn4, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';
import LockedValue from 'af-components/LockedValue';

type Props = {
	waterOrWetlands: boolean;
	waterOrWetlandsDescription: Nullable<string>;
	stormDrain: boolean;
	stormDrainDescription: Nullable<string>;
	bypassPumping: boolean;
	bypassPumpingDescription: Nullable<string>;
	sensitiveEnvironment: boolean;
	sensitiveEnvironmentDescription: Nullable<string>;
};

const EnvironmentalProtectionSection: React.FC<Props> = (props) => {
	const {
		bypassPumping,
		bypassPumpingDescription,
		sensitiveEnvironment,
		sensitiveEnvironmentDescription,
		stormDrain,
		stormDrainDescription,
		waterOrWetlands,
		waterOrWetlandsDescription,
	} = props;

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Environmental Protection
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Water/Wetlands" value={waterOrWetlands ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={waterOrWetlandsDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Storm Drain" value={stormDrain ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={stormDrainDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Bypass Pumping" value={bypassPumping ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={bypassPumpingDescription} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Sensitive Environment" value={sensitiveEnvironment ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Description" value={sensitiveEnvironmentDescription} />
				</div>
			</div>
		</div>
	);
};

export default React.memo(EnvironmentalProtectionSection);
