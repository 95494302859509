import * as React from 'react';

interface OwnProps {
	invalid: boolean;
	value: Nullable<string>;
}

type Props = OwnProps;

const RejectReason: React.FC<Props> = ({ invalid, value }) => {
	return (
		<td>
			<span className={`user-upsert__${invalid ? 'rejected' : 'accepted'}-param`}>
				{value ?? 'N/A'}
			</span>
		</td>
	);
};

export default React.memo(RejectReason);
