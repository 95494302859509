function normalizeNumberValue(value: number | string): Nullable<number> {
	const val: number = (typeof value !== 'number') ? parseFloat(value) : value;
	return !isNaN(val) ? (value as number) : null;
}

export const isEmptyNumber = (value: null | undefined | number | string): boolean => {
	return value !== 0 && !value;
};

export const isValidNumber = (value: undefined | null | number | string): boolean =>
	value !== null
	&& value !== undefined
	&& normalizeNumberValue(value) !== null;

/** Useful for checking indexes */
export const isValidInteger = (value: number | string | undefined | null) => isValidNumber(value) && Number.isInteger(+value!);

/** Useful for checking most (autoincrement) database ids */
export const isValidPositiveInteger = (value: number | string | undefined | null): value is number | string => (
	!!value && Number.isInteger(+value) && +value > 0
);
