import * as React from 'react';

import TextHighlight from 'af-components/TextHighlight';
import ColorSquare from 'af-components/ColorSquare';
import { ColorPalette } from 'acceligent-shared/enums/color';

interface OwnProps {
	code: string;
	specification: Nullable<string>;
	/** Equipment cost */
	equipmentCostCategoryColor: Nullable<ColorPalette>;
	searchText: string;
}

type Props = OwnProps;

export default class EquipmentOptionItem extends React.PureComponent<Props> {

	renderName = () => {
		const { code, specification, searchText } = this.props;
		return (
			<div>
				<span className="resource-lookup__resource-name">
					<TextHighlight searchText={searchText} text={code} />
				</span>
				{
					specification &&
					<span className="resource-lookup__resource-info">
						(<TextHighlight searchText={searchText} text={specification} />)
					</span>
				}
			</div>
		);
	};

	render() {
		const { equipmentCostCategoryColor } = this.props;

		return (
			<span className="resource-lookup__resource resource-lookup__resource--equipment">
				<div className="resource-lookup__resource-details-equipment">
					{equipmentCostCategoryColor && <ColorSquare color={equipmentCostCategoryColor} />}
					{this.renderName()}
				</div>
			</span>
		);
	}
}
