import * as React from 'react';

import LockedValue from 'af-components/LockedValue';

import { previewSectionClass, previewSectionClassColumn2, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';
import { PreviewContactVM } from 'ab-viewModels/workRequest/workRequestPreviewDetails.viewModel';

type Props = {
	billingContact: Nullable<PreviewContactVM>;
	siteContact: Nullable<PreviewContactVM>;
	customerContact: Nullable<PreviewContactVM>;
	billTo: Nullable<string>;
	billToAddress: Nullable<string>;
	billToCityStateZip: Nullable<string>;
	billToContactName: Nullable<string>;
	billToPhoneNumber: Nullable<string>;
	billToFaxNumber: Nullable<string>;
	billToEmailAddress: Nullable<string>;
	additionalContacts: PreviewContactVM[];
};

const _valuesMapper = (_method: string) => _method;

const ContactsSection: React.FC<Props> = (props) => {
	const {
		billingContact,
		customerContact,
		siteContact,
		billTo,
		billToAddress,
		billToCityStateZip,
		billToContactName,
		billToEmailAddress,
		billToFaxNumber,
		billToPhoneNumber,
		additionalContacts,
	} = props;

	const renderValues = React.useCallback((values: string[]) => values.map((_value, _i) => <div key={_i}>{_value}</div>), []);

	const renderContact = React.useCallback((contact: Nullable<PreviewContactVM>, label: string) => {
		return (
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label={label} value={contact?.fullName} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue
						label="E-mail"
						value={
							contact?.emails.length ?
								renderValues(contact?.emails.map(_valuesMapper))
								: null
						}
					/>
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue
						label="Phone"
						value={
							contact?.phones.length
								? renderValues(contact?.phones.map(_valuesMapper))
								: null
						}
					/>
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Address"
						value={
							contact?.addresses.length ?
								renderValues(contact.addresses.map(_valuesMapper))
								: null
						}
					/>
				</div>
			</div>
		);
	}, [renderValues]);

	const renderAdditionalContacts = React.useCallback((_ac, _index) => renderContact(_ac, `Additional Contact ${_index + 1}`), [renderContact]);

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Contacts
			</div>
			{renderContact(customerContact, 'Customer Contact')}
			{renderContact(siteContact, 'Site Contact')}
			{renderContact(billingContact, 'Billing Contact')}
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Bill To" value={billTo} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Address" value={billToAddress} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="City, State, Zip" value={billToCityStateZip} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Contact Name" value={billToContactName} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Phone Number" value={billToPhoneNumber} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Fax Number" value={billToFaxNumber} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Email Address" value={billToEmailAddress} />
				</div>
			</div>
			{additionalContacts.map(renderAdditionalContacts)}
		</div>
	);
};

export default React.memo(ContactsSection);
