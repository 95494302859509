import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from 'af-reducers';

import { ResourcesGroupItem } from 'ab-viewModels/scheduleBoardToolbarResources.viewModel';

import EquipmentGroup from './EquipmentGroup';

import { EquipmentModalProps } from '../../../Shared/ModalProps';

interface OwnProps extends EquipmentModalProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

interface StateProps {
	equipmentStatuses: ResourcesGroupItem[] | undefined;
}

type Props = OwnProps & StateProps;

class UnavailableEquipment extends React.Component<Props> {
	static defaultProps: Partial<Props> = {
		equipmentStatuses: [],
	};

	render() {
		const {
			dueDate,
			equipmentStatuses,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			setEquipmentModalData,
			setEquipmentModalVisibility,
		} = this.props;

		if (!equipmentStatuses?.length) {
			return null;
		}

		return (
			<div className="schedule-board-toolbar-equipment-row">
				<label>Equipment Unavailable</label>
				{
					equipmentStatuses.map(({ id: _eqStatusId, title: _eqStatus, isDeleted }) =>
					(
						<div className="schedule-board-toolbar-equipment-wrapper unavailable" key={_eqStatusId}>
							<div className="schedule-board-toolbar-equipment unavailable">
								<EquipmentGroup
									available={false}
									dueDate={dueDate}
									groupId={_eqStatusId}
									groupTitle={_eqStatus}
									hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
									hasReason={true}
									isDeleted={isDeleted}
									isDragAndDropDisabled={isDragAndDropDisabled}
									setEquipmentModalData={setEquipmentModalData}
									setEquipmentModalVisibility={setEquipmentModalVisibility}
								/>
							</div>
						</div>
					))
				}
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	return {
		equipmentStatuses: state?.scheduleBoard?.toolbarEquipmentGroupTitles?.unavailable,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(UnavailableEquipment);
