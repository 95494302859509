import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import AccountPermissionTemplate from 'acceligent-shared/enums/accountPermissionTemplate';
import AccountPermission from 'acceligent-shared/enums/accountPermission';

import { AccountPermissionTemplateOptions, AccountPermissionTemplates } from 'ab-enums/accountPermissionTemplates.enum';

import Checkbox from 'af-fields/Checkbox';

import PermissionGroup from './PermissionGroup';
import Tooltip, { Props as TooltipProps } from './Tooltip';
import { GROUPS, IMPLICATIONS } from './constants';

interface Props {
	change: (field: string, value: boolean | (typeof AccountPermissionTemplateOptions)[0]) => void;
	permissions: Record<AccountPermission, boolean>;
	isCompanyAdmin: boolean;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsAccounting: boolean;
	assignableAsManagement: boolean;
	assignableAsTechnician: boolean;
	fieldBase?: string;
	toggleAll: (value: boolean) => void;
}

class MemberPermissions extends React.PureComponent<Props> {

	static tooltip = (flag: TooltipProps['flag']) => <Tooltip flag={flag} />;

	selectAll = () => this.props.toggleAll(true);
	resetAll = () => this.props.toggleAll(false);

	selectPermissionsForTemplate = (template: AccountPermissionTemplate) => {
		const { change } = this.props;

		AccountPermissionTemplates[template].forEach((_permission) => {
			change(`permissions.${AccountPermission[_permission]}`, true);

			if (IMPLICATIONS[_permission]) {
				for (const consequentPermission of (IMPLICATIONS[_permission] ?? [])) {
					change(`permissions.${consequentPermission}`, true);
				}
			}
		});
	};

	renderGroup = (group: GROUPS, index: number) => {
		const {
			change,
			permissions,
			assignableToWorkOrder,
			assignableAsSuperintendent,
			assignableAsAccounting,
			assignableAsManagement,
			assignableAsTechnician,
			isCompanyAdmin,
			fieldBase,
		} = this.props;
		return (
			<PermissionGroup
				assignableAsAccounting={assignableAsAccounting}
				assignableAsManagement={assignableAsManagement}
				assignableAsSuperintendent={assignableAsSuperintendent}
				assignableAsTechnician={assignableAsTechnician}
				assignableToWorkOrder={assignableToWorkOrder}
				change={change}
				disabled={isCompanyAdmin}
				fieldBase={fieldBase}
				group={GROUPS[group]}
				key={index}
				permissions={permissions}
			/>
		);
	};

	onAssignableToWorkOrderSelect = (value: boolean) => {
		if (value) {
			this.selectPermissionsForTemplate(AccountPermissionTemplate.FIELD_WORKER);
		}
	};

	onAssignableAsSuperintendentSelect = (value: boolean) => {
		if (value) {
			this.selectPermissionsForTemplate(AccountPermissionTemplate.SUPERINTENDENT);
		}
	};

	onAssignableAsProjectManagerSelect = (value: boolean) => {
		if (value) {
			this.selectPermissionsForTemplate(AccountPermissionTemplate.PROJECT_MANAGER);
		}
	};

	onAssignableAsQAQCSelect = (value: boolean) => {
		if (value) {
			this.selectPermissionsForTemplate(AccountPermissionTemplate.DELIVERABLE);
		}
	};

	onAssignableAsTechnicianSelect = (value: boolean) => {
		const { change } = this.props;
		if (value) {
			change(`permissions.${AccountPermission.TR_MANAGE}`, true);
			change(`permissions.${AccountPermission.TR_CREATE}`, true);
			change(`permissions.${AccountPermission.OR_CREATE}`, true);
			change(`permissions.${AccountPermission.PO_CREATE}`, true);
		}
	};

	onAssignableAsManagementSelect = (value: boolean) => {
		const { change } = this.props;
		if (value) {
			change('assignableAsProjectManager', true);
			change(`permissions.${AccountPermission.FR_MANAGEMENT_MANAGE}`, true);
			change(`permissions.${AccountPermission.FR_FILL}`, true);
			change(`permissions.${AccountPermission.FR_VIEW_ALL}`, true);
			change(`permissions.${AccountPermission.OR_CREATE}`, true);
			change(`permissions.${AccountPermission.PO_CREATE}`, true);
			this.selectPermissionsForTemplate(AccountPermissionTemplate.PROJECT_MANAGER);
		}
	};

	onAssignableAsAccountingSelect = (value: boolean) => {
		if (value) {
			this.selectPermissionsForTemplate(AccountPermissionTemplate.FINANCE);
		}
	};

	render() {
		const { fieldBase } = this.props;
		const field = fieldBase ? `${fieldBase}.` : '';
		return (
			<>
				<Row>
					<Col sm={6}>
						<Field
							component={Checkbox}
							id="assigned-field-worker"
							label="Assignable to work order"
							name={`${field}assignableToWorkOrder`}
							onValueChange={this.onAssignableToWorkOrderSelect}
							tooltipMessage={MemberPermissions.tooltip('assignableToWorkOrder')}
						/>
					</Col>
					<Col sm={6}>
						<Field
							component={Checkbox}
							id="assigned-superintendent"
							label="Assignable as superintendent"
							name={`${field}assignableAsSuperintendent`}
							onValueChange={this.onAssignableAsSuperintendentSelect}
							tooltipMessage={MemberPermissions.tooltip('assignableAsSuperintendent')}
						/>
					</Col>
					<Col sm={6}>
						<Field
							component={Checkbox}
							id="assigned-project-manager"
							label="Assignable as project manager"
							name={`${field}assignableAsProjectManager`}
							onValueChange={this.onAssignableAsProjectManagerSelect}
							tooltipMessage={MemberPermissions.tooltip('assignableAsProjectManager')}
						/>
					</Col>
				</Row>
				<Row className="row--padded-bottom">
					<Col sm={6}>
						<Field
							component={Checkbox}
							id="assigned-management"
							label="Assignable as Management"
							name={`${field}assignableAsManagement`}
							onValueChange={this.onAssignableAsManagementSelect}
							tooltipMessage={MemberPermissions.tooltip('assignableAsManagement')}
						/>
					</Col>
					<Col sm={6}>
						<Field
							component={Checkbox}
							id="assigned-accounting"
							label="Assignable as Accounting"
							name={`${field}assignableAsAccounting`}
							onValueChange={this.onAssignableAsAccountingSelect}
							tooltipMessage={MemberPermissions.tooltip('assignableAsAccounting')}
						/>
					</Col>
					<Col sm={6}>
						<Field
							component={Checkbox}
							id="assigned-qaqc"
							label="Assignable as QAQC"
							name={`${field}assignableAsQAQC`}
							onValueChange={this.onAssignableAsQAQCSelect}
							tooltipMessage={MemberPermissions.tooltip('assignableAsQAQC')}
						/>
					</Col>
					<Col sm={6}>
						<Field
							component={Checkbox}
							id="assigned-tech"
							label="Assignable as Technician"
							name={`${field}assignableAsTechnician`}
							onValueChange={this.onAssignableAsTechnicianSelect}
							tooltipMessage={MemberPermissions.tooltip('assignableAsTechnician')}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<span className="member-permissions__control" onClick={this.selectAll}>Select All</span>
						<span className="member-permissions__control" onClick={this.resetAll}>Reset All</span>
					</Col>
					{Object.keys(GROUPS).map(this.renderGroup)}
				</Row>
			</>
		);
	}
}

export default MemberPermissions;
