import { isValidTextInput } from 'acceligent-shared/utils/text';

import DivisionRequestModel from 'ab-requestModels/division.requestModel';

export function validate(values: DivisionRequestModel) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name)) {
		errors.name = 'Name is required';
	}

	return errors;
}
