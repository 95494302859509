import * as React from 'react';

export default () => {
	return (
		<div className="labor-statistics --loading">
			<div className="loading-col-4 loading-box-area" />
			<div className="loading-col-4 loading-box-area" />
			<div className="loading-col-4 loading-box-area" />
			<div className="loading-col-4 loading-box-area" />
		</div>
	);
};
