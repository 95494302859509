import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

const BillingCodeHeader: React.FC = React.memo(() => ((
	<Row className="job-upsert__billing-jobs__list__header">
		<Col sm={22}>
			<Col sm={2}>Line Item No.</Col>
			<Col sm={2}>Customer No.</Col>
			<Col sm={2}>Customer ID</Col>
			<Col sm={2}>Owner No.</Col>
			<Col sm={2}>Owner ID</Col>
			<Col sm={2}>Bid Qty</Col>
			<Col sm={2}>Unit</Col>
			<Col sm={2}>Unit Price</Col>
			<Col sm={2}>Group</Col>
			<Col sm={4}>Description</Col>
		</Col>
	</Row>
)));

export default BillingCodeHeader;
