export const MAX_EMPLOYEE_ASSIGNMENTS_PER_DAY = 5;
export const MAX_EQUIPMENT_ASSIGNMENTS_PER_DAY = 5;

export const SCHEDULE_BOARD_MAX_ITEMS_IN_ROW = 12;
export const SCHEDULE_BOARD_MAX_TOOLBAR_AVAILABLE_EQUIPMENT_COLUMNS = 2;
export const SCHEDULE_BOARD_MAX_TOOLBAR_AVAILABLE_EMPLOYEE_COLUMNS = 2;

export const RESOURCE_PLACEHOLDER = -1;
export const EQUIPMENT_PLACEHOLDER = -1;
export const EMPLOYEE_PLACEHOLDER = -1;

export const TOOLBAR_GROUP_DEFAULT_ID = -1;
export const MECHANIC_VIEW_DEFAULT_ID = -2;

export const SCHEDULE_BOARD_TOOLBAR_AVAILABLE = 'available';
export const SCHEDULE_BOARD_TOOLBAR_UNAVAILABLE = 'unavailable';

export const DEFAULT_SCHEDULE_BOARD_ZOOM_LEVEL = 0;
export const MAX_SCHEDULE_BOARD_ZOOM_LEVEL = 2;
export const MIN_SCHEDULE_BOARD_ZOOM_LEVEL = 0;

export const DEFAULT_DISPLAY_VIEW_CHANGE_TIME = 1080; // 6pm
export const DEFAULT_DISPLAY_VIEW_ROTATION_TIME = 15; // 15 seconds

export const WORK_ORDERS_PER_ZOOM_LEVEL = {
	0: 20,
	1: 10,
	2: 5,
};
