import AccountingFSN from './AccountingFSN';
import AnalyticsFSN from './AnalyticsFSN';
import CommunicationFSN from './CommunicationFSN';
import OrdersFSN from './OrdersFSN';
import OrganizationResourcesFSN from './OrganizationResourcesFSN';
import OrganizationSettingsFSN from './OrganizationSettingsFSN';
import ResourcesFSN from './ResourcesFSN';
import SettingsFSN from './SettingsFSN';

export const Accounting = AccountingFSN;
export const Analytics = AnalyticsFSN;
export const Communication = CommunicationFSN;
export const Orders = OrdersFSN;
export const OrganizationResources = OrganizationResourcesFSN;
export const OrganizationSettings = OrganizationSettingsFSN;
export const Resources = ResourcesFSN;
export const Settings = SettingsFSN;
