import * as React from 'react';

interface NavigationData {
	/**
	 * URL of the path that is being routed to. Be carefull when using inside useEffect hook as the value is updated after components are rendered.
	 * That means that useEffect will fire two times, one initial with old value and one new one with updated value.
	 */
	to: string;
	/**
	 * URL of the path that is being routed away from. Be carefull when using inside useEffect hook as the value is updated after components are rendered.
	 * That means that useEffect will fire two times, one initial with the old value and a new one with the updated value.
	 */
	from: string;
	/**
	 * Attempts to fire effects on routing.
	 * Expects a function that returns true when its effects are fired. After firing the effect it will be unregistered.
	 * Be carefull not to fire any effects if the function does not return true as it will fire those effects for any routing.
	 */
	pathChangedSideEffects: Array<(to: string, from: string) => boolean>;
}

export const NavigationContext = React.createContext<NavigationData>({ to: '', from: '', pathChangedSideEffects: [] });
