interface AddressRequestObject {
	id?: number;
	aa1: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	country: Nullable<string>;
	/** defaults to 0 */
	latitude: Nullable<number>;
	/** defaults to 0 */
	longitude: Nullable<number>;
	locality: Nullable<string>;
	postalCode: Nullable<string>;
	route: Nullable<string>;
	street: Nullable<string>;
	streetNumber: Nullable<string>;
	suite: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
}

export function isValidAddressRequestObject(values: Nullable<Partial<AddressRequestObject>>, errorPrefix: string) {
	const errors: ValidationErrors = {};

	// if null is accidentally passed
	errorPrefix = errorPrefix ?? '';

	if (!values?.street) {
		const streetErrorMessage = (errorPrefix + ' street is required').trim();
		errors.street = streetErrorMessage[0].toUpperCase() + streetErrorMessage.substring(1);
	}

	if (!values?.aa1) {
		const aa1ErrorMessage = (errorPrefix + ' state is required').trim();
		errors.aa1 = aa1ErrorMessage[0].toUpperCase() + aa1ErrorMessage.substring(1);
	}

	if (!values?.locality) {
		const localityErrorMessage = (errorPrefix + ' city is required').trim();
		errors.locality = localityErrorMessage[0].toUpperCase() + localityErrorMessage.substring(1);
	}

	if (!values?.country) {
		const countryErrorMessage = (errorPrefix + ' country is required').trim();
		errors.country = countryErrorMessage[0].toUpperCase() + countryErrorMessage.substring(1);
	}

	if (!values?.postalCode) {
		const zipCodeErrorMessage = (errorPrefix + ' zip code is required').trim();
		errors.postalCode = zipCodeErrorMessage[0].toUpperCase() + zipCodeErrorMessage.substring(1);
	}

	return {
		isValid: Object.keys(errors).length === 0,
		errors,
	};
}
