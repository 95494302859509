import * as React from 'react';

import { dollarFormatter } from 'af-utils/format.util';

import styles from './styles.module.scss';

interface Props {
	totalRevenue: number;
}

const TableHeader: React.FC<Props> = ({ totalRevenue }) => {
	return (
		<div className={styles['job-work-summary__table-header']}>
			<span>
				Total Billable:
			</span>
			<b>
				{dollarFormatter.format(totalRevenue)}
			</b>
		</div>
	);
};

export default React.memo(TableHeader);
