import * as UserRequestModel from 'ab-requestModels/users.requestModel';

export default function validate(values: UserRequestModel.UserPhoneCode) {
	const errors: UserRequestModel.UserPhoneCode = {} as UserRequestModel.UserPhoneCode;

	if (!values.activationCode) {
		errors.activationCode = 'Code is required';
	}

	return errors;
}
