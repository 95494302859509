import * as React from 'react';

import { WorkOrderEmployeeVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import Checkbox from 'af-components/Controls/Checkbox';

interface OwnProps {
	change: (key: string, value: Nullable<number[]>) => void;
	employee: WorkOrderEmployeeVM;
	index: number;
	name: string;
	selector: (key: string) => number[];
}

type Props = OwnProps;

const WorkOrderEmployeeCheckbox: React.FC<Props> = (props: Props) => {
	const { employee, selector, name, change } = props;

	const renderLabel = () => {
		return (
			<span>
				{employee.fullName}
				{employee.isLastSignee &&
					<span className="work-order-report-reject-modal__signee">Report Signee</span>
				}
			</span>
		);
	};

	const getFieldIndex = React.useCallback(() => {
		const fields = selector(name);

		return fields?.findIndex((_field) => _field === employee.id) ?? -1;
	}, [selector, employee, name]);

	const onCheck = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked = e.target.checked;
		const fields = selector(name) ?? [];

		if (isChecked) {
			fields.push(employee.id);
		} else {
			const employeeIndex = getFieldIndex();
			if (employeeIndex >= 0) {
				fields.splice(employeeIndex, 1);
			}
		}
		change(name, fields.length ? fields : null);
	}, [name, selector, change, getFieldIndex, employee]);

	const isChecked = getFieldIndex() >= 0;

	return (
		<Checkbox
			handleChange={onCheck}
			id={employee.id.toString()}
			isChecked={isChecked}
			isStandalone={true}
			label={renderLabel()}
		/>
	);
};

export default React.memo(WorkOrderEmployeeCheckbox);
