import * as React from 'react';

import Pill from './Pill';

interface OwnProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: any;
	labelKey: string;
	emptyLabel: string;
	modalTitle: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	modalBody: (original: any) => JSX.Element;
	destructiveAction: (index: number) => void;
	skipConfirmationModal?: boolean;
	isField?: boolean;
}

type Props = OwnProps;

class PillsList extends React.PureComponent<Props> {

	render() {
		const {
			items,
			destructiveAction,
			skipConfirmationModal,
			labelKey,
			modalBody,
			modalTitle,
			emptyLabel,
		} = this.props;

		return (
			<div className="pills">
				{!!items.length && items.map((_item: Metadata, _index: number) =>
					<Pill
						index={_index}
						item={items.get(_index)}
						key={`pill#${_index}`}
						labelKey={labelKey}
						modalBody={modalBody}
						modalTitle={modalTitle}
						removeItem={destructiveAction}
						skipConfirmationModal={skipConfirmationModal}
					/>
				)}
				{!items.length &&
					<div className="pills__empty">{emptyLabel}</div>
				}
			</div>
		);
	}
}

export default PillsList;
