import * as React from 'react';

import { TimeSheetVM } from 'acceligent-shared/dtos/web/view/timeSheet/timeSheet';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { OverlapMeta } from 'acceligent-shared/utils/timeSheetEntry';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import TimelineEntityType from 'acceligent-shared/enums/timelineEntityType';

import { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';

import { bemElement } from 'ab-utils/bem.util';

import TimelineBarSection from './TimelineBarSection';

const EMPTY_OBJECT = {
	startTime: false,
	endTime: false,
	occupied: false,
};

interface Props {
	entities: TimelineEntitesForAccount[];
	overlaps: Nullable<Record<string, OverlapMeta>>;
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	superintendentApprovalStatus: TimeSheetVM['superintendentApprovalStatus'];
	isManagerOrAdmin: boolean;
}

const entitiesMapper = (
	entities: TimelineEntitesForAccount[],
	overlaps: Nullable<Record<string, OverlapMeta>>,
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'],
	superintendentApprovalStatus: TimeSheetVM['superintendentApprovalStatus'],
	isManagerOrAdmin: boolean,
	totalDurationInMinutesWithoutGaps: number
) => {
	return (tse: TimelineEntitesForAccount, _index: number) => {
		let percentageOfTimeline: Nullable<number>;
		let prevEntryIsGap = false;
		let nextEntryIsGap = false;
		let hasOverlap = false;
		if (tse.type !== TimelineEntityType.GAP) {
			const endTime = tse.entry.endTime ?? TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME);
			percentageOfTimeline = TimeUtils.getDiff(endTime, tse.entry.startTime, 'minutes', TimeFormat.ISO_DATETIME) / totalDurationInMinutesWithoutGaps;
			prevEntryIsGap = entities[_index - 1]?.type === TimelineEntityType.GAP;
			nextEntryIsGap = entities[_index + 1]?.type === TimelineEntityType.GAP;
			const {
				startTime: overlapStartTime = false,
				endTime: overlapEndTime = false,
				occupied: overlaptOccupied = false,
			} = overlaps?.[tse.entry.id] ?? EMPTY_OBJECT;
			hasOverlap = overlapStartTime || overlapEndTime || overlaptOccupied;
		} else {
			percentageOfTimeline = 0;
		}
		percentageOfTimeline *= 100;

		return (
			<TimelineBarSection
				employeeApprovalStatus={employeeApprovalStatus}
				entity={tse}
				isManagerOrAdmin={isManagerOrAdmin}
				key={`${tse.type}#${_index}#${percentageOfTimeline}`}
				overlap={hasOverlap}
				percentageOfTimeline={percentageOfTimeline}
				roundedLeft={prevEntryIsGap || (_index === 0)}
				roundedRight={nextEntryIsGap || (_index === entities.length - 1)}
				superintendentApprovalStatus={superintendentApprovalStatus}
			/>
		);
	};
};

const totalDurationWithoutGapReducer = (_totalDuration: number, _currEntity: TimelineEntitesForAccount) => {
	if (_currEntity.type === TimelineEntityType.GAP) {
		return _totalDuration;
	}

	const endTime = _currEntity.entry.endTime ?? TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME);
	return _totalDuration + TimeUtils.getDiff(endTime, _currEntity.entry.startTime, 'minutes', TimeFormat.ISO_DATETIME);
};

const TimelineBar: React.FC<Props> = (props: Props) => {
	const { entities, overlaps, employeeApprovalStatus, isManagerOrAdmin, superintendentApprovalStatus } = props;

	const totalDurationInMinutesWithoutGaps = React.useMemo(() => entities.reduce<number>(totalDurationWithoutGapReducer, 0), [entities]);

	return (
		<div className={bemElement('time-sheet-list__row__timeline', 'bar')}>
			{entities.map(entitiesMapper(
				entities, overlaps, employeeApprovalStatus, superintendentApprovalStatus, isManagerOrAdmin, totalDurationInMinutesWithoutGaps
			))}
		</div>
	);
};

export default TimelineBar;
