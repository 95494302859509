import { PhoneTypes } from 'acceligent-shared/enums/contactMethodType';
import TimePeriodRecent from 'acceligent-shared/enums/timePeriodRecent';
import Status from 'acceligent-shared/enums/workRequestStatus';

import Month from 'ab-enums/month.enum';

const uiLabels = {
	MONTH: {
		[Month.JANUARY]: 'January',
		[Month.FEBRUARY]: 'February',
		[Month.MARCH]: 'March',
		[Month.APRIL]: 'April',
		[Month.MAY]: 'May',
		[Month.JUNE]: 'June',
		[Month.JULY]: 'July',
		[Month.AUGUST]: 'August',
		[Month.SEPTEMBER]: 'September',
		[Month.OCTOBER]: 'October',
		[Month.NOVEMBER]: 'November',
		[Month.DECEMBER]: 'December',
	},
	TIME_SPAN: {
		[TimePeriodRecent.YESTERDAY]: 'YESTERDAY',
		[TimePeriodRecent.LAST_WEEK]: 'LAST WEEK',
		[TimePeriodRecent.LAST_MONTH]: 'LAST MONTH',
	},
	STATUS: {
		[Status.ACCEPTED]: 'Accepted',
		[Status.COMPLETE]: 'Complete',
		[Status.ESTIMATE]: 'Estimate',
		[Status.REJECTED]: 'Rejected',
		[Status.REQUEST]: 'Request',
		[Status.REVIEW]: 'Review',
		[Status.SUBMITTED]: 'Submitted',
		[Status.FINISHED]: 'Finished',
		[Status.IN_PROGRESS]: 'In Progress',
		[Status.SHORT_CIRCUITED]: 'In Progress',
	},
	PHONE_TYPES: {
		[PhoneTypes.PHONE_DIRECT]: 'Direct',
		[PhoneTypes.PHONE_FAX]: 'Fax',
		[PhoneTypes.PHONE_MOBILE]: 'Mobile',
		[PhoneTypes.PHONE_OFFICE]: 'Office',
	},
};

export default uiLabels;
