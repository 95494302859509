import * as React from 'react';

import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import EmployeeDraggable from 'af-root/scenes/Company/ScheduleBoard/Shared/EmployeeDraggable';

import { EmployeeModalProps } from '../../Shared/ModalProps';

interface Props extends EmployeeModalProps {
	droppableId: string;
	draggablePrefix: string; // to distinguish if it's board or toolbar dragged employee
	employeeIds: number[];
	isToolbar: boolean;
	startIndex: number;
	dueDate: string;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	available?: boolean;
	isCardDisabled: boolean;
	hasReason?: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

const EmployeeList: React.FC<Props> = (props: Props) => {
	const {
		available,
		draggablePrefix,
		droppableId,
		dueDate,
		employeeIds,
		hasPermissionsToEditScheduleBoard,
		hasReason,
		isCardDisabled,
		isCopyPlaceholder,
		isDragAndDropDisabled,
		isToolbar,
		isWorkOrderCanceled,
		startIndex,
		setEmployeeModalData,
		setEmployeeModalVisibility,
	} = props;

	return (
		<>
			{employeeIds.map((id: number, index: number) => {
				return (
					<EmployeeDraggable
						available={available}
						draggableId={`${draggablePrefix}#${ScheduleBoardProperty.EMPLOYEE}#${dueDate}#${id}`}
						droppableId={droppableId}
						dueDate={dueDate}
						employeeId={id}
						hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
						hasReason={hasReason}
						index={startIndex + index}
						isCardDisabled={isCardDisabled}
						isCopyPlaceholder={isCopyPlaceholder}
						isDragAndDropDisabled={isDragAndDropDisabled}
						isToolbar={isToolbar}
						isWOLocked={false}
						isWorkOrderCanceled={isWorkOrderCanceled}
						key={`${draggablePrefix}#${ScheduleBoardProperty.EMPLOYEE}#${dueDate}#${id}`}
						setEmployeeModalData={setEmployeeModalData}
						setEmployeeModalVisibility={setEmployeeModalVisibility}
					/>
				);
			})}
		</>
	);
};

export default EmployeeList;
