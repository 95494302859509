import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';

import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TableButtonType from 'ab-enums/tableButtonType.enum';
import TableNameEnum from 'ab-enums/tableName.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { JobPayrollTableBetaRowVM, JobPayrollTableCSVComplexValueGetterLookup } from 'ab-viewModels/accounting/jobPayrollTableBeta.viewModel';

import CLIENT from 'af-constants/routes/client';
import * as SettingsKeys from 'af-constants/settingsKeys';

import * as AccountingActions from 'af-actions/accounting';

import { RootState } from 'af-reducers';

import { downloadCSV } from 'af-utils/csv.utils';
import { getFullClientUrl } from 'af-utils/http.util';
import * as SettingsUtils from 'af-utils/settings.util';

import Table, { TabProps, Column, CellInfo, ExpandedDefinition, RowInfo } from 'af-components/Table6';
import TableComponent from 'af-components/Table6/Table';
import BooleanCell from 'af-components/Table6/Cells/BooleanCell';
import DateFilter from 'af-components/DateFilter';
import ScrollToLoad from 'af-components/ScrollToLoad';
import Breadcrumbs from 'af-components/Breadcrumbs';

import ChildTableBeta from './ChildTableBeta';

const MAX_CUSTOM_DAYS = 31;

type OwnProps = CustomRouteComponentProps;
interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

const connector = connect(mapStateToProps, mapDispatchToProps());

type Props = OwnProps & ConnectedProps<typeof connector> & SettingProps;

interface State {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	expanded: ExpandedDefinition;
	showExpander: boolean;
}

class JobPayrollReportBeta extends React.PureComponent<Props, State> {

	static readonly BREADCRUMBS = [{ label: 'Job Payroll Report Beta' }];

	static readonly COLLAPSE_ICON = <span className="table-container__expander icon-collapse" />;
	static readonly EXPAND_ICON = <span className="table-container__expander icon-expand" />;

	static readonly PERIOD_OPTIONS = [TimePeriodRecurrence.DAILY, TimePeriodRecurrence.WEEKLY, TimePeriodRecurrence.CUSTOM];

	static readonly DATA_COLUMNS: Column<JobPayrollTableBetaRowVM>[] = [
		{
			Header: 'Job ID',
			accessor: 'calculatedJobCode',
			sortable: false,
			width: 200,
			className: 'bold',
			Cell: ({ original }) => original.calculatedJobCode,
		},
		{
			Header: 'Division',
			accessor: 'divisionName',
			sortable: false,
			width: 200,
			Cell: ({ original }) => original.divisionName,
		},
		{
			Header: 'Job Title',
			accessor: 'jobTitle',
			sortable: false,
			width: 700,
			Cell: ({ original }) => original.jobTitle,
		},
		{
			Header: 'Is Internal',
			accessor: 'allCrewIsInternal',
			sortable: false,
			Cell: ({ original }) => <BooleanCell value={original.allCrewIsInternal} />,
		},
	];

	private _tableRef: Nullable<TableComponent<JobPayrollTableBetaRowVM>> = null;
	private _list: Nullable<ScrollToLoad<JobPayrollTableBetaRowVM>> = null;

	state: State = {
		startDate: this.props.startDate,
		endDate: this.props.endDate,
		period: this.props.period,
		expanded: {},
		showExpander: true,
	};

	readonly columns: Column<JobPayrollTableBetaRowVM>[] = [
		{
			Header: (cellInfo) => {
				const { data } = cellInfo as unknown as { data: JobPayrollTableBetaRowVM[]; };
				const { expanded } = this.state;

				if (!data?.length) {
					return <></>;
				}

				const isAnyExpanded = Object.values(expanded).some(Boolean);
				return (
					<a
						className={`table-container__expander ${isAnyExpanded ? 'icon-collapse' : 'icon-expand'}`}
						onClick={isAnyExpanded ? this.collapseAll : this.expandAll}
						role="submit"
					/>
				);
			},
			accessor: 'expander',
			sortable: false,
			expander: true,
			Expander: ({ isExpanded, index, original }: CellInfo<JobPayrollTableBetaRowVM>) => {
				if ((original as unknown as { infiniteScroll?: boolean; }).infiniteScroll) {
					return (
						<div className="table-container__expander-container">
							{isExpanded ? 'Hide' : 'Show'} Rows
							{isExpanded ? JobPayrollReportBeta.COLLAPSE_ICON : JobPayrollReportBeta.EXPAND_ICON}
						</div>
					);
				}
				return (
					<div onClick={this.toggleExpand.bind(this, index)}>
						{isExpanded ? JobPayrollReportBeta.COLLAPSE_ICON : JobPayrollReportBeta.EXPAND_ICON}
					</div>
				);
			},
			getProps: JobPayrollReportBeta.getExpanderCellProps,
			width: 50,
			Cell: () => <div />,
		},
		...JobPayrollReportBeta.DATA_COLUMNS,
	];

	readonly csvValueGetters: JobPayrollTableCSVComplexValueGetterLookup = {
		'reportUrl': (parentRow, childRow) => {
			if (!childRow) {
				throw new Error('Child row not provided');
			}

			const { location: { state: { orgAlias } }, companyName } = this.props;
			return getFullClientUrl(orgAlias, CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(childRow.workOrderId.toString(), orgAlias, companyName));
		},
	};

	static getExpanderCellProps() {
		return {
			onClick: (e: React.MouseEvent) => {
				e.stopPropagation(); // stops the event from propagating to row click when expanding on desktop
			},
		};
	}

	onTableMount = (table: TableComponent<JobPayrollTableBetaRowVM>, list: ScrollToLoad<JobPayrollTableBetaRowVM>) => {
		this._tableRef = table;
		this._list = list;
	};

	refresh = () => {
		if (this._tableRef) {
			this._tableRef.refreshTable();
		} else if (this._list) {
			this._list.refreshList();
		}
	};

	onExpandedChange = (expanded: ExpandedDefinition) => this.setState(() => ({ expanded }));

	toggleExpand = (idx: number) => {
		const { expanded } = this.state;
		const next = { ...expanded };
		next[idx] = !next[idx];
		this.onExpandedChange(next);
	};

	expandAll = () => {
		const allRows = this._tableRef?.props?.table?.rows;
		const newExpanded = (allRows ?? []).reduce<ExpandedDefinition>((_acc, _row, _index) => {
			_acc[_index] = true;
			return _acc;
		}, {});
		this.onExpandedChange(newExpanded);
	};

	collapseAll = () => {
		this.onExpandedChange({});
	};

	filterByDate = (startDate: Date, endDate: Date) => {
		this.setState(
			() => ({ startDate, endDate }),
			() => {
				SettingsUtils.setPayrollReportStartDate(startDate);
				SettingsUtils.setPayrollReportEndDate(endDate);
				this.refresh();
			}
		);
	};

	changePeriod = (period: TimePeriodRecurrence, selected: Date) => {
		let startDate: Nullable<Date>;
		let endDate: Nullable<Date>;

		switch (period) {
			case TimePeriodRecurrence.WEEKLY:
				// FIXME: refactor so that moment is not exposed
				const selectedMoment = TimeUtils.parseMoment(selected);
				startDate = selectedMoment ? selectedMoment.clone().startOf('week').toDate() : null;
				endDate = selectedMoment ? selectedMoment.clone().endOf('week').toDate() : null;
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
			default:
				startDate = selected;
				endDate = selected;
				break;
		}

		if (!startDate || !endDate) {
			return;
		}

		this.setState(
			() => ({ period, startDate: startDate!, endDate: endDate! }),
			() => {
				SettingsUtils.setPayrollReportPeriod(period);
				SettingsUtils.setPayrollReportStartDate(startDate!);
				SettingsUtils.setPayrollReportEndDate(endDate!);
				this.refresh();
			}
		);
	};

	fetch = async (tableRequestModel: TableQuery, requestId: number) => {
		const { findJobPayrollTableNew } = this.props;
		const { startDate, endDate } = this.state;

		const startDateFormatted = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
		const endDateFormatted = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);

		const result = await findJobPayrollTableNew(tableRequestModel, startDateFormatted, endDateFormatted);
		return { ...result, requestId };
	};

	onRowClick = (row: RowInfo<JobPayrollTableBetaRowVM>) => this.toggleExpand(row.index);

	onDownloadCSVClick = async () => {
		const { companyName, findJobPayrollRowsNew } = this.props;
		const { startDate, endDate } = this.state;

		const startDateFormatted = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
		const endDateFormatted = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);

		const data = findJobPayrollRowsNew(startDateFormatted, endDateFormatted);

		const filename = `${companyName}_job_payroll_${TimeUtils.formatDate(startDate, TimeFormat.SHORT_DATE)}_-_${TimeUtils.formatDate(endDate, TimeFormat.SHORT_DATE)}`;

		downloadCSV(JobPayrollTableBetaRowVM.toCSVData((await data) ?? [], this.csvValueGetters, true), `${filename}.csv`);
	};

	renderChildTable = (row: RowInfo<JobPayrollTableBetaRowVM>) => {
		const { location: { state: { orgAlias } }, companyName } = this.props;
		const { original: { childTable } } = row;

		return (
			<ChildTableBeta companyName={companyName} data={childTable} orgAlias={orgAlias} />
		);
	};

	renderFilter = () => {
		const { endDate, startDate, period } = this.state;

		// FIXME: not `field-report-orders-table`
		return (
			<div className="table-filter field-report-orders-table__filters">
				<DateFilter
					changePeriod={this.changePeriod}
					endDate={endDate}
					maxCustomDays={MAX_CUSTOM_DAYS}
					onChange={this.filterByDate}
					options={JobPayrollReportBeta.PERIOD_OPTIONS}
					period={period}
					startDate={startDate}
				/>
			</div>
		);
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	tabs: TabProps<JobPayrollTableBetaRowVM>[] = [{
		label: 'Job Payroll Report',
		columns: this.columns,
		additionalFilter: this.renderFilter,
		fetch: this.fetch,
		onRowClick: this.onRowClick,
		onExpandedChange: this.onExpandedChange,
		buttons: [{
			type: TableButtonType.EXPORT,
			hasPermission: true,
			onClick: this.onDownloadCSVClick,
		}],
	}];

	render() {
		const { expanded } = this.state;

		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={JobPayrollReportBeta.BREADCRUMBS} />
				<Table
					expanded={expanded}
					onMount={this.onTableMount}
					SubComponent={this.renderChildTable}
					tableName={TableNameEnum.JOB_PAYROLL_REPORT}
					tabs={this.tabs}
				/>
			</div>
		);
	}
}
function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findJobPayrollTableNew: AccountingActions.findJobPayrollTableNew,
		findJobPayrollRowsNew: AccountingActions.findJobPayrollRowsNew,
	};
}

const enhance = compose(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.PAYROLL_REPORT_START_DATE(),
			mappedName: 'startDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.PAYROLL_REPORT_END_DATE(),
			mappedName: 'endDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.PAYROLL_REPORT_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(JobPayrollReportBeta);
