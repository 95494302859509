import * as React from 'react';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import { NotificationSnackbarTypes } from 'ab-enums/notificationSnackbarContext.enum';

import NotificationSnackbar from './NotificationSnackbar';
import styles from './styles.module.scss';

interface NotificationType {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

interface NotificationsData {
	notifications: NotificationType[];
}

const NotificationSnackbarList = (props: NotificationsData) => {
	const { notifications } = props;
	const notificationSnackbar = useNotificationSnackbar();

	const onClickHandler = React.useCallback((notification: NotificationType) => {
		notificationSnackbar.removeNotificationSnackbar(notification);
	}, [notificationSnackbar]);

	return (
		<div className={`${styles.notificationContainer}`}>
			{
				notifications.map((notification, index) =>
					<NotificationSnackbar
						closeNotification={onClickHandler.bind(this, notification)}
						key={index}
						notification={notification}
					/>
				)
			}
		</div >
	);
};

export default NotificationSnackbarList;
