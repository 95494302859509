import * as React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import enUs from 'date-fns/locale/en-US';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { RectangleButton } from 'af-components/MultipleOptionsButton/RectangleButton';

interface OwnProps {
	start: Date;
	end: Date;
	onChange: (startDate: Date, endDate: Date) => void;
	isPopperFixed?: boolean;
	isPopperInline?: boolean;
	maxDate?: Date;
	minDate?: Date;
	maxDays?: number;
}

type Props = OwnProps;

const POPPER_MODIFIERS: ReactDatePickerProps['popperModifiers'] = {
	preventOverflow: {
		enabled: true,
		escapeWithReference: false,
		boundariesElement: 'scrollParent',
	},
};

const FIXED_POPPER_PROPS: ReactDatePickerProps['popperProps'] = {
	positionFixed: true,
};

const CALENDAR_ICON = (
	<RectangleButton
		action={undefined}
		isLeftOpen={true}
		isSquare={true}
		label={<span className="icon-calendar" />}
	/>
);

const PeriodPicker: React.FC<Props> = (props) => {
	const {
		start,
		end,
		onChange,
		isPopperFixed = false,
		isPopperInline = false,
		maxDate,
		minDate,
		maxDays,
	} = props;

	const handleSelectStart = React.useCallback((date: Date) => {
		const resolvedEnd = (maxDays && TimeUtils.getDiff(end, date, 'days') > maxDays) ? TimeUtils.addDays(date, maxDays - 1).toDate() : end;
		onChange(date, resolvedEnd);
	}, [maxDays, end, onChange]);

	const handleSelectEnd = React.useCallback((date: Date) => {
		const resolvedStart = (maxDays && TimeUtils.getDiff(date, start, 'days') > maxDays) ? TimeUtils.addDays(date, -(maxDays - 1)).toDate() : start;
		onChange(resolvedStart, date);
	}, [maxDays, onChange, start]);

	return (
		<div className="table-filter__date period-picker">
			<DatePicker
				className="form-control form-control--connected-right"
				dateFormat={TimeUtils.datePickerFormat(TimeFormatEnum.DATE_ONLY)}
				dropdownMode="select"
				endDate={end}
				locale={enUs}
				maxDate={end}
				minDate={minDate}
				onChange={handleSelectStart}
				popperModifiers={POPPER_MODIFIERS}
				popperPlacement={isPopperInline ? 'right' : undefined}
				popperProps={isPopperFixed ? FIXED_POPPER_PROPS : undefined}
				selected={start}
				selectsStart={true}
				showMonthDropdown={true}
				showYearDropdown={true}
				startDate={start}
				todayButton="Today"
			/>
			<DatePicker
				className="form-control form-control--open-left"
				dateFormat={TimeUtils.datePickerFormat(TimeFormatEnum.DATE_ONLY)}
				dropdownMode="select"
				endDate={end}
				locale={enUs}
				maxDate={maxDate}
				minDate={start}
				onChange={handleSelectEnd}
				popperModifiers={POPPER_MODIFIERS}
				popperPlacement={isPopperInline ? 'left' : undefined}
				popperProps={isPopperFixed ? FIXED_POPPER_PROPS : undefined}
				selected={end}
				selectsEnd={true}
				showMonthDropdown={true}
				showYearDropdown={true}
				startDate={start}
				todayButton="Today"
			/>
			{CALENDAR_ICON}
		</div>
	);
};

export default React.memo(PeriodPicker);
