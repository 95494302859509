import * as React from 'react';
import { Button } from 'react-bootstrap';

import { formatDate } from 'acceligent-shared/utils/time';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import { bemElement } from 'ab-utils/bem.util';

import ImageTag from 'af-components/Image';

interface OwnProps {
	openSignatureModal: () => void;
	fullName: string;
	name: string;
	signatureValue: Nullable<{ signedAt: string; signature: string; fullName: string; }>;
}

type Props = OwnProps;

const SignatureField: React.FC<Props> = (props) => {

	const { name, fullName, signatureValue, openSignatureModal } = props;

	const labelClassName = bemElement('time-sheet-signature-status-label', 'pill', ['signed']);

	return (
		<>
			{
				!!signatureValue?.signature
					? (
						<div className="report-block__signature-field">
							<div>
								<span className={labelClassName}>
								Signed
								</span>
								<div className="report-block__signature-field__signature-name">
								by {fullName} {formatDate(signatureValue.signedAt, TimeFormatEnum.FULL_DATE, TimeFormatEnum.ISO_DATETIME)}
								</div>
							</div>
							<ImageTag className="report-block__signature-field__signature-image" src={signatureValue.signature} />
						</div>
					)
					: (
						<Button
							onClick={openSignatureModal}
							variant="info"
						>
							Sign {name}
						</Button>
					)
			}
		</>
	);
};

export default React.memo(SignatureField);
