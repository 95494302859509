import * as React from 'react';
import { Field } from 'redux-form';

import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';

import * as ReduxUtils from 'ab-utils/reduxForms.util';

interface Props {
	fieldPrefix: string;
	isDisabled?: boolean;
}

class CardInputFields extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		isDisabled: false,
	};

	render() {
		const { fieldPrefix, isDisabled } = this.props;

		return (
			<div className="work-order-metrics">
				<Field
					component={Input}
					disabled={isDisabled}
					forceShowError={true}
					format={ReduxUtils.formatDecimalNumber}
					id="revenue"
					isDollarValue={true}
					name={`${fieldPrefix}.revenue`}
					normalize={ReduxUtils.normalizeDecimalNumber}
					placeholder="$00.00"
					type="text"
				/>
				<Field
					component={Input}
					disabled={isDisabled}
					forceShowError={true}
					format={ReduxUtils.formatDecimalNumber}
					id="manHourAverage"
					isDollarValue={true}
					name={`${fieldPrefix}.manHourAverage`}
					normalize={ReduxUtils.normalizeDecimalNumber}
					placeholder="$00.00"
					type="text"
				/>
				<br />
				<Field
					component={Input}
					disabled={isDisabled}
					format={ReduxUtils.formatDecimalNumber}
					id="jobHours"
					name={`${fieldPrefix}.jobHours`}
					normalize={ReduxUtils.normalizeDecimalNumber}
					placeholder="Enter Job Hours"
					type="text"
				/>
				<Field
					component={Input}
					disabled={isDisabled}
					format={ReduxUtils.formatDecimalNumber}
					id="shopHours"
					name={`${fieldPrefix}.shopHours`}
					normalize={ReduxUtils.normalizeDecimalNumber}
					placeholder="Enter Shop Hours"
					type="text"
				/>
				<Field
					component={Input}
					disabled={isDisabled}
					format={ReduxUtils.formatDecimalNumber}
					id="travelHours"
					name={`${fieldPrefix}.travelHours`}
					normalize={ReduxUtils.normalizeDecimalNumber}
					placeholder="Enter Travel Hours"
					type="text"
				/>
				<br />
				<Field
					component={Textarea}
					disabled={isDisabled}
					id="workDescription"
					name={`${fieldPrefix}.workDescription`}
					placeholder="Work Description"
					rows={5}
					type="text"
				/>
			</div>
		);
	}
}

export default CardInputFields;
