import * as React from 'react';

import { isFunction } from 'acceligent-shared/utils/extensions';

import TableSettingsRequestModel, { ColumnSettingsViewModel } from 'ab-requestModels/tableSettings.requestModel';

import { ModalStyle } from 'af-components/CustomModal';

import { filterMap } from 'ab-utils/array.util';

import { Column, RowInfo, ItemBlueprint, NonCascadeItemBlueprint, DividerItemBlueprint, CascadeItemBlueprint, SettingsItemBlueprint } from './index';
import TableSettings from './Settings';
import SettingsCell, { Item, NonCascadeItem, isDividerItem } from './Cells/SettingsCell';

export const getActiveTabStorageKey = (tableName: string) => `${tableName}_ACTIVE_TAB`;

const _getLoadingColumns = () => ({
	Header: <div className="loading-box-radio standalone" />,
	Cell: () => <div className="loading-box-row standalone" />,
});

function _constructColumnsByAccessor<T>(dict: { [name: string]: Column<T>; }, column: Column<T>): { [name: string]: Column<T>; } {
	const name = column.accessor as string;
	if (dict[name]) {
		// should never happen, but just to maintain old behavior with find
		return dict;
	}
	dict[name] = column;
	return dict;
}

const _isColumnVisible = ({ visible }: ColumnSettingsViewModel) => visible;

function _isDividerItemBlueprint<T>(itemBlueprint: ItemBlueprint<T>): itemBlueprint is DividerItemBlueprint<T> {
	return !!(itemBlueprint as DividerItemBlueprint<T>).isDivider;
}

function _isCascadeItemBlueprint<T>(itemBlueprint: ItemBlueprint<T>): itemBlueprint is CascadeItemBlueprint<T> {
	return !!(itemBlueprint as CascadeItemBlueprint<T>).isCascade;
}

export function getRowActions<T>(
	rowActions: ItemBlueprint<T>[],
	original: T,
	refreshAction: (action: (element: T) => Promise<void>, original: T, shouldRefresh: boolean) => Promise<void>
): Item[] {
	const _isItemShown = (_rowAction: ItemBlueprint<T>) => !(_rowAction.hide?.(original));
	const _getItemLabel = (_nonDividerRowIAction: CascadeItemBlueprint<T> | SettingsItemBlueprint<T>) =>
		isFunction(_nonDividerRowIAction.label) ? _nonDividerRowIAction.label(original) : _nonDividerRowIAction.label;

	const _nonCascadeItemReducer = (_accumulatedItems: Item[], _rowAction: NonCascadeItemBlueprint<T>): Item[] => {
		if (!_isItemShown(_rowAction)) {
			return _accumulatedItems;
		}
		if (_isDividerItemBlueprint(_rowAction)) {
			const _lastAddedItem = _accumulatedItems.length ? _accumulatedItems[_accumulatedItems.length - 1] : null;
			if (!_lastAddedItem || isDividerItem(_lastAddedItem)) {
				// don't add divider at top or after another divider
				return _accumulatedItems;
			}
			_accumulatedItems.push({ isDivider: true });
		} else {
			_accumulatedItems.push({
				label: _getItemLabel(_rowAction),
				action: _rowAction.action ? refreshAction.bind(this, _rowAction.action, original, _rowAction.shouldRefresh) : null,
				hasModal: _rowAction.hasModal,
				modalTitle: _rowAction.modalTitle ? _rowAction.modalTitle(original) : null,
				modalBody: _rowAction.modalBody ? _rowAction.modalBody(original) : null,
				modalText: _rowAction.modalText ? _rowAction.modalText(original) : null,
				modalStyle: _rowAction.modalStyle ? _rowAction.modalStyle : 'danger' as ModalStyle,
				disabled: _rowAction.isDisabled ? _rowAction.isDisabled(original) : false,
				beforeModalOpen: _rowAction.beforeModalOpen?.bind?.(this, original) ?? undefined,
				onModalClose: _rowAction.onModalClose?.bind?.(this, original) ?? undefined,
			});
		}
		return _accumulatedItems;
	};

	return rowActions.reduce(
		(_accumulatedItems: Item[], _rowAction: NonCascadeItemBlueprint<T>): Item[] => {
			if (!_isItemShown(_rowAction)) {
				return _accumulatedItems;
			}
			if (_isCascadeItemBlueprint(_rowAction)) {
				const _rowSubActions: NonCascadeItem[] = _rowAction.items.reduce(_nonCascadeItemReducer, []);
				if (!_rowSubActions.length) {
					// Cascade item row without sub actions is hidden
					return _accumulatedItems;
				}
				_accumulatedItems.push({ isCascade: true, label: _getItemLabel(_rowAction), items: _rowSubActions });
			} else {
				// using this reducer method to mutate existing array because the same divider checks need to be applied
				_accumulatedItems = _nonCascadeItemReducer(_accumulatedItems, _rowAction);
			}
			return _accumulatedItems;
		},
		[]
	);
}

export function renderColumns<T>(
	cols: Column<T>[] = [],
	rows: T[] = [],
	isLoaded: boolean,
	message: string | undefined | null = 'No entries found',
	rowActions: ItemBlueprint<T>[] | undefined,
	refreshAction: (action: (element: T) => Promise<void>, original: T, shouldRefresh: boolean) => Promise<void>,
	accountId: Nullable<number>,
	tableSettings: Nullable<TableSettingsRequestModel>,
	openTableSettingsModal: () => void,
	dynamicColumns: boolean = false
) {
	/** shallow copy of cols argument */
	let columns = [...cols];

	if (!isLoaded) {
		return columns.map(_getLoadingColumns);
	}

	// Get cols in correct ordering
	if (tableSettings && !dynamicColumns) {
		const colsByAccessor = cols.reduce(_constructColumnsByAccessor, {} as { [name: string]: Column<T>; });
		columns = filterMap(
			tableSettings.columnSettings,
			_isColumnVisible,
			({ name, width }) => ({ ...colsByAccessor[name], width })
		);
	}

	// Add additional row actions if needed
	columns.push({
		Header: accountId && !dynamicColumns ? <TableSettings openTableSettingsModal={openTableSettingsModal} /> : null,
		filterable: false,
		sortable: false,
		resizable: false,
		className: 'settings-cell',
		Cell: (rowActions?.length)
			? ({ original, index }: RowInfo<T>) => {
				const _visibleRowActionItems = getRowActions.call(this, rowActions, original, refreshAction);

				if (!_visibleRowActionItems.length) {
					return null;
				}
				return (
					<SettingsCell
						isFirstHalf={index < rows.length / 2}
						items={_visibleRowActionItems}
						rowIndex={index}
					/>
				);
			}
			: () => null,
	});

	if (!rows.length) {
		return columns.map((_col, _index) => (
			{
				..._col,
				Cell: () => _index ? <span /> : (<span className="table-message-cover">{message}</span>),
			}
		));
	}
	return columns;
}

export function renderRows<T>(rows: T[] = [], isLoaded: boolean) {
	if (!isLoaded || rows === null) {
		return Array(10).fill({});
	}
	return rows;
}
