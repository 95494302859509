import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import Breadcrumbs, { BreadcrumbItem } from 'af-components/Breadcrumbs';

interface OwnProps {
	breadcrumbItems: BreadcrumbItem[];
}

type Props = OwnProps;

class Loading extends React.Component<Props> {

	render() {
		const { breadcrumbItems } = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs items={breadcrumbItems} />
				<div className="form-box">
					<Row className="row--padded-top">
						<Col className="loading-box-field" sm={12} />
					</Row>
					<Row className="row--padded">
						<Col className="loading-box-row" sm={4} />
						<Col className="loading-box-row" sm={4} />
						<Col className="loading-box-row" sm={4} />
						<Col className="loading-box-row" sm={4} />
						<Col className="loading-box-row" sm={4} />
						<Col className="loading-box-row" sm={4} />
					</Row>
				</div>
			</div>
		);
	}
}

export default Loading;
