import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { MarkerProps } from '@react-google-maps/api';

import MapField from 'af-components/Maps/AddressMap';
import Tooltip from 'af-components/Tooltip';

interface Props {
	index?: number;
	marker?: MarkerProps;
	latitudePropName: string;
	longitudePropName: string;
	locationPropName: string;
	streetNumberPropName: string;
	routePropName: string;
	localityPropName: string;
	aa1PropName: string;
	aa2PropName: string;
	aa3PropName: string;
	countryPropName: string;
	postalCodePropName: string;
	suitePropName: string;
	postalOfficeBoxPropName: string;
	removeAddress?: (index: number) => void;
	formName: string;
	disableMap?: boolean;
	autosave?: () => Promise<void>;
	disableRemove?: boolean;
	disabled?: boolean;
	onValueUpdate?: () => void;
}

class AddressField extends React.PureComponent<Props> {

	removeThisAddress = () => {
		const { removeAddress, index } = this.props;
		if (removeAddress && index !== undefined) {
			removeAddress(index);
		}
	};

	render() {
		const { disableRemove } = this.props;

		return (
			<Row className="row--column-aligned">
				<Col className="p-l-0" sm={23}>
					<MapField
						{...this.props}
					/>
				</Col>
				<Col sm={1}>
					{!disableRemove &&
						<Tooltip message="Delete">
							<Button
								className="btn btn--flat btn--icon"
								onClick={this.removeThisAddress}
							>
								<span className="icon-delete" />
							</Button>
						</Tooltip>
					}
				</Col>
			</Row>
		);
	}
}

export default AddressField;
