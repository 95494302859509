export enum SortByEnum {
	PRIORITY = 'PRIORITY',
	LAST_UPDATED = 'LAST_UPDATED',
	EXPECTED_RETURN = 'EXPECTED_RETURN'
}

export enum OrderByEnum {
	DESCENDING = 'DESCENDING',
	ASCENDING = 'ASCENDING'
}
