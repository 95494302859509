import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import ResourcePreview from '../../Shared/ResourcePreview';

import { BreadcrumbItem } from 'af-components/Breadcrumbs';

interface OwnProps {
	backToListUrl: string;
	backToListLabel: string;
	breadcrumbs: BreadcrumbItem[];
}

type Props = OwnProps;

class PreviewSaleLoading extends React.PureComponent<Props> {

	render() {
		return (
			<ResourcePreview
				{...this.props}
			>
				<Row className="row--padded-top">
					<Col className="loading-box-row" lg={12} md={16} />
					<Col className="loading-box-row" lg={6} md={8} />
				</Row>
				<Row>
					<Col className="loading-box-row" lg={6} md={8} />
					<Col className="loading-box-row" lg={6} md={8} />
					<Col className="loading-box-row" lg={6} md={8} />
				</Row>
				<Row>
					<Col className="loading-box-row" xs={12} />
				</Row>
			</ResourcePreview>
		);
	}

}

export default PreviewSaleLoading;
