import * as React from 'react';

import { AdditionalColors } from 'acceligent-shared/enums/color';

import { WarningWorkOrderModel } from 'af-models/scheduleBoard.models';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import LabelWithColor from 'af-components/LabelWithColor';

interface OwnProps {
	showWarning: boolean;
	warnings: WarningWorkOrderModel[];
	isMultiSelectModeActive: boolean;
	closeModal: () => void;
	submit: () => void;
}

type Props = OwnProps;

class WarningModal extends React.PureComponent<Props> {

	render() {
		const { showWarning, closeModal, isMultiSelectModeActive, submit, warnings } = this.props;

		if (!warnings?.length) {
			return null;
		}

		return (
			<CustomModal
				className="schedule-board-modal"
				closeModal={closeModal}
				modalStyle="info"
				showModal={showWarning}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title={`Publish ${isMultiSelectModeActive ? 'selected' : 'all'} Work Orders`}
				/>
				<CustomModal.Body>
					<div>
						<div>Some {isMultiSelectModeActive && 'of the selected'} work orders cannot be published:</div>
						{
							warnings.map((_w: WarningWorkOrderModel, _index: number) => (
								<div
									className="schedule-board-modal__work-order-pill"
									key={`${_index}-${_w.woCode}`}
								>
									<LabelWithColor
										color={_w.color ?? AdditionalColors.BLACK}
										text={_w.woCode}
									/>
									<div className="schedule-board-modal__work-order-pill__reason">{_w.reason}</div>
								</div>)
							)
						}
					</div>
				</CustomModal.Body>
				<CustomModal.Footer>
					<SubmitButton
						label="Continue"
						onClick={submit}
						variant="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default WarningModal;
