import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { JobStatusViewModel } from 'ab-viewModels/jobStatus/jobStatus.viewModel';

import { RootState } from 'af-reducers';

import * as JobStatusActions from 'af-actions/jobStatus';

import CLIENT from 'af-constants/routes/client';

import LockedValue from 'af-components/LockedValue';
import LockedColorValue from 'af-components/LockedColorValue';

import ResourcePreview from '../../Shared/ResourcePreview';
import Loading from './Loading';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const Preview: React.FC<Props> = (props) => {
	const {
		findById,
		match: { params: { id } },
		location: { state: { orgAlias } },
		companyName,
	} = props;

	const [jobStatus, setJobStatus] = React.useState<Nullable<JobStatusViewModel>>(null);

	const fetchJobStatus = React.useCallback(async () => {
		const newJobStatus = await findById(id);

		if (!newJobStatus) {
			return;
		}

		setJobStatus(newJobStatus);
	}, [id, findById]);

	React.useEffect(() => {
		fetchJobStatus();
	}, [fetchJobStatus]);

	const breadcrumbs = React.useMemo(() => ([
		{ label: 'Job Statuses', url: CLIENT.COMPANY.RESOURCES.JOB_STATUS.LIST(orgAlias, companyName) },
		{ label: 'Preview' },
	]), [orgAlias, companyName]);

	return jobStatus
		? (
			<ResourcePreview
				backToListLabel="Back to Job Status List"
				backToListUrl={CLIENT.COMPANY.RESOURCES.JOB_STATUS.LIST(orgAlias, companyName)}
				breadcrumbs={breadcrumbs}
				editUrl={CLIENT.COMPANY.RESOURCES.JOB_STATUS.EDIT(`${jobStatus.id}`, orgAlias, companyName)}
			>
				<Row className="row--padded-top">
					<Col md={8}><LockedValue label="Name" value={jobStatus.name} /></Col>
					<Col md={8}><LockedValue label="Description" value={jobStatus.description} /></Col>
					<Col md={8}><LockedColorValue color={jobStatus.color} label="Color" /></Col>
				</Row>
			</ResourcePreview>
		)
		: <Loading />;
};

const mapStateToProps = (state: RootState) => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

const mapDispatchToProps = () => ({
	findById: JobStatusActions.findById,
});

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	React.memo
);

export default enhance(Preview);
