import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { CompoundUnitEnum, CompoundUnitValueLimits } from 'acceligent-shared/enums/quantityUnit';

import Label from 'af-components/LockedValue/Label';
import { OwnProps as TooltipProps } from 'af-components/Tooltip';

import { isValidNumber } from 'ab-utils/validation.util';

interface OwnProps {
	defaultValue?: string;
	id: string;
	label: string;
	tooltipMessage?: TooltipProps['message'];
	unit: CompoundUnitEnum;
	valueClassName?: Nullable<string>;
}

type Props = OwnProps & WrappedFieldProps;

class CompoundText extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		defaultValue: '-',
		valueClassName: null,
	};

	renderValues = (elementUnit: string, index: number) => {
		const { input, valueClassName } = this.props;
		const value = input.value?.[elementUnit] ?? 0;

		return (
			<div className={valueClassName ? `locked-input__content ${valueClassName}` : 'locked-input__content'} key={index} >
				{value} {elementUnit}
			</div>
		);
	};
	render() {
		const { id, label, unit, input, defaultValue, valueClassName, tooltipMessage } = this.props;

		if (!CompoundUnitEnum[unit]) {
			return null;
		}

		const elements = Object.keys(CompoundUnitValueLimits[unit]);
		const hasValue = Object.values(input.value).some((_value) => isValidNumber(_value as number));

		return (
			<>
				<Label label={label} tooltipMessage={tooltipMessage} />
				{hasValue
					? (
						<div className="locked-input__inline-content" id={id}>
							{elements.map(this.renderValues)}
						</div>
					)
					: (
						<div className={valueClassName ? `locked-input__content ${valueClassName}` : 'locked-input__content'} id={id} >
							{defaultValue}
						</div>
					)
				}
			</>
		);
	}
}

export default CompoundText;
