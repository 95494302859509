import { getId } from 'acceligent-shared/utils/array';
import { isValidTextInput } from 'acceligent-shared/utils/text';

import NotifyCompanyRM from 'ab-requestModels/companyNotify.requestModel';
import UserGroupRM from 'ab-requestModels/userGroup.requestModel';

const _userGroupAccountIdMapper = (userGroup: UserGroupRM) => userGroup.accounts?.map(getId) ?? [];

const _getAccounts = (userGroups: Nullable<UserGroupRM[]>) => {
	return userGroups?.flatMap(_userGroupAccountIdMapper) ?? [];
};

export function validate(values: NotifyCompanyRM) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.subject)) {
		errors.subject = 'Subject is required';
	}

	if (!isValidTextInput(values.message)) {
		errors.message = 'Message is required';
	}

	if (!values.notificationPriority) {
		errors.notificationPriority = 'Notification priority is required';
	}

	if (!values.notifyAll) {
		if (!values?.includedAccounts?.length && !_getAccounts(values?.userGroups ?? null)?.length) {
			errors.userGroups = 'There are no employees to notify';
		}
	}
	return errors;
}

