import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

export default () => (
	<div className="form-segment">
		<div className="form-box">
			<Row className="row--padded-top">
				<Col className="loading-box-field standalone" md={8} />
				<Col className="loading-box-field standalone" md={8} />
				<Col className="loading-box-field standalone" md={8} />
			</Row>
			<Row className="row--padded">
				<Col className="loading-box-field standalone" md={8} />
				<Col className="loading-box-field standalone" md={8} />
			</Row>
			<Row className="row--padded">
				<Col className="loading-box-field standalone" md={5} />
				<Col className="loading-box-field standalone" md={3} />
				<Col className="loading-box-field standalone" md={4} />
				<Col className="loading-box-field standalone" md={3} />
				<Col className="loading-box-field standalone" md={3} />
				<Col className="loading-box-field standalone" md={3} />
				<Col className="loading-box-field standalone" md={3} />
			</Row>
		</div>
	</div>
);
