import * as React from 'react';
import { Document, Page } from 'react-pdf';

import { WO_PREVIEW_MOBILE_BREAKPOINT } from 'af-constants/values';

import LoadingIndicator from 'af-components/LoadingIndicator';
interface OwnProps {
	scale: number;
	data: Buffer;
}

type Props = OwnProps;

interface State {
	visiblePages: number;
	totalPages: number;
}

class PdfDocument extends React.PureComponent<Props, State> {

	pagesPerLoad = 10;

	state: State = {
		visiblePages: 0,
		totalPages: 0,
	};

	static getBufferData = (data: Buffer) => {
		return { data };
	};

	range = (n) => Array(n).fill(1).map((_, index) => index + 1);

	onDocumentLoad = ({ numPages }) => {
		this.setState(() => ({
			totalPages: numPages,
			visiblePages: Math.min(this.pagesPerLoad, numPages),
		}));
	};

	loadMorePages = () => {
		const { totalPages, visiblePages } = this.state;

		this.setState(() => ({ visiblePages: Math.min(visiblePages + this.pagesPerLoad, totalPages) }));
	};

	getAdditionalClassNameIndex = () => {
		const { scale } = this.props;
		if (scale < 0.6) {
			return null;
		} else if (scale < 0.7) {
			return 0;
		} else if (scale < 0.8) {
			return 1;
		} else if (scale < 0.9) {
			return 2;
		} else if (scale < 1) {
			return 3;
		} else {
			return 4;
		}
	};

	render() {
		const { scale, data } = this.props;
		const { totalPages, visiblePages } = this.state;

		if (!data) {
			return (
				<div className="loading-box text-center">
					<div>Loading document</div>
					<LoadingIndicator color="blue" />
				</div>
			);
		}
		const additionalClassName = window.innerWidth < WO_PREVIEW_MOBILE_BREAKPOINT ? `shifted-page-${this.getAdditionalClassNameIndex()}` : undefined;

		return (
			<Document
				file={{ data }}
				onLoadSuccess={this.onDocumentLoad}
			>
				{this.range(visiblePages).map((page, index, array) =>
					<Page
						className={[array.length - 1 !== index ? 'pdf-page' : undefined, additionalClassName]}
						key={index}
						pageNumber={page}
						renderTextLayer={false}
						scale={scale}
					/>
				)}
				{visiblePages < totalPages &&
					<div className="load-more-pages pointer" onClick={this.loadMorePages}>
						Load more pages
					</div>
				}
			</Document>
		);
	}
}

export default PdfDocument;
