import * as React from 'react';

import LockedValue from 'af-components/LockedValue';

import { previewSectionClass, previewSectionClassColumn2, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';

type Props = {
	targetStartDate: Nullable<string>;
	targetCompletionDate: Nullable<string>;
	targetDaysFromStart: Nullable<number>;
	startDate: Nullable<string>;
	guaranteedCompletionDate: Nullable<string>;
	guaranteedDaysFromStart: Nullable<number>;
};

const ScheduleInfoSection: React.FC<Props> = (props) => {
	const {
		guaranteedCompletionDate,
		guaranteedDaysFromStart,
		startDate,
		targetCompletionDate,
		targetDaysFromStart,
		targetStartDate,
	} = props;

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Schedule Info
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Target Start Date" value={targetStartDate} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Target Completion Date" value={targetCompletionDate} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Days from Start" value={targetDaysFromStart} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Actual Start Date" value={startDate} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Guaranteed Completion Date" value={guaranteedCompletionDate} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Days from Start" value={guaranteedDaysFromStart} />
				</div>
			</div>
		</div>
	);
};

export default ScheduleInfoSection;
