import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

const Loading = () => {
	return (
		<div className="job-preview__info">
			<div className="job-preview__header">
				<div className="job-preview__job-title">
					&nbsp;
				</div>
			</div>
			<div className="job-preview__general-info">
				<Row>
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
				</Row>
				<Row>
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
				</Row>
				<Row>
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
					<Col className="loading-box-row" md={4} />
				</Row>
			</div>
		</div>
	);
};

export default Loading;
