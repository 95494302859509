import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect, ResolveThunks } from 'react-redux';

import ScheduleBoardFilterGroup from 'acceligent-shared/enums/scheduleBoardFilterGroup';

import ScheduleBoardFilterVM, { FilterVM } from 'acceligent-shared/dtos/web/view/scheduleBoard/filter';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import CustomModal from 'af-components/CustomModal';
import SegmentLabel from 'af-components/SegmentLabel';

import Loading from '../LoadingModal';
import FilterGroup from './FilterGroup';

interface OwnProps {
	modalVisible: boolean;
	closeFilterModal: () => void;
	shouldReloadFilters: boolean;
}

interface DispatchProps {
	applyFilters: typeof ScheduleBoardActions.applyFilters;
	findAllFilters: typeof ScheduleBoardActions.findAllFilters;
}

interface State {
	filters: Nullable<ScheduleBoardFilterVM>;
}

type Props = OwnProps & ResolveThunks<DispatchProps>;

class FilterModal extends React.Component<Props, State> {

	state: State = {
		filters: null,
	};

	async componentDidUpdate(prevProps: Props) {
		const { modalVisible } = this.props;
		const { filters } = this.state;
		if ((!filters && prevProps.modalVisible !== modalVisible) || (!prevProps.shouldReloadFilters && this.props.shouldReloadFilters)) {
			await this.fetchFilters();
		}
	}

	fetchFilters = async () => {
		const { findAllFilters } = this.props;
		const _filters: ScheduleBoardFilterVM = await findAllFilters();
		this.setState(() => ({ filters: _filters }));
	};

	onCheck = async (option: FilterVM) => {
		const { filters } = this.state;

		if (!filters) {
			throw new Error('Filters not defined');
		}

		const filterOption = filters[option.filterGroup].find((_item: FilterVM) => _item.id === option.id);
		if (!filterOption) {
			throw new Error('Filter option not found');
		}

		filterOption.checked = option.checked;
		this.setState(() => ({ filters: { ...filters } }));
	};

	onCheckAll = (checked: boolean, filterGroup: ScheduleBoardFilterGroup) => {
		const { filters } = this.state;

		if (!filters) {
			throw new Error('Filters not defined');
		}

		filters[filterGroup]?.forEach((_item: FilterVM) => {
			_item.checked = checked;
		});
		this.setState(() => ({ filters: { ...filters } }));
	};

	applyFilters = () => {
		const { applyFilters, closeFilterModal } = this.props;
		const { filters } = this.state;

		if (!filters) {
			throw new Error('Filters not defined');
		}

		applyFilters(filters);
		closeFilterModal();
	};

	renderLoadingModalContent = () => {
		const { closeFilterModal } = this.props;

		return (
			<Loading onHide={closeFilterModal} />
		);
	};

	renderDataModalContent = () => {
		const { closeFilterModal } = this.props;
		const { filters } = this.state;

		if (!filters) {
			return null;
		}

		return (
			<>
				<CustomModal.Header
					closeModal={closeFilterModal}
					title="Filter"
				/>
				<CustomModal.Body>
					<div>
						Use filter to filter out the specific data you would like to see on the Schedule Board.
						<br />
						You can simultaneously filter and do a word search, both results will be shown.
						<br />
						<b>NOTE:</b> The items that do not match the filtering criteria will not be removed, but only dimmed out.
					</div>
					<div>
						<SegmentLabel label="Work Orders" />
						<FilterGroup
							filterGroup={ScheduleBoardFilterGroup.CREW_TYPE}
							label="Crew Types"
							onCheck={this.onCheck}
							onCheckAll={this.onCheckAll}
							options={filters[ScheduleBoardFilterGroup.CREW_TYPE]}
						/>
					</div>
					<div>
						<SegmentLabel label="Equipment" />
						<FilterGroup
							filterGroup={ScheduleBoardFilterGroup.EQUIPMENT_COST}
							label="Equipment Costs"
							onCheck={this.onCheck}
							onCheckAll={this.onCheckAll}
							options={filters[ScheduleBoardFilterGroup.EQUIPMENT_COST]}
						/>
						<FilterGroup
							filterGroup={ScheduleBoardFilterGroup.EQUIPMENT_OFFICE}
							label="Offices"
							onCheck={this.onCheck}
							onCheckAll={this.onCheckAll}
							options={filters[ScheduleBoardFilterGroup.EQUIPMENT_OFFICE]}
						/>
					</div>
					<div>
						<SegmentLabel label="Employees" />
						<FilterGroup
							filterGroup={ScheduleBoardFilterGroup.EMPLOYEE_OFFICE}
							label="Offices"
							onCheck={this.onCheck}
							onCheckAll={this.onCheckAll}
							options={filters[ScheduleBoardFilterGroup.EMPLOYEE_OFFICE]}
						/>
					</div>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeFilterModal}
						variant="info"
					>
						Close
					</Button>
					<Button
						onClick={this.applyFilters}
						variant="primary"
					>
						Apply Filter
					</Button>
				</CustomModal.Footer>
			</>
		);
	};

	render() {
		const { modalVisible, closeFilterModal } = this.props;
		const { filters } = this.state;

		return (
			<CustomModal
				className="filter-modal"
				closeModal={closeFilterModal}
				modalStyle="info"
				showModal={modalVisible}
				size="xl"
			>
				{filters ? this.renderDataModalContent() : this.renderLoadingModalContent()}
			</CustomModal>
		);
	}
}

const mapDispatchToProps = (): DispatchProps => ({
	applyFilters: ScheduleBoardActions.applyFilters,
	findAllFilters: ScheduleBoardActions.findAllFilters,
});

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(FilterModal);
