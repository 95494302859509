import { CompanyViewModel } from 'ab-viewModels/company.viewModel';
import { CSVBulkAccountVM } from 'ab-viewModels/account/upload.viewModel';
import { NotificationSettingsViewModel } from 'ab-viewModels/notification.viewModel';

import * as constants from 'af-constants/actionTypes';

export interface GetCompany {
	type: typeof constants.GET_COMPANY;
	payload: CompanyViewModel;
}
export function GET_COMPANY(payload: CompanyViewModel): GetCompany {
	return {
		type: constants.GET_COMPANY,
		payload,
	};
}

export interface GetGeneralNotificationsSettings {
	type: typeof constants.GET_COMPANY_NOTIFICATION_SETTINGS;
	payload: Partial<NotificationSettingsViewModel>;
}
export function GET_COMPANY_NOTIFICATION_SETTINGS(payload: Partial<NotificationSettingsViewModel>): GetGeneralNotificationsSettings {
	return {
		type: constants.GET_COMPANY_NOTIFICATION_SETTINGS,
		payload,
	};
}

export interface SetAcceptedMembers {
	type: typeof constants.SET_ACCEPTED_MEMBERS;
	payload: CSVBulkAccountVM['valid'];
}
export function SET_ACCEPTED_MEMBERS(payload: CSVBulkAccountVM['valid']): SetAcceptedMembers {
	return {
		type: constants.SET_ACCEPTED_MEMBERS,
		payload,
	};
}

export interface SetRejectedMembers {
	type: typeof constants.SET_REJECTED_MEMBERS;
	payload: CSVBulkAccountVM['invalid'];
}
export function SET_REJECTED_MEMBERS(payload: CSVBulkAccountVM['invalid']): SetRejectedMembers {
	return {
		type: constants.SET_REJECTED_MEMBERS,
		payload,
	};
}

export type CompanyAction = GetCompany
	| SetAcceptedMembers | SetRejectedMembers
	| GetGeneralNotificationsSettings;
