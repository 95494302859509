import * as React from 'react';
import { Field } from 'redux-form';

import Checkbox from 'af-fields/Checkbox';

interface OwnProps {
	disabled: boolean;
	isPreview: boolean;
	formName: string;
	name: string;
	tooltipMessage?: React.ComponentProps<typeof Checkbox>['tooltipMessage'];
	onValueChange: () => void;
}

type Props = OwnProps;

const BooleanField: React.FC<Props> = (props) => {
	const {
		disabled,
		isPreview,
		formName,
		name,
		tooltipMessage,
		onValueChange,
	} = props;
	return (
		<Field
			component={Checkbox}
			extraClass="field-report-block__field__boolean"
			inline={true}
			isDisabled={isPreview || disabled}
			isStandalone={false}
			label={name}
			name={formName}
			onValueChange={onValueChange}
			propName={name}
			tooltipMessage={tooltipMessage}
		/>
	);
};

export default React.memo(BooleanField);
