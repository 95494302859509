import { reducer as formReducer } from 'redux-form';
import { WORK_ORDER_FORM, SORT_LOCATION_FORM } from 'af-constants/reduxForms';
import { SAVE_WORK_ORDER } from 'af-constants/actionTypes';

export default formReducer.plugin({
	[WORK_ORDER_FORM]: (state, action) => {
		switch (action.type) {
			case SAVE_WORK_ORDER:
				// anyTouched is used to check if form was modified
				// when SAVE_WORK_ORDER is dispatched, work order from edit form is saved and anyTouched is reseted
				// if anyTouched is true user will get warning that there are unsaved changes when leaving the screen
				return {
					...state,
					dirty: false,
				};
			default:
				return state;
		}
	},
	[SORT_LOCATION_FORM]: (state, action) => {
		switch (action.type) {
			case '@@redux-form/UNREGISTER_FIELD':
				return undefined;
			default:
				return state;
		}
	},
});
