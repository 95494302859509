import * as React from 'react';
import { HandleThunkActionCreator } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { SkillRequestModel } from 'ab-requestModels/skill.requestModel';

import { SkillViewModel } from 'ab-viewModels/skill.viewModel';

import { SKILL_EDIT } from 'af-constants/reduxForms';

import * as SkillActions from 'af-actions/skill';

import SkillForm from 'af-components/SharedForms/Resources/Skill';
import { validate } from 'af-components/SharedForms/Resources/Skill/validation';

interface OwnProps {
	updateSkill: HandleThunkActionCreator<typeof SkillActions.update>;
	toggleEdit: (id: Nullable<number>) => void;
	skill: SkillViewModel;
	index: number;
}

type Props = OwnProps & InjectedFormProps<SkillRequestModel, OwnProps>;

class SkillEdit extends React.PureComponent<Props> {
	updateSkill = async (form: SkillRequestModel) => {
		const { skill, toggleEdit, updateSkill } = this.props;
		await updateSkill(skill.id, form);
		toggleEdit(null);
	};

	close = () => {
		const { toggleEdit } = this.props;
		toggleEdit(null);
	};

	render() {
		return (
			<SkillForm {...this.props} close={this.close} onSubmit={this.updateSkill} />
		);
	}
}

export default reduxForm<SkillRequestModel, OwnProps>({
	form: SKILL_EDIT, validate, enableReinitialize: true, destroyOnUnmount: false,
})(SkillEdit);
