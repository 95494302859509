import { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { GetRootState } from 'af-reducers';
import { BillingCodeVM } from 'ab-viewModels/job.viewModel';

export function findForJobId(jobId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<BillingCodeVM[]>(API.V1.BILLING_CODE.FIND_BY_JOB_ID(jobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
