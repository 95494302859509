import { EquipmentCostImportBatch, EquipmentCostCSVRequestModel } from 'ab-requestModels/equipmentCost.requestModel';
import { CSVRow, CSVData } from 'ab-viewModels/csv.viewModel';

const PROPS = {
	EQUIPMENT_COST: {
		TYPE: 'type',
		GROUP: 'group',
		CATEGORY: 'category',
		CATEGORY_COLOR: 'categoryColor',
		SUBCATEGORY: 'subcategory',
		MOB_CHARGE: 'mobCharge',
		DAILY_COST: 'dailyCost',
		WEEKLY_COST: 'weeklyCost',
		MONTHLY_COST: 'monthlyCost',
		OPERATING_CHARGE: 'operatingCharge',
		FUEL_COST: 'fuelCost',
		LICENSES: 'licenses',
		SKILLS: 'skills',
	},
	SKILLS: {
		NAME: 'name',
		COLOR: 'color',
	},
};

interface CSVIdToArrayIdxMapper {
	[csvId: string]: { idx: number; };
}

const parseRow = (row: CSVRow): EquipmentCostCSVRequestModel => {
	const equipmentCost: Partial<EquipmentCostCSVRequestModel> = {
		type: '',
		group: '',
		category: '',
		categoryColor: '',
		subcategory: '',
		mobCharge: '0',
		dailyCost: '0',
		weeklyCost: '0',
		monthlyCost: '0',
		operatingCharge: '0',
		fuelCost: '0',
		licenses: '',
		skills: [],
	};
	const elementIdx = {} as CSVIdToArrayIdxMapper;

	Object.entries(row).forEach(([_column, _value]) => {
		if (!_value) {
			return;
		}

		const properties = _column.split('.');
		const [ccProp] = properties;

		switch (ccProp) {
			case PROPS.EQUIPMENT_COST.TYPE:
			case PROPS.EQUIPMENT_COST.GROUP:
			case PROPS.EQUIPMENT_COST.CATEGORY:
			case PROPS.EQUIPMENT_COST.CATEGORY_COLOR:
			case PROPS.EQUIPMENT_COST.SUBCATEGORY:
			case PROPS.EQUIPMENT_COST.LICENSES:
				equipmentCost[_column] = _value;
				break;
			case PROPS.EQUIPMENT_COST.MOB_CHARGE:
			case PROPS.EQUIPMENT_COST.DAILY_COST:
			case PROPS.EQUIPMENT_COST.WEEKLY_COST:
			case PROPS.EQUIPMENT_COST.MONTHLY_COST:
			case PROPS.EQUIPMENT_COST.OPERATING_CHARGE:
			case PROPS.EQUIPMENT_COST.FUEL_COST:
				equipmentCost[_column] = parseFloat(_value).toString();
				break;
			case PROPS.EQUIPMENT_COST.SKILLS:
				const [, elementId, elementProp] = properties;
				const elements = equipmentCost[ccProp];
				const csvId = `${ccProp}.${elementId}`;

				if (!elementIdx[csvId]) {
					elementIdx[csvId] = { idx: elements.length };
					elements.push({});
				}
				const { idx } = elementIdx[csvId];

				elements[idx][elementProp] = _value;
		}
	});

	return equipmentCost as EquipmentCostCSVRequestModel;
};

export default (data: CSVData): EquipmentCostImportBatch => ({ equipmentCostBatch: data.map(parseRow) });
