import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

const Loading = () => {
	return (
		<div className="form-segment">
			<div className="form-box loading-box">
				<Row>
					<Col className="loading-box-field" sm={6} />
					<Col className="loading-box-field" sm={6} />
					<Col sm={12} />
				</Row>
				<Row>
					<Col className="loading-box-field" sm={6} />
					<Col className="loading-box-field" sm={6} />
					<Col sm={12} />
				</Row>
				<Row>
					<Col className="loading-box-field" sm={6} />
					<Col className="loading-box-field" sm={6} />
					<Col sm={12} />
				</Row>
				<Row>
					<Col className="loading-box-field" sm={6} />
					<Col sm={18} />
				</Row>
				<Row>
					<Col className="loading-box-field" sm={6} />
					<Col className="loading-box-field" sm={6} />
					<Col className="loading-box-field" sm={6} />
					<Col sm={6} />
				</Row>
				<Row>
					<Col className="loading-box-area" sm={24} />
				</Row>
			</div>
		</div>
	);
};

export default Loading;
