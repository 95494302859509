import Priority from 'acceligent-shared/enums/priority';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import { ColorPalette } from 'acceligent-shared/enums/color';
import { EmailTypesArray } from 'acceligent-shared/enums/contactMethodType';

import { UpdatedByViewModel, UpdatedByAccountViewModel } from 'acceligent-shared/dtos/web/view/updatedBy';

import { toUtcDate, formatDate } from 'acceligent-shared/utils/time';
import { filterMap } from 'acceligent-shared/utils/array';
import * as ContactUtils from 'acceligent-shared/utils/contact';
import { getShortAddress } from 'acceligent-shared/utils/address';

import WorkRequest from 'acceligent-shared/models/workRequest';
import User from 'acceligent-shared/models/user';
import JobStatus from 'acceligent-shared/models/jobStatus';
import Address from 'acceligent-shared/models/address';
import Project from 'acceligent-shared/models/project';
import ContactLookup from 'acceligent-shared/models/contactLookup';
import ContactMethod from 'acceligent-shared/models/contactMethod';

import { stateAbbreviation } from 'ab-enums/states.enum';

import * as CodeUtils from 'ab-utils/codes.util';
import { capitalize } from 'ab-utils/text.util';

const CSV_HEADER_KEYS = [
	'Finished',
	'Status',
	'Job number',
	'Customer Company',
	'Job Title',
	'City',
	'State',
	'Calculated Start Date',
	'Guaranteed Completion Date',
	'Expected Completion Date',
	'Short Scope Summary',
	'Billing to Date',
	'Est Total Price',
	'Office',
	'Division',
	'Project Manager',
	'Priority',
	'Updated',
];

class AddressVM {
	city: Nullable<string>;
	state: Nullable<string>;

	constructor(address: Address) {
		this.city = address.locality;
		this.state = address.aa1;
	}
}

class JobStatusVM {
	id: number;
	name: string;
	description: Nullable<string>;
	color: ColorPalette;

	constructor(jobStatus: JobStatus) {
		this.id = jobStatus.id;
		this.name = jobStatus.name;
		this.description = jobStatus.description;
		this.color = jobStatus.color;
	}
}

export class ProjectVM {
	id: number;
	mainWorkRequest: SubjobVM;
	subjobs: SubjobVM[];

	constructor(project: Project) {
		this.id = project.id;
		this.mainWorkRequest = new SubjobVM(project.mainWorkRequest);
		this.subjobs = filterMap(
			project.workRequests,
			(subjob) => subjob.id !== project.mainWorkRequestId,
			(subjob) => SubjobVM.staticConstructor(subjob)
		);

	}
}

class SubjobVM {
	id: number;
	title: string;
	jobCode: string;

	constructor(job: WorkRequest) {
		this.id = job.id;
		this.title = job.title;
		this.jobCode = job.jobCode;
	}

	static staticConstructor(job: WorkRequest) {
		return new SubjobVM(job);
	}
}

export class JobTableViewModel {
	id: number;
	bidDate: Nullable<Date>;
	code: string;
	jobCode: string;
	customerCompanyName: Nullable<string>; // new job form
	customerCompany: Nullable<string>;
	customerCity: Nullable<string>;
	customerState: Nullable<string>;
	projectManager: Nullable<string>;
	status: WorkRequestStatus;
	jobStatus: Nullable<JobStatusVM>;
	updatedAt: Date;
	updatedBy: UpdatedByViewModel;
	/** YYYY-MM-DD */
	startDate: Nullable<string>;
	calculatedStartDate: Nullable<Date>;
	/** YYYY-MM-DD */
	targetCompletionDate: Nullable<string>;
	/** YYYY-MM-DD */
	guaranteedCompletionDate: Nullable<string>;
	priority: Priority;
	estimateTotal: Nullable<number>;
	title: Nullable<string>;
	publicLink: Nullable<string>;
	isInactive: boolean = false;
	company: string;
	isArchived: boolean;
	estTotalPrice?: number;
	office: Nullable<string>;
	division: Nullable<string>;
	travelLocation: Nullable<AddressVM>;
	isProject: boolean;
	projectId: Nullable<number>;
	project: Nullable<ProjectVM>;

	constructor(workRequest: WorkRequest) {
		const _projectManager: Nullable<User> = workRequest?.projectManager?.account?.user ?? null;

		this.id = workRequest.id;
		this.bidDate = workRequest.bidDate ? toUtcDate(workRequest.bidDate, TimeFormat.DB_DATE_ONLY) : null;
		this.code = CodeUtils.workRequestCode(workRequest.year, workRequest.code);
		this.jobCode = workRequest.jobCode ?? this.code;
		this.status = workRequest.status;
		this.jobStatus = workRequest.jobStatus ? new JobStatusVM(workRequest.jobStatus) : null;
		this.startDate = workRequest.startDate;
		this.calculatedStartDate = workRequest.calculatedStartDate ? toUtcDate(workRequest.calculatedStartDate, TimeFormat.DB_DATE_ONLY) : null;
		this.title = workRequest.title;
		this.updatedBy = new UpdatedByAccountViewModel(workRequest.updatedBy);
		this.updatedAt = workRequest.updatedAt;
		this.publicLink = workRequest.publicLink;
		this.customerCompany = null;
		this.customerCity = null;
		this.customerState = null;
		this.company = workRequest?.company?.name;
		this.isArchived = workRequest.isArchived;
		this.office = workRequest?.office?.nickname ?? null;
		this.division = workRequest?.division?.name ?? null;

		this.guaranteedCompletionDate = workRequest.guaranteedCompletionDate;
		this.targetCompletionDate = workRequest?.targetCompletionDate;
		this.estimateTotal = workRequest?.estimateTotal;
		this.priority = workRequest?.priority;

		this.projectManager = _projectManager ? `${_projectManager.firstName} ${_projectManager.lastName}` : null;

		this.customerCompanyName = workRequest.customerCompanyName;
		this.travelLocation = workRequest.travelLocation ? new AddressVM(workRequest.travelLocation) : null;

		const customer = workRequest.customerContact;

		this.customerCompany = customer?.contact?.companyName ?? null;
		this.customerCity = customer?.contactLookupAddresses?.[0]?.contactAddress?.address?.locality ?? null;
		this.customerState = customer?.contactLookupAddresses?.[0]?.contactAddress?.address?.aa1 ?? null;

		this.isInactive = workRequest.status === WorkRequestStatus.FINISHED;
		this.isProject = workRequest.project?.mainWorkRequestId === workRequest.id;
		this.projectId = workRequest.projectId;
		this.project = workRequest.project ? new ProjectVM(workRequest.project) : null;
	}

	static toCSVData(viewModels: JobTableViewModel[]): string[][] {
		const header: string[] = [...CSV_HEADER_KEYS];

		const rows: string[][] = viewModels.map((_entry: JobTableViewModel) => {
			const _row: string[] = [
				_entry?.status === WorkRequestStatus.FINISHED ? 'Yes' : 'No',
				_entry?.jobStatus?.name ?? 'None',
				_entry?.jobCode,
				_entry?.customerCompanyName ?? '-',
				_entry?.title ?? '',
				_entry?.travelLocation?.city ?? '-',
				(_entry?.travelLocation?.state && stateAbbreviation[_entry.travelLocation.state]) ?? _entry?.travelLocation?.state ?? '-',
				_entry?.calculatedStartDate ? formatDate(_entry.calculatedStartDate) : '-',
				_entry?.guaranteedCompletionDate ? formatDate(_entry.guaranteedCompletionDate) : '-',
				_entry?.targetCompletionDate ? formatDate(_entry.targetCompletionDate) : '-',
				'-', // entry.shortScopeSummary('-'), TODO: update when WorkRequestModel gets updated.
				'-', // entry.billingToDate('-'), TODO: update when WorkRequestModel gets updated.
				_entry?.estimateTotal?.toString() ?? '-',
				_entry?.office ?? '-',
				_entry?.division ?? '-',
				_entry?.projectManager ?? '-',
				capitalize(_entry?.priority) ?? '-',
				`${formatDate(_entry?.updatedAt, TimeFormat.FULL_DATE)} by ${_entry?.updatedBy?.fullName}`,
			];
			return _row;
		});

		return [header, ...rows];
	}
}

const getSelectedContactEmails = (billingContact: ContactLookup): string[] => {
	const selectedEmailIds = ContactUtils.filterContactMethod(billingContact.contactLookupMethods, EmailTypesArray);
	const emailTypeEmailIdsMap: Record<string, number[]> = {};
	billingContact.contact.contactMethods.forEach((_method) => {
		if (selectedEmailIds.includes(_method.id)) {
			emailTypeEmailIdsMap[_method.type] = emailTypeEmailIdsMap[_method.type]
				? [...emailTypeEmailIdsMap[_method.type], _method.id]
				: [_method.id];
		}
	});
	return billingContact.contact.contactMethods.reduce((_acc: string[], _method: ContactMethod) => {
		const selectedIdForMethodType = emailTypeEmailIdsMap[_method.type];
		if (selectedIdForMethodType?.length === 1) {
			_acc.push(_method.value);
		}
		return _acc;
	}, []);
};

export function JobsTableViewModel(jobs: WorkRequest[]): JobTableViewModel[] {
	return jobs.map((_job: WorkRequest) => new JobTableViewModel(_job));
}

export interface PreviewInvoiceContactVM {
	selectedContactMethodEmails: string[];
}

export class JobSelectForInvoicesViewModel {
	id: number;
	jobCode: string;
	isInternal: boolean;
	title: Nullable<string>;
	customerCompany: Nullable<string>;
	customerFormatted: Nullable<string>;
	travelLocationShort: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	office: Nullable<string>;
	billingContact: Nullable<PreviewInvoiceContactVM>;

	constructor(workRequest: WorkRequest) {
		this.id = workRequest.id;
		this.jobCode = workRequest.jobCode;
		this.isInternal = !!workRequest.isInternal;
		this.title = workRequest.title;
		this.customerCompany = workRequest.customerCompanyName ?? workRequest.customerContact?.contact.companyName ?? null;
		this.customerFormatted = workRequest.customerContact?.contact.fullName ? `${workRequest.customerContact.contact.fullName}${this.customerCompany ? `, ${this.customerCompany}` : ''}` : null;
		this.travelLocationShort = workRequest.travelLocation ? getShortAddress(workRequest.travelLocation) : null;
		this.office = workRequest.office?.nickname ?? null;
		this.city = workRequest.travelLocation?.locality ?? null;
		this.state = workRequest.travelLocation?.aa1 ?? null;
		this.billingContact = workRequest.billingContact ? {
			selectedContactMethodEmails: getSelectedContactEmails(workRequest.billingContact),
		} : null;
	}

}

export function JobsSelectForInvoicesViewModel(jobs: WorkRequest[]): JobSelectForInvoicesViewModel[] {
	return jobs.map((_job: WorkRequest) => new JobSelectForInvoicesViewModel(_job));
}
