import * as React from 'react';

import WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import ResourcesList from './ResourcesList';

import Changes from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfoModal/Changes';

import SegmentLabel from 'af-components/SegmentLabel';

import { getAddedWorkOrderResources, getRemovedWorkOrderResourceDictionary } from 'af-utils/workOrderModal.util';

interface Props {
	workOrderDataToShow: WorkOrderModalViewModel;
	currentWorkOrderData: WorkOrderModalViewModel;
}

class ResourceLookupsSection extends React.PureComponent<Props> {
	getAddedWorkOrderResourcesPopover = () => {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;

		const addedWorkOrderResources = getAddedWorkOrderResources(workOrderDataToShow, currentWorkOrderData);

		if (addedWorkOrderResources.length === 0) {
			return null;
		}

		return (
			<>
				<span className="added-work-order-resource-info">List of resources that were added in the meantime:</span>
				<ResourcesList
					dueDate={currentWorkOrderData.dueDate}
					workOrderResourceLookups={addedWorkOrderResources}
					workOrderStatus={currentWorkOrderData.status}
				/>
			</>
		);
	};

	render() {
		const { workOrderDataToShow, currentWorkOrderData } = this.props;
		const removedWorkOrderResourceDictionary = getRemovedWorkOrderResourceDictionary(workOrderDataToShow, currentWorkOrderData);

		const addedWorkOrderResourcesPopover = this.getAddedWorkOrderResourcesPopover();

		const isAtLeastOneResourceLookupRemoved = Object.keys(removedWorkOrderResourceDictionary).length > 0;

		return (
			<div className="work-order-info-modal__resources-container">
				<Changes addedDataPopover={addedWorkOrderResourcesPopover} removedData={isAtLeastOneResourceLookupRemoved} />
				<SegmentLabel label="LABOR AND EQUIPMENT" />
				<ResourcesList
					dueDate={currentWorkOrderData.dueDate}
					removedWorkOrderResourceLookupsDictionary={removedWorkOrderResourceDictionary}
					workOrderResourceLookups={workOrderDataToShow?.workOrderResourceLookups ?? []}
					workOrderStatus={currentWorkOrderData.status}
				/>
			</div>
		);
	}
}

export default ResourceLookupsSection;
