import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';

export default function withRouterV6<P extends object>(
	WrappedComponent: React.ComponentType<P>
): React.FC<P> {
	const WithRouterV6: React.FC<P> = (props: P) => {
		const navigate = useNavigate();
		const location = useLocation();
		const params = useParams();

		return (
			<WrappedComponent location={location} navigate={navigate} params={params} {...props as P} />
		);

	};
	return WithRouterV6;
}
