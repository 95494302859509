import * as React from 'react';
import { Button } from 'react-bootstrap';

import { SCREEN_BREAKPOINT_XL } from 'af-constants/values';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	onPress?: () => void;
}

type Props = OwnProps;

const NightShiftsButton: React.FC<Props> = (props: Props) => {
	const { onPress } = props;

	const [isButtonSmall, setIsButtonSmall] = React.useState(window.innerWidth < SCREEN_BREAKPOINT_XL);

	const updateWindowDimensions = React.useCallback(() => {
		const nextIsButtonSmall = window.innerWidth < SCREEN_BREAKPOINT_XL;

		if (isButtonSmall !== nextIsButtonSmall) {
			setIsButtonSmall(nextIsButtonSmall);
		}
	}, [isButtonSmall]);

	React.useEffect(() => {
		updateWindowDimensions();
		window.addEventListener('resize', updateWindowDimensions);

		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, [updateWindowDimensions]);

	const button = React.useMemo(() => (
		<Button className="btn--icon" onClick={onPress} variant="info">
			<span className="icon-moon" />
			{!isButtonSmall && 'Night Shifts'}
		</Button>
	), [isButtonSmall, onPress]);

	return isButtonSmall
		? <Tooltip message="Show yesterday's night shifts">{button}</Tooltip>
		: button;
};

export default React.memo(NightShiftsButton);
