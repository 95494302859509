import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

const TOOLTIP_COPY_MESSAGE = 'Copy to clipboard.';
const TOOLTIP_COPIED_MESSAGE = 'Copied!';
const TOOLTIP_MESSAGE_RESET_TOME = 1000;

interface OwnProps {
	value: string;
	parentId: string;
}

interface State {
	tooltipMessage: string;
}

type Props = OwnProps;

class CopyValueComponent extends React.PureComponent<Props, State> {
	state: State = {
		tooltipMessage: TOOLTIP_COPY_MESSAGE,
	};

	timeoutTimer: number;

	componentWillUnmount() {
		if (this.timeoutTimer) {
			window.clearTimeout(this.timeoutTimer);
		}
	}

	copyToClipboard = () => {
		const { value, parentId } = this.props;
		const elem = document.createElement('input');
		const parent = document.getElementById(parentId);

		elem.value = value;
		parent?.appendChild(elem);
		elem.select();
		document.execCommand('copy');
		parent?.removeChild(elem);

		this.setState(() => ({ tooltipMessage: TOOLTIP_COPIED_MESSAGE }));
		this.clearCopyToClipboardText();
	};

	clearCopyToClipboardText = () => {
		this.timeoutTimer = window.setTimeout(
			() => this.setState(() => ({ tooltipMessage: TOOLTIP_COPY_MESSAGE })),
			TOOLTIP_MESSAGE_RESET_TOME
		);
	};

	render() {
		const { tooltipMessage } = this.state;
		return (
			<Tooltip className="tooltip--center-text" message={tooltipMessage} placement="right">
				<span className="icon-copy contact-preview__method-copy" onClick={this.copyToClipboard}/>
			</Tooltip>
		);
	}
}

export default CopyValueComponent;
