import * as React from 'react';

import { ColorPalette } from 'acceligent-shared/enums/color';

import Tooltip from 'af-components/Tooltip';

import { getColorBackgroundClass } from 'ab-utils/color.util';

interface BarMeterEntry {
	label: string;
	value: number;
	color?: ColorPalette;
	isHidden?: boolean;
}

interface BarMeterEntryState extends BarMeterEntry {
	percentage: number;
	colorBackgroundClass: string;
}

const _sumValues = (sum: number, entry: BarMeterEntry) => sum + entry.value;

interface OwnProps {
	entries: BarMeterEntry[];
	total?: number;
	unitLabel?: string;
	withTooltip?: boolean;
	tooltipTitle?: string;
}

type Props = OwnProps;

interface State {
	entryStates: BarMeterEntryState[];
	total: number;
}

class BarMeter extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		entries: [],
		withTooltip: false,
	};

	state: State = {
		entryStates: [],
		total: 0,
	};

	static getDerivedStateFromProps(props: Props): Partial<State> {
		const entryStates: BarMeterEntryState[] = [];
		const total: number = props.total ?? props.entries.reduce(_sumValues, 0);
		let totalPercentage = 0;

		if (!props.entries.length) {
			return { entryStates, total };
		}

		for (const entry of props.entries) {
			const percentage = total ? Math.round(100 * entry.value / total) : 0;
			const colorBackgroundClass = getColorBackgroundClass(entry.color, true);
			entryStates.push({ ...entry, percentage, colorBackgroundClass });
			totalPercentage += percentage;
		}
		if (totalPercentage !== 0) {
			while (totalPercentage !== 100) {
				// fix percentages so that all sum up to 100
				for (const entry of entryStates) {
					if (entry.percentage === 0) {
						continue;
					}
					if (totalPercentage === 100) {
						break;
					}
					const diff = totalPercentage > 100 ? -1 : 1;
					entry.percentage += diff;
					totalPercentage += diff;
				}
			}
		}
		return { entryStates, total };
	}

	renderTooltipMessage = () => {
		const { entryStates } = this.state;
		const { tooltipTitle, unitLabel } = this.props;
		const lines: JSX.Element[] = [];

		for (const entry of entryStates) {
			lines.push(
				<span key={entry.label}>
					<strong>{entry.label}:</strong>&nbsp;{entry.percentage}% ({entry.value}{unitLabel ? ` ${unitLabel}` : ''})
				</span>
			);
		}
		return (
			<>
				{tooltipTitle && <h5>{tooltipTitle}</h5>}
				{lines}
			</>
		);
	};

	renderBars = (): JSX.Element[] => {
		const { entryStates } = this.state;
		const bars: JSX.Element[] = [];

		for (const entry of entryStates) {
			if (entry.isHidden) {
				continue;
			}
			bars.push(
				<div
					className={`bar-meter__percentage ${entry.colorBackgroundClass}`}
					key={entry.label}
					style={{ width: `${entry.percentage}%` }}
				/>
			);
		}
		return bars;
	};

	render() {
		const { withTooltip } = this.props;

		if (withTooltip) {
			return (
				<Tooltip className="bar-meter__tooltip" message={this.renderTooltipMessage()}>
					<div className="bar-meter">
						{this.renderBars()}
					</div>
				</Tooltip>
			);
		}
		return (
			<div className="bar-meter">
				{this.renderBars()}
			</div>
		);
	}
}

export default BarMeter;
