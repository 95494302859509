import * as React from 'react';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars';

interface OwnProps {
	contentWrapperClassName?: string;
	isVerticalHidden?: boolean;
	isHorizontalHidden?: boolean;
	scrollbarsClassName?: string;
	onMount?: (element: React.RefObject<Scrollbars>) => void;
	onScroll?: (scrollPercentage: number, width: number) => void;
	onScrollUpdate?: () => void;
}

type Props = React.PropsWithChildren<OwnProps>;

export default class CustomScrollbar extends React.Component<Props> {
	static defaultProps: Partial<Props> = {
		scrollbarsClassName: '',
		contentWrapperClassName: '',
		isVerticalHidden: false,
		isHorizontalHidden: false,
	};

	_scrollbars: Nullable<React.RefObject<Scrollbars>> = null;
	_lastClientY = null;
	_lastClientX = null;
	_dragging = false;

	constructor(props: Props) {
		super(props);
		this._scrollbars = React.createRef();
	}

	componentDidMount() {
		const { onMount } = this.props;
		if (onMount && this._scrollbars) {
			onMount(this._scrollbars);
		}
	}

	componentDidUpdate() {
		const { onScrollUpdate } = this.props;
		if (onScrollUpdate) {
			onScrollUpdate();
		}
	}

	handleScroll = () => {
		const { onScroll } = this.props;
		if (onScroll && this._scrollbars?.current) {
			const { scrollLeft, scrollWidth, clientWidth } = this._scrollbars.current.getValues();
			const scrollPercentage = scrollLeft / (scrollWidth - clientWidth);
			onScroll(scrollPercentage, scrollWidth - clientWidth);
		}
	};

	renderHiddenElement = (): JSX.Element => <div className="display-none" />;

	render() {
		const { children, scrollbarsClassName, contentWrapperClassName, isHorizontalHidden, isVerticalHidden } = this.props;

		const scrollbarProps: ScrollbarProps = {
			ref: this._scrollbars,
			autoHide: true,
			autoHideTimeout: 200,
			autoHideDuration: 200,
			thumbMinSize: 60,
			universal: true,
			className: scrollbarsClassName,
			onScroll: this.handleScroll,
			hideTracksWhenNotNeeded: true,
		};

		if (isHorizontalHidden) {
			scrollbarProps.renderTrackHorizontal = this.renderHiddenElement;
			scrollbarProps.renderThumbHorizontal = this.renderHiddenElement;
		}

		if (isVerticalHidden) {
			scrollbarProps.renderTrackVertical = this.renderHiddenElement;
			scrollbarProps.renderThumbVertical = this.renderHiddenElement;
		}

		return (
			<Scrollbars {...scrollbarProps} >
				<div
					className={contentWrapperClassName}
				>
					{children}
				</div>
			</Scrollbars>
		);
	}
}
