import * as React from 'react';

interface OwnProps {
	title: string;
	type: 'segment' | 'block';
	style: 'primary' | 'secondary';
	/** hidden if undefined or null */
	completionStatus?: Nullable<boolean>;
}

type Props = OwnProps;

const PrintoutSegmentHeader: React.FC<Props> = (props: Props) => {
	const { title, type, style, completionStatus } = props;

	const isCompletionStatusHidden = completionStatus === undefined || completionStatus === null;

	return (
		<div className={`printout-segment-header printout-segment-header--${type}-${style}`}>
			<div className="printout-segment-header__title">
				{title}
			</div>
			{!isCompletionStatusHidden &&
				<div className="printout-segment-header__completion">
					{completionStatus
						? <><span className="icon icon-check" /> Completed</>
						: <><span className="icon icon-close" /> Not completed</>
					}
				</div>
			}
		</div>
	);
};

export default React.memo(PrintoutSegmentHeader);
