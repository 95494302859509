import * as React from 'react';

import styles from './styles.module.scss';

interface OwnProps {
	isSelected: boolean;
	onSelect: (tabId: number) => void;
	tabId: number;
	title: string;
}

type Props = OwnProps;

const Tab: React.FC<Props> = (props: Props) => {
	const { tabId, onSelect, isSelected, title } = props;

	const select = React.useCallback(() => onSelect(tabId), [tabId, onSelect]);

	const tabClassName = isSelected
		? [styles['signature-modal__tab'], styles['signature-modal__tab--active']].join(' ')
		: styles['signature-modal__tab'];

	return (
		<div className={tabClassName} onClick={select}>
			{title}
		</div>
	);
};

export default React.memo(Tab);
