import * as constants from 'af-constants/actionTypes';

export interface UploadProgress {
	type: typeof constants.UPLOAD_PROGRESS;
	payload: number;
}

interface DownloadProgressSize {
	type: typeof constants.DOWNLOAD_PROGRESS_SIZE;
	payload: number;
}

interface DownloadProgress {
	type: typeof constants.DOWNLOAD_PROGRESS;
	payload: number;
}

export interface UploadProgressSize {
	type: typeof constants.UPLOAD_PROGRESS_SIZE;
	payload: number;
}

export interface SubmitStart {
	type: typeof constants.SUBMIT_START;
	payload: string | string[];
}

export interface SubmitEnd {
	type: typeof constants.SUBMIT_END;
	payload: string | string[];
}

type HttpAction = (
	{ type: typeof constants.FETCH_START | typeof constants.FETCH_END; }
	| UploadProgress
	| UploadProgressSize
	| DownloadProgress
	| DownloadProgressSize
	| SubmitStart
	| SubmitEnd
);

export interface HttpStoreState {
	isFetching: boolean;
	uploadProgress: number;
	uploadProgressSize: number;
	downloadProgress: number;
	downloadProgressSize: number;
	submitting: string[];
}

export function UPLOAD_PROGRESS(payload: number): UploadProgress {
	return {
		type: constants.UPLOAD_PROGRESS,
		payload,
	};
}

export function UPLOAD_PROGRESS_SIZE(payload: number): UploadProgressSize {
	return {
		type: constants.UPLOAD_PROGRESS_SIZE,
		payload,
	};
}

export function DOWNLOAD_PROGRESS(payload: number): DownloadProgress {
	return {
		type: constants.DOWNLOAD_PROGRESS,
		payload,
	};
}

export function DOWNLOAD_PROGRESS_SIZE(payload: number): DownloadProgressSize {
	return {
		type: constants.DOWNLOAD_PROGRESS_SIZE,
		payload,
	};
}

export function SUBMIT_START(payload: string | string[]): SubmitStart {
	return {
		type: constants.SUBMIT_START,
		payload,
	};
}

export function SUBMIT_END(payload: string | string[]): SubmitEnd {
	return {
		type: constants.SUBMIT_END,
		payload,
	};
}

const initialState: HttpStoreState = {
	isFetching: false,
	submitting: [],
	uploadProgress: 0,
	uploadProgressSize: 0,
	downloadProgress: 0,
	downloadProgressSize: 0,
};

export default function HttpReducer(state: HttpStoreState = initialState, action: HttpAction): HttpStoreState {
	switch (action.type) {
		case constants.FETCH_START:
			return { ...state, isFetching: true };
		case constants.FETCH_END:
			return { ...state, isFetching: false };
		case constants.UPLOAD_PROGRESS:
			return { ...state, uploadProgress: action.payload };
		case constants.UPLOAD_PROGRESS_SIZE:
			return { ...state, uploadProgressSize: action.payload };
		case constants.DOWNLOAD_PROGRESS:
			return { ...state, downloadProgress: action.payload };
		case constants.DOWNLOAD_PROGRESS_SIZE:
			return { ...state, downloadProgressSize: action.payload };
		case constants.SUBMIT_START:
			return {
				...state,
				submitting: Array.isArray(action.payload) ? [...state.submitting, ...action.payload] : [...state.submitting, action.payload],
			};
		case constants.SUBMIT_END:
			return {
				...state,
				submitting: Array.isArray(action.payload) ?
					state.submitting.filter((key: string) => !action.payload.includes(key)) :
					state.submitting.filter((key: string) => key !== action.payload),
			};
		default:
			return state;
	}
}
