import * as React from 'react';

interface OwnProps {
	numberOfMails: number;
	numberOfSms: number;
	dueDate: string;
	description?: string;
}

type Props = OwnProps;

class TableFooter extends React.PureComponent<Props> {

	render() {
		const { description, numberOfMails, numberOfSms, dueDate } = this.props;
		return (
			<div className="question">
				Send <b><span className="text-orange">{numberOfMails}</span></b> e-mail notifications
				and <b><span className="text-orange">{numberOfSms}</span></b> SMS notifications
				to laborers {description} for <b>{dueDate}</b>?
			</div>
		);
	}
}

export default TableFooter;
