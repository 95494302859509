import CountryCode, { CountryCallingCode } from 'acceligent-shared/enums/countryCode';

/**
 * Regexp for raw phone number format.
 * Basically determines how many digits are in the phone number.
 */
export const PhoneNumberRawInputRegexp = {
	[CountryCode.US]: /(\d{3})(\d{3})(\d{4})/,
	[CountryCode.HR]: /(\d{2})(\d{3})(\d{3}\d?)/,
};

/**
 * Regexp for phone number used on client side (phone number fields)
 */
export const PhoneNumberFormattedInputRegexp = {
	[CountryCode.US]: /^\([0-9]{3}\) [0-9]{3}-([0-9]{4})$/i,
	[CountryCode.HR]: /(\d{2})(\d{3})(\d{3}\d?)/,
};

export const PhoneNumberFormatRegexp = {
	[CountryCode.US]: { input: PhoneNumberRawInputRegexp[CountryCode.US], output: '($1) $2-$3' },
	[CountryCode.HR]: { input: PhoneNumberRawInputRegexp[CountryCode.HR], output: '$1 $2 $3' },
};

/**
 * Formats phone number
 * @param phoneNumber phone number - database format (only numbers)
 * @param countryCode country code - determines the output format of phone number
 * @example
 * formatPhoneNumber('1234567890', 'US') => '(123) 456-7890'
 * formatPhoneNumber('911234567', 'HR') => '091 123 4567'
 */
export const formatPhoneNumber = (phoneNumber: Nullable<string>, countryCode: Nullable<CountryCode>) => {
	if (!phoneNumber || !countryCode || !PhoneNumberFormatRegexp[countryCode]) {
		return phoneNumber; // keep null or undefined, whatever it was
	}

	const { input, output } = PhoneNumberFormatRegexp[countryCode];
	return phoneNumber.replace(input, output);
};

/**
 * Converts any phone number format to database phone number format
 * @param phoneNumber phone number in any format
 * @param countryCode optional country code param if you want phone number with calling code as well
 * @example
 * toRawPhoneNumber('(123) 456-7890') => '1234567890'
 * toRawPhoneNumber('123-456-7890') => '1234567890'
 */
export const toRawPhoneNumber = (phoneNumber: string, countryCode?: Nullable<CountryCode>) => {
	if (!phoneNumber) {
		return phoneNumber; // keep null or undefined, whatever it was
	}

	const countryCallingCode = countryCode ? CountryCallingCode[countryCode] : '';
	return `${countryCallingCode}${removeSpecialCharacters(phoneNumber)}`;
};

/**
 * Checks if the phone number (raw number, i.e. database format) is valid for the given country code
 *
 * @param phoneNumber phone number (raw number, i.e. database format)
 * @param countryCode country code param which determines phone number format, defaults to `US` if `null` or `undefined`
 * @example
 * isValidPhoneNumber('1234567890', 'US') => true
 * isValidPhoneNumber('432', 'US') => false
 */
export const isValidPhoneNumber = (phoneNumber: string, countryCode?: Nullable<CountryCode>) => {
	const regexp = PhoneNumberRawInputRegexp[countryCode ?? CountryCode.US];
	if (!regexp) {
		return false;
	}

	return regexp.test(phoneNumber);
};

export const removeSpecialCharacters = (phoneNumber: string) => phoneNumber ? phoneNumber.replace(/[^\d]/g, '') : '';

export const removeCountryCallingCode = (phoneNumber: string, countryCode: CountryCode) => phoneNumber ? phoneNumber.replace(CountryCallingCode[countryCode], '') : '';

/**
 * Returns raw phone number without any formatting or calling code
 * @param value phone number
 * @returns normalized raw phone number value
 */
export const normalizeToRawPhoneNumber = (value: string) => {
	if (!value) {
		return value;
	} else if (value.startsWith(CountryCallingCode[CountryCode.US])) {
		return toRawPhoneNumber(value.replace(CountryCallingCode[CountryCode.US], ''));
	} else if (value.startsWith(CountryCallingCode[CountryCode.HR])) {
		return toRawPhoneNumber(value.replace(CountryCallingCode[CountryCode.US], ''));
	}
	return toRawPhoneNumber(value);
};
