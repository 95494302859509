import * as React from 'react';

interface OwnProps {
	errorColumns: string;
}

type Props = OwnProps;

const ValidationBanner: React.FC<Props>  = (props: Props) => {
	const { errorColumns } = props;

	return (
		<div className="banner-wrapper">
			<div className="banner-container">
				<div className="banner-description">
					<strong>Data not saved</strong>
				</div>
				<div className="banner-description">
					<strong>Validation Error in Column(s):</strong>
					<span className="m-l-s">{errorColumns}</span>
				</div>
			</div>
		</div>
	);
};

export default React.memo(ValidationBanner);
