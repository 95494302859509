import { Dispatch, AnyAction } from 'redux';

import { ShiftViewModel } from 'ab-viewModels/shift.viewModel';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { GetRootState } from 'af-reducers';

export function create(name: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<ShiftViewModel>(API.V1.RESOURCES.SHIFT.CREATE(), { name });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findByQuery(query: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<ShiftViewModel[]>(API.V1.RESOURCES.SHIFT.LIST(query));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
