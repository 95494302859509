import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';

import LoadingIndicator from 'af-components/LoadingIndicator';

import { RootState } from 'af-reducers';

interface OwnProps {
	label?: string | JSX.Element;
	variant?: string;
	variantDisabled?: string;
	disabled?: boolean;
	reduxFormSubmitting?: boolean;
	type?: string;
	/** often form name value */
	submitKey?: Nullable<string>;
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	className?: string;
	id?: string;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

class SubmitButton extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		disabled: false,
		label: 'Submit',
		type: 'submit',
		variant: 'primary',
		submitKey: null,
		className: '',
	};

	onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		const { onClick, isSubmitting } = this.props;
		if (onClick && !isSubmitting) {
			onClick(e);
		} else if (!onClick && isSubmitting) {
			e.preventDefault();
		}
	};

	render() {
		const {
			variant,
			variantDisabled,
			className,
			disabled,
			isFormValid,
			isSubmitting,
			label,
			onClick,
			type,
			id,
		} = this.props;

		const isDisabled = !!disabled || !isFormValid;
		let cn = className;
		cn = isSubmitting ? `${cn} btn--fetching` : cn;

		return (
			<Button
				className={cn}
				disabled={isDisabled || isSubmitting}
				id={id}
				onClick={isDisabled || isSubmitting ? undefined : onClick}
				type={type}
				variant={!isDisabled ? variant : (variantDisabled ?? variant)}
			>
				{isSubmitting ? <LoadingIndicator color="white" size="small" /> : label}
			</Button>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { http: { submitting }, form } = state;
	const { submitKey, reduxFormSubmitting } = ownProps;

	const formSyncErrors = submitKey ? form[submitKey]?.syncErrors : null;
	const isFormValid = !!formSyncErrors ? Object.keys(formSyncErrors).length === 0 : true;
	const isSubmitting = submitKey ? submitting.includes(submitKey) : reduxFormSubmitting;

	return {
		isFormValid,
		isSubmitting,
	};
}

const connector = connect(mapStateToProps);

export default connector(SubmitButton);
