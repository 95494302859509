import { PurchaseOrderFormModel } from './formModel';

export const validate = (form: PurchaseOrderFormModel) => {
	const errors: ValidationErrors = {};

	if (!form.status) {
		errors.status = 'Status is required';
	}

	if (!form.vendorId) {
		errors.vendor = 'Vendor is required';
	}

	if (form.purchaseOrderItems?.length) {
		const itemsErrors: ValidationErrors[] = [];
		form.purchaseOrderItems.forEach((item, index) => {
			const itemErrors: ValidationErrors = {};

			if (!item.quantity) {
				itemErrors.quantity = 'Quantity is required';
			} else if (item.quantity <= 0) {
				itemErrors.quantity = 'Quantity must be positive';
			}
			if (!item.itemName) {
				itemErrors.itemName = 'Item name is required';
			}
			if (!item.priceEach && item.priceEach !== 0) {
				itemErrors.priceEach = 'Price each is required';
			} else if (item.priceEach < 0) {
				itemErrors.priceEach = 'Price each must be positive';
			}
			if (!item.itemsReceived) {
				itemErrors.itemsReceived = 'Items received is required';
			}
			if (!item.itemsInvoiced) {
				itemErrors.itemsInvoiced = 'Items invoiced is required';
			}

			itemsErrors[index] = itemErrors;
		});

		if (itemsErrors.length > 0) {
			errors.purchaseOrderItems = itemsErrors;
		}
	}

	return errors;
};
