/**
 * Checks whether passed object is empty.
 * @example
 * isEmpty({}) => true
 * isEmpty({ key1: 'value1' }) => false
 */

export const isEmpty = (objectToCheck: Record<string, unknown>) => {
	return objectToCheck.constructor === Object && Object.keys(objectToCheck).length === 0;
};

/**
 * Creates union of two objects
 *
 * Adds all properties from `newValues` missing in `original`
 *
 * Removes all properties from `original` missing in `newValues`
 *
 * Keeps all references for properties both in `original` and `newValues`
 */
export const join = <T extends Record<string, unknown>> (original: T, newValues: T): T => {
	const originalKeys = Object.keys(original);
	const newValuesKeys = Object.keys(newValues);

	const result = originalKeys.reduce((_acc: T, _key: keyof T) => {
		if (newValues[_key]) {
			// keep only properties found in both arguments
			_acc[_key] = original[_key];
		}
		return _acc;
	}, {} as T);

	return newValuesKeys.reduce((_acc: T, _key: keyof T) => {
		if (!original[_key]) {
			// add only missing keys
			_acc[_key] = newValues[_key];
		}
		return _acc;
	}, { ...result });
};
