import * as React from 'react';
import { flexRender, Header } from '@tanstack/react-table';

import styles from '../Table/styles.module.scss';

interface Props<T> {
	headerCellClassName: string;
	header: Header<T, unknown>;
	setHeaderReference: (headerId: string) => (_ref: HTMLSpanElement) => HTMLSpanElement;
	isDisplayColumn: boolean;
	onColumnDrag: (header: string) => () => void;
	headerCellStyleProps: React.CSSProperties;
	onSortChange: (_header: Header<T, unknown>) => (e: React.MouseEvent<HTMLSpanElement>) => Promise<void>;
}
function BaseHeaderCell<T,>({
	header,
	headerCellClassName,
	isDisplayColumn,
	onColumnDrag,
	setHeaderReference,
	headerCellStyleProps,
	onSortChange,
}: Props<T>) {
	const renderSortIcon = React.useCallback((_header: Header<T, unknown>) => {
		const isSorted = _header.column.getIsSorted();
		const isSortable = _header.column.getCanSort();

		if (!isSortable) {
			return null;
		}

		if (!isSorted) {
			return <span className="icon-table_sort" />;
		}

		if (isSorted === 'asc') {
			return <span className="icon-up" />;
		} else {
			return <span className="icon-down" />;
		}
	}, []);

	const resizerStyle = styles['table-container__table__body__row__header-cell__resizer'];

	const textStyle = styles['table-container__table__body__row__header-cell__text-with-sort'];

	return (
		<span
			className={headerCellClassName}
			key={header.id}
			ref={setHeaderReference(header.id)}
			style={headerCellStyleProps}
		>
			<span
				className={textStyle}
				onClick={onSortChange(header)}
			>
				{flexRender(header.column.columnDef.header, header.getContext())}
				{renderSortIcon(header)}
			</span>
			{!isDisplayColumn &&
				<span
					className={resizerStyle}
					onMouseDown={onColumnDrag(header.id)}
				/>
			}
		</span>
	);
}

const propsAreEqual = (prevProps, nextProps) => {
	if (prevProps.header !== nextProps.header) {
		return false;
	}
	if (prevProps.headerCellClassName !== nextProps.headerCellClassName) {
		return false;
	}
	if (prevProps.isDisplayColumn !== nextProps.isDisplayColumn) {
		return false;
	}
	if (prevProps.onColumnDrag !== nextProps.onColumnDrag) {
		return false;
	}
	if (prevProps.setHeaderReference !== nextProps.setHeaderReference) {
		return false;
	}
	if (prevProps.onSortChange !== nextProps.onSortChange) {
		return false;
	}
	for (const _style of Object.keys(nextProps.headerCellStyleProps)) {
		if (prevProps.headerCellStyleProps[_style] !== nextProps.headerCellStyleProps[_style]) {
			return false;
		}
	}

	return true;
};

export default React.memo(BaseHeaderCell, propsAreEqual) as typeof BaseHeaderCell;
