import * as React from 'react';
import * as qs from 'query-string';
import { CustomRouteComponentProps, Route } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import * as AuthenticationActions from 'af-actions/authentication';

import * as UserRequestModel from 'ab-requestModels/users.requestModel';

import CustomerSupport from 'af-components/CustomerSupport';

import CLIENT from 'af-constants/routes/client';

import { RootState } from 'af-reducers';

import { toRawPhoneNumber } from 'ab-utils/phone.util';
import { getPlatformAdminDataFromStorage } from 'af-utils/localStorage.util';

import PhoneNumberForm from './PhoneNumberForm';
import EmailForm from './EmailForm';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	isEmailForm: boolean;
}

const isAdminLoggedIn = (): boolean => {
	return !!getPlatformAdminDataFromStorage();
};

const isEmailFormCheck = (props: Props) => {
	const { isPhoneForm } = qs.parse(props.location.search) as { isPhoneForm: string; };
	return isPhoneForm !== 'true';
};

class Login extends React.PureComponent<Props, State> {

	state: State = {
		isEmailForm: isEmailFormCheck(this.props),
	};

	async componentDidMount() {
		const { platformAdminOrganizationLogin, location: { state: { orgAlias } } } = this.props;
		if (isAdminLoggedIn()) {
			await platformAdminOrganizationLogin(orgAlias);
		}
	}

	emailLogin = async (form: UserRequestModel.UserCredentials) => {
		const { emailLogin, location: { state: { orgAlias } } } = this.props;
		await emailLogin(new UserRequestModel.UserEmailCredentials(form.email, form.password, orgAlias));
	};

	phoneCodeRequest = async (form: UserRequestModel.UserCredentials) => {
		const { history, phoneCodeRequest, location: { state: { orgAlias } } } = this.props;
		const phoneNumber = toRawPhoneNumber(form.phoneNumber);
		await phoneCodeRequest(phoneNumber, form.countryCode, orgAlias);
		history.push(CLIENT.AUTH.LOGIN_CODE(orgAlias));
	};

	setEmailForm = () => this.setState(() => ({ isEmailForm: true }));

	setPhoneForm = () => this.setState(() => ({ isEmailForm: false }));

	renderCustomerSupportButton = () => <CustomerSupport isSidenavLink={false} location={this.props.location} />;

	render() {
		const { organizationPublicDetails, location: { state: { orgAlias } } } = this.props;
		const { isEmailForm } = this.state;

		if (!organizationPublicDetails) {
			return null;
		}

		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone">
					<h2>Log into {organizationPublicDetails.name}</h2>
					<h4><b>{window.location.host}</b></h4>
					<div className="login-tabs">
						<span className={`login-tabs__tab ${isEmailForm ? 'login-tabs__tab--active' : ''}`} onClick={this.setEmailForm}>Email</span>
						<span className={`login-tabs__tab ${!isEmailForm ? 'login-tabs__tab--active' : ''}`} onClick={this.setPhoneForm}>Phone</span>
					</div>
					{isEmailForm ?
						<EmailForm onSubmit={this.emailLogin} orgAlias={orgAlias} /> :
						<PhoneNumberForm onSubmit={this.phoneCodeRequest} />
					}
					<Route component={this.renderCustomerSupportButton} />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	return {
		organizationPublicDetails: state.organization.publicDetails,
	};
}

function mapDispatchToProps() {
	return {
		emailLogin: AuthenticationActions.emailLogin,
		phoneCodeRequest: AuthenticationActions.phoneCodeRequest,
		platformAdminOrganizationLogin: AuthenticationActions.platformAdminOrganizationLogin,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Login);
