import * as React from 'react';

interface OwnProps {
	activeCount: Nullable<number>;
	idleCount: Nullable<number>;
}

type Props = OwnProps;

const ConnectionCounters: React.FC<Props> = (props: Props) => {
	const { activeCount, idleCount } = props;

	return (
		<div className="connection-counters">
			<div className="connection-counters__counter">
				<span className="icon-user text-green" />
				<span>{activeCount ?? '*'} active user{activeCount === 1 ? '' : 's'}</span>
			</div>
			<div className="connection-counters__counter">
				<span className="icon-user" />
				<span>{idleCount ?? '*'} idle user{idleCount === 1 ? '' : 's'}</span>
			</div>
		</div>
	);
};

export default React.memo(ConnectionCounters);
