import * as React from 'react';
import { Field } from 'redux-form';

import Textarea from 'af-fields/Textarea';

import WorkRequestUpsertFM from '../../formModel';
import { formSectionClass, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../helpers';

const WORK_SUMMARY_MAX_LENGTH = 600;

const SummaryOfWorkSection: React.FC = () => {
	return (
		<div className={formSectionClass}>
			<div className={formSectionClassRow}>
				<div className={formSectionTitleClass}>
					Summary of Work
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					<Field
						component={Textarea}
						maxCharacters={WORK_SUMMARY_MAX_LENGTH}
						name={WorkRequestUpsertFM.getAttributeName('requestJobNote')}
						placeholder="Enter text"
						rows={4}
						showMaxCharactersLabel={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(SummaryOfWorkSection);
