import * as React from 'react';

import { filterMap } from 'acceligent-shared/utils/array';

import WorkSummaryVM from 'ab-viewModels/fieldReport/workSummary.viewModel';
import { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';

import { CellWidthsMap } from 'af-utils/react.util';

import WorkSummaryTable from '../../Table/index';
import WorkSummaryBillableWorkTableRow, { BillableWorkTableRowProps, NonBillableWorkTableRowProps } from './BillableAndNonBillableWorkTableRow';
import { WorkSummaryTableSortKey, WORK_SUMMARY_TABLE_SORT_META } from '../../Table/values';
import { WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS, WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_HEADERS } from '../../values';
import { resolveHighlightGroupKey } from '../../helpers';

interface BillableWorkSummaryProps {
	isBillable: true;
	onBillingCodeSelect: (billingCode: Nullable<BillingCodeVM>, workSummaryDetailIds: number[]) => Promise<void>;
}

interface NonBillableWorkSummaryProps {
	isBillable: false;
	onBillingCodeSelect?: never;
}
interface OwnPropsBase {
	workSummaryDetails: WorkSummaryDetailVM[];
	workSummary: WorkSummaryVM[];
	billingCodes: BillingCodeVM[];
	isReadOnly: boolean;
	onRowHighlight: (groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => void;
	highlightedGroup: Nullable<string>;
	highlightedGroupKey: Nullable<string>;
	workSummaryDetailsByGroupKey: Record<string, WorkSummaryDetailVM[]>;
	alternativeWorkSummary: Set<WorkSummaryVM>;
}

type Props = OwnPropsBase & (BillableWorkSummaryProps | NonBillableWorkSummaryProps);

const _maxDefinitionFieldsReducer = (_max: number, _currentWSD: { definitionFields: unknown[]; }) => {
	if (_currentWSD.definitionFields?.length > _max) {
		_max = _currentWSD.definitionFields?.length;
	}

	return _max;
};

const _resolveWorkSummaryBillableWorkTableHeaders = (maxNumberOfDefinitionFields: number, isBillable: boolean) => {
	const headers: string[] = [];
	const headerTemplate = isBillable ? WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS : WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_HEADERS;
	for (const headerTemplateKey of Object.keys(headerTemplate)) {
		if (headerTemplate[headerTemplateKey] === headerTemplate.DEFINITION_FIELD) {
			for (let i = 0; i < maxNumberOfDefinitionFields; i++) {
				headers.push(`${headerTemplate[headerTemplateKey]} ${i + 1}`);
			}
		}

		else {
			headers.push(headerTemplate[headerTemplateKey]);
		}
	}

	return headers;
};

const _findFirstWithGroupName = ({ workSummaryGroup }) => !!workSummaryGroup;

const _getGroupKey = (item: WorkSummaryVM) => resolveHighlightGroupKey(item.billableWorkId, item.type, item.definitionFields);

function _workSummariesAreEqual(
	detail: WorkSummaryDetailVM,
	summary: WorkSummaryVM
): boolean {
	// Check basic properties
	if (detail.billableWorkId !== summary.billableWorkId ||
		detail.group !== summary.group ||
		detail.isBillable !== summary.isBillable ||
		detail.billingCodeCustomerId !== summary.customerId ||
		detail.billingCodeDescription !== summary.description ||
		detail.unit !== summary.unit ||
		detail.workName !== summary.work ||
		detail.workType !== summary.type ||
		detail.workSummaryGroup !== summary.workSummaryGroup
	) {
		return false;
	}

	// Check definition fields
	if (detail.definitionFields.length !== summary.definitionFields.length) {
		return false;
	}

	for (let i = 0; i < detail.definitionFields.length; i++) {
		const detailField = detail.definitionFields[i];
		const summaryField = summary.definitionFields[i];

		if (detailField.fieldType !== summaryField.fieldType ||
			detailField.name !== summaryField.fieldName ||
			detailField.value !== summaryField.value) {
			return false;
		}
	}

	return true;
}

const BillableAndNonBillableWorkTable: React.FC<Props> = (props) => {

	const {
		workSummaryDetails,
		workSummary,
		billingCodes,
		isBillable,
		onBillingCodeSelect,
		onRowHighlight,
		highlightedGroup,
		isReadOnly,
		highlightedGroupKey,
		workSummaryDetailsByGroupKey,
		alternativeWorkSummary,
	} = props;

	const maxNumberOfDefinitionFieldsFromDetails = workSummaryDetails?.reduce(_maxDefinitionFieldsReducer, 0) ?? 0;
	const maxNumberOfDefinitionFieldsFromSummary = workSummary?.reduce(_maxDefinitionFieldsReducer, 0) ?? 0;
	const maxNumberOfDefinitionFields = Math.max(maxNumberOfDefinitionFieldsFromDetails, maxNumberOfDefinitionFieldsFromSummary);
	const tableHeaders = _resolveWorkSummaryBillableWorkTableHeaders(maxNumberOfDefinitionFields, isBillable);

	const renderWorkSummaryBillableWorkTableRow = React.useCallback((data: WorkSummaryVM, cellWidths: CellWidthsMap) => {
		const billableDependentProps = isBillable
			? { isBillable: true, onBillingCodeSelect } as BillableWorkTableRowProps
			: { isBillable: false } as NonBillableWorkTableRowProps;

		const groupKey = resolveHighlightGroupKey(data.billableWorkId, data.type, data.definitionFields);
		const hasPreviouslyBeenSplit = !!workSummaryDetailsByGroupKey[groupKey]?.find(_findFirstWithGroupName);
		const isOfCurrentlyHighlightedGroupKey = highlightedGroupKey === groupKey;
		const isAlternativeOption = alternativeWorkSummary.has(data);

		const ids = filterMap(
			workSummaryDetails,
			(_wsd) => _workSummariesAreEqual(_wsd, data),
			(_wsd) => _wsd.id
		);

		return (
			<WorkSummaryBillableWorkTableRow
				billingCodes={billingCodes}
				cellWidths={cellWidths}
				hasPreviouslyBeenSplit={hasPreviouslyBeenSplit}
				highlightedGroup={highlightedGroup}
				isAlternativeOption={isAlternativeOption}
				isOfCurrentlyHighlightedGroupKey={isOfCurrentlyHighlightedGroupKey}
				isReadOnly={isReadOnly}
				key={data.group ?? groupKey}
				maxNumberOfDefinitionFields={maxNumberOfDefinitionFields}
				onRowHighlight={onRowHighlight}
				rowData={data}
				workSummaryDetailIds={ids}
				{...billableDependentProps}
			/>
		);
	}, [billingCodes,
		highlightedGroup,
		highlightedGroupKey,
		isBillable,
		isReadOnly,
		maxNumberOfDefinitionFields,
		onBillingCodeSelect,
		onRowHighlight,
		workSummaryDetailsByGroupKey,
		alternativeWorkSummary,
		workSummaryDetails,
	]);

	const sort = WORK_SUMMARY_TABLE_SORT_META[WorkSummaryTableSortKey.TYPE].sort;

	return (
		<WorkSummaryTable<WorkSummaryVM>
			data={workSummary}
			getGroupKey={_getGroupKey}
			headers={tableHeaders}
			highlightedGroupKey={highlightedGroupKey}
			renderRow={renderWorkSummaryBillableWorkTableRow}
			sort={sort}
			useGroups={true}
		/>
	);
};

export default React.memo(BillableAndNonBillableWorkTable);
