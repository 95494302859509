import { Dispatch, AnyAction } from 'redux';

import { GetRootState } from 'af-reducers';

import API from 'af-routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

export function findJWSWorkRequestIds(fieldReportIds: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<number[]>(API.V1.JOB_WORK_SUMMARY.FIND_WORK_REQUEST_IDS(fieldReportIds));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

