import { Dispatch, AnyAction } from 'redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import SaleRequestModel from 'ab-requestModels/sale.requestModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { SaleTableRowVM } from 'ab-viewModels/sale/tableRow.viewModel';

import { toRequestModelWithFormattedPhoneNumbers } from 'ab-utils/form.util';

import { GetRootState } from 'af-reducers';
import { SaleFormViewModel } from 'ab-viewModels/sale/saleForm.viewModel';

export function create(form: SaleRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.RESOURCES.SALE.CREATE(), { ...form });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(form: SaleRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			if (!form.id) {
				throw new Error('Sales ID not provided');
			}

			await http.put(API.V1.RESOURCES.SALE.EDIT(form.id.toString()), { ...form });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const sale: SaleRequestModel = await http.get(API.V1.RESOURCES.SALE.FIND_BY_ID(id));
			return toRequestModelWithFormattedPhoneNumbers(sale, ['phone', 'phoneCountryCode'], ['fax', 'faxCountryCode']);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteSaleFromTable(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.SALE.DELETE(id.toString()));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkDeleteSaleFromTable(ids: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.SALE.BULK_DELETE, { saleIds: ids });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<SaleTableRowVM>>(API.V1.RESOURCES.SALE.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForOrganizationTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<SaleTableRowVM>>(API.V1.RESOURCES.SALE.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForForm() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<SaleFormViewModel[]>(API.V1.RESOURCES.SALE.FIND_FOR_FORM);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
