import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

import CustomMenuItem from './MenuItem';

interface OwnProps {
	items: CascadeMenuItem[];
	label: string;
	position: 'left' | 'right';
}

interface MenuItemType {
	action: () => void;
	label: string;
	isDisabled?: boolean;
}

interface Divider {
	divider?: boolean;
}

type CascadeMenuItem = MenuItemType | Divider;

type Props = OwnProps;

export default class CascadeActionsMenuItem extends React.PureComponent<Props> {
	isDivider = (item: CascadeMenuItem): item is Divider => {
		return !!(item as Divider).divider;
	};

	isMenuItem = (item: CascadeMenuItem): item is MenuItemType => {
		const _item = item as MenuItemType;
		return !!_item.action && !!_item.label;
	};

	render() {
		const { items, label, position } = this.props;

		const idBase = `action-menu-${label.toLowerCase().replace(/\s+/g, '-')}`;

		return (
			<Dropdown className="dropdown-cascade" id={`${idBase}__button`}>
				<Dropdown.Toggle className="dropdown-toggle--cascade">
					<span>{label}</span>
					<span className="icon-right"></span>
				</Dropdown.Toggle>
				<Dropdown.Menu
					className={`dropdown-menu--fit-content dropdown-menu--cascade-${position}`}
					id={`${idBase}__dropdown`}
				>
					{items.map((_item: CascadeMenuItem, _index: number) => {
						if (this.isDivider(_item)) {
							return <div className="dropdown-menu__divider" key={`menu#${_index}`} />;
						} else if (this.isMenuItem(_item)) {
							return (
								<CustomMenuItem
									action={_item.action}
									isDisabled={_item.isDisabled}
									key={`actionsmenu#${_index}`}
									label={_item.label}
								/>
							);
						} else {
							return null;
						}
					})}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}
