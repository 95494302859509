import { isValidTextInput } from 'acceligent-shared/utils/text';

import NotifyUsers from 'ab-requestModels/notifyUsers.requestModel';

export function validate(values: NotifyUsers) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.subject)) {
		errors.subject = 'Subject is required';
	}

	if (!isValidTextInput(values.message)) {
		errors.message = 'Message is required';
	}

	return errors;
}
