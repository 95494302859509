import { Dispatch } from 'redux';

import { touch, reset, FormAction } from 'redux-form';
import { errorHandler } from 'af-utils/actions.util';

import { GetRootState } from 'af-reducers';

// Private

// i.e. error for tasks is [{...}, {...}]
// function will return array of string like:
// [ 'tasks[0].prop1', 'tasks[0].prop2', 'tasks[1].prop1', ...]
const _mapErrorArrayToFieldKeys = (error, fieldKey) => {
	const arrayFields = error.map((errObj, index) => Object.keys(errObj).map((key) => `${fieldKey}[${index}].${key}`));
	return arrayFields.reduce((a, b) => a.concat(b));
};

// Public

export interface SetSyncError {
	type: '@@redux-form/UPDATE_SYNC_ERRORS';
	meta: { form: string; };
	payload: { syncErrors: Metadata; };
}

export function SET_SYNC_ERRORS(form: string, syncErrors: Metadata): SetSyncError {
	return {
		type: '@@redux-form/UPDATE_SYNC_ERRORS',
		meta: { form },
		payload: { syncErrors },
	};
}

export function TOUCH_FIELDS(form: string, errors: Metadata) {
	const fields: string[] =
		Object
			.keys(errors)
			.reduce((result: string[], fieldKey: string) => {
				return result.concat(
					Array.isArray(errors[fieldKey]) ?
						_mapErrorArrayToFieldKeys(errors[fieldKey], fieldKey) :
						fieldKey
				);
			}, []);
	return touch(form, ...fields);
}

export function SET_TABLE_CHANGE(form: string, field: string) {
	return {
		type: '@@redux-form/CHANGE',
		meta: {
			form,
			field,
			touch: false,
			persistentSubmitErrors: false,
		},
		payload: '',
	};
}

export function resetForm(formName: string) {
	return async (dispatch: Dispatch<FormAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			dispatch(reset(formName));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
