import * as React from 'react';
import { Field as ReduxField } from 'redux-form';

import Textarea from 'af-fields/Textarea';

import { INPUT_FIELD_MAX_CHARACTERS } from 'af-constants/values';

import fieldHoc from '../Shared/fieldHoc';

interface OwnProps {
	disabled: boolean;
	formName: string;
	name: string;
	tooltipMessage?: React.ComponentProps<typeof Textarea>['tooltipMessage'];
	maxCharacters?: number;
	onFocus?: () => void;
	onBlur: () => void;
	onChange: () => void;
}

type Props = OwnProps;

const TextareaField: React.FC<Props> = (props) => {
	const {
		disabled,
		formName,
		name,
		tooltipMessage,
		maxCharacters,
		onBlur,
		onFocus,
		onChange,
	} = props;
	return (
		<ReduxField
			component={Textarea}
			disabled={disabled}
			dynamicRows={true}
			label={name}
			maxCharacters={maxCharacters ?? INPUT_FIELD_MAX_CHARACTERS}
			name={formName}
			onBlur={onBlur}
			onChange={onChange}
			onFocus={onFocus}
			placeholder={name}
			rows={1}
			tooltipMessage={tooltipMessage}
		/>
	);
};

export default fieldHoc(React.memo(TextareaField));
