import * as React from 'react';

import styles from './styles.module.scss';

type Props = {
	onDeleteClick: () => void;
};

const DeleteCell: React.FC<Props> = ({ onDeleteClick }) => {
	return (
		<span className={styles['delete-cell']}>
			<span className="icon-delete" onClick={onDeleteClick} />
		</span>
	);
};

export default React.memo(DeleteCell);
