import * as React from 'react';

import Checkbox from 'af-components/Controls/Checkbox';

import JobReportListVM from 'ab-viewModels/report/jobReportList.viewModel';

import styles from './styles.module.scss';

interface OwnProps {
	isChecked: boolean;
	item: JobReportListVM;
	onValueChange: (id: number, value: boolean) => void;
}

type Props = OwnProps;

const JobReport: React.FC<Props> = (props) => {
	const { item, isChecked, onValueChange } = props;

	const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		onValueChange(item.workRequestId, event.target.checked);
	}, [onValueChange, item.workRequestId]);

	const containerClassName = React.useMemo(() => {
		return item.areAllReportsInAccounting
			? [styles['job-report-item__container']]
			: [styles['job-report-item__container'], styles['job-report-item__container--not-approved']];
	}, [item.areAllReportsInAccounting]);

	return (
		<div className={containerClassName.join(' ')}>
			<div>
				<Checkbox
					extraClass={styles['job-report-item__checkbox']}
					handleChange={onChange}
					inline={true}
					isChecked={isChecked}
				/>
			</div>
			<div className={styles['job-report-item__code']}>{item.jobCode}</div>
			<div className={styles['job-report-item__info-container']}>
				<div>{item.customer ?? '-'}</div>
				<div>|</div>
				<div>{item.title ?? '-'}</div>
				<div>|</div>
				<div>{item.address ?? '-'}</div>
			</div>
		</div>
	);
};

export default React.memo(JobReport);
