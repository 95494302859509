import * as React from 'react';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import EmptyCell from './EmptyCell';
import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	date: string;
	format: TimeFormat;
	dateSourceFormat: TimeFormat;
	isLeftAligned?: boolean;
}

type Props = OwnProps;

const ComparableDateCell = ({ date, format, isLeftAligned = true, dateSourceFormat }: Props) => {
	if (!date) {
		return <EmptyCell />;
	}

	let className = 'date-cell';
	className = isLeftAligned ? `${className} date-cell--left` : className;

	const startDate = TimeUtils.parseDate(date, dateSourceFormat);
	const diff = TimeUtils.daysBetween(startDate, new Date(), TimeFormat.ISO_DATETIME) ?? 0;

	return (
		<div className={className}>
			<div>
				{TimeUtils.formatDate(date, format, dateSourceFormat)}
			</div>

			<div className="date-cell__additional-info">
				{diff < 0 && <span className={bemElement('date-cell', 'due-date', ['early'])}>Due in {-diff} days</span>}
				{diff === 0 && <span className={bemElement('date-cell', 'due-date', ['today'])}>Due today</span>}
				{diff > 0 && <span className={bemElement('date-cell', 'due-date', ['late'])}>Due {diff} days ago</span>}
			</div>
		</div >
	);
};

export default React.memo(ComparableDateCell);
