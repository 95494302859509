import * as actionTypes from 'af-constants/actionTypes';

import { ReportBlockRM, ReportBlockFieldRM } from 'ab-requestModels/reportBlock/reportBlock.requestModel';

export interface SetNewReportBlock {
	type: typeof actionTypes.SET_NEW_REPORT_BLOCK;
	reportBlock: ReportBlockRM;
	fields: ReportBlockFieldRM[] | null;
}
export function SET_NEW_REPORT_BLOCK(reportBlock: ReportBlockRM, fields: Nullable<ReportBlockFieldRM[]>): SetNewReportBlock {
	return {
		type: actionTypes.SET_NEW_REPORT_BLOCK,
		reportBlock,
		fields,
	};
}

export type ReportBlockAction = SetNewReportBlock;
