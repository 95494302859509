import * as React from 'react';
import { MarkerProps } from '@react-google-maps/api';

import MapField from 'af-components/Maps/AddressMap';

interface Props {
	marker?: MarkerProps;
	latitudePropName: string;
	longitudePropName: string;
	locationPropName: string;
	streetNumberPropName: string;
	routePropName: string;
	localityPropName: string;
	aa1PropName: string;
	aa2PropName: string;
	aa3PropName: string;
	countryPropName: string;
	postalCodePropName: string;
	suitePropName: string;
	postalOfficeBoxPropName: string;
	formName: string;
	disableMap?: boolean;
}

class AddressField extends React.PureComponent<Props> {

	render() {
		const {
			formName,
			marker,
			locationPropName,
			latitudePropName,
			longitudePropName,
			streetNumberPropName,
			routePropName,
			postalOfficeBoxPropName,
			localityPropName,
			aa1PropName,
			aa2PropName,
			aa3PropName,
			countryPropName,
			postalCodePropName,
			suitePropName,
			disableMap,
		} = this.props;

		return (
			<MapField
				aa1PropName={aa1PropName}
				aa2PropName={aa2PropName}
				aa3PropName={aa3PropName}
				countryPropName={countryPropName}
				disableMap={disableMap}
				formName={formName}
				latitudePropName={latitudePropName}
				localityPropName={localityPropName}
				locationPropName={locationPropName}
				longitudePropName={longitudePropName}
				marker={marker}
				postalCodePropName={postalCodePropName}
				postalOfficeBoxPropName={postalOfficeBoxPropName}
				routePropName={routePropName}
				streetNumberPropName={streetNumberPropName}
				suitePropName={suitePropName}
			/>
		);
	}
}

export default AddressField;
