import * as React from 'react';

import TextHighlight from 'af-components/TextHighlight';

import { WageRateVM } from 'ab-viewModels/wageRate/wageRate.viewModel';

interface OwnProps extends WageRateVM {
	searchText: string;
}

type Props = OwnProps;

export default class LaborPlaceholderOptionItem extends React.PureComponent<Props> {

	render() {
		const { type, wageClassification, searchText } = this.props;

		return (
			<div>
				<span className="resource-lookup__resource-name"><TextHighlight searchText={searchText} text={type} /></span>
				<span className="resource-lookup__resource-info"><TextHighlight searchText={searchText} text={`(${wageClassification})`} /></span>
			</div>
		);
	}

}
