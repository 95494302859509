import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

import ValidationState from 'ab-enums/validationState.enum';
import Tooltip from 'af-components/Tooltip';

interface Props {
	eventKey: string;
	customMessage?: string;
	additionalCondition?: boolean;
	validationState: ValidationState;
	event: () => void;
	text: string;
}

const MenuItemWithValidationTooltip: React.FC<Props> = (props: Props) => {
	const {
		event,
		customMessage,
		additionalCondition = true,
		eventKey,
		validationState,
		text,
	} = props;
	const isValid = validationState === ValidationState.COMPLETE && additionalCondition;
	const message = customMessage ?? 'Cannot perform action on invalid Work Order.';

	return isValid
		? <Dropdown.Item disabled={!isValid} eventKey={eventKey} onClick={event}>{text}</Dropdown.Item>
		: (
			<Tooltip
				customCursor="not-allowed"
				message={message}
				placement="top"
			>
				<Dropdown.Item disabled={!isValid} eventKey={eventKey} onClick={undefined}>
					{text}
				</Dropdown.Item>
			</Tooltip>
		);
};

export default React.memo(MenuItemWithValidationTooltip);
