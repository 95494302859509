import * as React from 'react';
import { Field } from 'redux-form';

import Textarea from 'af-fields/Textarea';

import { formSectionClass, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../helpers';
import WorkRequestUpsertFM from '../../formModel';

const JOB_HAZARD_SUMMARY_MAX_LENGTH = 600;

const JobHazardSummarySection: React.FC = () => {
	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Job Hazard Summary
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					<Field
						component={Textarea}
						maxCharacters={JOB_HAZARD_SUMMARY_MAX_LENGTH}
						name={WorkRequestUpsertFM.getAttributeName('hazardSummary')}
						placeholder="Enter text"
						rows={4}
						showMaxCharactersLabel={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(JobHazardSummarySection);
