import { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import { FieldReportSettingsRM } from 'ab-requestModels/company/fieldReportSettings.requestModel';

export const fromVMtoRM = (company: CompanyViewModel): FieldReportSettingsRM => {
	return {
		fieldReportAccessDayDuration: company.fieldReportAccessDayDuration,
		isFRManageAllowedToApproveTimeSheet: company.isFRManageAllowedToApproveTimeSheet,
		isFRManageAllowedToEditTimeSheet: company.isFRManageAllowedToEditTimeSheet,
		isFRManageLevelAllowedInReviewProcess: company.isFRManageLevelAllowedInReviewProcess,
		allowAllNonBillableShortcut: company.allowAllNonBillableShortcut,
	};
};
