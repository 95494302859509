import AccountPermission from 'acceligent-shared/enums/accountPermission';
import * as React from 'react';

import { FLAG_IMPLICATIONS, LABEL } from './constants';

const FLAG_TOOLTIP = {
	assignableToWorkOrder: 'User will appear in Resources Labor table, and will be selectable as an Field Worker option for the Work Order.',
	assignableAsSuperintendent: 'User will be selectable as Superintendent option for the Work Order.',
	assignableAsManagement: 'User will be selectable as Management option for the Field Report approval process.',
	assignableAsAccounting: 'User will be selectable as Accounting option for the final Field Report approval process.',
	assignableAsQAQC: 'User will be selectable as QA/QC option for the deliverable Job.',
	assignableAsProjectManager: 'User will be selectable as Project Manager option for the Work Order.',
	assignableAsTechnician: 'User will be selectable as Technician option for the Tool Repair.',
};

interface OwnProps {
	flag: keyof typeof FLAG_TOOLTIP;
}

export type Props = OwnProps;

const RoleFlagTooltip: React.FC<Props> = (props) => {
	const { flag } = props;
	const permissions = Object.keys(FLAG_IMPLICATIONS[flag] ?? {});

	const renderPermission = React.useCallback((permission: AccountPermission, index: number) => {
		return <li key={index}>{LABEL[permission]}</li>;
	}, []);

	return (
		<div>
			<span>{FLAG_TOOLTIP[flag]}</span>
			{!!permissions?.length &&
				<div className="member-permissions__description">
					<span className="member-permissions__description__title">
						Required Permissions:
					</span>
					<ul className="member-permissions__description__list">
						{permissions.map(renderPermission)}
					</ul>
				</div>
			}
		</div>
	);
};

export default RoleFlagTooltip;
