import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

const Loading = () => {
	return (
		<div className="form-box">
			<Row className="row--padded-top">
				<Col className="loading-box-field" sm={6} />
				<Col className="loading-box-field" sm={7} />
				<Col className="loading-box-field" sm={11} />
			</Row>
			<Row className="row--padded-top">
				<Col sm={6} />
				<Col className="loading-box-field" sm={7} />
				<Col className="loading-box-field" sm={11} />
			</Row>
		</div>
	);
};

export default React.memo(Loading);
