import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import CLIENT from 'af-constants/routes/client';

type Props = CustomRouteComponentProps;

export default class PasswordResetSuccess extends React.PureComponent<Props> {

	render(): JSX.Element {
		const { location: { state: { orgAlias } } } = this.props;

		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone form-box--padded">
					<div className="form-box__icon-container">
						<span className="form-box__icon form-box__icon--success icon-check" />
					</div>
					<h2>Your password has been successfuly reset</h2>
					<h4 className="light">In order to start using the platform, please <Link to={CLIENT.AUTH.LOGIN(orgAlias)}>log in</Link>.</h4>
					<div className="form-box__footer">
						<Link
							className="btn btn-primary"
							to={CLIENT.AUTH.LOGIN(orgAlias)}
						>
							Log in
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
