import * as React from 'react';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';

import { ColorHex } from 'acceligent-shared/enums/color';

import { getId } from 'acceligent-shared/utils/array';

import { RootState } from 'af-reducers';

import Checkbox from 'af-fields/Checkbox';

import * as DropdownSelector from 'af-components/Controls/Dropdown';
import BadgeCell from 'af-components/Table6/Cells/BadgeCell';
import Label from 'af-components/Label';

import * as WorkRequestActions from 'af-actions/workRequests';

import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import SubjobArray, { OwnProps as SubjobArrayProps } from './Subjobs/SubjobsArray';

import { formSectionClass, formSectionClassColumn1, formSectionClassColumn2, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../helpers';
import { SubjobFM } from '../../formModel';

interface OwnProps {
	isProject: boolean;
	projectId: Nullable<number>;
	redirectOnSubmit: boolean;
	orgAlias: string;
	subjobs: SubjobFM[];
	availableSubjobs: SubjobFM[];
	formName: string;
	onTurnToProjectChange: (value: boolean) => void;
	handleSubjobSelect: (option: Nullable<SubjobFM>) => void;
	setSubjobSearchText: (text: string) => void;
	setAvailableSubjobs: (subjobs: SubjobFM[]) => void;
	goToJobForm: () => void;
	workRequestId?: number;
	selectedSubjob: Nullable<SubjobFM>;
	openAddSubjobConfirmationModal: () => void;
	turnToProjectDisabled: boolean;
	disableTurnToProject: () => void;
	subjobSearchText: string;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const renderProjectLabel = () => {
	return (
		<div className="work-order-upsert__warning">
			Convert this Job to Project.
			<div className="work-order-upsert__project-label">
				This will enable associating other Jobs with this Job and will turn them into Sub-Jobs. This action is irreversible.
			</div>
		</div>
	);
};

const createNewJobOption = {
	id: 0,
} as SubjobFM;

const highlightText = (text: string, highlight: string) => {
	const _lowerText = text?.toLowerCase();
	const _lowerHighLight = highlight?.toLowerCase();
	const index = _lowerText.indexOf(_lowerHighLight);

	if (!highlight || index === -1) {
		return <span>{text}</span>;
	}
	const _highlightText = text.slice(index, index + highlight.length);

	return (
		<>
			{text.split(_highlightText).map((_subtext, _index, _list) => (
				<span key={_index}>
					{_subtext}{_index + 1 !== _list.length && <mark>{_highlightText}</mark>}
				</span>)
			)}
		</>
	);
};

const ProjectSection: React.FC<Props> = (props) => {
	const {
		projectCode,
		isProject,
		redirectOnSubmit,
		projectId,
		orgAlias,
		subjobs,
		availableSubjobs,
		onTurnToProjectChange,
		handleSubjobSelect,
		setSubjobSearchText,
		goToJobForm,
		workRequestId,
		findAllAvailableSubjobs,
		setAvailableSubjobs,
		hasPermissionsToManageProjects,
		selectedSubjob,
		openAddSubjobConfirmationModal,
		subjobSearchText,
		turnToProjectDisabled,
		disableTurnToProject,
	} = props;

	const [filteredSubjobs, setFilteredSubjobs] = React.useState<SubjobFM[]>(availableSubjobs);

	const filterSubjobOption = React.useCallback((option: SubjobFM, rawInput: string) => {
		const { id, jobCode, title, customerCompany, customerFormatted, travelLocationShort } = option;

		if (rawInput !== subjobSearchText) {
			setSubjobSearchText(rawInput);
		}

		if (!id) {
			return rawInput !== projectCode;
		}
		return `${jobCode} ${title} ${customerCompany} ${customerFormatted} ${travelLocationShort}`.toLowerCase().includes(rawInput.toLowerCase());
	}, [projectCode, setSubjobSearchText, subjobSearchText]);

	React.useEffect(() => {
		if (subjobSearchText) {
			setFilteredSubjobs(availableSubjobs.filter((option) => filterSubjobOption(option, subjobSearchText)));
		} else {
			setFilteredSubjobs(availableSubjobs);
		}
	}, [subjobSearchText, availableSubjobs, filterSubjobOption]);

	const renderJobMenuItem = React.useCallback((option: SubjobFM, searchText: string) => {
		const { id, jobCode, isInternal, title: subjobTitle, customerCompany, customerFormatted, travelLocationShort } = option;

		if (!id) {
			return (
				<div key={option.id}>
					<span className="work-order-upsert__job-picker-create-label">
						<span className="icon-plus" />
						Create New Job:
					</span>
					<span>{searchText}</span>
				</div>
			);
		}

		return (
			<div className="work-order-upsert__job-picker-option" key={option.id}>
				<div>
					<strong>{highlightText(jobCode, searchText)}</strong>
					<small><br /></small>
					{!!customerCompany && <small>{highlightText(customerCompany, searchText)}</small>}
					{!!subjobTitle && <small>{(!!customerCompany) && ' |'} {highlightText(subjobTitle, searchText)}</small>}
					{!!customerFormatted &&
						<small>
							{(!!subjobTitle || !!customerCompany) && ' |'} {highlightText(customerFormatted, searchText)}
						</small>
					}
					{!!travelLocationShort &&
						<small>
							{(!!customerFormatted || !!subjobTitle || !!customerCompany) && ' |'} {highlightText(travelLocationShort, searchText)}
						</small>
					}
				</div>
				{isInternal &&
					<BadgeCell
						badgeColor={ColorHex.BLACK}
						colorNegative={false}
						text="INTERNAL"
					/>
				}
			</div>
		);
	}, []);

	const fetchAvailableSubjobs = React.useCallback(async () => {
		const jobId = workRequestId ?? undefined;
		const _subjobs = await findAllAvailableSubjobs(jobId);
		const subjobIds: number[] = subjobs?.map((_s) => _s.id) ?? [];
		const jobIds = jobId ? [...subjobIds, jobId] : subjobIds;
		setAvailableSubjobs(_subjobs.filter((_sj) => !jobIds.includes(_sj.id)));
	}, [findAllAvailableSubjobs, setAvailableSubjobs, subjobs, workRequestId]);

	const renderSelectedItem = React.useCallback(() => {
		return <>{selectedSubjob?.jobCode ? `${selectedSubjob.jobCode}` : ''}</>;
	}, [selectedSubjob]);

	React.useEffect(() => {
		fetchAvailableSubjobs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workRequestId]);

	React.useEffect(() => {
		if (isProject && !turnToProjectDisabled) {
			disableTurnToProject();
		}
	}, [disableTurnToProject, isProject, turnToProjectDisabled]);

	const handleSelect = React.useCallback((option: Nullable<SubjobFM>) => {
		if (option?.id === 0) {
			return goToJobForm();
		}
		handleSubjobSelect(option);
	}, [goToJobForm, handleSubjobSelect]);

	const canManageProjects = React.useMemo(() => { return hasPermissionsToManageProjects && process.env.FTD_PROJECT !== 'true'; }, [hasPermissionsToManageProjects]);

	const subjobOptions = React.useMemo(() => {
		if (!filteredSubjobs.length) {
			return [createNewJobOption];
		}
		const subjobIds = subjobs?.map(getId) ?? [];
		return availableSubjobs.filter((option) => !subjobIds.includes(option.id)) ?? [];
	}, [availableSubjobs, filteredSubjobs, subjobs]);

	if (!canManageProjects || (!isProject && projectId) || redirectOnSubmit) {
		return null;
	}

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				PROJECT
			</div>
			{
				!isProject &&
				!projectId &&
				<div className={formSectionClassRow}>
					<div className={formSectionClassColumn8}>
						<Field
							component={Checkbox}
							inline={true}
							isDisabled={isProject}
							isStandalone={true}
							label={renderProjectLabel()}
							name="isProject"
							onValueChange={onTurnToProjectChange}
							propName="isProject"
						/>
					</div>
				</div>
			}
			{
				isProject &&
				<>
					<div className="work-order-upsert__project-banner">
						<Label text="Project" />
						<div>
							This job has been converted to a Project.
							It still retains its regular functionalities but now you can associate other Jobs(Subjobs) with it.
						</div>
					</div>
					<FieldArray<SubjobArrayProps>
						component={SubjobArray}
						name="subjobs"
						orgAlias={orgAlias}
						rerenderOnEveryChange={true}
						subjobs={subjobs}
					/>
					<div className={formSectionClassRow}>
						<div className={formSectionClassColumn2}>
							<DropdownSelector.default<SubjobFM>
								defaultValue={null}
								filterable={true}
								filterBy={filterSubjobOption}
								hasBlankOption={true}
								id={'subjobsDropdown'}
								label="Associate Sub-Job:"
								labelKey="jobCode"
								onValueChange={handleSelect}
								options={subjobOptions}
								renderMenuItem={renderJobMenuItem}
								renderSelected={renderSelectedItem}
								valueKey="id"
								withBorder={true}
								withCaret={true}
							/>
						</div>
						{
							!!selectedSubjob &&
							<div className={formSectionClassColumn1}>
								<div className="work-order-upsert__associate-button" onClick={openAddSubjobConfirmationModal}>
									<span className="icon-check" />
									Associate
								</div>
							</div>
						}
					</div>
				</>
			}
		</div>
	);
};

function mapStateToProps(state: RootState, props: OwnProps) {

	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}
	const jobCode = formValueSelector(props.formName)(state, 'jobCode');

	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const hasPermissionsToManageProjects = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_PROJECTS,
		permissions,
		isCompanyAdmin,
		role
	);
	return {
		hasPermissionsToManageProjects,
		projectCode: jobCode,
	};
}

function mapDispatchToProps() {
	return {
		findAllAvailableSubjobs: WorkRequestActions.findAllAvailableSubjobs,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(ProjectSection);
