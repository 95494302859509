import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import SubmitButton from 'af-components/SubmitButton';
import BackButton from 'af-components/BackButton';

import Input from 'af-fields/Input';

import * as ReduxUtils from 'ab-utils/reduxForms.util';

interface OwnProps {
	disabled: boolean;
	submitting: boolean;
	isDefaultWageRate?: boolean;
}

type Props = OwnProps;

export default class WageRateForm extends React.PureComponent<Props> {
	render() {
		const {
			submitting,
			disabled,
			isDefaultWageRate,
		} = this.props;

		return (
			<div className="form-box">
				<Row className="row--padded-top">
					<Col sm={12}>
						<Field
							component={Input}
							disabled={isDefaultWageRate}
							label="Labor Type *"
							name="type"
							placeholder="Enter Labor Type"
							type="text"
						/>
					</Col>
					<Col sm={12}>
						<Field
							component={Input}
							disabled={isDefaultWageRate}
							label="Wage Classification *"
							name="wageClassification"
							placeholder="Enter Wage Classification"
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Field
							component={Input}
							format={ReduxUtils.formatDecimalNumber}
							isDollarValue={true}
							label="Hourly Rate *"
							name="hourlyRate"
							normalize={ReduxUtils.normalizeDecimalNumber}
							placeholder="Enter Hourly Rate"
							type="text"
						/>
					</Col>
					<Col sm={12} />
				</Row>
				<Row className="row--submit">
					<BackButton />
					<SubmitButton
						disabled={disabled}
						reduxFormSubmitting={submitting}
					/>
				</Row>
			</div>
		);
	}
}
