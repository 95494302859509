import { AuthenticatedResponseLocals } from 'express';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import QuantityUnitType from 'acceligent-shared/enums/quantityUnit';

import { BlockValueType, parseRawFieldValue } from 'acceligent-shared/utils/fieldReport';
import { filterMap } from 'acceligent-shared/utils/array';

import * as FieldReportSyncVMs from 'acceligent-shared/dtos/socket/view/newFieldReport/sync';

import { FieldReportBlockFieldBaseServiceModel } from 'ab-serviceModels/fieldReport.serviceModel';

import { FIELD_REPORT_PUBLIC_LINK_CODE_LENGTH } from 'ab-constants/value';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { getStringPermission, isAllowed } from 'ab-utils/auth.util';
import * as CodeUtils from 'ab-utils/codes.util';
import { isValidNumber } from 'ab-utils/validation.util';

export * from 'acceligent-shared/utils/fieldReport';

// Private

const _isCalculatedField = (serviceModel: FieldReportBlockFieldBaseServiceModel) => serviceModel.fieldType === ReportBlockFieldEnum.Type.CALCULATED;

const _constructSyncFieldValue = (serviceModel: FieldReportBlockFieldBaseServiceModel): FieldReportSyncVMs.SyncFieldValue => {
	return {
		fieldId: serviceModel.fieldReportBlockFieldId.toString(),
		blockId: serviceModel.fieldReportBlockId.toString(),
		value: parseRawFieldValue(serviceModel),
		index: null,
	};
};

type SegmentCountMap = { [instanceIndex: number]: { [segmentIndex: number]: true; }; };
type SegmentCount = { [instanceIndex: number]: number; };
type FieldReportBlockBaseVM = {
	segmentIndex: Nullable<number>;
	instanceIndex: Nullable<number>;
};
type SegmentAccumulator = { blockCountMap: SegmentCountMap; segmentCount: SegmentCount; };
const _segmentCountReducer = (_acc: SegmentAccumulator, _block: FieldReportBlockBaseVM) => {
	const { instanceIndex, segmentIndex } = _block;
	if (instanceIndex !== null && !_acc.blockCountMap[instanceIndex]) {
		_acc.blockCountMap[instanceIndex] = {};
		_acc.segmentCount[instanceIndex] = 0;
	}
	if (instanceIndex !== null && segmentIndex !== null && !_acc.blockCountMap[instanceIndex][segmentIndex]) {
		_acc.blockCountMap[instanceIndex][segmentIndex] = true;
		_acc.segmentCount[instanceIndex] += 1;
	}
	return _acc;
};

// Public

export const generatePublicLink = () => CodeUtils.generateId(FIELD_REPORT_PUBLIC_LINK_CODE_LENGTH);

export const getSegmentCount = (fieldReportBlocks: FieldReportBlockBaseVM[]) => {
	const { segmentCount } = fieldReportBlocks.reduce<SegmentAccumulator>(_segmentCountReducer, { blockCountMap: {}, segmentCount: {} });
	return segmentCount;
};

export const resolveBlockDisplayIndex = (
	instanceIndex: number,
	segmentIndex: number,
	blockIndex: number,
	instanceCount: number,
	segmentCount: number
): Nullable<string> => {
	if (blockIndex !== 0) {
		return null;
	}

	const isPrimarySegment = segmentIndex === 0;

	if (isPrimarySegment && instanceCount > 1) {
		return (instanceIndex + 1).toString();
	} else if (!isPrimarySegment && segmentCount > 2) {
		return `${instanceIndex + 1}.${segmentIndex}`;
	}
	return null;
};

export const parseFieldValue = (value: string, index: Nullable<number>): BlockValueType => {
	return isValidNumber(index) ? JSON.parse(value ?? '[]').splice(index, 1) : value;
};

export const mapCustomElementServiceModelsToSyncFieldEvents = (elements: FieldReportBlockFieldBaseServiceModel[]): FieldReportSyncVMs.SyncFieldValue[] => {
	return filterMap(elements, _isCalculatedField, _constructSyncFieldValue);
};

interface FieldReportBlockFieldValue {
	id: number;
	value: Nullable<string>;
	index?: number;
	isRepeating: boolean;
	valueType: ReportBlockFieldEnum.ValueType;
	fieldType: ReportBlockFieldEnum.Type;
	unit: Nullable<QuantityUnitType>;
}
export const mapBlockFieldValuesToSyncFieldEvents = (
	blockId: number, data: Nullable<FieldReportBlockFieldValue[]>,
	areCalculatedFields?: true
): FieldReportSyncVMs.SyncFieldValue[] => {
	if (!data) {
		return [];
	}

	return data.map((_field) => {

		const index = _field?.index ?? null;
		const value = (index !== null && index !== undefined && areCalculatedFields)
			? parseRawFieldValue(_field)[index]
			: parseRawFieldValue(_field);

		return ({
			fieldId: _field.id.toString(),
			value,
			blockId: blockId.toString(),
			index,
		});
	});
};

export const hasPermissionToEditFieldReport = (locals: AuthenticatedResponseLocals) => {
	const { companyAccount: { isCompanyAdmin, permissions }, user } = locals;

	const isManagerOrAdmin = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE, permissions.map(getStringPermission), isCompanyAdmin, user.role);
	const hasFieldReportFillPermission = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FILL, permissions.map(getStringPermission), isCompanyAdmin, user.role);
	return isManagerOrAdmin || hasFieldReportFillPermission;
};
