import { Dispatch, AnyAction } from 'redux';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';
import NotificationHistoryTableVM from 'acceligent-shared/dtos/web/view/notification/historyTable';
import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import ContactRM from 'acceligent-shared/dtos/web/request/contact/upsert';
import MergeContactRM from 'acceligent-shared/dtos/web/request/contact/merge';
import ImportContactRM from 'acceligent-shared/dtos/web/request/contact/import';

import { BulkCreateResponseViewModel } from 'ab-viewModels/csv.viewModel';
import { CompanyContactTableVM, CompanyUnsavedContactTableVM } from 'ab-viewModels/contact/table.viewModel';

import * as ContactCreateAPI from 'ab-api/web/contact/create';

import API from 'af-constants/routes/api';
import CLIENT from 'af-constants/routes/client';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { GetRootState } from 'af-reducers';

export function bulkSoftDeleteContact(contactIds: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.CONTACT.BULK_SOFT_DELETE, { contactIds });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function importBatch(data: ImportContactRM[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<BulkCreateResponseViewModel<ImportContactRM>>(API.V1.CONTACT.IMPORT, data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createContact(form: ContactCreateAPI.W_Contact_Create_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<ContactCreateAPI.W_Contact_Create_VM>(ContactCreateAPI.URL(), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ContactVM>(API.V1.CONTACT.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findNotificationHistory(id: number, tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<NotificationHistoryTableVM>>(API.V1.CONTACT.FIND_HISTORY_BY_ID(id, data));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllSavedForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<CompanyContactTableVM>>(API.V1.CONTACT.TABLE_SAVED(data));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllUnsavedForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<CompanyUnsavedContactTableVM>>(API.V1.CONTACT.TABLE_UNSAVED(data));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function softDeleteContact(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.CONTACT.SOFT_DELETE(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllContacts() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ContactVM[]>(API.V1.CONTACT.FIND_ALL);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editContact(form: ContactRM, orgAlias: string, companyName: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			if (!form.id) {
				throw new Error('Contact ID not provided');
			}
			await http.put(API.V1.CONTACT.EDIT(form.id), form);
			redirectTo(CLIENT.COMPANY.CONTACTS.LIST(orgAlias, companyName));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function addUnsaved(savedContactId: number, form: MergeContactRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.CONTACT.ADD_UNSAVED(savedContactId), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
