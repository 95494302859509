import * as React from 'react';

import SegmentLabel from 'af-components/SegmentLabel';

interface OwnProps {
	label: string;
}

type Props = React.PropsWithChildren<OwnProps>;

const Section: React.FC<Props> = ({ label, children }) => {
	return (
		<div className="form-box">
			<div className="user-upsert__section-label">
				<SegmentLabel label={label} />
			</div>
			{children}
		</div>
	);
};

export default React.memo<React.FC<Props>>(Section);
