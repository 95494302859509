import * as React from 'react';
import { Button } from 'react-bootstrap';

interface Props {
	disabled?: boolean;
	variant?: string;
	actionActive?: boolean;
	action?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	activeClassName?: string;
	inactiveClassName?: string;
	disabledClassName?: string;
	element?: JSX.Element;
	activeElement?: JSX.Element;
	inactiveElement?: JSX.Element;
	disabledElement?: JSX.Element;
}

export default class ActionButton extends React.Component<Props, null> {

	render() {
		const {
			action,
			actionActive,
			variant,
			element,
			disabled,
			activeClassName,
			inactiveClassName,
			disabledClassName,
			activeElement,
			inactiveElement,
			disabledElement,
		} = this.props;
		let content = element;
		if (!element) {
			content = actionActive ? inactiveElement ?? activeElement : activeElement;
		}

		if (disabled) {
			return (
				<Button
					className={disabledClassName}
					disabled={true}
					onClick={action}
					variant={variant}
				>
					{disabledElement}
				</Button>
			);
		}
		return (
			<Button
				className={actionActive ? inactiveClassName : activeClassName}
				disabled={actionActive}
				onClick={action}
				variant={variant}
			>
				{content}
			</Button>
		);
	}
}
