import SavedContactRM from 'acceligent-shared/dtos/web/request/contact/upsert';

import { isValidNumber } from 'ab-utils/validation.util';

export const warn = (form: SavedContactRM) => {
	const errors: ValidationErrors = {};

	for (let _index = 0; _index < form.addresses?.length ?? 0; _index++) {
		const _address = form.addresses[_index];
		if (!isValidNumber(_address?.address?.latitude) || !isValidNumber(_address?.address?.longitude)) {
			if (!errors.addresses) {
				errors.addresses = [];
			}
			errors.addresses[_index] = {
				address: {
					street: 'Location is not recognized by Google Maps.',
				},
			};
		}
	}

	return errors;
};
