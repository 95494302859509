import * as React from 'react';
import { FormControl } from 'react-bootstrap';
import { Field } from 'redux-form';

import { ConstrainedUnitValueLimits, ConstrainedUnitEnum } from 'acceligent-shared/enums/quantityUnit';

import { roundUnitValue } from 'acceligent-shared/utils/unit';

import Input, { Sizes } from 'af-fields/Input';

import { OwnProps as TooltipProps } from 'af-components/Tooltip';

import Label from 'af-components/LockedValue/Label';

interface OwnProps {
	name: string;
	unit: ConstrainedUnitEnum;
	id?: string;
	label?: string | JSX.Element;
	disabled?: boolean;
	onValueChange: (value: string) => void;
	inputSize?: Sizes;
	tooltipMessage?: TooltipProps['message'];
	className?: string;
	disableErrors?: boolean;
	hideErrorText?: boolean;
	forceShowError?: boolean;
	onKeyDown?: (event: Metadata) => void;
	onFocus?: (event: Metadata) => void;
	onBlur?: (event: Metadata) => void;
}

type Props = OwnProps;

const ConstrainedInput: React.FC<Props> = (props) => {

	const {
		label,
		tooltipMessage,
		name,
		disabled,
		unit,
		onFocus,
		onBlur,
		onKeyDown,
		inputSize,
		className,
	} = props;

	const changeValue = (elementUnit: string, value: string) => {
		const { onValueChange } = props;
		onValueChange?.(`${roundUnitValue(+value, elementUnit as ConstrainedUnitEnum)}`);
	};

	const normalizeFieldValue = (min: number, max: number | undefined, elementUnit: string) => {
		return (value: string) => {
			if (value) {
				const normalizedValue = roundUnitValue(+value, elementUnit as ConstrainedUnitEnum);
				if (normalizedValue < min) {
					return min;
				} else if (max !== undefined && normalizedValue > max) {
					return max;
				} else {
					return normalizedValue;
				}
			}
		};
	};

	const renderInput = () => {
		const limits = ConstrainedUnitValueLimits[unit];

		return (
			<Field
				addonAfter={unit}
				className={className}
				component={Input}
				disabled={disabled}
				disableErrors={true}
				hideErrorText={true}
				inputSize={inputSize}
				max={limits.max}
				min={limits.min}
				name={`${name}.${unit}`}
				normalize={normalizeFieldValue(limits.min, limits.max, unit)}
				onBlur={onBlur}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				onValueChange={changeValue.bind(this, unit)}
				placeholder="0"
				type="number"
			/>
		);
	};

	return (
		<div className="compound-field">
			<Label
				label={label}
				tooltipMessage={tooltipMessage}
				tooltipPlacement="top"
				withMargin={true}
			/>
			<div className="compound-field__inputs">
				{renderInput()}
			</div>
			<FormControl.Feedback />
		</div>
	);
};

export default React.memo(ConstrainedInput);
