import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

const Loading = () => {
	return (
		<div className="form-segment">
			<Row>
				<Col sm={6}>
					<div className="loading-col-6 loading-box-area dark" />
				</Col>
				<Col sm={10}>
					<div className="loading-col-10 loading-box-area dark" />
				</Col>
				<Col sm={8}>
					<div className="loading-col-8 loading-box-area dark" />
				</Col>
			</Row>
		</div>
	);
};

export default Loading;
