import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'af-reducers';

import * as UserGroupActions from 'af-actions/userGroup';

import { MemberViewModel } from 'ab-viewModels/member.viewModel';

import UserGroupRequestModel from 'ab-requestModels/userGroup.requestModel';

import CLIENT from 'af-constants/routes/client';

import PagePermissions from 'ab-enums/pagePermissions.enum';
import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import LockedPillList from 'af-components/Pills/LockedList';
import _Table, { OwnProps as TableOwnProps, TabProps, Column, ButtonData } from 'af-components/Table6';
import Breadcrumbs from 'af-components/Breadcrumbs';

import { isAllowed } from 'ab-utils/auth.util';

const Table = _Table as unknown as React.ComponentClass<TableOwnProps<UserGroupRequestModel | MemberViewModel>>;

type OwnProps = CustomRouteComponentProps;
type Props = OwnProps & ConnectedProps<typeof connector>;

const NAME_COLUMN_WIDTH = 200;

class UserGroupTable extends React.PureComponent<Props> {

	static readonly BREADCRUMBS = [{ label: 'User Groups' }];

	static userGroupsColumns: Column<UserGroupRequestModel>[] = [
		{
			Header: 'Name',
			accessor: 'name',
			Cell: ({ original }) => original.name,
			className: 'text-strong',
			width: NAME_COLUMN_WIDTH,
		},
		{
			accessor: 'members',
			Header: 'Members',
			Cell: ({ original }) => {
				const accounts = original?.accounts ?? [];
				return <LockedPillList emptyLabel="No members" items={accounts} labelKey="fullName" />;
			},
			sortable: false,
		},
	];

	static accountsColumns: Column<MemberViewModel>[] = [
		{
			Header: 'Name',
			accessor: 'fullName',
			className: 'text-strong',
			width: NAME_COLUMN_WIDTH,
			Cell: ({ original }) => original.fullName,
		},
		{
			accessor: 'userGroups',
			Header: 'User Groups',
			Cell: ({ original }) => {
				const groups = original?.assignedUserGroups?.map((userGroupName) => ({ userGroupName })) ?? [];
				return <LockedPillList emptyLabel="No groups" items={groups} labelKey="userGroupName" />;
			},
			sortable: false,
		},
	];

	static hideClearUserGroup = (original: UserGroupRequestModel) => !original?.accounts?.length;

	static clearUserGroupModalTitle = (original: UserGroupRequestModel) => `Are you sure you want to clear this user group (${original.name})?`;
	static clearUserGroupModalBody = () => (
		<>
			Clearing the group, all users will be removed from it and the group will remain empty. This action cannot be undone.
		</>
	);
	static clearUserGroupModalText = () => 'Clear User Group';

	static deleteUserGroupModalTitle = (original: UserGroupRequestModel) => `Are you sure you want to delete this user group (${original.name})?`;

	static deleteUserGroupModalBody = (original: UserGroupRequestModel) => {
		const {
			hasPastWorkOrderNotification,
			hasScheduleBoardEmailNotification,
			hasYesterdaysScheduleBoardEmailNotification,
			hasFailedNotificationsNotification,
		} = original;

		const message = 'This action cannot be undone.';
		if (
			hasPastWorkOrderNotification
			|| hasScheduleBoardEmailNotification
			|| hasYesterdaysScheduleBoardEmailNotification
			|| hasFailedNotificationsNotification
		) {
			const notifications: string[] = [];
			if (hasPastWorkOrderNotification) {
				notifications.push('Changes on past Work Orders');
			}
			if (hasScheduleBoardEmailNotification) {
				notifications.push('Schedule board');
			}
			if (hasYesterdaysScheduleBoardEmailNotification) {
				notifications.push('Yesterday\'s schedule board');
			}
			if (hasFailedNotificationsNotification) {
				notifications.push('Failed notifications');
			}

			return (
				<span>
					Following notifications are assigned to this user group: (<strong>{notifications.join(', ')}</strong>).
					They will be disabled and cleared. {message}
				</span>
			);
		}
		return message;
	};

	static deleteUserGroupModalText = () => 'Delete User Group';

	clearUserGroup = async (original: UserGroupRequestModel) => {
		await this.removeUserGroup(original, true);
	};

	deleteUserGroup = async (original: UserGroupRequestModel) => {
		await this.removeUserGroup(original, false);
	};

	editUserGroup = async (original: UserGroupRequestModel) => {
		const { history, companyName, location: { state: { orgAlias } } } = this.props;
		history.push(CLIENT.COMPANY.SETTINGS.USER_GROUPS.EDIT(original.id.toString(), orgAlias, companyName));
	};

	notifyUserGroup = async (original: UserGroupRequestModel) => {
		const { history, companyName, location: { state: { orgAlias } }, location: { pathname } } = this.props;
		history.push(CLIENT.COMPANY.COMMUNICATION.NOTIFY_EMPLOYEES(orgAlias, companyName, original.id, pathname));
	};

	removeUserGroup = async (userGroup: UserGroupRequestModel, clearOnly: boolean = false) => {
		const { removeUserGroup, findAllForCompanyTable } = this.props;
		if (userGroup) {
			await removeUserGroup(userGroup.id, clearOnly);
			await findAllForCompanyTable();
		}
	};

	editUser = async (original: MemberViewModel) => {
		const { history, companyName, location: { state: { orgAlias } } } = this.props;
		history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.EDIT(orgAlias, companyName, original.accountId.toString()));
	};

	onGroupRowClick = ({ original }: { original: UserGroupRequestModel; }) => {
		const { companyName, history, location: { state: { orgAlias } } } = this.props;
		if (original.id) {
			history.push(CLIENT.COMPANY.SETTINGS.USER_GROUPS.EDIT(original.id.toString(), orgAlias, companyName));
		}
	};

	onUserRowClick = ({ original }: { original: MemberViewModel; }) => {
		const { companyName, history, location: { state: { orgAlias } } } = this.props;
		if (original.accountId) {
			history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.EDIT(orgAlias, companyName, original.accountId.toString()));
		}
	};

	goToNotificationScreen = async () => {
		const { history, location: { state: { orgAlias }, pathname }, companyName } = this.props;
		history.push(CLIENT.COMPANY.COMMUNICATION.NOTIFY_EMPLOYEES(orgAlias, companyName, undefined, pathname));
	};

	tabs = (): (TabProps<UserGroupRequestModel> | TabProps<MemberViewModel>)[] => {
		const {
			findAllForCompanyTable,
			findAllAccountsWithUserGroupsForCompanyTable,
			location: { state: { orgAlias } },
			companyName,
			hasCommunicationPermission,
			hasUserGroupManagePermission,
			hasUserManagePermission,
			history,
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.LINK,
				hasPermission: hasCommunicationPermission,
				label: 'Notify Users',
				icon: 'notifications_active',
				onClick: this.goToNotificationScreen,
			},
			{
				type: TableButtonType.PRIMARY,
				hasPermission: hasUserGroupManagePermission,
				icon: 'plus',
				label: 'New User Group',
				onClick: async () => history.push(CLIENT.COMPANY.SETTINGS.USER_GROUPS.CREATE(orgAlias, companyName)),
			},
		];

		const userGroupRowActions: TabProps<UserGroupRequestModel>['rowActions'] = [];
		if (hasUserGroupManagePermission) {
			userGroupRowActions.push(
				{
					label: 'Edit',
					action: this.editUserGroup,
					shouldRefresh: false,
				},
				{
					label: 'Delete User Group',
					action: this.deleteUserGroup,
					hasModal: true,
					modalTitle: UserGroupTable.deleteUserGroupModalTitle,
					modalBody: UserGroupTable.deleteUserGroupModalBody,
					modalText: UserGroupTable.deleteUserGroupModalText,
					shouldRefresh: true,
				},
				{
					label: 'Clear User Group',
					action: this.clearUserGroup,
					hasModal: true,
					modalTitle: UserGroupTable.clearUserGroupModalTitle,
					modalBody: UserGroupTable.clearUserGroupModalBody,
					modalText: UserGroupTable.clearUserGroupModalText,
					shouldRefresh: true,
					hide: UserGroupTable.hideClearUserGroup,
				}
			);
		}
		if (hasCommunicationPermission) {
			userGroupRowActions.push(
				{
					label: 'Notify User Group',
					action: this.notifyUserGroup,
					shouldRefresh: false,
				}
			);
		}

		return [
			{
				label: 'Groups',
				columns: UserGroupTable.userGroupsColumns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'User Groups',
				buttons,
				fetch: findAllForCompanyTable,
				onRowClick: this.onGroupRowClick,
				rowActions: userGroupRowActions.length ? userGroupRowActions : undefined,
			},
			{
				label: 'Users',
				columns: UserGroupTable.accountsColumns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'User Groups',
				buttons,
				fetch: findAllAccountsWithUserGroupsForCompanyTable,
				onRowClick: this.onUserRowClick,
				rowActions: hasUserManagePermission
					? [
						{
							label: 'Edit User',
							action: this.editUser,
							shouldRefresh: false,
						},
					]
					: undefined,
			},
		];
	};

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={UserGroupTable.BREADCRUMBS} />
				<Table
					tableName={TableNameEnum.USER_GROUP}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasCommunicationPermission: boolean = isAllowed(
		PagePermissions.COMPANY.COMMUNICATION.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);
	const hasUserGroupManagePermission: boolean = isAllowed(
		PagePermissions.COMPANY.SETTINGS.USER_GROUPS,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);
	const hasUserManagePermission: boolean = isAllowed(
		PagePermissions.COMPANY.SETTINGS.MEMBERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		hasCommunicationPermission,
		hasUserGroupManagePermission,
		hasUserManagePermission,
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		removeUserGroup: UserGroupActions.removeUserGroup,
		findAllForCompanyTable: UserGroupActions.findAllForCompanyTable,
		findAllAccountsWithUserGroupsForCompanyTable: UserGroupActions.findAllAccountsWithUserGroupsForCompanyTable,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(UserGroupTable);
