import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { Droppable, DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Row } from 'react-bootstrap';

import AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';

import { EmployeeOptionVM } from 'ab-viewModels/employee/extendedOption.viewModel';
import EquipmentViewModel from 'ab-viewModels/equipment.viewModel';
import TemporaryEmployeeOptionVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';

import WorkOrderFM from '../../formModel';

import AddResourceLookupButton from './AddResourceLookupButton';
import DraggableResourceLookup from './DraggableResourceLookup';

export interface OwnProps {
	change: (fieldName: string, resources: WorkOrderFM['workOrderResourceLookups']) => void;
	disabled?: boolean;
	dueDate: string;
	employees: EmployeeOptionVM[];
	equipment: EquipmentViewModel[];
	temporaryEmployees: TemporaryEmployeeOptionVM[];
	isAllowedToManagePerDiems: boolean;
	loadEmployees: (isLazyLoaded: boolean) => void;
	loadEquipment: (isLazyLoaded: boolean) => void;
	loadTemporaryEmployees: (isLazyLoaded: boolean) => void;
	resetTimer: () => void;
	selector: (fieldName: string) => WorkOrderFM['workOrderResourceLookups'];
	showResourceAssignConfirmationModal: (resourceType: AssignableResourceType) => void;
	workOrderId: number;
}

type Props = OwnProps & WrappedFieldArrayProps<WorkOrderFM['workOrderResourceLookups'][0]>;

export default class ResourceLookups extends React.PureComponent<Props> {

	static mapUpdatedResourceLookups = (worl: WorkOrderFM['workOrderResourceLookups'][0], index: number) => {
		return {
			...worl,
			index,
		};
	};

	addWorkOrderResourceLookup = (propName: string) => {
		const { fields } = this.props;
		const field = {
			[propName]: null,
			index: fields.length,
		} as Partial<WorkOrderFM['workOrderResourceLookups'][0]>;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fields.push(field as any);
	};

	onRemove = (index: number) => {
		const { selector, change } = this.props;

		const resourceLookups = selector('workOrderResourceLookups') as WorkOrderFM['workOrderResourceLookups'];

		resourceLookups.splice(index, 1);

		const updatedResourceLookups = resourceLookups.map(ResourceLookups.mapUpdatedResourceLookups);

		change('workOrderResourceLookups', updatedResourceLookups);
	};

	onDragEnd = ({ source, destination }: DropResult) => {
		const { selector, change } = this.props;

		if (!destination) {
			return;
		}

		const resourceLookups = selector('workOrderResourceLookups') as WorkOrderFM['workOrderResourceLookups'];

		if (source.index === destination.index) {
			return;
		}

		const [movedItem] = resourceLookups.splice(source.index, 1);

		resourceLookups.splice(destination.index, 0, movedItem);

		const updatedResourceLookups = resourceLookups.map(ResourceLookups.mapUpdatedResourceLookups);

		change('workOrderResourceLookups', updatedResourceLookups);
	};

	render() {
		const {
			disabled,
			dueDate,
			employees,
			equipment,
			temporaryEmployees,
			fields,
			isAllowedToManagePerDiems,
			loadEmployees,
			loadEquipment,
			loadTemporaryEmployees,
			resetTimer,
			workOrderId,
			showResourceAssignConfirmationModal,
		} = this.props;

		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<div className="resource-lookup">
					<Droppable
						droppableId="RESOURCE_LOOKUPS"
						type="RESOURCE_LOOKUPS"
					>
						{(droppableProvided) => (
							<div className="resource-lookup__list" ref={droppableProvided.innerRef}>
								{fields.map((_resourceLookupPropName, _index) => {
									return (
										<DraggableResourceLookup
											disabled={disabled}
											draggableId={`workOrderResourceLookup#${_index}`}
											dueDate={dueDate}
											employees={employees}
											equipment={equipment}
											index={_index}
											isAllowedToManagePerDiems={isAllowedToManagePerDiems}
											key={`workOrderResourceLookup#${_index}`}
											loadEmployees={loadEmployees}
											loadEquipment={loadEquipment}
											loadTemporaryEmployees={loadTemporaryEmployees}
											removeWorkOrderResourceLookup={this.onRemove}
											resetTimer={resetTimer}
											resourceLookup={fields.get(_index) as WorkOrderFM['workOrderResourceLookups'][0]}
											resourceLookupPropName={_resourceLookupPropName}
											showResourceAssignConfirmationModal={showResourceAssignConfirmationModal}
											temporaryEmployees={temporaryEmployees}
											workOrderId={workOrderId}
										/>
									);
								})}
							</div>
						)}
					</Droppable>
					<Row className="row--non-padded row--flex">
						<AddResourceLookupButton
							addWorkOrderResourceLookup={this.addWorkOrderResourceLookup}
							disabled={!!disabled}
							label="Add Labor"
							propName="employeeId"
						/>
						<AddResourceLookupButton
							addWorkOrderResourceLookup={this.addWorkOrderResourceLookup}
							disabled={!!disabled}
							label="Add Equipment"
							propName="equipmentId"
						/>
						<AddResourceLookupButton
							addWorkOrderResourceLookup={this.addWorkOrderResourceLookup}
							disabled={!!disabled}
							label="Add Temporary Labor"
							propName="temporaryEmployeeId"
						/>
						<AddResourceLookupButton
							addWorkOrderResourceLookup={this.addWorkOrderResourceLookup}
							disabled={!!disabled}
							label="Add Labor Placeholder"
							propName="wageRateId"
						/>
						<AddResourceLookupButton
							addWorkOrderResourceLookup={this.addWorkOrderResourceLookup}
							disabled={!!disabled}
							label="Add Equipment Placeholder"
							propName="equipmentCostId"
						/>
					</Row>
				</div>
			</DragDropContext>
		);
	}
}
