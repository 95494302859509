import * as React from 'react';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import DailyViewDatePicker from './DailyViewDatePicker';
import WeeklyViewDatePicker from './WeeklyViewDatePicker';

interface OwnProps {
	refreshConnectionCount: (dates: string[]) => void;
	scheduleBoardView: Nullable<ScheduleBoardView>;
}

type Props = OwnProps;

const ScheduleBoardDatePicker: React.FC<Props> = (props: Props) => {
	const { scheduleBoardView, refreshConnectionCount } = props;

	switch (scheduleBoardView) {
		case ScheduleBoardView.WEEKLY_VIEW:
			return <WeeklyViewDatePicker refreshConnectionCount={refreshConnectionCount} />;
		case ScheduleBoardView.DAILY_VIEW:
			return <DailyViewDatePicker refreshConnectionCount={refreshConnectionCount} />;
		default:
			return null;
	}
};

export default React.memo(ScheduleBoardDatePicker);
