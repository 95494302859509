import * as React from 'react';

export default () => {
	return (
		<div className="form-segment form-segment--mini">
			<div className="form-box form-box--standalone form-box--padded">
				<div className="loading-box-row dark" />
				<div className="loading-box-row dark" />
			</div>
		</div>
	);
};
