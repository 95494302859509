import * as React from 'react';

interface Props {
	isOpaque?: boolean;
}

const NightShiftBadge = (props: Props) => {
	const { isOpaque } = props;

	let className = 'icon-night_shift night-shift-badge';
	if (isOpaque) {
		className = `${className} night-shift-badge--opaque`;
	}

	return (
		<span className={className}>
			<span className="path1" />
			<span className="path2" />
		</span>
	);
};

export default React.memo(NightShiftBadge);
