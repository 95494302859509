import * as React from 'react';
import { Col } from 'react-bootstrap';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import OrderContact from 'af-components/SharedForms/Contacts/ContactWrapper';

import Section from '../Shared/Section';

interface OwnProps {
	disabled: boolean;
	resetTimer: () => void;
}

type Props = OwnProps;

const WorkOrderSiteContact: React.FC<Props> = (props) => {

	const { disabled } = props;

	return (
		<Section label="Site Contact">
			<Col sm={24}>
				<OrderContact
					className="work-order-upsert__site-contact"
					disabled={disabled}
					fieldName="siteContact"
					formName={WORK_ORDER_FORM}
				/>
			</Col>
		</Section>
	);
};

export default React.memo(WorkOrderSiteContact);
