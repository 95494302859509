import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import Tooltip from 'af-components/Tooltip';
import LockedValue from 'af-components/LockedValue';

interface OwnProps {
	jobCode: string;
	assigneeFullName: string;
	comments: string[];
	notes: Nullable<string>;
}

type Props = OwnProps;

const renderComment = (comment: string, index: number) => <div key={index}>{comment}</div>;

const DeliverableHeader: React.FC<Props> = (props: Props) => {
	const { assigneeFullName, comments, jobCode, notes } = props;

	return (
		<Row className="deliverable-status-history-modal-details">
			<Col sm={6}><LockedValue defaultValue="N/A" label="Job ID" value={jobCode} /></Col>
			<Col md={8}><LockedValue defaultValue="N/A" label="QAQC Assignee" value={assigneeFullName} /></Col>
			<Col md={5}>
				<LockedValue defaultValue="N/A"
					label="Comments"
					value={comments.length &&
						<Tooltip message={<div>{comments.map(renderComment)}</div>}>
							<span className="icon-chat chat-icon" />
						</Tooltip>
					}
				/>
			</Col>
			<Col md={5}>
				<LockedValue defaultValue="N/A"
					label="Notes"
					value={notes &&
						<Tooltip message={notes}>
							<span className="icon-display_view notes-icon" />
						</Tooltip>
					}
				/>
			</Col>
		</Row>
	);
};

export default React.memo(DeliverableHeader);
