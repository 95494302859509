import * as React from 'react';

import TimeInterval from 'af-utils/interval.util';

interface OwnProps {
	secondsTotal: number;
	secondsLeft: number;
}

type Props = OwnProps;

interface State {
	rate: number;
}

class DisplayViewTimer extends React.PureComponent<Props, State> {
	state: State = {
		rate: 0,
	};

	timer: TimeInterval;

	constructor(props: Props) {
		super(props);
		if (props.secondsTotal > 0) {
			const step = 100 / props.secondsTotal;
			this.timer = new TimeInterval(this.progress, 1000 / step);
		}
	}

	componentWillUnmount() {
		this.timer?.cancel();
	}

	componentDidUpdate(prevProps: Props) {
		const { secondsLeft, secondsTotal } = this.props;
		if (secondsLeft !== prevProps.secondsLeft) {
			this.setState(() => ({ rate: 0 }));
		}
		if (secondsTotal !== prevProps.secondsTotal && secondsTotal > 0) {
			this.timer?.cancel();
			if (secondsTotal > 0) {
				const step = 100 / secondsTotal;
				this.timer = new TimeInterval(this.progress, 1000 / step);
			}
		}
	}

	progress = () => {
		this.setState((state) => ({ rate: state.rate + 1 }));
	};

	render() {
		const { secondsTotal, secondsLeft } = this.props;
		const { rate } = this.state;

		if (secondsTotal <= 0) {
			return null;
		}

		const progress = ((secondsTotal - secondsLeft) / secondsTotal) * 100;
		const step = 100 / secondsTotal;
		const style = {
			width: `${progress + rate}%`,
			transitionDuration: `${1000 / (step - 1)}ms`,
		};

		return (
			<div className="display-view__timer">
				<div className="display-view__timer--progress" style={style} />
			</div>
		);
	}
}

export default DisplayViewTimer as React.ComponentClass<OwnProps>;
