import * as React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Row, Col } from 'react-bootstrap';

import GoogleScriptLoader from '../Shared/Loader';

interface OwnProps {
	location?: google.maps.LatLngLiteral;
}

type Props = OwnProps;

const defaults = {
	center: { lat: 38.7099085, lng: -95.137812 },
	zoom: 3,
};
class NonEditableMap extends React.Component<Props> {
	render() {
		const { location } = this.props;

		return (
			<div>
				<Row>
					<Col sm={24}>
						<GoogleScriptLoader>
							<GoogleMap
								center={location ?? defaults.center}
								mapContainerStyle={{ height: '400px', marginBottom: '24px' }}
								options={{ scrollwheel: false }}
								zoom={location ? 13 : 3}
							>
								{!!location
									? <Marker position={location} />
									: <></>
								}
							</GoogleMap>
						</GoogleScriptLoader>
					</Col>
				</Row>
			</div>
		);
	}
}

export default NonEditableMap;
