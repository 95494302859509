import * as React from 'react';

type Props = {
	label: string | null;
	oldValue: string | number | null;
	newValue: string | number | null;
};

const valueDisplay = (value: string | number | null) => {
	if (!value) {
		return <div className="cell-additional-info__empty">None</div>;
	}

	return <div>{value}</div>;
};

const anyChangeDisplay = (oldValue: string | number | null, newValue: string | number | null) => {
	return (
		<>
			<div className="cell-additional-info cell-additional-info--change-log">
				<span className="icon-minus cell-additional-info__icon cell-additional-info__icon--minus" />
				{valueDisplay(oldValue)}
			</div>
			<div className="cell-additional-info cell-additional-info--change-log">
				<span className="icon-plus cell-additional-info__icon cell-additional-info__icon--plus" />
				{valueDisplay(newValue)}
			</div>
		</>
	);
};

const showChanges = (oldValue: string | number | null, newValue: string | number | null) => {
	let modifiedOldValue;
	let modifiedNewValue;
	try {
		modifiedOldValue = oldValue && typeof oldValue === 'string' ? JSON.parse(oldValue) : null;
	} catch (error) {
		// Parsing of floats like '1,435.00' fails
		modifiedOldValue = oldValue;
	}
	try {
		modifiedNewValue = newValue && typeof newValue === 'string' ? JSON.parse(newValue) : null;
	} catch (error) {
		// Parsing of floats like '1,435.00' fails
		modifiedNewValue = oldValue;
	}
	const objectKeys = oldValue ? Object.keys(modifiedOldValue) : Object.keys(modifiedNewValue);

	return (
		<>
			{objectKeys.map((key, index) => (
				<React.Fragment key={index} >
					{
						modifiedOldValue !== (undefined || null) && modifiedOldValue[key] !== (undefined || null) ? (
							<div className="cell-additional-info cell-additional-info--change-log" >
								<span className="icon-minus cell-additional-info__icon cell-additional-info__icon--minus" />
								{key}: {String(modifiedOldValue[key])}
							</div>
						) : null
					}
					{
						modifiedNewValue !== (undefined || null) && modifiedNewValue[key] !== (undefined || null) ? (
							<div className="cell-additional-info cell-additional-info--change-log">
								<span className="icon-plus cell-additional-info__icon cell-additional-info__icon--plus" />
								{key}: {String(modifiedNewValue[key])}
							</div>
						) : null
					}
				</React.Fragment >
			))
			}
		</>
	);
};

const ValueChangeCell: React.FC<Props> = ({ label, oldValue, newValue }: Props) => {
	return (
		<>
			{label ? (
				<div className="cell-multirow">
					<div>{label}</div>
					{anyChangeDisplay(oldValue, newValue)}
				</div>
			) : (
				<div className="cell-multirow">{showChanges(oldValue, newValue)}</div>
			)}
		</>
	);
};

export default React.memo(ValueChangeCell);
