export enum SocketEventVersion {
	V1 = 'v1.0',
	V2 = 'v2.0'
}

type V1 = readonly [SocketEventVersion.V1];
type V2 = readonly [SocketEventVersion.V2];
type V1_2 = readonly [SocketEventVersion.V1, SocketEventVersion.V2];
type Versioning = V1 | V2 | V1_2;

type VersionCombination = {
	[SocketEventVersion.V1]: V1 | V1_2;
	[SocketEventVersion.V2]: V2 | V1_2;
};

type EventDefinition = { [action: string]: { event: string; versions: Versioning; }; };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SocketHandler = ((...args: any[]) => void) | (() => void);

export type SocketEventHandler<T extends EventDefinition, V extends SocketEventVersion> = {
	-readonly [key in keyof T as `${T[key]['event']} ${T[key]['versions'] extends VersionCombination[V] ? V : never}`]: SocketHandler;
};
export type PredefinedSocketEventHandler<T extends { [key: string]: string; }> = { [key in T[keyof T]]: SocketHandler };

type SocketEventType<T extends EventDefinition, V extends SocketEventVersion> = {
	-readonly [key in keyof T]: `${T[key]['event']} ${T[key]['versions'] extends VersionCombination[V] ? V : never}`;
};

type SocketEventTree<V extends SocketEventVersion> = {
	BE: {
		GENERAL: SocketEventType<typeof SocketEvents.BE.GENERAL, V>;
		SCHEDULE_BOARD: SocketEventType<typeof SocketEvents.BE.SCHEDULE_BOARD, V>;
		COMPANY: SocketEventType<typeof SocketEvents.BE.COMPANY, V>;
		FIELD_REPORT: SocketEventType<typeof SocketEvents.BE.FIELD_REPORT, V>;
		FIELD_REPORT_LIST: SocketEventType<typeof SocketEvents.BE.FIELD_REPORT_LIST, V>;
		TIME_CARD: SocketEventType<typeof SocketEvents.BE.TIME_CARD, V>;
		TIME_SHEET: SocketEventType<typeof SocketEvents.BE.TIME_SHEET, V>;
		ACCOUNTING: SocketEventType<typeof SocketEvents.BE.ACCOUNTING, V>;
	};
	FE: {
		GENERAL: SocketEventType<typeof SocketEvents.FE.GENERAL, V>;
		SCHEDULE_BOARD: SocketEventType<typeof SocketEvents.FE.SCHEDULE_BOARD, V>;
		COMPANY: SocketEventType<typeof SocketEvents.FE.COMPANY, V>;
		FIELD_REPORT: SocketEventType<typeof SocketEvents.FE.FIELD_REPORT, V>;
		FIELD_REPORT_LIST: SocketEventType<typeof SocketEvents.FE.FIELD_REPORT_LIST, V>;
		TIME_CARD: SocketEventType<typeof SocketEvents.FE.TIME_CARD, V>;
		TIME_SHEET: SocketEventType<typeof SocketEvents.FE.TIME_SHEET, V>;
		ACCOUNTING: SocketEventType<typeof SocketEvents.FE.ACCOUNTING, V>;
	};
};

type SocketEventsRoot = 'ACCOUNTING' | 'SCHEDULE_BOARD' | 'COMPANY' | 'FIELD_REPORT' | 'GENERAL' | 'FIELD_REPORT_LIST' | 'TIME_CARD' | 'TIME_SHEET';

export const SocketEvents = {
	// BE are events that are sent by BE (usually responses)
	BE: {
		GENERAL: {
			SYNC_ERROR: { event: 'RES syncError', versions: [SocketEventVersion.V2] },
		},

		SCHEDULE_BOARD: {
			// connection count
			DAILY_VIEW_CONNECTIONS_COUNT: { event: 'RES connectionsCount', versions: [SocketEventVersion.V2] },
			WEEKLY_VIEW_CONNECTIONS_COUNT: { event: 'RES weeklyViewConnectionsCount', versions: [SocketEventVersion.V2] },
			// data pull & refresh calls
			CONNECTED: { event: 'RES connected', versions: [SocketEventVersion.V2] },
			RELOAD_BOARD: { event: 'RES reloadBoard', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			RELOAD_BOARD_RESOURCES: { event: 'RES reloadBoardResources', versions: [SocketEventVersion.V2] },
			RELOAD_EQUIPMENT: { event: 'RES reloadEquipment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			RELOAD_EMPLOYEE_MODAL_MOBILE: { event: 'RES reloadEmployeeModalMobile', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			RELOAD_EQUIPMENT_MODAL_MOBILE: { event: 'RES reloadEquipmentModalMobile', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			RELOAD_TEMPORARY_EMPLOYEE_MODAL_MOBILE: { event: 'RES reloadTemporaryEmployeeModalMobile', versions: [SocketEventVersion.V2] },
			RELOAD_WORK_ORDER: { event: 'RES reloadWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			RELOAD_WORK_ORDER_EMPLOYEES: { event: 'RES reloadWorkOrderEmployees', versions: [SocketEventVersion.V2] },
			RELOAD_WORK_ORDER_EQUIPMENT: { event: 'RES reloadWorkOrderEquipment', versions: [SocketEventVersion.V2] },
			SYNC_LABOR_STATISTICS: { event: 'RES syncLaborStatistics', versions: [SocketEventVersion.V2] },
			RELOAD_DAILY_PER_DIEM_TIP: { event: 'RES reloadDailyPerDiemTip', versions: [SocketEventVersion.V2] },
			RELOAD_DAILY_TIP: { event: 'RES reloadDailyTip', versions: [SocketEventVersion.V2] },
			// data responses
			RESOURCES: { event: 'RES resources', versions: [SocketEventVersion.V2] },
			DAILY_VIEW_BOARD: { event: 'RES dailyViewBoard', versions: [SocketEventVersion.V2] },
			DAILY_VIEW_BOARD_REJOIN: { event: 'RES dailyViewBoardRejoin', versions: [SocketEventVersion.V2] },
			WEEKLY_VIEW_BOARD: { event: 'RES weeklyViewBoard', versions: [SocketEventVersion.V2] },
			WEEKLY_VIEW_BOARD_REJOIN: { event: 'RES weeklyViewBoardRejoin', versions: [SocketEventVersion.V2] },
			GET_LABOR_STATISTICS: { event: 'RES getLaborStatistics', versions: [SocketEventVersion.V2] },
			// work order updates
			UPDATE_WORK_ORDER: { event: 'RES updateWorkOrder', versions: [SocketEventVersion.V2] },
			MOBILE_UPDATE_WORK_ORDER: { event: 'RES mobileUpdateWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			UPDATE_WORK_ORDER_MODAL: { event: 'RES updateWorkOrderModal', versions: [SocketEventVersion.V2] },
			ADD_BLANK_WORK_ORDER: { event: 'RES addBlankWorkOrder', versions: [SocketEventVersion.V2] },
			REMOVE_BLANK_WORK_ORDER: { event: 'RES removeBlankWorkOrder', versions: [SocketEventVersion.V2] },
			DELETE_WORK_ORDER: { event: 'RES deleteWorkOrder', versions: [SocketEventVersion.V2] },
			MOBILE_DELETE_WORK_ORDER: { event: 'RES mobileDeleteWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			DELETE_WORK_ORDER_IN_EDIT: { event: 'RES deleteWorkOrderInEdit', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			PUBLISH_WORK_ORDER: { event: 'RES publishWorkOrder', versions: [SocketEventVersion.V2] },
			MOBILE_PUBLISH_WORK_ORDER: { event: 'RES mobilePublishWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			CANCEL_WORK_ORDER: { event: 'RES cancelWorkOrder', versions: [SocketEventVersion.V2] },
			PAUSE_WORK_ORDER: { event: 'RES pauseWorkOrder', versions: [SocketEventVersion.V2] },
			RESUME_WORK_ORDER: { event: 'RES resumeWorkOrder', versions: [SocketEventVersion.V2] },
			REVERT_WORK_ORDER: { event: 'RES revertWorkOrder', versions: [SocketEventVersion.V2] },
			LOCK_STATUS_WORK_ORDER: { event: 'RES lockStatusWorkOrder', versions: [SocketEventVersion.V2] },
			UNLOCK_STATUS_WORK_ORDER: { event: 'RES unlockStatusWorkOrder', versions: [SocketEventVersion.V2] },
			MOBILE_CANCEL_WORK_ORDER: { event: 'RES mobileCancelWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			MOBILE_PAUSE_WORK_ORDER: { event: 'RES mobilePauseWorkOrder', versions: [SocketEventVersion.V2] },
			MOBILE_RESUME_WORK_ORDER: { event: 'RES mobileResumeWorkOrder', versions: [SocketEventVersion.V2] },
			MOBILE_REVERT_WORK_ORDER: { event: 'RES mobileRevertWorkOrder', versions: [SocketEventVersion.V2] },
			MOBILE_LOCK_STATUS_WORK_ORDER: { event: 'RES mobileLockStatusWorkOrder', versions: [SocketEventVersion.V2] },
			MOBILE_UNLOCK_STATUS_WORK_ORDER: { event: 'RES mobileUnlockStatusWorkOrder', versions: [SocketEventVersion.V2] },
			COPY_MULTIPLE_WORK_ORDER: { event: 'RES copyMultipleWorkOrder', versions: [SocketEventVersion.V2] },
			SET_PER_DIEM_FOR_WORK_ORDERS: { event: 'RES setPerDiemForWorkOrders', versions: [SocketEventVersion.V2] },
			UPDATE_WORK_ORDER_HAS_ATTACHMENTS: { event: 'RES updateWorkOrderHasAttachments', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			UPDATE_WORK_ORDER_NOTE: { event: 'RES updateWorkOrderNote', versions: [SocketEventVersion.V2] },
			SYNC_WORK_ORDER_METRICS: { event: 'RES syncWorkOrderMetrics', versions: [SocketEventVersion.V2] },
			UPDATE_CREW_TYPE: { event: 'RES updateCrewType', versions: [SocketEventVersion.V2] },
			// locks
			ALREADY_LOCKED_WORK_ORDER: { event: 'RES alreadyLockedWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			LOCK_WORK_ORDER: { event: 'RES lockWorkOrder', versions: [SocketEventVersion.V2] },
			MOBILE_LOCK_WORK_ORDER: { event: 'RES mobileLockWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			UNLOCK_WORK_ORDER: { event: 'RES unlockWorkOrder', versions: [SocketEventVersion.V2] },
			MOBILE_UNLOCK_WORK_ORDER: { event: 'RES mobileUnlockWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			ALREADY_LOCKED_DAILY_METRICS: { event: 'RES alreadyLockedDailyMetrics', versions: [SocketEventVersion.V2] },
			LOCK_DAILY_METRICS: { event: 'RES lockDailyMetrics', versions: [SocketEventVersion.V2] },
			UNLOCK_DAILY_METRICS: { event: 'RES unlockDailyMetrics', versions: [SocketEventVersion.V2] },
			TAKE_OVER_DAILY_METRICS: { event: 'RES takeOverDailyMetrics', versions: [SocketEventVersion.V2] },
			DISABLE_WORK_ORDER: { event: 'RES disableWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			ENABLE_WORK_ORDER: { event: 'RES enableWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			// drag and drop
			EMPLOYEE_DRAG_START: { event: 'RES employeeDragStart', versions: [SocketEventVersion.V2] },
			EMPLOYEE_DRAG_END: { event: 'RES employeeDragEnd', versions: [SocketEventVersion.V2] },
			EQUIPMENT_DRAG_START: { event: 'RES equipmentDragStart', versions: [SocketEventVersion.V2] },
			EQUIPMENT_DRAG_END: { event: 'RES equipmentDragEnd', versions: [SocketEventVersion.V2] },
			PLACEHOLDER_DRAG_START: { event: 'RES placeholderDragStart', versions: [SocketEventVersion.V2] },
			PLACEHOLDER_DRAG_END: { event: 'RES placeholderDragEnd', versions: [SocketEventVersion.V2] },
			WORK_ORDER_DRAG_START: { event: 'RES workOrderDragStart', versions: [SocketEventVersion.V2] },
			WORK_ORDER_DRAG_END: { event: 'RES workOrderDragEnd', versions: [SocketEventVersion.V2] },
			TEMPORARY_EMPLOYEE_DRAG_START: { event: 'RES temporaryEmployeeDragStart', versions: [SocketEventVersion.V2] },
			TEMPORARY_EMPLOYEE_DRAG_END: { event: 'RES temporaryEmployeeDragEnd', versions: [SocketEventVersion.V2] },
			UPDATE_SCHEDULE_BOARD_DROPPABLE_LIST: { event: 'RES updateScheduleBoardDroppableList', versions: [SocketEventVersion.V2] },
			ADD_TO_DROPPABLE_LIST: { event: 'RES addToDroppableList', versions: [SocketEventVersion.V2] },
			REORDER_WORK_ORDERS: { event: 'RES reorderWorkOrders', versions: [SocketEventVersion.V2] },
			MOBILE_REORDER_WORK_ORDERS: { event: 'RES mobileReorderWorkOrders', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			// resource assignments
			CREATE_RESOURCE_PLACEHOLDER: { event: 'RES createResourcePlaceholder', versions: [SocketEventVersion.V2] },
			CREATE_EMPLOYEE_ASSIGNMENT: { event: 'RES createEmployeeAssignment', versions: [SocketEventVersion.V2] },
			REMOVE_EMPLOYEE_ASSIGNMENT: { event: 'RES removeEmployeeAssignment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			CREATE_EQUIPMENT_ASSIGNMENT: { event: 'RES createEquipmentAssignment', versions: [SocketEventVersion.V2] },
			REMOVE_EQUIPMENT_ASSIGNMENT: { event: 'RES removeEquipmentAssignment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			CREATE_PLACEHOLDER_ASSIGNMENT: { event: 'RES createPlaceholderAssignment', versions: [SocketEventVersion.V2] },
			REMOVE_PLACEHOLDER_ASSIGNMENT: { event: 'RES removePlaceholderAssignment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			CREATE_TEMPORARY_EMPLOYEE_ASSIGNMENT: { event: 'RES createTemporaryEmployeeAssignment', versions: [SocketEventVersion.V2] },
			REMOVE_TEMPORARY_EMPLOYEE_ASSIGNMENT: { event: 'RES removeTemporaryEmployeeAssignment', versions: [SocketEventVersion.V2] },
			ADD_WORK_ORDER_RESOURCE_LOOKUP_TO_DICT: { event: 'RES addWorkOrderResourceLookupToDict', versions: [SocketEventVersion.V2] },
			ADD_TEMPORARY_EMPLOYEE: { event: 'RES addTemporaryEmployee', versions: [SocketEventVersion.V2] },
			REMOVE_ALL_EMPLOYEE_ASSIGNMENT_FROM_DICT: { event: 'RES removeAllWorkOrderEmployeeFromDict', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			REMOVE_ALL_EMPLOYEE_ASSIGNMENT_ON_DRAFTS: { event: 'RES removeAllWorkOrderEmployeeOnDrafts', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			REMOVE_ALL_EQUIPMENT_ASSIGNMENT_FROM_DICT: { event: 'RES removeAllWorkOrderEquipmentFromDict', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			REMOVE_ALL_EQUIPMENT_ASSIGNMENT_ON_DRAFTS: { event: 'RES removeAllWorkOrderEquipmentOnDrafts', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			REMOVE_TOOLBAR_EMPLOYEE: { event: 'RES removeToolbarEmployee', versions: [SocketEventVersion.V2] },
			REMOVE_TOOLBAR_EMPLOYEE_FROM_DATE: { event: 'RES removeToolbarEmployeeFromDate', versions: [SocketEventVersion.V2] },
			REMOVE_MULTIPLE_TOOLBAR_EMPLOYEE: { event: 'RES removeMultipleToolbarEmployee', versions: [SocketEventVersion.V2] },
			REMOVE_TOOLBAR_EQUIPMENT: { event: 'RES removeToolbarEquipment', versions: [SocketEventVersion.V2] },
			REMOVE_MULTIPLE_TOOLBAR_EQUIPMENT: { event: 'RES removeMultipleToolbarEquipment', versions: [SocketEventVersion.V2] },
			REMOVE_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS: { event: 'RES removeToolbarEmployeeForAllDays', versions: [SocketEventVersion.V2] },
			REMOVE_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS: { event: 'RES removeToolbarEquipmentForAllDays', versions: [SocketEventVersion.V2] },
			ADD_TOOLBAR_EMPLOYEE: { event: 'RES addToolbarEmployee', versions: [SocketEventVersion.V2] },
			ADD_TOOLBAR_EMPLOYEE_FROM_DATE: { event: 'RES addToolbarEmployeeFromDate', versions: [SocketEventVersion.V2] },
			ADD_TOOLBAR_EQUIPMENT: { event: 'RES addToolbarEquipment', versions: [SocketEventVersion.V2] },
			ADD_EQUIPMENT_DOWN_DETAILS: { event: 'RES addEquipmentDownDetails', versions: [SocketEventVersion.V2] },
			ADD_EMPLOYEE_DOWN_DETAILS: { event: 'RES addEmployeeDownDetails', versions: [SocketEventVersion.V2] },
			ADD_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS: { event: 'RES addToolbarEmployeeForAllDays', versions: [SocketEventVersion.V2] },
			ADD_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS: { event: 'RES addToolbarEquipmentForAllDays', versions: [SocketEventVersion.V2] },
			// resource updates
			ADD_UNAVAILABILITY_REASON: { event: 'RES addEquipmentUnavailabilityReason', versions: [SocketEventVersion.V2] },
			CLEAR_UNAVAILABILITY_REASON: { event: 'RES clearUnavailabilityReason', versions: [SocketEventVersion.V2] },
			CHANGE_RETURN_DATE: { event: 'RES changeReturnDate', versions: [SocketEventVersion.V2] },
			UPDATE_EMPLOYEE_PER_DIEM: { event: 'RES updateEmployeePerDiem', versions: [SocketEventVersion.V2] },
			UPDATE_EMPLOYEE: { event: 'RES updateEmployee', versions: [SocketEventVersion.V2] },
			UPDATE_TEMPORARY_EMPLOYEE: { event: 'RES updateTemporaryEmployee', versions: [SocketEventVersion.V2] },
			UPDATE_EQUIPMENT: { event: 'RES updateEquipment', versions: [SocketEventVersion.V2] },
			// notifications
			SYNC_NOTIFICATION_STATUS: { event: 'RES syncNotificationStatus', versions: [SocketEventVersion.V2] },
			MOBILE_SYNC_NOTIFICATION_STATUS: { event: 'RES mobileSyncNotificationStatus', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			ADD_NIGHT_SHIFT_ASSIGNMENT: { event: 'RES addNightShiftAssignment', versions: [SocketEventVersion.V2] },
			REMOVE_NIGHT_SHIFT_ASSIGNMENT: { event: 'RES removeNightShiftAssignment', versions: [SocketEventVersion.V2] },
			ADD_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT: { event: 'RES addTemporaryEmployeeNightShiftAssignment', versions: [SocketEventVersion.V2] },
			REMOVE_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT: { event: 'RES removeTemporaryEmployeeNightShiftAssignment', versions: [SocketEventVersion.V2] },
		},
		COMPANY: {
			UPDATE_SETTINGS: { event: 'RES updateCompanySettings', versions: [SocketEventVersion.V2] },
		},
		FIELD_REPORT: {
			CONNECTION_COUNT: { event: 'RES fieldReportConnectionCount', versions: [SocketEventVersion.V2] },
			LOCK_FIELD_REPORT_BLOCK_NEW: { event: 'RES lockFieldReportBlockNew', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			/** @deprecated */ LOCK_FIELD_REPORT_BLOCK: { event: 'RES lockFieldReportBlock', versions: [SocketEventVersion.V1] },
			/** @deprecated */ LOCK_SHARED_FIELD_REPORT_BLOCK: { event: 'RES lockSharedFieldReportBlock', versions: [SocketEventVersion.V1] },
			/** Emits only id value of unlocked block */
			UNLOCK_FIELD_REPORT_BLOCK_NEW: { event: 'RES unlockFieldReportBlockNew', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			/** @deprecated */ UNLOCK_FIELD_REPORT_BLOCK: { event: 'RES unlockFieldReportBlock', versions: [SocketEventVersion.V1] },
			/** @deprecated */ UNLOCK_SHARED_FIELD_REPORT_BLOCK: { event: 'RES unlockSharedFieldReportBlock', versions: [SocketEventVersion.V1] },
			FORCE_UNLOCK_FIELD_REPORT_BLOCK_NEW: { event: 'RES forceUnlockFieldReportBlock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			/** @deprecated */ FORCE_UNLOCK_FIELD_REPORT_BLOCK: { event: 'RES forceUnlockFieldReportBlock', versions: [SocketEventVersion.V1] },
			/** @deprecated */ FORCE_UNLOCK_SHARED_FIELD_REPORT_BLOCK: { event: 'RES forceUnlockSharedFieldReportBlock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			SYNC_FIELD_REPORT_TYPES: { event: 'RES syncFieldReportTypes', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			/** @deprecated */ SYNC_MOBILE_FIELD_REPORT_TYPES: { event: 'RES syncMobileFieldReportTypes', versions: [SocketEventVersion.V1] },
			/** @deprecated */ SYNC_VALUES: { event: 'RES syncValues', versions: [SocketEventVersion.V1] },
			/** @deprecated */ SYNC_VALUES_MOBILE: { event: 'RES syncValuesMobile', versions: [SocketEventVersion.V1] },
			/** @deprecated */ ADD_INSTANCE: { event: 'RES addInstance', versions: [SocketEventVersion.V1] },
			/** @deprecated */ ADD_INSTANCE_MOBILE: { event: 'RES addInstanceMobile', versions: [SocketEventVersion.V1] },
			/** @deprecated */ ADD_SEGMENT: { event: 'RES addSegment', versions: [SocketEventVersion.V1] },
			/** @deprecated */ ADD_SEGMENT_MOBILE: { event: 'RES addSegmentMobile', versions: [SocketEventVersion.V1] },
			REMOVE_INSTANCE: { event: 'RES removeInstance', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			/** @deprecated */ REMOVE_INSTANCE_MOBILE: { event: 'RES removeInstanceMobile', versions: [SocketEventVersion.V1] },
			REMOVE_SEGMENT: { event: 'RES removeSegment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			/** @deprecated */ REMOVE_SEGMENT_MOBILE: { event: 'RES removeSegmentMobile', versions: [SocketEventVersion.V1] },
			/** @deprecated */ DELETE_FIELD_REPORT: { event: 'RES deleteFieldReport', versions: [SocketEventVersion.V1] },
			SYNC_BLOCK_COMMENT_THREAD: { event: 'RES syncBlockCommentThread', versions: [SocketEventVersion.V2] },
			SYNC_COMMENT_DELETED: { event: 'RES syncCommentDeleted', versions: [SocketEventVersion.V2] },
			/** @deprecated */ SYNC_FIELD_REPORT_SHARED_REPORT_TYPE: { event: 'RES syncFieldReportSharedReportType', versions: [SocketEventVersion.V1] },
			/** @deprecated */ SYNC_FIELD_REPORT_SHARED_REPORT_TYPE_MOBILE: { event: 'RES syncFieldReportSharedReportTypeMobile', versions: [SocketEventVersion.V1] },
			SYNC_FIELD_REPORT_BLOCK_COMPLETED_STATUS: { event: 'RES syncFieldReportBlockCompletedStatus', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			SYNC_FIELD_REPORT_BLOCK: { event: 'RES syncFieldReportBlock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			SYNC_FIELD_REPORT_BLOCK_VALUE: { event: 'RES syncFieldReportBlockValue', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			BULK_SYNC_FIELD_REPORT_BLOCK_VALUE: { event: 'RES bulkSyncFieldReportBlockValue', versions: [SocketEventVersion.V2] },
			SYNC_REMOVE_REPEATABLE_FIELD_REPORT_BLOCK: { event: 'RES syncRemoveFieldReportBlock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			SYNC_FIELD_REPORT_ADD_INSTANCE: { event: 'RES syncFieldReportAddInstance', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			SYNC_FIELD_REPORT_ADD_SEGMENT: { event: 'RES syncFieldReportAddSegment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
		},
		FIELD_REPORT_LIST: {
			CONNECTION_COUNT: { event: 'RES fieldReportListConnectionCount', versions: [SocketEventVersion.V1] },
			FIELD_REPORT_LIST_UPDATED: { event: 'RES fieldReportListUpdated', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			UPDATE_INTERNAL_REVIEW_STATUS: { event: 'RES updateInternalReviewStatus', versions: [SocketEventVersion.V2] },
			UPDATE_TIME_SHEET: { event: 'RES updateTimeSheet', versions: [SocketEventVersion.V2] },
			WORK_SUMMARY_STATUS_CHANGED: { event: 'RES workSummaryStatusChanged', versions: [SocketEventVersion.V2] },
			WORK_SUMMARY_UPDATE_BILLING_CODES: { event: 'RES workSummaryUpdateBillingCodes', versions: [SocketEventVersion.V2] },
		},
		TIME_CARD: {
			UPDATE_TIME_CARD_ENTRIES: { event: 'RES updateTimeCardEntries', versions: [SocketEventVersion.V2] },
			UPDATE_TIME_CARD_REJECTED_TIME_SHEETS: { event: 'RES updateTimeCardRejectedTimeSheets', versions: [SocketEventVersion.V2] },
		},
		TIME_SHEET: {
			UPDATE_TIME_SHEET_ENTRIES: { event: 'RES updateTimeSheetEntries', versions: [SocketEventVersion.V2] },
		},
		ACCOUNTING: {
			EXPORT_JOB_PAYROLL: { event: 'RES syncJobPayrollDataForExport', versions: [SocketEventVersion.V2] },
			EXPORT_JOB_PAYROLL_BETA: { event: 'RES syncJobPayrollDataForExportBeta', versions: [SocketEventVersion.V2] },
		},
	},
	// FE are events that are sent by FE (usually requests)
	FE: {
		GENERAL: {
			MARK_CONNECTION_IDLE: { event: 'REQ markConnectionIdle', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
		},
		SCHEDULE_BOARD: {
			JOIN_DAILY_VIEW: { event: 'REQ joinDailyView', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			JOIN_WEEKLY_VIEW: { event: 'REQ joinWeeklyView', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			JOIN_MOBILE_DAILY_VIEW: { event: 'REQ joinMobileDailyView', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			ACTIVATE_DAILY_VIEW: { event: 'REQ activateDailyView', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			ACTIVATE_WEEKLY_VIEW: { event: 'REQ activateWeeklyView', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			LOAD_BOARD_RESOURCES: { event: 'REQ loadBoardResources', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			GET_DAILY_VIEW: { event: 'REQ getDailyView', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			GET_WEEKLY_VIEW: { event: 'REQ getWeeklyView', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			GET_DAILY_VIEW_REJOIN: { event: 'REQ getDailyViewRejoin', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			GET_WEEKLY_VIEW_REJOIN: { event: 'REQ getWeeklyViewRejoin', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			LEAVE_BOARD: { event: 'REQ leaveBoard', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			EMPLOYEE_DRAG_START: { event: 'REQ employeeDragStart', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			EMPLOYEE_DRAG_END: { event: 'REQ employeeDragEnd', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			EQUIPMENT_DRAG_START: { event: 'REQ equipmentDragStart', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			EQUIPMENT_DRAG_END: { event: 'REQ equipmentDragEnd', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			PLACEHOLDER_DRAG_START: { event: 'REQ placeholderDragStart', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			PLACEHOLDER_DRAG_END: { event: 'REQ placeholderDragEnd', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			WORK_ORDER_DRAG_START: { event: 'REQ workOrderDragStart', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			WORK_ORDER_DRAG_END: { event: 'REQ workOrderDragEnd', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			TEMPORARY_EMPLOYEE_DRAG_START: { event: 'REQ temporaryEmployeeDragStart', versions: [SocketEventVersion.V2] },
			TEMPORARY_EMPLOYEE_DRAG_END: { event: 'REQ temporaryEmployeeDragEnd', versions: [SocketEventVersion.V2] },
			CREATE_LABOR_PLACEHOLDER: { event: 'REQ createLaborPlaceholder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },	// FIXME: rename labor to employee for consistency
			CREATE_EQUIPMENT_PLACEHOLDER: { event: 'REQ createEquipmentPlaceholder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			CREATE_EMPLOYEE_ASSIGNMENT: { event: 'REQ createEmployeeAssignment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			REMOVE_EMPLOYEE_ASSIGNMENT: { event: 'REQ removeEmployeeAssignment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			CREATE_EQUIPMENT_ASSIGNMENT: { event: 'REQ createEquipmentAssignment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			REMOVE_EQUIPMENT_ASSIGNMENT: { event: 'REQ removeEquipmentAssignment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			CREATE_PLACEHOLDER_ASSIGNMENT: { event: 'REQ createPlaceholderAssignment', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			CREATE_UNAVAILABILITY_REASON: { event: 'REQ createUnavailabilityReason', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			ASSIGN_UNAVAILABILITY_REASON: { event: 'REQ assignUnavailabilityReason', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			CLEAR_UNAVAILABILITY_REASON: { event: 'REQ clearUnavailabilityReason', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			CHANGE_RETURN_DATE: { event: 'REQ changeReturnDate', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			UPDATE_EMPLOYEE_PER_DIEM: { event: 'REQ updateEmployeePerDiem', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			ADD_WORK_ORDER_LOCK: { event: 'REQ addWorkOrderLock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			REMOVE_WORK_ORDER_LOCK: { event: 'REQ removeWorkOrderLock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			FORCE_UNLOCK_WORK_ORDER: { event: 'REQ forceUnlockWorkOrder', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			ADD_DAILY_METRICS_LOCK: { event: 'REQ addDailyMetricsLock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			REMOVE_DAILY_METRICS_LOCK: { event: 'REQ removeDailyMetricsLock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			TAKE_OVER_DAILY_METRICS: { event: 'REQ takeOverDailyMetrics', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			UNSUBSCRIBE_FROM_SCHEDULE_BOARD_EVENTS: { event: 'REQ unsubscribeFromScheduleBoardEvents', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			SET_PER_DIEM_FOR_WORK_ORDERS: { event: 'REQ setPerDiemForWorkOrders', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			GET_LABOR_STATISTICS: { event: 'REQ getLaborStatistics', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			SYNC_LABOR_STATISTICS: { event: 'REQ syncLaborStatistics', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			REFRESH_CONNECTION_COUNT: { event: 'REQ refreshConnectionCount', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
		},
		COMPANY: {
			CHANGE_COMPANY: { event: 'REQ changeCompany', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
		},
		FIELD_REPORT: {
			JOIN_FIELD_REPORT: { event: 'REQ joinFieldReport', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			LEAVE_FIELD_REPORT: { event: 'REQ leaveFieldReport', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			DELETE_FIELD_REPORT: { event: 'REQ deleteFieldReport', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			LOCK_FIELD_REPORT_BLOCK: { event: 'REQ lockFieldReportBlock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			UNLOCK_FIELD_REPORT_BLOCK: { event: 'REQ unlockFieldReportBlock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			FORCE_UNLOCK_FIELD_REPORT_BLOCK: { event: 'REQ forceUnlockFieldReportBlock', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
		},
		FIELD_REPORT_LIST: {
			JOIN_FIELD_REPORT_LIST: { event: 'REQ joinFieldReportList', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
			LEAVE_FIELD_REPORT_LIST: { event: 'REQ leaveFieldReportList', versions: [SocketEventVersion.V1, SocketEventVersion.V2] },
		},
		TIME_CARD: {
			JOIN: { event: 'REQ joinTimeCard', versions: [SocketEventVersion.V2] },
			LEAVE: { event: 'REQ leaveTimeCard', versions: [SocketEventVersion.V2] },
		},
		TIME_SHEET: {
			JOIN: { event: 'REQ joinTimeSheet', versions: [SocketEventVersion.V2] },
			LEAVE: { event: 'REQ leaveTimeSheet', versions: [SocketEventVersion.V2] },
		},
		ACCOUNTING: {
			EXPORT_JOB_PAYROLL: { event: 'REQ getJobPayrollDataForExport', versions: [SocketEventVersion.V2] },
			EXPORT_JOB_PAYROLL_BETA: { event: 'REQ getJobPayrollDataForExportBeta', versions: [SocketEventVersion.V2] },
		},
	},
} as const;

const PredefinedManagerEvent = {
	ERROR: 'error',
	RECONNECT: 'reconnect',
	RECONNECT_ATTEMPT: 'reconnect_attempt',
	RECONNECT_ERROR: 'reconnect_error',
	RECONNECT_FAILED: 'reconnect_failed',
	PING: 'ping',
} as const;

const PredefinedClientEvent = {
	CONNECT: 'connect',
	DISCONNECT: 'disconnect',
	CONNECT_ERROR: 'connect_error',
} as const;

const PredefinedServerEvent = {
	CONNECTION: 'connection',
	NEW_NAMESPACE: 'new_namespace',
	DISCONNECT: 'disconnect',
	DISCONNECTING: 'disconnecting',
} as const;

const PredefinedEngineEvent = {
	INITIAL_HEADERS: 'initial_headers',
	HEADERS: 'headers',
	CONNECTION_ERROR: 'connection_error',
} as const;

export const PredefinedSocketEvents = {
	PredefinedClientEvent,
	PredefinedManagerEvent,
	PredefinedServerEvent,
	PredefinedEngineEvent,
};

function _createSocketEventForVersion(event: string, versionId: SocketEventVersion) {
	return `${event} ${versionId}`;
}

function _constructSocketEventsForRoute<
	EventType extends 'BE' | 'FE',
	EventRoot extends SocketEventsRoot,
	V extends SocketEventVersion
>(
	eventType: EventType,
	eventRoot: EventRoot,
	versionId: V
) {
	const eventsDict = SocketEvents[eventType][eventRoot];
	return Object.keys(eventsDict).reduce((_socketEventsForVersion, _eventKey) => {
		if (eventsDict[_eventKey]?.versions?.includes(versionId)) {
			_socketEventsForVersion[_eventKey] = _createSocketEventForVersion(eventsDict[_eventKey].event, versionId);
		}
		return _socketEventsForVersion;
	}, {} as SocketEventTree<V>[EventType][EventRoot]);
}

function _getSocketEventsForVersion<V extends SocketEventVersion>(versionId: V) {
	return {
		BE: {
			GENERAL: _constructSocketEventsForRoute('BE', 'GENERAL', versionId),
			SCHEDULE_BOARD: _constructSocketEventsForRoute('BE', 'SCHEDULE_BOARD', versionId),
			COMPANY: _constructSocketEventsForRoute('BE', 'COMPANY', versionId),
			FIELD_REPORT: _constructSocketEventsForRoute('BE', 'FIELD_REPORT', versionId),
			FIELD_REPORT_LIST: _constructSocketEventsForRoute('BE', 'FIELD_REPORT_LIST', versionId),
			TIME_CARD: _constructSocketEventsForRoute('BE', 'TIME_CARD', versionId),
			TIME_SHEET: _constructSocketEventsForRoute('BE', 'TIME_SHEET', versionId),
			ACCOUNTING: _constructSocketEventsForRoute('BE', 'ACCOUNTING', versionId),
		},
		FE: {
			GENERAL: _constructSocketEventsForRoute('FE', 'GENERAL', versionId),
			SCHEDULE_BOARD: _constructSocketEventsForRoute('FE', 'SCHEDULE_BOARD', versionId),
			COMPANY: _constructSocketEventsForRoute('FE', 'COMPANY', versionId),
			FIELD_REPORT: _constructSocketEventsForRoute('FE', 'FIELD_REPORT', versionId),
			FIELD_REPORT_LIST: _constructSocketEventsForRoute('FE', 'FIELD_REPORT_LIST', versionId),
			TIME_CARD: _constructSocketEventsForRoute('FE', 'TIME_CARD', versionId),
			TIME_SHEET: _constructSocketEventsForRoute('FE', 'TIME_SHEET', versionId),
			ACCOUNTING: _constructSocketEventsForRoute('FE', 'ACCOUNTING', versionId),
		},
	} as const;
}

export default {
	PREDEFINED: { ...PredefinedManagerEvent, ...PredefinedClientEvent, ...PredefinedServerEvent, ...PredefinedEngineEvent },
	V1: _getSocketEventsForVersion(SocketEventVersion.V1),
	V2: _getSocketEventsForVersion(SocketEventVersion.V2),
};
