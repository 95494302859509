import * as React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	provided: DraggableProvided;
	available: boolean;
	name: string;
	deleteStatus: (available: boolean, name: string) => void;
}

type Props = OwnProps;

class DraggableItem extends React.PureComponent<Props> {

	deleteItem = () => {
		const { available, name, deleteStatus } = this.props;
		deleteStatus(available, name);
	};

	render() {
		const { provided, name } = this.props;

		return (
			<span
				{...provided?.draggableProps}
				{...provided?.dragHandleProps}
				className="update-resource-status__item"
				ref={provided?.innerRef}
			>
				<div className="update-resource-status__draggable-label">
					<span className="icon-drag_indicator update-resource-status__draggable-indicator" />
					<span>{name}</span>
				</div>
				<Tooltip message="Delete">
					<span className="icon-delete update-resource-status__remove-icon" onClick={this.deleteItem} />
				</Tooltip>
			</span>
		);
	}
}

export default React.memo(DraggableItem);
