export enum WORK_SUMMARY_DETAILS_TABLE_HEADERS {
	ACTIONS = '',
	DATE = 'Date',
	WORK_ORDER = 'Work Order',
	FR_SEGMENT = 'FR Segment',
	FR_SECONDARY_SEGMENT = 'FR Secondary Segment',
	WORK = 'Work',
	TYPE = 'Type',
	DEFINITION_FIELD = 'Definition',
	QUANTITY = 'Quantity',
	UNIT = 'Unit',
	INFORMATIONAL_FIELD = 'Info',
	BILLING_CODE_ID = 'Billing code ID',
	BILLING_CODE_DESCRIPTION = 'Billing code description'
}

export enum WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS {
	RECENTLY_USED = 'Recently Used',
	WORK = 'Work',
	TYPE = 'Type',
	DEFINITION_FIELD = 'Definition',
	QUANTITY = 'Quantity',
	UNIT = 'Unit',
	CHARGE_RATE = 'Charge Rate',
	UNIT_PRICE = 'Unit Price ($)',
	DESCRIPTION = 'Description',
	REVENUE = 'Revenue ($)'
}

export enum WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_HEADERS {
	RECENTLY_USED = 'Recently Used',
	WORK = 'Work',
	TYPE = 'Type',
	DEFINITION_FIELD = 'Definition',
	QUANTITY = 'Quantity',
	UNIT = 'Unit',
	BILLING_CODE = 'Billing Code',
	UNIT_PRICE = 'Unit Price ($)',
	DESCRIPTION = 'Description',
	REVENUE = 'Revenue ($)'
}
