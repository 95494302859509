import * as React from 'react';
import { Button } from 'react-bootstrap';

import { SCREEN_BREAKPOINT_XL } from 'af-constants/values';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	isMultiSelectActive: boolean;
	toggleMultiSelect: () => void;
}

interface State {
	isButtonSmall: boolean;
}

type Props = OwnProps;

class SelectMultipleButton extends React.PureComponent<Props, State> {

	state: State = {
		isButtonSmall: window.innerWidth < SCREEN_BREAKPOINT_XL,
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		const { isButtonSmall: prevIsButtonSmall } = this.state;
		const isButtonSmall = window.innerWidth < SCREEN_BREAKPOINT_XL;

		if (prevIsButtonSmall !== isButtonSmall) {
			this.setState(() => ({ isButtonSmall }));
		}
	};

	render() {
		const { toggleMultiSelect, isMultiSelectActive } = this.props;
		const { isButtonSmall } = this.state;

		return (
			<>
				{
					isButtonSmall ?
						<Tooltip message="Select Work Orders">
							<Button className={`btn--icon ${isMultiSelectActive ? 'btn-info--blue-fill' : ''}`} onClick={toggleMultiSelect} variant="info">
								<span className="icon-checkbox_multiple" />
							</Button>
						</Tooltip> :
						<Button className={isMultiSelectActive ? 'btn-info--blue-fill' : ''} onClick={toggleMultiSelect} variant="info">
							<span className="icon-checkbox_multiple m-r-xs" />
							Select WOs
						</Button>
				}
			</>
		);
	}
}

export default SelectMultipleButton;
