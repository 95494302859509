import * as React from 'react';

import { DisplayViewPlaceholderViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import SkillsColorGrid from 'af-components/SkillsColorGrid';

import * as ColorUtils from 'ab-utils/color.util';

interface OwnProps {
	placeholder: DisplayViewPlaceholderViewModel;
}

type Props = OwnProps;

const PlaceholderItem: React.FC<Props> = (props: Props) => {
	const { displayName, skills, categoryColor } = props.placeholder;

	return (
		<div className="sb-resource-item sb-resource-item--placeholder">
			<div className="sb-resource-item__content">
				<span className="sb-resource-item__full-name sb-resource-item__full-name--transparent-bg">{displayName}</span>
				{!!skills?.length && <SkillsColorGrid skills={skills} />}
				{!!categoryColor && <div className={`sb-resource-item__category-color-preview ${ColorUtils.getColorBackgroundClass(categoryColor, true)}`} />}
			</div>
		</div>
	);
};

export default React.memo(PlaceholderItem);
