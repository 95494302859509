import * as React from 'react';

import styles from './styles.module.scss';

type Props = {
	onEditClick: () => void;
	isInEditMode: boolean;
};

const EditCell: React.FC<Props> = ({ onEditClick, isInEditMode }) => {
	return (
		<span className={styles['edit-cell']}>
			{isInEditMode
				? <span className="icon-save" onClick={onEditClick} />
				: <span className="icon-edit" onClick={onEditClick} />
			}
		</span>
	);
};

export default React.memo(EditCell);
