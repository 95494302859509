import * as React from 'react';

import { SkillViewModel } from 'ab-viewModels/skill.viewModel';

import { MultiTagOptionProps } from 'af-fields/MultiTagSelect';

import Tag from 'af-components/Controls/Tag';

type Props = MultiTagOptionProps<SkillViewModel>;

const SkillMultiValueLabel = (props: Props) => {
	const { data, onRemove } = props;

	return (
		<Tag color={data.color} size="small">
			<span>{data.name}</span>
			<span className="icon-close" onMouseDown={onRemove} role="button" />
		</Tag>
	);
};

export default SkillMultiValueLabel;
