import * as React from 'react';

interface OwnProps {
	fullWidth?: boolean;
	isLink?: boolean;
	label: string | JSX.Element;
	reduced?: 'top' | 'all';
}

type Props = OwnProps;

export default ({ label, reduced, isLink = false, fullWidth = false }: Props) => {

	let segmentLabelClassName = 'segment-label';
	segmentLabelClassName = reduced ? `${segmentLabelClassName} segment-label--reduced-${reduced}` : segmentLabelClassName;
	segmentLabelClassName = isLink ? `${segmentLabelClassName} segment-label--link` : segmentLabelClassName;
	segmentLabelClassName = fullWidth ? `${segmentLabelClassName} segment-label--stretched` : segmentLabelClassName;

	return (
		<div className={segmentLabelClassName}>
			{label}
		</div>
	);
};
