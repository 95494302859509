import * as React from 'react';
import { FieldArray } from 'redux-form';

import JobContact from 'af-components/SharedForms/Contacts/ContactWrapper';

import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';

import WorkRequestUpsertFM from '../../../formModel';
import { formSectionClass, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../../helpers';

import AdditionalBillingInformation from './AdditionalBillingInformation';
import styles from '../../../styles.module.scss';
import AdditionalContacts from './AdditionalContacts';

type Props = {
	formName: string;
};

const ContactsSection: React.FC<Props> = ({ formName }) => {

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Contacts
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					Customer Contact
					<JobContact
						className={styles['work-request-form__contact']}
						contactLabel="Enter Customer Contact"
						fieldName={WorkRequestUpsertFM.getAttributeName('customerContact')}
						formName={formName}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					Site Contact
					<JobContact
						className={styles['work-request-form__contact']}
						contactLabel="Enter Site Contact"
						fieldName={WorkRequestUpsertFM.getAttributeName('siteContact')}
						formName={formName}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					Billing Contact
					<JobContact
						className={styles['work-request-form__contact']}
						contactLabel="Enter Billing Contact"
						fieldName={WorkRequestUpsertFM.getAttributeName('billingContact')}
						formName={formName}
					/>
				</div>
			</div>
			<AdditionalBillingInformation />
			<FieldArray<void>
				component={AdditionalContacts}
				name={getAttributeName('additionalContacts')}
			/>
		</div>
	);
};

export default React.memo(ContactsSection);
