import CDLStatus from 'acceligent-shared/enums/cdlStatus';
import TimePeriodSpan from 'acceligent-shared/enums/timePeriodSpan';
import { ColorPalette, DefaultColor } from 'acceligent-shared/enums/color';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import { TimePeriodRange } from 'acceligent-shared/utils/time';

import Skill from 'acceligent-shared/models/skill';

import { TableQuery, TableSortBy } from 'ab-common/dataStructures/tableQuery';

import { LaborUtilizationServiceModel, MonthlyLaborUtilizationServiceModel } from 'ab-serviceModels/employee.serviceModel';

import { LineChartDataVectorViewModel, UtilizationInfoCardChartViewModel } from 'ab-common/dataStructures/chart';

import { MONTH_LABEL_SHORT } from 'ab-enums/month.enum';

import { somePredicates } from 'ab-utils/array.util';
import { buildTable } from 'ab-utils/table.util';
import { getFormattedFullName } from 'ab-utils/user.util';
import { moneyNormalizer } from 'ab-utils/formatting.util';

// PRIVATE

const CSV_HEADER_KEYS = [
	'Name',
	'Scheduled',
	'Not Scheduled',
	'Not Available',
	'Daily Revenue',
	'Total Revenue',
	'skill.0.name',
	'skill.0.color',
];

/** Used for empty skills in CSV */
const EMPTY_SKILL = {
	name: '',
	color: '',
};

const NO_SKILLS_OPTION = {
	id: -1,
	name: 'NO SKILL',
	color: DefaultColor.NO_SKILL,
};

const DEFAULT_SORT_BY: TableSortBy[] = [{ id: 'fullName', desc: false }, { id: 'timePeriodId', desc: true }];

function _getUtilizationRowsFilterByTextPredicate(text: string): Nullable<ArrayPredicate<LaborUtilizationViewModel>> {
	const trimmedText = (text || '').trim();
	if (!trimmedText) {
		return null;
	}
	const uppercaseText = trimmedText.toUpperCase();

	const filterFunctions: ((_e: LaborUtilizationViewModel) => boolean)[] = [
		(_e: LaborUtilizationViewModel) => _e.timePeriodId.toString().toUpperCase().includes(uppercaseText),
		(_e: LaborUtilizationViewModel) => _e.lastName.toUpperCase().startsWith(uppercaseText) || _e.firstName.toUpperCase().startsWith(uppercaseText),
	];

	if (trimmedText.length <= 2) {
		return somePredicates(filterFunctions);
	}
	// if length is more then 2 search the middle of the string, not just the start:
	filterFunctions[1] = (_e: LaborUtilizationViewModel) => _e.fullName.toUpperCase().includes(uppercaseText);

	return somePredicates(filterFunctions);
}

function _filterUtilizationRowsByText(rows: LaborUtilizationViewModel[], text: string): LaborUtilizationViewModel[] {
	const predicate = _getUtilizationRowsFilterByTextPredicate(text);
	if (!predicate?.length) {
		return rows;
	}
	return rows.filter(predicate);
}

function _getMonthlyAverageDailyRevenue(serviceModel: MonthlyLaborUtilizationServiceModel): Nullable<number> {
	if (serviceModel.employeeCount === 0) {
		return null;
	}
	return +serviceModel.totalRevenue / (serviceModel.workDatesCount || 1);
}

class SkillViewModel {
	id: number;
	name: string;
	color: ColorPalette;

	constructor(skill: Skill) {
		this.id = skill.id;
		this.name = skill.name;
		this.color = skill.color;
	}

	static bulkConstructor(skills: Skill[] = []): SkillViewModel[] {
		return skills.map(SkillViewModel._constructorMap);
	}

	private static _constructorMap = (_skill: Skill) => new SkillViewModel(_skill);
}

// PUBLIC

export class LaborUtilizationViewModel {
	id: number;

	firstName: string;
	lastName: string;
	fullName: string;

	timePeriod: TimePeriodSpan;
	timePeriodId: number | string;

	cdlStatus: CDLStatus;
	skills: SkillViewModel[];

	daysAssigned: number;
	daysAvailable: number;
	daysUnavailable: number;
	totalDays: number;

	dailyRevenue: number;
	totalRevenue: number;
	/** CURRENTLY ALWAYS `0` */
	dailyCost: number;
	/** CURRENTLY ALWAYS `0` */
	totalCost: number;
	/** difference between `totalRevenue` and `totalCost`, CURRENTLY ALWAYS EQUALS `totalRevenue` */
	targetProfit: number;

	/** NOT IMPLEMENTED */
	hired: undefined;
	/** NOT IMPLEMENTED */
	hoursThisWeek: undefined;
	/** NOT IMPLEMENTED */
	hoursThisMonth: undefined;

	constructor(
		serviceModel: LaborUtilizationServiceModel,
		employeeSkillDictionary: { [employeeId: number]: Skill[]; },
		timePeriodId: number | string,
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR
	) {
		this.id = serviceModel.employeeId;

		this.firstName = serviceModel.firstName;
		this.lastName = serviceModel.lastName;
		this.fullName = getFormattedFullName(this.firstName, this.lastName);

		this.timePeriod = timePeriod;
		this.timePeriodId = timePeriodId;

		this.cdlStatus = serviceModel.cdlStatus;
		this.skills = SkillViewModel.bulkConstructor(employeeSkillDictionary[serviceModel.employeeId]);

		this.daysAssigned = serviceModel.daysAssigned;
		this.daysAvailable = serviceModel.daysAvailable;
		this.daysUnavailable = serviceModel.daysUnavailable;
		this.totalDays = this.daysAssigned + this.daysAvailable + this.daysUnavailable;

		this.dailyRevenue = +serviceModel.averageDailyRevenue;
		this.totalRevenue = +serviceModel.totalRevenue;
		this.dailyCost = 0;
		this.totalCost = 0;
		this.targetProfit = this.totalRevenue - this.totalCost;
	}

	static toCSVData(viewModels: LaborUtilizationViewModel[]): string[][] {
		let maxNumberOfSkills = 1;	// if skills are able to be shown on export, the first group of columns should always be visible
		const header: string[] = [...CSV_HEADER_KEYS];

		// We should expand header keys (if there are users that have more than one skill)
		viewModels.forEach((_entry: LaborUtilizationViewModel) => {
			if (_entry.skills.length > maxNumberOfSkills) {
				for (let i = maxNumberOfSkills; i < _entry.skills.length; i++) {
					header.push(`skill.${i}.name`, `skill.${i}.color`);
				}
				maxNumberOfSkills = _entry.skills.length;
			}
		});

		const rows: string[][] = viewModels.map((_entry: LaborUtilizationViewModel) => {
			const _row: string[] = [
				_entry.fullName,
				`${_entry.daysAssigned} days`,
				`${_entry.daysAvailable} days`,
				`${_entry.daysUnavailable} days`,
				`${moneyNormalizer(_entry.dailyRevenue)}`,
				`${moneyNormalizer(_entry.totalRevenue)}`,
			];
			for (let _index = 0; _index < maxNumberOfSkills; _index++) {
				const _skill = _entry.skills[_index] || EMPTY_SKILL;
				_row.push(_skill.name, _skill.color);
			}
			return _row;
		});

		return [header, ...rows];
	}
}

export class LaborUtilizationDataViewModel {
	employeeIdsBySkillId: { [skillId: string]: string[]; };
	rows: LaborUtilizationViewModel[];

	daysAssignedSum: number;
	daysAvailableSum: number;
	daysUnavailableSum: number;
	totalDaysSum: number;

	/** rounded average by for `rows`, equals sum if no `rows` (i.e. equals `0`) */
	daysAssigned: number;
	/** rounded average by for `rows`, equals sum if no `rows` (i.e. equals `0`) */
	daysAvailable: number;
	/** rounded average by for `rows`, equals sum if no `rows` (i.e. equals `0`) */
	daysUnavailable: number;
	/** shorthand for `daysAssigned + daysAvailable + daysUnavailable` */
	totalDays: number;

	/** `null` if `totalDays === 0`, average `totalRevenue` by `totalDays` */
	dailyRevenue: Nullable<number>;
	/** sum of al `rows` elements' `totalRevenue` */
	totalRevenue: number;
	/** `null` if `totalDays === 0`, average `totalCost` by `totalDays` */
	dailyCost: Nullable<number>;
	/** sum of al `rows` elements' `totalCost` */
	totalCost: number;
	/** difference between `totalRevenue` and `totalCost` */
	targetProfit: number;

	timePeriod: TimePeriodSpan;
	timePeriodRanges?: TimePeriodRange[];

	constructor(
		laborServiceModelLists: LaborUtilizationServiceModel[][],
		employeeSkillDictionary: { [employeeId: number]: Skill[]; },
		timePeriodIds: number[] | string[],
		timePeriod: TimePeriodSpan = TimePeriodSpan.YEAR,
		timePeriodRanges?: TimePeriodRange[]
	) {
		this.employeeIdsBySkillId = Object.entries(employeeSkillDictionary).reduce((_dict, [_employeeId, _skills]) => {
			for (const _skill of _skills) {
				if (!_dict[_skill.id]) {
					_dict[_skill.id] = [];
				}
				_dict[_skill.id].push(_employeeId);
			}
			return _dict;
		}, {} as { [skillId: string]: string[]; });

		this.rows = [];

		this.daysAssignedSum = 0;
		this.daysAvailableSum = 0;
		this.daysUnavailableSum = 0;

		this.totalRevenue = 0;
		this.totalCost = 0;

		this.employeeIdsBySkillId[NO_SKILLS_OPTION.id] = [];

		laborServiceModelLists.forEach((_laborServiceModelList: LaborUtilizationServiceModel[], _index: number) => {
			for (const _serviceModel of _laborServiceModelList) {
				const _viewModel = new LaborUtilizationViewModel(_serviceModel, employeeSkillDictionary, timePeriodIds[_index], timePeriod);

				this.daysAssignedSum += _viewModel.daysAssigned;
				this.daysAvailableSum += _viewModel.daysAvailable;
				this.daysUnavailableSum += _viewModel.daysUnavailable;
				this.totalRevenue += _viewModel.totalRevenue;
				this.totalCost += _viewModel.totalCost;

				if (_viewModel.skills.length === 0) {
					this.employeeIdsBySkillId[NO_SKILLS_OPTION.id].push(_serviceModel.employeeId.toString());
				}
				this.rows.push(_viewModel);
			}
		});
		this.totalDaysSum = this.daysAssignedSum + this.daysAvailableSum + this.daysUnavailableSum;

		this.daysAssigned = Math.round(this.daysAssignedSum / (this.rows.length || 1));
		this.daysAvailable = Math.round(this.daysAvailableSum / (this.rows.length || 1));
		this.daysUnavailable = Math.round(this.daysUnavailableSum / (this.rows.length || 1));
		this.totalDays = this.daysAssigned + this.daysAvailable + this.daysUnavailable;

		this.dailyRevenue = this.totalDaysSum ? (this.totalRevenue / this.totalDaysSum) : null;
		this.dailyCost = this.totalDaysSum ? (this.totalCost / this.totalDaysSum) : null;
		this.targetProfit = this.totalRevenue - this.totalCost;

		this.timePeriod = timePeriod;
		this.timePeriodRanges = timePeriodRanges;
	}

	static getTable(
		tableRequest: TableQuery,
		selectedSkillIds: Nullable<number[]>,
		cdlOption: Nullable<CDLStatus>,
		data: LaborUtilizationDataViewModel
	): Nullable<TableViewModel<LaborUtilizationViewModel>> {
		let rows: LaborUtilizationViewModel[] = [];
		const sortBy: TableSortBy[] = !tableRequest.sortBy?.length ? DEFAULT_SORT_BY : tableRequest.sortBy;

		if (!selectedSkillIds?.length) {
			rows = data.rows;
		} else {	// filter by skills
			rows = data.rows.filter((_row) => {
				const { id: employeeId } = _row;

				return selectedSkillIds.every((_skillId) => {
					return data.employeeIdsBySkillId[_skillId]?.includes(employeeId.toString());
				});
			});
		}

		if (cdlOption !== null) {
			rows = rows.filter((_row) => _row.cdlStatus === cdlOption);
		}

		return buildTable({ ...tableRequest, sortBy }, rows, _filterUtilizationRowsByText);
	}
}

export interface LaborUtilizationChartsViewModel {
	averageDailyRevenueChart: AverageDailyRevenueChartViewModel;
}

export class AverageDailyRevenueChartViewModel implements UtilizationInfoCardChartViewModel {
	currentHalfYear: number;
	currentHalfYearData: LineChartDataVectorViewModel<Nullable<number>>;
	previousHalfYear: number;
	previousHalfYearData: Nullable<LineChartDataVectorViewModel<Nullable<number>>>;

	hAxisKeys: string[];
	hAxisBaselineKeyIndex: number | undefined;

	constructor(
		currentHalfYear: number,
		currentHalfYearServiceModels: MonthlyLaborUtilizationServiceModel[],
		previousHalfYear: number,
		previousHalfYearServiceModels: MonthlyLaborUtilizationServiceModel[],
		fiscalYearStartMonth: number
	) {
		this.currentHalfYear = currentHalfYear;
		this.currentHalfYearData = {
			label: this.currentHalfYear.toString(),
			values: currentHalfYearServiceModels.map(_getMonthlyAverageDailyRevenue),
		};

		this.previousHalfYear = previousHalfYear;
		this.previousHalfYearData = (previousHalfYear && previousHalfYearServiceModels)
			? {
				label: this.previousHalfYear.toString(),
				values: previousHalfYearServiceModels.map(_getMonthlyAverageDailyRevenue),
			}
			: null;

		this.hAxisKeys = currentHalfYearServiceModels.map((_serviceModel) => MONTH_LABEL_SHORT[_serviceModel.month]);
		this.hAxisBaselineKeyIndex = this.hAxisKeys.findIndex((_monthLabel) => _monthLabel === MONTH_LABEL_SHORT[fiscalYearStartMonth]);
		if (this.hAxisBaselineKeyIndex === -1) {
			this.hAxisBaselineKeyIndex = undefined;
		}
	}
}
