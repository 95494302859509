import * as React from 'react';

import AssociatedSubjobVM from 'ab-viewModels/workRequest/associatedSubjob.viewModel';

import TimeAllocationEntry from './TimeAllocationEntry';
import styles from './styles.module.scss';

interface OwnProps {
	redirectToVirtualFieldReport: (subjob: AssociatedSubjobVM) => void;
	allocations: AssociatedSubjobVM[];
}

type Props = OwnProps;

const WorkOrderAssociatedSubjobData: React.FC<Props> = (props: Props) => {
	const {
		redirectToVirtualFieldReport,
		allocations,
	} = props;

	return (
		<div className={styles['associated-subjob-data-container']}>
			<span className={styles['associated-subjob-data-header']}>
				Associated Sub-job Data:
			</span>
			{
				allocations.length === 0 &&
				<span className={styles['associated-subjob-data-text']}>
					Data associated with different Sub-jobs will be shown here. You can do this from the "Associate with:" dropdown.
				</span>
			}
			{allocations.map((allocation) => (
				<TimeAllocationEntry
					isProject={allocation.isProject}
					key={allocation.id}
					redirectToVirtualFieldReport={redirectToVirtualFieldReport}
					timeAllocationEntry={allocation}
				/>
			))}
		</div>
	);
};

export default React.memo(WorkOrderAssociatedSubjobData);
