import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';

import * as UserGroupActions from 'af-actions/userGroup';

import UserGroupRequestModel from 'ab-requestModels/userGroup.requestModel';

import Dropdown from 'af-components/Controls/Dropdown';
import Pills from 'af-components/Pills';

export interface OwnProps {
	onClearAllUserGroups?: () => void;
	onUserGroupRemove?: (userGroupName: string) => void;
	label?: string;
	skipConfirmationModal?: boolean;
	includeAccounts?: boolean;
	fixed?: boolean;
	initialGroupId?: string;
}

type ConnectOwnProps = OwnProps & WrappedFieldArrayProps<UserGroupRequestModel>;

type Props = ConnectOwnProps & ConnectedProps<typeof connector>;

interface State {
	userGroupsForCompany: UserGroupRequestModel[];
	options: UserGroupRequestModel[];
}

class UserGroups extends React.PureComponent<Props, State> {
	state: State = {
		userGroupsForCompany: [],
		options: [],
	};

	static readonly FILTER_BY = ['name'] as const;

	static modalBody = (item: UserGroupRequestModel) => (
		<>
			You are about to remove user from <b>{item.name}</b> user group.
			Are you sure you want to continue?
		</>
	);

	async componentDidMount() {
		const { findAllForCompany, includeAccounts, initialGroupId, fields } = this.props;
		const userGroups = await findAllForCompany(!!includeAccounts);

		const selectedIndex = initialGroupId ? userGroups.findIndex((_group) => _group.id === +initialGroupId) : null;
		const options = [...userGroups];
		const [selected] = selectedIndex ? options.splice(selectedIndex, 1) : [];

		if (selected) {
			fields.push(selected);
		}

		this.setState(() => ({ userGroupsForCompany: userGroups, options }));
	}

	componentDidUpdate(prevProps: Props): void {
		const { fields } = this.props;
		const { userGroupsForCompany } = this.state;

		if (prevProps.fields.length !== fields.length) {
			const selectedGroups = fields.getAll();

			if (!!selectedGroups) {
				const options = userGroupsForCompany.filter(
					(_userGroup) => !selectedGroups.some(
						(_selectedUserGroup) => _selectedUserGroup.id === _userGroup.id
					)
				);
				this.setState(() => ({ options }));
			}
		}
	}

	removeUserGroup = (index: number) => {
		const { fields, onClearAllUserGroups, onUserGroupRemove } = this.props;
		const userGroup = fields.get(index);
		const userGroupsCount = fields.length - 1;
		fields.remove(index);
		if (onUserGroupRemove) {
			onUserGroupRemove(userGroup.name);
		}
		if (!userGroupsCount && onClearAllUserGroups) {
			onClearAllUserGroups();
		}
	};

	onChange = async (option: UserGroupRequestModel) => {
		const { fields } = this.props;
		fields.push(option);
	};

	render() {
		const { label, fields, skipConfirmationModal, fixed } = this.props;
		const { options } = this.state;

		return (
			<div className="user-groups-control">
				<div className="user-groups-control__input">
					<Dropdown<UserGroupRequestModel>
						filterable={true}
						filterBy={UserGroups.FILTER_BY}
						fixed={fixed}
						forcePlaceholder={true}
						isWhite={true}
						label={label}
						labelKey="name"
						onValueChange={this.onChange}
						options={options}
						placeholder="Select user group"
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<Pills
					destructiveAction={this.removeUserGroup}
					emptyLabel="No user groups selected"
					items={fields}
					labelKey="name"
					modalBody={UserGroups.modalBody}
					modalTitle="Remove User"
					skipConfirmationModal={skipConfirmationModal}
				/>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		findAllForCompany: UserGroupActions.findAllForCompany,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(UserGroups);
