import { isValidTextInput } from 'acceligent-shared/utils/text';

import SystemNotificationRequestModel from 'ab-requestModels/systemNotification.requestModel';

export function validate(values: SystemNotificationRequestModel) {
	const errors: ValidationErrors = {};

	if (!values.type) {
		errors.type = 'Type is required.';
	}

	if (!isValidTextInput(values.content)) {
		errors.content = 'Content is required.';
	}

	if (!values.startsAtTime) {
		errors.startsAtTime = 'Start time is required.';
	}

	if (!values.startsAtDate) {
		errors.startsAtDate = 'Start date is required.';
	}

	if (!values.endsAtTime) {
		errors.endsAtTime = 'End time is required.';
	}

	if (!values.endsAtDate) {
		errors.endsAtDate = 'End date is required.';
	} else if (
		values.startsAtDate && values.endsAtDate &&
		((values.startsAtDate > values.endsAtDate) || (values.startsAtDate === values.endsAtDate && values.startsAtTime! >= values.endsAtTime!))
	) {
		errors.endsAtDate = 'End must be after start.';
	}

	return errors;
}
