import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import { VendorList, VendorPackageTypeItem } from 'acceligent-shared/enums/vendorPackageType';

import VendorVM from 'ab-viewModels/vendor/vendor.viewModel';

import Input from 'af-fields/Input';
import AsyncSelect from 'af-fields/AsyncSelect';
import Textarea from 'af-fields/Textarea';
import Dropdown from 'af-fields/Dropdown';

type Props = {
	vendors: { label: string; value: number; }[];
	onVendorChange: (name: Nullable<string>) => void;
};

const getVendorValue = (option: Props['vendors'][0]) => option.value;
const getVendorLabel = (option: Props['vendors'][0]) => option.label;
const formatVendorLabel = (option: Props['vendors'][0]) => <span>{option.label}</span>;

const Vendor = (props: Props) => {
	const { vendors, onVendorChange } = props;

	const filterVendor = React.useCallback(async (key: string) => {
		if (!key) {
			return vendors;
		}
		return vendors.filter((_item) => {
			const name = _item.label.toLowerCase();
			return name.includes(key.toLowerCase());
		});
	}, [vendors]);

	const onVendorCreate = React.useCallback((name: string) => {
		onVendorChange(name);
	}, [onVendorChange]);

	const onVendorClear = React.useCallback(() => {
		onVendorChange(null);
	}, [onVendorChange]);

	const isNewVendorValid = React.useCallback(() => {
		return true;
	}, []);

	const getNewVendor = React.useCallback((value: string, label: string) => {
		return { id: +value, name: label } as VendorVM;
	}, []);

	// fixes issue where vendor list is not shown correctly after creating new option and clearing it.
	// Props would update correctly, but new value wouldn't be shown
	// filtering by new vendor name would show it correctly.
	const getOptions = React.useCallback(() => {
		return vendors;
	}, [vendors]);

	const packageTypeItem = React.useCallback((option: VendorPackageTypeItem) => <div>{option.label}</div>, []);

	return (
		<Row>
			<Col md={8}>
				<Field
					allowNew={true}
					component={AsyncSelect}
					formatOptionLabel={formatVendorLabel}
					getNewOptionData={getNewVendor}
					getOptionLabel={getVendorLabel}
					getOptionValue={getVendorValue}
					isValidNewOption={isNewVendorValid}
					label="Vendor"
					name="vendor"
					onClear={onVendorClear}
					onCreateNew={onVendorCreate}
					onSearch={filterVendor}
					options={getOptions()}
					placeholder="Vendor"
				/>
			</Col>
			<Col md={8}>
				<Field
					component={Input}
					controlCursor={true}
					label="Vendor Part Number"
					name="vendorPartNumber"
					type="text"
				/>
			</Col>
			<Col md={8}>
				<Field
					component={Input}
					controlCursor={true}
					label="Vendor Url"
					name="vendorUrl"
					type="text"
				/>
			</Col>
			<Col md={8}>
				<Field
					component={Dropdown}
					id="packageType"
					label="Package Type"
					name="packageType"
					options={VendorList}
					renderMenuItem={packageTypeItem}
					valueKey="id"
					withCaret={true}
				/>
			</Col>
			<Col md={8}>
				<Field
					component={Input}
					controlCursor={true}
					label="Package Quantity"
					name="packageQuantity"
					type="text"
				/>
			</Col>
			<Col md={8}>
				<Field
					component={Input}
					controlCursor={true}
					label="Vendor Price"
					min={0}
					name="vendorPrice"
					step={0.01}
					type="number"
				/>
			</Col>
			<Col md={24}>
				<Field
					component={Textarea}
					controlCursor={true}
					label="Notes"
					name="note"
					rows={3}
				/>
			</Col>
		</Row>
	);
};

export default Vendor;
