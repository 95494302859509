import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

import { splitByFilter } from 'ab-utils/array.util';
import { bemElement } from 'ab-utils/bem.util';

import { ReportBlockFormModel, ReportBlockFieldFormModel } from '../../../Shared/formModel';

interface OwnProps {
	isMain: boolean;
	onToggle: (id: string) => void;
	remove: (id: string) => void;
	reportBlock: ReportBlockFormModel;
	reportTypeBlockId: string;
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; };
	showDragIndicator?: boolean;	// TODO: check if needed
}

type Props = OwnProps;

const _isKeyParameter = (field: ReportBlockFieldFormModel) => field.isKeyParameter;
const _getFieldName = (field: ReportBlockFieldFormModel) => field.name;

const ReportTypeCustomTypeFormDraggableBlock: React.FC<Props> = (props: Props) => {
	const { reportBlock: { name, reportBlockFieldIds, uniqueId }, reportFieldsByIdMap, showDragIndicator, isMain, remove, onToggle, reportTypeBlockId } = props;

	const [expanded, setExpanded] = React.useState<boolean>(false);
	const [keyFields, setKeyFields] = React.useState<Nullable<string[]>>(null);
	const [plainFields, setPlainFields] = React.useState<Nullable<string[]>>(null);

	React.useEffect(() => {
		const fields = reportBlockFieldIds?.map((_id) => reportFieldsByIdMap[_id]);
		const {
			is: keyFieldsList,
			isNot: plainFieldsList,
		} = splitByFilter(fields ?? [], _isKeyParameter);
		setKeyFields(keyFieldsList.map(_getFieldName));
		setPlainFields(plainFieldsList.map(_getFieldName));
	}, [reportBlockFieldIds, reportFieldsByIdMap]);

	const handleRemoveClick = React.useCallback(() => {
		remove(reportTypeBlockId);
	}, [remove, reportTypeBlockId]);

	const toggle = React.useCallback(() => {
		setExpanded(!expanded);
		onToggle(reportTypeBlockId);
	}, [setExpanded, onToggle, expanded, reportTypeBlockId]);

	const labelOuterClassName = bemElement('report-block-form', 'draggable-label', { 'non-padded': true, expandable: true, main: isMain });
	const labelInnerClassName = bemElement('report-block-form', 'draggable-label', { stretched: true });
	const labelExpandedSegmentClassName = bemElement('report-block-form', 'draggable-label__expanded-segment');

	return (
		<>
			{showDragIndicator && <span className="icon-drag_indicator report-block-form__draggable-indicator" />}
			<div className={labelOuterClassName} onClick={toggle}>
				<div className={labelInnerClassName}>
					<div>
						<span>{name}</span> <span className="report-block-form__draggable-label__uniqueId">({uniqueId})</span>
					</div>
					<div className="report-block-form__expander">
						<span className="report-block-form__expander__label">MORE DETAILS</span>
						<span className={`icon-${expanded ? 'up' : 'down'}`} />
					</div>
				</div>
				{expanded && (
					<div className={labelExpandedSegmentClassName}>
						<b>{keyFields?.join(', ')}</b>
						{(!!keyFields?.length && !!plainFields?.length) && ', '}
						{plainFields?.join(', ')}
					</div>
				)}
			</div>
			<Tooltip message="Delete">
				<span className="icon-delete report-block-form__remove-icon" onClick={handleRemoveClick} />
			</Tooltip>
		</>
	);
};

export default React.memo(ReportTypeCustomTypeFormDraggableBlock);
