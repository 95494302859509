import * as React from 'react';

import { ColorPalette } from 'acceligent-shared/enums/color';

import Tooltip from 'af-components/Tooltip';
import ColorSquare from 'af-components/ColorSquare';

interface OwnProps {
	removedData: boolean;
	modifiedData: boolean;
	addedDataPopover: Nullable<JSX.Element>;
}

type Props = OwnProps;

class Changes extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		removedData: false,
		modifiedData: false,
		addedDataPopover: null,
	};

	render() {
		const { removedData, modifiedData, addedDataPopover } = this.props;

		return (
			<div className="work-order-info-modal__changes">
				{
					removedData &&
					<Tooltip message="Red color signifies that some data has been removed." placement="left">
						<span><ColorSquare color={ColorPalette.RED} /></span>
					</Tooltip>
				}
				{
					modifiedData &&
					<Tooltip message="Orange color signifies that data has been modified." placement="left">
						<span><ColorSquare color={ColorPalette.ORANGE} /></span>
					</Tooltip>
				}
				{
					addedDataPopover &&
					<Tooltip className="order-info-modal-added-data-tooltip" message={addedDataPopover} placement="left">
						<span><ColorSquare color={ColorPalette.GREEN} /></span>
					</Tooltip>
				}
				{(removedData || modifiedData || addedDataPopover) && <span className="icon-diff" />}
			</div>
		);
	}
}

export default Changes;
