import QuantityUnitType, { CompoundUnitEnum, PlainUnitEnum } from 'acceligent-shared/enums/quantityUnit';
import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';

import FieldReportWorkSummaryTable from 'ab-common/interfaces/domainEntities/views/fieldReportWorkSummaryTable';

import Address from 'acceligent-shared/models/address';

enum WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS {
	WORK = 'Work',
	TYPE = 'Type',
	DEFINITION_FIELD = 'Definition',
	QUANTITY = 'Quantity',
	UNIT = 'Unit',
	BILLING_CODE = 'Billing code',
	BILLING_GROUP = 'Billing group',
	UNIT_PRICE = 'Unit Price ($)',
	DESCRIPTION = 'Description',
	REVENUE = 'Revenue ($)'
}

export interface WorkSummaryDefinitionFieldVM {
	value: string;
	fieldName: string;
	fieldType: ReportBlockFieldEnum.Type;
	fieldUnit: Nullable<QuantityUnitType>;
}

function _resolveUnit(unit: QuantityUnitType) {
	switch (unit) {
		case CompoundUnitEnum.FT_IN: return PlainUnitEnum.IN;
		case CompoundUnitEnum.CFT_CIN: return PlainUnitEnum.CIN;
		case CompoundUnitEnum.HH_MM: return PlainUnitEnum.MIN;
		case CompoundUnitEnum.LFT_LIN: return PlainUnitEnum.LIN;
		case CompoundUnitEnum.SFT_SIN: return PlainUnitEnum.SIN;
		case CompoundUnitEnum.VFT_VIN: return PlainUnitEnum.VIN;
	}
	return unit;
}

function _resolveFieldValue(value: string, fieldType: ReportBlockFieldEnum.Type) {
	let resolvedValue = value;

	if (fieldType === ReportBlockFieldEnum.Type.BOOLEAN) {
		resolvedValue = new Boolean(value) ? 'Yes' : 'No';
	}

	if (fieldType === ReportBlockFieldEnum.Type.ADDRESS) {
		return (value as unknown as Address)?.street ?? null;
	}

	return resolvedValue;
}

class WorkSummaryVM {
	billableWorkId: number;
	billingCodeId: Nullable<number>;
	billingGroup: Nullable<string>;
	work: string;
	isBillable: boolean;
	quantity: number;
	quantityFieldName: string;
	quantityFieldType: ReportBlockFieldEnum.Type;
	total: Nullable<number>;
	unit: Nullable<QuantityUnitType>;
	type: string;
	typeFieldName: string;
	typeFieldType: ReportBlockFieldEnum.Type;
	definitionFields: WorkSummaryDefinitionFieldVM[];
	customerId: Nullable<string>;
	unitPrice: Nullable<number>;
	description: Nullable<string>;
	group: Nullable<string>;
	workSummaryGroup: Nullable<string>;
	isRecentlyUsed: boolean;

	constructor(workSummary: FieldReportWorkSummaryTable) {
		this.billableWorkId = workSummary.billableWorkId;
		this.billingCodeId = workSummary.billingCodeId;
		this.billingGroup = workSummary.group;
		this.work = workSummary.work;
		this.isBillable = workSummary.isBillable;
		this.quantity = +workSummary.quantity;
		this.quantityFieldName = workSummary.quantityFieldName;
		this.quantityFieldType = workSummary.quantityFieldType;
		this.total = workSummary.total ? +workSummary.total : null;
		this.unit = workSummary.unit ? _resolveUnit(workSummary.unit) : null;
		this.type = workSummary.type;
		this.typeFieldName = workSummary.typeFieldName;
		this.typeFieldType = workSummary.typeFieldType;
		this.definitionFields = [];
		for (let i = 0; i < workSummary.definitionFieldCount; i++) {
			const value = workSummary[`definition${i}`];
			this.definitionFields.push({
				value,
				fieldName: workSummary[`definition${i}FieldName`],
				fieldType: workSummary[`definition${i}FieldType`],
				fieldUnit: workSummary[`definition${i}FieldUnit`] ? _resolveUnit(workSummary[`definition${i}FieldUnit`] as QuantityUnitType) : null,
			});
		}

		this.customerId = workSummary.customerId;
		this.unitPrice = workSummary.unitPrice;
		this.description = workSummary.description;
		this.group = workSummary.group;
		this.workSummaryGroup = workSummary.groupName;
		this.isRecentlyUsed = workSummary.isRecentlyUsed && workSummary.isBillable;
	}

	private static _constructorMap = (_workSummary: FieldReportWorkSummaryTable) => new WorkSummaryVM(_workSummary);

	private static maxDefinitionFieldsReducer = (_max: number, _currentWS: WorkSummaryVM) => {
		if (_currentWS.definitionFields?.length > _max) {
			_max = _currentWS.definitionFields?.length;
		}

		return _max;
	};

	private static _sortWorkSummaryDefinitionFields =
		(a: WorkSummaryDefinitionFieldVM, b: WorkSummaryDefinitionFieldVM) => a.fieldName.localeCompare(b.fieldName);

	static resolveWorkSummaryBillableWorkTableHeaders = (maxNumberOfDefinitionFields: number) => {
		const headers: string[] = [];
		const headerTemplate = WORK_SUMMARY_BILLABLE_WORK_TABLE_HEADERS;
		const headerTemplateKeys = Object.keys(headerTemplate);
		for (const headerTemplateKey of headerTemplateKeys) {
			if (headerTemplate[headerTemplateKey] === headerTemplate.DEFINITION_FIELD) {
				for (let i = 0; i < maxNumberOfDefinitionFields; i++) {
					headers.push(`${headerTemplate[headerTemplateKey]} ${i + 1}`);
				}
			} else {
				headers.push(headerTemplate[headerTemplateKey]);
			}
		}

		return headers;
	};

	static bulkConstructor = (workSummaries: FieldReportWorkSummaryTable[]) => workSummaries.map(WorkSummaryVM._constructorMap);

	static toCSVData = (workSummaries: WorkSummaryVM[]): string[][] => {
		const maxNumberOfDefinitionFields = workSummaries.reduce(WorkSummaryVM.maxDefinitionFieldsReducer, 0);
		const headers = WorkSummaryVM.resolveWorkSummaryBillableWorkTableHeaders(maxNumberOfDefinitionFields);

		const rows = workSummaries.map((_ws) => {

			const adjustedDefFields: string[] = [];
			for (let i = 0; i < maxNumberOfDefinitionFields; i++) {
				const defFieldName = _ws.definitionFields[i]?.fieldName ? `${_ws.definitionFields[i]?.fieldName}:` : '';
				const defFieldValue = _ws.definitionFields[i] ? _resolveFieldValue(_ws.definitionFields[i].value, _ws.definitionFields[i].fieldType) : 'N/A';
				const defValue = `${defFieldName} ${defFieldValue}`;
				adjustedDefFields.push(defValue);
			}

			const workTypeAdjustedName = _ws.typeFieldType === ReportBlockFieldEnum.Type.NUMERIC_ATTRIBUTE
				? _ws.typeFieldName
				: `${_ws.typeFieldName}: ${_resolveFieldValue(_ws.type, _ws.typeFieldType)}`;

			return [
				_ws.work,
				_ws.type ? workTypeAdjustedName : 'N/A',
				...adjustedDefFields,
				_ws.quantity + '',
				_ws.unit ?? '',
				_ws.customerId ?? 'N/A',
				_ws.billingGroup ?? 'N/A',
				_ws.unitPrice ? (_ws.unitPrice + '') : 'N/A',
				_ws.description ?? 'N/A',
				_ws.total ? (_ws.total + '') : 'N/A',
			];
		});

		return [headers, ...rows];
	};
}

export default WorkSummaryVM;
