import * as React from 'react';
import { FormCheck } from 'react-bootstrap';

import Tooltip, {OwnProps as TooltipProps} from 'af-components/Tooltip';

export interface OwnProps {
	checked: boolean;
	onCheck: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> | void;
	label?: string;
	value?: string | number;
	inline?: boolean;
	disabled?: boolean;
	className?: string;
	tooltipMessage?: TooltipProps['message'];
}

type Props = OwnProps;

class Radio extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		disabled: false,
		inline: true,
		className: '',
	};

	onCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { onCheck } = this.props;

		if (onCheck) {
			onCheck(event);
		}
	};

	render() {
		const {
			disabled,
			inline,
			label,
			value,
			checked,
			className,
			tooltipMessage,
		} = this.props;

		let cn = 'radio-button';
		cn = className ? `${cn} ${className}` : cn;
		cn = disabled ? `${cn} radio-button--disabled` : cn;
		cn = !label ? `${cn} radio-button--no-label` : cn;

		return (
			<FormCheck
				className={cn}
				disabled={disabled}
				inline={inline}
				value={value}
			>
				<FormCheck.Label className="radio-button__label">
					<FormCheck.Input
						checked={checked}
						disabled={disabled}
						onChange={this.onCheck}
						type="radio"
					/>
					<span>
						{label}
						{tooltipMessage &&
							<Tooltip
								message={tooltipMessage}
								placement="top"
							>
								<span className="icon-help_fill" />
							</Tooltip>
						}
					</span>
				</FormCheck.Label>
			</FormCheck>
		);
	}
}

export default Radio as React.ComponentClass<OwnProps>;
