import * as React from 'react';

import CDLStatus from 'acceligent-shared/enums/cdlStatus';

import { DisplayViewEmployeeViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';
import ScheduleBoardEmployeeViewModel from 'ab-viewModels/scheduleBoardEmployee.viewModel';

import EmployeeAdditionalInfo from './EmployeeAdditionalInfo';

import * as ColorUtils from 'ab-utils/color.util';

interface OwnProps {
	employee: DisplayViewEmployeeViewModel;
}

type Props = OwnProps;

const UnavailableEmployeeItem: React.FC<Props> = (props: Props) => {
	const { employee } = props;
	const { unavailabilityReason, returnDate, officeColor, cdlStatus } = employee;
	const { firstName, lastName } = ScheduleBoardEmployeeViewModel.getDisplayData(employee as unknown as ScheduleBoardEmployeeViewModel);

	return (
		<div className="display-view__unavailable-item">
			<div className="display-view__card-and-date">
				<div className={`sb-resource-item sb-resource-item--employee ${cdlStatus === CDLStatus.NO_CDL ? 'sb-resource-item--no-cdl' : ''}`}>
					<div className="sb-resource-item__content">
						<span className={`sb-resource-item__full-name ${ColorUtils.getColorTextClass(officeColor, true)}`}>
							{firstName} <span className="sb-resource-item__last-name">{lastName}</span>
						</span>
						<EmployeeAdditionalInfo employee={employee} />
					</div>
				</div>
				<span className="display-view__card-and-date__date">{returnDate}</span>
			</div>
			<span className="display-view__card-and-date__reason">{unavailabilityReason}</span>
		</div>
	);
};

export default React.memo(UnavailableEmployeeItem);
