import * as React from 'react';

import CDLStatus from 'acceligent-shared/enums/cdlStatus';

import ScheduleBoardEmployeeViewModel from 'ab-viewModels/scheduleBoardEmployee.viewModel';

import Icon from 'af-components/Icon';

import SkillsColorGrid from 'af-components/SkillsColorGrid';

interface OwnProps {
	employee: ScheduleBoardEmployeeViewModel;
}

type Props = OwnProps;

export default (props: Props) => {
	const { employee } = props;
	const { skills, account, cdlStatus } = employee;

	if (account.assignableAsSuperintendent && account.assignableAsProjectManager) {
		return <Icon name="helmet_si_pm" pathNumber={5} />;
	} else if (account.assignableAsSuperintendent) {
		return <Icon name="helmet_s" pathNumber={5} />;
	} else if (account.assignableAsProjectManager) {
		return <Icon name="helmet_pm" pathNumber={5} />;
	} else {
		return <SkillsColorGrid enableCdlIndicator={cdlStatus === CDLStatus.CDL_A} skills={skills} />;
	}
};
