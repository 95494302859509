import * as React from 'react';

import { NotificationSnackbarTypes, NotificationActionTypes } from 'ab-enums/notificationSnackbarContext.enum';

import NotificationSnackbarList from 'af-components/NotificationSnackbarList';

import { notificationSnackbarReducer } from 'af-reducers/notificationSnackbarReducer';

interface NotificationData {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

export interface NotificationSnackbarContextType {
	notifications: NotificationData[];
	success: (content: string, date?: Date) => NotificationData;
	warning: (content: string, date?: Date) => NotificationData;
	info: (content: string, date?: Date) => NotificationData;
	error: (content: string, date?: Date) => NotificationData;
	loading: (content: string, date?: Date) => NotificationData;
	updateContent: (notification: NotificationData, content: string) => NotificationData;
	removeNotificationSnackbar: (notification: NotificationData) => NotificationData;
}

const emptyMessage = {} as NotificationData;

const defaultValue: NotificationSnackbarContextType = {
	notifications: [],
	success: () => { return emptyMessage; },
	warning: () => { return emptyMessage; },
	info: () => { return emptyMessage; },
	error: () => { return emptyMessage; },
	loading: () => { return emptyMessage; },
	updateContent: () => { return emptyMessage; },
	removeNotificationSnackbar: () => { return emptyMessage; },
};

export const NotificationSnackbarContext = React.createContext<NotificationSnackbarContextType>(defaultValue);

export const NotificationSnackbarContextProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(notificationSnackbarReducer, defaultValue);

	const addNotificationSnackbar = React.useCallback((type: NotificationSnackbarTypes, content: string, date?: Date) => {
		const id = Date.now();
		const notification = { id, content, type, date };
		dispatch({ type: NotificationActionTypes.ADD_NOTIFICATION, payload: notification });
		return notification;
	}, []);

	const removeNotificationSnackbar = React.useCallback((notification: NotificationData) => {
		dispatch({
			type: NotificationActionTypes.DELETE_NOTIFICATION, payload: notification,
		});
		return notification;
	}, []);

	const updateContent = React.useCallback((notification: NotificationData, content: string) => {
		dispatch({
			type: NotificationActionTypes.UPDATE_CONTENT, payload: { ...notification, content },
		});
		return notification;
	}, []);

	const success = React.useCallback((content: string, date?: Date) => {
		return addNotificationSnackbar(NotificationSnackbarTypes.SUCCESS, content, date);
	}, [addNotificationSnackbar]);

	const warning = React.useCallback((content: string, date?: Date) => {
		return addNotificationSnackbar(NotificationSnackbarTypes.WARNING, content, date);
	}, [addNotificationSnackbar]);

	const info = React.useCallback((content: string, date?: Date) => {
		return addNotificationSnackbar(NotificationSnackbarTypes.INFO, content, date);
	}, [addNotificationSnackbar]);

	const loading = React.useCallback((content: string, date?: Date) => {
		return addNotificationSnackbar(NotificationSnackbarTypes.LOADING, content, date);
	}, [addNotificationSnackbar]);

	const error = React.useCallback((content: string, date?: Date) => {
		return addNotificationSnackbar(NotificationSnackbarTypes.ERROR, content, date);
	}, [addNotificationSnackbar]);

	const value: NotificationSnackbarContextType = React.useMemo(() => ({
		notifications: state.notifications,
		success,
		warning,
		info,
		loading,
		error,
		updateContent,
		removeNotificationSnackbar,
	}), [
		error,
		info,
		loading,
		removeNotificationSnackbar,
		state.notifications,
		success,
		warning,
		updateContent,
	]);

	return (
		<NotificationSnackbarContext.Provider value={value} >
			<NotificationSnackbarList notifications={state.notifications} />
			{children}
		</NotificationSnackbarContext.Provider >
	);
};
