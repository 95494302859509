import * as React from 'react';
import { compose } from 'redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'af-reducers';

import * as AccountActions from 'af-actions/accounts';

import { ActiveVM } from 'ab-viewModels/account/active.viewModel';

import { AccountRM } from 'ab-requestModels/account/updateActive.requestModel';

import Breadcrumbs from 'af-components/Breadcrumbs';

import AccountPreview from './AccountPreview';
import AccountEdit from './AccountEdit';
import * as FormModel from './FormModel';

type OwnProps = CustomRouteComponentProps;
type Props = OwnProps & ConnectedProps<typeof connector>;

const AccountDetails: React.FC<Props> = (props) => {
	const { getAccountDetails, userData } = props;

	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [account, setAccount] = React.useState<Nullable<ActiveVM>>(null);
	const [initialValues, setInitialValues] = React.useState<AccountRM | undefined>(undefined);

	React.useEffect(() => {
		(async () => {
			const _account = await getAccountDetails();
			setAccount(_account);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		(async () => {
			account && setInitialValues(await FormModel.toRequestModel(account));
		})();
	}, [account]);

	const toggleEdit = React.useCallback(() => {
		setIsModalOpen(!isModalOpen);
	}, [isModalOpen]);

	const onSubmit = React.useCallback((data: ActiveVM) => {
		setAccount(data);
		setIsModalOpen(false);
	}, []);

	return (
		<div className="form-segment">
			<Breadcrumbs items={[{ label: 'Account' }]} />
			<AccountPreview accountDetails={account} toggleEdit={toggleEdit} userData={userData} />
			<AccountEdit
				initialValues={initialValues}
				isVisible={isModalOpen}
				onSubmit={onSubmit}
				toggleEdit={toggleEdit}
				userData={userData}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { userData } = state.user;
	if (!userData) {
		throw new Error('User not logged in');
	}

	return {
		userData,
	};
}

function mapDispatchToProps() {
	return {
		getAccountDetails: AccountActions.findActiveAccount,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector
);

export default enhance(AccountDetails);

