import * as React from 'react';
import { Field, FieldArray } from 'redux-form';

import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';

import { formSectionClass, formSectionClassColumn1, formSectionClassRow, formSectionTitleClass } from '../../../helpers';
import WorkRequestUpsertFM from '../../../formModel';
import styles from '../../../styles.module.scss';

import AdditionalTrucksAndEquipment from './AdditionalTrucksAndEquipment';

const TrucksAndEquipmentSection: React.FC = () => {
	const columnClassName = React.useMemo(() => [formSectionClassColumn1, styles['work-request-form__trucks-and-equipment__column']].join(' '), []);
	const fieldColumnClassName = React.useMemo(() => [styles['work-request-form__trucks-and-equipment__number-of-employees__field-column']].join(' '), []);
	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Trucks/Equipment
			</div>
			<div className={formSectionClassRow}>
				<div className={columnClassName}>
					<Field
						component={Checkbox}
						id="jc80gpm"
						label="JC (80 gpm)"
						name={WorkRequestUpsertFM.getAttributeName('jc80gpm')}
					/>
					<Field
						component={Checkbox}
						id="sjc80gpm"
						label="SJC (80 gpm)"
						name={WorkRequestUpsertFM.getAttributeName('sjc80gpm')}
					/>
					<Field
						component={Checkbox}
						id="jcRecycler80"
						label="JC Recycler (80 gpm)"
						name={WorkRequestUpsertFM.getAttributeName('jcRecycler80')}
					/>
					<Field
						component={Checkbox}
						id="jjc120gpm"
						label="JJC (120 gpm)"
						name={WorkRequestUpsertFM.getAttributeName('jjc120gpm')}
					/>
					<Field
						component={Checkbox}
						id="jjc160gpm"
						label="JJC (160 gpm)"
						name={WorkRequestUpsertFM.getAttributeName('jjc160gpm')}
					/>
					<Field
						component={Checkbox}
						id="jcHiRail"
						label="JC HiRail"
						name={WorkRequestUpsertFM.getAttributeName('jcHiRail')}
					/>
					<Field
						component={Checkbox}
						id="jetter"
						label="Jetter"
						name={WorkRequestUpsertFM.getAttributeName('jetter')}
					/>
					<Field
						component={Checkbox}
						id="harbenJetter"
						label="Harben Jetter"
						name={WorkRequestUpsertFM.getAttributeName('harbenJetter')}
					/>
				</div>
				<div className={columnClassName}>
					<Field
						component={Checkbox}
						id="hjJetter"
						label="HJ Jetter"
						name={WorkRequestUpsertFM.getAttributeName('hjJetter')}
					/>
					<Field
						component={Checkbox}
						id="tvTruck"
						label="TV Truck"
						name={WorkRequestUpsertFM.getAttributeName('tvTruck')}
					/>
					<Field
						component={Checkbox}
						id="tvLateralTk"
						label="TV Lateral Tk"
						name={WorkRequestUpsertFM.getAttributeName('tvLateralTk')}
					/>
					<Field
						component={Checkbox}
						id="tvGroutTk"
						label="TV Grout Tk"
						name={WorkRequestUpsertFM.getAttributeName('tvGroutTk')}
					/>
					<Field
						component={Checkbox}
						id="tvLatGroutTk"
						label="TV Lat Grout Tk"
						name={WorkRequestUpsertFM.getAttributeName('tvLatGroutTk')}
					/>
					<Field
						component={Checkbox}
						id="tvSonarTk"
						label="TV Sonar Tk"
						name={WorkRequestUpsertFM.getAttributeName('tvSonarTk')}
					/>
					<Field
						component={Checkbox}
						id="tvPolaris"
						label="TV Polaris"
						name={WorkRequestUpsertFM.getAttributeName('tvPolaris')}
					/>
					<Field
						component={Checkbox}
						id="tvLCRTk"
						label="TV LCR Tk"
						name={WorkRequestUpsertFM.getAttributeName('tvLCRTk')}
					/>
				</div>
				<div className={columnClassName}>
					<Field
						component={Checkbox}
						id="tvLateralReinstator"
						label="TV Lateral Reinstator"
						name={WorkRequestUpsertFM.getAttributeName('tvLateralReinstator')}
					/>
					<Field
						component={Checkbox}
						id="tvMTHTk"
						label="TV MTH Tk"
						name={WorkRequestUpsertFM.getAttributeName('tvMTHTk')}
					/>
					<Field
						component={Checkbox}
						id="tvLaserTk"
						label="TV Laser Tk"
						name={WorkRequestUpsertFM.getAttributeName('tvLaserTk')}
					/>
					<Field
						component={Checkbox}
						id="minicam"
						label="Minicam"
						name={WorkRequestUpsertFM.getAttributeName('minicam')}
					/>
					<Field
						component={Checkbox}
						id="vacTk"
						label="Vac Tk"
						name={WorkRequestUpsertFM.getAttributeName('vacTk')}
					/>
					<Field
						component={Checkbox}
						id="hivacTk"
						label="HiVac Tk"
						name={WorkRequestUpsertFM.getAttributeName('hivacTk')}
					/>
					<Field
						component={Checkbox}
						id="hiRailvacTk"
						label="HiRail Vac Tk"
						name={WorkRequestUpsertFM.getAttributeName('hiRailvacTk')}
					/>
					<Field
						component={Checkbox}
						id="tanker"
						label="Tanker"
						name={WorkRequestUpsertFM.getAttributeName('tanker')}
					/>
				</div>
				<div className={columnClassName}>
					<Field
						component={Checkbox}
						id="wb"
						label="WB"
						name={WorkRequestUpsertFM.getAttributeName('wb')}
					/>
					<Field
						component={Checkbox}
						id="hwBoiler"
						label="HW Boiler"
						name={WorkRequestUpsertFM.getAttributeName('hwBoiler')}
					/>
					<Field
						component={Checkbox}
						id="pressureWash"
						label="Pressure Wash"
						name={WorkRequestUpsertFM.getAttributeName('pressureWash')}
					/>
					<Field
						component={Checkbox}
						id="airComp"
						label="Air Compressor"
						name={WorkRequestUpsertFM.getAttributeName('airComp')}
					/>
					<Field
						component={Checkbox}
						id="clamTk"
						label="Clam Tk"
						name={WorkRequestUpsertFM.getAttributeName('clamTk')}
					/>
					<Field
						component={Checkbox}
						id="bucketMach"
						label="Bucket Mach"
						name={WorkRequestUpsertFM.getAttributeName('bucketMach')}
					/>
					<Field
						component={Checkbox}
						id="mhRehabTk"
						label="MH Rehab Tk"
						name={WorkRequestUpsertFM.getAttributeName('mhRehabTk')}
					/>
					<Field
						component={Checkbox}
						id="microtrax"
						label="Microtrax"
						name={WorkRequestUpsertFM.getAttributeName('microtrax')}
					/>
				</div>
				<div className={columnClassName}>
					<Field
						component={Checkbox}
						id="smallEasementMachine"
						label="Small Easement Machine"
						name={WorkRequestUpsertFM.getAttributeName('smallEasementMachine')}
					/>
					<Field
						component={Checkbox}
						id="bigEasementMachine"
						label="Big Easement Machine"
						name={WorkRequestUpsertFM.getAttributeName('bigEasementMachine')}
					/>
					<Field
						component={Checkbox}
						id="arrowBoard"
						label="Arrow Board"
						name={WorkRequestUpsertFM.getAttributeName('arrowBoard')}
					/>
					<Field
						component={Checkbox}
						id="supportTk"
						label="Support Tk"
						name={WorkRequestUpsertFM.getAttributeName('supportTk')}
					/>
					<Field
						component={Checkbox}
						id="rollOffTk"
						label="Roll Off Tk"
						name={WorkRequestUpsertFM.getAttributeName('rollOffTk')}
					/>
					<Field
						component={Checkbox}
						id="pump"
						label="Pump"
						name={WorkRequestUpsertFM.getAttributeName('pump')}
					/>
					<Field
						component={Checkbox}
						id="pigLaunch"
						label="Pig Launch"
						name={WorkRequestUpsertFM.getAttributeName('pigLaunch')}
					/>
					<Field
						component={Checkbox}
						id="stoveMach"
						label="Stove Mach"
						name={WorkRequestUpsertFM.getAttributeName('stoveMach')}
					/>
				</div>
				<div className={columnClassName}>
					<Field
						component={Checkbox}
						id="powerRodder"
						label="Power Rodder"
						name={WorkRequestUpsertFM.getAttributeName('powerRodder')}
					/>
					<Field
						component={Checkbox}
						id="reeferTruck"
						label="Reefer Truck"
						name={WorkRequestUpsertFM.getAttributeName('reeferTruck')}
					/>
					<Field
						component={Checkbox}
						id="lateralReinstatorTrailer"
						label="Lateral Reinstator Trailer"
						name={WorkRequestUpsertFM.getAttributeName('lateralReinstatorTrailer')}
					/>
					<Field
						component={Checkbox}
						id="steamCureTruck"
						label="Steam Cure Truck"
						name={WorkRequestUpsertFM.getAttributeName('steamCureTruck')}
					/>
					<Field
						component={Checkbox}
						id="airTesting"
						label="Air Testing"
						name={WorkRequestUpsertFM.getAttributeName('airTesting')}
					/>
					<Field
						component={Checkbox}
						id="trackMachine"
						label="Track Machine"
						name={WorkRequestUpsertFM.getAttributeName('trackMachine')}
					/>
					<FieldArray<void>
						component={AdditionalTrucksAndEquipment}
						name={WorkRequestUpsertFM.getAttributeName('additionalTruckEquipmentFields')}
					/>

				</div>
			</div>
			<div className={[formSectionClassRow, styles['work-request-form__trucks-and-equipment__number-of-employees']].join(' ')}>
				<div className={styles['work-request-form__trucks-and-equipment__number-of-employees__label']}>
					Number of People needed on Job:
				</div>
				<div className={fieldColumnClassName}>
					<Field
						className={styles['work-request-form__trucks-and-equipment__number-of-employees__field']}
						component={Input}
						id="numberOfEmployees"
						name={WorkRequestUpsertFM.getAttributeName('numberOfEmployees')}
						normalize={WorkRequestUpsertFM.normalizeNumberOfEmployees}
						step="1"
						type="number"
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(TrucksAndEquipmentSection);
