import * as React from 'react';

import { EquipmentDownRequestModel } from 'ab-requestModels/equipmentDown.requestModel';

import AvailableTable from './AvailableTable';
import UnavailableBoard from './UnavailableBoard';

interface OwnProps {
	onEquipmentDownEdit: (equipment: EquipmentDownRequestModel) => void;
}

type Props = OwnProps;

class Body extends React.PureComponent<Props> {
	render() {
		const { onEquipmentDownEdit } = this.props;

		return (
			<div className="mechanic-view-body">
				<div className="mechanic-view-body__available">
					<AvailableTable />
				</div>
				<UnavailableBoard onEquipmentDownEdit={onEquipmentDownEdit} />
			</div>
		);
	}
}

export default Body;
