import * as React from 'react';
import { CustomRouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';

import * as TimeUtils from 'acceligent-shared/utils/time';

import * as EquipmentActions from 'af-actions/equipment';

import TableNameEnum from 'ab-enums/tableName.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import TableComponent from 'af-components/Table6/Table';
import Table, { TabProps, Column } from 'af-components/Table6';
import Breadcrumbs from 'af-components/Breadcrumbs';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import StatusLabel from 'af-components/StatusLabel';
import LockedValue from 'af-components/LockedValue';
import DateFilter from 'af-components/DateFilter';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import Dropdown from 'af-components/Controls/Dropdown';

import * as SettingsKeys from 'af-constants/settingsKeys';
import { ALL_STATUSES_OPTION_ID } from 'ab-constants/value';

import CLIENT from 'af-routes/client';

import { RootState } from 'af-reducers';

import { EquipmentRequestModel } from 'ab-requestModels/equipment.requestModel';

import { EquipmentStatusHistoryTableViewModel } from 'ab-viewModels/equipmentStatusHistoryTable.viewModel';
import * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import { downloadCSV } from 'af-utils/csv.utils';
import * as SettingsUtils from 'af-utils/settings.util';
import { bemElement } from 'ab-utils/bem.util';

const ALL_STATUSES_OPTION: ResourceStatusesViewModel.Item = {
	name: 'All Statuses',
	id: ALL_STATUSES_OPTION_ID,
	index: ALL_STATUSES_OPTION_ID,
	available: false,
	isDeleted: false,
};

interface PathParams {
	id: string;
}

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	textFilter: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

const connector = connect(mapStateToProps, mapDispatchToProps());

type Props = OwnProps & SettingProps & ConnectedProps<typeof connector>;

interface State {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	textFilter: string;
	statuses: ResourceStatusesViewModel.Item[];
	filterByStatus: ResourceStatusesViewModel.Item;
	equipment: Nullable<EquipmentRequestModel>;
}

const setLocalStorageStartDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.EQUIPMENT_STATUS_HISTORY_START_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStorageEndDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.EQUIPMENT_STATUS_HISTORY_END_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStoragePeriod = (period: TimePeriodRecurrence) => {
	SettingsUtils.setItem(SettingsKeys.EQUIPMENT_STATUS_HISTORY_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
};

class EquipmentStatusHistory extends React.PureComponent<Props, State> {
	state: State = {
		startDate: this.props.startDate,
		endDate: this.props.endDate,
		period: this.props.period,
		textFilter: this.props.textFilter,
		statuses: [] as ResourceStatusesViewModel.Item[],
		filterByStatus: {} as ResourceStatusesViewModel.Item,
		equipment: null,
	};

	private _tableRef: Nullable<TableComponent<EquipmentStatusHistoryTableViewModel>> = null;

	columns: Column<EquipmentStatusHistoryTableViewModel>[] = [
		{
			Header: 'Status',
			sortable: false,
			accessor: 'equipmentStatus',
			Cell: ({ original }) => <StatusLabel isAvailable={original.status?.available ?? true} label={original.status?.name ?? 'Available'} statusLabelClassname="__bold" />,
		},
		{
			Header: 'Status Valid',
			accessor: 'statusValid',
			sortable: false,
			Cell: ({ original }) => (
				<div className="locked-input-one-row">
					<LockedValue label="from " labelClassName="locked-input-one-row__header" value={original.statusValidFrom} valueClassName="locked-input-one-row__content"></LockedValue>
					{
						original.statusValidTo &&
						<LockedValue label="to " labelClassName="locked-input-one-row__header" value={original.statusValidTo} valueClassName="locked-input-one-row__content"></LockedValue>
					}

				</div>
			),
		},
		{
			Header: 'Unavailability Reason',
			accessor: 'unavailabilityReason',
			sortable: false,
			Cell: ({ original }) => (original.status?.available ?? true) ? <EmptyCell /> : (original.unavailabilityReason ?? <EmptyCell message="None" />),
		},
		{
			Header: 'Failure details',
			accessor: 'failureDetails',
			sortable: false,
			Cell: ({ original }) => (original.status?.available ?? true) ? <EmptyCell /> : (original.failureDetails ?? <EmptyCell message="None" />),
		},
	];

	private _findStatusHistoryForEquipment: (
		table: TableQuery,
		startDate: Date,
		endDate: Date,
		filterByStatusId: number,
	) => Promise<TableViewModel<EquipmentStatusHistoryTableViewModel>>;

	constructor(props: Props) {
		super(props);
		const { findStatusHistoryForEquipment, match: { params: { id } } } = this.props;
		this._findStatusHistoryForEquipment = findStatusHistoryForEquipment.bind(this, id);
	}

	findAllForStatusHistoryTable = async (tableRequestModel: TableQuery) => {
		const { startDate, endDate, filterByStatus } = this.state;

		return await this._findStatusHistoryForEquipment(tableRequestModel, startDate, endDate, filterByStatus.id);
	};

	onTableMount = (table: TableComponent<EquipmentStatusHistoryTableViewModel>) => {
		this._tableRef = table;
	};

	refresh = () => {
		if (this._tableRef) {
			this._tableRef.refreshTable();
		}
	};

	filterByDate = (startDate: Date, endDate: Date) => {
		this.setState(
			() => ({ startDate, endDate }),
			() => {
				setLocalStorageStartDate(startDate);
				setLocalStorageEndDate(endDate);
				this.refresh();
			}
		);
	};

	changePeriod = (period: TimePeriodRecurrence, selected: Date) => {
		let startDate: Date = selected, endDate: Date = selected;

		switch (period) {
			case TimePeriodRecurrence.MONTHLY:
				startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(selected, 'start', 'month'),
					'start',
					'day'
				);
				endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(selected, 'start', 'week'),
					'start',
					'day'
				);
				endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				startDate = TimeUtils.positionDate(selected, 'start', 'day');
				endDate = TimeUtils.positionDate(selected, 'end', 'day');
			default:
				break;
		}

		this.setState(
			() => ({ period, startDate, endDate }),
			() => {
				setLocalStoragePeriod(period);
				setLocalStorageStartDate(startDate);
				setLocalStorageEndDate(endDate);
				this.refresh();
			}
		);
	};

	filterByStatus = async (option: ResourceStatusesViewModel.Item) => {
		this.setState(
			() => ({ filterByStatus: option || {} as ResourceStatusesViewModel.Item }),
			() => {
				if (this._tableRef) {
					this._tableRef.refreshTable();
				}
			}
		);
	};

	changeFilterText = (textFilter: string) => {
		this.setState(
			() => ({ textFilter }),
			() => {
				this.refresh();
			}
		);
	};

	onDownloadCSVClick = async () => {
		const {
			match: { params: { id } },
			companyData: { name: companyName },
		} = this.props;

		const tableRequestModel = new TableQuery({
			pageSize: 100,
			page: 0,
			sortBy: [{ id: 'date', desc: true }],
			filterByText: this.state.textFilter ?? '',
			includeAll: true,
		});
		const listViewModel = await this.findAllForStatusHistoryTable(tableRequestModel);
		downloadCSV(EquipmentStatusHistoryTableViewModel.toCSVData(listViewModel.rows), `${companyName}_equipment_${id}.csv`);
	};

	async componentDidMount() {
		const { findAllStatusesForEquipment, findEquipmentById, match: { params: { id } } } = this.props;
		const equipment = await findEquipmentById(id);
		const statuses = await findAllStatusesForEquipment(id);
		const extractedStatuses = [...statuses.available, ...statuses.unavailable];
		this.setState(() => ({ equipment, statuses: extractedStatuses }));
	}

	tabs = (): TabProps<EquipmentStatusHistoryTableViewModel>[] => {
		return [
			{
				label: 'Equipment Status History',
				columns: this.columns,
				buttons: [
					{
						type: TableButtonType.EXPORT,
						hasPermission: true,
						onClick: this.onDownloadCSVClick,
					},
				],
				hasSearchInput: true,
				searchLabel: 'Equipment Status History',
				additionalFilter: this.renderFilter,
				fetch: this.findAllForStatusHistoryTable,
			},
		];
	};

	breadcrumbs = () => {
		const { location: { state: { orgAlias } }, companyData: { name: companyName } } = this.props;
		const { equipment } = this.state;

		return [
			{ label: 'Equipment', url: CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName) },
			{ label: `Status History: ${equipment?.code}` },
		];
	};

	static renderStatusMenuItem = (optionItem: ResourceStatusesViewModel.Item) => {
		return !optionItem ? <></> : <div>{optionItem.name}</div>;
	};

	renderFilter = () => {
		const { endDate, startDate, period, statuses } = this.state;

		return (
			<div className="table-filter field-report-orders-table__filters">
				<div className={bemElement('table-filter', 'parameter', ['no-margin-top', 'margin-right'])}>
					<Dropdown<ResourceStatusesViewModel.Item>
						className="field-report-orders-table__filter-button"
						defaultValue={ALL_STATUSES_OPTION}
						id="equipment-statuses-history-filter"
						labelKey="name"
						onValueChange={this.filterByStatus}
						options={[ALL_STATUSES_OPTION, ...statuses]}
						renderMenuItem={EquipmentStatusHistory.renderStatusMenuItem}
						valueKey="id"
						withBorder={false}
					/>
					<RectangleButton
						action={undefined}
						isLeftOpen={true}
						isSquare={true}
						label={<span className="icon-filter" />}
					/>
				</div>
				<DateFilter
					changePeriod={this.changePeriod}
					endDate={endDate}
					onChange={this.filterByDate}
					period={period}
					startDate={startDate}
				/>
			</div>
		);
	};

	render() {
		const { textFilter } = this.state;

		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={this.breadcrumbs()} />
				<Table
					onFilterTextChange={this.changeFilterText}
					onMount={this.onTableMount}
					tableName={TableNameEnum.EQUIPMENT_STATUS_HISTORY}
					tabs={this.tabs()}
					textFilter={textFilter}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	if (!companyData || !userData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
		userData,
	};
}

function mapDispatchToProps() {
	return {
		findStatusHistoryForEquipment: EquipmentActions.findStatusHistoryForEquipment,
		findEquipmentById: EquipmentActions.findById,
		findAllStatusesForEquipment: EquipmentActions.findAllStatusesForEquipment,

	};
}

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormatEnum.FULL_DATE);
};
const enhance = compose<React.ComponentClass>(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.EQUIPMENT_STATUS_HISTORY_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.EQUIPMENT_STATUS_HISTORY_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.EQUIPMENT_STATUS_HISTORY_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	withRouter,
	connector
);
export default enhance(EquipmentStatusHistory);
