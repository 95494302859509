import * as bcrypt from 'bcryptjs';

// PASSWORD HASH LOGIC
//   all logic should be cointained inside the same file in case of change
//   in case of hash logic change, build and apply a migration

export const hashPassword = (password: string, numberOfRounds?: number): string =>
	bcrypt.hashSync(password, numberOfRounds && bcrypt.genSaltSync(numberOfRounds));

export const checkPassword = (nonHashedPassword: string, hashedPassword: string): boolean => bcrypt.compareSync(nonHashedPassword, hashedPassword);

// HASHING SIGNATURE

// hash signature string
export const encryptCustomerSignature = (signature: string, numberOfRounds?: number): string =>
	bcrypt.hashSync(signature, numberOfRounds && bcrypt.genSaltSync(numberOfRounds));

// compare signature and hashed signature
export const compareEncryptedSignature = (signature: string, hashedSignature: string): boolean => bcrypt.compareSync(signature, hashedSignature);
