import styles from './styles.module.scss';

export const formSectionClass = styles['job-form__tab-content__form-section'];
export const formSectionTitleClass = styles['job-form__tab-content__form-section__title'];
export const formSectionClassRow = styles['job-form__tab-content__form-section__row'];
export const formSectionClassColumn1 = styles['job-form__tab-content__form-section__row__column-1'];
export const formSectionClassColumn2 = styles['job-form__tab-content__form-section__row__column-2'];
export const formSectionClassColumn4 = styles['job-form__tab-content__form-section__row__column-4'];
export const formSectionClassColumn6 = styles['job-form__tab-content__form-section__row__column-6'];
export const formSectionClassColumn8 = styles['job-form__tab-content__form-section__row__column-8'];
export const formSectionDropdownItem = styles['job-form__contact-dropdown-item'];
export const formSectionDropdownItemMain = styles['job-form__contact-dropdown-item__main'];
export const formSectionDropdownItemSecondary = styles['job-form__contact-dropdown-item__secondary'];
