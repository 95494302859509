import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

export const wrapElementWithTooltip = (elementToWrap: JSX.Element | string, message: Nullable<string>) => {
	return !message
		? elementToWrap
		: (
			<Tooltip
				message={<b>{message}</b>}
				placement="top"
			>
				<span>{elementToWrap}</span>
			</Tooltip>
		);
};
