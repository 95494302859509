import * as React from 'react';

const Loading = () => {
	return (
		<div className="loading">
			<div className="schedule-board-card-wrapper columns-1">
				<div className="schedule-board-card">
					<div className="schedule-board-card-order-info">
						<div className="schedule-board-card-crew">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-supervisor">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-code">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-address">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
					</div>
					<div className="schedule-board-card-employees">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
					<div className="schedule-board-card-equipment">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
				</div>
			</div>
			<div className="schedule-board-card-wrapper columns-2">
				<div className="schedule-board-card">
					<div className="schedule-board-card-order-info">
						<div className="schedule-board-card-crew">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-supervisor">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-code">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-address">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
					</div>
					<div className="schedule-board-card-employees">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
					<div className="schedule-board-card-equipment">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
				</div>
			</div>
			<div className="schedule-board-card-wrapper columns-1">
				<div className="schedule-board-card">
					<div className="schedule-board-card-order-info">
						<div className="schedule-board-card-crew">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-supervisor">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-code">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-address">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
					</div>
					<div className="schedule-board-card-employees">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
					<div className="schedule-board-card-equipment">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
				</div>
			</div>
			<div className="schedule-board-card-wrapper columns-1">
				<div className="schedule-board-card">
					<div className="schedule-board-card-order-info">
						<div className="schedule-board-card-crew">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-supervisor">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-code">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-address">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
					</div>
					<div className="schedule-board-card-employees">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
					<div className="schedule-board-card-equipment">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
				</div>
			</div>
			<div className="schedule-board-card-wrapper columns-2">
				<div className="schedule-board-card">
					<div className="schedule-board-card-order-info">
						<div className="schedule-board-card-crew">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-supervisor">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-code">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-address">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
					</div>
					<div className="schedule-board-card-employees">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
					<div className="schedule-board-card-equipment">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
				</div>
			</div>
			<div className="schedule-board-card-wrapper columns-1">
				<div className="schedule-board-card">
					<div className="schedule-board-card-order-info">
						<div className="schedule-board-card-crew">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-supervisor">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-code">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
						<div className="schedule-board-card-address">
							<div className="loading-col-24 loading-box-row dark" />
						</div>
					</div>
					<div className="schedule-board-card-employees">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
					<div className="schedule-board-card-equipment">
						<div className="loading-col-24 loading-box-area dark" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Loading;
