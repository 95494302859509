import * as React from 'react';

class Loading extends React.PureComponent {

	render() {
		return (
			<div className="mechanic-view-body">
				<div className="mechanic-view-body__available">
					<div className="draggable-table">
						<div className="toolbar-group p-l">
							<div className="loading-box-row dark" />
							<div className="loading-box-row dark" />
							<div className="loading-box-row dark" />
							<div className="loading-box-row dark" />
							<div className="loading-box-row dark" />
						</div>
					</div>
				</div>
				<div className="mechanic-view-body__unavailable">
					<div className="mechanic-view-body__unavailable__list">
						<div className="unavailable-equipment-card-wrapper loading-col-8 loading-box-area dark" />
						<div className="unavailable-equipment-card-wrapper loading-col-8 loading-box-area dark" />
						<div className="unavailable-equipment-card-wrapper loading-col-8 loading-box-area dark" />
						<div className="unavailable-equipment-card-wrapper loading-col-8 loading-box-area dark" />
						<div className="unavailable-equipment-card-wrapper loading-col-8 loading-box-area dark" />
					</div>
				</div>
			</div>
		);
	}
}

export default Loading;
