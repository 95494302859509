import * as React from 'react';
import { Button } from 'react-bootstrap';

interface OwnProps {
	disabled: boolean;
	isPreviewInFocus: boolean;
	isPrimaryBlock: boolean;
	onEditClick: () => void;
	onPreviewClick: () => void;
	onSaveClick: () => void;
	onSaveAsClick: () => void;
	reportBlockName: string;
	showPreviewToggle: boolean;
	maxColumnspanBreached: boolean;
	hasIncompatibleFields: boolean;
}

type Props = OwnProps;

const ReportBlockFormHeader: React.FC<Props> = (props: Props) => {
	const {
		isPreviewInFocus,
		onEditClick,
		onPreviewClick,
		isPrimaryBlock,
		reportBlockName,
		showPreviewToggle,
		disabled,
		onSaveClick,
		onSaveAsClick,
		maxColumnspanBreached,
		hasIncompatibleFields,
	} = props;

	const headerSubtitle = isPrimaryBlock ? 'PRIMARY BLOCK' : 'SECONDARY BLOCK';

	const saveDisabled = disabled || maxColumnspanBreached || hasIncompatibleFields;

	let previewBtnClassName = 'btn btn-toggle';
	previewBtnClassName = isPreviewInFocus ? `${previewBtnClassName} active` : previewBtnClassName;

	return (
		<div className="report-block-form__fields-header">
			<div>
				<div className="report-block-form__header-title">{reportBlockName}</div>
				<div className="report-block-form__fields-header--subtitle">{headerSubtitle}</div>
			</div>
			<div className="report-block-form__header-buttons">
				{showPreviewToggle &&
					<a className={previewBtnClassName} onClick={onPreviewClick} role="button">
						<span className="icon-visibility_on" />
						Preview
					</a>
				}
				<Button
					onClick={onEditClick}
					variant="info"
				>
					Edit
				</Button>
				<Button
					onClick={onSaveAsClick}
					variant="info"
				>
					Save As
				</Button>
				<Button
					className="report-block-form__header-buttons__primary"
					disabled={saveDisabled}
					onClick={onSaveClick}
				>
					Save
				</Button>
			</div>
		</div>
	);
};

export default React.memo(ReportBlockFormHeader);
