import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

export default () => {
	return (
		<React.Fragment>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio standalone" sm={1}/><Col className="loading-box-row standalone" sm={23} /></Row>
			<Row className="m-0 p-m p-l-xl p-r-l"><Col className="loading-box-radio" sm={1}/><Col className="loading-box-row" sm={23} /></Row>
		</React.Fragment>
	);
};
