import { isValidTextInput } from 'acceligent-shared/utils/text';

import CrewTypeRequestModel from 'ab-requestModels/crewType.requestModel';

export function validate(values: CrewTypeRequestModel) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name)) {
		errors.name = 'Name is required';
	}

	if (!values.color) {
		errors.color = 'Color is required';
	}

	return errors;
}
