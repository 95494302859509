import { nanoid } from 'nanoid';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import QuantityUnit from 'acceligent-shared/enums/quantityUnit';
import OperationType, { MathOperations } from 'acceligent-shared/enums/operation';
import ReportBlockType from 'acceligent-shared/enums/reportBlockType';
import ReportTypeBlockType from 'acceligent-shared/enums/reportTypeBlockType';
import RepeatableBlockType from 'acceligent-shared/enums/repeatableBlockType';
import { ExtendedColorPalette } from 'acceligent-shared/enums/color';
import OperandType from 'acceligent-shared/enums/operand';

import * as ReportBlockRMs from 'ab-requestModels/reportBlock/reportBlock.requestModel';
import * as ReportTypeRMs from 'ab-requestModels/reportType/reportType.requestModel';

import * as ReportBlockMapVMs from 'ab-viewModels/reportBlock/reportBlockMap.viewModel';
import * as ReportTypeVMs from 'ab-viewModels/reportType/reportType.viewModel';

import { UNIQUE_ID_SIZE } from 'ab-constants/value';

/** Calculated Field Option Condition by Operation Type
*	1. Operation 'SUM' will only show numeric fields or Calculated Fields with operation 'SUM'
*	2. Operation 'COUNT_EMPTY' will show all fields
*	3. Operation 'COUNT_FILLED' will show all fields
*/
export const OPERATION_TYPE_FILTER: { [T in keyof typeof OperationType]: (option: ReportBlockFieldFormModel) => boolean; } = {
	[OperationType.SUM]: (option: ReportBlockFieldFormModel) => option.valueType === ReportBlockFieldEnum.ValueType.NUMBER
		|| (option.fieldType === ReportBlockFieldEnum.Type.CALCULATED && (!!option.operationType && MathOperations[option.operationType])),
	[OperationType.SUBTRACT]: (option: ReportBlockFieldFormModel) => option.valueType === ReportBlockFieldEnum.ValueType.NUMBER
		|| (option.fieldType === ReportBlockFieldEnum.Type.CALCULATED && (!!option.operationType && MathOperations[option.operationType])),
	[OperationType.MULTIPLY]: (option: ReportBlockFieldFormModel) => option.valueType === ReportBlockFieldEnum.ValueType.NUMBER
		|| (option.fieldType === ReportBlockFieldEnum.Type.CALCULATED && (!!option.operationType && MathOperations[option.operationType])),
	[OperationType.DIVIDE]: (option: ReportBlockFieldFormModel) => option.valueType === ReportBlockFieldEnum.ValueType.NUMBER
		|| (option.fieldType === ReportBlockFieldEnum.Type.CALCULATED && (!!option.operationType && MathOperations[option.operationType])),
	[OperationType.COUNT]: (option: ReportBlockFieldFormModel) => option === option,
	[OperationType.COUNT_EMPTY]: (option: ReportBlockFieldFormModel) => option === option,
	[OperationType.COUNT_FILLED]: (option: ReportBlockFieldFormModel) => option === option,
};

export interface BlockDropdownSection<T> {
	name: string;
	blockId?: number;
	segment?: number;
	isPrimary?: boolean;
	fields: T[];
}

export class CalculatedFieldOptionFormModel {
	id: Nullable<string>;
	constant: Nullable<number>;
	type: OperandType;
	index: number;
	isOperandBlockInPrimarySegment: Nullable<boolean>;

	private static _toReportTypeRM = (option: CalculatedFieldOptionFormModel): ReportTypeRMs.CalculatedFieldOptionRM => {
		if (!option.id) {
			throw new Error('Calculated field lookup is missing operand id');
		}
		return {
			id: option.id,
			type: option.type,
			constant: option.constant,
			index: option.index,
			isOperandBlockInPrimarySegment: option.isOperandBlockInPrimarySegment,
		};
	};

	private static _toReportBlockRM = (option: CalculatedFieldOptionFormModel): ReportBlockRMs.CalculatedFieldOptionRM => {
		if (!option.id) {
			throw new Error('Calculated field lookup is missing operand id');
		}
		return {
			id: option.id,
			type: option.type,
			constant: option.constant,
			index: option.index,
		};
	};

	private static _fromReportTypeVM = (option: ReportTypeVMs.CalculatedFieldOptionsVM): CalculatedFieldOptionFormModel => {
		return {
			id: option.virtualId,
			constant: option.constant,
			type: option.type,
			index: option.index,
			isOperandBlockInPrimarySegment: option.isOperandBlockInPrimarySegment,
		};
	};

	private static _fromReportBlockMapVM = (
		option: ReportBlockMapVMs.CalculatedFieldOptionsVM,
		oldToVirtualIdMap: { [id: number]: string; }
	): CalculatedFieldOptionFormModel => {
		let id = nanoid(UNIQUE_ID_SIZE);
		if (option.operandFieldId) {
			id = oldToVirtualIdMap[option.operandFieldId];
		}
		return {
			id,
			constant: option.constant,
			type: option.type,
			index: option.index,
			isOperandBlockInPrimarySegment: null,
		};
	};

	private static _fromReportBlockRM = (option: ReportBlockRMs.CalculatedFieldOptionRM): CalculatedFieldOptionFormModel => {
		return {
			id: option.id,
			constant: option.constant,
			type: option.type,
			index: option.index,
			isOperandBlockInPrimarySegment: null,
		};
	};

	static bulkToReportBlockRM = (options: CalculatedFieldOptionFormModel[]): ReportBlockRMs.CalculatedFieldOptionRM[] => {
		return options.map(CalculatedFieldOptionFormModel._toReportBlockRM);
	};

	static bulkToReportTypeRM = (options: CalculatedFieldOptionFormModel[]): ReportTypeRMs.CalculatedFieldOptionRM[] => {
		return options.map(CalculatedFieldOptionFormModel._toReportTypeRM);
	};

	static bulkFromReportTypeVM = (options: ReportTypeVMs.CalculatedFieldOptionsVM[]): CalculatedFieldOptionFormModel[] => {
		return options.map(CalculatedFieldOptionFormModel._fromReportTypeVM);
	};

	static bulkFromReportBlockMapVM = (
		options: ReportBlockMapVMs.CalculatedFieldOptionsVM[],
		oldToVirtualIdMap: { [id: number]: string; }
	): CalculatedFieldOptionFormModel[] => {
		return options.map((_option) => CalculatedFieldOptionFormModel._fromReportBlockMapVM(_option, oldToVirtualIdMap));
	};

	static bulkFromReportBlockRM = (options: ReportBlockRMs.CalculatedFieldOptionRM[]): CalculatedFieldOptionFormModel[] => {
		return options.map(CalculatedFieldOptionFormModel._fromReportBlockRM);
	};
}

export class ReportBlockFieldFormModel {
	id?: number;
	virtualId: string;
	blockId?: Nullable<string>;
	reportBlockVirtualId: string;
	name: string;
	valueType: ReportBlockFieldEnum.ValueType;
	unit: Nullable<QuantityUnit>;
	defaultValue: Nullable<string>;
	imageFile: Nullable<File>;
	dimension: ReportBlockFieldEnum.Dimension;
	isKeyParameter: boolean;
	isRequired: boolean;
	isUnique: boolean;
	fieldType: ReportBlockFieldEnum.Type;
	names?: Nullable<string[]>;
	isVisibleToCustomer: boolean;
	hasTooltip: boolean;
	tooltipText?: Nullable<string>;
	options?: string[];
	allowCustomDropdownListValue?: boolean;
	operationType: Nullable<OperationType>;
	calculatedFieldOptions: Nullable<CalculatedFieldOptionFormModel[]>;
	isDescriptiveTextBold: boolean;
	descriptiveTextColor?: ExtendedColorPalette;
	icon: Nullable<string>;

	private static _fromReportBlockRM = (field: ReportBlockRMs.ReportBlockFieldRM, reportBlockVirtualId: string): ReportBlockFieldFormModel => {
		return {
			id: field.id,
			virtualId: field.virtualId,
			reportBlockVirtualId: reportBlockVirtualId,
			name: field.name,
			valueType: field.valueType,
			unit: field.unit,
			defaultValue: field.defaultValue,
			imageFile: null,
			dimension: field.dimension,
			isKeyParameter: field.isKeyParameter,
			isRequired: field.isRequired,
			isUnique: field.isUnique,
			fieldType: field.fieldType,
			names: null,
			isVisibleToCustomer: field.isVisibleToCustomer,
			hasTooltip: field.hasTooltip,
			tooltipText: field?.tooltipText,
			options: field?.options ?? [],
			allowCustomDropdownListValue: field?.allowCustomDropdownListValue ?? false,
			operationType: field?.operationType ?? null,
			calculatedFieldOptions: field.calculatedFieldOptions
				? CalculatedFieldOptionFormModel.bulkFromReportBlockRM(field.calculatedFieldOptions)
				: null,
			isDescriptiveTextBold: field.isDescriptiveTextBold,
			descriptiveTextColor: field.descriptiveTextColor ?? undefined,
			icon: field.icon,
		};
	};

	static toReportTypeRM = (field: ReportBlockFieldFormModel, index: Nullable<number>): ReportTypeRMs.ReportBlockFieldRM => {
		return {
			id: field.id,
			virtualId: field.virtualId,
			name: field.name,
			index,
			valueType: field.valueType,
			unit: field.unit,
			dimension: field.dimension,
			fieldType: field.fieldType,
			isVisibleToCustomer: field.isVisibleToCustomer,
			hasTooltip: field.hasTooltip,
			tooltipText: field.tooltipText,
			operationType: field.operationType,
			calculatedFieldOptions: field.calculatedFieldOptions
				? CalculatedFieldOptionFormModel.bulkToReportTypeRM(field.calculatedFieldOptions)
				: null,
		};
	};

	static toReportBlockRM = (field: ReportBlockFieldFormModel, index: Nullable<number>): ReportBlockRMs.ReportBlockFieldRM => {
		return {
			id: field.id,
			virtualId: field.virtualId,
			name: field.name,
			index,
			valueType: field.valueType,
			icon: field.icon,
			unit: field.unit,
			dimension: field.dimension,
			fieldType: field.fieldType,
			defaultValue: field.defaultValue,
			isKeyParameter: field.isKeyParameter,
			isRequired: field.isRequired,
			isUnique: field.isUnique,
			isDescriptiveTextBold: field.isDescriptiveTextBold,
			isVisibleToCustomer: field.isVisibleToCustomer,
			hasTooltip: field.hasTooltip,
			tooltipText: field.tooltipText,
			options: field.options,
			descriptiveTextColor: field.descriptiveTextColor,
			allowCustomDropdownListValue: field.allowCustomDropdownListValue,
			operationType: field.operationType,
			calculatedFieldOptions: field.calculatedFieldOptions
				? CalculatedFieldOptionFormModel.bulkToReportBlockRM(field.calculatedFieldOptions)
				: null,
		};
	};

	static fromReportTypeVM = (field: ReportTypeVMs.ReportBlockFieldVM): ReportBlockFieldFormModel => {
		return {
			id: field.id,
			virtualId: field.virtualId,
			reportBlockVirtualId: field.reportBlockVirtualId,
			name: field.name,
			icon: field.icon,
			valueType: field.valueType,
			unit: field.unit,
			defaultValue: field.defaultValue,
			imageFile: null,
			dimension: field.dimension,
			isKeyParameter: field.isKeyParameter,
			isRequired: field.isRequired,
			isUnique: field.isUnique,
			fieldType: field.fieldType,
			names: null,
			isVisibleToCustomer: field.isVisibleToCustomer,
			hasTooltip: field.hasTooltip,
			tooltipText: field?.tooltipText,
			options: field?.options ?? [],
			allowCustomDropdownListValue: field?.allowCustomDropdownListValue,
			operationType: field?.operationType,
			calculatedFieldOptions: field.calculatedFieldOptions
				? CalculatedFieldOptionFormModel.bulkFromReportTypeVM(field.calculatedFieldOptions)
				: null,
			isDescriptiveTextBold: field.isDescriptiveTextBold,
			descriptiveTextColor: field.descriptiveTextColor ?? undefined,
		};
	};

	static fromReportBlockMapVM = (
		field: ReportBlockMapVMs.ReportBlockFieldVM,
		reportBlockVirtualId: string,
		oldToVirtualIdMap: { [id: number]: string; }
	): ReportBlockFieldFormModel => {
		return {
			id: field.id,
			virtualId: oldToVirtualIdMap[field.id],
			reportBlockVirtualId,
			name: field.name,
			icon: field.icon,
			valueType: field.valueType,
			unit: field.unit,
			defaultValue: field.defaultValue,
			imageFile: null,
			dimension: field.dimension,
			isKeyParameter: field.isKeyParameter,
			isRequired: field.isRequired,
			isUnique: field.isUnique,
			fieldType: field.fieldType,
			names: null,
			isVisibleToCustomer: field.isVisibleToCustomer,
			hasTooltip: field.hasTooltip,
			tooltipText: field?.tooltipText,
			options: field?.options ?? [],
			allowCustomDropdownListValue: field?.allowCustomDropdownListValue,
			operationType: field?.operationType,
			calculatedFieldOptions: field.calculatedFieldOptions
				? CalculatedFieldOptionFormModel.bulkFromReportBlockMapVM(field.calculatedFieldOptions, oldToVirtualIdMap)
				: null,
			isDescriptiveTextBold: field.isDescriptiveTextBold,
			descriptiveTextColor: field.descriptiveTextColor ?? undefined,
		};
	};

	static bulkFromReportBlockRM = (fields: ReportBlockRMs.ReportBlockFieldRM[], reportBlockVirtualId: string) => {
		return fields.map((_field) => ReportBlockFieldFormModel._fromReportBlockRM(_field, reportBlockVirtualId));
	};

	static bulkToReportBlockRM = (fields: ReportBlockFieldFormModel[]): ReportBlockRMs.ReportBlockFieldRM[] => {
		return fields.map(ReportBlockFieldFormModel.toReportBlockRM);
	};
}

export class ReportBlockFormModel {
	id?: number;
	virtualId: string;
	name: string;
	uniqueId: string;
	hasCompletionStatus: boolean;
	completionFieldVirtualId: Nullable<string>;
	isMain: boolean;
	isRepeating: boolean;
	repeatableBlockType?: Nullable<RepeatableBlockType>;
	reportBlockFieldIds: Nullable<string[]>;
	type: Nullable<ReportBlockType>;

	static fromListViewModel = (block: ReportBlockMapVMs.ReportBlockVM): ReportBlockFormModel => {
		return {
			id: block.id,
			virtualId: nanoid(UNIQUE_ID_SIZE),
			name: block.name,
			uniqueId: block.uniqueId,
			hasCompletionStatus: !!block.completionReportBlockFieldId,
			completionFieldVirtualId: null, // Will be set later
			isMain: block.isMain,
			isRepeating: block.isRepeating,
			reportBlockFieldIds: null, // Will be set later
			type: block.type,
			repeatableBlockType: block.repeatableBlockType,
		};
	};

	static fromReportTypeViewModel = (block: ReportTypeVMs.ReportBlockVM): ReportBlockFormModel => {
		return {
			id: block.id,
			virtualId: block.virtualId,
			name: block.name,
			uniqueId: block.uniqueId,
			hasCompletionStatus: !!block.completionReportBlockFieldId,
			completionFieldVirtualId: block.completionReportBlockFieldVirtualId,
			isMain: block.isMain,
			isRepeating: block.isRepeating,
			reportBlockFieldIds: block.reportBlockFieldIds,
			type: block.type,
			repeatableBlockType: block.repeatableBlockType,
		};
	};

	static fromReportBlockRequestModel = (block: ReportBlockRMs.ReportBlockRM): ReportBlockFormModel => {
		return {
			id: block.id,
			virtualId: block.virtualId,
			name: block.name,
			uniqueId: block.uniqueId,
			isMain: block.isMain,
			isRepeating: block.isRepeating,
			hasCompletionStatus: !!block.completionFieldVirtualId,
			completionFieldVirtualId: block.completionFieldVirtualId,
			reportBlockFieldIds: null,
			type: null,
			repeatableBlockType: block.repeatableBlockType,
		};
	};

	static toRequestModel = (block: ReportBlockFormModel): ReportBlockRMs.ReportBlockRM => {
		return {
			id: block.id,
			virtualId: block.virtualId,
			name: block.name,
			isMain: block.isMain,
			uniqueId: block.uniqueId,
			isRepeating: block.isRepeating,
			completionFieldVirtualId: block.completionFieldVirtualId,
			repeatableBlockType: block.repeatableBlockType ?? null,
		};
	};
}

export class ReportTypeBlockFormModel {
	id?: number;
	virtualId: string;
	reportTypeId: Nullable<number>;
	reportBlockId: Nullable<number>;
	reportBlockVirtualId: string;
	isPrimary: boolean;
	type: ReportTypeBlockType;

	static fromReportTypeVM = (reportTypeBlock: ReportTypeVMs.ReportTypeBlockVM): ReportTypeBlockFormModel => {
		return {
			virtualId: reportTypeBlock.virtualId,
			id: reportTypeBlock.id,
			reportTypeId: reportTypeBlock.reportTypeId,
			reportBlockId: reportTypeBlock.reportBlockId,
			reportBlockVirtualId: reportTypeBlock.reportBlockVirtualId,
			isPrimary: reportTypeBlock.isPrimary,
			type: reportTypeBlock.type,
		};
	};
}
