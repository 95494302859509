import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import { bemElement } from 'ab-utils/bem.util';

import LockedValue from 'af-components/LockedValue';

import { TimeSplitEntryVM } from 'acceligent-shared/dtos/web/view/timeSheet/timeSheet';

import ErrorMessage from './ErrorMessage';
import TimeSplitTimes from './TimeSplitTimes';
import TimeSplitEquipment from './TimeSplitEquipment';
import TimeSplitClassificationCodes from './TimeSplitClassificationCodes';

interface OwnProps {
	isManagerOrAdmin: boolean;
	isSuperintendent: boolean;
	showEquipmentActionButton: boolean;
	showEquipmentTimeError: boolean;
	timeSplitEntries: TimeSplitEntryVM[];
	totalJobDuration: number;
	showEquipmentModal?: () => void;
}

const TimeSheetTimeSplits = (props: OwnProps) => {
	const {
		isManagerOrAdmin,
		isSuperintendent,
		showEquipmentTimeError,
		timeSplitEntries,
	} = props;

	const hasTimeSplitEntries = !!timeSplitEntries && timeSplitEntries.length > 0;

	if (!hasTimeSplitEntries) {
		return <></>;
	}

	const rowClassName = bemElement('time-sheet-list', 'row__info');

	return (
		<div className={bemElement(rowClassName, 'time-split-table-container', { error: showEquipmentTimeError })}>
			{showEquipmentTimeError && <ErrorMessage />}
			<Row>
				<Col sm={24}>
					<div className="time-sheet-list__row__info__time-split-table">
						<div className="time-sheet-list__row__info__time-split-table-left-element">
							<LockedValue
								label="Time"
								value={<TimeSplitTimes timeSplitEntries={timeSplitEntries} />}
							/>
						</div>
						<div className="time-sheet-list__row__info__time-split-table-left-element">
							<LockedValue
								label="Equipment"
								value={<TimeSplitEquipment timeSplitEntries={timeSplitEntries} />}
							/>
						</div>
						<div className="time-sheet-list__row__info__time-split-table-right-element">
							{(isSuperintendent || isManagerOrAdmin) &&
								<LockedValue
									label="Classification Code"
									value={<TimeSplitClassificationCodes timeSplitEntries={timeSplitEntries} />}
								/>
							}
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default React.memo(TimeSheetTimeSplits);
