import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';

export class InvoiceStatusFilterItem {
	static DEFAULT_ALL: InvoiceStatusFilterItem = Object.freeze({
		id: -1,
		name: 'All Statuses',
		index: 0,
	});

	static STATUSES: InvoiceStatusFilterItem[] = [{
		id: 0,
		name: InvoiceStatusDisplay.DRAFT,
		index: 1,
	},
	{
		id: 1,
		name: InvoiceStatusDisplay.INVOICED,
		index: 2,
	},
	{
		id: 2,
		name: InvoiceStatusDisplay.PARTIALLY_PAID,
		index: 3,
	},
	{
		id: 3,
		name: InvoiceStatusDisplay.PAID,
		index: 4,
	},
	{
		id: 4,
		name: InvoiceStatusDisplay.OVERPAID,
		index: 5,
	},
	];

	id: number;
	name: InvoiceStatusDisplay | string;
	/** 1-based index */
	index: number;

	constructor(status: InvoiceStatusDisplay, index) {
		this.id = index - 1;
		this.name = status;
		this.index = index;
	}
}
