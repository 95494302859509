import * as React from 'react';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import SegmentLabel from 'af-components/SegmentLabel';

import * as BemUtil from 'ab-utils/bem.util';

import { ItemCreateRM, ItemDeleteRM } from 'ab-requestModels/resources/resourceStatusesUpdate.requestModel';

import ResourceStatusCreateForm from './CreateForm';
import ResourceStatusList from './List';

import { Props, State, CreateFormModel, OrderChangedFormModel, RequestModel } from './types';
import { mapViewModelToState, validateCreateAgainstState, StateActionCreators } from './helpers';

const INFO_SEGMENT_CLASS_NAME = BemUtil.bemElement('company-settings', 'info-segment');
const STATUSES_CONTAINER_CLASS_NAME = BemUtil.bemElement(INFO_SEGMENT_CLASS_NAME, 'row', ['stretched']);
const STATUSES_CONTAINER_ITEM_CLASS_NAME = BemUtil.bemElement(INFO_SEGMENT_CLASS_NAME, 'item');

class ResourceStatusesModal extends React.Component<Props, State> {
	static DEFAULT_STATE: State = {
		statuses: { available: [], unavailable: [] },
		forCreateLookup: {},
		forDeleteLookup: {},
		isSubmitting: false,
	};

	state: State = ResourceStatusesModal.DEFAULT_STATE;

	componentDidUpdate(prevProps: Props) {
		const { getStatuses, isVisible } = this.props;
		const hasOpenedOnThisUpdate = !prevProps.isVisible && isVisible;
		const hasClosedOnThisUpdate = !hasOpenedOnThisUpdate && prevProps.isVisible && !isVisible;
		if (hasOpenedOnThisUpdate && getStatuses) {
			const statuses = mapViewModelToState(getStatuses());
			this.setState(() => ({ statuses }));
		} else if (hasClosedOnThisUpdate) {
			this.setState(() => ResourceStatusesModal.DEFAULT_STATE);
		}
	}

	handleSubmit = () => {
		const { close, submit } = this.props;
		const { statuses, forCreateLookup, forDeleteLookup, isSubmitting } = this.state;
		if (isSubmitting) {
			return;	// this should never happen
		}
		this.setState(() => ({ isSubmitting: true }), async () => {
			const data: RequestModel = {
				available: statuses.available,
				unavailable: statuses.unavailable,
				forCreateList: Object.values(forCreateLookup).filter((_item): _item is NonNullable<ItemCreateRM> => !!_item),
				forDeleteList: Object.values(forDeleteLookup).filter((_item): _item is NonNullable<ItemDeleteRM> => !!_item),
			};
			await submit(data);
			this.setState(() => ({ isSubmitting: false }), close);
		});
	};

	createStatus = (form: CreateFormModel) => {
		validateCreateAgainstState(form, this.state);
		this.setState(StateActionCreators.createStatus(form));
	};

	handleOrderChange = (form: OrderChangedFormModel) => this.setState(StateActionCreators.moveStatus(form));

	deleteStatus = (available: boolean, name: string) => this.setState(StateActionCreators.deleteStatus(available, name));

	render() {
		const { close, isVisible } = this.props;
		const { statuses, isSubmitting } = this.state;

		return (
			<CustomModal
				closeModal={close}
				modalStyle="info"
				showModal={isVisible}
				size="md"
			>
				<CustomModal.Header
					closeModal={close}
					title="Manage Statuses"
				/>
				<CustomModal.Body padding="all-but-top">
					<ResourceStatusCreateForm createStatus={this.createStatus} />
					<hr />
					<div className="p-0 company-settings">
						<SegmentLabel label="Reorder Statuses" />
						<div className={STATUSES_CONTAINER_CLASS_NAME}>
							<div className={STATUSES_CONTAINER_ITEM_CLASS_NAME}>
								<ResourceStatusList
									available={true}
									deleteStatus={this.deleteStatus}
									iconStatus="complete"
									items={statuses.available}
									label="Available"
									onOrderChange={this.handleOrderChange}
								/>
							</div>
							<div className={STATUSES_CONTAINER_ITEM_CLASS_NAME}>
								<ResourceStatusList
									available={false}
									deleteStatus={this.deleteStatus}
									iconStatus="incomplete"
									items={statuses.unavailable}
									label="Unavailable"
									onOrderChange={this.handleOrderChange}
								/>
							</div>
						</div>
					</div>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={close}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						label="Submit"
						onClick={this.handleSubmit}
						reduxFormSubmitting={isSubmitting}
						variant="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default ResourceStatusesModal;
