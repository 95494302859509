/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { getCustomerSupportUniqueId } from 'af-utils/settings.util';

interface Props {
	appId: string;
	displayName: string;
	email: string;
	onShow: () => void;
	onHide: () => void;
	hideDefaultLauncher?: boolean;
	color: 'orange' | 'grey';
}

interface State {
	isVisible: boolean;
}

declare const window;
declare const Intercom;

export default class IntercomInboxButton extends React.Component<Props, State> {

	state = {
		isVisible: false,
		hideDefaultLauncher: true,
	};

	componentDidMount() {
		const { displayName, email, appId, onShow, onHide, hideDefaultLauncher } = this.props;

		window.intercomSettings = {
			app_id: appId,
			name: displayName,
			created_at: Date.now(),
			email: email,
			user_id: getCustomerSupportUniqueId(),
			hide_default_launcher: hideDefaultLauncher,
			custom_launcher_selector: 'acceligent-intercom-luncher',
		};

		(function () {
			var w = window;
			var ic = w.Intercom;

			if (typeof ic === 'function') {
				ic('reattach_activator');
				ic('update', w.intercomSettings);
			} else {
				var i = function () {
					// eslint-disable-next-line prefer-rest-params
					(i as any).c(arguments);
				};
				(i as any).q = [];
				(i as any).c = function (args) {
					(i as any).q.push(args);
				};
				w.Intercom = i;
				var l = function () {
					var d = document;
					var s = d.createElement('script');
					s.type = 'text/javascript';
					s.async = true;
					s.src = `https://widget.intercom.io/widget/${appId}`;
					var x = d.getElementsByTagName('script')[0];
					x.parentNode?.insertBefore(s, x);
				};

				l();
			}
		})();

		Intercom('onShow', onShow);
		Intercom('onHide', onHide);
	}

	toggleInbox = () => {
		const { isVisible } = this.state;
		this.setState({ isVisible: !isVisible }, () => {
			Intercom(isVisible ? 'hide' : 'show');
		});
	};

	render() {
		const { color, hideDefaultLauncher } = this.props;

		return hideDefaultLauncher && (
			<a
				id="acceligent-intercom-luncher"
				onClick={this.toggleInbox}
			>
				<span className={`sidenav-icon icon-chat text-${color}`} />
				Customer Support
			</a>
		);
	}
}
