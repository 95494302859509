import * as React from 'react';

import TimeSheetSignatureStatus from 'acceligent-shared/enums/timeSheetSignatureStatus';

import { TimeSheetInfoVM, TimeSheetEntryInfoVM } from 'acceligent-shared/dtos/web/view/workOrder/submitForReviewRecap';

import SignatureStatusLabel from 'af-root/scenes/Company/FieldReports/Shared/SignatureStatusLabel';

import { useToggle } from 'af-utils/react.util';

import TimeSheet from './TimeSheet';
import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	timeSheet: TimeSheetInfoVM;
	timeSheetEntries: TimeSheetEntryInfoVM[];
	timezone: Nullable<string>;
}

type Props = OwnProps;

const TimeSheetEmployee: React.FC<Props> = (props) => {
	const { timeSheet, timeSheetEntries, timezone } = props;

	const { value: isExpanded, toggle: toggleExpanded } = useToggle(false);

	const itemClassName = bemElement('report-submit-modal__section', 'list-item', {
		expanded: isExpanded,
	});

	const signatureStatus = timeSheet.isSignedBySI
		? TimeSheetSignatureStatus.SIGNED
		: timeSheet.signatureStatus;

	return (
		<div className={itemClassName}>
			<div className="modal-time-sheet-employee">

				<div>
					{
						timeSheet.isTemporaryEmployee &&
						<span className="icon-temp_labor" />
					}
					{timeSheet.userName}
				</div>
				<div className="modal-time-sheet-employee__status">
					<SignatureStatusLabel status={signatureStatus} />
					<span className={`modal-time-sheet-employee__expander ${isExpanded ? 'icon-up' : 'icon-down'}`} onClick={toggleExpanded} />
				</div>
			</div>
			{isExpanded &&
				<TimeSheet
					timeSheet={timeSheet}
					timeSheetEntries={timeSheetEntries}
					timezone={timezone}
				/>
			}
		</div>
	);
};

export default React.memo(TimeSheetEmployee);
