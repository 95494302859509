import * as React from 'react';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { reduxForm, InjectedFormProps, FormAction, reset, formValueSelector, change, Field } from 'redux-form';

import Input from 'af-fields/Input';
import RadioGroup from 'af-fields/RadioGroup';

import SubmitButton from 'af-components/SubmitButton';
import SegmentLabel from 'af-components/SegmentLabel';

import { RootState } from 'af-reducers';

import { RESOURCE_STATUS_CREATE } from 'af-constants/reduxForms';

import { CreateFormModel } from '../types';
import { validate } from './validation';

// FIXME: this component requires a refactor when RadioGroup is refactored

export type InternalFormModel = CreateFormModel & { availableOption: string; };

interface OwnProps {
	createStatus: (form: CreateFormModel) => void;
}

interface StateProps {
	getField: (field: string) => string;
}

interface DispatchProps {
	resetForm: () => FormAction;
	changeField: (prop: string, value: string) => FormAction;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & InjectedFormProps<InternalFormModel, FormOwnProps>;

class ResourceStatusCreateForm extends React.Component<Props> {

	static radioOptions = [
		{ label: 'Available', value: 'Available' },
		{ label: 'Unavailable', value: 'Unavailable' },
	];

	createStatus = (form: InternalFormModel) => {
		const { resetForm, createStatus } = this.props;
		createStatus({ name: form.name, available: form.availableOption === 'Available' });
		resetForm();
	};

	handleKeyDown = (e: React.KeyboardEvent) => {
		const { handleSubmit, submitting } = this.props;
		if (e.key === 'Enter' && e.shiftKey === false) {
			e.preventDefault();
			if (!submitting) {
				handleSubmit(this.createStatus)(e);
			}
		}
	};

	render() {
		const { getField, changeField, invalid, submitting, handleSubmit } = this.props;
		return (
			<>
				<SegmentLabel label="Create new Status" />
				<Field
					component={Input}
					label="Name"
					name="name"
					onKeyDown={this.handleKeyDown}
					placeholder="Enter name"
					type="text"
				/>
				<RadioGroup
					changeField={changeField}
					field="availableOption"
					initialValue="Available"
					inline={true}
					items={ResourceStatusCreateForm.radioOptions}
					selector={getField}
				/>
				<div className="status-modal__form-footer">
					<SubmitButton
						disabled={invalid}
						label="Add Status"
						onClick={handleSubmit(this.createStatus)}
						reduxFormSubmitting={submitting}
						variant="primary"
						variantDisabled="info"
					/>
				</div>
			</>
		);
	}
}

const _selector = formValueSelector(RESOURCE_STATUS_CREATE);

function mapStateToProps(state: RootState): StateProps {
	return {
		getField: (field: string) => _selector(state, field),
	};
}

function mapDispatchToProps() {
	return {
		resetForm: () => reset(RESOURCE_STATUS_CREATE),
		changeField: (prop: string, value: string) => change(RESOURCE_STATUS_CREATE, prop, value),
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<InternalFormModel, FormOwnProps>({
		form: RESOURCE_STATUS_CREATE,
		validate,
		enableReinitialize: true,
		initialValues: { availableOption: 'Available' },
	})
);

export default enhance(ResourceStatusCreateForm);
