import * as React from 'react';
import { Button } from 'react-bootstrap';
import { FieldArray, formValueSelector } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';

import TimeSplitEquipmentVM from 'acceligent-shared/dtos/web/view/timeSplitEquipment/timeSplitEquipment';
import FieldWorkClassificationCodeListItemVM from 'acceligent-shared/dtos/web/view/fieldWorkClassificationCode/listItem';
import { TimeSplitEntryRM, TimeSheetEntryRM } from 'acceligent-shared/dtos/web/request/timeSheet/timeSheetUpdate';

import { mapTimeSheetApprovalStatus, TimeSheetInternalApprovalStatus } from 'acceligent-shared/enums/timeSheetApprovalStatus';
import WorkOrderReviewStatus from 'acceligent-shared/enums/workOrderReviewStatus';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from 'acceligent-shared/constants/value';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { findOverlapsByVirtualId, OverlapMeta } from 'acceligent-shared/utils/timeSheetEntry';
import * as TimeSheetUtils from 'acceligent-shared/utils/timeSheet';

import CondensedTableVM from 'ab-viewModels/workOrder/workOrderForReportsCondensedTable';
import OccupiedSlotsForWorkOrderVM from 'ab-viewModels/timeSheet/occupiedSlotsForWorkOrder.viewModel';

import { RootState } from 'af-reducers';

import * as FORMS from 'af-constants/reduxForms';

import Tooltip from 'af-components/Tooltip';

import { useToggle } from 'af-utils/react.util';

import RejectModal from '../../Shared/RejectTimeSheetModal';

import ReviewCell from './ReviewCell';
import TimeSheetArray from './TimeSheetArray';
import Equipment from './TimeSplitArray';
import SignatureStatus from './SignatureStatus';
import FormModel from './formModel';
import { sumTotalEquipmentTime, sumTotalJobTime } from './helpers';

interface EntryForOverlapCheck {
	/** format: ISO_DATETIME */
	startTime: string;
	/** format: ISO_DATETIME */
	endTime: Nullable<string>;
	virtualId: string;
}

interface OwnProps {
	canEdit: boolean;
	workOrderId: number;
	workRequestId: number;
	data: CondensedTableVM['employees'][0];
	classificationCodeList: FieldWorkClassificationCodeListItemVM[];
	equipment: TimeSplitEquipmentVM;
	dueDate: string;
	occupiedSlots: OccupiedSlotsForWorkOrderVM;
	updateTimeSheet: (accountId: number, form: TimeSheetEntryRM[]) => void;
	updateEquipment: (accountId: number, form: TimeSplitEntryRM[]) => void;
	/** value: ISO_DATETIME */
	change: (fieldName: string, value: string) => void;
	onReject: () => void;
	onEditStart: (accountId: number) => void;
	onEditEnd: (accountId: number) => void;
	isTemporaryEmployee: boolean;
	reviewStatus: WorkOrderReviewStatus;
	isReportReadonly: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const renderError = (error: string, index: number) => <span key={index}>{error}</span>;
const renderTooltip = (errors: string[]) => <div className="condensed-table__employee-list-employee__error-list">{errors.map(renderError)}</div>;

const CondensedRowEmployee: React.FC<Props> = (props) => {
	const {
		data,
		dueDate,
		isAllowedToApprove,
		isAllowedToEditTimeSheet,
		canEdit,
		workOrderId,
		workRequestId,
		classificationCodeList,
		equipment,
		updateEquipment,
		updateTimeSheet,
		change,
		onReject,
		onEditEnd,
		onEditStart,
		timeSheetEntries,
		timeSplitEntries,
		occupiedSlots,
		isTemporaryEmployee,
		reviewStatus,
		isReportReadonly,
	} = props;

	const {
		value: showRejectModal,
		setToTrue: openRejectModal,
		setToFalse: closeRejectModal,
	} = useToggle(false);

	const {
		value: editSheet,
		setToTrue: _startEditingSheet,
		setToFalse: _endEditingSheet,
	} = useToggle(false);

	const {
		value: editEquipment,
		setToTrue: _startEditingEquipment,
		setToFalse: _endEditingEquipment,
	} = useToggle(false);

	const [hasOverlap, setHasOverlap] = React.useState(false);
	const [overlap, setOverlap] = React.useState<Record<string, OverlapMeta>>({});

	const startEditingEquipment = React.useCallback(() => {
		_startEditingEquipment();
		onEditStart(data.accountId);
	}, [_startEditingEquipment, onEditStart, data]);

	const endEditingEquipment = React.useCallback(() => {
		_endEditingEquipment();
		if (!editSheet) {
			onEditEnd(data.accountId);
		}
	}, [_endEditingEquipment, onEditEnd, data, editSheet]);

	const startEditingSheet = React.useCallback(() => {
		_startEditingSheet();
		onEditStart(data.accountId);
	}, [_startEditingSheet, onEditStart, data]);

	const endEditingSheet = React.useCallback(() => {
		_endEditingSheet();
		if (!editEquipment) {
			onEditEnd(data.accountId);
		}
	}, [_endEditingSheet, onEditEnd, editEquipment, data]);

	React.useEffect(() => {
		const formattedEntries = timeSheetEntries.reduce<EntryForOverlapCheck[]>((_acc, _e, _index) => {
			if (!!_e.startTime) {
				_acc.push({
					startTime: _e.startTime,
					endTime: _e.endTime,
					virtualId: _e.id?.toString() ?? `created_${_index}`,
				});
			}
			return _acc;
		}, []);
		const overlaps = findOverlapsByVirtualId(formattedEntries, occupiedSlots?.slots);

		const _hasOverlap = Object.keys(overlaps).some((_key) => (overlaps[_key].startTime || overlaps[_key].endTime || overlaps[_key].occupied));
		setHasOverlap(_hasOverlap);
		setOverlap(overlaps);

	}, [occupiedSlots, timeSheetEntries]);

	const timeSheetEntriesName = `workOrderMap.${workOrderId}.${data.accountId}.timeSheetEntries`;
	const timeSplitEntriesName = `workOrderMap.${workOrderId}.${data.accountId}.timeSplitEntries`;

	const handleUpdateEquipment = React.useCallback((form: TimeSplitEntryRM[]) => {
		updateEquipment(data.accountId, form);
	}, [data, updateEquipment]);

	const handleUpdateSheet = React.useCallback((form: TimeSheetEntryRM[]) => {
		updateTimeSheet(data.accountId, form);
	}, [data, updateTimeSheet]);

	const totalJobTime = React.useMemo(() => {
		const sum = timeSheetEntries.reduce(sumTotalJobTime, 0);
		return TimeUtils.roundTimeDurationToInterval(sum, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
	}, [timeSheetEntries]);

	const totalEquipmentTime = React.useMemo(() => {
		return timeSplitEntries.reduce(sumTotalEquipmentTime, 0);
	}, [timeSplitEntries]);

	const errors = React.useMemo(() => {
		const result: string[] = [];
		if (hasOverlap) {
			result.push('One or more corrections overlap with activities on the timeline');
		}
		if (totalEquipmentTime > totalJobTime) {
			result.push('Total equipment time exceeds total job time');
		}
		return result.length ? renderTooltip(result) : null;
	}, [totalJobTime, totalEquipmentTime, hasOverlap]);

	const showRejectButton = React.useMemo(() => {
		const approvalStatus = mapTimeSheetApprovalStatus(data.approvalStatus);
		return !isReportReadonly && !!data.approvalStatus && TimeSheetUtils.shouldTSBeRejectable(
			reviewStatus,
			approvalStatus,
			isAllowedToApprove
		);
	}, [isReportReadonly, data.approvalStatus, reviewStatus, isAllowedToApprove]);

	return (
		<div className="condensed-table__employee-list-item">
			<div className="condensed-table__employee-list-employee">
				<div className="condensed-table__employee-list-employee__error-indicator">
					{!!errors && (
						<Tooltip message={errors} placement="right">
							<span className="icon-warning" />
						</Tooltip>
					)}
				</div>
				<div className="bold">
					{
						isTemporaryEmployee &&
						<span className="icon-temp_labor condensed-table__temp-labor-icon" />
					}
					{data.fullName}
				</div>
				{showRejectButton ? (
					<Button
						className="condensed-table__employee-list-employee__reject"
						onClick={openRejectModal}
						variant="danger"
					>
						Reject Time Sheet
					</Button>
				) : (
					<div />
				)}
				<SignatureStatus signatureStatus={isTemporaryEmployee ? null : data.signatureStatus} />
				<div className="condensed-table__employee-list-employee__review-status">
					<ReviewCell
						approvalSignature={data.approvalSignatureUrl}
						approvedAt={data.approvedAt}
						approvedBy={data.approvedBy}
						rejectedAt={data.rejectedAt}
						rejectedBy={data.rejectedBy}
						rejectReason={data.rejectReason}
						status={data.approvalStatus}
					/>
				</div>
			</div>
			<FieldArray
				canEdit={isAllowedToEditTimeSheet}
				change={change}
				component={TimeSheetArray}
				dueDate={dueDate}
				endEditing={endEditingSheet}
				inEditMode={editSheet}
				name={timeSheetEntriesName}
				overlap={overlap}
				startEditing={startEditingSheet}
				update={handleUpdateSheet}
			/>
			<FieldArray
				canEdit={canEdit}
				classificationCodeList={classificationCodeList}
				component={Equipment}
				endEditing={endEditingEquipment}
				equipment={equipment}
				inEditMode={editEquipment}
				name={timeSplitEntriesName}
				startEditing={startEditingEquipment}
				update={handleUpdateEquipment}
				workOrderId={workOrderId}
				workRequestId={workRequestId}
			/>
			<RejectModal
				accountId={data.accountId}
				close={closeRejectModal}
				code="code"
				dueDate={dueDate}
				employeeFullName={data.fullName}
				onSubmit={onReject}
				showModal={showRejectModal}
				workOrderId={workOrderId}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState, props: OwnProps) {
	const { data, workOrderId, canEdit } = props;
	const { company } = state.company;

	if (!company) {
		throw new Error('company is required in this context');
	}

	const workOrderMap: FormModel['workOrderMap'] = formValueSelector(FORMS.CONDENSED_TABLE_EMPLOYEE)(state, 'workOrderMap');
	const accountData = workOrderMap?.[workOrderId]?.[data.accountId];
	const sheetApproved = data.approvalStatus === TimeSheetInternalApprovalStatus.APPROVED;

	return {
		timeSheetEntries: accountData?.timeSheetEntries ?? [],
		timeSplitEntries: accountData?.timeSplitEntries ?? [],
		isAllowedToApprove: company.isFRManageAllowedToApproveTimeSheet && canEdit,
		isAllowedToEditTimeSheet: !sheetApproved && company.isFRManageAllowedToEditTimeSheet && canEdit,
	};
}

const connector = connect(mapStateToProps);

export default connector(CondensedRowEmployee);
