import * as React from 'react';
import { Button } from 'react-bootstrap';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	startTime: Nullable<string>;
	endTime: Nullable<string>;
	disableStartEndValidation: boolean;
	hasOverlaps: boolean;
	showSignatureModal: () => void;
}

const SignButton = (props: OwnProps) => {
	const { disableStartEndValidation, startTime, endTime, hasOverlaps, showSignatureModal } = props;

	if (!disableStartEndValidation && (!startTime || !endTime)) {
		let message: Nullable<string> = null;
		if (!startTime && !endTime) {
			message = 'Time Sheet is empty';
		} else if (!endTime) {
			message = 'End time is empty';
		}

		return (
			<Tooltip message={message}>
				<Button
					disabled={true}
					onClick={showSignatureModal}
					variant="info"
				>
					Sign My Time Sheet
				</Button>
			</Tooltip>
		);
	}

	return (
		<Button
			disabled={hasOverlaps}
			onClick={showSignatureModal}
			variant="info"
		>
			Sign My Time Sheet
		</Button>
	);
};

export default SignButton;
