import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import DailyPerDiemTip from 'acceligent-shared/models/dailyPerDiemTip';

export class W_DailyPerDiemTip_Create_VM {
	id: number;
	companyId: number;
	message: string;
	date: string;

	constructor(dailyPerDiemTip: DailyPerDiemTip) {
		this.id = dailyPerDiemTip.id;
		this.companyId = dailyPerDiemTip.companyId;
		this.message = dailyPerDiemTip.message;
		this.date = TimeUtils.formatDate(dailyPerDiemTip.date, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);
	}

	static toViewModels(dailyPerDiemTips: DailyPerDiemTip[]) {
		return dailyPerDiemTips.map((_dt) => new W_DailyPerDiemTip_Create_VM(_dt));
	}
}
