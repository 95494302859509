import * as React from 'react';
import { Dropdown, FormControl, FormLabel } from 'react-bootstrap';

import Checkbox from 'af-components/Controls/Checkbox';
import Tooltip from 'af-components/Tooltip';

export interface Props {
	id: string;
	options: Metadata[];
	selected: Metadata[];
	labelKey: string;
	renderMenuItem: (option: Nullable<Metadata>, searchText?: string, index?: number) => JSX.Element;

	hasBlankOption?: boolean;
	valueKey?: string;
	filterBy?: string[] | ((option: Nullable<Metadata>, searchText: string) => boolean);
	className?: string;
	containerClassName?: string;
	placeholder?: string;
	alwaysShowErrors?: boolean;
	label?: string;
	tooltip?: string;
	disabled?: boolean;
	fixed?: boolean;
	onValueChange?: (selectedOption: Nullable<Metadata>) => Promise<void>;
	renderSelected?: (option: Nullable<Metadata>) => JSX.Element;
	onLazyLoad?: (isLazyLoaded: boolean) => Promise<void>;
	disableErrorMessage?: boolean;
	filterable?: boolean;

	handleChange?: (item, event: Nullable<Metadata>) => void;
	dropdownClassName?: string;
	block?: JSX.Element;
	isStandalone?: boolean;
	withCaret?: boolean;
	isWhite?: boolean;
}

interface State {
	isLazyLoading: boolean;
	lazyLoaded: boolean;
	changed: boolean;
	dropup: boolean;
	searchText: string;
	locationX: number;
	locationY: number;
	width: number;
	height: number;
}

class PlainMultiselectDropdown extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		options: [],
		alwaysShowErrors: false,
		className: 'dropdown-field dropdown-field--multiselect',
		hasBlankOption: false,
		placeholder: '',
		disableErrorMessage: false,
		filterable: false,
		selected: [],
		isStandalone: false,
		withCaret: false,
		isWhite: false,
		fixed: false,
	};

	state: State = {
		isLazyLoading: false,
		lazyLoaded: false,
		changed: false,
		dropup: false,
		searchText: '',
		locationX: 0,
		locationY: 0,
		width: 0,
		height: 0,
	};

	// 300 is max height of the dropdowns menu in pixels
	// 1.1 increases max height by 10% just to be sure we're not at the bottom of the screen
	// 5 for borders, paddings, margins
	MAX_DROPDOWN_MENU_HEIGHT = 300 * 1.1 + 5;

	_dropdownToggle: Nullable<React.RefObject<HTMLElement>> = null;

	constructor(props: Props) {
		super(props);
		this._dropdownToggle = React.createRef();
	}

	componentDidUpdate() {
		const { locationX, locationY } = this.state;
		const { id } = this.props;

		const menuItem = document.getElementById(id);
		if (menuItem) {
			const { x, y, width, height } = menuItem.getBoundingClientRect();
			if (x !== locationX || y !== locationY) {
				this.setState(() => ({ locationX: x, locationY: y, width, height }));
			}
		}
	}

	defaultHandleChange = async (item, e) => {
		const { valueKey, labelKey, onValueChange, selected } = this.props;
		const isChecked = e.target.checked;
		const calculatedPropName = valueKey ?? labelKey;
		let newSelected: Metadata[] = [];

		if (isChecked) {
			newSelected = selected.concat(item);
		} else {
			newSelected = selected.filter((_option) => _option[calculatedPropName] !== item[calculatedPropName]);
		}

		// on change callback
		if (onValueChange) {
			await onValueChange(newSelected);
		}
	};

	onToggle = (isOpen: boolean) => {
		const { onLazyLoad, options } = this.props;

		if (onLazyLoad) {
			const lazyLoaded = options && options.length > 0;
			this.setState(() => ({ isLazyLoading: true, lazyLoaded }), async () => {
				await onLazyLoad(lazyLoaded);
				this.setState(() => ({ isLazyLoading: false }));
			});
		}

		if (isOpen && this._dropdownToggle && this._dropdownToggle.current) {
			const { height: bodyHeight } = document.body.getClientRects()[0];
			const { y: dropdownTogglePositionY = 0 } = this._dropdownToggle.current.getClientRects()[0] || {};

			const isDropup = dropdownTogglePositionY > (bodyHeight - this.MAX_DROPDOWN_MENU_HEIGHT);
			if (this.state.dropup !== isDropup) {
				this.setState(() => ({ dropup: isDropup }));
			}
		}
	};

	onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const searchText = event.target.value;

		this.setState(() => ({ searchText }));
	};

	clearSearchInput = () => {
		this.setState(() => ({ searchText: '' }));
	};

	getClassName = () => {
		const { className, alwaysShowErrors } = this.props;

		return (alwaysShowErrors) ? `${className} dropdown-field--with-error` : className;
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	defaultRenderOption = (option: Nullable<Metadata>, searchText?: string, index?: number) => {
		const { labelKey } = this.props;

		if (option === null) {
			return null;
		}

		return option[labelKey];
	};

	filter = (option: Nullable<Metadata>) => {
		const { filterBy } = this.props;
		const { searchText } = this.state;

		if (!searchText) {
			return true;
		}

		if (Array.isArray(filterBy)) {
			const searchInputs = (searchText || '').replace(/\s\s+/g, ' ').toLowerCase().split(' ');
			const isFiltered = searchInputs.every((_searchInput: string) => {
				return filterBy.some((_field: string) => {
					const value = option?.[_field] || '';
					return value.toLowerCase().includes(_searchInput);
				});
			});
			return isFiltered;
		}

		if (typeof filterBy === 'function') {
			return filterBy(option, searchText);
		}

		return true;
	};

	dropdownStyle = (): React.CSSProperties => {
		const { fixed } = this.props;
		const { locationX, locationY, width, height, dropup } = this.state;

		const heightOffset = dropup ? (height - this.MAX_DROPDOWN_MENU_HEIGHT) : height;
		return fixed
			? {
				position: 'fixed',
				right: `calc(100vw - ${locationX}px - ${width}px - 1px)`,
				top: `calc(${locationY}px + ${heightOffset}px)`,
				bottom: 'auto',
				left: 'auto',
			}
			: {};
	};

	renderMenuItem = (option: Nullable<Metadata>, index: number) => {
		const {
			filterable,
			renderMenuItem = this.defaultRenderOption,
			selected,
			valueKey,
			labelKey,
			handleChange,
		} = this.props;
		const { searchText } = this.state;
		const calculatedPropName = valueKey ?? labelKey;

		const isChecked = !!selected.find((_option) => _option[calculatedPropName] === option?.[calculatedPropName]);
		const isFiltered = !filterable || this.filter(option);
		const isDisabled = !!option && option.disabled;

		return isFiltered && (
			<li className="dropdown-menu__multiselect-item" key={`menuItem#${index + 1}`}>
				<a role="button">
					<Checkbox
						handleChange={handleChange ? handleChange.bind(this, option) : this.defaultHandleChange.bind(this, option)}
						inline={true}
						isChecked={isChecked}
						isDisabled={isDisabled}
						label={renderMenuItem(option, searchText, index)}
					/>
				</a>
			</li>
		);
	};

	renderSelected = () => {
		const { selected, placeholder, renderSelected, renderMenuItem, options, valueKey, labelKey } = this.props;

		if (!selected) {
			return <span className="dropdown-toggle__placeholder">{placeholder}</span>;
		}

		const renderItem = renderSelected ?? renderMenuItem;
		if (renderItem) {
			return renderItem(selected);
		}

		// case where [name] is some value but we don't have [propName]
		// e.g. [name]=AM1200 but no [propName], we get the value from options
		if (typeof selected === 'string' || typeof selected === 'number') {
			if (options.length) {
				const calculatedPropName = valueKey ?? labelKey;
				const option = options.find((_option) => _option[calculatedPropName] === selected);
				return option?.[labelKey];
			}
			return selected;
		}
		return selected[labelKey];
	};

	render() {
		const {
			containerClassName,
			disabled,
			hasBlankOption,
			id,
			label,
			options,
			tooltip,
			filterable,
			dropdownClassName,
			block,
			isStandalone,
			withCaret,
			isWhite,
		} = this.props;

		const { isLazyLoading, dropup, searchText } = this.state;

		let fromGroupClassName = containerClassName ?? '';
		fromGroupClassName = isStandalone ? `${fromGroupClassName} form-group--standalone` : fromGroupClassName;

		let dropdownToggleClassName = isLazyLoading ? 'dropdown-toggle--lazy-loading' : '';
		dropdownToggleClassName = withCaret ? `${dropdownToggleClassName} dropdown-toggle--with-caret` : dropdownToggleClassName;
		dropdownToggleClassName = isWhite ? `${dropdownToggleClassName} dropdown-toggle--white` : dropdownToggleClassName;

		return (
			<div className={fromGroupClassName}>
				{label &&
					<FormLabel>
						{label}
						{tooltip &&
							<Tooltip
								message={tooltip}
								placement="top"
							>
								<span className="icon-info" />
							</Tooltip>
						}
					</FormLabel>
				}
				<Dropdown
					className={dropdownClassName ?? this.getClassName()}
					drop={dropup ? 'up' : undefined}
					id={id}
					onToggle={this.onToggle}
				>
					<Dropdown.Toggle
						className={dropdownToggleClassName}
						disabled={disabled}
					>
						<div className="dropdown-toggle__container" ref={this._dropdownToggle as React.LegacyRef<HTMLDivElement>}>
							{this.renderSelected()}
							{isLazyLoading && <div className="rbt-aux"><div className="rbt-loader" /></div>}
						</div>
					</Dropdown.Toggle>
					<Dropdown.Menu
						className="dropdown-menu--scroll-container"
						style={this.dropdownStyle()}
					>
						{filterable &&
							<Dropdown.Header className="dropdown-menu__search-input-container">
								<input
									className="dropdown-menu__search-input"
									onChange={this.onSearchInputChange}
									placeholder="Search"
									type="text"
									value={searchText}
								/>
								{
									searchText ?
										<span
											className="dropdown-menu__icon icon-close"
											onClick={this.clearSearchInput}
											role="button"
										/> :
										<span className="dropdown-menu__icon icon-search" />
								}
							</Dropdown.Header>
						}
						{isLazyLoading && <Dropdown.Item disabled={true} key="menuItem#0">Loading data...</Dropdown.Item>}
						{hasBlankOption && this.renderMenuItem(null, -1)}
						{options.map(this.renderMenuItem)}
					</Dropdown.Menu>
				</Dropdown>
				<FormControl.Feedback />
				{block}
			</div>
		);
	}
}

export default PlainMultiselectDropdown;
