import * as React from 'react';
import * as moment from 'moment';
import { compose } from 'redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import PerDiemWorkOrderEmployeeViewModel, { PerDiemWorkOrderEmployeeListViewModel } from 'ab-viewModels/perDiemWorkOrderEmployee.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as PerDiemActions from 'af-actions/perDiem';

import Table, { TypedTable, TabProps, Column, RowInfo, ButtonData, TableFinalState } from 'af-components/Table6';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import PeriodPicker from 'af-components/Controls/PeriodPicker';
import Breadcrumbs from 'af-components/Breadcrumbs';

import { DOWNLOAD_LOGS } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';
import * as SettingsKeys from 'af-constants/settingsKeys';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import { downloadCSV } from 'af-utils/csv.utils';
import { isEmpty } from 'af-utils/object.util';
import { withSettings, setItemWithFormatter } from 'af-utils/settings.util';

const PerDiemTable = Table as TypedTable<PerDiemWorkOrderEmployeeViewModel>;

interface SettingProps {
	startDate: Date;
	endDate: Date;
}

type OwnProps = CustomRouteComponentProps;
type ConnectOwnProps = OwnProps & SettingProps;
type Props = ConnectOwnProps & ConnectedProps<typeof connector>;

interface State {
	startDate: Date;
	endDate: Date;
}

class PerDiemList extends React.PureComponent<Props, State> {
	state: State = {
		startDate: this.props.startDate,
		endDate: this.props.endDate,
	};

	columns: Column<PerDiemWorkOrderEmployeeViewModel>[] = [
		{
			Header: 'Full Name',
			accessor: 'fullName',
			Cell: ({ original }) => (
				<span>
					<b>{original?.fullName}</b>
				</span>
			),
		},
		{
			Header: 'Date Of Work',
			accessor: 'dueDate',
			Cell: ({ original }) => original.dueDate,
		},
		{
			Header: 'Week Day',
			accessor: 'weekDay',
			Cell: ({ original }) => original.weekDay,
		},
		{
			Header: 'Job',
			accessor: 'jobCode',
			Cell: ({ original }) => original.jobCode,
		},
		{
			Header: 'Work Order',
			accessor: 'workOrderCode',
			Cell: ({ original }) => original.workOrderCode,
		},
		{
			Header: 'Amount $',
			accessor: 'perDiemAmount',
			Cell: ({ original }) => original.perDiemAmount,
		},
		{
			Header: 'Location',
			accessor: 'location',
			Cell: ({ original }) => original.location || <EmptyCell />,
		},
	];

	// FIXME: table already has the onMount callback, rather expand it than use a ref and expose the entire table
	tableRef: Nullable<{ refreshTable: (reload: boolean) => void; }> = null;
	list: Nullable<{ refreshList: () => void; }> = null;

	static setPerDiemReportStartDate(date: Date = new Date()): void {
		setItemWithFormatter(SettingsKeys.PER_DIEM_REPORT_START_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
	}

	static setPerDiemReportEndDate(date: Date = new Date()): void {
		setItemWithFormatter(SettingsKeys.PER_DIEM_REPORT_END_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
	}

	onRowClick = ({ original }) => {
		const { location: { state: { orgAlias } }, companyName, history } = this.props;

		if (original?.id) {
			history.push(CLIENT.COMPANY.SETTINGS.LOGS.PREVIEW(orgAlias, companyName, original.id.toString()));
		}
	};

	getPerDiemReportTable = async (tableRequestModel: TableQuery): Promise<TableViewModel<PerDiemWorkOrderEmployeeViewModel>> => {
		const { getPerDiemReportTable } = this.props;
		const { startDate, endDate } = this.state;

		return await getPerDiemReportTable(
			tableRequestModel,
			TimeUtils.formatDate(startDate),
			TimeUtils.formatDate(endDate)
		);
	};

	downloadPerDiemReport = async () => {
		const { getPerDiemReportForDateInterval, companyName } = this.props;
		const { startDate, endDate } = this.state;

		const perDiemWorkOrderEmployeeList = await getPerDiemReportForDateInterval(
			TimeUtils.formatDate(startDate),
			TimeUtils.formatDate(endDate)
		);
		downloadCSV(PerDiemWorkOrderEmployeeListViewModel.toCSVData(perDiemWorkOrderEmployeeList), `${companyName}_perDiemReport_${startDate}-${endDate}.csv`);
	};

	selectPeriod = (startDate: Date, endDate: Date) => {
		this.setState(
			() => ({ startDate, endDate }),
			() => {
				PerDiemList.setPerDiemReportStartDate(startDate);
				PerDiemList.setPerDiemReportEndDate(endDate);
				if (this.tableRef) {
					const table = this.tableRef;
					table.refreshTable(true);
				} else if (this.list) {
					this.list.refreshList();
				}
			}
		);
	};

	additionalFilter = () => {
		const { startDate, endDate } = this.state;

		return (
			<PeriodPicker
				end={endDate}
				onChange={this.selectPeriod}
				start={startDate}
			/>
		);
	};

	getRowClassName = (state: TableFinalState<PerDiemWorkOrderEmployeeViewModel>, rowInfo: RowInfo<PerDiemWorkOrderEmployeeViewModel>) => {
		const { data } = state;
		let isDarkerRow = false;

		// If data is [{}] it means that the table is still being loaded or that there are no records in the table.
		// In both cases, we shouldn't do anything.
		if (data.length === 1 && isEmpty(data[0] as unknown as Record<string, unknown>)) {
			return '';
		}

		for (let i = 1; i <= rowInfo.index; i++) {
			const previousRow = data[i - 1];
			const currentRow = data[i];

			if (previousRow?.fullName !== currentRow?.fullName) {
				isDarkerRow = !isDarkerRow;
			}
		}

		return isDarkerRow ? 'rt-tr--darker' : '';
	};

	tabs = (): TabProps<PerDiemWorkOrderEmployeeViewModel>[] => {
		const buttons: ButtonData[] = [
			{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: this.downloadPerDiemReport,
				actionKey: DOWNLOAD_LOGS,
			},
		];

		return [
			{
				label: 'Per Diem',
				columns: this.columns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Report',
				buttons,
				fetch: this.getPerDiemReportTable,
				onRowClick: this.onRowClick,
				additionalFilter: this.additionalFilter,
				getRowClassName: this.getRowClassName,
			},
		];
	};

	onMount = (table, list) => {
		this.tableRef = table;
		this.list = list;
	};

	breadcrumbs = () => [{ label: 'Per Diem' }];

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={this.breadcrumbs()} />
				<PerDiemTable
					onMount={this.onMount}
					tableName={TableNameEnum.PER_DIEM_REPORT}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		getPerDiemReportTable: PerDiemActions.getPerDiemReportTable,
		getPerDiemReportForDateInterval: PerDiemActions.getPerDiemReportForDateInterval,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.PER_DIEM_REPORT_START_DATE(),
			mappedName: 'startDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: moment().startOf('week').toDate(), // FIXME: remove moment and use time utils
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.PER_DIEM_REPORT_END_DATE(),
			mappedName: 'endDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: moment().endOf('week').toDate(), // FIXME: remove moment and use time utils
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(PerDiemList);
