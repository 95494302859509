import { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import SystemNotificationRequestModel from 'ab-requestModels/systemNotification.requestModel';

import { GetRootState } from 'af-reducers';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

export function createSystemNotification(form: SystemNotificationRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const parsedForm = new SystemNotificationRequestModel(form);
			return await http.post<SystemNotificationRequestModel[]>(API.V1.SYSTEM_NOTIFICATIONS.ROOT, parsedForm);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllSystemNotifications() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<SystemNotificationRequestModel[]>(API.V1.SYSTEM_NOTIFICATIONS.ROOT);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteSystemNotification(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.delete(API.V1.SYSTEM_NOTIFICATIONS.DELETE(id));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
