import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';

import ValidationState from 'ab-enums/validationState.enum';

import MenuItemWithValidationTooltip from './MenuItemWithValidationTooltip';

interface Props {
	status: WorkOrderStatus;
	isPaused: boolean;
	code: string;
	validationState: ValidationState;
	openCancelModal: () => void;
	openCopyModal: () => void;
	openPauseModal: () => void;
	openResumeModal: () => void;
	openDeleteModal: () => void;
	previewOrder: () => void;
	openNotifyParticipantsModal: () => void;
	openNotificationPreviewModal: () => void;
	publishOrder: () => void;
	jobStatus: WorkRequestStatus;
	workOrderId: number;
	additionalValidationCondition?: boolean;
	readOnly: boolean;
	notificationsDisabled: boolean;
}

export default class SettingsButton extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		additionalValidationCondition: true,
	};

	render() {
		const {
			status,
			code,
			validationState,
			additionalValidationCondition,
			readOnly,
			openCancelModal,
			openCopyModal,
			openDeleteModal,
			openPauseModal,
			openResumeModal,
			openNotifyParticipantsModal,
			openNotificationPreviewModal,
			publishOrder,
			previewOrder,
			jobStatus,
			notificationsDisabled,
			isPaused,
		} = this.props;

		const isDraft: boolean = status === WorkOrderStatus.DRAFT;
		const isOutdated: boolean = status === WorkOrderStatus.OUTDATED;
		const isPublished: boolean = status === WorkOrderStatus.PUBLISHED;
		const isCanceled: boolean = status === WorkOrderStatus.CANCELED;
		const isJobFinished: boolean = jobStatus === WorkRequestStatus.FINISHED;
		const wasPublished: boolean = isPublished || isOutdated;
		const isPausable = (isPublished || isOutdated) && !isPaused;

		if (readOnly) {
			return (
				<div className="work-order-info-modal__header-actions">
					<Dropdown id={code}>
						<Dropdown.Toggle>
							<span className="icon-actions" />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<MenuItemWithValidationTooltip
								additionalCondition={!!additionalValidationCondition || isCanceled}
								event={previewOrder}
								eventKey="Preview"
								text="Preview in PDF"
								validationState={validationState}
							/>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			);
		}
		return (
			<div className="work-order-info-modal__header-actions">
				<Dropdown id={code}>
					<Dropdown.Toggle>
						<span className="icon-actions" />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{(isDraft || isOutdated) &&
							<MenuItemWithValidationTooltip
								additionalCondition={additionalValidationCondition}
								event={publishOrder}
								eventKey="Publish"
								text={isDraft ? 'Publish' : 'Republish'}
								validationState={validationState}
							/>
						}
						{(!isJobFinished && !isCanceled) &&
							<Dropdown.Item eventKey="Copy" onClick={openCopyModal}>
								Copy
							</Dropdown.Item>
						}
						{isPausable && <Dropdown.Item disabled={!isPublished} eventKey="Pause" onClick={openPauseModal}>Pause</Dropdown.Item>}
						{isPaused && <Dropdown.Item disabled={!isPublished} eventKey="Resume" onClick={openResumeModal}>Resume</Dropdown.Item>}
						{!isCanceled &&
							<Dropdown.Item disabled={!wasPublished} eventKey="Cancel" onClick={wasPublished ? openCancelModal : undefined}>
								Cancel
							</Dropdown.Item>
						}
						<Dropdown.Item disabled={!isDraft} eventKey="Delete" onClick={isDraft ? openDeleteModal : undefined}>
							Delete
						</Dropdown.Item>
						<Dropdown.Item divider={true} />
						<MenuItemWithValidationTooltip
							additionalCondition={!!additionalValidationCondition || isCanceled}
							event={previewOrder}
							eventKey="Preview"
							text="Preview in PDF"
							validationState={validationState}
						/>
						{!notificationsDisabled &&
							<MenuItemWithValidationTooltip
								additionalCondition={isPublished || isCanceled}
								customMessage="Can only send notifications for published Work Orders."
								event={openNotifyParticipantsModal}
								eventKey="Notify"
								text="Notify Participants"
								validationState={validationState}
							/>}
						{!notificationsDisabled &&
							<MenuItemWithValidationTooltip
								additionalCondition={isPublished || isCanceled || isOutdated}
								customMessage="Can only send notifications for published Work Orders."
								event={openNotificationPreviewModal}
								eventKey="Notification Preview"
								text="Notification Preview"
								validationState={validationState}
							/>}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	}
}
