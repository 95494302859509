import * as React from 'react';
import { formSectionClass, formSectionClassColumn2, formSectionClassColumn4, formSectionClassRow, formSectionTitleClass } from '../../helpers';
import { Field } from 'redux-form';
import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';

import styles from '../../styles.module.scss';
import WorkRequestUpsertFM from '../../formModel';

const EnvironmentalProtectionSection: React.FC = () => {
	const checkboxStyle = React.useMemo(() => [formSectionClassColumn2, styles['work-request-form__tab-content__form-section__row__centered-column']].join(' '), []);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Environmental Protection
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="waterOrWetlands"
						label="Water/Wetlands"
						name={WorkRequestUpsertFM.getAttributeName('waterOrWetlands')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="waterOrWetlandsDescription"
						label="Description"
						name={WorkRequestUpsertFM.getAttributeName('waterOrWetlandsDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="stormDrain"
						label="Storm Drain"
						name={WorkRequestUpsertFM.getAttributeName('stormDrain')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="stormDrainDescription"
						label="Description"
						name={WorkRequestUpsertFM.getAttributeName('stormDrainDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="bypassPumping"
						label="Bypass Pumping"
						name={WorkRequestUpsertFM.getAttributeName('bypassPumping')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="byPassPumpingDescription"
						label="Description"
						name={WorkRequestUpsertFM.getAttributeName('bypassPumpingDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						id="sensitiveEnvironment"
						label="Sensitive Environment"
						name={WorkRequestUpsertFM.getAttributeName('sensitiveEnvironment')}
					/>
				</div>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						id="sensitiveEnvironmentDescription"
						label="Description"
						name={WorkRequestUpsertFM.getAttributeName('sensitiveEnvironmentDescription')}
						placeholder="Enter Description"
						type="text"
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(EnvironmentalProtectionSection);
