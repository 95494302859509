import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import ResourcePreview from './ResourcePreview';

const breadcrumbs = [
	{ label: 'Message' },
	{ label: 'Preview' },
];

export default () => (
	<ResourcePreview
		backToListLabel="Back to Communication History"
		backToListUrl="#"
		breadcrumbs={breadcrumbs}
		editUrl="#"
	>
		<Row className="row--padded-top">
			<Col className="loading-box-field standalone" md={7} />
			<Col md={17}>
				<Row>
					<Col className="loading-box-row" md={6} />
					<Col className="loading-box-row" md={6} />
					<Col className="loading-box-row" md={6} />
					<Col className="loading-box-row" md={6} />
				</Row>
				<Row>
					<Col className="loading-box-row" md={6} />
					<Col className="loading-box-row" md={6} />
					<Col className="loading-box-row" md={12} />
				</Row>
				<Row>
					<Col className="loading-box-row" md={6} />
					<Col className="loading-box-row" md={6} />
					<Col className="loading-box-row" md={12} />
				</Row>
			</Col>
		</Row>
		<Row>
			<Col className="loading-box-field standalone" md={24} />
		</Row>
	</ResourcePreview>
);
