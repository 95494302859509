import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import UnitEnum, { QuantityUnitLabel } from 'acceligent-shared/enums/quantityUnit';

import { BILLING_CODE_DECIMAL_POINTS } from 'af-constants/values';

import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';
import Dropdown from 'af-fields/Dropdown';

import * as ReduxUtils from 'ab-utils/reduxForms.util';

import { BillingCodeFM, CustomFormErrors } from './BillingCodeArray';

interface DropdownOption<T> {
	id: T;
	label: string;
}

const quantityUnitOptions: DropdownOption<UnitEnum>[] = Object.keys(QuantityUnitLabel)
	.map((_unit: UnitEnum) => ({ id: _unit, label: QuantityUnitLabel[_unit] }));

const normalizeDecimalNumber4DecimalPlaces = (
	value: string | number,
	previousValue: string
): string => ReduxUtils.normalizeDecimalNumberForDecimalPoints(value, previousValue, BILLING_CODE_DECIMAL_POINTS);

const formatDecimalNumber4DecimalPlaces =
	(value: string | number): string => ReduxUtils.formatDecimalNumberForDecimalPoints(value, BILLING_CODE_DECIMAL_POINTS);

interface OwnProps {
	fieldName: string;
	index: number;
	onRemove: (index: number) => void;
	change: (fieldName: string, value: Nullable<string>) => void;
	changeEditable: (index: number, editable: boolean) => void;
	errors: CustomFormErrors<BillingCodeFM>;
}

type Props = OwnProps;

const BillingCodeEdit: React.FC<Props> = React.memo((props) => {
	const { index, onRemove, fieldName, change, changeEditable, errors } = props;

	const remove = React.useCallback(() => {
		onRemove(index);
	}, [onRemove, index]);

	const filterUnits = React.useCallback((option: DropdownOption<UnitEnum>, searchText: string) => {
		return option.label.toLowerCase().includes(searchText);
	}, []);

	const clearUnit = React.useCallback(() => {
		change(`${fieldName}.unit`, null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setReadOnly = React.useCallback(() => {
		changeEditable(index, false);
	}, [changeEditable, index]);

	const billingCodeInvalid = errors && Object.keys(errors).length > 0;

	return (
		<>
			<Row className="job-upsert__billing-jobs__list__item__edit">
				<Col sm={4}>
					<Field
						component={Input}
						id="lineItemNumber"
						label="Line Item Number *"
						name={`${fieldName}.lineItemNumber`}
						placeholder="0"
						type="number"
					/>
				</Col>
				<Col sm={4}>
					<Field
						component={Input}
						id="customerNumber"
						label="Customer Number"
						name={`${fieldName}.customerNumber`}
						placeholder="0"
						type="number"
					/>
				</Col>
				<Col sm={4}>
					<Field
						component={Input}
						id="customerId"
						label="Customer ID *"
						name={`${fieldName}.customerId`}
						placeholder="0"
						type="text"
					/>
				</Col>
				<Col sm={4}>
					<Field
						component={Input}
						id="ownerNumber"
						label="Owner Number"
						name={`${fieldName}.ownerNumber`}
						placeholder="0"
						type="text"
					/>
				</Col>
				<Col sm={4}>
					<Field
						component={Input}
						id="ownerId"
						label="Owner ID"
						name={`${fieldName}.ownerId`}
						placeholder="Code"
						type="text"
					/>
				</Col>
				<Col className="job-upsert__billing-jobs__list__item__actions job-upsert__billing-jobs__list__item__edit__actions" sm={4}>
					<span
						className={`icon-check ${billingCodeInvalid ? 'disabled' : ''}`}
						onClick={billingCodeInvalid ? undefined : setReadOnly}
					/>
					<span className="icon-delete" onClick={remove} />
				</Col>
			</Row>
			<Row>
				<Col sm={4}>
					<Field
						component={Input}
						id="bidQuantity"
						label="Bid Quantity"
						name={`${fieldName}.bidQuantity`}
						placeholder="0"
						type="number"
					/>
				</Col>
				<Col md={4}>
					<Field
						component={Dropdown}
						filterable={true}
						filterBy={filterUnits}
						id="unit"
						label="Unit *"
						labelKey="label"
						name={`${fieldName}.unit`}
						onClear={clearUnit}
						options={quantityUnitOptions}
						placeholder="Select Unit"
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col sm={4}>
					<Field
						component={Input}
						format={formatDecimalNumber4DecimalPlaces}
						id="unitPrice"
						isDollarValue={true}
						label="Unit Price *"
						name={`${fieldName}.unitPrice`}
						normalize={normalizeDecimalNumber4DecimalPlaces}
						placeholder="0"
						type="text"
					/>
				</Col>
				<Col sm={4}>
					<Field
						component={Input}
						id="group"
						label="Group"
						name={`${fieldName}.group`}
						placeholder="Enter Group"
						type="text"
					/>
				</Col>
			</Row>
			<Row>
				<Col sm={20}>
					<Field
						component={Textarea}
						id="description"
						label="Description *"
						name={`${fieldName}.description`}
						placeholder="Enter Description"
						rows={2}
						showMaxCharactersLabel={false}
					/>
				</Col>
			</Row>
		</>
	);
});

export default BillingCodeEdit;
