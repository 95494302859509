import { ColorPalette, TextColor, AdditionalColors, ColorHex } from 'acceligent-shared/enums/color';

export const getColorBackgroundClass = (
	color: Nullable<ColorPalette | AdditionalColors | string> | undefined,
	defaultToGrey: boolean = false,
	defaultClass?: string
) => {
	if (!color) {
		if (defaultClass) {
			return defaultClass;
		}

		return defaultToGrey ? 'bg-pastel-greyUndefined' : '';
	}
	return `bg-pastel-${color.toLowerCase()}`;
};

export const getColorTextBasedOnBackground = (color: ColorPalette | AdditionalColors | string | undefined) => {
	switch (color) {
		case ColorPalette.APRICOT:
		case ColorPalette.BRONZE:
		case ColorPalette.TAN:
		case ColorPalette.PEACH:
		case ColorPalette.ORANGE:
		case ColorPalette.APRICOT:
		case ColorPalette.GOLD:
		case ColorPalette.LEMON:
		case ColorPalette.GREEN:
		case ColorPalette.PALEGREEN:
		case ColorPalette.BABYBLUE:
		case ColorPalette.VIOLET:
		case ColorPalette.RUBY:
		case ColorPalette.CERISE:
		case AdditionalColors.GREY:
		case AdditionalColors.BLACK:
			return ColorHex.BLACK;
		case ColorPalette.BROWN:
		case ColorPalette.MAROON:
		case ColorPalette.RED:
		case ColorPalette.RUST:
		case ColorPalette.OCHER:
		case ColorPalette.BLUE:
		case ColorPalette.EGGPLANT:
		case ColorPalette.ULTRAMARINE:
		case ColorPalette.FORRESTGREEN:
		case ColorPalette.PURPLE:
		case ColorPalette.CRIMSON:
		default:
			return ColorHex.WHITE;
	}
};

export const getColorTextClass = (
	color: Nullable<ColorPalette | AdditionalColors | TextColor | string> | undefined,
	defaultToGrey: boolean = false,
	defaultClass?: string
) => {
	if (!color) {
		if (defaultClass) {
			return defaultClass;
		}

		return defaultToGrey ? 'text-pastel-greyUndefined' : '';
	}
	return `text-pastel-${color.toLowerCase()}`;
};
