import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import FSN from './FSN';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector>;

const ResourcesFSN: React.FC<Props> = (props: Props) => {
	const { companyData, userRole, location: { state: { orgAlias }, pathname } } = props;
	const companyName = companyData.name;

	const links = React.useMemo(() => [
		{
			url: CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, companyName),
			label: 'Wage Rate',
			icon: 'icon-dollar',
			visibleFor: PagePermissions.COMPANY.RESOURCES.WAGE_RATE,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.WAGE_RATE.ROOT(orgAlias, companyName)),
			id: 'accqa__resources__wage-rate',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.LIST(orgAlias, companyName),
			label: 'Classification Code',
			icon: 'icon-daily_report',
			visibleFor: PagePermissions.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.ROOT(orgAlias, companyName)),
			id: 'accqa__resources__classification-code',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.EMPLOYEE.LIST(orgAlias, companyName),
			label: 'Labor',
			icon: 'icon-user',
			visibleFor: PagePermissions.COMPANY.RESOURCES.EMPLOYEE,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.EMPLOYEE.ROOT(orgAlias, companyName)),
			id: 'accqa__resources__labor',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.LIST(orgAlias, companyName),
			label: 'Temporary Labor',
			icon: 'icon-temp_labor',
			visibleFor: PagePermissions.COMPANY.SETTINGS.TEMP_LABOR,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.ROOT(orgAlias, companyName)),
			id: 'accqa__resources__temporary-labor',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.AGENCY.LIST(orgAlias, companyName),
			label: 'Temp Agencies',
			icon: 'icon-temp_agency',
			visibleFor: PagePermissions.COMPANY.SETTINGS.TEMP_LABOR,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.AGENCY.ROOT(orgAlias, companyName)),
			id: 'accqa__resources__agency',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.EMPLOYEE_STATUS(orgAlias, companyName),
			label: 'Employee Status',
			icon: 'icon-user_status',
			visibleFor: PagePermissions.COMPANY.RESOURCES.EMPLOYEE_STATUS,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.EMPLOYEE_STATUS(orgAlias, companyName)),
			id: 'accqa__resources__employee-status',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.TREE(orgAlias, companyName),
			label: 'Equipment Cost',
			icon: 'icon-equipment_cost',
			visibleFor: PagePermissions.COMPANY.RESOURCES.EQUIPMENT_COST,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.ROOT(orgAlias, companyName)),
			id: 'accqa__resources__equipment-cost',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName),
			label: 'Equipment',
			icon: 'icon-equipment',
			visibleFor: PagePermissions.COMPANY.RESOURCES.EQUIPMENT.MANAGE,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.EQUIPMENT.ROOT(orgAlias, companyName)),
			id: 'accqa__resources__equipment',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.ITEM_CATEGORY(orgAlias, companyName),
			label: 'Accessory Category',
			icon: 'icon-equipment_status',
			visibleFor: PagePermissions.COMPANY.RESOURCES.EQUIPMENT.MANAGE,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.ITEM_CATEGORY(orgAlias, companyName)),
			id: 'accqa__resources__category-resource',
			isForceHidden: DisabledFeatures.Inventory || !companyData.arePortalFunctionalitiesEnabled,
		},
		{
			url: CLIENT.COMPANY.RESOURCES.EQUIPMENT_STATUS(orgAlias, companyName),
			label: 'Equipment Status',
			icon: 'icon-equipment_status',
			visibleFor: PagePermissions.COMPANY.RESOURCES.EQUIPMENT_STATUS,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.EQUIPMENT_STATUS(orgAlias, companyName)),
			id: 'accqa__resources__equipment-status',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.CREW_TYPE.LIST(orgAlias, companyName),
			label: 'Crew Type',
			icon: 'icon-users',
			visibleFor: PagePermissions.COMPANY.RESOURCES.CREW_TYPE,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.CREW_TYPE.LIST(orgAlias, companyName)),
			id: 'accqa__resources__crew-type',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.SKILL.LIST(orgAlias, companyName),
			label: 'Skill',
			icon: 'icon-star',
			visibleFor: PagePermissions.COMPANY.RESOURCES.SKILL,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.SKILL.LIST(orgAlias, companyName)),
			id: 'accqa__resources__skill',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.LOCATION.LIST(orgAlias, companyName),
			label: 'Location',
			icon: 'icon-location_pin',
			visibleFor: PagePermissions.COMPANY.RESOURCES.LOCATION,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.LOCATION.ROOT(orgAlias, companyName)),
			id: 'accqa__resources__location',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.DIVISION.LIST(orgAlias, companyName),
			label: 'Division',
			icon: 'icon-division',
			visibleFor: PagePermissions.COMPANY.RESOURCES.DIVISION,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.DIVISION.LIST(orgAlias, companyName)),
			id: 'accqa__resources__division',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.SALE.LIST(orgAlias, companyName),
			label: 'Sales',
			icon: 'icon-cost',
			visibleFor: PagePermissions.COMPANY.RESOURCES.SALE,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.SALE.ROOT(orgAlias, companyName)),
			id: 'accqa__resources__sales',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.DOCUMENT.LIST(orgAlias, companyName),
			label: 'Document',
			icon: 'icon-notes',
			visibleFor: PagePermissions.COMPANY.RESOURCES.DOCUMENT,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.DOCUMENT.LIST(orgAlias, companyName)),
			id: 'accqa__resources__document',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.DELIVERABLE.LIST(orgAlias, companyName),
			label: 'Deliverable Resource',
			icon: 'icon-deliverables',
			visibleFor: PagePermissions.COMPANY.RESOURCES.DELIVERABLE.MANAGE,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.DELIVERABLE.LIST(orgAlias, companyName)),
			id: 'accqa__resources__deliverable-resource',
		},
		{
			url: CLIENT.COMPANY.RESOURCES.JOB_STATUS.LIST(orgAlias, companyName),
			label: 'Job Status',
			icon: 'icon-check_circle_outline',
			visibleFor: PagePermissions.COMPANY.RESOURCES.JOB_STATUS,
			isActive: pathname.includes(CLIENT.COMPANY.RESOURCES.JOB_STATUS.LIST(orgAlias, companyName)),
			id: 'accqa__resources__deliverable-resource',
		},
	], [orgAlias, companyName, pathname, companyData.arePortalFunctionalitiesEnabled]);

	return (
		<FSN
			infoLabel="Resources"
			isCompanyAdmin={companyData.isCompanyAdmin}
			links={links}
			permissions={companyData.permissions}
			scrollLabel="none"
			userRole={userRole}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
		userRole: userData.role,
	};
}

const connector = connect(mapStateToProps);

export default connector(ResourcesFSN);
