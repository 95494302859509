import { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';
import { SEND_SB_TEMPLATE_FORM, SCHEDULE_SB_TEMPLATE_EMAIL } from 'af-constants/reduxForms';

import { ScheduleEmployeesForAutomaticNotification, SendManualTemplateNotificationMultipleWO, SendManualTemplateNotificationSingleWO } from 'ab-requestModels/notification.requestModel';
import ScheduleBoardTemplateNotificationRequestModel from 'ab-requestModels/scheduleBoardTemplateNotification.requestModel';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { GetRootState } from 'af-reducers';

export function sendTemplateNotificationSingleWO(form: SendManualTemplateNotificationSingleWO) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(API.V1.NOTIFY.SEND_TEMPLATE_NOTIFICATION_SINGLE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function sendTemplateNotificationMultipleWO(form: SendManualTemplateNotificationMultipleWO) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(API.V1.NOTIFY.SEND_TEMPLATE_NOTIFICATION_MULTIPLE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function sendSBTemplateNotification(form: ScheduleBoardTemplateNotificationRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.NOTIFY.SEND_SB_TEMPLATE_NOTIFICATION, form, { submitting: SEND_SB_TEMPLATE_FORM });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function scheduleForAutoNotify(form: ScheduleEmployeesForAutomaticNotification) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.NOTIFY.SCHEDULE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function scheduleSBForAutoNotify(form: ScheduleBoardTemplateNotificationRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.NOTIFY.SCHEDULE_SB, form, { submitting: SCHEDULE_SB_TEMPLATE_EMAIL });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
