import WorkSummaryVM from 'ab-viewModels/fieldReport/workSummary.viewModel';
import WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';

export enum WorkSummaryTableSortKey {
	WORK = 'WORK',
	TYPE = 'TYPE',
	QUANITITY = 'QUANTITY',
	UNIT = 'UNIT',
}

export interface WorkSummaryTableSortMeta<K extends WorkSummaryTableSortKey> {
	id: K;
	label: string;
	sort: (a: WorkSummaryDetailVM | WorkSummaryVM, b: WorkSummaryDetailVM | WorkSummaryVM) => number;
}

export const WORK_SUMMARY_TABLE_SORT_META: { [K in WorkSummaryTableSortKey]: WorkSummaryTableSortMeta<K> } = {
	[WorkSummaryTableSortKey.TYPE]: {
		id: WorkSummaryTableSortKey.TYPE,
		label: 'Type',
		sort: (a: WorkSummaryDetailVM, b: WorkSummaryDetailVM) => (a.workType ?? '').localeCompare(b.workType ?? ''),
	},
	[WorkSummaryTableSortKey.WORK]: {
		id: WorkSummaryTableSortKey.WORK,
		label: 'Name',
		sort: (a: WorkSummaryDetailVM, b: WorkSummaryDetailVM) => a.workName.localeCompare(b.workName),
	},
	[WorkSummaryTableSortKey.QUANITITY]: {
		id: WorkSummaryTableSortKey.QUANITITY,
		label: 'Quantity',
		sort: (a: WorkSummaryDetailVM, b: WorkSummaryDetailVM) => (b.quantity ?? 0) - (a.quantity ?? 0),
	},
	[WorkSummaryTableSortKey.UNIT]: {
		id: WorkSummaryTableSortKey.UNIT,
		label: 'Unit',
		sort: (a: WorkSummaryDetailVM, b: WorkSummaryDetailVM) => (b.unit ?? '').localeCompare(a.unit ?? ''),
	},
};
