import * as React from 'react';

import AccountPermission from 'acceligent-shared/enums/accountPermission';

import { permissionDescriptions } from './descriptions';
import DescriptionBullet from './DescriptionBullet';

interface OwnProps {
	permission: AccountPermission;
}

type Props = OwnProps;

class PermissionDescription extends React.PureComponent<Props> {

	public static exists(permission: AccountPermission): boolean {
		const { GENERAL, WEB, MOBILE } = permissionDescriptions[permission];
		return !!GENERAL?.length || !!WEB?.length || !!MOBILE?.length;
	}

	public static constructIfExists(permission: AccountPermission): Nullable<JSX.Element> {
		return PermissionDescription.exists(permission) ? <PermissionDescription permission={permission} /> : null;
	}

	static renderDescriptionBullet = (bullet: string, index: number) => <DescriptionBullet key={index} message={bullet} />;

	render() {
		const { permission } = this.props;
		const { GENERAL, WEB, MOBILE } = permissionDescriptions[permission];

		return (
			<>
				{!!GENERAL?.length &&
					<div className="member-permissions__description">
						<span className="member-permissions__description__title">
							General:
						</span>
						<ul className="member-permissions__description__list">
							{GENERAL.map(PermissionDescription.renderDescriptionBullet)}
						</ul>
					</div>
				}
				{!!WEB?.length &&
					<div className="member-permissions__description">
						<span className="member-permissions__description__title">
							Web:
						</span>
						<ul className="member-permissions__description__list">
							{WEB.map(PermissionDescription.renderDescriptionBullet)}
						</ul>
					</div>
				}
				{!!MOBILE?.length &&
					<div className="member-permissions__description">
						<span className="member-permissions__description__title">
							Mobile:
						</span>
						<ul className="member-permissions__description__list">
							{MOBILE.map(PermissionDescription.renderDescriptionBullet)}
						</ul>
					</div>
				}
			</>
		);
	}
}

export default PermissionDescription;
