import * as React from 'react';

import UserGuideModal from 'af-components/UserGuideModal';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

interface Props {
	visible: boolean;
	startGuideUri: string;
}

const UserGuideLegend: React.FC<Props> = (props) => {

	const { visible, startGuideUri } = props;

	const [isModalOpen, setIsModalOpen] = React.useState(false);

	const openModal = React.useCallback(() => {
		setIsModalOpen(true);
	}, []);

	const closeModal = React.useCallback(() => {
		setIsModalOpen(false);
	}, []);

	return (
		<>
			{(visible && !DisabledFeatures.UserGuide) ? (
				<>
					<div className="user-guide-legend" onClick={openModal}>
						<span className="icon-help" />
						<span className="legend-text">Help</span>
					</div>
					<UserGuideModal closeModal={closeModal} isModalOpen={isModalOpen} startGuideUri={startGuideUri} ></UserGuideModal>
				</>
			) : <></>}

		</>);
};

export default UserGuideLegend;
