import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, FormErrors, WrappedFieldProps } from 'redux-form';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import TimeSheetEntryWorkType, { TimeSheetEntryWorkTypeLabel, TimeSheetWorkTypeDisplayInfo } from 'acceligent-shared/enums/timeSheetEntryWorkType';

import { OverlapMeta } from 'acceligent-shared/utils/timeSheetEntry';
import * as TimeUtils from 'acceligent-shared/utils/time';

import { bemElement } from 'ab-utils/bem.util';
import * as TimeSheetUtils from 'af-utils/timeSheet.util';

import SubmitButton from 'af-components/SubmitButton';
import Tooltip from 'af-components/Tooltip';

import Dropdown from 'af-fields/Dropdown';
import TimePicker from 'af-fields/TimePicker';
import DateInput from 'af-fields/DateInput';

import { TimeSheetAddedEntryFormModel, TimeSheetAddedEntryWithType, TimeSheetEntryFormModel, TimeSheetEntryFormType, TimeSheetEntryWithType, TimeSheetGapEntryWithType } from '../formModel';

interface OwnProps {
	timeSheetId: number;
	onSave: () => void;
	onDelete: (index: number) => void;
	onCancel: () => void;
	index: number;
	change: (fieldName: string, value) => void;
	/** YYYY-MM-DD */
	dueDate: string;
	getEntry: () => TimeSheetEntryWithType | TimeSheetAddedEntryWithType;
	onEditingEntry: (index: number, newEntry: TimeSheetEntryWithType) => void;
	editingEntryInitialEntry: Nullable<TimeSheetEntryWithType | TimeSheetGapEntryWithType>;
	overlap: OverlapMeta;
	syncErrors: FormErrors<TimeSheetEntryFormModel | TimeSheetAddedEntryFormModel, string>;
	timeZoneInUse: Nullable<string>;
}

interface TimeSheetEntryTypeOption {
	id: TimeSheetEntryWorkType;
	label?: string;
}

const timeSheetEntryTypeOptions: TimeSheetEntryTypeOption[] = Object.keys(TimeSheetEntryWorkType)
	.map((_currType: TimeSheetEntryWorkType) => ({ id: _currType, label: TimeSheetEntryWorkTypeLabel[_currType] }));

type Props = OwnProps & WrappedFieldProps;

const TimeSheetEntryNewOrEdit: React.FC<Props> = (props) => {
	const {
		onSave,
		onDelete,
		index,
		onCancel,
		change,
		dueDate,
		getEntry,
		input,
		onEditingEntry,
		editingEntryInitialEntry,
		overlap,
		syncErrors,
		timeZoneInUse,
	} = props;
	const { entry } = getEntry();
	const { startTime, endTime, workType, startDate, isNew } = entry;
	const initialWorkType = React.useRef(workType);
	// new dates need to be created unless change in redux will change initial refs too
	const initialStartTime = React.useRef(!!startTime ? new Date(startTime) : null);
	const initialEndTime = React.useRef(!!endTime ? new Date(endTime) : null);
	const initialStartDate = React.useRef(startDate);

	const fieldNameWorkType = `${input.name}.workType`;
	const fieldNameStartTime = `${input.name}.startTime`;
	const fieldNameStartDate = `${input.name}.startDate`;
	const fieldNameEndTime = `${input.name}.endTime`;

	const handleCancel = React.useCallback(() => {
		if (isNew) {
			onDelete(index);
			return;
		}
		onCancel();
	}, [onCancel, index, isNew, onDelete]);

	const handleDelete = React.useCallback(() => {
		onDelete(index);
	}, [index, onDelete]);

	const [icon, setIcon] = React.useState<Nullable<TimeSheetEntryWorkType>>(workType);

	const normalizeEndTime = React.useCallback((value: Nullable<string>): Nullable<string> => {
		const { endTime: _endTime } = TimeSheetUtils.adjustEndTime(value, entry);
		if (!entry.isNew) {
			onEditingEntry(
				index,
				{
					entry: {
						...entry as TimeSheetEntryFormModel,
						endTime: _endTime,
					},
					type: TimeSheetEntryFormType.ENTRY,
				}
			);
		}
		return _endTime;
	}, [entry, index, onEditingEntry]);

	const normalizeStartTime = React.useCallback((value: Nullable<string>): Nullable<string> => {
		const { startTime: _startTime, endTime: _endTime } = TimeSheetUtils.adjustStartTime(value, entry);

		if (_endTime) {
			change(fieldNameEndTime, _endTime);
		}
		if (!entry.isNew) {
			const newEntry = {
				entry: {
					...entry as TimeSheetEntryFormModel,
					endTime: _endTime,
				},
				type: TimeSheetEntryFormType.ENTRY,
			};
			if (_startTime) {
				newEntry.entry.startTime = _startTime;
			}
			onEditingEntry(index, newEntry as TimeSheetEntryWithType);
		}
		return _startTime;
	}, [change, entry, fieldNameEndTime, index, onEditingEntry]);

	const onWorkTypeChange = React.useCallback((entryWorkType: Nullable<TimeSheetEntryTypeOption>) => {
		if (entryWorkType?.id) {
			setIcon(entryWorkType.id);
		}
	}, []);

	const onDateChange = React.useCallback((/** ISO_DATETIME */ value: string) => {
		const { startTime: _startTime, endTime: _endTime } = TimeSheetUtils.onStartDateChange(value, entry);
		if (_startTime) {
			change(fieldNameStartTime, _startTime);
		}
		if (_endTime) {
			change(fieldNameEndTime, _endTime);
		}

		if (_startTime && !entry.isNew) {
			// We want to extract date at that time zone, and conversion to date takes place in UTC
			const browserOffset = (new Date()).getTimezoneOffset();
			const dateOffset = browserOffset;

			const _startDate = TimeUtils.offsetTime(_startTime, dateOffset, 'minutes', TimeFormat.DATE_ONLY);
			if (!_startDate) {
				throw new Error('Failed to offset startDate');
			}

			const newEntry = {
				entry: {
					...entry as TimeSheetEntryFormModel,
					endTime: _endTime,
					startTime: _startTime,
					startDate: _startDate,
				},
				type: TimeSheetEntryFormType.ENTRY,
			};
			onEditingEntry(index, newEntry as TimeSheetEntryWithType);
		}
	}, [change, entry, fieldNameEndTime, fieldNameStartTime, index, onEditingEntry]);

	const minDate = React.useMemo(() => TimeUtils.addDays(dueDate, 0), [dueDate]);
	const maxDate = React.useMemo(() => TimeUtils.addDays(dueDate, 1), [dueDate]);

	const startEndTimeDiff = endTime && startTime
		? TimeUtils.minutesToHoursAndMinutes(TimeUtils.getDiff(endTime, startTime, 'minutes', TimeFormat.ISO_DATETIME))
		: TimeUtils.minutesToHoursAndMinutes(0);

	const workTypeIcon = icon ? TimeSheetWorkTypeDisplayInfo[icon] : null;
	const hasChanges = (
		!!(startTime && initialStartTime.current) && TimeUtils.toDate(startTime, TimeFormat.ISO_DATETIME)?.getTime() !== initialStartTime.current.getTime() ||
		(!!(endTime && initialEndTime.current) && TimeUtils.toDate(endTime, TimeFormat.ISO_DATETIME)?.getTime() !== initialEndTime.current.getTime()) ||
		startDate !== initialStartDate.current ||
		workType !== initialWorkType.current ||
		editingEntryInitialEntry && !isNew && (editingEntryInitialEntry.entry.startTime !== startTime
			|| editingEntryInitialEntry.entry.endTime !== endTime
			|| editingEntryInitialEntry.entry.startDate !== startDate)
	);

	const hasSyncErrors = (syncErrors?.startTime || syncErrors?.endTime || syncErrors?.startDate) ? true : false;
	const isSaveDisabled = !startTime || !endTime || !startDate || !icon || !hasChanges || hasSyncErrors;

	return (
		<div className={bemElement('time-sheet-edit-modal', 'timeline__new-entry')}>
			<Row className="row--column-aligned row--padded-medium">
				<Col sm={2}>
					{workTypeIcon && <span className={`${bemElement('time-sheet-edit-modal', 'timeline__new-entry__icon')} icon-${workTypeIcon.icon}`} />}
				</Col>
				<Col sm={6}>
					<Field
						className={bemElement('time-sheet-edit-modal', 'timeline__new-entry__input-field')}
						component={Dropdown}
						id={fieldNameWorkType}
						isStandalone={true}
						labelKey="label"
						name={fieldNameWorkType}
						onValueChange={onWorkTypeChange}
						options={timeSheetEntryTypeOptions}
						placeholder="Work Type"
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col sm={5}>
					<Field
						alwaysShowErrors={true}
						className={bemElement('time-sheet-edit-modal', 'timeline__new-entry__input-field')}
						component={TimePicker}
						containerClassName={bemElement('time-sheet-edit-modal', 'timeline__new-entry__input-wrapper')}
						disableErrorMessage={true}
						forceErrorClassname={overlap?.startTime}
						id={fieldNameStartTime}
						name={fieldNameStartTime}
						normalize={normalizeStartTime}
						timeZoneInUse={timeZoneInUse}
					/>
				</Col>
				<Col className={bemElement('time-sheet-edit-modal', 'timeline__new-entry__minus-sign')} sm={1}>
					-
				</Col>
				<Col sm={5}>
					<Field
						alwaysShowErrors={true}
						className={bemElement('time-sheet-edit-modal', 'timeline__new-entry__input-field')}
						component={TimePicker}
						containerClassName={bemElement('time-sheet-edit-modal', 'timeline__new-entry__input-wrapper')}
						disableErrorMessage={true}
						forceErrorClassname={overlap?.endTime}
						id={fieldNameEndTime}
						name={fieldNameEndTime}
						normalize={normalizeEndTime}
						timeZoneInUse={timeZoneInUse}
					/>
				</Col>
				<Col className={bemElement('time-sheet-edit-modal', 'timeline__new-entry__total')} sm={4}>
					{startEndTimeDiff}
				</Col>
				<Col sm={1}>
					<Tooltip message="Cancel">
						<span className={`${bemElement('time-sheet-edit-modal', 'timeline__new-entry__close')} icon-close`} onClick={handleCancel} />
					</Tooltip>
				</Col>
			</Row>
			<Row className="row--column-aligned row--padded-medium">
				<Col sm={8}>
					{
						!isNew &&
						<span className="time-sheet-edit-modal__timeline__edit-entry__delete-container">
							<div className="time-sheet-edit-modal__timeline__edit-entry__delete" onClick={handleDelete}>
								<span className="icon-delete" />
								<span className="time-sheet-edit-modal__timeline__edit-entry__delete__text">
									Delete
								</span>
							</div>
						</span>
					}
				</Col>
				<Col sm={11}>
					<Field
						calendarSettings={{ minDate: minDate.toDate(), maxDate: maxDate.toDate() }}
						className={bemElement('time-sheet-edit-modal', 'timeline__new-entry__input-field')}
						component={DateInput}
						dateFormat={TimeFormat.DATE_ONLY}
						disableErrorMessage={true}
						fixed={true}
						isStandalone={true}
						name={fieldNameStartDate}
						onValueChange={onDateChange}
						originalDateFormat={TimeFormat.DATE_ONLY}
						placeholderText="Start Date"
					/>
				</Col>
				<Col sm={5}>
					<SubmitButton
						disabled={isSaveDisabled}
						label="Save"
						onClick={onSave}
						variant="primary"
					/>
				</Col>
			</Row>
		</div>
	);
};

export default React.memo(TimeSheetEntryNewOrEdit);
