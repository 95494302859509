import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { FieldArray, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { Col } from 'react-bootstrap';

import FormFieldArrayStatusEnum from 'acceligent-shared/enums/formFieldArrayStatus';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';

import { RootState } from 'af-reducers';

import * as ReportTypeActions from 'af-actions/reportType';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import ReportTypeArray, { OwnProps as ReportTypeArrayProps } from './ReportTypeArray';

import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import Section from '../../Shared/Section';

import WorkOrderFM from '../../formModel';

interface OwnProps {
	resetTimer: () => void;
}

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<WorkOrderFM>;

class ReportTypes extends React.PureComponent<Props> {

	static mapUpdatedReportTypes = (wort: WorkOrderFM['workOrderReportTypeLookup'][0], index: number) => {
		return {
			...wort,
			reportTypeLookup: {
				...wort.reportTypeLookup,
				index,
			},
			status: wort.status === FormFieldArrayStatusEnum.OLD ? FormFieldArrayStatusEnum.EDITED : wort.status,
		};
	};

	async componentDidMount() {
		const { loadRequiredReportTypes, change, isCreate } = this.props;
		const requiredReportTypes = await loadRequiredReportTypes();
		if (isCreate) {
			change(
				'workOrderReportTypeLookup',
				requiredReportTypes.
					sort((_rtA, _rtB) => {
						if (!_rtA.defaultIndex) {
							return -1;
						}
						if (!_rtB.defaultIndex) {
							return 1;
						}
						return _rtA.defaultIndex - _rtB.defaultIndex;
					}).
					map((_rt, _index) => ({ status: 'ADDED', reportTypeLookup: { reportTypeId: _rt.id, name: _rt.name, isRequired: true, index: _index } }))
			);
		}
	}

	onDragEnd = ({ source, destination }: DropResult) => {
		const { selector, change } = this.props;

		if (!destination) {
			return;
		}

		const reportTypes = selector('workOrderReportTypeLookup') as WorkOrderFM['workOrderReportTypeLookup'];

		if (source.index === destination?.index) {
			return;
		}

		const [movedItem] = reportTypes.splice(source.index, 1);

		reportTypes.splice(destination?.index, 0, movedItem);

		const updatedReportTypes = reportTypes.map(ReportTypes.mapUpdatedReportTypes);

		change('workOrderReportTypeLookup', updatedReportTypes);
	};

	renderDroppable = () => {
		const { resetTimer, selector, loadReportTypes, change, hasReorderReportTypesPermission, isPaused, isLocked } = this.props;
		return (
			<Droppable direction="vertical" droppableId="report-type-lookup">
				{(droppableProvided) => (
					<div ref={droppableProvided.innerRef}>
						<FieldArray<ReportTypeArrayProps>
							change={change}
							component={ReportTypeArray}
							hasReorderReportTypesPermission={hasReorderReportTypesPermission}
							isEditable={!isPaused && !isLocked}
							loadReportTypes={loadReportTypes}
							name="workOrderReportTypeLookup"
							resetTimer={resetTimer}
							selector={selector}
						/>
					</div>
				)}
			</Droppable>
		);
	};

	render() {
		return (
			<Section label="Field Report Types">
				<Col md={8}>
					<DragDropContext onDragEnd={this.onDragEnd}>
						{this.renderDroppable()}
					</DragDropContext>
				</Col>
			</Section>
		);
	}
}

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const { workOrder, user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasReorderReportTypesPermission = isAllowed(
		PagePermissions.COMPANY.FIELD_REPORT.REORDER_RTS,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		hasReorderReportTypesPermission,
		isCreate: !workOrder.order?.id,
		isPaused: workOrder.order?.isPaused ?? false,
		isLocked: workOrder.order?.status === WorkOrderStatus.LOCKED,
		selector: (fieldName: string) => selector(state, fieldName),
	};
}

function mapDispatchToProps() {
	return {
		loadReportTypes: ReportTypeActions.findCustomShallowActiveList,
		loadRequiredReportTypes: ReportTypeActions.findRequiredShallowActiveList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());
const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<WorkOrderFM>({ form: WORK_ORDER_FORM })
);

export default enhance(ReportTypes);
