import * as React from 'react';

interface OwnProps {
	isAvailable: boolean;
	label: string;
	statusLabelClassname?: string;
}

type Props = OwnProps;

class StatusLabel extends React.PureComponent<Props> {

	render() {
		const { isAvailable, label, statusLabelClassname } = this.props;

		let className = 'status-label__icon';
		className = isAvailable ? `${className} icon-dot` : `${className} icon-dot_outline`;
		className = isAvailable ? `${className} status-label__icon--available` : `${className} status-label__icon--unavailable`;

		return (
			<div className={'status-label' + (statusLabelClassname ?? '')}>
				<span className={className} />
				{label}
			</div>
		);
	}
}

export default StatusLabel;
