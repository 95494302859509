import * as React from 'react';

interface OwnProps {
	ifTrue?: string;
	ifFalse?: string;
	value: boolean;
}

type Props = OwnProps;

const BooleanCell = ({ ifTrue = 'Yes', ifFalse = 'No', value }: Props) => {
	return (
		<span className="boolean-cell">
			{
				value
					? <><span className="icon-check boolean-cell__true"/>{ifTrue}</>
					: <><span className="icon-close boolean-cell__false"/>{ifFalse}</>
			}
		</span>
	);
};

export default BooleanCell;
