import * as React from 'react';
import { formValueSelector } from 'redux-form';
import { ConnectedProps, connect } from 'react-redux';

import WorkRequestJobHazardAssessmentStatus from 'acceligent-shared/enums/workRequestJobHazardAssessmentStatus';

import { RootState } from 'af-reducers';

import RadioGroup from 'af-fields/RadioGroup';

import WorkRequestUpsertFM from '../../formModel';
import { formSectionClass, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../helpers';

import styles from '../../styles.module.scss';

const JOB_HAZARD_ASSESSMENT_OPTIONS = Object.keys(WorkRequestJobHazardAssessmentStatus)
	.map((_dm) => {
		switch (_dm) {
			case (WorkRequestJobHazardAssessmentStatus.COMPLETE): {
				return { value: _dm, label: 'Complete' };
			}

			case (WorkRequestJobHazardAssessmentStatus.IN_PROGRESS): {
				return { value: _dm, label: 'In Progress' };
			}

			case (WorkRequestJobHazardAssessmentStatus.NOT_STARTED): {
				return { value: _dm, label: 'Not Started' };
			}

			default: {
				return { value: _dm, label: 'Not Started' };
			}
		}
	});

type OwnProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (fieldName: string, value: any) => void;
	formName: string;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const StatusSection: React.FC<Props> = (props) => {
	const { change, jobHazardAssessmentStatus } = props;

	const onDisposalMethodChange = React.useCallback((field: string, value) => {
		change(field, value);
	}, [change]);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Status
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					{!jobHazardAssessmentStatus && (
						<div className={styles['work-request-form__job-hazard-assessment-status-error']}>
							Please select current status of the Job Hazard Assessment Form.
						</div>
					)}
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					<RadioGroup
						changeField={onDisposalMethodChange}
						extraClass={styles['work-request-form__radio-group']}
						field={WorkRequestUpsertFM.getAttributeName('jobHazardAssessmentStatus')}
						inline={true}
						items={JOB_HAZARD_ASSESSMENT_OPTIONS}
					/>
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState, props: OwnProps) {
	const selector = formValueSelector(props.formName);

	const jobHazardAssessmentStatus = selector(
		state,
		WorkRequestUpsertFM.getAttributeName('jobHazardAssessmentStatus')
	);

	return {
		jobHazardAssessmentStatus,
	};
}

const connector = connect(mapStateToProps);

export default connector(StatusSection);
