import * as React from 'react';

import { PCFieldReportVM, PCFRTypeVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import FieldReportType from './FieldReportType';

interface OwnProps {
	fieldReport: PCFieldReportVM;
}

type Props = OwnProps;

const renderFieldReportType = (frt: PCFRTypeVM) => <FieldReportType fieldReportType={frt} key={`report-type-${frt.index}`} />;

const FieldReport: React.FC<Props> = (props: Props) => {
	const { title, fieldReportTypes } = props.fieldReport;

	return (
		<>
			<div className="public-report__report-info">
				<div className="public-report__report-info__title-row">
					<div className="public-report__report-info__title-row__title">
						{title}
					</div>
				</div>
			</div>
			{fieldReportTypes.map(renderFieldReportType)}
		</>
	);
};

export default React.memo(FieldReport);
