import * as React from 'react';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import TextArea from 'af-fields/PlainTextArea';

import styles from './styles.module.scss';

interface Props {
	closeModal: () => void;
	showModal: boolean;
	handleMarkAsLostSave: (lostForReason: string) => Promise<void>;
}

const MAX_CHARACTERS_LOST_REASON = 300;

const WorkRequestReasonForLostModal: React.FC<Props> = (props) => {
	const { closeModal, showModal, handleMarkAsLostSave } = props;
	const [reasonForLost, setReasonForLost] = React.useState<string>('');

	const onReasonForLostChange = React.useCallback((value: string) => {
		setReasonForLost(value);
	}, []);

	React.useEffect(() => {
		if (!showModal) {
			setReasonForLost('');
		}
	}, [showModal]);

	const onSaveClick = React.useCallback(async () => {
		closeModal();
		await handleMarkAsLostSave(reasonForLost);
	}, [closeModal, handleMarkAsLostSave, reasonForLost]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Work Request Status: Lost"
			/>
			<CustomModal.Body>
				<div className={styles['lost-for-reason-modal']}>
					Reason of Lost Work Request *
					<TextArea
						maxCharacters={MAX_CHARACTERS_LOST_REASON}
						onValueChange={onReasonForLostChange}
						placeholder="Enter text"
						rows={4}
						showMaxCharactersLabel={true}
						value={reasonForLost}
					/>
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={!reasonForLost}
					label="Save"
					onClick={onSaveClick}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default React.memo(WorkRequestReasonForLostModal);
