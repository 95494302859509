import * as React from 'react';
import { Link } from 'react-router-dom';

import CLIENT from 'af-constants/routes/client';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

interface Props {
	workOrderId: number;
	orgAlias: string;
	companyName: string;
	text?: string;
	disabled?: boolean;
	onHide: () => void;
	scheduleBoardView: ScheduleBoardView;
}

const EditButton = ({ scheduleBoardView, workOrderId, orgAlias, companyName, text = 'Edit', disabled, onHide }: Props) => {

	let originUrl = CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName);

	if (scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW) {
		originUrl = CLIENT.COMPANY.SCHEDULE_BOARD.WEEKLY_VIEW(orgAlias, companyName);
	}

	let className = 'btn btn-info work-order-info-modal__header-edit';
	className = disabled ? `${className} disabled` : className; // .disabled as modifier on .btn

	return (
		<Link
			className={className}
			onClick={onHide}
			to={{
				pathname: CLIENT.COMPANY.WORK_ORDERS.ORDER(`${workOrderId}`, orgAlias, companyName),
				state: { originUrl, originLabel: 'Schedule Board' },
			}}
		>
			{text || 'Edit'}
		</Link>
	);

};

export default EditButton;
