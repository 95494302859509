import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import Checkbox from 'af-fields/Checkbox';

import Section from '../Shared/Section';

interface OwnProps {
	disabled: boolean;
}

type Props = OwnProps;

const WorkOrderNotifications: React.FC<Props> = (props) => {

	const { disabled } = props;

	return (
		<Section label="Notifications">
			<Col sm={24}>
				<Field
					component={Checkbox}
					isDisabled={disabled}
					isStandalone={true}
					label="Exclude Work Order from Default Notifications"
					name="excludeFromNotify"
				/>
			</Col>
			<Col sm={24}>
				<Field
					component={Checkbox}
					isDisabled={disabled}
					isStandalone={true}
					label="Exclude Temporary Labor from Notifications"
					name="excludeTempLaborFromNotify"
				/>
			</Col>
		</Section>
	);
};

export default React.memo(WorkOrderNotifications);
