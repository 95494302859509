import * as React from 'react';

import { Field } from 'redux-form';

import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';

import styles from '../../../styles.module.scss';

type Props = {
	fieldName: string;
	onRemoveClick: () => void;
};

const AdditionalField: React.FC<Props> = ({ fieldName, onRemoveClick }) => {
	return (
		<div className={styles['job-form__trucks-and-equipment__additional-field']}>
			<Field
				component={Checkbox}
				name={`${fieldName}.isChecked`}
			/>
			<Field
				className={styles['job-form__trucks-and-equipment__additional-field__name']}
				component={Input}
				name={`${fieldName}.name`}
				type="text"
			/>
			<div className={styles['job-form__trucks-and-equipment__additional-field__delete-button']}>
				<span className="icon-delete" onClick={onRemoveClick} />
			</div>
		</div>
	);
};

export default React.memo(AdditionalField);
