import * as React from 'react';
import { compose } from 'redux';

import * as SettingsUtils from 'af-utils/settings.util';

import * as SettingsKeys from 'af-constants/settingsKeys';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import Breadcrumbs from 'af-components/Breadcrumbs';
import TabNavigation from 'af-components/TabNavigation';

import TruckTable from '../Equipment/Table';
import ItemTable from '../Item/Table';
import ToolTable from '../Tool/List';

const BREADCRUMBS = [{ label: 'Equipment' }];

const TAB_IDS = {
	EQUIPMENT: 0,
	SMALL_TOOL: 1,
	ACCESSORY: 2,
};

const TABS = [
	{ id: TAB_IDS.EQUIPMENT, label: 'Equipment' },
	{ id: TAB_IDS.SMALL_TOOL, label: 'Small tools' },
	{ id: TAB_IDS.ACCESSORY, label: 'Accessories' },
];

interface SettingProps {
	tabId: number;
}

type Props = SettingProps;

interface State {
	activeTabId: number;
	disableTab: boolean;
}

class EquipmentList extends React.PureComponent<Props, State> {
	state: State = {
		activeTabId: this.props.tabId ?? TAB_IDS.EQUIPMENT,
		disableTab: DisabledFeatures.Inventory,
	};

	selectTab = (tabId: number) => {
		this.setState(() => {
			return { activeTabId: tabId };
		}, () => {
			SettingsUtils.setItem(SettingsKeys.INVENTORY_TAB(), `${tabId}`, BrowserStorageEnum.LOCAL_STORAGE);
		});
	};

	renderTab = () => {
		switch (this.state.activeTabId) {
			case TAB_IDS.SMALL_TOOL:
				return <ToolTable />;
			case TAB_IDS.ACCESSORY:
				return <ItemTable />;
			case TAB_IDS.EQUIPMENT:
			default:
				return <TruckTable />;
		}
	};

	render() {
		return (
			<div className="form-segment">
				<Breadcrumbs items={BREADCRUMBS} />
				<div className="table-container table-container--tabbed">
					<div>
						{!this.state.disableTab && (
							<TabNavigation
								active={this.state.activeTabId}
								onClick={this.selectTab}
								tabs={TABS}
							/>
						)}
						{this.renderTab()}
					</div>
				</div>
			</div>
		);
	}
}

const enhance = compose<React.ComponentClass>(SettingsUtils.withSettings<SettingProps>(() => ([
	{
		key: SettingsKeys.INVENTORY_TAB(),
		mappedName: 'tabId',
		normalize: (item: string) => DisabledFeatures.Inventory ? TAB_IDS.EQUIPMENT : +item, // override value if tabs are disabled
		defaultValue: TAB_IDS.EQUIPMENT,
		source: BrowserStorageEnum.LOCAL_STORAGE,
	},
])));

export default enhance(EquipmentList);
