const _isInteger = (value: number) => {
	return value % parseInt(value.toString()) === 0;
};

export const formatDecimalNumber = (value: number | string, minimumFractionDigits: number = 2, maximumFractionDigits: number = 2): string => {
	value = value || 0;
	value = typeof value === 'number' ? value : parseFloat(value);
	return _isInteger(value)
		? value.toLocaleString()
		: value.toLocaleString('en', { minimumFractionDigits, maximumFractionDigits });
};

export const aliasNormalizer = (value: string): string => value.replace(/([^a-z0-9]+)/gi, '');

export const moneyNormalizer = (value: number | string | null, minimumFractionDigits: number = 2, maximumFractionDigits: number = 2): string => {
	if (!value && value !== 0) {
		return '-';
	}
	return `$ ${formatDecimalNumber(value, minimumFractionDigits, maximumFractionDigits)}`;
};

export const prettyMoney = (value: number): string => {
	if (value > 1e9) {
		return `$ ${Math.floor(value / 1e9)}B`;
	} else if (value > 1e6) {
		return `$ ${Math.floor(value / 1e6)}M`;
	} else if (value > 1e3) {
		return `$ ${Math.floor(value / 1e3)}K`;
	} else if (value < -1e9) {
		return `$ ${Math.floor(value / 1e9)}B`;
	} else if (value < -1e6) {
		return `$ ${Math.floor(value / 1e6)}M`;
	} else if (value < -1e3) {
		return `$ ${Math.floor(value / 1e3)}K`;
	} else {
		return `$ ${Math.floor(value)}`;
	}
};

export const formatPercentage = (value: number | string): string => {
	if (!value || value === Infinity) {
		return '0.00 %';
	}
	return `${formatDecimalNumber(value)} % `;
};

export const yesOrNoFormat = (value: boolean) => value ? 'Yes' : 'No';

const UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const prettyBytes = (num: number) => {
	if (!Number.isFinite(num)) {
		throw new TypeError(`Expected a finite number, got ${typeof num}: ${num} `);
	}

	const neg = num < 0;

	if (neg) {
		num = -num;
	}

	if (num < 1) {
		return (neg ? '-' : '') + num + ' B';
	}

	const exponent = Math.min(Math.floor(Math.log10(num) / 3), UNITS.length - 1);
	const numStr = Number((num / Math.pow(1000, exponent)).toPrecision(3));
	const unit = UNITS[exponent];

	return (neg ? '-' : '') + numStr + ' ' + unit;
};

interface GetStreetAddress {
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
}
export const getStreetAddress = (address: GetStreetAddress): string => {
	return getStreetAddressPlain(address.street, address.streetNumber, address.route);
};

export const getStreetAddressPlain = (street: string, streetNumber: Nullable<string>, route: Nullable<string>): string => {
	if (route) {
		return streetNumber
			? `${streetNumber} ${route}`
			: route;
	}
	return street ? street.split(',')[0] : '';
};

interface GetAddressPostalLocation {
	city: Nullable<string>;
	stateAbbreviation: Nullable<string>;
	state: Nullable<string>;
	zip: Nullable<string>;
}
export const getAddressPostalLocation = (address: GetAddressPostalLocation): string => {
	return `${address.city ?? ''} ${address.stateAbbreviation ?? address.state} ${address.zip ?? ''}`.trim();
};
