import * as React from 'react';
import { compose } from 'redux';
import { reduxForm, InjectedFormProps, getFormValues } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';

import { TotalBlockType } from 'acceligent-shared/enums/reportBlockType';
import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import OperandType from 'acceligent-shared/enums/operand';

import { RootState } from 'af-reducers';

import { bemElement } from 'ab-utils/bem.util';

import * as BuilderCalculationUtils from 'af-utils/builderCalculation.utils';

import TabNavigation from 'af-components/TabNavigation';

import { REPORT_BLOCK_PREVIEW } from 'af-constants/reduxForms';

import ReportBlock from '../../Shared/Preview/ReportBlock';
import PreviewTabsEnum, { PREVIEW_TABS } from '../../Shared/previewTabs.enum';
import { ReportTypeBlockFormModel, ReportBlockFieldFormModel, ReportBlockFormModel, CalculatedFieldOptionFormModel } from '../../Shared/formModel';
import { HighlightedBillableWorkBlockFields } from '../Shared/values';

const DEFAULT_FORM_VALUES = {};

interface OwnProps {
	calculationFieldMap: { [id: string]: true; };
	currentlyHighlightedFields: HighlightedBillableWorkBlockFields;
	lowerTotalBlockId: Nullable<string>;
	primaryTypeBlockIds: string[];
	primaryTypeMainBlockId: Nullable<string>;
	reportTypeBlockByIdMap: { [id: string]: ReportTypeBlockFormModel; };
	reportBlockByIdMap: { [id: string]: ReportBlockFormModel; };
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; };
	secondaryTypeBlockIds: string[];
	secondaryTypeMainBlockId: Nullable<string>;
	upperTotalBlockId: Nullable<string>;
}

type Props = OwnProps & InjectedFormProps<Record<string, unknown>, OwnProps> & ConnectedProps<typeof connector>;

const _getOperandMapper = (
	fieldsByIdMap: { [virtualId: string]: ReportBlockFieldFormModel; },
	blocksByIdMap: { [id: string]: ReportBlockFormModel; },
	isRepeating: boolean,
	reportBlockVirtualId: string
) => {
	return (operand: CalculatedFieldOptionFormModel): BuilderCalculationUtils.Operand => {
		const { id, constant, type } = operand;

		if (!id) {
			throw new Error('Missing field id');
		}

		if (type === OperandType.CONSTANT) {
			return {
				virtualId: id,
				blockId: reportBlockVirtualId,
				fieldType: null,
				unit: null,
				constant,
				isRepeating,
				type: OperandType.CONSTANT,
			};
		}
		const field = fieldsByIdMap[id];
		if (!field) {
			throw new Error('Missing field in operand mapper');
		}
		const block = blocksByIdMap[field.reportBlockVirtualId];
		if (!block) {
			throw new Error('Missing block in operand mapper');
		}
		return {
			virtualId: id,
			blockId: field.reportBlockVirtualId,
			fieldType: field.fieldType,
			unit: field.unit,
			constant: null,
			isRepeating: field.fieldType === ReportBlockFieldEnum.Type.COMPLETION
				? false
				: block.isRepeating,
			type: OperandType.FIELD,
		};
	};
};

const _getOperandReducer = (
	fieldId: string,
	operands: CalculatedFieldOptionFormModel[],
	reportFieldsByIdMap: { [virtualId: string]: ReportBlockFieldFormModel; },
	reportBlockByIdMap: { [id: string]: ReportBlockFormModel; }
) => {
	return (acc: BuilderCalculationUtils.CalculationMapType, option: CalculatedFieldOptionFormModel) => {
		const { id } = option;

		const _field = reportFieldsByIdMap[fieldId];
		if (!_field.operationType) {
			throw new Error('Missing operation type');
		}

		if (!id) {
			throw new Error('Missing field id');
		}

		if (!acc[id]) {
			acc[id] = {};
		}
		const _block = reportBlockByIdMap[_field.reportBlockVirtualId];

		acc[id][fieldId] = {
			operationType: _field.operationType,
			blockId: _field.reportBlockVirtualId,
			isRepeating: _block.isRepeating,
			unit: _field.unit,
			operands: operands.map(_getOperandMapper(reportFieldsByIdMap, reportBlockByIdMap, _block.isRepeating, _field.reportBlockVirtualId)),
		};
		return acc;
	};
};

const _getCalculationsReducer = (
	reportFieldsByIdMap: { [virtualId: string]: ReportBlockFieldFormModel; },
	reportBlockByIdMap: { [id: string]: ReportBlockFormModel; }
) => {
	return (acc: BuilderCalculationUtils.CalculationMapType, resultFieldId: string) => {
		const field = reportFieldsByIdMap[resultFieldId];
		if (!field) {
			throw new Error('Missing field');
		}
		const reducer = _getOperandReducer(
			resultFieldId,
			field.calculatedFieldOptions ?? [],
			reportFieldsByIdMap,
			reportBlockByIdMap
		);
		return (field.calculatedFieldOptions ?? []).reduce(reducer, acc);
	};
};

const _getCalculationMap = (
	calculationFieldMap: { [id: string]: true; },
	reportFieldsByIdMap: { [virtualId: string]: ReportBlockFieldFormModel; },
	reportBlockByIdMap: { [id: string]: ReportBlockFormModel; }
) => {
	return Object.keys(calculationFieldMap ?? {}).reduce(
		_getCalculationsReducer(reportFieldsByIdMap, reportBlockByIdMap),
		{}
	);
};

const ReportTypeCustomTypeFormPreview: React.FC<Props> = (props: Props) => {
	const {
		change,
		calculationFieldMap,
		currentlyHighlightedFields,
		lowerTotalBlockId,
		reportBlockByIdMap,
		reportFieldsByIdMap,
		reportTypeBlockByIdMap,
		formValues,
		upperTotalBlockId,
		primaryTypeBlockIds,
		secondaryTypeBlockIds,
		primaryTypeMainBlockId,
		secondaryTypeMainBlockId,
	} = props;

	const [primarySegmentExpanded, setPrimarySegmentExpanded] = React.useState<boolean>(true);
	const [secondarySegmentExpanded, setSecondarySegmentExpanded] = React.useState<boolean>(true);
	const [activeTab, setActiveTab] = React.useState<number>(PreviewTabsEnum.PREVIEW);
	const [calculationMap, setCalculationMap] = React.useState<BuilderCalculationUtils.CalculationMapType>({});

	React.useEffect(() => {
		setCalculationMap(
			calculationFieldMap
				? _getCalculationMap(calculationFieldMap, reportFieldsByIdMap, reportBlockByIdMap)
				: {}
		);
	}, [calculationFieldMap, reportFieldsByIdMap, reportBlockByIdMap]);

	const changeActiveTab = React.useCallback((id: number) => setActiveTab(id), []);

	const togglePrimary = React.useCallback(() => setPrimarySegmentExpanded(!primarySegmentExpanded), [primarySegmentExpanded]);
	const toggleSecondary = React.useCallback(() => setSecondarySegmentExpanded(!secondarySegmentExpanded), [secondarySegmentExpanded]);

	const onFieldValueChange = React.useCallback((id: string, newValue: string, index?: number) => {
		BuilderCalculationUtils.calculateOnFieldValueChange(id, newValue, calculationMap, formValues, change, index);
	}, [calculationMap, formValues, change]);

	const onRemoveRepeatableFields = React.useCallback((fields: Record<string, true>, index: number) => {
		BuilderCalculationUtils.calculateOnRemoveFields(fields, calculationMap, formValues, change, index);
	}, [calculationMap, formValues, change]);

	const onCalculatedFieldChange = React.useCallback((id: string, isRepeating: boolean) => {
		const field = reportFieldsByIdMap[id];
		if (!field) {
			throw new Error('Missing field');
		}
		BuilderCalculationUtils.calculateOnFieldChange(field, reportFieldsByIdMap, reportBlockByIdMap, isRepeating, calculationMap, formValues, change);
	}, [calculationMap, reportFieldsByIdMap, reportBlockByIdMap, formValues, change]);

	const onCompletionFieldChange = React.useCallback((id: string, value: boolean) => {
		BuilderCalculationUtils.calculateOnCompletionFieldChange(id, value, calculationMap, formValues, change);
	}, [calculationMap, formValues, change]);

	const renderBlock = (reportTypeBlockId: string, isPrimarySegment: boolean) => {
		const highlightVisibleToCustomer = activeTab === PreviewTabsEnum.PREVIEW;

		const typeBlock = reportTypeBlockByIdMap[reportTypeBlockId];
		if (!typeBlock) {
			throw new Error('Missing report type block');
		}
		const block = reportBlockByIdMap[typeBlock.reportBlockVirtualId];
		if (!block) {
			throw new Error('Missing report block');
		}

		return (
			<ReportBlock
				completionFieldId={block.completionFieldVirtualId ?? null}
				currentlyHighlightedFields={currentlyHighlightedFields}
				filterVisibleToCustomer={activeTab === PreviewTabsEnum.CUSTOMER_VIEW}
				form={REPORT_BLOCK_PREVIEW}
				formValues={formValues}
				hasCompletionStatus={block.hasCompletionStatus}
				highlightVisibleToCustomer={highlightVisibleToCustomer}
				id={block.virtualId}
				isInPrimarySegment={isPrimarySegment}
				isMain={block.isMain}
				isRepeating={block.isRepeating}
				isSegmentExpanded={isPrimarySegment ? primarySegmentExpanded : secondarySegmentExpanded}
				key={typeBlock.reportBlockVirtualId ?? block.name}
				name={block.name}
				onCalculatedFieldChange={onCalculatedFieldChange}
				onCompletionFieldChange={onCompletionFieldChange}
				onFieldValueChange={onFieldValueChange}
				onRemoveRepeatableFields={onRemoveRepeatableFields}
				onToggleSegment={isPrimarySegment ? togglePrimary : toggleSecondary}
				reportBlockFieldIds={block.reportBlockFieldIds}
				reportFieldsByIdMap={reportFieldsByIdMap}
				type={typeBlock.type}
			/>
		);
	};

	const renderTotalBlock = (typeBlock: Nullable<ReportTypeBlockFormModel>, type: TotalBlockType) => {
		if (!typeBlock) {
			return null;
		}
		const block = reportBlockByIdMap[typeBlock.reportBlockVirtualId];
		if (!block) {
			throw new Error('Missing block');
		}

		const className = bemElement('report-type', 'primary', {
			'margin-top': type === TotalBlockType.UPPER,
			'margin-bottom': type === TotalBlockType.LOWER,
		});

		return (
			<div className={className}>
				<ReportBlock
					completionFieldId={block.completionFieldVirtualId ?? null}
					currentlyHighlightedFields={currentlyHighlightedFields}
					filterVisibleToCustomer={activeTab === PreviewTabsEnum.CUSTOMER_VIEW}
					form={REPORT_BLOCK_PREVIEW}
					formValues={formValues}
					hasCompletionStatus={block.hasCompletionStatus}
					highlightVisibleToCustomer={highlightVisibleToCustomer}
					id={block.virtualId}
					isInPrimarySegment={true}
					isMain={block.isMain}
					isRepeating={block.isRepeating}
					key={block.id ?? block.name}
					name={block.name}
					onCalculatedFieldChange={onCalculatedFieldChange}
					onCompletionFieldChange={onCompletionFieldChange}
					onFieldValueChange={onFieldValueChange}
					onRemoveRepeatableFields={onRemoveRepeatableFields}
					reportBlockFieldIds={block.reportBlockFieldIds}
					reportFieldsByIdMap={reportFieldsByIdMap}
					type={typeBlock.type}
				/>
			</div>
		);
	};

	const isEmpty = React.useMemo(() => {
		return !primaryTypeBlockIds?.length
			&& !secondaryTypeBlockIds?.length
			&& !primaryTypeMainBlockId
			&& !secondaryTypeMainBlockId
			&& !upperTotalBlockId
			&& !lowerTotalBlockId;
	}, [
		primaryTypeBlockIds,
		secondaryTypeBlockIds,
		primaryTypeMainBlockId,
		secondaryTypeMainBlockId,
		upperTotalBlockId,
		lowerTotalBlockId,
	]);

	const renderPrimarySegmentBlock = (blockId: string) => renderBlock(blockId, true);
	const renderSecondarySegmentBlock = (blockId: string) => renderBlock(blockId, false);

	const highlightVisibleToCustomer = activeTab === PreviewTabsEnum.PREVIEW;

	return (
		<>
			<TabNavigation
				active={activeTab}
				onClick={changeActiveTab}
				tabs={PREVIEW_TABS}
			/>
			<div className="report-block-form report-block-form__sticky-sidebar__content">
				{highlightVisibleToCustomer && (
					<div className="report-block-form__legend">
						<span className="text-grey">Note: Red fields are visible to customer</span>
					</div>
				)}
				{isEmpty &&
					<div className="report-block">
						<div className="report-block__empty-preview">Add fields to see preview of your Report</div>
					</div>
				}
				<div className="report-type">
					{upperTotalBlockId && renderTotalBlock(reportTypeBlockByIdMap[upperTotalBlockId], TotalBlockType.UPPER)}
					<div className="report-type__primary">
						{primaryTypeMainBlockId && renderPrimarySegmentBlock(primaryTypeMainBlockId)}
						{primaryTypeBlockIds?.map(renderPrimarySegmentBlock)}
					</div>
					<div className="report-type__secondary">
						{secondaryTypeMainBlockId && renderSecondarySegmentBlock(secondaryTypeMainBlockId)}
						{secondaryTypeBlockIds?.map(renderSecondarySegmentBlock)}
					</div>
					{lowerTotalBlockId && renderTotalBlock(reportTypeBlockByIdMap[lowerTotalBlockId], TotalBlockType.LOWER)}
				</div>
			</div>
		</>
	);
};

const getForm = getFormValues(REPORT_BLOCK_PREVIEW);

function mapStateToProps(state: RootState) {
	return {
		formValues: (getForm(state) ?? DEFAULT_FORM_VALUES) as Record<string, string>[],
	};
}

const connector = connect(mapStateToProps, null, null, {
	areStatesEqual: (nextState, prevState) => nextState.form[REPORT_BLOCK_PREVIEW] === prevState.form[REPORT_BLOCK_PREVIEW],
});

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector,
	reduxForm<Record<string, unknown>, OwnProps>({ form: REPORT_BLOCK_PREVIEW })
);

export default enhance(ReportTypeCustomTypeFormPreview);
