import * as React from 'react';

import SubscriptionStatus from 'acceligent-shared/enums/subscriptionStatus';

import Checkbox from 'af-components/Controls/Checkbox';
import Tooltip from 'af-components/Tooltip';
import LabelWithColor from 'af-components/LabelWithColor';
import Label from 'af-components/LockedValue/Label';

import { EmployeeNotificationStatusDict } from 'af-models/employeeNotifyModal.model';

import { NOTIFY_MODAL_COL_SPAN, NOTIFY_EMPLOYEE_TYPE } from 'af-constants/values';

import EmailStatusInfo from './EmailStatusInfo';
import SmsStatusInfo from './SmsStatusInfo';

interface OwnProps {
	employees: EmployeeNotificationStatusDict;
	isSending: boolean;
	onCheck: (id: number, type: NOTIFY_EMPLOYEE_TYPE) => void;
	showSubscribedColumns: boolean;
	subscribedAt: string;
}

type Props = OwnProps;

class EmployeeList extends React.PureComponent<Props> {

	wrapUnableToNotify = (type: NOTIFY_EMPLOYEE_TYPE) => {
		return (
			<Tooltip
				message={`Missing or invalid ${type === NOTIFY_EMPLOYEE_TYPE.EMAIL ? 'email address' : 'mobile phone'}`}
				placement="top"
			>
				<span className="icon-checkbox_disabled text-grey input-checkbox input-checkbox--desaturated input-checkbox--no-label" />
			</Tooltip>
		);
	};

	renderEmployee = (employeeId: string, index: number) => {
		const { onCheck, isSending, showSubscribedColumns, subscribedAt, employees } = this.props;
		const employee = employees[employeeId];

		if (!employee) {
			return;
		}

		const {
			fullName,
			email,
			phoneNumber,
			officeColor,
			officeNickname,
			shouldSendEmail,
			shouldSendSms,
			lastSmsSentStatus,
			lastEmailSentAt,
			lastSmsSentAt,
			subscribedForEmail,
			subscribedForSms,
			subscriptionStatus,
			lastEmailSentStatus,
			emailInvalidatedAt,
			phoneInvalidatedAt,
			smsErrorMessage,
			emailErrorMessage,
			isAssignedToWO,
		} = employee;

		return (
			<tr key={`workOrderEmployee-${index}`}>
				<td colSpan={NOTIFY_MODAL_COL_SPAN.CREW}>
					<LabelWithColor
						color={officeColor}
						text={
							<div className="modal-flex-row">
								<Label label={fullName} tooltipMessage={officeNickname ? `Office: ${officeNickname}` : undefined} tooltipPlacement="top" />
								{
									!isAssignedToWO &&
									<Tooltip message="User is no longer assigned to work order">
										<span className="icon-warning text-red" />
									</Tooltip>
								}
							</div>
						}
					/>
				</td>
				<td colSpan={NOTIFY_MODAL_COL_SPAN.CHECKBOX}>
					{email ?
						<Checkbox
							handleChange={onCheck.bind(this, employee.id, NOTIFY_EMPLOYEE_TYPE.EMAIL)}
							isChecked={shouldSendEmail}
							isDisabled={isSending || !email}
						/> :
						this.wrapUnableToNotify(NOTIFY_EMPLOYEE_TYPE.EMAIL)
					}
				</td>
				<EmailStatusInfo
					email={email}
					emailInvalidatedAt={emailInvalidatedAt}
					errorMessage={emailErrorMessage}
					lastEmailSentAt={lastEmailSentAt}
					lastEmailSentStatus={lastEmailSentStatus}
				/>
				{showSubscribedColumns &&
					<td colSpan={NOTIFY_MODAL_COL_SPAN.SEND_TIME}>
						{subscribedForEmail ? <span>{subscribedAt}</span> : <span>-</span>}
					</td>
				}
				<td colSpan={NOTIFY_MODAL_COL_SPAN.CHECKBOX}>
					{phoneNumber && subscriptionStatus !== SubscriptionStatus.UNSUBSCRIBED ?
						<Checkbox
							handleChange={onCheck.bind(this, employee.id, NOTIFY_EMPLOYEE_TYPE.SMS)}
							isChecked={shouldSendSms}
							isDisabled={isSending || !phoneNumber}
						/> :
						this.wrapUnableToNotify(NOTIFY_EMPLOYEE_TYPE.SMS)
					}
				</td>
				<SmsStatusInfo
					errorMessage={smsErrorMessage}
					lastSmsSentAt={lastSmsSentAt}
					lastSmsSentStatus={lastSmsSentStatus}
					phoneInvalidatedAt={phoneInvalidatedAt}
					phoneNumber={phoneNumber}
					subscriptionStatus={subscriptionStatus}
				/>
				{showSubscribedColumns &&
					<td colSpan={NOTIFY_MODAL_COL_SPAN.SEND_TIME}>
						{subscribedForSms ? <span>{subscribedAt}</span> : <span>-</span>}
					</td>
				}
			</tr>
		);
	};

	render() {
		const { employees } = this.props;

		return Object.keys(employees).map(this.renderEmployee);
	}
}

export default EmployeeList;
