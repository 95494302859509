import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormGroup } from 'react-bootstrap';

import { ColorPalette } from 'acceligent-shared/enums/color';

import ColorPickerInput from 'af-components/Controls/ColorPicker';
import { Position, Alignment } from 'af-components/Controls/ColorPicker/ColorsGrid';
import Label from 'af-components/LockedValue/Label';

interface Props extends WrappedFieldProps {
	label?: string;
	disabled?: boolean;
	onValueChange?: (color: ColorPalette) => void;
	onFocus?: () => void;
	position?: Position;
	align?: Alignment;
	grouped?: boolean;
	showAdditionalOptions?: boolean;
}

export default class ColorPicker extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		disabled: false,
		position: 'bottom',
		align: 'center',
		grouped: false,
		showAdditionalOptions: false,
	};

	overlayRef: Nullable<React.RefObject<unknown>> = null;

	constructor(props: Props) {
		super(props);

		this.overlayRef = React.createRef();
	}

	onChange = (color: ColorPalette) => {
		const { input, onValueChange } = this.props;

		input.onChange(color as unknown as React.ChangeEvent<ColorPalette>);

		if (onValueChange) {
			onValueChange(color);
		}
	};

	clear = () => {
		const { input } = this.props;
		input.onChange(null);
	};

	renderColorPicker = () => {
		const {
			input,
			disabled,
			label,
			onFocus,
			meta: { error, touched },
			position,
			align,
			showAdditionalOptions,
		} = this.props;
		return (
			<>
				<Label
					label={label}
				/>
				<div className="color-picker__field">
					<ColorPickerInput
						align={align}
						className={touched && error ? 'color-picker--error' : undefined}
						disabled={disabled}
						onChange={this.onChange}
						onFocus={onFocus}
						position={position}
						showAdditionalOptions={showAdditionalOptions}
						value={input.value}
					/>
				</div>
			</>
		);
	};

	render() {
		const {
			grouped,
		} = this.props;

		if (grouped) {
			return <FormGroup>{this.renderColorPicker()}</FormGroup>;
		}

		return <div>{this.renderColorPicker()}</div>;
	}
}
