import * as React from 'react';

import Tab from './Tab';

import styles from './styles.module.scss';

export type Tab = {
	id: number;
	title: string;
};

interface OwnProps {
	tabs: Tab[];
	activeTabId: Tab['id'];
	setActiveTabId: (tabId: Tab['id']) => void;
}

type Props = OwnProps;

const Tabs: React.FC<Props> = (props: Props) => {
	const { activeTabId, setActiveTabId, tabs } = props;

	const renderTab = React.useCallback((tab: Tab) => {
		return (
			<Tab
				isSelected={activeTabId === tab.id}
				key={tab.id}
				onSelect={setActiveTabId}
				tabId={tab.id}
				title={tab.title}
			/>
		);
	}, [activeTabId, setActiveTabId]);

	return (
		<div className={styles['signature-modal__tabs']}>
			{tabs.map(renderTab)}
		</div>
	);
};

export default React.memo(Tabs);
