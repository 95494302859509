import TimeSheetEntryType from 'acceligent-shared/enums/timeSheetEntryType';
import TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';
import * as UserUtils from 'acceligent-shared/utils/user';

import TimeSheetEntryHistoryServiceModel from 'ab-serviceModels/timeSheetEntryHistory.serviceModel';

type TimeSheetEntryHistoryReducerType = {
	activeEntries: Record<number, TimeSheetEntryHistoryItemVM>;
	deletedEntries: TimeSheetEntryHistoryItemVM[];
	usedEntries: Record<number, boolean>;
};

export class TimeSheetEntryHistoryParentVM {
	id: Nullable<number>;
	type: Nullable<TimeSheetEntryType>;
	workType: Nullable<TimeSheetEntryWorkType>;
	/** ISO Date string */
	startTime: Nullable<string>;
	/** ISO Date string */
	endTime: Nullable<string>;
	/** ISO Date string */
	createdAt: Nullable<string>;
	createdBy: string;
	/** ISO Date string */
	deletedAt: Nullable<string>;
	deletedBy: Nullable<string>;
	dataFilledBy: string;

	constructor(entry: TimeSheetEntryHistoryServiceModel) {
		this.id = entry.deletedEntryId;
		this.type = entry.deletedEntryType;
		this.workType = entry.deletedEntryWorkType;
		this.startTime = entry.deletedEntryStartTime ? TimeUtils.formatDate(entry.deletedEntryStartTime, TimeFormat.ISO_DATETIME) : null;
		this.endTime = entry.deletedEntryEndTime
			? TimeUtils.formatDate(entry.deletedEntryEndTime, TimeFormat.ISO_DATETIME)
			: null;
		this.createdAt = entry.deletedEntryCreatedAt ? TimeUtils.formatDate(entry.deletedEntryCreatedAt, TimeFormat.ISO_DATETIME) : null;
		this.createdBy = UserUtils.getUserName(entry.deletedEntryCreatedBy, true);
		this.deletedAt = entry.deletedEntryDeletedAt ? TimeUtils.formatDate(entry.deletedEntryDeletedAt, TimeFormat.ISO_DATETIME) : null;
		this.deletedBy = UserUtils.getUserName(entry.deletedEntryDeletedBy, true);
		this.dataFilledBy = UserUtils.getUserName(entry.deletedEntryDataFilledBy, true);
	}

	private static _constructorMap = (entry: TimeSheetEntryHistoryServiceModel) => new TimeSheetEntryHistoryParentVM(entry);

	private static _offsetTimelineEntry(entry: TimeSheetEntryHistoryParentVM, offset: number): TimeSheetEntryHistoryParentVM {
		return {
			...entry,
			startTime: entry.startTime ? TimeUtils.offsetTime(entry.startTime, offset, 'minutes', TimeFormat.ISO_DATETIME) : null,
			endTime: entry.endTime ? TimeUtils.offsetTime(entry.endTime, offset, 'minutes', TimeFormat.ISO_DATETIME) : null,
		};
	}

	static bulkConstructor = (entries: TimeSheetEntryHistoryServiceModel[]) => entries.map(TimeSheetEntryHistoryParentVM._constructorMap);

	/**
	 * Called only on FE when updating how items are shown on the timeline with stale data
	 */
	static bulkRevertOffsetTimelineEntriesForTimeZone(entries: TimeSheetEntryHistoryParentVM[], offset: number): TimeSheetEntryHistoryParentVM[] {
		if (!offset) {
			return entries;
		}
		return entries.map((_entry) => TimeSheetEntryHistoryParentVM._offsetTimelineEntry(_entry, offset));
	}
}

export class TimeSheetEntryHistoryItemVM {
	id: number;
	type: TimeSheetEntryType;
	workType: TimeSheetEntryWorkType;
	/** ISO Date string */
	startTime: Nullable<string>;
	/** ISO Date string */
	endTime: Nullable<string>;
	/** ISO Date string */
	createdAt: string;
	createdBy: string;

	/** ISO Date string */
	deletedAt: Nullable<string>;
	deletedBy: string;

	dataFilledBy: string;

	parentEntries: Nullable<TimeSheetEntryHistoryParentVM[]>;

	constructor(entry: TimeSheetEntryHistoryServiceModel) {
		this.id = entry.id;
		this.type = entry.type;
		this.workType = entry.workType;
		this.startTime = TimeUtils.formatDate(entry.startTime, TimeFormat.ISO_DATETIME);
		this.endTime = entry.endTime ? TimeUtils.formatDate(entry.endTime, TimeFormat.ISO_DATETIME) : null;
		this.createdAt = TimeUtils.formatDate(entry.createdAt, TimeFormat.ISO_DATETIME);
		this.createdBy = UserUtils.getUserName(entry.createdBy, true);
		this.deletedAt = entry.deletedAt ? TimeUtils.formatDate(entry.deletedAt, TimeFormat.ISO_DATETIME) : null;
		this.deletedBy = UserUtils.getUserName(entry.deletedBy, true);
		this.dataFilledBy = UserUtils.getUserName(entry.dataFilledBy, true);
	}

	private static _constructorMap = (entry: TimeSheetEntryHistoryServiceModel) => new TimeSheetEntryHistoryItemVM(entry);

	private static _offsetTimelineEntry(entry: TimeSheetEntryHistoryItemVM, offset: number): TimeSheetEntryHistoryItemVM {
		return {
			...entry,
			startTime: entry.startTime ? TimeUtils.offsetTime(entry.startTime, offset, 'minutes', TimeFormat.ISO_DATETIME) : null,
			endTime: entry.endTime ? TimeUtils.offsetTime(entry.endTime, offset, 'minutes', TimeFormat.ISO_DATETIME) : null,
			parentEntries: entry.parentEntries?.length
				? TimeSheetEntryHistoryParentVM.bulkRevertOffsetTimelineEntriesForTimeZone(entry.parentEntries, offset)
				: null,
		};
	}

	static bulkConstructor = (entries: TimeSheetEntryHistoryServiceModel[]) => entries.map(TimeSheetEntryHistoryItemVM._constructorMap);

	/**
	 * Called only on FE when updating how items are shown on the timeline with stale data
	 */
	static bulkRevertOffsetTimelineEntriesForTimeZone(entries: TimeSheetEntryHistoryItemVM[], timeZone: Nullable<string>): TimeSheetEntryHistoryItemVM[] {
		if (!timeZone) {
			return entries;
		}

		const browserOffset = (new Date()).getTimezoneOffset();
		const timeZoneOffset = TimeUtils.getOffset(timeZone);

		const timeOffset = timeZoneOffset + browserOffset;

		return entries.map((_entry) => TimeSheetEntryHistoryItemVM._offsetTimelineEntry(_entry, timeOffset));

	}
}

class TimeSheetEntryHistoryVM {
	activeEntries: Nullable<TimeSheetEntryHistoryItemVM[]>;
	deletedEntries: Nullable<TimeSheetEntryHistoryItemVM[]>;

	constructor(entries: TimeSheetEntryHistoryServiceModel[]) {
		const {
			activeEntries,
			deletedEntries,
		} = entries.reduce(TimeSheetEntryHistoryVM._reducer, { activeEntries: {}, deletedEntries: [], usedEntries: {} } as TimeSheetEntryHistoryReducerType);

		this.activeEntries = activeEntries ? Object.values(activeEntries) : null;
		this.deletedEntries = deletedEntries ? deletedEntries : null;

		// Sort parent entries by created at DESC
		for (const entry of this.activeEntries ?? []) {
			entry.parentEntries?.sort((_entry1, _entry2) =>
				_entry1.createdAt && _entry2.createdAt && TimeUtils.isBefore(_entry1.createdAt, _entry2.createdAt, TimeFormat.ISO_DATETIME)
					? 1
					: -1
			);
		}
	}

	private static _reducer = (acc: TimeSheetEntryHistoryReducerType, entry: TimeSheetEntryHistoryServiceModel) => {
		if (!entry.deletedAt) {
			if (acc.activeEntries[entry.id]) {
				if (entry.deletedEntryId && !acc.usedEntries[entry.deletedEntryId]) {
					const deletedEntry = new TimeSheetEntryHistoryParentVM(entry);
					(acc.activeEntries[entry.id].parentEntries ?? []).push(deletedEntry);
					acc.usedEntries[entry.deletedEntryId] = true;
				}
			} else {
				const activeEntry = new TimeSheetEntryHistoryItemVM(entry);
				if (entry.deletedEntryId && !acc.usedEntries[entry.deletedEntryId]) {
					const deletedEntry = new TimeSheetEntryHistoryParentVM(entry);
					activeEntry.parentEntries = [deletedEntry];
					acc.usedEntries[entry.deletedEntryId] = true;
				}
				acc.activeEntries[entry.id] = activeEntry;
			}
		} else {
			acc.deletedEntries.push(new TimeSheetEntryHistoryItemVM(entry));
		}
		return acc;
	};
}

export default TimeSheetEntryHistoryVM;
