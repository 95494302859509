import * as React from 'react';
import { CustomRouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ResolveThunks } from 'react-redux';
import { compose } from 'redux';
import DatePicker from 'react-datepicker';
import enUs from 'date-fns/locale/en-US';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import * as SettingsKeys from 'af-constants/settingsKeys';

import { DATE_CHANGE_DELAY } from 'ab-constants/value';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';

import { withSettings, setWorkOrderSelectedDueDate } from 'af-utils/settings.util';
import { debounce } from 'af-utils/actions.util';

import DailyDatePicker from './DailyDatePicker';

type MomentType = ReturnType<typeof TimeUtils.normalizeDateToMoment>;

interface OwnProps {
	refreshConnectionCount: (dates: string[]) => void;
	// Called before changing date, change date action is passed as param
	// Used to display warning modal on edit prod data screen
	beforeDateChange?: (confirmAction: () => void) => void;
}

interface SettingsProps {
	selectedDate: MomentType;
}

interface DispatchProps {
	updateScheduleBoardDate: typeof ScheduleBoardActions.updateScheduleBoardDate;
}

type ConnectOwnProps = OwnProps & CustomRouteComponentProps;
type Props = ConnectOwnProps & SettingsProps & ResolveThunks<DispatchProps>;

interface State {
	shiftDays: number;
	selectedDate: MomentType;
	isProductionData: boolean;
}

class DailyViewDatePicker extends React.Component<Props, State> {
	datepicker = null;

	state: State = {
		shiftDays: 0,
		selectedDate: this.props.selectedDate,
		isProductionData: this.props.location.pathname.endsWith('/metrics'),
	};

	componentDidMount() {
		const { selectedDate, location: { state: { selectedDate: selectedDateParam } } } = this.props;

		const date = (selectedDateParam && TimeUtils.normalizeDateToMoment(selectedDateParam)) || selectedDate;
		this._onDateSelect(date);
	}

	_onDateSelect = (date: Nullable<MomentType>) => {
		if (!date) {
			throw new Error('Selected date cannot be null');
		}

		setWorkOrderSelectedDueDate(date);
		this.setState(() => ({ selectedDate: date }));
		this.onDateChanged(date.format(TimeFormatEnum.DATE_ONLY));
	};

	_onDateShift = (shiftDays: number) => {
		const { refreshConnectionCount, selectedDate: pastSelectedDate } = this.props;
		if (!pastSelectedDate) {
			throw new Error('Date incorrectly shifted');
		}
		const pastDate = TimeUtils.formatDate(pastSelectedDate);

		this.setState((state) => ({
			shiftDays: 0,
			selectedDate: state.selectedDate!.add(shiftDays, 'days'),
		}), () => {
			const { selectedDate, isProductionData } = this.state;
			if (!isProductionData) {
				refreshConnectionCount([pastDate]);
			}

			if (!selectedDate) {
				throw new Error('Selected date not defined');
			}
			setWorkOrderSelectedDueDate(selectedDate);
			this.onDateChanged(selectedDate.format(TimeFormatEnum.DATE_ONLY));
		});
	};

	_onPreviousDate = () => this._onDateShift(this.state.shiftDays);

	_onNextDate = () => this._onDateShift(this.state.shiftDays);

	// eslint-disable-next-line @typescript-eslint/member-ordering
	_onPreviousDateDebounce = debounce(this._onPreviousDate, DATE_CHANGE_DELAY);

	// eslint-disable-next-line @typescript-eslint/member-ordering
	_onNextDateDebounce = debounce(this._onNextDate, DATE_CHANGE_DELAY);

	onPreviousDate = () => {
		this.setState(
			(state: State) => ({ shiftDays: state.shiftDays - 1 }),
			() => this._onPreviousDateDebounce()
		);
	};

	onNextDate = () => {
		this.setState(
			(state: State) => ({ shiftDays: state.shiftDays + 1 }),
			() => this._onNextDateDebounce()
		);
	};

	onDateChanged = (date: string) => {
		const { updateScheduleBoardDate } = this.props;
		updateScheduleBoardDate(date);
	};

	onDatePickerChange = (date: Date) => this._onDateSelect(TimeUtils.parseMoment(date));

	onDateChangeAction = (date: Date) => {
		const { beforeDateChange } = this.props;
		if (beforeDateChange) {
			beforeDateChange(() => this.onDatePickerChange(date));
		} else {
			this.onDatePickerChange(date);
		}
	};

	onPreviousDateAction = () => {
		const { beforeDateChange } = this.props;
		if (beforeDateChange) {
			beforeDateChange(this.onPreviousDate);
		} else {
			this.onPreviousDate();
		}
	};

	onNextDateAction = () => {
		const { beforeDateChange } = this.props;
		if (beforeDateChange) {
			beforeDateChange(this.onNextDate);
		} else {
			this.onNextDate();
		}
	};

	render() {
		const { selectedDate } = this.state;

		return (
			<div className="schedule-board-datepicker">
				<div className="schedule-board-datepicker-input">
					<DatePicker
						className="form-control" // Pull out the date in DATE_ONLY format and allow the picker to format through moment
						customInput={<DailyDatePicker />}
						dateFormat={TimeUtils.datePickerFormat(TimeFormatEnum.DATE_ONLY)}
						dropdownMode="select"
						locale={enUs}
						onChange={this.onDateChangeAction}
						selected={selectedDate?.toDate?.()}
						showMonthDropdown={true}
						showYearDropdown={true}
						todayButton="Today"
					/>
				</div>
				<MultipleOptionsButton isLeftFlat={true}>
					<RectangleButton
						action={this.onPreviousDateAction}
						isSquare={true}
						label={<span className="icon-left" />}
						tooltipMessage="Previous Day"
						tooltipPlacement="bottom"
					/>
					<RectangleButton
						action={this.onNextDateAction}
						isSquare={true}
						label={<span className="icon-right" />}
						tooltipMessage="Next Day"
						tooltipPlacement="bottom"
					/>
				</MultipleOptionsButton>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		updateScheduleBoardDate: ScheduleBoardActions.updateScheduleBoardDate,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	withSettings<Props>(() => ([
		{
			key: SettingsKeys.WORK_ORDER_SELECTED_DUE_DATE(),
			mappedName: 'selectedDate',
			normalize: TimeUtils.normalizeDateToMoment,
			defaultValue: TimeUtils.parseMoment(new Date()),
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
	])),
	withRouter,
	connect<null, DispatchProps, ConnectOwnProps>(null, mapDispatchToProps())
);
export default enhance(DailyViewDatePicker);
