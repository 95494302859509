import * as React from 'react';
import { Modal } from 'react-bootstrap';

interface OwnProps {
	className?: string;
}

type Props = React.PropsWithChildren<OwnProps>;

const Footer: React.FC<Props> = ({ children, className }: Props) => {
	if (!children) {
		return null;
	}

	return (
		<Modal.Footer className={className}>
			{children}
		</Modal.Footer>
	);
};

export default React.memo(Footer);
