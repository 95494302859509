import * as React from 'react';

import { PreviewContactVM } from 'ab-viewModels/workRequest/workRequestPreviewDetails.viewModel';

import LockedValue from 'af-components/LockedValue';

import WorkRequestDisposalMethod from 'acceligent-shared/enums/workRequestDisposalMethod';

import { previewSectionClass, previewSectionClassColumn2, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';

type Props = {
	ableToCleanAndWashEquipmentAtCustomerLocation: boolean;
	cleanAndWashLocation: Nullable<string>;
	isWasteGeneratedSolid: boolean;
	isWasteGeneratedSludge: boolean;
	isWasteGeneratedLiquid: boolean;
	isWasteHazardous: boolean;
	isWasteManifest: boolean;
	wasteDescription: Nullable<string>;
	expectedWasteQuantity: Nullable<number>;
	disposalMethod: Nullable<WorkRequestDisposalMethod>;
	disposalInstructions: Nullable<string>;
	disposalContact: Nullable<PreviewContactVM>;
};

const _valuesMapper = (_method: string) => _method;

const DisposalSection: React.FC<Props> = (props) => {
	const {
		ableToCleanAndWashEquipmentAtCustomerLocation,
		cleanAndWashLocation,
		disposalContact,
		disposalInstructions,
		disposalMethod,
		expectedWasteQuantity,
		isWasteGeneratedLiquid,
		isWasteGeneratedSludge,
		isWasteGeneratedSolid,
		isWasteHazardous,
		isWasteManifest,
		wasteDescription,
	} = props;

	const renderValues = React.useCallback((values: string[]) => values.map((_value, _i) => <div key={_i}>{_value}</div>), []);

	const wasteGenerated = React.useMemo(() => {
		const newWasteGenerated: string[] = [];
		isWasteGeneratedLiquid && newWasteGenerated.push('Liquid');
		isWasteGeneratedSludge && newWasteGenerated.push('Sludge');
		isWasteGeneratedSolid && newWasteGenerated.push('Solid');
		return newWasteGenerated;
	}, [isWasteGeneratedLiquid, isWasteGeneratedSludge, isWasteGeneratedSolid]);

	const hazardousOrManifest = React.useMemo(() => {
		const newHazardousOrManifest: string[] = [];
		isWasteHazardous && newHazardousOrManifest.push('Hazardous');
		isWasteManifest && newHazardousOrManifest.push('Manifest');

		return newHazardousOrManifest;
	}, [isWasteHazardous, isWasteManifest]);

	const resolveDisposalMethod = React.useCallback((_dm: WorkRequestDisposalMethod) => {
		switch (_dm) {
			case (WorkRequestDisposalMethod.NONE):
				return 'None';
			case (WorkRequestDisposalMethod.OFF_SITE_DISPOSAL):
				return 'Off-site Disposal';
			case (WorkRequestDisposalMethod.ON_SITE_DISPOSAL):
				return 'On-site Disposal';
			case (WorkRequestDisposalMethod.THIRD_PARTY_TRANSPORT):
				return 'Third Party Transport';
			default:
				return '';
		}
	}, []);

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Disposal
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Able To Clean And Wash Equipment at Customer Location" value={ableToCleanAndWashEquipmentAtCustomerLocation ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Clean and Wash Location" value={cleanAndWashLocation} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Waste Generated" value={wasteGenerated.join(', ')} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Hazardous / Manifest" value={hazardousOrManifest.join(', ')} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Waste Description" value={wasteDescription} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Expected Waste Quantity" value={expectedWasteQuantity} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Disposal Method" value={disposalMethod ? resolveDisposalMethod(disposalMethod) : null} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Disposal Instructions" value={disposalInstructions} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Disposal Contact" value={disposalContact?.fullName} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue
						label="E-mail"
						value={
							disposalContact?.emails.length ?
								renderValues(disposalContact?.emails.map(_valuesMapper))
								: null
						}
					/>
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue
						label="Phone"
						value={
							disposalContact?.phones.length
								? renderValues(disposalContact?.phones.map(_valuesMapper))
								: null
						}
					/>
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Address"
						value={
							disposalContact?.addresses.length ?
								renderValues(disposalContact.addresses.map(_valuesMapper))
								: null
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(DisposalSection);
