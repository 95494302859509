import * as React from 'react';
import { Button } from 'react-bootstrap';

interface OwnProps {
	isActive?: boolean;
	isDisabled?: boolean;
	displayLevel: number;
	label: string;
	iconClass: string;
	className?: string;
	onClick: () => void;
}

type Props = OwnProps;

class SelectMultipleButton extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		isActive: false,
		isDisabled: false,
		className: '',
	};

	render() {
		const { onClick, isActive, displayLevel, iconClass, label, isDisabled, className } = this.props;

		return (
			<Button className={`${className} ${isActive ? 'btn-info--blue-fill' : ''}`} disabled={isDisabled} onClick={!isDisabled ? onClick : undefined} variant="info">
				{
					(displayLevel === 1 || displayLevel === 2) &&
					<span className={`${iconClass} m-r-xs`} />
				}
				{
					(displayLevel === 0 || displayLevel === 1 || displayLevel === 2) &&
					<span>{label}</span>
				}
			</Button>
		);
	}
}

export default SelectMultipleButton;
