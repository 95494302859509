import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import { formatDate } from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';
import { compose } from 'redux';

type Props = ConnectedProps<typeof connector>;

const DisplayViewNavigation: React.FC<Props> = (props: Props) => {
	const { displayViewDate } = props;

	return (
		<div className="display-view-navigation">
			<div className="display-view-navigation-title">
				{formatDate(displayViewDate, TimeFormatEnum.SB_DATE_LONG)}
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	return {
		displayViewDate: state.scheduleBoard.displayViewDate,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType>(
	React.memo,
	connector
);

export default enhance(DisplayViewNavigation);
