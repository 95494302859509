import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { FieldArray, FormErrors, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from 'acceligent-shared/constants/value';

import FieldReportAccessRoleEnum from 'acceligent-shared/enums/fieldReportAccessRole';
import WorkOrderReviewStatus, { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';
import TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';

import { TimeSheetsBulkUpdateRM } from 'acceligent-shared/dtos/web/request/timeSheet/timeSheetUpdate';

import { TimeSheetVM } from 'acceligent-shared/dtos/web/view/timeSheet/timeSheet';
import FieldWorkClassificationCodeListItemVM from 'acceligent-shared/dtos/web/view/fieldWorkClassificationCode/listItem';
import { EquipmentListItemVM } from 'acceligent-shared/dtos/web/view/timeSplitEquipment/timeSplitEquipment';

import * as TimeSheetUtils from 'acceligent-shared/utils/timeSheet';
import * as TimeUtils from 'acceligent-shared/utils/time';
import { findOverlapsByVirtualId, getTimelineEntities } from 'acceligent-shared/utils/timeSheetEntry';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import OccupiedSlotsForWorkOrderVM from 'ab-viewModels/timeSheet/occupiedSlotsForWorkOrder.viewModel';
import { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import * as TimeSheetActions from 'af-actions/timeSheet';
import * as WorkRequestActions from 'af-actions/workRequests';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import ConfirmationModal from 'af-components/ConfirmationModal';

import * as ReduxFormKeys from 'af-constants/reduxForms';

import { RootState } from 'af-reducers';

import TimeSheetEditModalBody, { OwnProps as ModalBodyProps } from './TimeSheetEditModalBody';
import TimeSheetEditFormModel, { TimeAllocationEntryFormModel, TimeSheetAddedEntryWithType, TimeSheetBulkEditFormModel, TimeSheetEntryFormModel, TimeSheetEntryWithType, TimeSheetGapEntryWithType, TimeSheetOccupiedEntryWithType, TimesPerWorkType, TimeSplitEntryFormModel } from './formModel';
import styles from './styles.module.scss';
import LoadingTab from './Loading';
import { findEntriesTimeRange, offsetOccupiedSlots } from '../../helpers';

const FORM_KEY = ReduxFormKeys.TIME_SHEET;

const getForm = getFormValues(FORM_KEY);

export enum BulkOrSingleEditModal {
	BULK = 'BULK',
	SINGLE = 'SINGLE'
}

interface OwnProps {
	close: () => void;
	save: (workOrderId: number, data: TimeSheetsBulkUpdateRM) => Promise<void>;
	bulkOrSingleEditModal: BulkOrSingleEditModal;
	showModal: boolean;
	accessRole: Nullable<FieldReportAccessRoleEnum>;
	/** YYYY-MM-DD */
	dueDate: string;
	code: string;
	workOrderId: number;
	workRequestId: number;
	timeSheets: Nullable<TimeSheetVM[]>;
	areAllTimeSheetsReadOnly: boolean;
	areFRsReadOnly: boolean;
	canSeeTimeAllocations: boolean;
	/** Keeps track of work order / job / company time zone */
	timeZone: Nullable<string>;
	/** Keeps track of which time zone we're using in the form */
	timeZoneInUse: Nullable<string>;
	isPaused: boolean;
	isSuperintendent: boolean;
	hasSIUnassignedPermission: boolean;
	workOrderReviewStatus: WorkOrderReviewStatus;
	workOrderReviewLevel: WorkOrderReviewLevel;
	classificationCodes: FieldWorkClassificationCodeListItemVM[];
	sections: Nullable<TimeSplitEntryEquipmentSections>[];
	isVirtual: boolean;
}

type Props = OwnProps & InjectedFormProps<TimeSheetBulkEditFormModel> & ConnectedProps<typeof connector>;

type TimeSplitEntryEquipmentSections = {
	title: string;
	options: EquipmentListItemVM[];
};
type SingleTimeSheetSplitData = {
	indexOfNewEntry: Nullable<number>;
	occupiedSlots: Nullable<OccupiedSlotsForWorkOrderVM>;
	timeSheetEntries: TimeSheetEntryFormModel[];
	timeSheetEquipment: TimeSplitEntryFormModel[];
	timeAllocationEntries: TimeAllocationEntryFormModel[];
	accountId: number;
	userFullName: string;
	approvalStatus: TimeSheetVM['superintendentApprovalStatus'];
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
};
type SingleTimeSheetSplitDataByFormId = {
	[tsId: string]: SingleTimeSheetSplitData;
};

const usePrevious = (value) => {
	const ref = React.useRef();
	React.useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

const TimeSheetsEditModal: React.FC<Props> = (props) => {
	const {
		close,
		save,
		showModal,
		workOrderId,
		timeSheets,
		areAllTimeSheetsReadOnly,
		areFRsReadOnly,
		timeZone,
		timeZoneInUse,
		isPaused,
		workOrderReviewStatus,
		findWorkOrderTimeSheetAndSplitEntriesForAccount,
		findOccupiedSlots,
		formData,
		isManagementAllowedToEdit,
		isManagerOrAdmin,
		accountId,
		initialize,
		valid,
		submitting,
		handleSubmit,
		classificationCodes,
		sections,
		change,
		dueDate,
		bulkOrSingleEditModal,
		workRequestId,
		findAllSubjobs,
		canSeeTimeAllocations,
		timeSheetsSyncErrors,
		isAccounting,
		isManagement,
		hasPermissionToEdit,
		isSuperintendent,
		isVirtual,
	} = props;

	const [showDiscardModalData, setShowDiscardModalData] = React.useState(false);
	const [storedFormData, setStoredFormData] = React.useState(null);
	const [isInEditMode, setIsInEditMode] = React.useState(false);
	const [timeSheetSplitData, setTimeSheetSplitData] = React.useState<SingleTimeSheetSplitDataByFormId>({});
	const [loadingResources, setLoadingResources] = React.useState(false);
	const [subjobsForAllocation, setSubjobsForAllocation] = React.useState<SubjobVM[]>([]);

	const isEditable = React.useMemo(() => (
		(!areAllTimeSheetsReadOnly || !areFRsReadOnly) && !isVirtual
	), [areAllTimeSheetsReadOnly, areFRsReadOnly, isVirtual]);

	const isDone = React.useMemo(() => {
		return workOrderReviewStatus === WorkOrderReviewStatus.FINAL;
	}, [workOrderReviewStatus]);

	const canEditClassificationCodes = React.useMemo(() => {
		return isAccounting || isManagement || isSuperintendent;
	}, [isAccounting, isManagement, isSuperintendent]);

	const closeDiscardModal = React.useCallback(() => setShowDiscardModalData(false), []);

	const getOccupiedSlots = React.useCallback(async (employeeAccountId: number, entries: TimeSheetEntryFormModel[]) => {
		const { maxEndTime, minStartTime } = findEntriesTimeRange(entries, timeZoneInUse, timeZone);

		if (minStartTime && maxEndTime) {
			const occupiedSlotsResponse = await findOccupiedSlots(workOrderId, employeeAccountId, minStartTime, maxEndTime);
			return offsetOccupiedSlots(occupiedSlotsResponse, timeZoneInUse);
		} else {
			return null;
		}
	}, [findOccupiedSlots, timeZone, timeZoneInUse, workOrderId]);

	const loadOccupiedSlots = React.useCallback(async (timeSheetId: number, entries: TimeSheetEntryFormModel[]) => {
		const timeSheet = timeSheets?.find((ts) => ts.id === timeSheetId);

		if (!timeSheet || !entries) {
			return;
		}

		const occupiedSlots = await getOccupiedSlots(timeSheet.accountId, entries);

		setTimeSheetSplitData((tssData) => ({
			...tssData,
			['ts-' + timeSheetId]: {
				...tssData['ts-' + timeSheetId],
				occupiedSlots: occupiedSlots,
			},
		}));
	}, [getOccupiedSlots, timeSheets]);

	const loadSubjobsForAllocation = React.useCallback(async () => {
		const subjobs = await findAllSubjobs(workRequestId);
		setSubjobsForAllocation(subjobs);
	}, [findAllSubjobs, workRequestId]);

	const loadTimeSheetAndSplitEntries = React.useCallback(async (timeSheetId: number) => {
		const timeSheet = timeSheets?.find((ts) => ts.id === timeSheetId);

		if (!timeSheet) {
			return;
		}

		setLoadingResources(true);

		const {
			timeSheetEntries,
			timeSplitEntries,
			timeAllocationEntries,
		} = await findWorkOrderTimeSheetAndSplitEntriesForAccount(workOrderId, timeSheet.accountId);
		const formSplitEntries = timeSplitEntries?.map(TimeSplitEntryFormModel.parseFromVM);
		const formSheetEntries = timeSheetEntries.map((_e) => TimeSheetEntryFormModel.parseFromVM(_e, timeZoneInUse));
		const formAllocationEntries = timeAllocationEntries?.map(TimeAllocationEntryFormModel.parseFromVM);

		loadOccupiedSlots(timeSheet.id, formSheetEntries);
		if (canSeeTimeAllocations) {
			loadSubjobsForAllocation();
		}

		setTimeSheetSplitData((tssData) => ({
			...tssData,
			['ts-' + timeSheetId]: {
				...tssData['ts-' + timeSheetId],
				accountId: timeSheet.accountId,
				userFullName: timeSheet.userFullName,
				approvalStatus: timeSheet.superintendentApprovalStatus,
				employeeApprovalStatus: timeSheet.employeeApprovalStatus,
				timeSheetEquipment: formSplitEntries,
				timeSheetEntries: formSheetEntries,
				timeAllocationEntries: formAllocationEntries,
			},
		}));
		setLoadingResources(false);

	}, [
		timeSheets,
		findWorkOrderTimeSheetAndSplitEntriesForAccount,
		workOrderId,
		loadOccupiedSlots,
		loadSubjobsForAllocation,
		timeZoneInUse,
		canSeeTimeAllocations,
	]);

	const initializeTotalTimesPerWorkType = (
		timeSheetEntriesWithTypes: (TimeSheetEntryWithType | TimeSheetGapEntryWithType | TimeSheetOccupiedEntryWithType | TimeSheetAddedEntryWithType)[]
	): TimesPerWorkType => {
		const timeSheetEntries = TimeSheetEditFormModel.mapEntriesWithTypesToEntries(
			TimeSheetEditFormModel.filterOutGapAndOccupiedEntries(timeSheetEntriesWithTypes)
		);

		const totalJobTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.JOB);
		const roundedTotalJobTime = TimeUtils.roundTimeDurationToInterval(totalJobTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

		const totalBreakTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.BREAK);
		const roundedTotalBreakTime = TimeUtils.roundTimeDurationToInterval(totalBreakTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

		const totalShopTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.SHOP);
		const roundedTotalShopTime = TimeUtils.roundTimeDurationToInterval(totalShopTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

		const totalTravelTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.TRAVEL);
		const roundedTotalTravelTime = TimeUtils.roundTimeDurationToInterval(totalTravelTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

		return {
			job: {
				roundedWorkTimeBefore: roundedTotalJobTime,
				roundedWorkTimeAfter: roundedTotalJobTime,
			},
			break: {
				roundedWorkTimeBefore: roundedTotalBreakTime,
				roundedWorkTimeAfter: roundedTotalBreakTime,
			},
			shop: {
				roundedWorkTimeBefore: roundedTotalShopTime,
				roundedWorkTimeAfter: roundedTotalShopTime,
			},
			travel: {
				roundedWorkTimeBefore: roundedTotalTravelTime,
				roundedWorkTimeAfter: roundedTotalTravelTime,
			},
		};
	};

	const previousTimeSheets = usePrevious(timeSheets);
	React.useEffect(() => {
		if (!!timeSheets && timeSheets !== previousTimeSheets) {
			timeSheets.forEach((timeSheet) => {
				loadTimeSheetAndSplitEntries(timeSheet.id);
			});

		}
		if (!!timeSheets && !(formData?.timeSheets)?.length) {
			const bulkFormModel = new TimeSheetBulkEditFormModel();
			const bulkFormModelSheets: TimeSheetEditFormModel[] = [];
			let countOfInitialized = 0;
			Object.keys(timeSheetSplitData).forEach((tsKey) => {
				if (
					timeSheetSplitData?.[tsKey]?.timeSheetEntries
					&& timeSheetSplitData?.[tsKey]?.timeSheetEquipment
					&& timeSheetSplitData?.[tsKey]?.timeAllocationEntries
					&& timeSheetSplitData?.[tsKey]?.occupiedSlots !== undefined
				) {

					const overlaps = findOverlapsByVirtualId(timeSheetSplitData[tsKey].timeSheetEntries, timeSheetSplitData[tsKey].occupiedSlots?.slots);
					const hasOverlap = Object.keys(overlaps).some((_key) => (overlaps[_key].startTime || overlaps[_key].endTime || overlaps[_key].occupied));
					const timelineEntities = getTimelineEntities(timeSheetSplitData[tsKey].timeSheetEntries, timeSheetSplitData[tsKey].occupiedSlots?.slots);
					const timeSheetEntries = TimeSheetEditFormModel.mapTimelineEntitiesToTimeSheetEntriesList(timelineEntities);

					bulkFormModelSheets.push({
						accountId: timeSheetSplitData?.[tsKey].accountId,
						userFullName: timeSheetSplitData?.[tsKey].userFullName,
						approvalStatus: timeSheetSplitData?.[tsKey].approvalStatus,
						employeeApprovalStatus: timeSheetSplitData?.[tsKey].employeeApprovalStatus,
						timeSheetEntries: timeSheetEntries,
						timeSplitEntries: timeSheetSplitData[tsKey].timeSheetEquipment,
						timeAllocationEntries: timeSheetSplitData?.[tsKey]?.timeAllocationEntries,
						timeSheetEntriesHaveChanged: false,
						timeSplitEntriesHaveChanged: false,
						timeAllocationEntriesHaveChanged: false,
						overlaps: overlaps,
						hasOverlap: hasOverlap,
						editingEntryIndex: null,
						editingEntryInitialEntry: null,
						timeSheetInEditIndex: null,
						workTimesBeforeAndAfter: initializeTotalTimesPerWorkType(timeSheetEntries),
					});
					countOfInitialized += 1;
				}

			});
			if (countOfInitialized === timeSheets.length) {
				bulkFormModel.timeSheets = bulkFormModelSheets;
				initialize(bulkFormModel);
			}
		} else if (!timeSheets && timeSheets !== previousTimeSheets) {
			initialize(new TimeSheetBulkEditFormModel());
		}
	}, [timeSheets, formData, initialize, loadOccupiedSlots, previousTimeSheets, timeSheetSplitData, loadTimeSheetAndSplitEntries, timeZoneInUse]);

	const cancel = React.useCallback(() => {
		close();
		setStoredFormData(null);
		setShowDiscardModalData(false);
		setIsInEditMode(false);
		setTimeSheetSplitData({});
	}, [close]);

	const promptCancel = React.useCallback(() => {
		if (!timeSheets) {
			return;
		}

		if (TimeSheetBulkEditFormModel.hasUnsavedChanges(formData)) {
			setShowDiscardModalData(true);
			setIsInEditMode(false);
		} else {
			cancel();
		}
	}, [cancel, formData, timeSheets]);

	const saveChanges = React.useCallback(async () => {
		if (!timeSheets) {
			throw new Error('timeSheet are required in this context');
		}

		const requestModel = TimeSheetBulkEditFormModel.toRequestModel(formData, timeSheets, timeZoneInUse);
		await save(workOrderId, requestModel);

		cancel();
	}, [cancel, formData, save, timeSheets, timeZoneInUse, workOrderId]);

	const isAllowedToEditTimeSheet = React.useCallback((_accountId: number) => {
		return TimeSheetUtils.isUserAllowedToEdit(
			_accountId === accountId,
			isManagerOrAdmin,
			isSuperintendent,
			isManagementAllowedToEdit
		);
	}, [accountId, isManagementAllowedToEdit, isManagerOrAdmin, isSuperintendent]);

	const renderSingleTimeSheetWithEquipment = React.useCallback(() => {
		if (!formData || !timeSheets) {
			return null;
		}
		if (loadingResources
			|| !classificationCodes
			|| !sections) {
			return (<LoadingTab key={'loading'} />);
		}

		return (
			<FieldArray<ModalBodyProps>
				areFRsReadOnly={areFRsReadOnly || isVirtual}
				canEditClassificationCodes={canEditClassificationCodes}
				canSeeTimeAllocations={canSeeTimeAllocations}
				change={change}
				classificationCodes={classificationCodes}
				component={TimeSheetEditModalBody}
				dueDate={dueDate}
				getOccupiedSlots={getOccupiedSlots}
				isAllowedToEditEquipment={!isDone && hasPermissionToEdit}
				isAllowedToEditTimeSheet={isAllowedToEditTimeSheet}
				isWorkOrderPaused={isPaused}
				name="timeSheets"
				rerenderOnEveryChange={true}
				sections={sections}
				subJobs={subjobsForAllocation}
				timeSheetsSyncErrors={timeSheetsSyncErrors}
				timeZoneInUse={timeZoneInUse}
				workOrderReviewStatus={workOrderReviewStatus}
				workRequestId={workRequestId}
			/>
		);
	}, [
		areFRsReadOnly,
		change,
		classificationCodes,
		formData,
		dueDate,
		getOccupiedSlots,
		isAllowedToEditTimeSheet,
		isPaused,
		loadingResources,
		sections,
		timeSheets,
		workOrderReviewStatus,
		timeZoneInUse,
		subjobsForAllocation,
		workRequestId,
		canSeeTimeAllocations,
		timeSheetsSyncErrors,
		hasPermissionToEdit,
		isDone,
		canEditClassificationCodes,
		isVirtual,
	]);

	if (!timeSheets) {
		return null;
	}

	const hasNoAddedTimeSheetEntriesInProgress = TimeSheetBulkEditFormModel.hasNoAddedTimeSheetEntriesInProgress(formData);

	const isSaveChangesEnabled = valid
		&& TimeSheetBulkEditFormModel.hasUnsavedChanges(formData)
		&& hasNoAddedTimeSheetEntriesInProgress
		&& !isInEditMode;

	return (
		<>
			<CustomModal
				className={styles['time-sheet-bulk-edit-modal']}
				closeModal={promptCancel}
				showModal={showModal && !!timeSheets && !storedFormData && !showDiscardModalData}
				size="lg"
			>
				<CustomModal.Header
					className={`${!hasNoAddedTimeSheetEntriesInProgress ? styles['time-sheet-bulk-edit-modal__title-opaque'] : ''}`}
					closeModal={promptCancel}
					title={bulkOrSingleEditModal === BulkOrSingleEditModal.BULK ? 'Time Sheets Bulk Edit' : isEditable ? 'Edit Time Sheet' : 'Preview Time Sheet'}
				/>
				<CustomModal.Body className={styles['time-sheet-bulk-edit-modal']}>
					{renderSingleTimeSheetWithEquipment()}
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						className={`${!hasNoAddedTimeSheetEntriesInProgress ? styles['time-sheet-bulk-edit-modal__footer-opaque'] : ''}`}
						onClick={promptCancel}
						variant="info"
					>
						Cancel
					</Button>
					{isEditable &&
						<SubmitButton
							className={`${!hasNoAddedTimeSheetEntriesInProgress ? styles['time-sheet-bulk-edit-modal__footer-opaque'] : ''}`}
							disabled={!isSaveChangesEnabled}
							label="Save Changes"
							onClick={handleSubmit(saveChanges)}
							reduxFormSubmitting={submitting}
							variant="primary"
						/>
					}
				</CustomModal.Footer>
			</CustomModal>
			<ConfirmationModal
				body="Canceling without saving will discard all changes made to the Time Sheets. Do you want to continue?"
				closeModal={closeDiscardModal}
				closeText="Cancel"
				confirmAction={cancel}
				confirmText="Discard"
				modalStyle="danger"
				showModal={showDiscardModalData}
				title="Discard Time Sheet Changes"
			/>
		</>
	);
};

const formConnector = reduxForm<TimeSheetBulkEditFormModel>({
	form: FORM_KEY,
	validate: TimeSheetBulkEditFormModel.validate,
});

const getErrors = getFormSyncErrors(FORM_KEY);
export type EntryValidationErrors = TimeSheetBulkEditFormModel;

const mapStateToProps = (state: RootState) => {
	const formData = getForm(state) as TimeSheetBulkEditFormModel;
	const {
		user: {
			userData,
			companyData,
		},
		company: {
			company,
		},
	} = state;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { timeSheets: timeSheetsSyncErrors } = getErrors(state) as FormErrors<EntryValidationErrors, string>;

	const { role } = userData;
	const { accountId, isCompanyAdmin, permissions } = companyData;
	const isAccounting = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.ACCOUNTING, permissions, isCompanyAdmin, role);
	const hasPermissionToEdit = isAccounting || isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FILL, permissions, isCompanyAdmin, role);

	return {
		formData,
		accountId,
		isAccounting,
		hasPermissionToEdit,
		isManagement: isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.MANAGEMENT, permissions, isCompanyAdmin, role),
		isManagerOrAdmin: isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE, permissions, isCompanyAdmin, role),
		isManagementAllowedToEdit: company?.isFRManageAllowedToEditTimeSheet ?? false,
		timeSheetsSyncErrors: timeSheetsSyncErrors as FormErrors<EntryValidationErrors, string>,
	};
};

const mapDispatchToProps = () => {
	return {
		findOccupiedSlots: TimeSheetActions.findOccupiedSlotsWithInterval,
		findWorkOrderTimeSheetAndSplitEntriesForAccount: TimeSheetActions.findWorkOrderTimeSheetAndSplitEntriesForAccount,
		findAllSubjobs: WorkRequestActions.findAllSubjobs,
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	formConnector,
	connector
);

export default enhance(TimeSheetsEditModal);
