import * as React from 'react';
import { CustomRouteComponentProps, withRouter } from 'react-router-dom';

type Props = CustomRouteComponentProps<void>;

class BackButton extends React.Component<Props> {

	goBack = () => this.props.history.goBack();

	render() {
		return (
			<span
				className="btn btn-info"
				onClick={this.goBack}
			>
				Back
			</span>
		);
	}
}

export default withRouter(BackButton);
