import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps, ResolveThunks } from 'react-redux';
import { compose } from 'redux';
import { Button } from 'react-bootstrap';
import { reduxForm, InjectedFormProps, Field, getFormValues, getFormSyncErrors, FieldArray, FormErrorsWithArray } from 'redux-form';
import { FileRejection } from 'react-dropzone';
import { nanoid } from 'nanoid';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import FileType, { IMAGE_FILE_TYPES } from 'acceligent-shared/enums/fileType';
import BlobStorageContainer from 'acceligent-shared/enums/blobStorageContainer';

import * as TimeUtils from 'acceligent-shared/utils/time';

import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';
import { NotificationSnackbarTypes } from 'ab-enums/notificationSnackbarContext.enum';

import * as ReduxUtils from 'ab-utils/reduxForms.util';
import { getId } from 'ab-utils/array.util';

import { PendingAttachmentVM } from 'ab-viewModels/attachment/pendingAttachment.viewModel';
import InvoiceCodesVM from 'ab-viewModels/workRequest/invoiceCodes.viewModel';

import * as JobActions from 'af-actions/jobs';
import * as AttachmentActions from 'af-actions/attachment';

import Breadcrumbs from 'af-components/Breadcrumbs';
import SubmitButton from 'af-components/SubmitButton';
import LockedValue from 'af-components/LockedValue';
import { RouteParams } from 'af-components/Header';
import ConfirmationModal from 'af-components/ConfirmationModal';
import FileUploadMessageWithTypes from 'af-components/Dropzone/FileUploadMessageWithTypes';
import DropzoneWithButton from 'af-components/DropzoneWithButton';

import * as FORMS from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';
import Tooltip from 'af-components/Tooltip';

import Input from 'af-fields/Input';
import DateInput from 'af-fields/DateInput';
import Textarea from 'af-fields/Textarea';
import Checkbox from 'af-fields/Checkbox';

import { RootState } from 'af-reducers';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import { dollarFormatter } from 'af-utils/format.util';

import styles from './styles.module.scss';
import { InvoiceFM } from './formModel';

import { PreviewTabsEnum } from '../..';
import BillingContactArray, { OwnProps as BillingContactArrayProps } from '../BillingContacts/BillingContactArray';
import AttachmentItems, { OwnProps as AttachmentArrayProps } from '../Attachments/AttachmentItems';

interface PathParams {
	jobId: string;
	invoiceId?: string;
}

type OwnProps = CustomRouteComponentProps<RouteParams & PathParams>;

interface DispatchProps {
	createInvoice: typeof JobActions.createInvoice;
	updateInvoice: typeof JobActions.updateInvoice;
	getInvoiceById: typeof JobActions.getInvoiceById;
	getAllInvoiceCodesForJob: typeof JobActions.getAllInvoiceCodesForJob;
	areAutomaticRemindersEnabled: typeof JobActions.areAutomaticRemindersEnabled;
	uploadPendingAttachments: typeof AttachmentActions.uploadPendingAttachments;
	deletePendingAttachments: typeof AttachmentActions.deletePendingAttachments;
}

type FormOwnProps = ConnectedProps<typeof connector> & ResolveThunks<DispatchProps> & OwnProps;
type Props = FormOwnProps & InjectedFormProps<InvoiceFM>;

interface NotificationData {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

const CreateOrEditInvoice: React.FC<Props> = (props) => {
	const {
		createInvoice,
		updateInvoice,
		getInvoiceById,
		getAllInvoiceCodesForJob,
		areAutomaticRemindersEnabled,
		uploadPendingAttachments,
		deletePendingAttachments,
		history,
		match: { params: { jobId, invoiceId } },
		location: { state: { orgAlias, jobCode, billingEmailsToPrefill, backToAccountingPage } },
		companyName,
		handleSubmit,
		submitting,
		initialize,
		valid,
		currentFormValues,
		change,
		uploadProgress,
		isSendingInvoiceNotificationsEnabled,
		errors,
		initialized,
		currentUserFullName,
	} = props;

	const notificationSnackbar = useNotificationSnackbar();

	const [automaticRemindersEnabled, setAutomaticRemindersEnabled] = React.useState<boolean>(false);
	const [showInvoiceModal, setShowInvoiceModal] = React.useState<boolean>(false);
	const [showLeaveModal, setShowLeaveModal] = React.useState<boolean>(false);
	const [invoicing, setInvoicing] = React.useState<boolean>(false);
	const [currentlyFilledForm, setCurrentlyFilledForm] = React.useState<Nullable<InvoiceFM>>(null);
	const [existingInvoiceCodes, setExistingInvoiceCodes] = React.useState<Nullable<InvoiceCodesVM[]>>([]);

	const INVOICE_INVOICE_MODAL_BODY = React.useMemo(() => (
		<>
			You are about to change status of this draft to <i>Invoiced</i>.
			<br />
			You will not be able to make any changes to the data after it has been invoiced.
			<br />
			<br />
			Continue with invoicing?
		</>
	), []);

	const getInvoiceModalBody = React.useCallback(() => {
		if (currentFormValues?.billingContacts?.length && currentFormValues.sendReminderOnInvoice) {
			return (<>
				You are about to change status of this draft to <i>Invoiced</i>.
				<br />
				You will not be able to make any changes to the data after it has been invoiced.
				<br />
				<br />
				Invoice information will be sent to following billing contacts:
				<br />
				{
					currentFormValues.billingContacts.map((contact, index) => (
						<b key={index}>
							{contact.email}
							{index !== currentFormValues.billingContacts.length - 1 ? ', ' : '.'}
						</b>
					))
				}
				< br />
				To invoice without sending an email, uncheck this option in the
				< i > Reminders</i > section.
				< br />
				<br />
				Continue with invoicing ?
			</>);
		}
		return INVOICE_INVOICE_MODAL_BODY;
	}, [INVOICE_INVOICE_MODAL_BODY, currentFormValues]);

	const LEAVE_PAGE_MODAL_BODY = (
		<>
			You are about to leave this page without saving or invoicing.
			<br />
			Changes that you made will not be saved.
		</>
	);

	const ALLOWED_ATTACHMENT_FILE_TYPES_MIME = {
		'image/*': IMAGE_FILE_TYPES,
		'application/*': [
			FileType.PDF,
			FileType.XLSX,
			FileType.XLS,
			FileType.DOCX,
			FileType.DOC,
		],
		'text/*': [FileType.CSV],
	};

	const closeInvoiceModal = React.useCallback(() => setShowInvoiceModal(false), []);
	const closeLeaveModal = React.useCallback(() => setShowLeaveModal(false), []);

	const fetchAndInitializeData = React.useCallback(async () => {
		// get invoice codes to check uniqueness
		const invoiceCodes = await getAllInvoiceCodesForJob(+jobId);
		setExistingInvoiceCodes(invoiceCodes);

		// get company setting for notifications
		const areAutomaticInvoiceRemindersEnabledForCompany = await areAutomaticRemindersEnabled();
		setAutomaticRemindersEnabled(areAutomaticInvoiceRemindersEnabledForCompany);

		if (invoiceId !== undefined && jobId) {
			const invoiceVM = await getInvoiceById(+jobId, +invoiceId);
			if (!invoiceVM) {
				throw new Error('Invoice not found');
			}
			initialize(InvoiceFM.fromVMtoFM(invoiceVM));
			return;
		} else {
			initialize({
				status: InvoiceStatusDisplay.DRAFT,
				excludeFromAutomaticReminders: false,
				sendReminderOnInvoice: false,
				billingContacts: billingEmailsToPrefill?.map((_em: string) => { return { email: _em, editable: false }; }),
			});
		}
	}, [getAllInvoiceCodesForJob, areAutomaticRemindersEnabled, jobId, invoiceId, getInvoiceById, initialize, billingEmailsToPrefill]);

	// in order to be used only once the component is mounted, deps are left empty
	React.useEffect(() => {
		fetchAndInitializeData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearPendingAttachments = React.useCallback(async () => {
		if (currentFormValues?.uploadedAttachmentIds?.length) {
			await deletePendingAttachments({ attachmentIds: currentFormValues?.uploadedAttachmentIds });
		}
	}, [currentFormValues?.uploadedAttachmentIds, deletePendingAttachments]);

	const onBack = React.useCallback(() => {
		setShowLeaveModal(true);
	}, []);

	const goBackToTable = React.useCallback(() => {
		clearPendingAttachments();
		if (backToAccountingPage) {
			history.push({
				pathname: CLIENT.COMPANY.ACCOUNTING.INVOICES.TABLE(orgAlias, companyName),
				state: {
					orgAlias,
				},
			});
		} else {
			history.push({
				pathname: CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, jobId),
				state: {
					orgAlias,
					activeTabEnum: PreviewTabsEnum.INVOICES,
				},
			});
		}
	}, [backToAccountingPage, clearPendingAttachments, companyName, history, jobId, orgAlias]);

	const onSubmit = React.useCallback(async (form: InvoiceFM) => {
		if (form.id) {
			await updateInvoice(+jobId, form.id, InvoiceFM.fromFMtoRM(form));
		} else {
			await createInvoice(+jobId, InvoiceFM.fromFMtoRM(form));
		}
		goBackToTable();
	}, [createInvoice, goBackToTable, jobId, updateInvoice]);

	const onInvoice = React.useCallback(async (form: InvoiceFM) => {
		setCurrentlyFilledForm(form);
		setShowInvoiceModal(true);
	}, []);

	const invoiceDraft = React.useCallback(async () => {
		if (!currentlyFilledForm) {
			throw new Error('Missing form values');
		}
		setInvoicing(true);
		currentlyFilledForm.status = InvoiceStatusDisplay.INVOICED;
		currentlyFilledForm.invoicingDate = TimeUtils.formatDate(new Date, TimeFormat.DB_DATE_ONLY);
		onSubmit(currentlyFilledForm);
		setCurrentlyFilledForm(null);
	}, [currentlyFilledForm, onSubmit]);

	const invoiceStatusDraftValue = React.useMemo(() => {
		return (
			<span className={`${styles['invoice-form__status-cell']} ${styles['invoice-form__status-cell--draft']} `}>
				<span className="icon-draft" />
				<b>Draft</b>
			</span>
		);
	}, []);

	const handleAttachmentsUpload = React.useCallback((attachmentIds: number[]) => {
		change('uploadedAttachmentIds', [...attachmentIds]);
	}, [change]);

	const handleAttachmentDeleted = React.useCallback((attachmentId: number) => {
		const indexOfAttachmentId = currentFormValues.uploadedAttachmentIds?.findIndex((_id) => _id === attachmentId);
		if (indexOfAttachmentId !== undefined && indexOfAttachmentId !== -1 && currentFormValues.uploadedAttachmentIds) {
			const nextAttachmentIds = [...currentFormValues.uploadedAttachmentIds];
			nextAttachmentIds.splice(indexOfAttachmentId, 1);
			change('uploadedAttachmentIds', nextAttachmentIds);
		}
	}, [change, currentFormValues]);

	const handleAttachmentsChanged = React.useCallback((currentAttachments: InvoiceFM['attachments'], changedAttachments: InvoiceFM['attachments']) => {
		const addedAttachments: InvoiceFM['attachments'] = [...currentAttachments];
		// UGLY but needed. Simple table Field component cannot handle bulk change.
		// this was written as a workaround.
		changedAttachments.forEach((_att) => {
			addedAttachments.push(_att);
			change('attachments', addedAttachments);
		});
	}, [change]);

	const handleAttachmentUploadStart = React.useCallback((correlationId: string) => {
		const nextUploadingAttachments = { ...currentFormValues.uploadingAttachments };
		nextUploadingAttachments[correlationId] = true;
		change('uploadingAttachments', nextUploadingAttachments);
	}, [change, currentFormValues]);

	const handleAttachmentUploadEnd = React.useCallback((correlationId: string) => {
		const nextUploadingAttachments = { ...currentFormValues.uploadingAttachments };
		delete nextUploadingAttachments[correlationId];
		change('uploadingAttachments', nextUploadingAttachments);
	}, [change, currentFormValues]);

	const handleFileUpload = React.useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {

		if (fileRejections.length > 0) {
			let message = `Uploading of ${fileRejections[0].file.name} failed.`;
			if (fileRejections[0].errors[0].code === 'file-invalid-type') {
				message = 'Uploading failed - File type not supported';
			}
			notificationSnackbar.error(message, new Date());
			return;
		}

		const correlationId = nanoid();
		handleAttachmentUploadStart?.(correlationId);
		let uploadedAttachments: PendingAttachmentVM[] = [];

		const msgBase = acceptedFiles.length > 1
			? `${acceptedFiles[0].name} and ${acceptedFiles.length - 1} other file${acceptedFiles.length > 2 ? 's' : ''}`
			: acceptedFiles[0].name;

		const notification: Nullable<NotificationData> = notificationSnackbar.loading(`Uploading ${msgBase}`, new Date());

		try {
			uploadedAttachments = await uploadPendingAttachments({ requestFiles: acceptedFiles }, BlobStorageContainer.INVOICES_ATTACHMENTS);

			if (notification) {
				notificationSnackbar.removeNotificationSnackbar(notification);
				const notificationText = `${msgBase} has successfully been uploaded.`;
				notificationSnackbar.success(notificationText, new Date());
			}
		} finally {
			handleAttachmentUploadEnd?.(correlationId);
		}

		const nextUploadedAttachmentIds = [...(currentFormValues.uploadedAttachmentIds ?? []), ...uploadedAttachments.map(getId)];
		const nextUploadedAttachments = uploadedAttachments.map((_att) => ({ ..._att, fileName: _att.name, uploadedBy: { fullName: currentUserFullName } }));
		handleAttachmentsChanged(currentFormValues.attachments ?? [], nextUploadedAttachments);
		handleAttachmentsUpload(nextUploadedAttachmentIds);
	}, [
		currentFormValues,
		handleAttachmentUploadEnd,
		handleAttachmentUploadStart,
		handleAttachmentsChanged,
		handleAttachmentsUpload,
		uploadPendingAttachments,
		notificationSnackbar,
		currentUserFullName,
	]);

	const uploading = uploadProgress !== 100 && uploadProgress !== 0;

	const style: Partial<React.CSSProperties> | undefined = uploading
		? { marginTop: '15px', padding: '1px', background: `linear-gradient(to right, #9FCAFF ${uploadProgress}%, transparent ${uploadProgress}% 100%)` }
		: { marginTop: '15px' };

	const isSaveFormEnabled = React.useMemo(() => {
		return InvoiceFM.saveFormEnabled(valid, currentFormValues);
	}, [currentFormValues, valid]);

	const checkUniqueInvoiceCode = React.useCallback((value: string) => {
		if (!value || !existingInvoiceCodes) {
			return true;
		}
		if (existingInvoiceCodes.some((_item) => _item.invoiceCode === value && (!invoiceId || +invoiceId !== _item.id))) {
			return 'Invoice ID already taken';
		}
	}, [existingInvoiceCodes, invoiceId]);

	const manualReminderTooltipMessage =
		<>
			<strong>Send Manual Reminder</strong> is disabled until the form has been invoiced and all changes have been saved.
		</>;

	return (

		<>

			<Breadcrumbs
				items={[
					{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
					{ label: jobCode, url: CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, jobId) },
					{ label: 'Invoices', onClick: goBackToTable },
					{ label: invoiceId ? 'Edit' : 'New Invoice' },
				]}
			/>
			<div className={styles['invoice-form__tab-content__form']}>
				<div className={styles['invoice-form__submit-section']}>
					<div className={styles['invoice-form__submit-section__invoice-code-and-reminder']}>
						<span className={styles['invoice-form__submit-section__invoice-code-and-reminder__code']}>
							{currentFormValues?.invoiceCode ?? 'NEW INVOICE'}
						</span>

						{
							isSendingInvoiceNotificationsEnabled &&
							<Tooltip message={manualReminderTooltipMessage}>
								<Button disabled={true} variant="info">
									<span className="icon-email" />
									Send Manual Reminder
								</Button>
							</Tooltip>
						}
					</div>
					<Button className={styles['invoice-form__back-to-list']} onClick={onBack} variant="info">
						Back
					</Button>
					<SubmitButton
						disabled={!isSaveFormEnabled}
						label="Save"
						onClick={handleSubmit(onSubmit)}
						reduxFormSubmitting={!invoicing && submitting}
					/>
					<SubmitButton
						disabled={!isSaveFormEnabled}
						label="Invoice"
						onClick={handleSubmit(onInvoice)}
						reduxFormSubmitting={invoicing}
					/>
				</div>
				<div className={styles['invoice-form__tab-content__form__section']}>
					<div className={styles['invoice-form__tab-content__form__section__title']}>
						GENERAL INFO
					</div>
					<div className={styles['invoice-form__tab-content__form__section__row']}>
						<div className={styles['invoice-form__tab-content__form__section__row__column-id']}>
							<Field
								component={Input}
								id="invoiceCode"
								isRequired={true}
								label="Invoice ID"
								name={InvoiceFM.getAttributeName('invoiceCode')}
								placeholder="Invoice ID"
								required={true}
								type="text"
								validate={checkUniqueInvoiceCode}
							/>
						</div>
						<div className={styles['invoice-form__tab-content__form__section__row__column-date']}>
							<Field
								component={DateInput}
								dateFormat={TimeFormat.DB_DATE_ONLY}
								id="dateCreated"
								isRequired={true}
								label="Date Created"
								name={InvoiceFM.getAttributeName('dateCreated')}
								originalDateFormat={TimeFormat.DB_DATE_ONLY}
								placeholderText={TimeFormat.DB_DATE_ONLY}
								required={true}
							/>
						</div>
						<div className={styles['invoice-form__tab-content__form__section__row__column-date']}>
							<Field
								component={DateInput}
								dateFormat={TimeFormat.DB_DATE_ONLY}
								id="dueDate"
								isRequired={true}
								label="Due Date"
								name={InvoiceFM.getAttributeName('dueDate')}
								originalDateFormat={TimeFormat.DB_DATE_ONLY}
								placeholderText={TimeFormat.DB_DATE_ONLY}
								required={true}
							/>
						</div>
						<div className={styles['invoice-form__tab-content__form__section__row__column-date']}>
							<LockedValue label="Invoice Status" value={invoiceStatusDraftValue} />

						</div>

					</div>
					<div className={styles['invoice-form__tab-content__form__section__row']}>
						<div className={styles['invoice-form__tab-content__form__section__row__column-note']}>
							<Field
								component={Textarea}
								id="note"
								label="Note"
								maxCharacters={300}
								name={InvoiceFM.getAttributeName('note')}
								rows={1}
								type="text"
							/>
						</div>
					</div>
				</div>
				<div className={styles['invoice-form__tab-content__form__section']}>
					<div className={styles['invoice-form__tab-content__form__section__title']}>
						BILLING CONTACTS
					</div>
					<FieldArray<BillingContactArrayProps>
						change={change}
						component={BillingContactArray}
						name="billingContacts"
						rerenderOnEveryChange={true}
					/>
				</div>
				{
					isSendingInvoiceNotificationsEnabled &&
					<div className={styles['invoice-form__tab-content__form__section']}>
						<div className={styles['invoice-form__tab-content__form__section__title']}>
							REMINDERS
						</div>
						<Field
							component={Checkbox}
							inline={true}
							label="Send email to billing contacts when invoicing"
							name="sendReminderOnInvoice"
							tooltipMessage="Pay attention that if there are no billing contacts listed above, emails still won't be send even if this checkbox is selected."
						/>
						{
							automaticRemindersEnabled &&
							<Field
								component={Checkbox}
								inline={true}
								label="Exclude from automatic reminders"
								name="excludeFromAutomaticReminders"
							/>
						}
					</div>
				}
				<div className={styles['invoice-form__tab-content__form__section']}>
					<div className={styles['invoice-form__tab-content__form__section__title']}>
						PAYMENT INFORMATION
					</div>
					<div className={styles['invoice-form__tab-content__form__section__row']}>
						<div className={styles['invoice-form__tab-content__form__section__row__column-payment-info']}>
							<Field
								component={Input}
								id="totalAmount"
								isDollarValue={true}
								isRequired={true}
								label="Total"
								name={InvoiceFM.getAttributeName('totalAmount')}
								normalize={ReduxUtils.normalizeDecimalNumber}
								required={true}
								type="number"
							/>
						</div>
						<div className={styles['invoice-form__tab-content__form__section__row__column-payment-info']}>
							<LockedValue label="Total Paid" value={dollarFormatter.format(0)} />
						</div>
						<div className={styles['invoice-form__tab-content__form__section__row__column-payment-info']}>
							<LockedValue label="Outstanding" value={dollarFormatter.format(currentFormValues?.totalAmount ?? 0)} />
						</div>
						<div className={styles['invoice-form__tab-content__form__section__row__column-payment-info']}>
							<Field
								component={Input}
								id="retainedAmount"
								isDollarValue={true}
								label="Retained Amount"
								name={InvoiceFM.getAttributeName('retainedAmount')}
								normalize={ReduxUtils.normalizeDecimalNumber}
								type="number"
							/>
						</div>
					</div>
				</div>
				<div className={styles['invoice-form__tab-content__form__section']}>

					<div className={styles['invoice-form__tab-content__form__section__title']}>
						INSTALLMENTS
					</div>
					<div className={styles['invoice-form__tab-content__form__section__installment-notice']}>
						Draft needs to be invoiced in order to add installments.
					</div>
				</div>
				<div className={styles['invoice-form__tab-content__form__section']}>
					<div className={styles['invoice-form__tab-content__form__section__title']}>
						ATTACHMENTS
					</div>
					<FieldArray<AttachmentArrayProps>
						component={AttachmentItems}
						errors={errors}
						initialized={initialized}
						name="attachments"
						onDelete={handleAttachmentDeleted}
						rerenderOnEveryChange={true}
					/>
					<DropzoneWithButton
						allowedTypes={ALLOWED_ATTACHMENT_FILE_TYPES_MIME}
						onDrop={handleFileUpload}
						style={style}
					>
						<FileUploadMessageWithTypes
							uploading={uploading}
						/>
					</DropzoneWithButton>
				</div>
			</div>
			<ConfirmationModal
				body={getInvoiceModalBody()}
				closeModal={closeInvoiceModal}
				closeText="Cancel"
				confirmAction={invoiceDraft}
				confirmText="Continue"
				modalStyle="info"
				showModal={showInvoiceModal}
				size="md"
				title="Invoice draft?"
			/>
			<ConfirmationModal
				body={LEAVE_PAGE_MODAL_BODY}
				closeModal={closeLeaveModal}
				closeText="Stay"
				confirmAction={goBackToTable}
				confirmText="Leave"
				modalStyle="danger"
				showModal={showLeaveModal}
				size="md"
				title="Leave Page?"
			/>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const currentFormValues = getFormValues(FORMS.INVOICE_CREATE_FORM)(state) as InvoiceFM;

	const errors = getFormSyncErrors(FORMS.INVOICE_CREATE_FORM)(state) as FormErrorsWithArray<InvoiceFM, string>;

	const currentUserFullName = `${userData.firstName} ${userData.lastName}`;

	return {
		currentUserFullName,
		companyName: companyData.name,
		isSendingInvoiceNotificationsEnabled: companyData.isSendingInvoiceNotificationsEnabled,
		currentFormValues,
		errors,
		uploadProgress: state.http.uploadProgress,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		createInvoice: JobActions.createInvoice,
		updateInvoice: JobActions.updateInvoice,
		getInvoiceById: JobActions.getInvoiceById,
		getAllInvoiceCodesForJob: JobActions.getAllInvoiceCodesForJob,
		areAutomaticRemindersEnabled: JobActions.areAutomaticRemindersEnabled,
		uploadPendingAttachments: AttachmentActions.uploadPendingAttachments,
		deletePendingAttachments: AttachmentActions.deletePendingAttachments,
	};
}

const formConnector = reduxForm<InvoiceFM>({
	form: FORMS.INVOICE_CREATE_FORM,
	validate: InvoiceFM.validate,

});

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	formConnector,
	connector
);
export default enhance(CreateOrEditInvoice);
