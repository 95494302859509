import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import DailyTip from 'acceligent-shared/models/dailyTip';

export class W_DailyTip_Create_VM {
	id: number;
	companyId: number;
	message: string;
	date: string;
	isCustom: boolean;

	constructor(dailyTip: DailyTip) {
		this.id = dailyTip.id;
		this.companyId = dailyTip.companyId;
		this.message = dailyTip.message;
		this.date = TimeUtils.formatDate(dailyTip.date, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);
		this.isCustom = dailyTip.isCustom;
	}

}
