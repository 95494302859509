import baseRoute from '../baseRoute';

export * from './requestModel';
export * from './viewModel';

type RouteDefinition = `${typeof baseRoute}/portalFunctionalities`;

export const ROUTE: RouteDefinition = `${baseRoute}/portalFunctionalities`;

export const URL = (): RouteDefinition => `${baseRoute}/portalFunctionalities`;
