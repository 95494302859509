import * as React from 'react';
import { CustomRouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { InjectedFormProps, reduxForm, formValueSelector } from 'redux-form';

import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';
import FieldReportAccessRole from 'acceligent-shared/enums/fieldReportAccessRole';

import FieldWorkClassificationCodeListItemVM from 'acceligent-shared/dtos/web/view/fieldWorkClassificationCode/listItem';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import * as FieldReportActions from 'af-actions/fieldReport';
import * as FieldWorkClassificationCodeActions from 'af-actions/fieldWorkClassificationCode';
import * as EquipmentActions from 'af-actions/equipment';
import * as TimeSheetActions from 'af-actions/timeSheet';
import * as TimeSplitEntryActions from 'af-actions/timeSplitEntry';
import * as WorkOrderActions from 'af-actions/workOrder';
import * as AccountActions from 'af-actions/accounts';
import * as FieldReportAccessActions from 'af-actions/fieldReportAccess';

import CondensedTableVM from 'ab-viewModels/workOrder/workOrderForReportsCondensedTable';
import AccountOptionsVM from 'ab-viewModels/account/option.viewModel';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import * as SettingsUtils from 'af-utils/settings.util';
import { debounce } from 'af-utils/actions.util';

import * as SettingsKeys from 'af-constants/settingsKeys';
import * as FORMS from 'af-constants/reduxForms';

import ScrollToLoad from 'af-components/ScrollToLoad';
import Toast from 'af-components/Toast';

import Header from './Header';
import Filter from './Filter';
import WorkOrderCard from './WorkOrderCard';
import FormModel from './formModel';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

type Props = CustomRouteComponentProps & SettingProps & ConnectedProps<typeof connector> & InjectedFormProps<FormModel>;

interface State {
	accounting: AccountOptionsVM[];
	management: AccountOptionsVM[];
	data: CondensedTableVM[];
	totalCount: number;
	classificationCodeList: FieldWorkClassificationCodeListItemVM[];
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	textFilter: string;
	sortBy: keyof CondensedTableVM;
	sortAscending: boolean;
	loaded: boolean;
	pageNumber: number;
}

const END_MESSAGE = (
	<Toast>No more Work Orders to display</Toast>
);

const PAGE_SIZE = 5;

class CondensedTable extends React.PureComponent<Props, State> {
	state: State = {
		totalCount: 0,
		data: [],
		classificationCodeList: [],
		accounting: [],
		management: [],
		startDate: this.props.startDate,
		endDate: this.props.endDate,
		period: this.props.period,
		textFilter: '',
		sortAscending: true,
		sortBy: 'dueDate',
		loaded: false,
		pageNumber: 0,
	};

	_list: Nullable<ScrollToLoad<CondensedTableVM>> = null;

	async componentDidMount() {
		const { findClassificationCodes, findAllAccounting, findAllManagement } = this.props;
		const [classificationCodeList, accounting, management] = await Promise.all([
			findClassificationCodes(),
			findAllAccounting(),
			findAllManagement(),
		]);
		if (!classificationCodeList) {
			throw new Error('classificationCodeList is required');
		}
		this.setState(() => ({ classificationCodeList, accounting, management, loaded: true }));
	}

	fetch = async (limit: number, page: number, isRefresh: boolean) => {
		const { findAllForReportsTable, initialize, formValues, change } = this.props;
		const { startDate, endDate, data: stateData, textFilter, sortAscending, sortBy } = this.state;
		const rows = await findAllForReportsTable(startDate, endDate, page, limit, textFilter, sortBy, sortAscending);

		const data = isRefresh ? rows?.rows ?? [] : [...stateData, ...rows?.rows ?? []];
		const formData = FormModel.bulkConstructor(rows?.rows ?? []);

		if (isRefresh || !formValues) {
			initialize(formData);
		} else {
			change('workOrderMap', { ...formData.workOrderMap, ...formValues });
		}

		this.setState(() => ({
			data,
			totalCount: rows?.totalCount ?? 0,
		}));
	};

	onSortChange = (sortBy: keyof CondensedTableVM) => {
		this.setState((state) => ({
			sortBy,
			sortAscending: state.sortBy === sortBy ? !state.sortAscending : true,
		}), () => {
			this._list?.refreshList();
		});
	};

	refreshWorkOrderData = async (workOrderId: number, index: number) => {
		const { findById, change } = this.props;
		const { data } = this.state;

		const workOrderData = await findById(workOrderId);

		let _index = index;
		if (data[_index]?.id !== workOrderData?.id) {
			_index = data.findIndex((_workOrder) => _workOrder.id === workOrderData?.id);
		}

		if (_index !== -1) {
			const copy = [...data];
			copy.splice(_index, 1, workOrderData);
			this.setState(() => ({ data: copy }));
			const formData = FormModel.bulkConstructor(copy);
			change(`workOrderMap[${workOrderId}]`, { ...formData.workOrderMap[workOrderId] });
		}
	};

	setDateFilter = (period: TimePeriodRecurrence, startDate: Date, endDate: Date) => {
		SettingsUtils.setWorkOrderReportsPeriod(period);
		SettingsUtils.setWorkOrderReportsStartDate(startDate);
		SettingsUtils.setWorkOrderReportsEndDate(endDate);
		this.setState(() => ({ startDate, endDate, period }), this._list?.refreshList);
	};

	refreshList = () => this._list?.refreshList();

	// eslint-disable-next-line @typescript-eslint/member-ordering
	refreshListDebounced = debounce(this.refreshList, 300);

	onFilterTextChangeDebouncedHandler = (value: string) => {
		this.setState(
			() => ({ textFilter: value }),
			() => this.refreshListDebounced()
		);
	};

	onMount = (list: ScrollToLoad<CondensedTableVM>) => {
		this._list = list;
	};

	setPage = (pageNumber: number) => {
		this.setState(() => ({ pageNumber }));
	};

	refetch = () => {
		this.setPage(0);
		this.fetch(PAGE_SIZE, 0, true);
	};

	renderRow = (workOrder: CondensedTableVM, index: number) => {
		const {
			currentAccountId,
			fieldReportAccessRole,
			companyData,
			findEquipment,
			rejectWorkOrder,
			approveWorkOrder,
			finalizeWorkOrder,
			updateEquipment,
			updateTimeSheet,
			change,
			findEmployeesWithAccess,
			submitForReview,
			getOccupiedSlots,
			isAccounting,
			isManagement,
			hasPermissionToEdit,
			hasPermissionToFinalize,
			hasWorkOrderOverlaps,
			findTrackedEntriesForWorkOrder,
			endShiftsForWorkOrder,
		} = this.props;

		const { classificationCodeList, accounting, management } = this.state;

		return (
			<WorkOrderCard
				accounting={accounting}
				approveWorkOrder={approveWorkOrder}
				change={change}
				classificationCodeList={classificationCodeList}
				companyName={companyData.name}
				currentAccountId={currentAccountId}
				data={workOrder}
				endShiftsForWorkOrder={endShiftsForWorkOrder}
				fieldReportAccessRole={fieldReportAccessRole}
				finalizeWorkOrder={finalizeWorkOrder}
				findEmployeesWithAccess={findEmployeesWithAccess}
				findTrackedEntriesForWorkOrder={findTrackedEntriesForWorkOrder}
				getOccupiedSlots={getOccupiedSlots}
				hasPermissionToEdit={hasPermissionToEdit}
				hasPermissionToFinalize={hasPermissionToFinalize}
				hasWorkOrderOverlaps={hasWorkOrderOverlaps}
				index={index}
				isAccounting={isAccounting}
				isManagement={isManagement}
				key={workOrder.id}
				loadEquipment={findEquipment}
				management={management}
				refetch={this.refetch}
				refresh={this.refreshWorkOrderData}
				rejectWorkOrder={rejectWorkOrder}
				submitForReview={submitForReview}
				updateEquipment={updateEquipment}
				updateTimeSheet={updateTimeSheet}
			/>
		);
	};

	render() {
		const { data, totalCount, startDate, endDate, period, textFilter, sortAscending, sortBy, loaded, pageNumber } = this.state;
		return (
			<>
				<Filter
					endDate={endDate}
					filterText={textFilter}
					onFilterTextChange={this.onFilterTextChangeDebouncedHandler}
					period={period}
					setDateFilter={this.setDateFilter}
					startDate={startDate}
				/>
				<Header
					onSortChange={this.onSortChange}
					sortedAscending={sortAscending}
					sortedBy={sortBy}
				/>
				<ScrollToLoad
					data={data}
					endMessage={END_MESSAGE}
					fetch={this.fetch}
					handlePageChange={this.setPage}
					pageNumber={pageNumber}
					pageSize={PAGE_SIZE}
					ref={this.onMount}
					reloadProp={loaded}
					renderItem={this.renderRow}
					totalCount={totalCount}
				/>
			</>
		);
	}
}

const selector = formValueSelector(FORMS.CONDENSED_TABLE_EMPLOYEE);
function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const isAccounting = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.ACCOUNTING, permissions, isCompanyAdmin, role);
	const isManagement = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.MANAGEMENT, permissions, isCompanyAdmin, role);
	const hasPermissionToEdit = isAccounting || isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FILL, permissions, isCompanyAdmin, role);
	const hasPermissionToFinalize = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FINALIZE, permissions, isCompanyAdmin, role);

	const formValues: FormModel['workOrderMap'] = selector(state, 'workOrderMap');

	let fieldReportAccessRole: Nullable<FieldReportAccessRole> = null;

	if (companyData.assignableAsAccounting || isCompanyAdmin) {
		fieldReportAccessRole = FieldReportAccessRole.ACCOUNTING;
	} else if (companyData.assignableAsManagement) {
		fieldReportAccessRole = FieldReportAccessRole.MANAGEMENT;
	}

	return {
		formValues,
		userData,
		companyData,
		fieldReportAccessRole,
		hasPermissionToEdit,
		hasPermissionToFinalize,
		isManagement,
		isAccounting,
		currentAccountId: companyData.accountId,
	};
}

function mapDispatchToProps() {
	return {
		findAllForReportsTable: FieldReportActions.findAllForReportsCondensedTable,
		findById: FieldReportActions.findByIdForReportsCondensedTable,
		findClassificationCodes: FieldWorkClassificationCodeActions.findAllForCompanyList,
		findEquipment: EquipmentActions.findListForTimeSplitsByWorkOrder,
		rejectWorkOrder: WorkOrderActions.rejectReview,
		approveWorkOrder: WorkOrderActions.approveReview,
		finalizeWorkOrder: WorkOrderActions.finalizeReview,
		submitForReview: WorkOrderActions.submitForReview,
		updateTimeSheet: TimeSheetActions.updateWorkOrderTimeSheetEntriesForAccount,
		getOccupiedSlots: TimeSheetActions.findOccupiedSlots,
		hasWorkOrderOverlaps: TimeSheetActions.hasWorkOrderOverlaps,
		updateEquipment: TimeSplitEntryActions.updateWorkOrderTimeSplitEntriesForAccount,
		findAllAccounting: AccountActions.findAllAssignableAsAccounting,
		findAllManagement: AccountActions.findAllAssignableAsManagement,
		findEmployeesWithAccess: FieldReportAccessActions.findAllByWorkOrderId,
		findTrackedEntriesForWorkOrder: TimeSheetActions.findTrackedEntriesForWorkOrder,
		endShiftsForWorkOrder: TimeSheetActions.endShiftsForWorkOrder,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(),
			mappedName: 'startDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(),
			mappedName: 'endDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	reduxForm<FormModel>({ form: FORMS.CONDENSED_TABLE_EMPLOYEE }),
	withRouter,
	connector
);

export default enhance(CondensedTable);
