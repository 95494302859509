import * as React from 'react';
import { Form, Field, FormSubmitHandler, reduxForm, InjectedFormProps } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import SubmitButton from 'af-components/SubmitButton';

import Input from 'af-fields/Input';

import CLIENT from 'af-constants/routes/client';

import { USER_EMAIL_LOGIN } from 'af-constants/reduxForms';

import { emailValidate } from './validations';

import * as UserRequestModel from 'ab-requestModels/users.requestModel';

interface OwnProps {
	orgAlias: string;
	onSubmit: FormSubmitHandler;
}

type Props = OwnProps & InjectedFormProps<UserRequestModel.UserEmailCredentials, OwnProps>;

class EmailForm extends React.PureComponent<Props> {
	render() {
		const { onSubmit, orgAlias, submitting, handleSubmit } = this.props;

		return (
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col sm={24}>
						<Field
							component={Input}
							id="email"
							label="Email"
							name="email"
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<Field
							additionalElement={
								<Link
									className="additional-input-element"
									tabIndex={-1}
									to={CLIENT.AUTH.PASS_FORGOTTEN(orgAlias)}
								>
									Forgot Password?
								</Link>
							}
							component={Input}
							id="password"
							label="Password"
							name="password"
							type="password"
						/>
					</Col>
				</Row>
				<Row className="row--padded">
					<Col sm={24}>
						<SubmitButton
							className="btn--full"
							label="Log in"
							reduxFormSubmitting={submitting}
						/>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default reduxForm<UserRequestModel.UserEmailCredentials, OwnProps>({ form: USER_EMAIL_LOGIN, validate: emailValidate })(EmailForm);
