import * as React from 'react';

import * as TimeUtils from 'acceligent-shared/utils/time';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import styles from './styles.module.scss';

type Props = {
	/** YYYY-MM-DDTHH:mm:ss.SSSZ */
	date: Date;
};

const DateCell: React.FC<Props> = ({ date }) => {
	const updatedAtDate = React.useMemo(() => TimeUtils.formatDate(date, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME), [date]);
	const updatedAtTime = React.useMemo(() => TimeUtils.formatDate(date, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME), [date]);

	return (
		<span className={styles['updated-by-cell']}>
			{updatedAtDate}
			<div className={styles['updated-by-cell__time']}>
				{updatedAtTime}
			</div>
		</span>
	);
};

export default React.memo(DateCell);
