import * as React from 'react';

interface OwnProps {
	uniqueId: string;
}

type Props = OwnProps;

const UniqueIdIndicator: React.FC<Props> = (props: Props) => {
	const { uniqueId } = props;

	return (
		<span className="sb-resource-item__unique-id">{uniqueId}</span>
	);
};

export default React.memo(UniqueIdIndicator);
