import { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { DeliverableDataRequestModel } from 'ab-requestModels/deliverableData.requestModel';

import { DeliverableDataListViewModel } from 'ab-viewModels/deliverableData.viewModel';

import { GetRootState } from 'af-reducers';

export function create(form: DeliverableDataRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.RESOURCES.DELIVERABLE.DATA.CREATE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(form: DeliverableDataRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			if (!form.id) {
				throw new Error('Deliverable ID not provided');
			}
			await http.put(API.V1.RESOURCES.DELIVERABLE.DATA.EDIT(form.id.toString()), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteData(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.DELIVERABLE.DATA.DELETE(id.toString()));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<DeliverableDataListViewModel>(API.V1.RESOURCES.DELIVERABLE.DATA.LIST);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
