import * as React from 'react';

import { TimeSplitEntryVM } from 'acceligent-shared/dtos/web/view/timeSheet/timeSheet';

interface OwnProps {
	timeSplitEntries: TimeSplitEntryVM[];
}

type Props = OwnProps;

const TimeSplitClassificationCodes: React.FC<Props> = (props) => {
	const { timeSplitEntries } = props;

	const renderTimeSplitClassificationCode = React.useCallback((timeSplitEntry: TimeSplitEntryVM) => {
		const { id, classificationCode } = timeSplitEntry;

		return (
			<div className="time-sheet-list__row__cell__classification-code" key={id}>
				{classificationCode ?? ''}
			</div>
		);
	}, []);

	return (
		<>{timeSplitEntries.map(renderTimeSplitClassificationCode)}</>
	);
};

export default React.memo(TimeSplitClassificationCodes);
