import * as React from 'react';
import { getFormValues } from 'redux-form';

import { SUBMIT_DELAY } from 'af-constants/values';
import AppStore from 'af-root/store';

type ActionFunctionType = (...params) => Promise<void> | void;

export default class AutosaveComponent<P = Record<string, unknown>, S = Record<string, unknown>> extends React.Component<P, S> {
	autosaveTimeout: number;

	static triggerStatelessAutosave = (form: Metadata, action: ActionFunctionType, ...optional): void => {
		setTimeout(async () => {
			await action(form, ...optional);
		}, SUBMIT_DELAY);
	};

	cancelAutosave = async (): Promise<void> => {
		if (this.autosaveTimeout) {
			window.clearTimeout(this.autosaveTimeout);
		}
	};

	/** FIXME: not async */
	triggerAutosave = async (formName: string, action: ActionFunctionType, ...optional): Promise<void> => {
		if (this.autosaveTimeout) {
			window.clearTimeout(this.autosaveTimeout);
		}
		this.autosaveTimeout = window.setTimeout(async () => {
			const form = getFormValues(formName)(AppStore.getStore().getState());
			await action(form, ...optional);
		}, SUBMIT_DELAY);
	};

	/** FIXME: not async */
	triggerCustomAutosave = async (form: Metadata, action: ActionFunctionType, ...optional): Promise<void> => {
		if (this.autosaveTimeout) {
			window.clearTimeout(this.autosaveTimeout);
		}
		this.autosaveTimeout = window.setTimeout(async () => {
			await action(form, ...optional);
		}, SUBMIT_DELAY);
	};

}
