import * as React from 'react';

import { getHostnameWithAlias } from 'ab-constants/value';

import { bemElement } from 'ab-utils/bem.util';

const privacyPolicyLink = `${getHostnameWithAlias('app')}/${'privacyPolicy'}`;

export default () => (
	<div className="form-segment text-page">
		<div className={`form-box form-box--midi ${bemElement('text-page', 'container')}`}>
			<div className={bemElement('text-page', 'title')}>
				<h2>Acceligent Messaging Terms of Service</h2>
				<h4>Updated July 15, 2019</h4>
			</div>
			<strong>Scope</strong>
			<p>
				These Messaging Terms of Service cover the use of mobile device text and multimedia messaging services provided by Acceligent.
				These Messaging Terms of Service may be superseded by the applicable terms of any agreements governing the
				relationship between your organization and Acceligent.
			</p>
			<strong>Terms of Service</strong>
			<p>
				Acceligent is a comprehensive construction management solution that helps contractors bid smarter,
				utilize resources more efficiently and improve job performance. The Acceligent messaging service is designed to transmit important schedule,
				performance and safety information in an efficient and timely manner. The Acceligent messaging service may not be used to send marketing or
				commercial messages to users. Users and organizations violating these Messaging Terms of Service may face account suspension or termination.
				Acceligent products and services are made available to users through organizations. The organization account administrator is responsible for
				determining the messaging and notification policy for the organization. In addition, your organization may require you to receive, accept and
				reply to Acceligent messages and notifications as a condition of your employment. To ensure accurate communication between users, Acceligent
				collects information about your messaging subscription status and may share such information with other users in your organization. Please
				contact your organization’s account administrator for additional information regarding messaging and notification.
			</p>
			<ol>
				<li>
					When you register for Acceligent, or when your organization’s account administrator creates a user account for you, Acceligent will send
					you an SMS message to confirm your signup.
				</li>
				<li>Acceligent SMS messages are delivered from the short code number 222-354 (Acceli).</li>
				<li>
					You can cancel the SMS service at any time. Just text “STOP” to 222-354. After you send the SMS message “STOP” to Acceligent,
					we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from
					Acceligent. If you want to join again, just sign up as you did the first time and Acceligent will start sending SMS messages to you again.
				</li>
				<li>
					If at any time you forget what keywords are supported, just text “HELP” to 222-354. After you send the SMS message “HELP” to Acceligent,
					we will respond with instructions on how to use our service as well as how to unsubscribe.
				</li>
				<li>
					Acceligent is able to deliver messages to the following mobile phone carriers:
					Major carriers: AT&T, Verizon Wireless, Sprint, T-Mobile, MetroPCS, U.S. Cellular, Alltel, Boost Mobile, Nextel, and Virgin Mobile.
					Minor carriers: Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular,
					Cellular One of East Central IL (ECIT), Cellular One of Northeast Pennsylvania, Cincinnati Bell Wireless, Cricket,
					Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden State,
					Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa Wireless),
					Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications,
					Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless,
					Viaero Wireless, and West Central (WCC or 5 Star Wireless). ***Carriers are not liable for delayed or undelivered messages***
				</li>
				<li>
					As always, message and data rates may apply for any messages sent to you from Acceligent and to us from you.
					You may receive several messages per day. If you have any questions about your text plan or data plan,
					it is best to contact your wireless provider. For all questions about the services provided by this short code,
					you can send an email to {process.env.SUPPORT_EMAIL}.
				</li>
				<li>If you have any questions regarding privacy, please read our <a className="text-blue" href={privacyPolicyLink}>privacy policy</a>.</li>
			</ol>
		</div>
	</div>
);
