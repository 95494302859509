import * as React from 'react';

import SettingPreview from '../../Shared/SettingPreview';

type Props = {
	prefix: Nullable<string>;
	toggleEdit: () => void;
};

const PurchaseOrderNumberPrefixPreview: React.FC<Props> = ({ prefix, toggleEdit }) => {
	return (
		<SettingPreview toggleEdit={toggleEdit}>
			<div className="company-settings__info-segment">
				Current Purchase Order prefix: {prefix ?? 'No prefix set'}
			</div>
		</SettingPreview>
	);
};

export default React.memo(PurchaseOrderNumberPrefixPreview);
