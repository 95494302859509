import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import { Field } from 'redux-form';
import { Option } from 'react-select/src/filters';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import CreateTemporaryEmployeeAssignmentRM from 'ab-requestModels/workOrderTemporaryEmployee/createTemporaryEmployeeAssignment';

import AgencyVM from 'ab-viewModels/agency/agency.viewModel';
import TemporaryEmployeeOptionVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';
import { TemporaryEmployeeAssignmentsVM } from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import { RootState } from 'af-reducers';

import { TEMPORARY_EMPLOYEE_ASSIGNMENT } from 'af-constants/reduxForms';

import CustomModal from 'af-components/CustomModal';
import LabelWithColor from 'af-components/LabelWithColor';
import SubmitButton from 'af-components/SubmitButton';

import Select from 'af-fields/SelectField';

import * as TemporaryEmployeeActions from 'af-actions/temporaryEmployee';
import * as AgencyActions from 'af-actions/agency';

import CreateTemporaryEmployeeForm from './CreateTemporaryEmployeeForm';
import CreateTemporaryEmployeeAssignmentForm, { CreateTemporaryEmployeeForm as CreateTemporaryEmployeeFM, TemporaryEmployeeForm } from './createTemporaryEmployeeAssignmentForm';
import { validate } from './validation';

type TemporaryEmployeeOption = TemporaryEmployeeOptionVM & { isDisabled: boolean; };

interface OwnProps {
	/** MM-DD-YYYY */
	dueDate: string;
	initialValues: { index: Nullable<number>; workOrderId: Nullable<number>; };
	onClose: () => void;
	onSubmit: (workOrderId: number, data: CreateTemporaryEmployeeAssignmentRM) => void;
	temporaryEmployeeAssignments: TemporaryEmployeeAssignmentsVM | undefined;
	workOrderCode: string;
}

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & InjectedFormProps<CreateTemporaryEmployeeAssignmentForm, FormOwnProps>;

const getPlaceHolder = (isAllowedToCreate: boolean) => `Select Temporary Laborer ${isAllowedToCreate ? 'or Create New' : ''}`;

const getOptionText = (option: Nullable<TemporaryEmployeeOption>) => option ? `${option.fullName} ${option.agency} ${option.uniqueId}` : '';

const getOptionValue = (option: Nullable<TemporaryEmployeeOption>) => `${option?.id ?? ''}`;

const isValidNewOption = (inputValue: string, value: TemporaryEmployeeOption, options: TemporaryEmployeeOption[]) => {
	return !!inputValue && !options.some(({ fullName }) => fullName.toLowerCase() === inputValue.toLowerCase());
};

const getEmployeeMapper = (temporaryEmployeeAssignments: TemporaryEmployeeAssignmentsVM, workOrderCode: string) => {
	return (employee: TemporaryEmployeeOptionVM) => {
		const workOrderCodes = temporaryEmployeeAssignments[employee.id];
		if (workOrderCodes?.includes(workOrderCode)) {
			return { ...employee, isDisabled: true };
		}
		return { ...employee, isDisabled: false };
	};
};

const getNewOptionData = (inputValue: string): { __isNew__: boolean; } & Partial<TemporaryEmployeeOption> => {
	return {
		__isNew__: true,
		fullName: inputValue,
	};
};

const renderMenuItem = (item: TemporaryEmployeeOption) => {
	return (
		<div>
			<div>
				<span className="temp-labor-assign-modal__employee-option__name">{item.fullName}</span>
				<span>({item.uniqueId})</span>
			</div>
			<div>
				<LabelWithColor
					className="temp-labor-assign-modal__employee-option__agency"
					color={item.agencyColor}
					text={item.agency}
				/>
			</div>
		</div>
	);
};

const renderSelectedItem = (item: TemporaryEmployeeOption) => {
	return (
		<div>
			<span className="temp-labor-assign-modal__employee-option__name">{item.fullName}</span>
			<span>({item.uniqueId})</span>
		</div>
	);
};

const formatCreateLabel = (inputValue: string) => {
	return (
		<div>
			<span className="react-select-dropdown__create-label">
				<span className="icon-plus" />
				Create New Temporary Laborer:
			</span>
			<span>{inputValue}</span>
		</div>
	);
};

const CreateTemporaryLaborForm: React.FC<Props> = (props: Props) => {
	const {
		change,
		dueDate,
		findAllAgencies,
		findEmployees,
		handleSubmit,
		initialize,
		initialValues,
		invalid,
		isAllowedToCreate,
		isTemporaryEmployeeNameAvailable,
		onClose,
		onSubmit,
		selector,
		submitting,
		temporaryEmployeeAssignments,
		workOrderCode,
	} = props;

	const [showCreateEmployeeForm, setShowCreateEmployeeForm] = React.useState(false);
	const [createEmployeeInitialValues, setCreateEmployeeInitialValues] = React.useState<Nullable<CreateTemporaryEmployeeFM>>(null);
	const [employees, setEmployees] = React.useState<TemporaryEmployeeOption[]>([]);
	const [isFormInitialized, setIsFormInitialized] = React.useState(false);
	const [agencies, setAgencies] = React.useState<AgencyVM[]>([]);

	React.useEffect(() => {
		if (!isFormInitialized) {
			initialize({ ...initialValues, newTemporaryEmployee: createEmployeeInitialValues });
			setIsFormInitialized(true);
		}
	}, [isFormInitialized, setIsFormInitialized, initialValues, initialize, createEmployeeInitialValues]);

	const filterBy = React.useCallback((option: Option, searchText: string) => {
		if (!option) {
			return false;
		}
		const text = getOptionText(option.data);
		return text.toLowerCase().includes(searchText.toLowerCase());
	}, []);

	const lazyLoadEmployees = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			if (!temporaryEmployeeAssignments) {
				throw new Error('Assignments not loaded');
			}

			const _employees = await findEmployees(TimeUtils.formatDate(dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY));
			setEmployees((_employees ?? []).map(getEmployeeMapper(temporaryEmployeeAssignments, workOrderCode)) ?? []);
		}
	}, [findEmployees, setEmployees, temporaryEmployeeAssignments, workOrderCode, dueDate]);

	const lazyLoadAgencies = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const response = await findAllAgencies();
			setAgencies(response ?? []);
		}
	}, [setAgencies, findAllAgencies]);

	const showCreateTempEmployeeForm = React.useCallback((inputValue: string) => {
		const initialValuesArray = inputValue.split(' ');
		const initialNewEmployee = {
			firstName: initialValuesArray?.[0] ?? null,
			lastName: initialValuesArray?.splice(1)?.join(' ') ?? null,
			agencyId: null,
		};
		setCreateEmployeeInitialValues(initialNewEmployee);
		setIsFormInitialized(false);
		setShowCreateEmployeeForm(true);
	}, [setShowCreateEmployeeForm]);

	const onTemporaryLaborChange = React.useCallback((employee: TemporaryEmployeeOption) => {
		change('temporaryEmployee', TemporaryEmployeeForm.fromViewModel(employee));
		change('newTemporaryEmployee', null);
	}, [change]);

	const onClearTemporaryEmployee = React.useCallback(() => change('temporaryEmployee', null), [change]);

	const onClearAgency = React.useCallback(() => {
		const employee: Nullable<CreateTemporaryEmployeeFM> = selector('newTemporaryEmployee');
		if (employee && Object.keys(employee).length === 1) {
			change('newTemporaryEmployee', null);
		} else {
			change('newTemporaryEmployee.agencyId', null);
		}
	}, [change, selector]);

	const closeCreateTempEmployeeForm = React.useCallback(() => {
		setCreateEmployeeInitialValues(null);
		setIsFormInitialized(false);
		setShowCreateEmployeeForm(false);
	}, []);

	const submit = (form: CreateTemporaryEmployeeAssignmentForm) => {
		onSubmit(form.workOrderId, CreateTemporaryEmployeeAssignmentForm.toRequestModel(form));
	};

	return (
		<>
			<CustomModal.Body>
				{showCreateEmployeeForm
					? (
						<CreateTemporaryEmployeeForm
							agencies={agencies}
							initialValues={createEmployeeInitialValues}
							isNameAvailable={isTemporaryEmployeeNameAvailable}
							lazyLoadAgencies={lazyLoadAgencies}
							onClearAgency={onClearAgency}
							onClose={closeCreateTempEmployeeForm}
							propName="newTemporaryEmployee"
						/>
					) : (
						<></>
					)
				}
				<Row className="row--padded-top">
					<Col sm={24}>
						<Field
							allowNew={isAllowedToCreate}
							component={Select}
							filterOption={filterBy}
							fixed={true}
							formatCreateLabel={formatCreateLabel}
							formatOptionLabel={renderMenuItem}
							formatSelectedOption={renderSelectedItem}
							getNewOptionData={getNewOptionData}
							getOptionValue={getOptionValue}
							isDisabled={showCreateEmployeeForm}
							isValidNewOption={isValidNewOption}
							name="temporaryEmployee"
							onClear={onClearTemporaryEmployee}
							onCreateNew={showCreateTempEmployeeForm}
							onLazyLoad={lazyLoadEmployees}
							onValueChange={onTemporaryLaborChange}
							options={employees}
							placeholder={getPlaceHolder(isAllowedToCreate)}
						/>
					</Col>
				</Row>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={onClose}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					label="Add Temporary Laborer"
					onClick={handleSubmit(submit)}
					reduxFormSubmitting={submitting}
					submitKey={TEMPORARY_EMPLOYEE_ASSIGNMENT}
				/>
			</CustomModal.Footer>
		</>
	);
};

const formSelector = formValueSelector(TEMPORARY_EMPLOYEE_ASSIGNMENT);

function mapDispatchToProps() {
	return {
		findEmployees: TemporaryEmployeeActions.findAllForWorkOrdersForDueDate,
		findAllAgencies: AgencyActions.findAll,
		isTemporaryEmployeeNameAvailable: TemporaryEmployeeActions.isNameAvailable,
	};
}

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isAllowedToCreate = isAllowed(PagePermissions.COMPANY.SETTINGS.TEMP_LABOR, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	return {
		isAllowedToCreate,
		selector: (field: string) => formSelector(state, field),
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<CreateTemporaryEmployeeAssignmentForm, FormOwnProps>({ form: TEMPORARY_EMPLOYEE_ASSIGNMENT, validate }),
	React.memo
);

export default enhance(CreateTemporaryLaborForm);
