import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

import EmptyCell from './EmptyCell';

interface OwnProps {
	comments: string[];
}

type Props = OwnProps;

const commentMap = (comment: string, index: number) => (<div key={index}>{comment}</div>);

const CommentCell = ({ comments }: Props) => {
	if (!comments?.length) {
		return <EmptyCell />;
	}

	const tooltip = <div>{comments.map(commentMap)}</div>;
	return (
		<div>
			<Tooltip message={tooltip}>
				<span className="icon-chat chat-icon" />
			</Tooltip>
		</div>
	);
};

export default React.memo(CommentCell);
