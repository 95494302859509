import * as Err from 'restify-errors';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import InvoiceStatus from 'acceligent-shared/enums/invoiceStatus';
import FileType from 'acceligent-shared/enums/fileType';
import ResourceStatus from 'acceligent-shared/enums/resourceStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';
import * as UserUtils from 'acceligent-shared/utils/user';
import * as BlobStorageUtil from 'acceligent-shared/utils/blobStorage';

import Invoice from 'acceligent-shared/models/invoice';
import Installment from 'acceligent-shared/models/installment';

import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';
import Attachment from 'acceligent-shared/models/attachment';
import Account from 'acceligent-shared/models/account';

import * as BlobStorageUtilLocal from 'ab-utils/blobStorage.util'; // TODO: move everything to shared
import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

export class W_Accounting_FindInvoicesTable_VM extends TableViewModel<W_Accounting_FindInvoicesTable_VM_Row>{
	constructor(invoices: Invoice[], pages: number, totalCount: number) {
		super(
			W_Accounting_FindInvoicesTable_VM_Row.bulkConstructor(invoices),
			pages,
			totalCount
		);
	}
}

class W_Accounting_FindInvoicesTable_VM_Row {
	id: number;
	workRequestId: number;
	jobCode: string;
	invoiceCode: string;
	status: InvoiceStatusDisplay;
	totalAmount: number;
	paidAmount: number;
	outstandingDebt: number;
	retainedAmount: number;
	percentagePaid: number;
	dateCreated: string;
	invoicingDate: Nullable<string>;
	dueDate: string;
	note: Nullable<string>;
	billingEmails: string[];
	sendReminderOnInvoice: boolean;
	excludeFromAutomaticReminders: boolean;
	installments: W_Accounting_FindInvoicesTable_VM_Installment[];
	lastInstallmentDate: Nullable<Date>;
	attachments: W_Accounting_FindInvoicesTable_VM_Attachment[];
	uploadedAttachmentIds: number[];

	constructor(invoice: Invoice) {
		const _totalAmount = parseFloat(invoice.totalAmount);
		const _paidAmount = _resolvePaidAmount(invoice.installments);

		this.id = invoice.id;
		this.invoiceCode = invoice.invoiceCode;

		if (invoice.workRequest?.jobCode) {
			this.jobCode = invoice.workRequest?.jobCode;
		} else {
			throw new Err.NotFoundError('Job code and jobCustomName not found');
		}

		this.workRequestId = invoice.workRequestId;
		this.status = _resolveStatusForDisplay(invoice.status, _totalAmount, _paidAmount);
		this.totalAmount = _totalAmount;
		this.paidAmount = _paidAmount;
		this.outstandingDebt = _resolveOutstandingDebt(_totalAmount, _paidAmount);
		this.retainedAmount = invoice.retainedAmount ? parseFloat(invoice.retainedAmount) : 0;
		this.percentagePaid = +(Math.min(_paidAmount / _totalAmount * 100, 100)).toFixed(2);
		this.dateCreated = invoice.dateCreated;
		this.invoicingDate = invoice.invoicingDate;
		this.dueDate = invoice.dueDate;
		this.note = invoice.note;
		this.sendReminderOnInvoice = invoice.sendReminderOnInvoice;
		this.excludeFromAutomaticReminders = invoice.excludeFromAutomaticReminders;
		this.billingEmails = invoice.invoiceBillingEmailLookups?.map((_lookup) => _lookup.email) ?? [];
		this.installments = W_Accounting_FindInvoicesTable_VM_Installment.bulkConstructor(invoice.installments);
		this.lastInstallmentDate = _resolveLastInstallmentDate(invoice.installments);
		this.attachments = invoice.invoiceAttachmentLookups ?
			W_Accounting_FindInvoicesTable_VM_Attachment.bulkConstructor(invoice.invoiceAttachmentLookups.map((_lookup) => _lookup.attachment))
			: [];
		this.uploadedAttachmentIds = invoice.invoiceAttachmentLookups?.map((_lookup) => _lookup.attachmentId) ?? [];
	}

	static bulkConstructor = (invoices: Invoice[]) => invoices.map((_in) => new W_Accounting_FindInvoicesTable_VM_Row(_in));
}

class W_Accounting_FindInvoicesTable_VM_Installment {
	id: number;
	invoiceId: number;
	amount: string;
	/** YYYY-MM-DD */
	datePaid: string;
	note: Nullable<string>;
	createdAt: Date;

	constructor(installment: Installment) {
		this.id = installment.id;
		this.invoiceId = installment.invoiceId;
		this.amount = installment.amount;
		this.datePaid = installment.datePaid;
		this.note = installment.note;
		this.createdAt = installment.createdAt;
	}

	static bulkConstructor = (installments: Installment[]) => installments.map((_in) => new W_Accounting_FindInvoicesTable_VM_Installment(_in));
}

class W_Accounting_FindInvoicesTable_VM_UserInfo {
	accountId?: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;

	constructor(account: Account) {
		this.accountId = account.id ?? undefined;
		this.userId = account.user.id;
		this.firstName = account.user.firstName;
		this.lastName = account.user.lastName;
		this.fullName = UserUtils.getUserName(account.user);
	}
}

class W_Accounting_FindInvoicesTable_VM_Attachment {
	id: number;
	size: number;
	name: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	lastModifiedAt: Date;
	uploadedBy: Nullable<W_Accounting_FindInvoicesTable_VM_UserInfo>;

	constructor(attachment: Attachment) {
		this.id = attachment.id;
		this.size = attachment.size;
		this.name = attachment.name;
		this.type = attachment.type;
		this.status = attachment.status;
		this.storageName = attachment.storageName;
		this.storageContainer = attachment.storageContainer;
		this.lastModifiedAt = attachment.createdAt;

		const directories = BlobStorageUtil.parseDirectoryPath(this.storageContainer);

		this.originalSrc = BlobStorageUtilLocal.generatePresignedGetUrl(directories, this.storageName);

		const attUser = attachment.uploadedBy?.user;
		const uploadedObj = attachment.uploadedBy ? new W_Accounting_FindInvoicesTable_VM_UserInfo(attachment.uploadedBy) : null;

		this.uploadedBy = attUser ? uploadedObj : null;
	}

	private static _constructorMap = (attachment: Attachment) => new W_Accounting_FindInvoicesTable_VM_Attachment(attachment);

	static bulkConstructor = (attachments: Attachment[]) => attachments?.map(W_Accounting_FindInvoicesTable_VM_Attachment._constructorMap) ?? [];
}

const _resolvePaidAmount = (installments: Installment[]) => {
	if (!installments) {
		return 0;
	}
	return installments.reduce((_sum, _inst) => {
		return _sum + parseFloat(_inst.amount);
	}, 0);
};

const _resolveOutstandingDebt = (totalAmount: number, paidAmount: number) => {
	if (paidAmount >= totalAmount) {
		return 0;
	}
	return totalAmount - paidAmount;
};

const _resolveStatusForDisplay = (invoiceStatus: InvoiceStatus, totalAmount: number, paidByInstallments: number) => {
	if (paidByInstallments === 0) {
		if (invoiceStatus === InvoiceStatus.DRAFT) {
			return InvoiceStatusDisplay.DRAFT;
		}
		return InvoiceStatusDisplay.INVOICED;
	}

	if (paidByInstallments < totalAmount) {
		return InvoiceStatusDisplay.PARTIALLY_PAID;
	} else if (paidByInstallments === totalAmount) {
		return InvoiceStatusDisplay.PAID;
	} else {
		return InvoiceStatusDisplay.OVERPAID;
	}
};

const _resolveLastInstallmentDate = (installments: Installment[]) => {
	if (!installments) {
		return null;
	}
	const installmentDates = installments.map((_i) => TimeUtils.toUtcDate(_i.datePaid, TimeFormat.DB_DATE_ONLY));
	return new Date(Math.max.apply(null, installmentDates));
};
