import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import ImageUpload from 'af-components/ImageUpload';
import ImageTag from 'af-components/Image';
import Label from 'af-components/LockedValue/Label';
import { OwnProps as TooltipProps } from 'af-components/Tooltip';

import { bemElement } from 'ab-utils/bem.util';

import EditImageModal from '../../../EditImageModal';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CameraLogo = require('acceligent-assets/assets/ic_camera.svg');

interface OwnProps extends WrappedFieldProps {
	disabled: boolean;
	inputGroupClassName?: string;
	isEditable: boolean;
	label: string;
	onSave: (imageUrl: File) => void;
	onClear: () => void;
	tooltipMessage?: TooltipProps['message'];
	onFocus?: () => void;
}

interface State {
	imageUrl: Nullable<string>;
	showImageEdit: boolean;
	showImageUpload: boolean;
}

type Props = OwnProps;

class ImageField extends React.PureComponent<Props, State> {

	state: State = {
		imageUrl: this.props.input?.value,
		showImageEdit: false,
		showImageUpload: false,
	};

	componentDidUpdate(prevProps: Props) {
		const { input: { value } } = this.props;
		const { input: { value: prevValue } } = prevProps;
		if (this.state.imageUrl === undefined || value !== prevValue) {
			this.setState(() => ({ imageUrl: value }));
		}
	}

	onImageUploadSave = (image: File, imageUrl: string) => {
		const { onSave } = this.props;
		this.setState(
			() => ({ imageUrl }),
			() => onSave(image));
	};

	openUploadModal = () => {
		const { onFocus } = this.props;
		onFocus?.();
		this.setState(() => ({ showImageUpload: true }));
	};

	closeUploadModal = () => this.setState(() => ({ showImageUpload: false }));

	showImagePreview = () => {
		const { onFocus } = this.props;
		onFocus?.();
		this.setState(() => ({ showImageEdit: true }));
	};

	hideImagePreview = () => this.setState(() => ({ showImageEdit: false }));

	deleteImage = () => {
		const { onClear } = this.props;
		this.setState(() => ({ imageUrl: null }), onClear);
	};

	renderImage = () => {
		const { disabled } = this.props;
		const { imageUrl } = this.state;

		if (!imageUrl) {
			const className = bemElement('image-field', 'empty', { disabled: disabled });

			return (
				<div className={className} onClick={!disabled ? this.openUploadModal : undefined}>
					<span className="icon-camera" />
				</div>
			);
		}

		return (
			<ImageTag
				className="image-field__image"
				fallbackSrc={CameraLogo}
				minSize={BlobStorageImageSizeContainer.SIZE_50X50}
				onClick={this.showImagePreview}
				src={imageUrl}
				tryOriginal={true}
			/>
		);
	};

	render() {
		const { label, disabled, tooltipMessage, isEditable } = this.props;
		const { showImageEdit, imageUrl, showImageUpload } = this.state;

		return (
			<div>
				<Label
					label={label}
					tooltipMessage={tooltipMessage}
				/>
				<div className="image-field__container">
					<ImageUpload
						closeModal={this.closeUploadModal}
						disableActions={true}
						freeAspectRatio={true}
						onSave={this.onImageUploadSave}
						showModal={showImageUpload}
					/>
					{this.renderImage()}
					<EditImageModal
						disabled={disabled}
						fieldName={label}
						imageUrl={imageUrl}
						isEditable={isEditable}
						onDelete={this.deleteImage}
						onHide={this.hideImagePreview}
						show={showImageEdit}
					/>
				</div>
			</div>
		);
	}
}

export default ImageField;
