import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import { WorkOrderCancelForm } from 'ab-requestModels/workOrder.requestModel';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Textarea from 'af-fields/Textarea';

import { ORDER_CANCEL_FORM } from 'af-constants/reduxForms';

interface OwnProps {
	showCancelModal: boolean;
	workOrderCode: string;
	closeCancelModal: () => void;
	onSubmit: (form: WorkOrderCancelForm) => Promise<void>;
}

type FormOwnProps = OwnProps;
type Props = OwnProps & InjectedFormProps<WorkOrderCancelForm, FormOwnProps> & ConnectedProps<typeof connector>;

const CancelModal: React.FC<Props> = (props) => {
	const { cancellationReason = '', closeCancelModal, onSubmit, showCancelModal, workOrderCode, handleSubmit, submitting, destroy } = props;

	React.useEffect(() => {
		if (!showCancelModal) {
			destroy();
		}
	}, [showCancelModal, destroy]);

	const submit = (form: WorkOrderCancelForm) => {
		form.date = TimeUtils.formatDate(new Date());	// FIXME: this should probably be done on the server side
		onSubmit(form);
	};

	return (
		<CustomModal
			closeModal={closeCancelModal}
			modalStyle="info"
			showModal={showCancelModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeCancelModal}
				title={`Work Order - ${workOrderCode}`}
			/>
			<CustomModal.Body>
				<Field
					component={Textarea}
					disabled={submitting}
					label="Cancellation Reason *"
					name="cancellationReason"
					placeholder="Please enter cancellation reason"
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button onClick={closeCancelModal} variant="info">Back</Button>
				<SubmitButton
					disabled={!cancellationReason}
					label="Cancel Work Order"
					onClick={handleSubmit(submit)}
					reduxFormSubmitting={submitting}
					submitKey={ORDER_CANCEL_FORM}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

const selector = formValueSelector(ORDER_CANCEL_FORM);

function mapStateToProps(state: RootState) {
	return {
		cancellationReason: selector(state, 'cancellationReason'),
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector,
	reduxForm<WorkOrderCancelForm, FormOwnProps>({ form: ORDER_CANCEL_FORM, enableReinitialize: true, destroyOnUnmount: true })
);

export default enhance(CancelModal);
