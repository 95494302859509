import * as React from 'react';
import { Popover, OverlayTrigger, Overlay, OverlayProps } from 'react-bootstrap';
import { nanoid } from 'nanoid';

import { UNIQUE_ID_SIZE } from 'ab-constants/value';

export interface OwnProps {
	message: string | JSX.Element | JSX.Element[] | null;
	className?: string;
	placement?: OverlayProps['placement'];
	onExited?: () => void;
	/** disable only when message has connected component */
	useOverlayTrigger?: boolean;
	/** add more when needed */
	customCursor?: 'not-allowed';
}

type Props = React.PropsWithChildren<OwnProps>;

interface State {
	showPopover: boolean;
}

class Tooltip extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		placement: 'top',
		useOverlayTrigger: true,
	};

	state: State = {
		showPopover: false,
	};

	_ref = React.createRef<HTMLDivElement>();
	_target: OverlayProps['target'] | undefined = undefined;

	showOverlay = (e: React.MouseEvent) => {
		this._target = e.target as OverlayProps['target'];
		this.setState(() => ({ showPopover: true }));
	};

	hideOverlay = () => {
		this.setState(() => ({ showPopover: false }));
	};

	renderOverlayTrigger() {
		const {
			children,
			className,
			message,
			placement,
			onExited,
			customCursor,
		} = this.props;

		let cn = 'tooltip';
		cn = className ? `${cn} ${className}` : cn;

		const tooltip = (
			<Popover className={cn} id={`popover-${nanoid(UNIQUE_ID_SIZE)}`}>
				{message}
			</Popover>
		);

		let triggerWrapperClassName = 'tooltip__trigger-wrapper';
		triggerWrapperClassName = customCursor ? `${triggerWrapperClassName} ${triggerWrapperClassName}--${customCursor}` : triggerWrapperClassName;

		return (
			<OverlayTrigger
				onExited={onExited}
				overlay={tooltip}
				placement={placement}
			>
				<div className={triggerWrapperClassName}>
					{children}
				</div>
			</OverlayTrigger>
		);
	}

	render() {
		const {
			children,
			className,
			message,
			placement,
			useOverlayTrigger,
		} = this.props;

		const { showPopover } = this.state;

		if (useOverlayTrigger) {
			return this.renderOverlayTrigger();
		}

		return (
			<div
				className="tooltip-wrapper"
				onMouseEnter={this.showOverlay}
				onMouseLeave={this.hideOverlay}
				ref={this._ref}
			>
				{children}
				<Overlay
					container={this._ref.current}
					placement={placement}
					show={showPopover}
					target={this._target ?? null}
				>
					<Popover
						className={`tooltip ${className}`}
						id={`popover-${nanoid(UNIQUE_ID_SIZE)}`}
					>
						{message}
					</Popover>
				</Overlay>
			</div>
		);
	}
}

export default Tooltip;
