import * as React from 'react';

import { isEqualShallow } from 'acceligent-shared/utils/extensions';

import { bemElement } from 'ab-utils/bem.util';

import Buttons from './Buttons';
import FilterInput from './FilterInput';
import TableTypeEnum from '../tableType.enum';
import { TabProps, ButtonData } from '../types';

interface OwnProps {
	additionalFilter: TabProps<unknown>['additionalFilter'];
	buttons: ButtonData[] | undefined;
	filterText: string;
	hasSearchInput: boolean;
	onFilterTextChange: (filterText: string) => void;
	searchLabel: string;
	tableType: TableTypeEnum;
	exportAsZip?: boolean;
}

type Props = OwnProps;

const PROPS_TO_ALWAYS_CHECK: (keyof Props)[] = ['buttons', 'tableType', 'hasSearchInput'];
const PROPS_TO_CHECK_ON_SEARCH: (keyof Props)[] = ['filterText', 'onFilterTextChange', 'searchLabel'];

const ActionHeader: React.FC<Props> = (props) => {

	const {
		additionalFilter,
		filterText,
		hasSearchInput,
		onFilterTextChange,
		searchLabel,
		tableType,
		buttons = [],
		exportAsZip = false,
	} = props;

	const isDefaultView = tableType === TableTypeEnum.DEFAULT;
	const tableContainerActionsClassName = bemElement('table-container', 'actions', [hasSearchInput && !buttons?.length ? 'with-search-only' : '']);

	return (
		<div className={bemElement('table-container', 'header-regular')}>
			<div className={tableContainerActionsClassName}>
				{hasSearchInput &&
					<FilterInput
						filterText={filterText}
						onFilterTextChange={onFilterTextChange}
						searchLabel={searchLabel}
						tableType={tableType}
					/>
				}
				{additionalFilter && isDefaultView && additionalFilter()}
				<Buttons buttons={buttons} exportAsZip={exportAsZip} tableType={tableType} />
			</div>
			{additionalFilter && !isDefaultView &&
				<div className={bemElement('table-container', 'actions')}>
					{additionalFilter()}
				</div>
			}
		</div>
	);
};

export default React.memo(ActionHeader, (props, nextProps) => {
	if (!!nextProps.additionalFilter || !!props.additionalFilter) {
		// always re-render if `additionalFilter` exists (existed)
		return false;
	}

	let propsAreEqual = isEqualShallow(nextProps, props, PROPS_TO_ALWAYS_CHECK, true);
	if (propsAreEqual && nextProps.hasSearchInput) {
		propsAreEqual = isEqualShallow(nextProps, props, PROPS_TO_CHECK_ON_SEARCH, true);
	}
	return propsAreEqual;
});
