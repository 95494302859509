import * as React from 'react';
import { Link } from 'react-router-dom';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const NotionLink: React.FC<Props> = (props) => {
	const { href, ...restProps } = props;

	if (!href) {
		return null;
	}

	return (
		<Link
			to={href}
			{...restProps}
		/>
	);
};

export default NotionLink;
