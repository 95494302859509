import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import * as MechanicViewUtil from 'af-utils/mechanicView.util';

import CellWithTooltip from './CellWithTooltip';
import Body from './Body';

class AvailableTable extends React.PureComponent {
	render() {

		return (
			<div className="draggable-table">
				<div className="draggable-table__row draggable-table__row--header">
					<div className="draggable-table__cell draggable-table__cell--blank-space" />
					<CellWithTooltip content="Equipment ID" />
					<CellWithTooltip content="Job ID" />
					<CellWithTooltip content="Location" />
					<CellWithTooltip content="Type" />
					{/* removed for now*/}
					{/* <CellWithTooltip content="Current Location" />
					<CellWithTooltip content="Operator" />
					<CellWithTooltip content="Next Service Due" /> */}
					<div className="draggable-table__cell draggable-table__cell--blank-space" />
				</div>
				<Droppable droppableId={MechanicViewUtil.AVAILABLE_CONTAINER_ID}>
					{(_provided) => <Body provided={_provided} />}
				</Droppable>
			</div>
		);
	}
}

export default AvailableTable;
