import WorkOrderModalViewModel, { ContactAddressModel, WorkOrderRevisionContactModel, WorkOrderResourceLookupModel, AddressModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';
import WorkOrderResourceLookupViewModel from 'ab-viewModels/workOrderResourceLookup.viewModel';

// Site Contact Section

export const generateSiteContactAddressStringIdentifier = (address: ContactAddressModel) => {
	let stringIdentifier = '';

	if (address.street) stringIdentifier += `${address.street}, `;

	if (address.suite) stringIdentifier += `${address.suite}, `;

	if (address.city) stringIdentifier += `${address.city}, `;

	if (address.stateAbbreviation) stringIdentifier += `${address.stateAbbreviation}, `;

	if (address.zip) stringIdentifier += `${address.zip}, `;

	if (address.country) stringIdentifier += `${address.country}`;

	return stringIdentifier;
};

export const getRemovedSiteContactEmailDictionary = (
	siteContactToShow: Nullable<WorkOrderRevisionContactModel>,
	currentSiteContact: Nullable<WorkOrderRevisionContactModel>
) => {

	if (siteContactToShow?.id !== currentSiteContact?.id) {
		return {};
	}

	const currentSiteContactEmailDictionary = {};

	currentSiteContact?.emails?.forEach((_email) => {
		currentSiteContactEmailDictionary[_email.value] = true;
	});

	const removedSiteContactEmailDictionary = {};

	siteContactToShow?.emails?.forEach((_email) => {
		if (!currentSiteContactEmailDictionary[_email.value]) {
			removedSiteContactEmailDictionary[_email.value] = true;
		}
	});

	return removedSiteContactEmailDictionary;
};

export const getRemovedSiteContactPhoneDictionary = (
	siteContactToShow: Nullable<WorkOrderRevisionContactModel>,
	currentSiteContact: Nullable<WorkOrderRevisionContactModel>
) => {
	if (siteContactToShow?.id !== currentSiteContact?.id) {
		return {};
	}

	const currentSiteContactPhoneNumberDictionary = {};

	currentSiteContact?.phones?.forEach((_phone) => {
		currentSiteContactPhoneNumberDictionary[_phone.value] = true;
	});

	const removedSiteContactPhoneNumberDictionary = {};

	siteContactToShow?.phones?.forEach((_phone) => {
		if (!currentSiteContactPhoneNumberDictionary[_phone.value]) {
			removedSiteContactPhoneNumberDictionary[_phone.value] = true;
		}
	});

	return removedSiteContactPhoneNumberDictionary;
};

export const getRemovedSiteContactAddressDictionary = (
	siteContactToShow: Nullable<WorkOrderRevisionContactModel>,
	currentSiteContact: Nullable<WorkOrderRevisionContactModel>
) => {
	if (siteContactToShow?.id !== currentSiteContact?.id) {
		return {};
	}

	const currentSiteContactAddressDictionary = {};

	currentSiteContact?.addresses?.forEach((_address) => {
		currentSiteContactAddressDictionary[generateSiteContactAddressStringIdentifier(_address)] = true;
	});

	const removedSiteContactAddressDictionary = {};

	siteContactToShow?.addresses?.forEach((_address) => {
		const _addressStringIdentifier = generateSiteContactAddressStringIdentifier(_address);

		if (!currentSiteContactAddressDictionary[_addressStringIdentifier]) {
			removedSiteContactAddressDictionary[_addressStringIdentifier] = true;
		}
	});

	return removedSiteContactAddressDictionary;
};

// Resource Lookups Section

const generateEquipmentPlaceholderStringIdentifier = (equipmentPlaceholder: WorkOrderResourceLookupModel) => {
	const { equipmentCostId, name, color } = equipmentPlaceholder;

	return `equipment-placeholder-${equipmentCostId}${name}${color}`;
};

const generateEmployeePlaceholderStringIdentifier = (employeePlaceholder: WorkOrderResourceLookupModel) => {
	const { name, wageRateId } = employeePlaceholder;

	return `employee-placeholder-${wageRateId}${name}`;
};

export const getRemovedWorkOrderResourceDictionary = (workOrderDataToShow: WorkOrderModalViewModel, currentWorkOrderData: WorkOrderModalViewModel) => {
	const currentEquipmentDictionary = {}, currentEmployeeDictionary = {}, currentPlaceholderDictionary = {};

	currentWorkOrderData?.workOrderResourceLookups?.forEach((_workOrderResourceLookup) => {
		if (_workOrderResourceLookup.equipmentId) {
			currentEquipmentDictionary[_workOrderResourceLookup.equipmentId] = true;
		} else if (_workOrderResourceLookup.employeeId) {
			currentEmployeeDictionary[_workOrderResourceLookup.employeeId] = true;
		} else if (_workOrderResourceLookup.equipmentCostId) {
			currentPlaceholderDictionary[generateEquipmentPlaceholderStringIdentifier(_workOrderResourceLookup)] = true;
		} else if (_workOrderResourceLookup.wageRateId) {
			currentPlaceholderDictionary[generateEmployeePlaceholderStringIdentifier(_workOrderResourceLookup)] = true;
		}
	});

	const removedWorkOrderResourceDictionary = {};

	workOrderDataToShow?.workOrderResourceLookups?.forEach((_workOrderResourceLookup) => {
		if (_workOrderResourceLookup.equipmentId && !currentEquipmentDictionary[_workOrderResourceLookup.equipmentId]) {
			removedWorkOrderResourceDictionary[_workOrderResourceLookup.id] = true;
		} else if (_workOrderResourceLookup.employeeId && !currentEmployeeDictionary[_workOrderResourceLookup.employeeId]) {
			removedWorkOrderResourceDictionary[_workOrderResourceLookup.id] = true;
		} else if (_workOrderResourceLookup.equipmentCostId
			&& !currentPlaceholderDictionary[generateEquipmentPlaceholderStringIdentifier(_workOrderResourceLookup)]) {
			removedWorkOrderResourceDictionary[_workOrderResourceLookup.id] = true;
		} else if (_workOrderResourceLookup.wageRateId
			&& !currentPlaceholderDictionary[generateEmployeePlaceholderStringIdentifier(_workOrderResourceLookup)]) {
			removedWorkOrderResourceDictionary[_workOrderResourceLookup.id] = true;
		}
	});

	return removedWorkOrderResourceDictionary;
};

export const getAddedWorkOrderResources = (workOrderDataToShow: WorkOrderModalViewModel, currentWorkOrderData: WorkOrderModalViewModel) => {
	const equipmentDictionaryOfItemsToShow = {}, employeeDictionaryOfItemsToShow = {}, placeholderDictionaryOfItemsToShow = {};

	workOrderDataToShow?.workOrderResourceLookups?.forEach((_workOrderResourceLookup) => {
		if (_workOrderResourceLookup.equipmentId) {
			equipmentDictionaryOfItemsToShow[_workOrderResourceLookup.equipmentId] = true;
		} else if (_workOrderResourceLookup.employeeId) {
			employeeDictionaryOfItemsToShow[_workOrderResourceLookup.employeeId] = true;
		} else if (_workOrderResourceLookup.equipmentCostId) {
			placeholderDictionaryOfItemsToShow[generateEquipmentPlaceholderStringIdentifier(_workOrderResourceLookup)] = true;
		} else if (_workOrderResourceLookup.wageRateId) {
			placeholderDictionaryOfItemsToShow[generateEmployeePlaceholderStringIdentifier(_workOrderResourceLookup)] = true;
		}
	});

	const addedWorkOrderResources: WorkOrderResourceLookupModel[] = [];

	currentWorkOrderData?.workOrderResourceLookups?.forEach((_workOrderResourceLookup) => {
		if (_workOrderResourceLookup.equipmentId && !equipmentDictionaryOfItemsToShow[_workOrderResourceLookup.equipmentId]) {
			addedWorkOrderResources.push(_workOrderResourceLookup);
		} else if (_workOrderResourceLookup.employeeId && !employeeDictionaryOfItemsToShow[_workOrderResourceLookup.employeeId]) {
			addedWorkOrderResources.push(_workOrderResourceLookup);
		} else if (_workOrderResourceLookup.equipmentCostId
			&& !placeholderDictionaryOfItemsToShow[generateEquipmentPlaceholderStringIdentifier(_workOrderResourceLookup)]) {
			addedWorkOrderResources.push(_workOrderResourceLookup);
		} else if (_workOrderResourceLookup.wageRateId
			&& !placeholderDictionaryOfItemsToShow[generateEmployeePlaceholderStringIdentifier(_workOrderResourceLookup)]) {
			addedWorkOrderResources.push(_workOrderResourceLookup);
		}
	});

	return addedWorkOrderResources;
};

// Work Locations Section

export const generateWorkLocationAddressStringIdentifier = (address: AddressModel) => {
	let stringIdentifier = '';

	if (address.streetAddress) stringIdentifier += `${address.streetAddress}, `;

	if (address.suite) stringIdentifier += `${address.suite}, `;

	if (address.locality) stringIdentifier += `${address.locality}, `;

	if (address.aa1) stringIdentifier += `${address.aa1}, `;

	if (address.postalCode) stringIdentifier += `${address.postalCode}, `;

	if (address.postalOfficeBoxCode) stringIdentifier += `${address.postalOfficeBoxCode}, `;

	if (address.country) stringIdentifier += `${address.country}`;

	return stringIdentifier;
};

export const getRemovedWorkLocationAddressesDictionary = (workOrderDataToShow: WorkOrderModalViewModel, currentWorkOrderData: WorkOrderModalViewModel) => {
	const currentAddressesDictionary = {};

	currentWorkOrderData?.addresses?.forEach((_address) => {
		currentAddressesDictionary[generateWorkLocationAddressStringIdentifier(_address)] = true;
	});

	const removedAddressesDictionary = {};

	workOrderDataToShow?.addresses?.forEach((_address) => {
		const _addressStringIdentifier = generateWorkLocationAddressStringIdentifier(_address);

		if (!currentAddressesDictionary[_addressStringIdentifier]) {
			removedAddressesDictionary[_addressStringIdentifier] = true;
		}
	});

	return removedAddressesDictionary;
};

export const getListOfAddedWorkLocationAddresses = (workOrderDataToShow: WorkOrderModalViewModel, currentWorkOrderData: WorkOrderModalViewModel) => {
	const dictionaryOfAddressesToShow = {};

	workOrderDataToShow?.addresses?.forEach((_address) => {
		dictionaryOfAddressesToShow[generateWorkLocationAddressStringIdentifier(_address)] = true;
	});

	const listOfAddedAddresses: AddressModel[] = [];

	currentWorkOrderData?.addresses?.forEach((_address) => {
		const _addressStringIdentifier = generateWorkLocationAddressStringIdentifier(_address);

		if (!dictionaryOfAddressesToShow[_addressStringIdentifier]) {
			listOfAddedAddresses.push(_address);
		}
	});

	return listOfAddedAddresses;
};

export const getSelectedEmployeeMap = (selectedResourceLookups: WorkOrderResourceLookupViewModel[]): { [id: number]: true; } => (
	selectedResourceLookups.reduce((_acc, { employeeId }) => {
		if (employeeId) {
			_acc[employeeId] = true;
		}
		return _acc;
	}, {} as { [id: number]: true; })
);

export const getSelectedTemporaryEmployeeMap = (selectedResourceLookups: WorkOrderResourceLookupViewModel[]): { [id: number]: true; } => (
	selectedResourceLookups.reduce((_acc, { temporaryEmployeeId }) => {
		if (temporaryEmployeeId) {
			_acc[temporaryEmployeeId] = true;
		}
		return _acc;
	}, {} as { [id: number]: true; })
);
