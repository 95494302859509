import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

const EquipmentTimeWarning = () => {
	return (
		<div className={bemElement('time-sheet-list__equipment-modal', 'equipment-time-warning')}>
			<span className="icon-info" />Time sum does not match total job time
		</div>
	);
};

export default React.memo(EquipmentTimeWarning);
