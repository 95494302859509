import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import { validate } from './validation';
import DeliverableCommentForm from 'af-components/SharedForms/Deliverables/Comment';
import DropdownAccountItem from 'af-components/DropdownAccountItem';
import SubmitButton from 'af-components/SubmitButton';

import * as FORMS from 'af-constants/reduxForms';

import Textarea from 'af-fields/Textarea';
import Dropdown from 'af-fields/Dropdown';

import { AccountViewModel } from 'ab-viewModels/account.viewModel';
import { DeliverableStatusViewModel } from 'ab-viewModels/deliverableStatusTable.viewModel';
import { DeliverableComment } from 'ab-viewModels/deliverableTable.viewModel';

import * as DeliverableStatusActions from 'af-actions/deliverableStatus';
import * as AccountActions from 'af-actions/accounts';

import StatusOption from '../Shared/StatusOption';
import DeliverableFM from './formModel';

const NOTES_MAX_LENGTH = 750;
const NOTES_NUMBER_OF_LINS = 10;

type FormProps = InjectedFormProps<DeliverableFM, FormOwnProps>;

interface OwnProps {
	comments: DeliverableComment[];
	createComment: (comment: string) => void;
	onBack: () => void;
	onSubmit: (form: DeliverableFM) => void;
	initialValues: FormProps['initialValues'];
}

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & FormProps;

interface State {
	deliverableStatusesForCompany: DeliverableStatusViewModel[];
	deliverableManagersForCompany: AccountViewModel[];
}

class DeliverableForm extends React.PureComponent<Props, State> {

	static readonly DELIVERABLE_MANAGERS_FILTER_BY: (keyof AccountViewModel)[] = ['firstName', 'lastName', 'uniqueId'];

	state: State = {
		deliverableStatusesForCompany: [],
		deliverableManagersForCompany: [],
	};

	static renderStatusOption = (option: DeliverableStatusViewModel) => <StatusOption status={option} />;

	static renderDeliverableManagerItem = (option: AccountViewModel) => option ? <DropdownAccountItem option={option} /> : <div />;

	lazyLoadDeliverableManagers = async (isLazyLoaded: boolean) => {
		const { findAllAssignableForDeliverable } = this.props;
		if (!isLazyLoaded) {
			const accounts = await findAllAssignableForDeliverable();
			this.setState(() => ({ deliverableManagersForCompany: accounts }));
		}
	};

	lazyLoadStatuses = async (isLazyLoaded: boolean) => {
		const { findAllStatuses } = this.props;
		if (!isLazyLoaded) {
			const statuses = await findAllStatuses();
			this.setState(() => ({ deliverableStatusesForCompany: statuses }));
		}
	};

	render() {
		const {
			handleSubmit,
			invalid,
			submitting,
			onBack,
			onSubmit,
			comments,
			createComment,
		} = this.props;
		const { deliverableManagersForCompany, deliverableStatusesForCompany } = this.state;

		return (
			<Form className="form-box">
				<Row className="row--padded-top">
					<Col sm={8}>
						<Field
							component={Dropdown}
							id="statusId"
							label="Status *"
							name="statusId"
							onLazyLoad={this.lazyLoadStatuses}
							options={deliverableStatusesForCompany}
							propName="status"
							renderMenuItem={DeliverableForm.renderStatusOption}
							valueKey="id"
						/>
					</Col>
					<Col sm={8}>
						<Field
							component={Dropdown}
							filterable={true}
							filterBy={DeliverableForm.DELIVERABLE_MANAGERS_FILTER_BY}
							id="deliverableAssigneeId"
							label="Deliverable QA/QC Assignee *"
							name="deliverableAssigneeId"
							onLazyLoad={this.lazyLoadDeliverableManagers}
							options={deliverableManagersForCompany}
							propName="deliverableAssignee"
							renderMenuItem={DeliverableForm.renderDeliverableManagerItem}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={16}>
						<Field
							component={Textarea}
							label="Notes"
							maxCharacters={NOTES_MAX_LENGTH}
							name="notes"
							placeholder="Enter Notes"
							rows={NOTES_NUMBER_OF_LINS}
							showMaxCharactersLabel={true}
						/>
					</Col>
				</Row>
				<DeliverableCommentForm comments={comments} onSubmit={createComment} />
				<Row className="row--submit">
					<a
						className="btn btn-info"
						onClick={onBack}
					>
						Back
					</a>
					<SubmitButton
						disabled={invalid}
						onClick={handleSubmit(onSubmit)}
						reduxFormSubmitting={submitting}
						variant="primary"
						variantDisabled="info"
					/>
				</Row>
			</Form>
		);
	}
}

function mapDispatchToProps() {
	return {
		findAllAssignableForDeliverable: AccountActions.findAllAssignableForDeliverable,
		findAllStatuses: DeliverableStatusActions.findAllJobStatusesForCompany,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<DeliverableFM, FormOwnProps>({ form: FORMS.DELIVERABLE, validate, enableReinitialize: true })
);
export default enhance(DeliverableForm);
