import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';

import CLIENT from 'af-constants/routes/client';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import SideNav, { LinkProps } from 'af-components/SideNav/SideNav';

type Props = CustomRouteComponentProps;

export default class PlatformSideNav extends React.PureComponent<Props> {

	render(): JSX.Element {
		const { location: { state: { orgAlias }, pathname } } = this.props;

		const links: LinkProps[] = [
			{
				visibleFor: PagePermissions.HOME,
				url: CLIENT.PLATFORM.ALL_ORGANIZATIONS(orgAlias),
				label: 'Organizations',
				icon: 'icon-gavel',
				isActive: pathname.startsWith(CLIENT.PLATFORM.ALL_ORGANIZATIONS(orgAlias)),
			},
			{
				visibleFor: PagePermissions.HOME,
				url: CLIENT.PLATFORM.SYSTEM_NOTIFICATIONS(orgAlias),
				label: 'System Notifications',
				icon: 'icon-notifications',
				isActive: pathname.startsWith(CLIENT.PLATFORM.SYSTEM_NOTIFICATIONS(orgAlias)),
			},
		];

		return (
			<SideNav
				links={links}
			/>
		);
	}
}
