import * as React from 'react';

import { getAddressPostalLocation } from 'ab-utils/formatting.util';

interface OwnProps {
	address: {
		street: string;
		city: Nullable<string>;
		stateAbbreviation: Nullable<string>;
		state: Nullable<string>;
		zip: Nullable<string>;
	};
}

type Props = OwnProps;

const AddressCell: React.FC<Props> = ({ address }) => (
	<div>
		<div className="cell-main-info">{getAddressPostalLocation(address)}</div>
		<div className="cell-additional-info">{address.street ?? '-'}</div>
	</div>
);

export default AddressCell;
