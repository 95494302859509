import { SCREEN_BREAKPOINT_S, SCREEN_BREAKPOINT_REPORT_BLOCK_FORM } from 'af-constants/values';

enum DisplayTypeEnum {
	DEFAULT = 'DEFAULT',
	SMALL = 'SMALL',
	EXTRA_SMALL = 'EXTRA_SMALL',
}

export const getDisplayType = (width: number): DisplayTypeEnum => {
	if (width < SCREEN_BREAKPOINT_S) {
		return DisplayTypeEnum.EXTRA_SMALL;
	} else if (width < SCREEN_BREAKPOINT_REPORT_BLOCK_FORM) {
		return DisplayTypeEnum.SMALL;
	} else {
		return DisplayTypeEnum.DEFAULT;
	}
};

export default DisplayTypeEnum;
