import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DraggableProvided, DraggableStateSnapshot, Draggable } from 'react-beautiful-dnd';

import { ColorPalette } from 'acceligent-shared/enums/color';
import Priority from 'acceligent-shared/enums/priority';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { EquipmentDownRequestModel } from 'ab-requestModels/equipmentDown.requestModel';

import * as ColorUtils from 'ab-utils/color.util';

import { MechanicViewUnavailableEquipmentDataVM } from 'ab-viewModels/mechanicView/mechanicView.viewModel';

import PriorityLabel from 'af-components/PriorityLabel';

import { ONE_SECOND } from 'af-constants/values';

import { scrollIntoView } from 'af-utils/browser.util';
import * as MechanicViewUtil from 'af-utils/mechanicView.util';
import Timeout from 'af-utils/timeout.util';
import { generateEquipmentSearchItemId } from 'af-utils/scheduleBoard.util';

const NOTES_COLLAPSE_TIME = 20 * ONE_SECOND;

interface OwnProps {
	equipment: MechanicViewUnavailableEquipmentDataVM;
	index: number;
	isActive: boolean;
	isMatch: boolean;
	returnDateTitle: string;
	onEquipmentDownEdit: (equipment: EquipmentDownRequestModel) => void;
}

interface State {
	showNotes: boolean;
}

type Props = OwnProps;

class EquipmentCard extends React.PureComponent<Props, State> {
	_ref: Nullable<HTMLDivElement> = null;

	state: State = {
		showNotes: true,
	};

	private collapseCardTimeout: Timeout;

	constructor(props: Props) {
		super(props);
		this.collapseCardTimeout = new Timeout(this.collapseCard, NOTES_COLLAPSE_TIME);
		this.collapseCardTimeout.cancel();
	}

	componentDidUpdate(prevProps: Props) {
		if (this._ref && !prevProps.isActive && this.props.isActive) {
			scrollIntoView(this._ref);
		}
	}

	componentWillUnmount() {
		this.collapseCardTimeout.cancel();
	}

	setRef = (element: HTMLDivElement) => {
		this._ref = element;
	};

	getPriorityClassName = (priority: Priority): string => {
		switch (priority) {
			case Priority.HIGH:
				return ColorUtils.getColorTextClass(ColorPalette.RED);
			case Priority.MEDIUM:
				return ColorUtils.getColorTextClass(ColorPalette.ORANGE);
			case Priority.LOW:
			default:
				return ColorUtils.getColorTextClass('', true);
		}
	};

	collapseCard = () => {
		if (!this.state.showNotes) {
			return;
		}

		this.setState(
			() => ({ showNotes: false }),
			() => { this.collapseCardTimeout.cancel(); }
		);
	};

	expandCard = () => {
		if (this.state.showNotes) {
			return;
		}

		this.setState(
			() => ({ showNotes: true }),
			() => { this.collapseCardTimeout.reset(); }
		);
	};

	editEquipment = () => {
		const { equipment, onEquipmentDownEdit } = this.props;

		onEquipmentDownEdit({
			downNotes: equipment.failureDetails,
			returnDate: equipment.returnDate,
			currentDate: TimeUtils.toDatabaseDateOnly(new Date())!,
			id: equipment.id,
			priority: equipment.priority,
			statusId: equipment.statusId,
			unavailabilityReason: equipment.unavailabilityReason,
		});
	};

	getDraggableStyle = (provided: DraggableProvided, snapshot: DraggableStateSnapshot): React.CSSProperties | undefined => {
		if (snapshot.isDragging) {
			return provided.draggableProps.style;
		}
	};

	getClassName = () => {
		const { isMatch, isActive } = this.props;

		if (!isMatch) {
			return 'unavailable-equipment-card';
		}
		return `unavailable-equipment-card unavailable-equipment-card${isMatch ? '--highlighted' : ''}${isActive ? '-active' : ''}`;
	};

	renderDropdown = () => {
		const { equipment: { code, categoryColor } } = this.props;

		return (
			<Dropdown
				className="actions"
				id={`equipment-card-dropdown-${code}`}
			>
				<Dropdown.Toggle
					className={`btn--icon ${ColorUtils.getColorBackgroundClass(categoryColor)}`}
				>
					<span className="icon-actions icon" />
				</Dropdown.Toggle>
				<Dropdown.Menu >
					<Dropdown.Item
						onClick={this.editEquipment}
						open={false}
					>
						Edit
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	renderInfoRow = (label: string, value: string | JSX.Element, className: string = '') => {
		return (
			<div className="unavailable-equipment-card__entry">
				<div className="unavailable-equipment-card__entry__label">{label}: </div>
				<div className={`unavailable-equipment-card__entry__value ${className}`}>{value}</div>
			</div>
		);
	};

	renderCardDetails = () => {
		const { equipment, returnDateTitle } = this.props;
		const { showNotes } = this.state;
		const {
			code,
			categoryColor,
			returnDate,
			failureDetails,
			lastUpdatedBy: {
				name,
				officeColor,
			},
			lastUpdatedOn,
			priority,
			specification,
			unavailabilityReason,
		} = equipment;

		return (
			<>
				<div className={`unavailable-equipment-card__title ${ColorUtils.getColorBackgroundClass(categoryColor)}`} ref={this.setRef}>
					<div className="unavailable-equipment-card__title-left">
						<span className="unavailable-equipment-card__title-full">{code}</span>
						{specification && <span className="unavailable-equipment-card__title-full--additional">{specification}</span>}
					</div>
					{this.renderDropdown()}
				</div>
				<div className="unavailable-equipment-card__body">
					{this.renderInfoRow('Priority', <PriorityLabel priority={priority} />, this.getPriorityClassName(priority))}
					{this.renderInfoRow(returnDateTitle, returnDate ? TimeUtils.formatDate(returnDate, TimeFormatEnum.DATE_ONLY_SLASHES) : 'N/A')}
					{this.renderInfoRow('Updated At', TimeUtils.formatDate(lastUpdatedOn, TimeFormatEnum.FULL_DATE_SLASHES))}
					{this.renderInfoRow('Updated By', name, ColorUtils.getColorTextClass(officeColor))}
				</div>
				<div className="unavailable-equipment-card__failure-details-wrapper" onClick={showNotes ? this.collapseCard : this.expandCard}>
					<div
						className="unavailable-equipment-card__toggle-notes-button"
						onClick={showNotes ? this.collapseCard : this.expandCard}
					>
						{showNotes ? 'Hide' : 'Show'} Notes <span className={showNotes ? 'icon-collapse' : 'icon-expand'} />
					</div>
					{showNotes &&
						<div className="unavailable-equipment-card__failure-details">
							<div>
								Unavailability Reason:
							</div>
							<div className="unavailable-equipment-card__details-content">
								{unavailabilityReason ?? <span className="text-grey">N/A</span>}
							</div>
							<div>
								Failure Details:
							</div>
							<div className="unavailable-equipment-card__details-content">
								{
									failureDetails
										? failureDetails
										: <span className="text-grey">N/A</span>
								}
							</div>
						</div>
					}
				</div>
			</>
		);
	};

	render() {
		const {
			id,
			statusId,
			statusIndex,
		} = this.props.equipment;
		const { index, isMatch, isActive } = this.props;

		return (
			<div className="unavailable-equipment-card-wrapper">
				<div className="arrow-container">
					{isMatch && <div className={`arrow ${isActive ? 'arrow--active' : ''}`} />}
				</div>
				<Draggable
					draggableId={MechanicViewUtil.generateUnavailableDraggableId(id, { statusId, statusIndex })}
					index={index}
				>
					{
						(_provided, _snapshot) => (
							<div
								{...(_provided.draggableProps)}
								{...(_provided.dragHandleProps)}
								className={this.getClassName()}
								id={generateEquipmentSearchItemId(id.toString())}
								ref={_provided.innerRef}
								style={this.getDraggableStyle(_provided, _snapshot)}
							>
								{this.renderCardDetails()}
							</div>
						)
					}
				</Draggable>
			</div>
		);
	}
}

export default EquipmentCard;
