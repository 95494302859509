import { CustomFormErrors, FormErrors } from 'redux-form';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { isValidEmail } from 'acceligent-shared/utils/email';

import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';

import InstallmentRM from 'ab-requestModels/invoice/installment/installment.requestModel';

import InvoiceVM from 'ab-viewModels/workRequest/invoice.viewModel';
import InvoicedInvoiceRM from 'ab-requestModels/invoice/invoicedInvoiceUpdate.requestModel';
import FileType from 'acceligent-shared/enums/fileType';
import ResourceStatus from 'acceligent-shared/enums/resourceStatus';

class InstallmentFM {
	id: number | string;
	invoiceId: number;
	instNumber: number;
	amount: Nullable<string>;
	/** YYYY-MM-DD */
	datePaid: Nullable<string>;
	note: Nullable<string>;

	static validate = (form: InstallmentFM): FormErrors<InstallmentFM> => {

		const errors: FormErrors<InstallmentFM> = {};

		if (!form.amount) {
			errors.amount = 'Amount is required';
		}

		if (!form.datePaid) {
			errors.datePaid = 'Date paid is required';
		} else if (form.datePaid && !TimeUtils.isDateInCorrectFormat(form.datePaid, TimeFormat.DB_DATE_ONLY)) {
			errors.datePaid = 'Date paid is not in YYYY-MM-DD format';
		}

		return errors;
	};

	static fromFMtoRM(fm: InstallmentFM): InstallmentRM {

		if (fm.invoiceId === null || fm.invoiceId === undefined || !fm.datePaid || !fm.amount) {
			throw new Error('Installment is not filled out correctly');
		}

		return {
			invoiceId: fm.invoiceId,
			amount: fm.amount,
			datePaid: fm.datePaid,
			note: fm.note,
		};
	}
}

interface UserInfoFM {
	accountId?: Nullable<number>;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}

export class AttachmentFM {
	id: number;
	size: number;
	fileName: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	lastModifiedAt: Date;
	uploadedBy: Nullable<UserInfoFM>;
}

class BillingContactFM {
	email: string;
	editable: boolean;

	static validate = (form: BillingContactFM): FormErrors<BillingContactFM> => {
		const errors: FormErrors<BillingContactFM> = {};

		if (!form.email || form.email.length === 0) {
			errors.email = 'E-mail is required.';
			return errors;
		}

		if (!isValidEmail(form.email)) {
			errors.email = 'Invalid E-mail';
		}

		return errors;
	};
}

export class InvoiceFM {
	id: number;
	invoiceCode: string;
	/** YYYY-MM-DD */
	dateCreated: string;
	/** YYYY-MM-DD */
	dueDate: string;
	status: InvoiceStatusDisplay;
	note: Nullable<string>;
	totalAmount: number;
	paidAmount: number;
	outstandingDebt: number;
	retainedAmount: number;
	billingContacts: BillingContactFM[];
	sendReminderOnInvoice: boolean;
	excludeFromAutomaticReminders: boolean;
	installments: InstallmentFM[];
	attachments: AttachmentFM[];
	uploadedAttachmentIds?: number[];
	uploadingAttachments?: Record<string, true>;

	static getAttributeName = (attribute: keyof InvoiceFM) => attribute;

	static recalculatePaidAmount = (installments: InstallmentFM[]) => {
		return installments.reduce((_sum, _inst) => {
			_sum += (+(_inst.amount ?? 0));
			return _sum;
		}, 0);
	};

	static fromVMtoFM(vm: InvoiceVM): InvoiceFM {
		return {
			id: vm.id,
			invoiceCode: vm.invoiceCode,
			status: vm.status,
			dateCreated: vm.dateCreated,
			dueDate: vm.dueDate,
			totalAmount: vm.totalAmount,
			paidAmount: vm.paidAmount,
			outstandingDebt: vm.outstandingDebt,
			retainedAmount: vm.retainedAmount,
			sendReminderOnInvoice: vm.sendReminderOnInvoice,
			excludeFromAutomaticReminders: vm.excludeFromAutomaticReminders,
			billingContacts: vm.billingEmails?.map((_email) => { return { email: _email, editable: false }; }) ?? [],
			installments: vm.installments?.reverse().map((_inst, _ind) => {
				return { ..._inst, invoiceId: vm.id, editable: false, instNumber: (_ind + 1) };
			}) ?? [],
			note: vm.note,
			attachments: vm.attachments.map((_att) => { return { ..._att, fileName: _att.name }; }) ?? [],
			uploadedAttachmentIds: vm.uploadedAttachmentIds ?? [],
		};
	}

	static fromFMtoRM(fm: InvoiceFM): InvoicedInvoiceRM {
		return {
			id: fm.id,
			retainedAmount: fm.retainedAmount?.toString() ?? '0',
			excludeFromAutomaticReminders: fm.excludeFromAutomaticReminders,
			installments: fm.installments?.map(InstallmentFM.fromFMtoRM) ?? null,
			uploadedAttachmentIds: fm.uploadedAttachmentIds ?? [],
			billingEmails: fm.billingContacts.map((_bc) => _bc.email) ?? [],
			totalAmount: fm.totalAmount,
		};
	}

	static validate = (form: InvoiceFM): FormErrors<InvoiceFM> => {

		const errors: CustomFormErrors<InvoiceFM> = {};

		errors.installments = form?.installments?.reduce((_acc, _entry) => {
			const validatedEntry = InstallmentFM.validate(_entry);
			_acc.push(validatedEntry);
			return _acc;
		}, [] as FormErrors<InstallmentFM>[]);

		errors.billingContacts = form?.billingContacts?.reduce((_acc, _entry) => {
			const validatedEntry = BillingContactFM.validate(_entry);
			_acc.push(validatedEntry);
			return _acc;
		}, [] as FormErrors<BillingContactFM>[]);

		if (!!form.uploadingAttachments && Object.keys(form.uploadingAttachments).length) {
			errors.uploadingAttachments = 'Attachment upload in progress.';
		}

		return errors as FormErrors<InvoiceFM>;
	};

	static saveFormEnabled = (valid: boolean, form: InvoiceFM, areThereInstallmentsInEditMode: boolean): boolean => {
		if (!form || !valid) {
			return false;
		}

		const thereAreAttachmentsInUpload = !!form.uploadingAttachments && Object.keys(form.uploadingAttachments).length;

		return !areThereInstallmentsInEditMode && !thereAreAttachmentsInUpload;
	};

}
