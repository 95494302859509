import * as React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';

import CLIENT from 'af-constants/routes/client';

const Error500: React.FC = () => {

	const location = useLocation();
	const orgAlias = location.state.orgAlias;

	return (
		<div className="form-segment form-segment--mini">
			<div className="form-box form-box--standalone form-box--padded">
				<div className="form-box__icon-container">
					<span className="form-box__icon icon-settings rotate" />
				</div>
				<h2>Something went wrong</h2>
				<h4>There was a problem on our end!</h4>
				<h4>Our team is working hard to fix the issue. Please come back later.</h4>
				<div className="form-box__footer">
					<Link className="btn btn-primary" to={CLIENT.HOME(orgAlias)}>Back to Home</Link>
				</div>
			</div>
		</div>
	);
};

export default Error500;
