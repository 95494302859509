import * as React from 'react';

import Dropdown, { OwnProps as DropdownOptions, DropdownOptionType } from 'af-components/Controls/Dropdown';

type Props = DropdownOptions<DropdownOptionType>;

const ActionsCell: React.FC<Props> = (props) => {
	const onActionCellClick = React.useCallback((e) => e.stopPropagation(), []);
	return (
		<div onClick={onActionCellClick}>
			<Dropdown
				{...props}
			/>
		</div>
	);
};

export default React.memo(ActionsCell);
