import * as React from 'react';
import { Field } from 'redux-form';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { OverlapMeta } from 'acceligent-shared/utils/timeSheetEntry';

import TimeSheetEntryWorkType, { TimeSheetWorkTypeDisplayInfo } from 'acceligent-shared/enums/timeSheetEntryWorkType';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import TimePicker from 'af-fields/TimePicker';
import DatePicker from 'af-fields/DateInput';
import Dropdown from 'af-fields/Dropdown';

import * as TimeSheetUtils from 'af-utils/timeSheet.util';

import { bemBlock } from 'ab-utils/bem.util';

import { TimeSheetEntryFM } from './formModel';

import Stopwatch from '../../Shared/Stopwatch';

interface OwnProps {
	entry: TimeSheetEntryFM;
	field: string;
	index: number;
	inEditMode: boolean;
	dueDate: string;
	canEdit: boolean;
	overlap: OverlapMeta;
	onValueChange: (index: number, entry: TimeSheetEntryFM) => void;
	remove: (index: number) => void;
	onToggleEdit: () => void;
	/** value in ISO_DATETIME */
	change: (fieldName: string, value: Nullable<string>) => void;
}

type Props = OwnProps;

const WORK_TYPE_OPTIONS = Object.keys(TimeSheetEntryWorkType).map((_type) => ({ id: _type, label: TimeSheetWorkTypeDisplayInfo[_type].label }));

const checkEntry = (
	id: number | undefined,
	startDate: Nullable<string>,
	/** ISO_DATETIME */
	startTime: Nullable<string>,
	/** ISO_DATETIME */
	endTime: Nullable<string>,
	workType: Nullable<TimeSheetEntryWorkType>
) => !!id || (!!startDate && !!startTime && !!endTime && !!workType);

const TimeSheetEntry: React.FC<Props> = (props) => {
	const {
		index,
		entry,
		field,
		onValueChange,
		remove,
		inEditMode,
		onToggleEdit,
		dueDate,
		change,
		canEdit,
		overlap,
	} = props;

	const workTypeDisplay = entry.workType ? TimeSheetWorkTypeDisplayInfo[entry.workType] : null;
	const isTrackedEntry = entry.isInActiveShift && !entry.endTime;

	const removeEntry = React.useCallback(() => {
		remove(index);
	}, [remove, index]);

	const handleWorkTypeChange = React.useCallback((value: typeof WORK_TYPE_OPTIONS[0]) => {
		if (checkEntry(
			entry.id,
			entry.startDate,
			entry.startTime ?? null,
			entry.endTime ?? null,
			value.id as TimeSheetEntryWorkType
		)) {
			onValueChange(index, { ...entry, workType: value.id as TimeSheetEntryWorkType });
		}
	}, [entry, index, onValueChange]);

	const handleDateChange = React.useCallback((/** ISO_DATETIME */ value: string) => {
		const { startTime, endTime } = TimeSheetUtils.onStartDateChange(value, entry);
		change(`${field}.startTime`, startTime ?? null);
		change(`${field}.endTime`, endTime ?? null);
		if (checkEntry(entry.id, value, startTime, endTime, entry.workType)) {
			onValueChange(index, { ...entry, startDate: value, startTime: startTime, endTime: endTime });
		}
	}, [entry, index, onValueChange, change, field]);

	const handleStartTimeChange = React.useCallback((/** ISO_DATETIME */ value: string) => {
		if (!value) {
			change(`${field}.startTime`, null);
			return value;
		}

		const { startTime, endTime } = TimeSheetUtils.adjustStartTime(value, entry);

		change(`${field}.startTime`, startTime ?? null);
		change(`${field}.endTime`, endTime ?? null);

		if (checkEntry(entry.id, entry.startDate, startTime, endTime, entry.workType)) {
			onValueChange(index, { ...entry, startTime, endTime });
		}
	}, [entry, index, onValueChange, change, field]);

	const handleEndTimeChange = React.useCallback((value: string) => {
		if (!value) {
			change(`${field}.endTime`, null);
			return value;
		}

		const { startTime, endTime } = TimeSheetUtils.adjustEndTime(value, entry);

		change(`${field}.startTime`, startTime ?? null);
		change(`${field}.endTime`, endTime ?? null);

		if (checkEntry(entry.id, entry.startDate, startTime, endTime, entry.workType)) {
			onValueChange(index, { ...entry, startTime, endTime });
		}
	}, [entry, index, onValueChange, change, field]);

	const className = bemBlock('icon-edit', { active: inEditMode });

	const calendarSettings = React.useMemo(() => {
		return {
			maxDate: TimeUtils.addDays(dueDate, 1, TimeFormat.DB_DATE_ONLY).toDate(),
			minDate: new Date(dueDate),
		};
	}, [dueDate]);

	const startTimeInputWrapperClassName = bemBlock('condensed-table__input-wrapper', { error: overlap?.startTime || overlap?.occupied });
	const endTimeInputWrapperClassName = bemBlock('condensed-table__input-wrapper', { error: overlap?.endTime || overlap?.occupied });

	const renderEditMode = React.useMemo(() => {
		return (
			<div className="condensed-table__employee-list-sheet__entry condensed-table__employee-list-sheet__entry--edit">
				<div className="condensed-table__employee-list-sheet__entry__work-type">
					{workTypeDisplay ? <span className={`icon-${workTypeDisplay.icon}`} /> : <div />}
					<Field
						className="condensed-table__input-wrapper"
						component={Dropdown}
						disabled={isTrackedEntry}
						inputClassName="condensed-table__input"
						isStandalone={true}
						labelKey="label"
						name={`${field}.workType`}
						onValueChange={handleWorkTypeChange}
						options={WORK_TYPE_OPTIONS}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<Field
					calendarSettings={calendarSettings}
					component={DatePicker}
					dateFormat={TimeFormat.DATE_ONLY}
					datePickerClassName="condensed-table__input"
					disabled={isTrackedEntry}
					isStandalone={true}
					name={`${field}.startDate`}
					onValueChange={handleDateChange}
					originalDateFormat={TimeFormat.DATE_ONLY}
					withAppend={false}
					wrapperClassName="condensed-table__input-wrapper"
				/>
				<Field
					className={startTimeInputWrapperClassName}
					component={TimePicker}
					containerClassName="condensed-table__time-picker"
					disabled={isTrackedEntry}
					handleChangeOutside={true}
					inputTimeFormat={TimeFormat.ISO_DATETIME}
					name={`${field}.startTime`}
					onValueChange={handleStartTimeChange}
					toggleClassName="condensed-table__input condensed-table__time-picker-toggle"
				/>
				<div>-</div>
				<Field
					className={endTimeInputWrapperClassName}
					component={TimePicker}
					containerClassName="condensed-table__time-picker"
					disabled={isTrackedEntry}
					handleChangeOutside={true}
					inputTimeFormat={TimeFormat.ISO_DATETIME}
					name={`${field}.endTime`}
					onValueChange={handleEndTimeChange}
					toggleClassName="condensed-table__input condensed-table__time-picker-toggle"
				/>
				<div>
					{
						TimeUtils.minutesToHoursAndMinutes(
							!entry.startTime || !entry.endTime
								? 0
								: TimeUtils.getDiff(entry.endTime, entry.startTime, 'minutes', TimeFormat.ISO_DATETIME)
						)
					}
				</div>
				<div className="icon-delete" onClick={removeEntry} />
			</div>
		);
	}, [
		entry,
		removeEntry,
		handleStartTimeChange,
		handleEndTimeChange,
		handleDateChange,
		handleWorkTypeChange,
		calendarSettings,
		field,
		startTimeInputWrapperClassName,
		endTimeInputWrapperClassName,
		workTypeDisplay,
		isTrackedEntry,
	]);

	const renderReadOnlyMode = React.useMemo(() => {
		return (
			<div className="condensed-table__employee-list-sheet__entry">
				<div className="condensed-table__employee-list-sheet__entry__work-type">
					{workTypeDisplay ? <span className={`icon-${workTypeDisplay.icon} ${!!overlap ? 'text-red' : ''}`} /> : <div />}
					<div>{workTypeDisplay?.label}</div>
				</div>
				<div>{entry.startDate}</div>
				<div className="condensed-table__employee-list-sheet__entry__start-time">
					{TimeUtils.formatDate(entry.startTime ?? 0, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME)}
				</div>
				<div>-</div>
				<div>{entry.endTime ? TimeUtils.formatDate(entry.endTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME) : 'N/A'}</div>
				<div>
					{(isTrackedEntry && entry.startTime) ?
						<Stopwatch startTime={entry.startTime} />
						: TimeUtils.minutesToHoursAndMinutes(
							!entry.startTime || !entry.endTime
								? 0 :
								TimeUtils.getDiff(entry.endTime, entry.startTime, 'minutes', TimeFormat.ISO_DATETIME)
						)
					}
				</div>
			</div>
		);
	}, [workTypeDisplay, overlap, entry, isTrackedEntry]);

	return (
		<>
			{canEdit && !index ? <div className={className} onClick={onToggleEdit} /> : <div />}
			{inEditMode ? renderEditMode : renderReadOnlyMode}
		</>
	);
};

export default React.memo(TimeSheetEntry);
