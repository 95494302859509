import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import Address from 'acceligent-shared/models/address';

export const resolveFieldValue = (value: Nullable<string | Address>, type: ReportBlockFieldEnum.Type) => {
	if (!value) {
		return 'N/A';
	}

	let resolvedValue = value;

	if (type === ReportBlockFieldEnum.Type.BOOLEAN) {
		resolvedValue = value === 'true' ? 'Yes' : 'No';
	}

	if (type === ReportBlockFieldEnum.Type.ADDRESS) {
		// since non repeatable address fields are saved as stringified objects
		const isNonRepeatingField = (typeof value === 'string');

		return isNonRepeatingField
			? JSON.parse(value).street
			: (value as unknown as Address)?.street;
	}

	return resolvedValue;
};

