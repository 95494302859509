import * as React from 'react';
import { Badge } from 'react-bootstrap';

import { PhoneTypes, PhoneTypeNames, EmailTypes } from 'acceligent-shared/enums/contactMethodType';
import CountryCode from 'acceligent-shared/enums/countryCode';

import { formatPhoneNumber } from 'ab-utils/phone.util';

interface Phone {
	value: string;
	type: PhoneTypes | EmailTypes;
	countryCode?: CountryCode;
}

interface OwnProps {
	phones: Phone[];
}

type Props = OwnProps;

const _mapPhones = (phone: Phone, index: number) => (
	<div className="phone-cell" key={phone.value} title={phone.value}>
		<span>{index + 1}.</span>
		<Badge>{PhoneTypeNames[phone.type]}</Badge>
		{formatPhoneNumber(phone.value, phone.countryCode ?? null)}
	</div>
);

const PhoneCell = ({ phones }: Props) => {
	return <>{phones.map(_mapPhones)}</>;
};

export default PhoneCell;
