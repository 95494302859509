import * as React from 'react';
import { FormControl } from 'react-bootstrap';
import { FinalState } from 'react-table-6';

import Select from 'af-components/Controls/Select';
import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import { RectangleButton } from 'af-components/MultipleOptionsButton/RectangleButton';

import { bemElement, bemBlock } from 'ab-utils/bem.util';
import { DEFAULT_TABLE_PAGE_SIZE } from 'ab-constants/value';

const PageSizeSelect = Select as unknown as new () => Select<PageSizeOption>;

type Props<T> = FinalState<T>;

interface State {
	pageSizeOptions: PageSizeOption[];
}

type PageSizeOption = {
	value: number;
	label: number;
};

const leftIcon = <span className="icon-left" />;
const rightIcon = <span className="icon-right" />;

export default class PaginationComponent<T> extends React.PureComponent<Props<T>, State> {
	state: State = {
		pageSizeOptions: this.props.pageSizeOptions.map((value) => ({ value, label: value })),
	};

	private inputRef: Nullable<HTMLInputElement> = null;

	getOptionValue = (option: PageSizeOption) => option.value?.toString();

	getOptionLabel = (option: PageSizeOption) => option.value?.toString();

	previousPage = () => {
		const { page = 1, onPageChange, canPrevious } = this.props;
		if (canPrevious) {
			onPageChange(page - 1);
		}
		if (!this.inputRef) {
			throw new Error('Pagination incorrectly initialized');
		}

		this.inputRef.value = `${+this.inputRef.value - 1}`;
	};

	nextPage = () => {
		const { page = 1, onPageChange, canNext } = this.props;
		if (canNext) {
			onPageChange(page + 1);
		}

		if (!this.inputRef) {
			throw new Error('Pagination incorrectly initialized');
		}

		this.inputRef.value = `${+this.inputRef.value + 1}`;
	};

	onPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { onPageChange, pages = 1 } = this.props;
		const value: number = +event.target.value;
		onPageChange(Math.max(0, Math.min(value - 1, pages)));
	};

	onPageSizeChange = (item: PageSizeOption) => {
		const { onPageSizeChange, pageSize = DEFAULT_TABLE_PAGE_SIZE, page = 1 } = this.props;
		const newPageSize = item.value;
		const newPage = Math.floor(page * (pageSize / newPageSize));
		onPageSizeChange(newPageSize, newPage);

		if (!this.inputRef) {
			throw new Error('Pagination incorrectly initialized');
		}

		this.inputRef.value = `${newPage + 1}`;
	};

	onFormControlMount = (ref: HTMLInputElement) => this.inputRef = ref;

	render() {
		const { pageText, pages, page = 1, canPrevious, canNext, pageSize } = this.props;
		const { pageSizeOptions } = this.state;

		return (
			<div className={bemBlock('pagination')}>
				<div className={bemElement('pagination', 'page-size')}>
					<span>Rows per page</span>
					<PageSizeSelect
						controlledValue={true}
						getOptionLabel={this.getOptionLabel}
						getOptionValue={this.getOptionValue}
						isClearable={false}
						isSearchable={false}
						menuPlacement="top"
						onValueChange={this.onPageSizeChange}
						options={pageSizeOptions}
						value={{ value: pageSize!, label: pageSize! }}
					/>
				</div>
				<div className={bemElement('pagination', 'page-number')}>
					<span>{pageText}</span>
					<FormControl
						autoComplete="off"
						className="page-input"
						defaultValue={`${page + 1}`}
						max={pages}
						min={1}
						onChange={this.onPageChange}
						ref={this.onFormControlMount}
						step={1}
						type="number"
					/>
					<span className="m-r-s">of {pages}</span>
					<MultipleOptionsButton>
						<RectangleButton
							action={this.previousPage}
							isDisabled={!canPrevious}
							isSquare={true}
							label={leftIcon}
							tooltipMessage="Previous page"
							tooltipPlacement="top"
						/>
						<RectangleButton
							action={this.nextPage}
							isDisabled={!canNext}
							isSquare={true}
							label={rightIcon}
							tooltipMessage="Next page"
							tooltipPlacement="top"
						/>
					</MultipleOptionsButton>
				</div>
			</div>
		);
	}
}
