enum WorkOrderSettings {
	dayShift = 'Day Shift:',
	nightShift = 'Night Shift:',
	dayShiftStart = 'Day Shift Start Time',
	dayShiftEnd = 'Day Shift End Time',
	nightShiftStart = 'Night Shift Start Time',
	nightShiftEnd = 'Night Shift End Time',
	fieldReportAccessDayDuration = 'Automatic access removal:',
	isFRManageAllowedToApproveTimeSheet = 'Allow management to approve Time Sheets',
	isFRManageAllowedToEditTimeSheet = 'Allow management to edit Time Sheets',
	isFRManageLevelAllowedInReviewProcess = 'Allow management level to approve field reports',
	allowAllNonBillableShortcut = 'Allow management to mark all work summary details as non-billable',
}

export enum WorkOrderSettingsHint {
	dayShift = 'Default start and end time for Day Shift.',
	nightShift = 'Default start and end time for Night Shift.',
	fieldReportAccessDayDuration = 'Automatically remove Field Workers access to its Field Reports. Changes get applied every day at midnight. This can be manually overriden.',
	isFRManageAllowedToApproveTimeSheet = 'Accounting, Management and Company Admin will have permission to approve Time Sheets',
	isFRManageAllowedToEditTimeSheet = 'Accounting, Management and Company Admin will have permission to edit Time Sheets',
	isFRManageLevelAllowedInReviewProcess = 'Work Order approval process will have an additional step where Management can review Work Orders',
	allowAllNonBillableShortcut = 'Accounting, Management and Company Admin will have permission to define all work summary details as non-billable at once.'
}

export default WorkOrderSettings;
