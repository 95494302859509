import WorkOrderRM from 'acceligent-shared/dtos/web/request/workOrder/upsert';

import CDLStatus from 'acceligent-shared/enums/cdlStatus';
import { ColorPalette } from 'acceligent-shared/enums/color';
import { DirectoryIcon } from 'acceligent-shared/enums/directoryIcon';
import FileType from 'acceligent-shared/enums/fileType';
import FormFieldArrayStatus from 'acceligent-shared/enums/formFieldArrayStatus';
import ResourceStatus from 'acceligent-shared/enums/resourceStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkOrderPosition from 'acceligent-shared/enums/workOrderPosition';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';
import * as TimeOptionUtils from 'acceligent-shared/utils/timeOption';

import { Default } from 'ab-viewModels/workOrder/workOrderUpsert.viewModel';

interface ShiftFM {
	id: number;
	name: string;
}

interface AddressFM {
	id: number;
	aa1: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	country: Nullable<string>;
	latitude: number;
	longitude: number;
	locality: Nullable<string>;
	postalCode: Nullable<string>;
	route: Nullable<string>;
	street: string;
	streetNumber: Nullable<string>;
	suite: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
}

interface AddressArrayRM {
	address: AddressFM;
	status: FormFieldArrayStatus;
}

interface ContactFM {
	id: number;
	role: Nullable<string>;
	contactAddressIds: number[];
	contactEmailIds: number[];
	contactPhoneIds: number[];
}

interface SkillFM {
	id: number;
}

interface EmployeeFM {
	id: number;
	formattedCode: string;
	firstName: string;
	lastName: string;
	fullName: string;
	skills: SkillFM[];
	locationId: Nullable<number>;
	locationNickname: Nullable<string>;
	locationColor: Nullable<ColorPalette>;
	cdlStatus: CDLStatus;
}

export interface EquipmentFM {
	id: number;
	code: string;
	specification: Nullable<string>;
	equipmentCostId: number;
	equipmentCost?: Nullable<EquipmentCostFM>;
	equipmentCostCategoryColor: Nullable<ColorPalette>;
	skills: SkillFM[];
	allSkills: SkillFM[];
	showOnScheduleBoard: boolean;
}

interface WageRateFM {
	id: number;
	type: string;
	wageClassification: string;
}

interface EquipmentCostFM {
	id: number;
	categoryName: string;
	skills: SkillFM[];
}

interface TemporaryEmployeeFM {
	id: number;
	firstName: string;
	lastName: string;
	fullName: string;
	uniqueId: string;
}

interface WorkOrderResourceLookupFM {
	id: number;
	workOrderId: number;
	workOrderEmployeeId?: Nullable<number>;
	workOrderEquipmentId?: Nullable<number>;
	workOrderPlaceholderId?: Nullable<number>;
	workOrderTemporaryEmployeeId?: Nullable<number>;
	employeeId?: Nullable<number>;
	employee?: Nullable<EmployeeFM>;
	equipmentId?: Nullable<number>;
	equipment?: Nullable<EquipmentFM>;
	wageRateId?: Nullable<number>;
	wageRate?: Nullable<WageRateFM>;
	equipmentCostId?: Nullable<number>;
	equipmentCost?: Nullable<EquipmentCostFM>;
	temporaryEmployeeId?: Nullable<number>;
	temporaryEmployee?: Nullable<TemporaryEmployeeFM>;
	skills?: SkillFM[];
	index: number;
	perDiem: Nullable<boolean>;
	perDiemAmount: Nullable<string>;
}

interface UserFM {
	id: number;
	formattedCode: string;
	firstName: string;
	lastName: string;
}

interface UpdatedByFM {
	/** userId */
	id: number;
	fullName: string;
}

interface JobFM {
	id: number;
	status: WorkRequestStatus;
	isInternal: boolean;
	customerCompany: Nullable<string>;
	customerFormatted: Nullable<string>;
	customerFullName: Nullable<string>;
	office: Nullable<string>;
	title: Nullable<string>;
	projectManagerId?: Nullable<number>;
	projectManager?: Nullable<UserFM>;
	jobCode: string;
	travelLocationShort: Nullable<string>;
	deliverableSoftware: Nullable<string>;
	deliverableFileFormat: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	isDeliverable: boolean;
}

interface CrewTypeFM {
	id: number;
	name: string;
	color: ColorPalette;
}

interface WorkOrderReportTypeLookupFM {
	id: number;
	reportTypeId: number;
	name?: string;
	index: Nullable<number>;
	isRequired: boolean;
}

interface WorkOrderReportTypeLookupArrayFM {
	reportTypeLookup: WorkOrderReportTypeLookupFM;
	status: FormFieldArrayStatus;
}

interface UserInfoFM {
	accountId?: Nullable<number>;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}

interface AttachmentFM {
	id: number;
	size: number;
	name: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	lastModifiedAt: Date;
	uploadedBy: Nullable<UserInfoFM>;
}

export interface WorkOrderDirectoryFM {
	id: number;
	name: string;
	parentId: Nullable<number>;
	icon: Nullable<DirectoryIcon>;
	lastModifiedAt: Nullable<Date>;
	lastModifiedBy: Nullable<UserInfoFM>;
	attachmentIds: number[];
}

class WorkOrderFM {
	id: number;
	code: string;
	dailyCode: number;
	index: Nullable<number>;
	/** YYYY-MM-DD */
	dueDate: string;
	cancellationReason: Nullable<string>;
	status: WorkOrderStatus;

	jobId: number;
	job: JobFM;
	crewTypeId: Nullable<number>;
	crewType: Nullable<CrewTypeFM>;
	customCrewType: Nullable<string>;
	timeToStart: Nullable<number>;
	timeToEnd: Nullable<number>;
	shift: ShiftFM;
	isInternal: boolean;

	notes: Nullable<string>;
	scopeOfWork: Nullable<string>;

	projectManagerId: Nullable<number>;
	projectManager: Nullable<UserFM>;
	supervisorId: number;
	supervisor: UserFM;
	siteContact: Nullable<ContactFM>;
	workLocationAddress: Nullable<string>;
	officeNickname: Nullable<string>;
	divisionName: Nullable<string>;
	customerName: Nullable<string>;

	addresses: AddressArrayRM[];

	workOrderResourceLookups: WorkOrderResourceLookupFM[];

	workOrderReportTypeLookup: WorkOrderReportTypeLookupArrayFM[];
	updateExistingReports: boolean;

	position: WorkOrderPosition;

	excludeFromNotify: boolean;
	excludeTempLaborFromNotify: boolean;
	excludeFromDeliverables: boolean;

	revenue: Nullable<string>;
	manHourAverage: Nullable<string>;
	jobHours: Nullable<number>;
	shopHours: Nullable<number>;
	travelHours: Nullable<number>;
	workDescription: Nullable<string>;

	deliverableSoftware: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	deliverableFileFormat: Nullable<string>;

	updatedAt: Date;
	updatedBy: UpdatedByFM;

	customerWorkOrder: Nullable<string>;

	directories: WorkOrderDirectoryFM[];
	uploadedAttachmentIds: number[];

	attachments: AttachmentFM[];
	uploadingAttachments: Record<string, true>;

	constructor(vm: Default) {
		this.id = vm.id;
		this.code = vm.code;
		this.dailyCode = vm.dailyCode;
		this.index = vm.index;
		this.dueDate = TimeUtils.formatDate(vm.dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY);
		this.cancellationReason = vm.cancellationReason;
		this.status = vm.status;
		this.jobId = vm.jobId;
		this.job = vm.job;
		this.isInternal = vm.isInternal;
		this.crewTypeId = vm.crewTypeId;
		this.crewType = vm.crewType;
		this.customCrewType = vm.customCrewType;
		this.timeToStart = TimeOptionUtils.toNumber(vm.timeToStart);
		this.timeToEnd = TimeOptionUtils.toNumber(vm.timeToEnd);
		this.shift = vm.shift;
		this.notes = vm.notes;
		this.scopeOfWork = vm.scopeOfWork;
		this.projectManagerId = vm.projectManagerId;
		this.projectManager = vm.projectManager;
		this.supervisorId = vm.superintendentId;
		this.supervisor = vm.superintendent;
		this.siteContact = vm.siteContact;
		this.workLocationAddress = vm.workLocationAddress;
		this.officeNickname = vm.officeNickname;
		this.divisionName = vm.divisionName;
		this.customerName = vm.customerName;
		this.addresses = vm.addresses.map((address) => ({
			address: address.address,
			status: FormFieldArrayStatus.OLD,
		}));
		this.workOrderResourceLookups = vm.workOrderResourceLookups;
		this.workOrderReportTypeLookup = vm.workOrderReportTypeLookups.map((reportTypeLookup) => ({
			reportTypeLookup,
			status: FormFieldArrayStatus.OLD,
		}));
		this.updateExistingReports = false;
		this.position = WorkOrderPosition.DEFAULT;
		this.excludeFromNotify = vm.excludeFromNotify;
		this.excludeTempLaborFromNotify = vm.excludeTempLaborFromNotify,
			this.excludeFromDeliverables = vm.excludeFromDeliverables;
		this.revenue = vm.revenue;
		this.manHourAverage = vm.manHourAverage;
		this.jobHours = vm.jobHours;
		this.shopHours = vm.shopHours;
		this.travelHours = vm.travelHours;
		this.workDescription = vm.workDescription;
		this.deliverableSoftware = vm.deliverableSoftware;
		this.deliverableCodeStandard = vm.deliverableCodeStandard;
		this.deliverableFileFormat = vm.deliverableFileFormat;
		this.updatedAt = vm.updatedAt;
		this.updatedBy = vm.updatedBy;
		this.customerWorkOrder = vm.customerWorkOrder;
		this.directories = vm.directories;
		this.uploadedAttachmentIds = [];
		this.attachments = vm.attachments;

		this.uploadingAttachments = {};
	}

	static toRequestModel(form: WorkOrderFM): WorkOrderRM {
		return {
			id: form.id,
			code: form.code,
			dailyCode: form.dailyCode,
			index: form.index,
			dueDate: form.dueDate,
			cancellationReason: form.cancellationReason,
			status: form.status,
			jobId: form.jobId,
			job: form.job,
			crewTypeId: form.crewTypeId,
			crewType: form.crewType,
			customCrewType: form.customCrewType,
			timeToStart: form.timeToStart,
			timeToEnd: form.timeToEnd,
			shift: form.shift,
			isInternal: form.isInternal,
			notes: form.notes,
			scopeOfWork: form.scopeOfWork,
			projectManagerId: form.projectManagerId,
			projectManager: form.projectManager,
			supervisorId: form.supervisorId,
			supervisor: form.supervisor,
			siteContact: form.siteContact,
			workLocationAddress: form.workLocationAddress,
			officeNickname: form.officeNickname,
			divisionName: form.divisionName,
			customerName: form.customerName,
			addresses: form.addresses,
			workOrderResourceLookups: form.workOrderResourceLookups,
			workOrderReportTypeLookup: form.workOrderReportTypeLookup,
			updateExistingReports: form.updateExistingReports,
			position: form.position,
			excludeFromNotify: form.excludeFromNotify,
			excludeTempLaborFromNotify: form.excludeTempLaborFromNotify,
			excludeFromDeliverables: form.excludeFromDeliverables,
			revenue: form.revenue,
			manHourAverage: form.manHourAverage,
			jobHours: form.jobHours,
			shopHours: form.shopHours,
			travelHours: form.travelHours,
			workDescription: form.workDescription,
			deliverableSoftware: form.deliverableSoftware,
			deliverableCodeStandard: form.deliverableCodeStandard,
			deliverableFileFormat: form.deliverableFileFormat,
			updatedAt: form.updatedAt,
			updatedBy: form.updatedBy,
			customerWorkOrder: form.customerWorkOrder,
			directories: form.directories,
			uploadedAttachmentIds: form.uploadedAttachmentIds,
		};
	}

}

export default WorkOrderFM;
