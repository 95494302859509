import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import { USER_SAVE_DIGITAL_SIGNATURE } from 'af-constants/reduxForms';

import Signature from 'af-fields/Signature';
import Checkbox from 'af-fields/Checkbox';

import { RootState } from 'af-reducers/index';

import CLIENT from 'af-routes/client';

import * as AccountActions from 'af-actions/accounts';

import UpdateDigitalSignatureRM from 'ab-requestModels/account/updateDigitalSignature.requestModel';

import { validate } from './validations';
import styles from './styles.module.scss';

const TITLE = 'Digital Signature';

interface OwnProps {
	closeModal: () => void;
	showModal: boolean;
	signatureImage: Nullable<string>;
}

type FormOwnProps = OwnProps;

type FormProps = InjectedFormProps<UpdateDigitalSignatureRM, FormOwnProps>;

type Props = FormOwnProps & FormProps & ConnectedProps<typeof connector>;

const DigitalSignatureSaveModal: React.FC<Props> = (props) => {
	const {
		closeModal,
		showModal,
		companyName,
		initialize,
		destroy,
		signatureImage,
		saveDigitalSignature,
		invalid,
		submitting,
		handleSubmit,
		orgAlias,
	} = props;

	React.useEffect(() => {
		if (signatureImage) {
			initialize({ signatureImage });
		} else {
			destroy();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signatureImage]);

	const onConfirm = React.useCallback(async (form: UpdateDigitalSignatureRM) => {
		await saveDigitalSignature(form);
		closeModal();
	}, [saveDigitalSignature, closeModal]);

	const onCancel = React.useCallback(async (form: UpdateDigitalSignatureRM) => {
		if (form.hideCreateDigitalSignature) {
			await saveDigitalSignature({ hideCreateDigitalSignature: form.hideCreateDigitalSignature, signatureImage: null });
		}
		closeModal();
	}, [saveDigitalSignature, closeModal]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={TITLE}
			/>
			<CustomModal.Body>
				<div className={styles['save-signature-modal__text']}>Do you want to save your digital signature for all future approval actions?</div>
				<Field
					component={Signature}
					name="signatureImage"
				/>
				<div className={[styles['save-signature-modal__text'], styles['save-signature-modal__text--margin-top']].join(' ')}>
					You can change your digital signature any time under
					<a
						className="text-blue"
						href={CLIENT.COMPANY.SETTINGS.ACCOUNT(orgAlias, companyName)}
					>
						{' Settings > Account'}
					</a>
				</div>
				<Field
					component={Checkbox}
					inline={true}
					label="Don't ask me again"
					name="hideCreateDigitalSignature"
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<SubmitButton
					label="Don't save"
					onClick={handleSubmit(onCancel)}
					reduxFormSubmitting={submitting}
					variant="info"
				/>
				<SubmitButton
					disabled={invalid}
					label="Save signature"
					onClick={handleSubmit(onConfirm)}
					reduxFormSubmitting={submitting}
				/>
			</CustomModal.Footer>
		</CustomModal >
	);
};

function mapDispatchToProps() {
	return {
		saveDigitalSignature: AccountActions.saveDigitalSignature,
	};
}

function mapStateToProps(state: RootState) {
	const { user: { companyData, organizationData } } = state;

	if (!companyData || !organizationData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
		orgAlias: organizationData.alias,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector,
	reduxForm<UpdateDigitalSignatureRM, FormProps>({ form: USER_SAVE_DIGITAL_SIGNATURE, validate })
);

export default enhance(DigitalSignatureSaveModal);
