import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import Scrollbars from 'react-custom-scrollbars';
import { History } from 'history';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import { SCROLL_EVENT_LOCK_TIMEOUT } from 'af-constants/values';

import * as User from 'ab-viewModels/user.viewModel';

import CustomScrollbar from 'af-components/CustomScrollbar';
import WeeklyHorizontalScrollbar from 'af-root/scenes/Company/ScheduleBoard/WeeklyView/WeeklyViewContainer/WeeklyHorizontalScrollbar';
import Toolbar from 'af-root/scenes/Company/ScheduleBoard/Shared/Toolbar';
import ToolbarHeader from 'af-root/scenes/Company/ScheduleBoard/WeeklyView/WeeklyViewContainer/ToolbarHeader';
import DayView from 'af-root/scenes/Company/ScheduleBoard/WeeklyView/WeeklyViewContainer/DayView';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import SharedModalProps from '../../Shared/ModalProps';

interface OwnProps extends SharedModalProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	companyName: string;
	dates: string[];
	forceUnlockOrder: (dueDate: string, workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	history: History;
	isDragAndDropDisabled: boolean;
	lastOpenedOrderCode: string;
	onDragEnd: (dragEvent: DropResult) => void;
	onDragStart: (dragEvent: DropResult) => void;
	orgAlias: string;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	scheduleAutoNotify: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
	sendNotification: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
}

interface StateProps {
	maxWorkOrdersPerDay: number;
	weeklyViewDateWithToolbar: Nullable<string>;
	weeklyViewSelectMultiple: string;
	companyData: User.CompanyData;
	userData: User.UserData;
}

interface DispatchProps {
	setWeeklyViewHorizontalScrollingPercentage: typeof ScheduleBoardActions.setWeeklyViewHorizontalScrollingPercentage;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	maxWidth: number;
	hideScrollbars: boolean;
}

class WeeklyViewContainer extends React.PureComponent<Props, State> {

	state: State = {
		hideScrollbars: false,
		maxWidth: 0, // max width of card containers for every day in weekly view
	};

	_verticalScrollbarRef: Nullable<React.RefObject<Scrollbars>> = null;
	_scrollbarRefs: React.RefObject<Scrollbars>[] = [];
	_onScrollEventLocked: boolean = false;
	_onScrollEventLockTimer: Nullable<NodeJS.Timeout> = null;

	componentDidMount() {
		window.addEventListener('resize', this.onScrollUpdate);
	}

	componentWillUnmount() {
		if (this._onScrollEventLockTimer) {
			clearTimeout(this._onScrollEventLockTimer);
		}
		window.removeEventListener('resize', this.onScrollUpdate);
	}

	onVerticalScrollMount = (scrollElement: React.RefObject<Scrollbars>) => {
		this._verticalScrollbarRef = scrollElement;
	};

	onScrollMount = (scrollElement: React.RefObject<Scrollbars>) => {
		if (scrollElement?.current) {
			this._scrollbarRefs.push(scrollElement);
			this._scrollbarRefs = this._scrollbarRefs.filter(Boolean);
			const hideScrollbars = scrollElement.current.getScrollWidth() <= scrollElement.current.getClientWidth();
			this.setState((prevState: State) => ({
				hideScrollbars: prevState.hideScrollbars || hideScrollbars,
				maxWidth: Math.max(prevState.maxWidth, scrollElement.current?.getScrollWidth() ?? 0),
			}));
		}
	};

	onScrollUpdate = () => {
		const { setWeeklyViewHorizontalScrollingPercentage } = this.props;

		// find max width of horizontal scroll containers
		let maxWidth = 0;
		let scrollBarLeftOffset = 0;
		let clientWidth = 0;
		let hideScrollbars = false;

		this._scrollbarRefs.forEach(
			(_ref: React.RefObject<Scrollbars>) => {
				if (_ref.current) {
					hideScrollbars = hideScrollbars || _ref.current.getScrollWidth() <= _ref.current.getClientWidth();
					scrollBarLeftOffset = Math.max(scrollBarLeftOffset, (_ref.current.getScrollLeft() || 0));
					maxWidth = Math.max(maxWidth, (_ref.current.getScrollWidth() || 0));
					clientWidth = Math.max(clientWidth, (_ref.current.getClientWidth() || 0));
				}
			}
		);

		this.setState(() => ({ hideScrollbars, maxWidth }));

		const newScrollingPercentage = scrollBarLeftOffset / (maxWidth - clientWidth);
		setWeeklyViewHorizontalScrollingPercentage(newScrollingPercentage);
	};

	onHorizontalScroll = (scrollPercentage: number, width: number, force: boolean = false) => {
		if (!force && this._onScrollEventLocked) {
			return;
		}
		if (force && this._onScrollEventLockTimer) {
			clearTimeout(this._onScrollEventLockTimer);
		}
		this._onScrollEventLocked = true;
		this._onScrollEventLockTimer = setTimeout(() => {
			this._onScrollEventLocked = false;
		}, SCROLL_EVENT_LOCK_TIMEOUT);

		const { setWeeklyViewHorizontalScrollingPercentage } = this.props;
		this._scrollbarRefs.forEach((_ref: React.RefObject<Scrollbars>) => {
			if (_ref.current) {
				_ref.current.scrollLeft(scrollPercentage * width);
			}
		});
		setWeeklyViewHorizontalScrollingPercentage(scrollPercentage);
	};

	render() {
		const {
			addBlankWorkOrder,
			companyName,
			currentWorkOrderModalId,
			dates,
			forceUnlockOrder,
			hasPermissionsToEditScheduleBoard,
			hasPermissionsToSendNotifications,
			history,
			isDragAndDropDisabled,
			lastOpenedOrderCode,
			maxWorkOrdersPerDay,
			onDragEnd,
			onDragStart,
			orgAlias,
			removeBlankWorkOrder,
			scheduleAutoNotify,
			sendNotification,
			setWorkOrderModalId,
			setWorkOrderNoteModalData,
			setEmployeeModalData,
			setEmployeeModalVisibility,
			setEquipmentModalData,
			setEquipmentModalVisibility,
			setTemporaryEmployeeModalData,
			weeklyViewDateWithToolbar,
			weeklyViewSelectMultiple,
		} = this.props;
		const { maxWidth, hideScrollbars } = this.state;

		return (
			<CustomScrollbar
				contentWrapperClassName="schedule-board-weekly-view-wrapper"
				onMount={this.onVerticalScrollMount}
			>
				{!hideScrollbars &&
					<WeeklyHorizontalScrollbar
						maxWidth={maxWidth}
						onHorizontalScroll={this.onHorizontalScroll}
						position="top"
						verticalScrollRef={this._verticalScrollbarRef}
					/>
				}
				<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
					{
						dates.map((_dueDate, _index) => {
							let className = _index === 0 ? 'start-day' : '';
							className = _index === dates.length - 1 ? 'end-day' : className;
							return (
								<DayView
									addBlankWorkOrder={addBlankWorkOrder}
									className={className}
									companyName={companyName}
									currentWorkOrderModalId={currentWorkOrderModalId}
									droppableId={ScheduleBoardUtil.generateDroppableId(
										ScheduleBoardContext.BOARD,
										ScheduleBoardProperty.WORK_ORDER, _dueDate, _dueDate
									)}
									dueDate={_dueDate}
									forceUnlockOrder={forceUnlockOrder}
									hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
									history={history}
									isDragAndDropDisabled={isDragAndDropDisabled}
									isWeeklyViewSelectMultiple={!!weeklyViewSelectMultiple && weeklyViewSelectMultiple !== TimeUtils.formatDate(_dueDate)}
									key={`weeklyView#${_dueDate}`}
									lastOpenedOrderCode={lastOpenedOrderCode}
									maxWorkOrdersPerDay={maxWorkOrdersPerDay}
									onHorizontalScroll={this.onHorizontalScroll}
									onHorizontalScrollMount={this.onScrollMount}
									onHorizontalScrollUpdate={this.onScrollUpdate}
									orgAlias={orgAlias}
									removeBlankWorkOrder={removeBlankWorkOrder}
									setEmployeeModalData={setEmployeeModalData}
									setEmployeeModalVisibility={setEmployeeModalVisibility}
									setEquipmentModalData={setEquipmentModalData}
									setEquipmentModalVisibility={setEquipmentModalVisibility}
									setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
									setWorkOrderModalId={setWorkOrderModalId}
									setWorkOrderNoteModalData={setWorkOrderNoteModalData}
									weeklyViewDateWithToolbar={weeklyViewDateWithToolbar}
								/>
							);
						})
					}
					{
						weeklyViewDateWithToolbar &&
						<div className="weekly-view-toolbar-wrapper">
							<ToolbarHeader dueDate={weeklyViewDateWithToolbar} />
							<Toolbar
								dueDate={weeklyViewDateWithToolbar}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
								isDragAndDropDisabled={isDragAndDropDisabled}
								scheduleAutoNotify={scheduleAutoNotify}
								sendNotification={sendNotification}
								setEmployeeModalData={setEmployeeModalData}
								setEmployeeModalVisibility={setEmployeeModalVisibility}
								setEquipmentModalData={setEquipmentModalData}
								setEquipmentModalVisibility={setEquipmentModalVisibility}
							/>
						</div>
					}
				</DragDropContext>
				{!hideScrollbars &&
					<WeeklyHorizontalScrollbar
						maxWidth={maxWidth}
						onHorizontalScroll={this.onHorizontalScroll}
						position="bottom"
						verticalScrollRef={this._verticalScrollbarRef}
					/>
				}
			</CustomScrollbar>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { user: { userData, companyData }, scheduleBoard } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		weeklyViewDateWithToolbar: scheduleBoard.weeklyViewDateWithToolbar,
		weeklyViewSelectMultiple: Object.keys(scheduleBoard.weeklyViewSelectMultiple ?? {})[0],
		maxWorkOrdersPerDay: Math.max(0, ...Object.values(scheduleBoard.workOrdersByDateDictionary).map(({ workOrdersOrdering, workOrders }) => {
			return workOrdersOrdering.reduce((_sum, _workOrderCode) => {
				if (ScheduleBoardUtil.isBlankWorkOrderId(_workOrderCode)) {
					return _sum + 1;
				}
				return _sum + ScheduleBoardUtil.getColumnNumberForWorkOrder(workOrders[_workOrderCode]);
			}, 0);
		})),
		userData,
		companyData,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		setWeeklyViewHorizontalScrollingPercentage: ScheduleBoardActions.setWeeklyViewHorizontalScrollingPercentage,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(WeeklyViewContainer);
