import * as React from 'react';

interface OwnProps {
	isCollapsed: boolean;
	onChange: (isCollapsed: boolean) => void;
	expandText?: string;
	collapseText?: string;
	className?: string;
}

type Props = React.PropsWithChildren<OwnProps>;

const Accordion: React.FC<Props> = React.memo((props) => {
	const {
		isCollapsed,
		onChange,
		children,
		expandText = 'Expand',
		collapseText = 'Collapse',
		className = '',
	} = props;

	const handleChange = React.useCallback(() => {
		onChange(!isCollapsed);
	}, [onChange, isCollapsed]);

	return (
		<>
			<div className={`accordion ${className}`} onClick={handleChange}>
				<span className={`accordion__icon ${isCollapsed ? 'icon-down' : 'icon-up'}`} />
				<span className={'accordion__text'}>{isCollapsed ? expandText : collapseText}</span>
			</div>
			{!isCollapsed && children}
		</>
	);
});

export default Accordion;
