import { WageRateRequestModel, WageRateImportBatch } from 'ab-requestModels/wageRate.requestModel';
import { CSVRow, CSVData } from 'ab-viewModels/csv.viewModel';

const PROPS = {
	TYPE: 'type',
	WAGE_CLASSIFICATION: 'wageClassification',
	HOURLY_RATE: 'hourlyRate',
};

const parseRow = (row: CSVRow): WageRateRequestModel => {
	const wageRate: WageRateRequestModel = {
		type: '',
		wageClassification: '',
		hourlyRate: 0,
	};

	Object.entries(row).forEach(([_column, _value]) => {
		if (!_value) {
			return;
		}

		switch (_column) {
			case PROPS.TYPE:
			case PROPS.WAGE_CLASSIFICATION:
				wageRate[_column] = _value;
				break;
			case PROPS.HOURLY_RATE:
				wageRate[_column] = parseFloat(_value);
				break;
		}
	});

	return wageRate;
};

export default (data: CSVData): WageRateImportBatch => ({ wageRateBatch: data.map(parseRow) });
