import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import { SEARCH_DELAY } from 'ab-constants/value';

import { SortByEnum, OrderByEnum } from 'ab-enums/mechanicView.enum';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { RootState } from 'af-reducers';

import { debounce } from 'af-utils/actions.util';

import SortByButtonGroup from './SortByButtonGroup';
import SearchFilter from './SearchFilter';

interface StateProps {
	query: string;
	sortBy: SortByEnum;
	orderBy: OrderByEnum;
}

interface DispatchProps {
	setMechanicViewSearchQuery: typeof ScheduleBoardActions.setMechanicViewSearchQuery;
	setSortBy: typeof ScheduleBoardActions.setMechanicViewSortBy;
}

type Props = StateProps & ResolveThunks<DispatchProps>;

interface State {
	internalQuery: string;
}

class MechanicViewHeader extends React.PureComponent<Props, State> {
	state: State = {
		internalQuery: '',
	};

	onQueryChanged = (query: string = '') => {
		const { setMechanicViewSearchQuery } = this.props;
		setMechanicViewSearchQuery(query);
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	onQueryChangedDebounced = debounce(this.onQueryChanged, SEARCH_DELAY);

	onQueryChangedDebouncedEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const query = event.target.value || '';
		this.setState(
			() => ({ internalQuery: query }),
			() => this.onQueryChangedDebounced(this.state.internalQuery)
		);
	};

	clearSearch = () => {
		this.setState(
			() => ({ internalQuery: '' }),
			() => {
				const { setMechanicViewSearchQuery } = this.props;
				setMechanicViewSearchQuery('');
			}
		);
	};

	changeSort = (newSort: SortByEnum) => {
		const { orderBy, sortBy, setSortBy } = this.props;
		let newOrder = OrderByEnum.DESCENDING;
		if (newSort === sortBy && orderBy === OrderByEnum.DESCENDING) {
			newOrder = OrderByEnum.ASCENDING;
		}
		setSortBy(newSort, newOrder);
	};

	render() {
		const {
			sortBy,
			orderBy,
		} = this.props;
		const { internalQuery } = this.state;

		return (
			<div className="mechanic-view-header">
				<div className="mechanic-view-header__left">
					<SearchFilter
						changeSearchText={this.onQueryChangedDebouncedEventHandler}
						clearSearchText={this.clearSearch}
						searchText={internalQuery}
					/>
				</div>
				<div className="mechanic-view-header__right">
					<SortByButtonGroup
						changeSortBy={this.changeSort}
						orderBy={orderBy}
						sortBy={sortBy}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const {
		scheduleBoard: {
			mechanicView: {
				orderEquipmentBy,
				searchQuery,
				sortEquipmentBy,
			},
		},
	} = state;

	return {
		orderBy: orderEquipmentBy,
		query: searchQuery,
		sortBy: sortEquipmentBy,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		setMechanicViewSearchQuery: ScheduleBoardActions.setMechanicViewSearchQuery,
		setSortBy: ScheduleBoardActions.setMechanicViewSortBy,
	};
}

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps())(MechanicViewHeader);
