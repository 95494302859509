import * as React from 'react';
import { Button } from 'react-bootstrap';

import ColorLegendModal from 'af-root/scenes/Company/ScheduleBoard/Shared/ColorLegendModal';

import { SCREEN_BREAKPOINT_L } from 'af-constants/values';

import Tooltip from 'af-components/Tooltip';

interface State {
	isHelpModalOpen: boolean;
	isButtonSmall: boolean;
}

type Props = Record<string, never>;

class ColorLegend extends React.PureComponent<Props, State> {

	state: State = {
		isHelpModalOpen: false,
		isButtonSmall: window.innerWidth < SCREEN_BREAKPOINT_L,
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState(() => ({ isButtonSmall: window.innerWidth < SCREEN_BREAKPOINT_L }));
	};

	openHelpModal = () => this.setState(() => ({ isHelpModalOpen: true }));

	closeHelpModal = () => this.setState(() => ({ isHelpModalOpen: false }));

	render() {
		const { isHelpModalOpen, isButtonSmall } = this.state;
		return (
			<>
				{
					isButtonSmall ?
						<Tooltip message="Legend" placement="bottom">
							<Button className="btn--icon" onClick={this.openHelpModal} variant="info">
								<span className="icon-help" />
							</Button>
						</Tooltip> :
						<Button onClick={this.openHelpModal} variant="info">
							<span className="icon-help" />
							Legend
						</Button>
				}
				<ColorLegendModal
					closeModal={this.closeHelpModal}
					modalVisible={isHelpModalOpen}
				/>
			</>
		);
	}
}

export default ColorLegend;
