import * as React from 'react';
import { Field } from 'redux-form';
import { ConnectedProps, connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import DeliverableDataTypeEnum from 'acceligent-shared/enums/deliverableDataType';

import * as DeliverableDataActions from 'af-actions/deliverableData';
import * as AccountActions from 'af-actions/accounts';

import AccountViewModel from 'ab-viewModels/account.viewModel';
import { DeliverableDataListViewModel } from 'ab-viewModels/deliverableData.viewModel';

import DropdownAccountItem from 'af-components/DropdownAccountItem';

import Dropdown from 'af-fields/Dropdown';
import Textarea from 'af-fields/Textarea';

const renderDeliverableManagerItem = (option: AccountViewModel) => option ? <DropdownAccountItem option={option} /> : <div />;

type Props = ConnectedProps<typeof connector>;

const DELIVERABLE_MANAGERS_FILTER_BY: (keyof AccountViewModel)[] = ['firstName', 'lastName', 'uniqueId'];
const DELIVERABLE_NOTES_MAX_LENGTH = 250;
const DELIVERABLE_NOTES_NUMBER_OF_LINES = 10;

const DeliverableSection: React.FC = (props: Props) => {
	const { loadDeliverableData, findAllAssignableForDeliverable } = props;

	const [deliverableManagersForCompany, setDeliverableManagersForCompany] = React.useState<AccountViewModel[]>([]);
	const initialDeliverableData = React.useMemo(() => ({
		[DeliverableDataTypeEnum.DELIVERABLE_CODE_STANDARD]: [],
		[DeliverableDataTypeEnum.DELIVERABLE_SOFTWARE]: [],
		[DeliverableDataTypeEnum.DELIVERABLE_FILE_FORMAT]: [],
		[DeliverableDataTypeEnum.DELIVERABLE_DELIVERY_METHOD]: [],
		[DeliverableDataTypeEnum.DELIVERABLE_DELIVERY_TIMELINE]: [],
	}), []);

	const [
		deliverableData,
		setDeliverableData,
	] = React.useState<DeliverableDataListViewModel>(initialDeliverableData);

	const lazyLoadDeliverableManagers = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const deliverableManagers = await findAllAssignableForDeliverable();
			setDeliverableManagersForCompany(deliverableManagers ?? []);
		}
	}, [findAllAssignableForDeliverable]);

	const lazyLoadDeliverableData = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const data = await loadDeliverableData();
			setDeliverableData(data ?? initialDeliverableData);
		}
	}, [initialDeliverableData, loadDeliverableData]);

	return (
		<Row>
			<Col sm={8}>
				<Field
					component={Dropdown}
					id="deliverableSoftwareId"
					label="Deliverable Software"
					labelKey="name"
					name="deliverableSoftwareId"
					onLazyLoad={lazyLoadDeliverableData}
					options={deliverableData[DeliverableDataTypeEnum.DELIVERABLE_SOFTWARE]}
					propName="deliverableSoftware"
					valueKey="id"
					withCaret={true}
				/>
				<Field
					component={Dropdown}
					id="deliverableCodeStandardId"
					label="Deliverable Code Standard"
					labelKey="name"
					name="deliverableCodeStandardId"
					onLazyLoad={lazyLoadDeliverableData}
					options={deliverableData[DeliverableDataTypeEnum.DELIVERABLE_CODE_STANDARD]}
					propName="deliverableCodeStandard"
					valueKey="id"
					withCaret={true}
				/>
				<Field
					component={Dropdown}
					id="deliverableFileFormatId"
					label="Deliverable File Format"
					labelKey="name"
					name="deliverableFileFormatId"
					onLazyLoad={lazyLoadDeliverableData}
					options={deliverableData[DeliverableDataTypeEnum.DELIVERABLE_FILE_FORMAT]}
					propName="deliverableFileFormat"
					valueKey="id"
					withCaret={true}
				/>
			</Col>
			<Col sm={8}>
				<Field
					component={Dropdown}
					id="deliverableDeliveryMethodId"
					label="Deliverable Delivery Method"
					labelKey="name"
					name="deliverableDeliveryMethodId"
					onLazyLoad={lazyLoadDeliverableData}
					options={deliverableData[DeliverableDataTypeEnum.DELIVERABLE_DELIVERY_METHOD]}
					propName="deliverableDeliveryMethod"
					valueKey="id"
					withCaret={true}
				/>
				<Field
					component={Dropdown}
					id="deliverableDeliveryTimelineId"
					label="Deliverable Delivery Timeline"
					labelKey="name"
					name="deliverableDeliveryTimelineId"
					onLazyLoad={lazyLoadDeliverableData}
					options={deliverableData[DeliverableDataTypeEnum.DELIVERABLE_DELIVERY_TIMELINE]}
					propName="deliverableDeliveryTimeline"
					valueKey="id"
					withCaret={true}
				/>
				<Field
					component={Dropdown}
					filterable={true}
					filterBy={DELIVERABLE_MANAGERS_FILTER_BY}
					id="deliverableAssigneeId"
					isArrayField={true}
					label="Deliverable QA/QC Assignee *"
					name="deliverableAssigneeId"
					onLazyLoad={lazyLoadDeliverableManagers}
					options={deliverableManagersForCompany}
					propName="deliverableAssignee"
					renderMenuItem={renderDeliverableManagerItem}
					valueKey="id"
					withCaret={true}
				/>
			</Col>
			<Col sm={8}>
				<Field
					component={Textarea}
					id="accqa__job__deliverable-notes"
					label="Deliverable Notes"
					maxCharacters={DELIVERABLE_NOTES_MAX_LENGTH}
					name="deliverableNotes"
					placeholder="Enter Notes"
					rows={DELIVERABLE_NOTES_NUMBER_OF_LINES}
					showMaxCharactersLabel={true}
				/>
			</Col>
		</Row>
	);
};

function mapDispatchToProps() {
	return {
		loadDeliverableData: DeliverableDataActions.findAllForCompany,
		findAllAssignableForDeliverable: AccountActions.findAllAssignableForDeliverable,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(DeliverableSection);
