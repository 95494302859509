import * as React from 'react';

import { WorkOrderResourceLookupModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import { getColorBackgroundClass } from 'ab-utils/color.util';

import Tooltip from 'af-components/Tooltip';

interface Props {
	resourceLookup: WorkOrderResourceLookupModel;
	removed: boolean;
}

const EquipmentItem: React.FC<Props> = (props) => {
	const { removed, resourceLookup } = props;

	const { name, color } = resourceLookup;
	const _color = getColorBackgroundClass(color, true);

	return (
		removed ? (
			<Tooltip message="Removed" placement="right">
				<div className={`work-order-info-modal__resource-item work-order-info-modal__resource-item--equipment work-order-info-modal__resource-item--removed ${_color}`}>
					{name.toUpperCase()}
				</div>
			</Tooltip>
		) : (
			<div className={`work-order-info-modal__resource-item work-order-info-modal__resource-item--equipment ${_color}`}>
				{name.toUpperCase()}
			</div>
		)
	);
};

export default React.memo(EquipmentItem);
