import { toDate } from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import EmployeeDownRM from 'ab-requestModels/employee/employeeDown.requestModel';

export function validate(values: EmployeeDownRM, props) {
	const errors: ValidationErrors = {};

	if (values.returnDate) {
		const returnDateDate = toDate(values.returnDate, TimeFormat.DATE_ONLY);
		const dateDate = toDate(props.date, TimeFormat.DATE_ONLY);

		if (returnDateDate && dateDate && returnDateDate <= dateDate) {
			errors.returnDate = 'Return Date has to be after date of work';
		}
	}

	return errors;
}

export function warn(values: EmployeeDownRM, props) {
	const errors: ValidationErrors = {};

	if (!values.returnDate) {
		errors.returnDate = 'Leaving Return Date empty will make employee permanently unavailable and will remove any future statuses';
	} else {
		if (props.isAutomaticReturnDate) {
			errors.returnDate = 'Employee will become available on this date';
		}
	}

	return errors;
}

