import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import LockedValue from 'af-components/LockedValue';

interface OwnProps {
	label: string;
	defaultValue?: Nullable<string>;
	id?: string;
	onlyValue?: boolean;
	valueClassName?: Nullable<string>;
	/** Intentionally called different from "addonAfter" in Input Field, to allow better control */
	valueSuffix?: Nullable<string>;
	tooltipMessage?: Nullable<string>;
}

type Props = OwnProps & WrappedFieldProps;

class Text extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		onlyValue: false,
		defaultValue: null,
		valueClassName: null,
	};

	render() {
		const {
			defaultValue,
			id,
			input,
			label,
			onlyValue,
			valueClassName,
			valueSuffix,
			tooltipMessage,
		} = this.props;

		const value = input.value || defaultValue || null;
		// Default value is usually "N/A" or "None" after which we don't want to add anything
		const displayValue = !!input.value && !!valueSuffix ? `${value} ${valueSuffix}` : value;

		if (onlyValue) {
			return displayValue;
		}

		return (
			<LockedValue
				id={id}
				label={label}
				tooltipMessage={tooltipMessage}
				value={displayValue}
				valueClassName={valueClassName}
			/>
		);
	}
}

export default Text;
