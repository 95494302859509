import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import Tooltip from 'af-components/Tooltip';
import LockedValue from 'af-components/LockedValue';

interface OwnProps {
	workOrderCode: string;
	assigneeFullName: string;
	notes: string;
}

type Props = OwnProps;

class DeliverableAssignmentHeader extends React.PureComponent<Props> {

	render() {
		const { assigneeFullName, workOrderCode, notes } = this.props;

		return (
			<Row className="deliverable-status-history-modal-details">
				<Col sm={8}><LockedValue defaultValue="N/A" label="Work Order ID" value={workOrderCode} /></Col>
				<Col sm={8}><LockedValue defaultValue="N/A" label="Field Worker" value={assigneeFullName} /></Col>
				<Col sm={8}>
					<LockedValue
						defaultValue="N/A"
						label="Notes"
						value={notes &&
							<Tooltip message={notes}>
								<span className="icon-display_view notes-icon" />
							</Tooltip>
						}
					/>
				</Col>
			</Row>
		);
	}
}

export default DeliverableAssignmentHeader;
