import * as React from 'react';
import { Field, FormAction } from 'redux-form';
import { Option } from 'react-select/src/filters';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import Select from 'af-fields/SelectField';

import CreateContactModal from './CreateContactModal';

const ContactSelect = Select as unknown as new () => Select<ContactVM>;

interface Props {
	canCreateNew?: boolean;
	changeField: (form: string, field: string, value: ContactVM) => FormAction;
	clearContact: () => void;
	contacts: ContactVM[];
	disabled?: boolean;
	fieldName: string;
	formName: string;
	onLazyLoad?: () => Promise<void>;
	placeholder?: string;
	prepopulateWithRole?: string;
}

interface State {
	showCreateNewModal: boolean;
	contactName: Nullable<string>;
}

export default class SelectContact extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		canCreateNew: true,
		disabled: false,
	};

	state: State = {
		showCreateNewModal: false,
		contactName: null,
	};

	static renderMenuItem = (item: ContactVM) => {
		return (
			<div className="contact__dropdown-item">
				<div>{item.fullName}</div>
				<div>
					{item.companyName && <small>{item.companyName}</small>}
					{item.companyName && item.title && <small> | </small>}
					{item.title && <small>{item.title}</small>}
				</div>
			</div>
		);
	};

	static getOptionText = (option: Nullable<ContactVM>) => option ? `${option.fullName} ${option.title} ${option.companyName}` : '';

	static getOptionValue = (option: Nullable<ContactVM>) => `${option?.id ?? ''}`;

	static isValidNewOption = (inputValue: string, value: ContactVM, options: ContactVM[]) => {
		return !!inputValue && !options.some(({ fullName }) => fullName.toLowerCase() === inputValue.toLowerCase());
	};

	static getNewOptionData = (inputValue: string): { __isNew__: boolean; } & Partial<ContactVM> => {
		return {
			__isNew__: true,
			fullName: inputValue,
		};
	};

	openCreateContactModal = (contactName: string) => this.setState(() => ({ showCreateNewModal: true, contactName }));

	closeCreateContactModal = () => this.setState(() => ({ showCreateNewModal: false, contactName: null }));

	onCreateNew = (data: ContactVM) => this.onContactChange(data);

	onContactChange = (data: ContactVM) => {
		const { formName, fieldName, changeField } = this.props;
		changeField(formName, fieldName, data);
	};

	filterBy = (option: Option, searchText: string) => {
		if (!option) {
			return false;
		}
		const text = SelectContact.getOptionText(option.data);
		return text.toLowerCase().includes(searchText.toLowerCase());
	};

	render() {
		const {
			canCreateNew,
			clearContact,
			contacts,
			disabled,
			fieldName,
			onLazyLoad,
			placeholder,
		} = this.props;
		const { showCreateNewModal, contactName } = this.state;

		return (
			<div className="contact__select-container">
				<Field
					allowNew={canCreateNew}
					className="contact__dropdown"
					component={ContactSelect}
					containerClassName="contact__dropdown-container"
					disableErrorMessage={true}
					filterOption={this.filterBy}
					formatOptionLabel={SelectContact.renderMenuItem}
					getNewOptionData={SelectContact.getNewOptionData}
					getOptionValue={SelectContact.getOptionValue}
					isDisabled={disabled}
					isValidNewOption={SelectContact.isValidNewOption}
					name={fieldName}
					onClear={clearContact}
					onCreateNew={this.openCreateContactModal}
					onLazyLoad={onLazyLoad}
					onValueChange={this.onContactChange}
					options={contacts}
					placeholder={placeholder}
				/>
				{canCreateNew && showCreateNewModal && contactName &&
					<CreateContactModal
						closeModal={this.closeCreateContactModal}
						contactName={contactName}
						onCreateNew={this.onCreateNew}
						showModal={showCreateNewModal}
					/>
				}
			</div>
		);
	}
}
