import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';

import CreateLaborPlaceholderForm from 'ab-requestModels/scheduleBoardCreateLaborPlaceholder.requestModel';
import CreateTemporaryEmployeeAssignmentRM from 'ab-requestModels/workOrderTemporaryEmployee/createTemporaryEmployeeAssignment';

import { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import CustomModal from 'af-components/CustomModal';

import Tabs, { TAB_IDS } from './Tabs';
import LaborPlaceholderForm from './CreateLaborPlaceholder/LaborPlaceholderForm';
import CreateTemporaryLaborForm from './CreateTemporaryEmployeeAssignment';

const DEFAULT_TAB = TAB_IDS.TEMPORARY_LABOR;

interface OwnProps {
	resetTimer: () => void;
	onCreatePlaceholder: (form: CreateLaborPlaceholderForm) => void;
	onCreateAssignment: (workOrderId: number, form: CreateTemporaryEmployeeAssignmentRM) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const LaborModal: React.FC<Props> = (props: Props) => {
	const {
		dueDate,
		index,
		onCreateAssignment,
		onCreatePlaceholder,
		resetTimer,
		setLaborModalData,
		setLaborModalVisibility,
		showModal,
		temporaryEmployeeAssignments,
		workOrderCode,
		workOrderId: _workOrderId,
	} = props;

	const [activeTabId, setActiveTabId] = React.useState(DEFAULT_TAB);

	const closeModal = React.useCallback(() => {
		setLaborModalVisibility(false);
		setLaborModalData({ workOrderCode: null, workOrderId: null, index: null, dueDate: null });
	}, [setLaborModalVisibility, setLaborModalData]);

	const onSubmitPlaceholder = React.useCallback((form: CreateLaborPlaceholderForm) => {
		onCreatePlaceholder(form);
		closeModal();
	}, [onCreatePlaceholder, closeModal]);

	const onSubmitAssignTempEmployee = React.useCallback((workOrderId: number, form: CreateTemporaryEmployeeAssignmentRM) => {
		onCreateAssignment(workOrderId, form);
		closeModal();
	}, [onCreateAssignment, closeModal]);

	const onTabSelect = React.useCallback((tabId: number) => setActiveTabId(tabId), [setActiveTabId]);

	const initialValues = React.useMemo(() => {
		return {
			index,
			workOrderId: _workOrderId,
		};
	}, [index, _workOrderId]);

	const renderBody = React.useCallback(() => {
		if (activeTabId === 0) {
			return (
				<LaborPlaceholderForm
					initialValues={initialValues}
					onClose={closeModal}
					onSubmit={onSubmitPlaceholder}
					resetTimer={resetTimer}
				/>
			);
		}

		if (!dueDate || !workOrderCode) {
			return null;
		}

		return (
			<CreateTemporaryLaborForm
				dueDate={dueDate}
				initialValues={initialValues}
				onClose={closeModal}
				onSubmit={onSubmitAssignTempEmployee}
				temporaryEmployeeAssignments={temporaryEmployeeAssignments}
				workOrderCode={workOrderCode}
			/>
		);
	}, [
		activeTabId,
		dueDate,
		workOrderCode,
		initialValues,
		closeModal,
		onSubmitAssignTempEmployee,
		temporaryEmployeeAssignments,
		onSubmitPlaceholder,
		resetTimer,
	]);

	return (
		<CustomModal
			className="schedule-board-modal"
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={`Add Labor on ${workOrderCode}`}
			/>
			<Tabs
				activeTabId={activeTabId}
				setActiveTabId={onTabSelect}
			/>
			{renderBody()}
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	const { isOpen, workOrderCode, workOrderId, index, dueDate } = state.scheduleBoard.laborModal;

	const temporaryEmployeeAssignments = dueDate
		? state.scheduleBoard.workOrdersByDateDictionary?.[dueDate]?.temporaryEmployeeAssignments
		: undefined;

	return {
		showModal: isOpen,
		workOrderCode,
		dueDate,
		temporaryEmployeeAssignments,
		index, workOrderId,
	};
}

function mapDispatchToProps() {
	return {
		setLaborModalVisibility: ScheduleBoardActions.setLaborModalVisibility,
		setLaborModalData: ScheduleBoardActions.setLaborModalData,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(connector(LaborModal));
