import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

import LoadingIndicator from 'af-components/LoadingIndicator';

export default () => {
	return (
		<div className={bemElement('time-sheet-edit-modal', 'loading')}>
			<LoadingIndicator color="orange" />
		</div>
	);
};
