import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { CellContext } from '@tanstack/react-table';

import { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import LinkCell from 'af-components/Table/Cells/LinkCell';
import Label from 'af-components/Label';
import SimpleTable from 'af-components/Controls/SimpleTable';
import { SimpleTableProps } from 'af-components/Controls/SimpleTable/types';

import * as WorkRequestActions from 'af-actions/workRequests';

import CLIENT from 'af-routes/client';

import { RootState } from 'af-reducers';

import { getFullClientUrl } from 'af-utils/http.util';

import { stateAbbreviation } from 'ab-enums/states.enum';

import { previewSectionCenteredColumn, previewSectionClass, previewSectionTitleClass, tableBlueTextClass, tableCellTextClass, tableLightTextClass, tableUppercaseTextClass } from '../../helpers';

type OwnProps = {
	workRequestId: number;
	orgAlias: string;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const SubjobsSection: React.FC<Props> = (props) => {
	const {
		workRequestId,
		findAllSubjobs,
		companyName,
		orgAlias,
	} = props;

	const [subJobs, setSubJobs] = React.useState<SubjobVM[]>([]);

	const fetchAndSetSubjobs = React.useCallback(async () => {
		const fetchedSubJobs = await findAllSubjobs(workRequestId, { page: 0, pageSize: 10000 });
		setSubJobs(fetchedSubJobs.rows);
	}, [findAllSubjobs, workRequestId]);

	React.useEffect(() => {
		fetchAndSetSubjobs();
	}, [fetchAndSetSubjobs]);

	const renderLinkCell = React.useCallback(() =>
		(_cell: CellContext<SubjobVM, number>) => {

			const path = getFullClientUrl(orgAlias, CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, `${_cell.getValue()}`));

			return <LinkCell label="" path={path} />;
		}, [companyName, orgAlias]);

	const renderTextCell = React.useCallback((isUppercase: boolean) => (cell: CellContext<SubjobVM, string>) => {

		const value = cell.getValue();
		const baseStyle = isUppercase ? tableUppercaseTextClass : tableCellTextClass;
		const style = !!value ? baseStyle : tableLightTextClass;

		return (
			<span className={style}>
				{value ?? 'N/A'}
			</span>
		);
	}, []);

	const renderJobIdCell = React.useCallback(() => (cell: CellContext<SubjobVM, string>) => {
		return (
			<span className={previewSectionCenteredColumn}>
				<Label
					className="subjob__margin"
					text="S"
				/>
				<span className={tableBlueTextClass}>{cell.getValue() ?? 'N/A'}</span>
			</span>

		);
	}, []);

	const renderStateCell = React.useCallback(() => (cell: CellContext<SubjobVM, string>) => {

		const cellValue = cell.getValue();
		const style = !!cellValue ? tableCellTextClass : tableLightTextClass;

		const value = cellValue
			? stateAbbreviation[cellValue] ?? cellValue
			: 'N/A';

		return (
			<span className={style}>{value}</span>
		);
	}, []);

	const columns: SimpleTableProps<SubjobVM>['columns'] = React.useMemo(() => [
		{
			id: 'id',
			accessor: 'id',
			header: '',
			cell: renderLinkCell(),
			enableSorting: false,
			size: 50,
		},
		{
			id: 'jobCode',
			accessor: 'jobCode',
			header: 'Job ID',
			cell: renderJobIdCell(),
			enableSorting: true,
		},
		{
			id: 'title',
			accessor: 'title',
			header: 'Job Name',
			cell: renderTextCell(true),
			enableSorting: true,
		},
		{
			id: 'customerCompany',
			accessor: 'customerCompany',
			header: 'Customer Company',
			cell: renderTextCell(true),
			enableSorting: true,
		},
		{
			id: 'city',
			accessor: 'city',
			header: 'City',
			cell: renderTextCell(false),
			enableSorting: true,
		},
		{
			id: 'state',
			accessor: 'state',
			header: 'State',
			cell: renderStateCell(),
			enableSorting: true,
		},
		{
			id: 'office',
			accessor: 'office',
			header: 'Office',
			cell: renderTextCell(false),
			enableSorting: true,
		},
		{
			id: 'projectManager',
			accessor: 'projectManager',
			header: 'Project Manager',
			cell: renderTextCell(true),
			enableSorting: true,
		},
		{
			id: 'startDate',
			accessor: 'startDate',
			header: 'Start Date',
			cell: renderTextCell(false),
			enableSorting: true,
		},
	], [renderJobIdCell, renderLinkCell, renderStateCell, renderTextCell]);

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Associated Sub-Jobs
			</div>
			<SimpleTable
				columns={columns}
				rows={subJobs}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findAllSubjobs: WorkRequestActions.findAllSubjobsTable,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector
);

export default enhance(SubjobsSection);
