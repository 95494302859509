import * as React from 'react';

import { TimeSheetEntryHistoryItemVM } from 'ab-viewModels/timeSheet/timeSheetEntryHistory.viewModel';

import { bemElement } from 'ab-utils/bem.util';

import TimeSheetEntryHistoryItem from './TimeSheetEntryHistoryItem';

interface OwnProps {
	items: Nullable<TimeSheetEntryHistoryItemVM[]>;
}

type Props = OwnProps;

const renderHistoryListItem = (item: TimeSheetEntryHistoryItemVM) => {
	return (
		<TimeSheetEntryHistoryItem
			item={item}
			key={item.id}
		/>
	);
};

const TimeSheetEntryHistorySection: React.FC<Props> = (props: Props) => {
	const { items } = props;

	if (!items?.length) {
		return null;
	}

	return (
		<div className={bemElement('timeline-history-modal', 'section', ['active'])}>
			<div className="timeline-history-modal__section-title">ACTIVE ENTRIES</div>
			{items.map(renderHistoryListItem)}
		</div>
	);
};

export default React.memo(TimeSheetEntryHistorySection);
