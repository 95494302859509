import { Dispatch, AnyAction } from 'redux';

import { SHORTEN_URL } from 'af-constants/reduxForms';

import API from 'af-constants/routes/api';
import CLIENT from 'af-constants/routes/client';

import NotifyCompanyRequestModel from 'ab-requestModels/companyNotify.requestModel';
import { ShortenUrlRequestModel } from 'ab-requestModels/shortenUrl.requestModel';
import NotifyOrganizationRequestModel from 'ab-requestModels/organizationNotify.requestModel';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { GetRootState } from 'af-reducers';

export function notifyAllForCompany(form: NotifyCompanyRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.COMMUNICATION.COMPANY_NOTIFY_ALL, form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function notifyAllForOrganization(form: NotifyOrganizationRequestModel, clientAlias: string, orgAlias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.COMMUNICATION.ORGANIZATION_NOTIFY_ALL(clientAlias), form);
			return redirectTo(CLIENT.PLATFORM.ALL_ORGANIZATIONS(orgAlias));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function shortenUrl(form: ShortenUrlRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<string>(API.V1.COMMUNICATION.SHORTEN_URL, form, { submitting: SHORTEN_URL });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

