import * as React from 'react';

import { previewSectionClass, previewSectionClassColumn6, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';

type Props = {
	summaryOfWork: Nullable<string>;
};

const SummaryOfWorkSection: React.FC<Props> = (props) => {
	const { summaryOfWork } = props;
	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Summary of Work
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn6}>
					<b>{summaryOfWork ?? '-'}</b>
				</div>
			</div>
		</div>
	);
};

export default React.memo(SummaryOfWorkSection);
