import * as React from 'react';

class AddressHeader extends React.PureComponent {
	render() {
		return (
			<div className="work-order-info-modal__work-location work-order-info-modal__work-location--header">
				<div>Street Address</div>
				<div>Suite/Floor</div>
				<div>City</div>
				<div>State</div>
				<div>Zip</div>
				<div>P.O. Box</div>
				<div>Country</div>
			</div>
		);
	}
}

export default AddressHeader;
