import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import CLIENT from 'af-constants/routes/client';
import { SCREEN_BREAKPOINT_M, SCREEN_BREAKPOINT_L } from 'af-constants/values';

import { MAX_SCHEDULE_BOARD_ZOOM_LEVEL, MIN_SCHEDULE_BOARD_ZOOM_LEVEL } from 'ab-constants/scheduleBoard';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import SocketEvent from 'ab-enums/socketEvent.enum';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import ViewSwitcher from 'af-root/scenes/Company/ScheduleBoard/Shared/ViewSwitcher';
import ScheduleBoardViewDatePicker from 'af-root/scenes/Company/ScheduleBoard/Shared/Header/ScheduleBoardViewDatePicker';

import * as User from 'ab-viewModels/user.viewModel';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import ConnectionCounters from 'af-components/ConnectionCounters';

import { RootState } from 'af-reducers';

import { isAllowed } from 'ab-utils/auth.util';

import { getWorkOrderSelectedDueDate } from 'af-utils/settings.util';
import * as BrowserUtil from 'af-utils/browser.util';
import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';
import socket from 'af-utils/socket.util';

import ColorLegend from './ColorLegend';
import NavigationItem from './NavigationItem';
interface StateProps {
	userData: User.UserData;
	companyData: User.CompanyData;
	idleConnections: number;
	activeConnections: number;
	scheduleBoardView: Nullable<ScheduleBoardView>;
	isAllowedToCreateJob: boolean;
	isAllowedToCreateWorkOrder: boolean;
	zoomLevel: number;
}

interface DispatchProps {
	clearDailyView: typeof ScheduleBoardActions.clearDailyView;
	clearWeeklyView: typeof ScheduleBoardActions.clearWeeklyView;
	setZoomLevel: typeof ScheduleBoardActions.setZoomLevel;
}

type Props = StateProps & ResolveThunks<DispatchProps>;

const ScheduleBoardNavigation: React.FC<Props> = (props) => {

	const {
		setZoomLevel,
		scheduleBoardView,
		clearDailyView,
		clearWeeklyView,
		companyData,
		zoomLevel,
		activeConnections,
		idleConnections,
		isAllowedToCreateWorkOrder,
		isAllowedToCreateJob,
	} = props;

	const navigate = useNavigate();
	const location = useLocation();
	const orgAlias = location.state.orgAlias;

	const [areCreationButtonsVisible, setAreCreationButtonsVisible] = React.useState(window.innerWidth >= SCREEN_BREAKPOINT_M);
	const [isFullscreen, setIsFullscreen] = React.useState(false);
	const [isFullscreenButtonVisible, setIsFullscreenButtonVisible] = React.useState(window.innerWidth >= SCREEN_BREAKPOINT_M);

	const originUrl = React.useMemo(() => {
		if (scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW) {
			return CLIENT.COMPANY.SCHEDULE_BOARD.WEEKLY_VIEW(orgAlias, companyData.name);
		}
		return CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyData.name);
	}, [companyData.name, orgAlias, scheduleBoardView]);

	const updateWindowDimensions = React.useCallback(() => {
		setIsFullscreenButtonVisible(window.innerWidth >= SCREEN_BREAKPOINT_M);
		setAreCreationButtonsVisible(window.innerWidth >= SCREEN_BREAKPOINT_M);

		let _zoomLevel: Nullable<number> = null;
		if (window.innerWidth < SCREEN_BREAKPOINT_M) {
			_zoomLevel = 2;
		} else if (window.innerWidth >= SCREEN_BREAKPOINT_M && window.innerWidth < SCREEN_BREAKPOINT_L) {
			_zoomLevel = 1;
		} else {
			_zoomLevel = 0;
		}
		setZoomLevel(_zoomLevel);
	}, [setZoomLevel]);

	React.useEffect(() => {
		updateWindowDimensions();
		window.addEventListener('resize', updateWindowDimensions);
		return () => {
			window.removeEventListener('resize', updateWindowDimensions);
		};
	}, [updateWindowDimensions]);

	const redirectToWorkOrderForm = React.useCallback(() => {

		ScheduleBoardUtil.clearScheduleBoardRedux(
			scheduleBoardView,
			clearDailyView,
			clearWeeklyView
		);

		const _woDueDate = getWorkOrderSelectedDueDate();
		navigate(CLIENT.COMPANY.WORK_ORDERS.ORDER(null, orgAlias, companyData.name), {
			state: {
				defaultDueDate: _woDueDate && TimeUtils.formatDate(_woDueDate, TimeFormatEnum.JS_TIME),
				originUrl,
				originLabel: 'Schedule Board',
				orgAlias,
			},
		});
	}, [clearDailyView, clearWeeklyView, companyData.name, navigate, orgAlias, originUrl, scheduleBoardView]);

	const zoomIn = React.useCallback(() => {

		if (zoomLevel >= MAX_SCHEDULE_BOARD_ZOOM_LEVEL) {
			return;
		}

		setZoomLevel(zoomLevel + 1);
	}, [setZoomLevel, zoomLevel]);

	const zoomOut = React.useCallback(() => {

		if (zoomLevel <= MIN_SCHEDULE_BOARD_ZOOM_LEVEL) {
			return;
		}

		setZoomLevel(zoomLevel - 1);
	}, [setZoomLevel, zoomLevel]);

	const fullScreenToggle = React.useCallback(() => {
		BrowserUtil.toggleFullscreen();
		setIsFullscreen(!isFullscreen);
	}, [isFullscreen]);

	const redirectToJobForm = React.useCallback(() => {

		ScheduleBoardUtil.clearScheduleBoardRedux(
			scheduleBoardView,
			clearDailyView,
			clearWeeklyView
		);
		navigate(
			CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyData.name),
			{
				state: {
					originUrl,
					orgAlias,
				},
			}
		);
	}, [clearDailyView, clearWeeklyView, companyData.name, navigate, orgAlias, originUrl, scheduleBoardView]);

	const refreshConnectionCount = React.useCallback((dates: string[]) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.REFRESH_CONNECTION_COUNT, dates);
	}, []);

	return (
		<div className="schedule-board-navigation">
			<div className="schedule-board-navigation-left">
				<div>
					<div className="navigation-title">
						Schedule Board
					</div>
					<ConnectionCounters activeCount={activeConnections} idleCount={idleConnections} />
				</div>
			</div>
			<div className="schedule-board-navigation-center">
				<div className="sb-view-switcher">
					<ViewSwitcher
						companyName={companyData.name}
						history={history}
						orgAlias={orgAlias}
						refreshConnectionCount={refreshConnectionCount}
						view={scheduleBoardView}
					/>
				</div>
				<div className="sb-date-picker">
					<ScheduleBoardViewDatePicker
						refreshConnectionCount={refreshConnectionCount}
						scheduleBoardView={scheduleBoardView}
					/>
					<MultipleOptionsButton>
						<>
							<RectangleButton
								action={zoomOut}
								isSquare={true}
								label={<span className="icon-minus" />}
								tooltipMessage="Zoom out"
								tooltipPlacement="bottom"
							/>
							<RectangleButton
								action={zoomIn}
								isSquare={true}
								label={<span className="icon-plus" />}
								tooltipMessage="Zoom in"
								tooltipPlacement="bottom"
							/>
							{
								isFullscreenButtonVisible &&
								<RectangleButton
									action={fullScreenToggle}
									isSquare={true}
									label={<span className={isFullscreen ? 'icon-fullscreen_exit' : 'icon-fullscreen_enter'} />}
									tooltipMessage="Full screen"
									tooltipPlacement="bottom"
								/>
							}
						</>
					</MultipleOptionsButton>
				</div>
			</div>
			<div className="schedule-board-navigation-right">
				<ColorLegend />
				{
					areCreationButtonsVisible && isAllowedToCreateWorkOrder &&
					<a
						className="btn btn-primary"
						onClick={redirectToWorkOrderForm}
						role="button"
					>
						Add New WO
					</a>
				}
				{
					areCreationButtonsVisible && isAllowedToCreateJob &&
					<a
						className="btn btn-primary"
						onClick={redirectToJobForm}
						role="button"
					>
						Add New Job
					</a>
				}
				<NavigationItem />
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState): StateProps {
	const { user: { companyData, userData }, scheduleBoard: { idleConnections, activeConnections, scheduleBoardView } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isAllowedToCreateJob: boolean = isAllowed(PagePermissions.COMPANY.JOBS.CREATE, companyData.permissions, companyData.isCompanyAdmin, userData.role);
	const isAllowedToCreateWorkOrder: boolean = isAllowed(
		PagePermissions.COMPANY.WORK_ORDERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		userData,
		companyData,
		idleConnections: idleConnections ?? 0,
		activeConnections: activeConnections ?? 0,
		scheduleBoardView: scheduleBoardView ?? ScheduleBoardView.DAILY_VIEW,
		isAllowedToCreateJob,
		isAllowedToCreateWorkOrder,
		zoomLevel: state.scheduleBoard.zoomLevel,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		clearDailyView: ScheduleBoardActions.clearDailyView,
		clearWeeklyView: ScheduleBoardActions.clearWeeklyView,
		setZoomLevel: ScheduleBoardActions.setZoomLevel,
	};
}

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps())(ScheduleBoardNavigation);
