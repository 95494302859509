import * as React from 'react';
import { Field } from 'redux-form';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import Checkbox from 'af-fields/Checkbox';
import Dropdown from 'af-fields/Dropdown';
import DateInput from 'af-fields/DateInput';
import Input from 'af-fields/Input';

import { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';
import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';

import DropdownEmployeeItem from 'af-components/DropdownEmployeeItem';

import { formSectionClass, formSectionClassColumn2, formSectionClassColumn4, formSectionClassRow, formSectionTitleClass } from '../../helpers';
import styles from '../../styles.module.scss';

type Props = {
	findAllSuperintendentsAndProjectManagers: () => Promise<EmployeeOptionVM[]>;
	isDisabled: boolean;
};

const filterOption = (option: EmployeeOptionVM, rawInput: string) => {
	const { firstName, lastName, uniqueId } = option;
	return `${firstName} ${lastName} ${uniqueId}`.toLowerCase().includes(rawInput.toLowerCase());
};

const SurveyDataSection: React.FC<Props> = ({ findAllSuperintendentsAndProjectManagers, isDisabled }) => {
	const [superintendentsAndProjectManagers, setSuperintendentsAndProjectManagers] = React.useState<EmployeeOptionVM[]>([]);
	const lazyLoadSuperintendentsAndProjectManagers = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const fetchedSuperintendentsAndProjectManagers = await findAllSuperintendentsAndProjectManagers();
			setSuperintendentsAndProjectManagers(fetchedSuperintendentsAndProjectManagers ?? []);
		}
	}, [findAllSuperintendentsAndProjectManagers]);

	const renderSelectedSIorPM = React.useCallback((option: EmployeeOptionVM) => <DropdownEmployeeItem option={option} showUserRole={false} />, []);

	const renderSIAndPMMenuItem = React.useCallback((option: EmployeeOptionVM) => <DropdownEmployeeItem option={option} showUserRole={true} />, []);

	const checkboxStyle = React.useMemo(() => [formSectionClassColumn2, styles['job-form__tab-content__form-section__row__centered-column']].join(' '), []);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Survey Data
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="surveyNeeded"
						label="Survey Needed"
						name={getAttributeName('surveyNeeded')}

					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Dropdown}
						disabled={isDisabled}
						filterable={true}
						filterBy={filterOption}
						id="surveyedById"
						label="Surveyed By"
						name={getAttributeName('surveyedById')}
						onLazyLoad={lazyLoadSuperintendentsAndProjectManagers}
						options={superintendentsAndProjectManagers}
						placeholder="Select"
						propName="surveyedBy"
						renderMenuItem={renderSIAndPMMenuItem}
						renderSelected={renderSelectedSIorPM}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={DateInput}
						disabled={isDisabled}
						id="surveyDate"
						label="Survey Date"
						name={getAttributeName('surveyDate')}
						originalDateFormat={TimeFormat.DATE_ONLY}
						placeholderText={TimeFormat.DATE_ONLY}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="waterAvailable"
						label="Water Available"
						name={getAttributeName('waterAvailable')}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="waterSupplyInformation"
						label="Water Supply Information"
						name={getAttributeName('waterSupplyInformation')}
						placeholder="Enter Water Supply Information"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={checkboxStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="subcontractorNeeded"
						label="Subcontractor Needed"
						name={getAttributeName('subcontractorNeeded')}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="subcontractorInformation"
						label="Subcontractor Information"
						name={getAttributeName('subcontractorInformation')}
						placeholder="Enter Subcontractor Information"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="pipeSizes"
						label="Pipe Sizes"
						name={getAttributeName('pipeSizes')}
						placeholder="Enter Text"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="pipeTypes"
						label="Pipe Types"
						name={getAttributeName('pipeTypes')}
						placeholder="Enter Text"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="manholeDepths"
						label="Manhole Depths"
						name={getAttributeName('manholeDepths')}
						placeholder="Enter Text"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="manholeFrameDiameters"
						label="Manhole Frame Diameters"
						name={getAttributeName('manholeFrameDiameters')}
						placeholder="Enter Text"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn4}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="informationsProvidedByCustomersBy"
						label="How Will Information be Provided by Customer?"
						name={getAttributeName('informationsProvidedByCustomersBy')}
						placeholder="Enter Text"
						type="text"
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(SurveyDataSection);
