import { FormErrors } from 'redux-form';

import { NOTION_ORGANIZATIONS, NOTION_URL } from 'ab-constants/company';

import { EHSRM } from 'ab-requestModels/company.requestModel';

export function validate(form: EHSRM) {
	const { ehsUrl } = form;
	const errors: FormErrors<EHSRM> = {};

	if (!ehsUrl) {
		return errors;
	}

	// just for the lamda below, it thinks it can change and be null
	const possibleNotionOrganizationPrefixes = NOTION_ORGANIZATIONS.map((_organization) => `${NOTION_URL}/${_organization}/`);
	if (!possibleNotionOrganizationPrefixes.some((_prefix) => ehsUrl.startsWith(_prefix))) {
		errors.ehsUrl = 'Invalid EHS url';
	}
	return errors;
}
