import * as React from 'react';
import { Field } from 'redux-form';

import ConfirmationModal from 'af-components/ConfirmationModal';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';

import { useToggle } from 'af-utils/react.util';

import fieldHoc from '../Shared/fieldHoc';

const OTHER_OPTION = { id: null, label: 'Other' };

interface OwnProps {
	disabled: boolean;
	formName: string;
	name: string;
	options: string[];
	allowCustomValue: boolean;
	tooltipMessage?: React.ComponentProps<typeof Dropdown>['tooltipMessage'];
	initialValue: string;
	onFocus?: () => void;
	onValueChange: () => void;
	change: (fieldName: string, value: string) => void;
}

type Props = OwnProps;

type Option = { id: string; label: string; };

const mapStringToOption = (option: string) => ({ id: option, label: option });

const getOptions = (options: string[] = [], allowCustomValue: boolean = false, otherOption?: Nullable<Option>) => {
	const mappedOptions = options?.map(mapStringToOption) ?? [];
	if (!allowCustomValue) {
		return mappedOptions;
	}

	if (!otherOption || options.includes(otherOption.id)) {
		return [...mappedOptions, OTHER_OPTION];
	}

	return [...mappedOptions, OTHER_OPTION, otherOption];
};

const DropdownField: React.FC<Props> = (props) => {
	const {
		disabled,
		formName,
		name,
		tooltipMessage,
		initialValue,
		options,
		allowCustomValue,
		onValueChange,
		onFocus,
		change,
	} = props;

	const {
		value: showModal,
		setToTrue: openModal,
		setToFalse: closeModal,
	} = useToggle(false);

	const [value, setValue] = React.useState('');
	const [stateInitialValue, setStateInitialValue] = React.useState(initialValue);
	const [otherOption, setOtherOption] = React.useState<Nullable<Option>>(null);
	const [stateOptions, setStateOptions] = React.useState(getOptions(options, allowCustomValue));

	React.useEffect(() => {
		const _initialValue = (showModal || !initialValue) ? stateInitialValue : initialValue;
		const initialValueAsOption = !!_initialValue ? { id: _initialValue, label: `Other: ${_initialValue}` } : null;

		const selectedOtherOption = !!otherOption ? otherOption : initialValueAsOption;

		setStateInitialValue(_initialValue);
		setStateOptions(getOptions(options, allowCustomValue, selectedOtherOption));
	}, [showModal, initialValue, otherOption, options, allowCustomValue, stateInitialValue]);

	const onDropdownChange = React.useCallback((option: Option) => {
		if (option.id === OTHER_OPTION.id) {
			openModal();
		} else {
			onValueChange();
		}
	}, [openModal, onValueChange]);

	const save = React.useCallback(() => {
		onValueChange();
		closeModal();
	}, [onValueChange, closeModal]);

	const closeModalCallback = React.useCallback(() => {
		change(formName, stateInitialValue);
		setOtherOption(null);
		closeModal();
	}, [change, closeModal, formName, stateInitialValue]);

	const onInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const { value: newValue } = e.target;
		setValue(newValue);
		setOtherOption({ id: newValue, label: `Other: ${newValue}` });
	}, [setValue, setOtherOption]);

	const renderModalBody = React.useCallback(() => {
		return (
			<Field
				component={Input}
				name={formName}
				onChange={onInputChange}
				placeholder="Other"
				type="text"
			/>
		);
	}, [formName, onInputChange]);

	return (
		<>
			<Field
				className="field-report-block__field--other-dropdown"
				component={Dropdown}
				disabled={disabled}
				id={formName}
				label={name}
				labelKey="label"
				name={formName}
				onMenuOpen={onFocus}
				onValueChange={onDropdownChange}
				options={stateOptions}
				tooltipMessage={tooltipMessage}
				valueKey="id"
				withCaret={true}
			/>
			<ConfirmationModal
				body={renderModalBody()}
				closeModal={closeModalCallback}
				confirmAction={save}
				disabled={!value?.length}
				hideOnConfirm={false}
				modalStyle="info"
				showModal={showModal}
				size="md"
				title={name}
			/>
		</>
	);
};

export default fieldHoc(React.memo(DropdownField));
