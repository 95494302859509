import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { RootState } from 'af-reducers';

import * as ContactActions from 'af-actions/contacts';

import CLIENT from 'af-constants/routes/client';

import LockedValue from 'af-components/LockedValue';
import Breadcrumbs from 'af-components/Breadcrumbs';
import EmailPreview from 'af-components/ContactMethodPreview/EmailPreview';
import PhonePreview from 'af-components/ContactMethodPreview/PhonePreview';
import AddressRowPreview from 'af-components/ContactMethodPreview/AddressPreview';

import Loading from './Loading';
import NotificationHistoryTable from './NotificationHistoryTable';

import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal';

const NOTIFICATION_HISTORY_BREADCRUMBS = [{ label: 'Notification History' }];

interface PathParams {
	contactId: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

interface State {
	contact: Nullable<ContactVM>;
	showDeleteConfirmationModal: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

class Preview extends React.PureComponent<Props, State> {

	state: State = {
		contact: null,
		showDeleteConfirmationModal: false,
	};

	static mapEmails = (_email: ContactVM['emails'][0]) => (
		<Col key={_email.id} lg={6} md={8} sm={12} xs={24}>
			<EmailPreview showCopyToClipboard={true} type={_email.type} value={_email.value} />
		</Col>
	);

	static mapPhones = (_phone: ContactVM['phones'][0]) => (
		<Col key={_phone.id} lg={6} md={8} sm={16} xs={24}>
			<PhonePreview showCopyToClipboard={true} type={_phone.type} value={_phone.value} />
		</Col>
	);

	static mapAddress = (_address: ContactVM['addresses'][0]) => <AddressRowPreview address={_address.address} key={_address.addressId} />;

	static renderLabel = (label: string, className: string, padded: boolean = true) => {
		return (
			<Row className={padded ? 'row--padded-top' : ''}>
				<Col sm={24}>
					<div className={className}>
						{label}
					</div>
				</Col>
			</Row>
		);
	};

	static renderPhones = (phones: ContactVM['phones']) => {
		return (
			<>
				{Preview.renderLabel('Phone', 'locked-input__header', false)}
				<Row className="row--padded-bottom">{phones.map(Preview.mapPhones)}</Row>
			</>
		);
	};

	static renderEmails = (emails: ContactVM['emails']) => {
		return (
			<>
				{Preview.renderLabel('Email', 'locked-input__header', false)}
				<Row className="row--padded-bottom">{emails.map(Preview.mapEmails)}</Row>
			</>
		);
	};

	static renderAddresses = (addresses: ContactVM['addresses']) => {
		return (
			<>
				{Preview.renderLabel('Address', 'contact-preview__label', false)}
				{addresses.map(Preview.mapAddress)}
			</>
		);
	};

	async componentDidMount() {
		const { findById, match: { params: { contactId } } } = this.props;

		const contact = await findById(+contactId);
		if (!contact) {
			throw new Error('Contact not found');
		}

		this.setState(() => ({ contact }));
	}

	softDelete = async () => {
		const {
			softDelete,
			match: { params: { contactId } },
			location: { state: { orgAlias } },
			history: { push },
			companyName,
		} = this.props;

		await softDelete(+contactId);
		push(CLIENT.COMPANY.CONTACTS.LIST(orgAlias, companyName));
	};

	openDeleteConfirmationModal = () => {
		this.setState(() => ({ showDeleteConfirmationModal: true }));
	};

	closeDeleteConfirmationModal = () => {
		this.setState(() => ({ showDeleteConfirmationModal: false }));
	};

	edit = () => {
		const {
			match: { params: { contactId } },
			location: { state: { orgAlias } },
			history: { push },
			companyName,
		} = this.props;

		push(CLIENT.COMPANY.CONTACTS.EDIT(orgAlias, companyName, contactId));
	};

	fetchHistoryTable = async (tableRequest: TableQuery) => {
		const { match: { params: { contactId } }, findHistoryTable } = this.props;
		return await findHistoryTable(+contactId, tableRequest);
	};

	render() {
		const { location: { state: { orgAlias } }, companyName, hasEditPermission } = this.props;
		const { contact, showDeleteConfirmationModal } = this.state;

		if (!contact) {
			return <Loading />;
		}

		const breadcrumbs = [
			{ label: 'Contacts', url: CLIENT.COMPANY.CONTACTS.LIST(orgAlias, companyName) },
			{ label: 'Details' },
		];

		return (
			<div className="form-segment">
				<Breadcrumbs items={breadcrumbs} />
				<div className="form-box contact-preview">
					<Row className="row--padded">
						<Col md={24}>
							<span className="contact-preview__user">
								{contact.fullName}
							</span>
						</Col>
					</Row>
					<Row className="row--padded-bottom">
						<Col md={6}><LockedValue defaultValue="N/A" label="Title" value={contact.title ?? null} /></Col>
						<Col md={6}><LockedValue defaultValue="N/A" label="Company Name" value={contact.companyName ?? null} /></Col>
						<Col md={12} />
					</Row>
					{!!contact.emails.length && Preview.renderEmails(contact.emails)}
					{!!contact.phones.length && Preview.renderPhones(contact.phones)}
					{!!contact.addresses.length && Preview.renderAddresses(contact.addresses)}
					{hasEditPermission && <div className="contact-preview__actions">
						<Button onClick={this.openDeleteConfirmationModal} variant="info">
							Delete
						</Button>
						<Button onClick={this.edit} variant="info">
							Edit
						</Button>
					</div>}
				</div>
				<Breadcrumbs items={NOTIFICATION_HISTORY_BREADCRUMBS} />
				<NotificationHistoryTable fetchTable={this.fetchHistoryTable} />
				<DeleteConfirmationModal
					closeModal={this.closeDeleteConfirmationModal}
					contactName={contact.fullName}
					onDelete={this.softDelete}
					showModal={showDeleteConfirmationModal}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}
	return {
		companyName: companyData.name,
		hasEditPermission: isAllowed(PagePermissions.COMPANY.CONTACTS.MANAGE, companyData.permissions, companyData.isCompanyAdmin, userData.role),
	};
}

function mapDispatchToProps() {
	return {
		findById: ContactActions.findById,
		softDelete: ContactActions.softDeleteContact,
		findHistoryTable: ContactActions.findNotificationHistory,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(Preview);
