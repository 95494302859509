import * as React from 'react';

import styles from './styles.module.scss';

type OwnProps = {
	text: string;
	className?: string;
};

type Props = OwnProps;

const Label: React.FC<Props> = (props) => {
	const {
		text,
		className,
	} = props;

	return (
		<div className={styles[(!!className ? `label__${className}` : 'label')]}>
			{text}
		</div>
	);
};

export default React.memo(Label);
