import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import AgencyVM from 'ab-viewModels/agency/agency.viewModel';
import TemporaryEmployeeNameAvailableVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeNameAvailable.viewModel';

import { TemporaryEmployeeNameAvailableRM } from 'ab-requestModels/temporaryEmployee.requestModel';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';

import { debounce } from 'af-utils/actions.util';

import LabelWithColor from 'af-components/LabelWithColor';

import { CreateTemporaryEmployeeForm } from './createTemporaryEmployeeAssignmentForm';

interface OwnProps {
	agencies: AgencyVM[];
	initialValues: Nullable<CreateTemporaryEmployeeForm>;
	isNameAvailable: (data: TemporaryEmployeeNameAvailableRM) => Promise<TemporaryEmployeeNameAvailableVM>;
	lazyLoadAgencies: (isLazyLoaded: boolean) => Promise<void>;
	onClearAgency: () => void;
	onClose: () => void;
	propName: string;
}

type Props = OwnProps;

const VALIDATION_DELAY = 400;
const FILTER_AGENCY_BY = ['name', 'email', 'phone', 'website'];
const DUPLICATE_ERROR = 'A user with this name in this agency already exists. A new one will be created.';

const renderAgencyItem = (option: AgencyVM) => <LabelWithColor color={option.color} text={<strong>{option.name}</strong>} />;

const CreateTemporaryEmployeeForm: React.FC<Props> = (props: Props) => {
	const { agencies, propName, lazyLoadAgencies, onClearAgency, onClose, isNameAvailable, initialValues } = props;

	const [error, setError] = React.useState<Nullable<string>>(null);
	const [firstName, setFirstName] = React.useState<Nullable<string>>(initialValues?.firstName ?? null);
	const [lastName, setLastName] = React.useState<Nullable<string>>(initialValues?.lastName ?? null);

	const validateOnChange = React.useMemo(
		() => debounce(async (fieldName: string, inputValue: string) => {
			let _firstName = firstName;
			let _lastName = lastName;
			if (fieldName === 'firstName') {
				_firstName = inputValue;
				setFirstName(inputValue);
			}
			if (fieldName === 'lastName') {
				_lastName = inputValue;
				setLastName(inputValue);
			}
			if (_firstName && _lastName) {
				const { available } = await isNameAvailable({ firstName: _firstName, lastName: _lastName });
				if (!available && !error) {
					setError(DUPLICATE_ERROR);
				} else if (available && error) {
					setError(null);
				}
			}
		}, VALIDATION_DELAY), [error, isNameAvailable, firstName, lastName]);

	return (
		<>
			<div className="temp-labor-assign-modal__create-employee-container">
				<Row className="temp-labor-assign-modal__create-employee-container__header">
					<span className="icon-close temp-labor-assign-modal__create-employee-container__close" onClick={onClose} />
				</Row>
				<Row>
					<Col sm={12}>
						<Field
							component={Input}
							label="First Name *"
							name={`${propName}.firstName`}
							onValueChange={validateOnChange.bind(this, 'firstName')}
							placeholder="Enter First Name *"
							type="text"
						/>
					</Col>
					<Col sm={12}>
						<Field
							autoFocus={true}
							component={Input}
							label="Last Name *"
							name={`${propName}.lastName`}
							onValueChange={validateOnChange.bind(this, 'lastName')}
							placeholder="Enter Last Name *"
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<Field
							className="temp-labor-assign-modal__address"
							component={Dropdown}
							filterable={true}
							filterBy={FILTER_AGENCY_BY}
							label="Agency *"
							name={`${propName}.agencyId`}
							onClear={onClearAgency}
							onLazyLoad={lazyLoadAgencies}
							options={agencies}
							placeholder="Select Agency"
							renderMenuItem={renderAgencyItem}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
			</div>
			{error &&
				<div className="temp-labor-assign-modal__warning">
					{error}
				</div>
			}
		</>
	);
};

export default CreateTemporaryEmployeeForm;
