import { createStore, applyMiddleware, Store, compose, AnyAction } from 'redux';
import { formValueSelector } from 'redux-form';
import reduxThunk from 'redux-thunk';
import * as LogRocket from 'logrocket';

import rootReducer, { RootState } from 'af-reducers';

import { USER_EMAIL_LOGIN, USER_RESET_PASSWORD, USER_FINALIZE, ACCOUNT_EDIT } from 'af-constants/reduxForms';

type FromActionType = {
	meta: {
		form: string;
	};
};

export function configureStore(): Store<RootState, AnyAction> {
	return AppStore.getStore();
}

export default class AppStore {

	private static store: Store<RootState, AnyAction> | undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static initStore(redirect: any) {
		const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose;

		const middlewareList = [
			reduxThunk.withExtraArgument({
				redirectTo: redirect,
				formSelector: (formName, state, field) => formValueSelector(formName)(state, field),
			}),
		];

		if (!!process.env.LOG_ROCKET_APP_ID) {
			middlewareList.push(
				LogRocket.reduxMiddleware({
					actionSanitizer: (action: FromActionType) => {
						const formName = action?.meta?.form;
						switch (formName) {
							case USER_FINALIZE:
							case USER_EMAIL_LOGIN:
							case USER_RESET_PASSWORD:
							case ACCOUNT_EDIT:
								return null;
							default:
								return action;
						}
					},
					stateSanitizer: (state: RootState) => ({
						...state,
						form: {
							...state.form,
							[USER_EMAIL_LOGIN]: undefined,
							[USER_FINALIZE]: undefined,
							[USER_RESET_PASSWORD]: undefined,
							[ACCOUNT_EDIT]: undefined,
						},
					}),
				})
			);
		}

		const store = createStore<RootState, AnyAction, unknown, unknown>(rootReducer, composeEnhancers(
			applyMiddleware(
				...middlewareList
			)
		));

		if (module.hot) {
			module.hot.accept('af-reducers', () => { store.replaceReducer(rootReducer); });
		}

		AppStore.store = store;
	}

	/**
	 * Must call init store before this beacause the redirect function needs to be passed to the store instance.
	 */
	static getStore(): Store<RootState, AnyAction> {
		if (!AppStore.store) throw new Error('Redux store not initialized.');
		return AppStore.store;
	}

}
