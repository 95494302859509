import * as React from 'react';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, DispatchActionsMapped } from 'react-redux';
import { InjectedFormProps, reduxForm, FieldArray, Form } from 'redux-form';
import { StaticContext } from 'react-router';

import { toMomentUtc, normalizeDateToMoment, formatDate } from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as GeneralActions from 'af-actions/general';

import ScheduleBoardMetricsRequestModel from 'ab-requestModels/scheduleBoardMetrics.requestModel';

import { ScheduleBoardDailyMetricsRequestModels } from 'ab-socketModels/requestModels/scheduleBoard/dailyMetricsLock.requestModel';

import { ScheduleBoardOnDateViewModel } from 'ab-viewModels/scheduleBoardWorkOrdersOnDateView.viewModel';
import ScheduleBoardViewModel from 'ab-viewModels/scheduleBoard.viewModel';

import { ScheduleBoardDailyMetricsLockViewModels } from 'ab-socketModels/viewModels/scheduleBoard/dailyMetricsLock.viewModel';

import { UPDATE_DAILY_VIEW_METRICS } from 'af-constants/reduxForms';
import * as SettingsKeys from 'af-constants/settingsKeys';
import CLIENT from 'af-constants/routes/client';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import SocketEvent from 'ab-enums/socketEvent.enum';

import socket from 'af-utils/socket.util';
import { withSettings } from 'af-utils/settings.util';
import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import WorkOrdersMetrics, { OwnProps as WorkOrderMetricsOwnProps } from './WorkOrdersMetrics';
import LockedBanner from './LockedBanner';
import ValidationBanner from './ValidationBanner';
import ConfirmTakeOverModal from './ConfirmTakeOverModal';
import UserKickedModal from './UserKickedModal';
import { fromVMtoRM } from './formModel';

type MomentType = ReturnType<typeof normalizeDateToMoment>;

interface LocationStateParams {
	orgAlias: string;
	originUrl: string;
	fromWeeklyView?: boolean;
}

interface SettingProps {
	selectedDate: MomentType;
}

interface StateSelector {
	getScheduleBoardOnDate: () => ScheduleBoardOnDateViewModel | undefined;
}

interface StateProps {
	companyName: string;
	dueDate: string;
	shouldLoadResources: boolean;
	stateSelector: StateSelector;
}

interface DispatchProps {
	scheduleBoardActions: typeof ScheduleBoardActions;
	generalActions: typeof GeneralActions;
}

type OwnProps = CustomRouteComponentProps<void, StaticContext, LocationStateParams>;
type ConnectOwnProps = OwnProps & SettingProps;
type FormOwnProps = ConnectOwnProps & StateProps & DispatchActionsMapped<DispatchProps>;
type Props = FormOwnProps & InjectedFormProps<ScheduleBoardMetricsRequestModel, FormOwnProps>;

interface State {
	areDailyMetricsLocked: boolean;
	showTakeOverModal: boolean;
	showUserKickedModal: boolean;
	lockedBy: Nullable<string>;
}

class DailyMetrics extends React.Component<Props, State> {

	state: State = {
		areDailyMetricsLocked: false,
		showTakeOverModal: false,
		showUserKickedModal: false,
		lockedBy: null,
	};

	componentDidMount() {
		const { generalActions, scheduleBoardActions } = this.props;

		socket?.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ALREADY_LOCKED_DAILY_METRICS,
			({ dueDate, lockedBy, data }: ScheduleBoardDailyMetricsLockViewModels.AlreadyLockedDailyMetrics) => {
				// locking failed, set the lock data and initialize the current state into the form
				if (dueDate === this.props.dueDate) {
					this.setState(() => ({ areDailyMetricsLocked: true, lockedBy }), () => {
						// refresh data after lock trial in case data is stale or cleared after refresh
						this.reinitialize(data);
					});
				}
			}
		);

		socket?.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UNLOCK_DAILY_METRICS,
			({ dueDate }: ScheduleBoardDailyMetricsLockViewModels.UnlockDailyMetrics) => {
				// if user is viewing a locked page and receives an unlock event, they will automatically try to lock it for themselves
				if (dueDate === this.props.dueDate && this.state.areDailyMetricsLocked) {
					this.lockDailyMetrics(true);
				}
			}
		);

		socket?.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.LOCK_DAILY_METRICS,
			({ dueDate, lockedBy }: ScheduleBoardDailyMetricsLockViewModels.LockDailyMetrics) => {
				// someone else just locked the board, update lock info
				if (dueDate === this.props.dueDate) {
					this.setState(() => ({ areDailyMetricsLocked: true, lockedBy }));
				}
			}
		);

		socket?.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.TAKE_OVER_DAILY_METRICS,
			({ dueDate, lockedBy }: ScheduleBoardDailyMetricsLockViewModels.TakeOverDailyMetrics) => {
				// someone else just took over (force locked) the board, kick current user if metric were unlocked
				if (dueDate === this.props.dueDate) {
					const wasUnlockedForUser = !this.state.areDailyMetricsLocked;
					this.setState(() => ({ areDailyMetricsLocked: true, lockedBy }), () => {
						if (wasUnlockedForUser) {
							this.openUserKickedModal();
						}
					});
				}
			}
		);

		ScheduleBoardUtil.subscribeDailySocketEvents(generalActions, scheduleBoardActions);

		this.joinRoomOnDueDate();
		this.lockDailyMetrics(true);
	}

	componentDidUpdate(prevProps: Props) {
		const { dueDate } = this.props;

		if (prevProps.dueDate !== dueDate && socket?.connection?.isConnected()) {
			this.removeDailyMetricsLock(prevProps.dueDate);
			this.joinRoomOnDueDate();
			this.lockDailyMetrics(true);
		} else if (ScheduleBoardUtil.shouldLoadResourcesOnUpdate(prevProps, this.props)) {
			ScheduleBoardUtil.loadResources();
		}
	}

	componentWillUnmount() {
		const { dueDate } = this.props;

		this.removeDailyMetricsLock(dueDate, true);

		if (socket.connection) {
			socket.connection.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ALREADY_LOCKED_DAILY_METRICS);
			socket.connection.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UNLOCK_DAILY_METRICS);
			socket.connection.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.LOCK_DAILY_METRICS);
			socket.connection.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.TAKE_OVER_DAILY_METRICS);
		}
	}

	joinRoomOnDueDate = () => {
		const { dueDate, shouldLoadResources: loadResources, scheduleBoardActions } = this.props;
		if (!dueDate) {
			return;
		}
		if (loadResources) {
			ScheduleBoardUtil.loadResources();
		}
		const loadedDates = scheduleBoardActions.getLoadedDates();
		if (loadedDates[dueDate]) {
			ScheduleBoardUtil.activateDailyView(dueDate, loadedDates);
		} else {
			ScheduleBoardUtil.joinDailyView(dueDate, loadedDates);
		}
	};

	/**
	 * Tries to re-initialize form with `data`.
	 *
	 * If `data` does not exist or does not contain current `dueDate`,
	 * will fallback to initializing from current SB redux state
	 */
	reinitialize = (data?: ScheduleBoardViewModel) => {
		const { dueDate, stateSelector, initialize } = this.props;
		const newScheduleBoardOnDate = data?.workOrdersByDateDictionary?.[dueDate] ?? stateSelector.getScheduleBoardOnDate();
		newScheduleBoardOnDate && initialize(fromVMtoRM(newScheduleBoardOnDate));
	};

	/**
	 * Ack callback for:
	 *  - `SocketEvent.V10.FE.SCHEDULE_BOARD.ADD_DAILY_METRICS_LOCK`
	 *  - `SocketEvent.V10.FE.SCHEDULE_BOARD.TAKE_OVER_DAILY_METRICS`
	 *
	 * Triggered only if locking was a success (took over editing)
	 */
	onSuccessfulTakeover = (data?: ScheduleBoardViewModel) => {
		this.reinitialize(data);
		this.setState(() => ({ areDailyMetricsLocked: false, lockedBy: null }));
	};

	/**
	 * @param refreshData if true and locking was a success, will set state and reinitialize the form
	 */
	lockDailyMetrics = (refreshData: boolean = false) => {
		const { dueDate } = this.props;
		const dailyMetricsLockRequest: ScheduleBoardDailyMetricsRequestModels.AddDailyMetricsLock = { dueDate, refreshData };
		socket?.connection?.emitWithPromise(
			SocketEvent.V2.FE.SCHEDULE_BOARD.ADD_DAILY_METRICS_LOCK,
			{ cb: this.onSuccessfulTakeover },
			dailyMetricsLockRequest
		);
	};

	takeOverDailyMetrics = () => {
		const { dueDate } = this.props;
		const dailyMetricsLockRequest: ScheduleBoardDailyMetricsRequestModels.TakeOverDailyMetrics = { dueDate, refreshData: true };
		socket?.connection?.emitWithPromise(
			SocketEvent.V2.FE.SCHEDULE_BOARD.TAKE_OVER_DAILY_METRICS,
			{ cb: this.onSuccessfulTakeover },
			dailyMetricsLockRequest
		);
	};

	removeDailyMetricsLock = (dueDate: string, skipAck: boolean = false) => {
		const dailyMetricsLockRequest: ScheduleBoardDailyMetricsRequestModels.RemoveDailyMetricsLock = { dueDate };
		const cb = () => {
			if (skipAck) {
				return;
			}
			this.setState(() => ({ areDailyMetricsLocked: false, lockedBy: null }));
		};
		socket?.connection?.emitWithPromise(SocketEvent.V2.FE.SCHEDULE_BOARD.REMOVE_DAILY_METRICS_LOCK, { cb }, dailyMetricsLockRequest);
	};

	clearWeaklyViewOnLeaveIfNeeded = () => {
		const { scheduleBoardActions, location: { state: { fromWeeklyView } } } = this.props;
		// if we got here from weekly view
		// clear redux state in order to have proper loading on weekly view
		if (fromWeeklyView) {
			scheduleBoardActions.clearWeeklyView();
		}
	};

	onSubmit = async (form: ScheduleBoardMetricsRequestModel) => {
		const { scheduleBoardActions } = this.props;

		const { areDailyMetricsLocked } = this.state;
		if (areDailyMetricsLocked) {
			return;
		}

		await scheduleBoardActions.saveWorkOrdersMetrics(form);
		this.goBackToWorkOrders();
	};

	goBackToWorkOrders = () => {
		const { history, companyName, location: { state: { orgAlias, originUrl } } } = this.props;
		const pathname = originUrl || CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName);
		this.clearWeaklyViewOnLeaveIfNeeded();
		history.push({ pathname });
	};

	openTakeOverModal = () => this.setState(() => ({ showTakeOverModal: true }));
	closeTakeOverModal = () => this.setState(() => ({ showTakeOverModal: false }));

	openUserKickedModal = () => this.setState(() => ({ showUserKickedModal: true }));
	closeUserKickedModal = () => this.setState(() => ({ showUserKickedModal: false }));

	render() {
		const { handleSubmit, error } = this.props;
		const { areDailyMetricsLocked, lockedBy, showTakeOverModal, showUserKickedModal } = this.state;
		let className = 'schedule-board metrics zoom-2';
		className += areDailyMetricsLocked || error ? ' with-banner' : '';
		className += areDailyMetricsLocked ? ' locked' : '';
		className += error ? ' validation' : '';

		return (
			<Form className={className} onSubmit={handleSubmit(this.onSubmit)}>
				{error && <ValidationBanner errorColumns={error} />}
				{areDailyMetricsLocked && <LockedBanner lockedBy={lockedBy} onUnlockClick={this.openTakeOverModal} />}
				<FieldArray<WorkOrderMetricsOwnProps>
					areDailyMetricsLocked={areDailyMetricsLocked}
					component={WorkOrdersMetrics}
					name="workOrders"
					rerenderOnEveryChange={true}
				/>
				<ConfirmTakeOverModal
					closeModal={this.closeTakeOverModal}
					showModal={showTakeOverModal}
					takeOverDailyMetrics={this.takeOverDailyMetrics}
				/>
				<UserKickedModal
					closeModal={this.closeUserKickedModal}
					goBackToWorkOrders={this.goBackToWorkOrders}
					lockedBy={lockedBy}
					showModal={showUserKickedModal}
				/>
			</Form>
		);
	}
}

const stateSelector: StateSelector = {
	getScheduleBoardOnDate: () => undefined,
};

function mapStateToProps(state: RootState, ownProps: SettingProps): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	const { date: dueDate, workOrdersByDateDictionary } = state.scheduleBoard;
	const scheduleBoardOnDate = !!dueDate
		? workOrdersByDateDictionary?.[dueDate]
		: undefined;
	const shouldLoadResources = !state.scheduleBoard.resourcesLoaded;

	stateSelector.getScheduleBoardOnDate = () => scheduleBoardOnDate;

	const _dueDate = state.scheduleBoard.date ?? (ownProps.selectedDate && formatDate(ownProps.selectedDate));
	if (!_dueDate) {
		throw new Error('Due date not provided');
	}

	return {
		companyName: companyData.name,
		dueDate: _dueDate,
		shouldLoadResources,
		stateSelector,
	};
}

function mapDispatchToProps(dispatch: Dispatch<GeneralActions.GeneralAction | ScheduleBoardActions.ScheduleBoardAction>): DispatchProps {
	return {
		generalActions: bindActionCreators(GeneralActions, dispatch),
		scheduleBoardActions: bindActionCreators(ScheduleBoardActions, dispatch),
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDER_SELECTED_DUE_DATE(),
			mappedName: 'selectedDate',
			normalize: normalizeDateToMoment,
			defaultValue: toMomentUtc(new Date()),
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
	])),
	connect<StateProps, DispatchProps, ConnectOwnProps>(mapStateToProps, mapDispatchToProps),
	reduxForm<ScheduleBoardMetricsRequestModel, FormOwnProps>({
		form: UPDATE_DAILY_VIEW_METRICS,
		enableReinitialize: false,	// otherwise a state change on work orders (e.g. reordering) would cause a reset
	})
);

export default enhance(DailyMetrics);
