import * as React from 'react';

class DraggableLoadingPlaceholder extends React.PureComponent {
	render() {
		return (
			<div className="sb-resource-item sb-resource-item--placeholder">
				<div className="loading-col-24 loading-box-row dark" />
			</div>
		);
	}
}

export default DraggableLoadingPlaceholder;
