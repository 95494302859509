import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import { EmployeeRM } from 'ab-requestModels/employee/update.requestModel';

import { EMPLOYEE_EDIT } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import { RootState } from 'af-reducers';

import Breadcrumbs from 'af-components/Breadcrumbs';

import * as EmployeeActions from 'af-actions/employee';

import EditEmployeeForm from './EmployeeForm';
import { validate } from './validation';
import { convertStatusId } from './helpers';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<EmployeeRM>;

class Edit extends React.PureComponent<Props> {

	async componentDidMount() {
		const { findById, match: { params: { id } }, initialize } = this.props;
		const employee = await findById(id);

		if (!employee) {
			throw new Error('Employee not found');
		}

		initialize(employee);
	}

	editEmployee = async (form: EmployeeRM) => {
		form.employeeStatusId = convertStatusId(form.employeeStatusId);
		const {
			update,
			location: { state: { orgAlias } },
			match: { params: { id: employeeId } },
			companyData,
			history,
		} = this.props;
		await update(form);
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.PREVIEW(employeeId, orgAlias, companyData.name));
	};

	render() {
		const {
			location: { state: { orgAlias } },
			match: { params: { id: employeeId } },
			companyData,
		} = this.props;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={[
						{ label: 'Labor', url: CLIENT.COMPANY.RESOURCES.EMPLOYEE.LIST(orgAlias, companyData.name) },
						{ label: 'Preview', url: CLIENT.COMPANY.RESOURCES.EMPLOYEE.PREVIEW(employeeId, orgAlias, companyData.name) },
						{ label: 'Edit' },
					]}
				/>
				<EditEmployeeForm
					{...this.props}
					companyName={companyData.name}
					onSubmit={this.editEmployee}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
	};
}

function mapDispatchToProps() {
	return {
		findById: EmployeeActions.findById,
		update: EmployeeActions.update,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm({ form: EMPLOYEE_EDIT, validate })
);
export default enhance(Edit);
