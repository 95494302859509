import * as React from 'react';
import { Field, formValueSelector } from 'redux-form';
import { ConnectedProps, connect } from 'react-redux';

import DeliverablesSharedForm from 'af-components/SharedForms/Job/Details/Deliverables';

import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';

import Checkbox from 'af-fields/Checkbox';

import { RootState } from 'af-reducers';

import { formSectionClass, formSectionTitleClass, formSectionClassRow } from '../../helpers';

import styles from '../../styles.module.scss';

type OwnProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
	formName: string;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const DeliverableSection: React.FC<Props> = (props) => {
	const { change, isDeliverable } = props;

	const onIsDeliverableClick = React.useCallback(() => {
		change(getAttributeName('deliverableAssigneeId'), null);
		change(getAttributeName('deliverableAssignee'), null);
		change(getAttributeName('deliverableFileFormatId'), null);
		change(getAttributeName('deliverableFileFormat'), null);
		change(getAttributeName('deliverableSoftwareId'), null);
		change(getAttributeName('deliverableSoftware'), null);
		change(getAttributeName('deliverableCodeStandardId'), null);
		change(getAttributeName('deliverableCodeStandard'), null);
		change(getAttributeName('deliverableDeliveryTimelineId'), null);
		change(getAttributeName('deliverableDeliveryTimeline'), null);
		change(getAttributeName('deliverableDeliveryMethodId'), null);
		change(getAttributeName('deliverableDeliveryMethod'), null);
		change(getAttributeName('deliverableNotes'), null);
	}, [change]);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Deliverables
			</div>
			<div className={[formSectionClassRow, styles['work-request-form__deliverables']].join(' ')}>
				<Field
					component={Checkbox}
					id="isDeliverable"
					label="Is Deliverable"
					name={getAttributeName('isDeliverable')}
					onValueChange={onIsDeliverableClick}
				/>
			</div>
			{isDeliverable &&
				<DeliverablesSharedForm />}
		</div>
	);
};

function mapStateToProps(state: RootState, props: OwnProps) {
	const { formName } = props;
	const selector = formValueSelector(formName);

	const isDeliverable = selector(
		state,
		getAttributeName('isDeliverable')
	);

	return {
		isDeliverable,
	};
}

const connector = connect(mapStateToProps);

export default connector(DeliverableSection);
