import * as ActionTypes from 'af-constants/actionTypes';

import { OrganizationAction } from 'af-actions/organizations';

import * as Organization from 'af-models/organization.models';

const initialState: Organization.OrganizationStoreState = {
	publicDetails: null,
	multiplePublicDetails: null,
};

export default function CompanyReducer(state: Organization.OrganizationStoreState = initialState, action: OrganizationAction) {
	switch (action.type) {
		case ActionTypes.ORGANIZATION_PUBLIC:
			return { ...state, publicDetails: action.payload };
		case ActionTypes.MULTIPLE_ORGANIZATION_PUBLIC:
			return { ...state, multiplePublicDetails: action.payload };
		default:
			return state;
	}
}
