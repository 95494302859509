import * as React from 'react';
import enUs from 'date-fns/locale/en-US';
import DatePicker from 'react-datepicker';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import { datePickerFormat } from 'acceligent-shared/utils/time';

import { RectangleButton } from 'af-components/MultipleOptionsButton/RectangleButton';

interface OwnProps {
	selected: Date;
	onChange: (date: Date) => void;
}

type Props = OwnProps;

class DailyPicker extends React.PureComponent<Props> {

	private _datepicker: Nullable<DatePicker> = null;

	onSelect = (date: Date) => {
		const { onChange } = this.props;
		onChange(date);
	};

	setDatepickerRef = (datepicker: DatePicker) => this._datepicker = datepicker;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	focusDatePicker = () => (this._datepicker as any).input.focus();

	render() {
		const { selected } = this.props;
		return (
			<div className="table-filter__date">
				<DatePicker
					className="form-control"
					dateFormat={datePickerFormat(TimeFormatEnum.DATE_ONLY)}
					dropdownMode="select"
					locale={enUs}
					onChange={this.onSelect}
					ref={this.setDatepickerRef}
					selected={selected}
					showMonthDropdown={true}
					showYearDropdown={true}
					todayButton="Today"
				/>
				<RectangleButton
					action={this.focusDatePicker}
					isLeftOpen={true}
					isSquare={true}
					label={<span className="icon-calendar" />}
				/>
			</div>
		);
	}
}

export default DailyPicker;
