import * as React from 'react';

import AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';

import { EmployeeOptionVM } from 'ab-viewModels/employee/extendedOption.viewModel';
import EquipmentViewModel from 'ab-viewModels/equipment.viewModel';
import TemporaryEmployeeOptionVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';

import WorkOrderFM from '../../formModel';

import LaborItem from './Labor/LaborItem';
import EquipmentItem from './Equipment/EquipmentItem';
import EquipmentPlaceholderItem from './Equipment/EquipmentPlaceholderItem';
import LaborPlaceholderItem from './Labor/LaborPlaceholderItem';
import TemporaryLaborItem from './Temporarylabor/TemporaryLaborItem';

interface Props {
	disabled: boolean;
	dueDate: string;
	employees: EmployeeOptionVM[];
	equipment: EquipmentViewModel[];
	temporaryEmployees: TemporaryEmployeeOptionVM[];
	index: number;
	loadEmployees: (isLazyLoaded: boolean) => void;
	loadEquipment: (isLazyLoaded: boolean) => void;
	loadTemporaryEmployees: (isLazyLoaded: boolean) => void;
	resetTimer: () => void;
	resourceLookup: WorkOrderFM['workOrderResourceLookups'][0];
	resourceLookupPropName: string;
	showResourceAssignConfirmationModal: (resourceType: AssignableResourceType) => void;
}

export default class ResourceLookupItem extends React.PureComponent<Props> {

	render() {
		const {
			disabled,
			dueDate,
			employees,
			equipment,
			loadEmployees,
			loadEquipment,
			loadTemporaryEmployees,
			resetTimer,
			resourceLookup,
			resourceLookupPropName,
			temporaryEmployees,
			showResourceAssignConfirmationModal,
		} = this.props;

		if (resourceLookup.employeeId === null || resourceLookup.employeeId) {
			return (
				<LaborItem
					disabled={disabled}
					dueDate={dueDate}
					employees={employees}
					name={`${resourceLookupPropName}.employeeId`}
					onLazyLoad={loadEmployees}
					propName={`${resourceLookupPropName}.employee`}
					resetTimer={resetTimer}
					showResourceAssignConfirmationModal={showResourceAssignConfirmationModal}
				/>
			);
		} else if (resourceLookup.equipmentId === null || resourceLookup.equipmentId) {
			return (
				<EquipmentItem
					disabled={disabled}
					dueDate={dueDate}
					equipment={equipment}
					name={`${resourceLookupPropName}.equipmentId`}
					onLazyLoad={loadEquipment}
					propName={`${resourceLookupPropName}.equipment`}
					resetTimer={resetTimer}
					showResourceAssignConfirmationModal={showResourceAssignConfirmationModal}
				/>
			);
		} else if (resourceLookup.wageRateId === null || resourceLookup.wageRateId) {
			return (
				<LaborPlaceholderItem
					disabled={disabled}
					name={`${resourceLookupPropName}.wageRateId`}
					propName={`${resourceLookupPropName}.wageRate`}
					resetTimer={resetTimer}
					skillPropName={`${resourceLookupPropName}.skills`}
				/>
			);
		} else if (resourceLookup.equipmentCostId === null || resourceLookup.equipmentCostId) {
			return (
				<EquipmentPlaceholderItem
					disabled={disabled}
					isModal={false}
					name={`${resourceLookupPropName}.equipmentCostId`}
					propName={`${resourceLookupPropName}.equipmentCost`}
					resetTimer={resetTimer}
				/>
			);
		} else if (resourceLookup.temporaryEmployeeId === null || resourceLookup.temporaryEmployeeId) {
			return (
				<TemporaryLaborItem
					disabled={disabled}
					dueDate={dueDate}
					name={`${resourceLookupPropName}.temporaryEmployeeId`}
					onLazyLoad={loadTemporaryEmployees}
					propName={`${resourceLookupPropName}.temporaryEmployee`}
					resetTimer={resetTimer}
					showResourceAssignConfirmationModal={showResourceAssignConfirmationModal}
					temporaryEmployees={temporaryEmployees}
				/>
			);
		} else {
			return null;
		}

	}

}
