import * as React from 'react';

import * as TimeUtils from 'acceligent-shared/utils/time';

interface OwnProps {
	timeZoneInUse: Nullable<string>;
	changeTimeZone: () => void;
}

type Props = OwnProps;

const TimeZoneBanner: React.FC<Props> = (props: Props) => {
	const { timeZoneInUse, changeTimeZone } = props;

	const timeZoneText = timeZoneInUse
		? 'All Start and End times are shown in the work time zone'
		: 'All Start and End times are shown in your local time zone';

	return (
		<div className="time-sheet-list__time-zone">
			<div className="time-sheet-list__time-zone__banner">
				<span className="icon-clock" />
				{timeZoneText}: <b>{TimeUtils.abbreviateTimeZone(timeZoneInUse, 'z (Z)')}</b>
			</div>
			<div className="time-sheet-list__time-zone__swithc">
				<span className="btn--link" onClick={changeTimeZone}>Change</span>
			</div>
		</div>
	);
};

export default React.memo(TimeZoneBanner);
