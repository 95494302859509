import { TimeOptionKeys, TimeOptionValues } from 'acceligent-shared/enums/timeOption';

import * as TimeUtils from 'acceligent-shared/utils/time';
import * as TimeOptionUtils from 'acceligent-shared/utils/timeOption';

export function toValueString(time: Nullable<number>): string {
	return TimeOptionValues[TimeOptionUtils.toString(time)];
}

export function optionToUtcOption(time: number, timezone: string) {
	const isDST = TimeUtils.isDST(timezone);
	const utcOffset = TimeUtils.getOffset(timezone);
	return time - utcOffset + (isDST ? 60 : 0);
}

export function utcOptionToOption(time: number, timezone: string) {
	const isDST = TimeUtils.isDST(timezone);
	const utcOffset = TimeUtils.getOffset(timezone);
	return time + utcOffset - (isDST ? 60 : 0);
}

export const enumWithoutNullValue = () => {
	const nullValueIndex = Object.keys(TimeOptionValues).findIndex((_option) => _option === TimeOptionKeys.TBD);
	return [
		...Object.keys(TimeOptionValues).slice(0, nullValueIndex).map(toTimeOption),
		...Object.keys(TimeOptionValues).slice(nullValueIndex + 1).map(toTimeOption),
	];
};

export const toTimeOption = (key: string) => ({ id: key, name: TimeOptionValues[key] });
