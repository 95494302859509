import { Reducer } from 'redux';

import { FieldReportAction } from 'af-actions/fieldReport';

import * as actionTypes from 'af-constants/actionTypes';

import { FieldReportStoreState } from 'af-models/fieldReport.models';

import { join } from 'af-utils/object.util';

const initialState: FieldReportStoreState = {
	fieldReport: null,
	locks: {},
	connectionCount: null,
	activeSearchItemIndex: 0,
	query: '',
	deletingInstanceIndex: null,
	deletingSegmentIndex: null,
};

const FieldReportReducer: Reducer<FieldReportStoreState, FieldReportAction> = (state: FieldReportStoreState = initialState, action: FieldReportAction) => {
	switch (action.type) {
		case actionTypes.SET_FIELD_REPORT_NEW:
			return { ...state, fieldReport: action.payload };
		case actionTypes.SET_FIELD_REPORT_BLOCK_LOCKS:
			return { ...state, locks: { ...state.locks, ...action.payload } };
		case actionTypes.SET_CONNECTION_COUNT:
			return { ...state, connectionCount: action.payload };
		case actionTypes.REMOVE_FIELD_REPORT_BLOCK_LOCK: {
			if (!state.locks[action.payload]) {
				return state;
			}
			const newLockState = Object.keys(state.locks).reduce((_acc, _key) => {
				if (_key !== action.payload) {
					_acc[_key] = state.locks[_key];
				}
				return _acc;
			}, {} as FieldReportStoreState['locks']);
			return { ...state, locks: newLockState };
		}
		case actionTypes.ADD_INSTANCE: {
			const {
				typeMap,
				typeOrder,
				instanceMap,
				segmentMap,
				fieldReportBlockMap,
				fieldReportBlockFieldMap,
			} = action.payload;

			if (!state.fieldReport) {
				throw new Error('Field Report not initialized');
			}

			const instanceId = typeMap[typeOrder[0]].instances[typeMap[typeOrder[0]].instances.length - 1];
			return {
				...state,
				fieldReport: {
					...state.fieldReport,
					typeMap: {
						...state.fieldReport.typeMap,
						[typeOrder[0]]: {
							...state.fieldReport.typeMap[typeOrder[0]],
							instances: [...state.fieldReport.typeMap[typeOrder[0]].instances, instanceId],
						},
					},
					instanceMap: { ...state.fieldReport.instanceMap, ...instanceMap },
					segmentMap: { ...state.fieldReport.segmentMap, ...segmentMap },
					fieldReportBlockMap: { ...state.fieldReport.fieldReportBlockMap, ...fieldReportBlockMap },
					fieldReportBlockFieldMap: { ...state.fieldReport.fieldReportBlockFieldMap, ...fieldReportBlockFieldMap },
				} as FieldReportStoreState['fieldReport'],
			};
		}
		case actionTypes.ADD_SEGMENT: {
			const {
				typeOrder,
				instanceMap,
				segmentMap,
				fieldReportBlockMap,
				fieldReportBlockFieldMap,
			} = action.payload;

			if (!state.fieldReport) {
				throw new Error('Field Report not initialized');
			}

			const [instanceId] = Object.keys(instanceMap);
			// each time DB generates different instance id
			// so we need to find original
			const fieldReport = state.fieldReport.typeMap[typeOrder[0]];
			const instance = Object.values(instanceMap)[0];
			const oldInstanceId = fieldReport.instances[instance.index];

			const updatedBlockMap = Object.keys(fieldReportBlockMap).reduce((_acc, _blockId) => {
				_acc[_blockId] = {
					...fieldReportBlockMap[_blockId],
					instanceId: oldInstanceId,
				};
				return _acc;
			}, {} as typeof fieldReportBlockMap);

			return {
				...state,
				fieldReport: {
					...state.fieldReport,
					instanceMap: {
						...state.fieldReport.instanceMap,
						[oldInstanceId]: {
							...state.fieldReport.instanceMap[oldInstanceId],
							segments: [...state.fieldReport.instanceMap[oldInstanceId].segments, instanceMap[instanceId].segments[0]],
						},
					},
					segmentMap: { ...state.fieldReport.segmentMap, ...segmentMap },
					fieldReportBlockMap: { ...state.fieldReport.fieldReportBlockMap, ...updatedBlockMap },
					fieldReportBlockFieldMap: { ...state.fieldReport.fieldReportBlockFieldMap, ...fieldReportBlockFieldMap },
				} as FieldReportStoreState['fieldReport'],
			};
		}
		case actionTypes.REMOVE_INSTANCE: {
			const { fieldReportTypeId, instanceIndex } = action.payload;

			if (!state.fieldReport) {
				throw new Error('Field Report not initialized');
			}

			const fieldReportType = state.fieldReport.typeMap[fieldReportTypeId];
			if (!fieldReportType) {
				return state;
			}
			const instances = [...fieldReportType.instances];
			instances.splice(instanceIndex, 1);

			const clearDeletingSegment = state.deletingInstanceIndex === instanceIndex;

			return {
				...state,
				fieldReport: {
					...state.fieldReport,
					typeMap: {
						...state.fieldReport.typeMap,
						[fieldReportTypeId]: {
							...state.fieldReport.typeMap[fieldReportTypeId],
							instances,
						},
					},
				} as FieldReportStoreState['fieldReport'],
				deletingInstanceIndex: clearDeletingSegment ? null : state.deletingInstanceIndex,
				deletingSegmentIndex: clearDeletingSegment ? null : state.deletingSegmentIndex,
			};
		}
		case actionTypes.REMOVE_SEGMENT: {
			const { fieldReportTypeId, instanceIndex, segmentIndex } = action.payload;

			if (!state.fieldReport) {
				throw new Error('Field Report not initialized');
			}

			const fieldReportType = state.fieldReport.typeMap[fieldReportTypeId];
			if (!fieldReportType) {
				return state;
			}

			const instanceId = fieldReportType.instances[instanceIndex];
			if (!instanceId) {
				return state;
			}
			const segments = [...state.fieldReport.instanceMap[instanceId].segments];
			segments.splice(segmentIndex, 1);

			const clearDeletingSegment = state.deletingInstanceIndex === instanceIndex && state.deletingSegmentIndex === segmentIndex;

			return {
				...state,
				fieldReport: {
					...state.fieldReport,
					instanceMap: {
						...state.fieldReport.instanceMap,
						[instanceId]: {
							...state.fieldReport.instanceMap[instanceId],
							segments,
						},
					},
				} as FieldReportStoreState['fieldReport'],
				deletingInstanceIndex: clearDeletingSegment ? null : state.deletingInstanceIndex,
				deletingSegmentIndex: clearDeletingSegment ? null : state.deletingSegmentIndex,
			};
		}
		case actionTypes.SET_FIELD_REPORT_BLOCK_VALUE_NEW: {
			const {
				fieldReportBlockMap,
				fieldReportBlockFieldMap,
			} = action.payload;

			if (!state.fieldReport) {
				throw new Error('Field Report not initialized');
			}

			const [blockId] = Object.keys(fieldReportBlockMap);

			const fieldReportBlock = state.fieldReport.fieldReportBlockMap[blockId];
			const newBlock = {
				...fieldReportBlockMap[blockId],
				segmentId: fieldReportBlock.segmentId,
				instanceId: fieldReportBlock.instanceId,
			};

			return {
				...state,
				fieldReport: {
					...state.fieldReport,
					fieldReportBlockMap: { ...state.fieldReport.fieldReportBlockMap, [blockId]: newBlock },
					fieldReportBlockFieldMap: { ...state.fieldReport.fieldReportBlockFieldMap, ...fieldReportBlockFieldMap },
				} as FieldReportStoreState['fieldReport'],
			};
		}
		case actionTypes.SET_FIELD_REPORT_TYPES: {
			const {
				typeMap,
				typeOrder,
				instanceMap,
				segmentMap,
				fieldReportBlockMap,
				fieldReportBlockFieldMap,
				blockMap,
				fieldMap,
			} = action.payload.fieldReport;

			if (!state.fieldReport) {
				throw new Error('Field Report not initialized');
			}

			return {
				...state,
				fieldReport: {
					...state.fieldReport,
					typeOrder,
					typeMap,
					instanceMap,
					segmentMap,
					fieldReportBlockMap,
					fieldReportBlockFieldMap: join(state.fieldReport.fieldReportBlockFieldMap, fieldReportBlockFieldMap),
					blockMap: join(state.fieldReport.blockMap, blockMap),
					fieldMap: join(state.fieldReport.fieldMap, fieldMap),
				} as FieldReportStoreState['fieldReport'],
			};
		}
		case actionTypes.SET_FR_BLOCK_COMPLETED:
			if (!state.fieldReport) {
				throw new Error('Field Report not initialized');
			}

			return {
				...state,
				fieldReport: {
					...state.fieldReport,
					fieldReportBlockMap: {
						...state.fieldReport.fieldReportBlockMap,
						[action.payload.blockId]: {
							...state.fieldReport.fieldReportBlockMap[action.payload.blockId],
							completed: action.payload.completed,
						},
					},
				} as FieldReportStoreState['fieldReport'],
			};
		case actionTypes.SET_SEGMENT_DELETING:
			return {
				...state,
				deletingInstanceIndex: action.payload.instanceIndex,
				deletingSegmentIndex: action.payload.segmentIndex,
			};
		default:
			return state;
	}
};

export default FieldReportReducer;
