import * as React from 'react';
import { FieldArray } from 'redux-form';

import { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';

import { getId } from 'acceligent-shared/utils/array';

import { WorkOrderEmployeeVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import Dropdown from 'af-components/Controls/Dropdown';

import WorkOrderEmployees, { OwnProps as WorkOrderEmployeesProps } from './WorkOrderEmployees';

interface OwnProps {
	anyTouched: boolean;
	assignedToIdsError: string;
	change: (key: string, value: Nullable<number[]>) => void;
	selectedLevel: WorkOrderReviewLevel;
	assignedToIds: number[];
	users: Nullable<WorkOrderEmployeeVM[]>;
}

type Props = OwnProps;

const FILTER_BY: (keyof WorkOrderEmployeeVM)[] = ['fullName'];

const getSelectedUsers = (targetLevel: WorkOrderReviewLevel, users: Nullable<WorkOrderEmployeeVM[]>) => {
	if (targetLevel < WorkOrderReviewLevel.LEVEL_2) {
		return users ?? [];
	}
	return [];
};

const renderOption = (option: WorkOrderEmployeeVM) => <span>{option.fullName} ({option.uniqueId})</span>;

const WorkOrderEmployeeSelect: React.FC<Props> = (props: Props) => {
	const { users, selectedLevel, change, assignedToIds, anyTouched, assignedToIdsError } = props;

	const [options, setOptions] = React.useState(users ?? []);
	const [selectedUsers, setSelectedUsers] = React.useState(getSelectedUsers(selectedLevel, users));

	React.useEffect(() => {
		const newSelectedUsers = getSelectedUsers(selectedLevel, users);
		setSelectedUsers(newSelectedUsers);
		setOptions(users ?? []);
		change('assignedToIds', newSelectedUsers.map(getId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [users, selectedLevel]); // Removed change from dependency array cause not needed and was causing undesired behavior

	const onChange = React.useCallback((selectedOption: Nullable<WorkOrderEmployeeVM>) => {
		const newUsers = [...selectedUsers];
		if (selectedOption) {
			newUsers.push(selectedOption);
		}
		const newOptions = options.filter((_option) => _option.id !== selectedOption?.id);
		const nextAssignedToIds = [...(assignedToIds ?? [])];
		if (selectedOption) {
			nextAssignedToIds.push(selectedOption.id);
		}
		change('assignedToIds', nextAssignedToIds);

		setSelectedUsers(newUsers);
		setOptions(newOptions);
	}, [change, options, selectedUsers, assignedToIds]);

	const error = (anyTouched && assignedToIdsError) ? assignedToIdsError : undefined;

	return (
		<>
			{selectedLevel > WorkOrderReviewLevel.LEVEL_1 &&
				<Dropdown
					className="work-order-report-reject-modal__dropdown"
					filterable={true}
					filterBy={FILTER_BY}
					fixed={true}
					label="Select User *"
					labelKey="fullName"
					onValueChange={onChange}
					options={options}
					placeholder="Select user to reject to"
					renderMenuItem={renderOption}
					valueKey="id"
					withCaret={true}
				/>
			}
			<FieldArray<WorkOrderEmployeesProps>
				assignedToIds={assignedToIds}
				change={change}
				component={WorkOrderEmployees}
				employees={selectedUsers}
				error={error}
				name="assignedToIds"
				propName="assignedToIds"
			/>
		</>
	);
};

export default React.memo(WorkOrderEmployeeSelect);
