import * as React from 'react';

interface OwnProps {
	pauseReason: Nullable<string>;
}

type Props = OwnProps;

const WorkOrderPauseReason: React.FC<Props> = (props: Props) => {
	const { pauseReason } = props;

	if (!pauseReason) {
		return null;
	}

	return (
		<div className="field-report-work-order-info-card__header__pause-reason-container">
			<span className="field-report-work-order-info-card__header__pause-title">
				Pause Reason:
				<span className="field-report-work-order-info-card__header__pause-reason">
					{pauseReason}
				</span>
			</span>
		</div>
	);
};

export default React.memo(WorkOrderPauseReason);
