import * as React from 'react';

import RepeatableBlockType from 'acceligent-shared/enums/repeatableBlockType';
import * as ReportBlockFieldEnums from 'acceligent-shared/enums/reportBlockField';

import DroppableFieldList from './DroppableFieldList';
import Title from './Title';

const PRIMARY_IMPORTANT_FIELD_LIST = Object.keys(ReportBlockFieldEnums.ImportantFieldTypes);
const SECONDARY_IMPORTANT_FIELD_LIST = Object.keys(ReportBlockFieldEnums.ImportantFieldTypes).slice(1);
const INFORMATIONAL_FIELD_LIST = Object.keys(ReportBlockFieldEnums.InformationalFieldTypes);
const TABLE_COMPATIBLE_FIELD_LIST = Object.keys(ReportBlockFieldEnums.TableCompatibleFields);
const ACCESSORIES_LIST = Object.keys(ReportBlockFieldEnums.AccessoriesFieldTypes);

interface OwnProps {
	hideIdField: boolean;
	repeatableBlockType?: Nullable<RepeatableBlockType>;
}

type Props = OwnProps;

const FieldList: React.FC<Props> = (props) => {
	const { hideIdField, repeatableBlockType } = props;

	const importantFieldList = React.useMemo(() => {
		let fields = !hideIdField ? PRIMARY_IMPORTANT_FIELD_LIST : SECONDARY_IMPORTANT_FIELD_LIST;
		if (repeatableBlockType && repeatableBlockType !== RepeatableBlockType.DEFAULT) {
			fields = fields.filter((field) => TABLE_COMPATIBLE_FIELD_LIST.includes(field));
		}
		return fields;
	}, [hideIdField, repeatableBlockType]);

	const informationalFieldList = React.useMemo(() => {
		let fields = INFORMATIONAL_FIELD_LIST;
		if (repeatableBlockType && repeatableBlockType !== RepeatableBlockType.DEFAULT) {
			fields = fields.filter((field) => TABLE_COMPATIBLE_FIELD_LIST.includes(field));
		}
		return fields;
	}, [repeatableBlockType]);

	return (
		<div className="report-block-form">
			<Title title="Important Field Types" tooltip={ReportBlockFieldEnums.FieldTypeHint.IMPORTANT} />
			<DroppableFieldList items={importantFieldList} />
			<Title title="Informational Field Types" tooltip={ReportBlockFieldEnums.FieldTypeHint.INFORMATIONAL} />
			<DroppableFieldList items={informationalFieldList} />
			{
				(!repeatableBlockType || repeatableBlockType === RepeatableBlockType.DEFAULT) &&
				<>
					<Title title="Accessories" tooltip={null} />
					<DroppableFieldList items={ACCESSORIES_LIST} />
				</>
			}
		</div>
	);
};

export default React.memo(FieldList);
