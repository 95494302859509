import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import HorizontalScrollbar from 'af-components/HorizontalScrollbar';

interface Props {
	setMetricsHorizontalScrollingPercentage: typeof ScheduleBoardActions.setMetricsHorizontalScrollingPercentage;
	maxWidth: number;
	scrollPercentage: number;
	position: 'top' | 'bottom';
	onHorizontalScroll: (scrollLeft: number, width: number, force?: boolean) => void;
}

class MetricsHorizontalScrollbar extends React.PureComponent<Props> {
	render() {
		const {
			position, scrollPercentage, maxWidth, setMetricsHorizontalScrollingPercentage, onHorizontalScroll,
		} = this.props;

		return (
			<HorizontalScrollbar
				maxWidth={maxWidth}
				onHorizontalScroll={onHorizontalScroll}
				position={position}
				scrollPercentage={scrollPercentage}
				setScrollingPercentage={setMetricsHorizontalScrollingPercentage}
			/>
		);
	}
}

function mapStateToProps(state: RootState) {
	return {
		scrollPercentage: state.scheduleBoard.metricsHorizontalScrollingPercentage,
	};
}

function mapDispatchToProps() {
	return {
		setMetricsHorizontalScrollingPercentage: ScheduleBoardActions.setMetricsHorizontalScrollingPercentage,
	};
}

export default connect(mapStateToProps, mapDispatchToProps())(MetricsHorizontalScrollbar);
