import * as React from 'react';
import { FieldArray } from 'redux-form';

import UserGroupsField, { OwnProps as UserGroupOwnProps } from '../Shared/UserGroupsField';

const UserGroup: React.FC = () => {
	return (
		<div className="user-upsert__user-group">
			<p>
				Groups are used to logically organize and notify large amounts of users.
				<br />
				Assign a user to a group by adding group tags.
			</p>
			<FieldArray<UserGroupOwnProps>
				component={UserGroupsField}
				label="Group Tags"
				name="userGroups"
			/>
		</div>
	);
};

export default UserGroup;
