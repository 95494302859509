import * as React from 'react';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';

interface Props {
	showModal: boolean;
	closeModal: () => void;
	errorMessage: Nullable<string>;
}

class ErrorInfoModal extends React.PureComponent<Props> {

	render() {
		const { showModal, closeModal, errorMessage } = this.props;

		return (
			<CustomModal
				closeModal={closeModal}
				id="data-status-error"
				modalStyle="danger"
				showModal={showModal}
				size="sm"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Error While Saving Status"
				/>
				<CustomModal.Body>
					{errorMessage}
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default ErrorInfoModal;
