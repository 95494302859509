import * as React from 'react';
import styles from './styles.module.scss';

interface Props {
	uploading?: boolean;
	disabled?: boolean;
	error?: Nullable<string>;
}

const FileUploadMessageWithTypes = ({ disabled = false, uploading = false, error }: Props) => {
	if (disabled) {
		return <span className={styles['dropzone-element__text']}>Upload is disabled	</span>;
	} else if (uploading) {
		return <span className={styles['dropzone-element__text']}>Uploading...</span>;
	} else {
		return (
			<>
				{error &&
					<span className={styles['dropzone-element__error']}>
						{error}
					</span>
				}
				<span className={styles['dropzone-element__uploading-icon']}>
					<span className="icon-upload" />
				</span>
				<div className={styles['dropzone-element__text']}>
					<div className={styles['dropzone-element__text__bold']}>
						Select files or drag and drop here
					</div>
					<span className={styles['dropzone-element__text__types']}>
						JPG, PNG, SVG, XLS, XLSX, DOC, DOCX, CSV or PDF
					</span>
				</div >
			</>
		);
	}
};

export default React.memo(FileUploadMessageWithTypes);
