import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';
import * as UserUtils from 'acceligent-shared/utils/user';

import * as WorkOrderActions from 'af-actions/workOrder';

import { CURRENT_WORK_ORDER_REVISION_ITEM } from 'ab-constants/value';

import WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';
import WorkOrderRevisionViewModel, { CurrentWorkOrderModalItemViewModel, UnavailableWorkOrderRevision, WorkOrderRevisionModalItemViewModel } from 'ab-viewModels/workOrderRevisionItem.viewModel';

import Dropdown from 'af-components/Controls/Dropdown';

type WorkOrderRevisionsType = CurrentWorkOrderModalItemViewModel | WorkOrderRevisionModalItemViewModel | UnavailableWorkOrderRevision;

interface OwnProps {
	selectRevision: (revisionId: number, isCurrentWorkOrder: boolean) => void;
	workOrder: WorkOrderModalViewModel;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const _getDefaultRevision = (workOrder: WorkOrderModalViewModel) => {
	return {
		id: workOrder?.id,
		dueDate: workOrder?.dueDate,
		code: workOrder?.code,
		revision: workOrder?.revision,
		updatedAt: workOrder?.updatedAt,
		status: workOrder?.status,
		additionalText: CURRENT_WORK_ORDER_REVISION_ITEM,
		isCurrentWorkOrder: true,
		updatedByFullName: UserUtils.getUserName(
			workOrder.updatedBy?.firstName && workOrder.updatedBy?.lastName
				? { firstName: workOrder.updatedBy.firstName, lastName: workOrder.updatedBy.lastName }
				: null
		),
	};
};

const _getStatusIcon = (status: Nullable<WorkOrderStatus>) => {
	switch (status) {
		case WorkOrderStatus.PUBLISHED:
			return <span className="icon-check text-green" />;
		case WorkOrderStatus.CANCELED:
			return <span className="icon-warning text-red" />;
		case WorkOrderStatus.OUTDATED:
			return <span className="icon-clock text-orange" />;
		default:
			return null;
	}
};

const currentWorkOrderAdditionalDisplayText = (option: WorkOrderRevisionViewModel) => {
	const { status, additionalText, updatedByFullName } = option;
	return status === WorkOrderStatus.OUTDATED
		? ` ${additionalText} by ${updatedByFullName}`
		: ` ${updatedByFullName ?? ''}`;
};

const _renderRevisionItem = (option: WorkOrderRevisionViewModel) => {
	if (!option) {
		return (<div className="clear-option" />);
	}

	const { revision, updatedAt, status, additionalText, disabled, isCurrentWorkOrder } = option;

	const revisionDisplayText = isCurrentWorkOrder
		? `Current - ${updatedAt && TimeUtils.formatDate(updatedAt, TimeFormat.FULL_DATE_SLASHES, TimeFormat.ISO_DATETIME)}`
		: `Rev. ${revision} - ${updatedAt && TimeUtils.formatDate(updatedAt, TimeFormat.FULL_DATE_SLASHES, TimeFormat.ISO_DATETIME)}`;
	const additionalDisplayText = isCurrentWorkOrder
		? currentWorkOrderAdditionalDisplayText(option)
		: ` ${additionalText}`;

	return (
		<span className="work-order-info-modal__revision-item">
			{!disabled && _getStatusIcon(status)}
			<strong>{!disabled && revisionDisplayText}</strong>
			<span className="work-order-info-modal__revision-item-details">{additionalDisplayText}</span>
		</span>
	);
};

const RevisionDropdown: React.FC<Props> = (props: Props) => {
	const { workOrder, lazyLoadRevisions, selectRevision } = props;

	const [workOrderRevisions, setWorkOrderRevisions] = React.useState<WorkOrderRevisionsType[]>([]);
	const [defaultWorkOrderRevision, setDefaultWorkOrderRevision] = React.useState<WorkOrderRevisionViewModel>(_getDefaultRevision(workOrder));

	React.useEffect(() => {
		setDefaultWorkOrderRevision(_getDefaultRevision(workOrder));
	}, [workOrder]);

	const loadRevisions = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const revisions = await lazyLoadRevisions(workOrder.workOrderId ?? workOrder.id);
			setWorkOrderRevisions(revisions);
		}
	}, [lazyLoadRevisions, workOrder.workOrderId, workOrder.id]);

	const selectWorkOrderRevision = React.useCallback(async (workOrderRevision: Nullable<WorkOrderRevisionViewModel>) => {
		if (!workOrderRevision) {
			throw new Error('Invalid revision selected');
		}

		const { id, isCurrentWorkOrder } = workOrderRevision;

		if (!id) {
			throw new Error('No revision to select');
		}
		selectRevision(id, !!isCurrentWorkOrder);
	}, [selectRevision]);

	return (
		<div className="work-order-info-modal__revisions">
			<div className="work-order-info-modal__revisions-dropdown-container">
				<Dropdown<WorkOrderRevisionViewModel>
					defaultValue={defaultWorkOrderRevision}
					fullWidth={true}
					id="accqa__revision-dropdown"
					onLazyLoad={loadRevisions}
					onValueChange={selectWorkOrderRevision}
					options={workOrderRevisions}
					renderMenuItem={_renderRevisionItem}
					valueKey="id"
					withCaret={true}
				/>
			</div>
		</div>
	);
};

function mapDispatchToProps() {
	return {
		lazyLoadRevisions: WorkOrderActions.lazyLoadRevisions,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(RevisionDropdown);
