import * as React from 'react';
import { compose } from 'redux';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { Button, Row } from 'react-bootstrap';

import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';

import { REPORT_TYPE } from 'af-constants/reduxForms';

import { ReportTypeRM } from 'ab-requestModels/reportType/reportType.requestModel';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { validate } from './validation';

const DESCRIPTION_LENGTH_LIMIT = 100;

const BLOCK_TYPE_OPTIONS = [
	{ id: true, label: 'Primary Block' },
	{ id: false, label: 'Secondary Block' },
];

interface OwnProps {
	closeModal: () => void;
	initialDescription: Nullable<string>;
	initialName?: string;
	showModal: boolean;
	onSave: (name: string, description: Nullable<string>) => void;
}

type Props = OwnProps & InjectedFormProps<ReportTypeRM, OwnProps>;

const ReportTypeModal: React.FC<Props> = (props: Props) => {
	const { handleSubmit, closeModal, showModal, invalid, destroy, initialize, onSave, initialName, initialDescription, submitting } = props;

	React.useEffect(() => {
		// Initializing *only* on modal open
		if (showModal) {
			initialize({ name: initialName, description: initialDescription });
		} else {
			destroy();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal]);

	const onSubmit = React.useCallback((form: ReportTypeRM) => {
		onSave(form.name, form.description);
		closeModal();
	}, [onSave, closeModal]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={`${initialName ? 'Edit' : 'New'} Report Type`}
			/>
			<CustomModal.Body>
				<Row className="row--non-padded">
					<Field
						component={Input}
						label="Name *"
						name="name"
						placeholder="Enter Type Name"
					/>
				</Row>
				<Row className="row--non-padded">
					<Field
						component={Textarea}
						label="Description"
						maxCharacters={DESCRIPTION_LENGTH_LIMIT}
						name="description"
						options={BLOCK_TYPE_OPTIONS}
						showMaxCharactersLabel={true}
					/>
				</Row>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					label="Continue"
					onClick={handleSubmit(onSubmit)}
					reduxFormSubmitting={submitting}
					submitKey={REPORT_TYPE}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	reduxForm<ReportTypeRM, OwnProps>({
		form: REPORT_TYPE,
		validate,
	})
);

export default enhance(ReportTypeModal);
