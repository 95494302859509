import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';

import DisplayTypeEnum from '../../Shared/displayType.enum';

export const DISPLAY_TYPE_COL_SIZE = {
	[DisplayTypeEnum.DEFAULT]: { BLOCK_LIST: 6, TYPE_INFO: 8, PREVIEW: 10 },
	[DisplayTypeEnum.SMALL]: { BLOCK_LIST: 0, TYPE_INFO: 10, PREVIEW: 14 },
	[DisplayTypeEnum.EXTRA_SMALL]: { BLOCK_LIST: 0, TYPE_INFO: 24, PREVIEW: 24 },
};

export const VALID_BILLABLE_WORK_TYPE_FIELDS = {
	[ReportBlockFieldEnum.Type.BOOLEAN]: true,
	[ReportBlockFieldEnum.Type.DROPDOWN]: true,
	[ReportBlockFieldEnum.Type.NUMERIC_ATTRIBUTE]: true,
	[ReportBlockFieldEnum.Type.CALCULATED]: true,
};

export const VALID_DEFINITION_FIELD_FIELDS = {
	[ReportBlockFieldEnum.Type.BOOLEAN]: true,
	[ReportBlockFieldEnum.Type.DROPDOWN]: true,
	[ReportBlockFieldEnum.Type.NUMERIC_ATTRIBUTE]: true,
	[ReportBlockFieldEnum.Type.CALCULATED]: true,
};

export type HighlightedBillableWorkBlockFields = Record<string, boolean>;
