import { DEFAULT_TABLE_PAGE_SIZE } from 'ab-constants/value';

export interface TableSortBy {
	id: string;
	desc: boolean;
}

export class TableQuery {
	pageSize: number | undefined;
	page: number;
	sortBy?: TableSortBy[];
	filterByText?: string;
	includeAll?: boolean;

	constructor(tableState: Partial<TableQuery> = {} as TableQuery) {
		this.pageSize = tableState.pageSize ?? DEFAULT_TABLE_PAGE_SIZE;
		this.page = tableState.page ?? 0;
		this.sortBy = tableState.sortBy ?? [];
		this.filterByText = tableState.filterByText ?? '';
		this.includeAll = tableState.includeAll ?? false;
	}
}

export interface TableRequestQuery {
	query: Stringified<Encoded<TableQuery>>;
}
