import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	openTableSettingsModal: () => void;
}

type Props = OwnProps;

export default ({ openTableSettingsModal }: Props) => (
	<div className="table-settings">
		<Tooltip message="Settings">
			<span className="icon-settings pointer" onClick={openTableSettingsModal} />
		</Tooltip>
	</div>
);
