import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

import CustomModal from 'af-components/CustomModal';

const actionsExampleImage ='/images/elements/ws_actions_example.svg';
const groupingExampleImage ='/images/elements/ws_grouping_example.svg';
const detailsRowsExampleImage ='/images/elements/ws_details_example.svg';

interface Props {
	closeModal: () => void;
	showModal: boolean;
}

const UserGuideModal: React.FC<Props> = (props) => {

	const { closeModal, showModal } = props;

	const modalClassName = 'field-report__work-summary__billable-work__user-guide-modal';
	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="lg"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="User Guide"
			/>
			<CustomModal.Body>
				<div className={modalClassName}>
					<p>
						<i>Work Summary</i> contains a list of all finished work on a Field report.
						It consists of three tables: <i>Billable work</i>, <i>Non-billable work</i>, and <i>Details</i>.
						Every item from <i>Billable work</i> and <i>Non-billable work</i> tables
						has a corresponding <i>Details</i> section which shows every individual entry for that item of work.
					</p>
					<p>
						In order for <i>Work Summary</i> to be in the <i>Complete</i> state
						all billable work should have a billing code assigned to it, or be moved to <i>Non-billable Work </i>
						table in case it is not being billed.
					</p>
					<div className={bemElement(modalClassName, 'subtitle')}>
						Billing
					</div>
					<p>
						All entries are included in the calculation of total billable work by default.
						Items from <i>Billable work</i> table can be moved to <i>Non-billable work</i> table in which case they will not be included in the
						total calculation.<br />
						This can be done by clicking on
							<span className={bemElement(modalClassName, 'billable', { 'inline': true })}>
								<span className="icon-dollar"/>
							</span>
						and alternating between the two states, or accessed from the three-dot menu.
					</p>
					<p>
						If an entry from <i>Billable work</i> table already has a billing code assigned to it when it is moved to <i>Non-billable</i> table,
						the billing code remains in read-only mode and is added to non-billable total.
					</p>
					<div className={bemElement(modalClassName, 'subtitle')}>
						Colors
					</div>
					<div className={bemElement(modalClassName, 'row')}>
						<span className={bemElement(modalClassName, 'empty-cell', { 'selected': true })}/>
						<div>
							<b>Selected</b>: <i>Work Summary</i> row and associated <i>Details</i> rows are selected.
						</div>
					</div>
					<div className={bemElement(modalClassName, 'row')}>
						<span className={bemElement(modalClassName, 'empty-cell', { 'alternative': true })}/>
						<div>
							<b>Alternative billable work</b>: billable work with the same definition and work type fields but different quantities
							- potentially more profitable, helps avoid double billing.
						</div>
					</div>
					<div className={bemElement(modalClassName, 'subtitle')}>
						Icons
					</div>
					<div className={bemElement(modalClassName, 'row')}>
						<span className={bemElement(modalClassName, 'recently-used')}>
							<span className="icon-star"/>
						</span>
						<div>
							<b>Recently used: </b>billable work used on Work Orders in the last 7 days.
						</div>
					</div>
					<div className={bemElement(modalClassName, 'row')}>
						<span className={bemElement(modalClassName, 'billable', { 'standalone': true })}>
							<span className="icon-dollar"/>
						</span>
						<div>
							<b>Included in calculation: </b> Every <i>Details</i> row with this icon will be included in the total.
						</div>
					</div>
					<div className={bemElement(modalClassName, 'row')}>
						<span className={bemElement(modalClassName, 'non-billable')}>
							<span className="icon-dollar"/>
						</span>
						<div>
							<b>Excluded from calculation: </b> Every <i>Details</i> row with this icon will be excluded from the total
							and its relating item will be automatically placed from <i>Billable work</i> table into the <i>Non-billable</i> work table.
						</div>
					</div>
					<div className={bemElement(modalClassName, 'subtitle')}>
						Assigning billing codes
					</div>
					<p>
						Billing codes for each billable work can be selected from the dropdown.
						Only billing codes that use related units are available. Non-applicable options will not be shown.
					</p>
					<div className={bemElement(modalClassName, 'subtitle')}>
						Grouping
					</div>
					<p>
						In case we want to assign different billing codes to elements of the same billable work we can split them into separate groups.
						This can be done by selecting <i>Details</i> rows we want to group separately and choosing “Separate group” from the three-dot menu:
					</p>
					<div className={bemElement(modalClassName, 'examples')}>
						<img src={actionsExampleImage}/>
						<img src={groupingExampleImage}/>
					</div>
					<p>
						This will also group corresponding items in the <i>Billable work</i> and <i>Non-billable work</i> tables:
					</p>
					<img src={detailsRowsExampleImage}/>
					<div className={bemElement(modalClassName, 'subtitle')}>
						Work Summary review process
					</div>
					<p>
						This review process is not related to the Field report and Time Sheet review process. Possible Work Summary review statuses are:
					</p>
					<div className={bemElement(modalClassName, 'work-summary-status')}>
						<div className={bemElement(modalClassName, 'work-summary-status__draft')}>
							<span className="icon-dot"/>
							<b>Draft</b>
							<span className={bemElement(modalClassName, 'work-summary-status__draft__description')}>
								User has not started working on the Work Summary
							</span>
						</div>
						<div className={bemElement(modalClassName, 'work-summary-status__in-progress')}>
							<span className="icon-dot"/>
							<b>In progress</b>
							<span className={bemElement(modalClassName, 'work-summary-status__in-progress__description')}>
								User has made an edit on Work Summary
							</span>
						</div>
						<div className={bemElement(modalClassName, 'work-summary-status__reviewed')}>
							<span className="icon-dot"/>
							<b>Reviewed</b>
							<span className={bemElement(modalClassName, 'work-summary-status__reviewed__description')}>
								<span>User has reviewed the Work Summary - clicked</span>
								<button className="btn btn-info">
									<span className="icon-visibility_on"/>
									Review
								</button>
							</span>
						</div>
						<div className={bemElement(modalClassName, 'work-summary-status__outdated')}>
							<span className="icon-dot_outline"/>
							<b>Outdated</b>
							<span className={bemElement(modalClassName, 'work-summary-status__outdated__description')}>
								User has made a change to <b>Reviewed</b> or <b>Completed</b> Work Summary
							</span>
						</div>
						<div className={bemElement(modalClassName, 'work-summary-status__completed')}>
							<span className="icon-dot"/>
							<b>Completed</b>
							<span className={bemElement(modalClassName, 'work-summary-status__completed__description')}>
								<span>Accounting has completed the Work Summary - clicked </span>
								<button className="btn btn-info">
									<span className="icon-check"/>
									Complete
								</button>
							</span>
						</div>
					</div>
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<button
					className="btn btn-info"
					onClick={closeModal}
				>
					Close
				</button>
			</CustomModal.Footer>
		</CustomModal>
);
};

export default UserGuideModal;
