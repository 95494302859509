import * as React from 'react';

import { ColorPalette } from 'acceligent-shared/enums/color';

import TemporaryEmployeeOptionVM, { WorkOrderAssignmentsVM } from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';

import * as ColorUtils from 'ab-utils/color.util';

import LabelWithColor from 'af-components/LabelWithColor';
import TextHighlight from 'af-components/TextHighlight';
import Tooltip from 'af-components/Tooltip';

import NightShiftBadge from 'af-root/scenes/Company/ScheduleBoard/Shared/NightShiftBadge';

interface OwnProps extends TemporaryEmployeeOptionVM {
	searchText: string;
	showAgency?: boolean;
}

type Props = OwnProps;

const renderMessage = (agency: string, agencyColor: ColorPalette) => {
	return (
		<>
			<div className="location-nickname">
				<LabelWithColor color={agencyColor} text={agency} />
			</div>
		</>
	);
};

const getAssignments = (assignments: WorkOrderAssignmentsVM[]) => {
	if (!assignments?.length) {
		return '';
	}
	return assignments.length === 1
		? `${assignments[0].code}`
		: `${assignments[0].code} +${assignments.length - 1} more`;
};

const LaborOptionItem: React.FC<Props> = (props: Props) => {
	const {
		agency,
		agencyColor,
		assignments: _assignments,
		fullName,
		searchText,
		showAgency = false,
		uniqueId,
		wasInNightShift,
	} = props;

	const assignments = getAssignments(_assignments);
	const color = ColorUtils.getColorTextClass(agencyColor, true);

	const renderName = () => {
		return (
			<div>
				<div>
					<span className={`icon-temp_labor ${color}`} />
					<span className={`resource-lookup__resource-name ${color}`}>
						<TextHighlight searchText={searchText} text={fullName} />
					</span>
					<span className="resource-lookup__resource-info">
						<TextHighlight searchText={searchText} text={`(${uniqueId})`} />
					</span>
					<span className="resource-lookup__resource-additional-info">{assignments}</span>
				</div>
				{showAgency && <span className="resource-lookup__resource-additional-info">{agency}</span>}
			</div>
		);
	};

	return (
		<Tooltip
			className="labor-popover"
			message={renderMessage(agency, agencyColor)}
			placement="right"
		>
			<span className="resource-lookup__resource">
				<div className="resource-lookup__resource-details-employee">
					{renderName()}
					{wasInNightShift && <NightShiftBadge />}
				</div>
				<small className="resource-lookup__resource-additional-info resource-lookup__resource-additional-info--small">{assignments}</small>
			</span>
		</Tooltip>
	);
};

export default React.memo(LaborOptionItem);
