import * as React from 'react';
import { Field } from 'redux-form';

import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';

import { formSectionClass, formSectionClassColumn1, formSectionClassColumn2, formSectionClassRow, formSectionTitleClass } from '../../helpers';
import WorkRequestUpsertFM from '../../formModel';

import styles from '../../styles.module.scss';

const JobDetailSection: React.FC = () => {
	const taxExemptStyle = React.useMemo(() => [formSectionClassColumn2, styles['work-request-form__tab-content__form-section__row__centered-column']].join(' '), []);
	const unionPrevailingWageStyle = React.useMemo(() => [formSectionClassColumn1, styles['work-request-form__tab-content__form-section__row__centered-column']].join(' '), []);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Job Detail
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="projectOwner"
						label="Project Owner"
						name={WorkRequestUpsertFM.getAttributeName('projectOwner')}
						placeholder="Enter Project Owner"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="ownerReference"
						label="Owner Reference"
						name={WorkRequestUpsertFM.getAttributeName('ownerReference')}
						placeholder="Enter Owner Reference"
						type="text"
					/>
				</div>
				<div className={taxExemptStyle}>
					<Field
						component={Checkbox}
						id="taxExempt"
						label="Tax Exempt"
						name={WorkRequestUpsertFM.getAttributeName('taxExempt')}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="generalContractor"
						label="General Contractor"
						name={WorkRequestUpsertFM.getAttributeName('generalContractor')}
						placeholder="Enter General Contractor"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="contractorReference"
						label="Contractor Reference"
						name={WorkRequestUpsertFM.getAttributeName('contractorReference')}
						placeholder="Enter Contractor Reference"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={unionPrevailingWageStyle}>
					<Field
						component={Checkbox}
						id="union"
						label="Union"
						name={WorkRequestUpsertFM.getAttributeName('isUnion')}
					/>
				</div>
				<div className={unionPrevailingWageStyle}>
					<Field
						component={Checkbox}
						id="prevailingWage"
						label="Prevailing Wage"
						name={WorkRequestUpsertFM.getAttributeName('isPrevailingWage')}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="jurisdiction"
						label="Jurisdiction"
						name={WorkRequestUpsertFM.getAttributeName('jurisdiction')}
						placeholder="Enter Jurisdiction"
						type="text"
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(JobDetailSection);
