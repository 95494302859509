// TODO: move to frontend models when we decide the structure
export class ColumnSettingItem {
	label: React.ReactNode;
	fixed: boolean;
	name: string;
	visible: boolean;
	width: number | undefined;

	constructor(name: string, visible: boolean, width: number | undefined, fixed: boolean, label: React.ReactNode) {
		this.name = name;
		this.visible = visible;
		this.width = width;
		this.fixed = fixed;
		this.label = label;
	}
}
