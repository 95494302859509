import * as React from 'react';

import JobHazardAssessmentPreviewVM from 'ab-viewModels/workRequest/jobHazardAssessmentPreview.viewModel';

import JobHazardSummarySection from './Sections/JobHazardSummarySection';
import PermitsRequiredSection from './Sections/PermitsRequiredSection';
import SafetySection from './Sections/SafetySection';
import EnvironmentalProtectionSection from './Sections/EnvironmentalProtectionSection';
import PublicSafetySection from './Sections/PublicSafetySection';
import JobHazardAssessmentStatusSection from './Sections/JobHazardAssessmentStatusSection';

import styles from '../styles.module.scss';

type Props = {
	workRequest: JobHazardAssessmentPreviewVM;
};

const JobHazardAssesmentPreviewTab: React.FC<Props> = ({ workRequest }) => {
	return (
		<div className={styles['work-request-preview__tab-content']}>
			<JobHazardSummarySection jobHazardSummary={workRequest.hazardSummary} />
			<PermitsRequiredSection permitsRequired={workRequest.permitsRequired} />
			<SafetySection
				dangerousAnimalsOrPlants={workRequest.dangerousAnimalsOrPlants}
				dangerousAnimalsOrPlantsDescription={workRequest.dangerousAnimalsOrPlantsDescription}
				electricalHazards={workRequest.electricalHazards}
				electricalHazardsDescription={workRequest.electricalHazardsDescription}
				environmentalExposure={workRequest.environmentalExposure}
				environmentalExposureDescription={workRequest.environmentalExposureDescription}
				hazardousMaterials={workRequest.hazardousMaterials}
				hazardousMaterialsDescription={workRequest.hazardousMaterialsDescription}
				heightWork={workRequest.heightWork}
				heightWorkDescription={workRequest.heightWorkDescription}
				mechanicalHazards={workRequest.mechanicalHazards}
				mechanicalHazardsDescription={workRequest.mechanicalHazardsDescription}
				overheadHazards={workRequest.overheadHazards}
				overheadHazardsDescription={workRequest.overheadHazardsDescription}
				respiratoryProtection={workRequest.respiratoryProtection}
				respiratoryProtectionDescription={workRequest.respiratoryProtectionDescription}
			/>
			<EnvironmentalProtectionSection
				bypassPumping={workRequest.bypassPumping}
				bypassPumpingDescription={workRequest.bypassPumpingDescription}
				sensitiveEnvironment={workRequest.sensitiveEnvironment}
				sensitiveEnvironmentDescription={workRequest.sensitiveEnvironmentDescription}
				stormDrain={workRequest.stormDrain}
				stormDrainDescription={workRequest.stormDrainDescription}
				waterOrWetlands={workRequest.waterOrWetlands}
				waterOrWetlandsDescription={workRequest.waterOrWetlandsDescription}
			/>
			<PublicSafetySection
				pedestrianCrossing={workRequest.pedestrianCrossing}
				pedestrianCrossingDescription={workRequest.pedestrianCrossingDescription}
				vehicleTraffic={workRequest.vehicleTraffic}
				vehicleTrafficDescription={workRequest.vehicleTrafficDescription}
			/>
			<JobHazardAssessmentStatusSection status={workRequest.jobHazardAssessmentStatus} />
		</div>
	);
};

export default React.memo(JobHazardAssesmentPreviewTab);
