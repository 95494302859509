import * as React from 'react';

type Props = React.PropsWithChildren<unknown>;

const Toast: React.FC<Props> = ({ children }) => {
	return (
		<div className="toast">
			<div className="toast__message">{children}</div>
		</div>
	);
};

export default React.memo(Toast);
