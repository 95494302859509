import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps, FieldArray } from 'redux-form';
import { compose } from 'redux';
import { Button } from 'react-bootstrap';

import * as FieldReportAccessActions from 'af-actions/fieldReportAccess';
import * as EmployeeAccessActions from 'af-actions/employee';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { FieldReportAccessOptionVM } from 'ab-viewModels/employee/fieldReportAccessOption.viewModel';

import AccessRM from 'ab-requestModels/fieldReport/fieldReportAccess.requestModel';

import { FIELD_REPORT_ACCESS } from 'af-constants/reduxForms';

import List, { OwnProps as EmployeeListOwnProps } from './List';

import { validate } from './validation';

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & InjectedFormProps<AccessRM, FormOwnProps>;

interface OwnProps {
	showModal: boolean;
	workOrderId: number;
	fieldReportAccessDayDuration: number;
	close: () => void;
}

interface State {
	superintendents: FieldReportAccessOptionVM[];
}

class FieldReportAccessModal extends React.PureComponent<Props, State> {
	state: State = {
		superintendents: [],
	};

	async componentDidUpdate(prevProps: OwnProps) {
		const { initialize, findAll, findAllSuperintendents, workOrderId, showModal } = this.props;
		if (!prevProps.showModal && showModal) {
			const [data, superintendents] = await Promise.all([
				findAll(workOrderId),
				findAllSuperintendents(workOrderId),
			]);
			initialize({ accessList: data });
			this.setState(() => ({ superintendents }));
		}
	}

	closeModal = () => {
		const { reset, close } = this.props;
		reset();
		close();
	};

	saveChanges = async (form: AccessRM) => {
		const { update, workOrderId, close } = this.props;
		await update(workOrderId, form);
		close();
	};

	render() {
		const { showModal, submitting, fieldReportAccessDayDuration, handleSubmit, invalid } = this.props;
		const { superintendents } = this.state;

		return (
			<CustomModal
				closeModal={this.closeModal}
				showModal={showModal}
				size="lg"
			>
				<CustomModal.Header
					closeModal={this.closeModal}
					title="Manage Employee Access"
				/>
				<CustomModal.Body>
					<FieldArray<EmployeeListOwnProps>
						component={List}
						fieldReportAccessDayDuration={fieldReportAccessDayDuration}
						name="accessList"
						rerenderOnEveryChange={true}
						superintendents={superintendents}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={this.closeModal}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(this.saveChanges)}
						reduxFormSubmitting={submitting}
						variant="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps() {
	return {
		update: FieldReportAccessActions.update,
		findAll: FieldReportAccessActions.findAllByWorkOrderId,
		findAllSuperintendents: EmployeeAccessActions.findAllSuperintendentsForFieldReport,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<AccessRM, FormOwnProps>({ form: FIELD_REPORT_ACCESS, validate })
);

export default enhance(FieldReportAccessModal);
