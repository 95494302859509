export * from 'acceligent-shared/utils/array';

export function uniqueBy<T>(arr: T[] = [], ...keys: (keyof T)[]): T[] {
	if (!keys?.length || !arr.length) {
		return [];
	}
	const result: T[] = [];
	const seen: { [key: string]: true; } = {};
	const prefix = 'key';
	for (const _item of arr) {
		let uniqueKey = prefix;
		for (const _key of keys) {
			uniqueKey += _item[_key] || '';
		}
		if (uniqueKey === prefix) {
			// _item does not have any value on keys
			continue;
		}
		if (!seen[uniqueKey]) {
			seen[uniqueKey] = true;
			result.push(_item);
		}
	}
	return result;
}

export function uniqueArray<T extends string | number>(arr: T[] = []): T[] {
	const lookup: { [item: string]: true; } = {};
	const accumulator: T[] = []; // used to maintain type in case of number
	for (const _item of arr) {
		if (lookup[_item]) {
			continue;
		}
		lookup[_item] = true;
		accumulator.push(_item);
	}
	return accumulator;
}

export function addToArrayIfExists(array: string[], item: string) {
	if (item) {
		array.push(item);
	}
}

export function somePredicates<T>(predicates: ArrayPredicate<T>[]): ArrayPredicate<T> {
	return (elem: T, index: number, array: T[]) => {
		for (const _predicate of predicates) {
			if (_predicate(elem, index, array)) {
				return true;
			}
		}
		return false;
	};
}

export function everyPredicates<T>(predicates: ArrayPredicate<T>[]): ArrayPredicate<T> {
	return (elem: T, index: number, array: T[]) => {
		for (const _predicate of predicates) {
			if (!_predicate(elem, index, array)) {
				return false;
			}
		}
		return true;
	};
}

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunkSize {Integer} Size of every group
 */
export function chunkArray<T>(originalArray: T[], chunkSize: number): T[][] {
	const resultArray: T[][] = [];

	for (let index = 0; index < originalArray.length; index += chunkSize) {
		resultArray.push(originalArray.slice(index, index + chunkSize));
	}
	return resultArray;
}

export function move<T, TArray extends T[]>(list: TArray, fromIndex: number, toIndex: number): TArray {
	const result = Array.from(list);
	const [removed] = result.splice(fromIndex, 1);
	result.splice(toIndex, 0, removed);
	return result as TArray;
}

export function remove<T, TArray extends T[]>(list: TArray, index: number): TArray {
	const result = Array.from(list);
	result.splice(index, 1);
	return result as TArray;
}

export function insert<T, TArray extends T[]>(list: TArray, index: number, item: T): TArray {
	const result = Array.from(list);
	result.splice(index, 0, item);
	return result as TArray;
}

type SplitByFilterReturnType<T> = { is: T[]; isNot: T[]; };
export function splitByFilter<T>(list: T[], filter: ArrayPredicate<T>): SplitByFilterReturnType<T> {
	const result: SplitByFilterReturnType<T> = { is: [], isNot: [] };
	for (let _index = 0; _index < list.length; _index++) {
		const _item = list[_index];
		const _target: keyof SplitByFilterReturnType<T> = filter(_item, _index, list) ? 'is' : 'isNot';
		result[_target].push(_item);
	}
	return result;
}

export function createExistsMap<T>(items: T[], extractId: (item: T) => number | string): { [id: string]: true; } {
	const existsMap: { [id: string]: true; } = {};
	for (const _item of items) {
		existsMap[extractId(_item)] = true;
	}
	return existsMap;
}

/** Creates array from any number of arguments whether they are array or not*/
export function flattenElements<T>(...args: (Nullable<T> | Nullable<T[]>)[]) {
	let result: T[] = [];
	args.forEach((_arg: T[] | T) => {
		if (Array.isArray(_arg)) {
			result = result.concat(_arg);
		} else if (_arg) {
			result.push(_arg);
		}
	});
	return result;
}

/** @example array.sort(sortByIndex) */
export function sortByIndex<T extends { index: Nullable<number>; }>(elem1: T, elem2: T) {
	return (elem1.index ?? 0) - (elem2.index ?? 0);
}

/** @example array.sort(sortByCreatedAt) */
export function sortByCreatedAt<T extends { createdAt: Date; }>(elem1: T, elem2: T) {
	return (+elem1.createdAt) - (+elem2.createdAt);
}

export function arraysHaveSameElements<T>(a: T[], b: T[]): boolean {
	const setA = new Set(a);
	const setB = new Set(b);

	if (setA.size !== setB.size) {
		return false;
	}

	for (const element of setA) {
		if (!setB.has(element)) {
			return false;
		}
	}

	return true;
}

/**
 * Function responsible for getting the string value that is used for sorting from an object. Example: obj.name
 */
type ValueGetter<T> = (obj: T) => string;
type Comparator<T> = (a: T, b: T) => number;
/**
 * Function that takes the string value to be used for sorting and extracts the string and value parts into an object.
 */
type NumberParser = (value: string) => { string: string; number: number; } | undefined;
type SortableHolder<T, V> = {
	value: T;
	sortable: V;
	secondarySortable?: string;
};

/**
 *
 * Funtion that numerically sorts an array of objects with some string field.
 *
 * @param array Array of objects.
 * @param parser Function that takes the string value to be used for sorting and extracts the string and value parts into an object.
 * @param valueGetter Function responsible for getting the string value that is used for sorting from an object. Example: obj.name.
 * @param stringComparator Function that compares strings in the sorting.
 * @param numberComparator Function that compares numbers in the sorting.
 * @param stringFirst True if the first part of the sorted array should be values that should be sorted like regular strings.
 * @returns The same array sorted using the params.
 */
export function sortStringValueNumerically<T>(
	array: T[],
	parser: NumberParser,
	valueGetter: ValueGetter<T>,
	stringComparator: Comparator<string>,
	numberComparator: Comparator<number>,
	stringFirst: boolean
): T[] {

	const strings: SortableHolder<T, string>[] = [];
	const numbers: SortableHolder<T, number>[] = [];

	array.forEach((element) => {
		const _value: string = valueGetter(element);
		const parsedValues = parser(_value);

		if (parsedValues === undefined) {
			strings.push({ value: element, sortable: _value });
			return;
		}

		numbers.push({ value: element, sortable: parsedValues.number, secondarySortable: parsedValues.string });
	});

	strings.sort((a, b) => stringComparator(a.sortable, b.sortable));
	numbers.sort((a, b) => {
		const comparison: number = numberComparator(a.sortable, b.sortable);
		if (comparison !== 0) {
			return comparison;
		}
		if (!a.secondarySortable || !b.secondarySortable) {
			return 0;
		}
		return stringComparator(a.secondarySortable, b.secondarySortable);
	});

	return stringFirst ? [
		...strings.map((v) => v.value),
		...numbers.map((v) => v.value),
	] :
		[...numbers.map((v) => v.value),
		...strings.map((v) => v.value),
		];
}
