import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import LockedValue from 'af-components/LockedValue';

interface OwnProps {
	selectedContactAddresses: ContactVM['addresses'];
}

interface State {
	expanded: boolean;
}

type Props = OwnProps;

class AddressList extends React.PureComponent<Props, State> {
	state: State = {
		expanded: false,
	};

	toggle = () => {
		this.setState((state) => ({ expanded: !state.expanded }));
	};

	renderAddress = (address: ContactVM['addresses'][0]) => {
		return (
			<div className="contact__address-list" key={address.addressId}>
				<Row className="row--padded">
					<Col md={18}><LockedValue label="Street Address" value={address.address.street}/></Col>
					<Col md={6}><LockedValue label="Suite/Floor" value={address.address.suite}/></Col>
				</Row>
				<Row>
					<Col md={12}><LockedValue label="City" value={address.address.city}/></Col>
					<Col md={12}><LockedValue label="State" value={address.address.state}/></Col>
				</Row>
				<Row className="row--padded">
					<Col md={6}><LockedValue label="Zip" value={address.address.zip}/></Col>
					<Col md={6}><LockedValue label="P.O. Box" value={address.address.postalOfficeBoxCode}/></Col>
					<Col md={12}><LockedValue label="Country" value={address.address.country}/></Col>
				</Row>
			</div>
		);
	};

	render() {
		const { selectedContactAddresses } = this.props;
		const { expanded } = this.state;
		return (
			<>
				<Row>
					<Col sm={24}>
						<Button className="btn--link" onClick={this.toggle}>
							<span>More Information</span>
							<span className={`icon-${expanded ? 'up' : 'down'}`}/>
						</Button>
					</Col>
				</Row>
				{expanded && selectedContactAddresses.map(this.renderAddress)}
			</>
		);
	}
}

export default AddressList;
