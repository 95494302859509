import { saveAs } from 'file-saver';
import * as html2canvas from 'html2canvas';

import { iconElementsToHTMLEntity } from 'af-utils/icon.util';

const DAILY_VIEW_SCREENSHOT_HEADER_HEIGHT = 80;

// Class Name for div container inside dropdown that has svg icons.
const BOOTSTRAP_DROPDOWN_SVG_CONTAINER_CLASS_NAME = 'css-tlfecz-indicatorContainer';

// dunno why extra height is needed, but it is
const EXTRA_HEIGHT = 240;

function _ignoreElements(element: Element) {
	if (element.id?.toLowerCase().includes('upscope')) {
		return true;
	}
	if (element.id?.toLowerCase().includes('intercom')) {
		return true;
	}
	if (element.tagName.toLowerCase() === 'script') {
		return true;
	}

	// Ignore Clear and Upside Down Caret on Unavailability Reason Dropdown
	if (element.classList.contains(BOOTSTRAP_DROPDOWN_SVG_CONTAINER_CLASS_NAME)) {
		return true;
	}

	return false;
}

export async function captureDailyViewScheduleBoard(width: number, filename: string, zoomLevel: number) {
	// 1. grab elements for capture:
	const datePicker = document.getElementsByClassName('schedule-board-daily-date-picker')[0] as HTMLElement;
	const laborStatistics = document.getElementsByClassName('labor-statistics')[0] as HTMLElement;

	const employeeToolbar = document.getElementsByClassName('schedule-board-toolbar-column employees')[0] as HTMLElement;
	const equipmentToolbar = document.getElementsByClassName('schedule-board-toolbar-column equipment')[0] as HTMLElement;
	const board = document.getElementsByClassName('schedule-board-cards-scrollable-container')[0] as HTMLElement;

	// 2. do HTML manipulations necessary for a correct capture:

	const locations = laborStatistics.children[0] as HTMLElement;
	const locationsTransform = locations.style.transform;
	locations.style.transform = 'translateX(0px)';	// need to move offset back to zero

	[laborStatistics, employeeToolbar, equipmentToolbar, board].forEach(iconElementsToHTMLEntity);

	const noCDLs = document.getElementsByClassName('sb-resource-item--no-cdl') as HTMLCollectionOf<HTMLElement>;
	const noCDLBackgroundImage = noCDLs[0].style.backgroundImage;
	Array.from(noCDLs).forEach((_element: HTMLElement) => {
		// html2canvas does not support `repeating-linear-gradient`
		_element.style.backgroundImage = 'var(--no-cdl-background-image-alternative)';
	});

	// 3. calculate dimensions:

	const headerElementHeight = DAILY_VIEW_SCREENSHOT_HEADER_HEIGHT;
	const headerHeight = DAILY_VIEW_SCREENSHOT_HEADER_HEIGHT;
	const contentsHeight = Math.max(employeeToolbar.scrollHeight, equipmentToolbar.scrollHeight, board.scrollHeight) + EXTRA_HEIGHT;
	const toolbarClientWidth = employeeToolbar.clientWidth + equipmentToolbar.clientWidth;

	const boardWidth = Math.max(board.clientWidth, locations.clientWidth);
	const clientWidth = toolbarClientWidth + board.clientWidth;
	const widthFactor = width / clientWidth;

	// 4. prepare html2canvas options and start the processes:

	const generalOptions = {
		logging: false,
		ignoreElements: _ignoreElements,
	};
	const headerOptions = {
		...generalOptions,
		height: headerHeight,
		windowHeight: headerHeight,
	};
	const contentsOptions = {
		...generalOptions,
		height: contentsHeight,
	};

	let equipmentFactor = 1;
	if (zoomLevel === 1) {
		equipmentFactor = 0.8;
	} else if (zoomLevel === 0) {
		equipmentFactor = 1;
	}
	const laborStatisticsShiftFactor = clientWidth > 2400 ? clientWidth / 700 : 1;

	const dateWidth = 500;
	let equipmentShiftFactor = 1.7;
	let boardShiftFactor = 5;

	if (board.clientWidth > 2400) {
		boardShiftFactor = clientWidth / 200;
	} else if (board.clientWidth > 2000) {
		boardShiftFactor = 2 * clientWidth / 200;
	} else if (board.clientWidth > 1500) {
		boardShiftFactor = 2;
	}

	if (toolbarClientWidth > 1500) {
		equipmentShiftFactor = 3 * clientWidth / 500;
	} else if (toolbarClientWidth > 900) {
		equipmentShiftFactor = clientWidth / 500;
	}

	const additionalEquipmentWidth = zoomLevel === 0 ? 200 : 0;

	const defaultWidth = board.clientWidth * widthFactor;
	const employeeToolbarCanvasPromise = html2canvas(employeeToolbar, {
		...contentsOptions,
		windowHeight: employeeToolbar.scrollHeight,
		windowWidth: width,
		width: employeeToolbar.clientWidth * widthFactor,
	});
	const equipmentToolbarCanvasPromise = html2canvas(equipmentToolbar, {
		...contentsOptions,
		windowHeight: equipmentToolbar.scrollHeight,
		windowWidth: defaultWidth * equipmentFactor,
		width: equipmentToolbar.clientWidth * widthFactor + additionalEquipmentWidth,
		onclone: (_document: Document) => {
			const _equipmentSection = _document.getElementsByClassName('schedule-board-toolbar-column equipment')[0] as HTMLElement;
			_equipmentSection.style.paddingLeft = `${equipmentShiftFactor * 30}px`;
		},
	});
	const boardCanvasPromise = html2canvas(board, {
		...contentsOptions,
		windowHeight: board.scrollHeight,
		windowWidth: boardWidth,
		width: boardWidth * widthFactor,
		onclone: (_document: Document) => {
			const _board = _document.getElementsByClassName('schedule-board-cards-scrollable-container')[0] as HTMLElement;
			_board.style.paddingLeft = `${boardShiftFactor * 30}px`;
		},
	});

	// 6. wait for all processes to finish:

	const [
		employeeToolbarCanvas,
		equipmentToolbarCanvas,
		boardCanvas,
	] = await Promise.all([
		employeeToolbarCanvasPromise,
		equipmentToolbarCanvasPromise,
		boardCanvasPromise,
	]);

	const toolbarCanvasWidth = employeeToolbarCanvas.width + equipmentToolbarCanvas.width;
	const fullWidth = toolbarClientWidth + boardWidth;

	const dateCanvas = await html2canvas(datePicker, {
		...headerOptions,
		width: dateWidth * widthFactor,
		onclone: (_document: Document) => {
			const _datePicker = _document.getElementsByClassName('schedule-board-daily-date-picker')[0] as HTMLElement;
			const _btn = _datePicker.children[1] as HTMLElement;

			_datePicker.style.height = `${headerElementHeight}px`;
			_datePicker.style.width = `${dateWidth * widthFactor}px`;
			_datePicker.style.paddingLeft = '8px';
			_btn.style.display = 'none';
		},
	});

	const laborStatsWidth = (fullWidth - dateWidth) * widthFactor;

	const laborStatisticsCanvas = await html2canvas(laborStatistics, {
		...headerOptions,
		windowWidth: width,
		width: laborStatsWidth,
		onclone: (_document: Document) => {
			const _laborStatistics = _document.getElementsByClassName('labor-statistics')[0] as HTMLElement;
			_laborStatistics.className = 'labor-statistics';
			_laborStatistics.style.height = `${headerElementHeight}px`;
			_laborStatistics.style.width = '100%';
			_laborStatistics.style.paddingLeft = laborStatisticsShiftFactor > 1 ? `${laborStatisticsShiftFactor * 300}px` : '200px';
		},
	});

	// 7. calculate dimensions and coordinates, and draw all canvases on a single canvas:

	const headerCanvasHeight = Math.max(dateCanvas.height, laborStatisticsCanvas.height);
	const contentsCanvasHeight = Math.max(employeeToolbarCanvas.height, equipmentToolbarCanvas.height, boardCanvas.height);
	const canvas = document.createElement('canvas');
	canvas.height = headerCanvasHeight + contentsCanvasHeight;
	canvas.width = toolbarCanvasWidth + Math.max(boardCanvas.width, laborStatisticsCanvas.width);

	const context = canvas.getContext('2d');

	if (!context) {
		throw new Error('[SCREENSHOT] Context not defined');
	}

	context.drawImage(dateCanvas, 0, 0);
	context.drawImage(laborStatisticsCanvas, dateCanvas.width, 0);
	context.drawImage(employeeToolbarCanvas, 0, headerCanvasHeight);
	context.drawImage(equipmentToolbarCanvas, employeeToolbarCanvas.width, headerCanvasHeight);
	context.drawImage(boardCanvas, toolbarCanvasWidth, headerCanvasHeight);

	// 8. revert some HTML manipulations so that the page is visually as it was:

	Array.from(noCDLs).forEach((_element: HTMLElement) => {
		_element.style.backgroundImage = noCDLBackgroundImage;
	});

	locations.style.transform = locationsTransform; // move back to where it was

	// 9. export the canvas and finish:

	await new Promise<void>((resolve) => canvas.toBlob((blob: Blob) => {
		saveAs(blob, `${filename}.png`);
		resolve();
	}, 'image/png', 1));
}
