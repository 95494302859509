import * as TimeOptionUtils from 'acceligent-shared/utils/timeOption';

export interface WorkOrderSettingsFormToModel {
	dayShiftStart: Nullable<number>;
	dayShiftEnd: Nullable<number>;
	nightShiftStart: Nullable<number>;
	nightShiftEnd: Nullable<number>;
}

export class WorkOrderSettingsRM {
	dayShiftStart: string;
	dayShiftEnd: string;
	nightShiftStart: string;
	nightShiftEnd: string;

	static toModel(form: WorkOrderSettingsRM): WorkOrderSettingsFormToModel {
		return {
			dayShiftStart: TimeOptionUtils.toNumber(form.dayShiftStart),
			dayShiftEnd: TimeOptionUtils.toNumber(form.dayShiftEnd),
			nightShiftStart: TimeOptionUtils.toNumber(form.nightShiftStart),
			nightShiftEnd: TimeOptionUtils.toNumber(form.nightShiftEnd),
		};
	}
}
