import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import * as JobActions from 'af-actions/jobs';

import JobPreviewViewModel from 'ab-viewModels/jobPreview.viewModel';

import JobInfo from 'af-root/scenes/Company/Jobs/JobsDashboard/JobInfo';

interface PathParams {
	wrId: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	job: Nullable<JobPreviewViewModel>;
}

class WorkRequestDrafts extends React.Component<Props, State> {
	state: State = {
		job: null,
	};

	async componentDidMount() {
		const { findJob, match: { params: { wrId } } } = this.props;
		const job = await findJob(+wrId);
		if (!job) {
			throw new Error('Job not found');
		}

		this.setState(() => ({ job }));
	}

	renderDashboardCardItem = (label: string, value: string, icon?: JSX.Element) => {
		return (
			<Row>
				<Col md={24}>
					<div className="dasboard-card-text-item">
						<span className="dashboard-card-text-label">{icon}{label}</span>
						<strong className="dashboard-card-text-value">{value}</strong>
					</div>
				</Col>
			</Row>
		);
	};

	renderProductionChartValues = (label: string, value: string, line?: JSX.Element) => {
		return (
			<Row>
				<Col md={24}>
					<div className="dasboard-card-production-chart-item">
						{line}
						<div>
							<div className="dasboard-card-production-chart-label">{label}</div>
							<div className="dasboard-card-production-chart-value">{value}</div>
						</div>
					</div>
				</Col>
			</Row>
		);
	};

	render() {
		const { job } = this.state;

		if (!job) {
			return null;
		}

		return (
			<div className="job-dashboard">
				<div className="form-segment">
					<Row>
						<Col md={6}>
							<JobInfo
								job={job}
								renderItem={this.renderDashboardCardItem}
							/>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		findJob: JobActions.findByIdForPreview,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(WorkRequestDrafts);
