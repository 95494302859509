import * as User from 'ab-viewModels/user.viewModel';

import { LOCALSTORAGE_MULTIPLE_SIGNINS } from 'af-constants/localstorage';

import * as TextUtil from 'ab-utils/text.util';

const _isAdminSingIn = (signIn: User.SignInData) => signIn.isPlatformAdmin;

export function getAllSignIns(): User.SignInData[] {
	const allSignInsString: Nullable<string> = localStorage.getItem(LOCALSTORAGE_MULTIPLE_SIGNINS);
	return allSignInsString ? JSON.parse(allSignInsString) : [];
}

export function getSignInForOrg(orgAlias: Nullable<string>, fallbackToAdmin: boolean = false): Nullable<User.SignInData> {
	const allSignIns: User.SignInData[] = getAllSignIns();

	let signIn = allSignIns.find((_signIn: User.SignInData) => TextUtil.isLowerCaseEqual(_signIn.orgAlias, orgAlias));

	if (!signIn && fallbackToAdmin) {
		signIn = allSignIns.find(_isAdminSingIn);
	}
	return signIn ?? null;
}

export function updateSignInForOrg(orgAlias: string, signInData: Partial<User.SignInData>, returnFalseIfDoesNotExist: boolean = false): boolean {
	const allSignIns: User.SignInData[] = getAllSignIns();

	const existingSignInIndex = allSignIns.findIndex((_signIn: User.SignInData) => TextUtil.isLowerCaseEqual(_signIn.orgAlias, orgAlias));
	if (existingSignInIndex === -1) {
		if (returnFalseIfDoesNotExist) {
			return false;
		}
		throw new Error('No sign in data for organization to update');
	}
	const data: User.SignInData = { ...allSignIns[existingSignInIndex], ...signInData };
	allSignIns.splice(existingSignInIndex, 1, data);

	localStorage.setItem(LOCALSTORAGE_MULTIPLE_SIGNINS, JSON.stringify(allSignIns));
	return true;
}

export function upsertSignInForOrg(orgAlias: string, signInData: User.SignInData) {
	const allSignIns: User.SignInData[] = getAllSignIns();

	const existingSignInIndex = allSignIns.findIndex((_signIn: User.SignInData) => TextUtil.isLowerCaseEqual(_signIn.orgAlias, orgAlias));
	if (existingSignInIndex === -1) {
		allSignIns.push(signInData);
	} else {
		const data: User.SignInData = { ...allSignIns[existingSignInIndex], ...signInData };
		allSignIns.splice(existingSignInIndex, 1, data);
	}

	localStorage.setItem(LOCALSTORAGE_MULTIPLE_SIGNINS, JSON.stringify(allSignIns));
}

export function removeSignInForOrg(orgAlias: string) {
	const allSignIns: User.SignInData[] = getAllSignIns();

	const index: number = allSignIns.findIndex((_signIn: User.SignInData) => TextUtil.isLowerCaseEqual(_signIn.orgAlias, orgAlias));
	if (index >= 0) {
		allSignIns.splice(index, 1);
	}
	localStorage.setItem(LOCALSTORAGE_MULTIPLE_SIGNINS, JSON.stringify(allSignIns));
}

export function getPlatformAdminDataFromStorage(): User.SignInData | undefined {
	const allSignIns: User.SignInData[] = getAllSignIns();
	return allSignIns.find(_isAdminSingIn);
}

export function addPlatformAdminDataToStorage(data: User.SignInData): void {
	if (!data) {
		return;
	}
	const allSignIns: User.SignInData[] = getAllSignIns();

	const adminSignInIndex: number = allSignIns.findIndex(_isAdminSingIn);

	if (adminSignInIndex < 0) {
		allSignIns.push(data);
	} else {
		allSignIns.splice(adminSignInIndex, 1, data);
	}

	localStorage.setItem(LOCALSTORAGE_MULTIPLE_SIGNINS, JSON.stringify(allSignIns));
}
