import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

export interface ReportSidebarInfo {
	name: string;
	hasCustomerSignature: boolean;
}

export const sidebarReportsMapper = (currentReportIndex: Nullable<number>, onSidebarReportClick: (index: number) => () => void) =>
	({ name, hasCustomerSignature }, index: number) => {
		const isInView = currentReportIndex === index;
		return (
			<div className={bemElement('public-report__sidebar', 'element', { active: isInView })} key={name} onClick={onSidebarReportClick(index)}>
				<div className={bemElement('public-report__sidebar__element', 'name')}>{name}</div>
				{hasCustomerSignature && <span className="icon-check"/>}
			</div>
		);
	};
