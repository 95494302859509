import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import Input from 'af-fields/Input';

import styles from '../styles.module.scss';

interface OwnProps {
	fieldName: string;
	index: number;
	orderNumber: number;
	onRemove: (index: number) => void;
	changeEditable: (index: number, editable: boolean) => void;
}

type Props = OwnProps;

const BillingContactEdit: React.FC<Props> = React.memo((props) => {
	const { index, onRemove, fieldName, changeEditable, orderNumber } = props;

	const remove = React.useCallback(() => {
		onRemove(index);
	}, [onRemove, index]);

	const setReadOnly = React.useCallback(() => {
		changeEditable(index, false);
	}, [changeEditable, index]);

	return (
		<div className={styles['billing-contacts__list__item']}>
			<Row className={styles['billing-contacts__list__item__edit']}>
				<Col sm={7}>
					<Field
						component={Input}
						id="email"
						isRequired={true}
						label={'Billing Email ' + orderNumber}
						name={`${fieldName}.email`}
						onTouchEnd={setReadOnly}
						type="text"
					/>
				</Col>

				<Col className={styles['billing-contacts__list__item__edit__actions']} sm={2}>
					<span className={`${styles['billing-contacts__list__item__edit__actions--red']}`} onClick={remove} >
						<span
							className={'icon-delete'}
						/>
					</span>
				</Col>

			</Row>
		</div>
	);
});

export default BillingContactEdit;
