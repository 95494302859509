import * as React from 'react';

import LockedValue from 'af-components/LockedValue';

import { previewSectionClass, previewSectionClassColumn2, previewSectionClassColumn4, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';

type Props = {
	isDeliverable: boolean;
	deliverableSoftware: Nullable<string>;
	deliverableAssignee: Nullable<string>;
	deliverableDeliveryMethod: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	deliverableDeliveryTimeline: Nullable<string>;
	deliverableFileFormat: Nullable<string>;
	deliverableNotes: Nullable<string>;

};

const DeliverablesSection: React.FC<Props> = (props) => {
	const {
		deliverableAssignee,
		deliverableCodeStandard,
		deliverableDeliveryMethod,
		deliverableDeliveryTimeline,
		deliverableFileFormat,
		deliverableNotes,
		deliverableSoftware,
		isDeliverable,
	} = props;

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Deliverables
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Is Deliverable" value={isDeliverable ? 'YES' : 'NO'} />
				</div>
				<div className={previewSectionClassColumn4}>
					<LockedValue label="Deliverable Notes" value={deliverableNotes} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Software" value={deliverableSoftware} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Delivery Method" value={deliverableDeliveryMethod} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Code Standard" value={deliverableCodeStandard} />
				</div>
			</div>
			<div className={previewSectionClassRow}>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="Delivery Timeline" value={deliverableDeliveryTimeline} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="File Format" value={deliverableFileFormat} />
				</div>
				<div className={previewSectionClassColumn2}>
					<LockedValue label="QA / QC Assignee" value={deliverableAssignee} />
				</div>
			</div>
		</div>
	);
};

export default React.memo(DeliverablesSection);
