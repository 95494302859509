import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'af-reducers';

type Props = ConnectedProps<typeof connector>;

const Loading: React.FC<Props> = (props) => {
	if (!props.isFetching) {
		return null;
	}

	return (
		<div id="loader-overlay">
			<div id="loader" />
		</div>
	);
};

function mapStateToProps(state: RootState) {
	return {
		isFetching: state.http.isFetching,
	};
}

const connector = connect(mapStateToProps);

export default connector(Loading);
