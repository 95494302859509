import { isValidEmail } from 'acceligent-shared/utils/email';
import { isValidFormattedPhoneNumber } from 'acceligent-shared/utils/phone';
import { isValidTextInput } from 'acceligent-shared/utils/text';

import AccountPermissionTemplate from 'acceligent-shared/enums/accountPermissionTemplate';

import AccountFM from '../Form/formModel';

export function validate(values: AccountFM) {
	const errors: ValidationErrors = {};
	const { user = {} as AccountFM['user'] } = values;

	if (!isValidTextInput(user?.uniqueId ?? null)) {
		errors.uniqueId = 'Unique ID is required';
	}

	if (!isValidTextInput(user?.email ?? null) && !isValidTextInput(values.user?.phoneNumber ?? null)) {
		errors.email = 'Email or mobile phone required';
	}

	if (user?.email && values.user?.email && !isValidEmail(values.user?.email)) {
		errors.email = 'Invalid email address';
	}

	if (user?.phoneNumber && values.user?.phoneNumber) {
		if (!isValidFormattedPhoneNumber(values.user?.phoneNumber)) {
			errors.phoneNumber = 'Invalid mobile phone';
		}
	}

	if (!isValidTextInput(user?.firstName ?? null)) {
		errors.firstName = 'First Name is required';
	}

	if (!isValidTextInput(user?.lastName ?? null)) {
		errors.lastName = 'Last Name is Required';
	}

	if (!values.accountTemplateId || values.accountTemplateId === AccountPermissionTemplate.CUSTOM) {
		errors.accountTemplateId = 'Account template is Required';
	}

	return Object.keys(errors).length ? { user: errors } : errors;
}
