import * as React from 'react';
import { connect } from 'react-redux';

import { ColorPalette, ExtendedColorPalette, DefaultColor } from 'acceligent-shared/enums/color';

import { RootState } from 'af-reducers';

import * as ColorUtils from 'ab-utils/color.util';

interface SkillGridItem {
	color: ExtendedColorPalette;
	show: boolean;
}

interface OwnProps {
	withBackground?: boolean;
	skills?: { color: ExtendedColorPalette; }[];
	showAll?: boolean;
	enableCdlIndicator?: boolean;
	size?: 'small' | 'medium' | 'large';
	forceCdlIndicator?: boolean;
}

interface StateProps {
	/** default: [] */
	skillColorGrid?: ColorPalette[][];
	cdlInSkillsGrid: boolean;
}

interface State {
	skillGrid: SkillGridItem[][];
}

type Props = OwnProps & StateProps;

// company object needs to be loaded in redux form
class SkillsColorGrid extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		enableCdlIndicator: false,
		size: 'small',
		showAll: false,
		skillColorGrid: [],
		withBackground: true,
		forceCdlIndicator: false,
	};

	state: State = {
		skillGrid: [],
	};

	static getDerivedStateFromProps(props: Props): State {
		const { skillColorGrid, cdlInSkillsGrid, enableCdlIndicator, skills, showAll, forceCdlIndicator } = props;

		const skillTypes = {};
		if (skills?.length) {
			for (const _skill of skills) {
				if (_skill?.color) {
					skillTypes[_skill.color.toUpperCase()] = true;
				}
			}
		}

		const skillGrid: SkillGridItem[][] = [];
		for (let _rowIndex = 0; _rowIndex < skillColorGrid!.length; _rowIndex++) {
			skillGrid.push([]);
			for (const _color of skillColorGrid![_rowIndex]) {
				skillGrid[_rowIndex].push({
					color: _color,
					show: !!showAll || skillTypes[_color.toUpperCase()],
				});
			}
		}
		if (forceCdlIndicator || (cdlInSkillsGrid && enableCdlIndicator && skillGrid[0] && skillGrid[0][0])) {
			skillGrid[0][0].color = DefaultColor.CDL_AS_SKILL;
			skillGrid[0][0].show = true;
		}

		return {
			skillGrid,
		};
	}

	render() {
		const { size, withBackground } = this.props;
		const { skillGrid } = this.state;

		return (
			<div className={`skills ${skillGrid.length && withBackground ? 'skills--with-background' : ''}`}>
				{skillGrid.map((row: SkillGridItem[], i1: number) => (
					<div className="skills__row" key={i1}>
						{row.map((item: SkillGridItem, i2: number) => (
							<div className={`skills__wrapper skills__wrapper--${size}`} key={i2}>
								<div className={`skills__skill skills__skill--square ${item.show ? ColorUtils.getColorBackgroundClass(item.color) : ''}`} />
							</div>
						))}
					</div>
				))}
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;

	return {
		skillColorGrid: company?.skillColorGrid ?? undefined,
		cdlInSkillsGrid: company?.cdlInSkillsGrid ?? false,
	};
}

export default connect<StateProps, OwnProps>(mapStateToProps)(SkillsColorGrid);
