import { TypedColumn, FinalState } from 'react-table-6';

import { Column as Column6 } from 'af-components/Table6';
import { Column } from 'af-components/Table/types';

import TableSettingsRequestModel from 'ab-requestModels/tableSettings.requestModel';

import { DEFAULT_TABLE_PAGE_SIZE } from 'ab-constants/value';

import { TableQuery, TableSortBy } from 'ab-common/dataStructures/tableQuery';

import * as ArrayUtil from 'ab-utils/array.util';

/** logic copied form: https://github.com/tannerlinsley/react-table/blob/master/src/utils.js */
export function getByAccessor<T>(data: T, accessor: Column6<T>['accessor'], defaultValue: null | string | number = null): null | string | number {
	if (!accessor) {
		return defaultValue;
	}
	if (typeof accessor === 'function') {
		return accessor(data);
	}
	const path = _makePathArray(accessor);
	let val: undefined | string | number = undefined;
	try {
		val = path.reduce((cursor, pathPart) => cursor[pathPart], data);
	} catch (e) {
		// continue regardless of error
	}
	return typeof val !== 'undefined' ? val : defaultValue;
}

/** logic copied form: https://github.com/tannerlinsley/react-table/blob/master/src/utils.js */
function _makePathArray(path: string | string[]) {
	return _flattenDeep(path)
		.join('.')
		.replace(/\[/g, '.')
		.replace(/\]/g, '')
		.split('.');
}

/** logic copied form: https://github.com/tannerlinsley/react-table/blob/master/src/utils.js */
function _flattenDeep(arr: string | string[], newArr: string[] = []) {
	if (!Array.isArray(arr)) {
		newArr.push(arr);
	} else {
		for (let i = 0; i < arr.length; i += 1) {
			_flattenDeep(arr[i], newArr);
		}
	}
	return newArr;
}

export function getDefaultTable6Settings<T>(tableName: string, accountId: Nullable<number>, columns: TypedColumn<T>[]): TableSettingsRequestModel {
	return {
		tableName,
		accountId,
		pageSize: 100,
		columnSettings: ArrayUtil.filterMap(
			columns,
			(_col) => !!_col.accessor,
			(_col) => ({
				name: _col.accessor as string,
				visible: true,
				width: _col.width ?? undefined,
			})
		),
		sort: [],
	};
}

export function getDefaultTableSettings<T>(
	tableName: string,
	accountId: Nullable<number>,
	columns: Column<T>[],
	defaultPageSize?: number
): TableSettingsRequestModel {
	return {
		tableName,
		accountId,
		pageSize: defaultPageSize ?? DEFAULT_TABLE_PAGE_SIZE,
		columnSettings: ArrayUtil.filterMap(
			columns,
			(_col) => !!_col.accessor,
			(_col) => ({
				name: _col.id,
				visible: true,
				width: _col.size ?? undefined,
			})
		),
		sort: [],
	};
}

export function areColumnNamesEqual(tableSettings1: TableSettingsRequestModel, tableSettings2: TableSettingsRequestModel): boolean {
	if (tableSettings1.columnSettings.length !== tableSettings2.columnSettings.length) {
		return false;
	}
	const tableSettings1Set = new Set(tableSettings1.columnSettings.map(({ name }) => name));

	let areEqual = true;
	for (const _ts2 of tableSettings2.columnSettings) {
		if (!tableSettings1Set.has(_ts2.name)) {
			areEqual = false;
			break;
		}
	}

	return areEqual;
}

export function updateTableSettingsColumns(
	tableSettings: TableSettingsRequestModel,
	columnSettings: TableSettingsRequestModel['columnSettings']
): TableSettingsRequestModel & { accountId: number; } {
	type _ReduceResult = { columnNames: string[]; updatedColumnSettings: TableSettingsRequestModel['columnSettings']; };

	if (!tableSettings.accountId) {
		throw new Error('Table settings not available for ACS');
	}

	const { columnNames, updatedColumnSettings } = columnSettings.reduce(
		(_acc: _ReduceResult, _column, _index) => {
			_acc.columnNames.push(_column.name);
			if (_column?.name === tableSettings.columnSettings[_index]?.name) {
				// same column name at same place => persist old settings
				_acc.updatedColumnSettings.push(tableSettings.columnSettings[_index]);
			} else {
				_acc.updatedColumnSettings.push(_column);
			}
			return _acc;
		},
		{ columnNames: [], updatedColumnSettings: [] }
	);
	return {
		...tableSettings,
		accountId: tableSettings.accountId,
		columnSettings: updatedColumnSettings,
		sort: tableSettings.sort.filter((_sortRule) => columnNames.includes(_sortRule.id)),
	};
}

export const getTableRequestModel = <T>(
	tableState: Partial<FinalState<T>>,
	getSortBy?: (sortBy: TableSortBy[]) => TableSortBy[],
	filterText?: string
): TableQuery => {
	return tableState ? {
		pageSize: tableState.pageSize,
		page: tableState.page ?? 0,
		sortBy: getSortBy ? getSortBy(tableState.sorted!) : tableState.sorted,
		filterByText: filterText,
	} : new TableQuery();
};

export const generateTabName = (tableName: string, tabLabel: string): string => {
	return `${tableName}#${tabLabel}`;
};
