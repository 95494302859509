import WorkRequest from 'acceligent-shared/models/workRequest';
import WorkOrder from 'acceligent-shared/models/workOrder';

import WorkOrderReviewStatus, { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';

import { getShortAddress } from 'acceligent-shared/utils/address';
import * as TextUtils from 'acceligent-shared/utils/text';

import { SearchableModel } from 'acceligent-shared/dtos/web/view/searchableModel';

import * as CodeUtils from 'ab-utils/codes.util';

class JobReportListVM implements SearchableModel {

	static matches = TextUtils.matches;

	workRequestId: number;
	code: string;
	jobCode: string;
	title: Nullable<string>;
	areAllReportsInAccounting: boolean;
	customer: Nullable<string>;
	address: Nullable<string>;
	_desc: string;

	constructor(workRequest: WorkRequest) {
		this.workRequestId = workRequest.id;
		this.code = CodeUtils.workRequestCode(workRequest.year, workRequest.code);
		this.jobCode = workRequest.jobCode ?? this.code;
		this.title = workRequest.title;
		this.customer = workRequest.customerCompanyName;
		this.areAllReportsInAccounting = workRequest.workOrders.every(JobReportListVM._isInAccountingApprovedOrHigher);
		this.address = getShortAddress(workRequest.travelLocation);
		this._desc = [this.jobCode, this.title, this.customer, this.address].filter(Boolean).join(' ');
	}

	static bulkConstructor = (jobs: WorkRequest[]) => jobs.map(JobReportListVM._constructorMap);

	private static _constructorMap = (job: WorkRequest) => new JobReportListVM(job);

	private static _isInAccountingApprovedOrHigher = (workOrder: WorkOrder) => (
		(workOrder.reviewLevel === WorkOrderReviewLevel.LEVEL_3
		&& workOrder.reviewStatus === WorkOrderReviewStatus.APPROVED)
		|| workOrder.reviewStatus === WorkOrderReviewStatus.FINAL
	);
}

export default JobReportListVM;
