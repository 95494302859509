import * as path from 'path';
import { nanoid } from 'nanoid';

import FileType, { IMAGE_FILE_TYPES } from 'acceligent-shared/enums/fileType';

import { UNIQUE_ID_SIZE } from 'ab-constants/value';

/**
 * Checks if the given extension is image extension
 */
export const isImageExt = (ext: FileType) => IMAGE_FILE_TYPES.includes(ext);

/**
 * Checks if the given filename contains image extension
 */
export const isImageFile = (filename: string) => IMAGE_FILE_TYPES.some((fileType: FileType) => filename.includes(fileType));

/**
 * Checks if the given filename contains PDF extension
 */
export const isPDFFile = (filename: string) => filename.includes(FileType.PDF);

/**
 * Returns path to the company directory.
 * NOTE: Does not end with forwardslash
 * @example
 * getCompanyDirectoryPath(1, 2, 'folder1') => "1/2/folder1"
 * @param organizationId
 * @param companyId
 * @param directories directory name or hierarchy of directories
 */
export const getCompanyDirectoryPath = (organizationId: number, companyId: number, ...directories: string[]): string => {
	if (!organizationId || !companyId) {
		throw new Error('Missing organizationId or companyId');
	}

	return [
		organizationId,
		companyId,
		...directories,
	].filter(Boolean).join('/');
};

/**
 * Returns path to the organization directory.
 * NOTE: Does not end with forwardslash
 * @example
 * getOrganizationDirectoryPath(1, 'folder1') => "1/folder1"
 * @param organizationId
 * @param directory directory name or hierarchy of directories
 */
export const getOrganizationDirectoryPath = (organizationId: number, ...directories: string[]): string => {
	if (!organizationId) {
		throw new Error('Missing organizationId');
	}

	return [
		organizationId,
		...directories,
	].filter(Boolean).join('/');
};

/**
 * Generates hashed file name
 * @param {string} name original file name
 * @example hashFileName('somefile.jpg') => "id234ijs.jpg"
 */
export const hashFileName = (name: string) => {
	const file = path.parse(name);
	return nanoid(UNIQUE_ID_SIZE) + file.ext;
};

