import * as React from 'react';
import { CustomRouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { Treebeard } from 'react-treebeard';

import * as EquipmentCostActions from 'af-actions/equipmentCost';

import { EquipmentCostEditableTree, EquipmentCostCategoryItem, CreateEquipmentCostTreeNode } from 'ab-viewModels/editableTree.viewModel';

import * as ColorUtils from 'ab-utils/color.util';

import EquipmentCostCategoryForm from './EquipmentCostCategoryForm';

export const SELECTABLE_LEVEL = 2;

interface OwnProps {
	onCategorySelect: (equipmentCostCategory: EquipmentCostEditableTree) => void;
	onToggle: (isVisible: boolean) => void;
	onCreate: (values: EquipmentCostCategoryItem) => void;
}

interface DispatchProps {
	findAllForCompanyEditableTree: typeof EquipmentCostActions.findAllForCompanyEditableTree;
}

type ConnectOwnProps = OwnProps & CustomRouteComponentProps;

type Props = ConnectOwnProps & ResolveThunks<DispatchProps>;

interface State {
	isVisible: boolean;
	selectedCategory: Nullable<EquipmentCostEditableTree>;
	allowAdd: boolean;
	treeData: Nullable<EquipmentCostEditableTree[]>;
}

class TreeView extends React.PureComponent<Props, State> {

	state: State = {
		isVisible: false,
		selectedCategory: null,
		allowAdd: true,
		treeData: null,
	};

	private decorators = {
		Toggle: (props: EquipmentCostEditableTree) => {
			if (props.toCreate) {
				return null;
			}

			return (
				<span className="pointer p-l-m p-r-s" onClick={props.isCategory ? this.toggle.bind(null, props) : null}>
					{
						props.isCategory ?
							<span className={`icon text-grey text-big ${props.toggled ? 'icon-folder_open' : 'icon-folder_closed'}`} /> :
							<span className={`icon icon-cost text-big ${props.active ? 'text-grey' : ColorUtils.getColorTextClass(props.color)}`} />
					}
				</span>
			);
		},
		Header: (props: EquipmentCostEditableTree) => {
			if (props.toCreate) {
				if (!props.parent) {
					throw new Error('Parent not defined');
				}
				return (
					<EquipmentCostCategoryForm
						onCreateCategory={this.onCreateCategory}
						parent={props.parent}
						removeAddCategoryForm={this.removeAddCategoryForm}
					/>
				);
			}
			return (
				<span className="maximize-width pointer treebeard-item-label" onClick={this.select.bind(null, props)}>
					{
						props.level === SELECTABLE_LEVEL &&
						<span className={`color-square m-r-s ${ColorUtils.getColorBackgroundClass(props.color)}`} />
					}
					{props.name}
					{
						props.isCategory &&
						<span className="text-grey m-l-s">{props.count}</span>
					}
				</span>
			);
		},
		Container: (props) => {
			const node: EquipmentCostEditableTree = props.node;

			const nodeStyle = {
				marginLeft: -19 * node.level + 'px',
				paddingLeft: 19 * node.level + 'px',
			};

			return (
				<span className={`tree-node ${node.active ? 'active-leaf' : ''}`} style={nodeStyle}>
					<props.decorators.Toggle {...node} />
					<props.decorators.Header {...node} />
				</span>
			);
		},
	};

	async componentDidMount() {
		const { findAllForCompanyEditableTree } = this.props;

		const { nodes } = await findAllForCompanyEditableTree();
		this.setState(() => ({ treeData: nodes }));
	}

	_findNodeById = (nodeId: number, nodes: Nullable<EquipmentCostEditableTree[]>) => {
		if (!nodes) {
			return null;
		}
		let leaf: Nullable<EquipmentCostEditableTree>;
		return nodes?.find((_node: EquipmentCostEditableTree) => {
			if (_node.nodeId === nodeId) {
				return true;
			}
			leaf = this._findNodeById(nodeId, _node.children ?? null) ?? null;
		});
		return leaf;
	};

	onCreateCategory = (value: CreateEquipmentCostTreeNode) => {
		const { onCreate } = this.props;
		onCreate(value.item);
		this._replaceFormWithNodeAfterCreate(value.node, this.state.treeData);
		this.setState({ allowAdd: true });
		// TODO: rerender properly
	};

	removeAddCategoryForm = async () => {
		const { treeData } = this.state;
		const { children } = this._removeCreateForm(treeData);

		this.setState({ treeData: children ? [...children] : [], allowAdd: true });
	};

	_toggle = (level: Nullable<EquipmentCostEditableTree[]>, node: EquipmentCostEditableTree): boolean => {
		if (!level) {
			return false;
		}

		return level?.some((_child: EquipmentCostEditableTree) => {
			if (_child.nodeId === node.nodeId) {
				_child.toggled = !_child.toggled;
				return true;
			}
			return this._toggle(_child.children, node);
		});
	};

	toggle = async (node: EquipmentCostEditableTree) => {
		const { treeData } = this.state;

		if (!node.isCategory) {
			return;
		}

		this._toggle(treeData, node);

		this.setState(() => ({ treeData: treeData ? [...treeData] : [] }));
	};

	_select = (level: Nullable<EquipmentCostEditableTree[]>, node: Nullable<EquipmentCostEditableTree>): void => {
		level?.forEach((_child: EquipmentCostEditableTree) => {
			if (!node) {
				console.error('Node not found');
				return;
			}

			_child.active = (_child.nodeId === node.nodeId) ? !_child.active : false;
			this._select(_child.children, node);
		});
	};

	select = async (node: Nullable<EquipmentCostEditableTree>) => {
		const { treeData, selectedCategory } = this.state;

		if (!node) {
			throw new Error('Node not found');
		}

		this._select(treeData, node);

		// If it's the same, it has been deselected
		const isDeselected = selectedCategory && node.nodeId === selectedCategory.nodeId;

		this.setState(() => ({ treeData: treeData ? [...treeData] : [], selectedCategory: isDeselected ? null : node }));
	};

	_addCategoryForm = (level: Nullable<EquipmentCostEditableTree[]>): boolean => {
		const { selectedCategory } = this.state;

		if (!level) {
			return false;
		}

		return level.some((_child: EquipmentCostEditableTree) => {
			if (_child.nodeId === selectedCategory?.nodeId) {
				_child.children?.splice(0, 0, { toCreate: true, parent: EquipmentCostCategoryItem.fromTree(_child) } as EquipmentCostEditableTree);
				_child.toggled = true;
				return true;
			}
			return this._addCategoryForm(_child.children ?? null);
		});
	};

	_removeCreateForm = (children: Nullable<EquipmentCostEditableTree[]>): { children: Nullable<EquipmentCostEditableTree[]>; found: boolean; } => {
		const found = children?.some((_child: EquipmentCostEditableTree) => _child.toCreate);

		if (found && children) {
			return {
				children: children.filter((_child: EquipmentCostEditableTree) => !_child.toCreate),
				found: true,
			};
		} else {
			const _found = children?.some((_child: EquipmentCostEditableTree) => {
				const result = this._removeCreateForm(_child.children ?? null);
				_child.children = result.children ?? null;
				return result.found;
			});

			return {
				children: children ?? null,
				found: !!_found,
			};
		}
	};

	_replaceFormWithNodeAfterCreate = (node: EquipmentCostEditableTree, children: Nullable<EquipmentCostEditableTree[]>) => {
		const found = children?.some((_child: EquipmentCostEditableTree) => _child.toCreate);

		if (found && children) {
			children.shift();
			children.unshift(node);
			children = [...children];
			return true;
		} else {
			return (children ?? []).some((_child: EquipmentCostEditableTree) => this._replaceFormWithNodeAfterCreate(node, _child.children ?? null));
		}
	};

	addCategoryForm = async () => {
		const { treeData, allowAdd } = this.state;

		if (!allowAdd) {
			return;
		}

		this._addCategoryForm(treeData);

		this.setState(() => ({ treeData: treeData ? [...treeData] : [], allowAdd: false }));
	};

	toggleTreeView = async () => {
		const { isVisible } = this.state;
		const { onToggle } = this.props;

		if (isVisible) {
			await this.hideTreeView();
			return;
		}
		await this.setState(() => {
			const newVisibility = true;
			onToggle(newVisibility);
			return { isVisible: newVisibility };
		});
	};

	hideTreeView = async () => {
		const { onToggle } = this.props;

		await this.removeAddCategoryForm();
		await this.setState({ isVisible: false });
		await onToggle(false);
	};

	selectCategory = async () => {
		const { onCategorySelect } = this.props;
		const { selectedCategory } = this.state;

		if (!selectedCategory) {
			throw new Error('No category selected');
		}

		await onCategorySelect(selectedCategory);
		await this.hideTreeView();
	};

	render() {
		const { treeData } = this.state;
		const { isVisible, allowAdd, selectedCategory } = this.state;

		const enableAdd = allowAdd && selectedCategory && selectedCategory.level < SELECTABLE_LEVEL;
		const enableSelect = selectedCategory && selectedCategory.level === SELECTABLE_LEVEL;

		return (
			<div className="treeview">
				<span
					className={`treeview__toggle ${isVisible ? 'treeview__toggle--active' : ''}`}
					onClick={this.toggleTreeView}
				>
					<span className={`icon icon-list ${isVisible ? 'text-white' : 'text-grey'}`} />
				</span>
				{
					isVisible &&
					<div className="treeview__active-border">
						<div className="treeview__header">
							<span className="treeview__title">
								EQUIPMENT COSTS
							</span>
							<div className="justify-end">
								<span
									className={`btn btn--icon btn-success ${!enableAdd ? 'disabled' : ''}`}
									onClick={enableAdd ? this.addCategoryForm : undefined}
								>
									<span className="icon icon-plus" />
								</span>
								<span
									className="btn btn--icon btn-info"
									onClick={this.hideTreeView}
								>
									<span className="icon icon-close" />
								</span>
								<span
									className={`btn btn--icon btn-primary ${!enableSelect ? 'disabled' : ''}`}
									onClick={enableSelect ? this.selectCategory : undefined}
								>
									<span className="icon icon-check" />
								</span>
							</div>
						</div>
						<div className="treeview__treebeard">
							<Treebeard
								data={treeData}
								decorators={this.decorators}
							/>
						</div>
					</div>
				}
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllForCompanyEditableTree: EquipmentCostActions.findAllForCompanyEditableTree,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	withRouter,
	connect<null, DispatchProps, ConnectOwnProps>(null, mapDispatchToProps())
);

export default enhance(TreeView);
