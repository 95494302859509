import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import CDLStatus, { CDLStatusLabel } from 'acceligent-shared/enums/cdlStatus';
import { TimePeriodSpanLabel } from 'acceligent-shared/enums/timePeriodSpan';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import { RootState } from 'af-reducers';

import * as SkillActions from 'af-actions/skill';
import * as KPIActions from 'af-actions/kpi';
import * as CompanyActions from 'af-actions/companies';

import CLIENT from 'af-constants/routes/client';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { SkillViewModel, SkillOptionViewModel, NO_SKILLS_OPTION } from 'ab-viewModels/skill.viewModel';
import { LaborUtilizationViewModel, LaborUtilizationDataViewModel } from 'ab-viewModels/laborUtilization.viewModel';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import PlainMultiselectDropdown from 'af-fields/PlainMultiselectDropdown';

import Table, { TabProps, Column } from 'af-components/Table6';
import MonetaryCell from 'af-components/Table6/Cells/MonetaryCell';
import SkillsCell from 'af-components/Table6/Cells/SkillsCell';
import SkillsColorGrid from 'af-components/SkillsColorGrid';
import Dropdown from 'af-components/Controls/Dropdown';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import Breadcrumbs from 'af-components/Breadcrumbs';
import TableComponent from 'af-components/Table6/Table';
import InfiniteScroll from 'af-components/ScrollToLoad';

import { downloadCSV } from 'af-utils/csv.utils';
import { getTableRequestModel } from 'af-utils/table.utils';

import * as ColorUtil from 'ab-utils/color.util';

import WorkingCell from '../UtilizationShared/WorkingCell';
import InfoCard from './InfoCard';

interface CdlOption {
	id: CDLStatus;
	name: CDLStatusLabel;
}

const cdlOptions: CdlOption[] = Object.keys(CDLStatus).map((_cdlStatus: CDLStatus) => ({ id: _cdlStatus, name: CDLStatusLabel[_cdlStatus] }));

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	utilizationData: Nullable<LaborUtilizationDataViewModel>;
	skills: SkillOptionViewModel[];
	selectedSkills: SkillOptionViewModel[];
	selectedCdlOption: Nullable<CdlOption>;
}

class LaborUtilizationTable extends React.PureComponent<Props, State> {
	state: State = {
		utilizationData: null,
		skills: [],
		selectedSkills: [],
		selectedCdlOption: null,
	};

	columns: Column<LaborUtilizationViewModel>[] = [
		{
			Header: 'Name',
			accessor: 'fullName',
			sortable: true,
			Cell: ({ original }) => <strong>{original.fullName}</strong>,
		},
		{
			Header: undefined,	// set in `tabs`
			accessor: 'timePeriodId',
			sortable: true,
			Cell: ({ original }) => original.timePeriodId || <EmptyCell />,
		},
		{
			Header: 'CDL',
			accessor: 'cdlStatus',
			sortable: false,
			Cell: ({ original }) => CDLStatusLabel[original.cdlStatus],
		},
		{
			Header: 'Skills',
			accessor: 'skills',
			sortable: false,
			Cell: ({ original }) => <SkillsCell skills={original.skills} />,
		},
		{
			Header: 'Working',
			accessor: 'daysAssigned',
			sortable: true,
			Cell: WorkingCell,
		},
		{
			Header: 'Daily Revenue',
			accessor: 'dailyRevenue',
			sortable: false,
			Cell: MonetaryCell,
		},
		{
			Header: 'Total Revenue',
			accessor: 'totalRevenue',
			sortable: false,
			Cell: MonetaryCell,
		},
	];

	private _table: Nullable<TableComponent<LaborUtilizationViewModel>> = null;
	private _list: Nullable<InfiniteScroll<LaborUtilizationViewModel>> = null;

	static getLaborUtilizationTable = (
		data: Nullable<LaborUtilizationDataViewModel>,
		selectedSkillIds: Nullable<number[]>,
		cdlOption: Nullable<CDLStatus>,
		tableRequestModel: TableQuery
	) => {
		const sanitizedRequestModel = new TableQuery(tableRequestModel);

		return data ? LaborUtilizationDataViewModel.getTable(
			sanitizedRequestModel,
			selectedSkillIds,
			cdlOption,
			data
		) : {} as TableViewModel<LaborUtilizationViewModel>;
	};

	async componentDidMount() {
		const { getCompany, findAllForDropdown, fetchLaborUtilizationData } = this.props;

		await getCompany();
		const [
			skills,
			utilizationData,
		] = await Promise.all([
			findAllForDropdown(),
			fetchLaborUtilizationData(),
		]);
		this.setState(() => ({ skills: [NO_SKILLS_OPTION, ...skills], utilizationData }));
	}

	componentDidUpdate() {
		if (this._table) {
			this._table.refreshTable();
			const tableRequestModel = getTableRequestModel(this._table.state);
			this.fetchTable(tableRequestModel);
		} else if (this._list) {
			this._list.refreshList();
		}
	}

	fetchTable = async (tableRequestModel: TableQuery) => {
		const { selectedSkills, selectedCdlOption, utilizationData } = this.state;

		return LaborUtilizationTable.getLaborUtilizationTable(
			utilizationData,
			selectedSkills.map((_skillOption) => _skillOption.id),
			selectedCdlOption?.id ?? null,
			tableRequestModel
		) ?? {} as TableViewModel<LaborUtilizationViewModel>;
	};

	onTableMount = (table, list) => {
		this._table = table;
		this._list = list;
	};

	onRowClick = ({ original }: { original: LaborUtilizationViewModel; }) => {
		if (original?.id) {
			this.goToEmployeePreview(original);
		}
	};

	goToEmployeePreview = async (original: LaborUtilizationViewModel) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.PREVIEW(original.id.toString(), orgAlias, companyName));
	};

	goToEmployeeStatusHistory = async (original: LaborUtilizationViewModel) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.STATUS_HISTORY(original.id.toString(), orgAlias, companyName));
	};

	onValueChange = async (selectedSkills: SkillOptionViewModel[]): Promise<void> => {
		this.setState(() => ({
			selectedSkills,
		}));
	};

	renderLaborSkillOptionItem = (option: SkillViewModel) => {
		const { color, name } = option;

		return (
			<span className="utilization-view__option-item">
				<div className={`utilization-view__option-color-preview ${ColorUtil.getColorBackgroundClass(color)}`} />
				<span>{name}</span>
			</span>
		);
	};

	renderCdlSelectedItem = (optionItem: CdlOption) => {
		if (optionItem === null) {
			return <span className="text-grey">CDL</span>;
		}
		return <>{optionItem.name}</>;
	};

	renderCdlMenuItem = (optionItem: CdlOption) => {
		if (optionItem === null) {
			return <></>;
		}
		return <>{optionItem.name}</>;
	};

	onCdlItemChange = async (optionItem: CdlOption) => this.setState(() => ({ selectedCdlOption: optionItem }));

	renderSelectedLaborPlaceholderSkillOptionItem = (skills: SkillViewModel[]) => {
		return (
			<div className="utilization-view__selected-labor-skills">
				<SkillsColorGrid skills={skills} />
				<span>Skills</span>
			</div>
		);
	};

	additionalFilter = () => {
		const { skills, selectedSkills } = this.state;
		return (
			<>
				<Dropdown<CdlOption>
					hasBlankOption={true}
					id="cdl-filter"
					isWhite={true}
					labelKey="name"
					onValueChange={this.onCdlItemChange}
					options={cdlOptions}
					renderMenuItem={this.renderCdlMenuItem}
					renderSelected={this.renderCdlSelectedItem}
					valueKey="id"
					withCaret={true}
				/>
				<PlainMultiselectDropdown
					containerClassName="utilization-view__labor-skills-dropdown"
					id="multiselectDropdownLaborUtilizationView"
					isWhite={true}
					labelKey="name"
					onValueChange={this.onValueChange}
					options={skills}
					renderMenuItem={this.renderLaborSkillOptionItem}
					renderSelected={this.renderSelectedLaborPlaceholderSkillOptionItem}
					selected={selectedSkills}
					valueKey="id"
					withCaret={true}
				/>
			</>
		);
	};

	onDownloadCSVClick = async () => {
		const { companyName } = this.props;
		const { utilizationData } = this.state;

		downloadCSV(LaborUtilizationViewModel.toCSVData(utilizationData?.rows ?? []), `${companyName}_labor-utilization.csv`);
	};

	tabs = (): TabProps<LaborUtilizationViewModel>[] => {
		const { utilizationData } = this.state;

		this.columns[1].Header = utilizationData?.timePeriod && TimePeriodSpanLabel[utilizationData.timePeriod];

		return [
			{
				label: 'Labor Utilization View',
				columns: this.columns,
				hasSearchInput: true,
				searchLabel: 'Employee',
				additionalFilter: this.additionalFilter,
				fetch: this.fetchTable,
				buttons: [{
					type: TableButtonType.EXPORT,
					hasPermission: true,
					onClick: this.onDownloadCSVClick,
				}],
				onRowClick: this.onRowClick,
				rowActions: [
					{
						label: 'Preview',
						action: this.goToEmployeePreview,
						shouldRefresh: false,
					},
					{
						label: 'Status History',
						action: this.goToEmployeeStatusHistory,
						shouldRefresh: false,
					},
				],
			},
		];
	};

	breadcrumbs = () => [{ label: 'Labor Utilization' }];

	render() {
		const { utilizationData } = this.state;
		return (
			<div className="utilization-view form-segment">
				<Breadcrumbs items={this.breadcrumbs()} />
				<InfoCard
					daysAssigned={utilizationData?.daysAssigned ?? 0}
					daysAvailable={utilizationData?.daysAvailable ?? 0}
					daysUnavailable={utilizationData?.daysUnavailable ?? 0}
					targetProfit={utilizationData?.targetProfit ?? 0}
					totalDays={utilizationData?.totalDays ?? 0}
					totalRevenue={utilizationData?.totalRevenue ?? 0}
				/>
				<Table
					onMount={this.onTableMount}
					tableName={TableNameEnum.LABOR_UTILIZATION}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		getCompany: CompanyActions.getCompany,
		findAllForDropdown: SkillActions.findAllForDropdown,
		fetchLaborUtilizationData: KPIActions.fetchLaborUtilizationData,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(LaborUtilizationTable);
