import * as React from 'react';

import SubmitButton from 'af-components/SubmitButton';
import Tooltip from 'af-components/Tooltip';

import { bemBlock } from 'ab-utils/bem.util';

import { ButtonData } from '../../types';

interface OwnProps {
	actionKey: ButtonData['actionKey'];
	icon: ButtonData['icon'];
	label: string;
	onClick: ButtonData['onClick'];
	showSmallButtons: boolean;
	id?: string;
}

const PrimaryButton = ({ showSmallButtons, label, icon, actionKey, onClick, id }: OwnProps) => {
	const showIcon = showSmallButtons && !!icon;

	const primaryButtonLabel = (
		<Tooltip message={label} placement="bottom">
			{showIcon ? <span className={`icon-${icon}`} /> : <span>{label}</span>}
		</Tooltip>
	);
	const primaryButtonClass = bemBlock('btn', { icon: showIcon});

	return (
		<SubmitButton
			className={primaryButtonClass}
			id={id}
			label={primaryButtonLabel}
			onClick={onClick}
			submitKey={actionKey}
			variant="primary"
		/>
	);
};

export default React.memo(PrimaryButton);
