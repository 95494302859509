import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

export default class Loading extends React.Component {

	render() {
		return (
			<div className="form-segment feedback-loading p-xl">
				<div className="form-box form-box--midi">
					<Row className="p-l">
						<Col className="loading-box-field rounded standalone" sm={2} />
						<Col className="loading-box-field standalone" sm={6} />
						<Col sm={12} />
						<Col className="loading-box-field standalone" sm={4} />
					</Row>
					<hr />
					<Row>
						<Col sm={12}>
							<Col className="loading-box-field" sm={12} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
						</Col>
						<Col sm={12}>
							<Col className="loading-box-field" sm={12} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<Col className="loading-box-field" sm={12} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
						</Col>
						<Col sm={12}>
							<Col className="loading-box-field" sm={12} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
							<Col className="loading-box-row" sm={18} />
						</Col>
					</Row>
					<hr />
					<Row className="p-l">
						<Col className="loading-box-field rounded" sm={4} />
						<Col sm={20} />
					</Row>
				</div>
			</div>
		);
	}
}
