import * as React from 'react';

import Details from './Details';
import JobPicker from './JobPicker';
import ReportTypes from './ReportTypes';

interface OwnProps {
	createNewJob: (jobCode: string) => void;
	onDateChange: (date: string) => void;
	resetTimer: () => void;
	setIsJobChanged: () => void;
}

type Props = OwnProps;

class WorkOrderHeader extends React.PureComponent<Props> {

	render() {
		const { resetTimer, createNewJob, onDateChange, setIsJobChanged } = this.props;

		return (
			<div className="form-box">
				<JobPicker
					createNewJob={createNewJob}
					resetTimer={resetTimer}
					setIsJobChanged={setIsJobChanged}
				/>
				<Details
					onDateChange={onDateChange}
					resetTimer={resetTimer}
				/>
				<ReportTypes resetTimer={resetTimer} />
			</div>
		);
	}
}

export default WorkOrderHeader;
