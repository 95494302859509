import * as React from 'react';
import { Button } from 'react-bootstrap';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Field, FieldArray, InjectedFormProps, reduxForm, getFormValues } from 'redux-form';

import { QuantityUnitLabel } from 'acceligent-shared/enums/quantityUnit';
import QuantityUnitType from 'acceligent-shared/enums/quantityUnit';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import JobWorkSummaryVM from 'ab-viewModels/workRequest/jobWorkSummary.viewModel';
import JobWorkSummaryWorkOrderVM from 'ab-viewModels/workRequest/jobWorkSummaryWorkOrder.viewModel';
import { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import JobWorkSummaryRM from 'ab-requestModels/workRequest/jobWorkSummaryUpsert.requestModel';

import * as JobActions from 'af-actions/jobs';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import Dropdown from 'af-fields/Dropdown';
import DateInput from 'af-fields/DateInput';
import Textarea from 'af-fields/Textarea';

import * as FORMS from 'af-constants/reduxForms';

import { RootState } from 'af-reducers';

import { dollarFormatter } from 'af-utils/format.util';

import Input from 'af-fields/Input';
import Text from 'af-fields/Text';

import AddBillableWorkModalInformationalField from './AddBillableWorkModalInformationalField';
import AddBillableWorkModalDefinitionField from './AddBillableWorkModalDefinitionField';
import { FormModel } from '../FormModel';
import styles from './styles.module.scss';
import { SubjobBillingCodes, SubjobWorkOrders } from '..';

interface BillingCodeDropdownOption {
	id: number;
	customerId: string;
	unitPrice: Nullable<string>;
	unit: QuantityUnitType;
	description: string;
	group: Nullable<string>;
}

interface QuantityDropdownOption {
	id: QuantityUnitType;
	label: string;
}

const getFormData = getFormValues(FORMS.WORK_SUMMARY_BILLABLE_WORK);

const _delimitersRegEx = new RegExp('\/|-| ');

const _filterBillingCodes = (_option: BillingCodeVM, _searchText: string) => {
	_searchText.trim();
	const splitSearch = _searchText.toLowerCase().split(_delimitersRegEx).join('');
	const _customerIdToLowerCase = _option.customerId.toLowerCase().split(_delimitersRegEx).join('');
	const _descriptionToLowerCase = _option.description.toLowerCase().split(_delimitersRegEx).join('');

	if (!!splitSearch && _customerIdToLowerCase.includes(splitSearch) || _descriptionToLowerCase.includes(splitSearch)) {
		return true;
	}

	return false;
};

const _renderBillingCodeMenuItem = (_billingCode: BillingCodeDropdownOption) => {
	return (
		<div className={styles['add-billable-work-modal__billing-code-dropdown-item']}>
			<b>{_billingCode.customerId}</b>
			<b>{`$ ${_billingCode.unitPrice} ${_billingCode.group ?? ''}`}</b>
			<p>{_billingCode.description}</p>
		</div>
	);
};

const _renderSubjobMenuItem = (_subjob: SubjobVM) => {
	return (
		<div className={styles['add-billable-work-modal__billing-code-dropdown-item']}>
			<b>{_subjob.jobCode}</b>
		</div>
	);
};

const _renderSelectedDropdownMenuItem = (_billingCode: BillingCodeDropdownOption) => <span key={_billingCode.id}>{_billingCode.customerId}</span>;

const _renderSelectedSubjob = (_subjob: SubjobVM) => <span key={_subjob.id}>{_subjob.jobCode}</span>;

const _renderNoBillingCodeMenuItem = (item) => <span>{item.label}</span>;

const quantityUnitOptions: QuantityDropdownOption[] = Object.keys(QuantityUnitLabel)
	.map((_unit: QuantityUnitType) => ({ id: _unit, label: QuantityUnitLabel[_unit] }));

type OwnProps = {
	showModal: boolean;
	closeModal: () => void;
	billingCodes: BillingCodeVM[];
	workOrders: JobWorkSummaryWorkOrderVM[];
	jobId: number;
	refreshTable: () => void;
	currentlyEditedRow: Nullable<JobWorkSummaryVM>;
	isProject: boolean;
	subjobs: SubjobVM[];
	subjobBillingCodes: SubjobBillingCodes[];
	subjobWorkOrders: SubjobWorkOrders[];
};

type Props = OwnProps & InjectedFormProps<FormModel> & ConnectedProps<typeof connector>;

const AddBillableWorkModal: React.FC<Props> = (props) => {
	const {
		closeModal,
		showModal,
		billingCodes,
		workOrders,
		initialize,
		destroy,
		change,
		formDataUnit,
		formDataQuantity,
		formDataRevenue,
		formDataUnitPrice,
		formDataCustomerId,
		formDataBillingCodeId,
		formDataGroup,
		valid,
		createJobWorkSummary,
		handleSubmit,
		jobId,
		refreshTable,
		currentlyEditedRow,
		editJobWorkSummary,
		dirty,
		isProject,
		subjobs,
		subjobBillingCodes,
		subjobWorkOrders,
		formWorkRequestId,
	} = props;

	const filteredBillingCodes = React.useMemo(
		() => {
			const _billingCodes = !!subjobBillingCodes.length
				? subjobBillingCodes.find((_sjbc) => _sjbc.workRequestId === (formWorkRequestId ?? jobId))?.billingCodes ?? []
				: billingCodes;

			return !!formDataUnit ?
				_billingCodes.filter((_bc) => _bc.unit === formDataUnit)
				: _billingCodes;
		}, [billingCodes, formDataUnit, formWorkRequestId, jobId, subjobBillingCodes]
	);

	const billingCodeOptions = React.useMemo(() =>
		filteredBillingCodes.map<BillingCodeDropdownOption>(
			(_bc) => ({
				customerId: _bc.customerId,
				id: _bc.id,
				description: _bc.description,
				unitPrice: _bc.unitPrice,
				group: _bc.group,
				unit: _bc.unit,
			})),
		[filteredBillingCodes]
	);

	const workOrderOptions = React.useMemo(() => {
		const _workOrders = !!subjobWorkOrders.length
			? subjobWorkOrders.find((_sjwo) => _sjwo.workRequestId === (formWorkRequestId ?? jobId))?.workOrders ?? []
			: workOrders;

		return _workOrders.map((_wo) => ({ id: _wo.id, label: _wo.workOrderCode }));
	}, [formWorkRequestId, jobId, subjobWorkOrders, workOrders]);

	const onBillingCodeChange = React.useCallback((_billingCode: BillingCodeDropdownOption) => {
		change('billingCodeId', _billingCode.id);
		change('customerId', _billingCode.customerId);
		change('group', _billingCode.group);
		change('unitPrice', _billingCode.unitPrice);
		change('description', _billingCode.description);
		change('revenue', +(_billingCode.unitPrice ?? 0) * (formDataQuantity ?? 0));
		change('unit', _billingCode.unit);
	}, [formDataQuantity, change]);

	const onQuantityChange = React.useCallback((quantity) => {
		change('revenue', quantity * +(formDataUnitPrice ?? 0));
	}, [formDataUnitPrice, change]);

	const onBillingCodeClear = React.useCallback(() => {
		change('unit', null);
		change('customerId', null);
		change('billingCodeId', null);
		change('group', null);
		change('description', null);
		change('revenue', null);
		change('unitPrice', null);
	}, [change]);

	const onUnitClear = React.useCallback(() => {
		change('unit', null);
	}, [change]);

	const onWorkOrderClear = React.useCallback(() => {
		change('workOrderId', null);
	}, [change]);

	const renderBillingCodePlaceholder = React.useCallback(() =>
		<span className={styles['add-billable-work-modal__billing-code-placeholder']}>
			Add Billing Code to see
		</span>
		, []);

	const onJobChange = React.useCallback((_jobId: React.ChangeEvent<HTMLInputElement>) => {
		change('workRequestId', _jobId);
		// Clean billing code and work Order information
		onBillingCodeClear();
		onWorkOrderClear();
	}, [change, onBillingCodeClear, onWorkOrderClear]);

	const hasSelectableBillingCodes = !!billingCodeOptions?.length;

	const renderBillingCodeDropdown = React.useCallback(() => {
		if (hasSelectableBillingCodes) {
			const selectedBillingCode = billingCodeOptions.find((_bco) => _bco.id === formDataBillingCodeId);

			return (
				<Field
					component={Dropdown}
					defaultValue={selectedBillingCode}
					filterable={true}
					filterBy={_filterBillingCodes}
					fixed={false}
					isClearable={true}
					label="Billing Code *"
					name="customerId"
					onClear={onBillingCodeClear}
					onValueChange={onBillingCodeChange}
					options={billingCodeOptions ?? []}
					placeholder="Add Billing Code"
					propName="id"
					renderMenuItem={_renderBillingCodeMenuItem}
					renderSelected={_renderSelectedDropdownMenuItem}
					valueKey="id"
					withCaret={true}
				/>
			);
		}

		return (
			<Field
				component={Dropdown}
				id="customerId"
				label="Billing Code *"
				name="customerId"
				options={[{ disabled: true, label: 'No billing codes found.' }]}
				placeholder="No billing codes found."
				renderMenuItem={_renderNoBillingCodeMenuItem}
				withCaret={true}
			/>
		);
	}, [hasSelectableBillingCodes, billingCodeOptions, onBillingCodeClear, onBillingCodeChange, formDataBillingCodeId]);

	const onSubmit = React.useCallback(async (form: FormModel) => {
		if (
			!form.billingCodeId
			|| !form.customerId
			|| !form.unit
			|| !form.unitPrice
			|| !form.description
			|| !form.quantity
			|| !form.work
			|| !form.type
		) {
			return;
		}

		const jobWorkSummaryRM: JobWorkSummaryRM = {
			id: !!currentlyEditedRow ? currentlyEditedRow.id : undefined,
			billingCodeId: form.billingCodeId,
			customerId: form.customerId,
			definitionField1Name: currentlyEditedRow?.definitionField1Name ?? null,
			definitionField1Value: form.definitionFields?.[0] ?? null,
			definitionField2Name: currentlyEditedRow?.definitionField2Name ?? null,
			definitionField2Value: form.definitionFields?.[1] ?? null,
			definitionField3Name: currentlyEditedRow?.definitionField3Name ?? null,
			definitionField3Value: form.definitionFields?.[2] ?? null,
			definitionField4Name: currentlyEditedRow?.definitionField4Name ?? null,
			definitionField4Value: form.definitionFields?.[3] ?? null,
			informationField1Value: [form.informationalFields?.[0] ?? null],
			informationField1Name: undefined,
			informationField2Value: [form.informationalFields?.[1] ?? null],
			informationField2Name: undefined,
			informationField3Value: [form.informationalFields?.[2] ?? null],
			informationField3Name: undefined,
			informationField4Value: [form.informationalFields?.[3] ?? null],
			informationField4Name: undefined,
			description: form.description,
			group: form.group,
			invoiceId: null,
			quantity: form.quantity,
			startDate: form.date,
			type: form.type,
			unit: form.unit,
			work: form.work,
			workOrderId: form.workOrderId,
			fieldReportId: form.fieldReportId,
			unitPrice: form.unitPrice,
			comment: form.comment,
			workRequestId: form.workRequestId ?? jobId,
		};

		if (currentlyEditedRow) {
			await editJobWorkSummary(jobWorkSummaryRM);
		} else {
			await createJobWorkSummary(jobWorkSummaryRM);
		}
		closeModal();
		refreshTable();
	}, [currentlyEditedRow, closeModal, refreshTable, editJobWorkSummary, jobId, createJobWorkSummary]);

	const currentSubjob = React.useMemo(() => subjobs.find((sj) => sj.id === jobId), [jobId, subjobs]);

	React.useEffect(() => {
		if (showModal) {
			!!currentlyEditedRow
				? initialize(new FormModel(currentlyEditedRow))
				: initialize(new FormModel());
			change('workRequestId', currentSubjob?.id ?? null);
		} else {
			destroy();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentlyEditedRow, destroy, initialize, showModal]);

	const showRevenue = !!formDataCustomerId;
	const titleAndSubmitName = !!currentlyEditedRow ? 'Edit Billable Work' : 'Add New Billable Work';

	const canSubmitEdited = dirty && valid;
	const canSubmit = !!currentlyEditedRow ? canSubmitEdited : valid;

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="lg"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={titleAndSubmitName}
			/>
			<CustomModal.Body>
				<div className={styles['add-billable-work-modal']}>
					<Field
						component={Dropdown}
						filterable={true}
						filterBy={['label']}
						fixed={false}
						hasBlankOption={true}
						id="workOrderId"
						label="Work Order"
						labelKey="label"
						name="workOrderId"
						onClear={onWorkOrderClear}
						options={workOrderOptions}
						placeholder="Select Work Order"
						valueKey="id"
						withCaret={true}
					/>

					<div className={styles['add-billable-work-modal__date']}>
						<Field
							component={DateInput}
							dateFormat={TimeFormatEnum.DB_DATE_ONLY}
							id="date"
							label="Date *"
							name="date"
							originalDateFormat={TimeFormatEnum.DB_DATE_ONLY}
							placeholderText="Select Date"
							showTimeSelect={true}
						/>
					</div>
					<div className={styles['add-billable-work-modal__multiple']}>
						<Field
							component={Input}
							fixed={true}
							id="work"
							label="Work *"
							name="work"
							placeholder="Add Work"
						/>
					</div>
					<div className={styles['add-billable-work-modal__multiple']}>
						<Field
							component={Input}
							fixed={true}
							id="type"
							label="Type *"
							name="type"
							placeholder="Add Type"
						/>
					</div>
					<div className={styles['add-billable-work-modal__multiple']}>
						<FieldArray<void>
							component={AddBillableWorkModalDefinitionField}
							name="definitionFields"
						/>
					</div>
					<div className={styles['add-billable-work-modal__multiple']}>
						<FieldArray<void>
							component={AddBillableWorkModalInformationalField}
							name="informationalFields"
						/>
					</div>

					<Field
						component={Input}
						fixed={true}
						label="Quantity *"
						name="quantity"
						onValueChange={onQuantityChange}
						placeholder="Add Quantity"
						type="number"
					/>
					<Field
						component={Dropdown}
						defaultValue={undefined}
						disabled={!!formDataCustomerId}
						filterable={true}
						filterBy={['id', 'label']}
						fixed={true}
						id="unit"
						label="Unit"
						labelKey="label"
						name="unit"
						onClear={onUnitClear}
						options={quantityUnitOptions}
						placeholder="Add Unit"
						valueKey="id"
						withCaret={true}
					/>
					<div>
						{
							isProject &&
							process.env.FTD_PROJECT !== 'true' &&
							<Field
								component={Dropdown}
								defaultValue={currentSubjob}
								disabled={!!formDataCustomerId}
								fixed={true}
								isClearable={true}
								label="Sub-job"
								name="workRequestId"
								onChange={onJobChange}
								options={subjobs}
								renderMenuItem={_renderSubjobMenuItem}
								renderSelected={_renderSelectedSubjob}
								valueKey="id"
								withCaret={true}
							/>
						}
						{renderBillingCodeDropdown()}
						<div className={styles['add-billable-work-modal__billing-code-item']}>
							Group
							<Field
								component={Text}
								defaultValue={formDataCustomerId && !formDataGroup ? '-' : renderBillingCodePlaceholder()}
								id="group"
								name="group"
							/>
						</div>
						<div className={styles['add-billable-work-modal__billing-code-item']}>
							Description
							<Field
								component={Text}
								defaultValue={renderBillingCodePlaceholder()}
								id="description"
								name="description"
							/>
						</div>
						<div className={styles['add-billable-work-modal__billing-code-item']}>
							Unit price
							<Field
								component={Text}
								defaultValue={renderBillingCodePlaceholder()}
								id="unitPrice"
								name="unitPrice"
							/>
						</div>
						<div className={styles['add-billable-work-modal__billing-code-item']}>
							<div>
								Revenue
							</div>
							<b>
								{showRevenue
									? dollarFormatter.format(formDataRevenue ?? 0)
									: renderBillingCodePlaceholder()
								}
							</b>
						</div>
					</div>
					<div className={styles['add-billable-work-modal__comment']}>
						<Field
							className={styles['add-billable-work-modal']}
							component={Textarea}
							id="comment"
							label="Comment"
							maxCharacters={750}
							name="comment"
							placeholder="Add Comment"
							rows={16}
							showMaxCharactersLabel={true}
						/>
					</div>
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={!canSubmit}
					label={titleAndSubmitName}
					onClick={handleSubmit(onSubmit)}
					variant="primary"
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	const {
		billingCodeId: formDataBillingCodeId,
		unit: formDataUnit,
		quantity: formDataQuantity,
		revenue: formDataRevenue,
		unitPrice: formDataUnitPrice,
		customerId: formDataCustomerId,
		group: formDataGroup,
		workRequestId: formWorkRequestId,
	} = (getFormData(state) as FormModel) ?? ({} as Partial<FormModel>);

	return {
		formDataBillingCodeId,
		formDataUnit,
		formDataQuantity,
		formDataRevenue,
		formDataUnitPrice,
		formDataCustomerId,
		formDataGroup,
		formWorkRequestId,
	};
}

const formConnector = reduxForm<FormModel>({
	form: FORMS.WORK_SUMMARY_BILLABLE_WORK,
	validate: FormModel.validate,
});

function mapDispatchToProps() {
	return {
		createJobWorkSummary: JobActions.createJobWorkSummary,
		editJobWorkSummary: JobActions.editJobWorkSummary,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	formConnector,
	connector
);

export default enhance(AddBillableWorkModal);
