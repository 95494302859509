import * as React from 'react';

import TableButtonType from 'ab-enums/tableButtonType.enum';

import { bemElement } from 'ab-utils/bem.util';
import { filterMap } from 'ab-utils/array.util';

import { ButtonData } from '../../types';
import TableTypeEnum from '../../tableType.enum';

import ExportButton from './ExportButton';
import LinkButton from './LinkButton';
import PrimaryButton from './PrimaryButton';

interface OwnProps {
	buttons: ButtonData[];
	tableType: TableTypeEnum;
	exportAsZip?: boolean;
}

type Props = OwnProps;

const buttonHasPermission = (button: ButtonData) => button.hasPermission;

const Buttons: React.FC<Props> = (props) => {

	const { tableType, exportAsZip = false, buttons } = props;

	const renderButton = React.useCallback(({ type, onClick, label, icon, actionKey }: ButtonData) => {

		const showSmallButtons = tableType !== TableTypeEnum.DEFAULT;
		const key = `${type}#${label}`;
		const buttonId = label ? `accqa__${label.split(' ').join('-').toLowerCase()}` : undefined;

		switch (type) {
			case TableButtonType.LINK:
				return (
					<LinkButton
						icon={icon}
						id={buttonId}
						key={key}
						label={label ?? ''}
						onClick={onClick}
						showSmallButtons={showSmallButtons}
					/>
				);
			case TableButtonType.EXPORT:
				return (
					<ExportButton
						exportAsZip={exportAsZip}
						key={key}
						onClick={onClick}
						showSmallButtons={showSmallButtons}
					/>
				);
			case TableButtonType.PRIMARY:
			default:
				return (
					<PrimaryButton
						actionKey={actionKey}
						icon={icon}
						id={buttonId}
						key={key}
						label={label ?? ''}
						onClick={onClick}
						showSmallButtons={showSmallButtons}
					/>
				);
		}
	}, [exportAsZip, tableType]);

	return (
		<div className={bemElement('table-container', 'buttons')}>
			{filterMap(buttons, buttonHasPermission, renderButton)}
		</div>
	);
};

export default React.memo(Buttons);
