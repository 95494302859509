import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

import { getByAccessor } from 'af-utils/table.utils';

import { Column, RowInfo, CellInfo, ItemBlueprint } from './types';
import { getRowActions } from './helpers';
import SettingsCell from './Cells/SettingsCell';

interface ExpanderProps<T> {
	isExpanded: boolean;
	original: T;
}

interface OwnProps<T> {
	item: T;
	index: number;
	columns: Column<T>[];
	refreshList: () => void;
	onRowClick?: (rowInfo: RowInfo<T>) => void;
	rowActions?: ItemBlueprint<T>[];
	SubComponent?: (rowInfo: RowInfo<T>) => React.ReactNode;
}

type Props<T> = OwnProps<T>;

interface State<T> {
	isExpanded: boolean;
	hasExpander: boolean;
	expander: Nullable<Column<T>>;
}

export default class ScrollToLoadItem<T> extends React.PureComponent<Props<T>, State<T>> {
	state: State<T> = {
		isExpanded: false,
		hasExpander: false,
		expander: null,
	};

	static getDerivedStateFromProps(props: Props<Metadata>) {
		const expanders = props.columns?.length ? props.columns.filter((_column) => _column.expander) : [];
		return {
			hasExpander: !!expanders.length,
			expander: expanders[0],
		};
	}

	getRowActions = (item: T) => {
		const { rowActions } = this.props;

		return getRowActions.call(this, rowActions, item, this.refreshAction);
	};

	onItemClick = () => {
		const { onRowClick, item } = this.props;
		if (!onRowClick) {
			return;
		}
		onRowClick({ original: item } as RowInfo<T>);
	};

	toggleExpand = (e: React.MouseEvent) => {
		e.stopPropagation(); // if the whole item is clickable this prevents the click from propagating when expanding the SubComponent
		this.setState((state: State<T>) => ({ isExpanded: !state.isExpanded }));
	};

	refreshAction = async (action: (rowElement: T) => Promise<void>, rowElement: T, shouldRefresh: boolean) => {
		const { refreshList } = this.props;
		await action(rowElement);
		if (shouldRefresh) {
			refreshList();
		}
	};

	renderColumn = (_column: Column<T>, _index: number) => {
		const { item } = this.props;

		if (_column.expander) {
			return null;
		}

		const _propsValue = getByAccessor(item, _column.accessor, '');
		const _value = _column.Cell ? _column.Cell({ original: item, value: _propsValue } as CellInfo<T>) : _propsValue;

		return (
			<div className={bemElement('scroll-to-load', 'item-value')} key={_index}>
				{_column.Header && <div className={bemElement('scroll-to-load', 'item-value-header')}><b className="m-r-m"><>{_column.Header}:</></b></div>}
				<div className={`${bemElement('scroll-to-load', 'item-value-data')} ${_column.className ?? ''}`}>{_value}</div>
			</div>
		);
	};

	renderExpander = () => {
		const { item } = this.props;
		const { isExpanded, expander } = this.state;

		const _expandFunction = expander?.Expander as React.FunctionComponent<ExpanderProps<T>>;
		return (
			<div className={bemElement('scroll-to-load', 'item-value')} onClick={this.toggleExpand}>
				<div className={bemElement('scroll-to-load', 'item-value-header')}>
					{React.createElement(_expandFunction, { isExpanded, original: { ...item, infiniteScroll: true } })}
				</div>
			</div>
		);
	};

	render() {
		const { item, rowActions, index, columns, SubComponent } = this.props;
		const { hasExpander, isExpanded } = this.state;

		return (
			<>
				<div className={bemElement('scroll-to-load', 'item')}>
					<div className="scroll-to-load__item-values-container" onClick={this.onItemClick}>
						{columns.map(this.renderColumn)}
						{hasExpander && this.renderExpander()}
					</div>
					{!!rowActions?.length ?
						<SettingsCell
							isFirstHalf={true}
							items={this.getRowActions(item)}
							rowIndex={index}
						/>
						: null
					}
				</div>
				{isExpanded && SubComponent && SubComponent({ original: item } as RowInfo<T>)}
			</>
		);
	}
}
