import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import FSN from './FSN';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector>;

const PERMISSIONS = [];

const OrganizationResourcesFSN = (props: Props) => {
	const { organizationData, userRole, location: { state: { orgAlias }, pathname } } = props;

	const links = React.useMemo(() => [
		{
			url: CLIENT.ORGANIZATION.RESOURCES.WAGE_RATE(orgAlias),
			label: 'Wage Rate',
			icon: 'icon-dollar',
			visibleFor: [],
			isActive: (pathname.includes(CLIENT.ORGANIZATION.RESOURCES.WAGE_RATE(orgAlias))),
		},
		{
			url: CLIENT.ORGANIZATION.RESOURCES.EMPLOYEE(orgAlias),
			label: 'Labor',
			icon: 'icon-user',
			visibleFor: [],
			isActive: (pathname.includes(CLIENT.ORGANIZATION.RESOURCES.EMPLOYEE(orgAlias))),
		},
		{
			url: CLIENT.ORGANIZATION.RESOURCES.EQUIPMENT_COST(orgAlias),
			label: 'Equipment Cost',
			icon: 'icon-equipment_cost',
			visibleFor: [],
			isActive: (pathname.includes(CLIENT.ORGANIZATION.RESOURCES.EQUIPMENT_COST(orgAlias))),
		},
		{
			url: CLIENT.ORGANIZATION.RESOURCES.EQUIPMENT(orgAlias),
			label: 'Equipment',
			icon: 'icon-equipment',
			visibleFor: [],
			isActive: (pathname.includes(CLIENT.ORGANIZATION.RESOURCES.EQUIPMENT(orgAlias))),
		},
		{
			url: CLIENT.ORGANIZATION.RESOURCES.CREW_TYPE(orgAlias),
			label: 'Crew Type',
			icon: 'icon-users',
			visibleFor: [],
			isActive: (pathname.includes(CLIENT.ORGANIZATION.RESOURCES.CREW_TYPE(orgAlias))),
		},
		{
			url: CLIENT.ORGANIZATION.RESOURCES.SKILL(orgAlias),
			label: 'Skill',
			icon: 'icon-star',
			visibleFor: [],
			isActive: (pathname.includes(CLIENT.ORGANIZATION.RESOURCES.SKILL(orgAlias))),
		},
		{
			url: CLIENT.ORGANIZATION.RESOURCES.LOCATION(orgAlias),
			label: 'Location',
			icon: 'icon-location_pin',
			visibleFor: [],
			isActive: (pathname.includes(CLIENT.ORGANIZATION.RESOURCES.LOCATION(orgAlias))),
		},
		{
			url: CLIENT.ORGANIZATION.RESOURCES.SALE(orgAlias),
			label: 'Sales',
			icon: 'icon-cost',
			visibleFor: [],
			isActive: (pathname.includes(CLIENT.ORGANIZATION.RESOURCES.SALE(orgAlias))),
		},
		{
			url: CLIENT.ORGANIZATION.RESOURCES.DOCUMENT(orgAlias),
			label: 'Document',
			icon: 'icon-notes',
			visibleFor: [],
			isActive: (pathname.includes(CLIENT.ORGANIZATION.RESOURCES.DOCUMENT(orgAlias))),
		},
		{
			url: CLIENT.ORGANIZATION.RESOURCES.DIVISION(orgAlias),
			label: 'Division',
			icon: 'icon-division',
			visibleFor: [],
			isActive: (pathname.includes(CLIENT.ORGANIZATION.RESOURCES.DIVISION(orgAlias))),
		},
	], [orgAlias, pathname]);

	return (
		<FSN
			infoLabel="Resources"
			isCompanyAdmin={organizationData.isPlatformAdmin}
			links={links}
			permissions={PERMISSIONS}
			scrollLabel="none"
			userRole={userRole}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, organizationData } = state.user;
	if (!userData || !organizationData) {
		throw new Error('User not logged in');
	}

	return {
		organizationData,
		userRole: userData.role,
	};
}

const connector = connect(mapStateToProps);

export default connector(OrganizationResourcesFSN);
