import * as React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { FormatOptionLabelMeta, OptionTypeBase } from 'react-select';
import {
	getOptionLabel as getOptionLabelType,
	getOptionValue as getOptionValueType,
} from 'react-select/src/builtins';

import AsyncSelect from 'af-fields/AsyncSelect';
import Radio from 'af-fields/Radio';

interface Item {
	label: string;
	value: string | number;
}

interface Props<T extends OptionTypeBase> {
	items: Item[];
	valueKey?: string;
	labelKey?: string;
	onValueChange?: () => void;
	focused?: boolean;
	field: string;
	placeholder?: string;
	inline?: boolean;
	disabled?: boolean;
	withOther?: boolean;
	initialValue?: string | number;
	extraClass?: string;
	padded?: boolean;
	selector: (field: string) => string | number | boolean;
	changeField: (field: string, value: string | number | null) => void;
	searchOtherOptions: (inputValue: string) => Promise<T[]>;
	onNew?: (name: string) => Promise<void>;
	isValidNewOption?: (inputValue: string, value: T, options: T[]) => boolean;
	formatOptionLabel?: (option: T, labelMeta: FormatOptionLabelMeta<T, boolean>) => React.ReactNode;
	getOptionLabel?: getOptionLabelType<T>;
	getOptionValue?: getOptionValueType<T>;
}

interface State {
	otherValue: string | number;
}

export default class RadioGroup<T extends OptionTypeBase> extends React.PureComponent<Props<T>, State> {
	static DEFAULT_OTHER_VALUE = 0;

	static OTHER_SHIFT_ITEM = {
		id: RadioGroup.DEFAULT_OTHER_VALUE,
		label: 'Other',
	};

	static defaultProps: Partial<Props<Metadata>> = {
		extraClass: '',
		padded: false,
	};

	state: State = {
		otherValue: RadioGroup.OTHER_SHIFT_ITEM.id,
	};

	getFieldName = (): string => {
		const { field, valueKey } = this.props;
		return valueKey ? `${field}.${valueKey}` : field;
	};

	getLabelName = (): string => {
		const { field, labelKey } = this.props;
		return labelKey ? `${field}.${labelKey}` : field;
	};

	isOtherSelected = (): boolean => {
		const { selector, items } = this.props;
		const fieldName = this.getFieldName();
		const selectedValue = selector(fieldName);
		return !items.find((_item: Item) => _item.value === selectedValue);
	};

	onOtherValueChange = (selectedValue: string | number): void => {
		const { onValueChange } = this.props;
		this.setState(() => ({ otherValue: selectedValue }));
		if (onValueChange) {
			onValueChange();
		}
	};

	onOtherValueClear = (): void => {
		const { field, changeField, onValueChange } = this.props;
		this.setState(() => ({ otherValue: RadioGroup.OTHER_SHIFT_ITEM.id }));
		changeField(field, null);
		if (onValueChange) {
			onValueChange();
		}
	};

	onValueChange = async (label: string) => {
		const { onValueChange, changeField, labelKey } = this.props;

		if (labelKey) {
			changeField(this.getLabelName(), label);
		}

		if (onValueChange) {
			onValueChange();
		}
	};

	onOtherRadioChange = async () => {
		const { onValueChange, changeField, field } = this.props;

		if (field) {
			changeField(field, null);
		}

		if (onValueChange) {
			onValueChange();
		}
	};

	renderRadioButtons = (props: WrappedFieldProps): JSX.Element => {
		const { valueKey, items, inline, disabled, withOther, field, changeField, initialValue } = this.props;
		const { otherValue } = this.state;
		const { input } = props;

		const fieldName = this.getFieldName();

		// when not selected, input.value can be an empty string
		const selectedValue = (valueKey ? input.value[valueKey] : input.value) || initialValue;
		const _otherValue = valueKey ? otherValue[valueKey] : otherValue;
		const isOtherSelected = selectedValue === _otherValue || !items.find((x) => x.value === selectedValue);

		return (
			<>
				{
					items.map((_item: Item, _index: number) => (
						<Radio
							changeField={changeField}
							checked={_item.value === selectedValue}
							disabled={disabled}
							field={fieldName}
							inline={inline} // bind here not to make Radio component dirty. Whole component needs a refactor.
							key={`radio.${field}#${_index}`}
							label={_item.label}
							onCheck={this.onValueChange.bind(this, _item.label)}
							value={_item.value}
						/>
					))
				}
				{
					withOther &&
					<Radio
						changeField={changeField}
						checked={isOtherSelected}
						disabled={disabled}
						field={field}
						inline={inline}
						key={`radio.${field}#other`}
						label={RadioGroup.OTHER_SHIFT_ITEM.label}
						onCheck={this.onOtherRadioChange}
						value={otherValue}
					/>
				}
			</>
		);
	};

	render(): JSX.Element {
		const {
			focused,
			placeholder,
			onNew,
			field,
			withOther,
			searchOtherOptions,
			disabled,
			extraClass,
			padded,
			formatOptionLabel,
			getOptionLabel,
			getOptionValue,
			isValidNewOption,
			inline,
		} = this.props;

		const showOther = withOther && this.isOtherSelected();

		let className = 'radio-button-group';
		className = padded ? `${className} radio-button-group--padded` : className;
		className = extraClass ? `${className} ${extraClass}` : className;
		className = inline ? `${className} radio-button-group--inline` : className;

		return (
			<div className={className}>
				<Field
					component={this.renderRadioButtons}
					disabled={disabled}
					focused={focused}
					name={field}
				/>
				{showOther &&
					<Field
						allowNew={true}
						component={AsyncSelect}
						formatOptionLabel={formatOptionLabel}
						getOptionLabel={getOptionLabel}
						getOptionValue={getOptionValue}
						grouped={false}
						isDisabled={disabled}
						isValidNewOption={isValidNewOption}
						name={field}
						onClear={this.onOtherValueClear}
						onCreateNew={onNew}
						onSearch={searchOtherOptions}
						onValueChange={this.onOtherValueChange}
						placeholder={placeholder}
					/>
				}
			</div>
		);
	}
}
