export enum NotificationSnackbarTypes {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info',
	LOADING = 'loading'
}

export enum NotificationActionTypes {
	ADD_NOTIFICATION = 'ADD_NOTIFICATION_SNACKBAR',
	DELETE_NOTIFICATION = 'DELETE_NOTIFICATION_SNACKBAR',
	UPDATE_CONTENT = 'UPDATE_CONTENT'
}
