import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { change, FormAction, formValueSelector } from 'redux-form';

import { RootState } from 'af-reducers';

import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import RequestContactWrapper from 'af-components/SharedForms/Contacts/RequestWrapper';

interface OwnProps {
	formName: string;
	fieldName: string;
	disabled?: boolean;
	className?: string;
	contactLabel?: string;
}

interface StateProps {
	contact: ContactVM;
	contactIdsSelector: (field: string) => number[];
}

interface DispatchProps {
	setContact: (value: Nullable<ContactVM>) => FormAction;
}

type Props = OwnProps & StateProps & DispatchProps;

class ContactWrapper extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		className: '',
	};

	clearContact = () => {
		const { setContact } = this.props;
		setContact(null);
	};

	render() {
		const { formName, className, contact, disabled, fieldName, contactIdsSelector, contactLabel } = this.props;

		let wrapperClassName = 'contact';
		wrapperClassName = className ? `${wrapperClassName} ${className}` : wrapperClassName;

		return (
			<div className={wrapperClassName}>
				<RequestContactWrapper
					addressFieldName={`${fieldName}.contactAddressIds`}
					canPickAddress={true}
					canPickContactMethods={true}
					clearContact={this.clearContact}
					contact={contact}
					contactIdsSelector={contactIdsSelector}
					contactLabel={contactLabel ?? 'Contact Name'}
					disabled={disabled}
					emailFieldName={`${fieldName}.contactEmailIds`}
					fieldName={fieldName}
					formName={formName}
					phoneFieldName={`${fieldName}.contactPhoneIds`}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { formName, fieldName } = ownProps;
	const selector = formValueSelector(formName);
	return {
		contact: selector(state, fieldName),
		contactIdsSelector: (field: string) => selector(state, field),
	};
}

function mapDispatchToProps(dispatch: Dispatch<FormAction>, ownProps: OwnProps): DispatchProps {
	const { formName, fieldName } = ownProps;
	return {
		setContact: (value: ContactVM) => dispatch(change(formName, fieldName, value)),
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(ContactWrapper);
