import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

import styles from './styles.module.scss';

interface Props {
	filterText: string;
	onFilterTextChange: (filterText: string) => void;
	searchLabel: string;
}

const FilterInput: React.FC<Props> = (props) => {
	const { onFilterTextChange, filterText, searchLabel } = props;

	const clearFilter = React.useCallback(() => onFilterTextChange(''), [onFilterTextChange]);

	const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement> | string) => {
		const currFilterText = typeof e === 'string' ? e : e.target.value;
		onFilterTextChange(currFilterText);
	}, [onFilterTextChange]);

	const renderSearchIcon = React.useCallback(() => {
		if (filterText) {
			return (
				<Tooltip
					message="Clear"
					placement="bottom"
				>
					<a
						onClick={clearFilter}
						role="button"
					>
						<span className="icon-close" />
					</a>
				</Tooltip>
			);
		}
		return (
			<Tooltip message="Search" placement="bottom">
				<span className="icon-search" />
			</Tooltip>
		);
	}, [clearFilter, filterText]);

	const searchInputClassName = [
		styles['action-header__filters__search-input'],
	];

	return (
		<div className={searchInputClassName.join(' ')} >
			<input
				onChange={handleInputChange}
				placeholder={`Search ${searchLabel}`}
				type="text"
				value={filterText}
			/>
			{renderSearchIcon()}
		</div>
	);
};

export default React.memo(FilterInput);
