enum CompanyFeatureFlags {
	enableNotifications = 'Enable Email and SMS notifications',
	enableAutomaticNotificationsOnWorkDays = 'Send automatic notifications only on work days',
	enablePastWorkOrdersNotification = 'Change on past WO',
	enableItemLowQuantityNotification = 'Item low on stock',
	enableDailyItemLowQuantityNotification = 'Daily Item low on stock',
	areWorkRequestsEnabled = 'Enable Work Requests',
	arePortalFunctionalitiesEnabled = 'Enable Portal functionalities - Orders, Purchase orders, Tool Repairs',
	isSendingInvoiceNotificationsEnabled = 'Allow sending invoice notifications',
	notifyScheduleBoardEmail = 'Schedule Board',
	enableToolRepairNotification = 'Tool Repair Modified',
	notifyYesterdaysScheduleBoardEmail = 'Yesterday\'s Schedule Board',
	notifyOnPublish = 'Published Work Orders',
	notifyOnCancel = 'Canceled Work Orders',
	notifyOnPause = 'Paused Work Orders',
	notifyOnResume = 'Resumed Work Orders',
	notifyUnassignedEmployees = 'Unassigned workers',
	autoNotifyTimezone = 'Timezone',
	notifyMultipleAssignments = 'Employees with multiple assignments',
	notifyOnMonday = 'Monday',
	notifyOnTuesday = 'Tuesday',
	notifyOnWednesday = 'Wednesday',
	notifyOnThursday = 'Thursday',
	notifyOnFriday = 'Friday',
	notifyOnSaturday = 'Saturday',
	notifyOnSunday = 'Sunday ',
	notifySupervisors = 'Supervisors and project managers',
	notifyFailedNotifications = 'Failed notifications',
	notifyQAQC = 'Deliverable WO published',
	notifyDeliverableAssignee = 'Change on Deliverable Assignment',
	customPDFHeader = 'Enable custom headers in PDF printouts',
	notifyOnReportReject = 'Field Report rejected',
	notifyOnTimeSheetReject = 'Time Sheet rejected',
	notifyTemporaryLabor = 'Notify temporary labor about WO changes',
	unfinishedPurchaseOrderCreatedNotification = 'Unfinished Purchase Order created',
	purchaseOrderWithUnreceivedItems = 'Purchase Order with Unreceived Items',
	invoiceRemindersNotification = 'Send Invoice Reminders',
	notifyOnOrderCreation = 'Order Created',
	notifyForUnfinishedOrders = 'Unfinished Orders',
	notifyForOrdersTotalExpenseDaily = 'Orders Total Expense Daily Report',
	notifyForOrdersTotalExpenseWeekly = 'Orders Total Expense Weekly Report',
	notifyForOrdersTotalExpenseMonthly = 'Orders Total Expense Monthly Report',
	notifyForDuplicateOrdersMonthly = 'Duplicate Orders Monthly Report',
}

export enum CompanyFeatureFlagsTips {
	notifyOnPublish = 'Send laborers their assignments and updates to their assignments for the next work day.',
	notifyOnCancel = 'Notify laborers about canceled Work Orders.',
	notifyOnPause = 'Notify laborers about paused Work Orders.',
	notifyOnResume = 'Notify laborers about resumed Work Orders.',
	notifyScheduleBoardEmail = 'Send preview of tomorrow\'s Schedule Board to selected user group.',
	notifyYesterdaysScheduleBoardEmail = 'Send preview of yesterday\'s Schedule Board with Daily Metrics to selected user group.',
	notifyFailedNotifications = 'Send list of all notifications that failed to be sent that day to selected user group.',
	enablePastWorkOrdersNotification = 'Notify selected user group about any changes on Work Orders from last week or earlier.',
	enableItemLowQuantityNotification = 'Notify selected user group about Item low on stock',
	enableDailyItemLowQuantityNotification = 'Daily notify selected user group about Item low on stock',
	notifyUnassignedEmployees = 'Notify available laborers that have no assignment for the next day.',
	notifyMultipleAssignments = 'Send list of Work Order codes to laborers with multiple assignments.',
	notifySupervisors = 'Notify Supervisors and Project Managers about Draft And Outdated Work Orders for the next day.',
	notifyQAQC = 'Notify QA/QC assignee, Supervisor and Project Manager about Work Orders with a Deliverable job for the next day.',
	notifyDeliverableAssignee = 'Send Deliverable Assignment info to laborer.',
	customPDFHeader = 'When enabled, all PDF printout headers will be replaced with the custom header.',
	workRequests = 'When enabled, Work Requests will be visible and accessible from side menu.',
	portalFunctionalities = 'When enabled, Orders, Purchase Orders and Tool Repairs will be visible and accessible from side menu.',
	invoiceNotifications = 'When this is enabled, options to set automatic reminders for invoices, send manual reminders and notices for invoiced invoice, will also be enabled.',
	notifyOnReportReject = 'Notify laborers about rejected Reports.',
	notifyOnTimeSheetReject = 'Notify laborers about rejected Time Sheets.',
	notifyTemporaryLabor = 'Allows temporary labor to receive notifications regarding published, canceled, paused and resumed Work Orders',
	unfinishedPurchaseOrderCreatedNotification = 'Notify Purchase Order creator about Unfinished Purchase Order',
	purchaseOrderWithUnreceivedItems = 'Notify Purchase Order creator about Purchase Order with Unreceived Items',
	enableToolRepairNotification = 'Notify selected user group about tool repair requests.',
	invoiceRemindersNotification = 'Automatically send reminders for unpaid invoices. First reminder will get sent one day after invoice’s due date. Second reminder will be sent 30 days after due date. Third after 90 days have passed, and after that every two weeks (14 days)',
	notifyOnOrderCreation = 'Notify selected user group about order creation.',
	notifyForUnfinishedOrders = 'Notify the selected user group if order items are in "Unfulfilled" status 2 days after submission or in "On Order", "Awaiting Delivery" or "Awaiting Pickup" status 7 days after submission.',
	notifyForOrdersTotalExpenseDaily = 'Notify the selected user group about the total daily order expenses.',
	notifyForOrdersTotalExpenseWeekly = 'Notify the selected user group about the total weekly order expenses.',
	notifyForOrdersTotalExpenseMonthly = 'Notify the selected user group about the total monthly order expenses.',
	notifyForDuplicateOrdersMonthly = 'Notify the selected user group about monthly duplicated orders.',
	enableAutomaticNotificationsOnWorkDays = 'Automatic notifications will not be sent on non-working days or holidays. All notifications will be sent before the last working day is over (e.g. notifications for Saturday, Sunday and Monday will all be sent on Friday if weekend is considered non-working). Last-minute adjustments on non-work days will be sent immediately instead',
}

export default CompanyFeatureFlags;
