import * as React from 'react';
import * as InfiniteScrollComponent from 'react-infinite-scroll-component';

import LoadingIndicator from 'af-components/LoadingIndicator';

const AREA_BEFORE_TABLE_HEIGHT = 178; // 2x 64px for the header and 2x (24px + 1px) for standard padding around table elements
const DEFAULT_LOAD_CHUNK = 25;
const INITIAL_PAGE_VALUE = -1;

interface OwnProps<T> {
	data: Nullable<T[]>;
	totalCount: Nullable<number>;
	className?: string;
	autoHeight?: boolean;
	pageSize?: number;
	pageNumber?: number;
	fetch: (limit: number, page: number, isRefresh?: boolean) => void | Promise<void>;
	renderItem: (item: T, index: number, items: T[]) => JSX.Element | null;
	endMessage?: React.ReactNode;
	/** Used only to force scrollToLoad to rerender items
	 * Useful when renderItem depends on data that is loaded "at the same time" as the table data
	 * check condensed view and classification code, management, and accounting lists.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	reloadProp?: any;
	handlePageChange?: (nextPageNumber: number) => void;
}

type Props<T> = OwnProps<T>;

interface State {
	page: number;
	hasMore: boolean;
	heightOffset: number;
	height: Nullable<number>;
	loadedInitialData: boolean;
	isLoading: boolean;
}

export default class ScrollToLoad<T> extends React.PureComponent<Props<T>, State> {
	state: State = {
		page: INITIAL_PAGE_VALUE,
		hasMore: false,
		heightOffset: AREA_BEFORE_TABLE_HEIGHT,
		height: null,
		loadedInitialData: false,
		isLoading: false,
	};

	static elementId = 'infinite-scroll';

	loadingIndicator = (
		<div className="infinite-scroll-container">
			<div className="loading-item">
				<LoadingIndicator color="orange" />
			</div>
		</div>
	);

	_scrollComponentWrapperRef: Nullable<HTMLElement> = null;

	componentDidMount() {
		this.fetchData();
	}

	async componentDidUpdate(prevProps: Props<T>, prevState: State) {
		const { data, totalCount, pageNumber } = this.props;
		const { height, page, isLoading, loadedInitialData } = this.state;

		let hasMore = this.state.hasMore;

		if (data?.length !== prevProps.data?.length) {
			hasMore = data?.length && totalCount ? data?.length < totalCount : false;
			this.setState(() => ({ hasMore }));
		}

		const wrapperDimensions = this._scrollComponentWrapperRef?.getBoundingClientRect();
		if (!wrapperDimensions) {
			return;
		}

		const hasChanges = prevState.page !== page || prevProps.pageNumber !== pageNumber;
		if (hasChanges && !isLoading && page > INITIAL_PAGE_VALUE && hasMore && wrapperDimensions.height < (height ?? 0)) {
			await this.fetchData();
		}

		// Don't change page until initial data is loaded
		if (loadedInitialData && pageNumber !== undefined && pageNumber !== page) {
			this.setState(() => ({ page: pageNumber }));
		}
	}

	onMount = (scrollableRef) => {
		this._scrollComponentWrapperRef = scrollableRef?.el?.firstElementChild;

		const scrollableContainer = document.getElementById(ScrollToLoad.elementId);
		const scrollableContainerRect = scrollableContainer?.getBoundingClientRect();

		if (!scrollableContainerRect) {
			return;
		}
		const heightOffset = scrollableContainerRect.top + 1 + 16;
		const height = this.props.autoHeight ? null : window.innerHeight - heightOffset;
		// extra border-bottom and margin-bottom
		this.setState(() => ({ heightOffset, height }));
	};

	refreshList = () => {
		const { handlePageChange } = this.props;

		this.setState(
			() => ({ page: INITIAL_PAGE_VALUE }),
			async () => {
				await this.fetchData(true);
				const scrollableContainer = document.getElementById(ScrollToLoad.elementId);
				if (scrollableContainer) {
					scrollableContainer.scrollTop = 0;
				}
			}
		);

		if (handlePageChange) {
			handlePageChange(INITIAL_PAGE_VALUE);
		}
	};

	fetchData = async (isRefresh: boolean = false) => {
		const { fetch, pageSize, handlePageChange } = this.props;
		const { page, isLoading } = this.state;

		if (isLoading) {
			return;
		}

		this.setState(
			() => ({ isLoading: true }),
			async () => {
				await fetch(pageSize ?? DEFAULT_LOAD_CHUNK, page + 1, isRefresh);

				if (handlePageChange) {
					handlePageChange(page + 1);
				}

				this.setState(() => ({ page: page + 1, loadedInitialData: true, isLoading: false }));
			}
		);

	};

	render() {
		const { data, className, totalCount, autoHeight, renderItem, endMessage } = this.props;
		const { heightOffset, loadedInitialData } = this.state;

		if (!data) {
			return this.loadingIndicator;
		}

		const height = autoHeight ? null : window.innerHeight - heightOffset;
		return (
			<div
				className={`infinite-scroll-container ${!!className ? className : ''}`}
				id={ScrollToLoad.elementId}
				style={height ? { height } : {}}
			>
				<InfiniteScrollComponent
					className="scroll-to-load"
					dataLength={data?.length}
					endMessage={endMessage}
					hasMore={loadedInitialData && totalCount ? data?.length < totalCount : true}
					loader={this.loadingIndicator}
					next={this.fetchData}
					ref={this.onMount}
					scrollableTarget={ScrollToLoad.elementId}
				>
					{data.map(renderItem)}
				</InfiniteScrollComponent>
			</div>
		);
	}
}
