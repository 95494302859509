import * as React from 'react';

import Tab from './Tab';

enum TabsEnum {
	PLACEHOLDER = 'PLACEHOLDER',
	TEMPORARY_LABOR = 'TEMPORARY_LABOR'
}

const TAB_TITLES: Record<TabsEnum, string>  = {
	PLACEHOLDER: 'Placeholder',
	TEMPORARY_LABOR: 'Temporary Labor',
};

export const TAB_IDS: Record<TabsEnum, number> = {
	PLACEHOLDER: 0,
	TEMPORARY_LABOR: 1,
};

interface OwnProps {
	activeTabId: number;
	setActiveTabId: (tabId: number) => void;
}

type Props = OwnProps;

const Tabs: React.FC<Props> = (props: Props) => {
	const { activeTabId, setActiveTabId } = props;

	return (
		<div className="schedule-board-labor-modal__tabs">
			<Tab
				isSelected={activeTabId === TAB_IDS[TabsEnum.PLACEHOLDER]}
				onSelect={setActiveTabId}
				tabId={TAB_IDS[TabsEnum.PLACEHOLDER]}
				title={TAB_TITLES[TabsEnum.PLACEHOLDER]}
			/>
			<Tab
				isSelected={activeTabId === TAB_IDS[TabsEnum.TEMPORARY_LABOR]}
				onSelect={setActiveTabId}
				tabId={TAB_IDS[TabsEnum.TEMPORARY_LABOR]}
				title={TAB_TITLES[TabsEnum.TEMPORARY_LABOR]}
			/>
		</div>
	);
};

export default React.memo(Tabs);
