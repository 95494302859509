import * as React from 'react';

interface Props {
	code: string;
	shouldCopy: boolean;
}

export default React.memo(({ code, shouldCopy }: Props) => {
	return (
		<span key={code}>
			<span className={`icon-dot ${shouldCopy ? 'text-green' : 'text-orange'}`} />
			{code}
		</span>
	);
});
