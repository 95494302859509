import * as React from 'react';
import { Link, CustomRouteComponentProps } from 'react-router-dom';
import { InjectedFormProps, Field, FieldArray } from 'redux-form';
import { Row, Col, Form } from 'react-bootstrap';

import ContactRM from 'acceligent-shared/dtos/web/request/contact/upsert';
import AddressRM from 'acceligent-shared/dtos/web/request/address/upsert';

import CLIENT from 'af-constants/routes/client';

import Input from 'af-fields/Input';
import SubmitButton from 'af-components/SubmitButton';
import Addresses, { OwnProps as AddressesProps } from 'af-components/SharedForms/Address/Addresses';

import EmailContactMethods, { OwnProps as EmailProps } from '../ContactMethod/EmailContactMethods';
import PhoneContactMethods, { OwnProps as PhoneProps } from '../ContactMethod/PhoneContactMethods';
import { isValidTextInput } from 'acceligent-shared/utils/text';

interface RemoveAddressOnDeleteProps extends SharedOwnProps {
	removeOnDelete: true;
}

interface UpdateAddressOnDeleteProps extends SharedOwnProps {
	removeOnDelete: false;
	selector: (fieldName: string) => AddressRM;
}

interface SharedOwnProps extends InjectedFormProps<ContactRM> {
	companyName: string;
	hideButtons?: boolean;
	emailPhoneError?: string;
	onSubmit: (form: ContactRM) => Promise<void>;
	fullName: string;
}

type OwnProps = RemoveAddressOnDeleteProps | UpdateAddressOnDeleteProps;

interface PathParams {
	contactId: string;
}

type Props = OwnProps & (CustomRouteComponentProps<PathParams> | CustomRouteComponentProps);

interface State {
	removedEmails: ContactRM['emails'];
	removedPhones: ContactRM['phones'];
	removedAddresses: ContactRM['addresses'];
}

class ContactForm extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		hideButtons: false,
	};

	state: State = {
		removedEmails: [],
		removedPhones: [],
		removedAddresses: [],
	};

	removeEmail = (email: ContactRM['emails'][0]) => {
		const { touch } = this.props;
		touch?.('emails');
		this.setState((state) => ({ removedEmails: [...state.removedEmails, email] }));
	};

	removePhone = (phone: ContactRM['phones'][0]) => {
		const { touch } = this.props;
		touch?.('phones');
		this.setState((state) => ({ removedPhones: [...state.removedPhones, phone] }));
	};

	removeAddress = (address: ContactRM['addresses'][0]) => {
		this.setState((state) => ({ removedAddresses: [...state.removedAddresses, address] }));
	};

	submit = (form: ContactRM) => {
		const { onSubmit } = this.props;
		const { removedAddresses, removedPhones, removedEmails } = this.state;
		onSubmit({
			...form,
			emails: [...(form.emails ?? []), ...removedEmails],
			phones: [...(form.phones ?? []), ...removedPhones],
			addresses: [...form.addresses, ...removedAddresses],
		});
	};

	renderAddresses = () => {
		const { form, removeOnDelete, change } = this.props;

		if (removeOnDelete === false) {
			const { selector } = this.props;
			return (
				<FieldArray<AddressesProps>
					change={change}
					component={Addresses}
					formName={form}
					name="addresses"
					onAddressRemove={this.removeAddress}
					removeOnDelete={removeOnDelete}
					selector={selector}
				/>
			);
		}

		return (
			<FieldArray<AddressesProps>
				change={change}
				component={Addresses}
				formName={form}
				name="addresses"
				onAddressRemove={this.removeAddress}
				removeOnDelete={removeOnDelete}
			/>
		);
	};

	render() {
		const {
			location: { state: { orgAlias } },
			companyName,
			submitting,
			invalid,
			emailPhoneError,
			anyTouched,
			handleSubmit,
			change,
			hideButtons,
			fullName,
		} = this.props;

		const disableSubmit = invalid || !!emailPhoneError || !isValidTextInput(fullName);
		return (
			<Form>
				<div className="form-box-highlighted-input">
					<Row className="row--padded-top">
						<Col sm={8}>
							<Field
								className="contact__item"
								component={Input}
								label="Full name *"
								name="fullName"
								placeholder="Enter Contact Name *"
								type="text"
							/>
						</Col>
						<Col sm={8}>
							<Field
								component={Input}
								label="Title"
								name="title"
								placeholder="Title"
								type="text"
							/>
						</Col>
						<Col sm={8}>
							<Field
								component={Input}
								label="Company name"
								name="companyName"
								placeholder="Company name"
								type="text"
							/>
						</Col>
					</Row>
				</div>
				<div>
					<FieldArray<EmailProps>
						change={change}
						component={EmailContactMethods}
						customError={anyTouched ? emailPhoneError : undefined}
						name="emails"
						onRemoveContactMethod={this.removeEmail}
					/>
					<FieldArray<PhoneProps>
						change={change}
						component={PhoneContactMethods}
						customError={anyTouched ? emailPhoneError : undefined}
						name="phones"
						onRemoveContactMethod={this.removePhone}
					/>
				</div>
				<div>
					{this.renderAddresses()}
				</div>
				{!hideButtons &&
					<div className="form-box__after">
						<div>
							<Link
								className="btn btn-info"
								to={CLIENT.COMPANY.CONTACTS.LIST(orgAlias, companyName)}
							>
								Cancel
							</Link>
							<SubmitButton
								disabled={disableSubmit}
								onClick={handleSubmit(this.submit)}
								reduxFormSubmitting={submitting}
								variant="primary"
								variantDisabled="info"
							/>
						</div>
					</div>
				}
			</Form>
		);
	}
}

export default ContactForm;
