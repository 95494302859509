import * as React from 'react';

interface Props {
	value?: string;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
	onClear?: () => void;
}

export default class CustomDatePicker extends React.PureComponent<Props> {
	render() {
		const { value, onClick, onClear } = this.props;

		return (
			<div className="sb-resource-item__expected-return-date-picker">
				<div className="sb-resource-item__expected-return-date-picker__date">
					{value ?? 'N/A'}
				</div>
				{value && onClear && <div className="icon-close" onClick={onClear} />}
				<div className="btn-datepicker" onClick={onClick} />
			</div>
		);
	}
}
