import * as React from 'react';

import { EquipmentUtilizationViewModelShared, EquipmentUtilizationGroupViewModelBase } from 'ab-viewModels/equipmentUtilization.viewModel';

import _Table, { OwnProps as TableOwnProps, TabProps, Column, CellInfo } from 'af-components/Table6';
import TableComponent from 'af-components/Table6/Table';
import MonetaryCell from 'af-components/Table6/Cells/MonetaryCell';
import ScrollToLoad from 'af-components/ScrollToLoad';

import WorkingCell from '../UtilizationShared/WorkingCell';

const Table = _Table as unknown as React.ComponentClass<TableOwnProps<EquipmentUtilizationGroupViewModelBase>>;

interface OwnProps {
	tableName: string;
	timePeriodLabel: string;
	label: string;
	onMount?: (table: TableComponent<EquipmentUtilizationGroupViewModelBase>, list: ScrollToLoad<EquipmentUtilizationGroupViewModelBase>) => void;
	goDownInSelection: (groupingId: string, timePeriodId: string) => void;
	getSharedTabProps: () => Omit<TabProps<EquipmentUtilizationViewModelShared>, 'columns'>;
}

type Props = OwnProps;

class EquipmentUtilizationGroupTable extends React.PureComponent<Props> {

	readonly columns: Column<EquipmentUtilizationGroupViewModelBase>[] = [
		{
			Header: undefined,	// set in `tabs`
			accessor: 'name',
			sortable: true,
			Cell: ({ original }) => (
				<span>
					<strong>{original.name}</strong><span className="text-grey m-l-s">{original.equipmentCount}</span>
				</span>
			),
		},
		{
			Header: undefined,	// set in `tabs`
			accessor: 'timePeriodId',
			sortable: true,
			Cell: ({ original }) => original.timePeriodId,
		},
		{
			Header: 'Working',
			accessor: 'daysAssigned',
			sortable: true,
			Cell: WorkingCell,
		},
		{
			Header: 'Daily Revenue',
			accessor: 'dailyRevenue',
			sortable: true,
			Cell: MonetaryCell,
		},
		{
			Header: 'Daily Cost',
			accessor: 'dailyCost',
			sortable: true,
			Cell: MonetaryCell,
		},
		{
			Header: 'Target Profit/Loss',
			accessor: 'targetProfit',
			sortable: true,
			Cell: MonetaryCell,
		},
	];

	onRowClick = ({ original }: CellInfo<EquipmentUtilizationGroupViewModelBase>) => {
		const { goDownInSelection } = this.props;
		goDownInSelection(original.id.toString(), original.timePeriodId.toString());
	};

	tabs = (): TabProps<EquipmentUtilizationGroupViewModelBase>[] => {
		const { getSharedTabProps, label, timePeriodLabel } = this.props;

		this.columns[0].Header = label;
		this.columns[1].Header = timePeriodLabel;

		return [{
			...(getSharedTabProps() as Omit<TabProps<EquipmentUtilizationGroupViewModelBase>, 'columns'>),
			columns: this.columns,
			onRowClick: this.onRowClick,
		}];
	};

	render() {
		const { tableName, onMount } = this.props;

		return (
			<Table
				onMount={onMount}
				tableName={tableName}
				tabs={this.tabs()}
			/>
		);
	}
}

export default EquipmentUtilizationGroupTable;
