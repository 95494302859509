import * as React from 'react';

import UploadStatus from 'ab-enums/uploadStatus.enum';

import { bemElement as bem } from 'ab-utils/bem.util';
import Tooltip from 'af-components/Tooltip';

interface Props {
	uploadStatus: UploadStatus;
	uploadingFileName?: string;
	importedCount?: number;
	updatedCount?: number;
	filesError?: Nullable<JSX.Element>;
}

export default ({
	uploadStatus,
	uploadingFileName = '',
	importedCount = 0,
	updatedCount = 0,
	filesError,
}: Props) => {
	const commonMessage = (
		<>
			<span className={`icon-upload ${bem('dropzone-element', 'icon-upload')}`} />
			<span className={bem('dropzone-element', 'text')}>
				Drop files to attach or <span className="text-blue">click to browse</span>
			</span>
		</>
	);

	switch (uploadStatus) {
		case UploadStatus.UPLOADING:
			return <span className={bem('dropzone-element', 'text', ['uploading'])}>Uploading "{uploadingFileName}" ...</span>;
		case UploadStatus.SUCCESS:
			return (
				<>
					<span className="text-green">Imported records: <strong>{importedCount || 0}</strong>, Updated records: <strong>{updatedCount || 0}</strong>, File: <strong>{uploadingFileName}</strong></span>
					{commonMessage}
				</>
			);
		case UploadStatus.FAILURE:
			return (
				<>
					<Tooltip message={filesError} placement="top">
						<span className={bem('dropzone-element', 'error')}>"{uploadingFileName}" import failed</span>
					</Tooltip>
					{commonMessage}
				</>
			);
		case UploadStatus.INITIAL:
		default:
			return commonMessage;
	}
};
