import * as React from 'react';

import { DisplayViewEmployeeViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import UnavailableEmployeeItem from 'af-root/scenes/Company/ScheduleBoard/DisplayView/ResourceItems/EmployeeItems/UnavailableEmployeeItem';

interface OwnProps {
	employeeGroup: DisplayViewEmployeeViewModel[];
}

type Props = OwnProps;

const UnavailableEmployeeColumn: React.FC<Props> = (props: Props) => {
	const { employeeGroup } = props;

	return (
		<div className="display-view-unavailable-column-wrapper">
			<div className="display-view-column">
				{
					employeeGroup.map((_employee, _index) => (
						<UnavailableEmployeeItem employee={_employee} key={_index} />
					))
				}
			</div>
		</div>
	);
};

export default React.memo(UnavailableEmployeeColumn);
