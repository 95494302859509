import * as React from 'react';
import { DropdownOptionType } from '..';

export interface FlatListProps<T extends DropdownOptionType> {
	options: T[];
}

interface SharedProps<T extends DropdownOptionType> {
	calculatedPropName: keyof T;
	defaultValue?: Nullable<T>;
	filterOptions?: (option: T, index: number) => boolean;
	onChange: (item: string, options: T[]) => void;
	hasBlankOption: boolean;
	isLazyLoading: boolean;
	renderMenuItem: (item: Nullable<T>, index: number, searchText: string, onChangeHandler: (item: string) => void) => JSX.Element;
	searchText: string;
	selected: Nullable<T>;
}

type Props<T extends DropdownOptionType> = FlatListProps<T> & SharedProps<T>;

interface State<T extends DropdownOptionType> {
	options: T[];
}

class FlatListMenu<T extends DropdownOptionType> extends React.PureComponent<Props<T>, State<T>> {

	state: State<T> = {
		options: this.props.options,
	};

	static getDerivedStateFromProps(props: Props<Record<string, unknown>>) {
		const { options, filterOptions } = props;

		if (filterOptions) {
			return { options: options.filter(filterOptions) };
		}
		return { options };
	}

	componentDidMount() {
		const { defaultValue, selected, calculatedPropName } = this.props;

		if (defaultValue && !selected) {
			this.handleChange(defaultValue[calculatedPropName]);
		}
	}

	handleChange = (item: string) => {
		const { options, onChange } = this.props;
		onChange(item, options);
	};

	optionRenderItemMapper = (option, index) => {
		const { searchText, renderMenuItem } = this.props;
		return renderMenuItem(option, index, searchText, this.handleChange);
	};

	render() {
		const { options, hasBlankOption, isLazyLoading, renderMenuItem, searchText } = this.props;
		return (
			<>
				{hasBlankOption && !isLazyLoading && renderMenuItem(null, -1, searchText, this.handleChange)}
				{options.map(this.optionRenderItemMapper)}
			</>
		);
	}
}

export default FlatListMenu;
