import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Field, InjectedFormProps } from 'redux-form';

import AgencyCreateRM from 'ab-requestModels/agency/create.requestModel';
import AgencyUpdateRM from 'ab-requestModels/agency/update.requestModel';

import { bemBlock } from 'ab-utils/bem.util';
import { phoneNormalizer } from 'ab-utils/reduxForms.util';

import BackButton from 'af-components/BackButton';
import SubmitButton from 'af-components/SubmitButton';

import Input from 'af-fields/Input';
import ColorPicker from 'af-fields/ColorPicker';

import AgencyVM from 'ab-viewModels/agency/agency.viewModel';

interface OwnProps extends InjectedFormProps<AgencyCreateRM | AgencyUpdateRM> {
	onSubmit: () => void;
	agencyList: AgencyVM[];
	agencyId: Nullable<number>;
}

type Props = OwnProps;

const AgencyForm: React.FC<Props> = (props) => {

	const { onSubmit, submitting, valid, agencyList, agencyId } = props;

	const validateName = React.useCallback((fieldValue: string) => {

		if (agencyList.find(
			(agency) => (
				(agency.name === fieldValue && agency.id !== agencyId)
			)
		))
		{
			return 'Name already exists';
		}
		return undefined;
	}, [agencyId, agencyList]);

	const nameValidations = React.useMemo(() => ([validateName]), [validateName]);

	return (
		<Form onSubmit={onSubmit}>
			<div className="form-segment">
				<div className={bemBlock('form-box', ['padded-top'])}>
					<Row>
						<Col md="12">
							<Field
								autoFocus={true}
								component={Input}
								label="Name *"
								name="name"
								placeholder="Enter Agency Name"
								type="text"
								validate={nameValidations}
							/>
							<Field
								component={ColorPicker}
								label="Color"
								name="color"
							/>
						</Col>
						<Col md="12">
							<Field
								component={Input}
								label="Phone"
								name="phone"
								normalize={phoneNormalizer}
								placeholder="Enter Phone Number"
								type="text"
							/>
							<Field
								component={Input}
								label="E-mail"
								name="email"
								placeholder="Enter E-mail"
								type="text"
							/>
							<Field
								component={Input}
								label="Website"
								name="website"
								placeholder="Enter Website"
								type="text"
							/>
						</Col>
					</Row>
					<Row className="row--submit">
						<BackButton />
						<SubmitButton
							disabled={!valid}
							reduxFormSubmitting={submitting}
							variant="primary"
						/>
					</Row>
				</div>
			</div>
		</Form>
	);
};

export default React.memo(AgencyForm);
