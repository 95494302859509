import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';

import TimePeriodSpan, { TimePeriodSpanLabel } from 'acceligent-shared/enums/timePeriodSpan';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import { RootState } from 'af-reducers';

import * as KPIActions from 'af-actions/kpi';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { EquipmentUtilizationViewModelShared, EquipmentUtilizationViewModel, EquipmentUtilizationGroupViewModelBase } from 'ab-viewModels/equipmentUtilization.viewModel';
import { EquipmentUtilizationTableSelectionModel, EquipmentUtilizationTablesViewModel } from 'ab-viewModels/equipmentUtilizationTable.viewModel';

import EquipmentUtilizationLevel, { EquipmentUtilizationLevelLabel, getAllUpperEquipmentUtilizationLevels } from 'ab-enums/equipmentUtilizationLevel.enum';
import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import { TabProps } from 'af-components/Table6';
import TableComponent from 'af-components/Table6/Table';
import Breadcrumbs from 'af-components/Breadcrumbs';
import InfiniteScroll from 'af-components/ScrollToLoad';

import { bemElement } from 'ab-utils/bem.util';

import { downloadCSV } from 'af-utils/csv.utils';

import EquipmentUtilizationGroupTable from './EquipmentUtilizationGroupTable';
import EquipmentUtilizationTable from './EquipmentUtilizationTable';
import InfoCard from './InfoCard';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	ignoredTimePeriodId: Nullable<string>;
	data: Nullable<EquipmentUtilizationTablesViewModel>;
	selection: EquipmentUtilizationTableSelectionModel;
	timePeriod: TimePeriodSpan;
}

class EquipmentUtilization extends React.PureComponent<Props, State> {
	state: State = {
		ignoredTimePeriodId: null,
		data: null,
		selection: {
			level: EquipmentUtilizationLevel.TYPE,
		} as EquipmentUtilizationTableSelectionModel,
		timePeriod: TimePeriodSpan.YEAR,
	};

	private _table: Nullable<TableComponent<EquipmentUtilizationViewModel | EquipmentUtilizationGroupViewModelBase>> = null;
	private _list: Nullable<InfiniteScroll<EquipmentUtilizationViewModel | EquipmentUtilizationGroupViewModelBase>> = null;

	static getEquipmentUtilizationTable(
		data: Nullable<EquipmentUtilizationTablesViewModel>,
		selection: EquipmentUtilizationTableSelectionModel,
		tableRequestModel: TableQuery
	) {
		const sanitizedRequestModel = new TableQuery(tableRequestModel);

		return EquipmentUtilizationTablesViewModel.getTable(
			sanitizedRequestModel,
			selection,
			data
		) ?? {} as TableViewModel<EquipmentUtilizationViewModelShared>;
	}

	async componentDidMount() {
		const { fetchEquipmentUtilizationTables } = this.props;
		const data = await fetchEquipmentUtilizationTables();
		this.setState(() => ({ data }));
	}

	componentDidUpdate() {
		// every props and state change should cause a table refresh
		if (this._table) {
			this._table.refreshTable();
		} else if (this._list) {
			this._list.refreshList();
		}
	}

	onTableMount = (table: TableComponent<EquipmentUtilizationViewModel | EquipmentUtilizationGroupViewModelBase>, list) => {
		this._table = table;
		this._list = list;
	};

	fetchTable = async (tableRequestModel: TableQuery) => {
		const { data, selection } = this.state;

		return EquipmentUtilization.getEquipmentUtilizationTable(data, selection, tableRequestModel);
	};

	goDownInSelection = (groupingId: string, timePeriodId: string) => {
		const { selection } = this.state;

		switch (selection.level) {
			case EquipmentUtilizationLevel.TYPE:
				this.setState((state) => ({
					selection: {
						...state.selection,
						level: EquipmentUtilizationLevel.GROUP,
						equipmentType: groupingId,
						timePeriodId: timePeriodId,
					} as EquipmentUtilizationTableSelectionModel,
				}));
				break;
			case EquipmentUtilizationLevel.GROUP:
				this.setState((state) => ({
					selection: {
						...state.selection,
						level: EquipmentUtilizationLevel.CATEGORY,
						equipmentGroupId: groupingId,
						timePeriodId: timePeriodId,
					} as EquipmentUtilizationTableSelectionModel,
				}));
				break;
			case EquipmentUtilizationLevel.CATEGORY:
				this.setState((state) => ({
					selection: {
						...state.selection,
						level: EquipmentUtilizationLevel.EQUIPMENT_COST,
						equipmentCategoryId: groupingId,
						timePeriodId: timePeriodId,
					} as EquipmentUtilizationTableSelectionModel,
				}));
				break;
			case EquipmentUtilizationLevel.EQUIPMENT_COST:
				this.setState((state) => ({
					selection: {
						...state.selection,
						level: EquipmentUtilizationLevel.EQUIPMENT,
						equipmentCostId: groupingId,
						timePeriodId: timePeriodId,
					} as EquipmentUtilizationTableSelectionModel,
				}));
				break;
			default:
				console.error('Invalid Equipment Utilization Level');
		}

		this.setState(() => ({ ignoredTimePeriodId: null }));
	};

	goToSection = (level: EquipmentUtilizationLevel) => {

		switch (level) {
			case EquipmentUtilizationLevel.TYPE:
				this.setState((state) => ({
					selection: {
						...state.selection,
						level: EquipmentUtilizationLevel.TYPE,
						equipmentType: null,
						equipmentGroupId: null,
						equipmentCategoryId: null,
						equipmentCostId: null,
						timePeriodId: null,
					} as EquipmentUtilizationTableSelectionModel,
					ignoredTimePeriodId: null,
				}));
				break;
			case EquipmentUtilizationLevel.GROUP:
				this.setState((state) => ({
					selection: {
						...state.selection,
						level: EquipmentUtilizationLevel.GROUP,
						equipmentGroupId: null,
						equipmentCategoryId: null,
						equipmentCostId: null,
					} as EquipmentUtilizationTableSelectionModel,
				}));
				break;
			case EquipmentUtilizationLevel.CATEGORY:
				this.setState((state) => ({
					selection: {
						...state.selection,
						level: EquipmentUtilizationLevel.CATEGORY,
						equipmentCategoryId: null,
						equipmentCostId: null,
					} as EquipmentUtilizationTableSelectionModel,
				}));
				break;
			case EquipmentUtilizationLevel.EQUIPMENT_COST:
				this.setState((state) => ({
					selection: {
						...state.selection,
						level: EquipmentUtilizationLevel.EQUIPMENT_COST,
						equipmentCostId: null,
					} as EquipmentUtilizationTableSelectionModel,
				}));
				break;
			default:
				console.error('Invalid Equipment Utilization Level');
		}
	};

	onDownloadCSVClick = async () => {
		const { companyName } = this.props;
		const { selection, data } = this.state;

		if (!data) {
			throw new Error('Data not available');
		}

		const equipmentUtilizationViewModelSharedList = EquipmentUtilizationTablesViewModel.getSelectionRows(selection, data);

		const csvData = selection.level === EquipmentUtilizationLevel.EQUIPMENT
			? EquipmentUtilizationViewModel.toCSVData(equipmentUtilizationViewModelSharedList as EquipmentUtilizationViewModel[])
			: EquipmentUtilizationGroupViewModelBase.toCSVData(
				equipmentUtilizationViewModelSharedList as EquipmentUtilizationGroupViewModelBase[],
				EquipmentUtilizationLevelLabel[selection.level]
			);

		downloadCSV(csvData, `${companyName}_equipment-utilization.csv`);
	};

	changeEquipmentCost = (equipmentCostId: string) => {
		this.setState((state) => ({ selection: { ...state.selection, equipmentCostId } as EquipmentUtilizationTableSelectionModel }));
	};

	toggleTimePeriodIgnore = () => {
		const { ignoredTimePeriodId, selection: { timePeriodId } } = this.state;
		const oldTimePeriodId = timePeriodId;
		const newTimePeriodId = ignoredTimePeriodId;

		this.setState((state) => ({
			ignoredTimePeriodId: oldTimePeriodId,
			selection: {
				...state.selection,
				timePeriodId: newTimePeriodId,
			} as EquipmentUtilizationTableSelectionModel,
		}));
	};

	renderToggleTimePeriodIgnore = () => {
		const { ignoredTimePeriodId, timePeriod, selection: { timePeriodId } } = this.state;
		const timePeriodLabel = TimePeriodSpanLabel[timePeriod];
		const selectedTimePeriod = timePeriodId ?? ignoredTimePeriodId;

		return (
			<ButtonGroup className="utilization-view__year-switch">
				<Button
					active={timePeriodId !== null}
					className={bemElement('btn', 'switch-group-item')}
					onClick={this.toggleTimePeriodIgnore}
				>
					{timePeriodLabel} {selectedTimePeriod}
				</Button>
				<Button
					active={ignoredTimePeriodId !== null}
					className={bemElement('btn', 'switch-group-item')}
					onClick={this.toggleTimePeriodIgnore}
				>
					All {timePeriodLabel}s
				</Button>
			</ButtonGroup>
		);
	};

	getSharedTabProps = (): Omit<TabProps<EquipmentUtilizationViewModelShared>, 'columns'> => {
		const { level } = this.state.selection;

		const tabProps: Omit<TabProps<EquipmentUtilizationViewModelShared>, 'columns'> = {
			label: EquipmentUtilizationLevelLabel[level].toUpperCase(),
			fetch: this.fetchTable,
			hasSearchInput: true,
			searchLabel: EquipmentUtilizationLevelLabel[level],
			buttons: [{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: this.onDownloadCSVClick,
			}],
		};

		if (level !== EquipmentUtilizationLevel.TYPE) {
			tabProps.additionalFilter = this.renderToggleTimePeriodIgnore;
		}

		return tabProps;
	};

	breadcrumbs = () => {
		const { level } = this.state.selection;

		const upperLevels = getAllUpperEquipmentUtilizationLevels(level);
		return [
			...upperLevels.map((_level) => ({ label: EquipmentUtilizationLevelLabel[_level], onClick: this.goToSection.bind(this, _level) })),
			{ label: EquipmentUtilizationLevelLabel[level] },
		];
	};

	render() {
		const {
			location: { state: { orgAlias } },
			companyName,
			history,
		} = this.props;
		const { data, selection, timePeriod } = this.state;
		const { level } = selection;

		if (!level || !EquipmentUtilizationLevel[level]) {
			return null;
		}
		const sharedProps = {
			tableName: `${TableNameEnum.EQUIPMENT_UTILIZATION}#${level}`,
			getSharedTabProps: this.getSharedTabProps,
			timePeriodLabel: TimePeriodSpanLabel[timePeriod],
		};

		if (level === EquipmentUtilizationLevel.EQUIPMENT && !!data) {
			const { equipmentCostTablesByCategory, timePeriodIds } = data;
			const { equipmentCategoryId, timePeriodId, equipmentCostId } = selection;

			const equipmentCostTable = equipmentCategoryId
				? equipmentCostTablesByCategory?.[equipmentCategoryId]?.[timePeriodId ?? timePeriodIds[timePeriodIds.length - 1]]
				: null;
			const equipmentCost = equipmentCostTable?.rows?.find((_equipmentCost) => _equipmentCost.id.toString() === equipmentCostId);

			return (
				<div className="utilization-view form-segment">
					<Breadcrumbs items={this.breadcrumbs()} />
					<InfoCard
						changeEquipmentCost={this.changeEquipmentCost}
						equipmentCostOptions={equipmentCostTable?.rows ?? []}
						selectedEquipmentCost={equipmentCost}
					/>
					<EquipmentUtilizationTable
						{...sharedProps}
						companyName={companyName}
						onMount={this.onTableMount}
						orgAlias={orgAlias}
						redirectTo={history.push}
					/>
				</div>
			);
		}

		return (
			<div className="utilization-group-view form-segment">
				<Breadcrumbs items={this.breadcrumbs()} />
				<EquipmentUtilizationGroupTable
					{...sharedProps}
					goDownInSelection={this.goDownInSelection}
					label={EquipmentUtilizationLevelLabel[level]}
					onMount={this.onTableMount}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		fetchEquipmentUtilizationTables: KPIActions.fetchEquipmentUtilizationTables,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(EquipmentUtilization);
