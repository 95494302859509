import * as React from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { bemElement } from 'ab-utils/bem.util';

import { OrderChangedFormModel, ResourceStatusesList, ResourceStatusesItem } from '../types';

import DraggableItem from './DraggableItem';

interface OwnProps {
	available: boolean;
	items: ResourceStatusesList;
	onOrderChange: (form: OrderChangedFormModel) => void;
	deleteStatus: (available: boolean, name: string) => void;
}

type Props = OwnProps;

const CONTAINER_EMPTY_CLASS_NAME = `${bemElement('company-settings', 'empty')} ${bemElement('company-settings', 'feature-flag', ['indented'])}`;

class DroppableContainer extends React.PureComponent<Props> {

	onDragEnd = ({ draggableId, source, destination }: DropResult) => {
		const { available, onOrderChange } = this.props;
		if (!destination || source.index === destination.index) {
			return;
		}
		onOrderChange({
			available,
			sourceName: draggableId,
			sourceIndex: source.index,
			destinationIndex: destination.index,
		});
	};

	renderDraggable = (item: ResourceStatusesItem, index: number) => {
		const { available, deleteStatus } = this.props;
		const { name } = item;

		return (
			<Draggable
				draggableId={name}
				index={index}	// important
				key={name}
			>
				{(_provided) => (
					<DraggableItem
						available={available}
						deleteStatus={deleteStatus}
						name={name}
						provided={_provided}
					/>
				)}
			</Draggable>
		);
	};

	render() {
		const { items } = this.props;

		if (!items?.length) {
			return (
				<div className={CONTAINER_EMPTY_CLASS_NAME}>
					There are no statuses available.
				</div>
			);
		}

		return (
			<DragDropContext onDragEnd={this.onDragEnd} >
				<Droppable droppableId="resource-statuses">
					{(droppableProvided) => (
						<div ref={droppableProvided.innerRef}>
							{items.map(this.renderDraggable)}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}

export default DroppableContainer;
