import * as React from 'react';
import { Button } from 'react-bootstrap';

import Tooltip from 'af-components/Tooltip';

import { PREVIEW_LINK_COPIED_MESSAGE_TIME } from 'af-constants/values';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	link: string;
}

type Props = OwnProps;

interface State {
	copyToClipboardText: string;
}

class PreviewLink extends React.Component<Props, State> {
	state: State = {
		copyToClipboardText: 'Copy to clipboard.',
	};
	timeoutTimer: number;

	componentWillUnmount = () => {
		if (this.timeoutTimer) {
			window.clearTimeout(this.timeoutTimer);
		}
	};

	copyToClipboard = () => {
		this.setState(() => ({ copyToClipboardText: 'Copied!' }));
		const el = document.getElementById('copy-to-clipboard') as HTMLInputElement;
		el.select();
		document.execCommand('copy');
	};

	clearCopyToClipboardText = () => {
		this.timeoutTimer = window.setTimeout(
			() => this.setState(() => ({ copyToClipboardText: 'Copy to clipboard.' })),
			PREVIEW_LINK_COPIED_MESSAGE_TIME
		);
	};

	renderCopyToClipboard = () => {
		const { link } = this.props;

		return (
			<>
				<Tooltip
					message={this.state.copyToClipboardText}
					onExited={this.clearCopyToClipboardText}
					placement="right"
				>
					<Button
						className={`btn btn--flat btn--icon ${bemElement('preview-link', 'icon')}`}
						onClick={this.copyToClipboard}
					>
						<span className="icon-copy" />
					</Button>
				</Tooltip>
				<input className="copy-to-clipboard" id="copy-to-clipboard" readOnly={true} type="text" value={link} />
			</>
		);
	};

	openLink = () => {
		const { link } = this.props;
		window.open(link, '_blank');
	};

	render() {
		const { link } = this.props;

		return (
			<div className="preview-link">
				<a className={bemElement('preview-link', 'text')} onClick={this.openLink}>{link}</a>
				{this.renderCopyToClipboard()}
			</div>
		);
	}
}

export default PreviewLink;
