import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { SelectedWorkOrderModel } from 'af-models/scheduleBoard.models';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { SET_PER_DIEM_FOR_WORK_ORDERS } from 'af-constants/reduxForms';

interface OwnProps {
	modalVisible: boolean;
	closeModal: () => void;
	onSave: () => void;
	selectedWorkOrders: SelectedWorkOrderModel[];
}

type Props = OwnProps;

export default class AddPerDiemModal extends React.PureComponent<Props> {

	render() {
		const { modalVisible, selectedWorkOrders, onSave, closeModal } = this.props;

		return (
			<CustomModal
				className="per-diem-modal"
				closeModal={closeModal}
				modalStyle="info"
				showModal={modalVisible}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Add Per Diem to Work Orders"
				/>
				<CustomModal.Body>
					<Row>
						<Col sm={24}>
							Laborers on the following work order(s) will be marked for "Per Diem":
							<br />
							<div className="work-order-modal__codes-list">
								<div className="work-order-modal__codes-group">
									{selectedWorkOrders.map((_workOrder) =>
										<span key={_workOrder.code}>
											<span className="icon-dot text-green" />
											{_workOrder.code}
										</span>
									)}
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col sm={24}>
							Per Diems are visible only to certain user roles and are indicated by a little green dot
							in the lower-right part of each laborer card.
							<div className="per-diem-modal__example">
								<div className="per-diem-modal__employee">
									J Doe
									<div className="per-diem-modal__indicator" />
								</div>
							</div>
						</Col>
					</Row>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Close
					</Button>
					<SubmitButton
						label="Add Per Diem"
						onClick={onSave}
						submitKey={SET_PER_DIEM_FOR_WORK_ORDERS}
						variant="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}
