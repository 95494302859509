import * as React from 'react';

export enum DeviceType {
	MOBILE = 'MOBILE',
	TABLET = 'TABLET',
	PC = 'PC',
}

const MOBILE_MAX_WIDTH = 600;
const TABLET_MAX_WIDTH = 1024;

const getDeviceTypeFromWindowWidth = (windowWidth: number): DeviceType => {
	let type: DeviceType = DeviceType.MOBILE;

	if (windowWidth <= MOBILE_MAX_WIDTH) {
		type = DeviceType.MOBILE;
	} else if (windowWidth > MOBILE_MAX_WIDTH && windowWidth <= TABLET_MAX_WIDTH) {
		type = DeviceType.TABLET;
	} else {
		type = DeviceType.PC;
	}
	return type;
};

export const useDeviceType = (): DeviceType => {

	const initialType = getDeviceTypeFromWindowWidth(window.innerWidth);
	const [deviceType, setDeviceType] = React.useState<DeviceType>(initialType);

	const resizeHandler = React.useCallback(() => {
		const resizedDeviceType = getDeviceTypeFromWindowWidth(window.innerWidth);
		setDeviceType(resizedDeviceType);
	}, []);

	React.useEffect(() => {
		window.addEventListener('resize', resizeHandler);
		return () => window.removeEventListener('resize', resizeHandler);
	}, [resizeHandler]);

	return deviceType;
};

