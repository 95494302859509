import * as React from 'react';

import { dollarFormatter } from 'af-utils/format.util';

import styles from './styles.module.scss';

interface Props {
	totalInvoiced: number;
	totalPaid: number;
	outstandingDebt: number;
}

const TableHeader: React.FC<Props> = ({ totalInvoiced, totalPaid, outstandingDebt }) => {
	return (
		<div className={styles['invoice__table-header']}>
			<span>
				Total Invoiced:
			</span>
			<b>
				{dollarFormatter.format(totalInvoiced)}
			</b>
			<span>
				Total Paid:
			</span>
			<b>
				{dollarFormatter.format(totalPaid)}
			</b>
			<span>
				Outstanding Debt:
			</span>
			<b>
				{dollarFormatter.format(outstandingDebt)}
			</b>
		</div>
	);
};

export default React.memo(TableHeader);
