import * as React from 'react';

import Pill from './Pill';

interface OwnProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: any;
	labelKey: string;
	colorKey?: string;
	emptyLabel: string;
}

type Props = OwnProps;

class PillsList extends React.PureComponent<Props> {

	render() {
		const {
			items,
			labelKey,
			colorKey,
			emptyLabel,
		} = this.props;

		return (
			<div className="pills pills--non-padded">
				{!!items.length && items.map((_item: Metadata, _index: number) =>
					<Pill
						color={colorKey && _item[colorKey]}
						index={_index}
						isLocked={true}
						item={_item}
						key={`pill#${_index}`}
						labelKey={labelKey}
					/>
				)}
				{!items.length &&
					<div className="pills__empty">{emptyLabel}</div>
				}
			</div>
		);
	}
}

export default PillsList;
