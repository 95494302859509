import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';

import { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import FSN from './FSN';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector>;

const AccountingFSN = (props: Props) => {
	const { companyData, userRole, location: { state: { orgAlias }, pathname } } = props;
	const companyName = companyData.name;

	const links = React.useMemo(() => ([
		{
			url: CLIENT.COMPANY.ACCOUNTING.PER_DIEM_REPORT.TABLE(orgAlias, companyName),
			label: 'Per Diem Report',
			icon: 'icon-per_diem',
			visibleFor: PagePermissions.COMPANY.ACCOUNTING.PER_DIEM,
			isActive: pathname.includes(CLIENT.COMPANY.ACCOUNTING.PER_DIEM_REPORT.ROOT(orgAlias, companyName)),
		},
		{
			url: CLIENT.COMPANY.ACCOUNTING.PREVAILING_WAGE.TABLE(orgAlias, companyName),
			label: 'Prevailing Wage Report',
			icon: 'icon-wo_table',
			visibleFor: PagePermissions.COMPANY.ACCOUNTING.PREVAILING_WAGE,
			isActive: (
				pathname.includes(CLIENT.COMPANY.ACCOUNTING.PREVAILING_WAGE.ROOT(orgAlias, companyName))
				&& !pathname.includes(CLIENT.COMPANY.ACCOUNTING.PREVAILING_WAGE_EXCEPTION_REPORT.ROOT(orgAlias, companyName))
			),
		},
		{
			url: CLIENT.COMPANY.ACCOUNTING.PREVAILING_WAGE_EXCEPTION_REPORT.TABLE(orgAlias, companyName),
			label: 'Exception Report for PW',
			icon: 'icon-wo_table',
			visibleFor: PagePermissions.COMPANY.ACCOUNTING.PREVAILING_WAGE,
			isActive: pathname.includes(CLIENT.COMPANY.ACCOUNTING.PREVAILING_WAGE_EXCEPTION_REPORT.ROOT(orgAlias, companyName)),
		},
		{
			url: CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_EXPORT(orgAlias, companyName),
			label: 'Job Payroll Export',
			icon: 'icon-download',
			visibleFor: PagePermissions.COMPANY.ACCOUNTING.JOB_PAYROLL,
			isActive: pathname.includes(CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_EXPORT(orgAlias, companyName)),
		},
		{
			url: CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_EXPORT_BETA(orgAlias, companyName),
			label: 'Job Payroll Export Beta',
			icon: 'icon-download',
			isForceHidden: DisabledFeatures.RedundantJobPayrollReportFE,
			visibleFor: PagePermissions.COMPANY.ACCOUNTING.JOB_PAYROLL,
			isActive: pathname.includes(CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_EXPORT_BETA(orgAlias, companyName)),
		},
		{
			url: CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_REPORT.TABLE(orgAlias, companyName),
			label: 'Job Payroll Report',
			icon: 'icon-daily_report',
			visibleFor: PagePermissions.COMPANY.ACCOUNTING.JOB_PAYROLL,
			isActive: pathname.includes(CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_REPORT.ROOT(orgAlias, companyName)),
		},
		{
			url: CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_REPORT_BETA.TABLE(orgAlias, companyName),
			label: 'Job Payroll Report Beta',
			icon: 'icon-daily_report',
			isForceHidden: DisabledFeatures.RedundantJobPayrollReportFE,
			visibleFor: PagePermissions.COMPANY.ACCOUNTING.JOB_PAYROLL,
			isActive: pathname.includes(CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_REPORT_BETA.ROOT(orgAlias, companyName)),
		},
		{
			url: CLIENT.COMPANY.ACCOUNTING.JOB_WORK_SUMMARY.TABLE(orgAlias, companyName),
			label: 'Work Summary',
			icon: 'icon-wo_table',
			visibleFor: PagePermissions.COMPANY.ACCOUNTING.WORK_SUMMARY,
			isActive: pathname.includes(CLIENT.COMPANY.ACCOUNTING.JOB_WORK_SUMMARY.ROOT(orgAlias, companyName)),
		},
		{
			url: CLIENT.COMPANY.ACCOUNTING.INVOICES.TABLE(orgAlias, companyName),
			label: 'Invoices',
			icon: 'icon-wo_table',
			visibleFor: PagePermissions.COMPANY.ACCOUNTING.MANAGE_INVOICES,
			isActive: pathname.includes(CLIENT.COMPANY.ACCOUNTING.INVOICES.ROOT(orgAlias, companyName)),
			isForceHidden: DisabledFeatures.Invoices,
		},
	]), [orgAlias, companyName, pathname]);

	return (
		<FSN
			infoLabel="Accounting"
			isCompanyAdmin={companyData.isCompanyAdmin}
			links={links}
			permissions={companyData.permissions}
			scrollLabel="none"
			userRole={userRole}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
		userRole: userData.role,
	};
}

const connector = connect(mapStateToProps);

export default connector(AccountingFSN);
