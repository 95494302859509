import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { DragDropContext, DropResult, Droppable, Draggable } from 'react-beautiful-dnd';

import LocationOptionVM from 'ab-viewModels/location/option.viewModel';

import DraggableLocation from './DraggableLocation';
import DraggableLoading from './DraggableLoading';

export interface OwnProps {
	isLoading: boolean;
}

interface Props extends OwnProps, WrappedFieldArrayProps<LocationOptionVM> { }

export default class SortableLocations extends React.PureComponent<Props> {

	onDragEnd = ({ source, destination }: DropResult) => {
		const { fields } = this.props;
		if (destination) {
			fields.move(source.index, destination.index);
		}
	};

	render() {
		const { fields, isLoading } = this.props;

		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable-locations">
					{(droppableProvided) => (
						<div
							ref={droppableProvided.innerRef}
							{...droppableProvided.droppableProps}
							className="locations-table__droppable-container"
						>
							{!isLoading ?
								fields.map((_locationPropName, _index) => {
									const loc = fields.get(_index);
									return (
										<Draggable
											draggableId={`draggable-location-${_index}`}
											index={_index}
											key={_locationPropName}
										>
											{(draggableProvided, draggableSnapshot) => (
												<DraggableLocation
													color={loc.color}
													nickname={loc.nickname}
													provided={draggableProvided}
													snapshot={draggableSnapshot}
												/>
											)}
										</Draggable>
									);
								}) :
								<>
									<DraggableLoading />
									<DraggableLoading />
									<DraggableLoading />
									<DraggableLoading />
								</>
							}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}
