import * as React from 'react';

import SelectOrCreateTree from './SelectOrCreateTree';

interface OwnProps {
	formName: string;
	changeField: (field: string, value: string | number) => void;
}

type Props = OwnProps;

class EquipmentCostHeader extends React.PureComponent<Props> {
	render() {
		const { formName, changeField } = this.props;

		return (
			<div className="equipment-cost-upsert__header">
				<div>
					<SelectOrCreateTree
						changeField={changeField}
						initialValuePropName="category"
						reduxFormName={formName}
						reduxPropName="categoryId"
					/>
				</div>
			</div>
		);
	}
}

export default EquipmentCostHeader;
