import { Dispatch, AnyAction } from 'redux';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import API from 'af-constants/routes/api';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import MessageTableVM from 'ab-viewModels/message/messageTable.viewModel';
import MessageVM from 'ab-viewModels/message/message.viewModel';
import MessageStatusHistoryTableVM from 'ab-viewModels/message/messageStatusHistoryTable.viewModel';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { GetRootState } from 'af-reducers';

export function findMessagesForTable(tableRequestModel: TableQuery, startDate: Date, endDate: Date) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.ISO_DATETIME);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.ISO_DATETIME);

			return await http.get<TableViewModel<MessageTableVM>>(
				API.V1.MESSAGE.FIND_FOR_TABLE(data, startDateString, endDateString)
			);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findMessageById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<MessageVM>(API.V1.MESSAGE.FIND_BY_ID(id));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findMessagesStatusHistoryForTable(messageId: number, tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<TableViewModel<MessageStatusHistoryTableVM>>(
				API.V1.MESSAGE.FIND_MESSAGE_STATUS_HISTORY_FOR_TABLE(messageId.toString(), tableRequestModel)
			);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
