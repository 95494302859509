import * as React from 'react';

interface Props {
	isDark?: boolean;
}

export default class AttachmentsBadge extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		isDark: false,
	};

	render() {
		const { isDark } = this.props;

		let className = 'attachments-badge icon-attachment';
		className = isDark ? `${className} attachments-badge--dark` : className;

		return (
			<span className={className} />
		);
	}
}
