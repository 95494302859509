import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import * as ReduxUtils from 'ab-utils/reduxForms.util';

import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';

import Section from '../Shared/Section';

interface OwnProps {
	disabled: boolean;
	resetTimer: () => void;
}

type Props = OwnProps;

const TEXTAREA_ROW_COUNT = 6;
const DESCRIPTION_MAX_CHAR_COUNT = 750;

const WorkOrderProductionData: React.FC<Props> = (props) => {

	const { disabled, resetTimer } = props;

	return (
		<Section label="Production Data">
			<Col sm={6}>
				<Field
					component={Input}
					disabled={disabled}
					forceShowError={true}
					format={ReduxUtils.formatDecimalNumber}
					id="revenue"
					isDollarValue={true}
					label="Revenue"
					name="revenue"
					normalize={ReduxUtils.normalizeDecimalNumber}
					placeholder="$00.00"
					type="text"
				/>
			</Col>
			<Col sm={6}>
				<Field
					component={Input}
					disabled={disabled}
					forceShowError={true}
					format={ReduxUtils.formatDecimalNumber}
					id="manHourAverage"
					isDollarValue={true}
					label="Man Hour Average"
					name="manHourAverage"
					normalize={ReduxUtils.normalizeDecimalNumber}
					placeholder="$00.00"
					type="text"
				/>
			</Col>
			<Col sm={4}>
				<Field
					component={Input}
					disabled={disabled}
					format={ReduxUtils.formatDecimalNumber}
					id="jobHours"
					label="Job Hours"
					name="jobHours"
					normalize={ReduxUtils.normalizeDecimalNumber}
					placeholder="Enter Job Hours"
					type="text"
				/>
			</Col>
			<Col sm={4}>
				<Field
					component={Input}
					disabled={disabled}
					format={ReduxUtils.formatDecimalNumber}
					id="shopHours"
					label="Shop Hours"
					name="shopHours"
					normalize={ReduxUtils.normalizeDecimalNumber}
					placeholder="Enter Shop Hours"
					type="text"
				/>
			</Col>
			<Col sm={4}>
				<Field
					component={Input}
					disabled={disabled}
					format={ReduxUtils.formatDecimalNumber}
					id="travelHours"
					label="Travel Hours"
					name="travelHours"
					normalize={ReduxUtils.normalizeDecimalNumber}
					placeholder="Enter Travel Hours"
					type="text"
				/>
			</Col>
			<Col sm={24}>
				<Field
					component={Textarea}
					disabled={disabled}
					label="Work Description"
					maxCharacters={DESCRIPTION_MAX_CHAR_COUNT}
					name="workDescription"
					onValueChange={resetTimer}
					placeholder="Enter Work Description"
					rows={TEXTAREA_ROW_COUNT}
					showMaxCharactersLabel={true}
				/>
			</Col>
		</Section>
	);
};

export default React.memo(WorkOrderProductionData);
