import * as TimeUtils from 'acceligent-shared/utils/time';

import * as actionTypes from 'af-constants/actionTypes';

import { WorkOrderForm } from 'ab-requestModels/workOrder.requestModel';

import WorkOrderIdAndDueDate from 'ab-viewModels/workOrderIdAndDueDate.viewModel';
import * as WorkOrderUpsertVM from 'ab-viewModels/workOrder/workOrderUpsert.viewModel';
import WorkOrderCopyVM from 'ab-viewModels/workOrder/copyWorkOrders.viewModel';

type MomentType = ReturnType<typeof TimeUtils.parseMoment>;

export interface GetWorkOrders {
	type: typeof actionTypes.FIND_ALL_WORK_ORDERS;
	payload: Nullable<WorkOrderForm>[] | null;
	selectedDate: MomentType;
	filterByText: string;
}

export interface LockWorkOrder {
	type: typeof actionTypes.LOCK_WORK_ORDER;
	payload: WorkOrderIdAndDueDate;
}

export interface UnlockWorkOrder {
	type: typeof actionTypes.UNLOCK_WORK_ORDER;
	payload: WorkOrderIdAndDueDate;
}

export interface DeleteWorkOrder {
	type: typeof actionTypes.DELETE_WORK_ORDER;
	payload: number;
}

export interface GetWorkOrderById {
	type: typeof actionTypes.FIND_WORK_ORDER;
	payload: WorkOrderUpsertVM.Default;
}

export interface GetWorkOrderConfirmationData {
	type: typeof actionTypes.GET_WORK_ORDER_CONFIRMATION_DATA;
	payload: Buffer;
}

export interface ClearWorkOrderParticipantsEmailStatus {
	type: typeof actionTypes.CLEAR_WORK_ORDER_PARTICIPANTS_EMAIL_STATUS;
}

export interface SaveWorkOrder {
	type: typeof actionTypes.SAVE_WORK_ORDER;
}

export interface ClearWorkOrderList {
	type: typeof actionTypes.CLEAR_WORK_ORDER_LIST;
}

export interface ResetWorkOrder {
	type: typeof actionTypes.RESET_WORK_ORDER;
}

export function FIND_ALL_WORK_ORDERS(payload: Nullable<WorkOrderForm>[] | null, selectedDate?: MomentType, filterByText?: string): GetWorkOrders {
	return {
		type: actionTypes.FIND_ALL_WORK_ORDERS,
		payload,
		selectedDate: selectedDate ?? null,
		filterByText: filterByText ?? '',
	};
}

export function LOCK_WORK_ORDER(payload: WorkOrderIdAndDueDate): LockWorkOrder {
	return {
		type: actionTypes.LOCK_WORK_ORDER,
		payload,
	};
}

export function UNLOCK_WORK_ORDER(payload: WorkOrderIdAndDueDate): UnlockWorkOrder {
	return {
		type: actionTypes.UNLOCK_WORK_ORDER,
		payload,
	};
}

export function FIND_WORK_ORDER(payload: WorkOrderUpsertVM.Default): GetWorkOrderById {
	return {
		type: actionTypes.FIND_WORK_ORDER,
		payload,
	};
}

export function DELETE_WORK_ORDER(payload: number): DeleteWorkOrder {
	return {
		type: actionTypes.DELETE_WORK_ORDER,
		payload,
	};
}

export function GET_WORK_ORDER_CONFIRMATION_DATA(payload: Buffer): GetWorkOrderConfirmationData {
	return {
		type: actionTypes.GET_WORK_ORDER_CONFIRMATION_DATA,
		payload,
	};
}

export function CLEAR_WORK_ORDER_PARTICIPANTS_EMAIL_STATUS(): ClearWorkOrderParticipantsEmailStatus {
	return {
		type: actionTypes.CLEAR_WORK_ORDER_PARTICIPANTS_EMAIL_STATUS,
	};
}

export function SAVE_WORK_ORDER(): SaveWorkOrder {
	return {
		type: actionTypes.SAVE_WORK_ORDER,
	};
}

export function CLEAR_WORK_ORDER_LIST(): ClearWorkOrderList {
	return {
		type: actionTypes.CLEAR_WORK_ORDER_LIST,
	};
}

export function RESET_WORK_ORDER(): ResetWorkOrder {
	return {
		type: actionTypes.RESET_WORK_ORDER,
	};
}

interface SetCopyWorkOrderModalData {
	type: typeof actionTypes.SET_COPY_WORK_ORDER_MODAL_DATA;
	payload: WorkOrderCopyVM;
}
export function SET_COPY_WORK_ORDER_MODAL_DATA(payload: WorkOrderCopyVM): SetCopyWorkOrderModalData {
	return {
		type: actionTypes.SET_COPY_WORK_ORDER_MODAL_DATA,
		payload,
	};
}

export type WorkOrderAction = GetWorkOrders | GetWorkOrderById
	| GetWorkOrderConfirmationData
	| ClearWorkOrderParticipantsEmailStatus
	| DeleteWorkOrder | SaveWorkOrder
	| LockWorkOrder | UnlockWorkOrder
	| ClearWorkOrderList | ResetWorkOrder
	| SetCopyWorkOrderModalData;
