import * as React from 'react';

import styles from './styles.module.scss';

interface Props {
	sticky?: boolean;
	id?: string;
}

const FormHeader = (props: React.PropsWithChildren<Props>) => {
	const { children, sticky, id } = props;

	const className = React.useMemo(() => {
		if (sticky) {
			return `${styles.form__header} ${styles['form__header--sticky']}`;
		}
		return styles.form__header;
	}, [sticky]);

	return (
		<div className={className} id={id}>
			{children}
		</div>
	);
};

export default React.memo(FormHeader);
