/* eslint-disable react/no-find-dom-node */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, ResolveThunks } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { GoogleMap, Marker, MarkerProps, DirectionsRenderer, Autocomplete } from '@react-google-maps/api';

import { Row, Col } from 'react-bootstrap';

import Input from 'af-fields/Input';

import { DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER } from 'af-constants/values';

import * as AddressesActions from 'af-actions/addresses';

import { RootState } from 'af-reducers';

import GoogleScriptLoader from '../Shared/Loader';
import { OnMapClickFunction } from '../AddressMap/MapRow';

interface OwnProps {
	onMapClick?: OnMapClickFunction;
	marker: Nullable<MarkerProps>;
	name?: string;
	change: (field: string, value: string | number | null) => void;
	autosave?: () => Promise<void>;
	onValueUpdate?: () => void;
	prepoulateWith?: Record<string, unknown>;
	formName: string;
	latitudePropName: string;
	longitudePropName: string;
	locationPropName: string;
	streetPropName: string;
	streetNumberPropName: string;
	routePropName: string;
	localityPropName: string;
	aa1PropName: string;
	aa2PropName: string;
	aa3PropName: string;
	countryPropName: string;
	postalCodePropName: string;
	distancePropName?: string;
	durationPropName?: string;
	locationLabel?: string;
	renderDirections?: boolean;
	origin?: google.maps.LatLngLiteral;
	destination?: google.maps.LatLngLiteral;
	requestLocationCheckboxComponent?: React.Component;
}

interface StateProps {
	travelDistance?: string;
	travelDuration?: string;
}

interface DispatchProps {
	getAddressByLatLng: typeof AddressesActions.getAddressByLatLng;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	marker: Nullable<MarkerProps>;
	center?: google.maps.LatLngLiteral | google.maps.LatLng;
	zoom: number;
	directions: Nullable<google.maps.DirectionsResult>;
	destination: Nullable<google.maps.LatLngLiteral>;
}

const latLngCoordinates = (position: google.maps.LatLngLiteral | google.maps.LatLng): google.maps.LatLngLiteral => {
	if (position instanceof google.maps.LatLng) {
		return { lat: position.lat(), lng: position.lng() };
	}
	return { lat: position.lat, lng: position.lng };
};

const defaults: Pick<State, 'center' | 'zoom'> = {
	center: { lat: 38.7099085, lng: -95.137812 },
	zoom: 3,
};

class Map extends React.Component<Props, State> {
	static readonly autocompleteOptions = { componentRestrictions: { country: ['us', 'ca'] }, strictBounds: true };

	state: State = {
		marker: null,
		center: defaults.center,
		zoom: defaults.zoom,
		directions: null,
		destination: null,
	};

	private _searchBox: Nullable<google.maps.places.Autocomplete> = null;
	private _latitudeBox: Nullable<HTMLInputElement> = null;
	private _longitudeBox: Nullable<HTMLInputElement> = null;

	async UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { origin } = this.props;
		const { origin: newOrigin } = nextProps;

		if (newOrigin && origin && (origin.lat !== newOrigin.lat || origin.lng !== newOrigin.lng)) {
			await this.calculateDirections(nextProps.origin);
		}
	}

	async componentDidMount() {
		const { marker, origin } = this.props;
		if (marker) {
			await this.setState({
				marker,
				center: marker.position,
				zoom: DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER,
			});
		}
		await this.calculateDirections(origin);
	}

	calculateDirections = async (origin) => {
		const { renderDirections, destination } = this.props;
		const { marker } = this.state;
		const position = marker?.position;

		if (!renderDirections || !origin || (!destination && !position)) {
			this.setState({ directions: null });
			return;
		}
		const goal = position ? latLngCoordinates(position) : destination;
		const directionsService = new google.maps.DirectionsService();

		if (!goal) {
			throw new Error('Destination cannot be calculated');
		}

		directionsService.route({
			origin: new google.maps.LatLng(origin.lat, origin.lng),
			destination: new google.maps.LatLng(goal.lat, goal.lng),
			travelMode: google.maps.TravelMode.DRIVING,
		}, (result, status) => {
			if (status === google.maps.DirectionsStatus.OK && !!result) {
				this.updateDirections(result);
			} else {
				alert('Error in calculating route! Please input new location.');
			}
		});
	};

	updateDirections = async (directions: google.maps.DirectionsResult) => {
		if (!directions) {
			return;
		}
		const { onValueUpdate, autosave, change, distancePropName, durationPropName } = this.props;
		const legs = directions.routes[0].legs;
		const distance = legs.map((_leg) => _leg.distance?.value).reduce<number>((_sum, _value) => (_sum + (_value ?? 0)), 0);
		const duration = Math.ceil(legs.map((_leg) => _leg.duration?.value).reduce<number>((_sum, _value) => (_sum + (_value ?? 0)), 0) / 60);

		if (distancePropName) {
			change(distancePropName, distance);
		}

		if (durationPropName) {
			change(durationPropName, duration);
		}

		this.setState({ directions }, async () => {
			if (onValueUpdate) {
				onValueUpdate();
			}
			if (autosave) {
				await autosave();
			}
		});
	};

	updateFields = (lat, lng, address) => {
		const { onValueUpdate, autosave, change, ...rest } = this.props;
		change(rest.latitudePropName, lat);
		change(rest.longitudePropName, lng);
		change(rest.locationPropName, address.formattedAddress);
		change(rest.streetNumberPropName, address.streetNumber);
		change(rest.routePropName, address.route);
		change(rest.localityPropName, address.locality);
		change(rest.aa1PropName, address.aa1);
		change(rest.aa2PropName, address.aa2);
		change(rest.aa3PropName, address.aa3);
		change(rest.countryPropName, address.country);
		change(rest.postalCodePropName, address.postalCode);
		if (onValueUpdate) {
			onValueUpdate();
		}
		if (autosave) {
			autosave();
		}
	};

	onMapClick = async ({ latLng }: google.maps.MapMouseEvent) => {
		const { origin, getAddressByLatLng } = this.props;

		if (!latLng) {
			throw new Error('Pin not defined');
		}

		const marker = {
			position: latLng,
			defaultAnimation: 2,
			key: Date.now(),
		};

		this.setState({
			marker,
		});

		const address = await getAddressByLatLng(latLng.lat(), latLng.lng());
		this.updateFields(latLng.lat(), latLng.lng(), address);
		await this.calculateDirections(origin);
	};

	onPlacesChanged = () => {
		const { origin } = this.props;
		const place = this._searchBox?.getPlace();
		if (!place?.geometry?.location) {
			return;
		}

		const marker = {
			position: place.geometry.location,
			defaultAnimation: 2,
			key: Date.now(),
		};

		this.setState({
			marker,
			center: marker.position,
			zoom: DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER,
		});

		const address = AddressesActions.formatAddress(place);
		this.updateFields(marker.position.lat(), marker.position.lng(), address);
		this.calculateDirections(origin);
	};

	onLatitudeChange = async (event) => {
		const { origin, getAddressByLatLng } = this.props;
		const lat = parseFloat(event.target.value) || 0;
		const lng = +(this._longitudeBox?.value ?? 0);
		const latLng = new google.maps.LatLng(lat, lng);
		const marker = {
			position: latLng,
			defaultAnimation: 2,
			key: Date.now(),
		};
		this.setState({
			marker,
			center: latLng,
			zoom: DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER,
		});

		const address = await getAddressByLatLng(latLng.lat(), latLng.lng());
		this.updateFields(latLng.lat(), latLng.lng(), address);
		await this.calculateDirections(origin);
	};

	onLongitudeChange = async (event) => {
		const { origin, getAddressByLatLng } = this.props;
		const lng = parseFloat(event.target.value) || 0;
		const lat = +(this._latitudeBox?.value ?? 0);
		const latLng = new google.maps.LatLng(lat, lng);
		const marker = {
			position: latLng,
			defaultAnimation: 2,
			key: Date.now(),
		};

		this.setState({
			marker,
			center: latLng,
			zoom: DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER,
		});

		const address = await getAddressByLatLng(latLng.lat(), latLng.lng());
		this.updateFields(latLng.lat(), latLng.lng(), address);
		await this.calculateDirections(origin);
	};

	onLocationChange = () => {
		const { change, ...rest } = this.props;
		change(rest.latitudePropName, null);
		change(rest.longitudePropName, null);
		change(rest.streetNumberPropName, null);
		change(rest.routePropName, null);
		change(rest.localityPropName, null);
		change(rest.aa1PropName, null);
		change(rest.aa2PropName, null);
		change(rest.aa3PropName, null);
		change(rest.countryPropName, null);
		change(rest.postalCodePropName, null);
	};

	handleStreetFieldMount = (field: google.maps.places.Autocomplete) => {
		if (!field) {
			return;
		}

		this._searchBox = field;
	};

	handleLatitudeFieldMount = (field) => {
		if (!field) {
			return;
		}
		this._latitudeBox = (ReactDOM.findDOMNode(field) as Element).getElementsByTagName('input')[0];
		this._latitudeBox.addEventListener('change', this.onLatitudeChange);
	};

	handleLongitudeFieldMount = (field) => {
		if (!field) {
			return;
		}
		this._longitudeBox = (ReactDOM.findDOMNode(field) as Element).getElementsByTagName('input')[0];
		this._longitudeBox.addEventListener('change', this.onLongitudeChange);
	};

	formatTravelTime = (time) => {
		if (!time) {
			return '-';
		}
		const hours = Math.floor(time / 60);
		const minutes = time % 60;
		let message = '';

		if (hours === 1) {
			message += `${hours} hour`;
		} else if (hours > 1) {
			message += `${hours} hours`;
		}

		if (minutes === 1) {
			message += `, ${minutes} minute`;
		} else if (minutes > 1) {
			message += `, ${minutes} minutes`;
		}

		if (!hours) {
			message = message.substring(2);
		}

		return message;
	};

	render() {
		const { latitudePropName, longitudePropName, locationPropName, distancePropName,
			durationPropName, travelDistance, travelDuration, locationLabel = 'Location', requestLocationCheckboxComponent } = this.props;
		const { directions, marker, zoom, center } = this.state;

		return (
			<>
				<Row id={locationPropName && durationPropName && 'location-end'}>
					{
						locationPropName && durationPropName &&
						<Col md={1}>
							<img src="/images/elements/ic_destination_black.svg" />
						</Col>
					}
					<Col md={(locationPropName && durationPropName) ? 15 : 16} sm={16}>
						<GoogleScriptLoader>
							<Autocomplete
								onLoad={this.handleStreetFieldMount}
								onPlaceChanged={this.onPlacesChanged}
								options={Map.autocompleteOptions}
							>
								<Field
									component={Input}
									id={locationPropName}
									label={locationLabel}
									name={locationPropName}
									onValueChange={this.onLocationChange}
									type="text"
								/>
							</Autocomplete>
						</GoogleScriptLoader>
					</Col>
					<Col sm={4}>
						<Field
							component={Input}
							id={latitudePropName}
							label="Latitude"
							name={latitudePropName}
							ref={this.handleLatitudeFieldMount}
							step="any"
							type="number"
						/>
					</Col>
					<Col sm={4}>
						<Field
							component={Input}
							id={longitudePropName}
							label="Longitude"
							name={longitudePropName}
							ref={this.handleLongitudeFieldMount}
							step="any"
							type="number"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={24}>
						<GoogleScriptLoader>
							<GoogleMap
								center={center ?? defaults.center}
								onClick={this.onMapClick}
								options={{ scrollwheel: false }}
								zoom={zoom ?? defaults.zoom}
							>
								{directions
									? <DirectionsRenderer directions={directions} />
									: (!!marker
										? <Marker {...marker} />
										: <></>
									)
								}
							</GoogleMap>
						</GoogleScriptLoader>
					</Col>
				</Row>
				<Row>
					<Col md={17} sm={14}>
						<>{requestLocationCheckboxComponent}</>
					</Col>
					{
						distancePropName &&
						<Col md={3} sm={5}>
							<label className="map-distance">
								<img className="m-r-s" src="/images/elements/ic_drive_eta_black.svg" />
								Distance
							</label>
							<div><strong>{travelDistance ?? '-'} miles</strong></div>
							<Field
								component={Input}
								disabled={true}
								id={distancePropName}
								name={distancePropName}
								type="hidden"
							/>
						</Col>
					}
					{
						durationPropName &&
						<Col md={4} sm={5}>
							<label className="map-travel-time">
								<img className="m-r-s" src="/images/elements/ic_access_time_black.svg" />
								Travel Time
							</label>
							<div><strong>{this.formatTravelTime(travelDuration)}</strong></div>
							<Field
								component={Input}
								disabled={true}
								id={durationPropName}
								name={durationPropName}
								type="hidden"
							/>
						</Col>
					}
				</Row>
			</>
		);
	}
}

const toMiles = (meters: number): number => meters * 0.000621371;

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { formName, distancePropName, durationPropName } = ownProps;

	if (!distancePropName || !durationPropName) {
		return {};
	}

	const selector = formValueSelector(formName);
	const { travelDistance, travelDuration } = selector(state, distancePropName, durationPropName);
	return {
		travelDistance: travelDistance && toMiles(travelDistance).toFixed(2),
		travelDuration,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		getAddressByLatLng: AddressesActions.getAddressByLatLng,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(Map);
