import { Dispatch, AnyAction } from 'redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import * as httpActions from 'af-reducers/http.reducer';

import DocumentRowVM from 'ab-viewModels/document/table.viewModel';

import { AttachmentsForm } from 'ab-requestModels/attachment.requestModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { GetRootState } from 'af-reducers';

export function getDocuments(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<DocumentRowVM>>(API.V1.RESOURCES.DOCUMENT.LIST(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteDocument(documentId: number, storageName: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.DOCUMENT.DELETE(documentId, encodeURIComponent(storageName)));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteAllDocuments(documentIds: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.DOCUMENT.DOCUMENTS(documentIds));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function uploadDocuments(form: AttachmentsForm) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const options = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: (progressEvent) => {
					const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
					if (totalLength !== null) {
						dispatch(httpActions.UPLOAD_PROGRESS(Math.round((progressEvent.loaded * 100) / totalLength)));
						dispatch(httpActions.UPLOAD_PROGRESS_SIZE(progressEvent.loaded));
					}
				},
			};
			const fd = new FormData();
			form.requestFiles?.forEach((_file) => fd.append('requestFiles[]', _file));
			await http.post(API.V1.RESOURCES.DOCUMENT.UPLOAD(), fd, options);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
