import * as React from 'react';
import { compose } from 'redux';
import { connect, ResolveThunks } from 'react-redux';
import { CustomRouteComponentProps, withRouter } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { submit, isSubmitting } from 'redux-form';
import { Button } from 'react-bootstrap';

import { toMomentUtc, normalizeDateToMoment } from 'acceligent-shared/utils/time';

import { RootState } from 'af-reducers';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import SocketEvent from 'ab-enums/socketEvent.enum';

import * as SettingsKeys from 'af-constants/settingsKeys';
import { UPDATE_DAILY_VIEW_METRICS } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import SubmitButton from 'af-components/SubmitButton';
import ConfirmationModal from 'af-components/ConfirmationModal';
import { RouteParams } from 'af-components/Header';

import DailyViewDatePicker from 'af-root/scenes/Company/ScheduleBoard/Shared/Header/ScheduleBoardViewDatePicker/DailyViewDatePicker';

import * as User from 'ab-viewModels/user.viewModel';

import { withSettings } from 'af-utils/settings.util';
import socket from 'af-utils/socket.util';

type MomentType = ReturnType<typeof normalizeDateToMoment>;

interface LocationStateParams {
	orgAlias: string;
	originUrl: string;
	fromWeeklyView?: string;
}

interface SettingsProps {
	selectedDate: MomentType;
}

type ConnectOwnProps = SettingsProps & CustomRouteComponentProps<RouteParams, StaticContext, LocationStateParams>;

interface DispatchProps {
	clearWeeklyView: typeof ScheduleBoardActions.clearWeeklyView;
	submitCalculationsForm: typeof submit;
}

interface StateProps {
	userData: User.UserData;
	companyData: User.CompanyData;
	submitting: boolean;
}

type Props = ConnectOwnProps & ResolveThunks<DispatchProps> & StateProps;

interface State {
	showBackConfirmationModal: boolean;
	showChangeDateConfirmationModal: boolean;
	onConfirmChangeDateAction: (() => void) | undefined;
}

class ScheduleBoardNavigationDailyMetricsHeader extends React.PureComponent<Props, State> {
	static BACK_CONFIRMATION_MODAL_BODY = (
		<>
			Are you sure you want to leave Production Data without saving?
			<br />
			All changes will be lost.
		</>
	);

	static CHANGE_DATE_CONFIRMATION_MODAL_BODY = (
		<>
			Are you sure you want to change Production Data date?
			<br />
			All unsaved changes will be lost.
		</>
	);

	state: State = {
		showBackConfirmationModal: false,
		showChangeDateConfirmationModal: false,
		onConfirmChangeDateAction: undefined,
	};

	openBackConfirmationModal = () => this.setState(() => ({ showBackConfirmationModal: true }));
	closeBackConfirmationModal = () => this.setState(() => ({ showBackConfirmationModal: false }));

	closeChangeDateConfirmationModal = () => this.setState(() => ({ showChangeDateConfirmationModal: false, onConfirmChangeDateAction: undefined }));

	goBack = () => {
		const { clearWeeklyView, companyData: { name: companyName }, history, location: { state: { fromWeeklyView, orgAlias, originUrl } } } = this.props;
		if (!companyName) {
			throw new Error('Company name required');
		}

		const pathname = originUrl || CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName);
		if (fromWeeklyView) {
			clearWeeklyView();
		}
		history.push({ pathname });
	};

	refreshConnectionCount = (dates: string[]) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.REFRESH_CONNECTION_COUNT, dates);
	};

	onSubmit = () => this.props.submitCalculationsForm(UPDATE_DAILY_VIEW_METRICS);

	onDateChange = (confirmAction: () => void) => {
		this.setState(() => ({ showChangeDateConfirmationModal: true, onConfirmChangeDateAction: confirmAction }));
	};

	render() {
		const { submitting } = this.props;
		const { showBackConfirmationModal, showChangeDateConfirmationModal, onConfirmChangeDateAction } = this.state;

		return (
			<div className="schedule-board-navigation">
				<div className="schedule-board-navigation-left" />
				<div className="schedule-board-navigation-center">
					<span className="schedule-board-calculations-date">
						<DailyViewDatePicker
							beforeDateChange={this.onDateChange}
							refreshConnectionCount={this.refreshConnectionCount}
						/>
					</span>
				</div>
				<div className="schedule-board-navigation-right">
					<Button
						disabled={submitting}
						onClick={this.openBackConfirmationModal}
						variant="info"
					>
						Back
					</Button>
					<SubmitButton
						label="Save Data"
						onClick={this.onSubmit}
						reduxFormSubmitting={submitting}
					/>
				</div>
				<ConfirmationModal
					body={ScheduleBoardNavigationDailyMetricsHeader.BACK_CONFIRMATION_MODAL_BODY}
					closeModal={this.closeBackConfirmationModal}
					confirmAction={this.goBack}
					confirmText="Yes"
					modalStyle="danger"
					showModal={showBackConfirmationModal}
					title="Leaving Production Data"
				/>
				<ConfirmationModal
					body={ScheduleBoardNavigationDailyMetricsHeader.CHANGE_DATE_CONFIRMATION_MODAL_BODY}
					closeModal={this.closeChangeDateConfirmationModal}
					confirmAction={onConfirmChangeDateAction}
					confirmText="Yes"
					modalStyle="danger"
					showModal={showChangeDateConfirmationModal}
					title="Changing Production Data date"
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { userData, companyData, organizationData } = state.user;
	if (!userData || !companyData || !organizationData) {
		throw new Error('User not logged in');
	}

	return {
		userData,
		companyData,
		submitting: isSubmitting(UPDATE_DAILY_VIEW_METRICS)(state),
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		clearWeeklyView: ScheduleBoardActions.clearWeeklyView,
		submitCalculationsForm: submit,
	};
}

const enhance = compose(
	withSettings<SettingsProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDER_SELECTED_DUE_DATE(),
			mappedName: 'selectedDate',
			normalize: normalizeDateToMoment,
			defaultValue: toMomentUtc(new Date()),
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
	])),
	withRouter,
	connect<StateProps, DispatchProps, ConnectOwnProps>(mapStateToProps, mapDispatchToProps())
);

export default enhance(ScheduleBoardNavigationDailyMetricsHeader);
