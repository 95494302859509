import * as React from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import FieldWorkClassificationCodeUpsertRM from 'acceligent-shared/dtos/web/request/fieldWorkClassificationCode/upsert';

import SubmitButton from 'af-components/SubmitButton';
import BackButton from 'af-components/BackButton';

import Input from 'af-fields/Input';

interface OwnProps extends InjectedFormProps<FieldWorkClassificationCodeUpsertRM> {
	onSubmit: (form: FieldWorkClassificationCodeUpsertRM) => void;
}

type Props = OwnProps;

class FieldWorkClassificationCodeForm extends React.PureComponent<Props> {

	render() {
		const {
			handleSubmit,
			submitting,
			invalid,
			onSubmit,
		} = this.props;

		return (
			<Form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-box">
					<Row className="row--padded-top">
						<Col lg={6} md={8}>
							<Field
								component={Input}
								cursorControl={true}
								label="Code *"
								name="code"
								placeholder="Enter classification code"
								type="text"
							/>
						</Col>
						<Col lg={12} md={16}>
							<Field
								component={Input}
								cursorControl={true}
								label="Description"
								name="description"
								placeholder="Enter classification description"
								type="text"
							/>
						</Col>
					</Row>
					<Row className="row--submit">
						<BackButton />
						<SubmitButton
							disabled={invalid}
							reduxFormSubmitting={submitting}
							variant="primary"
						/>
					</Row>
				</div>
			</Form>
		);
	}
}

export default FieldWorkClassificationCodeForm;
