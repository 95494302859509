import * as React from 'react';
import { Button } from 'react-bootstrap';

interface OwnProps {
	displayLevel: number; // 0, 1 or 2 - defines the amount of content shown on the button itself
	iconClass: string;
	label: string;
	isDouble?: boolean;
	isActive?: boolean;
	isDisabled?: boolean;
	onClick: () => void;
}

type Props = OwnProps;

class SmallButton extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		isActive: false,
		isDisabled: false,
		isDouble: false,
	};

	render() {
		const { onClick, isActive, isDisabled, displayLevel, label, iconClass, isDouble } = this.props;

		return (
			<Button
				className={`btn--small ${isDouble ? 'double' : ''} ${isActive ? 'btn-info--blue-fill' : ''}`}
				disabled={isDisabled}
				onClick={!isDisabled ? onClick : undefined}
				variant="info"
			>
				{
					(!!(isDouble && (displayLevel === 1 || displayLevel === 2)) || (!isDouble && (displayLevel === 0 || displayLevel === 2))) &&
					<span className={`${iconClass} ${(isDouble || displayLevel === 2) ? 'm-r-xs' : ''}`} />
				}
				{
					(
						!!(isDouble && (displayLevel === 0 || displayLevel === 1 || displayLevel === 2))
						|| (!isDouble && (displayLevel === 1 || displayLevel === 2))
					) &&
					<span>{label}</span>
				}
			</Button>
		);
	}
}

export default SmallButton;
