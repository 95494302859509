import { isValidTextInput } from 'acceligent-shared/utils/text';

import { WageRateRequestModel } from 'ab-requestModels/wageRate.requestModel';

import { DEFAULT_WAGE_RATE_TYPE, DEFAULT_WAGE_RATE_CLASSIFICATION, MAX_WAGE_RATE_HOURLY_RATE } from 'ab-constants/value';

import * as TextUtil from 'ab-utils/text.util';

export function validate(values: WageRateRequestModel) {
	const errors: ValidationErrors = {};

	if (!values.hourlyRate) {
		errors.hourlyRate = 'Hourly Rate required';
	} else if (values.hourlyRate > MAX_WAGE_RATE_HOURLY_RATE) {
		errors.hourlyRate = 'Hourly Rate value is too high';
	}

	if (!isValidTextInput(values.type)) {
		errors.type = 'Type required';
	} else if (TextUtil.isLowerCaseEqual(values.type, DEFAULT_WAGE_RATE_TYPE)) {
		errors.type = 'Type invalid';
	}

	if (!isValidTextInput(values.wageClassification)) {
		errors.wageClassification = 'Wage Classification required';
	} else if (TextUtil.isLowerCaseEqual(values.wageClassification, DEFAULT_WAGE_RATE_CLASSIFICATION)) {
		errors.wageClassification = 'Wage Classification invalid';
	}

	return errors;
}
