import { IndexData, TextData, TreeElement } from 'af-components/UserGuideModal/types';

export const createLinkableId = (heading: string, pageSectionType: string): string => {
	return heading.replace(/\s+/g, '').toLowerCase() + '-' + pageSectionType;
};

export const createHeadingLink = (guide: string, heading: string, pageSectionType: string): string => {
	return '/' + guide.toLowerCase() + '#' + createLinkableId(heading, pageSectionType);
};

export const findFirstInExistingHeading = (
	result: TextData[],
	showHeadings: number,
	mainPage: string | undefined,
	page: string | undefined,
	subPage: string | undefined
) => {
	return result.find((_value) => {
		return _value.mainPage === mainPage && _value.page === page && _value.subPage === subPage && (!_value.depth || _value.depth >= showHeadings);
	});
};

/**
 * Flattens the tree to get only headings, text, inline code or emphasis from the agreed upon format. Those elements are needed because they are
 * either headings, which are needed and have only a single child or terminating elements, meaning they do not have any more children and
 * have a direct string value attribute.
 *
 * @param result populates the array with the flattened tree
 * @param element element that is being flattened
 * @returns void
 */
export const flattenTree = (element: TreeElement): TreeElement[] => {
	const result: TreeElement[] = [];
	flattenTreeRecursive(result, element);
	return result;
};

const flattenTreeRecursive = (result: TreeElement[], element: TreeElement): void => {
	if (!element.children) {
		result.push(element);
		return;
	}
	for (const branch of element.children) {
		if (!branch.children) {
			result.push(branch);
			continue;
		}
		if (branch.children.length > 1) {
			flattenTreeRecursive(result, branch);
			continue;
		}

		// Emphasis and link are expected to have one child, and we want the childs value but the emphasis or link elements position data.
		if (branch.type === 'emphasis' || branch.type === 'link' || branch.type === 'strong') {
			const child = branch.children[0];
			branch.value = child.value;
			branch.children = undefined;
			result.push(branch);
			continue;
		}

		// We do not want paragraphs, list or listItem as they have nested text or some other terminating element that we want instead.
		if (branch.children.length === 1 &&
			(branch.type === 'paragraph' ||
				branch.type === 'list' ||
				branch.type === 'listItem'
			)) {
			flattenTreeRecursive(result, branch);
			continue;
		}
		result.push(branch);
	}
};

/**
 * Merges text that belongs to the same line into the same text line.
 *
 * @param tree tree elements with text in same line as different elements
 * @returns merged tree
 */
export const mergeLinesForSearch = (tree: TreeElement[]): TreeElement[] => {
	const result: TreeElement[] = [];
	let currentLine = 1;
	let currentElement: TreeElement | undefined = undefined;

	for (const element of tree) {
		if (!element.position) {
			throw new Error('Element has no position');
		}

		if (element.type === 'heading') {
			if (currentElement) {
				result.push(currentElement);
			}
			result.push(element);
			currentLine = element.position.start.line + 1;
			currentElement = undefined;
			continue;
		}

		if (element.position.start.line > currentLine && currentElement) {
			result.push(currentElement);
			currentLine = element.position.start.line;
			currentElement = element;
			const elementCopy = JSON.parse(JSON.stringify(currentElement));
			currentElement.lineElements = [elementCopy];
			continue;
		}

		if (!currentElement) {
			currentElement = element;
			const elementCopy = JSON.parse(JSON.stringify(currentElement));
			currentElement.lineElements = [elementCopy];
			currentLine = element.position.start.line;
		} else {
			currentElement.value += element.value;
			if (!currentElement.position) {
				throw new Error('Element has no position.');
			}
			currentElement.position.end.offset = element.position.end.offset;
			currentElement.lineElements?.push(element);
		}

	}

	if (currentElement) {
		result.push(currentElement);
	}

	return result;
};

/**
 * Returns an array of ranges that need to be highlighted in an element that will be rendered.
 * Each range is not always an occurance of the entire string but can be a range that is part of
 * the searched string that needs to be highlighted. The reason for that is the possibility that
 * one element has the first part of the word that needs to be highlighted and the next element
 * has the rest of the word.
 *
 * @param searchStr string to search by
 * @param str string that is being searched
 * @param element element that contains data of a line that is being searched
 * @returns array of ranges
 */
const getIndicesOf = (searchStr: string, str: string, element: TreeElement, showHeadings: number): IndexData[] => {

	if (!element.position) {
		throw new Error('Element has no position.');
	}
	const searchStrLen = searchStr.length;

	if (element.type === 'heading' && element.depth && element.depth < showHeadings) {
		return [];
	}

	if (searchStrLen === 0) {
		return [];
	}

	let additionalOffset: number = 0;
	if (element.type === 'heading') {
		const child: TreeElement | undefined = element.children === undefined ? undefined : element.children[0];
		if (!child) {
			throw new Error('Heading has no child');
		}
		if (!child.position) {
			throw new Error('Heading child has no position');
		}
		additionalOffset = child.position.start.column - 1;
	}

	let startIndex = 0;
	let index: number = 0;
	const indices: IndexData[] = [];

	const _str = str.toLowerCase();
	const _searchStr = searchStr.toLowerCase();

	while ((index = _str.indexOf(_searchStr, startIndex)) > -1) {
		indices.push({ start: index + additionalOffset, end: index + searchStr.length + additionalOffset });
		startIndex = index + searchStrLen;
	}

	return recalculateIndices(indices, element);
};

/**
 * Takes the original search indices and recalculates them to reflect their actual positions in the markdown.
 *
 * @param indices original search indices
 * @param element full line element
 * @returns recalculated indices
 */
const recalculateIndices = (indices: IndexData[], element: TreeElement): IndexData[] => {

	if (!element.position) {
		throw new Error('Element has no position.');
	}

	if (!element.lineElements) {
		return indices;
	}

	const recalculatedIndices: IndexData[] = [];
	for (const indexData of indices) {
		const offsetFromStart = element.position.start.column; // Elements dont always start from the same column
		for (const lineElement of element.lineElements) {
			if (!lineElement.position) {
				throw new Error('Line element has no position.');
			}
			if (!element.position) {
				throw new Error('Element has no position.');
			}

			const elementStart = lineElement.position.start.column - offsetFromStart;
			const elementEnd = lineElement.position.end.column - offsetFromStart;

			/**
			 * STEP 1: Padd starting special characters
			 */
			// Add to start to take into account special characters from the markdown
			if (lineElement.type === 'emphasis' || lineElement.type === 'inlineCode' || lineElement.type === 'link') {
				indexData.start += 1;
				indexData.end += 1;
			}
			if (lineElement.type === 'strong') {
				indexData.start += 2;
				indexData.end += 2;
			}

			/**
			 * STEP 2: Padd ending special characters
			 */
			// Continue if start is not inside the range of the element
			if (indexData.start >= elementEnd) {
				if (lineElement.type === 'emphasis' || lineElement.type === 'inlineCode') {
					indexData.start += 1;
					indexData.end += 1;
				}
				if (lineElement.type === 'strong') {
					indexData.start += 2;
					indexData.end += 2;
				}
				continue;
			}
			// Continue if start is not inside the range of the link element
			if (lineElement.type === 'link' && indexData.start >= elementStart + lineElement.value.length + 1) {
				indexData.end = elementEnd + indexData.end - (elementStart + lineElement.value.length + 1);
				indexData.start = elementEnd + indexData.start - (elementStart + lineElement.value.length + 1);
				continue;
			}

			/**
			 * STEP 3: Found ending index inside the line element
			 */
			// Stop if end is found for non-link
			if (indexData.end >= elementStart && indexData.end <= elementEnd && lineElement.type !== 'link') {
				recalculatedIndices.push(indexData);
				break;
			}
			// Stop if end is found for link
			if (indexData.end >= elementStart && indexData.end <= elementStart + lineElement.value.length && lineElement.type === 'link') {
				recalculatedIndices.push(indexData);
				break;
			}

			/**
			 * STEP 4: Continue searching
			 */
			// Search of these special elements should end after the last letter, not at offset end as that adds an extra index
			let textSearchEnd: number = elementEnd;
			if (lineElement.type === 'emphasis' || lineElement.type === 'inlineCode') {
				textSearchEnd--;
				indexData.end++; // Pad by one because we want to move the index after the special character
			}
			if (lineElement.type === 'strong') {
				textSearchEnd--;
				textSearchEnd--;
				indexData.end++; // Pad by one because we want to move the index after the special character
				indexData.end++; // Pad by one because we want to move the index after the special character
			}
			if (lineElement.type === 'link') {
				textSearchEnd = indexData.start + lineElement.value.length;
				indexData.end = elementEnd + (indexData.end - indexData.start - lineElement.value.length);
			}

			recalculatedIndices.push({ start: indexData.start, end: textSearchEnd });
			indexData.start = elementEnd;
		}
	}

	return recalculatedIndices;
};

export const searchFlatTreeForText = (flatTree: TreeElement[], text: string, mainPage: string, showHeadings: number): TextData[] => {
	const result: TextData[] = [];
	text = text.toLowerCase();
	let currentPage: string | undefined = undefined;
	let currentSubPage: string | undefined = undefined;
	let currentSubTitle: string | undefined = undefined;

	for (const element of flatTree) {
		let content: string = '';
		let depth: number | undefined = undefined;

		if (!element.position) {
			throw new Error('Element has no position.');
		}

		if (element.type === 'heading' && element.depth === 1 && element.children) {
			currentPage = element.children[0].value;
			content = element.children[0].value;
			depth = element.depth;
			currentSubPage = undefined;
			currentSubTitle = undefined;
		}

		if (element.type === 'heading' && element.depth === 2 && element.children) {
			currentSubPage = element.children[0].value;
			content = element.children[0].value;
			depth = element.depth;
			currentSubTitle = undefined;
		}

		if (element.type === 'heading' && element.depth === 3 && element.children) {
			currentSubTitle = element.children[0].value;
			content = element.children[0].value;
			depth = element.depth;
		}

		if (element.value) {
			content = element.value;
		}

		if (content?.toLowerCase().includes(text)) {
			const originalOffset = element.position.start.offset;
			const columnOffset = element.position.start.column === 1 ? 0 : element.position.start.column;
			const startOffset = originalOffset - columnOffset - 1;

			result.push(
				{
					mainPage,
					page: currentPage,
					subPage: currentSubPage,
					subTitle: currentSubTitle,
					depth,
					text: content,
					linePosition: element.position.start.line,
					startOffset: startOffset < 0 ? 0 : startOffset, // Offset used for slicing raw files when searching and recalculating the indexes in the search
					occuranceIndices: getIndicesOf(text, content, element, showHeadings),
				});
		}

	}

	return result;
};

export const shouldShowLine = (element: TreeElement, showLines: number, line?: number, lineStartOffset?: number) => {
	if (!element.position) {
		throw new Error('Element position undefined.');
	}
	if (lineStartOffset === undefined || line === undefined) {
		throw new Error('Line data is undefined');
	}
	return element.position.end.line >= line && element.position.end.line <= line + showLines;
};

export const shouldStopAtHeading = (element: TreeElement, showHeadings: number, line?: number, lineStartOffset?: number) => {
	if (element.type !== 'heading') {
		return false;
	}
	if (!element.position) {
		throw new Error('Element position undefined.');
	}
	if (lineStartOffset === undefined || line === undefined) {
		throw new Error('Line data is undefined');
	}
	if (element.depth === undefined) {
		throw new Error('Heading depth is undefined');
	}
	return element.position.end.line > line && element.type === 'heading' && element.depth < showHeadings;
};

export const rangeContainsIndex = (nodeStart: number, nodeEnd: number, indexRange: IndexData) => {
	return (
		(nodeStart <= indexRange.start && nodeEnd >= indexRange.start) &&
		(nodeStart <= indexRange.end && nodeEnd >= indexRange.end));
};
