import * as React from 'react';
import { compose } from 'redux';
import { connect, ResolveThunks, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { reduxForm, InjectedFormProps, getFormSyncErrors, FormErrors, formValueSelector } from 'redux-form';

import ContactRM, { ContactValidationErrors } from 'acceligent-shared/dtos/web/request/contact/upsert';
import ContactVM from 'acceligent-shared/dtos/web/view/contact/contact';

import { RootState } from 'af-reducers';

import * as ContactActions from 'af-actions/contacts';

import CLIENT from 'af-constants/routes/client';
import { CONTACT_EDIT } from 'af-constants/reduxForms';

import Breadcrumbs from 'af-components/Breadcrumbs';
import ContactForm from 'af-components/SharedForms/Contacts/ContactForm';
import { warn } from 'af-components/SharedForms/Contacts/ContactForm/validation';

import Loading from './Loading';

interface PathParams {
	contactId: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

interface StateProps {
	companyName: string;
	emailPhoneError?: string;
}

interface DispatchProps {
	findById: typeof ContactActions.findById;
	editContact: typeof ContactActions.editContact;
}

type FormProps = InjectedFormProps<ContactRM, FormOwnProps>;

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

type Props = FormOwnProps & FormProps & ConnectedProps<typeof connector>;

interface State {
	isLoading: boolean;
}

class EditContact extends React.Component<Props, State> {

	state: State = {
		isLoading: false,
	};

	async componentDidMount() {
		this.loadContact();
	}

	loadContact = () => {
		this.setState(() => ({ isLoading: true }), async () => {

			const { findById, match: { params: { contactId } }, initialize } = this.props;
			const contact = await findById(+contactId);
			if (!contact) {
				throw new Error('Contact not loaded');
			}

			initialize(ContactVM.toRequestModel(contact));

			this.setState(() => ({ isLoading: false }));
		});
	};

	editContact = async (form: ContactRM) => {
		const {
			editContact,
			location: { state: { orgAlias } },
			companyName,
		} = this.props;

		await editContact(form, orgAlias, companyName);
	};

	breadcrumbs = () => {
		const {
			location: { state: { orgAlias } },
			companyName,
		} = this.props;
		return [
			{ label: 'Contacts', url: CLIENT.COMPANY.CONTACTS.LIST(orgAlias, companyName) },
			{ label: 'Edit' },
		];
	};

	render() {
		const { fullName } = this.props;
		const { isLoading } = this.state;

		if (isLoading) {
			return <Loading />;
		}

		return (
			<div className="form-segment">
				<Breadcrumbs items={this.breadcrumbs()} />
				<div className="form-box">
					<ContactForm
						{...this.props}
						fullName={fullName}
						onSubmit={this.editContact}
						removeOnDelete={false}
					/>
				</div>
			</div>
		);
	}
}

const getErrors = getFormSyncErrors(CONTACT_EDIT);

const formSelector = formValueSelector(CONTACT_EDIT);

function mapStateToProps(state: RootState) {
	const {
		emailsPhones: emailPhoneError,
	} = getErrors(state) as FormErrors<ContactValidationErrors, string>;

	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	const fullName = formSelector(state, 'fullName') as string;

	return {
		companyName: companyData.name,
		emailPhoneError: emailPhoneError as string,
		fullName,
		selector: (fieldName: string) => formSelector(state, fieldName),
	};
}

function mapDispatchToProps() {
	return {
		findById: ContactActions.findById,
		editContact: ContactActions.editContact,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<ContactRM, FormOwnProps>({
		form: CONTACT_EDIT,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true, // don't remove this pls
		validate: ContactRM.validateForm,
		warn,
	})
);

export default enhance(EditContact);
