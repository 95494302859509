import * as React from 'react';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	closeModal: () => void;
	contactName: string;
	onDelete: () => void;
	showModal: boolean;
}

type Props = OwnProps;

export default class FieldReportTypeRemovedInfoModal extends React.PureComponent<Props> {
	render() {
		const { closeModal, onDelete, showModal, contactName } = this.props;

		return (
			<CustomModal
				closeModal={closeModal}
				modalStyle="danger"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title={`Delete Contact (${contactName})`}
				/>
				<CustomModal.Body>
					This action cannot be undone.
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button onClick={closeModal} variant="info">Cancel</Button>
					<Button onClick={onDelete} variant="danger">Delete Contact</Button>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}
