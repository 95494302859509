import * as React from 'react';

import { ModalLink, ModalRoute, useModalBack, useModalForward, useModalLocation, useModalNavigate } from 'af-components/ModalNavigation/ModalRouter';

import SearchInput from './SearchInput';
import { RawPages, SearchPageResults, SearchablePageText } from './types';

interface Props {
	rawPages: RawPages;
	searchablePages: SearchablePageText;
	search: (text: string) => SearchPageResults | undefined;
}

const UserGuideModalBody: React.FC<Props> = (props) => {

	const { rawPages, searchablePages, search } = props;

	const modalLocation = useModalLocation();
	const navigate = useModalNavigate();
	const modalBack = useModalBack();
	const modalForward = useModalForward();
	const [searchResults, setSearchResults] = React.useState<SearchPageResults | undefined>(undefined);
	const [searchDropwdownResults, setSearchDropdownResults] = React.useState<SearchPageResults | undefined>(undefined);
	const [loadedSearchText, setLoadedSearchText] = React.useState('');

	// This enables loading searches as seperate pages when using back and forward
	React.useEffect(() => {
		const _searchText = modalLocation.split('?')[1];
		if (_searchText) {
			const results = search(_searchText);
			setLoadedSearchText(_searchText);
			setSearchDropdownResults(results);
			setSearchResults(results);
		}
	}, [modalLocation, search]);

	const back = React.useCallback(() => {
		modalBack();
	}, [modalBack]);

	const forward = React.useCallback(() => {
		modalForward();
	}, [modalForward]);

	const filterText = React.useCallback((text: string): void => {
		setSearchDropdownResults(search(text));
	}, [search]);

	const onSearch = React.useCallback((text: string): void => {
		setSearchResults(searchDropwdownResults);
		navigate('/search' + '?' + text);
	}, [navigate, searchDropwdownResults]);

	return (
		<div className="user-guide-modal-container">
			<div className="user-guide-modal-navigation">
				<div className="user-guide-modal-navigation__actions">
					<div>
						<span className="icon-left" onClick={back}></span>
						<span className="icon-right" onClick={forward}></span>
					</div>
					<ModalLink className="home-link" link={'/'}>Home</ModalLink>
					<SearchInput
						dropdownSearchedResults={searchDropwdownResults}
						initialText={loadedSearchText}
						onFilterTextChange={filterText}
						onSearch={onSearch}
						searchLabel={'User Guide'}
					/>
				</div>
			</div>
			<ModalRoute propsForPath={{ rawPages, searchResults, searchablePages }}></ModalRoute>
		</div>
	);
};

export default UserGuideModalBody;
