import * as React from 'react';
import { Field, FormErrorsWithArray, WrappedFieldArrayProps } from 'redux-form';
import { CellContext } from '@tanstack/react-table';
import { nanoid } from 'nanoid';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { UNIQUE_ID_SIZE } from 'ab-constants/value';

import * as ReduxUtils from 'ab-utils/reduxForms.util';

import Input from 'af-fields/Input';
import DateInput from 'af-fields/DateInput';
import SimpleTable, { SimpleTableRow } from 'af-fields/SimpleTable';

import { FooterButton } from 'af-components/Controls/SimpleTable/types';

import { dollarFormatter } from 'af-utils/format.util';

import { InvoiceFM } from '../formModel';

export type InstallmentFM = Required<InvoiceFM>['installments'][0];

export type CustomFormErrors<Model> = { [K in keyof Model]: Model[K] extends object ? CustomFormErrors<Model[K]> : string };
export type InstallmentErrors = CustomFormErrors<InstallmentFM[]>;

export interface OwnProps {
	setUnsavedChanges: (value: boolean) => void;
	invoiceId: number;
	errors: FormErrorsWithArray<InvoiceFM, string>;
	initialized: boolean;
	updateTotalPaid: (installments: InstallmentFM[]) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<InstallmentFM>;

const InstallmentItems: React.FC<Props> = (props) => {
	const { fields, errors, updateTotalPaid, invoiceId, setUnsavedChanges, initialized } = props;

	const addItem = React.useCallback(() => {
		fields.push({
			id: nanoid(UNIQUE_ID_SIZE),
			invoiceId: invoiceId,
			instNumber: fields.length,
			amount: null,
			datePaid: null,
			note: null,
		});
		setUnsavedChanges(true);
	}, [fields, invoiceId, setUnsavedChanges]);

	const footerButtons = React.useMemo(() => {
		const resolvedFooterButtons = [{
			iconName: 'icon-plus',
			label: 'Add Installment',
			onClick: addItem,
		}];
		return resolvedFooterButtons as FooterButton[];
	}, [addItem]);

	const columns = React.useMemo(() => [{
		id: 'instNumber',
		cell: (_cell: CellContext<InstallmentFM & SimpleTableRow, number>) => {
			return _cell.row.original.index + 1 ?? 'N/A';
		},
		header: 'No.',
		accessor: 'instNumber',
		enableSorting: true,
	},
	{
		id: 'amount',
		cell: (_cell: CellContext<InstallmentFM & SimpleTableRow, number>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						component={Input}
						isDollarValue={true}
						name={`${_cell.row.original.name}.amount`}
						normalize={ReduxUtils.normalizeDecimalNumber}
					/>
				);
			}
			return dollarFormatter.format(_cell.getValue() ?? 0);
		},
		header: 'Amount',
		accessor: 'amount',
		enableSorting: true,
	},
	{
		id: 'datePaid',
		cell: (_cell: CellContext<InstallmentFM & SimpleTableRow, DateInput>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						component={DateInput}
						dateFormat={TimeFormat.DB_DATE_ONLY}
						id="datePaid"
						name={`${_cell.row.original.name}.datePaid`}
						originalDateFormat={TimeFormat.DB_DATE_ONLY}
						placeholderText={TimeFormat.DB_DATE_ONLY}
						required={true}
					/>
				);
			}
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Date Paid',
		accessor: 'datePaid',
		enableSorting: true,
	},
	{
		id: 'note',
		cell: (_cell: CellContext<InstallmentFM & SimpleTableRow, string>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						component={Input}
						name={`${_cell.row.original.name}.note`}
					/>
				);
			}
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Note',
		accessor: 'note',
		enableSorting: true,
		size: 300,
	},
	], []);

	return (
		<SimpleTable
			allowEdit={true}
			columns={columns}
			emptyTableMessage='No installments added. To do that click "+ Add Installment".'
			errors={errors.installments}
			fields={fields}
			footerButtons={footerButtons}
			initialized={initialized}
			label="Installments"
			notifyAreThereItemsInEditMode={setUnsavedChanges}
			onFieldsUpdated={updateTotalPaid}
		/>
	);
};

export default React.memo(InstallmentItems);
