import { bemBlock } from 'ab-utils/bem.util';
import * as React from 'react';

import styles from './styles.module.scss';

interface Props {
	isHeader?: boolean;
	message?: string;
}

const EmptyCell: React.FC<Props> = ({ isHeader = false, message }) => {
	const className = bemBlock(styles['empty-cell'], { header: isHeader });

	return (
		<div className={className}>
			{message ?? ''}
		</div>
	);
};

export default React.memo(EmptyCell);
