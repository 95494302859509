import * as React from 'react';

interface Props {
	toggleSideNav: () => void;
	isMinimized: boolean;
}

export default class SideNavLink extends React.Component<Props> {

	enter = async () => {
		await this.setState(() => ({ isHovered: true }));
	};

	leave = async () => {
		await this.setState(() => ({ isHovered: false }));
	};

	toggle = () => {
		const { toggleSideNav } = this.props;
		toggleSideNav();
	};

	render(): JSX.Element {
		const { isMinimized } = this.props;

		return (
			<div className="sidenav-close" onClick={this.toggle} onMouseEnter={this.enter} onMouseLeave={this.leave}>
				<div className="sidenav-collapse">
					<span>Collapse</span>
				</div>
				<span className={isMinimized ? 'icon-right' : 'icon-left'} />
			</div>
		);
	}
}
