import * as React from 'react';

import CustomScrollbar from 'af-components/CustomScrollbar';

import { DEFAULT_EQUIPMENT_PLACEHOLDER } from 'ab-constants/value';

import UnavailableEquipment from './UnavailableEquipment';
import AvailableEquipment from './AvailableEquipment';
import ResourcePlaceholder from '../../ResourcePlaceholder';

import { EquipmentModalProps } from '../../../Shared/ModalProps';

interface Props extends EquipmentModalProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

export default (props: Props) => {
	const { dueDate, isDragAndDropDisabled, hasPermissionsToEditScheduleBoard, setEquipmentModalData, setEquipmentModalVisibility } = props;

	return (

		<CustomScrollbar
			contentWrapperClassName="schedule-board-toolbar-column equipment"
			isHorizontalHidden={true}
			isVerticalHidden={true}
			scrollbarsClassName="schedule-board-toolbar-column-wrapper equipment"
		>
			<ResourcePlaceholder
				dueDate={dueDate}
				isPlaceholder={true}
				label="Equipment PH"
				uniqueCode={DEFAULT_EQUIPMENT_PLACEHOLDER}
			/>
			<AvailableEquipment
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				isDragAndDropDisabled={isDragAndDropDisabled}
				setEquipmentModalData={setEquipmentModalData}
				setEquipmentModalVisibility={setEquipmentModalVisibility}
			/>
			<UnavailableEquipment
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				isDragAndDropDisabled={isDragAndDropDisabled}
				setEquipmentModalData={setEquipmentModalData}
				setEquipmentModalVisibility={setEquipmentModalVisibility}
			/>
		</CustomScrollbar>
	);
};
