import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field } from 'redux-form';

import * as EquipmentCostActions from 'af-actions/equipmentCost';

import { EquipmentCostViewModel } from 'ab-viewModels/equipmentCost.viewModel';

import Dropdown from 'af-fields/Dropdown';

import EquipmentPlaceholderOptionItem from './EquipmentPlaceholderOptionItem';
import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	name: string;
	propName: string;
	disabled?: boolean;
	isModal: boolean;
	resetTimer: () => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	options: EquipmentCostViewModel[];
}

class EquipmentPlaceholderItem extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		disabled: false,
	};

	state: State = {
		options: [],
	};

	filterEquipmentPlaceholderItem = (option: EquipmentCostViewModel, searchText: string) => {
		const { subcategory = '', categoryName = '' } = option;
		const searchInputs = (searchText || '').replace(/\s\s+/g, ' ').toLowerCase().split(' ');
		const searchableFields = [subcategory, categoryName];
		const isFiltered = searchInputs.every((_searchInput: string) => searchableFields.some((_field: string) => _field.toLowerCase().includes(_searchInput)));
		return isFiltered;
	};

	renderEquipmentPlaceholderOptionItem = (option: EquipmentCostViewModel, searchText: string) => (
		<EquipmentPlaceholderOptionItem {...option} searchText={searchText} />
	);

	lazyLoadEquipmentCosts = async (isLazyLoaded: boolean) => {
		const { findAllEquipmentCostsForCompany } = this.props;
		if (!isLazyLoaded) {
			const equipmentCosts = await findAllEquipmentCostsForCompany();
			this.setState(() => ({ options: equipmentCosts }));
		}
	};

	render() {
		const { name, propName, resetTimer, disabled, isModal } = this.props;
		const { options } = this.state;

		const className = bemElement('resource-lookup', 'item', { placeholder: true, 'non-padded': isModal });
		return (
			<div className={className}>
				<Field
					component={Dropdown}
					disabled={disabled}
					filterable={true}
					filterBy={this.filterEquipmentPlaceholderItem}
					fixed={isModal}
					hideErrorText={true}
					id={name}
					isArrayField={true}
					isStandalone={true}
					name={name}
					onLazyLoad={this.lazyLoadEquipmentCosts}
					onValueChange={resetTimer}
					options={options}
					placeholder="Choose equipment placeholder"
					propName={propName}
					renderMenuItem={this.renderEquipmentPlaceholderOptionItem}
					renderSelected={this.renderEquipmentPlaceholderOptionItem}
					valueKey="id"
					withCaret={true}
				/>
			</div>
		);
	}

}

function mapDispatchToProps() {
	return {
		findAllEquipmentCostsForCompany: EquipmentCostActions.findAllForCompany,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(EquipmentPlaceholderItem);
