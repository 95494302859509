import * as React from 'react';
import * as papaParse from 'papaparse';

import { CSVRow, CSVData } from 'ab-viewModels/csv.viewModel';

// private functions

const _getPrefix = (prefix: string) =>  prefix ? `${prefix}.` : '';

// recursive
const _renderErrors = (errors: Metadata, index: number = 0, prefix: string = '') => {
	return Object.keys(errors).map((_field, _keyIndex) => {
		const fieldValue = errors[_field];
		if (Array.isArray(fieldValue)) {
			return [...fieldValue.map((_value, _fieldIndex) => _renderErrors(_value, _keyIndex + index + _fieldIndex, `${_getPrefix(prefix)}${_field}[${_fieldIndex}]`))];
		} else if (typeof fieldValue === 'object') {
			return [..._renderErrors(fieldValue, _keyIndex + index, `${_getPrefix(prefix)}${_field}`)];
		}
		return (
			<div key={index + _keyIndex}>
				- {`${_getPrefix(prefix)}${_field}: ${fieldValue}`}
			</div>
		);
	});
};

// public functions

export const containsData = (row: CSVRow): boolean => {
	return Object.values(row).some((_value) => !!_value);
};

export const sanitizeRow = (row: CSVRow): CSVRow => {
	return Object.entries(row).reduce((_result, [_key, _value]) => {
		_result[_key] = _value.trim();
		return _result;
	}, {});
};

export const sanitize = (data: CSVData): CSVData => {
	return data.map(sanitizeRow).filter(containsData);
};

export const generateCSVHref = (data: Nullable<string>[][]): string => {
	const csv = papaParse.unparse(data);
	return `data:text/csv;charset=utf-8,${escape(csv)}`;
};

export const downloadCSV = (csvData: Nullable<string>[][], csvName: string) => {
	const url = generateCSVHref(csvData);
	const a = document.createElement('a');
	a.download = csvName;
	a.target = '_blank';
	a.href = url;
	a.click();
};

export const formatErrorTooltip = (errors: Metadata = {}): JSX.Element => {
	return (
		<div className="tooltip-formatted-content">
			{Object.keys(errors).map((_row, _i1) => {
				return isNaN(Number(_row)) ? (
					<div key={_i1}>
						<b>
							{_row}: {errors[_row]}
						</b>
					</div>
				) : (
					<div key={_i1}>
						<b>Row: {Number(_row) + 1}</b>
						{_renderErrors(errors[_row])}
					</div>
				);
			})}
		</div>
	);
};
