import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	label: string;
	id: number;
	isActive: boolean;
	onClick: (id: number) => void;
	renderLabel?: (id: number) => JSX.Element;
	resolveCustomClassName?: (active: boolean) => string;
}

type Props = OwnProps;

class Tab extends React.PureComponent<Props> {

	onTabClick = () => {
		const { onClick, id } = this.props;
		onClick(id);
	};

	render() {
		const { isActive, id, label, renderLabel, resolveCustomClassName } = this.props;

		const className = !!resolveCustomClassName
			? resolveCustomClassName(isActive)
			: bemElement('tabs-navigation', 'tab', {
				active: isActive,
			});

		return (
			<div
				className={className}
				key={`${id}#${label}`}
				onClick={!isActive ? this.onTabClick : undefined}
			>
				<span className="tab-title">{renderLabel?.(id) ?? label}</span>
			</div>
		);
	}
}

export default Tab as React.ComponentClass<OwnProps>;
