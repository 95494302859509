enum Month {
	JANUARY = 1,
	FEBRUARY = 2,
	MARCH = 3,
	APRIL = 4,
	MAY = 5,
	JUNE = 6,
	JULY = 7,
	AUGUST = 8,
	SEPTEMBER = 9,
	OCTOBER = 10,
	NOVEMBER = 11,
	DECEMBER = 12
}

export const MONTH_LABEL_SHORT = {
	[Month.JANUARY]: 'Jan',
	[Month.FEBRUARY]: 'Feb',
	[Month.MARCH]: 'Mar',
	[Month.APRIL]: 'Apr',
	[Month.MAY]: 'May',
	[Month.JUNE]: 'Jun',
	[Month.JULY]: 'Jul',
	[Month.AUGUST]: 'Aug',
	[Month.SEPTEMBER]: 'Sep',
	[Month.OCTOBER]: 'Oct',
	[Month.NOVEMBER]: 'Nov',
	[Month.DECEMBER]: 'Dec',
};

export default Month;
