import * as React from 'react';
import { Link } from 'react-router-dom';

import UserPermission from 'acceligent-shared/enums/userPermission';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import CLIENT from 'af-routes/client';

import StatusIcon from 'af-components/StatusIcon';
import SegmentLabel from 'af-components/SegmentLabel';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import AttachmentsBadge from '../AttachmentsBadge';
import NightShiftBadge from '../NightShiftBadge';

interface OwnProps {
	orgAlias: string;
	companyName: string;
	isAdmin: boolean;
	accountPermissions: string[];
	userPermission: UserPermission;
}

type Props = OwnProps;

interface State {
	isStatusHovered: boolean;
	workOrderStatus: WorkOrderStatus;
	notificationStatus: NotificationStatusEnum;
	isFirstRevision: boolean;
	isCrewTypeHovered: boolean;
	isProductionDataHovered: boolean;
	isSiPmHovered: boolean;
	isCustomerInformationHovered: boolean;
	isShiftHovered: boolean;
	isAttachmentHovered: boolean;
	isInternalHovered: boolean;
	isProjectHovered: boolean;
	isNightShiftHovered: boolean;
	isPaused: boolean;
	isFlagHovered: boolean;
	isJobStatusBoxHovered: boolean;
}

const DEFAULT_WO_STATUS = WorkOrderStatus.PUBLISHED;
const DEFAULT_NOTIFICATION_STATUS = NotificationStatusEnum.OPENED;

class WorkOrderCardLegend extends React.PureComponent<Props, State> {

	state: State = {
		isStatusHovered: false,
		workOrderStatus: DEFAULT_WO_STATUS,
		notificationStatus: DEFAULT_NOTIFICATION_STATUS,
		isFirstRevision: false,
		isCrewTypeHovered: false,
		isProductionDataHovered: false,
		isSiPmHovered: false,
		isCustomerInformationHovered: false,
		isShiftHovered: false,
		isAttachmentHovered: false,
		isInternalHovered: false,
		isProjectHovered: false,
		isNightShiftHovered: false,
		isPaused: false,
		isFlagHovered: false,
		isJobStatusBoxHovered: false,
	};

	onStatusHover = (
		isHovered: boolean,
		workOrderStatus: WorkOrderStatus,
		notificationStatus: NotificationStatusEnum,
		isFirstRevision: boolean,
		isPaused: boolean
	) => {
		this.setState(() => ({
			isStatusHovered: isHovered,
			workOrderStatus: workOrderStatus ?? DEFAULT_WO_STATUS,
			notificationStatus: notificationStatus ?? DEFAULT_NOTIFICATION_STATUS,
			isFirstRevision: isFirstRevision ?? false,
			isPaused: isPaused ?? false,
		}));
	};

	renderStatus = () => {
		const {
			workOrderStatus,
			notificationStatus,
			isCrewTypeHovered,
			isProductionDataHovered,
			isSiPmHovered,
			isCustomerInformationHovered,
			isShiftHovered,
			isFirstRevision,
			isAttachmentHovered,
			isInternalHovered,
			isProjectHovered,
			isPaused,
			isFlagHovered,
			isJobStatusBoxHovered,
		} = this.state;

		const isAnythingHovered =
			isCrewTypeHovered
			|| isProductionDataHovered
			|| isSiPmHovered
			|| isShiftHovered
			|| isCustomerInformationHovered
			|| isAttachmentHovered
			|| isInternalHovered
			|| isProjectHovered
			|| isFlagHovered
			|| isJobStatusBoxHovered;

		let className = 'legend-modal__work-order-status';
		className = isAnythingHovered ? `${className} legend-modal__opaque-partial` : className;

		return (
			<div className={className}>
				<StatusIcon firstRevision={isFirstRevision} isPaused={isPaused} notificationStatus={notificationStatus} status={workOrderStatus} />
			</div>
		);
	};

	onCrewTypeHover = (isHovered: boolean) => {
		this.setState(() => ({ isCrewTypeHovered: isHovered }));
	};

	renderCrewType = () => {
		const { isCrewTypeHovered, isInternalHovered, isProjectHovered } = this.state;

		let className = 'legend-modal__crew-type';
		className = isInternalHovered ? `${className} legend-modal__crew-type--internal` : className;
		className = (isCrewTypeHovered || isInternalHovered) ? `${className} legend-modal__hovered-partial` : className;

		return (
			<div className={className}>
				<div>JobID.01 REV B</div>
				{
					isProjectHovered &&
					<div className="legend-modal__project-code">
						ProjectJobID
					</div>
				}
			</div>
		);
	};

	renderProjectCode = () => {
		const { isProjectHovered } = this.state;

		if (!isProjectHovered) {
			return null;
		}

		return (
			<div className="schedule-board-card-project-code">
				ProjectJobID
			</div>
		);
	};

	onProductionDataHover = (isHovered: boolean) => {
		this.setState(() => ({ isProductionDataHovered: isHovered }));
	};

	renderProductionData = () => {
		const { isProductionDataHovered, isInternalHovered } = this.state;

		let className = 'legend-modal__production-data';
		className = isInternalHovered ? `${className} legend-modal__production-data--internal` : className;
		className = (isProductionDataHovered || isInternalHovered) ? `${className} legend-modal__hovered-partial` : className;

		return (
			<span className={className}>
				$100 / 20 HR
			</span>
		);
	};

	onSiPmHover = (isHovered: boolean) => {
		this.setState(() => ({ isSiPmHovered: isHovered }));
	};

	renderSiPm = () => {
		const { isSiPmHovered } = this.state;

		let className = 'legend-modal__sipm';
		className = isSiPmHovered ? `${className} legend-modal__hovered-partial` : className;

		return (
			<span className={className}>
				J Miller / A Smith
			</span>
		);
	};

	onShiftHover = (isHovered: boolean) => {
		this.setState(() => ({ isShiftHovered: isHovered }));
	};

	renderShift = () => {
		const { isShiftHovered, isNightShiftHovered } = this.state;

		let className = 'legend-modal__shift';
		className = isShiftHovered ? `${className} legend-modal__hovered-partial` : className;

		return (
			<div className={className}>
				{isNightShiftHovered && <NightShiftBadge />}
				<div className="legend-modal__shift__time">
					07:00 AM - 03:00 PM
				</div>
			</div>
		);
	};

	onCustomerInformationHover = (isHovered: boolean) => {
		this.setState(() => ({ isCustomerInformationHovered: isHovered }));
	};

	renderCustomerInformation = () => {
		const {
			isCustomerInformationHovered,
			isStatusHovered,
			workOrderStatus,
			isInternalHovered,
			isPaused,
		} = this.state;

		let className = 'legend-modal__customer-info';
		// check internal
		className = isInternalHovered ? `${className} legend-modal__customer-info--internal` : className;
		// check work order state
		className = isPaused
			? `${className} legend-modal__customer-info--paused`
			: (isStatusHovered && workOrderStatus === WorkOrderStatus.CANCELED)
				? `${className} legend-modal__customer-info--canceled`
				: (isStatusHovered && workOrderStatus === WorkOrderStatus.LOCKED)
					? `${className} legend-modal__customer-info--locked`
					: className;

		className = isCustomerInformationHovered
			|| (isStatusHovered && workOrderStatus === WorkOrderStatus.CANCELED)
			|| (isStatusHovered && workOrderStatus === WorkOrderStatus.LOCKED)
			|| isInternalHovered
			|| isPaused
			? `${className} legend-modal__hovered-partial`
			: className;

		return (
			<div className={className}>
				{isPaused
					? 'ON HOLD'
					: (isStatusHovered && workOrderStatus === WorkOrderStatus.CANCELED)
						? 'CANCELED'
						: (isStatusHovered && workOrderStatus === WorkOrderStatus.LOCKED)
							? 'LOCKED (APPROVED)'
							: <>
								Customer
								<br />
								Title
								<br />
								Address
							</>
				}
			</div>
		);
	};

	onAttachmentHover = (isHovered: boolean) => {
		this.setState(() => ({ isAttachmentHovered: isHovered }));
	};

	renderAttachment = () => {
		const { isAttachmentHovered } = this.state;

		return (
			<div className={isAttachmentHovered ? 'legend-modal__hovered-partial' : undefined}>
				<AttachmentsBadge isDark={isAttachmentHovered} />
			</div>
		);
	};

	renderJobStatusBox = () => {
		const { isJobStatusBoxHovered } = this.state;

		return (
			<div className={`legend-modal__job-status-box ${isJobStatusBoxHovered ? 'legend-modal__hovered-partial' : undefined}`} />
		);
	};

	renderUnionAndPrevailingWageFlags = () => {
		const { isFlagHovered } = this.state;

		return (
			<div className={`legend-modal__flags ${isFlagHovered ? 'legend-modal__hovered-partial' : undefined}`}>
				<div className="legend-modal__flag">U</div>
				<div className="legend-modal__flag">PW</div>
			</div>
		);
	};

	onInternalHover = (isHovered: boolean) => {
		this.setState(() => ({ isInternalHovered: isHovered }));
	};

	onProjectHover = (isHovered: boolean) => {
		this.setState(() => ({ isProjectHovered: isHovered }));
	};

	onFlagsHover = (isHovered: boolean) => {
		this.setState(() => ({ isFlagHovered: isHovered }));
	};

	onJobStatusBoxHover = (isHovered: boolean) => {
		this.setState(() => ({ isJobStatusBoxHovered: isHovered }));
	};

	onNightShiftHover = (isNightShiftHovered: boolean) => this.setState(() => ({ isNightShiftHovered }));

	render() {
		const {
			orgAlias,
			companyName,
			accountPermissions,
			isAdmin,
			userPermission,
		} = this.props;
		const {
			isCrewTypeHovered,
			isProductionDataHovered,
			isSiPmHovered,
			isCustomerInformationHovered,
			isShiftHovered,
			isStatusHovered,
			isAttachmentHovered,
			isInternalHovered,
			isProjectHovered,
			isFlagHovered,
			isJobStatusBoxHovered,
		} = this.state;

		const isAnythingHovered =
			isCrewTypeHovered
			|| isProductionDataHovered
			|| isSiPmHovered
			|| isCustomerInformationHovered
			|| isShiftHovered
			|| isStatusHovered
			|| isAttachmentHovered
			|| isInternalHovered
			|| isFlagHovered
			|| isJobStatusBoxHovered;

		const isAllowedToChangeCrewTypes = isAllowed(PagePermissions.COMPANY.RESOURCES.CREW_TYPE, accountPermissions, isAdmin, userPermission);

		let resourceCardClass = 'legend-modal__work-order-card legend-modal__work-order-card--equipment';
		resourceCardClass = isSiPmHovered ? `${resourceCardClass} legend-modal__hovered-partial` : resourceCardClass;
		resourceCardClass = isProjectHovered ? `${resourceCardClass} legend-modal__work-order-card--project` : resourceCardClass;

		return (
			<div className="legend-modal__segment">
				<SegmentLabel label="Work Order Card" />
				<div className="legend-modal__segment-content">
					<div className="legend-modal__work-order-card-container">
						<div className={resourceCardClass}>
							{isAnythingHovered && <div className="legend-modal__work-order-card-curtain" />}
							{this.renderAttachment()}
							{this.renderJobStatusBox()}
							{this.renderCrewType()}
							{this.renderUnionAndPrevailingWageFlags()}
							{this.renderProductionData()}
							{this.renderCustomerInformation()}
							{this.renderSiPm()}
							{this.renderShift()}
							{this.renderStatus()}
						</div>
					</div>
					<div className="legend-modal__description-container">
						<div
							className="legend-modal__description"
							onMouseEnter={this.onStatusHover.bind(this, true, null, null, null)}
							onMouseLeave={this.onStatusHover.bind(this, false, null, null, null)}
						>
							Work Order status
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onStatusHover.bind(this, true, WorkOrderStatus.DRAFT, null, null)}
							onMouseLeave={this.onStatusHover.bind(this, false, null, null, null)}
						>
							<StatusIcon isSmall={true} status={WorkOrderStatus.DRAFT} />
							Draft - Not yet published
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onStatusHover.bind(this, true, WorkOrderStatus.PUBLISHED, NotificationStatusEnum.DELIVERED, true)}
							onMouseLeave={this.onStatusHover.bind(this, false, null, null, null)}
						>
							<StatusIcon
								firstRevision={true}
								isSmall={true}
								notificationStatus={NotificationStatusEnum.DELIVERED}
								status={WorkOrderStatus.PUBLISHED}
							/>
							Published first revision - Notifications not yet read by all participants
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onStatusHover.bind(this, true, WorkOrderStatus.PUBLISHED, NotificationStatusEnum.DELIVERED, false)}
							onMouseLeave={this.onStatusHover.bind(this, false, null, null, null)}
						>
							<StatusIcon
								firstRevision={false}
								isSmall={true}
								notificationStatus={NotificationStatusEnum.DELIVERED}
								status={WorkOrderStatus.PUBLISHED}
							/>
							Re-published revision - Revision B onward
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onStatusHover.bind(this, true, WorkOrderStatus.OUTDATED, null, null, null)}
							onMouseLeave={this.onStatusHover.bind(this, false, null, null, null)}
						>
							<StatusIcon isSmall={true} status={WorkOrderStatus.OUTDATED} />
							Outdated - Changes have been made to an already published version, but have not yet been re-published
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onStatusHover.bind(this, true, WorkOrderStatus.PUBLISHED, NotificationStatusEnum.OPENED, null, null)}
							onMouseLeave={this.onStatusHover.bind(this, false, null, null, null)}
						>
							<StatusIcon isSmall={true} notificationStatus={NotificationStatusEnum.OPENED} status={WorkOrderStatus.PUBLISHED} />
							Published and notified - All participants have read their notifications / default for published if notifications are turned off
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onStatusHover.bind(this, true, WorkOrderStatus.CANCELED, null, null, null)}
							onMouseLeave={this.onStatusHover.bind(this, false, null, null, null)}
						>
							<StatusIcon isSmall={true} status={WorkOrderStatus.CANCELED} />
							Canceled
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onStatusHover.bind(this, true, WorkOrderStatus.LOCKED, null, null, null)}
							onMouseLeave={this.onStatusHover.bind(this, false, null, null, null)}
						>
							<StatusIcon isSmall={true} status={WorkOrderStatus.LOCKED} />
							Locked - It has been approved by Accounting
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onStatusHover.bind(this, true, WorkOrderStatus.PUBLISHED, null, null, true)}
							onMouseLeave={this.onStatusHover.bind(this, false, null, null, null)}
						>
							<StatusIcon isPaused={true} isSmall={true} status={WorkOrderStatus.PUBLISHED} />
							Paused
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onStatusHover.bind(this, true, WorkOrderStatus.PUBLISHED, NotificationStatusEnum.ERROR, null, null)}
							onMouseLeave={this.onStatusHover.bind(this, false, null, null, null)}
						>
							<StatusIcon isSmall={true} notificationStatus={NotificationStatusEnum.ERROR} status={WorkOrderStatus.PUBLISHED} />
							Error - One or more notifications failed to be sent
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onFlagsHover.bind(this, true)}
							onMouseLeave={this.onFlagsHover.bind(this, false)}
						>
							Union and Prevailing Wage Flags
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onInternalHover.bind(this, true)}
							onMouseLeave={this.onInternalHover.bind(this, false)}
						>
							Internal Work Order
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onProjectHover.bind(this, true)}
							onMouseLeave={this.onProjectHover.bind(this, false)}
						>
							Project
						</div>
						<div
							onMouseEnter={this.onCrewTypeHover.bind(this, true)}
							onMouseLeave={this.onCrewTypeHover.bind(this, false)}
						>
							<div className="legend-modal__description">
								Crew Type
								{isAllowedToChangeCrewTypes &&
									<div className="legend-modal__description legend-modal__description--details">
										Crew Types
										<Link
											className="btn--control"
											to={CLIENT.COMPANY.RESOURCES.CREW_TYPE.LIST(orgAlias, companyName)}
										>
											<span className="icon-settings" />
										</Link>
									</div>
								}
							</div>
							<div className="legend-modal__description legend-modal__description--additional">
								Indicated by the background color of the card
							</div>
							<div className="legend-modal__description">
								Work Order code
							</div>
							<div className="legend-modal__description legend-modal__description--additional">
								<b>JobID </b> First part of Work Order's code. Unique name of the job it belongs to
							</div>
							<div className="legend-modal__description legend-modal__description--additional">
								<b>01 </b> Creation index of the Work Order for that Job for the day (regardless of its position on the board)
							</div>
							<div className="legend-modal__description legend-modal__description--additional">
								<b>REV B</b> Revision of the Work Order, starts with <b>A</b>
							</div>
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onAttachmentHover.bind(this, true)}
							onMouseLeave={this.onAttachmentHover.bind(this, false)}
						>
							Attachment exists
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onJobStatusBoxHover.bind(this, true)}
							onMouseLeave={this.onJobStatusBoxHover.bind(this, false)}
						>
							Job Status Indicator
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onProductionDataHover.bind(this, true)}
							onMouseLeave={this.onProductionDataHover.bind(this, false)}
						>
							Revenue / Total hours
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onCustomerInformationHover.bind(this, true)}
							onMouseLeave={this.onCustomerInformationHover.bind(this, false)}
						>
							Customer information
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onSiPmHover.bind(this, true)}
							onMouseLeave={this.onSiPmHover.bind(this, false)}
						>
							Project Manager / Supervisor
						</div>
						<div
							onMouseEnter={this.onShiftHover.bind(this, true)}
							onMouseLeave={this.onShiftHover.bind(this, false)}
						>
							<div className="legend-modal__description">Shift</div>
							<div
								className="legend-modal__description legend-modal__description--additional"
								onMouseEnter={this.onNightShiftHover.bind(null, true)}
								onMouseLeave={this.onNightShiftHover.bind(null, false)}
							>
								<NightShiftBadge /> Night Shift - indicates a Work Order in a night shift
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WorkOrderCardLegend;
