import * as React from 'react';

import { ExtendedColorValue, ExtendedColorPalette } from 'acceligent-shared/enums/color';

import * as ColorUtils from 'ab-utils/color.util';

interface Props {
	color: ExtendedColorValue | ExtendedColorPalette | null;
	isSmall?: boolean;
	className?: string;
}

export default class ColorSquare extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		isSmall: false,
	};

	render() {
		const { color, isSmall, className: _className } = this.props;

		let className = `color-square ${ColorUtils.getColorBackgroundClass(color, true)}`;
		className = isSmall ? `${className} color-square--small` : className;
		className = _className ? `${className} ${_className}` : className;

		return (
			<div className={className} />
		);
	}
}
