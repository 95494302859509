import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

interface OwnProps {
	action: () => void;
	label: string;
	icon?: JSX.Element;
	isDisabled?: boolean;
	isActive?: boolean;
	id?: string;
}

type Props = OwnProps;

export default class ActionsMenuItem extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		isDisabled: false,
		isActive: false,
	};

	render() {
		const { isDisabled, icon, isActive, action, label, id } = this.props;

		return (
			<Dropdown.Item disabled={!!isDisabled} id={id} onClick={action}>
				<div className={`dropdown-menu-item ${isActive ? 'active' : ''}`}>
					{icon}
					<span>{label}</span>
					{isActive && <span className="m-l-l icon-check text-orange" />}
				</div>
			</Dropdown.Item>
		);
	}
}
