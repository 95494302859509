import { ColorPalette } from 'acceligent-shared/enums/color';
import SubscriptionStatus from 'acceligent-shared/enums/subscriptionStatus';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import { normalizeUTCDate } from 'acceligent-shared/utils/time';

import ScheduleBoardEmployee from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import ScheduleBoardTemporaryEmployeeVM from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';
import { NotificationStatusViewModel } from 'ab-viewModels/notification.viewModel';

import { NOTIFY_EMPLOYEE_TYPE } from 'af-constants/values';

export type AvailableEmployeesNotificationModalData = {
	employees: EmployeeNotificationStatusData;
	hasAlreadyNotifiedEmployees: boolean;
	groupTitle: Nullable<string>;
	groupId: number;
	dueDate: string;
	employeeIds: number[];
};

export type OpenAvailableEmployeesNotificationModalData = { groupId: number; dueDate: string; };

export type CheckEmployeeInAvailableEmployeesNotificationModal = { employeeId: number; type: NOTIFY_EMPLOYEE_TYPE; };

export type CheckAllInAvailableEmployeesNotificationModal = { value: boolean; type: NOTIFY_EMPLOYEE_TYPE; };

export type EmployeeNotificationStatusData = { [id: number]: EmployeeNotifyModalState; };

export interface NotifyModalBaseViewModel {
	id: number;
	fullName: string;
	phoneNumber: Nullable<string>;
	email: Nullable<string>;
	lastEmailSentStatus: Nullable<NotificationStatusEnum>;
	lastSmsSentStatus: Nullable<NotificationStatusEnum>;
	lastEmailSentAt: Date | undefined;
	lastSmsSentAt: Date | undefined;
	emailInvalidatedAt: Date | undefined;
	phoneInvalidatedAt: Date | undefined;
	subscriptionStatus: SubscriptionStatus;
	subscribedForEmail: boolean;
	subscribedForSms: boolean;
	emailErrorMessage: Nullable<string>;
	smsErrorMessage: Nullable<string>;
	isAssignedToWO: boolean;
}

interface EmployeeNotifyModalViewModel extends NotifyModalBaseViewModel {
	officeColor: Nullable<ColorPalette>;
	officeNickname: Nullable<string>;
}

export interface EmployeeNotifyModalState extends EmployeeNotifyModalViewModel {
	shouldSendEmail: boolean;
	shouldSendSms: boolean;
}

export type EmployeeNotificationStatusDict = { [id: number]: EmployeeNotifyModalState; };

export function getNotifyModalEmployeeFromEmployee(
	id: number,
	employee: ScheduleBoardEmployee,
	notificationStatus: Nullable<NotificationStatusViewModel>
): EmployeeNotifyModalViewModel {
	if (!employee) {
		throw new Error('Employee not provided');
	}

	return {
		id,
		fullName: `${employee.firstName} ${employee.lastName}`,
		phoneNumber: employee.phoneNumber,
		email: employee.email,
		officeColor: employee.office?.color ?? null,
		officeNickname: employee.office?.nickname ?? null,
		lastEmailSentStatus: notificationStatus?.emailStatus ?? null,
		lastSmsSentStatus: notificationStatus?.smsStatus ?? null,
		lastEmailSentAt: !notificationStatus?.isPreviousRevision && notificationStatus?.emailSentAt
			? normalizeUTCDate(notificationStatus.emailSentAt)
			: undefined,
		lastSmsSentAt: !notificationStatus?.isPreviousRevision && notificationStatus?.smsSentAt
			? normalizeUTCDate(notificationStatus.smsSentAt)
			: undefined,
		emailInvalidatedAt: undefined,
		phoneInvalidatedAt: undefined,
		subscriptionStatus: employee.subscriptionStatus ?? SubscriptionStatus.SUBSCRIBED,
		subscribedForEmail: notificationStatus?.emailStatus === NotificationStatusEnum.SCHEDULED,
		subscribedForSms: notificationStatus?.smsStatus === NotificationStatusEnum.SCHEDULED,
		emailErrorMessage: notificationStatus?.emailErrorMessage ?? null,
		smsErrorMessage: notificationStatus?.smsErrorMessage ?? null,
		isAssignedToWO: true,
	};
}

interface TempEmployeeNotifyModalViewModel extends NotifyModalBaseViewModel {
	officeColor: Nullable<ColorPalette>;
	officeNickname: Nullable<string>;
	agencyColor: ColorPalette;
}

export interface TempEmployeeNotifyModalState extends TempEmployeeNotifyModalViewModel {
	shouldSendEmail: boolean;
	shouldSendSms: boolean;
}

export type TemporaryEmployeeNotificationStatusDict = { [id: number]: TempEmployeeNotifyModalState; };

export function getNotifyModalTemporaryEmployeeFromTemporaryEmployee(
	id: number,
	temporaryEmployee: ScheduleBoardTemporaryEmployeeVM,
	notificationStatus: Nullable<NotificationStatusViewModel>
): TempEmployeeNotifyModalViewModel {
	if (!temporaryEmployee) {
		throw new Error('Temporary Employee not provided');
	}

	return {
		id,
		fullName: `${temporaryEmployee.firstName} ${temporaryEmployee.lastName}`,
		phoneNumber: temporaryEmployee.account.user.phoneNumber,
		email: temporaryEmployee.account.user.email,
		officeColor: null,
		officeNickname: null,
		agencyColor: temporaryEmployee.agencyColor,
		lastEmailSentStatus: notificationStatus?.emailStatus ?? null,
		lastSmsSentStatus: notificationStatus?.smsStatus ?? null,
		lastEmailSentAt: !notificationStatus?.isPreviousRevision && notificationStatus?.emailSentAt
			? normalizeUTCDate(notificationStatus.emailSentAt)
			: undefined,
		lastSmsSentAt: !notificationStatus?.isPreviousRevision && notificationStatus?.smsSentAt
			? normalizeUTCDate(notificationStatus.smsSentAt)
			: undefined,
		emailInvalidatedAt: undefined,
		phoneInvalidatedAt: undefined,
		subscriptionStatus: temporaryEmployee.subscriptionStatus ?? SubscriptionStatus.SUBSCRIBED,
		subscribedForEmail: notificationStatus?.emailStatus === NotificationStatusEnum.SCHEDULED,
		subscribedForSms: notificationStatus?.smsStatus === NotificationStatusEnum.SCHEDULED,
		emailErrorMessage: notificationStatus?.emailErrorMessage ?? null,
		smsErrorMessage: notificationStatus?.smsErrorMessage ?? null,
		isAssignedToWO: true,
	};
}
