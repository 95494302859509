import * as React from 'react';

interface Props {
	scrollPercentage: number;
	maxWidth: number;
	position?: 'top' | 'bottom';
	showCurtain?: boolean;
	className?: string;
	horizontalHeaderWidth?: number;
	onHorizontalScroll: (scrollLeft: number, width: number, force?: boolean) => void;
	setScrollingPercentage: (scrollingPercentage: number) => void;
	onVerticalScroll?: (elementClassName: string) => void;
	onRelease?: () => void;
}

interface State {
	scrollPercentage: number;
	scrollBarWidth: number;
	scrolling: boolean;
	anchorWidth: number;
}

export default class HorizontalScrollbar extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		position: 'top',
		className: '',
		showCurtain: false,
		horizontalHeaderWidth: 0,
	};

	state: State = {
		scrollPercentage: 0,
		scrollBarWidth: 0,
		scrolling: false,
		anchorWidth: 0,
	};

	_scrollBarRef: Nullable<HTMLElement> = null;
	_lastClientX: number = 0;

	constructor(props: Props) {
		super(props);
		window.addEventListener('mouseup', this.onMouseUp);
		window.addEventListener('mousemove', this.onMouseMove);
		window.addEventListener('touchend', this.onTouchEnd);
		window.addEventListener('touchmove', this.onTouchMove);
	}

	static getDerivedStateFromProps(props: Props, state: State) {
		if (!state.scrolling && props.scrollPercentage !== state.scrollPercentage) {
			return { ...state, scrollPercentage: props.scrollPercentage };
		}

		return null;
	}

	componentWillUnmount() {
		window.removeEventListener('mouseup', this.onMouseUp);
		window.removeEventListener('mousemove', this.onMouseMove);
		window.removeEventListener('touchend', this.onTouchEnd);
		window.removeEventListener('touchmove', this.onTouchMove);
	}

	getAnchorWidth = (): number => {
		const { maxWidth } = this.props;

		const scrollBarWidth = this.getScrollBarWidth();
		// calculate width of scroll anchor depending on max width of horizontal scroll containers
		if (maxWidth <= scrollBarWidth) {
			return scrollBarWidth;
		}
		return Math.min(100 * Math.max(1, 10 * scrollBarWidth / maxWidth), scrollBarWidth);
	};

	getScrollBarWidth = (): number => {
		const { scrollBarWidth: prevScrollBarWidth } = this.state;

		const elementRect = this._scrollBarRef?.getClientRects();
		return elementRect?.[0]?.width ?? prevScrollBarWidth;
	};

	onMount = (element) => {
		this._scrollBarRef = element;

		const anchorWidth = this.getAnchorWidth();
		const scrollBarWidth = this.getScrollBarWidth();
		this.setState(() => ({
			scrollBarWidth,
			anchorWidth,
		}));
	};

	onMouseMove = (event: MouseEvent) => {
		const element = document.elementFromPoint(event.pageX, event.pageY);
		this.onMove(element, event.clientX);

		event.stopPropagation();
		event.preventDefault();
	};

	onTouchMove = (event: TouchEvent) => {
		const touch = event.touches[0];
		const element = document.elementFromPoint(touch.pageX, touch.pageY);
		this.onMove(element, touch.clientX);
	};

	onMove = (element: Nullable<Element>, clientX: number) => {
		const { onVerticalScroll, onHorizontalScroll, maxWidth } = this.props;
		const { scrollPercentage: oldScrollPercentage, scrolling } = this.state;
		// calculate transition of scroll anchor when it is dragged to left/right
		// move every horizontal scroll container by calculated offset

		const elementClassName = element?.className ?? '';

		if (onVerticalScroll) {
			onVerticalScroll(elementClassName);
		}

		if (!scrolling) {
			return;
		}

		const anchorWidth = this.getAnchorWidth();
		const _scrollBarWidth = this.getScrollBarWidth();
		const shiftX = clientX - this._lastClientX;
		this._lastClientX = clientX;

		const scrollBarWidthWithoutAnchor = _scrollBarWidth - anchorWidth;
		const newLeftOffset = (oldScrollPercentage * scrollBarWidthWithoutAnchor + shiftX);
		const scrollPercentage = Math.max(0, Math.min(1, newLeftOffset / scrollBarWidthWithoutAnchor));

		this.setState(() => ({ scrollPercentage: scrollPercentage, scrollBarWidth: _scrollBarWidth }));
		onHorizontalScroll(scrollPercentage, maxWidth - _scrollBarWidth, true);
	};

	calculateTransition = (scrollBarWidth: number) => {
		const { scrollPercentage } = this.state;
		return Math.min(scrollBarWidth, Math.max(0, scrollBarWidth * scrollPercentage));
	};

	onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
		// start of horizontal scrolling for card containers
		this.setState(() => ({ scrolling: true }));
		this._lastClientX = event.clientX;
		event.stopPropagation();
		event.preventDefault();
	};

	onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
		// start of horizontal scrolling for card containers
		this.setState(() => ({ scrolling: true }));
		this._lastClientX = event.touches[0].clientX;
	};

	onMouseUp = (event: MouseEvent) => {
		// stop of horizontal scrolling for card containers
		this.onRelease();
		event.stopPropagation();
		event.preventDefault();
	};

	onTouchEnd = () => {
		// stop of horizontal scrolling for card containers
		this.onRelease();
	};

	onRelease = () => {
		const { setScrollingPercentage, onRelease } = this.props;
		const { scrolling } = this.state;
		if (onRelease) {
			onRelease();
		}
		if (scrolling) {
			setScrollingPercentage(this.state.scrollPercentage);
		}
		this.setState(() => ({ scrolling: false }));
		this._lastClientX = 0;
	};

	render() {
		const { position, showCurtain, className } = this.props;
		const anchorWidth = this.getAnchorWidth();
		const scrollBarWidth = this.getScrollBarWidth();
		const leftOffset = this.calculateTransition(scrollBarWidth - anchorWidth);

		return (
			<div className={`${className} horizontal-scrollbar ${position}`}>
				{showCurtain && <div className={`curtain ${position}`} />}
				<div className={`scrollbar-container ${position}`} ref={this.onMount}>
					<div
						className={`scrollbar-anchor ${position}`}
						onMouseDown={this.onMouseDown}
						onTouchStart={this.onTouchStart}
						style={{
							transform: `translateX(${leftOffset}px)`,
							minWidth: `${anchorWidth}px`,
							width: `${anchorWidth}px`,
						}}
					/>
				</div>
			</div>
		);
	}
}
