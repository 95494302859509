import * as React from 'react';

import { CUSTOMER_SERVICE_NAME } from 'acceligent-shared/constants/value';

import styles from './styles.module.scss';

interface Props {
	rejectionReason: Nullable<string>;
	rejectedBy: Nullable<string>;
	isOpaque: boolean;
}

const RejectionBanner: React.FC<Props> = (props: Props) => {
	const { rejectionReason, rejectedBy, isOpaque } = props;

	return (
		<div className={`${styles['time-sheet-bulk-edit-modal__timeline__rejection-banner']} 
		${isOpaque ? `${styles['time-sheet-bulk-edit-modal__timeline__rejection-banner--opaque']}` : ''}`}
		>
			<div>
				<span className={`${styles['time-sheet-bulk-edit-modal__timeline__rejection-banner__user']}`}>
					Time Sheet rejected by {rejectedBy ?? CUSTOMER_SERVICE_NAME}
				</span>
			</div>
			<div>
				<span className={styles['time-sheet-bulk-edit-modal__timeline__rejection-banner__reason-title']}>
					Reason:
				</span>
				<span className={styles['time-sheet-bulk-edit-modal__timeline__rejection-banner__info']}>
					{rejectionReason}
				</span>
			</div>
		</div>
	);
};

export default React.memo(RejectionBanner);
