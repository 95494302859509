import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { SCHEDULE_BOARD_MAX_ITEMS_IN_ROW, RESOURCE_PLACEHOLDER } from 'ab-constants/scheduleBoard';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import { RootState } from 'af-reducers';

import DroppableComponent from './Droppable';

import { ResourceModalProps } from '../../Shared/ModalProps';

interface OwnProps extends ResourceModalProps {
	droppableId: string;
	draggablePrefix: string; // to distinguish if it's board or toolbar dragged employee
	wrappedColumns?: boolean;
	resourceIds: number[];
	/** `MM-DD-YYYY` */
	dueDate: string;
	isWorkOrderCanceled: boolean;
	isCopyPlaceholder?: boolean;
	isCardDisabled: boolean;
	isCalculationsView: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	ignorePlaceholders: boolean;
	workOrderCode: string;
	isWOLocked: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const ResourceDroppable: React.FC<Props> = (props: Props) => {
	const {
		dueDate,
		droppableId,
		resourceIds,
		draggablePrefix,
		wrappedColumns = false,
		isCardDisabled,
		isCalculationsView,
		isWorkOrderCanceled,
		isCopyPlaceholder = false,
		isDisabled,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		ignorePlaceholders,
		workOrderCode,
		setEmployeeModalData,
		setEquipmentModalData,
		setTemporaryEmployeeModalData,
		setEmployeeModalVisibility,
		setEquipmentModalVisibility,
		isWOLocked,
	} = props;

	let className = 'resource-droppable';
	className += !resourceIds.length ? ' empty-droppable' : '';
	className += wrappedColumns ? ' wrapped-columns' : '';

	// case for employees within card
	const numberOfColumns = Math.max(1, Math.ceil(resourceIds.length / SCHEDULE_BOARD_MAX_ITEMS_IN_ROW));
	return (
		<div className="resource-droppable-columns-wrapper">
			{Array(numberOfColumns || 1).fill(1).map((i, index) => (
				<DroppableComponent
					className={`${className} ${index > 0 ? ' multiple-droppable-column' : ''}`}
					draggablePrefix={draggablePrefix}
					droppableId={`${droppableId}#${index}`}
					dueDate={dueDate}
					endIndex={(index + 1) * SCHEDULE_BOARD_MAX_ITEMS_IN_ROW}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					ignorePlaceholders={ignorePlaceholders}
					isCalculationsView={isCalculationsView}
					isCardDisabled={isCardDisabled}
					isCopyPlaceholder={isCopyPlaceholder}
					isDisabled={isDisabled || isCardDisabled}
					isDragAndDropDisabled={isDragAndDropDisabled}
					isWOLocked={isWOLocked}
					isWorkOrderCanceled={isWorkOrderCanceled}
					key={`${droppableId}#${index}`}
					resourceIds={resourceIds}
					setEmployeeModalData={setEmployeeModalData}
					setEmployeeModalVisibility={setEmployeeModalVisibility}
					setEquipmentModalData={setEquipmentModalData}
					setEquipmentModalVisibility={setEquipmentModalVisibility}
					setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
					startIndex={index * SCHEDULE_BOARD_MAX_ITEMS_IN_ROW}
					workOrderCode={workOrderCode}
				/>
			))}
		</div>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { resourceIds, dueDate, isWorkOrderCanceled } = ownProps;
	const {
		scheduleBoard: {
			workOrdersByDateDictionary,
			draggedEmployeeId,
			draggedEquipmentId,
			draggedTemporaryEmployeeId,
			copiedEquipmentId,
			copiedEmployeeId,
			copiedTemporaryEmployeeId,
			draggedResourceId,
			weeklyViewDateWithToolbar,
			scheduleBoardView,
			draggedPlaceholderId,
			draggingLaborPlaceholder,
			draggingEquipmentPlaceholder,
		},
	} = state;

	let isIncluded = false; // indicated if currently dragged item is already contained in current droppable zone
	let isSource = false; // indicates if current droppable zone is source of currently dragged item

	const disableDraggingWhenToolbarOpened = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW &&
		(!weeklyViewDateWithToolbar || weeklyViewDateWithToolbar !== dueDate);

	if (draggedResourceId && !disableDraggingWhenToolbarOpened) {
		const draggedResource = workOrdersByDateDictionary[dueDate].workOrderResourceLookups[draggedResourceId];
		resourceIds.some((_resourceId: number) => {
			const resource = workOrdersByDateDictionary[dueDate].workOrderResourceLookups[_resourceId];
			if (_resourceId === RESOURCE_PLACEHOLDER || !resource) {
				return false;
			}

			if (resource.workOrderEmployeeId) {
				const employeeId = resource.employeeId;
				isIncluded = isIncluded || employeeId === draggedEmployeeId || employeeId === copiedEmployeeId;
			} else if (resource.workOrderEquipmentId) {
				const equipmentId = resource.equipmentId;
				isIncluded = isIncluded || equipmentId === draggedEquipmentId || equipmentId === copiedEquipmentId;
			} else if (resource.workOrderTemporaryEmployeeId) {
				const temporaryEmployeeId = resource.temporaryEmployeeId;
				isIncluded = isIncluded || temporaryEmployeeId === draggedTemporaryEmployeeId || temporaryEmployeeId === copiedTemporaryEmployeeId;
			}

			isSource = draggedResource.id === _resourceId;
			return isIncluded && isSource;
		});
	}

	const isDisabled: boolean = disableDraggingWhenToolbarOpened ||
		isWorkOrderCanceled ||
		(
			!draggedEmployeeId
			&& !draggedEquipmentId
			&& !draggedPlaceholderId
			&& !draggedTemporaryEmployeeId
			&& !draggingLaborPlaceholder
			&& !draggingEquipmentPlaceholder
		) || ((
			draggedEmployeeId ??
			draggedEquipmentId ??
			draggedTemporaryEmployeeId ??
			draggingLaborPlaceholder ??
			draggingEquipmentPlaceholder ??
			draggedPlaceholderId
		) && ownProps.isWOLocked) ||
		(isIncluded && (!isSource || !!copiedEmployeeId || !!copiedEquipmentId || !!copiedTemporaryEmployeeId));

	return {
		isDisabled,
	};
}

const connector = connect(mapStateToProps);

export default connector(ResourceDroppable);
