import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormGroup, FormControl } from 'react-bootstrap';
import { components, OptionTypeBase } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { ThemeConfig } from 'react-select/src/theme';
import { ValueType, FocusEventHandler } from 'react-select/src/types';
import { Option } from 'react-select/src/filters';
import { FormatOptionLabelMeta } from 'react-select/src/Select';
import { StylesConfig } from 'react-select/src/styles';

import Label from 'af-components/LockedValue/Label';
import { OwnProps as TooltipProps } from 'af-components/Tooltip';

import { bemBlock } from 'ab-utils/bem.util';

type DefaultOptionType = {
	label: string;
	value: number | string;
};

interface OwnProps<OptionType extends OptionTypeBase = DefaultOptionType> {
	addonComponent?: () => JSX.Element | string;
	allowNew?: boolean;
	className?: string;
	containerClassName?: string;
	containerId?: string;
	disableErrorMessage?: boolean;
	filterOption?: (option: Option, rawInput: string) => boolean;
	fixed?: boolean;
	formatCreateLabel?: (input: string) => React.ReactNode;
	formatOptionLabel?: (option: OptionType, labelMeta: FormatOptionLabelMeta<OptionType, boolean>) => React.ReactNode;
	formatSelectedOption?: (option: OptionType) => React.ReactNode;
	getNewOptionData?: (inputValue: string, optionLabel: string) => OptionType;
	getOptionLabel?: (option: OptionType) => string; // generaly option.label
	getOptionValue?: (option: OptionType) => string; // generaly option.id
	isDisabled?: boolean;
	isValidNewOption?: (inputValue: string, value: OptionType, options: OptionType[]) => boolean;
	label?: string;
	onBlur?: FocusEventHandler;
	onClear?: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	onCreateNew?: (inputValue: string) => void;
	onFocus?: FocusEventHandler;
	onLazyLoad?: (isLazyLoaded: boolean) => Promise<void>;
	onValueChange?: (selectedOption: ValueType<OptionType, boolean>) => Promise<void>;
	options: OptionType[];
	placeholder?: string;
	tooltipMessage?: TooltipProps['message'];
	valueKey?: string; // use only when selecting string options
}

type Props<OptionType extends OptionTypeBase> = OwnProps<OptionType> & WrappedFieldProps;

const getTheme: ThemeConfig = (theme) => ({
	...theme,
	borderRadius: 2,
	colors: {
		...theme.colors,
		primary25: '#FEF4E9',
		primary50: '#FEF4E9',
		primary75: '#FFA726',
		primary: '#FFA726',
	},
});

const fixedMenuStyle: React.CSSProperties = {
	left: 'initial',
	right: 'initial',
	top: 'initial',
	bottom: 'initial',
};

const customStylesBase: StylesConfig<OptionTypeBase, boolean> = {
	control: (base: React.CSSProperties) => {
		return {
			...base,
			boxShadow: '0 !important',
			'&:hover': {
				borderColor: '#FFA726',
			},
		};
	},
};

const getComponents = (inputRef: React.RefObject<HTMLInputElement>, fixed: boolean) => {
	return {
		IndicatorSeparator: null,
		DropdownIndicator: () => <span className="icon-down" />,
		ClearIndicator: (props) => {
			const {
				getStyles,
				innerProps: { ref, ...restInnerProps },
			} = props;
			return (
				<div
					{...restInnerProps}
					ref={ref}
					style={getStyles('clearIndicator', props)}
				>
					<span className="icon-close" />
				</div>
			);
		},
		Control: (props) => {
			return (
				<div ref={inputRef}>
					<components.Control
						{...props}
					/>
				</div>
			);
		},
		Menu: (props) => {
			const dropdownMenuClassName = bemBlock('react-select-dropdown', {
				'scroll-container': true,
				'full-width': true,
				fixed: fixed,
			});
			return (
				<components.Menu
					{...props}
					className={dropdownMenuClassName}
				/>
			);
		},
	};
};

const defaultFormatCreateLabel = (input: string) => `Create: ${input}`;

const getContainerClassName = (containerClassName: string | undefined, addonComponent: (() => JSX.Element | string) | undefined) => {
	const containerClass = containerClassName ? containerClassName : '';
	const addonClass = addonComponent ? 'react-select-container--with-addon' : '';
	return `react-select-container ${containerClass} ${addonClass}`;
};

const getSelectClassName = (additionalClassName: string | undefined, touched: boolean, error: string) => {
	const className = additionalClassName ? additionalClassName : '';
	const errorClass = (touched && error) ? 'react-select-field--error' : '';
	return `react-select-field ${className ? className : ''} ${errorClass}`;
};

type SelectField<OptionType extends OptionTypeBase> = React.FC<Props<OptionType>>;

const SelectField = <OptionType extends OptionTypeBase,>(props: Props<OptionType>) => {
	const {
		addonComponent,
		allowNew = false,
		className,
		containerClassName,
		containerId,
		disableErrorMessage = false,
		filterOption,
		fixed = false,
		formatCreateLabel,
		formatOptionLabel,
		formatSelectedOption,
		getNewOptionData,
		getOptionLabel,
		getOptionValue,
		input,
		isDisabled = false,
		isValidNewOption,
		label,
		meta: { error, warning, touched },
		onBlur,
		onClear,
		onCreateNew,
		onFocus,
		onLazyLoad,
		onValueChange,
		options = [],
		placeholder,
		tooltipMessage,
		valueKey,
	} = props;
	const { value } = input;

	const inputRef = React.useRef<HTMLInputElement>(null);

	const [isLazyLoading, setIsLazyLoading] = React.useState(false);
	const [menuStyle, setMenuStyle] = React.useState<React.CSSProperties>(fixedMenuStyle);

	const updateMenuPosition = React.useCallback(() => {
		const {
			x: inputPositionX = 0,
			bottom: inputBottom = 0,
			width: inputWidth = 0,
		} = inputRef.current?.getClientRects()?.[0] ?? {};

		setMenuStyle({ ...menuStyle, top: inputBottom, left: inputPositionX, width: inputWidth, bottom: 'auto' });
	}, [menuStyle, setMenuStyle]);

	React.useEffect(() => {
		if (isLazyLoading) {
			const isLazyLoaded = !!options?.length;
			onLazyLoad?.(isLazyLoaded);
			setIsLazyLoading(false);
		}
	}, [isLazyLoading, onLazyLoad, setIsLazyLoading, options]);

	React.useEffect(() => {
		fixed && window.addEventListener('resize', updateMenuPosition);
		return () => {
			fixed && window.removeEventListener('resize', updateMenuPosition);
		};
	}, [updateMenuPosition, fixed]);

	const customStyles: StylesConfig<OptionTypeBase, boolean> = React.useMemo(() => {
		if (!fixed) {
			return customStylesBase;
		}
		return {
			...customStylesBase,
			menu: (base: React.CSSProperties) => {
				return {
					...base,
					...menuStyle,
				};
			},
		};
	}, [fixed, menuStyle]);

	const onChange = React.useCallback((item: ValueType<OptionType, boolean>, { action }) => {
		const selected = valueKey && item ? item[valueKey] : item;
		input.onChange(selected);
		switch (action) {
			case 'clear':
				if (onClear) {
					onClear(item);
					break;
				}
			case 'select-option':
				if (onValueChange) {
					onValueChange(item);
				}
				break;
		}
	}, [valueKey, input, onClear, onValueChange]);

	const handleBlur = React.useCallback((event: React.FocusEvent<HTMLElement>) => {
		input.onBlur(undefined);
		onBlur?.(event);
	}, [onBlur, input]);

	const handleFocus = React.useCallback((event: React.FocusEvent<HTMLElement>) => {
		input.onFocus(event);
		onFocus?.(event);
		if (onLazyLoad && !isLazyLoading) {
			setIsLazyLoading(true);
		}
		if (fixed) {
			updateMenuPosition();
		}
	}, [onFocus, onLazyLoad, setIsLazyLoading, fixed, isLazyLoading, updateMenuPosition, input]);

	const handleFormatOptionLabel = React.useCallback((
		option: OptionType & { __isNew__?: boolean; },
		labelMeta: FormatOptionLabelMeta<OptionType, boolean>
	) => {
		if (option.__isNew__) {
			if (!allowNew) {
				return <div>No options</div>;
			}
			const formatCreate = formatCreateLabel ?? defaultFormatCreateLabel;
			return <span>{formatCreate(labelMeta.inputValue)}</span>;
		} else if (formatSelectedOption && !!Object.keys(labelMeta.selectValue ?? {}).length) {
			return formatSelectedOption(option);
		} else {
			if (formatOptionLabel) {
				return formatOptionLabel(option, labelMeta);
			} else if (getOptionLabel) {
				return <div>{getOptionLabel(option)}</div>;
			}
		}
	}, [allowNew, formatOptionLabel, getOptionLabel, formatCreateLabel, formatSelectedOption]);

	const creatableProps: CreatableProps<OptionType, boolean> = React.useMemo(() => {
		return allowNew
			? {
				onCreateOption: onCreateNew,
				isValidNewOption,
				getNewOptionData,
			}
			: {};
	}, [allowNew, onCreateNew, isValidNewOption, getNewOptionData]);

	const containerClass = getContainerClassName(containerClassName, addonComponent);
	const selectClass = getSelectClassName(className, touched, error);
	const memoizedComponents = React.useMemo(() => getComponents(inputRef, fixed), [inputRef, fixed]);
	const newValue = valueKey && value ? { [valueKey]: value, label: value } : value;

	return (
		<FormGroup className={containerClass}>
			{label &&
				<Label label={label} tooltipMessage={tooltipMessage} withMargin={true} />
			}
			<CreatableSelect
				{...creatableProps}
				className={selectClass}
				components={memoizedComponents}
				filterOption={filterOption}
				formatOptionLabel={handleFormatOptionLabel}
				getOptionLabel={getOptionLabel}
				getOptionValue={getOptionValue}
				id={containerId}
				isClearable={!isDisabled}
				isDisabled={isDisabled}
				isLoading={isLazyLoading}
				isSearchable={!isDisabled}
				onBlur={handleBlur}
				onChange={onChange}
				onFocus={handleFocus}
				options={options}
				placeholder={placeholder}
				styles={customStyles}
				theme={getTheme}
				value={newValue}
			/>
			{addonComponent?.()}
			<FormControl.Feedback />
			{(touched && !disableErrorMessage) &&
				(
					(error && <span className="help-block"><span className="icon-info" /> {error}</span>) ||
					(warning && <span className="help-block text-orange"><span className="icon-info" /> {warning}</span>)
				)
			}
		</FormGroup>
	);
};

export default SelectField;
