import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';

import { ALLOWED_IMAGE_TYPES, ALLOWED_IMAGE_TYPES_STR } from 'acceligent-shared/enums/fileType';

import * as CompanyActions from 'af-actions/companies';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import ImageTag from 'af-components/Image';
import Tooltip from 'af-components/Tooltip';

import Checkbox from 'af-components/Controls/Checkbox';

import { CompanyPrintoutRM } from 'ab-requestModels/company.requestModel';

import { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';

interface OwnProps {
	isVisible: boolean;
	company: Nullable<CompanyViewModel>;
	toggleEdit: () => void;
}

interface DispatchProps {
	editPrintoutHeader: typeof CompanyActions.editPrintoutHeader;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	enabled: boolean;
	image: string | File | null;
	imageUrl: Nullable<string>;
	uploadError: Nullable<string>;
	submitting: boolean;
}

class EquipmentStatusSettingsEdit extends React.PureComponent<Props, State> {

	state: State = {
		image: null,
		imageUrl: null,
		uploadError: null,
		enabled: false,
		submitting: false,
	};

	private _inputElement: Nullable<React.ClassAttributes<HTMLInputElement>['ref'] & { click: () => void; }> = null;
	// 1866 × 274
	componentDidUpdate(prevProps: Props) {
		const { isVisible, company } = this.props;

		if (isVisible && !prevProps.isVisible && company) {
			this.setState(() => ({
				enabled: !!company.customPDFHeaderUrl,
				image: company.customPDFHeaderUrl,
				imageUrl: company.customPDFHeaderUrl,
			}));
		}
	}

	handleChange = () => this.setState((state) => ({ enabled: !state.enabled }));
	onImageUploadSave = (image: File, imageUrl: string) => this.setState(() => ({ image, imageUrl }));

	onInputClick = () => {
		this.setState(() => ({ uploadError: null }));
		this._inputElement?.click();
	};

	onMount = (element) => this._inputElement = element;

	changeImage = (event) => {
		const [file] = event.target.files as File[];

		if (file) {
			if (ALLOWED_IMAGE_TYPES.includes(file.type)) {
				const reader = new FileReader();
				reader.onloadend = () => {
					const _result = reader.result as string;
					this.setState(() => ({
						image: file,
						imageUrl: _result,
					}));
				};
				reader.readAsDataURL(file);
			} else {
				this.setState(() => ({ uploadError: `Invalid file format. Only ${ALLOWED_IMAGE_TYPES_STR} allowed.` }));
			}
		}
	};

	submit = async () => {
		const { toggleEdit, editPrintoutHeader } = this.props;
		const { image, enabled } = this.state;
		const form: CompanyPrintoutRM = {
			enableCustomPDFHeader: enabled,
			customPDFHeader: enabled ? image : null,
		};
		this.setState(() => ({ submitting: true }), async () => {
			await editPrintoutHeader(form);
			this.setState(() => ({ submitting: false }));
			toggleEdit();
		});
	};

	render() {
		const { isVisible, toggleEdit } = this.props;
		const { imageUrl, enabled, submitting } = this.state;
		const invalid = enabled && !imageUrl;

		return (
			<CustomModal
				closeModal={toggleEdit}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={toggleEdit}
					title="Edit Printout Settings"
				/>
				<CustomModal.Body padding="none">
					<Row className="row--padded-bottom">
						<Col sm={24}>
							<Checkbox
								handleChange={this.handleChange}
								isChecked={enabled}
								label={CompanyFeatureFlagsEnum.customPDFHeader}
								tooltipMessage={CompanyFeatureFlagsTips.customPDFHeader}
							/>
						</Col>
						{enabled &&
							<>
								<Col sm={24}>
									<ImageTag
										className="company-settings__printout"
										src={imageUrl}
									/>
								</Col>
								<Col sm={24}>
									Suggested image size is 1980 × 198.
								</Col>
								<Col className="image-upload__buttons" sm={21}>
									<Tooltip message="Upload">
										<Button
											className="image-upload__button"
											disabled={!enabled}
											onClick={this.onInputClick}
											variant="info"
										>
											<strong className="icon-upload" />
										</Button>
									</Tooltip>
									<input accept={ALLOWED_IMAGE_TYPES} className="display-none" onChange={this.changeImage} ref={this.onMount} type="file" />
								</Col>
							</>
						}
					</Row>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={toggleEdit}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={this.submit}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		editPrintoutHeader: CompanyActions.editPrintoutHeader,
	};
}

const connector = connect<void, DispatchProps>(null, mapDispatchToProps());

export default connector(EquipmentStatusSettingsEdit);
