import Priority from 'acceligent-shared/enums/priority';
import CountryCode from 'acceligent-shared/enums/countryCode';
import { ColorPalette } from 'acceligent-shared/enums/color';

import Employee from 'acceligent-shared/models/employee';
import Equipment from 'acceligent-shared/models/equipment';
import { SkillColorGrid } from 'acceligent-shared/models/company';
import WorkOrder from 'acceligent-shared/models/workOrder';

import { WORK_ORDERS_IN_EMAIL_ROW, RESOURCES_IN_EMAIL_ROW } from 'ab-constants/notifications';

import { UpdatedByAccountViewModel, UpdatedByViewModel } from 'acceligent-shared/dtos/web/view/updatedBy';

import { AdditionalColors, ColorHex } from 'acceligent-shared/enums/color';
import ResourceStatus from 'acceligent-shared/enums/resourceStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeOptionUtils from 'acceligent-shared/utils/timeOption';
import { getShortAddress } from 'acceligent-shared/utils/address';
import * as TimeUtils from 'acceligent-shared/utils/time';
import * as ScheduleBoardSharedUtils from 'acceligent-shared/utils/scheduleBoard';
import * as UserUtil from 'acceligent-shared/utils/user';
import { formatPhoneNumber } from 'acceligent-shared/utils/phone';

import CDLStatus from 'acceligent-shared/enums/cdlStatus';
import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';
import SubscriptionStatus from 'acceligent-shared/enums/subscriptionStatus';

import Skill from 'acceligent-shared/models/skill';
import User from 'acceligent-shared/models/user';
import Account from 'acceligent-shared/models/account';
import Location from 'acceligent-shared/models/location';
import WageRate from 'acceligent-shared/models/wageRate';
import EquipmentCost from 'acceligent-shared/models/equipmentCost';
import EquipmentCostCategory from 'acceligent-shared/models/equipmentCostCategory';
import WorkOrderResourceLookup from 'acceligent-shared/models/workOrderResourceLookup';

import { UNKNOWN_LOCATION_NICKNAME } from 'ab-constants/value';
import { AVAILABLE_EQUIPMENT_STATUS } from 'ab-constants/equipment';
import { AVAILABLE_EMPLOYEE_STATUS } from 'ab-constants/employee';
import { SCHEDULE_BOARD_TOOLBAR_AVAILABLE } from 'ab-constants/scheduleBoard';

import * as CodeUtils from 'ab-utils/codes.util';
import * as ColorUtil from 'ab-utils/color.util';
import * as ScheduleBoardUtil from 'ab-utils/scheduleBoard.util';
import { addToArrayIfExists } from 'ab-utils/array.util';

import { DailyEmployeeStatusServiceModel } from 'ab-serviceModels/dailyEmployeeStatus.serviceModel';

// INTERFACES & TYPES:

type EquipmentAssignments = { [equipmentId: number]: string[]; }; // [equipmentId]: workOrderCode[]
type TemporaryEmployeeAssignmentsVM = { [temporaryEmployeeId: number]: string[]; }; // [temporaryEmployeeId]: workOrderCode[]
type EmployeeAssignments = { [employeeId: number]: string[]; }; // [employeeId]: workOrderCode[]

type ScheduleBoardEmployeesViewModel = { [employeeId: number]: ScheduleBoardEmployee; };

interface EquipmentUnavailabilityDetails {
	priority?: Nullable<Priority>;
	notes?: Nullable<string>;
	updatedAt?: Nullable<Date>;
	updatedById?: Nullable<number>;
	updatedByFirstName?: Nullable<string>;
	updatedByLastName?: Nullable<string>;
	updatedByLocationId?: Nullable<number>;
	/** YYYY-MM-DD */
	returnDate?: Nullable<string>;
	unavailabilityReasonId?: Nullable<number>;
	unavailabilityReason?: Nullable<string>;
	equipmentStatusId?: Nullable<number>;
	isEquipmentDeleted?: boolean;
}

// FIXME: this is actually daily equipment with a lot of unnecessary information. Clean up
interface DailyEquipmentStatusViewModel extends EquipmentUnavailabilityDetails {
	id: number;
	equipmentId: number;
	companyId: number;
	/** YYYY-MM-DD */
	date: string;
	equipmentStatusId: number;
	returnDate: string;
	unavailabilityReasonId: number;
	equipmentStatus: string;
	available: boolean;
	unavailabilityReason: string;
	isValid: boolean;
	/** YYYY-MM-DD */
	dateValue: string;
	priority: Priority;
	notes: string;
	updatedAt: Date;
	updatedById: Nullable<number>;
	updatedByFirstName: string;
	updatedByLastName: string;
	updatedByLocationId: number;
}

interface IndividualLaborStatisticsParsed {
	officeNickname: string;
	color: string;
	assignedLaborCount: number;
	totalLaborCount: number;
	crewsCount: number;
	totalRevenue: number;
	revenuePerPerson: number;
	showRevenue: boolean;
	hidden: boolean;
}

interface ScheduleBoardLaborStatistics {
	laborStatisticsPerLocation: IndividualLaborStatisticsParsed[];
	totalLaborStatistics: IndividualLaborStatisticsParsed;
}

interface AddressVM {
	id: number;
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	stateAbbreviation: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	shortAddress: string;
	latitude: number;
	longitude: number;
}

interface LocationViewModel {
	id: number;
	nickname: string;
	fax: string;
	phone: string;
	faxCountryCode: CountryCode;
	phoneCountryCode: CountryCode;
	color: ColorPalette;
	index: number;
	showInStatistics: boolean;
	address: AddressVM | undefined;
	isDeleted: boolean;
}

interface ColorItem {
	hasColor: boolean;
	color?: string;
}

type EquipmentModel = {
	code: string;
	specification: Nullable<string>;
	color: string;
	assignmentCount?: number;
	showAssignmentCount?: boolean;
	isDowned: boolean;
	toolbar?: boolean;
	isEquipment?: boolean;
	isAvailable: boolean;
};

type TemporaryEmployeeModel = {
	temporaryEmployeeId: number;
	fullName: string;
	color: ColorPalette;
	assignmentCount?: number;
	showAssignmentCount?: boolean;
	isTemporaryEmployee?: boolean;
};

type SkillModel = Partial<{
	orange: boolean;
	green: boolean;
	blue: boolean;
	red: boolean;
	turquoise: boolean;
	violet: boolean;
	purple: boolean;
	pink: boolean;
}>;

interface PlaceholderModel {
	text: string;
	skills?: ColorItem[][];
	showSkills?: boolean;
	showColorSquare?: boolean;
	color?: string;
	isPlaceholder: boolean;
}

type ResourceModel = EmployeeModel | EquipmentModel | TemporaryEmployeeModel | PlaceholderModel;

interface ResourceGroup<T> {
	title?: string;
	showTitle: boolean;
	items: T[];
}

type ToolbarResourceUnparsed<T> = { [title: string]: ResourceGroupUnparsed<T>[]; };

type ResourceGroupUnparsed<T> = T & { title: string; };

type EmployeeDisplayNamesDict = { [displayName: string]: number[]; };

type EquipmentColorMap = { [equipmentId: number]: Nullable<ColorPalette>; };

interface ScheduleBoardToolbar {
	labor: ResourceGroup<EmployeeModel>[][];
	equipment: ResourceGroup<EquipmentModel>[][];
}

interface EmployeeStatusMap {
	[employeeId: number]: DailyEmployeeStatusServiceModel;
}

// PRIVATE FUNCTIONS:

function skillsToSkillTypes(skills: Skill[], skillColorGrid: SkillColorGrid): ColorItem[][] {
	const skillTypes: SkillModel = {};
	for (const _skill of skills) {
		skillTypes[_skill.color.toLowerCase()] = true;
	}
	return skillColorGrid.map((_colorRow) =>
		_colorRow.map((_color) =>
			skills.some((_skill) =>
				_skill.color === _color)
				? { hasColor: true, color: ColorHex[_color] }
				: { hasColor: false }
		)
	);
}

function getFullName(user: Nullable<User>, employeeDisplayNamesDict: EmployeeDisplayNamesDict, defaultValue: string = ''): string {
	let fullName = UserUtil.getUserName(user, true, defaultValue);
	if (employeeDisplayNamesDict[fullName] && employeeDisplayNamesDict[fullName].length > 1) {
		fullName = UserUtil.getUserName(user, true, defaultValue, 2);
	}
	return fullName;
}

function groupWorkOrders(workOrders: ScheduleBoardWorkOrder[], workOrdersInRow: number): ScheduleBoardWorkOrder[][] {
	return workOrders.reduce((_acc: ScheduleBoardWorkOrder[][], _wo: ScheduleBoardWorkOrder) => {
		const index = _acc.length - 1;
		if (_acc[index].length === workOrdersInRow) {
			_acc.push([_wo]);
		} else {
			_acc[index].push(_wo);
		}
		return _acc;
	}, [[]]);
}

function groupResourceGroups<T>(resources: ResourceGroup<T>[], resourcesInRow: number): ResourceGroup<T>[][] {
	return resources.reduce((_acc: ResourceGroup<T>[][], item: ResourceGroup<T>) => {
		const index = _acc.length - 1;
		if (_acc[index].length === resourcesInRow) {
			_acc.push([item]);
		} else {
			_acc[index].push(item);
		}
		return _acc;
	}, [[]]);
}

function getGroupTitle(key: string, availability: string): string {
	return `${key} (${availability})`;
}

function groupResources<T>(resources: ResourceGroupUnparsed<T>[], resourceGroupSize: number): ResourceGroup<T>[] {
	return resources.reduce((_acc: ResourceGroup<T>[], item: ResourceGroupUnparsed<T>) => {
		if (!_acc.length) {
			_acc.push({ title: item.title, showTitle: true, items: [item] });
		} else {
			const index = _acc.length - 1;
			if (_acc[index].items.length === resourceGroupSize) {
				_acc.push({ showTitle: false, items: [item] });
			} else {
				_acc[index].items.push(item);
			}
		}
		return _acc;
	}, []);
}

function parseToolbarResources<T>(resourcesDict: ToolbarResourceUnparsed<T>, resourceGroupSize: number): ResourceGroup<T>[] {
	return Object.values(resourcesDict).reduce((acc: ResourceGroup<T>[], items) => {
		const group = groupResources(items, resourceGroupSize);
		acc.push(...group);
		return acc;
	}, []);
}

// PUBLIC FUNCTIONS & CLASSES:

class EquipmentCostCategoryVM {
	name: string;
	categoryColor: Nullable<ColorPalette>;
	groupId: Nullable<number>;
	type: string; // level 1
	group: Nullable<EquipmentCostCategoryVM>; // level 2 and level 3
	equipmentCostsCount: number;

	constructor(ecc: EquipmentCostCategory) {
		this.categoryColor = ecc.categoryColor;
		this.groupId = ecc.groupId;
		this.group = ecc.group && new EquipmentCostCategoryVM(ecc.group);
		this.equipmentCostsCount = ecc.equipmentCosts?.length ?? 0;
		this.type = ecc.type;
		this.name = ecc.name;
	}
}

class EquipmentCostViewModel {
	id: number;
	type: Nullable<string>;
	categoryName: string;
	categoryId: number;
	categoryColor: Nullable<ColorPalette>;
	subcategory: string;
	groupName: Nullable<string>;
	category: EquipmentCostCategoryVM | undefined;
	group: Nullable<EquipmentCostCategoryVM>;
	image: Nullable<string>;
	licenses: Nullable<string>;
	mobCharge: string;
	dailyCost: string;
	weeklyCost: string;
	monthlyCost: string;
	operatingCharge: string;	// per hour
	fuelCost: string;	// per hour
	updatedById: Nullable<number>;
	updatedBy: UpdatedByViewModel;
	updatedAt: Date;
	skills: SkillVM[];

	// TODO: stop importing this in other view and request models. Then prevent equipment cost entering this constructor from being nullish
	constructor(equipmentCost: EquipmentCost) {
		const _equipmentCostCategory = equipmentCost?.category;
		this.id = equipmentCost?.id;
		this.group = _equipmentCostCategory?.group ? new EquipmentCostCategoryVM(_equipmentCostCategory.group) : null;
		this.category = _equipmentCostCategory && new EquipmentCostCategoryVM(equipmentCost.category);
		this.type = _equipmentCostCategory?.group?.type ?? null;
		this.groupName = _equipmentCostCategory?.group?.name ?? null;
		this.categoryName = _equipmentCostCategory?.name;
		this.categoryId = _equipmentCostCategory?.id;
		this.categoryColor = _equipmentCostCategory?.categoryColor ?? null;
		this.subcategory = equipmentCost?.subcategory;
		this.image = equipmentCost?.imageUrl;
		this.licenses = equipmentCost?.licenses;
		this.mobCharge = equipmentCost?.mobCharge;
		this.dailyCost = equipmentCost?.dailyCost;
		this.weeklyCost = equipmentCost?.weeklyCost;
		this.monthlyCost = equipmentCost?.monthlyCost;
		this.operatingCharge = equipmentCost?.operatingCharge;
		this.fuelCost = equipmentCost?.fuelCost;
		this.updatedById = equipmentCost?.updatedById;
		this.updatedBy = new UpdatedByAccountViewModel(equipmentCost.updatedBy);
		this.updatedAt = equipmentCost?.updatedAt;

		if (equipmentCost.skills?.length) {
			this.skills = equipmentCost.skills
				.map((_skill) => new SkillVM(_skill));
		} else if (equipmentCost.equipmentCostSkills?.length) {
			this.skills = equipmentCost.equipmentCostSkills
				.filter((_eqCostSkill) => !!_eqCostSkill.skill)
				.map((_eqCostSkill) => new SkillVM(_eqCostSkill.skill));
		} else {
			this.skills = [];
		}
	}
}

class WageRateVM {
	id: number;
	type: string;
	wageClassification: string;
	hourlyRate: number;
	updatedById: Nullable<number>;
	updatedBy: Nullable<UpdatedByViewModel>;
	createdAt: Date;
	updatedAt: Date;

	constructor(wageRate: WageRate) {
		this.id = wageRate.id;
		this.type = wageRate.type;
		this.wageClassification = wageRate.wageClassification;
		this.hourlyRate = +wageRate.hourlyRate;
		this.updatedById = wageRate.updatedById;
		this.updatedBy = wageRate.updatedBy ? new UpdatedByAccountViewModel(wageRate.updatedBy) : null;
		this.createdAt = wageRate.createdAt;
		this.updatedAt = wageRate.updatedAt;
	}
}

class ScheduleBoardWorkOrderResourceLookupViewModel {
	id: number;
	/** resource lookup index */
	index: number;

	/** only for Employee */
	workOrderEmployeeId?: number;
	/** only for Employee */
	employeeId?: number;
	/** only for Employee */
	perDiem?: boolean;

	/** only for Equipment */
	workOrderEquipmentId?: number;
	/** only for Equipment */
	equipmentId?: number;

	/** only for Temporary employee */
	workOrderTemporaryEmployeeId?: number;
	/** only for Temporary employee */
	temporaryEmployeeId?: number;

	/** only for Placeholder */
	workOrderPlaceholderId?: number;
	/** only for Employee Placeholder */
	wageRateId?: number;
	/** only for Employee Placeholder */
	wageRate?: WageRateVM;
	/** only for Equipment Placeholder */
	equipmentCostId?: number;
	/** only for Equipment Placeholder */
	equipmentCost?: EquipmentCostViewModel;
	/** only for Placeholder */
	skills?: SkillVM[];

	isDisabled: boolean;

	/** if Work Order data passed to constructor */
	workOrderCode?: string;

	constructor(woResourceLookup: WorkOrderResourceLookup, workOrderCode?: string) {
		this.id = woResourceLookup.id;
		this.index = woResourceLookup.index;

		this.workOrderEmployeeId = woResourceLookup.workOrderEmployeeId ?? undefined;
		this.employeeId = woResourceLookup.workOrderEmployee?.employeeId;
		this.perDiem = woResourceLookup.workOrderEmployee?.perDiem;

		this.workOrderEquipmentId = woResourceLookup.workOrderEquipmentId ?? undefined;
		this.equipmentId = woResourceLookup.workOrderEquipment?.equipmentId;

		this.workOrderPlaceholderId = woResourceLookup.workOrderPlaceholderId ?? undefined;
		this.wageRateId = woResourceLookup.workOrderPlaceholder?.wageRateId ?? undefined;
		this.wageRate = woResourceLookup.workOrderPlaceholder?.wageRate
			? new WageRateVM(woResourceLookup.workOrderPlaceholder.wageRate)
			: undefined;
		this.equipmentCostId = woResourceLookup.workOrderPlaceholder?.equipmentCostId ?? undefined;
		this.equipmentCost = woResourceLookup.workOrderPlaceholder?.equipmentCost
			? new EquipmentCostViewModel(woResourceLookup.workOrderPlaceholder.equipmentCost)
			: undefined;
		this.skills = woResourceLookup.workOrderPlaceholder?.skills
			? SkillVM.bulkConstructor(woResourceLookup.workOrderPlaceholder.skills)
			: undefined;

		this.workOrderTemporaryEmployeeId = woResourceLookup.workOrderTemporaryEmployeeId ?? undefined;
		this.temporaryEmployeeId = woResourceLookup.workOrderTemporaryEmployee?.temporaryEmployeeId;

		this.isDisabled = false;

		if (workOrderCode) {
			this.workOrderCode = workOrderCode;
		} else if (!!woResourceLookup.workOrder) {
			const _workRequest = woResourceLookup.workOrder?.workRequest;
			this.workOrderCode = _workRequest ? CodeUtils.workOrderCode(woResourceLookup.workOrder, _workRequest) : '';
		}
	}
}

type ScheduleBoardWorkOrderResourceLookupsViewModel = { [workOrderResourceLookupId: number]: ScheduleBoardWorkOrderResourceLookupViewModel; };

class SkillVM {
	id: number;
	name: string;
	color: ColorPalette;

	constructor(skill: Skill) {
		this.id = skill.id;
		this.name = skill.name;
		this.color = skill.color;
	}

	static bulkConstructor = (skills: Skill[]) => skills.map(SkillVM._constructorMap);
	private static _constructorMap = (_skill: Skill) => new SkillVM(_skill);
}

interface UserVM {
	id: number;
	fullName: string;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
}

class AccountVM {
	id: number;
	fullName: string;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	user: UserVM;

	constructor(account: Account) {
		this.id = account.id;
		this.fullName = UserUtil.getUserName(account.user);
		this.assignableToWorkOrder = account.assignableToWorkOrder;
		this.assignableAsSuperintendent = account.assignableAsSuperintendent;
		this.assignableAsProjectManager = account.assignableAsProjectManager;
		this.assignableAsQAQC = account.assignableAsQAQC;
		this.user = {
			id: account.user.id,
			fullName: UserUtil.getUserName(account.user),
			email: account.user.email,
			phoneNumber: account.user.phoneNumber ? formatPhoneNumber(account.user.phoneNumber) : null,
		};
	}
}

class ScheduleBoardOfficeVM {
	id: number;
	nickname: string;
	color: ColorPalette;
	index: number;

	constructor(location: Location) {
		this.id = location.id;
		this.nickname = location.nickname;
		this.color = location.color;
		this.index = location.index;
	}
}

class ScheduleBoardEmployee {
	id: number;
	firstName: string;
	lastName: string;
	skills: SkillVM[];
	office: Nullable<ScheduleBoardOfficeVM>;
	isDisabled: boolean; // do not need to initialize them on the server side
	isFilteredOnBoard: boolean; // do not need to initialize them on the server side
	isFilteredOnToolbar: boolean; // do not need to initialize them on the server side
	isMatched?: boolean;
	currentLocation: string;
	isDeleted: boolean;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	account: AccountVM;
	emailInvalidatedAt: Nullable<Date>;
	phoneInvalidatedAt: Nullable<Date>;
	/** subscription status, name stripped in order to save lots of bytes on sch.board */
	subscriptionStatus: SubscriptionStatus;
	wageRate: string;
	showOnScheduleBoard: boolean;
	cdlStatus: CDLStatus;

	duplicateDisplayNameExists?: boolean;

	_desc: string;

	constructor(employee: Employee) {
		const _employeeUser = employee?.account?.user;

		this.id = employee.id;
		this.firstName = _employeeUser?.firstName;
		this.lastName = _employeeUser?.lastName;

		this.skills = employee.skills ? SkillVM.bulkConstructor(employee.skills) : [];
		this.office = employee?.account?.location && new ScheduleBoardOfficeVM(employee.account.location);
		this.wageRate = employee?.wageRate?.type;
		this.isDeleted = !!employee.isDeleted;

		this.email = _employeeUser?.email;
		this.phoneNumber = _employeeUser?.phoneNumber;
		this.account = employee?.account && new AccountVM(employee.account);
		this.emailInvalidatedAt = _employeeUser?.emailInvalidatedAt;
		this.phoneInvalidatedAt = _employeeUser?.phoneInvalidatedAt;
		this.subscriptionStatus = _employeeUser?.subscriptionStatus;
		// variations of fullName
		const searchableLiterals = [`${this.firstName} ${this.lastName}`];

		addToArrayIfExists(searchableLiterals, employee?.wageRate?.type);
		addToArrayIfExists(searchableLiterals, employee?.wageRate?.wageClassification);

		this._desc = searchableLiterals.join(' ');
		this.isMatched = false;
		this.showOnScheduleBoard = employee.showOnScheduleBoard;
		this.cdlStatus = employee.cdlStatus;
	}
}

export class ScheduleBoardWorkOrder {
	index: number;
	status: string;
	crewTypeColor: string;
	isCanceled: boolean;
	isDraft: boolean;
	isPublished: boolean;
	isOutdated: boolean;
	isBlank: boolean;
	isPaused: boolean;
	isLocked: boolean;

	isInternal: boolean;
	customCrewType: Nullable<string>;
	shift: string;
	workStart: string;
	workEnd: string;

	customer: string;
	title: Nullable<string>;
	address: string;

	showRevenue: boolean;
	revenue: Nullable<string>;
	totalHours: number;

	officeNickname: string;
	officeColor: string | undefined;
	revision: string;
	codeStripped: string;
	code: string;

	superintendent: string;
	projectManager: string;

	notificationsReceivedColor: string;

	resources: ResourceModel[];

	notes: Nullable<string>;

	constructor(
		workOrder: WorkOrder,
		equipmentColorMap: EquipmentColorMap,
		employeeAssignments: EmployeeAssignments,
		equipmentAssignments: EquipmentAssignments,
		temporaryEmployeeAssignments: TemporaryEmployeeAssignmentsVM,
		employeeDisplayNamesDict: EmployeeDisplayNamesDict,
		showRevenue: boolean,
		skillColorGrid: SkillColorGrid,
		employeeStatusMap: EmployeeStatusMap
	) {
		const _workRequest = workOrder.workRequest;
		if (workOrder.index === null) {
			throw new Error('Work Order doesn\'t have an index');
		}

		this.index = workOrder.index;
		this.status = workOrder.status;
		this.isBlank = false;
		this.crewTypeColor = ColorUtil.getColorBackgroundClass(workOrder.crewType?.color ?? AdditionalColors.BLACK);
		this.isCanceled = workOrder.status === WorkOrderStatusEnum.CANCELED;
		this.isDraft = workOrder.status === WorkOrderStatusEnum.DRAFT;
		this.isPublished = workOrder.status === WorkOrderStatusEnum.PUBLISHED;
		this.isOutdated = workOrder.status === WorkOrderStatusEnum.OUTDATED;
		this.isPaused = workOrder.isPaused;
		this.isLocked = workOrder.status === WorkOrderStatusEnum.LOCKED;

		this.isInternal = workOrder.isInternal;
		this.customCrewType = workOrder.customCrewType;
		this.shift = workOrder.shift?.name ?? 'N/A';
		this.workStart = TimeOptionUtils.format(workOrder.timeToStart);
		this.workEnd = TimeOptionUtils.format(workOrder.timeToEnd);

		this.customer = _workRequest.customerCompanyName ?? workOrder.workRequest?.customerContact?.contact?.companyName ?? '';
		this.title = _workRequest?.title;
		this.address = getShortAddress(_workRequest?.travelLocation);

		this.showRevenue = showRevenue;
		this.revenue = workOrder.revenue;
		const _jobHours = workOrder.jobHours ?? 0;
		const _shopHours = workOrder.shopHours ?? 0;
		const _travelHours = workOrder.travelHours ?? 0;
		this.totalHours = _jobHours + _shopHours + _travelHours;

		const _office = _workRequest?.office;
		this.officeNickname = _office?.nickname ?? UNKNOWN_LOCATION_NICKNAME;
		this.officeColor = _office?.color;
		this.revision = CodeUtils.revisionCode(workOrder.revision);
		this.code = CodeUtils.workOrderCode(workOrder, workOrder.workRequest);
		this.codeStripped = CodeUtils.workOrderCodeStripped(workOrder, workOrder.workRequest);

		this.superintendent = getFullName(workOrder.supervisor?.account?.user ?? null, employeeDisplayNamesDict, 'N/A');
		this.projectManager = getFullName(workOrder.projectManager?.account?.user ?? null, employeeDisplayNamesDict, 'N/A');

		this.notificationsReceivedColor = ScheduleBoardUtil.getRevisionBackgroundColorClass(this.revision);

		this.resources = workOrder.workOrderResourceLookups.map((_woResourceLookup) => {
			if (_woResourceLookup.workOrderEmployeeId && _woResourceLookup.workOrderEmployee) {
				const _employee = _woResourceLookup.workOrderEmployee.employee;
				const _account = _employee.account;
				const assignmentCount = !this.isCanceled && (employeeAssignments[_employee?.id] || []).length || 0;
				const _isAvailable = employeeStatusMap[_employee?.id]?.available ?? true;
				return {
					employeeId: _employee?.id,
					fullName: getFullName(_account?.user, employeeDisplayNamesDict),
					color: ColorUtil.getColorTextClass(_account?.location?.color),
					isSuperintendentAndProjectManager: _account?.assignableAsSuperintendent && _account?.assignableAsProjectManager,
					isSuperintendent: _account?.assignableAsSuperintendent,
					isProjectManager: _account?.assignableAsProjectManager,
					perDiem: _woResourceLookup?.workOrderEmployee?.perDiem ?? false,
					assignmentCount,
					showAssignmentCount: assignmentCount > 1,
					isEmployee: true,
					showSkills: !!_employee?.skills?.length,
					skills: skillsToSkillTypes(_employee?.skills ?? [], skillColorGrid),
					noCDL: _employee?.cdlStatus === CDLStatus.NO_CDL,
					isDowned: !_isAvailable,
				} as EmployeeModel;
			} else if (_woResourceLookup.workOrderEquipmentId && _woResourceLookup.workOrderEquipment) {
				const _equipment = _woResourceLookup.workOrderEquipment.equipment;
				const assignmentCount = !this.isCanceled && (equipmentAssignments[_equipment?.id] || []).length || 0;
				const _dailyEquipmentStatus = ScheduleBoardSharedUtils.getEquipmentDailyStatusForDay(_equipment, workOrder.dueDate);
				const isAvailable = _dailyEquipmentStatus?.equipmentStatus?.available ?? true;
				return {
					code: `${_equipment?.code} ${_equipment?.specification ?? ''}`,
					specification: _equipment?.specification,
					color: ColorUtil.getColorBackgroundClass(equipmentColorMap[_equipment?.id]),
					assignmentCount,
					isAvailable,
					showAssignmentCount: assignmentCount > 1,
					isEquipment: true,
					isDowned: !isAvailable,
				} as EquipmentModel;
			} else if (_woResourceLookup.workOrderTemporaryEmployeeId) {
				const _temporaryEmployee = _woResourceLookup.workOrderTemporaryEmployee?.temporaryEmployee;
				const _account = _temporaryEmployee?.account;
				if (!_account) {
					throw new Error('Temporary employee incorrectly defined');
				}

				const assignmentCount = !this.isCanceled && (temporaryEmployeeAssignments[_temporaryEmployee.id] || []).length || 0;
				return {
					temporaryEmployeeId: _temporaryEmployee.id,
					fullName: getFullName(_account.user, employeeDisplayNamesDict),
					color: ColorUtil.getColorTextClass(_temporaryEmployee.agency?.color),
					assignmentCount,
					showAssignmentCount: assignmentCount > 1,
					isTemporaryEmployee: true,
				} as TemporaryEmployeeModel;
			} else {
				const _workOrderPlaceholder = _woResourceLookup?.workOrderPlaceholder;
				if (_workOrderPlaceholder?.wageRateId) {
					return {
						text: `${_workOrderPlaceholder?.wageRate?.type} (${_workOrderPlaceholder?.wageRate?.wageClassification})`,
						isPlaceholder: true,
						showSkills: !!_workOrderPlaceholder?.skills?.length,
						skills: skillsToSkillTypes(_workOrderPlaceholder?.skills, skillColorGrid),
					} as PlaceholderModel;
				} else {
					const _equipmentCost = _workOrderPlaceholder?.equipmentCost;
					return {
						text: `${_equipmentCost?.subcategory} (${_equipmentCost?.category?.name})`,
						isPlaceholder: true,
						showColorSquare: true,
						color: ColorUtil.getColorBackgroundClass(_equipmentCost?.category?.categoryColor),
					};
				}
			}
		});

		this.notes = workOrder.notes;
	}
}

class ScheduleBoardTemplateViewModel {
	workOrders: ScheduleBoardWorkOrder[][];
	toolbar: ScheduleBoardToolbar;
	laborStatistics: ScheduleBoardLaborStatistics;
	/** MM-DD-YYYY */
	dueDate?: string;

	/**
	 * @param dueDate Format: YYYY-MM-DD
	 */
	constructor(
		options: ScheduleBoardTemplateOptions,
		workOrders: WorkOrder[],
		employees: Employee[],
		equipmentList: Equipment[],
		dueDate: string | undefined,
		skillColorGrid: SkillColorGrid,
		locations: LocationViewModel[] = [],
		employeeNotificationStatuses: DailyEmployeeStatusServiceModel[] = [],
		dailyEquipmentStatuses: DailyEquipmentStatusViewModel[] = [],
		showFullStatistics: boolean = false
	) {
		this.dueDate = dueDate ? TimeUtils.formatDate(dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY) : undefined;

		const workOrderResourceLookups = workOrders.reduce((_acc: ScheduleBoardWorkOrderResourceLookupsViewModel, _workOrder: WorkOrder) => {
			if (_workOrder.status === WorkOrderStatusEnum.CANCELED) {
				return _acc;
			}
			const _workOrderCode = CodeUtils.workOrderCode(_workOrder, _workOrder.workRequest);

			for (const _workOrderResourceLookup of _workOrder.workOrderResourceLookups) {
				if (!_acc[_workOrderResourceLookup.id]) {
					_acc[_workOrderResourceLookup.id] = new ScheduleBoardWorkOrderResourceLookupViewModel(_workOrderResourceLookup, _workOrderCode);
				}
			}
			return _acc;
		}, {} as ScheduleBoardWorkOrderResourceLookupsViewModel);

		const employeeDisplayNamesDict: EmployeeDisplayNamesDict = {};
		employees.forEach((_employee) => {
			const fullName = UserUtil.getUserName(_employee?.account?.user, true);
			if (!employeeDisplayNamesDict[fullName]) {
				employeeDisplayNamesDict[fullName] = [];
			}
			employeeDisplayNamesDict[fullName].push(_employee.id);
		});

		type Assignments = {
			employeeAssignments: EmployeeAssignments;
			equipmentAssignments: EquipmentAssignments;
			temporaryEmployeeAssignments: TemporaryEmployeeAssignmentsVM;
		};
		const {
			employeeAssignments,
			equipmentAssignments,
			temporaryEmployeeAssignments,
		} = Object.values(workOrderResourceLookups).reduce(
			(_assignments: Assignments, _resourceLookup: ScheduleBoardWorkOrderResourceLookupViewModel) => {
				let targetObject: string = 'employeeAssignments';
				let targetIdPropName: string = 'employeeId';
				if (_resourceLookup.workOrderEquipmentId) {
					targetObject = 'equipmentAssignments';
					targetIdPropName = 'equipmentId';
				} else if (_resourceLookup.workOrderTemporaryEmployeeId) {
					targetObject = 'temporaryEmployeeAssignments';
					targetIdPropName = 'temporaryEmployeeId';
				}

				const targetId = _resourceLookup[targetIdPropName];
				if (!_assignments[targetObject][targetId]) {
					_assignments[targetObject][targetId] = [];
				}
				_assignments[targetObject][targetId].push(_resourceLookup.workOrderCode);
				return _assignments;
			},
			{
				employeeAssignments: {} as EmployeeAssignments,
				equipmentAssignments: {} as EquipmentAssignments,
				temporaryEmployeeAssignments: {} as TemporaryEmployeeAssignmentsVM,
			}
		);

		const equipmentColorMap: EquipmentColorMap = equipmentList.reduce((_acc, _equipment) => {
			_acc[_equipment.id] = _equipment?.equipmentCost?.category?.categoryColor;
			return _acc;
		}, {} as EquipmentColorMap);

		const employeeStatusMap = employeeNotificationStatuses.reduce((_acc, _des) => {
			_acc[_des?.employeeId] = _des;
			return _acc;
		}, {} as EmployeeStatusMap);

		// this section adds blank WOs to orders array
		const { orders } = workOrders.reduce((_acc: { orders: ScheduleBoardWorkOrder[]; lastIndex: number; }, _workOrder: WorkOrder) => {
			const _diff = _workOrder.index! - _acc.lastIndex - 1;
			const _currentLength = _acc.orders.length;
			if (_diff > 0) {
				// add $diff blanks
				for (let _index = 1; _index <= _diff; _index++) {
					_acc.orders.push({ isBlank: true, index: _currentLength + _index } as ScheduleBoardWorkOrder);
				}
			}
			_acc.orders.push(new ScheduleBoardWorkOrder(
				_workOrder,
				equipmentColorMap,
				employeeAssignments,
				equipmentAssignments,
				temporaryEmployeeAssignments,
				employeeDisplayNamesDict,
				showFullStatistics,
				skillColorGrid,
				employeeStatusMap
			));
			_acc.lastIndex = _workOrder.index!;
			return _acc;
		}, { orders: [] as ScheduleBoardWorkOrder[], lastIndex: 0 });

		this.workOrders = groupWorkOrders(orders, options.workOrdersInRow);

		const laborDict: ToolbarResourceUnparsed<EmployeeModel> = employees.reduce((_acc: ToolbarResourceUnparsed<EmployeeModel>, _employee) => {
			const _accountProxy = _employee?.account;

			const addToToolbarEmployees: boolean = _accountProxy?.assignableToWorkOrder;

			if (!!employeeAssignments[_employee.id] || !!_employee.isDeleted || !addToToolbarEmployees || !_employee.showOnScheduleBoard) {
				return _acc;
			}
			const _status = employeeStatusMap[_employee?.id];
			const _isAvailable = _status?.available ?? true;
			const _availability = ScheduleBoardUtil.getAvailabilityLabel(_status?.available ?? true);
			const _employeeStatus = _status?.employeeStatus ?? AVAILABLE_EMPLOYEE_STATUS;
			const title = getGroupTitle(_employeeStatus, _availability);

			const item = {
				title,
				employeeId: _employee?.id,
				fullName: getFullName(_accountProxy?.user, employeeDisplayNamesDict),
				color: ColorUtil.getColorTextClass(_accountProxy?.location?.color),
				isSuperintendentAndProjectManager: _accountProxy?.assignableAsSuperintendent && _accountProxy?.assignableAsProjectManager,
				isSuperintendent: _accountProxy?.assignableAsSuperintendent,
				isProjectManager: _accountProxy?.assignableAsProjectManager,
				toolbar: true,
				showSkills: !!_employee?.skills?.length,
				skills: skillsToSkillTypes(_employee?.skills, skillColorGrid),
				noCDL: _employee?.cdlStatus === CDLStatus.NO_CDL,
				isDowned: !_isAvailable,
			};
			if (!_acc[title]) {
				_acc[title] = [item];
			} else {
				_acc[title].push(item);
			}
			return _acc;
		}, { [getGroupTitle(AVAILABLE_EMPLOYEE_STATUS, SCHEDULE_BOARD_TOOLBAR_AVAILABLE)]: [] });

		const equipmentStatusMap = dailyEquipmentStatuses.reduce((_acc, _des) => {
			_acc[_des?.equipmentId] = _des;
			return _acc;
		}, {} as { [equipmentId: number]: DailyEquipmentStatusViewModel; });

		const equipmentDict = equipmentList.reduce<ToolbarResourceUnparsed<EquipmentModel>>(
			(_acc: ToolbarResourceUnparsed<EquipmentModel>, _equ: Equipment) => {
				if (!!equipmentAssignments[_equ.id] || _equ.status === ResourceStatus.DELETED || !_equ.showOnScheduleBoard) {
					return _acc;
				}
				const _dailyEquipmentStatus = equipmentStatusMap[_equ?.id];
				const _isAvailable = _dailyEquipmentStatus?.available ?? true;
				const _availability = ScheduleBoardUtil.getAvailabilityLabel(_isAvailable);
				const _key = _dailyEquipmentStatus?.equipmentStatus ?? AVAILABLE_EQUIPMENT_STATUS;
				const title = getGroupTitle(_key, _availability);

				const item = {
					title,
					code: `${_equ?.code} ${_equ?.specification ?? ''}`,
					specification: _equ?.specification,
					color: ColorUtil.getColorBackgroundClass(_equ?.equipmentCost?.category?.categoryColor),
					toolbar: true,
					isAvailable: _isAvailable,
					isDowned: !_isAvailable,
				};
				if (!_acc[title]) {
					_acc[title] = [item];
				} else {
					_acc[title].push(item);
				}
				return _acc;
			}, {}
		);

		this.toolbar = {
			labor: groupResourceGroups(parseToolbarResources(laborDict, options.employeeGroupSize), options.employeeGroupsInRow),
			equipment: groupResourceGroups(parseToolbarResources(equipmentDict, options.equipmentGroupSize), options.equipmentGroupsInRow),
		};
		const employeesDict = employees.reduce((_acc, _emp) => {
			_acc[_emp.id] = new ScheduleBoardEmployee(_emp);
			return _acc;
		}, {} as ScheduleBoardEmployeesViewModel);
		this.laborStatistics = ScheduleBoardUtil.calculateLaborStatisticsForSBEmail(employeesDict, orders, locations, showFullStatistics);
	}
}

interface ScheduleBoardTemplateOptions {
	workOrdersInRow: number;
	employeeGroupsInRow: number;
	equipmentGroupsInRow: number;
	employeeGroupSize: number;
	equipmentGroupSize: number;
}

const SCHEDULE_BOARD_EMAIL_NOTIFICATION_TEMPLATE_VIEW_MODEL: ScheduleBoardTemplateOptions = {
	workOrdersInRow: WORK_ORDERS_IN_EMAIL_ROW,
	employeeGroupsInRow: RESOURCES_IN_EMAIL_ROW,
	equipmentGroupsInRow: RESOURCES_IN_EMAIL_ROW,
	employeeGroupSize: 5,
	equipmentGroupSize: 5,
};

interface ColorItem {
	hasColor: boolean;
	color?: string;
}

interface EmployeeModel {
	employeeId: number;
	fullName: string;
	color: string;
	isSuperintendentAndProjectManager: boolean;
	isSuperintendent: boolean;
	isProjectManager: boolean;
	assignmentCount?: number;
	showAssignmentCount?: boolean;
	toolbar?: boolean;
	skills?: ColorItem[][];
	showSkills: boolean;
	isEmployee?: boolean;
	noCDL?: boolean;
	isDowned: boolean;
}

class AddressVM {
	id: number;
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	stateAbbreviation: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	shortAddress: string;
	latitude: number;
	longitude: number;
}

interface LocationViewModel {
	id: number;
	nickname: string;
	fax: string;
	phone: string;
	faxCountryCode: CountryCode;
	phoneCountryCode: CountryCode;
	color: ColorPalette;
	index: number;
	showInStatistics: boolean;
	address: AddressVM | undefined;
	isDeleted: boolean;
	departments: { name: string; }[];
}

interface DailyEquipmentStatusViewModel extends EquipmentUnavailabilityDetails {
	id: number;
	equipmentId: number;
	companyId: number;
	/** YYYY-MM-DD */
	date: string;
	equipmentStatusId: number;
	returnDate: string;
	unavailabilityReasonId: number;
	equipmentStatus: string;
	available: boolean;
	unavailabilityReason: string;
	isValid: boolean;
	/** YYYY-MM-DD */
	dateValue: string;
	priority: Priority;
	notes: string;
	updatedAt: Date;
	updatedById: Nullable<number>;
	updatedByFirstName: string;
	updatedByLastName: string;
	updatedByLocationId: number;

}

interface EquipmentUnavailabilityDetails {
	priority?: Nullable<Priority>;
	notes?: Nullable<string>;
	updatedAt?: Nullable<Date>;
	updatedById?: Nullable<number>;
	updatedByFirstName?: Nullable<string>;
	updatedByLastName?: Nullable<string>;
	updatedByLocationId?: Nullable<number>;
	/** YYYY-MM-DD */
	returnDate?: Nullable<string>;
	unavailabilityReasonId?: Nullable<number>;
	unavailabilityReason?: Nullable<string>;
	equipmentStatusId?: Nullable<number>;
	isEquipmentDeleted?: boolean;
}

export function isEmployeeModel(item: ResourceModel): item is EmployeeModel {
	return !!(item as EmployeeModel).isEmployee;
}

export default class ScheduleBoardEmailNotificationViewModel extends ScheduleBoardTemplateViewModel {
	/**
	 * @param dueDate Format: YYYY-MM-DD
	 */
	constructor(
		workOrders: WorkOrder[],
		employees: Employee[],
		equipmentList: Equipment[],
		dueDate: string,
		skillColorGrid: SkillColorGrid,
		locations: LocationViewModel[] = [],
		employeeNotificationStatuses: DailyEmployeeStatusServiceModel[] = [],
		dailyEquipmentStatuses: DailyEquipmentStatusViewModel[] = [],
		showFullStatistics: boolean = false
	) {
		super(
			SCHEDULE_BOARD_EMAIL_NOTIFICATION_TEMPLATE_VIEW_MODEL,
			workOrders,
			employees,
			equipmentList,
			dueDate,
			skillColorGrid,
			locations,
			employeeNotificationStatuses,
			dailyEquipmentStatuses,
			showFullStatistics
		);
	}
}
