import * as React from 'react';

import { IndividualLaborStatisticsParsed } from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';

import IndividualLaborStatistics from 'af-components/LaborStatistics/IndividualLaborStatistics';

interface Props {
	totalLaborStatistics: IndividualLaborStatisticsParsed;
}

export default class TotalLaborStatistics extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		totalLaborStatistics: {} as IndividualLaborStatisticsParsed,
	};

	render() {
		const { totalLaborStatistics } = this.props;
		return (
			<div className="statistics-carousel__totals">
				<IndividualLaborStatistics
					isTotals={true}
					label="Totals"
					laborStatistics={totalLaborStatistics}
				/>
			</div>
		);
	}
}
