import * as React from 'react';
import { ResolveThunks } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import CrewTypeRequestModel from 'ab-requestModels/crewType.requestModel';

import * as CrewTypeActions from 'af-actions/crewType';

import { CREW_TYPE_CREATE } from 'af-constants/reduxForms';

import CrewTypeForm from 'af-components/SharedForms/Resources/CrewType';
import { validate } from 'af-components/SharedForms/Resources/CrewType/validation';

interface OwnProps {
	close: () => void;
}

interface DisptachProps {
	createCrewType: typeof CrewTypeActions.create;
}

type FormOwnProps = OwnProps & ResolveThunks<DisptachProps>;
type Props = FormOwnProps & InjectedFormProps<CrewTypeRequestModel, FormOwnProps>;

class CrewTypeCreate extends React.PureComponent<Props> {

	createCrewType = async (form: CrewTypeRequestModel) => {
		const { close, createCrewType } = this.props;
		await createCrewType(form);
		close();
	};

	render() {
		return (
			<CrewTypeForm onSubmit={this.createCrewType} {...this.props} />
		);
	}
}

export default reduxForm<CrewTypeRequestModel, FormOwnProps>({ form: CREW_TYPE_CREATE, validate, enableReinitialize: true })(CrewTypeCreate);
