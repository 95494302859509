import * as React from 'react';
import { Button } from 'react-bootstrap';

import { TimeSheetVM } from 'acceligent-shared/dtos/web/view/timeSheet/timeSheet';

import { OverlapMeta } from 'acceligent-shared/utils/timeSheetEntry';

import { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';

import { bemElement } from 'ab-utils/bem.util';

import TimelineBar from './TimelineBar';

interface Props {
	entities: TimelineEntitesForAccount[];
	overlaps: Nullable<Record<string, OverlapMeta>>;
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	superintendentApprovalStatus: TimeSheetVM['superintendentApprovalStatus'];
	isManagerOrAdmin: boolean;
	isSuperIntendent: boolean;
	openViewHistoryModal?: () => void;
	showHistory?: boolean;
}

const Timeline: React.FC<Props> = (props: Props) => {
	const {
		entities,
		overlaps,
		employeeApprovalStatus,
		isManagerOrAdmin,
		isSuperIntendent,
		openViewHistoryModal,
		superintendentApprovalStatus,
		showHistory = true,
	} = props;

	const timelineClassName = 'time-sheet-list__row__timeline';

	const showHistoryButton = showHistory && (isManagerOrAdmin || isSuperIntendent);

	return (
		<div className={timelineClassName}>
			<div>
				<div className={bemElement(timelineClassName, 'title')}>
					TIMELINE
				</div>
				{showHistoryButton &&
					<Button className="btn btn--link" onClick={openViewHistoryModal}>
						<span>View history</span>
					</Button>
				}
			</div>
			{(entities?.length > 0) &&
				<TimelineBar
					employeeApprovalStatus={employeeApprovalStatus}
					entities={entities}
					isManagerOrAdmin={isManagerOrAdmin}
					overlaps={overlaps}
					superintendentApprovalStatus={superintendentApprovalStatus}
				/>
			}
		</div>
	);
};

export default Timeline;
