import * as React from 'react';
import * as qs from 'query-string';
import { CustomRouteComponentProps } from 'react-router-dom';

type Props = CustomRouteComponentProps;

export default class WorkOrderConfirmationUnassignedContainer extends React.PureComponent<Props> {

	render() {
		const { location: { search } } = this.props;
		const { code, dueDate } = qs.parse(search) as { code: string; dueDate: string; };

		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone form-box--padded">
					<div className="form-box__icon-container">
						<span className="form-box__icon icon-not_found" />
					</div>
					<h2>Work Order notice</h2>
					<h4>You are no longer assigned to (<strong>{code}</strong>) on <strong>{dueDate}</strong>.</h4>
					<h4>If you have any questions please check for more recent notifications or with your superintendent.</h4>
				</div>
			</div>
		);
	}
}
