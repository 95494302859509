import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { Option } from 'react-select/src/filters';

import LocationOptionVM from 'ab-viewModels/location/option.viewModel';
import ToolVM from 'ab-viewModels/tool/option.viewModel';
import WorkRequestOptionVM from 'ab-viewModels/workRequest/workRequestOption.viewModel';
import AccountOptionVM from 'ab-viewModels/account/option.viewModel';
import { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';
import EquipmentOptionVM from 'ab-viewModels/equipment/EquipmentOption.viewModel';

import * as JobAction from 'af-actions/workRequests';
import * as LocationAction from 'af-actions/location';
import * as ToolAction from 'af-actions/tool';
import * as EquipmentAction from 'af-actions/equipment';

import Textarea from 'af-fields/Textarea';
import Select from 'af-fields/SelectField';

import ToolLabel from './ToolOptionLabel';
import EquipmentLabel from './EquipmentOptionLabel';

interface OwnProps {
	superintendents: EmployeeOptionVM[];
	operators: EmployeeOptionVM[];
	disabled?: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const getSelectOptionValue = (option: { id: number; }) => option.id;
const getSelectAccountOptionValue = (option: { accountId: number; }) => option.accountId;

const formatLocationOptionLabel = (option: LocationOptionVM) => <div>{option.nickname}</div>;
const formatEmployeeOptionLabel = (option: EmployeeOptionVM) => <div>{option.firstName} {option.lastName}</div>;
const formatJobOptionLabel = (option: WorkRequestOptionVM) => <div>{option.jobCode}</div>;

const filterSelects = (textA?: string, textB?: string) => (textA ?? '').toLowerCase().includes((textB ?? '').toLowerCase());
const filterLocations = ({ data }: Option, text: string) => filterSelects(data.nickname, text);
const filterEmployee = ({ data }: Option, text: string) => filterSelects(`${data.firstName} ${data.lastName} ${data.uniqueId}`, text);
const filterJob = ({ data }: Option, text: string) => filterSelects(data?.jobCode ?? '', text);
const filterEquipment = ({ data }: Option, text: string) => filterSelects(`${data.code} ${data.specification}`, text);
const filterTool = ({ data }: Option, text: string) => filterSelects(`${data.serialNumber} ${data.toolType}`, text);

const LocationSelect = Select as unknown as new () => Select<LocationOptionVM>;
const ToolSelect = Select as unknown as new () => Select<ToolVM>;
const JobSelect = Select as unknown as new () => Select<WorkRequestOptionVM>;
const EquipmentSelect = Select as unknown as new () => Select<EquipmentOptionVM>;
const AccountSelect = Select as unknown as new () => Select<AccountOptionVM>;

const DetailsSection = (props: Props) => {
	const {
		superintendents,
		operators,
		findAllTools,
		findAllLocations,
		findAllJobs,
		findAllEquipment,
		disabled,
	} = props;

	const [loading, setLoading] = React.useState(false);
	const [locations, setLocations] = React.useState<LocationOptionVM[]>([]);
	const [tools, setTools] = React.useState<ToolVM[]>([]);
	const [jobs, setJobs] = React.useState<WorkRequestOptionVM[]>([]);
	const [equipment, setEquipment] = React.useState<EquipmentOptionVM[]>([]);

	React.useEffect(() => {
		setLoading(true);
	}, []);

	React.useEffect(() => {
		if (loading) {
			const loadStuff = async () => {
				const [
					_tools,
					_locations,
					_jobs,
					_equipment,
				] = await Promise.all([
					findAllTools(),
					findAllLocations(),
					findAllJobs(),
					findAllEquipment(),
				]);

				setTools(_tools);
				setLocations(_locations);
				setJobs(_jobs);
				setEquipment(_equipment);
				setLoading(false);
			};
			loadStuff();
		}
	}, [findAllEquipment, findAllJobs, findAllLocations, findAllTools, loading]);

	return (
		<Row className="row--padded">
			<Col md={6}>
				<Field
					component={ToolSelect}
					filterOption={filterTool}
					formatOptionLabel={ToolLabel}
					getOptionValue={getSelectOptionValue}
					isDisabled={disabled}
					label="Tool *"
					name="tool"
					options={tools}
					placeholder="Select tool"
				/>
				<Field
					component={JobSelect}
					filterOption={filterJob}
					formatOptionLabel={formatJobOptionLabel}
					getOptionValue={getSelectOptionValue}
					isDisabled={disabled}
					label="Job Number"
					name="workRequest"
					options={jobs}
					placeholder="Select JobNumber"
				/>
				<Field
					component={AccountSelect}
					filterOption={filterEmployee}
					formatOptionLabel={formatEmployeeOptionLabel}
					getOptionValue={getSelectAccountOptionValue}
					isDisabled={disabled}
					label="Superintendent *" // SELECT * FROM bamboohr_master bm LEFT JOIN bamboohr_role_master brm ON bm.bamboohr_role_id = brm.bamboohr_role_id WHERE bamboohr_status = 'A' AND brm.bamboohr_role_name = 'Superintendent' $append_sql ORDER BY first_name ASC
					name="superintendent"
					options={superintendents}
					placeholder="Select Superintendent"
				/>
			</Col>
			<Col md={6}>
				<Field
					component={LocationSelect}
					filterOption={filterLocations}
					formatOptionLabel={formatLocationOptionLabel}
					getOptionValue={getSelectOptionValue}
					isDisabled={disabled}
					label="Repair Location *"
					name="location"
					options={locations}
					placeholder="Select Repair Location"
				/>
				<Field
					component={EquipmentSelect}
					filterOption={filterEquipment}
					formatOptionLabel={EquipmentLabel}
					getOptionValue={getSelectOptionValue}
					isDisabled={disabled}
					label="Equipment"
					name="equipment"
					options={equipment}
					placeholder="Select Equipment"
				/>
				<Field
					component={AccountSelect}
					filterOption={filterEmployee}
					formatOptionLabel={formatEmployeeOptionLabel}
					getOptionValue={getSelectAccountOptionValue}
					isDisabled={disabled}
					label="Operator *" // SELECT * FROM bamboohr_master WHERE bamboohr_status = 'A' $append_sql ORDER BY first_name ASC
					name="operator"
					options={operators}
					placeholder="Select Operator"
				/>
			</Col>
			<Col md={12}>
				<Field
					component={Textarea}
					controlCursor={true}
					disabled={disabled}
					label="Reported Issue *"
					name="reportedIssue"
					rows={6}
				/>
			</Col>
		</Row>
	);
};

function mapDispatchToProps() {
	return {
		findAllTools: ToolAction.findAllForList,
		findAllLocations: LocationAction.findList,
		findAllJobs: JobAction.findWorkRequestOptionsForPurchaseOrder,
		findAllEquipment: EquipmentAction.findForPurchaseOrderOptions,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(DetailsSection);
