import * as React from 'react';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	message: string;
	showToolTip?: boolean;
}

type Props = OwnProps;

const ElipsisCell: React.FC<Props> = (props: Props) => {
	const { message, showToolTip } = props;

	if (showToolTip) {
		return (
			<Tooltip
				message={message}
			>
				<div className="selectable-text-cell">{message}</div>
			</Tooltip>
		);
	}

	return (
		<div className="selectable-text-cell">{message}</div>
	);
};

export default React.memo(ElipsisCell);
