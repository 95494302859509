import * as React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { CellContext } from '@tanstack/react-table';

import OrderItemStatus from 'acceligent-shared/enums/orderItemStatus';
import { VendorPackageTypeLabel } from 'acceligent-shared/enums/vendorPackageType';
import { OrderItemStatusLabelingMap } from 'acceligent-shared/enums/orderItemStatus';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-constants/value';

import ItemDepartmentVM from 'ab-viewModels/ItemDepartment/itemDepartment.viewModel';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';
import SimpleTableField, { SimpleTableRow } from 'af-fields/SimpleTable';

import TextHighlight from 'af-components/TextHighlight';
import DropdownComponent from 'af-components/Controls/Dropdown';
import { SimpleTableProps } from 'af-components/Controls/SimpleTable/types';
import ImageTag from 'af-components/Image';

import { useLazyLoad, useToggle } from 'af-utils/react.util';
import { dollarFormatter } from 'af-utils/format.util';

import OrderUpsertFM, { OrderItemFM } from './formModel';
import styles from './styles.module.scss';

export type OwnProps = {
	findItemDepartments: () => Promise<ItemDepartmentVM[]>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: ValidationErrors;
	calculateAndSetTotal: (orderItems: OrderItemFM[]) => void;
	disabled: boolean;
	initialized: boolean;
	formValues: OrderUpsertFM;
	isInEditMode: boolean;
	setHasItemsInEditMode: (value: boolean) => void;
};

type Props = WrappedFieldArrayProps<OrderUpsertFM['items'][0]> & OwnProps;

const ORDER_STATUS_ITEMS = Object.keys(OrderItemStatusLabelingMap).map((_status) => ({ id: _status, label: OrderItemStatusLabelingMap[_status] }));

const renderSelectedItemDepartmentOption = (option: ItemDepartmentVM) => {
	return (
		<div key={option.id}>
			{option.itemName}
			{option.packageType ? ` (${option.packageType}) ` : ''}
			{dollarFormatter.format(option.price)}
		</div>
	);
};
const renderItemDepartmentOptionItem = (option: ItemDepartmentVM, searchText: string) => {
	return (
		<>
			<ImageTag
				fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
				minSize={BlobStorageImageSizeContainer.SIZE_50X50}
				src={option.imageUrl}
				tryOriginal={true}
				tryRoot={true}
			/>
			<div className={styles['order-form__menu-option']} key={option.id}>
				<div className={styles['order-form__menu-option__text']}>
					<span className={styles['order-form__menu-option__text__name']}>
						<TextHighlight searchText={searchText} text={option.itemName} />
						{option.packageType ? `(${option.packageType})` : ''}
						{option.partNumber ? ` ${option.partNumber}` : ''}
					</span>
					{dollarFormatter.format(option.price)}
				</div>
				<div>
					<small className={styles['order-form__menu-option__sub-text']}>
						<TextHighlight searchText={searchText} text={option.modelNumber} />
						&nbsp;|&nbsp;
						<TextHighlight searchText={searchText} text={option.locationNickname} />
						&nbsp;|&nbsp;
						<TextHighlight searchText={searchText} text={option.departmentName} />
						&nbsp;|&nbsp;
						{option.partNumber &&
							<>
								<TextHighlight searchText={searchText} text={option.partNumber} />
								&nbsp;|&nbsp;
							</>}
						{` On stock ${option.currentStock}`}
					</small>
				</div>
			</div>
		</>

	);
};

const OrderItems: React.FC<Props> = (props) => {
	const { fields, errors, initialized, disabled, findItemDepartments, calculateAndSetTotal, formValues, isInEditMode, setHasItemsInEditMode } = props;

	const { lazyLoad: lazyLoadItemDepartments, options: itemDepartmentsOptions } = useLazyLoad(findItemDepartments);
	const [departmentOptions, setDepartmentOptions] = React.useState<ItemDepartmentVM[]>([]);
	const [itemOptionsInitialized, setItemOptionsInitialized] = React.useState(false);
	const {
		value: showSelectItemDropdown,
		setToFalse: hideItemDropdown,
		setToTrue: showItemDropdown,
	} = useToggle(false);

	React.useEffect(() => {
		calculateAndSetTotal(fields.getAll());
	}, [calculateAndSetTotal, fields]);

	React.useEffect(() => {
		const currentItems = fields.getAll();
		if (!itemOptionsInitialized && itemDepartmentsOptions.length && (currentItems !== undefined || !isInEditMode)) {
			setDepartmentOptions(itemDepartmentsOptions.filter((i) => !currentItems?.find((c) => c.itemDepartmentId === i.id)));
			setItemOptionsInitialized(true);
		}
	}, [fields, isInEditMode, itemDepartmentsOptions, itemOptionsInitialized]);

	const selectItem = React.useCallback((item: ItemDepartmentVM) => {
		fields.push({
			id: item.id,
			orderItemId: null,
			itemDepartmentId: item.id,
			itemDepartment: item,
			quantity: 1,
			excludeFromTotalPrice: false,
			status: OrderItemStatus.UNFULFILLED,
		});
		const updatedOptions = departmentOptions.filter((option) => option.id !== item.id);
		setDepartmentOptions(updatedOptions);
		setHasItemsInEditMode(true);
		hideItemDropdown();
	}, [departmentOptions, fields, hideItemDropdown, setHasItemsInEditMode]);

	const renderSelectItemDropdown = React.useCallback(() => {
		return (
			<div className={styles['item-table-footer__dropdown']}>
				<div>
					<DropdownComponent
						containerClassName={styles['item-dropdown']}
						filterable={true}
						filterBy={['itemName', 'locationNickname', 'departmentName', 'partNumber', 'modelNumber']}
						id="item-dropdown"
						onLazyLoad={lazyLoadItemDepartments}
						onValueChange={selectItem}
						options={departmentOptions}
						placeholder="Select From Inventory System"
						renderMenuItem={renderItemDepartmentOptionItem}
						renderSelected={renderSelectedItemDepartmentOption}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<span className={`icon-close ${styles['item-table-footer__action']}`} onClick={hideItemDropdown} />
			</div>
		);
	}, [lazyLoadItemDepartments, selectItem, departmentOptions, hideItemDropdown]);

	const renderAddAction = React.useCallback(() => {
		if (disabled) {
			return;
		}
		return (
			<div className={styles['item-table-footer__action']} onClick={showItemDropdown}>
				<span className="icon-plus" />
				<span>Add Item</span>
			</div>
		);
	}, [showItemDropdown, disabled]);

	// Use a ref to store the previous statuses
	const previousItemStatusesRef = React.useRef<string[] | undefined>();

	// We need this to make sure we only rerender columns when the item statuses change
	const itemStatuses = React.useMemo(() => {
		const newStatuses = formValues?.items.map((item) => item.status).sort();

		if (previousItemStatusesRef.current?.join(',') !== newStatuses?.join(',')) {
			previousItemStatusesRef.current = newStatuses;
			return newStatuses;
		}
		return previousItemStatusesRef.current;
	}, [formValues?.items]);

	const getOrderItemStatus = React.useCallback((index: number) => {
		return formValues?.items[index]?.status ?? OrderItemStatus.UNFULFILLED;

		// We don't want anything more here, we specifically want to depend ONLY on statuses, because if we depend on anything more it will cause problems
		// as it will rerender input elements as we are putting the info in and we will lose focus
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemStatuses]);

	const renderFulfilledQuantityField = React.useCallback((_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
		const status = getOrderItemStatus(_cell.row.original.index);
		const showField = status === OrderItemStatus.PARTIAL_ORDER;

		// only enabled if partial order
		const disableField = disabled || !_cell.row.original.isInEditMode;

		return (
			showField &&
			<Field
				component={Input}
				disabled={disableField}
				min={0}
				name={`${_cell.row.original.name}.fulfilledQuantity`}
				type="number"
			/>
		);
	}, [disabled, getOrderItemStatus]);

	const columns: SimpleTableProps<OrderItemFM & SimpleTableRow>['columns'] = React.useMemo(() => [
		{
			id: 'quantity',
			size: 100,
			cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode && !disabled) {
					return (
						<Field
							component={Input}
							min={1}
							name={`${_cell.row.original.name}.quantity`}
							type="number"
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
			header: 'Quantity',
			accessor: 'quantity',
			enableSorting: false,
		},
		{
			id: 'currentStock',
			cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, string>) => {
				const itemDepartment = _cell.row.original.itemDepartment;
				if (!itemDepartment) {
					return 'N/A';
				}

				const { departmentName, locationNickname, currentStock } = itemDepartment;
				return `${locationNickname} | ${departmentName}: ${currentStock}`;
			},
			header: 'Current Stock',
			accessor: 'itemDepartment',
		},
		{
			id: 'partNumber',
			cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, string>) => {
				return _cell.row.original.itemDepartment?.partNumber ?? 'N/A';
			},
			header: 'Part Number',
			accessor: 'itemDepartment',
			enableSorting: true,
		},
		{
			id: 'itemName',
			cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, string>) => {
				return _cell.row.original.itemDepartment?.itemName ?? 'N/A';
			},
			header: 'Item Name',
			size: 300,
			accessor: 'itemDepartment',
		},
		{
			id: 'itemType',
			size: 100,
			cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, string>) => {
				return _cell.row.original.itemDepartment?.packageType ? VendorPackageTypeLabel[_cell.row.original.itemDepartment?.packageType] : 'N/A';
			},
			header: 'Item Type',
			accessor: 'itemDepartment',
		},
		{
			id: 'price',
			size: 100,
			cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
				return dollarFormatter.format(_cell.row.original.itemDepartment?.price ?? 0);
			},
			header: 'Price Each',
			accessor: 'itemDepartment',
		},
		{
			id: 'excludeFromTotalPrice',
			size: 150,
			cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
				return (
					<Field
						component={Checkbox}
						isDisabled={disabled || !_cell.row.original.isInEditMode}
						name={`${_cell.row.original.name}.excludeFromTotalPrice`}
					/>
				);
			},
			header: 'Exclude Price from Total',
			accessor: 'excludeFromTotalPrice',
		},
		{
			id: 'status',
			cell: (_cell: CellContext<OrderItemFM & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							className={styles['dropdown-white-background']}
							component={Dropdown}
							disabled={disabled}
							labelKey="label"
							name={`${_cell.row.original.name}.status`}
							options={ORDER_STATUS_ITEMS}
							valueKey="id"
							withCaret={true}
						/>
					);
				}
				return OrderItemStatusLabelingMap[_cell.getValue()] ?? null;
			},
			header: 'Status',
			accessor: 'status',
		},
		{
			id: 'fulfilledQuantity',
			size: 100,
			cell: renderFulfilledQuantityField,
			header: 'Received Quantity',
			accessor: 'fulfilledQuantity',
		}], [disabled, renderFulfilledQuantityField]);

	return (
		<SimpleTableField
			allowEdit={!disabled}
			columns={columns}
			emptyTableMessage='No items added. Press "+ Add" to add an item.'
			errors={errors?.items}
			fields={fields}
			footerComponent={showSelectItemDropdown ? renderSelectItemDropdown : renderAddAction}
			initialized={initialized}
			notifyAreThereItemsInEditMode={setHasItemsInEditMode}
		/>
	);
};

export default React.memo(OrderItems);
