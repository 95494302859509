import * as React from 'react';
import { Button } from 'react-bootstrap';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import ImageTag from 'af-components/Image';
import ImageUpload from 'af-components/ImageUpload';

interface OwnProps {
	onImageUploadSave: (image: File, imageUrl: string) => void;
	imageUrl: string | null;
	fallbackSrc: string;
	minSize: BlobStorageImageSizeContainer;
	image: string | File | null;
	deleteImage: () => void;
}

type Props = OwnProps;

interface State {
	showModal: boolean;
}

class ImageUploadFull extends React.PureComponent<Props, State> {
	state: State = {
		showModal: false,
	};

	openModal = () => this.setState(() => ({ showModal: true }));
	closeModal = () => this.setState(() => ({ showModal: false }));

	render() {
		const { imageUrl, fallbackSrc, minSize, image, deleteImage, onImageUploadSave } = this.props;
		const { showModal } = this.state;

		return (
			<div className="image-upload">
				{/* ImageUpload not visible, only the row below it */}
				<ImageUpload
					closeModal={this.closeModal}
					onSave={onImageUploadSave}
					showModal={showModal}
				/>
				<ImageTag
					fallbackSrc={fallbackSrc}
					minSize={minSize}
					src={imageUrl}
					tryOriginal={true}
					tryRoot={true}
				/>
				<div className="image-upload__buttons">
					<Button
						className="image-upload__button"
						onClick={this.openModal}
						variant="info"
					>
						<strong className="icon-upload" />
					</Button>
					<Button
						className="image-upload__button"
						disabled={!image && !imageUrl}
						onClick={deleteImage}
						variant="danger"
					>
						<strong className="icon-delete" />
					</Button>
				</div>
			</div>
		);
	}
}

export default ImageUploadFull;
