import * as React from 'react';
import { connect } from 'react-redux';

import ScheduleBoardLaborStatistics from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';

import { RootState } from 'af-reducers';

import LaborStatisticsPerLocation from 'af-components/LaborStatistics/LaborStatisticsPerLocation';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import { SCREEN_BREAKPOINT_XS, SCREEN_BREAKPOINT_S, SCREEN_BREAKPOINT_M, SCREEN_BREAKPOINT_L, SCREEN_BREAKPOINT_XL, SB_STATISTIC_WIDTH } from 'af-constants/values';

import CrewsInfoLoading from './CrewsInfoLoading';

interface OwnProps {
	dueDate: string;
}

interface StateProps {
	laborStatistics: ScheduleBoardLaborStatistics | undefined;
}

type Props = OwnProps & StateProps;

interface State {
	maxShownStatisticsCount: number;
	offset: number;
	areAllStatisticsShown: boolean;
}

class CrewsInfo extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		laborStatistics: {
			laborStatisticsPerLocation: undefined,
			totalLaborStatistics: undefined,
		},
	};

	state: State = {
		maxShownStatisticsCount: 0,
		offset: 0,
		areAllStatisticsShown: true,
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	componentDidUpdate() {
		this.updateWindowDimensions();
	}

	updateWindowDimensions = () => {
		let maxShownStatisticsCount: Nullable<number> = null;
		if (window.innerWidth < SCREEN_BREAKPOINT_XS) {
			maxShownStatisticsCount = 0;
		} else if (window.innerWidth < SCREEN_BREAKPOINT_S) {
			maxShownStatisticsCount = 0;
		} else if (window.innerWidth < SCREEN_BREAKPOINT_M) {
			maxShownStatisticsCount = 2;
		} else if (window.innerWidth < SCREEN_BREAKPOINT_L) {
			maxShownStatisticsCount = 5;
		} else if (window.innerWidth < SCREEN_BREAKPOINT_XL) {
			maxShownStatisticsCount = 8;
		} else {
			maxShownStatisticsCount = 12;
		}

		const areAllStatisticsShown: boolean = this.getNumberOfVisibleLocations() < maxShownStatisticsCount;
		this.setState(() => ({ maxShownStatisticsCount: maxShownStatisticsCount!, areAllStatisticsShown }));
	};

	getNumberOfVisibleLocations = () => {
		const { laborStatistics: { laborStatisticsPerLocation } = {} } = this.props;
		return Object.values(laborStatisticsPerLocation ?? {}).filter((data) => !data.hidden).length;
	};

	increaseOffset = () => {
		const { offset, maxShownStatisticsCount } = this.state;
		if (offset > this.getNumberOfVisibleLocations() - maxShownStatisticsCount) {
			return;
		}
		this.setState((state: State) => ({ offset: state.offset + 1 }));
	};

	decreaseOffset = () => {
		const { offset } = this.state;
		if (offset <= 0) {
			return;
		}
		this.setState((state: State) => ({ offset: state.offset - 1 }));
	};

	render() {
		const { laborStatistics: { laborStatisticsPerLocation, totalLaborStatistics } = {} } = this.props;
		const { offset, maxShownStatisticsCount, areAllStatisticsShown } = this.state;

		if (!totalLaborStatistics) {
			return (<CrewsInfoLoading />);
		}

		return (
			<>
				{
					!!maxShownStatisticsCount && !areAllStatisticsShown &&
					<span className="btn btn--rectangle btn--icon btn--open-right" onClick={this.decreaseOffset}><span className="icon-left" /></span>
				}
				{
					!!maxShownStatisticsCount &&
					<div className={`labor-statistics ${areAllStatisticsShown ? '--centered' : ''}`} style={{ width: `${maxShownStatisticsCount * SB_STATISTIC_WIDTH}px` }}>
						<LaborStatisticsPerLocation
							laborStatisticsPerLocation={laborStatisticsPerLocation}
							offset={offset}
							totalLaborStatistics={totalLaborStatistics}
						/>
					</div>
				}
				{
					!!maxShownStatisticsCount && !areAllStatisticsShown &&
					<span className="btn btn--rectangle btn--icon btn--open-left" onClick={this.increaseOffset}><span className="icon-right" /></span>
				}
			</>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate } = ownProps;
	const workOrdersOnDate = state?.scheduleBoard?.workOrdersByDateDictionary?.[dueDate];

	if (workOrdersOnDate?.laborStatistics) {
		return {
			laborStatistics: ScheduleBoardUtil.ignoreUndefinedLocation(workOrdersOnDate?.laborStatistics) ?? undefined,
		};
	}

	return {
		laborStatistics: {
			laborStatisticsPerLocation: undefined,
			totalLaborStatistics: undefined,
		},
	};
}

export default connect(mapStateToProps, null)(CrewsInfo);
