import * as React from 'react';

import CustomScrollbar from 'af-components/CustomScrollbar';

import { DEFAULT_LABOR_PLACEHOLDER } from 'ab-constants/value';

import UnavailableEmployees from './UnavailableEmployees';
import AvailableEmployees from './AvailableEmployees';
import ResourcePlaceholder from '../../ResourcePlaceholder';

import { EmployeeModalProps } from '../../../Shared/ModalProps';

interface OwnProps extends EmployeeModalProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	sendNotification: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
	scheduleAutoNotify: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
}

type Props = OwnProps;

export default (props: Props) => {
	const {
		dueDate,
		sendNotification,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		hasPermissionsToSendNotifications,
		scheduleAutoNotify,
		setEmployeeModalData,
		setEmployeeModalVisibility,
	} = props;

	return (
		<CustomScrollbar
			contentWrapperClassName="schedule-board-toolbar-column employees"
			isHorizontalHidden={true}
			isVerticalHidden={true}
			scrollbarsClassName="schedule-board-toolbar-column-wrapper employees"
		>
			<ResourcePlaceholder
				dueDate={dueDate}
				isPlaceholder={false}
				label="Labor"
				uniqueCode={DEFAULT_LABOR_PLACEHOLDER}
			/>
			<AvailableEmployees
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
				isDragAndDropDisabled={isDragAndDropDisabled}
				scheduleAutoNotify={scheduleAutoNotify}
				sendNotification={sendNotification}
				setEmployeeModalData={setEmployeeModalData}
				setEmployeeModalVisibility={setEmployeeModalVisibility}
			/>
			<UnavailableEmployees
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				isDragAndDropDisabled={isDragAndDropDisabled}
				setEmployeeModalData={setEmployeeModalData}
				setEmployeeModalVisibility={setEmployeeModalVisibility}
			/>
		</CustomScrollbar>
	);
};
