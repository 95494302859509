import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';

import FieldReportBlockType from 'acceligent-shared/enums/fieldReportBlockType';
import FieldReportAccessRoleEnum from 'acceligent-shared/enums/fieldReportAccessRole';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import WorkOrderReviewStatus, { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';

import { BlockValueType } from 'acceligent-shared/utils/fieldReport';

import { bemElement } from 'ab-utils/bem.util';

import { RootState } from 'af-reducers';

import * as FieldReportActions from 'af-actions/fieldReport';

import LoadingOverlay from 'af-components/LoadingOverlay';

import TotalBlock from './TotalBlock';
import Instance from './Instance';
import { shouldReportForAccountBeReadOnly } from 'acceligent-shared/utils/workOrderFieldReport';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import { isAllowed } from 'ab-utils/auth.util';
import { RequestQueue } from '../../helpers';

interface OwnProps {
	areFRsReadOnly: boolean;
	fieldReportId: number;
	fieldReportTypeId: number;
	highlightedBlockId: Nullable<string>;
	change: (fieldName: string, value: BlockValueType) => void;
	lock: (blockId: string) => void;
	unlock: () => void;
	forceUnlock: (blockId: string) => void;
	isPreview: boolean;
	focusedBlockId: Nullable<number>;
	setFieldToFocus: (_ref: HTMLDivElement) => void;
	removeFocusedField: () => void;
	isAbleToReject: boolean;
	isPaused: boolean;
	fieldReportAccessRole: Nullable<FieldReportAccessRoleEnum>;
	workOrderStatus: WorkOrderStatus;
	reviewStatus: WorkOrderReviewStatus;
	reviewLevel: WorkOrderReviewLevel;
	requestQueue: RequestQueue;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	addingBlock: boolean;
}

class FieldReportType extends React.PureComponent<Props, State> {
	state: State = {
		addingBlock: false,
	};

	componentWillUnmount() {
		const { removeFocusedField, focusedBlockId } = this.props;

		if (focusedBlockId) {
			removeFocusedField();
		}
	}

	addInstance = () => this.setState(
		() => ({ addingBlock: true }),
		async () => {
			const { addInstance, fieldReportId, fieldReportTypeId } = this.props;
			const _fieldReportId = fieldReportId;
			if (isNaN(_fieldReportId)) {
				return;
			}
			await addInstance(fieldReportId, fieldReportTypeId);
			this.setState(() => ({ addingBlock: false }));
		}
	);

	renderTotalBlock = (type: FieldReportBlockType) => {
		const {
			upperTotalBlockId,
			lowerTotalBlockId,
			fieldReportTypeId,
			fieldReportId,
			focusedBlockId,
			setFieldToFocus,
			removeFocusedField,
		} = this.props;

		const blockId = type === FieldReportBlockType.UPPER_TOTAL ? upperTotalBlockId : lowerTotalBlockId;

		if (!blockId) {
			return null;
		}

		return (
			<TotalBlock
				blockId={blockId}
				fieldReportId={fieldReportId}
				fieldReportTypeId={fieldReportTypeId}
				focusedBlockId={focusedBlockId}
				key={blockId}
				removeFocusedField={removeFocusedField}
				setFieldToFocus={setFieldToFocus}
			/>
		);
	};

	renderInstance = (instanceId: string, key: number) => {
		const {
			areFRsReadOnly,
			fieldReportType,
			fieldReportTypeId,
			change,
			lock,
			highlightedBlockId,
			unlock,
			forceUnlock,
			isPreview,
			fieldReportId,
			focusedBlockId,
			setFieldToFocus,
			removeFocusedField,
			isAbleToReject,
			requestQueue,
		} = this.props;

		if (!fieldReportType) {
			return null;
		}

		return (
			<Instance
				areFRsReadOnly={areFRsReadOnly}
				change={change}
				fieldReportId={fieldReportId}
				fieldReportTypeId={fieldReportTypeId}
				focusedBlockId={focusedBlockId}
				forceUnlock={forceUnlock}
				highlightedBlockId={highlightedBlockId}
				instanceId={instanceId}
				isAbleToReject={isAbleToReject}
				isPreview={isPreview}
				key={key}
				lock={lock}
				removeFocusedField={removeFocusedField}
				requestQueue={requestQueue}
				setFieldToFocus={setFieldToFocus}
				showInstanceCount={fieldReportType.instances.length > 1}
				unlock={unlock}
			/>
		);
	};

	renderAddInstance = () => {
		const { firstBlockName } = this.props;
		return (
			<div className={bemElement('field-report-type', 'add-instance-button-container')}>
				<Button onClick={this.addInstance} variant="primary"><span className="icon-plus" /> Add {firstBlockName}</Button>
			</div>
		);
	};

	render() {
		const { fieldReportType, showAddInstance, isPreview } = this.props;
		const { addingBlock } = this.state;
		if (!fieldReportType) {
			return null;
		}

		const { instances } = fieldReportType;
		return (
			<>
				{this.renderTotalBlock(FieldReportBlockType.UPPER_TOTAL)}
				{instances.map(this.renderInstance)}
				{showAddInstance && !isPreview && this.renderAddInstance()}
				{this.renderTotalBlock(FieldReportBlockType.LOWER_TOTAL)}
				<LoadingOverlay label="Adding Block" show={addingBlock} />
			</>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { fieldReport: { fieldReport }, user: { userData, companyData } } = state;
	const {
		fieldReportTypeId,
		isPaused,
		isPreview,
		reviewLevel,
		reviewStatus,
		workOrderStatus,
		fieldReportAccessRole,
	} = ownProps;
	const fieldReportType = fieldReport?.typeMap?.[fieldReportTypeId];
	if (!fieldReportType) {
		return {
			fieldReportType: null,
			upperTotalBlockId: null,
			lowerTotalBlockId: null,
			firstBlockName: null,
			showAddInstance: false,
			isPreview: true,
		};
	}
	const upperTotalBlockId = fieldReportType.upperTotalBlockId;
	const lowerTotalBlockId = fieldReportType.lowerTotalBlockId;

	const instanceId = fieldReportType.instances[0];
	const instance = fieldReport.instanceMap[instanceId];
	const segment = fieldReport.segmentMap[instance.segments[0]];
	const fieldBlock = fieldReport.fieldReportBlockMap[segment.blocks[0]];
	const block = fieldReport.blockMap[fieldBlock.reportBlockId];

	const { isCompanyAdmin, permissions } = companyData!;
	const { role } = userData!;

	const isAccountingOrAdmin = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.ACCOUNTING, permissions, isCompanyAdmin, role);
	const isManagement = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.MANAGEMENT, permissions, isCompanyAdmin, role);
	const hasPermissionToEdit = isAccountingOrAdmin || isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FILL, permissions, isCompanyAdmin, role);
	const canEditInFinalizing = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.FINALIZING, permissions, isCompanyAdmin, role);
	const resolvedIsPreview = (fieldReportType.fwCanEditAfterSubmit && !isPaused)
		? shouldReportForAccountBeReadOnly(
			reviewStatus,
			reviewLevel,
			workOrderStatus,
			fieldReportType.fwCanEditAfterSubmit,
			isManagement ? FieldReportAccessRoleEnum.MANAGEMENT : fieldReportAccessRole,
			hasPermissionToEdit,
			isAccountingOrAdmin,
			canEditInFinalizing
		)
		: isPreview;

	return {
		fieldReportType,
		upperTotalBlockId,
		lowerTotalBlockId,
		firstBlockName: block.name,
		showAddInstance: fieldReportType?.isRepeatable,
		isPreview: resolvedIsPreview,
	};
}

function mapDispatchToProps() {
	return {
		addInstance: FieldReportActions.addInstance,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(FieldReportType);
