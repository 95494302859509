import * as React from 'react';

interface OwnProps {
	label: string;
	value: Nullable<string>;
}

type Props = OwnProps;

const FeatureFlagPreviewDay = ({ label, value }: Props) => {
	return (
		<div
			className="company-settings__feature-day"
			id={`accqa__notification-settings__default-times__${label}`}
		>
			<span>{label}</span>
			<b>{value}</b>
		</div>
	);
};

export default FeatureFlagPreviewDay;
